import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OmissoesSefazComponent} from './omissoes-sefaz.component';
import {UiModule} from '../../ui.module';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';


@NgModule({
  declarations: [OmissoesSefazComponent],
    imports: [
        CommonModule,
        UiModule,
        NzPageHeaderModule,
        NzProgressModule,
        NzBadgeModule,
        FormsModule,
        NzAlertModule,
        NzCheckboxModule,
        NzTagModule,
        ReactiveFormsModule,
        NzDatePickerModule
    ],
  exports: [OmissoesSefazComponent]
})
export class OmissoesSefazModule { }

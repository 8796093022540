<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Usuários">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Usuários"
             [ngClass]="[status ? 'card-fullscreen' : '']"
             style="max-width: 98vw!important;">

        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="checkedItems.size && onAlterarPerfilDeAcesso()"
                                    [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar perfil de acesso
                                </li>
                                <li (click)="onAlterarVisibilidadeColetiva()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar Visibilidades
                                </li>
                                <li (click)="modalStatus(true)"
                                    [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                    Alterar status Ativo/Inativo
                                </li>

                                <li (click)="modalSuporte(true)"
                                    [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                    Alterar suporte disponível
                                </li>
                                <li (click)="onEmailBoasVindas()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Enviar email de boas-vindas
                                </li>
                                <li (click)="onEmailMigracao()" [nzDisabled]="checkedItems?.size === 0"
                                    *ngIf="currentUser?.origem == 'console'"
                                    nz-menu-item>
                                    Enviar email de migração
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-item (click)="modalImportar(true);">Importar Planilha</li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <span *ngIf="!todosRegistros">{{ checkedItems.size }} Registros Selecionados</span>
                        <span *ngIf="todosRegistros">{{ totalTable }} Registros Selecionados</span>
                        <span *ngIf="!todosRegistros && pagination.total > pagination.per_page">,
                            <a nz-button nzType="link" style="padding-left: 0;"
                               (click)="selecionarTodosRegistros(true)">Selecionar Todos os {{totalTable}}
                                Registros</a>
                        </span>
                        <span *ngIf="todosRegistros && pagination.total > pagination.per_page">,
                            <a nz-button nzType="link" style="padding-left: 0;"
                               (click)="selecionarTodosRegistros(false)">Desmarcar Todos</a>
                        </span>
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplateUsuarios"
                  class="table-small">
            <thead>
            <tr>
                <th style="text-align: center;">
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"
                           [nzIndeterminate]="indeterminate"></label>
                </th>
                <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                <th nzColumnKey="login" [nzSortFn]="true">Login</th>
                <th nzColumnKey="email" [nzSortFn]="true">Email</th>
                <th nzColumnKey="suporte" [nzSortFn]="true" nzAlign="center">Suporte Disponível</th>
                <th nzColumnKey="perfil_id" [nzSortFn]="true">Perfil de Acesso</th>
                <th *ngIf="true" nzColumnKey="" nzAlign="center">Visibilidades</th>
                <!--                <th *ngIf="true" nzColumnKey="">Visibilidades</th>-->
                <th nzColumnKey="ultimoLoginData" [nzSortFn]="true" nzAlign="center">Último Login</th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Cadastro Data</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let usuario of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemCheckedUsuario(usuario.id, $event)"
                    [nzChecked]="checkedItems.has(usuario?.id) || todosRegistros"
                    [nzDisabled]="usuario.disabled">
                </td>
                <td style="text-align: left;">
                    <div style="display: flex; align-items: center; justify-content: left; gap: 5px; max-width: 100%; flex-wrap: wrap;">
                        <img nz-image class="imagem-avatar" [nzSrc]="'data:image/png;base64,' + usuario?.avatar"
                             [nzFallback]="fallbackAvatar"
                             alt="{{modoDemo(usuario?.nome)}}" nzDisablePreview>
                        <a style="word-break: break-word" nz-button nzType="link"
                           (click)="openTab('/administracao/usuario/detalhe', usuario.id, {id: usuario.id, nome: usuario.nome})">{{modoDemo(usuario?.nome)}}</a>
                        <span class="badge" style="background-color: #f50;" *ngIf="!usuario?.ativo">
                            Inativo
                        </span>
                        <span class="badge" *ngFor="let e of usuario.equipes" style="background-color: #ffb700;">
                            {{e}}
                        </span>
                    </div>
                </td>
                <td nzLeft>{{modoDemo(usuario?.login)}}</td>
                <td>{{modoDemo(usuario?.email)}}
                    <nz-tag *ngIf="usuario?.foraDominio" style="margin-left: 10px; font-size: 10px">
                        Fora do domínio
                    </nz-tag>
                </td>
                <td nzAlign="center">{{usuario?.suporte ? 'Sim' : '-'}}</td>
                <td>{{usuario?.perfil_descricao}}</td>
                <td nzAlign="left" style="max-width: 400px;">
                    <div style="max-height: 100px; overflow-y: auto;">
                        <nz-tag *ngFor="let visibilidade of usuario?.visibilidades"
                                class="tagVisibilidade"
                                (click)="onListarEmpresasVisibilidade(visibilidade?.id, visibilidade?.descricao)">
                            {{visibilidade.descricao}}
                        </nz-tag>
                        <nz-tag *ngIf="usuario?.visibilidades && usuario?.visibilidades.length === 0">Geral</nz-tag>
                    </div>

                </td>
                <td *ngIf="false">
                    <span *ngIf="usuario.visibilidades.length === 0" nz-typography nzDisabled><em>nenhuma</em></span>
                    <nz-tag *ngFor="let visibilidade of usuario?.visibilidades">{{visibilidade?.descricao}}</nz-tag>
                </td>
                <td nzAlign="center">{{usuario?.ultimoLoginData | date:'dd/MM/YY - HH:mm:ss'}}
                    <span *ngIf="verificaSenhaExpirada(usuario?.senhaValidadeData)" nz-icon nzType="key"
                          nzTheme="outline"
                          nz-tooltip="Senha expirou. Nova senha será cadastrada no próximo login."
                          style="color: #1890ff; cursor: help;"></span>
                </td>
                <td nzAlign="center">{{usuario?.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td *ngIf="false" nzAlign="center">{{getStatusLabel(usuario?.ativo)}}</td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateUsuarios let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>

</nz-content>


<nz-modal [(nzVisible)]="modalCadastrarVisible" [nzTitle]="'Cadastrar Novo Usuário'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)">
    <div nz-row *nzModalContent>
        <nz-result
                *ngIf="erroLicencas"
                nzStatus="warning"
                nzTitle=""
                nzSubTitle="Esta Conta tem limite de licenças para cadastro de Usuários e todas as licenças permitidas
                já foram utilizadas. Por favor entrar em contato com o Atendimento para a aquisição de novas licenças."
        ></nz-result>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar" *ngIf="!erroLicencas">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzFor="primeiroNome" nzRequired>Nome/Sobrenome</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <div nz-row [nzGutter]="8">
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="primeiroNome" placeholder="Nome"/>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="sobreNome" placeholder="Sobrenome"/>
                        </div>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" [nzRequired]="campoLogin == 'cpf'">CPF</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="cpf" placeholder="CPF do usuário"
                           autocomplete="off" size="60" maxlength="20" mask="000.000.000-00"
                           (ngModelChange)="changeLoginCadastro();">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Email</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input formControlName="email" placeholder="informe o seu Email" type="email" size="60"
                           maxlength="60" (ngModelChange)="changeLoginCadastro();">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired
                               [nzTooltipTitle]="campoLogin === 'cpf' ? 'O CPF deverá ser utilizado como login do usuário' :
                               campoLogin === 'email' ? 'O E-mail deverá ser utilizado como login do usuário' : ''">
                    Login
                </nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="login" placeholder="Login do usuário"
                           autocomplete="off" size="60" maxlength="30">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7"></nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <label nz-checkbox formControlName="gerarSenha"
                           (ngModelChange)="changeGerarSenha($event)"
                    >Gerar senha automaticamente</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formCadastrar.value.gerarSenha">
                <nz-form-label [nzSpan]="7" nzRequired>Senha</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group [nzSuffix]="showPassword">
                        <input style="border: none; width: 100%"
                               [type]="passwordVisible ? 'text' : 'password'"
                               formControlName="senha"
                               placeholder="Senha"
                               size="60"
                               maxlength="60"
                               nz-popover
                               nzPopoverTrigger="focus"
                               nzPopoverPlacement="right"
                               [nzPopoverTitle]="validadePasswordTitle"
                               [nzPopoverContent]="validadePasswordContent"
                               (ngModelChange)="verificaSenha($event, true)"/>
                    </nz-input-group>
                </nz-form-control>
                <ng-template #showPassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #validadePasswordTitle>
                    <h5 nz-typography style="margin: 0;">
                        <i class="fa-solid fa-key" style="margin-right: 3px;"></i> Segurança da Senha
                    </h5>
                </ng-template>
                <ng-template #validadePasswordContent>
                    <div [ngStyle]="passwordValidate.length && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.length"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.length" style="margin-right: 3px;"></span>
                        <span>A senha deve conter entre 8 e 30 caracteres.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.lowercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra minúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.uppercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra maiúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.number && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.number"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.number" style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos um número.</span>
                    </div>
                </ng-template>
            </nz-form-item>
            <nz-form-item *ngIf="!formCadastrar.value.gerarSenha"
                          [ngClass]="[!this.passwordValidate.confirm ? 'ant-form-item-with-help' : '']">
                <nz-form-label [nzSpan]="7" nzRequired>Confirma</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group [nzSuffix]="showPasswordConfirm">
                        <input nz-input
                               [type]="passwordConfirmVisible ? 'text' : 'password'"
                               formControlName="confirmeSenha"
                               placeholder="Confirme a senha"
                               (ngModelChange)="verificaConfirmSenha($event)"/>
                    </nz-input-group>
                    <div *ngIf="!this.passwordValidate.confirm"
                         class="ant-form-item-explain ant-form-item-explain-error ng-star-inserted">
                        <div class="ng-trigger ng-trigger-helpMotion">As senhas não coincidem</div>
                    </div>
                </nz-form-control>
                <ng-template #showPasswordConfirm>
                    <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordConfirmVisible = !passwordConfirmVisible" style="cursor: pointer;"></span>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Perfil de Acesso</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select formControlName="perfil_id" [nzPlaceHolder]="'Selecione'">
                        <nz-option *ngFor="let option of arrayComboPerfis" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select formControlName="visibilidade_id"
                               nzMode="multiple"
                               [nzPlaceHolder]="'Selecione'"
                               nzShowSearch>
                        <nz-option *ngFor="let option of arrayComboVisibilidades" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!erroLicencas" [nzLoading]="cadastrando"
                (click)="!cadastrando && confirmaCadastro()">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Perfil</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.perfil_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="perfil_id">
                        <nz-option *ngFor="let opt of arrayComboPerfis"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.value"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">E-mail:</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.emailForaDominio">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="emailForaDominio">
                        <nz-option [nzLabel]="'Todos'" [nzValue]="'todos'"></nz-option>
                        <nz-option [nzLabel]="'Fora de domínio'" [nzValue]="'sim'"></nz-option>
                        <nz-option [nzLabel]="'Apenas do domínio'" [nzValue]="'nao'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Equipes</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.equipes">
                    <nz-select formControlName="equipes"
                               nzMode="multiple"
                               [nzPlaceHolder]="'Selecione'"
                               nzShowSearch>
                        <nz-option *ngFor="let option of arrayComboEquipes"
                                   [nzLabel]="option.equipe"
                                   [nzValue]="option.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">


    <div *nzModalContent>
        <nz-result
                *ngIf="erroLicencas"
                nzStatus="warning"
                nzTitle=""
                nzSubTitle="Esta Conta tem limite de licenças para cadastro de Usuários e todas as licenças permitidas
                já foram utilizadas. Por favor entrar em contato com o Atendimento para a aquisição de novas licenças."
        ></nz-result>
        <div *ngIf="!erroLicencas">
            <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
            <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar
                um
                arquivo de modelo.</small><br>
            <small style="">Usuários novos serão cadastrados com a senha padrão (mudar123).</small>
            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzMultiple]="true"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal [(nzVisible)]="formSuporte.modalVisible"
          [nzTitle]="'Alterar Suporte Disponível'" [nzClosable]="true"
          (nzOnCancel)="modalSuporte(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formSuporte.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Suporte Disponível?</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="suporte" style="width: 100%;">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalSuporte(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarSuporte()" [nzLoading]="loadings.alterandoSuporte">
            Alterar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formStatus.modalVisible"
          [nzTitle]="'Alterar status Ativo/Inativo'" [nzClosable]="true"
          (nzOnCancel)="modalStatus(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formStatus.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Usuário Ativo?</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="ativo" style="width: 100%;">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalStatus(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarStatus()" [nzLoading]="loadings.alterandoStatus">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formVisibilidade.modalVisible"
          [nzTitle]="'Alterar visibilidades'" [nzClosable]="true"
          (nzOnCancel)="modalVisibilidadeColetiva(false)"
          [nzWidth]="625">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true"
              [nzLayout]="'vertical'"
              [formGroup]="formVisibilidade.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="16" [nzOffset]="4">Selecione a(s) visibilidade(s)</nz-form-label>
                <nz-form-control [nzSpan]="16" [nzOffset]="4"
                                 nzExtra="Se nenhuma selecionada, visibilidade será Geral">
                    <nz-select formControlName="visibilidades"
                               [nzPlaceHolder]="'Selecione'"
                               nzMode="multiple"
                               nzShowSearch>
                        <nz-option *ngFor="let option of arrayComboVisibilidades" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control [nzSpan]="16" [nzOffset]="4">
                    <label nz-checkbox formControlName="limpar">Limpar cadastradas anteriormente</label>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalVisibilidadeColetiva(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarVisibilidades()"
                [nzLoading]="loadings.salvandoVisibilidades">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formPerfil.modalVisible"
          [nzTitle]="'Alterar Perfil de Acesso'" [nzClosable]="true"
          (nzOnCancel)="modalAlterarPerfil(false)"
          [nzWidth]="625">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true"
              [nzLayout]="'vertical'"
              [formGroup]="formPerfil.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="16" [nzOffset]="4">Selecione o Perfil de Acesso</nz-form-label>
                <nz-form-control [nzSpan]="16" [nzOffset]="4">
                    <nz-select formControlName="perfil_id"
                               [nzPlaceHolder]="'Selecione'"
                               nzShowSearch>
                        <nz-option *ngFor="let option of arrayComboPerfis" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterarPerfil(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarPerfilAcesso()"
                [nzLoading]="loadings.salvandoPerfil">
            Alterar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalListarEmpresasVisible"
          [nzTitle]="'Empresas da visibilidade ' + nomeVisibilidadeSelecionada" [nzClosable]="true"
          (nzOnCancel)="modalListarEmpresas(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.carregandoEmpresas"></nz-skeleton>
        <nz-table #basicTable
                  [nzData]="arrayEmpresasVisibilidade"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  [nzScroll]="{ y: '340px' }"
                  style="width: 100%; font-size: 10px;"
                  *ngIf="!loadings.carregandoEmpresas"
        >
            <thead>
            <tr>
                <th>Empresa</th>
                <th>CNPJ</th>
                <th>Grupo Empresarial</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>{{ data?.nome }}</td>
                <td style="white-space: nowrap;">{{ data?.cnpj | cpfCnpj }}</td>
                <td>{{ data?.grupoDescricao }}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button class="new-markup-bnt" nz-button nzType="link"
                (click)="onEditarVisibilidade()">
            <i nz-icon nzType="edit" nzTheme="outline"></i> Editar visibilidades
        </button>
        <button nz-button nzType="link" (click)="modalListarEmpresas(false);">Fechar</button>
    </div>
</nz-modal>

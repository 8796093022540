import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';

@Injectable({providedIn: 'root'})
export class EditarResponsaveisService extends AbstractService<InscricaoRegime> {

    urlCertificados = environment.apiUrl + '/cadastros/responsavel/certificados';
    urlProcuracoes = environment.apiUrl + '/cadastros/responsavel/procuracoes';
    urlProcuradores = environment.apiUrl + '/cadastros/responsavel/procuradores';
    urlAcessos = environment.apiUrl + '/cadastros/responsavel/acessos-estaduais';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaDadosCadastrais(responsavelId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/responsavel/detalhe/${responsavelId}`);

    }

    editar(responsavelId: string, dados): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/responsavel/detalhe/${responsavelId}`, dados);

    }

    retornaComboUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaObrigacoesUF(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/estaduais/select/${uf}`);

    }

    retornaCamposAcessosEstaduais(obrigacao: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/obrigacao/dados-acesso/${obrigacao}`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaDadosDataPrev(empresaId: any): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-dataprev/detalhe/${empresaId}`);
    }


    insertProcuracoes(responsavelId: string, dados: any): Observable<any> {

        dados.responsavel_id = responsavelId;

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel/procuracao`, dados);

    }

    insertCertificado(empresaId: string, dados: any): Observable<any> {

        dados.responsavel_id = empresaId;
        dados.tipo = 'cpf';

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel/certificado`, dados);

    }

    insertProcuradores(responsavalId: string, dados: any): Observable<any> {

        dados.responsavel_id = responsavalId;

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel/procurador`, dados);

    }

    insertAcessos(responsavelId: string, dados: any): Observable<any> {

        dados.responsavel_id = responsavelId;

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel/acesso-estadual`, dados);

    }

    updateProcuracoes(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/responsavel/procuracao/${id}`, dados);

    }

    updateProcuradores(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/procurador/detalhe/${id}`, dados);

    }

    updateAcessos(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/detalhe/${id}`, dados);

    }

    updateCertificados(id: string, dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel/certificado/detalhe/${id}`, dados);

    }

    removeProcuracoes(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/procuracao/${id}`);

    }

    removeProcuradores(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/procurador/${id}`);

    }

    removeCertificados(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/${id}`);

    }

    removeAcessos(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/${id}`);

    }

    sincronizarEmbedded(acao: string, id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/responsavel/detalhe/${id}/sincronizarEmbedded/${acao}`);

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }


    exportExcel(certificadoId: any, senha: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/download/${certificadoId}?senha=${senha}`,
            {responseType: 'blob'});

    }

    buscarProcuracoes(id: any): Observable<any> {

        const dados: any = {
            responsavel_id: id,
            robo: 'ecac',
            tipo: 'procuracao'
        };
        return this.http.post(`${environment.apiUrl}/robo/rpa/solicitacao`, dados);

    }


}

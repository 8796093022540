<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Editar Responsável"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce"
                         [nzBodyStyle]="this.loadings.dadosCadastrais ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="this.loadings.dadosCadastrais">
                    <nz-card-meta *ngIf="data.documento_tipo === 'cpf' else cnpj"
                                  [nzTitle]="modoDemo(dadosCadastrais.nome, 'empresaCodigoNome')"
                                  [nzDescription]="dadosCadastrais.id ? 'CPF: ' + modoDemo((dadosCadastrais.documento | cpfCnpj), 'cpf') : ''">
                    </nz-card-meta>
                    <ng-template #cnpj>
                        <nz-card-meta [nzTitle]="modoDemo(dadosCadastrais.nome, 'empresaCodigoNome')"
                                      [nzDescription]="dadosCadastrais.id ? 'CNPJ: ' + modoDemo((dadosCadastrais.documento | cpfCnpj), 'cnpj') : ''">
                        </nz-card-meta>
                    </ng-template>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="ID">
                                <nz-list-item-meta-title>
                                    {{dadosCadastrais.id}}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Criado em">
                                <nz-list-item-meta-title>
                                    {{dadosCadastrais.created_at ? (dadosCadastrais.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                    <small *ngIf="dadosCadastrais.criado_por"><br>Por
                                        <b>{{dadosCadastrais.criado_por}}</b></small>
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última alteração">
                                <nz-list-item-meta-title>
                                    {{dadosCadastrais.updated_at ? (dadosCadastrais.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                    <small *ngIf="dadosCadastrais.atualizado_por"><br>Por
                                        <b>{{dadosCadastrais.atualizado_por}}</b></small>
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#st1" nzTitle="Certificado A1"></nz-link>
                    <div *ngIf="!data.apenasCertificado">
                        <nz-link nzHref="#st2" nzTitle="Procurações E-CAC"></nz-link>
                        <nz-link nzHref="#st3" nzTitle="Procuradores E-CAC"></nz-link>
                        <nz-link nzHref="#st4" nzTitle="Acessos Estaduais"></nz-link>
                    </div>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card *ngIf="!data.apenasCertificado" nzTitle="Dados Cadastrais" [nzExtra]="extraDadosCadastrais"
                     [nzBodyStyle]="this.loadings.dadosCadastrais ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="this.loadings.dadosCadastrais">
                <ng-template #extraDadosCadastrais>
                    <button nz-button nzType="primary" (click)="onAlterarResponsavel(true);">
                        Alterar
                    </button>
                    <button nz-button nzType="primary" *ngIf="currentUser?.origem === 'console'"
                            style="margin-left: 10px;" (click)="sincronizarEmbedded('importar');"
                            nz-tooltip [nzTooltipTitle]="titleTemplate" [nzLoading]="loadings.importando">
                        {{loadings.importando ? 'Importando...' : 'Importar'}}
                    </button>
                    <ng-template #titleTemplate let-thing>
                        <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                        <span style="margin-left: 5px;">Usuário console</span>
                    </ng-template>

                    <button nz-button nzType="primary" *ngIf="currentUser?.origem === 'console'"
                            (click)="sincronizarEmbedded('exportar');"
                            style="margin-left: 10px;"
                            nz-tooltip [nzTooltipTitle]="titleTemplate" [nzLoading]="loadings.exportando">
                        {{loadings.exportando ? 'Exportando...' : 'Exportar'}}
                    </button>
                </ng-template>
                <nz-list id="dadosCadastrais">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Nome">
                            <nz-list-item-meta-title>
                                {{modoDemo(dadosCadastrais.nome, 'empresaCodigoNome')}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="{{(data.documento_tipo | uppercase)}}">
                            <nz-list-item-meta-title>
                                {{dadosCadastrais.documento ? modoDemo((dadosCadastrais.documento | cpfCnpj), dadosCadastrais.documento_tipo) : '-'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <nz-card id="st1" class="ant-card-table" nzTitle="Certificado A1" [nzExtra]="st1Extra"
                     [nzBodyStyle]="this.loading.certificados ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="this.loading.certificados">
                <ng-template #st1Extra>
                    <button nz-button nzType="primary" (click)="cadastrar(formAdicionarCertificado);">
                        Adicionar
                    </button>
                </ng-template>
                <nz-table #basicTable1
                          nzSize="small"
                          [nzData]="listaCretificados"
                          [nzFrontPagination]="false"
                          [nzTotal]="pagination.certificados?.total"
                          [nzPageSize]="pagination.certificados?.per_page"
                          [nzPageIndex]="pagination.certificados?.current_page"
                          (nzQueryParams)="listarCertificados($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="emissor" [nzSortFn]="true" nzAlign="left">Emissor</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Categoria</th>
                        <th nzColumnKey="situacao" [nzSortFn]="false" nzShowSort="false" nzAlign="center">Situação</th>
                        <th nzColumnKey="validade" [nzSortFn]="true" nzAlign="center">Validade</th>
                        <th nzColumnKey="padrao" [nzSortFn]="true" nzAlign="center">Padrão</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable1.data">
                        <td>{{item.emissor}}</td>
                        <td nzAlign="center">{{item.tipo_descricao}}</td>
                        <td nzAlign="center">{{item.situacao_descricao}}</td>
                        <td nzAlign="center">{{item.validade | date: 'dd/MM/yyyy'}}</td>
                        <td nzAlign="center">{{item?.padrao ? (item?.padrao.toString() === '1' ? 'Sim' : '-') : '-'}}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarCertificadoA1(item);"
                                    style="margin-right: 10px;">
                                Detalhe
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('certificados', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <ng-template #templateToltipProcuracoes>
                <span nz-icon nzType="info-circle"
                      nzTheme="outline"
                      nzTooltipTitle="Empresas que eu posso acessar"
                      nzTooltipPlacement="bottomLeft"
                      style="color: #E4A11B; padding-right: 10px;font-weight: bold;cursor: pointer"
                      nz-button nz-tooltip>
                </span>
                <span>Procurações E-CAC</span>
            </ng-template>
            <div *ngIf="!data.apenasCertificado">
                <nz-card id="st2" class="ant-card-table" [nzTitle]="templateToltipProcuracoes" [nzExtra]="st2Extra"
                         [nzBodyStyle]="loading.procuracoes ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.procuracoes"
                         [ngClass]="[fullCardProcuracoes ? 'card-fullscreen' : 'ant-card-table']">
                    <ng-template #st2Extra>
                        <div style="display: flex;">
                            <button nz-button nzType="default" style="margin-right: 15px;"
                                    (click)="showConfirmBuscarProcuracao();"
                                    [nzLoading]="loading.buscarProcuracoes">
                                Buscar procurações no ECAC
                            </button>
                            <button nz-button nzType="primary" style="margin-right: 15px;"
                                    (click)="cadastrar(formProcuracoesECAC);"
                                    nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                    *ngIf="currentUser?.origem === 'console'"
                            >
                                Cadastrar
                            </button>

                            <nz-badge [nzCount]="qtdFiltrosAtivosProcuracoes"
                                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                                <button nz-button nzType="primary" style="margin-right: 10px;"
                                        (click)="modalFiltrarProcuracoes(true);">
                                    <i nz-icon nzType="search"></i>
                                    Filtros
                                </button>
                            </nz-badge>
                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                                <input (keydown.enter)="confirmaBuscarProcuracoes();" type="text" nz-input
                                       placeholder="Busca" [(ngModel)]="currentSearch.procuracoes">
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="primary" nzSearch
                                        (click)="confirmaBuscarProcuracoes();">
                                    <i nz-icon nzType="search"></i></button>
                            </ng-template>

                            <button nz-button nzType="default" style="margin-right: 10px;"
                                    (click)="btnResetSearchProcuracoes();">
                                Limpar
                            </button>

                            <button nz-button [nzType]="'default'" (click)="clickEventProcuracoes()"
                                    style="padding-left: 8px; padding-right: 8px;">
                                <i class="fas" [ngClass]="!fullCardProcuracoes ? 'fa-expand' : 'fa-compress'"></i>
                            </button>


                        </div>
                    </ng-template>
                    <nz-table #basicTable2
                              nzSize="small"
                              [nzData]="listaProcuracoes"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination.procuracoes?.total"
                              [nzPageSize]="pagination.procuracoes.per_page"
                              [nzPageIndex]="pagination.procuracoes.current_page"
                              (nzQueryParams)="listarProcuracoes($event)"
                              class="table-small"
                              [nzShowTotal]="rangeTemplateProcuracoes">
                        <thead>
                        <tr>
                            <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="left">Nome</th>
                            <th nzColumnKey="situacaoCadastral" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                                Situação
                            </th>
                            <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                            <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                            <th nzColumnKey="cadastrada" [nzSortFn]="true" nzAlign="center">Empresa cadastrada</th>
                            <th nzColumnKey="updated_at" [nzSortFn]="true" nzAlign="center">Atualizada</th>
                            <th *ngIf="currentUser?.origem === 'console'"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable2.data">
                            <td>
                                {{modoDemo(item.nome, 'empresaCodigoNome')}}<br>
                                <small>{{modoDemo((item.documento | cpfCnpj), 'cpf')}}</small>
                            </td>
                            <td nzAlign="center">{{item.situacao_descricao}}</td>
                            <td nzAlign="center">{{item.dataInicio | date:'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">{{item.dataFim | date:'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">{{item.cadastrada ? 'Sim' : 'Não'}}</td>
                            <td nzAlign="center">{{item.updated_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                            <td nzAlign="right" *ngIf="currentUser?.origem === 'console'">
                                <button nz-button nzType="default"
                                        (click)="alterarProcuracoesECAC(item);"
                                        style="margin-right: 10px;"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="currentUser?.origem === 'console'">
                                    Alterar
                                </button>
                                <button nz-button nzType="default" nz-tooltip nzTooltipTitle="Apenas usuário console"
                                        (click)="showConfirm('procuracoes', item);">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplateProcuracoes let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-card>
                <ng-template #templateToltipProcuradores>
                    <span nz-icon nzType="info-circle"
                          nzTheme="outline"
                          nzTooltipTitle="Empresas que podem me acessar"
                          nzTooltipPlacement="bottomLeft"
                          style="color: #E4A11B; padding-right: 10px;font-weight: bold;cursor: pointer"
                          nz-button nz-tooltip>
                    </span>
                    <span>Procuradores E-CAC</span>
                </ng-template>
                <nz-card id="st3" class="ant-card-table" [nzTitle]="templateToltipProcuradores" [nzExtra]="st3Extra"
                         [nzBodyStyle]="loading.procuradores ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.procuradores">
                    <ng-template #st3Extra>
                        <div style="display: flex;">

                            <button nz-button nzType="primary" (click)="cadastrar(formProcuradoresECAC);"
                                    style="margin-right: 15px;"
                                    nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                    *ngIf="currentUser?.origem === 'console'">
                                Cadastrar
                            </button>

                            <nz-badge [nzCount]="qtdFiltrosAtivosProcuradores"
                                      [nzStyle]="{background: '#52c41a', 'margin-right': '15px'}">
                                <button nz-button nzType="primary" style="margin-right: 10px;"
                                        (click)="modalFiltrarProcuradores(true);">
                                    <i nz-icon nzType="search"></i>
                                    Filtros
                                </button>
                            </nz-badge>

                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                                <input type="text" nz-input placeholder="Busca"
                                       [(ngModel)]="currentSearch.procuradores">
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="primary" nzSearch (click)="confirmaFiltrarProcuradores();">
                                    <i nz-icon nzType="search"></i>
                                </button>
                            </ng-template>

                            <button nz-button nzType="default" (click)="btnResetSearchProcuradores();">
                                Limpar
                            </button>
                        </div>
                    </ng-template>
                    <nz-table #basicTable3
                              nzSize="small"
                              [nzData]="listaProcuradores"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination.procuradores?.total"
                              [nzPageSize]="pagination.procuradores?.per_page"
                              [nzPageIndex]="pagination.procuradores?.current_page"
                              (nzQueryParams)="listarProcuradores($event)"
                              class="table-small"
                              [nzShowTotal]="rangeTemplateProcuracoes">
                        <thead>
                        <tr>
                            <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="left">Nome</th>
                            <th nzColumnKey="situacaoCadastral" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                                Situação
                            </th>
                            <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                            <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                            <th nzColumnKey="cadastrada" [nzSortFn]="true" nzAlign="center">Empresa cadastrada</th>
                            <th nzColumnKey="updated_at" [nzSortFn]="true" nzAlign="center">Atualizada</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable3.data">
                            <td>{{item.nome}}<br><small>{{ item.documento | cpfCnpj }}</small></td>
                            <td nzAlign="center">{{item.situacao_descricao}}</td>
                            <td nzAlign="center">{{item.dataInicio | date:'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">{{item.dataFim | date:'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">{{item.cadastrada ? 'Sim' : 'Não'}}</td>
                            <td nzAlign="center">{{item.updated_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarProcuradoresECAC(item);"
                                        style="margin-right: 10px;"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="currentUser?.origem === 'console'">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('procuradores', item);">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
                <nz-card id="st4" class="ant-card-table" nzTitle="Acessos Estaduais" [nzExtra]="st4Extra"
                         [nzBodyStyle]="loading.acessos ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.acessos">
                    <ng-template #st4Extra>
                        <button nz-button nzType="primary" (click)="cadastrar(formAcessosEstaduais);">
                            Cadastrar
                        </button>
                    </ng-template>
                    <nz-table #basicTable4
                              nzSize="small"
                              [nzData]="listaAcessos"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination.acessos?.total"
                              [nzPageSize]="pagination.acessos?.per_page"
                              [nzPageIndex]="pagination.acessos?.current_page"
                              (nzQueryParams)="listarAcessos($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="obrigacao_uf" [nzSortFn]="true" nzAlign="center">UF</th>
                            <th nzColumnKey="obrigacao_descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable4.data">
                            <td nzAlign="center">{{item.obrigacao_uf}}</td>
                            <td nzAlign="center">{{item.obrigacao_descricao}}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarAcessosEstaduais(item);"
                                        style="margin-right: 10px;">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('acessos', item);"
                                >
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
            </div>
        </div>
    </div>
</nz-content>
<nz-modal [(nzVisible)]="formCertificadoA1.modalVisible"
          [nzTitle]="'Alterar Certificado A1'" [nzClosable]="true"
          (nzOnCancel)="modal(formCertificadoA1, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCertificadoA1.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Emissor</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="emissor" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="categoria" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Situação</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="situacao" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Validade</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="validade" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formCertificadoA1, false);">Cancelar</button>
        <button nz-button nzType="primary">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formProcuracoesECAC.modalVisible"
          [nzTitle]="this.editando.procuracoes ? 'Alterar Procuração E-CAC' : 'Cadastrar Procuração E-CAC' "
          [nzClosable]="true"
          (nzOnCancel)="modal(formProcuracoesECAC, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formProcuracoesECAC.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="pessoa">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'J'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="documento" maxlength="20" size="60"
                           [mask]="maskPessoa(formProcuracoesECAC)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="nome" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formProcuracoesECAC, false);">Cancelar</button>
        <button nz-button nzType="primary"
                [nzLoading]="loading.procuracoes"
                (click)="insertProcuracoes();"
                *ngIf="!this.editando.procuracoes"
        >Confirmar
        </button>
        <button nz-button nzType="primary"
                (click)="updateProcuracoes();"
                [nzLoading]="loading.procuracoes"
                *ngIf="this.editando.procuracoes"
        >Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formProcuradoresECAC.modalVisible"
          [nzTitle]="this.editando.procuradores ? 'Alterar Procurador E-CAC' : 'Cadastrar Procurador E-CAC'"
          [nzClosable]="true"
          (nzOnCancel)="modal(formProcuradoresECAC, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formProcuradoresECAC.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="pessoa">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'J'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="documento" maxlength="20" size="60"
                           [mask]="maskPessoa(formProcuradoresECAC)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="nome" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="10">Fim</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formProcuradoresECAC, false);">Cancelar</button>
        <button nz-button nzType="primary"
                (click)="insertProcuradores();"
                [nzLoading]="loading.procuradores"
                *ngIf="!this.editando.procuradores"
        >Confirmar
        </button>
        <button nz-button nzType="primary"
                (click)="updateProcuradores();"
                [nzLoading]="loading.procuradores"
                *ngIf="this.editando.procuradores"
        >Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formAcessosEstaduais.modalVisible"
          [nzTitle]="editando.acessos ? 'Alterar Acesso Estadual' : 'Cadastrar Acesso Estadual'"
          [nzClosable]="true"
          (nzOnCancel)="modal(formAcessosEstaduais, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosEstaduais.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtrarObrigacoes(formAcessosEstaduais.formGroup.value.uf)">

                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Obrigação:</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="obrigacao_id"
                               (ngModelChange)="filtrarCampos(formAcessosEstaduais.formGroup.value.obrigacao_id);">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-content *ngIf="camposFormDataPrev.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrev">
                    <nz-form-label [nzSpan]="10" *ngIf="!loading.formAcessos">{{item.label}}:</nz-form-label>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length > 0 && !loading.formAcessos">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length === 0 && !loading.formAcessos">
                        <input nz-input type="text" formControlName="{{item.label}}"/>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formAcessosEstaduais, false);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!editando.acessos" (click)="insertAcessos();"
                [nzLoading]="loading.acessos">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="editando.acessos" (click)="updateAcessos();"
                [nzLoading]="loading.acessos">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formAdicionarCertificado.modalVisible"
          [nzTitle]="this.editando.certificados ? 'Detalhe Certificado Digital' : 'Adicionar Certificado Digital'"
          [nzClosable]="true"
          (nzOnCancel)="modal(formAdicionarCertificado, false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <div style="margin-top: 20px;">
            <form nz-form [nzNoColon]="true" [formGroup]="formAdicionarCertificado.formGroup">
                <nz-form-item *ngIf="!this.editando.certificados">
                    <nz-form-label [nzSpan]="4" nzRequired>Uso:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        Próprio
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item class="reduce-upload"
                              *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio' && !this.editando.certificados">
                    <nz-form-label [nzSpan]="4" [nzRequired]="!this.editando.certificados">Arquivo:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-upload
                                nzType="drag"
                                [nzMultiple]="true"
                                nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                                (nzChange)="handleChange($event)"
                                [(nzFileList)]="fileListCertificado" [nzBeforeUpload]="beforeUploadCertificado">
                            <p class="ant-upload-drag-icon">
                                <i nz-icon nzType="inbox"></i>
                            </p>
                            <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o
                                upload</p>
                        </nz-upload>
                        <p class="ant-upload-text" style="color: #3d3d3d; margin-bottom: 0; margin-top: 5px;">Somente
                            arquivos .pfx</p>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Uso:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>{{formAdicionarCertificado.formGroup.value.uso}}</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Arquivo:</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-form-text>{{editarCertificados.nomeArquivo}}</nz-form-text>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="2">
                        <nz-form-text>
                            <button *ngIf="this.currentUser?.origem === 'console'"
                                    nz-button nzType="default" (click)="modalSenha(true);">
                                <i nz-icon
                                   nzType="download"
                                   nzTheme="outline"></i>
                            </button>
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Senha:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>*********</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio' && !this.editando.certificados">
                    <nz-form-label [nzSpan]="4" [nzRequired]="!this.editando.certificados">Senha:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input nz-input type="password" formControlName="serial" maxlength="20" size="60">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Categoria:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                            <nz-option [nzLabel]="'eCNPJ'" [nzValue]="'cnpj'"></nz-option>
                            <nz-option [nzLabel]="'eCPF'" [nzValue]="'cpf'"></nz-option>
                        </nz-select>
                    </nz-form-control>

                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.formGroup.value.uso === 'Próprio'">
                    <nz-form-label [nzSpan]="4"></nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <label nz-checkbox formControlName="padrao">Certificado
                            Padrão?</label>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="dadosCadastrais.matriz">
                    <nz-form-label [nzSpan]="4"></nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <label nz-checkbox formControlName="replicar">Aplicar para
                            Filiais?</label>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formAdicionarCertificado, false);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!this.editando.certificados" [nzLoading]="loading.certificados"
                (click)="confirmAtualiarProcuracoes()">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="this.editando.certificados" [nzLoading]="loading.certificados"
                (click)="updateCertificados()">Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formSenha.modalVisible"
          [nzTitle]="'Definir senha do certificado'" [nzClosable]="true"
          (nzOnCancel)="modalSenha(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formSenha.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Senha</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="password" formControlName="senha" placeholder="informe a senha">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalSenha(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="downloadCertificado()" [nzLoading]="loading.senhaCertificado">
            Download
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrarProcuracoes.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarProcuracoes(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarProcuracoes.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Situação</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuracoes.formGroup.value.situacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Exibir somente expiradas/canceladas'" [nzValue]="'somente'"></nz-option>
                        <nz-option [nzLabel]="'Exibir também as expiradas/canceladas'" [nzValue]="'tambem'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuracoes.formGroup.value.cadastrada">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'sim'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'nao'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarProcuracoes(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarProcuracoes()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarProcuradores.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarProcuradores(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarProcuradores.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Expiradas / Canceladas</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuradores.formGroup.value.situacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Exibir somente expiradas/canceladas'" [nzValue]="'somente'"></nz-option>
                        <nz-option [nzLabel]="'Exibir também as expiradas/canceladas'" [nzValue]="'tambem'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuradores.formGroup.value.cadastrada">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'sim'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'nao'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarProcuradores(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarProcuradores()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="visibles.modalAlterar"
          [nzTitle]="'Alterar Dados Cadastrais'" [nzClosable]="true"
          (nzOnCancel)="onAlterarResponsavel(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAlterar"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formResponsavel.formGroup" style="width: 100%"
              *ngIf="!loadings.modalAlterar">
            <nz-form-item>
                <nz-form-label [nzSpan]="3">Nome</nz-form-label>
                <nz-form-control [nzSpan]="18" [nzErrorTip]="'Campo nome obrigatório'">
                    <input nz-input type="text" formControlName="nome" placeholder="Nome">
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onAlterarResponsavel(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaEditarResponsavel()"
                [nzLoading]="loadings.alterandoResponsavel">Confirmar
        </button>
    </div>
</nz-modal>

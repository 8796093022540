<div>
    <nz-table #basicTable
              [nzData]="logs"
              [nzFrontPagination]="false"
              [nzPageSize]="pagination.per_page"
              [nzPageIndex]="pagination.current_page"
              [nzTotal]="pagination.total"
              [nzLoading]="loading"
              [nzLoadingDelay]="150"
              nzSize="small"
              nzPaginationPosition="bottom"
              (nzPageIndexChange)="onPageChange($event)">
        <thead>
        <tr>
            <th>Tipo</th>
            <th>Usuário</th>
            <th>Ação</th>
            <th>Data</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of logs">
            <td>{{log?.tipo}}</td>
            <td>{{log?.user}}</td>
            <td>{{log?.acao}}</td>
            <td>{{log?.dataAcao | date: 'dd/MM/yyyy'}}</td>
        </tr>
        </tbody>
    </nz-table>
</div>

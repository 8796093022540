import { NgModule } from '@angular/core';
import { DatePipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {RegrasDesconsideracoesComponent} from './regras-desconsideracoes.component';
import {UiModule} from '../../ui.module';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzTransferModule} from 'ng-zorro-antd/transfer';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {CnpjMaskDirectveModule} from '../../../core/diretivas/cnpj-mask-directve.module';
import {NgxMaskModule} from 'ngx-mask';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {NzListModule} from "ng-zorro-antd/list";



@NgModule({
  declarations: [
    RegrasDesconsideracoesComponent
  ],
    imports: [
        CnpjMaskDirectveModule,
        UiModule,
        NzPageHeaderModule,
        NgClass,
        DatePipe,
        FormsModule,
        NgForOf,
        NgIf,
        NzCheckboxModule,
        NzTransferModule,
        NzTypographyModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NzBadgeModule,
        NgxMaskModule,
        ScrollingModule,
        NzListModule
    ]
})
export class XmlXSpedModule { }

<nz-page-header class="site-page-header" nzTitle="Drive" nzSubtitle="Upload de Arquivos">
</nz-page-header>
<nz-card>
    <nz-upload
            nzType="drag"
            [nzMultiple]="true"
            nzAction="{{apiUrl}}/arquivos-transitorios/upload"
            (nzChange)="handleChange($event)"
    >
        <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Clique ou arraste o(s) arquivo(s) para esta área para fazer o upload</p>
    </nz-upload>
</nz-card>

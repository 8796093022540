import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';

@Injectable({providedIn: 'root'})
export class EditarCredenciaisAcessoService extends AbstractService<InscricaoRegime> {

    urlCertificados = environment.apiUrl + '/cadastros/credenciais-acesso/certificados';
    urlProcuracoes = environment.apiUrl + '/cadastros/credenciais-acesso/procuracoes';
    urlProcuradores = environment.apiUrl + '/cadastros/credenciais-acesso/procuradores';
    urlAcessos = environment.apiUrl + '/cadastros/credenciais-acesso/acessos-estaduais';
    urlAcessosMunicipais = environment.apiUrl + '/cadastros/credenciais-acesso/acessos-municipais';
    urlAcessosEcac = environment.apiUrl + '/integracoes/integra-contador/tabela';
    urlAcessosPrevidenciarios = environment.apiUrl + '/cadastros/credenciais-acesso/acessos-previdenciarios';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaDadosCadastrais(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/empresa/detalhe/${empresaId}`);

    }

    retornaUltimaConsultaProcuracoes(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/ultimaConsultaProcuracoes/${empresaId}`);

    }

    retornaComboUFs(orgao: 'estadual' | 'municipal' = 'estadual'): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/ufsCredencialPortal/select?orgao=${orgao}`);

    }

    retornaPortaisUF(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/portais/estaduais/select/${uf}`);

    }

    retornaPortaisPrevidenciarios(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/portais/previdenciarios/select/-`);

    }

    retornaMunicipios(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/portais/municipais/select/${uf}`);

    }

    retornaCamposAcessosEstaduais(obrigacao: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/portais/dados-acesso/${obrigacao}`);

    }

    retornaCamposAcessosMunicipais(municipio: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/portais/dados-acesso-municipal/${municipio}`);

    }

    retornaCamposAcessosPrevidenciarios(portal: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/portais/dados-acesso-previdenciario/${portal}`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaDadosDataPrev(empresaId: any): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-dataprev/detalhe/${empresaId}`);
    }


    insertProcuracoes(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/procuracao`, dados);

    }


    insertProcuradores(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/procurador`, dados);

    }

    insertAcessos(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual`, dados);

    }

    insertAcessosMunicipais(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-municipal`, dados);

    }

    insertAcessosPrevidenciario(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-previdenciario`, dados);

    }


    updateProcuracoes(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/procuracao/detalhe/${id}`, dados);

    }

    updateProcuradores(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/procurador/detalhe/${id}`, dados);

    }

    updateAcessos(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/detalhe/${id}`, dados);

    }

    updateAcessosMunicipais(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-municipal/detalhe/${id}`, dados);

    }

    updateAcessosPrevidenciario(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-previdenciario/detalhe/${id}`, dados);

    }

    updateDataPrev(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-dataprev`, dados);

    }

    removeProcuracoes(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/procuracao/${id}`);

    }

    removeProcuradores(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/procurador/${id}`);

    }

    removeCertificados(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/${id}`);

    }

    removeAcessos(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/${id}`);

    }

    removeAcessoMunicipal(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-municipal/${id}`);

    }

    removeAcessosDataPrev(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-data-prev/${id}`);

    }

    exportExcel(certificadoId: any, senha: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/download/${certificadoId}?senha=${senha}`,
            {responseType: 'blob'});

    }

    buscarProcuracoes(id: any): Observable<any> {

        const dados: any = {
            empresa_id: id,
            robo: 'ecac',
            tipo: 'procuracao'
        };
        return this.http.post(`${environment.apiUrl}/robo/rpa/solicitacao`, dados);

    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);

    }

    ativarInativarAcessoIntegradoECAC(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/integracoes/integra-contador/gravar`, data);

    }

    retornarResponsaveis(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/responsaveis`);
    }


}

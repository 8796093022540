import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class AnalisesXMLSaidasService extends AbstractService<any> {

    url = environment.apiUrl + '/check/efdContrib/analises';

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select/cnpj/matriz`);
    }

    retornaDados(filtros: any): Observable<any> {

        if (filtros.periodoInicio) {
            filtros.periodoInicio = Helpers.formataDateBD(filtros.periodoInicio);
        }

        if (filtros.periodoFim) {
            filtros.periodoFim = Helpers.formataDateBD(filtros.periodoFim);
        }

        const queryStr = Helpers.montaQueryString(filtros, ['cnpj']);

        return this.http.get(`${environment.apiUrl}/notas/analise-nota-xml-saida/analises${queryStr}`);

    }

    solicitarAnalise(dados: any): Observable<any> {

        if (dados.periodoInicio) {
            dados.periodoInicio = Helpers.formataDateBD(dados.periodoInicio);
        }

        if (dados.periodoFim) {
            dados.periodoFim = Helpers.formataDateBD(dados.periodoFim);
        }

        return this.http.post(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise`, dados);

    }

    reprocessar(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/analise-nota-xml-saida/analises/reprocessar`, {ids});
    }

    remover(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/analise-nota-xml-saida/analises/remover`, {ids});
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    formataCompetencia(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }
}

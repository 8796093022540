import {inject, Injectable} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Resolve} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DashboardPublicPdfService implements Resolve<any> {
    http = inject(HttpClient);
    identificador = window.location.href.match(/(?<=:\/\/)\w+/i)[0];
    queryStr = window.location.search + `&identificador=${this.identificador}`;

    resolve(): any {
        return forkJoin([
            this.graficoPorEmpresa(),
            this.graficoPorAno(),
            this.graficoTopFiliais(),
            this.graficoPorGrupoEmpresarial()
        ]);
    }

    graficoPorEmpresa(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/publico/compliance/notas/dashboard/nao-escituradas/empresa${this.queryStr}`);
    }

    graficoPorAno(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/publico/compliance/notas/dashboard/nao-escituradas/ano${this.queryStr}`);
    }

    graficoTopFiliais(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/publico/compliance/notas/dashboard/nao-escituradas/top-filiais${this.queryStr}`);
    }

    graficoPorGrupoEmpresarial(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/publico/compliance/notas/dashboard/nao-escituradas/grupo-empresarial${this.queryStr}`);
    }

}

import { Utils } from '../utils';

export class Menu {
    id: string;
    descricao: string;
    descricaoEstendida: string;
    parentId: string;
    tipo: string;
    icone: string;
    url: string;
    urlTipo: string;
    ordem: string;
    child_id: string;
    parent_id: string;
    childId: string;
    extra: string;

    constructor(obj?: any) {
        /* if (obj) {
             for (const prop of Object.keys(obj)) {
                 this[Utils.camelCase(prop)] = obj[prop];
             }
         }*/
        if (obj) {

            const {
                id,
                descricao,
                descricaoEstendida,
                parentId,
                tipo,
                icone,
                url,
                urlTipo,
                ordem,
                parent_id,
                child_id,
                childId,
                extra,
            } = obj;
            this.id = id;
            this.descricao = descricao;
            this.descricaoEstendida = descricaoEstendida;
            this.tipo = tipo;
            this.icone = icone;
            this.url = url;
            this.urlTipo = urlTipo;
            this.ordem = ordem;
            this.child_id = child_id;
            this.parentId = parentId;
            this.parent_id = parent_id;
            this.childId = childId;
            this.extra = extra;
        }

    }

    convertToSave() {
        const properties = ['id', 'descricao', 'tipo', 'icone', 'url', 'urlTipo', 'ordem', 'extra', 'descricaoEstendida'];
        const menu = new Menu();
        for (const prop of properties) {
            menu[prop] = this[prop];
        }

        menu.parent_id = this.parentId;

        if (Utils.isEmpty(menu.id)) {
            delete menu.id;
        }
        return menu;
    }
}

import {Component, OnInit} from '@angular/core';
import {MonitorProcessamentosService} from './monitorProcessamentos.service';

@Component({
    selector: 'app-conta-MonitorProcessamentos',
    templateUrl: './monitorProcessamentos.component.html',
    styleUrls: ['./monitorProcessamentos.component.scss']
})

export class MonitorProcessamentosComponent implements OnInit {

    anoSelecionado = null;
    anos = [];
    colunasMeses = [];
    arrayProcessamentos = [];

    constructor(private service: MonitorProcessamentosService) {

        const anoAtual = new Date().getFullYear();
        let ano = anoAtual;

        while (ano > anoAtual - 5) {
            this.anos.push(ano);
            ano--;
        }

        this.anoSelecionado = anoAtual;

    }

    ngOnInit(): void {

        this.selecionaAno(this.anoSelecionado);

    }

    atualizaColunasMeses(ano): void {

        const anoAtual = new Date().getFullYear();
        const mesAtual = new Date().getMonth() + 1;
        let mes = 1;
        const ultimoMes = ano === anoAtual ? mesAtual : 12;
        this.colunasMeses = [];

        while (mes <= ultimoMes) {

            const data = new Date(ano, mes - 1, 1);
            const mesDescricao = data.toLocaleString('pt-br', {month: 'short'});

            this.colunasMeses.push(mesDescricao.toUpperCase().replace('.', ''));
            mes++;
        }

    }

    selecionaAno(ano: any) {

        this.anoSelecionado = ano;
        this.atualizaColunasMeses(ano);

        this.service.retornaProcessamentos(this.anoSelecionado).subscribe((response: any) => {

            this.arrayProcessamentos = [];

            if (response.itens.length > 0) {

                for (const item of response.itens) {

                    this.arrayProcessamentos.push({label: item.label});

                    if (item.itens) {
                        for (const subItem of item.itens) {

                            if (subItem.key === 'ultimoProcessamento') {


                            }

                            this.arrayProcessamentos.push({label: subItem.label, itens: subItem.itens});
                        }
                    }

                }

            }

        });

    }

}

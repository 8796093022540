import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class ComplianceCalendarService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/tributos', {});
    }


    retornaAnos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-anos`);
    }

    retornaObrigacoesPagamentos(ano: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-pagamentos-principais/` + ano);
    }
    retornaObrigacoesAcessorias(ano: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-obrigacoes-acessorias/` + ano);
    }
    retornaObrigacoesGrupo(ano: number, imposto: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-obrigacoes-grupo/` + ano + '/' + imposto);
    }


}

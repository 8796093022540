import {Action} from '@ngrx/store';
import {Tab} from '@models/tab.model';

export enum ActionsTab {
    addTab = 'ADD',
    removeTab = 'REM',
    clear = 'CLE',
    clearOthers = 'CLEOTH',
    reload = 'RELOAD',
    reorder = 'REORDER'
}

export const Add = (payload: Tab) => {
    return {type: ActionsTab.addTab, payload} as Action;
};

export const Remove = (index: number) => {
    return {type: ActionsTab.removeTab, index} as Action;
};

export const Clear = () => {
    return {type: ActionsTab.clear} as Action;
};

export const ClearOthers = (index: number) => {
    return {type: ActionsTab.clearOthers, index} as Action;
};

export const Reload = (payload: Tab) => {
    return {type: ActionsTab.reload, payload} as Action;
};

export const Reorder = (payload: Tab[]) => {
    return {type: ActionsTab.reorder, payload} as Action;
};

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';


@Injectable({providedIn: 'root'})
export class MonitorProcessamentosService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/tributos', {});
    }


    retornaProcessamentos(ano: string) {
        return this.http.get(`${environment.apiUrl}/monitoramento/processamentos/${ano}`);
    }

}

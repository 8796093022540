import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BaixasAutomaticasDetalhesComponentService} from './baixas-automaticas-detalhes.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import moment from 'moment';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-dashboard-decCnd',
    templateUrl: './baixas-automaticas-detalhes.component.html',
    styleUrls: ['./baixas-automaticas-detalhes.component.scss']
})
export class BaixasAutomaticasDetalhesComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, TabHandlerInterface {

    @Input() data: any;

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    statusOne = false;

    paginations: any = {
        baixas: Pagination
    };

    itens: any = [];
    loading = true;

    formFiltros: FormStack;
    qtdFiltros = 0;

    currentParams: NzTableQueryParams;

    comboEmpresa = [];
    comboCategoria = [];
    comboObrigacoes = [];

    modalResultadoVisible = false;
    reultadoDaBaixa = null;

    modalJsonFlag = false;
    textAreaValue = '';

    formatOne = (percent: number) => `${percent}%`;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private modalService: NzModalService,
        private service: BaixasAutomaticasDetalhesComponentService,
        private i18n: NzI18nService) {

        super(service, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                periodo: [null, null],
                unidade: [null, null],
                categoria_id: [null, null],
                obrigacao_id: [null, null],
                status: [null, null]
            })
        };
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    ngOnInit() {

        this.service.retornaComboEmpresas().subscribe((retorno: any) => {
            this.comboEmpresa = retorno.empresaCnpj;
        });

        this.retornaObrigacoes();

        this.service.retornaComboCategorias().subscribe((retorno: any) => {
            this.comboCategoria = retorno;
        });

        if (this.data.obrigacao_id) {
            this.formFiltros.formGroup.get('obrigacao_id').setValue(this.data.obrigacao_id);
        }

        if (this.data.unidade) {
            this.formFiltros.formGroup.get('unidade').setValue(this.data.unidade);
        }

        if (this.data.periodo) {
            this.formFiltros.formGroup.get('periodo').setValue(this.data.periodo);
        } else {
            this.formFiltros.formGroup.get('periodo').setValue('30');
        }

        this.table();

    }

    ngAfterViewInit() {
        this.resizeModal();
    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

    }

    retornaObrigacoes(categoria = null) {
        this.formFiltros.formGroup.get('obrigacao_id').setValue(null);

        this.service.retornaComboObrigacoes(categoria).subscribe((retorno: any) => {
            this.comboObrigacoes = retorno.obrigacoes;
        });
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltros = 0;

        if (typeof this.formFiltros !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {
                if (valor && chave !== 'pagina' && valor && chave !== 'quantidade' && chave !== 'procurar'
                    && chave !== 'ordenar' && chave !== 'sentido') {

                    this.qtdFiltros++;
                }

            }

        }

    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.baixas.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.baixas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.baixas.current_page) {
            this.table(params);
            this.paginations.baixas.current_page = params.pageIndex;
        }

    }

    table(params?: any, search?: any, filtros = null): void {

        this.currentParams = params || {filter: []};

        if (filtros) {
            this.currentParams.filter = filtros;
        } else {
            this.currentParams.filter = this.formFiltros.formGroup.value;
        }

        this.loading = true;

        this.calculaBadgeFiltros();

        this.service.table(this.currentParams, search).subscribe((response: any) => {

            this.itens = [];
            this.itens = response.data;

            this.itens.forEach(d => {

                if (d.roboBaixa_status != null){
                    d.roboBaixa_status = d.roboBaixa_status.toString();
                }

                if (d.roboBaixa_status === '0') {
                    d.roboBaixa_status_descricao = 'Na Fila';
                    d.roboBaixa_status_class = 'semCor';
                } else if (d.roboBaixa_status === '1') {
                    d.roboBaixa_status_descricao = 'Processando';
                    d.roboBaixa_status_class = 'semCor';
                } else if (d.roboBaixa_status === '3') {
                    d.roboBaixa_status_descricao = 'Erro ao processar';
                    d.roboBaixa_status_class = 'error';
                } else if (d.roboBaixa_status === '2') {
                    d.roboBaixa_status_descricao = `Processado`;
                    d.roboBaixa_status_class = 'success';
                } else if (d.roboBaixa_status === '6') {
                    d.roboBaixa_status_descricao = 'Armazenando';
                    d.roboBaixa_status_class = 'default';
                } else if (d.roboBaixa_status === '5') {
                    d.roboBaixa_status_descricao = 'Job solicitado';
                    d.roboBaixa_status_class = 'info';
                } else if (d.roboBaixa_status === '7') {
                    d.roboBaixa_status_descricao = 'Processado sem registros';
                    d.roboBaixa_status_class = 'warning';
                }

            });

            // sequência do job -> 0 - 5 - 1 - 6 - 2 3 ou 7

            this.paginations.baixas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

    }

    modalFiltrar(visible: boolean) {
        this.formFiltros.modalVisible = visible;
    }

    modalResultado(visible: boolean, resultado = null) {
        this.modalResultadoVisible = visible;
        this.reultadoDaBaixa = resultado;
    }

    confirmaFiltrar() {

        if (this.formFiltros.formGroup.valid) {
            const filtros = {};

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                const chaverParaValidarData = ['dataAtualizacaoInicio', 'dataAtualizacaoFim', 'dataExtracaoInicio', 'dataExtracaoFim'];

                if (valor) {
                    if (chaverParaValidarData.includes(chave)) {
                        filtros[chave] = moment(valor).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        filtros[chave] = valor.toString();
                    }
                }
            }

            this.currentParams.pageIndex = 1;

            this.table(this.currentParams, this.currentSearch, filtros);
            this.modalFiltrar(false);

        } else {
            Object.values(this.formFiltros.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    limpar() {
        this.currentParams.filter = [];
        this.currentSearch = '';
        this.formFiltros.formGroup.reset();
        this.currentParams.pageIndex = 1;
        this.table(this.currentParams, this.currentSearch);
    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

        }
    }

    modalJson(visible, conteudo) {
        this.textAreaValue = conteudo;
        this.modalJsonFlag = visible;
    }

}

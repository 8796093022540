<nz-page-header class="site-page-header" nzTitle="Downloads" nzSubtitle="Central de Downloads">
    <nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45rem; margin-right: 10px;"
                    (click)="atualizarTela();">
                <i class="fas fa-redo" style="margin-right: 5px;"></i>
                Atualizar
            </button>
            <nz-badge [nzCount]="qtdFiltrosAtivos"
                      [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="abrirModal(formFiltros)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style=" margin: 15px !important;">
        <ng-template #checkedMessage>
            <div nz-row nzAlign="middle" nzGutter="24">
                <div nz-col nzSpan="24">
                    <strong class="text-primary"></strong>
                    Atenção: Os arquivos ficam disponíveis para download por até 90 dias. Após esse período eles serão
                    excluídos automaticamente.
                </div>
            </div>
        </ng-template>
    </nz-alert>
    <nz-table #tableDownloads
              [nzData]="items"
              [nzFrontPagination]="false"
              [nzTotal]="pagination?.total"
              [nzPageSize]="pagination?.per_page"
              [nzPageIndex]="pagination?.current_page"
              (nzQueryParams)="queryTable($event)"
              [nzShowTotal]="rangeTemplate"
              class="table-small card-table">
        <thead></thead>
        <tbody>
        <tr *ngFor="let download of tableDownloads.data">
            <td>
                <nz-card class="fonte-doze" [nzLoading]="loading">
                    <nz-row>
                        <nz-col nzSpan="3"
                                class="d-flex justify-content-center align-items-center"
                                style="padding-right: 24px; border-right: 1px rgba(0,0,0,0.3) solid;">
                            <i *ngIf="download?.parametros?.tipoExportador === 'xlsx'"
                               class="far fa-file-excel download-icons" style="color: #32a852"></i>
                            <i *ngIf="download?.parametros?.tipoExportador === 'csv'"
                               class="fa fa-file-csv download-icons" style="color: #32a852"></i>
                            <i *ngIf="download?.parametros?.tipoExportador === 'pdf'"
                               class="far fa-file-pdf download-icons" style="color: #eb1a2b;"></i>
                            <i *ngIf="download?.parametros?.tipoExportador === 'xml'"
                               class="far fa-file-code download-icons" style="color: rgba(0,0,0,0.6);"></i>
                            <i *ngIf="download?.parametros?.tipoExportador === 'txt'"
                               class="far fa-file-alt download-icons" style="color: #1890ff;"></i>
                        </nz-col>
                        <nz-col nzSpan="18" style="padding-left: 48px;">
                            <div class="d-flex align-items-end">
                                <h3 nz-typography style="margin-right: 10px;">{{ descricoesTelas[download.tipo] }}</h3>
                                <p nz-typography style="color: rgba(0,0,0,.45);">
                                    Exportação de arquivo(s) em
                                    <strong>{{ download?.parametros?.tipoExportador }}</strong>
                                </p>
                            </div>
                            <p nz-typography class="d-flex" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i *ngIf="download?.status === 0 || download?.status === 1 || download?.status === 4"
                                       class="fas fa-info-circle" style="color: blue;"></i>
                                    <i *ngIf="download?.status === 2" class="fas fa-check-circle"
                                       style="color: green;"></i>
                                    <i *ngIf="download?.status === 3" class="fas fa-times-circle"
                                       style="color: red;"></i>
                                </span>
                                <strong *ngIf="download?.status === 0">Na fila</strong>
                                <strong *ngIf="download?.status === 1">Em processamento</strong>
                                <strong *ngIf="download?.status === 2">Processado com sucesso</strong>
                                <strong *ngIf="download?.status === 3">Erro ao
                                    processar</strong>
                                <span *ngIf="usuario.origem === 'console'"
                                      style="display:block; width: 25px; text-align: center; color: #1890ff;">
                                <i class="fa-solid fa-circle-info" *ngIf="download?.status === 3" nz-tooltip="true"
                                   nzTooltipTitle="{{download?.erroMensagem}}"></i>
                                </span>
                                <strong *ngIf="download?.status === 4">Sem arquivos</strong>
                            </p>
                            <p nz-typography class="d-flex" *ngIf="download?.cnpj" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i nz-icon nzType="bank" nzTheme="outline"></i>
                                </span>
                                <strong>CNPJ: </strong>&nbsp;{{ download?.cnpj | cpfCnpj }}&nbsp;
                                <strong>Competência: </strong> &nbsp;{{ download?.competencia }}
                            </p>
                            <p nz-typography
                               *ngIf="download.status === 2 || (download.status === 1 && download?.quantidade)"
                               class="d-flex" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="far fa-file-archive"></i>
                                </span>
                                <span>{{ download?.quantidade }} arquivos - {{ download?.tamanho }}</span>
                            </p>
                            <p nz-typography class="d-flex" *ngIf="download?.status === 0" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                                <strong>Solicitado:</strong>&nbsp;{{ download?.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}
                            </p>
                            <p nz-typography class="d-flex" *ngIf="download?.status !== 0" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                                <strong>Solicitado:</strong>&nbsp;{{ download?.processamentoInicio | date: 'dd/MM/yyyy HH:mm:ss' }}
                                <span *ngIf="download?.status === 2">                                   &nbsp;
                                    - <strong>Finalizado:</strong>&nbsp;{{ download?.processamentoFim | date: 'dd/MM/yyyy HH:mm:ss' }}
                                </span>
                            </p>
                            <p nz-typography *ngIf="download?.status === 2" class="d-flex" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="fas fa-hourglass-half"></i>
                                </span>
                                Tempo de processamento
                                &nbsp;
                                <strong>{{ download?.tempoProcessamento || '1s' }}</strong>
                            </p>
                            <p nz-typography *ngIf="download?.status === 1" class="d-flex" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="fas fa-hourglass-half"></i>
                                </span>
                                Em processamento há
                                &nbsp;
                                <strong>{{ download?.tempoProcessamento }}</strong>
                            </p>
                            <p nz-typography class="d-flex" style="margin-bottom: 0;">
                                <span style="display:block; width: 25px; text-align: center;">
                                    <i class="far fa-user"></i>
                                </span>
                                Por
                                &nbsp;
                                <strong>{{ download?.nome }}</strong>
                            </p>
                        </nz-col>
                        <nz-col nzSpan="3"
                                class="d-flex justify-content-center align-items-center"
                                style="padding-left: 24px; border-left: 1px rgba(0,0,0,0.3) solid;">
                            <button nz-button nzType="primary" nzSize="large" nzShape="circle"
                                    [nzLoading]="loadingsDownloads[download.id]"
                                    *ngIf="download?.status === 2" style="margin-right: 10px;"
                                    (click)="downloadArquivo(download.id, download?.tipo, download?.parametros?.tipoExportador, download?.processamentoFim)">
                                <i nz-icon nzType="download"></i>
                            </button>
                            <button nz-button nzType="primary" nzDanger nzSize="large" nzShape="circle"
                                    (click)="showConfirmRemoverArquivo(download.id)">
                                <i nz-icon nzType="close" nzTheme="outline"></i>
                            </button>
                        </nz-col>
                    </nz-row>
                </nz-card>
            </td>
        </tr>
        </tbody>
    </nz-table>
    <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
    </ng-template>
</nz-content>
<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltros)"
          [nzWidth]="648">
    <nz-row *nzModalContent>

        <form nz-form [formGroup]="formFiltros.formGroup" style="width: 100%;">

            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Tipo</nz-form-label>
                <nz-form-item nz-col [nzSpan]="17">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.tipo">
                        <nz-select nzShowSearch style="width: 100%;"
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="tipo"
                                   [(ngModel)]="formFiltros.formGroup.value.tipo">
                            <nz-option *ngFor="let option of selectDescricoesTelas"
                                       [nzLabel]="option.label"
                                       [nzValue]="option.value"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Emissão</nz-form-label>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.dataEmissaoDe">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataEmissaoDe" style="width: 100%;"
                                        [(ngModel)]="formFiltros.formGroup.value.dataEmissaoDe"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.dataEmissaoAte">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataEmissaoAte" style="width: 100%;"
                                        [(ngModel)]="formFiltros.formGroup.value.dataEmissaoAte"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Status</nz-form-label>
                <nz-form-item nz-col [nzSpan]="17">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.status">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="status"
                                   [(ngModel)]="formFiltros.formGroup.value.status">
                            <nz-option [nzLabel]="'Na fila'" [nzValue]="'0'"></nz-option>
                            <nz-option [nzLabel]="'Em processamento'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'Processado com sucesso'" [nzValue]="'2'"></nz-option>
                            <nz-option [nzLabel]="'Sem arquivos'" [nzValue]="'4'"></nz-option>
                            <nz-option [nzLabel]="'Erro ao processar'" [nzValue]="'3'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Status</nz-form-label>
                <nz-form-item nz-col [nzSpan]="17">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.usuario_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="usuario_id">
                            <nz-option  *ngFor="let opt of optionsUsuarios" [nzLabel]="opt.nome" [nzValue]="opt.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>


        </form>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltros)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-page-header class="site-page-header" nzTitle="Configurações" nzSubtitle="Configurações de baixas e automações">
</nz-page-header>

<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Empresas" [nzLoading]="loading"
             [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="checkedItems?.size > 0 ? modalAlterar(true) : ''"
                                    [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar Em Massa
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch)" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzShowTotal]="rangeTemplateUsuarios"
                  style="overflow-x: auto"
                  class="table-small">
            <thead>
            <tr>
                <th style="width: 50px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    id="check-all">
                </th>
                <th nzColumnKey="empres_codigo" [nzSortFn]="true">Código</th>
                <th nzColumnKey="empresa_nome" [nzSortFn]="true">Empresa</th>
                <th nzColumnKey="empresa_cnpj" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true">Grupo</th>
                <th nzAlign="center" nzColumnKey="empresa_uf" [nzSortFn]="true">UF</th>
                <th nzAlign="center" nzColumnKey="compliance" [nzSortFn]="true">Compliance</th>
                <th nzAlign="center" nzColumnKey="baixa_obrigacao" [nzSortFn]="true">Baixa de Obrigações</th>
                <th nzAlign="center" nzColumnKey="cnd" [nzSortFn]="true">CND</th>
                <th nzAlign="center" nzColumnKey="dec" [nzSortFn]="true">DEC</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let empresa of basicTable.data">
                <td (nzCheckedChange)="onItemChecked(empresa.empresa_id, $event)"
                    [nzChecked]="checkedItems.has(empresa.empresa_id)"
                    [nzDisabled]="empresa.disabled">
                </td>
                <td>{{ empresa?.empres_codigo || '-' }}</td>
                <td>
                    <span style="cursor: pointer; color: #1e9ff2"
                          (click)="openTab('/administracao/empresa/detalhe', empresa.empresa_id, {id: empresa.empresa_id, cnpj: empresa.cnpj})">
                        {{ empresa?.empresa_nome }}
                    </span>
                </td>
                <td nzLeft style="white-space: nowrap;">{{ empresa?.empresa_cnpj | cpfCnpj }}</td>
                <td>{{ empresa?.grupoEmpresarial_descricao }}</td>
                <td nzAlign="center">{{ empresa?.empresa_uf }}</td>
                <td nzAlign="center">
                    <nz-switch [ngModel]="empresa?.compliance" nzCheckedChildren="Habil." nzUnCheckedChildren="Desab."
                               [nzLoading]="alterando.compliance[empresa.empresa_id]"
                               (click)="alterarCompliance(empresa.empresa_id, {'compliance' : empresa.compliance == 1 ? '0' : '1'}, 'compliance')"></nz-switch>
                </td>
                <td nzAlign="center">
                    <span class="bt-link" [nz-tooltip]="'Alterar'" (click)="alterarBaixaObrigacao(empresa)">
                        {{ empresa?.baixa_obrigacao == 1 ? 'Atualização automática' : 'Via Solicitação' }}
                    </span>
                </td>
                <td nzAlign="center">
                    <nz-switch [ngModel]="empresa?.cnd" nzCheckedChildren="Habil." nzUnCheckedChildren="Desab."
                               [nzLoading]="alterando.cnd[empresa.empresa_id]"
                               (click)="alterarCompliance(empresa.empresa_id, {'cnd' : empresa.cnd == 1 ? '0' : '1'}, 'cnd')"></nz-switch>
                </td>
                <td nzAlign="center">
                    <nz-switch [ngModel]="empresa?.dec" nzCheckedChildren="Habil." nzUnCheckedChildren="Desab."
                               [nzLoading]="alterando.dec[empresa.empresa_id]"
                               (click)="alterarCompliance(empresa.empresa_id, {'dec' : empresa.dec == 1 ? '0' : '1'}, 'dec')"></nz-switch>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateUsuarios let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Compliance</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.compliance">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="compliance">
                        <nz-option [nzLabel]="'Habilitado'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desabilitado'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Baixa de Obrigacao</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.baixa_obrigacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="baixa_obrigacao">
                        <nz-option [nzLabel]="'Atualização automatica'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Via Solicitação'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="13" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrar.formGroup.value.empresa_id"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj') + ')'"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Grupo</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupo_empresarial_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="grupo_empresarial_id">
                        <nz-option *ngFor="let opt of arraySelectGrupos"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAlterar.modalVisible" [nzTitle]="'Alterar'" [nzClosable]="true"
          (nzOnCancel)="modalAlterar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formAlterar.formGroup"
              style="width: 100%;">
            <nz-form-item *ngIf="empresaSelecionada === null">
                <nz-form-label [nzSpan]="6">Compliance</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formAlterar.formGroup.value.compliance">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="compliance">
                        <nz-option [nzLabel]="'Habilitado'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desabilitado'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Baixa de Obrigacao</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formAlterar.formGroup.value.baixa_obrigacao">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="baixa_obrigacao">
                        <nz-option [nzLabel]="'Atualização automatica'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Via Solicitação'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="empresaSelecionada === null">
                <nz-form-label [nzSpan]="6">DEC</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formAlterar.formGroup.value.dec">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="dec">
                        <nz-option [nzLabel]="'Habilitado'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desabilitado'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="empresaSelecionada === null">
                <nz-form-label [nzSpan]="6">CND</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formAlterar.formGroup.value.cnd">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="cnd">
                        <nz-option [nzLabel]="'Habilitado'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desabilitado'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="alterandoEmMassa" (click)="alterarEmMassa()">Confirmar</button>
    </div>
</nz-modal>

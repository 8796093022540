<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Regras PIS/COFINS">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze" [nzExtra]="navBar" nzTitle="Regras PIS/COFINS"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true, true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="modalImportar(true);">Importar Planilha</li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                        <li nz-menu-item nz-button (click)="modalImportarMovimento(true);">
                            Importar via movimentação
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="buscar()"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="ncm" [nzSortFn]="true">NCM</th>
                <th nzAlign="center" nzColumnKey="cstPisEntrada" [nzSortFn]="true">CST PIS Entrada</th>
                <th nzAlign="center" nzColumnKey="aliqPisEntrada" [nzSortFn]="true">ALÍQ. PIS Entrada</th>
                <th nzAlign="center" nzColumnKey="cstCofinsEntrada" [nzSortFn]="true">CST COFINS Entrada</th>
                <th nzAlign="center" nzColumnKey="aliqCofinsEntrada" [nzSortFn]="true">ALÍQ. COFINS Entrada</th>
                <th nzAlign="center" nzColumnKey="cstPisSaida" [nzSortFn]="true">CST PIS Saída</th>
                <th nzAlign="center" nzColumnKey="aliqPisSaida" [nzSortFn]="true">ALÍQ. PIS Saída</th>
                <th nzAlign="center" nzColumnKey="cstCofinsSaida" [nzSortFn]="true">CST COFINS Saída</th>
                <th nzAlign="center" nzColumnKey="aliqCofinsSaida" [nzSortFn]="true">ALÍQ. COFINS Saída</th>
                <th nzAlign="center" nzColumnKey="vigenciaInicio" [nzSortFn]="true">Vigência Início</th>
                <th nzAlign="center" nzColumnKey="vigenciaFim" [nzSortFn]="true">Vigência Fim</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Data Cadastro</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">

                <td nzLeft (nzCheckedChange)="onItemChecked(item.id, $event)"
                    [nzChecked]="checkedItems.has(item.id)"
                    [nzDisabled]="item.disabled">
                </td>
                <td nzLeft>{{item.ncm | mask:'0000.00.00'}}</td>
                <td nzAlign="center">{{item.cstPisEntrada | number:'2.0'}}</td>
                <td nzAlign="center">{{item.aliqPisEntrada > 0 ? (item.aliqPisEntrada | number:'1.2') : '0,00'}}%</td>
                <td nzAlign="center">{{item.cstCofinsEntrada | number:'2.0'}}</td>
                <td nzAlign="center">{{item.aliqCofinsEntrada > 0 ? (item.aliqCofinsEntrada | number:'1.2') : '0,00'}}
                    %
                </td>
                <td nzAlign="center">{{item.cstPisSaida | number:'2.0'}}</td>
                <td nzAlign="center">{{item.aliqPisSaida > 0 ? (item.aliqPisSaida | number:'1.2') : '0,00'}}%</td>
                <td nzAlign="center">{{item.cstCofinsSaida | number:'2.0'}}</td>
                <td nzAlign="center">{{item.aliqCofinsSaida > 0 ? (item.aliqCofinsSaida | number:'1.2') : '0,00'}}%</td>

                <td nzAlign="center">{{item.vigenciaInicio | date:'dd/MM/yyyy'}}</td>
                <td nzAlign="center">{{item.vigenciaFim | date:'dd/MM/yyyy'}}</td>
                <td nzAlign="center">{{item.created_at | date:'dd/MM/yyyy'}}</td>

                <td class="text-nowrap" style="min-width: 200px;">
                    <button nz-button nzType="default" class="ant-btn-sm"
                            (click)="alterar(item);"
                            style="margin-right: 10px;">
                        Alterar
                    </button>
                    <button nz-button nzType="default" class="ant-btn-sm"
                            (click)="showConfirm(item);">
                        Remover
                    </button>
                </td>

            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>

</nz-content>


<nz-modal *ngIf="equipesOptions?.length" [(nzVisible)]="displayProfileModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Perfil de Acesso">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedProfile"
                   [nzPlaceHolder]="'Selecione'">
            <nz-option *ngFor="let option of equipesOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="saveBulkToProfiles()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Equipes">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedEquipes"
                   [nzPlaceHolder]="'Selecione as equipes'"
                   nzMode="multiple">
            <nz-option *ngFor="let option of equipeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparEquipes">Limpar as já cadastradas anteriormente</label>
    </ng-template>

    <ng-template #modalFooter>
        <button (click)="addNewEquipe()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova equipe
        </button>
        <button (click)="saveBulkToEquipes()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewEquipe()" (nzOnOk)="saveNewEquipe()" [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Equipe">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaEquipe"></label>
            <input [(ngModel)]="newEquipe" id="novaEquipe" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="modalFiltrarVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">

            <nz-form-item>
                <nz-form-label [nzSpan]="7">NCM</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.ncm !== '' && formFiltrar.formGroup.value.ncm !== null">
                    <input nz-input formControlName="ncm" type="text" nzallowclear mask="0000.00.00"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-top: 30px; margin-bottom: 0px;">
                <nz-form-label [nzSpan]="7">CST PIS</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cstPisEntrada">
                    <input nz-input
                           formControlName="cstPisEntrada" type="number" placeholder="Entrada"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cstPisSaida">
                    <input nz-input
                           formControlName="cstPisSaida" type="number" placeholder="Saída"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-top: 5px;">
                <nz-form-label [nzSpan]="7">Alíq. PIS</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.aliqPisEntrada">
                    <input nz-input
                           formControlName="aliqPisEntrada" type="number" placeholder="Entrada %"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.aliqPisSaida">
                    <input nz-input
                           formControlName="aliqPisSaida" type="number" placeholder="Saída %"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-top: 30px; margin-bottom: 0px;">
                <nz-form-label [nzSpan]="7">CST COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cstCofinsEntrada">
                    <input nz-input
                           formControlName="cstCofinsEntrada" type="number" placeholder="Entrada"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cstCofinsSaida">
                    <input nz-input
                           formControlName="cstCofinsSaida" type="number" placeholder="Saída"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-top: 5px;">
                <nz-form-label [nzSpan]="7">Alíq. COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.aliqCofinsEntrada">
                    <input nz-input
                           formControlName="aliqCofinsEntrada" type="number" placeholder="Entrada %"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.aliqCofinsSaida">
                    <input nz-input
                           formControlName="aliqCofinsSaida" type="number" placeholder="Saída %"
                           autocomplete="off" size="60" min="0"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Vigência Início</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.vigenciaInicioDe">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="vigenciaInicioDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.vigenciaInicioAte">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    style="width: 100%;"
                                    formControlName="vigenciaInicioAte"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="7">Vigência Fim</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.vigenciaFimDe">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="vigenciaFimDe"
                                    style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="1">
                    <nz-form-label [nzSpan]="7"></nz-form-label>
                </nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzValidateStatus]="'success'"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.vigenciaFimAte">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    style="width: 100%;"
                                    formControlName="vigenciaFimAte"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formCadastrar.modalVisible"
          [nzTitle]="this.editandoRegistro ? 'Alterar Regra PIS/Cofins' : 'Nova Regra PIS/Cofins'"
          [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">NCM</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="ncm" type="text" [mask]="'0000.00.00'"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-divider nzPlain nzOrientation="left" nzText="Entrada"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>CST PIS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="cstPisEntrada" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>

                <nz-form-label [nzSpan]="5" nzRequired>Alíq. PIS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="aliqPisEntrada" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>CST COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="cstCofinsEntrada" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>

                <nz-form-label [nzSpan]="5" nzRequired>Alíq. COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="aliqCofinsEntrada" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-divider nzPlain nzOrientation="left" nzText="Saída"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>CST PIS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="cstPisSaida" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>

                <nz-form-label [nzSpan]="5" nzRequired>Alíq. PIS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="aliqPisSaida" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>CST COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="cstCofinsSaida" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>

                <nz-form-label [nzSpan]="5" nzRequired>Alíq. COFINS</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input
                           formControlName="aliqCofinsSaida" type="number"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-divider></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Vigência Início</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="vigenciaInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>

                <nz-form-label [nzSpan]="5">Vigência Fim</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="vigenciaFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzAlign="left">CFOP(s) Ignorada(s)</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <nz-select [(ngModel)]="selectedCfops"
                               formControlName="cfopIgnorada"
                               [nzPlaceHolder]="'CFOP + [Enter]'"
                               nzShowArrow="false"
                               nzMode="tags" (ngModelChange)="adicionarCFOPCombo($event)">
                        <nz-option *ngFor="let option of cfopOptions" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Cód. Produto</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <input nz-input
                           formControlName="produtoCodigo" type="text"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzAlign="left">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="(opt.empresa_cnpj | cpfCnpj) + ' - ' + opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Participante</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <input nz-input
                           formControlName="participante" type="text"
                           autocomplete="off"/>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button *ngIf="!editandoRegistro" nz-button nzType="primary" [nzLoading]="cadastrando"
                (click)="confirmaInsert()">Confirmar
        </button>
        <button *ngIf="editandoRegistro" nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaUPD()">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
        <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar um
            arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal [(nzVisible)]="formImportarMovimento.modalVisible"
          [nzTitle]="'Importar via Movimentação'"
          [nzClosable]="true"
          (nzOnCancel)="modalImportarMovimento(false)"
          [nzWidth]="1200">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="false" [formGroup]="formImportarMovimento.formGroup" [nzLayout]="'vertical'"
              style="width: 100%;">

            <nz-form-item style="margin-top: 0px; margin-bottom: 0px;">
                <nz-form-label [nzOffset]="6" nzRequired>Competência</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzOffset]="6">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'MM/yyyy'"
                                    formControlName="competencia" style="width: 100%;"
                                    [nzMode]="'month'"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-top: 10px; margin-bottom: 0px;">
                <nz-form-control [nzSpan]="12" [nzOffset]="6">
                    <nz-list nzSize="small">
                        <nz-list-header>Fornecedores</nz-list-header>
                        <nz-list-item *ngFor="let item of comboFornecedores">
                            {{item.cnpj | cpfCnpj}} - {{item.nome}}
                        </nz-list-item>
                    </nz-list>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-top: 10px; margin-bottom: 0px;">

                <nz-form-control [nzSpan]="12" [nzOffset]="6">
                    <nz-list nzSize="small">
                        <nz-list-header>NCM</nz-list-header>
                        <nz-list-item>Todos</nz-list-item>
                    </nz-list>
                </nz-form-control>

            </nz-form-item>

            <nz-form-item style="margin-top: 10px; margin-bottom: 0px;">
                <nz-form-control [nzSpan]="12" [nzOffset]="6">
                    <nz-list nzSize="small">
                        <nz-list-header>CFOPs</nz-list-header>
                        <nz-list-item>{{cfops}}</nz-list-item>
                    </nz-list>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-top: 0px;">
                <nz-form-control [nzOffset]="6">
                    <button
                            nz-button
                            [nzType]="'default'"
                            [nzLoading]="buscando"
                            (click)="buscarMovimentacoes()"
                            [disabled]="!formImportarMovimento.formGroup.value.competencia && !formFiltrar.formGroup.value.fornecedor"
                            style="margin-top: 16px"
                    >
                        {{ !buscando ? 'Buscar informações' : 'Buscando' }}
                    </button>
                </nz-form-control>
            </nz-form-item>
        </form>

        <nz-divider></nz-divider>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="movimentacoes"
                  [nzShowPagination]="false"
                  [nzLoading]="importando"
                  *ngIf="showTable" style="width: 100%;"
                  [nzPageSize]="movimentacoes.length + 1" [nzScroll]="{ y: '300px' }">
            <thead>
            <tr style="font-size: 12px; width: 100%;">
                <th>NCM</th>
                <th nzAlign="center">CST</th>

                <th nzAlign="center">CST PIS <br>Entrada</th>
                <th nzAlign="center">ALÍQ. PIS <br>Entrada</th>
                <th nzAlign="center">CST COFINS <br>Entrada</th>
                <th nzAlign="center">ALÍQ. COFINS <br>Entrada</th>
                <th nzAlign="center">CST PIS <br>Saída</th>
                <th nzAlign="center">ALÍQ. PIS <br>Saída</th>
                <th nzAlign="center">CST COFINS <br>Saída</th>
                <th nzAlign="center">ALÍQ. COFINS <br>Saída</th>

                <th nzAlign="center" nzWidth="130px" class="text-nowrap">Já cadastrada?</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data" style="font-size: 12px;">

                <td nzLeft>
                    {{item.ncm ? (item.ncm | mask: '0000.00.00') : '-'}}
                </td>
                <td nzAlign="center">
                    {{item.cstMovimento ? item.cstMovimento : '-'}}
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.cstPisEntrada && item.jaCadastrada.cstPisEntrada != item.cstPisEntrada) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.cstPisEntrada">
                        {{item.jaCadastrada.cstPisEntrada ? (item.jaCadastrada.cstPisEntrada| number:'2.0') : '-'}}<br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.cstPisEntrada && {color: 'blue'}">
                        {{item.cstPisEntrada ? (item.cstPisEntrada | number:'2.0') : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.aliqPisEntrada && item.jaCadastrada.aliqPisEntrada != item.aliqPisEntrada) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.aliqPisEntrada">
                        {{item.jaCadastrada.aliqPisEntrada !== null ? (item.jaCadastrada.aliqPisEntrada | number:'1.2') + '%' : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.aliqPisEntrada && {color: 'blue'}">
                        {{item.aliqPisEntrada !== null ? (item.aliqPisEntrada | number:'1.2') + '%' : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.cstCofinsEntrada && item.jaCadastrada.cstCofinsEntrada != item.cstCofinsEntrada) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.cstCofinsEntrada">
                        {{item.jaCadastrada.cstCofinsEntrada ? (item.jaCadastrada.cstCofinsEntrada | number:'2.0') : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.cstCofinsEntrada && {color: 'blue'}">
                        {{item.cstCofinsEntrada ? (item.cstCofinsEntrada | number:'2.0') : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.aliqCofinsEntrada && item.jaCadastrada.aliqCofinsEntrada != item.aliqCofinsEntrada) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.aliqCofinsEntrada">
                        {{item.jaCadastrada.aliqCofinsEntrada !== null ? (item.jaCadastrada.aliqCofinsEntrada | number:'1.2') + '%' : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.aliqCofinsEntrada && {color: 'blue'}">
                        {{item.aliqCofinsEntrada !== null ? (item.aliqCofinsEntrada | number:'1.2') + '%' : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.cstPisSaida && item.jaCadastrada.cstPisSaida != item.cstPisSaida) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.cstPisSaida">
                        {{item.jaCadastrada.cstPisSaida ? (item.jaCadastrada.cstPisSaida | number:'2.0') : '-'}}<br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.cstPisSaida && {color: 'blue'}">
                        {{item.cstPisSaida ? (item.cstPisSaida | number:'2.0') : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.aliqPisSaida && item.jaCadastrada.aliqPisSaida != item.aliqPisSaida) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.aliqPisSaida">
                        {{item.jaCadastrada.aliqPisSaida !== null ? (item.jaCadastrada.aliqPisSaida | number:'1.2') + '%' : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.aliqPisSaida && {color: 'blue'}">
                        {{item.aliqPisSaida !== null ? (item.aliqPisSaida | number:'1.2') + '%' : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.cstCofinsSaida && item.jaCadastrada.cstCofinsSaida != item.cstCofinsSaida) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.cstCofinsSaida">
                        {{item.jaCadastrada.cstCofinsSaida ? (item.jaCadastrada.cstCofinsSaida | number:'2.0') : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.cstCofinsSaida && {color: 'blue'}">
                        {{item.cstCofinsSaida ? (item.cstCofinsSaida | number:'2.0') : '-'}}
                    </span>
                </td>

                <td nzAlign="center">
                    <span [ngStyle]="(item.jaCadastrada.aliqCofinsSaida && item.jaCadastrada.aliqCofinsSaida != item.aliqCofinsSaida) && {color: 'red'}"
                          *ngIf="item.jaCadastrada.aliqCofinsSaida">
                        {{item.jaCadastrada.aliqCofinsSaida !== null ? (item.jaCadastrada.aliqCofinsSaida | number:'1.2') + '%' : '-'}}
                        <br>
                    </span>
                    <span [ngStyle]="item.jaCadastrada.aliqCofinsSaida && {color: 'blue'}">
                        {{item.aliqCofinsSaida !== null ? (item.aliqCofinsSaida | number:'1.2') + '%' : '-'}}
                    </span>
                </td>

                <td class="text-nowrap" nzAlign="center">
                    <nz-switch [(ngModel)]="item.substituir" nzCheckedChildren="Substituir"
                               nzUnCheckedChildren="Ignorar" *ngIf="item.jaCadastrada"></nz-switch>
                </td>

            </tr>
            </tbody>
        </nz-table>


    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarMovimento(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="importando"
                (click)="importar()">{{importando ? 'Concluindo' : 'Concluir'}}</button>
    </div>
</nz-modal>

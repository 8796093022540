<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Visibilidades"></nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Visibilidades"

             [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
<!--                <nz-badge [nzCount]="qtdFiltrosAtivos"-->
<!--                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">-->
<!--                    <button nz-button nzType="primary"-->
<!--                            style="margin-right: 10px;"-->
<!--                            (click)="modalFiltrar(true);">-->
<!--                        <i nz-icon nzType="search"></i>-->
<!--                        Filtros-->
<!--                    </button>-->
<!--                </nz-badge>-->
<!--                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"-->
<!--                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">-->
<!--                    Opções-->
<!--                    <em nz-icon nzType="down"></em>-->
<!--                </button>-->
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
            <!--<Navbar-List (generalFilterHandler)="getGeneralSearch($event)" (resetForm)="resetSearch()"
                         [registerComponent]="'RegisterUserComponent'" (registerClick)="modalCadastrar(true)"
                         [tabTitle]="'Cadastrar Usuário'">
                <div class="nav-item" menuOpcoes>
                    <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                            nzType="default">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    &lt;!&ndash;<li (click)="onAlterarPerfilDeAcesso()" [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Alterar perfil de acesso
                                    </li>&ndash;&gt;
                                    <li (click)="onAlterarVisibilidadeColetiva()"
                                        [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Alterar visibilidade
                                    </li>
                                    &lt;!&ndash;<li (click)="onEmailBoasVindas()" [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Enviar email de boas-vindas
                                    </li>&ndash;&gt;
                                </ul>
                            </li>
                            &lt;!&ndash;<li nz-menu-item nzDisabled>Importar para Excel</li>
                            <li (click)="exportUsers()" nz-menu-item nzDisabled>Exportar para Excel</li>&ndash;&gt;
                        </ul>
                    </nz-dropdown-menu>
                </div>
                &lt;!&ndash;<User-Filter (handleFilterEvent)="getFilterParams($event)" filtro></User-Filter>&ndash;&gt;
            </Navbar-List>-->
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzLoading]="loading"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th></th>
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="left">Descrição</th>
                <th nzColumnKey="usuarios" [nzSortFn]="true" nzAlign="center">Usuários</th>
                <th nzColumnKey="empresas" [nzSortFn]="true" nzAlign="center">Empresas</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td style="width: 50px;"></td>
                <td>
                    <a nz-button nzType="link"
                       (click)="openTab('VisibilidadeDetalheComponent', item.id, {id: item.id, nome: item.descricao})">{{item?.descricao}}</a>
                </td>
                <td nzAlign="center">{{item?.usuarios}}</td>
                <td nzAlign="center">{{item?.empresas}}</td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="openTab('VisibilidadeDetalheComponent', item.id, {id: item.id, nome: item.descricao})">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button
                            (click)="showConfirmRemover(item.id)"
                            nzType="link">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal *ngIf="profileOptions?.length" [(nzVisible)]="displayProfileModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Perfil de Acesso">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedProfile"
                   [nzPlaceHolder]="'Selecione'">
            <nz-option *ngFor="let option of profileOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="saveBulkToProfiles()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>
<nz-modal *ngIf="visibilidadeOptions?.length" [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Visibilidades">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedVisibilidades"
                   [nzPlaceHolder]="'Selecione as visibilidades'"
                   nzMode="multiple">
            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparVisibilidades">Limpar as já cadastradas anteriormente</label>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="addNewVisibilidade()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova visibilidade
        </button>
        <button (click)="saveBulkToVisibilities()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>
<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="saveNewVisibilidade()" [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Visibilidade">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>
<nz-modal [(nzVisible)]="modalCadastrarVisible" [nzTitle]="'Cadastrar Nova Visibilidade'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzFor="primeiroNome" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.descricao !== '' && formFiltrar.formGroup.value.descricao !== null">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

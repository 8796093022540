import {
    Component,
    ElementRef,
    OnInit,
    ChangeDetectorRef, NgZone
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LOCALE_ID} from '@angular/core';
import {SefazErpService} from './sefazErp.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzUploadFile} from 'ng-zorro-antd/upload';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-check-sefaz-erp',
    templateUrl: './sefazErp.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./sefazErp.component.scss']
})

export class SefazErpComponent extends AbstractListTable<any> implements OnInit {

    loadingContadores: boolean;
    loadingCardTabs: boolean;
    arrayMeses = [];
    objectMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    cardCompetenciaAtivo: number;
    objectCountersValues = {
        unidades: 0,
        sefaz: 0,
        armazenadas: 0,
        erp: 0,
        mesSelecionado: '',
        anoSelecionado: '',
        updated_at: null
    };
    mesSelecionado: number;
    anoSelecionado: number;
    status = false;
    formFiltros: FormStack;
    tabAtiva = 0;
    collapseCard = false;
    arraySelectEmpresa = [];
    arrayVazio = true;
    qtdFiltrosAtivos = 0;

    modalUploadVisible = false;
    fileListUpload: NzUploadFile[] = [];
    formUpload: FormStack;
    arrayFiltrosTipos: any[] = [];


    loadings = {
        upload: false
    };

    comboGrupos = [];

    currentParams = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sort: [],
        filter: [],
    };

    constructor(
        private sefazErpService: SefazErpService,
        private compl: ActivatedRoute,
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private el: ElementRef,
        private fb: UntypedFormBuilder,
        private tabService: TabService,
        private toastService: ToastrService,
        private modalService: NzModalService,
    ) {

        super(sefazErpService, null, toastService);

        this.sefazErpService = sefazErpService;

        this.loadingContadores = true;

        this.sefazErpService.retornaCompetencias().subscribe((response: any) => {

            this.arrayMeses = response.dados;

            let verificaId = 1;

            for (const mes of this.arrayMeses) {

                if (this.arrayMeses[this.arrayMeses.length - verificaId].id === null) {

                    verificaId++;

                } else {

                    this.arrayVazio = false;

                    this.mesSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].mes;
                    this.anoSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].ano;

                    this.objectCountersValues = this.arrayMeses[this.arrayMeses.length - verificaId];
                    this.objectCountersValues.mesSelecionado = this.objectMeses[this.mesSelecionado - 1].toUpperCase();
                    this.objectCountersValues.anoSelecionado = this.anoSelecionado.toString();

                    setTimeout(() => {
                        this.cardCompetenciaAtivo = this.arrayMeses.length - verificaId;
                        this.collapseCard = true;
                    }, 1000);

                    setTimeout(() => {
                        this.loadingContadores = false;
                    }, 1200);

                    this.queryTable(this.currentParams, this.currentSearch);

                    return;

                }

            }

            if (this.arrayVazio && this.arrayMeses.length > 0) {

                verificaId = 1;

                this.mesSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].mes;
                this.anoSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].ano;

                this.objectCountersValues = this.arrayMeses[this.arrayMeses.length - verificaId];
                this.objectCountersValues.mesSelecionado = this.objectMeses[this.mesSelecionado - 1].toUpperCase();
                this.objectCountersValues.anoSelecionado = this.anoSelecionado.toString();

                setTimeout(() => {
                    this.cardCompetenciaAtivo = this.arrayMeses.length - verificaId;
                    this.collapseCard = true;
                }, 1000);

            } else {
                this.toastService.warning('Sem dados para exibir.');
            }

        });

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                ie: [null, null],
                grupo: [null, null],
            })
        };

        this.sefazErpService.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });

        this.formUpload = {
            modalVisible: false,
            formGroup: this.fb.group({
                erp: [null, Validators.required],
            })
        };

        this.sefazErpService.retornaComboGrupos().subscribe((retorno: any) => {
            retorno.grupoEmpresarial.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboGrupos = retorno.grupoEmpresarial;
        });

    }

    ngOnInit(): void {
        this.sefazErpService.retornaTipos().subscribe((response: any) => {
            this.arrayFiltrosTipos = response.dados;
        });
    }

    queryTable(params: NzTableQueryParams, search: string = null) {

        this.currentParams = params;

        this.currentSearch = search;

        this.loadingCardTabs = true;

        this.calculaBadgeFiltros();

        if (this.currentParams){
            this.currentParams.filter = [];
        }

        const filtros = this.formFiltros.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        let mes: any = this.mesSelecionado;

        if (Number(mes) < 10) {
            mes = '0' + mes;
        }

        const url = `${environment.apiUrl}/check/sefazErp/competencia/${this.anoSelecionado}/${mes}`;

        this.sefazErpService.retornaSefazErp(params, search, url).subscribe((response: any) => {

            this.items = response.data;

            this.loadingCardTabs = false;

            this.cdr.detectChanges();

            this.pagination = new Pagination(
                response?.per_page || 7,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 7);

        });

    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    clickEvent() {
        this.status = !this.status;
    }

    formatOne = (percent: number) => `${percent}%`;

    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined) && this.arrayMeses[event.clickedIndex].id !== null) {

            this.loadingContadores = true;

            this.cardCompetenciaAtivo = event.clickedIndex;

            this.mesSelecionado = this.arrayMeses[event.clickedIndex].mes;
            this.anoSelecionado = this.arrayMeses[event.clickedIndex].ano;

            this.objectCountersValues = this.arrayMeses[event.clickedIndex];
            this.objectCountersValues.mesSelecionado = this.objectMeses[this.arrayMeses[event.clickedIndex].mes - 1].toUpperCase();
            this.objectCountersValues.anoSelecionado = this.arrayMeses[event.clickedIndex].ano.toString();

            this.loadingContadores = false;

            this.queryTable(this.currentParams, this.currentSearch);

        }

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltros !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrar() {
        this.formFiltros.modalVisible = false;
        this.pagination.current_page = 1;
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.arrayMeses.length - 1) {
                    this.cardCompetenciaAtivo = this.arrayMeses.length - 1;
                }

                break;

        }

        if (!this.arrayVazio) {

            this.loadingContadores = true;
            this.loadingCardTabs = true;

            this.mesSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].mes;
            this.anoSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].ano;

            this.objectCountersValues = this.arrayMeses[this.cardCompetenciaAtivo];
            this.objectCountersValues.mesSelecionado = this.objectMeses[this.arrayMeses[this.cardCompetenciaAtivo].mes - 1].toUpperCase();
            this.objectCountersValues.anoSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].ano.toString();

            this.loadingContadores = false;

        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}): void {
        data['emissaoData'] = new Date(`${this.anoSelecionado}-${this.mesSelecionado}-01`)
        this.zone.run(() => {
            const component = getComponentConfigByName(componentName);
            const url = buildUrl(component, queryParams);
            const newTab = new Tab(component.name, component.title, url, component.urlType, data);
            this.tabService.closeAndAddTab(newTab);
        });
    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltros.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    modalUpload(visible: boolean): void {
        this.modalUploadVisible = visible;
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileListUpload = [];
        this.fileListUpload.push(file);
        return false;
    }

    confirmarUploadArquivo(): void {

        if (this.formUpload.formGroup.valid) {
            this.loadings.upload = true;

            const formData = new FormData();

            this.fileListUpload.forEach((file: any) => {
                formData.append('arquivo', file);
            });

            formData.append('erp', this.formUpload.formGroup.value.erp);


            this.sefazErpService.uploadArquivo(formData).subscribe((response: any) => {

                    this.loadings.upload = false;
                    this.toastrService.success(response.message);

                    this.fileListUpload = [];

                    this.modalUploadVisible = false;

                    this.queryTable(this.currentParams, this.currentSearch);

                },
                (response) => {
                    this.loadings.upload = false;
                    this.toastrService.error(response.error.message);
                });

        } else {
            Object.values(this.formUpload.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

}

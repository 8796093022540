import {
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LOCALE_ID} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ErpService} from './erp.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './erp.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./erp.component.scss']
})

export class ErpComponent extends AbstractListTable<any> implements OnInit {


    cardCompetenciaAtivo: number;

    formLayout: FormStack;
    formExportar: FormStack;

    empresaNome = '';

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    anoSelecionado: number = null;

    arrayAnos = [];
    arrayTableObrigacoes = [];

    status = false;
    statusProcessamento = 'emProcessamento';

    statusProcessamentoArray = [
        {value: '0', label: 'Na Fila'},
        {value: '1', label: 'Processando'},
        {value: '2', label: 'Processado'},
        {value: '3', label: 'Erro'},
    ];

    integracoes = [];

    expandSet = new Set<number>();

    gridLoading = false;

    cardTabsLoading = false;

    tabNumber = 0;

    collapseCard = false;

    pageTitle = 'Guias Federais';
    showTable = 'sap';

    formFiltrar: FormStack;
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectUf = [];
    viewModalUploadArquivo = false;

    apiUrl = environment.apiUrl;

    uploading = false;
    fileList: NzUploadFile[] = [];

    arquivoUrl = null;
    qtdFiltrosAtivos = 1;

    loadings = {
        gravando: false,
        downloads: [],
        detalhes: [],
        tableItensDetalhe: false
    };
    modalDetalheArquivoVisible = false;
    nomeArquivoSelecionado = '';
    arrayItensDetalhe: any[] = [];
    currentParamsItensDetalhe: NzTableQueryParams = {filter: [], pageIndex: 1, pageSize: 10, sort: []};
    currentSearchItensDetalhe: string;
    paginationItensDetalhe = new Pagination(10, 1, 5, 50);
    descricoesErp = {
        sap: 'Sap',
        totvs: 'Totvs'
    };
    titleTable = 'Sap';

    constructor(
        private toastService: ToastrService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef,
        private service: ErpService,
        private fb: UntypedFormBuilder
    ) {
        super(service, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                status: [null, null],
                dias: ['30', null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formLayout = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.service.retornaIntegracoesAtivas(
        ).subscribe((retorno: any) => {

            retorno.forEach(i => {
                this.integracoes.push({key: i.toUpperCase(), value: i});
            });

        }, error => {
            this.toastService.error('Falha ao obter integrações ativas.');
        });

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornarSelectsUfs(
        ).subscribe((retorno: any) => {
            this.arraySelectUf = retorno;
        });


    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    ngOnInit(): void {
        this.changeTabs();
    }

    changeTable(tabela) {

        this.showTable = tabela;
        this.queryTable(this.currentParams, this.currentSearch);
        this.titleTable = this.descricoesErp[tabela];

    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.cdr.detectChanges();
    }

    changeTabs(event: any = null) {

        switch (this.tabNumber) {

            case 0: {

                if (this.showTable === 'E' || this.showTable === 'M' || this.showTable === 'F') {
                    this.statusProcessamento = 'armazenadas';

                } else {
                    this.statusProcessamento = 'naoIdentificadas';

                }

                break;
            }

            case 1: {
                this.statusProcessamento = 'emProcessamento';
                break;
            }

        }

        this.queryTable(this.currentParams, this.currentSearch);

    }


    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

        this.cdr.detectChanges();
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params, this.currentSearch);
            this.pagination.current_page = params.pageIndex;
        }

    }

    listByTableDetalhes(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationItensDetalhe.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationItensDetalhe.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationItensDetalhe.current_page) {
            this.queryTableItensDetalhe(params);
            this.paginationItensDetalhe.current_page = params.pageIndex;
        }

    }


    queryTable(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;
        this.currentSearch = search;

        this.gridLoading = true;

        const url = this.service.baseUrl + '/tabela/' + this.showTable;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = [];
            this.items = response.data;

            this.items.forEach((d) => {

                switch (d.status) {
                    case '0':
                        d.status_processamento_descricao = 'Na fila';
                        d.status_class = 'default';
                        break;
                    case '1':
                        d.status_processamento_descricao = 'Processando';
                        d.status_class = 'info';
                        break;
                    case '2':
                        d.status_processamento_descricao = 'Processado';
                        d.status_class = 'success';
                        break;
                    case '3':
                        d.status_processamento_descricao = 'Erro';
                        d.status_class = 'error';
                        break;
                    default:
                        d.status_processamento_descricao = '-';
                        break;
                }
            });

            this.gridLoading = false;
            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);
        });
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrar(): any {

        this.calculaBadgeFiltros();

        this.formFiltrar.modalVisible = false;

        this.currentParams.pageIndex = 1;
        this.currentParams.filter = [];

        this.currentParams.filter.push({
            key: 'status',
            value: this.formFiltrar.formGroup.get('status').value
        });

        this.currentParams.filter.push({
            key: 'dias',
            value: this.formFiltrar.formGroup.get('dias').value
        });

        this.queryTable(this.currentParams, this.currentSearch);

        this.cdr.detectChanges();
    }

    exportDocumento(name) {

        this.service.exportDocumento(name).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, name);

        });

    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;

    }

    confirmarImportar(): void {

        this.uploading = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivo', file);
        });

        formData.append('erp', this.showTable);
        formData.append('formato', this.showTable);

        this.service.uploadArquivo(formData).subscribe((response: any) => {

                this.toastrService.success(response.message);

                this.fileList = [];

                this.viewModalUploadArquivo = false;

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.uploading = false;
            });

    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    modalArquivo(visible: boolean) {

        this.modalDetalheArquivoVisible = visible;
        if (!visible) {
            this.paginationItensDetalhe.current_page = 1;
            this.currentParamsItensDetalhe.pageIndex = 1;
            this.nomeArquivoSelecionado = null;
        }

    }

    modalImportarArquivo(visible: boolean) {
        this.viewModalUploadArquivo = visible;
    }

    confirmaFiltrar(): void {

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    confirmaExportar(): void {

        const tipo = this.formExportar.formGroup.value.tipo;

        this.service
            .exportExcel(this.currentParams.filter, this.currentSearch, tipo, this.showTable, this.statusProcessamento)
            .subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = this.pageTitle;

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.modalVisible = false;
                }

            });

    }

    showConfirmRemover(idGuia: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Arquivo?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerGuia(idGuia)
        });

    }

    removerGuia(id: string): void {

        if (id) {

            this.service.removerGuia(id).subscribe((response) => {


                this.queryTable(this.currentParams, this.currentSearch);
                this.toastService.success('Arquivo removido com sucesso');

            }, (response) => {
                this.toastService.error(response.error.message);
            });
        }
    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [{key: 'dias', value: '30'}],
        };

        this.formFiltrar.formGroup.reset();
        this.formFiltrar.formGroup.get('dias').setValue('30');
        this.qtdFiltrosAtivos = 1;

        this.queryTable(this.currentParams, this.currentSearch);

    }

    downloadAnexo(arquivoNome: string): void {

        this.loadings.downloads[arquivoNome] = true;

        this.service.downloadArquivo(arquivoNome).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome);
            this.loadings.downloads[arquivoNome] = false;

        }, (res) => {
            this.toastService.error('Falha ao fazer download do arquivo.');
            this.loadings.downloads[arquivoNome] = false;
        });

    }

    detalheAnexo(arquivoNome: string): void {
        this.nomeArquivoSelecionado = arquivoNome;
        this.queryTableItensDetalhe(this.currentParamsItensDetalhe);
    }

    queryTableItensDetalhe(params: NzTableQueryParams, search: string = null): void {
        this.currentParamsItensDetalhe = params;
        this.currentSearchItensDetalhe = search;

        if (this.nomeArquivoSelecionado) {

            this.loadings.tableItensDetalhe = true;

            const url = this.service.baseUrl + '/detalhe/' + this.nomeArquivoSelecionado + '/arquivo';

            this.service.listToTable(params, search, url).subscribe((response) => {

                this.arrayItensDetalhe = [];
                this.arrayItensDetalhe = response.data;
                this.loadings.tableItensDetalhe = false;


                this.paginationItensDetalhe = new Pagination(
                    response?.per_page || 10,
                    response?.current_page || 1,
                    response?.last_page || 5,
                    response?.total || 50);

                this.modalArquivo(true);

            }, (res) => {
                this.toastService.error('Erro ao carregar dados.');
                this.loadings.tableItensDetalhe = false;
                this.nomeArquivoSelecionado = null;
            });

        }

    }

}

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Obrigações Federais">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Obrigações Federais">
        <ng-template #navBar>
            <Navbar-List (generalFilterHandler)="getGeneralSearch($event)" (resetForm)="resetSearch()"
                         [registerComponent]="'RegisterUserComponent'" (registerClick)="modalCadastrar(true)"
                         [tabTitle]="'Cadastrar Usuário'">
                <div class="nav-item" menuOpcoes>
                    <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                            nzType="default">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    <li [nzDisabled]="checkedItems?.size === 0"
                                        nz-menu-item>
                                        Atualizar Situação na RFB
                                    </li>
                                </ul>
                            </li>
                            <li nz-menu-item nzDisabled>Exportar Planilha</li>
                            <li nz-menu-item nzDisabled>Importar Planilha Regime</li>
                            <li nz-menu-item nzDisabled>Importar Planilha IE/IEST</li>
                            <li nz-menu-item nzDisabled>Importar Planilha IM</li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
                <!--<User-Filter (handleFilterEvent)="getFilterParams($event)" filtro></User-Filter>-->
            </Navbar-List>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzWidth="60px"></th>
                <th nzColumnKey="id" [nzSortFn]="true">ID</th>
                <th nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="empresa" [nzSortFn]="true">Empresa</th>
                <th nzColumnKey="grupo" [nzSortFn]="true">Grupo</th>
                <th nzColumnKey="ie" [nzSortFn]="true">IE</th>
                <th nzColumnKey="uf" [nzSortFn]="true">UF</th>
                <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                <th nzColumnKey="obrigacoesSugeridas" [nzSortFn]="true">Obrigações Sugeridas</th>
                <th nzColumnKey="obrigacoesCadastradas" [nzSortFn]="true">Obrigações Cadastradas</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let inscricoesRegimes of basicTable.data">
                <tr>
                    <td nzLeft (nzCheckedChange)="onItemChecked(inscricoesRegimes.id, $event)"
                        [nzChecked]="checkedItems.has(inscricoesRegimes.id)"
                        [nzDisabled]="inscricoesRegimes.disabled">
                    </td>
                    <td [nzExpand]="expandSet.has(inscricoesRegimes.id)"
                        (nzExpandChange)="onExpandChange(inscricoesRegimes.id, $event)"></td>
                    <td nzLeft>{{inscricoesRegimes?.id}}</td>
                    <td nzLeft>{{inscricoesRegimes?.codigo}}</td>
                    <td nzLeft>{{inscricoesRegimes?.cnpj}}</td>
                    <td nzLeft>{{inscricoesRegimes?.empresa}}</td>
                    <td nzLeft>{{inscricoesRegimes?.grupo}}</td>
                    <td nzLeft>{{inscricoesRegimes?.ie}}</td>
                    <td nzLeft>{{inscricoesRegimes?.uf}}</td>
                    <td nzLeft>{{inscricoesRegimes?.tipo}}</td>
                    <td style="text-align: center;">{{inscricoesRegimes?.obrigacoesSugeridas}}</td>
                    <td style="text-align: center;">{{inscricoesRegimes?.obrigacoesCadastradas}}</td>
                </tr>
                <tr [nzExpand]="expandSet.has(inscricoesRegimes.id)">
                    <nz-table class="inside-table" [nzData]="items" nzTableLayout="fixed"
                              [nzFrontPagination]="false">
            <thead>
            <tr>
                <th>Id</th>
                <th>Descrição</th>
                <th>Periodicidade</th>
                <th>Tipo</th>
                <th>Cadastrada</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of items">
                <tr>
                    <td>SPED-SP</td>
                    <td>SPED-FISCAL</td>
                    <td>M</td>
                    <td>A</td>
                    <td>SIM</td>
                    <td>
                        <a>Excluir</a>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </nz-table>
        </tr>
        </ng-container>
        </tbody>
        </nz-table>
    </nz-card>
</nz-content>

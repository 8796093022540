import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../../core/helpers';

@Injectable({providedIn: 'root'})
export class ObrigacaoEmpresaService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/cadastros/obrigacao-empresa-detalhe/';
    newBaseUrl = environment.apiUrl + '/historico/obrigacao-empresa/';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaDados(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao-empresa-detalhe/${id}`);
    }

    alterar(id: string, dados: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao-empresa-detalhe/${id}`, dados);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    exportDocumento(id, orgao): any {

        const url = `${environment.apiUrl}/drive/obrigacoes/${orgao}/download/${id}`;

        return this.http.get(url, {responseType: 'blob'});

    }

    getComboUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }

    exportExcel(id: string, filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/historico/obrigacao-empresa/${id}/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    uploadAnexos(obrigacaoEmpresaId: string, dados: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/upload/${obrigacaoEmpresaId}`, dados);
    }

    setObservacao(obrigacaoEmpresaId: string, observacao: any, id = null): Observable<any> {

        if (id) {
            return this.http.post(`${environment.apiUrl}/historico/obrigacao-empresa/${obrigacaoEmpresaId}/observacao/${id}`, {observacao});
        }

        return this.http.post(`${environment.apiUrl}/historico/obrigacao-empresa/${obrigacaoEmpresaId}/observacao`, {observacao});
    }

    removerObservacao(id = null): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/historico/obrigacao-empresa/observacao/${id}`);
    }

    getComboGatilhos(obgEmpresaId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/obrigacao/gatilhos/empresa/${obgEmpresaId}`);
    }

    dispararGatilho(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/obrigacao/gatilho/disparar`, data);
    }

}

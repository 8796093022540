import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Empresa} from '@models/empresa.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class EmpresaService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/administracao/empresas', Empresa);
    }


    save(campos): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/empresa`, campos);
    }

    limiteCadastral(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/administracao/empresas/limiteCadastral`);
    }

    alterarGrupoEmpresarialMassa(empresas: any, grupo: string): Observable<any> {

        const data: any = {
            empresas,
            grupo
        };

        return this.http.put(`${environment.apiUrl}/administracao/empresas/alterarGrupoEmpresarialMassa`, data);
    }

    alterarAtividadesMassa(empresas: string[], atividade: string): Observable<any> {

        const data: any = {
            empresasIds: empresas,
            atividade
        };

        return this.http.post(`${environment.apiUrl}/administracao/empresas/alterar-atividade-massa`, data);
    }

    downloadModelo(): any {

        return this.http.get(`${environment.apiUrl}/administracao/empresas/exportar/modelo`,
            {responseType: 'blob'});

    }

    uploadPlanilha(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/empresas/importar`, formData);
    }

    alterarStatusEmMassa(empresas: any[], ativo: string): any {


        return this.http.post(`${environment.apiUrl}/administracao/empresas/alterarStatusMassa`, {empresas, ativo});

    }

    getVisibilidades(): any {


        return this.http.get(`${environment.apiUrl}/filtros/visibilidades`);

    }

    salvarVisibilidadesUsuarios(empresas: any[], visibilidade: string): Observable<any> {


        return this.http.post(`${environment.apiUrl}/administracao/empresas/alterarVisibilidadeMassa`,
            {empresas, visibilidade});

    }

    getProcuradoes(): any {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/credenciais-acesso?temCertificado=1`);

    }

    atualizarProcuracoes(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/robo/rpa/solicitacao`, data);

    }

}

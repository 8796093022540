import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import {CategoriasService} from '../../cadastros/categorias/categorias.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-obrigacoes-regras',
    templateUrl: './obrigacoes-regras.component.html',
    styleUrls: ['./obrigacoes-regras.component.scss']
})
export class ObrigacoesRegrasComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    comboObrigacoes = [];
    comboOrgaos = [
        {label: 'Municipal', value: 'Municipal'},
        {label: 'Estadual', value: 'Estatual'},
        {label: 'Federal', value: 'Federal'},
        {label: 'Previdenciário', value: 'Previdenciário'},
    ];

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    qtdFiltrosAtivos = 0;
    formFiltrar: FormStack;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    status = false;

    checked = false;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private categoriasService: CategoriasService,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);


        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                obrigacao_id: [null, null],
                grupo: [null, null],
                orgao: [null, null]
            })
        };

        this.retornaSelectObrigacoes();

    }

    ngOnInit() {

        // this.buildExportUrl();
        this.getPerfis();

    }


    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {

        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    clickEvent() {
        this.status = !this.status;
    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosUsuario = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosUsuario[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.usuarioService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/usuario/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.getPerfis();

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });


        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            this.currentParams.filter.push({key: chave, value: valor});

        }


        this.loading = true;

        const baseUrl = environment.apiUrl + '/check/obrigacoes/regras';

        this.abstractService.listToTable(this.currentParams, this.currentSearch, baseUrl).subscribe((response) => {

            this.checkedItems.clear();

            this.items = [];

            this.items = response.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

        });

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {

            if (chave === 'validadeInicio' || chave === 'validadeFim') {
                valor = this.abstractService.formataDateBD(valor.toString());
            }
            if (!this.currentParams.filter[chave]) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    retornaSelectObrigacoes() {
        this.categoriasService.retornaSelectObrigacoes().subscribe((retorno: any) => {
            retorno.forEach((value: any) => {
                this.comboObrigacoes.push({value: value.id, label: value.descricao});
            });
        });
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.items.forEach(({obrigacao_id}) => this.updateCheckedSet(obrigacao_id, checked));

    }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ConciliacoesDetalhesService extends AbstractService<any> {
    baseUrl = environment.apiUrl + '/check/armazenamentos/';

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornarSelectsObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaSelectsUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarCabecalho(obrigacao, competencia): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/cabecalho/${obrigacao}/${competencia}`);
    }

    resumoCFOP(checkObrigacaoValidacaoId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/resumo-cfop/${checkObrigacaoValidacaoId}`);
    }

    apuracaoICMS(checkObrigacaoValidacaoId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/apuracao-icms/${checkObrigacaoValidacaoId}`);
    }


    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10 && mes.toString().length === 1) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    exportDocumento(id, orgao): any {

        return this.http.get(`${environment.apiUrl}/check/conciliacoes/arquivo/${id}/${orgao}`, {responseType: 'blob'});

    }

    liberarRegras(selectedIds: any, tipo: string): Observable<any> {

        return this.http.post(`${environment.apiUrl}/check/conciliacoes/liberar/${tipo}`, {ids: selectedIds});

    }

    cadastrarAprovacao(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/debug/cadastro-aprovacao`, data);

    }

    iniciarConciliacao(arrayMd5): Observable<any> {

        return this.http.post(`${environment.apiUrl}/check/conciliacoes/solicitar/comparativo-imposto`, {md5: arrayMd5});

    }

    reprocessarRegistro(arrayMd5): Observable<any> {

        return this.http.post(`${environment.apiUrl}/validador-sped/solicitacao`, {md5: arrayMd5});
    }

    exportarDadosRegras(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=xlsx`);
        queryParams.push(`tipo=regrasConciliacoes`);

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}${queryString}`, {});

    }

    retornaDemaisColunas(obrigcaoEmpresaId: string, obrigacaoRegraId: string, params, search: string = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(`${environment.apiUrl}/check/conciliacoes/regras-detalhe/${obrigcaoEmpresaId}/${obrigacaoRegraId}${queryString}`);

    }
}

<div class="tasks">
    <nz-badge [nzCount]="badge" style="margin-right: 45px;">
        <button nz-button nzType="link" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu"
                [nzDisabled]="!staticConnected" disabled="{{!staticConnected}}"
                nzPlacement="bottomRight"
                style="padding-right: 5px!important;"
                [nzClickHide]="true">
            <i class="fas fa-list-ul" style="font-size: 22px;" [ngStyle]="staticConnected && {color: '#fff'}"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <div class="seta-para-cima seta-msg-list"></div>
            <div class="notify-container" style="margin-top: -3px; border: none; box-shadow: 0 0 5px 0.5px #e1e1e1">
                <div class="notify-header d-flex justify-content-end">
                    <button nz-button nzType="link"
                            nz-tooltip="true"
                            nzTooltipTitle="Limpar"
                            nzSize="small"
                            (click)="removerTasks()">
                        Limpar
                    </button>
                </div>
                <div class="notify-scrollbar">
                    <ul nz-menu>
                        <ng-container *ngIf="staticArrayTasks.length > 0">
                            <li nz-menu-item *ngFor="let task of staticArrayTasks;"
                                class="caixa-notificacao">
                                <div style="padding-right: 7px; width: 100%; min-width: 450px">
                                    <div style="padding-left: 7px;">
                                        <h5 nz-typography>
                                            {{ task.titulo }}
                                            <!--<span *ngIf="task.statusProcessamento === 0">em fila...</span>
                                            <span *ngIf="task.statusProcessamento === 1">em processamento...</span>
                                            <span *ngIf="task.statusProcessamento === 2">processado com sucesso!</span>
                                            <span *ngIf="task.statusProcessamento === 3">erro ao processar.</span>-->
                                        </h5>
                                        <p [innerHTML]="task.mensagem"
                                           style="max-width: 450px; white-space: normal; margin-bottom: 7px; color: rgba(0,0,0,0.8);"></p>
                                        <ng-container *ngFor="let widget of task.widget">
                                            <nz-progress
                                                    *ngIf="widget.tipo === 'progress_bar' && task.statusProcessamento === 1"
                                                    nzStatus="active"
                                                    [nzPercent]="widget.progressoPorc"
                                                    nz-tooltip="true"
                                                    nzTooltipTitle="{{widget.mensagem}}"
                                                    [nzFormat]="formatOne"></nz-progress>
                                            <nz-progress
                                                    *ngIf="widget.tipo === 'progress_bar' && task.statusProcessamento === 3"
                                                    nzStatus="exception"
                                                    [nzPercent]="widget.progressoPorc"
                                                    nz-tooltip="true"
                                                    nzTooltipTitle="{{widget.mensagem}}"
                                                    [nzFormat]="formatOne"></nz-progress>
                                        </ng-container>
                                        <!--<nz-progress
                                                *ngIf="task.statusProcessamento === 2"
                                                [nzPercent]="100"
                                                [nzFormat]="formatOne"></nz-progress>-->
                                        <h5 style="color: rgba(0,0,0,.45); margin-bottom: 0;">
                                     <span *ngIf="task.statusProcessamento === 0">
                                    Solicitado em:
                                         {{ task.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}
                                </span>
                                            <span *ngIf="task.statusProcessamento === 1">
                                    Iniciado em:
                                                {{ task.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}
                                </span>
                                            <span *ngIf="task.statusProcessamento === 2">
                                    Processado em:
                                                {{ task.updated_at | date: 'dd/MM/yyyy HH:mm:ss' }}
                                                -
                                        <span *ngIf="task.diasNotificacao > 0">há {{ task.diasNotificacao }}
                                            dia(s)</span>
                                        <span *ngIf="task.diasNotificacao === 0">Hoje</span>
                                </span>
                                            <span *ngIf="task.statusProcessamento === 3">
                                    Erro ao processar:
                                                {{ task.updated_at | date: 'dd/MM/yyyy HH:mm:ss' }}
                                </span>
                                        </h5>
                                    </div>
                                    <div>
                                        <button nz-button nzType="link" nzSize="small"
                                                *ngFor="let action of task.actions;"
                                                style="font-size: 12px;"
                                                (click)="openTab(action.url, action.data, action.data)">
                                            {{ action['titulo'] }}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <button nz-button nzType="link"
                                            (click)="tasksDimiss(task.id)" nzSize="small"
                                            *ngIf="task.statusProcessamento !== 1">
                                        <i class="fa-regular fa-trash-can" [nz-tooltip]="'Remover'"></i>
                                    </button>
                                </div>
                            </li>
                        </ng-container>
                        <li nz-menu-item *ngIf="staticArrayTasks.length === 0">
                            <div style="padding: 0 15px;">
                                <span>Você não tem processamentos no momento</span>
                                <h5 style="color: rgba(0,0,0,.45);">
                                    Última Atualização: {{ staticUltimaAtualizacao | date: 'dd/MM/yyyy h:mm:ss' }}
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nz-dropdown-menu>
    </nz-badge>
    <ng-template #badge>
        <span *ngIf="staticArrayTasks.length" class="badge-manual">
            {{ staticConnected ? staticArrayTasks.length : null }}
        </span>
    </ng-template>
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalCredencialDeAcessoComponent} from './modal-credencial-de-acesso.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {UiModule} from '../../../modules/ui.module';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzBadgeModule} from "ng-zorro-antd/badge";


@NgModule({
    declarations: [ModalCredencialDeAcessoComponent],
    exports: [ModalCredencialDeAcessoComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzUploadModule,
        NzDatePickerModule,
        UiModule,
        NzCheckboxModule,
        NzBadgeModule
    ]
})
export class ModalCredencialDeAcessoModule {
}

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Equipes">
</nz-page-header>
<nz-content>
    <nz-card class="fonte-doze" [nzExtra]="navBar" nzTitle="Equipes" [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true, true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="onAlterarEquipe()" [nzDisabled]="!checkedItems.size" nz-menu-item>
                                    Alterar Equipe
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer"
                  style="font-size: 12px; table-layout:fixed; overflow: auto;">
            <thead>
            <!--            nzColumnKey="nome" [nzSortFn]="true"-->
            <tr class="ant-table-row ng-star-inserted">
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    id="check-all" [ngStyle]="{'width' : '15px'}">
                </th>
                <th nzColumnKey="nome" [nzSortFn]="true" style="width: 230px">
                    <div #column1>
                        Usuários
                    </div>
                </th>
                <th #column2 style="width: 15px;"></th>
                <th nzColumnKey="email" [nzSortFn]="true" style="text-align: center">
                    <div #column4>
                        Email
                    </div>
                </th>
                <th style="text-align: center">
                    <div #column5>
                        Cadastro Data
                    </div>
                </th>
                <th #column6 [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of basicTable.data">
                <tr style="font-size: 12px; margin-top: 10px;" class="ant-table-row ng-star-inserted">
                    <td style="text-align: left;" colspan="2">
                        <strong>{{data.label}}</strong></td>
                    <td [nzExpand]="expandSet.has(data.key)"
                        (nzExpandChange)="onExpandChange(data.key, $event)"
                        [ngStyle]="{
                        'border-left':'none',
                        'border-right':'none',
                        'text-align': 'left',
                        'max-width': '15px;'
                        }"
                    ></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td style="white-space: nowrap;"
                        [ngStyle]="{
                        'white-space' : 'nowrap',
                        'border-left':'none',
                        'border-right':'none',
                        'text-align': 'right'}">

                        <button nz-button
                                nzType="link" *ngIf="data.key !== 'sem_equipe'" (click)="onEditarEquipe(data.key);">
                            Editar
                        </button>
                        <button nz-button
                                (click)="showConfirmEquipe(data.key)"
                                nzType="link" *ngIf="data.key !== 'sem_equipe'">
                            Remover
                        </button>
                    </td>
                </tr>
                <ng-container *ngFor="let usuario of data.itens">
                    <tr *ngIf="usuario.id" [nzExpand]="expandSet.has(data.key)"
                        class="ant-table-row ng-star-inserted" style="font-size: 12px;">
                        <td nzLeft (nzCheckedChange)="onItemChecked(usuario.id, $event)"
                            [nzChecked]="checkedItems.has(usuario.id)"
                            [nzDisabled]="usuario.disabled"
                            [ngStyle]="{'width' : '15px', 'background-color':'transparent', 'padding': '0px'}">
                        </td>
                        <td [ngStyle]="{
                            'width': column1Width + 'px',
                            'min-width': column1Width + 'px',
                            position: 'relative',
                            'word-break': 'break-word',
                            'background-color':'transparent',
                            'text-align' : 'left'
                            }">
                            {{modoDemo(usuario?.nome, 'empresaCodigoNome')}}
                            <nz-tag [nzColor]="'#f50'" *ngIf="!usuario?.ativo"
                                    style="margin-left: 5px; font-size: 10px">
                                Inativo
                            </nz-tag>
                        </td>
                        <td
                        ></td>
                        <td [ngStyle]="{
                            'width': column2Width + 'px',
                            'min-width': column2Width + 'px',
                            position: 'relative',
                            'word-break': 'break-word',
                            'background-color':'transparent',
                            'text-align' : 'left'
                            }"></td>
                        <td [ngStyle]="{
                            'width': column4Width + 'px',
                            'min-width': column4Width + 'px',
                            'word-break': 'break-word',
                            'background-color':'transparent',
                            'text-align' : 'center'
                            }">{{modoDemo(usuario?.email, 'total')}}</td>
                        <td [ngStyle]="{
                            'width': column5Width + 'px',
                            'min-width': column5Width + 'px',
                            'word-break': 'break-word',
                            'background-color':'transparent',
                            'text-align' : 'center'
                            }">{{usuario?.criacao_data | date:'dd/MM/YY - HH:mm:ss'}}</td>

                        <td [ngStyle]="{
                            'width': column6Width + 'px',
                            'min-width': column6Width + 'px',
                            'word-break': 'break-word',
                            'background-color':'transparent',
                            'text-align' : 'right',
                            'padding-right' : '20px'

                            }">
                            <a nz-dropdown [nzDropdownMenu]="menu">
                                <i nz-icon nzType="setting" nzTheme="outline"></i>
                            </a>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li nz-menu-item (click)="alterarEquipeIndividual(usuario.id)">Alterar Equipe</li>
                                    <li nz-menu-item *ngIf="usuario.equipe_id"
                                        (click)="showConfirm(usuario.id, usuario.equipe_id)">
                                        Remover Vínculo
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="displayVisibilidadeModal"
          (nzOnCancel)="handleCancel()"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Equipe" [nzWidth]="648">
    <ng-template nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formAlterarEquipes">
            <nz-select formControlName="equipesSelecionadas"
                       [nzPlaceHolder]="'Selecione as equipes'"
                       nzMode="multiple">
                <nz-option *ngFor="let option of equipeOptions" [nzLabel]="option.label"
                           [nzValue]="option.value"></nz-option>
            </nz-select>
            <label style="margin-top: 5px; margin-left: 310px"
                   formControlName="limparEquipes"
                   nz-checkbox>Limpar as já cadastradas anteriormente</label>
        </form>
    </ng-template>

    <ng-template #modalFooter>
        <button (click)="addNewEquipe()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova equipe
        </button>
        <button (click)="saveBulkToEquipes()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewEquipe()" (nzOnOk)="saveNewEquipe()" [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Equipe">
    <ng-template nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formNovaEquipe">
            <nz-form-item>
                <label for="novaEquipe"></label>
                <input formControlName="nome" id="novaEquipe" nz-input placeholder="Descrição" type="text">
            </nz-form-item>
        </form>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="modalFiltrarVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.nome !== '' && formFiltrar.value.nome !== null">
                    <input nz-input formControlName="nome" type="text" size="60"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Email</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.email !== '' && formFiltrar.value.email !== null">
                    <input nz-input formControlName="email" type="email" size="60"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Login
                </nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.login !== '' && formFiltrar.value.login !== null">
                    <input nz-input type="text" formControlName="login" autocomplete="off" size="60" maxlength="20"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Cadastro Data</nz-form-label>
                <nz-form-control [nzSpan]="6" [nzHasFeedback]="formFiltrar.value.created_at_de">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="created_at_de" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="6"
                                 [nzHasFeedback]="formFiltrar.value.created_at_ate">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="created_at_ate" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalEditarEquipeVisible"
          [nzAutofocus]="null"
          (nzOnCancel)="modalEditarEquipe(false)"
          nzTitle="Editar Equipe">


    <div nz-row *nzModalContent>

        <nz-skeleton [nzLoading]="loadingForm">
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarEquipe" [nzLayout]="'vertical'"
                  style="width: 100%;">
                <input nz-input type="hidden" formControlName="id"/>
                <nz-form-item>
                    <nz-form-label nzRequired>Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <input nz-input type="text" formControlName="nome"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Usuários vinculados</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select [nzPlaceHolder]="'Selecione os usuários'"
                                   nzMode="multiple"
                                   formControlName="usuarios">
                            <nz-option *ngFor="let option of usuarioOptions" [nzLabel]="option.nome"
                                       [nzValue]="option.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-skeleton>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarEquipe(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmarEditarEquipe()" [nzLoading]="loadingEditarEquipe">
            Confirmar
        </button>
    </div>

</nz-modal>

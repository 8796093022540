import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ObrigacoesPrincipaisService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaAnos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/principais/anos`);
    }

    retornaEmpresas(ano: number, filtros: any): any {

        const queryString = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/principais/empresas/${ano}${queryString}`);

    }

    retornaObrigacoes(ano: number, filtros: any): any {

        filtros.ano = ano;

        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/principais/armazenamentos${query}`);
    }

    retornaComposicaoDarf(url: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}${url}`);
    }

    retornaDadosModal(url: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}${url}`);
    }

    downloadModelo(): any {

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/planilha-modelo/darf`,
            {responseType: 'blob'});
    }

    downloadModeloComposicao(): any {

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/planilha-modelo/darfComposicao`,
            {responseType: 'blob'});
    }

    uploadPlanilha(formData: FormData): Observable<any> {

        return this.http.post(`${environment.apiUrl}/drive/obrigacoes/upload-planilha/darf`, formData);
    }

    uploadPlanilhaComposicao(formData: FormData): Observable<any> {

        return this.http.post(`${environment.apiUrl}/drive/obrigacoes/upload-planilha/darfComposicao`, formData);
    }

    retornaArquivos(url: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}${url}`, {responseType: 'blob'});
    }

    /*montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }

        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }*/

    exportExcel(ano: number, filtros): any {

        filtros.ano = ano;
        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/principais/armazenamentos/exportar${query}`,
            {responseType: 'blob'});

    }

    exportDocumento(id, tipoArquivo): any {

        let url = `${environment.apiUrl}/drive/obrigacoes/principais/download/${id}`;

        url += tipoArquivo === 'recibo' ? '/recibo' : '';

        return this.http.get(url, {responseType: 'blob'});

    }

    retornarSelectsEmpresas(grupoEmpId?: string): Observable<any> {

        const url = grupoEmpId ? '/filtros/empresa/select?grupoEmpresarial=' + grupoEmpId : '/filtros/empresa/select';

        return this.http.get(`${environment.apiUrl}${url}`);
    }

    retornarSelectsGrupoEmpresarial(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarSelectsCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);
    }

    retornaCabecalho(ano): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/principais/sumarizar/${ano}`);
    }

    montaOrder(parametros): any {

        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'asc' : 'desc'
                };
            }
        });

        return order;

    }

    retornaComboObrigacoes(empresaId, grupoEmp?: string, categoria?: string): Observable<any> {

        let url = `${environment.apiUrl}/filtros/obrigacoes/select?`;

        if (empresaId) {
            url = `${environment.apiUrl}/filtros/obrigacoes/select/${empresaId}?`;

        } else if (grupoEmp) {
            url += `grupoEmpresarial=${grupoEmp}&`;
        }

        if (categoria) {
            url += `categoria=${categoria}&`;
        }

        return this.http.get(url);

    }

    solicitarExportar(filtros, tipo, ano): any {

        const data = [];

        filtros.forEach((filtro) => {

            data.push({
                key: filtro.key,
                value: filtro.value
            });

        });

        data.push({
            key: 'ano',
            value: ano
        });

        data.push({
            key: 'tipoExportador',
            value: tipo
        });

        data.push({
            key: 'tipo',
            value: 'drivePrincipais'
        });

        data.push({
            key: 'orgao',
            value: 'principais'
        });

        const queryStr = Helpers.montaQueryString(data);

        return this.http.post(`${environment.apiUrl}/exportador${queryStr}`, null);

    }

}

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TransmissoesDetalhesService} from './transmissoesDetalhes.service';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';
import {DataService} from '@services/data.service';
import {Helpers} from '../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-transmissoes-detalhes',
    templateUrl: './transmissoesDetalhes.component.html',
    styleUrls: ['./transmissoesDetalhes.component.scss']
})
export class TransmissoesDetalhesComponent extends AbstractListTable<null> implements OnInit, AfterViewInit {
    @Input() data: any;
    loadingContadores = true;
    dadosCabecalho: any = {};
    formFiltrosCabecalho: FormStack;
    formFiltrosGrid: FormStack;
    arrayMeses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    arraySelectObrigacoes = [];
    arraySelectCategorias = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectGrupos = [];
    arraySelectResponsaveis = [];
    qtdFiltrosAtivos = 0;
    qtdFiltrosAtivosCabecalho = 0;
    currentUser;

    arquivoUrl: string;

    loadings = {
        pdf: false,
        zip: false,
        enviandoParaTransmissao: false,
        exportar: false
    };

    modalPdfVisible = false;

    pdfSelecionado: any;
    pdfSelecionadoNome = '';


    agrupamentoSet = new Set<number>();


    @ViewChild('column1') column1;
    column1Width = 0;
    @ViewChild('column2') column2;
    column2Width = 0;
    @ViewChild('column3') column3;
    column3Width = 0;
    @ViewChild('column4') column4;
    column4Width = 0;
    @ViewChild('column5') column5;
    column5Width = 0;
    @ViewChild('column6') column6;
    column6Width = 0;
    @ViewChild('column7') column7;
    column7Width = 0;
    @ViewChild('column8') column8;
    column8Width = 0;
    loadingsAgrupamentos: any = {};
    subRegistros: any = {};

    mes: string;
    ano: number;
    arraySelectUsuarios = [];

    constructor(
        private fb: UntypedFormBuilder,
        private service: TransmissoesDetalhesService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private el: ElementRef,
        private tabService: TabService,
        private modalSrv: NzModalService,
        private dataService: DataService) {
        super(service, null, toastService);

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                vencimento: [null, null],
                obrigacao: [null, null],
                categoria: [null, null],
            })
        };

        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupo: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                status: [null, null],
                usuario_id: [null, null],
            })
        };

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });

    }

    ngOnInit() {

        if (this.data && this.data.id) {
            this.formFiltrosCabecalho.formGroup.get('obrigacao').setValue(this.data.id);

        }

        if (this.data && this.data.vencimento) {
            const mesAno = this.data.vencimento.split('-');
            const mes = mesAno[1] < 9 ? `0${Number(mesAno[1]) + 1}` : `0${Number(mesAno[1]) + 1}`;
            this.formFiltrosCabecalho.formGroup.get('vencimento').setValue(new Date(`${mesAno[0]}-${mes}-01`));
        }

        if (this.data && this.data.categoria) {
            this.formFiltrosCabecalho.formGroup.get('categoria').setValue(this.data.categoria);

        }

        if (this.data && this.data.status) {
            this.formFiltrosGrid.formGroup.get('status').setValue(this.data.status);

        }

        this.service.retornaSelectsUsuarios().subscribe((retorno: any) => {
            this.arraySelectUsuarios = retorno;
        });


    }

    ngAfterViewInit() {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        this.decodeSearch([this.formFiltrosCabecalho.formGroup, this.formFiltrosGrid.formGroup]);

    }

    decodeSearch(forms) {

        this.retornaSelectObrigacoes(() => {

            forms.forEach((value) => {

                const urlParams: any = this.queryStringToJSON(window.location.href);

                const haveUrlParams = Object.entries(urlParams).length > 0;

                if (urlParams.competencia) {
                    const competencia = urlParams.competencia.split('-');
                    urlParams.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (urlParams.vencimento) {
                    const vencimento = urlParams.vencimento.split('-');
                    urlParams.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                if (haveUrlParams) {
                    this.resolveParams(urlParams, value);
                }

                const dataParams: any = Object.entries(this.data).filter((element) => {
                    return element[0] !== 'link' && element[0] !== 'extra';
                });

                if (this.data.competencia && Object.prototype.toString.call(this.data.competencia) !== '[object Date]') {
                    const competencia = this.data.competencia.split('-');
                    this.data.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (this.data.vencimento && Object.prototype.toString.call(this.data.vencimento) !== '[object Date]') {
                    const vencimento = this.data.vencimento.split('-');
                    this.data.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                const haveDataParams = dataParams.length > 0;

                if (haveDataParams) {
                    this.resolveParams(this.data, value);
                }

                if (!haveUrlParams && !haveDataParams) {

                    const params = {
                        vencimento: new Date(),
                        categoria: this.arraySelectCategorias[0].id
                    };

                    this.resolveParams(params, value);

                }

            });

            this.carregaCabecalho();

        });

    }

    resolveParams(params, form) {

        Object.entries(params).forEach((value: any) => {

            const input = form.get(value[0]);

            if (input) {
                form.get(value[0]).setValue(value[1]);
            }

        });

    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;
    }

    retornaSelectObrigacoes(callback) {

        this.service.retornarSelectsObrigacoes().subscribe((retorno: any) => {

            this.arraySelectObrigacoes = retorno.obrigacoes;
            this.arraySelectCategorias = retorno.categorias;

            callback();

        });

    }

    retornaselectsResponsaveis(vencimento) {

        this.service.retornaselectsResponsaveis(vencimento).subscribe((retorno: any) => {
            this.arraySelectResponsaveis = retorno;
        });

    }

    openTab(componentUrl: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentUrl);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.loading = true;

        this.currentParams = params;

        this.currentSearch = search;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrosGrid.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (vencimento) {
            ano = vencimento.getFullYear();
            mes = this.formatMonth(vencimento.getMonth() + 1);
        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        const baseUrl = `${environment.apiUrl}/check/transmissoes/tabela/${filtro}/${ano}-${mes}`;

        this.abstractService.newListToTable(params, search, baseUrl).subscribe((response) => {

            this.checkedItems.clear();

            this.items = [];


            this.items = response.data ? Object.values(response.data) : [];

            this.items.forEach((value) => {

                if (value.processamentoFim && value.status === 2 && value.processamentoInicio) {
                    value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio, value.processamentoFim);

                } else if (value.status === 1 && value.processamentoInicio) {
                    value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio);

                }
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

        });

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrosGrid !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }


    calculaBadgeFiltrosCabecalho() {

        this.qtdFiltrosAtivosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivosCabecalho++;
                }

            }

        }

        this.cdr.detectChanges();

    }


    formatOne = (percent: number) => `${percent}%`;



    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrarCabecalho(): any {

        this.carregaCabecalho();

        this.fecharModal(this.formFiltrosCabecalho);

        this.queryTable(this.currentParams, this.currentSearch);

    }

    filtrarGrid(): any {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.fecharModal(this.formFiltrosGrid);

    }

    carregaCabecalho(): void {

        this.loadingContadores = true;

        this.calculaBadgeFiltrosCabecalho();

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        if (vencimento) {
            this.ano = vencimento.getFullYear();
            this.mes = this.formatMonth(vencimento.getMonth() + 1);
        }

        this.retornaselectsResponsaveis(this.ano + '-' + this.mes);

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.service.retornarCabecalho(filtro, this.ano + '-' + this.mes).subscribe((response) => {

            this.dadosCabecalho.anoSelecionado = this.ano;
            this.dadosCabecalho.mesSelecionado = this.arrayMeses[Number(this.mes) - 1].toUpperCase();

            this.dadosCabecalho.obrigacao = response.descricao;
            this.dadosCabecalho.orgao = response.orgao;
            this.dadosCabecalho.qtdUnidades = response.unidades;
            this.dadosCabecalho.qtdObrigacoes = response.obrigacoes;
            this.dadosCabecalho.status = response.status;

            this.loadingContadores = false;

        });

        if (this.data) {
            this.queryTable(this.currentParams, this.currentSearch);
            delete this.data;
        }

    }

    modalFiltrar(visible: boolean): void {
        this.formFiltrosGrid.modalVisible = visible;
    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrosGrid.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clearInput(form, emitter, target) {

        const inputEmitter = form.get(emitter);
        const inputTarget = form.get(target);

        if (inputEmitter.value) {
            inputTarget.setValue(null);
        }

    }

    formatMonth(month) {

        if (month <= 9) {

            return '0' + month;

        } else {

            return month;

        }

    }

    onAllChecked(checked: boolean): void {

        this.items.forEach(({checkObrigacaoValidacao_id}) =>
            this.updateCheckedSet(checkObrigacaoValidacao_id, checked));

    }

    filtrarCampo(form, campo, valor) {
        form.formGroup.get(campo).setValue(valor);
        this.queryTable(this.currentParams, this.currentSearch);
    }

    showConfirm(): void {

        const arrayData = [];

        this.items.forEach(i => {
            if (this.checkedItems.has(i.checkObrigacaoValidacao_id) && i.MD5) {
                arrayData.push({MD5: i.MD5, obrigacaoEmpresa_id: i.obrigacaoEmpresa_id});
            }
        });

        this.modalSrv.confirm({
            nzTitle: 'Essa ação irá direcionar os arquivos para a lista de transmissão. Deseja confirmar?',
            nzOkText: 'Ok',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(arrayData)
        });

    }

    reprocessarRegistro(arrayData) {

        this.loadings.enviandoParaTransmissao = true;

        this.service.reprocessarRegistro(arrayData).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);
            this.checkedItems.clear();
            this.loadings.enviandoParaTransmissao = false;

        }, (retorno) => {

            this.loadings.enviandoParaTransmissao = false;
            this.toastrService.error(retorno.error.message);

        });

    }

    downloadPdf() {
        fileSaver.saveAs(this.pdfSelecionado, this.pdfSelecionadoNome);
    }

    modalPdf(visible: boolean): void {
        this.modalPdfVisible = visible;
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }


    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    exportarArquivosEmLote() {

        const mes = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento).getMonth() + 1;

        const data = {
            tipo: 'transmissoes-delivery',
            obrigacao: 'spedicms',
            obrigacaoVencimento: `${this.dadosCabecalho.anoSelecionado}-${mes <= 9 ? '0' + mes : mes}`,
            status: '2',
            tipoExportador: 'zip',
            grupo: this.formFiltrosGrid.formGroup.value.grupo,
            empresa_id: this.formFiltrosGrid.formGroup.value.empresa_id,
        };

        this.loadings.exportar = true;

        this.service.exportarEmLote(data).subscribe((res) => {

            this.loadings.exportar = false;

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('/downloadCenter', '', {});

        }, (res) => {
            this.loadings.exportar = false;
            this.toastService.error(res.error.message);
        });

    }

    onExpandChange(arquivo: any, checked: boolean): void {

        this.subRegistros[arquivo.id] = [];
        if (checked) {
            this.agrupamentoSet.add(arquivo.id);

            this.loadingsAgrupamentos[arquivo.id] = true;
            this.ajustaColunasAgrupamentos();

            const filtro = this.formFiltrosCabecalho.formGroup.value.categoria || this.formFiltrosCabecalho.formGroup.value.obrigacao;

            this.service.retornaAgrupamento(
                filtro, this.ano + '-' + this.mes, arquivo.obrigacaoEmpresa_id, arquivo.ignorarPva)
                .subscribe(res => {
                    this.subRegistros[arquivo.id] = res.data;
                    this.loadingsAgrupamentos[arquivo.id] = false;
                    this.subRegistros[arquivo.id].forEach((value) => {

                        if (value.processamentoFim && value.status === 2 && value.processamentoInicio) {
                            value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio, value.processamentoFim);

                        } else if (value.status === 1 && value.processamentoInicio) {
                            value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio);

                        }
                    });


                }, (res) => {
                    this.loadingsAgrupamentos[arquivo.id] = false;
                });


        } else {
            this.agrupamentoSet.delete(arquivo.id);
        }
    }

    ajustaColunasAgrupamentos(): void {
        setTimeout(() => {
            this.column1Width = (this.column1?.nativeElement?.offsetWidth ?? this.column1.host.nativeElement.offsetWidth) - 8;
            this.column2Width = (this.column2?.nativeElement?.offsetWidth ?? this.column2.host.nativeElement.offsetWidth) + 1;
            this.column3Width = (this.column3?.nativeElement?.offsetWidth ?? this.column3.host.nativeElement.offsetWidth) + 1;
            this.column4Width = (this.column4?.nativeElement?.offsetWidth ?? this.column4.host.nativeElement.offsetWidth) + 1;
            this.column5Width = (this.column5?.nativeElement?.offsetWidth ?? this.column5.host.nativeElement.offsetWidth) + 1;
            this.column6Width = (this.column6?.nativeElement?.offsetWidth ?? this.column6.host.nativeElement.offsetWidth) + 1;
            this.column7Width = (this.column7?.nativeElement?.offsetWidth ?? this.column7.host.nativeElement.offsetWidth) + 1;
            this.column8Width = (this.column8?.nativeElement?.offsetWidth ?? this.column8.host.nativeElement.offsetWidth) - 10;
        }, 500);
    }

}

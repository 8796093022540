import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdministracaoComponent} from './administracao.component';
import {UiModule} from '../ui.module';
import {RouterModule} from '@angular/router';
import {AdministracaoRoutingModule} from './administracao-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTagModule} from 'ng-zorro-antd/tag';


@NgModule({
    declarations: [AdministracaoComponent],
    imports: [
        AdministracaoRoutingModule,
        CommonModule,
        UiModule,
        RouterModule,
        ReactiveFormsModule,
        NzBreadCrumbModule,
        NzPageHeaderModule,
        NzTreeModule,
        NzTypographyModule,
        FormsModule,
        NzSkeletonModule,
        NzTagModule
    ],
    exports: [
        AdministracaoComponent
    ]
})
export class AdministracaoModule {
}

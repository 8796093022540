import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import {DomicilioEletronicoService} from './domicilioEletronico.service';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Usuario} from '@models/usuario.model';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {Obrigacao} from '@models/obrigacao.model';
import {StickyHeaderDirective} from '@components/directive/directive';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-cadastros-domicilioEletronico',
    templateUrl: './domicilioEletronico.component.html',
    styleUrls: ['./domicilioEletronico.component.scss']
})
export class CadastrosDomicilioEletronicoComponent extends AbstractListTable<Usuario>
    implements OnInit, AfterViewInit, TabHandlerInterface {

    dataFederal: any[] = [];
    totaisFederal = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataEstadual: any[] = [];
    totaisEstaduais = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataPrevidenciario: any[] = [];
    totaisPrevidenciario = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };

    dataProcessamento: Date = null;

    description = '';
    currentParams: any = {
        oficiais: {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        },
        calendar: this.abstractService.NzTableQueryParams,
    };
    currentSearch: any = {
        oficiais: '',
        calendar: '',
    };
    loading: any = {
        matrizes: false,
        regimes: false,
        ies: false
    };
    pagination: any = {
        oficiais: Pagination,
        calendar: Pagination,
    };

    offsetTop = 15;
    target: any;

    loadingCardTabsOne = false;
    loadingSugeridasMassa = false;
    tabAtivaOne = 0;
    statusOne = false;

    loadingCardTabsTwo = true;
    tabAtivaTwo = 0;
    statusTwo = false;

    arrayTableVencimentoOficial: any = [];
    arrayTableCalendar = [];
    arrayTableObrigacoes = [];

    formAddEmpresa: FormStack;
    formEditarEmpresa: FormStack;
    formEditarEmpresaMassa: FormStack;

    formCadastrarEmMassa: FormStack;

    heightTableObrigacoes = 0;
    heightTableTabsTwo = 0;
    selectedValue = '';
    arrayAnos: any[] = [];

    showTabsTwo = false;
    decSelecionadaLabel = '';
    decSelecionadaHabilitada = false;
    loadingHabilitandoDesabilitandoDec = false;
    decSelecionada = '';
    arraySelectEmpresa = [];

    @ViewChild('cardWidth') cardWidth: any;

    cardWidthValue;

    comboEmpresas = [];
    comboGrupos = [];

    erro = false;
    mensagem = '';

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    vencimentos = {};

    loadingAdicionarEmpresas = false;
    modalEmpresaEditar = false;

    quantidadeEmpresasInscricoes = 0;

    filtroObrigacoes = {
        orgao: '',
        fonte: ''
    };

    arraySelectIes = [];
    exibirIE = false;
    empresaIeEditar = null;

    listaNaoSelecionadas: any[] = [];
    listaSimSelecionadas: any[] = [];
    selectedNaoSeleciodo: any = [];
    selectedSimSeleciodo: any = [];

    private cadastroAnchor;
    qtdFiltrosAtivos = 0;

    @ViewChild('cadastroAnchor') set content(content: ElementRef) {
        if (content) {
            this.cadastroAnchor = content;
        }
    }

    private cardTabsTwo;

    @ViewChild('cardTabsTwo') set contentCard(content: ElementRef) {
        if (content) {
            this.cardTabsTwo = content;
        }
    }


    loadings: any = {
        insertEmpresas: false,
        modalEditar: false,
        updateEmpresa: false,
        updateEmpresaMassa: false,
        modalEditarMassa: false,
        editarDec: []
    };

    mostrarCndsSugeridas = false;
    formFiltrar: FormStack;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private tabService: TabService,
        private domicilioEletronicoService: DomicilioEletronicoService,
        private i18n: NzI18nService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService) {

        super(domicilioEletronicoService, Obrigacao, toastService);

        this.i18n.setLocale(pt_BR);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                inativo: [null, null],
                desativado: [null, null],
            })
        };

        this.formAddEmpresa = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
                ie: [null, null],
            })
        };

        this.formEditarEmpresa = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, Validators.required],
                empresa_id: [null, [Validators.required]],
                dataInicio: [null, [Validators.required]],
                dataFim: [null, null],
                acesso: [null, null],
                ie: [null, null],
            })
        };

        this.formEditarEmpresaMassa = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formCadastrarEmMassa = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                certidao_id: [null, null],
            })
        };


        this.domicilioEletronicoService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });


    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;

        this.retornaContadoresOrgao();

        this.heightTableObrigacoes = this.target.offsetHeight - (this.target.offsetHeight / 100 * 20);

        this.heightTableTabsTwo = this.target.offsetHeight - (this.target.offsetHeight / 100 * 23);

        this.cardWidthValue = this.cardWidth.nativeElement.offsetWidth;

        this.cdr.detectChanges();

    }

    getComboIe(empresaId: string) {
        this.formAddEmpresa.formGroup.get('ie').setValue(null);
        this.domicilioEletronicoService.retornarSelectsIes(this.decSelecionada, empresaId).subscribe((retorno: any) => {
            this.arraySelectIes = retorno;
        });

    }


    modalAddEmpresa(visible: boolean): void {
        this.formAddEmpresa.modalVisible = visible;
    }

    modalEditarEmpresa(visible: boolean): void {
        this.formEditarEmpresa.modalVisible = visible;
    }

    modalEditarEmpresasMassa(visible: boolean): void {

        this.formEditarEmpresaMassa.formGroup.reset();

        this.formEditarEmpresaMassa.modalVisible = visible;
    }

    clickEvent(card: string) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

            case 'two':
                this.statusTwo = !this.statusTwo;
                break;

        }

    }

    changeTabs(event: any, card: string) {

        this.checkedItems = new Set<string>();

        switch (card) {

            case 'one':

                this.tabAtivaOne = event.index;

                if (this.tabAtivaOne === 0) {
                    this.showTabsTwo = false;
                }

                if (this.tabAtivaOne === 1 && this.arrayTableObrigacoes.length === 0) {
                    this.loadingCardTabsOne = true;
                    this.retornaDecs();
                }

                break;

            case 'two':

                this.tabAtivaTwo = event.index;
                this.loadingCardTabsTwo = true;

                break;

        }

    }

    onAllChecked(checked: boolean): void {

        if (this.tabAtivaTwo === 0) {
            this.arrayTableVencimentoOficial.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
        }

        if (this.tabAtivaTwo === 1) {
            this.arrayTableCalendar.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);

        this.tabService.addTab(newTab);

    }

    retornaDecs() {

        this.domicilioEletronicoService.retornaDecs(this.filtroObrigacoes).subscribe((retorno: any) => {

            this.arrayTableObrigacoes = retorno;

            if (this.tabAtivaOne === 1) {
                this.loadingCardTabsOne = false;
            }

        }, error => {
            this.loadingCardTabsOne = false;
            this.toastService.error(error.error.message);
        });

    }

    retornaContadoresOrgao() {

        this.loadingCardTabsOne = true;

        this.domicilioEletronicoService.retornaContadoresOrgao().subscribe((response: any) => {

                const retorno = response.sumario;

                this.dataProcessamento = response.dataProcessamento;

                // Dados Orgão Federal
                const federal = [];
                this.totaisFederal.empresasCadastradas = 0;
                this.totaisFederal.cadastradas = 0;
                this.totaisFederal.estimadas = 0;

                Object.entries(retorno.Federal).forEach(([key, value]) => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = obj.tipo;
                        // obj.certidoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        federal.push(obj);

                        this.totaisFederal.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisFederal.cadastradas += obj.certidoes.cadastradas;
                        this.totaisFederal.estimadas += obj.certidoes.estimadas;
                    }
                });

                this.dataFederal = federal;

                // Dados Orgão Estadual
                this.totaisEstaduais.empresasCadastradas = 0;
                this.totaisEstaduais.cadastradas = 0;
                this.totaisEstaduais.estimadas = 0;
                const estadual = [];

                Object.entries(retorno.Estadual).forEach(([key, value]) => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = key;
                        // obj.certidoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        estadual.push(obj);

                        this.totaisEstaduais.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisEstaduais.cadastradas += obj.certidoes.cadastradas;
                        this.totaisEstaduais.estimadas += obj.certidoes.estimadas;
                    }
                });

                this.dataEstadual = estadual;

                // // Dados Orgão Previdenciáios
                this.totaisPrevidenciario.empresasCadastradas = 0;
                this.totaisPrevidenciario.cadastradas = 0;
                this.totaisPrevidenciario.estimadas = 0;
                const previdenciario = [];

                Object.entries(retorno.Municipal).forEach(([key, value]) => {
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = obj.tipo;
                        // obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        previdenciario.push(obj);

                        this.totaisPrevidenciario.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisPrevidenciario.cadastradas += obj.certidoes.cadastradas;
                        this.totaisPrevidenciario.estimadas += obj.certidoes.estimadas;
                    }
                });

                this.dataPrevidenciario = previdenciario;

                this.loadingCardTabsOne = false;

            }
        );

    }

    retornaAnosDecs(decId, decDescricao) {

        this.selectedValue = new Date().getFullYear().toString();

        this.decSelecionada = decId;
        this.decSelecionadaLabel = decDescricao;

        this.showTabsTwo = true;

        this.scrollContainer();

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTabsTwo.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 800);

    }

    listByTableRegras(params: NzTableQueryParams, search: string, scroll: boolean) {
        let buscar = false;

        if (params.pageIndex === this.pagination.oficiais.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.oficiais.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.oficiais.current_page) {
            this.retornaEmpresasDec(params, search, scroll);
            this.pagination.oficiais.current_page = params.pageIndex;
        }

    }

    retornaEmpresasDec(params: NzTableQueryParams, search: string = null, scroll = false) {

        this.retornaDecs();

        this.loadingCardTabsTwo = true;

        this.currentParams.oficiais = params;

        this.currentSearch.oficiais = search;

        this.checkedItems = new Set<string>();

        const url = environment.apiUrl + '/domicilio/domicilioEletronicoEmpresa/' + this.decSelecionada;

        this.calculaBadgeFiltros();

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.arrayTableVencimentoOficial = response.data;

            this.pagination.oficiais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadingCardTabsTwo = false;

            this.checked = false;

            this.resetCheckedSet();

            if (scroll) {
                this.scrollContainer(120);
            }

            this.loadings.editarDec = [];

        }, error => {
            this.loadingCardTabsTwo = false;
            this.toastService.error(error.error.message);
            this.loadings.editarDec = [];
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor && chave !== 'desativado') {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    editarDomicilio(dec: { id: string, descricao: string, ie: string, habilitado: number }, temAgenda = null) {
        this.loadings.editarDec.push(dec.id);
        if (dec.ie) {
            this.empresaIeEditar = dec.ie;
        }

        this.decSelecionadaHabilitada = dec.habilitado === 1;
        this.retornaAnosDecs(dec.id, dec.descricao);
        this.retornaComboEmpresas(dec.id);
        this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, true);
    }

    insertEmpresas(): void {
        if (this.formAddEmpresa.formGroup.valid) {

            this.loadings.insertEmpresas = true;

            const dados = this.formAddEmpresa.formGroup.value;

            Object.entries(dados).forEach(([key, value]) => {
                    if (value) {
                        const data: any = value;
                        if (typeof data.getMonth === 'function') {
                            dados[key] = this.domicilioEletronicoService.formataDateTimeBD(data) + ':00';
                        }
                    }
                }
            );

            dados.domicilioEletronico_id = this.decSelecionada;

            this.domicilioEletronicoService.insertDomicilioEmpresa(dados).subscribe((response: any) => {

                    this.toastrService.success('Empresa cadastrada com sucesso!');
                    this.formAddEmpresa.formGroup.reset();

                    this.modalAddEmpresa(false);
                    this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, true);
                    this.loadings.insertEmpresas = false;

                }, (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadings.insertEmpresas = false;
                }
            );
        } else {
            Object.values(this.formAddEmpresa.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    showConfirm(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) Empresa(s)?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerUnica(id)
        });

    }

    showConfirmMassa(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) Empresa(s)?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerMassa()
        });

    }

    showConfirmAdicionarDecs(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja adicionar as DECs Sugeridos?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.adicionarDecsSugeridas()
        });

    }

    removerUnica(id) {


        this.domicilioEletronicoService.removeEmpresaUnica(id).subscribe((response: any) => {

            this.toastrService.success('Empresa(s) removida(s) com sucesso!');

            this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, false);

            this.retornaDecs();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarEmpresas = false;

        });

    }

    removerMassa() {


        this.domicilioEletronicoService.removeEmpresasMassa(Array.from(this.checkedItems)).subscribe((response: any) => {

            this.toastrService.success('Empresa(s) removida(s) com sucesso!');

            this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, false);

            this.retornaDecs();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarEmpresas = false;

        });

    }

    adicionarDecsSugeridas() {

        this.loadingAdicionarEmpresas = true;

        this.domicilioEletronicoService
            .adicionarDecsSugeridas(this.decSelecionada).subscribe((response: any) => {

            this.loadingAdicionarEmpresas = false;

            this.toastrService.success('Empresas adicionadas com sucesso!');

            this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, false);

            this.retornaDecs();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarEmpresas = false;

        });

    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
    }

    reloadCardTwo() {
        if (this.tabAtivaTwo === 0) {
            this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais);
        }
    }

    filtrarOrgao(orgao: string, fonte: string = null): void {

        this.exibirIE = orgao === 'Estadual';

        this.filtroObrigacoes.orgao = orgao;

        this.filtroObrigacoes.fonte = fonte;

        this.tabAtivaOne = 1;

        this.showTabsTwo = false;

        this.loadingCardTabsOne = true;

        if (this.arrayTableObrigacoes.length > 0) {
            this.retornaDecs();
        }

    }

    limparFiltros(): void {
        this.filtrarOrgao(null, null);
    }

    retornaComboEmpresas(domicilio: string = null): void {
        this.domicilioEletronicoService.retornaComboEmpresas(domicilio).subscribe((retorno: any) => {
            this.comboEmpresas = [];
            this.comboEmpresas = retorno;
        });
    }

    modalCadastrarEmMassa(visible: boolean): void {

        if (visible) {
            this.formCadastrarEmMassa.formGroup.get('empresa_id').setValue('todas');
            this.formCadastrarEmMassa.formGroup.get('certidao_id').setValue('todas');
        }

        this.formCadastrarEmMassa.modalVisible = visible;

    }

    cadastrarSugeridasMassa(): void {

        const dados = this.formCadastrarEmMassa.formGroup.value;

        if (dados.empresa_id !== 'todas' && this.listaSimSelecionadas.length <= 0) {
            this.toastrService.error('Ao menos uma Certidão deve ser selecionada.');
            return;
        }

        if (this.listaSimSelecionadas.length > 0) {

            dados.domicilioEletronico_id = [];

            this.listaSimSelecionadas.forEach(value => {
                dados.domicilioEletronico_id.push(value.domicilioEletronico_id);
            });

        }

        this.loadingSugeridasMassa = true;

        this.modalCadastrarEmMassa(false);

        this.domicilioEletronicoService.cadastrarSugeridasMassa(dados).subscribe((response) => {
            this.toastrService.success('DECs Cadastrados com Sucesso.');
            this.retornaContadoresOrgao();
            this.loadingSugeridasMassa = false;
        }, (res) => {
            this.toastrService.error(res.error.message);
            this.loadingSugeridasMassa = false;
        });


    }

    clickNaoSelecionado(id: any): void {
        if (this.selectedNaoSeleciodo.includes(id)) {
            this.selectedNaoSeleciodo.splice(this.selectedNaoSeleciodo.indexOf(id), 1);
        } else {
            this.selectedNaoSeleciodo.push(id);
        }

    }

    clickSimSelecionado(id: any): void {
        if (this.selectedSimSeleciodo.includes(id)) {
            this.selectedSimSeleciodo.splice(this.selectedSimSeleciodo.indexOf(id), 1);
        } else {
            this.selectedSimSeleciodo.push(id);
        }
    }

    acaoDobleList(tipoAcao: string): void {

        switch (tipoAcao) {
            case 'right': {

                this.selectedNaoSeleciodo.forEach((selecionado) => {
                    this.listaNaoSelecionadas.forEach((obrigacao, index) => {
                        if (obrigacao.domicilioEletronico_id === selecionado) {
                            this.listaSimSelecionadas.push(this.listaNaoSelecionadas[index]);
                            this.listaNaoSelecionadas.splice(index, 1);
                        }
                    });
                });
                break;
            }
            case 'double-right' : {
                this.listaNaoSelecionadas.forEach((value, index) => {
                    this.listaSimSelecionadas.push(value);
                });
                this.listaNaoSelecionadas = [];
                break;
            }
            case 'left' : {
                this.selectedSimSeleciodo.forEach((selecionado) => {
                    this.listaSimSelecionadas.forEach((obrigacao, index) => {
                        if (obrigacao.domicilioEletronico_id === selecionado) {
                            this.listaNaoSelecionadas.push(this.listaSimSelecionadas[index]);
                            this.listaSimSelecionadas.splice(index, 1);
                        }
                    });
                });
                break;
            }
            case 'double-left' : {
                this.listaSimSelecionadas.forEach((value, index) => {
                    this.listaNaoSelecionadas.push(value);
                });
                this.listaSimSelecionadas = [];
                break;
            }
        }

        this.listaSimSelecionadas.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.listaNaoSelecionadas.sort((a, b) => (a.id > b.id) ? 1 : -1);

        this.selectedNaoSeleciodo = [];
        this.selectedSimSeleciodo = [];
    }


    onEditarDecEmpresa(id: string, ie: string): void {

        this.empresaIeEditar = ie;

        this.loadings.modalEditar = true;

        this.modalEditarEmpresa(true);

        this.domicilioEletronicoService.retornaDecEmpresa(id).subscribe((res) => {

            this.formEditarEmpresa.formGroup.reset();
            this.formEditarEmpresa.formGroup.get('id').setValue(res.id);

            this.formEditarEmpresa.formGroup.get('empresa_id').setValue(res.empresa_id);
            this.formEditarEmpresa.formGroup.get('empresa_id').disable();

            if (res.dataInicio) {
                const dataInicio = new Date(res.dataInicio);
                dataInicio.setDate(dataInicio.getDate() + 1);
                this.formEditarEmpresa.formGroup.get('dataInicio').setValue(dataInicio);
            }

            if (res.dataFim) {
                const dataFim = new Date(res.dataFim);
                dataFim.setDate(dataFim.getDate() + 1);
                this.formEditarEmpresa.formGroup.get('dataFim').setValue(dataFim);
            }

            this.formEditarEmpresa.formGroup.get('acesso').setValue(res.acesso);

            this.loadings.modalEditar = false;

        }, (res) => {
            this.toastService.error(res.error.message);
            this.modalEditarEmpresa(false);
            this.loadings.modalEditar = false;
        });

    }

    updateEmpresas(): void {

        if (this.formEditarEmpresa.formGroup.valid) {

            this.formEditarEmpresa.formGroup.get('ie').setValue(this.empresaIeEditar);

            const dados = this.formEditarEmpresa.formGroup.value;

            Object.entries(dados).forEach(([key, value]) => {
                if (value) {
                    const data: any = value;
                    if (typeof data.getMonth === 'function') {
                        dados[key] = this.domicilioEletronicoService.formataDateTimeBD(data) + ':00';
                    }
                }
            });

            this.loadings.updateEmpresa = true;

            this.domicilioEletronicoService.updateDomicilioEmpresa(dados.id, dados).subscribe(res => {
                this.toastService.success('Dados alterados com sucesso');
                this.loadings.updateEmpresa = false;
                this.modalEditarEmpresa(false);
                this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, true);
                this.formEditarEmpresa.formGroup.reset();
            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.updateEmpresa = false;
            });

        } else {
            Object.values(this.formEditarEmpresa.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    updateEmpresasMassa(): void {

        if (this.formEditarEmpresaMassa.formGroup.valid) {
            const dados = this.formEditarEmpresaMassa.formGroup.value;

            Object.entries(dados).forEach(([key, value]) => {
                if (value) {
                    const data: any = value;
                    if (typeof data.getMonth === 'function') {
                        dados[key] = this.domicilioEletronicoService.formataDateTimeBD(data) + ':00';
                    }
                }
            });

            dados.empresas = Array.from(this.checkedItems);

            this.loadings.updateEmpresaMassa = true;


            this.domicilioEletronicoService.updateDomicilioEmpresasMassa(dados).subscribe(res => {
                this.toastService.success('Dados alterados com sucesso');
                this.loadings.updateEmpresaMassa = false;
                this.modalEditarEmpresasMassa(false);
                this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais, true);
                this.formEditarEmpresaMassa.formGroup.reset();
            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.modalEditarEmpresasMassa = false;
            });


        } else {
            Object.values(this.formEditarEmpresaMassa.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    changeSelectCnds(empresaId) {

        if (empresaId) {

            this.mostrarCndsSugeridas = true;

            this.domicilioEletronicoService.retornarSelectsSugeridas(empresaId).subscribe((retorno: any) => {
                this.listaNaoSelecionadas = [];
                Object.entries(retorno).forEach(value => {
                    this.listaNaoSelecionadas.push(value[1]);
                });

            });

        } else {

            this.mostrarCndsSugeridas = false;

        }
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.oficiais.filter = [];

        this.currentParams.oficiais.pageIndex = 1;

        this.formFiltrar.formGroup.get('desativado').setValue(this.formFiltrar.formGroup.get('inativo').value);

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.oficiais.filter.push({key: chave, value: valor});
            }
        }

        this.retornaEmpresasDec(this.currentParams.oficiais, this.currentSearch.oficiais);

        this.modalFiltrar(false);

    }

    habilitaObrigacao(): void {

        this.loadingHabilitandoDesabilitandoDec = true;

        const dados = {
            habilitado: this.decSelecionadaHabilitada
        };

        this.domicilioEletronicoService.updateDomicilioEletronico(this.decSelecionada, dados).subscribe({
            next: (res) => {
                this.loadingHabilitandoDesabilitandoDec = false;
                this.toastService.success(res.message);
            },
            error: (err) => {
                this.decSelecionadaHabilitada = !this.decSelecionadaHabilitada;
                this.loadingHabilitandoDesabilitandoDec = false;
                this.toastService.error(err.error.message);
            }
        });

    }

}

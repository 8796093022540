import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})

export class TasksService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/tasks', {});
    }

    deleteTasks(tasksId = null): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/notificacoes/remover/${tasksId}`);
    }

    deleteAllTasks(usuarioId): Observable<any> {

        const data = {
            usuario_id: usuarioId,
            tipo: 'processamento'
        };

        return this.http.post(`${environment.apiUrl}/notificacoes/remover`, data);

    }

}

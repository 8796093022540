<Navbar-List (generalFilterHandler)="getGeneralSearch($event)" (resetForm)="resetSearch()"
             [registerComponent]="'RegisterProfileComponent'" [registerLink]="registerLink"
             [tabTitle]="'Cadastrar Perfil'">
    <div class="nav-item" menuOpcoes>
        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                    </ul>
                </li>
                <li nz-menu-item>Importar para Excel</li>
                <!--                <li (click)="exportUsers()" nz-menu-item>Exportar para Excel</li>-->
            </ul>
        </nz-dropdown-menu>
    </div>
<!--    <User-Filter (handleFilterEvent)="getFilterParams($event)" filtro></User-Filter>-->
</Navbar-List>
<div>
    <nz-table #basicTable
              (nzPageIndexChange)="onUpdateList($event)"
              [nzData]="items"
              [nzFrontPagination]="false"
              [nzLoadingDelay]="150"
              [nzLoading]="loading"
              [nzPageIndex]="pagination?.current_page"
              [nzPageSize]="pagination?.per_page"
              [nzTotal]="pagination?.total"
              nzPaginationPosition="bottom"
              nzSize="small">
        <thead>
        <tr>
            <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                id="check-all"></th>
            <th (click)="orderBy(i)" *ngFor="let title of tableTitles; let i = index" [id]="i">
                <em *ngIf="title.isDesc" nz-icon nzTheme="outline" nzType="arrow-up"></em>
                <em *ngIf="!title.isDesc" nz-icon nzTheme="outline" nzType="arrow-down"></em>
                {{ title.name }}
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
            <td (nzCheckedChange)="onItemChecked(data.id, $event)"
                [nzChecked]="checkedItems.has(data.id)"
                [nzDisabled]="data.disabled">
            </td>
            <td>{{data?.descricao}}</td>
            <td class="text-right">
                <button (click)="openTab('DetailProfileComponent',data.id,{id: data.id})"
                        nz-button nzType="default">Editar
                </button>
            </td>
        </tr>
        </tbody>
    </nz-table>
</div>

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {NotifyService} from '@components/notify/notify.service';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {TasksService} from '@components/tasks/tasks.service';

@Component({
    selector: 'tasks-app',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent extends AbstractService<any> implements OnInit {

    get staticArrayTasks(): any {
        return TasksComponent.arrayTasks;
    }

    get staticUserAdmin(): any {
        return TasksComponent.userAdmin;
    }

    get staticConnected(): any {
        return TasksComponent.connected;
    }

    get staticUltimaAtualizacao(): any {
        return TasksComponent.ultimaAtualizacao;
    }

    constructor(private router: Router,
                private dataService: DataService,
                public http: HttpClient,
                private toastService: ToastrService,
                private tabService: TabService,
                private tasksService: TasksService) {
        super(http, '/tasks', {});

        TasksComponent.http = this.http;

    }

    private static http: HttpClient;
    private static toastrService: ToastrService;
    private static currentUserId: any;
    private static arrayTasks = [];
    private static userAdmin: boolean;
    private static connected: boolean;
    private static ultimaAtualizacao: Date;

    static initChanels(): void {

        const paramsUsuario = NotifyService.retornaParametrosUsuario();

        this.currentUserId = paramsUsuario.currentUserId;
        this.userAdmin = paramsUsuario.userAdmin;
        this.connected = paramsUsuario.connected;

        this.retornaTasks();

        this.connectProcessPrivateChannel(paramsUsuario.currentUserId, paramsUsuario.identificador);

    }

    static connectProcessPrivateChannel(currentUserId, identificador) {

        window.Echo.private(`processamento-usuario-${currentUserId}-${identificador}`).listen(`NotificacaoPrivada`, (e) => {

            if (e.message.titulo && e.message.mensagem && e.message.id) {

                TasksComponent.tasksAdd(e);

            }

        });

    }

    static tasksAdd(e) {

        const dataNotificacao = new Date(e.message.created_at);

        e.message.diasNotificacao = TasksComponent.retornaQuantidadeDias(dataNotificacao, new Date());

        const temMensagem = this.arrayTasks.filter((element) => {
            return element.id === e.message.id;
        });

        if (temMensagem.length > 0) {

            this.arrayTasks.forEach((value) => {

                if (temMensagem[0].id === value.id) {

                    value.titulo = e.message.titulo;

                    value.mensagem = e.message.mensagem;

                    value.statusProcessamento = e.message.statusProcessamento;

                    value.updated_at = e.message.updated_at;

                    value.actions = e.message.actions;

                    value.widget = e.message.widget;

                    value.widget.forEach((valueTwo) => {

                        if (valueTwo.tipo === 'progress_bar') {

                            const progresso = valueTwo.progresso.split('/');

                            valueTwo.progressoMin = Number(progresso[0]);

                            valueTwo.progressoMax = Number(progresso[1]);

                            valueTwo.progressoPorc = Math.round(this.getPercentage(valueTwo.progressoMin, valueTwo.progressoMax));

                        }

                    });

                }

            });

        } else {

            this.arrayTasks.unshift(e.message);

            // this.toastService.info('Você tem uma nova atividade.');

        }

        this.ultimaAtualizacao = new Date();

    }

    static retornaQuantidadeDias(date1, date2) {

        const DifferenceInTime = date2.getTime() - date1.getTime();

        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

        return Math.round(DifferenceInDays);

    }

    static retornaTasks() {

        this.getTasks(TasksComponent.currentUserId).subscribe((response: any) => {

            if (response){
                response.forEach((value) => {

                    let json;

                    if (typeof value.mensagemJson === 'string' || value.mensagemJson instanceof String) {

                        json = JSON.parse(value.mensagemJson);

                    } else {

                        json = value.mensagemJson;

                    }

                    json.created_at = value.created_at;

                    json.updated_at = value.updated_at;

                    // value.statusProcessamento = json.statusProcessamento;

                    if (json.widget){
                        json.widget.forEach((valueTwo) => {

                            if (valueTwo.tipo === 'progress_bar') {

                                const progresso = valueTwo.progresso.split('/');

                                valueTwo.progressoMin = Number(progresso[0]);

                                valueTwo.progressoMax = Number(progresso[1]);

                                valueTwo.progressoPorc = Math.round(this.getPercentage(valueTwo.progressoMin, valueTwo.progressoMax));

                            }

                        });
                    }

                    json.id = value.id;

                    const dataNotificacao = new Date(value.created_at);

                    json.diasNotificacao = TasksComponent.retornaQuantidadeDias(dataNotificacao, new Date());

                    TasksComponent.arrayTasks.push(json);

                });
            }

            TasksComponent.arrayTasks.reverse();

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    static getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    static getTasks(currentUserId) {
        return this.http.get(`${environment.apiUrl}/notificacoes/listar?tipo=processamento&usuario_id=` + currentUserId);
    }

    ngOnInit(): void {
        this.atualizadoEm();
    }

    public tasksDimiss(tasksId = null): void {

        this.tasksService.deleteTasks(tasksId).subscribe((response: { message: string }) => {

            const message = response.message;

            this.toastService.success(message);

            TasksComponent.arrayTasks = this.staticArrayTasks.filter((element) => {
                return element.id !== tasksId;
            });

        }, (response) => {

            this.toastService.error(response.error.message);

        });

    }

    atualizadoEm() {

        TasksComponent.ultimaAtualizacao = new Date();

        setTimeout(() => {

            this.atualizadoEm();

        }, 60000);

    }

    generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    openTab(path: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

    }

    formatOne = (percent: number) => `${percent}%`;

    removerTasks() {

        this.tasksService.deleteAllTasks(TasksComponent.currentUserId).subscribe((response) => {

            const message = response.message;

            this.toastService.success(message);

            TasksComponent.arrayTasks = [];

        }, (response) => {

            this.toastService.error(response.error.message);

        });

    }

}

import {
    Component,
    OnInit
} from '@angular/core';

const PREFIX = '--';

@Component({
    selector: 'sass-helper',
    template: '<div></div>'
})
export class SassHelperComponent implements OnInit {

    static readProperty(name: string): string {
        const bodyStyles = window.getComputedStyle(document.body);
        return bodyStyles.getPropertyValue(PREFIX + name);
    }

    ngOnInit(): void {

    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MonitorArquivosTransitoriosComponent} from './arquivos-transitorios/arquivos-transitorios.component';
import {TransitoriosComponent} from './transitorios.component';

const routes: Routes = [
    {
        path: '',
        component: TransitoriosComponent,
    },
    {
        path: 'upload',
        component: MonitorArquivosTransitoriosComponent

    }, {
        path: 'agent',
        component: MonitorArquivosTransitoriosComponent
    }, {
        path: 'edi',
        component: MonitorArquivosTransitoriosComponent
    }];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ]
})

export class TransitoriosRoutingModule {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListLogComponent } from './pages/list/list-log.component';
import { LogRoutingModule } from './log-routing.module';
import { NgZorroModule } from '../../shared/ng-zorro.module';
import { LogComponent } from './pages/log.component';


@NgModule({
    declarations: [
        LogComponent,
        ListLogComponent
    ],
    imports: [
        CommonModule,
        LogRoutingModule,
        NgZorroModule,
    ]
})
export class LogModule {
}

import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-perfils',
    templateUrl: './perfis.component.html',
    styleUrls: ['./perfis.component.scss']
})
export class PerfisComponent extends AbstractListTable<Perfil> implements OnInit, TabHandlerInterface {

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    constructor(
        private fb: UntypedFormBuilder,
        private perfilService: PerfilService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(perfilService, Perfil, toastService);
    }

    ngOnInit() {

    }

    handleCancel() {
    }


    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    confirmaCadastro() {
        this.cadastrando = true;

        const dadosPerfil = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosPerfil[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.perfilService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Perfil cadastrado com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/perfil/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.formCadastrar = this.fb.group({
                descricao: [null, [Validators.required]]
            });

        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    showConfirmRemover(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Perfil?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    remover(id: string): void {

        this.loading = true;
        this.perfilService.remover(id).subscribe(
            (response) => {
                this.toastrService.success('Perfil removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loading = false;

            }
        );
    }

}

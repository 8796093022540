<nz-page-header class="site-page-header" nzTitle="{{pageTitle}}"
                nzSubtitle="Despachante de Notificações">
    <nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" [nzGhost]="showForm !== 'PUB'"
                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                    (click)="changeNotifyTipe('PUB')">
                Pública
            </button>
            <button nz-button nzType="primary" [nzGhost]="showForm !== 'PRI'"
                    class="ant-btn ant-btn-primary"
                    (click)="changeNotifyTipe('PRI')">
                Privada
            </button>
            <button nz-button nzType="primary" [nzGhost]="showForm !== 'PRO'"
                    style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;"
                    class="ant-btn ant-btn-primary"
                    (click)="changeNotifyTipe('PRO')">
                Processamento
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-row nzJustify="center">
        <nz-col nzSpan="12">
            <nz-card>
                <div style="padding: 30px 0;">
                    <form nz-form [formGroup]="validateForm" (ngSubmit)="enviarNotificacao()">
                        <nz-form-item *ngIf="showForm === 'PRI' || showForm === 'PRO'">
                            <nz-form-label [nzSpan]="7" nzRequired>Usuário</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Selecione o Usuário!">
                                <nz-select nzShowSearch
                                           nzAllowClear
                                           nzPlaceHolder="Selecione"
                                           formControlName="usuario_id"
                                           style="margin-bottom: 0;">
                                    <nz-option *ngFor="let opt of arraySelectUsuarios"
                                               [nzLabel]="opt.nome"
                                               [nzValue]="opt.id"
                                    ></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="7" nzRequired>Titulo</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Preencha o Título!">
                                <input nz-input formControlName="titulo" placeholder="Digite o Titulo...">
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="7" nzRequired>Mensagem</nz-form-label>
                            <nz-form-control [nzSpan]="12" nzErrorTip="Preencha a Mensagem!">
                            <textarea formControlName="mensagem" nz-input rows="2"
                                      placeholder="Digite sua Mensagem..."></textarea>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item style="margin-bottom: 0;">
                            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                                <button nz-button nzType="primary" [disabled]="!validateForm.valid"
                                        style="margin-right: 10px;">
                                    Enviar
                                </button>
                                <button nz-button (click)="limparForm()">Limpar</button>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {CheckNotasDetalhesService} from './checkNotasDetalhes.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthenticationService} from '../../../core/auth/authentication.service';
import {Helpers} from '../../../core/helpers';
import {NzModalService} from 'ng-zorro-antd/modal';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-spedNotasDetalhes',
    templateUrl: './checkNotasDetalhes.component.html',
    styleUrls: ['./checkNotasDetalhes.component.scss']
})
export class CheckNotasDetalhesComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @Output() generalFilterHandler = new EventEmitter<string>();

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    HighchartsSolidGauge: typeof Highcharts = Highcharts;

    chartSolidGaugeOptions: Highcharts.Options;
    chartColumnOptions: Highcharts.Options;

    updateFlag: boolean;

    selectedValue = new Date('2017-01-25');

    dadosAnalise: any = {};
    contadores: any = {};

    divergencias: any[] = [];

    formFiltrar: FormStack;

    formExportar: FormStack;

    pesquisar = '';
    comboUFs: any = [];

    cardNotaVisible = true;
    cardQuebraVisible = false;

    quebras: any[] = [];

    stringStatus = {
        1: 'Escrituradas Corretamente',
        2: 'Divergências',
        3: 'XMLs Ausentes',
        4: 'XMLs Fora do SPED',
        5: 'Escrituradas Indevidamente',
        6: 'NFs somente na SEFAZ',
        7: 'Divergência - Chave',
        8: 'Divergência - Série',
        9: 'Divergência - Tipo',
        10: 'Divergência - Oper. Documento',
        11: 'Divergência - Oper. Exrituração',
        12: 'Divergência - Modelo',
        13: 'Divergência - Situação, Duplicidade',
        14: 'Divergência - Documento',
        15: 'Divergência - Data, Data Emissão',
        16: 'Divergência - Valor, Valor IPI, Valor ICMS',
        18: 'Desconsideradas',
        52: 'Não escrituradas - ok',
        53: 'Não escrituradas',
        55: 'Quebra de Sequência',
    };
    objDescricaoStatus: any[] = [];

    stringModelo = {
        55: 'NFe (55)',
        57: 'CTe (57)',
        59: 'CFE (59)',
        65: 'NFCe (65)'
    };

    iframeUrl: SafeResourceUrl = '';
    isVisible = false;

    loadings = {
        contadores: true,
        cabecalho: true,
        ignoradas: false,
        removerDasIgnoradas: false,
        ignorando: false
    };

    currentParamsIgnoradas = {
        pageIndex: 1,
        pageSize: 1,
        sort: [],
        filter: []
    };

    checkedItems = new Set<string>();
    checked = false;

    modalNotasIgnoradasVisible = false;
    notasIgnoradas = [];

    formIgnorar: FormStack;

    msgModalIgnorar: string;

    paginationIgnoradas = new Pagination();

    statusCard = false;

    loadingExportar = false;

    qtdFiltrosAtivos = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private service: CheckNotasDetalhesService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        public sanitizer: DomSanitizer,
        private authService: AuthenticationService,
        private modalService: NzModalService) {

        super(usuarioService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                notaDe: [null, null],
                notaAte: [null, null],
                modelo: [null, null],
                serie: [null, null],
                emissaoTipo: [null, null],
                tipoDocumento: [null, null],
                tipoEscrituracao: [null, null],
                nome: [null, null],
                uf: [null, null],
                situacao: [null, null],
                dataDe: [null, null],
                dataAte: [null, null],
                entradaDataDe: [null, null],
                entradaDataAte: [null, null],
                valorDe: [null, null],
                valorAte: [null, null],
                icmsValorDe: [null, null],
                icmsValorAte: [null, null],
                ipiValorDe: [null, null],
                ipiValorAte: [null, null],
                natureza: [null, null],
                chave: [null, null],
                competencia: [null, null],
                status: [null, null],
                tipoDivergencia: [null, null],
                valorDivergencia: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formIgnorar = {
            modalVisible: false,
            formGroup: this.fb.group({
                motivo: [null, Validators.required],
            })
        };

        this.service.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

        Object.entries(this.stringStatus).forEach((index, value) => {
            const obj = {value: index[0], label: index[1]};
            this.objDescricaoStatus.push(obj);

        });

    }

    ngOnInit() {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.getNotasIgnoradas(this.currentParamsIgnoradas, true);
        this.retornaCabecalho();
        this.retornaQuebras();

    }

    retornaQuebras(): void {
        this.service.retornaQuebras(this.data.id).subscribe((response) => {
            this.quebras = [];
            this.quebras = response.data;

        });
    }

    retornaCabecalho(): void {

        this.loadings.cabecalho = true;

        this.service.retornaCabecalho(this.data.id).subscribe((response) => {
            this.dadosAnalise = response;
            this.loadings.cabecalho = false;
            this.retornaContadores();
        }, error => {
            this.loadings.cabecalho = false;
            this.toastService.error(error.error.message);
        });

    }

    retornaContadores(): void {

        this.loadings.contadores = true;

        this.service.retornaContadores(this.data.id).subscribe((response) => {
            this.contadores = response;

            this.divergencias = [];
            Object.entries(response.status_dv.divergencias).forEach(value => {
                const valor: any = value;
                const obj: any = {};
                obj.chave = valor[0];
                obj.label = valor[1].label;
                obj.qtd = valor[1].qtd;
                this.divergencias.push(obj);
            });


            this.loadings.contadores = false;
        }, error => {
            this.toastService.error(error.error.message);
            this.loadings.contadores = false;
        });

    }


    loadChartData(): void {

        this.chartColumnOptions = {
            chart: {
                type: 'column'
            },
            title: undefined,
            xAxis: {
                title: {
                    text: 'Entregas no prazo'
                },
                categories: [
                    '5',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30'
                ],
                crosshair: true
            },
            yAxis: {
                title: undefined,
                min: 0
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">Dia {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Entregas',
                data: [49, 71, 106, 129, 144, 176]

            }]
        };

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 180,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '60%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -50
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [78],
                dataLabels: {
                    format: '<div style="text-align:center; top: 0px;position: relative;">' +
                        '<span style="font-size:28px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }


    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosUsuario = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosUsuario[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.usuarioService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/usuario/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.getPerfis();

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });


        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.loading = true;

        this.currentParams = params;
        this.loading = true;

        this.service.id = this.data.id;
        this.service.montaUrl();

        if (this.formFiltrar.formGroup.value.dataDe) {
            this.formFiltrar.formGroup.value.dataDe = Helpers.formatDateDb(this.formFiltrar.formGroup.value.dataDe);
        }

        if (this.formFiltrar.formGroup.value.dataAte) {
            this.formFiltrar.formGroup.value.dataAte = Helpers.formatDateDb(this.formFiltrar.formGroup.value.dataAte);
        }

        if (this.formFiltrar.formGroup.value.entradaDataDe) {
            this.formFiltrar.formGroup.value.entradaDataDe = Helpers.formatDateDb(this.formFiltrar.formGroup.value.entradaDataDe);
        }

        if (this.formFiltrar.formGroup.value.entradaDataAte) {
            this.formFiltrar.formGroup.value.entradaDataAte = Helpers.formatDateDb(this.formFiltrar.formGroup.value.entradaDataAte);
        }

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;


        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }


        this.abstractService.listToTable(this.currentParams, this.currentSearch, this.service.url).subscribe((response) => {

            this.items = [];
            this.items = response?.data || response;

            // Campos Divergentes
            this.items.forEach((item) => {
                item.objDivergentes = item.camposDivergentes ? JSON.parse(item.camposDivergentes) : {};
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

        });
    }

    filtrarClick(status: string, tipo: string = null): void {

        status = status.replace('status_', '');

        this.currentParams.pageIndex = 1;

        this.currentParams.filter = [];

        this.currentParams.filter.push({key: 'status', value: status});


        this.zerarFormFiltrar();
        if (this.stringStatus[status]) {
            this.formFiltrar.formGroup.get('status').setValue(status);
        }

        if (tipo) {
            tipo = tipo.replace('tipo_', '');
            this.currentParams.filter.push({key: 'tipo', value: tipo});

            switch (tipo) {
                case '0': {
                    this.formFiltrar.formGroup.get('tipoEscrituracao').setValue('S');
                    break;
                }
                case '1': {
                    this.formFiltrar.formGroup.get('tipoDocumento').setValue('S');
                    break;
                }
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

    }


    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
        this.cdr.detectChanges();
    }

    cardQuebraSequencia(visible) {
        this.cardQuebraVisible = visible;
        this.cardNotaVisible = !visible;
        this.cdr.detectChanges();
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        if (this.formFiltrar.formGroup.value.competencia) {
            this.formFiltrar.formGroup.value.competencia = this.service.formataCompetencia(this.formFiltrar.formGroup.value.competencia);
        }

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.calculaBadgeFiltros();

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
        this.cdr.detectChanges();
    }


    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {

            this.loadingExportar = true;

            const tipo = this.formExportar.formGroup.value.tipo;

            this.service.exportExcel(this.data.id, this.currentParams.filter, tipo).subscribe((res) => {

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                const name = 'Notas Detalhes';

                fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                this.formExportar.modalVisible = false;

                this.loadingExportar = false;

                this.cdr.detectChanges();

            }, err => {
                this.loadingExportar = false;
                this.toastService.error(err.message);
            });

        } else {

            Object.values(this.formExportar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }

    }

    limparFiltros(): void {
        this.zerarFormFiltrar();

        this.currentParams.filter = [];
        this.currentSearch = '';
        this.pesquisar = null;

        this.calculaBadgeFiltros();

        this.queryTable(this.currentParams, this.currentSearch);

    }


    zerarFormFiltrar(): void {
        this.formFiltrar.formGroup.reset();
    }

    searsh(): void {
        this.currentSearch = this.pesquisar.length > 0 ? this.pesquisar : null;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    formataEntradaSaida(sigla: string): string {
        if (sigla === 'E') {
            return 'Entrada';
        } else if (sigla === 'S') {
            return 'Saída';
        } else {
            return sigla;
        }
    }

    modalIframe(visible): void {
        this.isVisible = visible;
    }

    showModal(competencia, modelo, chave, documento, numero, serie): void {

        const token = this.authService.currentTokenValue;
        const identificador = localStorage.getItem('identificador');
        const baseUrl = 'https://' + identificador + '.embedded.mastertax.app/contas/login/auth';
        const url = '/auditor/sped-fiscal/detalheEscrituracao/?competencia=' + competencia +
            '&modelo=' + modelo +
            '&chave=' + chave +
            '&documento=' + documento +
            '&numero=' + numero +
            '&serie=' + serie +
            '&conta_id=' + this.dadosAnalise.cnpj;

        const fullUrl = `${baseUrl}?token=${token.access_token}&extra=nosel&conta_id=` + this.dadosAnalise.cnpj + `&redirect=`
            + btoa(url);

        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
        this.isVisible = true;


    }

    abrirPdf(chave) {

        this.service.abrirPdf(chave).subscribe((res) => {

            const fileURL = window.URL.createObjectURL(res);
            const tab = window.open();
            tab.location.href = fileURL;


        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        this.checked = this.checkedItems.size === this.items.length;

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    showConfirmIgnorarNotas(visible: boolean): void {

        this.formIgnorar.modalVisible = visible;

        if (!visible) {
            this.formIgnorar.formGroup.reset();
        }

        this.msgModalIgnorar = this.checkedItems.size > 1 ?
            'Essas notas serão ignoradas em qualquer análise seja ela futura ou uma existente. Deseja Ignorar?' :
            'Essa nota será ignorada em qualquer análise seja ela futura ou uma existente. Deseja Ignorar?';
    }

    ignorarNotas() {

        if (this.formIgnorar.formGroup.valid) {

            this.loadings.ignorando = true;

            const motivo = this.formIgnorar.formGroup.get('motivo').value;

            this.service.ignorarNotas({chaves: Array.from(this.checkedItems), motivo}).subscribe((res) => {
                this.toastService.success(res.message || 'Notas ignoradas com sucesso.');
                this.checkedItems.clear();
                this.loadings.ignorando = false;
                this.showConfirmIgnorarNotas(false);
                this.getNotasIgnoradas(this.currentParamsIgnoradas, true);

                setTimeout(() => {
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.retornaCabecalho();
                }, 500);


            }, (response) => {
                this.loadings.ignorando = false;
                this.toastrService.error(response.error.message);

            });
        } else {
            Object.values(this.formIgnorar.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }

            });

        }
    }

    listByTableignoradas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationIgnoradas.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationIgnoradas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationIgnoradas.current_page) {
            this.getNotasIgnoradas(params);
            this.paginationIgnoradas.current_page = params.pageIndex;
        }

    }

    getNotasIgnoradas(params: NzTableQueryParams, inicial = false) {
        this.loadings.ignoradas = true;

        this.paginationIgnoradas.per_page = 50;
        this.currentParamsIgnoradas.pageSize = 50;

        this.currentParamsIgnoradas = params;

        this.service.getNotasIgnoradas(this.data.id, this.currentParamsIgnoradas).subscribe((response) => {
                this.notasIgnoradas = response.data;

                if (!inicial) {
                    this.modalNotasIgnoradas(true);
                } else if (!this.notasIgnoradas.length) {
                    this.modalNotasIgnoradas(false);
                }

                this.loadings.ignoradas = false;

                this.paginationIgnoradas = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

            }, (err) => {
                this.loadings.ignoradas = false;
                this.toastService.error(err.error.message);
            }
        );
    }

    modalNotasIgnoradas(visible, click = false) {

        this.formIgnorar.formGroup.get('motivo').setValue(null);
        this.modalNotasIgnoradasVisible = visible;
        if ((!visible || !this.notasIgnoradas.length) && !click) {
            setTimeout(() => {
                this.queryTable(this.currentParams, this.currentSearch);
                this.retornaCabecalho();
            }, 500);
        }
    }

    showConfirmRemoverDasIgnoradas(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Essa nota não será mais ignorada em todas as análises existentes. Deseja Continuar?',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerDasIgnoradas(id)
        });
    }

    removerDasIgnoradas(id) {

        this.loadings.removerDasIgnoradas = true;

        this.service.removerDasIgnoradas({chaves: [id]}).subscribe({
                next: (res) => {
                    this.loadings.removerDasIgnoradas = false;
                    this.toastService.success('Removida com sucesso.');
                    this.getNotasIgnoradas(this.currentParamsIgnoradas, true);

                }, error: (err) => {
                    this.loadings.removerDasIgnoradas = false;
                    this.toastService.error(err.error.message);
                }
            }
        );

    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.items.filter(({disabled}) => !disabled).forEach(({chave}) => this.updateCheckedSet(chave, checked));

    }

    clickEvent(card: string) {

        switch (card) {

            case 'cnd':
                this.statusCard = !this.statusCard;
                break;

            case 'dec':
                this.statusCard = !this.statusCard;
                break;

        }

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

            if (valor) {
                this.qtdFiltrosAtivos++;
            }

        }

    }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './pages/menu.component';
import { DetailMenuComponent } from './pages/detail/detail-menu.component';


const routes: Routes = [{
    path: '',
    component: MenuComponent,
    children: [
        {
            path: 'detalhar',
            component: DetailMenuComponent
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MenuRoutingModule {
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class CredenciaisAcessoService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/cadastros/empresas/credenciais-acesso';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }


    retornaComboUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    exportExcel(filtros: any, planilha: string, tipo: string, search?: string): any {

        if (search) {
            filtros.procurar = search;
        }

        filtros.tipoExportacao = tipo;
        filtros.planilha = planilha;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/credenciais-acesso/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    insertProcuracoes(ids, dados): Observable<any> {

        const data = {
            ids,
            dados
        };

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/procuracao/em-massa`, data);

    }

    ativarAcessoIntegradoECAC(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/integracoes/integra-contador/gravar`, data);

    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornarResponsaveis(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/responsaveis`);
    }


    downloadModelo(tipo: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/${tipo}/exportar/modelo`,
            {responseType: 'blob'});

    }

    uploadPlanilhaAE(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/empresas/credenciais-acesso/importar-acessos-estaduais`, formData);
    }


    exportarDados( params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=xlsx`);
        queryParams.push(`tipo=credenciaisAcesso`);


        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}${queryString}`, {});

    }
}

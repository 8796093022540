import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class CheckObrigacoesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornaCompetencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/competencias`);

    }

    retornaobrigacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/obrigacoes/${ano}/${mesStr}`);

    }

    retornaArmazenamento(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/armazenamentos/${ano}/${mesStr}`);
    }

    retornaValidacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/validacoes/${ano}/${mesStr}`);

    }

    retornaConciliacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/conciliacoes/${ano}/${mesStr}`);

    }

    retornaAprovacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/aprovacoes/${ano}/${mesStr}`);

    }

    retornaTransmissoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.retornaMesStr(mes);

        return this.http.get(`${environment.apiUrl}/check/obrigacoes/transmissoes/${ano}/${mesStr}`);

    }

    retornaMesStr(mes: number): string {

        let mesStr = '';

        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }


}

<nz-page-header class="site-page-header" nzTitle="Cadastros"
                nzSubtitle="Regras PIS/COFINS - Importar via Movimentação">
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">

        <div nz-col nzSpan="20" nzOffset="2">
            <nz-card>

                <form nz-form [nzNoColon]="false" [formGroup]="formFiltrar.formGroup" [nzLayout]="'vertical'">

                    <nz-form-item>
                        <nz-form-label [nzOffset]="6" nzRequired>Competência</nz-form-label>
                        <nz-form-control [nzSpan]="12" [nzOffset]="6">
                            <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'MM/yyyy'"
                                            formControlName="competencia" style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control [nzSpan]="12" [nzOffset]="6">
                            <nz-list nzSize="small">
                                <nz-list-header>Fornecedores</nz-list-header>
                                <nz-list-item *ngFor="let item of comboFornecedores">
                                    {{item.cnpj | cpfCnpj}} - {{item.nome}}
                                </nz-list-item>
                            </nz-list>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>

                        <nz-form-control [nzSpan]="12" [nzOffset]="6">
                            <nz-list nzSize="small">
                                <nz-list-header>NCM</nz-list-header>
                                <nz-list-item>Todos</nz-list-item>
                            </nz-list>
                        </nz-form-control>

                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control [nzOffset]="6">
                            <button
                                    nz-button
                                    [nzType]="'default'"
                                    [nzLoading]="buscando"
                                    (click)="buscarMovimentacoes()"
                                    [disabled]="!formFiltrar.formGroup.value.competencia && !formFiltrar.formGroup.value.fornecedor"
                                    style="margin-top: 16px"
                            >
                                {{ !buscando ? 'Buscar informações' : 'Buscando' }}
                            </button>
                        </nz-form-control>
                    </nz-form-item>
                </form>
                <nz-divider></nz-divider>

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="movimentacoes"
                          [nzShowPagination]="false"
                          [nzLoading]="importando"
                          *ngIf="showTable">
                    <thead>
                    <tr style="font-size: 12px;">
                        <!--        <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"-->
                        <!--            id="check-all">-->
                        <!--        </th>-->
                        <th>NCM</th>
                        <th>Participante</th>
                        <th nzAlign="center">CST PIS Entrada</th>
                        <th nzAlign="center">ALÍQ. PIS Entrada</th>
                        <th nzAlign="center">CST COFINS Entrada</th>
                        <th nzAlign="center">ALÍQ. COFINS Entrada</th>
                        <th nzAlign="center">CST PIS Saída</th>
                        <th nzAlign="center">ALÍQ. PIS Saída</th>
                        <th nzAlign="center">CST COFINS Saída</th>
                        <th nzAlign="center">ALÍQ. COFINS Saída</th>
                        <th nzAlign="center" nzWidth="130px">Já cadastrada?</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable.data" style="font-size: 12px;">

                        <td nzLeft>{{item.ncm | mask:'0000.00.00'}}</td>
                        <td nzLeft>{{item.participanete | cpfCnpj}}</td>
                        <td nzAlign="center">{{item.cstPisEntrada | number:'2.0'}}</td>
                        <td nzAlign="center">{{item.aliqPisEntrada > 0 ? (item.aliqPisEntrada | number:'1.2') : '0,00'}}
                            %
                        </td>
                        <td nzAlign="center">{{item.cstCofinsEntrada | number:'2.0'}}</td>
                        <td nzAlign="center">{{item.aliqCofinsEntrada > 0 ? (item.aliqCofinsEntrada | number:'1.2') : '0,00'}}
                            %
                        </td>
                        <td nzAlign="center">{{item.cstPisSaida | number:'2.0'}}</td>
                        <td nzAlign="center">{{item.aliqPisSaida > 0 ? (item.aliqPisSaida | number:'1.2') : '0,00'}}%
                        </td>
                        <td nzAlign="center">{{item.cstCofinsSaida | number:'2.0'}}</td>
                        <td nzAlign="center">{{item.aliqCofinsSaida > 0 ? (item.aliqCofinsSaida | number:'1.2') : '0,00'}}
                            %
                        </td>
                        <td class="text-nowrap" nzAlign="center">
                            <nz-switch [(ngModel)]="item.substituir" nzCheckedChildren="Substituir"
                                       nzUnCheckedChildren="Ignorar" *ngIf="item.jaCadastrada"></nz-switch>
                        </td>

                    </tr>
                    </tbody>
                </nz-table>


                <div style="float: right; margin-top: 20px;">
                    <button *ngIf="showTable"
                            nz-button
                            [nzType]="'default'"
                            (click)="cancelarImportar()"
                            style="margin-right: 15px;">Cancelar
                    </button>
                    <button *ngIf="showTable"
                            nz-button
                            [nzType]="'primary'"
                            [nzLoading]="importando"
                            (click)="importar()"
                            style="">{{importando ? 'Concluindo' : 'Concluir'}}
                    </button>
                </div>


            </nz-card>
        </div>
    </div>

</nz-content>


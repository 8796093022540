import {Component, Input, OnInit} from '@angular/core';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';

@Component({
    selector: 'trasitorios',
    templateUrl: './transitorios.component.html',
    styleUrls: ['./transitorios.component.scss']
})
export class TransitoriosComponent implements OnInit {
    @Input() data;

    constructor(
        private tabService: TabService
    ) {

    }

    ngOnInit(): void {

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }
}

import {ComplianceTributosEmpresaService} from './complianceTributosEmpresa.service';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    LOCALE_ID
} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import * as Highcharts from 'highcharts';
import * as fileSaver from 'file-saver-es';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthenticationService} from '../../../core/auth/authentication.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DataService} from '@services/data.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

interface DataItem {
    controleCertidaoProcessamento_id: string;
    created_at: string;
    descricao: string;
    emissao: string;
    id: string;
    identificador: string;
    status: number;
    tipoEmissao: string;
}

registerLocaleData(localePt);

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './complianceTributosEmpresas.component.html',
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./complianceTributosEmpresas.component.scss']
})

export class ComplianceTributosEmpresasComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    @Input() data;

    cardLoadingEmpresa = true;
    cardLoadingObrigacoesAcessorias = true;
    cardLoadingObrigacoesPrincipais = true;
    cardLoadingTabs = true;
    cardLoadingChart = true;

    empresa: any = {};
    idEmpresa = '';

    arrayAnos: any[] = [];
    ano = '';
    labelAno = '';
    selectedValue = '';
    impostoAtivo = '';
    mes = '';

    arraySeriesGrafico = [];
    arraySerie = [];

    minMaxObrigacoesOficiais = 0;
    minObrigacoesOficiais = 0;
    maxObrigacoesOficiais = 0;

    pagamentosObrigacoes = 0;
    pagtoMaxObrigacoes = 0;

    tabNumber = 0;

    arrayImpostos = [];

    arrayTableObrigacoesAcessoriasOficiais = [];
    arrayTableObrigacoesPrincipaisPagamentos = [];
    arrayTableObrigacoesEvolucao: any = [];

    Highcharts: typeof Highcharts = Highcharts;
    HighchartsPie: typeof Highcharts = Highcharts;
    chartPieOptions: Highcharts.Options;
    chartOptions: any;
    updateFlag = false;
    updateFlagPagamentos = false;

    status = false;
    expandSet = new Set<number>();
    expandSetAusencias = new Set<number>();
    expandSetDivergencias = new Set<number>();
    expandSetSub = new Set<number>();

    isVisible = false;
    isVisibleModalTable = false;
    loadingModalTabela = false;

    currentUser: any;

    expandAllCards = true;
    expandAll = true;

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;
    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;
    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;
    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;
    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;
    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;
    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;
    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;
    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;
    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;
    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;
    @ViewChild('column12') column12: ElementRef;
    column12Width = 0;
    @ViewChild('column13') column13: ElementRef;
    column13Width = 0;
    @ViewChild('column14') column14: ElementRef;
    column14Width = 0;
    @ViewChild('column15') column15: ElementRef;
    column15Width = 0;

    @ViewChild('column16') column16: ElementRef;
    column16Width = 0;

    @ViewChild('column17') column17: ElementRef;
    column17Width = 0;

    @ViewChild('column18') column18: ElementRef;
    column18Width = 0;

    @ViewChild('column19') column19: ElementRef;
    column19Width = 0;

    @ViewChild('column20') column20: ElementRef;
    column20Width = 0;

    @ViewChild('column21') column21: ElementRef;
    column21Width = 0;

    @ViewChild('column22') column22: ElementRef;
    column22Width = 0;

    @ViewChild('column23') column23: ElementRef;
    column23Width = 0;

    @ViewChild('column24') column24: ElementRef;
    column24Width = 0;

    @ViewChild('column25') column25: ElementRef;
    column25Width = 0;

    @ViewChild('column26') column26: ElementRef;
    column26Width = 0;

    @ViewChild('column27') column27: ElementRef;
    column27Width = 0;

    @ViewChild('column28') column28: ElementRef;
    column28Width = 0;

    @ViewChild('column29') column29: ElementRef;
    column29Width = 0;

    @ViewChild('column30') column30: ElementRef;
    column30Width = 0;

    limiteTentativas = 0;

    objectTableObrigacoesEvolucaoTotal = {
        obrigacoesAcessorias: {
            max: {
                jan: undefined,
                fev: undefined,
                mar: undefined,
                abr: undefined,
                mai: undefined,
                jun: undefined,
                jul: undefined,
                ago: undefined,
                set: undefined,
                out: undefined,
                nov: undefined,
                dez: undefined,
            },
            min: {
                jan: undefined,
                fev: undefined,
                mar: undefined,
                abr: undefined,
                mai: undefined,
                jun: undefined,
                jul: undefined,
                ago: undefined,
                set: undefined,
                out: undefined,
                nov: undefined,
                dez: undefined,
            }
        },
        obrigacoesPrincipais: {
            jan: undefined,
            fev: undefined,
            mar: undefined,
            abr: undefined,
            mai: undefined,
            jun: undefined,
            jul: undefined,
            ago: undefined,
            set: undefined,
            out: undefined,
            nov: undefined,
            dez: undefined,
        },
        pricipalAcessorias: {
            jan: undefined,
            fev: undefined,
            mar: undefined,
            abr: undefined,
            mai: undefined,
            jun: undefined,
            jul: undefined,
            ago: undefined,
            set: undefined,
            out: undefined,
            nov: undefined,
            dez: undefined,
        },
        minMax: {
            jan: undefined,
            fev: undefined,
            mar: undefined,
            abr: undefined,
            mai: undefined,
            jun: undefined,
            jul: undefined,
            ago: undefined,
            set: undefined,
            out: undefined,
            nov: undefined,
            dez: undefined,
        },
    };

    arrayColors = [
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#2196f3',
        '#00bcd4',
        '#009688',
        '#ffeb3b',
        '#ffc107',
        '#607d8b'
    ];

    bgTableDivergencia = '#fadb14';
    colorTableDivergencia = '#232323';

    divergenciaMes = 0;

    empresaSelecionada = '';

    iframeUrl: SafeResourceUrl = '';

    obrigacao;

    arrayTableDivergencias: any = [];
    qtdD = 0;
    arrayTableAusencias: any = [];
    qtdA = 0;

    tabAtiva = 0;
    tabAtivaTwo = 0;

    loadingCardTabs: boolean;
    loadingCardTabsTwo: boolean;

    formFiltrar: FormStack;
    qtdFiltrosAtivos = 0;

    formFiltrarCabecalho: FormStack;
    qtdFiltrosAtivosCabecalho = 2;

    arraySelectObrigacoes = [];
    arraySelectOcorrencias = [];

    listOfColumn = [
        {
            title: 'Descrição',
            sortFn: (a: DataItem, b: DataItem) => a.tipoEmissao.localeCompare(b.tipoEmissao),
            priority: 1,
            align: 'center'
        },
        {
            title: 'Criado Em',
            sortFn: (a: DataItem, b: DataItem) => a.created_at.localeCompare(b.created_at),
            priority: 5,
            align: 'center'
        },
        {
            title: '',
            compare: null,
            priority: false,
            align: 'center'
        }
    ];

    displayObservacaoModal = false;
    arrayTableObservacao = [];

    tipoOcorrencia;
    idOcorrencia;
    loadingObservacao = false;

    loadingsObservacao = {};
    loadingsObservacaoAdicionar = false;
    loadingsObservacaoEditar = false;
    loadingsObservacaoExcluir = {};

    displayObservasaoModal = false;
    flagEditarObservacao: any = false;
    observacaoValue = '';
    observacaoSelecionada = '';

    obrigacaoSelecionada = [];
    obrigacaoSelecionadaNome: string;

    loadingAtualizando = false;

    tributoEmBranco = false;

    ieSelecionada = null;

    expandCardsTwo = false;

    promoverCard = false;

    optionsEmpresas = [];
    loadings: {
        detalhe: boolean
    } = {
        detalhe: false
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private complianceTributosEmpresa: ComplianceTributosEmpresaService,
        private tabService: TabService,
        public sanitizer: DomSanitizer,
        private authService: AuthenticationService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private modalService: NzModalService,
        private dataService: DataService
    ) {

        super(complianceTributosEmpresa, null, toastService);

        this.ano = new Date().getFullYear().toString();

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                cnpj: [null, null],
                ie: [null, null],
                obrigacao: [null, null],
                ocorrencia: [null, null]
            })
        };

        this.formFiltrarCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                ano: [null, Validators.required],
                unidade: [null, Validators.required]
            })
        };

        this.complianceTributosEmpresa.retornarSelectsObrigacoes(
        ).subscribe((retorno: any) => {

            this.arraySelectObrigacoes = retorno.obrigacoes;

        });

        this.complianceTributosEmpresa.retornarSelectsOcorrencias(
        ).subscribe((retorno: any) => {

            this.arraySelectOcorrencias = retorno.ocorrencias;

        });

        this.complianceTributosEmpresa.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.promoverCard = false;

    }

    ngOnInit(): void {

        if (!this.data || !this.data.unidade) {
            this.toastrService.warning('Selecione uma empresa antes de carregar a tela');
            this.tabService.closeCurrentTab();
            // @ts-ignore
            const tab: Tab = {
                active: true,
                componentName: 'ComplianceTributosComponent',
                title: 'Compliance Tributos',
                url: '/compliance/complianceTributos'
            };
            this.tabService.addTab(tab);
        } else {
            this.ieSelecionada = this.data.ie;

            if (this.data.ie) {
                this.formFiltrarCabecalho.formGroup.get('unidade').setValue(this.data.unidade + '|' + this.data.ie);
            } else {
                this.formFiltrarCabecalho.formGroup.get('unidade').setValue(this.data.unidade);
            }

        }
    }

    ngAfterViewInit() {

        this.idEmpresa = this.data.unidade;
        this.ano = this.data.ano;
        this.obrigacao = this.data.obrigacao;
        this.mes = this.data.mes;

        this.formFiltrarCabecalho.formGroup.get('ano').setValue(this.ano);
        this.formFiltrar.formGroup.get('empresa_id').setValue(this.data.unidade);

        this.dadosEmpresa(this.idEmpresa, this.ano, this.data.ie);

        this.changeTabsTwo({index: 0});

    }

    dadosEmpresa(empresaId: string, ano: string, ie: string) {

        this.cardLoadingEmpresa = true;
        this.complianceTributosEmpresa.retornaEmpresa(empresaId, ano, ie).subscribe((response: any) => {

            this.empresa = response.dados;
            this.cardLoadingEmpresa = false;
            this.arrayAnos = response.anos;
            this.labelAno = this.ano;
            this.selectedValue = this.ano;

            this.atualizaObrigacoes(this.ano);

            this.dataService.currentUser.subscribe((data) => {
                this.currentUser = data;
                this.cdr.detectChanges();
            });

            this.cdr.detectChanges();

            if (this.data.divergencia) {
                setTimeout(() => {
                    this.changeImposto(this.data.divergencia, this.data.mes);
                    this.cdr.detectChanges();
                }, 1000);
            }

            // this.updateAusencias(this.ano);

        });
    }

    formatOne = (percent: number) => `${percent}%`;

    clickEvent() {
        this.status = !this.status;
    }

    exportar() {
        const imposto = this.arrayImpostos[this.tabNumber];
        this.complianceTributosEmpresa.exportExcel(this.idEmpresa, this.ano, imposto, this.data.ie).subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            const name = imposto + ' em ' + this.ano;

            fileSaver.saveAs(blob, name + '.xlsx');
        });
    }

    changeAno(ano: any) {
        this.selectedValue = ano;
        this.labelAno = ano;
        this.ano = ano;

        this.atualizaEmpresa(ano);
        this.atualizaObrigacoes(ano);
        this.changeTabs();
        this.updateDivergencias(ano);
    }

    changeImposto(imposto: string, mes: number = 0) {
        this.arrayImpostos.forEach((value, index) => {
            if (value === imposto) {
                this.impostoAtivo = imposto;
                this.tabNumber = index;
            }
        });
        this.changeTabs();
    }

    atualizaTributos(ano: string, imposto: string) {

        this.cardLoadingTabs = true;

        this.complianceTributosEmpresa.retornaTributos(this.idEmpresa, ano, imposto, this.ieSelecionada).subscribe((retorno: any) => {

            this.arrayTableObrigacoesEvolucao = [];

            const arrayTableObrigacoesEvolucao = [];

            const series = [];


            for (const item of Object.entries(retorno.obrigacoes)) {

                arrayTableObrigacoesEvolucao.push(item[1]);

                const registro: any = item[1];

                this.onExpandChange(registro.id, true);

                if (registro.obrigacoes && registro.obrigacoes.length > 0 && registro.id === 'acessorias') {

                    registro.obrigacoes.forEach((obrigacao, index) => {

                        let ObjGrafico: any = {};
                        ObjGrafico = this.montaSerieGraficoPagamento(obrigacao);
                        ObjGrafico.color = this.arrayColors[index];
                        series.push(ObjGrafico);

                    });

                }
            }

            // @ts-ignore Montando array para front subTabela *************************************
            arrayTableObrigacoesEvolucao.forEach(item => {

                item.obrigacoes.forEach(obg => {

                    const campos = obg.jan.dinamicos?.map(d => {
                        return {descricao: d.descricao, jan: {valor: d.valor, detalhe: d.detalhe}};
                    });

                    campos?.forEach(c => {
                        c.fev = obg.fev.dinamicos?.find(d => d.descricao === c.descricao);
                        c.mar = obg.mar.dinamicos?.find(d => d.descricao === c.descricao);
                        c.abr = obg.abr.dinamicos?.find(d => d.descricao === c.descricao);
                        c.mai = obg.mai.dinamicos?.find(d => d.descricao === c.descricao);
                        c.jun = obg.jun.dinamicos?.find(d => d.descricao === c.descricao);
                        c.jul = obg.jul.dinamicos?.find(d => d.descricao === c.descricao);
                        c.ago = obg.ago.dinamicos?.find(d => d.descricao === c.descricao);
                        c.set = obg.set.dinamicos?.find(d => d.descricao === c.descricao);
                        c.out = obg.out.dinamicos?.find(d => d.descricao === c.descricao);
                        c.nov = obg.nov.dinamicos?.find(d => d.descricao === c.descricao);
                        c.dez = obg.dez.dinamicos?.find(d => d.descricao === c.descricao);
                    });

                    obg.dinamicos = campos;
                });
            });

            this.arrayTableObrigacoesEvolucao = arrayTableObrigacoesEvolucao;

            let pagamentos: any = {};
            pagamentos = this.montaSerieStacklineGraficoPagamento(retorno.totais.obrigacoesPrincipais);

            this.arraySerie = [];
            series.forEach((serie) => {
                this.arraySerie.push(serie);
            });

            this.arraySerie.push(pagamentos);

            this.objectTableObrigacoesEvolucaoTotal = retorno.totais;

            this.cardLoadingTabs = false;

            setTimeout(() => {
                this.atualizaLargurasColunas();
                this.atualizaGraficoPagamentosAno();
            }, 500);

        });
    }

    montaSerieGraficoPagamento(dado: any): any {

        const ObjGrafico: any = {};
        const array: number[] = [];

        array.push(dado.jan.valor);
        array.push(dado.fev.valor);
        array.push(dado.mar.valor);
        array.push(dado.abr.valor);
        array.push(dado.mai.valor);
        array.push(dado.jun.valor);
        array.push(dado.jul.valor);
        array.push(dado.ago.valor);
        array.push(dado.set.valor);
        array.push(dado.out.valor);
        array.push(dado.nov.valor);
        array.push(dado.dez.valor);

        ObjGrafico.name = dado.nome;
        ObjGrafico.type = 'column';
        ObjGrafico.stack = 1;
        ObjGrafico.data = array;

        return ObjGrafico;

    }

    montaSerieStacklineGraficoPagamento(dado: any): any {

        const obj = {
            name: 'Total das Principais',
            type: 'spline',
            data: [],
            color: '#ffa063',
            tooltip: {
                valueSuffix: ''
            }
        };
        obj.data = [];
        obj.data.push(dado.jan ? dado.jan : 0);
        obj.data.push(dado.fev ? dado.fev : 0);
        obj.data.push(dado.mar ? dado.mar : 0);
        obj.data.push(dado.abr ? dado.abr : 0);
        obj.data.push(dado.mai ? dado.mai : 0);
        obj.data.push(dado.jun ? dado.jun : 0);
        obj.data.push(dado.jul ? dado.jul : 0);
        obj.data.push(dado.ago ? dado.ago : 0);
        obj.data.push(dado.set ? dado.set : 0);
        obj.data.push(dado.out ? dado.out : 0);
        obj.data.push(dado.nov ? dado.nov : 0);
        obj.data.push(dado.dez ? dado.dez : 0);

        return obj;

    }

    atualizaObrigacoes(ano: string) {

        this.cardLoadingObrigacoesAcessorias = true;
        this.cardLoadingObrigacoesPrincipais = true;
        this.cardLoadingTabs = true;
        this.cardLoadingChart = true;

        this.complianceTributosEmpresa.retornaObrigacoesAcessorias(this.idEmpresa, ano, this.ieSelecionada).subscribe((retorno: any) => {

            this.arrayTableObrigacoesAcessoriasOficiais = [];

            this.arrayImpostos = [];

            if (retorno.dados) {
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesAcessoriasOficiais.push(dado[1]);
                    const obj: any = dado[1];
                    this.arrayImpostos.push(obj.obrigacao);
                }
            }

            this.tabNumber = 0;

            // Totais
            this.minMaxObrigacoesOficiais = retorno.totais.totalMinMax;
            this.minObrigacoesOficiais = retorno.totais.totalMin;
            this.maxObrigacoesOficiais = retorno.totais.totalMax;

            this.cardLoadingObrigacoesAcessorias = false;
            this.cardLoadingObrigacoesPrincipais = false;
            this.cardLoadingTabs = false;

            this.cdr.detectChanges();

            setTimeout(() => {
                this.atualizaLargurasColunas();
            }, 100);

        }, (err) => {

            this.toastService.error(err.error.message);

            this.cardLoadingObrigacoesAcessorias = false;
            this.cardLoadingObrigacoesPrincipais = false;
            this.cardLoadingTabs = false;
        });

        // Pagamentos Obrigações Principais
        this.complianceTributosEmpresa.retornaObrigacoesPagamentos(this.idEmpresa, ano, this.ieSelecionada).subscribe((retorno: any) => {

            this.arrayTableObrigacoesPrincipaisPagamentos = [];

            if (retorno.dados) {
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesPrincipaisPagamentos.push(dado[1]);
                }

            }

            let totalPagamentos = 0;
            this.arrayTableObrigacoesPrincipaisPagamentos.forEach((pagamento, index) => {
                pagamento.cor = this.arrayColors[index];
                totalPagamentos += pagamento.pagamentos;
            });

            // Gráfico
            this.arraySeriesGrafico = [];
            this.arrayTableObrigacoesPrincipaisPagamentos.forEach((pagamento, index) => {
                const min = pagamento.pagamentos;
                const total = Math.round((min * 100) / totalPagamentos);

                const array = [];
                array.push(pagamento.obrigacao);
                array.push(total);
                this.arraySeriesGrafico.push(array);

            });


            this.cardLoadingObrigacoesPrincipais = false;
            this.cardLoadingChart = false;

            this.cdr.detectChanges();

            this.loadChartData();

            // Totais
            this.pagamentosObrigacoes = retorno.totais.pagamentosTotal;
            this.pagtoMaxObrigacoes = retorno.totais.pagamentosMaxTotal;
        });
    }

    atualizaEmpresa(ano: string) {
        // Dados empresa
        this.cardLoadingEmpresa = true;
        this.complianceTributosEmpresa.retornaEmpresa(this.idEmpresa, ano, this.data.ie).subscribe((retorno: any) => {
            this.empresa = retorno.dados;

            this.cardLoadingEmpresa = false;
            this.cdr.detectChanges();
        });
    }

    loadChartData(): void {

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: this.arrayColors,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: [{
                type: 'pie',
                name: 'Valor',
                data: this.arraySeriesGrafico
            }]
        };

        this.cdr.detectChanges();

        this.updateFlag = true;

    }

    atualizaGraficoPagamentosAno() {

        this.chartOptions = null;
        this.cdr.detectChanges();


        this.chartOptions = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: undefined
            },
            xAxis: [{
                categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                crosshair: true,
            }],
            yAxis: [{
                title: {
                    text: 'Valor',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    },
                },
            }, {
                title: {
                    text: 'Valor',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: '',
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 60,
                verticalAlign: 'top',
                y: 0,
                floating: true,
                backgroundColor: '#FFFFFF'
            },
            series: []
        };

        this.chartOptions.series = [];
        this.arraySerie.forEach((value) => {
            this.chartOptions.series.push(value);
        });
        this.cdr.detectChanges();


        setTimeout(() => {
            this.chartOptions.series = [];
            this.arraySerie.forEach((value) => {
                this.chartOptions.series.push(value);
            });

            setTimeout(() => {
                this.cardLoadingChart = false;
                this.cdr.detectChanges();
            }, 500);

        }, 1000);


    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.atualizaLargurasColunas();
    }

    onExpandChangeSub(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetSub.add(id);
        } else {
            this.expandSetSub.delete(id);
        }
        this.atualizaLargurasColunas();
    }

    onExpandAll(checked: boolean): void {

        this.arrayTableObrigacoesEvolucao.forEach((categorias) => {
            if (checked) {
                this.expandSet.add(categorias.id);
                this.expandSetSub.add(categorias.idObrigacao);
            } else {
                this.expandSet.delete(categorias.id);
                this.expandSetSub.delete(categorias.idObrigacao);
            }
        });

        this.expandAll = checked;

        this.atualizaLargurasColunas();
    }

    atualizaLargurasColunas(): void {

        let verifyWidth = false;

        if (this.column1Width !== this.column1.nativeElement.offsetWidth) {
            this.column1Width = this.column1.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column2Width !== this.column2.nativeElement.offsetWidth) {
            this.column2Width = this.column2.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column3Width !== this.column3.nativeElement.offsetWidth) {
            this.column3Width = this.column3.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column4Width !== this.column4.nativeElement.offsetWidth) {
            this.column4Width = this.column4.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column5Width !== this.column5.nativeElement.offsetWidth) {
            this.column5Width = this.column5.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column6Width !== this.column6.nativeElement.offsetWidth) {
            this.column6Width = this.column6.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column7Width !== this.column7.nativeElement.offsetWidth) {
            this.column7Width = this.column7.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column8Width !== this.column8.nativeElement.offsetWidth) {
            this.column8Width = this.column8.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column9Width !== this.column9.nativeElement.offsetWidth) {
            this.column9Width = this.column9.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column10Width !== this.column10.nativeElement.offsetWidth) {
            this.column10Width = this.column10.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column11Width !== this.column11.nativeElement.offsetWidth) {
            this.column11Width = this.column11.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column12Width !== this.column12.nativeElement.offsetWidth) {
            this.column12Width = this.column12.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column13Width !== this.column13.nativeElement.offsetWidth) {
            this.column13Width = this.column13.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column14Width !== this.column14.nativeElement.offsetWidth) {
            this.column14Width = this.column14.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        this.cdr.detectChanges();

        if (verifyWidth === true) {

            if (this.limiteTentativas === 3) {
                this.limiteTentativas = 0;
                return;
            }

            this.limiteTentativas++;

            setTimeout(() => {
                this.atualizaLargurasColunas();
            }, 100);

        }

    }

    showModal(empresaSelecionada, url, tributoEmBranco?: any): void {
        if (url) {

            if (tributoEmBranco === 1) {
                this.tributoEmBranco = true;
            } else {
                this.tributoEmBranco = false;
            }

            this.empresaSelecionada = empresaSelecionada;

            const token = this.authService.currentTokenValue;
            const identificador = localStorage.getItem('identificador');
            const baseUrl = 'https://' + identificador + '.embedded.mastertax.app/contas/login/auth';


            const fullUrl = `${baseUrl}?token=${token.access_token}&extra=nosel&conta_id=` + this.empresa.cnpj + `&redirect=`
                + url;
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
            this.isVisible = true;

        }

    }

    showModalTabela(visible: boolean, obrigacao?: any, url?: any): void {

        url = url ? url.split('/') : '';

        this.isVisibleModalTable = visible;

        this.loadingModalTabela = true;
        this.loadings.detalhe = true;

        if (visible) {
            this.complianceTributosEmpresa.retornaObrigacaoParaModal(url[url.length - 1]).subscribe((retorno: any) => {

                this.obrigacaoSelecionada = retorno;
                this.obrigacaoSelecionadaNome = obrigacao.nome;
                this.loadings.detalhe = false;
            });

        } else {
            this.obrigacaoSelecionada = [];
            this.obrigacaoSelecionadaNome = null;
            this.loadings.detalhe = false;
        }

        this.loadingModalTabela = false;

    }


    changeTabs() {

        let imposto = this.arrayImpostos[this.tabNumber];

        if (this.data.obrigacao) {

            imposto = this.data.obrigacao;

            this.arrayImpostos.forEach((value, index) => {

                if (this.data.obrigacao === value) {
                    this.tabNumber = index;
                }

            });

            this.impostoAtivo = this.data.obrigacao;

            this.data.obrigacao = null;

        }

        if (this.selectedValue === this.data.ano && imposto === this.data.divergencia) {

            this.divergenciaMes = this.data.mes;

        } else {

            this.divergenciaMes = 0;

        }

        this.cardLoadingChart = true;
        this.updateFlagPagamentos = true;

        this.atualizaTributos(this.ano, imposto);

    }

    changeTabsTwo(event, click = false) {

        this.loadingCardTabsTwo = true;
        this.tabAtivaTwo = event.index;


        switch (event.index) {
            case 0:
                this.updateDivergencias(this.ano, click);
                break;
            case 1:
                this.updateAusencias(this.ano);
                break;

        }
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    updateDivergencias(ano: any, click = false) {

        this.loadingCardTabsTwo = true;

        const filtros = this.formFiltrar.formGroup.value;

        this.calculaBadgeFiltros();

        this.complianceTributosEmpresa.retornaDivergencias(ano.toString(), filtros).subscribe((retorno: any) => {
            this.loadingCardTabsTwo = false;
            this.montaTabelaDivergencias(retorno, (callback) => {
                this.toggleParamsCardTabs(callback);
            }, click);

        });

    }

    updateAusencias(ano: any) {

        this.loadingCardTabsTwo = true;

        this.calculaBadgeFiltros();

        this.complianceTributosEmpresa.retornaAusencias(ano.toString(), this.formFiltrar.formGroup.value).subscribe((retorno: any) => {
            this.loadingCardTabsTwo = false;
            this.montaTabelaAusencias(retorno, (callback) => {
                this.toggleParamsCardTabs(callback);
            });

        });

    }

    calculaBadgeFiltros(): void {

        let qtd = 0;

        if (this.formFiltrar.formGroup.value.obrigacao) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.ocorrencia) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;

    }

    toggleParamsCardTabs(callback) {

        if (callback) {

            this.loadingCardTabs = false;

            if (this.tabAtiva === 1) {

                setTimeout(() => {
                    this.column1Width = this.column1.nativeElement.offsetWidth;
                    this.column2Width = this.column2.nativeElement.offsetWidth;
                    this.column3Width = this.column3.nativeElement.offsetWidth;
                    this.column4Width = this.column4.nativeElement.offsetWidth;
                    this.column5Width = this.column5.nativeElement.offsetWidth;
                    this.column6Width = this.column6.nativeElement.offsetWidth;
                    this.column7Width = this.column7.nativeElement.offsetWidth;
                    this.column8Width = this.column8.nativeElement.offsetWidth;
                }, 100);

            }

            this.cdr.detectChanges();

        }

    }

    montaTabelaDivergencias(retorno, callback, click = false) {

        this.arrayTableDivergencias = [];
        this.arrayTableDivergencias = retorno;

        if (!this.arrayTableDivergencias.length && !click) {
            this.changeTabsTwo({index: 1});
        }

        this.qtdD = 0;
        this.arrayTableDivergencias.forEach(d => {
            this.qtdD += d.registros?.length || 0;
        });

        callback(true);

    }

    montaTabelaAusencias(retorno, callback) {

        this.arrayTableAusencias = [];
        this.arrayTableAusencias = retorno;

        if (this.arrayTableAusencias.length) {
            this.promoverCard = true;
        }

        this.qtdA = 0;
        this.arrayTableAusencias.forEach(a => {
            this.qtdA += a.registros?.length || 0;
        });

        callback(true);

    }

    abrirModal(formulario: FormStack): void {

        formulario.modalVisible = true;

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrarCabecalho(): any {

        this.formFiltrarCabecalho.modalVisible = false;

        const unidadeComIeSelecionada = this.formFiltrarCabecalho.formGroup.get('unidade').value.split('|');

        this.idEmpresa = unidadeComIeSelecionada[0];
        this.ano = this.formFiltrarCabecalho.formGroup.get('ano').value;

        this.data.ie = unidadeComIeSelecionada[1];
        this.ieSelecionada = !unidadeComIeSelecionada[1] || unidadeComIeSelecionada[1] === 'null' ? null : unidadeComIeSelecionada[1];

        this.formFiltrar.formGroup.get('empresa_id').setValue(this.idEmpresa);
        this.formFiltrar.formGroup.get('ie').setValue(this.ieSelecionada);

        this.dadosEmpresa(this.idEmpresa, this.ano, this.data.ie);

        this.filtrar();

        this.changeTabsTwo({index: 0});
        this.changeTabs();
    }

    filtrar(): any {

        switch (this.tabAtiva) {
            case 0:
                this.updateAusencias(this.ano);
                break;
            case 1:
                this.updateDivergencias(this.ano);
                break;
        }

        this.formFiltrar.modalVisible = false;

    }

    modalOcorrencia(visible, tipoOcorrencia = null, idOcorrencia = null) {
        this.tipoOcorrencia = tipoOcorrencia;
        this.idOcorrencia = idOcorrencia;

        if (visible) {
            this.retornaObservacao();
        } else {
            this.displayObservacaoModal = visible;
            this.cdr.detectChanges();
        }
    }

    retornaObservacao() {

        this.loadingObservacao = true;

        this.complianceTributosEmpresa.retornaObservacao(this.tipoOcorrencia, this.idOcorrencia).subscribe((response) => {

                this.arrayTableObservacao = [];

                if (!response.error) {
                    this.arrayTableObservacao = response.ocorrencias;

                    this.arrayTableObservacao.forEach((value) => {
                        this.loadingsObservacao[value.id] = false;
                    });

                }

                this.displayObservacaoModal = true;
                this.cdr.detectChanges();
                this.loadingObservacao = false;

            },
            (response) => {
                this.loadingObservacao = false;

                this.toastrService.error(response.error.message);

            }
        );

    }

    showConfirmExcluirObservacao(tipoOcorrencia, idObservacao): void {
        this.modalService.confirm({
            nzTitle: 'Deseja remover o registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerObservacao(tipoOcorrencia, idObservacao)
        });
    }

    removerObservacao(tipoOcorrencia, idObservacao): void {

        this.loadingsObservacaoExcluir[idObservacao] = true;

        this.complianceTributosEmpresa.removerObservacao(tipoOcorrencia, idObservacao).subscribe((response) => {

            this.toastService.success(response.message);

            this.retornaObservacao();
            if (this.tabAtiva === 0) {
                this.updateAusencias(this.ano);
            } else {
                this.updateDivergencias(this.ano);
            }

            this.loadingsObservacaoExcluir[idObservacao] = false;

        }, (response) => {

            this.loadingsObservacaoExcluir[idObservacao] = false;

            this.toastrService.error(response.error.message);

        });

    }

    modalObservacao(visible, editar = false, descricao = null, observacaoId = null) {

        this.observacaoValue = editar ? descricao : '';

        this.observacaoSelecionada = observacaoId;

        this.flagEditarObservacao = editar;

        this.displayObservasaoModal = visible;

    }

    adicionarObservacao() {

        this.loadingsObservacaoAdicionar = true;

        this.complianceTributosEmpresa.adicionarObservacao(
            this.tipoOcorrencia,
            this.idOcorrencia,
            this.observacaoValue
        ).subscribe((response) => {

            this.toastrService.success(response.message);

            this.displayObservasaoModal = false;

            this.retornaObservacao();
            if (this.tabAtiva === 0) {
                this.updateAusencias(this.ano);
            } else {
                this.updateDivergencias(this.ano);
            }

            this.loadingsObservacaoAdicionar = false;

        }, (response) => {

            this.loadingsObservacaoAdicionar = false;
            this.toastrService.error(response.error.message);

        });

    }

    editarObservacao() {

        this.loadingsObservacaoEditar = true;

        this.complianceTributosEmpresa.editarObservacao(
            this.tipoOcorrencia,
            this.idOcorrencia,
            this.observacaoSelecionada,
            this.observacaoValue
        ).subscribe((response) => {

            this.toastrService.success(response.message);

            this.displayObservasaoModal = false;

            this.retornaObservacao();

            this.loadingsObservacaoEditar = false;

        }, (response) => {
            this.loadingsObservacaoEditar = false;
            this.toastrService.error(response.error.message);

        });

    }

    atuizarTributosEmpresa(): void {

        this.loadingAtualizando = true;

        this.complianceTributosEmpresa.atuizarTributosEmpresa(
            this.ano, this.empresa.id, this.arrayImpostos[this.tabNumber]).subscribe((retorno: any) => {

            this.toastrService.success(retorno.message);

            this.loadingAtualizando = false;

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);
            this.loadingAtualizando = false;

        });

    }

    exibirTudo(exibir: boolean) {
        this.expandAllCards = exibir;
    }

    exibirTudoTwo(exibir: boolean) {
        if (exibir) {
            this.arrayTableDivergencias.forEach(d => {
                this.expandSetDivergencias.add(d.grupoEmpresarial_id);
            });

            this.arrayTableAusencias.forEach(d => {
                this.expandSetAusencias.add(d.grupoEmpresarial_id);
            });
        } else {
            this.expandSetDivergencias.clear();
            this.expandSetAusencias.clear();
        }

        this.expandCardsTwo = exibir;
    }

    onExpandChangeDivergencias(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetDivergencias.add(id);
        } else {
            this.expandSetDivergencias.delete(id);
        }

        this.column16Width = this.column16.nativeElement.offsetWidth;
        this.column17Width = this.column17.nativeElement.offsetWidth;
        this.column18Width = this.column18.nativeElement.offsetWidth;
        this.column19Width = this.column19.nativeElement.offsetWidth;
        this.column20Width = this.column20.nativeElement.offsetWidth;
        this.column21Width = this.column21.nativeElement.offsetWidth;

    }

    onExpandChangeAusencias(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetAusencias.add(id);
        } else {
            this.expandSetAusencias.delete(id);
        }

        this.column22Width = this.column22.nativeElement.offsetWidth;
        this.column23Width = this.column23.nativeElement.offsetWidth;
        this.column24Width = this.column24.nativeElement.offsetWidth;
        this.column25Width = this.column25.nativeElement.offsetWidth;
        this.column26Width = this.column26.nativeElement.offsetWidth;
        this.column27Width = this.column27.nativeElement.offsetWidth;
        this.column28Width = this.column28.nativeElement.offsetWidth;

    }

}

import {
    Component,
    ViewChild,
    ElementRef,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {SwiperComponent} from 'swiper/angular';
import {CheckObrigacoesService} from './checkObrigacoes.service';

// import Swiper core and required components
import SwiperCore, {
    Navigation,
    Mousewheel,
} from 'swiper/core';

// install Swiper components
SwiperCore.use([
    Navigation,
    Mousewheel
]);

interface Obrigacoes {
    obrigacaoNome: string;
    obrigacaoTotal: number;
    armazenadas: number;
    naoArmazenadas: number;
    regrasValidacaoGraves: number;
    regrasValidacaoMediasAguardando: number;
    regrasValidacaoMediasLiberadas: number;
    regrasValidacaoLeves: number;
    regrasValidacaoSemOcorrencia: number;
    regrasConciliacoesGraves: number;
    regrasConciliacoesMediasAguardando: number;
    regrasConciliacoesMediasLiberadas: number;
    regrasConciliacoesLeves: number;
    regrasConciliacoesSemOcorrencia: number;
    aprovacoesAguardando: number;
    aprovacoesAprovadas: number;
    aprovacoesPorc: number;
    transmissoesNaoIniciado: number;
    transmissoesEmExecucao: number;
    transmissoesErro: number;
    transmissoesTransmitidas: number;
    formatOne: number;
    transmissoesNaoIniciadoWidth: number;
    transmissoesEmExecucaoWidth: number;
    transmissoesErroWidth: number;
    transmissoesTransmitidasWidth: number;
}

interface Armazenamento {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    origem: string;
    dataArmazenamento: string;
}

interface Validacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    campo: string;
    ocorrencia: string;
    situacao: string;
    liberacao: string;
}

interface Conciliacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    campo: string;
    ocorrencia: string;
    situacao: string;
    liberacao: string;
}

interface Aprovacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    aprovador1: string;
    aprovador2: string;
}

interface Transmissoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    transmissao: string;
    arquivos: string;
}

@Component({
    selector: 'app-check-obrigacoes',
    templateUrl: './checkObrigacoes.component.html',
    providers: [NzMessageService],
    styleUrls: ['./checkObrigacoes.component.scss']
})

export class CheckObrigacoesComponent {

    @ViewChild('swiperRef', {static: false}) swiperRef?: SwiperComponent;

    competencias: any = [];

    objectMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    obrigacoes: Obrigacoes[] = [];

    armazenamento: Armazenamento[] = [];

    validacoes: Validacoes[] = [];

    conciliacoes: Conciliacoes[] = [];

    aprovacoes: Aprovacoes[] = [];

    transmissoes: Transmissoes[] = [];

    cardCompetenciaAtivo: number;

    navigation = true;

    id = this.route.snapshot.params.id;
    i: any;
    date = null;
    tabNumber = 0;
    grupo = 'todos';
    unidade = 'todas';
    q: {
        pi: number;
        ps: number;
        buscar: string;
        sorter: string;
        table: string;
    } = {
        pi: 1,
        ps: 10,
        buscar: '',
        sorter: '',
        table: '',
    };
    data: any[] = [];
    loading = false;
    description = '';

    tabs: HTMLElement;

    cardTabsLoading = false;

    collapseCard = false;

    status = false;

    cabecalho: any = {};

    constructor(
        private checkObrigacoesService: CheckObrigacoesService,
        private route: ActivatedRoute,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef
    ) {

        this.checkObrigacoesService = checkObrigacoesService;


        this.carregaCompetencias();


    }

    carregaCompetencias(): void {

        this.checkObrigacoesService.retornaCompetencias().subscribe((response: any) => {
            this.competencias = response.dados;

            this.atualizaCabecalho(this.competencias[this.competencias.length - 1]);

            setTimeout(() => {
                this.cardCompetenciaAtivo = this.competencias.length - 1;
                this.changeTabs(null);
                setTimeout(() => {
                    this.collapseCard = true;
                }, 500);
            }, 800);


        });
    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    formatOne = (percent: number) => `${percent}%`;


    add(tpl: TemplateRef<{}>, table: string): void {
        this.loading = true;
        this.q.table = table;
        let keys: any = [];
        if (table === 'armazenamento') {
            keys = this.armazenamento[0];
        }

        for (const key in keys) {
            if (key) {
                this.data.push({index: key, value: keys[key]});
            }
        }
        this.cdr.detectChanges();
        this.modalSrv.create({
            nzTitle: 'Filtro Avançado',
            nzContent: tpl,
            nzOnOk: () => {
            }
        });
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        const result = (menor / maior) * 100;
        return result;
    }


    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined)) {
            this.cardCompetenciaAtivo = event.clickedIndex;

            this.atualizaCabecalho(this.competencias[event.clickedIndex]);

            this.changeTabs(null);

        }
    }

    atualizaCabecalho(competenciaSelecionada: any): void {

        this.cabecalho = competenciaSelecionada;
        // Armazenadas
        const totalArmazenadas = competenciaSelecionada.armazenamentoErro + competenciaSelecionada.armazenamentoOk;
        this.cabecalho.porcentagemArmazenadasAguardando = (competenciaSelecionada.armazenamentoErro * 100) / totalArmazenadas;
        this.cabecalho.porcentagemArmazenadasAprovadas = 100 - this.cabecalho.porcentagemArmazenadasAguardando;

        // Aprovações
        const totalAprovacoes = competenciaSelecionada.aprovacoesAguardando + competenciaSelecionada.aprovacoesAprovadas;
        this.cabecalho.porcentagemAprovacoesAguardando = (competenciaSelecionada.aprovacoesAguardando * 100) / totalAprovacoes;
        this.cabecalho.porcentagemAprovacoesAprovadas = 100 - this.cabecalho.porcentagemAprovacoesAguardando;

        // Transmissões
        const totalTransmissoes = competenciaSelecionada.transmissoesErro + competenciaSelecionada.transmissoesOk;
        this.cabecalho.porcentagemTransmissoesAguardando = (competenciaSelecionada.transmissoesErro * 100) / totalTransmissoes;
        this.cabecalho.porcentagemTransmissoesAprovadas = 100 - this.cabecalho.porcentagemTransmissoesAguardando;

    }

    changeTabs(event: any) {

        const mesAtivo = this.competencias[this.cardCompetenciaAtivo].mes;
        const anoAtivo = this.competencias[this.cardCompetenciaAtivo].ano;
        this.cardTabsLoading = true;
        switch (this.tabNumber) {
            case 0: { // Obrigações
                this.checkObrigacoesService.retornaobrigacoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.obrigacoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            case 1: { // Armazenamento
                this.checkObrigacoesService.retornaArmazenamento(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.armazenamento = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            case 2: { // Validações
                this.checkObrigacoesService.retornaValidacoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.validacoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            case 3: { // Conciliações
                this.checkObrigacoesService.retornaConciliacoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.conciliacoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            case 4: { // Aprovações
                this.checkObrigacoesService.retornaAprovacoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.aprovacoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            case 5: { // Transmissões
                this.checkObrigacoesService.retornaTransmissoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.transmissoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
            default: { // Obrigações
                this.checkObrigacoesService.retornaobrigacoes(mesAtivo, anoAtivo).subscribe((retorno: any) => {
                    this.obrigacoes = retorno.dados.data;
                    this.cardTabsLoading = false;
                    this.cdr.detectChanges();
                });
                break;
            }
        }

    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.competencias.length - 1) {
                    this.cardCompetenciaAtivo = this.competencias.length - 1;
                }

                break;

        }

        this.atualizaCabecalho(this.competencias[this.cardCompetenciaAtivo]);

        this.changeTabs(null);

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Perfil} from '@models/perfil.model';
import {environment} from "../../../../../environments/environment";

@Injectable({providedIn: 'root'})


export class VisibilidadeDetalheService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/visibilidade', {});
    }

    retornaVisibilidade(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}`);

    }

    retornaTodosUsuarios(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}/usuariosVinculados`);

    }

    retornaTodasEmpresas(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}/empresasVinculadas`);

    }

    atualizar(id: string, campos: any): Observable<any> {

        return this.http.put(`${this.baseUrl}/${id}`, campos);

    }

    vincularUsuarios(id: string, usuarios: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}/vincularUsuarios`, {usuarios});

    }

    vincularEmpresas(id: string, empresas: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}/vincularEmpresas`, {empresas});

    }

    removerUsuario(visibilidadeId: string,  usuarioId: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/visibilidade/detalhe/${visibilidadeId}/removerUsuario/${usuarioId}`);

    }

    removerEmpresa(visibilidadeId: string, empresaId: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/visibilidade/detalhe/${visibilidadeId}/removerEmpresa/${empresaId}`);

    }

    public getTree(id: number | string) {
        return this.http.get(`${this.baseUrl}/${id}/permissoes`);
    }

    transformPermissoesItem(items, nivel = 1, expanded = false, beta = false) {

        const permissoesItems = [];
        let ordem = 0;

        for (const item of items) {

            if (!beta) {
                if (item.release === 'alfa') {
                    continue;
                }
            }

            const permissaoItem = {
                level: nivel,
                title: item.title, //  + ' (' + item.id + ')',
                description: item.titleAlt,
                icon: item.icon,
                url: item.url,
                urlType: item.urlType,
                open: true,
                expanded,
                selectable: false,
                disabled: false,
                children: item.children ? this.transformPermissoesItem(item.children, nivel + 1, expanded, beta) : [],
                isLeaf: item.children ? item.children.length === 0 : true,
                key: item.id,
                tipo: item.type,
                ordem: ++ordem,
                extra: item.extra,
                beta: item.release === 'beta',
                alfa: item.release === 'alfa',
                release: item.release,
                favorito: item.favorite ? item.favorite : false
            };

            permissoesItems.push(permissaoItem);
        }

        return permissoesItems;

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '@models/usuario.model';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UsuarioService extends AbstractService<Usuario> {

    constructor(http: HttpClient) {
        super(http, '/administracao/usuarios', Usuario);
    }

    usuarioLogado: Subject<UsuarioLogado> = new Subject<UsuarioLogado>();



    alterarSuporteEmMassa(usuarios: any[], suporte: string, todos, filtros, procurar): any {

        const body = {usuarios, suporte, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuarios/alterarSuporteMassa`, mergedObject);

    }

    alterarStatusEmMassa(usuarios: any[], ativo: string, todos, filtros, procurar): any {

        const body = {usuarios, ativo, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuarios/alterarStatusMassa`, mergedObject);

    }

    getVisibilidades(): any {

        return this.http.get(`${environment.apiUrl}/filtros/visibilidades`);

    }

    getEquipes(): any {

        return this.http.get(`${environment.apiUrl}/filtros/equipes/select`);

    }

    getDadosConta(): Observable<any> {


        return this.http.get(`${environment.apiUrl}/conta`);

    }

    retornaEmpresasVisibilidade(visibilidadeId: string): any {
        return this.http.get(`${environment.apiUrl}/administracao/visibilidade/detalhe/${visibilidadeId}/empresas?paginar=false`);
    }


    salvarVisibilidadesUsuarios(usuarios: any[], visibilidades: any[], limpar: boolean = true, todos, filtros, procurar): Observable<any> {

        const body = {usuarios, visibilidades, limpar, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuarios/alterarVisibilidadeMassa`, mergedObject);

    }

    salvarPerfisColetivo(usuarios: any[], perfil: any[], todos, filtros, procurar): Observable<any> {

        const body = {usuarios, perfil, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuarios/alterarPerfilMassa`, mergedObject);

    }

    save(campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.post(`${environment.apiUrl}/administracao/usuario`, campos);

    }

    emailBoasVindas(arrayUsuarios: any, todos = false, filtros = {}, procurar = ''): Observable<any> {

        const body = {arrayUsuarios, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuario/emailBoasVindas`, mergedObject);

    }

    emailMigracao(arrayUsuarios: any, todos, filtros, procurar): Observable<any> {

        const body = {arrayUsuarios, todos, procurar};

        const mergedObject = Object.assign(body, filtros);

        return this.http.post(`${environment.apiUrl}/administracao/usuario/emailMigracao`, mergedObject);

    }

    downloadModelo(): any {

        return this.http.get(`${environment.apiUrl}/administracao/usuarios/exportar/modelo`,
            {responseType: 'blob'});
    }

    uploadPlanilha(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/usuarios/importar`, formData);
    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }


        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }

    getComboUsuarios(): any {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }


}

import {NgModule} from '@angular/core';
import {HistoricoDeAcessosComponent} from './historico-de-acessos.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from "../../../shared/ng-zorro.module";

@NgModule({
    declarations: [HistoricoDeAcessosComponent],
    imports: [
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        NzBadgeModule,
        NzInputModule,
        NzAlertModule,
        NzTableModule,
        NzModalModule,
        NzFormModule,
        NzSelectModule,
        NzDatePickerModule,
        ReactiveFormsModule,
        NgxMaskModule,
        FormsModule,
        NzCheckboxModule,
        CommonModule,
        NgZorroModule
    ]
})
export class HistoricoDeAcessosModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconListFaComponent} from '@components/icon-list-fa/icon-list-fa.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome' ;
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule} from '@angular/forms';
import {NzIconModule} from "ng-zorro-antd/icon";

@NgModule({
    declarations: [
        IconListFaComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NzInputModule,
        FormsModule,
        NzIconModule
    ],
    exports: [IconListFaComponent]
})
export class IconListFaModule {
}

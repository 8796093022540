import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class DomicilioEletronicoService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacoes', Obrigacao);
    }

    retornaDecs(filtros: any = null): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/listaDomicilio${queryStr}`);

    }

    retornaContadoresOrgao(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/sumario/lista`);
    }

    retornaComboEmpresas(obrigacao: string = null): Observable<any> {


        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/combo/empresas/${obrigacao}`);

    }

    retornaDecEmpresa(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/detalhe/${id}`);
    }

    insertDomicilioEmpresa(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa`, dados);

    }

    retornarSelectsIes(certidaoId: string, empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/combo/ie/${certidaoId}/${empresaId}`);
    }

    updateDomicilioEmpresa(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/${id}`, dados);

    }

    updateDomicilioEmpresasMassa(dados: any): Observable<any> {

        if (dados.dataInicio && dados.dataInicio !== '' && dados.dataInicio !== '') {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }else{
            delete dados.dataInicio;
        }

        if (dados.dataFim) {
            dados.dataFim = this.formataDateBD(dados.dataFim);
        }else{
            delete dados.dataFim;
        }

        return this.http.put(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/alteracaoEmMassa`, dados);

    }

    removeEmpresaUnica(id: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/${id}`);

    }

    removeEmpresasMassa(ids: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/removerMassa`, {ids});

    }

    retornarSelectsSugeridas(empresa): Observable<any> {

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/sugeridas/${empresa}`);
    }


    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';

        if (valor && typeof data.getMonth === 'function') {

            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());

        } else {

            retorno = valor.toString();

        }

        return retorno;

    }

    formataDateTimeBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';

        if (valor && typeof data.getMonth === 'function') {

            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate()) + ' ' +
                this.mesStr(data.getHours()) + ':' +
                this.mesStr(data.getMinutes());

        } else {

            retorno = valor.toString();

        }

        return retorno;

    }

    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    cadastrarSugeridasMassa(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/cadastrar/sugeridas`, dados);

    }

    adicionarDecsSugeridas(decId): Observable<any> {

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/cadastrar/sugeridas/${decId}`, null);

    }

    updateDomicilioEletronico(id: string, dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/habilitado/${id}`, dados);

    }


}

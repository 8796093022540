import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class ComplianceTributosEmpresaService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/tributos', {});
    }

    retornaEmpresa(id: string, ano: string, ie = null): Observable<any> {

        if (ie) {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/retorna-empresa/${id}/${ano}/${ie}`);
        } else {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/retorna-empresa/${id}/${ano}`);
        }
    }

    retornaObrigacaoParaModal(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/dcomp-web/detalhe/` + id);
    }

    retornaObrigacoesAcessorias(empresa: string, ano: string, ie = null): Observable<any> {
        if (ie) {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/obrigacoes-acessorias/${empresa}/${ano}/${ie}`);
        } else {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/obrigacoes-acessorias/${empresa}/${ano}`);
        }
    }

    retornaObrigacoesPagamentos(empresa: string, ano: string, ie = null): Observable<any> {
        if (ie) {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/pagamentos-principais/${empresa}/${ano}/${ie}`);
        } else {
            return this.http.get(`${environment.apiUrl}/compliance/tributos/empresa/pagamentos-principais/${empresa}/${ano}`);
        }
    }

    retornaTributos(empresa: string, ano: string, imposto: string, ie: string): Observable<any> {
        const ieParam = ie ? `&ie=${ie}` : '';

        return this.http.get(
            `${environment.apiUrl}/compliance/tributos/empresa/tributos/${empresa}?ano=${ano}&tributo=${imposto}` + ieParam);
    }

    exportExcel(empresa: string, ano: string, imposto: string, ie: string): any {
        const ieParam = ie ? `&ie=${ie}` : '';
        return this.http.get(
            `${environment.apiUrl}/compliance/tributos/empresa/tributos/${empresa}/exportar?ano=${ano}&tributo=${imposto}` + ieParam,
            {responseType: 'blob'});
    }

    retornaDivergencias(ano, filtros): Observable<any> {

        const queryString = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-divergencias/${ano}${queryString}`);

    }

    retornaAusencias(ano, filtros): Observable<any> {

        const queryString = Helpers.montaQueryString(filtros);
        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-ausencias/${ano}${queryString}`);
    }

    retornarSelectsObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

    retornarSelectsOcorrencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/ocorrencias/select`);
    }

    retornaObservacao(tipoOcorrencia, idOcorrencia): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-observacoes/${tipoOcorrencia}/${idOcorrencia}`);

    }

    removerObservacao(tipoOcorrencia, idObservasao): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/compliance/obrigacoes/excluir-observacao/${tipoOcorrencia}/${idObservasao}`);

    }

    adicionarObservacao(tipoOcorrencia, idOcorrencia, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.post(`${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}`, body);
    }

    editarObservacao(tipoOcorrencia, idOcorrencia, idObservacao, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.put(`${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}/${idObservacao}`, body);
    }

    atuizarTributosEmpresa(ano: string, empressId: string, tributoId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/robo/executar/compliance-tributo?ano=${ano}&empresa_id=${empressId}&tributo_id=${tributoId}`);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasComIEs/select`);
    }

}

<nz-page-header nzTitle="Drive" nzSubtitle="Solicitações de Baixas">
    <nz-page-header-extra></nz-page-header-extra>
</nz-page-header>

<nz-content style="width: 100%">

    <nz-card style="width: 100%" [nzLoading]="loading" [ngClass]="[statusOne ? 'card-fullscreen fonte-doze' : 'card-container fonte-doze']"
             nzTitle="Solicitações de Baixas" [nzExtra]="cndExtra" [nzBodyStyle]="{'padding': '2px'}">
        <ng-template #cndExtra>
            <div class="d-flex align-items-center">
                <nz-badge [nzCount]="qtdFiltros"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button nz-button [nzType]="'default'" (click)="limpar()"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="table(currentParams, currentSearch)"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    <i class="fas fa-redo-alt"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  style="width: 100%; overflow-y: hidden"
                  [nzData]="itens"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.baixas?.total"
                  [nzPageSize]="paginations.baixas?.per_page"
                  [nzPageIndex]="paginations.baixas?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzColumnKey="obrigacao_descricao" [nzSortFn]="true" nzAlign="left">Obrigação</th>
                <th nzColumnKey="empresa_nome" [nzSortFn]="true" nzAlign="left">Empresa</th>
                <th nzColumnKey="periodo" nzAlign="center">Período Solicitado</th>
                <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                <th nzColumnKey="resultado" nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzAlign="center" style="white-space: nowrap;">{{item.obrigacao_descricao}}</td>
                <td style="max-width: 200px" nzLeft>
                    <span style="word-break: break-word; display: block">{{modoDemo(item?.empresa_nome, 'empresaCodigoNome')}}</span>
                    <span style="word-break: keep-all">{{modoDemo(item?.empresa_cnpj | cpfCnpj, 'cnpj')}}</span>
                </td>
                <td style="max-width: 250px;" nzAlign="center">
                    <div style="display: flex; flex-direction: column">
                        {{item.roboBaixa_dataInicio ? (item.roboBaixa_dataInicio | date: 'dd/MM/YYYY') : '-'}}
                        a
                        {{item.roboBaixa_dataFim ? (item.roboBaixa_dataFim| date: 'dd/MM/YYYY') : '-'}}
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="display: flex; justify-content: center">

                        <div *ngIf="item.roboBaixa_status || item.roboBaixa_status.toString() === '0'  else semStatus"
                             class="alert {{item.roboBaixa_status_class}}">
                            <span style="font-weight: 700; word-break: keep-all">{{item.roboBaixa_status_descricao}}</span>
                            <div>
                                <span *ngIf="item.dataExecucao" style="word-break: keep-all">
                                    {{item.dataExecucao ? (item.dataExecucao | date: 'dd/MM/YYYY HH:mm') : '' }}
                                </span>
                                <span *ngIf="item.tempo">
                                    - Tempo {{item.tempo}}
                                </span>
                            </div>
                            <div *ngIf="item.quantidade">
                                <span>
                                    {{item.quantidade.toString()}} arquivos baixados
                                </span>
                            </div>
                        </div>
                        <ng-template #semStatus>
                            <span>-</span>
                        </ng-template>
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="width: 130px; margin: 0 auto; display: flex; justify-content: center">
                        <a *ngIf="item.roboBaixa_status == 2 || item.roboBaixa_status == 3 else semResultado" nz-button
                           nzType="link"  nz-tooltip="Visualizar resultado"
                           (click)="modalResultado(true,
                            (item.roboBaixa_status == 2 ? item.roboBaixa_retornoMensagem : item.roboBaixa_status == 3 ? item.roboBaixa_erroMensagem : null )
                            )">Resultado</a>
                        <ng-template #semResultado>
                            <a  nz-tooltip="Sem resultado para exibir" nz-button nzType="link" disabled="disabled">Resultado</a>
                        </ng-template>
                        <a style="margin-left: 5px" nz-button nzType="link"
                           (click)="modalJson(true, item.parametros)"
                           nz-tooltip="Visualizar JSON">
                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                        </a>
                    </div>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="itens && itens.length > 0">{{paginations.baixas?.from}}
                    -{{paginations.baixas?.to}}
                    de {{paginations.baixas?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltros.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltros.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Período</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.periodo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="periodo">
                        <nz-option [nzLabel]="'Último dia'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 15 dias'" [nzValue]="'15'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 30 dias'" [nzValue]="'30'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 60 dias'" [nzValue]="'60'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 90 dias'" [nzValue]="'90'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 [nzHasFeedback]="formFiltros.formGroup.value.unidade">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="unidade">
                        <nz-option *ngFor="let opt of comboEmpresa"
                                   [nzLabel]="modoDemo(opt.empresa_nome, 'empresaCodigoNome') + ' (' + (modoDemo(opt.descricao | cpfCnpj, 'cnpj')) + ')'"
                                   [nzValue]="opt.descricao">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.categoria_id">
                    <nz-select nzShowSearch nzAllowClear
                               (ngModelChange)="retornaObrigacoes(formFiltros.formGroup.value.categoria_id)"
                               [nzPlaceHolder]="'Selecione'" formControlName="categoria_id">
                        <nz-option *ngFor="let opt of comboCategoria"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.categoria">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 [nzHasFeedback]="formFiltros.formGroup.value.obrigacao_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Na Fila'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Job solicitado'" [nzValue]="'5'"></nz-option>
                        <nz-option [nzLabel]="'Processando'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Armazenando'" [nzValue]="'6'"></nz-option>
                        <nz-option [nzLabel]="'Erro ao processar'" [nzValue]="'3'"></nz-option>
                        <nz-option [nzLabel]="'Processado com sucesso'" [nzValue]="'2'"></nz-option>
                        <nz-option [nzLabel]="'Processado sem registros'" [nzValue]="'7'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalResultadoVisible" [nzTitle]="'Resultado da Baixa'" [nzClosable]="true"
          (nzOnCancel)="modalResultado(false)" [nzWidth]="980">
    <div nz-row *nzModalContent style="min-height: 300px; overflow-y: auto; max-height: 600px;">
        <pre>{{reultadoDaBaixa?.trim() || 'Sem resultado para exibir'}}</pre>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalResultado(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalJsonFlag"
          [nzTitle]="'Json'" [nzClosable]="true"
          (nzOnCancel)="modalJson(false, null)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <textarea rows="15" nz-input [(ngModel)]="textAreaValue"></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalJson(false, null);">Fechar</button>
    </div>
</nz-modal>

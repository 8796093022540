import {AfterViewInit, ChangeDetectorRef, Component, HostListener} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DecCndService} from './simplesNacional.service';
import {DataService} from '@services/data.service';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {environment} from '../../../../environments/environment';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {Pagination} from '@models/pagination.model';
import * as fileSaver from 'file-saver-es';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import moment from 'moment';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-dashboard-decCnd',
    templateUrl: './simplesNacional.component.html',
    styleUrls: ['./simplesNacional.component.scss']
})
export class SimplesNacionalComponent extends AbstractListTable<any> implements AfterViewInit, TabHandlerInterface {

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    statusOne = false;

    currentUser: any;

    apiUrl = environment.apiUrl;

    paginations: any = {
        sn: Pagination
    };

    itensSimplesNacional: any = [];
    cardLoading = true;

    formExportar: FormStack;
    formFiltrosSN: FormStack;
    qtdFiltrosSN = 0;

    currentParams: NzTableQueryParams;

    modalImportarVisible = false;
    substituir = 0;
    fileList: NzUploadFile[] = [];
    uploading = false;

    erroLicencas = false;

    loadingAtualizandoSimples = false;

    loadingContadores = false;
    contadoresCabecalho = {
        atualizados: 0,
        desatualizados: 0,
        solicitados: 0,
        porcAtualizados: 0,
        porcDesatualizados: 0,
        porcSolicitados: 0
    };

    formatOne = (percent: number) => `${percent}%`;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private service: DecCndService,
        private i18n: NzI18nService,
        private dataService: DataService) {

        super(service, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltrosSN = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                dataAtualizacaoInicio: [null, null],
                dataAtualizacaoFim: [null, null],
                dataExtracaoInicio: [null, null],
                dataExtracaoFim: [null, null],
                opcaoMei: [null, null],
                dataMeiInicio: [null, null],
                dataMeiFim: [null, null],
                exclusaoMeiInicio: [null, null],
                exclusaoMeiFim: [null, null],
                opcaoSimples: [null, null],
                dataSimplesInicio: [null, null],
                dataSimplesFim: [null, null],
                exclusaoSimplesInicio: [null, null],
                exclusaoSimplesFim: [null, null],
                status: [null, null]
            })
        };

        this.loadingContadores = true;

        this.service.contadoresCabecalho().subscribe((res) => {

            if (res) {
                this.contadoresCabecalho = res;

                const total =
                    Number(this.contadoresCabecalho.atualizados) + Number(this.contadoresCabecalho.desatualizados) +
                    Number(this.contadoresCabecalho.solicitados);

                this.contadoresCabecalho.porcAtualizados = Number((((this.contadoresCabecalho.atualizados) * 100) / total).toFixed(2));
                this.contadoresCabecalho.porcDesatualizados = Number(
                    (((this.contadoresCabecalho.desatualizados) * 100) / total).toFixed(2));
                this.contadoresCabecalho.porcSolicitados = Number((((this.contadoresCabecalho.solicitados) * 100) / total).toFixed(2));

                this.loadingContadores = false;
            } else {
                this.toastService.error('Erro ao obter contadores');
            }
        });

        this.tableSimplesNacional();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    ngAfterViewInit() {

        // this.resizeModal();

    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    substituirRegistros() {
        this.substituir = this.substituir === 0 ? 1 : 0;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosSN = 0;

        if (typeof this.formFiltrosSN !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosSN.formGroup.value)) {
                if (valor && chave !== 'pagina' && valor && chave !== 'quantidade' && chave !== 'procurar'
                    && chave !== 'ordenar' && chave !== 'sentido') {

                    this.qtdFiltrosSN++;
                }

            }

        }

    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    tableSimplesNacional(params?: any, search?: any, filtros = null): void {

        this.currentParams = params || {filter: []};

        if (filtros) {
            this.currentParams.filter = filtros;
        } else {
            this.currentParams.filter = this.formFiltrosSN.formGroup.value;
        }

        this.cardLoading = true;

        this.calculaBadgeFiltros();

        this.service.table(params, search).subscribe((response: any) => {

            this.itensSimplesNacional = [];
            this.itensSimplesNacional = response.data;

            this.paginations.sn = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.cardLoading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

    }

    modalFiltrarSP(visible: boolean) {
        this.formFiltrosSN.modalVisible = visible;
    }

    confirmaFiltrarSN() {

        const filtros = {};

        for (const [chave, valor] of Object.entries(this.formFiltrosSN.formGroup.value)) {

            const chaverParaValidarData = ['dataAtualizacaoInicio', 'dataAtualizacaoFim', 'dataExtracaoInicio', 'dataExtracaoFim'];

            if (valor) {
                if (chaverParaValidarData.includes(chave)) {
                    filtros[chave] = moment(valor).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    filtros[chave] = valor.toString();
                }
            }
        }

        this.currentParams.pageIndex = 1;

        this.tableSimplesNacional(this.currentParams, this.currentSearch, filtros);
        this.modalFiltrarSP(false);
    }

    filtrarSNByCard(status) {
        if (this.formFiltrosSN.formGroup.get('status').value === status) {
            this.formFiltrosSN.formGroup.get('status').setValue(null);
        } else {
            this.formFiltrosSN.formGroup.get('status').setValue(status);
        }

        this.confirmaFiltrarSN();
    }

    limparSN() {
        this.currentParams.filter = [];
        this.currentSearch = '';
        this.formFiltrosSN.formGroup.reset();
        this.currentParams.pageIndex = 1;
        this.tableSimplesNacional(this.currentParams, this.currentSearch);
    }

    exportPlanilha() {
        const url = `${environment.apiUrl}/exportador`;

        this.service.exportPlanilha(this.formFiltrosSN.formGroup.value, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('DownloadCenterComponent', '', {});

        }, (res) => {
            this.toastService.error(res.error.message);
        });

    }

    atualizarSimples(): void {

        this.loadingAtualizandoSimples = true;

        const cnpjs: string[] = [];
        this.checkedItems.forEach((c) => cnpjs.push(c));

        this.service.atualizarSimples(cnpjs).subscribe((res) => {

            this.toastService.success(res.message);

            this.loadingAtualizandoSimples = false;

            this.tableSimplesNacional(this.currentParams, this.currentSearch);

        }, (err) => {
            this.loadingAtualizandoSimples = false;
            this.toastService.error('Anexo é obrigatório!');
        });
    }

    downloadModelo(): void {

        this.service.downloadModelo().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    modalImportar(visible: boolean): void {

        this.modalImportarVisible = visible;
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        if (file.name.substr(file.name.length - 5) === '.xlsx') {
            this.fileList = [];
            this.fileList.push(file);
        } else {
            this.toastService.error('Extensão inválida. Esperado: xlsx (.xlsx)');
        }

        return false;
    }

    confirmarImportar(): void {

        const formData = new FormData();

        if (!this.fileList.length) {
            this.toastService.error('Anexo é obrigatório!');
        } else {

            this.uploading = true;

            this.fileList.forEach((file: any) => {

                formData.append('arquivo', file);
            });

            formData.append('substituir', String(this.substituir));

        }


        this.service.uploadPlanilha(formData).subscribe((response: any) => {
                this.toastrService.success(response.message);

                this.fileList = [];

                this.modalImportar(false);

                this.tableSimplesNacional(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {

                this.uploading = false;
                this.toastrService.error(response.error.message);
            });


    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

        }

    }

    onAllChecked(checked: boolean): void {

        this.itensSimplesNacional.forEach((item) => this.updateCheckedSet(item.cnpj, checked));

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        if (this.itensSimplesNacional.length !== this.checkedItems.size) {
            this.checked = false;
        }

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.itensSimplesNacional.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }


}

<nz-page-header class="site-page-header" nzTitle="Erp"
                nzSubtitle="Integrações">
    <nz-page-header-extra>
        <button nz-button nzType="primary" nzGhost
                (click)="modalImportarArquivo(true)"
                style="margin-right: 10px; border-radius: 0.45rem;">
            <i nz-icon nzType="upload"></i>
            Novo upload
        </button>
        <nz-button-group>
            <!--             class não funciona bem-->

            <button *ngFor="let i of integracoes" nz-button nzType="primary" [nzGhost]="showTable !== i.value"
                    (click)="changeTable(i.value)" [disabled]="gridLoading"
                    [ngStyle]="i.value === integracoes[0].value ? {'border-top-left-radius': '0.45rem', 'border-bottom-left-radius': '0.45rem'} :
                                i.value === integracoes[integracoes.length - 1].value ? {'border-top-right-radius': '0.45rem', 'border-bottom-right-radius': '0.45rem'} : {}"
            >
                {{i.key}}
            </button>

        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-card [ngClass]="[status ? 'card-fullscreen' : '', !gridLoading ? 'ant-card-table' : '']"
             [nzExtra]="tabsExtra" [nzTitle]="titleTable"
             [nzLoading]="gridLoading"
             [nzBodyStyle]="gridLoading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="listByTable($event)"
                  [nzShowPagination]="true"
                  [nzLoading]="gridLoading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate"
                  style="padding-bottom: 15px; overflow: auto">
            <thead>
            <tr>
                <th>Arquivo</th>

                <th nzAlign="center">Armazenamento</th>
                <th nzAlign="center">Tamanho</th>
                <th nzAlign="center">Status de processamento</th>
                <th></th>

            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of basicTable.data">
                <td class="text-nowrap" [nz-tooltip]="data.formato ? 'Formato: ' + data.formato : 'Fromato: -'">
                    {{data?.nome}}
                </td>
                <td nzAlign="center">{{data.dataArmazanamento ? (data.dataArmazanamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td nzAlign="center">{{data?.tamanho ? data?.tamanho : '-'}}</td>
                <td nzAlign="center" style="width: 50%">

                    <div *ngIf="data.status else semStatus"
                         class="alert {{data.status_class}}">
                        <span style="font-weight: 700; word-break: keep-all">
                            {{data?.status_processamento_descricao ? data?.status_processamento_descricao : '-'}}
                        </span>
                    </div>
                    <ng-template #semStatus>
                        <span>-</span>
                    </ng-template>
                </td>
                <td style="text-align: center;">
                    <div style="min-width: 100px">
                        <button *ngIf="data.status === '2' else semDetalhes" nz-button nzType="link"
                                (click)="detalheAnexo(data?.nome)"
                                [nzLoading]="data.nome == nomeArquivoSelecionado"
                                style="margin-right: 5px;" nz-tooltip="true" nzTooltipTitle="Detalhe">
                            <i nz-icon nzType="eye" nzTheme="outline"></i>
                        </button>
                        <ng-template #semDetalhes>
                            <button [disabled]="data.status !== '2'" nz-button nzType="link" style="margin-right: 5px;">
                                <i nz-icon nzType="eye" nzTheme="outline"></i>
                            </button>
                        </ng-template>
                        <button nz-button nzType="link" (click)="downloadAnexo(data?.nome)"
                                [nzLoading]="loadings.downloads[data.nome]" nz-tooltip="true" nzTooltipTitle="Download">
                            <i nz-icon nzType="download" nzTheme="outline"></i>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>

        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>

    </nz-card>
</nz-content>
<ng-template #tabsExtra>
    <div class="nav-item d-flex">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca"
                   [(ngModel)]="currentSearch">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearch();">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()" style="padding-left: 5px; padding-right: 5px;">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="viewModalUploadArquivo"
          [nzTitle]="'Importar Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalImportarArquivo(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo .csv para importar.</p>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarArquivo(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="uploading" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalDetalheArquivoVisible"
          [nzTitle]="'Detalhes de ' + nomeArquivoSelecionado" [nzClosable]="true"
          (nzOnCancel)="modalArquivo(false)"
          [nzWidth]="1024" [nzBodyStyle]="{'padding': '0'}">
    <div nz-row *nzModalContent>

        <nz-table #detalheTable
                  [nzLoading]="loadings.tableItensDetalhe"
                  [nzData]="arrayItensDetalhe"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="listByTableDetalhes($event)"
                  [nzShowPagination]="true"
                  [nzTotal]="paginationItensDetalhe?.total || 50"
                  [nzPageSize]="paginationItensDetalhe?.per_page || 10"
                  [nzPageIndex]="paginationItensDetalhe?.current_page || 1"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplateItensDetalhe"
                  style="overflow: auto; width: 100% !important;">
            <thead>
            <tr>
                <th>Chave</th>
                <th nzAlign="center">Modelo</th>
                <th nzAlign="center">Emitente</th>
                <th nzAlign="center">Destinatario</th>
            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of detalheTable.data">
                <td class="text-nowrap">
                    {{data?.chave}}
                </td>
                <td nzAlign="center">{{data.modelo}}</td>
                <td nzAlign="center">{{data.emitenteDocumento}}</td>
                <td nzAlign="center">{{data.destinatarioDocumento}}</td>

            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateItensDetalhe let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalArquivo(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'"
          [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Período</nz-form-label>
                <nz-form-control [nzSpan]="13" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dias">
                    <nz-select nzShowSearch
                               [nzPlaceHolder]="'Selecione'" formControlName="dias">
                        <nz-option [nzLabel]="'Últimos 30 dias'" [nzValue]="'30'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 60 dias'" [nzValue]="'60'"></nz-option>
                        <nz-option [nzLabel]="'Últimos 90 dias'" [nzValue]="'90'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status</nz-form-label>
                <nz-form-control [nzSpan]="13" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option *ngFor="let opt of statusProcessamentoArray" [nzLabel]="opt.label"
                                   [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary"
                [nzLoading]="false" (click)="filtrar();">Filtrar
        </button>
    </div>
</nz-modal>

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Regras XML Saída">

    <nz-page-header-extra>

        <nz-button-group>

            <button nz-button nzType="primary" nzGhost
                    [ngStyle]="{'border-top-left-radius': '0.45rem', 'border-bottom-left-radius': '0.45rem'}"
                    (click)="modalImportarRegra(true)">
                <i nz-icon nzType="upload"></i>
                Importar Regras
            </button>

            <button nz-button nzType="primary" nzGhost [nzLoading]="loadingBtBasicTable"
                    [ngStyle]="{'border-top-right-radius': '0.45rem', 'border-bottom-right-radius': '0.45rem'}"
                    (click)="modalRegras(true)">
                <span nz-icon nzType="eye" nzTheme="outline"></span>
                Visualizar Tabela
            </button>

        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <nz-card-tab>
            <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtiva"
                       (nzSelectChange)="changeTabs($event)"
                       [nzTabBarExtraContent]="navBar">
                <nz-tab nzTitle="ICMS"></nz-tab>
                <nz-tab nzTitle="PIS"></nz-tab>
                <nz-tab nzTitle="COFINS"></nz-tab>
                <nz-tab nzTitle="IPI"></nz-tab>
            </nz-tabset>
        </nz-card-tab>
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos[this.tributoSelecionado]"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="showModalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>

        <nz-table #tableICMS
                  *ngIf="tabAtiva === 0"
                  nzSize="small"
                  class="table-small"
                  [nzData]="regrasICMS"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="paginations.icms?.total"
                  [nzPageSize]="paginations.icms?.per_page"
                  [nzPageIndex]="paginations.icms?.current_page"
                  (nzQueryParams)="listByTableICMS($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="CST" [nzSortFn]="true">CST</th>
                <th nzAlign="center" nzColumnKey="CFOP">CFOP</th>
                <th nzAlign="center" nzColumnKey="uf">UF destinatário</th>
                <th nzAlign="center" nzColumnKey="base_calculo" [nzSortFn]="true">Base de Calculo ICMS</th>
                <th nzAlign="center" nzColumnKey="origem" [nzSortFn]="true">Origem Material</th>
                <th nzAlign="center" nzColumnKey="aliquota" [nzSortFn]="true">Aliquota</th>
                <th style="width: 130px; word-break: break-word" nzAlign="center" nzColumnKey="validar_tabela"
                    [nzSortFn]="true">CNPJ destinatário existe na
                    tabela?
                </th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableICMS.data">
                <td nzAlign="center">{{ item.CST | number:'2.0' }}</td>
                <td nzAlign="center">{{ item.CFOP }}</td>
                <td>
                    <div class="divTextoGrande">
                        {{ item.uf }}
                    </div>
                </td>
                <td nzAlign="center">{{ item.base_calculo ? 'Sim' : 'Não' }}</td>
                <td nzAlign="center">
                    {{ item.origem }}
                    <!--                    <div [nz-tooltip]="d" class="divTextoGrandeOrigem" *ngFor="let d of item.origensDescricoes">-->
                    <!--                        {{ d || '-' }}-->
                    <!--                    </div>-->
                </td>
                <td nzAlign="center">{{ item.aliquota ? item.aliquota + '%' : '-' }}</td>
                <td nzAlign="center">{{ item.validar_tabela ? 'Sim' : 'Não' }}</td>
                <td>
                    <div style="display: flex; width: 100%; justify-content: center">
                        <button nz-button nzType="link" (click)="modalCadastrar(true, item)">
                            Alterar
                        </button>
                        <button [nzLoading]="loadingRemove[item.id]" nz-button nzType="link"
                                (click)="showConfirmRemoverRegra(item.id)">
                            Excluir
                        </button>
                    </div>
                </td>

            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="regrasICMS && regrasICMS.length > 0">{{ paginations.icms?.from }}
                    -{{ paginations.icms?.to }}
                    de {{ paginations.icms?.total }} registros</span>
            </ng-template>
        </nz-table>

        <nz-table #tablePIS
                  *ngIf="tabAtiva === 1"
                  nzSize="small"
                  class="table-small"
                  [nzData]="regrasPIS"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="paginations.pis?.total"
                  [nzPageSize]="paginations.pis?.per_page"
                  [nzPageIndex]="paginations.pis?.current_page"
                  (nzQueryParams)="listByTablePIS($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="CST" [nzSortFn]="true">CST</th>
                <th nzAlign="center" nzColumnKey="CFOP">CFOP</th>
                <th nzAlign="center" nzColumnKey="uf">UF destinatário</th>
                <th nzAlign="center" nzColumnKey="base_calculo" [nzSortFn]="true">Base de Calculo</th>
                <th nzAlign="center" nzColumnKey="aliquota" [nzSortFn]="true">Aliquota</th>
                <th style="width: 130px; word-break: break-word" nzAlign="center" nzColumnKey="validar_tabela"
                    [nzSortFn]="true">CNPJ destinatário existe na
                    tabela?
                </th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tablePIS.data">
                <td nzAlign="center">{{ item.CST | number:'2.0' }}</td>
                <td nzAlign="center">{{ item.CFOP }}</td>
                <td>
                    <div class="divTextoGrande">
                        {{ item.uf }}
                    </div>
                </td>
                <td nzAlign="center">{{ item.base_calculo ? 'Sim' : 'Não' }}</td>
                <td nzAlign="center">{{ item.aliquota ? item.aliquota + '%' : '-' }}</td>
                <td nzAlign="center">{{ item.validar_tabela ? 'Sim' : 'Não' }}</td>
                <td>
                    <div style="display: flex; width: 100%; justify-content: center">
                        <button nz-button nzType="link" (click)="modalCadastrar(true, item)">
                            Alterar
                        </button>
                        <button [nzLoading]="loadingRemove[item.id]" nz-button nzType="link"
                                (click)="showConfirmRemoverRegra(item.id)">
                            Excluir
                        </button>
                    </div>
                </td>

            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="regrasPIS && regrasPIS.length > 0">{{ paginations.pis?.from }}-{{ paginations.pis?.to }}
                    de {{ paginations.pis?.total }} registros</span>
            </ng-template>
        </nz-table>

        <nz-table #tableCOFINS
                  *ngIf="tabAtiva === 2"
                  nzSize="small"
                  class="table-small"
                  [nzData]="regrasCOFINS"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="paginations.cofins?.total"
                  [nzPageSize]="paginations.cofins?.per_page"
                  [nzPageIndex]="paginations.cofins?.current_page"
                  (nzQueryParams)="listByTableCOFINS($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="CST" [nzSortFn]="true">CST</th>
                <th nzAlign="center" nzColumnKey="CFOP">CFOP</th>
                <th nzAlign="center" nzColumnKey="uf">UF destinatário</th>
                <th nzAlign="center" nzColumnKey="base_calculo" [nzSortFn]="true">Base de Calculo</th>
                <th nzAlign="center" nzColumnKey="aliquota" [nzSortFn]="true">Aliquota</th>
                <th style="width: 130px; word-break: break-word" nzAlign="center" nzColumnKey="validar_tabela"
                    [nzSortFn]="true">CNPJ destinatário existe na
                    tabela?
                </th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableCOFINS.data">
                <td nzAlign="center">{{ item.CST | number:'2.0' }}</td>
                <td nzAlign="center">{{ item.CFOP }}</td>
                <td>
                    <div class="divTextoGrande">
                        {{ item.uf }}
                    </div>
                </td>
                <td nzAlign="center">{{ item.base_calculo ? 'Sim' : 'Não' }}</td>
                <td nzAlign="center">{{ item.aliquota ? item.aliquota + '%' : '-' }}</td>
                <td nzAlign="center">{{ item.validar_tabela ? 'Sim' : 'Não' }}</td>
                <td>
                    <div style="display: flex; width: 100%; justify-content: center">
                        <button nz-button nzType="link" (click)="modalCadastrar(true, item)">
                            Alterar
                        </button>
                        <button [nzLoading]="loadingRemove[item.id]" nz-button nzType="link"
                                (click)="showConfirmRemoverRegra(item.id)">
                            Excluir
                        </button>
                    </div>
                </td>

            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="regrasCOFINS && regrasCOFINS.length > 0">{{ paginations.cofins?.from }}
                    -{{ paginations.cofins?.to }}
                    de {{ paginations.cofins?.total }} registros</span>
            </ng-template>
        </nz-table>

        <nz-table #tableIPI
                  *ngIf="tabAtiva === 3"
                  nzSize="small"
                  [nzData]="regrasIPI"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="paginations.ipi?.total"
                  [nzPageSize]="paginations.ipi?.per_page"
                  [nzPageIndex]="paginations.ipi?.current_page"
                  (nzQueryParams)="listByTableIPI($event)"
                  class="table-small"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="CST" [nzSortFn]="true">CST</th>
                <th nzAlign="center" nzColumnKey="CFOP">CFOP</th>
                <th nzAlign="center" nzColumnKey="uf">UF destinatário</th>
                <th nzAlign="center" nzColumnKey="base_calculo" [nzSortFn]="true">Base de Calculo IPI</th>
                <th nzAlign="center" nzColumnKey="codigo_enquadramento" [nzSortFn]="true">Cód. Enquadramento</th>
                <th nzAlign="center" nzColumnKey="origem" [nzSortFn]="true">Origem Material</th>
                <th style="width: 130px; word-break: break-word" nzAlign="center" nzColumnKey="validar_tabela"
                    [nzSortFn]="true">CNPJ destinatário existe na
                    tabela?
                </th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableIPI.data">
                <td nzAlign="center">{{ item.CST | number:'2.0' }}</td>
                <td nzAlign="center">{{ item.CFOP }}</td>
                <td>
                    <div class="divTextoGrande">
                        {{ item.uf }}
                    </div>
                </td>
                <td nzAlign="center">{{ item.base_calculo ? 'Sim' : 'Não' }}</td>
                <td nzAlign="center">{{ item.codigo_enquadramento }}</td>
                <td nzAlign="center">
                    {{ item.origem }}
                    <!--                    <div class="divTextoGrandeOrigem" *ngFor="let d of item.origensDescricoes">-->
                    <!--                        {{ d || '-' }}-->
                    <!--                    </div>-->
                </td>
                <td nzAlign="center">{{ item.validar_tabela ? 'Sim' : 'Não' }}</td>
                <td>
                    <div style="display: flex; width: 100%; justify-content: center">
                        <button nz-button nzType="link" (click)="modalCadastrar(true, item)">
                            Alterar
                        </button>
                        <button nz-button nzType="link" (click)="showConfirmRemoverRegra(item.id)"
                                [nzLoading]="loadingRemove[item.id]">
                            Excluir
                        </button>
                    </div>
                </td>

            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="regrasIPI && regrasIPI.length > 0">{{ paginations.ipi?.from }}-{{ paginations.ipi?.to }}
                    de {{ paginations.ipi?.total }} registros</span>
            </ng-template>
        </nz-table>

    </nz-card>

</nz-content>

<nz-modal [(nzVisible)]="modalFiltrarVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="showModalFiltrar(false)" [nzWidth]="650">

    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true"
                  [formGroup]="formFiltrar.formGroup">

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">CST</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="CST">
                            <nz-option *ngFor="let opt of comboCst"
                                       [nzLabel]="opt.value + ' - ' + opt.label"
                                       [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">CFOP</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzMode="tags" nzPlaceHolder="Informe o CFOP e aperte ENTER"
                                   formControlName="CFOP">
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">UF Destinatário</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzShowSearch nzAllowClear nzMode="tags"
                                   [nzPlaceHolder]="'Selecione'" formControlName="uf">
                            <nz-option *ngFor="let opt of comboUFs"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Base de Cálculo</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="base_calculo">
                            <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="tabAtiva == 3">
                    <nz-form-label [nzSpan]="6">Cód. Enquadramento</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     nzValidateStatus="success">
                        <input placeholder="Informe" nz-input formControlName="codigo_enquadramento"
                               (keydown)="keyPressNumber($event)" min="1" max="999" [maxLength]="3"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="tabAtiva !== 1 && tabAtiva !== 2">
                    <nz-form-label [nzSpan]="6">Origem Material</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzShowSearch nzAllowClear nzMode="tags"
                                   [nzPlaceHolder]="'Selecione'" formControlName="origem">
                            <nz-option *ngFor="let opt of comboOrigens"
                                       [nzLabel]="opt.value + ' - ' + opt.label"
                                       [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="tabAtiva !== 3" style="margin-top: 5px;">
                    <nz-form-label [nzSpan]="6">Alíquota</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <input nz-input
                               formControlName="aliquota" type="number" placeholder="Aliquota %"
                               autocomplete="off" size="60" min="0"/>
                    </nz-form-control>

                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Valída Tabela</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzValidateStatus]="'success'">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'CNPJ destinatário existe na tabela?'"
                                   formControlName="validar_tabela">
                            <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingBt" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formRegra.modalVisible"
          [nzTitle]="this.editandoRegistro ? 'Alterar Regra' : 'Nova Regra'"
          [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="748">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formRegra.formGroup"
                  style="width: 100%;">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Tributo</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="tributo_id">
                            <nz-option *ngFor="let opt of comboTributos"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">CST</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="CST">
                            <nz-option *ngFor="let opt of comboCst"
                                       [nzLabel]="opt.value + ' - ' + opt.label"
                                       [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6" nzAlign="left">CFOP(s)</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select [(ngModel)]="selectedCfops"
                                   formControlName="CFOP"
                                   [nzPlaceHolder]="'Selecione'"
                                   nzShowArrow="false"
                                   nzMode="tags" (ngModelChange)="adicionarCFOPCombo($event)">
                            <nz-option *ngFor="let option of cfopOptions"
                                       [nzLabel]="option.label"
                                       [nzValue]="option.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">UF Destinatário</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzMode="tags"
                                   [nzPlaceHolder]="'Selecione'" formControlName="uf">
                            <nz-option *ngFor="let opt of comboUFs"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Base Cálculo
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="base_calculo">
                            <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                            <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <!--            IPI-->
                <nz-form-item *ngIf="formRegra.formGroup.get('tributo_id').value === 'IPI'">
                    <nz-form-label [nzSpan]="6">Cód. Enquadramento</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <input placeholder="Informe" (keydown)="keyPressNumber($event)" min="1" max="999"
                               [maxLength]="3" nz-input formControlName="codigo_enquadramento"/>
                    </nz-form-control>
                </nz-form-item>

                <!--                ICMC e IPI-->
                <nz-form-item
                        *ngIf="formRegra.formGroup.get('tributo_id').value !== 'PIS' && formRegra.formGroup.get('tributo_id').value !== 'COFINS'">
                    <nz-form-label [nzSpan]="6">Origem Material</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzAllowClear nzMode="tags"
                                   [nzPlaceHolder]="'Selecione'" formControlName="origem">
                            <nz-option *ngFor="let opt of comboOrigens"
                                       [nzLabel]="opt.value + ' - ' + opt.label"
                                       [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <!--            ICMS, PIS-->
                <nz-form-item style="margin-top: 5px;" *ngIf="formRegra.formGroup.get('tributo_id').value !== 'IPI'">
                    <nz-form-label [nzSpan]="6">
                        Alíquota
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <input nz-input
                               formControlName="aliquota" type="number" placeholder="Aliquota %"
                               autocomplete="off" size="60" min="0"/>
                    </nz-form-control>

                </nz-form-item>


                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Valída Tabela</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'CNPJ destinatário existe na tabela?'"
                                   formControlName="validar_tabela">
                            <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                            <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button *ngIf="!editandoRegistro" nz-button nzType="primary" [nzLoading]="loadingBt"
                (click)="cadastrarRegra()">Confirmar
        </button>
        <button *ngIf="editandoRegistro" nz-button nzType="primary" [nzLoading]="loadingBt" (click)="alterarRegra()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarRegraVisible"
          [nzTitle]="'Importar Regra'" [nzClosable]="true"
          (nzOnCancel)="modalImportarRegra(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo .csv para importar.</p>
        <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar um
            arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/cadastros/regras-analise-xml-saida/importar"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarRegra(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingBt" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalRegrasVisible"
          [nzTitle]="'CNPJxImposto'" [nzClosable]="true"
          (nzOnCancel)="modalRegras(false)"
          [nzBodyStyle]="{'padding': '0'}"
          [nzWidth]="648">
    <div *nzModalContent>
        <nz-table #basicTable
                  nzSize="small"
                  class="table-small"
                  style="max-height: 550px; overflow-y: auto"
                  [nzData]="tabelaSimples"
                  [nzFrontPagination]="false"
                  [nzLoading]="loadingBtBasicTable"
                  [nzTotal]="paginations.cnpjs?.total"
                  [nzPageSize]="paginations.cnpjs?.per_page"
                  [nzPageIndex]="paginations.cnpjs?.current_page"
                  (nzQueryParams)="listByTableRegrasCnpjs($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ Cliente</th>
                <th nzAlign="center" nzColumnKey="tributo_id" [nzSortFn]="true">Imposto</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzAlign="center">{{ item.cnpj || '-' }}</td>
                <td nzAlign="center">{{ item.tributo_id || '-' }}</td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="tabelaSimples && tabelaSimples.length > 0">{{ paginations.cnpjs?.from }}
                    -{{ paginations.cnpjs?.to }}
                    de {{ paginations.cnpjs?.total }} registros</span>
            </ng-template>
        </nz-table>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="modalImportarBasicTable(true)">
            <i nz-icon nzType="upload"></i>
            Upload
        </button>
        <button nz-button nzType="link" (click)="modalRegras(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalBasicTableVisible"
          [nzTitle]="'Importar'" [nzClosable]="true"
          (nzOnCancel)="modalImportarBasicTable(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo .csv para importar.</p>
        <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModeloBasic();">aqui</a> para baixar
            um
            arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/cadastros/regras-analise-xml-saida/cnpjs/importar"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarBasicTable(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingBt" (click)="confirmarImportarBasicTable()">Enviar
            arquivo
        </button>
    </div>
</nz-modal>

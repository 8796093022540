import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EquipeService} from '@services/equipe.service';
import {UsuarioEquipeService} from '@services/usuarioEquipe.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import Equipe from '@models/equipe.model';
import {RegrasPisCofinsService} from './regras-pis-cofins.service';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-usuarios',
    templateUrl: './regras-pis-cofins.component.html',
    styleUrls: ['./regras-pis-cofins.component.scss']
})
export class RegrasPisCofinsComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {

    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    equipesOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    selectedEquipes: string[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    checked = false;

    token;
    exportUrl;

    modalFiltrarVisible = false;
    formFiltrar: FormStack;
    formCadastrar: FormStack;
    cadastrando = false;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    modalImportarVisible = false;

    fileList: NzUploadFile[] = [];

    editandoRegistro = null;

    erro = false;
    mensagem = '';

    uploading = false;

    cfopOptions: { label: string; value: string }[] = [];
    selectedCfops: string[] = [];

    // Importar via movimentação
    formImportarMovimento: FormStack;
    comboFornecedores: any[] = [];
    buscando = false;
    importando = false;
    movimentacoes: any[] = [];
    showTable = false;
    cfops: any = '5101, 6101, 5102, 6102, 6118, 6122, 6401 e 6403';

    pagination: Pagination;

    qtdFiltrosAtivos = 0;


    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioEquipeService: UsuarioEquipeService,
        private toastService: ToastrService,
        private equipeService: EquipeService,
        private service: RegrasPisCofinsService,
        private perfilService: PerfilService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(usuarioEquipeService, Usuario, toastService);

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                ncm: [null, null],
                cstPisEntrada: [null, null],
                aliqPisEntrada: [null, null],
                cstCofinsEntrada: [null, null],
                aliqCofinsEntrada: [null, null],
                cstPisSaida: [null, null],
                aliqPisSaida: [null, null],
                cstCofinsSaida: [null, null],
                aliqCofinsSaida: [null, null],
                empresa_id: [null, null],
                participante: [null, null],
                cfopIgnorada: [null, null],
                produtoCodigo: [null, null],
                vigenciaInicio: [null, null],
                vigenciaFim: [null, null],
            })
        };
        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({

                ncm: [null, null],
                cstPisEntrada: [null, null],
                aliqPisEntrada: [null, null],
                cstCofinsEntrada: [null, null],
                aliqCofinsEntrada: [null, null],
                cstPisSaida: [null, null],
                aliqPisSaida: [null, null],
                cstCofinsSaida: [null, null],
                aliqCofinsSaida: [null, null],
                vigenciaInicioDe: [null, null],
                vigenciaInicioAte: [null, null],
                vigenciaFimDe: [null, null],
                vigenciaFimAte: [null, null],

            })
        };

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        // Importar via movimentação
        this.formImportarMovimento = {
            modalVisible: false,
            formGroup: this.fb.group({
                competencia: [null, null],
            })
        };

        const fornecedores = [
            {
                cnpj: '01844555002630',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '01844555002711',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '01844555002800',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '43999424000114',
                nome: 'VOLVO DO BRASIL VEÍCULOS LTDA'
            },
            {
                cnpj: '43999424000629',
                nome: 'VOLVO DO BRASIL VEÍCULOS LTDA'
            }
        ];

        this.comboFornecedores = [...this.comboFornecedores, ...fornecedores];

        this.dataExport = {
            url: '/cadastros/pis-cofins-regra/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Regras PIS/COFINS',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    ngOnInit() {

        this.getEquipes();
    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getEquipes() {

        return this.equipeService.listToSelect().subscribe((result) => {
            this.equipeOptions = result.map((option: any) => ({
                label: option.nome,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.equipesOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    saveBulkToEquipes() {

        // this.equipeService.vincularEquipesUsuarios(this.checkedItems, this.selectedEquipes, this.limparEquipes)
        //     .subscribe(
        //         () => {
        //             this.toastrService.success(`Alteração em massa realizada!`);
        //             this.selectedEquipes = [];
        //             this.displayVisibilidadeModal = false;
        //             this.updateTable();
        //         },
        //         (response) => {
        //             this.toastrService.error(response.error.message);
        //         }
        //     );

    }

    saveBulkToProfiles() {
        this.usuarioEquipeService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewEquipe() {
        this.newVisibilidadeModal = true;
    }

    saveNewEquipe() {

        if (!Utils.isEmpty(this.newEquipe)) {

            this.equipeService.save({nome: this.newEquipe} as Equipe).subscribe((res: any) => {

                this.toastrService.success('Equipe gravada com sucesso');
                // this.dataVisibilityForm.data.push(res.data);
                this.selectedEquipes.push(res.id);
                this.getEquipes();
                this.newVisibilidadeModal = false;


            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewEquipe() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaFiltrar() {

        this.currentParams.filter = [];
        this.currentParams.pageIndex = 1;

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {

            if (valor) {

                if (chave === 'vigenciaInicioDe' || chave === 'vigenciaInicioAte') {
                    valor = this.service.formataDateBD(valor.toString());
                }

                if (chave === 'vigenciaFimDe' || chave === 'vigenciaFimAte') {
                    valor = this.service.formataDateBD(valor.toString());
                }

                if (chave !== 'cnpj') {
                    this.currentParams.filter.push({key: chave, value: valor});
                }

            }

        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalFiltrar(visible) {
        this.modalFiltrarVisible = visible;
    }

    modalImportarMovimento(visible) {
        this.formImportarMovimento.formGroup.get('competencia').setValue(null);
        this.showTable = false;
        this.buscando = false;
        this.formImportarMovimento.modalVisible = visible;
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.loading = true;
        this.abstractService.listToTable(this.currentParams, this.currentSearch, this.service.urlDados).subscribe((response) => {

            // Descomentar quando tiver endpoint
            this.items = [];
            this.items = response?.data || response;


            this.items.forEach(value => {
                const regimes = [];
                if (value.regimeLRA) {
                    regimes.push('LRA');
                }
                if (value.regimeLRT) {
                    regimes.push('LRT');
                }
                if (value.regimeLP) {
                    regimes.push('LP');
                }
                if (value.regimeSN) {
                    regimes.push('SN');
                }

                value.regimes = regimes.join(', ');

            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;
        }, (err) => {
            this.loading = false;
        });

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.ncm) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.cstPisEntrada) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.aliqPisEntrada) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.cstCofinsEntrada) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.aliqCofinsEntrada) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.cstPisSaida) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.aliqPisSaida) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.cstCofinsSaida) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.aliqCofinsSaida) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.vigenciaInicioDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.vigenciaInicioAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.vigenciaFimDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.vigenciaFimAte) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    modalCadastrar(visible: boolean, zerar: boolean = false) {
        this.formCadastrar.modalVisible = visible;
        if (zerar) {
            this.zerarFormCadastrar();
        }
    }


    zerarFormCadastrar(): void {

        this.editandoRegistro = null;
        this.formCadastrar.formGroup.get('empresa_id').setValue(null);
        this.formCadastrar.formGroup.get('ncm').setValue(null);
        this.formCadastrar.formGroup.get('produtoCodigo').setValue(null);
        this.formCadastrar.formGroup.get('vigenciaInicio').setValue(null);
        this.formCadastrar.formGroup.get('vigenciaFim').setValue(null);
        this.formCadastrar.formGroup.get('participante').setValue(null);
        this.formCadastrar.formGroup.get('cfopIgnorada').setValue(null);

        this.formCadastrar.formGroup.get('cstPisEntrada').setValue(null);
        this.formCadastrar.formGroup.get('aliqPisEntrada').setValue(null);
        this.formCadastrar.formGroup.get('cstCofinsEntrada').setValue(null);
        this.formCadastrar.formGroup.get('aliqCofinsEntrada').setValue(null);
        this.formCadastrar.formGroup.get('cstPisSaida').setValue(null);
        this.formCadastrar.formGroup.get('aliqPisSaida').setValue(null);
        this.formCadastrar.formGroup.get('cstCofinsSaida').setValue(null);
        this.formCadastrar.formGroup.get('aliqCofinsSaida').setValue(null);
        this.selectedCfops = [];

    }

    modalImportar(visible: boolean): void {
        this.modalImportarVisible = visible;
    }

    exportar(): void {
        this.toastrService.warning('Em desenvolvimento');
    }

    downloadModelo(): void {

        this.service.downloadModelo().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        // const status = file.status;
        // if (status !== 'uploading') {
        // }
        // if (status === 'done') {
        //     this.msg.success(`${file.name} file uploaded successfully.`);
        // } else if (status === 'error') {
        //     this.msg.error(`${file.name} file upload failed.`);
        // }
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;
    }

    confirmarImportar(): void {

        this.uploading = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        this.service.uploadPlanilha(formData).subscribe((response: any) => {

                if (response && response.status === 200) {
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }

                this.fileList = [];

                this.modalImportar(false);

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {
                this.toastrService.error(response.error.message);
            });

    }

    alterar(registro: any): void {


        this.zerarFormCadastrar();

        this.editandoRegistro = registro.id;
        this.service.retornaRegistro(registro.id).subscribe((item) => {
            this.formCadastrar.formGroup.get('empresa_id').setValue(item.empresa_id);
            this.formCadastrar.formGroup.get('ncm').setValue(item.ncm);
            this.formCadastrar.formGroup.get('produtoCodigo').setValue(item.produtoCodigo);
            this.formCadastrar.formGroup.get('participante').setValue(item.participante);
            this.formCadastrar.formGroup.get('cfopIgnorada').setValue(item.cfopIgnorada);
//
            if (item.vigenciaInicio) {
                const data = new Date(item.vigenciaInicio);
                data.setDate(data.getDate() + 1);
                this.formCadastrar.formGroup.get('vigenciaInicio').setValue(data);
            }

            if (item.vigenciaFim) {
                const data = new Date(item.vigenciaFim);
                data.setDate(data.getDate() + 1);
                this.formCadastrar.formGroup.get('vigenciaFim').setValue(data);
            }

            this.formCadastrar.formGroup.get('cstPisEntrada').setValue(item.cstPisEntrada);
            this.formCadastrar.formGroup.get('aliqPisEntrada').setValue(item.aliqPisEntrada);
            this.formCadastrar.formGroup.get('cstCofinsEntrada').setValue(item.cstCofinsEntrada);
            this.formCadastrar.formGroup.get('aliqCofinsEntrada').setValue(item.aliqCofinsEntrada);
            this.formCadastrar.formGroup.get('cstPisSaida').setValue(item.cstPisSaida);
            this.formCadastrar.formGroup.get('aliqPisSaida').setValue(item.aliqPisSaida);
            this.formCadastrar.formGroup.get('cstCofinsSaida').setValue(item.cstCofinsSaida);
            this.formCadastrar.formGroup.get('aliqCofinsSaida').setValue(item.aliqCofinsSaida);
        });


        this.modalCadastrar(true);

    }

    confirmaInsert(): void {

        this.validaFormCadastrar();

        if (!this.erro) {
            const dados = this.formCadastrar.formGroup.value;

            this.service.insertRegra(dados).subscribe((response) => {

                this.toastrService.success('Regra PIS/COFINS cadastrada com sucesso.');
                this.queryTable(this.currentParams, this.currentSearch);
                this.modalCadastrar(false);
            });


        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }
    }

    confirmaUPD(): void {

        this.validaFormCadastrar();

        if (!this.erro) {
            const dados = this.formCadastrar.formGroup.value;

            const id = this.editandoRegistro;


            this.service.updateRegra(id, dados).subscribe((response) => {

                this.toastrService.success('Regra PIS/COFINS atualizada com sucesso.');
                this.queryTable(this.currentParams, this.currentSearch);
                this.modalCadastrar(false);
            });


        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }


    }

    validaFormCadastrar(): void {
        this.erro = false;
        this.mensagem = ''; //

        if (this.formCadastrar.formGroup.get('cstPisEntrada').value === null) {
            this.erro = true;
            this.mensagem = 'Campo CST PIS Entrada obrigatório';
        }
        if (this.formCadastrar.formGroup.get('aliqPisEntrada').value === null) {
            this.erro = true;
            this.mensagem = 'Campo ALÍQ. PIS Entrada obrigatório';
        }
        if (this.formCadastrar.formGroup.get('cstCofinsEntrada').value === null) {
            this.erro = true;
            this.mensagem = 'Campo CST COFINS Entrada obrigatório';
        }
        if (this.formCadastrar.formGroup.get('aliqCofinsEntrada').value === null) {
            this.erro = true;
            this.mensagem = 'Campo ALÍQ. COFINS Entrada obrigatório';
        }

        if (this.formCadastrar.formGroup.get('cstPisSaida').value === null) {
            this.erro = true;
            this.mensagem = 'Campo CST PIS Saída obrigatório';
        }
        if (this.formCadastrar.formGroup.get('aliqPisSaida').value === null) {
            this.erro = true;
            this.mensagem = 'Campo ALÍQ. PIS Saída obrigatório';
        }
        if (this.formCadastrar.formGroup.get('cstCofinsSaida').value === null) {
            this.erro = true;
            this.mensagem = 'Campo CST COFINS Saída obrigatório';
        }
        if (this.formCadastrar.formGroup.get('aliqCofinsSaida').value === null) {
            this.erro = true;
            this.mensagem = 'Campo ALÍQ. COFINS Saída obrigatório';
        }

        if (this.formCadastrar.formGroup.get('vigenciaInicio').value === null) {
            this.erro = true;
            this.mensagem = 'Campo Vigência Inicio obrigatório';
        }

    }


    showConfirm(item: any): void {


        this.modalService.confirm({
            nzTitle: 'Deseja remover a Regra PIS/COFINS?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(item)
        });
    }

    remover(item: any): void {

        this.service.remover(item.id).subscribe(
            (response) => {
                this.toastrService.success('Regra PIS/COFINS removida com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.componentExport.visible = visible;
    }

    adicionarCFOPCombo(event: any): void {

        this.selectedCfops = [];
        event.forEach((value, index) => {
            const valor = value.replace(/\D/g, '');
            value = valor;
            if (value && value.length !== 4) {
                event.splice(index, 1);
                this.toastService.warning('CFOP Inválido');
            } else if (value) {
                value = value.substr(0, 1) + '.' + value.substr(1, 3);
                this.selectedCfops.push(value);
            }
        });

        // Remover Duplicados
        this.selectedCfops = this.selectedCfops.filter((n, i) => this.selectedCfops.indexOf(n) === i);

    }

    buscarMovimentacoes(): void {
        this.buscando = true;
        this.showTable = false;

        const dados: any = {};

        dados.competencia = this.formImportarMovimento.formGroup.value.competencia;

        const cnpjs = [];
        this.comboFornecedores.forEach((value) => {
            cnpjs.push(value.cnpj);
        });

        dados.cnpjs = cnpjs;

        // Descomentar quando tiver ajustado endpoint

        this.service.retornaMovimentacoes(dados).subscribe((response) => {
            this.movimentacoes = [];

            response.forEach(value => {
                value.substituir = false;
            });

            this.movimentacoes = response;
            setTimeout(() => {
                this.buscando = false;
                this.showTable = true;
            }, 2000);


        });

    }

    importar(): void {

        this.importando = true;
        this.service.importar(this.movimentacoes).subscribe((response) => {
            this.formImportarMovimento.formGroup.value.competencia = null;
            this.toastService.success(response.message);
            this.importando = false;
            this.showTable = false;
            this.formImportarMovimento.modalVisible = false;
            this.queryTable(this.currentParams, this.currentSearch);
        });
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }


}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {ReactiveFormsModule} from '@angular/forms';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzSelectModule} from 'ng-zorro-antd/select';

@NgModule({
    declarations: [ExportarTabelaComponent],
    exports: [ExportarTabelaComponent],
    imports: [
        CommonModule,
        NzModalModule,
        ReactiveFormsModule,
        NzFormModule,
        NzButtonModule,
        NzSelectModule
    ]
})
export class ExportarTabelaModule {
}

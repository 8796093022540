<nz-page-header class="site-page-header" nzTitle="Check" style="padding: 3px"
                nzSubtitle="Obrigações">
</nz-page-header>
<nz-content>
    <div nz-row>
        <div nz-col [nzSpan]="24">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="true"
                    [scrollbar]="false"
                    [navigation]="true"
            >
                <ng-template *ngFor="let item of array; index as i" swiperSlide let-data class="card-competencia ant-card pull-up card-{{i}}">
                    <div class="ant-card-head">
                        <h2>{{(item.mes | uppercase)}}</h2>
                        <h3>{{item.ano}}</h3>
                    </div>
                    <div class="text-center ant-card-body card-{{i}}">
                        <!--[ngClass]="i == 0 ? 'cardShadow' : ''" [nzTitle]=""-->
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.obrigacoes}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Obrigações
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.unidades}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Unidades
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <span nz-typography nzType="secondary">Armazenamento</span>
                                <nz-row>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.armazenamentoOk}}</span>
                                        <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                    </nz-col>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.armazenamentoErro}}</span>
                                        <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                        
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center">
                                <span nz-typography nzType="secondary">Validações</span>
                                <nz-row>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.validacoesOk}}</span>
                                        <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                    </nz-col>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.validacoesErro}}</span>
                                        <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <span nz-typography nzType="secondary">Conciliações</span>
                                <nz-row>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.conciliacoesOk}}</span>
                                        <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                    </nz-col>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.conciliacoesErro}}</span>
                                        <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                        
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center">
                                <span nz-typography nzType="secondary">Aprovações</span>
                                <nz-row>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.aprovacoesAguardando}}</span>
                                        <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                    </nz-col>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.aprovacoesAprovadas}}</span>
                                        <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                        
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <span nz-typography nzType="secondary">Transmissões</span>
                                <nz-row>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.transmissoesOk}}</span>
                                        <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                    </nz-col>
                                    <nz-col [nzSpan]="9" style="text-align: right">
                                        <span style="margin-right: 5px;">{{item.transmissoesErro}}</span>
                                        <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        
        </div>
        
        
        <nz-card nz-col [nzSpan]="24" class="tabsContainer" [nzTitle]="cardTitle" [nzExtra]="tabsExtra">
            <div nz-row nzAlign="top">
                <div nz-col nzSpan="24">
                    <nz-tabset class="tabs" [(nzSelectedIndex)]="tabNumber">
                        <nz-tab #obrigacoesTab nzTitle="Obrigações">
                            
                            <nz-table #tableObrigacoes [nzData]="obrigacoes" class="scrollbar">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Obrigações</th>
                                    <th>Armazenadas</th>
                                    <th>Regras de Validação</th>
                                    <th>Regras de Conciliações</th>
                                    <th>Aprovações</th>
                                    <th>Transmissões</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableObrigacoes.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold text-center"
                                        style="font-size: 16px;">{{data.obrigacaoNome}}
                                    </td>
                                    <td class="text-center">{{data.obrigacaoTotal}}</td>
                                    <td>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">teste</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.armazenadas}}</span>
                                                <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">Não Armazenadas</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.naoArmazenadas}}</span>
                                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <nz-progress nz-tooltip
                                                     nzTooltipTitle="{{data.armazenadas}} Armazenadas / {{data.naoArmazenadas}} Não Armazenadas"
                                                     [nzPercent]="data.formatOne" [nzShowInfo]="false">
                                        </nz-progress>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Graves</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasValidacaoGraves}}</span>
                                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px; width: 100px;">Médias</span>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div style="margin-right: 10px;" class="d-flex align-items-center">
                                                    <span style="margin-right: 5px;">{{data.regrasValidacaoMediasAguardando}}</span>
                                                    <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <span style="margin-right: 5px;">{{data.regrasValidacaoMediasLiberadas}}</span>
                                                    <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Leves</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasValidacaoLeves}}</span>
                                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Sem Ocorrências</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasValidacaoSemOcorrencia}}</span>
                                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Graves</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasConciliacoesGraves}}</span>
                                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px; width: 100px;">Médias</span>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <div style="margin-right: 10px;" class="d-flex align-items-center">
                                                    <span style="margin-right: 5px;">{{data.regrasConciliacoesMediasAguardando}}</span>
                                                    <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <span style="margin-right: 5px;">{{data.regrasConciliacoesMediasLiberadas}}</span>
                                                    <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Leves</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasConciliacoesLeves}}</span>
                                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Sem Ocorrências</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.regrasConciliacoesSemOcorrencia}}</span>
                                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nz-progress [nzPercent]="data.aprovacoesPorc" nzType="circle" [nzWidth]="40" nz-tooltip
                                                     nzTooltipTitle="{{data.aprovacoesAguardando}} Aguardando / {{data.aprovacoesAprovadas}} Aprovadas"
                                                     [nzFormat]="formatOne"></nz-progress>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">Aguardando</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.aprovacoesAguardando}}</span>
                                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">Aprovadas</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.aprovacoesAprovadas}}</span>
                                                <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="ant-progress-inner" style="background-color: #bfbfbf !important;">
                                            <div class="ant-progress-bg"
                                                 [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth + data.transmissoesErroWidth) + '%'}"
                                                 style="border-radius: 100px; height: 8px;background-color: red;"></div>
                                            <div class="ant-progress-success-bg ng-star-inserted"
                                                 [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth) + '%'}"
                                                 style="border-radius: 100px;height: 8px;background-color: #faad14;"></div>
                                            <div class="ant-progress-success-bg ng-star-inserted"
                                                 [ngStyle]="{'width': data.transmissoesTransmitidasWidth + '%'}"
                                                 style="border-radius: 100px; height: 8px;"></div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Não Iniciado</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.transmissoesNaoIniciado}}</span>
                                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">Em Execução</span>
                                            <div class="d-flex align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <span style="margin-right: 5px;">{{data.transmissoesEmExecucao}}</span>
                                                    <i class="fas fa-circle" style="color:#faad14; font-size:10px;"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px;">Erro</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.transmissoesErro}}</span>
                                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark" style="margin-right: 10px">Transmitidas</span>
                                            <div class="d-flex align-items-center">
                                                <span style="margin-right: 5px;">{{data.transmissoesTransmitidas}}</span>
                                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab #armazenamentoTab nzTitle="Armazenamento">
                            
                            <nz-table #tableArmazenamento [nzData]="armazenamento" class="scrollbar">
                                <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Empresa</th>
                                    <th>Competência</th>
                                    <th>Obrigação</th>
                                    <th>Situação</th>
                                    <th>Origem</th>
                                    <th>Armazenamento</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableArmazenamento.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold">{{data.grupo}}</td>
                                    <td>{{data.empresa}}</td>
                                    <td>{{data.competencia}}</td>
                                    <td>{{data.obrigacao}}</td>
                                    <td>
              <span nz-typography [ngStyle]="{'color': data.situacao == 'Armazenado' ? 'green' : 'red'}">
                {{data.situacao}}
              </span>
                                    </td>
                                    <td>{{data.origem}}</td>
                                    <td>{{data.dataArmazenamento}}</td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab #validacoesTab nzTitle="Validações">
                            <nz-table #tableValidacoes [nzData]="validacoes" class="scrollbar">
                                <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Empresa</th>
                                    <th>Competência</th>
                                    <th>Obrigação</th>
                                    <th>Campo</th>
                                    <th>Ocorrência</th>
                                    <th>Situação</th>
                                    <th>Liberação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableValidacoes.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold">{{data.grupo}}</td>
                                    <td>{{data.empresa}}</td>
                                    <td>{{data.competencia}}</td>
                                    <td>{{data.obrigacao}}</td>
                                    <td>{{data.campo}}</td>
                                    <td>{{data.ocorrencia}}</td>
                                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' ? 'green' : 'red'}">
                {{data.situacao}}
              </span>
                                    </td>
                                    <td>{{data.liberacao}}</td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab #conciliacoesTab nzTitle="Conciliações">
                            <nz-table #tableConciliacoes [nzData]="conciliacoes" class="scrollbar">
                                <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Empresa</th>
                                    <th>Competência</th>
                                    <th>Obrigação</th>
                                    <th>Campo</th>
                                    <th>Ocorrência</th>
                                    <th>Situação</th>
                                    <th>Liberação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableConciliacoes.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold">{{data.grupo}}</td>
                                    <td>{{data.empresa}}</td>
                                    <td>{{data.competencia}}</td>
                                    <td>{{data.obrigacao}}</td>
                                    <td>{{data.campo}}</td>
                                    <td>{{data.ocorrencia}}</td>
                                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' ? 'green' : (data.situacao == 'N/A' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                                    </td>
                                    <td>{{data.liberacao}}</td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab #aprovacoesTab nzTitle="Aprovações">
                            <nz-table #tableAprovacoes [nzData]="aprovacoes" class="scrollbar">
                                <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Empresa</th>
                                    <th>Competência</th>
                                    <th>Obrigação</th>
                                    <th>Situação</th>
                                    <th>Aprovador 1</th>
                                    <th>Aprovador 2</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableAprovacoes.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold">{{data.grupo}}</td>
                                    <td>{{data.empresa}}</td>
                                    <td>{{data.competencia}}</td>
                                    <td>{{data.obrigacao}}</td>
                                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' || data.situacao == 'Aprovado' ? 'green' : (data.situacao == 'N/A' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                                    </td>
                                    <td>{{data.aprovador1}}</td>
                                    <td>{{data.aprovador2}}</td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                        <nz-tab #transmissoesTab nzTitle="Transmissões">
                            <nz-table #tableTransmissoes [nzData]="transmissoes" class="scrollbar">
                                <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Empresa</th>
                                    <th>Competência</th>
                                    <th>Obrigação</th>
                                    <th>Situação</th>
                                    <th>Transmissão</th>
                                    <th>Arquivos</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of tableTransmissoes.data" style="white-space: nowrap;">
                                    <td class="font-weight-bold">{{data.grupo}}</td>
                                    <td>{{data.empresa}}</td>
                                    <td>{{data.competencia}}</td>
                                    <td>{{data.obrigacao}}</td>
                                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Transmitida' ? 'green' : (data.situacao == 'Na Fila' || data.situacao == 'Em Execução' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                                    </td>
                                    <td>{{data.transmissao}}</td>
                                    <td>
                                        <button nz-button nzType="primary" nzGhost>link 1</button>
                                        <button nz-button nzType="primary" nzGhost>link 2</button>
                                        <button nz-button nzType="primary" nzGhost>link 3</button>
                                    </td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>
        </nz-card>
    </div>
    <ng-template #tabsExtra>
        <nz-space>
            <button *nzSpaceItem nz-button (click)="add(modalContent,'')" [nzType]="'primary'"
                    style="margin-bottom: 15px;">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
            <button *nzSpaceItem nz-button nz-dropdown [nzDropdownMenu]="batchMenu" nzPlacement="bottomLeft"
                    style="margin-bottom: 15px;">
                Operação em Lote
                <i nz-icon nzType="down"></i>
            </button>
        </nz-space>
        <nz-dropdown-menu #batchMenu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item>Excluir</li>
                <li nz-menu-item>Aprovação</li>
            </ul>
        </nz-dropdown-menu>
    </ng-template>
</nz-content>
<ng-template #modalContent>
    <ng-container *ngFor="let item of data">
        <nz-form-item
                *ngIf="item.index == 'id' || item.index == 'codigo' || item.index == 'cnpj' || item.index == 'empresa'|| item.index == 'grupo' || item.index == 'receitaFederal' || item.index == 'regimeTributario' || item.index == 'inscricaoEstadual' || item.index == 'inscricaoEstadualSt' || item.index == 'inscricaoMunicipal'">
            <nz-form-label nzFor="no">{{item.title}}</nz-form-label>
            <nz-form-control>
                <input nz-input [(ngModel)]="description" name="{{item.index}}" placeholder=""/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="item.index == 'matriz'">
            <nz-form-label nzFor="no">Matriz</nz-form-label>
            <nz-form-control>
                <nz-select>
                    <nz-option [nzLabel]="'Matriz'" [nzValue]="'matriz'"></nz-option>
                    <nz-option [nzLabel]="'Filial'" [nzValue]="'filial'"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="item.index == 'uf'">
            <nz-form-label nzFor="no">UF</nz-form-label>
            <nz-form-control>
                <nz-select>
                    <nz-option [nzLabel]="'AC'" [nzValue]="'AC'"></nz-option>
                    <nz-option [nzLabel]="'AL'" [nzValue]="'AL'"></nz-option>
                    <nz-option [nzLabel]="'AP'" [nzValue]="'AP'"></nz-option>
                    <nz-option [nzLabel]="'AM'" [nzValue]="'AM'"></nz-option>
                    <nz-option [nzLabel]="'BA'" [nzValue]="'BA'"></nz-option>
                    <nz-option [nzLabel]="'CE'" [nzValue]="'CE'"></nz-option>
                    <nz-option [nzLabel]="'DF'" [nzValue]="'DF'"></nz-option>
                    <nz-option [nzLabel]="'ES'" [nzValue]="'ES'"></nz-option>
                    <nz-option [nzLabel]="'GO'" [nzValue]="'GO'"></nz-option>
                    <nz-option [nzLabel]="'MA'" [nzValue]="'MA'"></nz-option>
                    <nz-option [nzLabel]="'MG'" [nzValue]="'MG'"></nz-option>
                    <nz-option [nzLabel]="'MT'" [nzValue]="'MT'"></nz-option>
                    <nz-option [nzLabel]="'MS'" [nzValue]="'MS'"></nz-option>
                    <nz-option [nzLabel]="'PR'" [nzValue]="'PR'"></nz-option>
                    <nz-option [nzLabel]="'PB'" [nzValue]="'PB'"></nz-option>
                    <nz-option [nzLabel]="'PA'" [nzValue]="'PA'"></nz-option>
                    <nz-option [nzLabel]="'PE'" [nzValue]="'PE'"></nz-option>
                    <nz-option [nzLabel]="'PI'" [nzValue]="'PI'"></nz-option>
                    <nz-option [nzLabel]="'RJ'" [nzValue]="'RJ'"></nz-option>
                    <nz-option [nzLabel]="'RN'" [nzValue]="'RN'"></nz-option>
                    <nz-option [nzLabel]="'RS'" [nzValue]="'RS'"></nz-option>
                    <nz-option [nzLabel]="'RO'" [nzValue]="'RO'"></nz-option>
                    <nz-option [nzLabel]="'RR'" [nzValue]="'RR'"></nz-option>
                    <nz-option [nzLabel]="'SC'" [nzValue]="'SC'"></nz-option>
                    <nz-option [nzLabel]="'SE'" [nzValue]="'SE'"></nz-option>
                    <nz-option [nzLabel]="'SP'" [nzValue]="'SP'"></nz-option>
                    <nz-option [nzLabel]="'TO'" [nzValue]="'TO'"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</ng-template>

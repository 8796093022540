import {NgModule} from '@angular/core';
import {ConciliacoesComponent} from './conciliacoes.component';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {UiModule} from '../../ui.module';
import {ConciliacoesRoutingModule} from './conciliacoes-routing.module';
import {CommonModule} from '@angular/common';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {SwiperModule} from 'swiper/angular';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {ExportarTabelaModule} from "@components/exportar-tabela/exportar-tabela.module";
import {NzAlertModule} from "ng-zorro-antd/alert";

@NgModule({
    imports: [
        ConciliacoesRoutingModule,
        NzCarouselModule,
        NzAnchorModule,
        NzTypographyModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        FormsModule,
        NzSelectModule,
        UiModule,
        NzTabsModule,
        CommonModule,
        NzBreadCrumbModule,
        NzProgressModule,
        NzPageHeaderModule,
        SwiperModule,
        NzSpaceModule,
        NzListModule,
        ReactiveFormsModule,
        NzBadgeModule,
        ExportarTabelaModule,
        NzAlertModule
    ],
    declarations: [ConciliacoesComponent],
})
export class ConciliacoesModule {
}

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    AfterViewInit,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {NotasEmitidasEmpresaService} from './notasEmitidasEmpresa.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {differenceInCalendarDays} from 'date-fns';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-sefazErpDetalhes',
    templateUrl: './notasEmitidasEmpresa.component.html',
    styleUrls: ['./notasEmitidasEmpresa.component.scss']
})
export class NotasEmitidasEmpresaComponent extends AbstractListTable<Usuario> implements OnInit, AfterViewInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @Output() generalFilterHandler = new EventEmitter<string>();
    qtdFiltrosAtivos = 0;
    qtdFiltrosCabecalhoAtivos = 0;

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    HighchartsSolidGauge: typeof Highcharts = Highcharts;

    chartSolidGaugeOptions: Highcharts.Options;
    chartColumnOptions: Highcharts.Options;

    updateFlag: boolean;

    selectedValue = new Date('2017-01-25');

    dadosAnalise: any = {};
    contadores: any = {};

    divergencias: any[] = [];

    formFiltrar: FormStack;
    formFiltrarCabecalho: FormStack;

    formExportar: FormStack;
    formMotivo: FormStack;

    pesquisar = '';
    comboUFs: any = [];

    cardNotaVisible = true;
    cardQuebraVisible = false;

    quebras: any[] = [];

    stringStatus = {
        1: 'Escrituradas Corretamente',
        2: 'Divergências',
        3: 'XMLs Ausentes',
        4: 'XMLs Fora do SPED',
        5: 'Escrituradas Indevidamente',
        6: 'NFs somente na SEFAZ',
        7: 'Divergência - Chave',
        8: 'Divergência - Série',
        9: 'Divergência - Tipo',
        10: 'Divergência - Oper. Documento',
        11: 'Divergência - Oper. Exrituração',
        12: 'Divergência - Modelo',
        13: 'Divergência - Situação, Duplicidade',
        14: 'Divergência - Documento',
        15: 'Divergência - Data, Data Emissão',
        16: 'Divergência - Valor, Valor IPI, Valor ICMS',
        52: 'Não escrituradas - ok',
        53: 'Não escrituradas',
        55: 'Quebra de Sequência',
    };
    objDescricaoStatus: any[] = [];

    checkedItems = new Set<string>();
    checkedItemsNfe = new Set<string>();
    checkedItemsCte = new Set<string>();
    checked = false;

    screenWidth = 0;
    screenHeight = 0;

    loadings = {
        xlsx: false,
        pdf: false,
        xml: false,
        table: false
    };

    // temSefaz2 criado para efeito no front
    filtrosCabecalhos = {
        temSefaz: 0,
        temArmazenadas: 0,
        temErp: 0,
        temSefaz2: 0
    };

    listOfGroupOption = [
        {label: 'Sem manifestação', value: '0'},
        {label: 'Ciência', value: '1', groupLabel: 'NFe'},
        {label: 'Confirmada', value: '2', groupLabel: 'NFe'},
        {label: 'Não Realizada', value: '3', groupLabel: 'NFe'},
        {label: 'Desconhecida', value: '4', groupLabel: 'NFe'},
        {label: 'Desacordo', value: '5', groupLabel: 'CTe'},
    ];

    tiposManifestacao = {
        1: 'Ciência da Operação',
        2: 'Confirmada a Operação',
        3: 'Operação não Realizada',
        4: 'Desconhecida',
        5: 'Desacordo'
    };

    arrayStatusManifestacao: any[] = [];
    modalStatusManifestacaoVisible = false;

    emissaoDataLimit = new Date();
    competenciaDataLimit = new Date();

    habilitarEmissaoData = false;

    comboEmpresas = [];

    @ViewChild('basicTable', {read: ElementRef}) public basicTable: ElementRef<any>;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private service: NotasEmitidasEmpresaService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private modalService: NzModalService) {

        super(usuarioService, Usuario, toastService);


        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                numeroDe: [null, null],
                numeroAte: [null, null],
                modelo: [null, null],
                tipo: [null, null],
                serie: [null, null],
                emissaoTipo: [null, null],
                participanteDocumento: [null, null],
                uf: [null, null],
                situacao: [null, null],
                dataEmissaoDe: [null, null],
                dataEmissaoAte: [null, null],
                tipoEmissao: [null, null],
                valorDe: [null, null],
                valorAte: [null, null],
                chave: [null, null],
                manifestacao: [null, null]

            })
        };

        this.formFiltrarCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, null],
                dataCompetenciaDe: [null, null],
                dataCompetenciaAte: [null, null]
            })
        };

        // this.formFiltrar.formGroup.get('modelo').setValue('55');

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formMotivo = {
            modalVisible: false,
            formGroup: this.fb.group({
                motivo: [null, Validators.required],
            })
        };

        this.service.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

        Object.entries(this.stringStatus).forEach((index, value) => {
            const obj = {value: index[0], label: index[1]};
            this.objDescricaoStatus.push(obj);

        });


    }

    ngOnInit() {
        if (this.data.extraFilter) {
            this.filtrosCabecalhos[this.data.extraFilter] = 1;
        }
        this.retornaCabecalho();
        this.retornaQuebras();

        // Precisa de endpoint
        this.retornarComboEmpresas('CONVENIO115');

    }

    retornarComboEmpresas(obrigacao: string = null): void {
        this.service.retornarComboEmpresas(obrigacao).subscribe((retorno: any) => {
            this.comboEmpresas = [];
            this.comboEmpresas = retorno;
        });
    }

    ngAfterViewInit() {
        this.dispachResizeTable();
    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.dispachResizeTable();
    }

    dispachResizeTable() {
        this.screenWidth = Math.round(window.innerWidth - 70);
        this.screenHeight = Math.round(window.innerHeight - 250);
        this.cdr.detectChanges();
    }

    retornaQuebras(): void {
        this.service.retornaQuebras(this.data.id).subscribe((response) => {
            this.quebras = [];
            this.quebras = response.data;

        });
    }

    retornaCabecalho(): void {

        this.service.retornaCabecalho(this.data.id).subscribe((response) => {

            this.dadosAnalise = response;

            // validar se será isso mesmo
            this.formFiltrarCabecalho.formGroup.get('dataCompetenciaDe').setValue(response.dados.competencia);
            this.qtdFiltrosCabecalhoAtivos++;

            this.retornaContadores();

        });

    }

    retornaContadores(): void {

        this.service.retornaContadores(this.data.id).subscribe((response) => {

            this.contadores = {
                erp: {
                    erp1: 1000,
                    erp2: 120
                },
                sefaz: {
                    valor: 944500,
                    armazenados: 900,
                    total: 1000
                }
            };

        });

    }

    loadChartData(): void {

        this.chartColumnOptions = {
            chart: {
                type: 'column'
            },
            title: undefined,
            xAxis: {
                title: {
                    text: 'Entregas no prazo'
                },
                categories: [
                    '5',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30'
                ],
                crosshair: true
            },
            yAxis: {
                title: undefined,
                min: 0
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">Dia {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Entregas',
                data: [49, 71, 106, 129, 144, 176]

            }]
        };

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 180,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '60%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -50
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [78],
                dataLabels: {
                    format: '<div style="text-align:center; top: 0px;position: relative;">' +
                        '<span style="font-size:28px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }


    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }


    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosUsuario = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosUsuario[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.usuarioService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/usuario/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.getPerfis();

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });


        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    queryTableErp(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.currentSearch = search;

        this.loadings.table = true;

        this.service.id = this.data.id;

        this.service.montaUrl();

        if (this.formFiltrar.formGroup.value.dataEmissaoDe) {
            this.formFiltrar.formGroup.value.dataEmissaoDe = this.service.formataDateBD(this.formFiltrar.formGroup.value.dataEmissaoDe);
        }

        if (this.formFiltrar.formGroup.value.dataEmissaoAte) {
            this.formFiltrar.formGroup.value.dataEmissaoAte = this.service.formataDateBD(this.formFiltrar.formGroup.value.dataEmissaoAte);
        }

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        for (const [chave, valor] of Object.entries(this.filtrosCabecalhos)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.calculaBadgeFiltros();

        this.service.retornaSefazErpDetalhes(this.currentParams, this.currentSearch, this.service.url).subscribe((response) => {

            this.checkedItemsNfe.clear();
            this.checkedItemsCte.clear();

            this.items = [];

            this.items = response?.data || response;

            this.loadings.table = false;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        });

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;
        this.qtdFiltrosCabecalhoAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

        if (typeof this.formFiltrarCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrarCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosCabecalhoAtivos++;
                }

            }

        }

    }

    addRemoveFilter(chave: string): void {


        this.filtrosCabecalhos[chave] = this.filtrosCabecalhos[chave] ? 0 : 1;

        for (const [chaveFiltro, valorFiltro] of Object.entries(this.filtrosCabecalhos)) {
            if (chaveFiltro !== chave) {
                this.filtrosCabecalhos[chaveFiltro] = 0;
            }
        }

        this.zerarFormFiltrar();

        this.queryTableErp(this.currentParams, this.currentSearch);

    }


    modalFiltrarCabecalho(visible) {
        this.formFiltrarCabecalho.modalVisible = visible;
        this.cdr.detectChanges();
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
        this.cdr.detectChanges();
    }

    cardQuebraSequencia(visible) {
        this.cardQuebraVisible = visible;
        this.cardNotaVisible = !visible;
        this.cdr.detectChanges();
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        this.queryTableErp(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    confirmaFiltrarCabechalho(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        this.queryTableErp(this.currentParams, this.currentSearch);

        this.modalFiltrarCabecalho(false);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    modalMotivo(visible: boolean): void {
        this.formMotivo.modalVisible = visible;
    }

    modalRetornoManifestacoes(visible: boolean): void {
        this.modalStatusManifestacaoVisible = visible;
    }

    confirmaExportar(tipo: string): void {

        this.loadings[tipo] = true;
        this.service.solicitarExportar(
            this.currentParams.filter, tipo, this.dadosAnalise.dados.empresa_id, this.dadosAnalise.dados.checkSefazErpSumario_id
        ).subscribe((res) => {

            this.loadings[tipo] = false;

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.formExportar.modalVisible = false;

            this.formExportar.formGroup.reset();

            this.openTab('DownloadCenterComponent', '', {});

        });

    }

    confirmaExportarSemFiltros(tipo: string): void {

        this.loadings[tipo] = true;
        this.service.solicitarExportar(
            [], tipo, this.dadosAnalise.dados.empresa_id, this.dadosAnalise.dados.checkSefazErpSumario_id
        ).subscribe((res) => {

            this.loadings[tipo] = false;

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.formExportar.modalVisible = false;

            this.formExportar.formGroup.reset();

            this.openTab('DownloadCenterComponent', '', {});

        });

    }

    limparFiltros(): void {

        this.zerarFormFiltrar();

        this.currentParams.filter = [];
        this.currentSearch = '';
        this.pesquisar = null;

        this.queryTableErp(this.currentParams, this.currentSearch);

    }


    zerarFormFiltrar(): void {
        this.formFiltrar.formGroup.reset();
    }

    searsh(): void {
        this.currentSearch = this.pesquisar.length > 0 ? this.pesquisar : null;
        this.queryTableErp(this.currentParams, this.currentSearch);
    }

    registrarManifestacaoConfirm(id = null, evento) {

        let title = '';
        const total = this.checkedItemsCte.size + this.checkedItemsNfe.size;

        if (evento === 1 || evento === 2 || evento === 3 || evento === 4) {
            if (total === this.checkedItemsNfe.size) {
                title = 'Deseja manifestar a(s) NFe(s) selecionada(s)?';
            } else {
                title = 'Das ' + total + ' notas selecionadas, apensas ' + this.checkedItemsNfe.size +
                    ' serão manifestadas como ' + this.tiposManifestacao[evento] + '.';
            }
        } else if (evento === 5) {
            if (total === this.checkedItemsCte.size) {
                title = 'Deseja manifestar a(s) Cte(s) selecionada(s)?';
            } else {
                title = 'Das ' + total + ' notas selecionadas, apensas ' + this.checkedItemsCte.size +
                    ' serão manifestadas como ' + this.tiposManifestacao[evento] + '.';
            }
        }

        this.modalService.confirm({
            nzTitle: title,
            nzOkText: 'Manifestar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.registrarManifestacao(id, evento)
        });

    }

    registrarManifestacao(id = null, evento) {

        const motivo = this.formMotivo.formGroup.value.motivo;

        if (!motivo && evento === 3) {
            this.toastService.error('Por favor, digite o motivo');
            return;
        }

        let ids = [];
        if (evento === 1 || evento === 2 || evento === 3 || evento === 4) {
            ids = Array.from(this.checkedItemsNfe);
        } else if (evento === 5) {
            ids = Array.from(this.checkedItemsCte);
        }

        const dados = {
            ids,
            evento,
            motivo
        };

        this.service.registrarManifestacao(dados).subscribe((response: any) => {


            if (response.dados) {
                this.arrayStatusManifestacao = response.dados;
                this.modalRetornoManifestacoes(true);

            } else {
                this.toastService.success('Nota(s) Manifestada(s) com sucesso!');
            }

            this.formMotivo.modalVisible = false;

            this.checkedItemsNfe.clear();
            this.checkedItemsCte.clear();

            this.queryTableErp(this.currentParams, this.currentSearch);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    downloadXml(modelo: any, chave): void {

        this.service.downloadXml(chave).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/xml'});

            let pos = '';

            switch (Number(modelo)) {
                case 55: {
                    pos = '-ProcNFe';
                    break;
                }
                case 57: {
                    pos = '-ProcCTe';
                    break;
                }

            }

            fileSaver.saveAs(blob, chave + pos + '.xml');

        });

    }

    downloadDanfe(modelo: any, chave): void {

        this.service.downloadDanfe(chave).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            let pos = '';
            switch (Number(modelo)) {
                case 55: {
                    pos = '-ProcNFe';
                    break;
                }
                case 57: {
                    pos = '-ProcCTe';
                    break;
                }

            }

            fileSaver.saveAs(blob, chave + pos + '.pdf');

        });

    }


    scrollLeft() {

        const element = this.basicTable.nativeElement.children[0].children[0].children[0].children[0].children[0];

        element.scrollTo({
            left: (element.scrollLeft - 300),
            behavior: 'smooth'
        });

    }

    scrollRight() {

        const element = this.basicTable.nativeElement.children[0].children[0].children[0].children[0].children[0];

        element.scrollTo({
            left: (element.scrollLeft + 300),
            behavior: 'smooth'
        });

    }

    onItemCheckedSefaz(id: string, modelo: string, checked: boolean): void {

        this.updateCheckedSetSefaz(id, modelo, checked);

    }

    updateCheckedSetSefaz(id: string, modelo: any, checked: boolean): void {

        switch (Number(modelo)) {
            case 55: {
                if (checked) {
                    this.checkedItemsNfe.add(id);
                } else {
                    this.checkedItemsNfe.delete(id);
                }
                break;
            }
            case 57: {
                if (checked) {
                    this.checkedItemsCte.add(id);
                } else {
                    this.checkedItemsCte.delete(id);
                }
                break;
            }
        }

    }

    onAllCheckedSefaz(checked: boolean): void {
        this.items.filter(({disabled}) => !disabled).forEach((item) => {
            this.updateCheckedSetSefaz(item.chave, item.modelo, checked);
        });
    }

    limitDate(data): void {
        this.habilitarEmissaoData = true;
        this.formFiltrar.formGroup.get('dataEmissaoAte').setValue(null);
        this.emissaoDataLimit = data;
    }

    limitDateCompetencia(data): void {
        this.habilitarEmissaoData = true;
        this.formFiltrar.formGroup.get('dataEmissaoAte').setValue(null);
        this.competenciaDataLimit = data;
    }

    disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.emissaoDataLimit) < 0 ||
        differenceInCalendarDays(current, this.emissaoDataLimit) > 60

    disabledDateCompetencia = (current: Date): boolean => differenceInCalendarDays(current, this.competenciaDataLimit) < 0 ||
        differenceInCalendarDays(current, this.competenciaDataLimit) > 60

}

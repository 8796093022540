import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StatusComAnexosService} from './status-com-anexos.service';
import {ToastrService} from 'ngx-toastr';
import * as fileSaver from 'file-saver-es';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-status-com-anexos',
    templateUrl: './status-com-anexos.component.html',
    styleUrls: ['./status-com-anexos.component.scss']
})

export class StatusComAnexosComponent implements OnInit {

    @Input() arquivo: any;
    @Input() nomeValido: any;

    anexos = [];

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalFiltrarVisible = false;
    modalPdfVisible = false;
    formFiltrar: UntypedFormGroup;
    cadastrando = false;

    expandSet = new Set<number>();

    column1: ElementRef;
    column1Width = 0;
    column2Width = 0;
    column3Width = 0;
    column4Width = 0;
    column5Width = 0;

    loadingContadores = true;

    dadosCabecalho: any = {};

    formFiltrosCabecalho: FormStack;
    formFiltrosGrid: FormStack;

    anoAtivo = 0;
    mesAtivo = 0;

    arraySelectObrigacoes = [];
    arraySelectCategorias = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectGrupos = [];

    qtdFiltrosAtivos = 0;

    situacao = null;

    tabAtiva = 0;

    currentUser;
    screenWidth = 0;

    arquivoUrl: string;

    loadings = {
        pdf: false,
        zip: false,
        pdfId: null,
        pdfName: null,
        zipId: null,
    };

    pdfSelecionado: any;
    pdfSelecionadoNome = '';
    statusOne = false;

    modalVerAnexosVisible = false;
    listOfAnexos = [];

    arrayStatusDescricao = {
        0: 'Não Iniciado',
        1: 'Em Execução',
        2: 'Transmitida',
        3: 'Erro ao Transmitir',
        99: 'Em Execução (99)',
    };

    constructor(
        private fb: UntypedFormBuilder,
        private service: StatusComAnexosService,
        private toastService: ToastrService,
    ) {

    }

    ngOnInit(): void {

        this.arquivo.anexos?.forEach(a => {
            if (a.arquivoNome.includes(this.nomeValido) && a.tipo === 'zip') {
                this.anexos.push(a);
            }
        });

        if (this.nomeValido === 'relatorio-pva') {
            this.arrayStatusDescricao[2] = 'Processado';
            this.arrayStatusDescricao[3] = 'Erro ao Processar';
        }
    }

    modalAnexos(visible: boolean, anexos = []): void {
        if (visible && (!anexos || anexos.length === 0)) {
            this.listOfAnexos = [];
            this.toastService.warning('Sem arquivos para exibir');
        } else {
            this.listOfAnexos = anexos;
            this.modalVerAnexosVisible = visible;
        }
    }

    baixarZip(arquivo: any): void {
        this.arquivoUrl = '';
        this.loadings.zip = true;
        this.loadings.zipId = arquivo.obrigacaoEmpresaAnexo_id;
        this.service.retornaZip(arquivo.arquivoNome, arquivo.obrigacaoEmpresaAnexo_id).subscribe((res) => {

            const blob = new Blob([res], {type: 'application/zip; charset=utf-8'});

            fileSaver.saveAs(blob, arquivo.arquivoNome);

            this.loadings.zip = false;
            this.loadings.zipId = null;
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.zip = false;
            this.loadings.zipId = null;
        });
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({
    providedIn: 'root'
})

export class ArmazenamentosDetalhesService extends AbstractService<any> {
    baseUrl = environment.apiUrl + '/check/armazenamentos/';

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornaSelectsObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/armazenamentos/select`);
    }

    retornaSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaSelectsUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarCabecalho(obrigacao, ano, mes): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/armazenamentos/cabecalho/${obrigacao}/${ano}/${mes}`);
    }


    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10 && mes.toString().length === 1) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    reprocessarRegistro(md5): Observable<any> {
        return this.http.post(`${environment.apiUrl}/validador-sped/solicitacao`, {md5});
    }

    exportDocumento(id, orgao): any {

        const url = `${environment.apiUrl}/drive/obrigacoes/${orgao.toLowerCase()}/download/${id}`;

        return this.http.get(url, {responseType: 'blob'});

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    formataCompetencia(valor): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    iniciarConciliacao(arrayMd5): Observable<any> {

        const body = {
            md5: arrayMd5
        };

        return this.http.post(`${environment.apiUrl}/check/conciliacoes/solicitar/comparativo-imposto`, body);

    }

    exportExcel(filtros: any): any {

        let queryStr = '';

        filtros.forEach(f => {
            queryStr += `&${f.key}=${f.value}`;
        });

        return this.http.post(`${environment.apiUrl}/exportador?tipoExportador=xlsx&tipo=checkArmazenamento` + queryStr,
            {});

    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComplianceConfiguracoesComponent} from './compliance-configuracoes.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UiModule} from "../../ui.module";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzBadgeModule} from "ng-zorro-antd/badge";


@NgModule({
  declarations: [
    ComplianceConfiguracoesComponent
  ],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        NzListModule,
        NzButtonModule,
        NzGridModule,
        NzModalModule,
        NzFormModule,
        NzSelectModule,
        ReactiveFormsModule,
        UiModule,
        NzDatePickerModule,
        FormsModule,
        NzBadgeModule
    ]
})
export class ComplianceConfiguracoesModule { }

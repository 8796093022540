<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="SPED x Notas">
    <nz-page-header-extra>
        <div class="d-flex align-items-center">
            <nz-alert nzType="info" *ngIf="checkedItems.size > 0" nzShowIcon [nzMessage]="checkedMessage"
                      style="margin-right: 15px;">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }}
                            <span *ngIf="checkedItems?.size === 1">Registro selecionado -
                                <a (click)="onAllChecked(!checked)">
                                    {{ checked ? 'Desmarcar todos' : 'Selecionar todos' }}</a></span>
                            <span *ngIf="checkedItems?.size > 1">Registros selecionados -
                                <a (click)="onAllChecked(!checked)">
                                {{ checked ? 'Desmarcar todos' : 'Selecionar todos' }}
                            </a></span>
                            <i nz-icon nzType="close" nzTheme="outline" style="margin-left: 10px; cursor: pointer"
                               (click)="closeSelecionados();"></i>
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-button-group>
                <button nz-button nzType="primary" nzGhost
                        style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                        (click)="retornaDados(true);"
                >
                    <i nz-icon nzType="reload" nzTheme="outline"></i>
                    Atualizar
                </button>
                <button nz-button nzType="primary" nzGhost
                        (click)="modalCadastrar(true);"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Nova
                </button>

                <button [nzDropdownMenu]="menuAcoes" nzType="primary" nzGhost
                        nz-button
                        nz-dropdown>
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>

                <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }">
                    <button nz-button nzType="primary" nzGhost (click)="modalFiltrar(true);"
                            style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item (click)="checkedItems?.size > 0 && reprocessar();">
                                    Reprocessar
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item (click)="checkedItems?.size > 0 && remover();">
                                    Remover
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0" nz-submenu
                                    nzTitle="Adicionar marcador">

                                    <ul style="max-height: 300px; overflow-y: auto">
                                        <li *ngFor="let m of arrayMarcadores" nz-menu-item
                                            (click)="novoMarcador(m.id)">
                                            <nz-badge nzSize="default" style="margin-right: 5px"
                                                      nzColor="{{m.cor}}"></nz-badge>
                                            {{ m.marcador }}
                                        </li>
                                    </ul>


                                    <button nz-button nzType="link" (click)="showModalNovoMarcador(true)">
                                        Novo marcador
                                    </button>

                                </li>
                                <li [nzDisabled]="checkedItems.size === 0" nz-menu-item
                                    (click)="checkedItems.size && modalExportar(true);">Exportar Análises
                                </li>

                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </nz-button-group>
        </div>
    </nz-page-header-extra>
</nz-page-header>
<ng-container *ngFor="let item of items">
    <nz-card class="list-card" [nzLoading]="dadosLoading"
             [nzBodyStyle]="{'padding-top': '15px', 'padding-bottom': '15px'}">
        <nz-content>
            <nz-row>
                <nz-col [nzSpan]="15" class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <label style="margin-right: 10px; margin-left: -10px" nz-checkbox
                               (nzCheckedChange)="onItemChecked(item.id, $event)"
                               [nzChecked]="checkedItems.has(item.id)"></label>
                        <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px;"
                             class="d-flex justify-content-center align-items-center">
                            <svg width="40" height="40" [data-jdenticon-value]="item?.unidade"></svg>
                        </div>
                        <div style="margin-left: 15px;">
                            <div style="margin-bottom: 10px">
                                <strong>
                                    {{ modoDemo(item?.unidade, 'empresaCodigoNome') }}
                                </strong>
                                ({{ modoDemo(item?.cnpj | cpfCnpj, 'cnpj') }})
                            </div>
                            <div style="margin-bottom: 10px">
                                <span style="margin-right: 5px; font-weight: 500; color: #6b6f82;">
                                    <i class="far fa-calendar"></i>
                                    {{ (item.dataInicio | date: 'dd/MM/yyyy') + ' à ' + (item.dataFim | date: 'dd/MM/yyyy') }}
                                </span>
                                <span style="color: #28d094;" *ngIf="item.spedAlterados > 0">
                                    <i class="fas fa-check-circle"></i>
                                    {{ item.spedAlterados + ' SPED(s) alterados' }}
                                </span>

                            </div>
                            <div>
                                <nz-tag nzColor="{{item.statusCor}}">{{ item.statusDescricao }}</nz-tag>
                                <!--<nz-avatar nzText="AL"></nz-avatar>-->
                                <span style="font-weight: 500;color: #6b6f82;">{{ item.cadastroUsuarioNome ? item.cadastroUsuarioNome : '-' }}
                                    solicitou em {{ (item.cadastroData | date:'dd/MM/yyyy HH:mm') }}</span>
                            </div>
                            <div class="boxMarcadores">
                                <div *ngFor="let m of item?.marcadores">

                                     <span *ngIf="m.marcador !== 'Compliance LDC' else marcadorLDC" class="marcador"
                                           [nz-tooltip]="'Clique para remover o marcador'"
                                           (click)="m.marcador !== 'Compliance LDC' && removerMacador(m.id, item.id)"
                                           [ngStyle]="{color: '#fff', 'background-color': m.cor}">{{ m.marcador }}
                                    </span>

                                    <ng-template #marcadorLDC>
                                        <span class="marcadorLDC"
                                              [ngStyle]="{color: '#fff', 'background-color': m.cor}">{{ m.marcador }}
                                        </span>
                                    </ng-template>

                                </div>
                            </div>
                        </div>
                    </div>
                </nz-col>
                <nz-col [nzSpan]="9">
                    <nz-progress
                            nzStatus="active"
                            [nzPercent]="item.porcentagemProcessamento"
                            *ngIf="item.statusProcessamento === 1"></nz-progress>
                    <nz-progress
                            [nzPercent]="0"
                            *ngIf="item.statusProcessamento === 0" [nzShowInfo]="false"></nz-progress>
                    <div class="d-flex justify-content-between align-items-center"
                         *ngIf="item.statusProcessamento === 2">
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [nz-tooltip]="'Escrituradas Corretamente'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#28d094'}">
                                <i class="far fa-file-alt"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_ec ? (item.status_ec | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [nz-tooltip]="'Escrituradas Incorretamente'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#ff9149'}">
                                <i class="far fa-file-alt"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_ei ? (item.status_ei | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [nz-tooltip]="'Não Escrituradas'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#ff4961'}">
                                <i class="far fa-file"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_ne ? (item.status_ne | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [nz-tooltip]="'XML Ausente'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#9c27b0'}">
                                <i class="fas fa-code"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_xa ? (item.status_xa | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador itemContadorDisabled"
                                 [nz-tooltip]="'Divergências'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#333333'}">
                                <i class="fas fa-not-equal"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_dv ? (item.status_dv | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador itemContadorDisabled"
                                 [nz-tooltip]="'Desconsideradas'"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : 'rgba(156,39,176,0.75)'}">
                                <i class="fa-solid fa-box-archive"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{ item.status_desconsideradas ? (item.status_desconsideradas | number:'1.0-0' : 'pt-br') : '0' }}</span>
                        </div>
                    </div>
                    <div style="text-align: center; margin-top: 8px">
                        <button nz-button nzBlock nzType="dashed" *ngIf="item.statusProcessamento === 2"
                                (click)="openTab('/compliance/spedNotasDetalhes/:id', item.id, {id: item.id})">
                            Ver Resultado
                        </button>
                        <button nz-button nzBlock nzType="dashed" disabled="true" style="margin-top: 30px;"
                                *ngIf="item.statusProcessamento !== 2"> Ver Resultado
                        </button>
                    </div>
                </nz-col>
            </nz-row>
        </nz-content>
    </nz-card>
</ng-container>

<div style="display: flex; justify-content: center" *ngIf="items?.length === 0">
    <span style="color: #8c8c8c; font-size: large; padding: 150px 0">
        Sem dados para exibir.
    </span>
</div>

<nz-pagination *ngIf="items?.length > 0" style="float: right; margin-bottom: 50px"
               (nzPageIndexChange)="changePage($event)"
               [nzPageIndex]="pagination.current_page"
               [nzTotal]="pagination.total"></nz-pagination>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">

            <nz-form-item *ngIf="comboResponsaveis?.length">
                <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzHasFeedback]="formFiltrar.formGroup.value.responsavel">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="responsavel">
                        <nz-option *ngFor="let opt of comboResponsaveis"
                                   [nzLabel]="opt"
                                   [nzValue]="opt"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Grupos Empresariais</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzHasFeedback]="this.formFiltrar.formGroup.value.grupos?.length > 0">
                    <nz-select nzShowSearch nzAllowClear
                               nzMode="multiple"
                               nzPlaceHolder="Selecione"
                               formControlName="grupos">
                        <nz-option *ngFor="let opt of arraySelectGrupos"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrar.formGroup.value.empresa_id"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataInicio">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataFim">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim"
                                    style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Marcador</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.marcador_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="marcador_id">
                        <nz-option *ngFor="let opt of arrayMarcadores"
                                   [nzLabel]="opt.marcador"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible"
          [nzTitle]="'Solicitar Análise'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCadastrar.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formCadastrar.formGroup.value.empresa_id"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false);">Cancelar</button>
        <button [nzLoading]="loadingAnalise" nz-button nzType="primary" (click)="confirmaCadastrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalNovoMarcador"
          [nzTitle]="'Criar Novo Marcador'" [nzClosable]="true"
          (nzOnCancel)="showModalNovoMarcador(false)"
          [nzWidth]="500">
    <div *nzModalContent>
        <div>
            <label style="margin: 0 15px 10px 30px">Marcador</label>
            <input style="width: 280px" nz-input [(ngModel)]="novoMarcadorDescricao"/>
        </div>
        <div style="margin-top: 15px; display: flex; align-items: center;">
            <label style="margin: 0 15px 0 65px;">Cor</label>
            <input style="width: 280px; border: 1px solid #d9d9d9; height: 35px" [(ngModel)]="corNovoMarcador"
                   type="color">
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalNovoMarcador(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="novoMarcador()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalExportarVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="true" [formGroup]="formExportar" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipoExportador" style="width: 100%;">
                        <nz-option *ngFor="let op of tiposAceitos" [nzLabel]="op.key"
                                   [nzValue]="op.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formExportar.controls.tipoExportador.value === 'xlsx'">
                <nz-form-label [nzSpan]="24">Selecione os status que deseja filtrar.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzMode="multiple" nzPlaceHolder="Todos os status selecionados por padrão."
                               formControlName="status" style="width: 100%;">
                        <nz-option *ngFor="let op of statusAceitos" [nzLabel]="op.key"
                                   [nzValue]="op.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaExportar()" [nzLoading]="loadingExportar">Exportar</button>
    </div>
</nz-modal>
<nz-page-header nzTitle="Drive" nzSubtitle="Transferência de Arquivos">
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card nzTitle="Upload via Sistema" style="height: 95%;" class="card-admin">
                <p class="p-descricao">
                    Arquivos enviados dentro do próprio sistema
                </p>
                <p class="d-flex justify-content-between">
                    <button nz-button nzType="primary"
                            (click)="openTab('MonitorArquivosTransitoriosComponentUpload', null,'upload')">Detalhar
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card nzTitle="MasterTaxAgent" style="height: 95%;" class="card-admin">
                <p class="p-descricao">
                    Arquivos enviados pelo programa MasterTaxAgent instalado no computador do usuário
                </p>
                <p class="d-flex justify-content-between">
                    <button nz-button nzType="primary"
                            (click)="openTab('MonitorArquivosTransitoriosComponentAgent',null,'agent')">Detalhar
                    </button>
                </p>
            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card nzTitle="EDI (Integração)" style="height: 95%;" class="card-admin">
                <p class="p-descricao">
                    Arquivos enviados por integração EDI (SFTP. API, SOAP, etc..)
                    <br/>
                    <br/>
                </p>
                <p class="d-flex justify-content-between">
                    <button nz-button nzType="primary"
                            (click)="openTab('MonitorArquivosTransitoriosComponentEdi',null,'edi')">Detalhar
                    </button>
                </p>
            </nz-card>
        </div>
    </div>
</nz-content>

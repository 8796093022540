<nz-page-header class="site-page-header" nzTitle="Notas" nzSubtitle="Sefaz x ERP">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 12)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 12)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{collapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content class="sefazErp">
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false">
                <ng-template *ngFor="let item of arrayMeses; index as i" swiperSlide let-data
                             class="swiper-card-competencia">
                    <div class="card-competencia ant-card pull-up card-{{i}}"
                         [ngClass]="collapseCard && 'compress'">
                        <div [ngClass]="[item.id === null ? 'ant-card-head-gray d-flex justify-content-between' : 'ant-card-head']">
                            <div>
                                <h2>{{objectMeses[item.mes - 1] | uppercase}}</h2>
                                <h3>{{item.ano}}</h3>
                            </div>
                            <a nz-button nzType="link" nzSize="small" *ngIf="item.id === null">
                                <i class="fas fa-lock" style="color:#fff;"></i>
                            </a>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <!--[ngClass]="i == 0 ? 'cardShadow' : ''" [nzTitle]=""-->
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.unidades}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <!--<div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.qtdObrigacoes | number}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>-->
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center;">
                                    <span nz-typography nzType="secondary">SEFAZ</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="color: #28d094;">{{item.sefaz}}</span>
                                        </nz-col>
                                    </nz-row>
                                    <!--<nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <nz-progress
                                                    [nzPercent]="item.porcAusencias | number: '1.0-0'"
                                                    [nzFormat]="formatOne"
                                                    [nzShowInfo]="true"
                                                    [nzStrokeColor]="'#ff9149'">
                                            </nz-progress>
                                        </nz-col>
                                    </nz-row>-->
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center;">
                                    <span nz-typography nzType="secondary">Armazenadas</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center; color: #ff0000;">
                                            <span style="color: #ff9149;">{{item.armazenadas}}</span>
                                        </nz-col>
                                    </nz-row>
                                    <!--<nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <nz-progress [nzPercent]="item.porcDivergencias | number: '1.0-0'"
                                                         [nzFormat]="formatOne"
                                                         [nzShowInfo]="true"
                                                         [nzStrokeColor]="'#ff4961'">
                                            </nz-progress>
                                        </nz-col>
                                    </nz-row>-->
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center">
                                    <span nz-typography nzType="secondary">ERP</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center; color: #9c27b0;">
                                            <span>{{item.erp}}</span>
                                        </nz-col>
                                    </nz-row>
                                    <!--<nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <nz-progress [nzPercent]="item.porcDivergencias | number: '1.0-0'"
                                                         [nzFormat]="formatOne"
                                                         [nzShowInfo]="true"
                                                         [nzStrokeColor]="'#ff4961'">
                                            </nz-progress>
                                        </nz-col>
                                    </nz-row>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</nz-content>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="5">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                {{objectCountersValues.anoSelecionado}}
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0;">
                                {{objectCountersValues.mesSelecionado}}
                            </h1>
                            <!--<small style="position: absolute;" class="ant-page-header-heading-sub-title">
                                Atualizado em {{objectCountersValues.updated_at | date: 'dd/MM/yy HH:mm'}}</small>-->
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                        <i class="far fa-building"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important;">
                                {{objectCountersValues.unidades}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="5">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center justify-content-center">
                        <i class="fa fa-cloud"
                           style="font-size: 60px; margin-right: 15px; color:#28d094 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Sefaz
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #28d094 !important;">
                                {{objectCountersValues.sefaz}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="5">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex justify-content-center align-items-center">
                        <i class="fa fa-database" aria-hidden="true"
                           style="font-size: 60px; margin-right: 15px; color: #ff9149 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Armazenadas
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #ff9149 !important;">
                                {{objectCountersValues.armazenadas}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="5">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex justify-content-center align-items-center justify-content-center">
                        <i class="fa fa-server" aria-hidden="true"
                           style="font-size: 60px; margin-right: 15px; color: #9c27b0 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                ERP
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #9c27b0 !important;">
                                {{objectCountersValues.erp}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-card [ngClass]="[status ? 'card-fullscreen' : '', !loadingCardTabs ? 'ant-card-table' : '']"
         [nzLoading]="loadingCardTabs">
    <nz-card-tab>
        <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtiva" [nzTabBarExtraContent]="tabsExtra">
            <nz-tab nzTitle="Empresas"></nz-tab>
        </nz-tabset>
    </nz-card-tab>
    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
              *ngIf="checkedItems.size > 0">
        <ng-template #checkedMessage>
            <div nz-row nzAlign="middle" nzGutter="24">
                <div nz-col nzSpan="24">
                    <strong class="text-primary"></strong>
                    {{ checkedItems.size }} Registros Selecionados
                </div>
            </div>
        </ng-template>
    </nz-alert>
    <nz-table #tableObrigacoes
              [nzData]="items"
              [nzFrontPagination]="false"
              [nzTotal]="pagination?.total"
              [nzPageSize]="pagination?.per_page"
              [nzPageIndex]="pagination?.current_page"
              [nzShowTotal]="rangeTemplate"
              (nzQueryParams)="listByTable($event)"
              class="scrollbar table-small"
    >
        <thead>
        <tr>
            <!--<th nzAlign="center">
                <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
            </th>-->
            <th nzAlign="center" nzColumnKey="nome" [nzSortFn]="true">Empresa</th>
            <th nzAlign="center" nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true">Grupo</th>
            <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
            <th nzAlign="center" nzColumnKey="ie" [nzSortFn]="true">IE</th>
            <th nzAlign="center" nzColumnKey="sefaz" [nzSortFn]="true">Sefaz</th>
            <th nzAlign="center" nzColumnKey="armazenadas" [nzSortFn]="true">Armazenadas</th>
            <th nzAlign="center" nzColumnKey="erp" [nzSortFn]="true">ERP</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of tableObrigacoes.data">
            <!--<td nzLeft (nzCheckedChange)="onItemChecked(data.id, $event)"
                [nzChecked]="checkedItems.has(data.id)"
                [nzDisabled]="data.disabled">
            </td>-->
            <td>
                {{data.nome}}
            </td>
            <td>{{data.grupoEmpresarial_descricao}}</td>
            <td nzAlign="center">{{data.cnpj | cpfCnpj}}</td>
            <td nzAlign="center">{{data.ie}}</td>
            <td nzAlign="center">
                <button nz-button nzType="link"
                        (click)="openTab('SefazErpDetalhesComponent', '', {id: data.id, extraFilter: 'temSefaz', cnpj: data.cnpj})">
                {{data.qtdSefaz ? (data.qtdSefaz | number: '1.0-0') : 0}}
                </button>
            </td>
            <td nzAlign="center">
                <button nz-button nzType="link"
                        (click)="openTab('SefazErpDetalhesComponent', '', {id: data.id, extraFilter: 'temArmazenadas', cnpj: data.cnpj})">
                    {{data.qtdArmazenadas ? (data.qtdArmazenadas | number: '1.0-0') : 0}}
                </button>
            </td>
            <td nzAlign="center">
                <button nz-button nzType="link"
                        (click)="openTab('SefazErpDetalhesComponent', '', {id: data.id, extraFilter: 'temErp', cnpj: data.cnpj})">
                    {{data.qtdErp | number: '1.0-0'}}
                </button>

                </td>
        </tr>
        </tbody>
    </nz-table>
    <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
    </ng-template>
</nz-card>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <button nz-button nzType="primary" nzGhost
                (click)="modalUpload(true)"
                style="margin-right: 10px; border-radius: 0.45rem;">
            <i nz-icon nzType="upload"></i>
            Novo upload
        </button>

        <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button nz-button [nzType]="'primary'" (click)="abrirModal(formFiltros)"
                    style="margin-right: 0;">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
        </nz-badge>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearch();">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltros)" [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltros.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.empresa_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltros.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') +
                                        ' (' + modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj') + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.grupo">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="grupo">
                            <nz-option *ngFor="let opt of comboGrupos"
                                       [nzLabel]="modoDemo(opt.label)"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">IE</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.ie !== '' && formFiltros.formGroup.value.ie !== null">
                        <input nz-input type="text" formControlName="ie" maxlength="20" size="60"/>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltros)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalUploadVisible"
          [nzTitle]="'Upload de Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalUpload(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="false" [formGroup]="formUpload.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">ERP</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip="Campo obrigatório!">
                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="erp">
                        <nz-option *ngFor="let opt of arrayFiltrosTipos"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
        <p style="margin: 0px;">Selecione o arquivo (.csv) para upload.</p>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    [(nzFileList)]="fileListUpload" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalUpload(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.upload" (click)="confirmarUploadArquivo()">Enviar arquivo</button>
    </div>
</nz-modal>

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Inscrições e Regimes">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze" [nzExtra]="navBar" nzTitle="Inscrições e Regimes"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva" *ngIf="usuarioLogado.origem == 'console'">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item (click)="importarExportarEmbedded('importar');" nz-tooltip
                                    nzTooltipTitle="Usuário Console">
                                    Importar Embedded
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item (click)="importarExportarEmbedded('exportar');" nz-tooltip
                                    nzTooltipTitle="Usuário Console">
                                    Exportar Embedded
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loading"
                                    nz-menu-item (click)="atualizarDadosCadastraisRFB();">
                                    Atualizar dados cadastrais RFB
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loading"
                                    nz-menu-item (click)="showConfirmatualizarIEViaPortal();">
                                    Atualizar IE/IE ST via portal
                                </li>
                            </ul>
                        </li>
                        <li [nzDisabled]="loading" nz-menu-item (click)="atualizarRFB();">
                            Atualizar Situação na RFB
                        </li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                        <li nz-menu-item nz-button (click)="modalImportarRegime(true);">Importar Planilha Regime
                        </li>
                        <li nz-menu-item nz-button (click)="modalImportarIeiest(true);">Importar Planilha IE/IEST
                        </li>
                        <li nz-menu-item nz-button (click)="modalImportarIm(true);">Importar Planilha IM</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group
                        nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" (keydown.enter)="buscar()" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all" *ngIf="usuarioLogado.origem == 'console'">
                </th>
                <!--<th nzColumnKey="id" [nzSortFn]="true">ID</th>-->
                <th nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="nome" [nzSortFn]="true">Empresa</th>
                <th nzColumnKey="situacaoCadastral" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                    Situação Cadastral RFB
                </th>
                <th nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                    Grupo
                </th>
                <th nzColumnKey="uf" [nzSortFn]="false" nzShowSort="false" nzAlign="center">UF</th>
                <th nzColumnKey="cidade" [nzSortFn]="true" nzAlign="center">Cidade</th>
                <!--<th nzColumnKey="matriz" [nzSortFn]="true">Matriz</th>-->
                <th nzColumnKey="empresaInscricaoFederal_documento" [nzSortFn]="false" nzShowSort="false"
                    nzAlign="center">
                    Inscrição Federal
                </th>
                <th nzColumnKey="regimeTributario" [nzSortFn]="false" nzShowSort="false" nzAlign="center">Regime
                    Tributário
                </th>
                <th nzAlign="center">IE Isento</th>
                <th nzColumnKey="empresaInscricaoEstadual_quantidade" [nzSortFn]="false" nzShowSort="false"
                    nzAlign="center">
                    Inscrição Estadual
                </th>
                <th nzColumnKey="empresaInscricaoEstadualST_quantidade" [nzSortFn]="false" nzShowSort="false"
                    nzAlign="center">Inscrição
                    Estadual ST
                </th>
                <th nzColumnKey="empresaInscricaoMunicipal_quantidade" [nzSortFn]="false" nzShowSort="false"
                    nzAlign="center">Inscrição
                    Municipal
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let inscricoesRegimes of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(inscricoesRegimes.cnpj, $event)"
                    [nzChecked]="checkedItems.has(inscricoesRegimes.cnpj)"
                    [nzDisabled]="inscricoesRegimes.disabled"
                    *ngIf="usuarioLogado.origem == 'console'">
                </td>
                <!--<td nzLeft>{{inscricoesRegimes?.id}}</td>-->
                <td nzLeft>{{modoDemo(inscricoesRegimes?.codigo, 'total')}}</td>
                <td nzLeft [noWrap]="1">{{modoDemo(inscricoesRegimes?.cnpj | cpfCnpj, 'cnpj')}}</td>
                <td nzLeft>{{modoDemo(inscricoesRegimes?.nome, 'empresaCodigoNome')}}</td>
                <td nzLeft
                    nzAlign="center">{{inscricoesRegimes?.situacaoCadastral ? inscricoesRegimes?.situacaoCadastral : '-' }}</td>
                <td nzLeft nzAlign="center">{{modoDemo(inscricoesRegimes?.grupoEmpresarial_descricao)}}</td>
                <td nzLeft nzAlign="center">{{inscricoesRegimes?.uf ? inscricoesRegimes?.uf : '-'}}</td>
                <td nzLeft nzAlign="center">{{inscricoesRegimes?.cidade ? inscricoesRegimes?.cidade : '-'}}</td>
                <td nzLeft
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoFederal_quantidade ? inscricoesRegimes.empresaInscricaoFederal_quantidade + ' Ativa(s)' : '-'}}</td>
                <td nzLeft
                    nzAlign="center">{{inscricoesRegimes.regimeTributario ? inscricoesRegimes.regimeTributario : '-'}}</td>
                <td nzAlign="center">{{inscricoesRegimes.ieIsento ? inscricoesRegimes.ieIsento : '-'}}
                </td>
                <td nzLeft *ngIf="inscricoesRegimes.empresaInscricaoEstadualAtiva_quantidade > 0"
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoEstadualAtiva_quantidade + ' Ativa(s)'}}
                </td>
                <td nzLeft
                    *ngIf="inscricoesRegimes.empresaInscricaoEstadualAtiva_quantidade == 0 && inscricoesRegimes.empresaInscricaoEstadualInativo_quantidade > 0"
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoEstadualInativo_quantidade + ' Inativa(s)'}}
                </td>
                <td nzLeft
                    *ngIf="inscricoesRegimes.empresaInscricaoEstadualAtiva_quantidade == 0 && inscricoesRegimes.empresaInscricaoEstadualInativo_quantidade == 0"
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoEstadualAtiva_quantidade + ' Ativa(s)'}}
                </td>
                <td nzLeft
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoEstadualST_quantidade ? inscricoesRegimes.empresaInscricaoEstadualST_quantidade + ' Ativa(s)' : '-'}}</td>
                <td nzLeft
                    nzAlign="center">{{inscricoesRegimes.empresaInscricaoMunicipal_quantidade ? inscricoesRegimes.empresaInscricaoMunicipal_quantidade + ' Ativa(s)' : '-'}}</td>
                <td nzRight nzAlign="center">
                    <button (click)="openTab('/cadastros/editar-inscricoes-regimes/', inscricoesRegimes.id, {id: inscricoesRegimes.id})"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Código</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.codigo !== '' && formFiltrar.formGroup.value.codigo !== null">
                    <input nz-input
                           formControlName="codigo" type="text"
                           autocomplete="off" size="60" nzAllowClear/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cnpj">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cnpj"
                               (ngModelChange)="alterarFiltroEmpresa(formFiltrar.formGroup.value.cnpj);">
                        <nz-option *ngFor="let opt of arraySelectCnpj"
                                   [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                   [nzValue]="opt.descricao"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{opt.nome}}
                            <br><small>({{opt.cnpj | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupoEmpresarial">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="grupo">
                        <nz-option *ngFor="let opt of comboGrupos"
                                   [nzLabel]="modoDemo(opt.label)"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">UF / Cidade</nz-form-label>
                <nz-form-control [nzSpan]="5"
                                 nzValidateStatus="success">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'UF'" formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formFiltrar.formGroup.value.uf)">
                        <nz-option *ngFor="let opt of comboUfs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-control [nzSpan]="7" [nzOffset]="1"
                                 nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cidade">
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Situação Cadastral</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacaoCadastral">
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativa'"></nz-option>
                        <nz-option [nzLabel]="'Inativa'" [nzValue]="'Inativa'"></nz-option>
                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspensa'"></nz-option>
                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapta'"></nz-option>
                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixada'"></nz-option>
                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nula'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Receita Federal</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresaInscricaoFederal_documento">
                        <nz-option [nzLabel]="'Inativa'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'1'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Regime Tributário</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="regimeTributario">
                        <nz-option [nzLabel]="'Lucro Presumido'" [nzValue]="'LP'"></nz-option>
                        <nz-option [nzLabel]="'Lucro Real Anual'" [nzValue]="'LRA'"></nz-option>
                        <nz-option [nzLabel]="'Lucro Real Trimestral'" [nzValue]="'LRT'"></nz-option>
                        <nz-option [nzLabel]="'Simples Nacional'" [nzValue]="'SN'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal [(nzVisible)]="modalImportarPlanilhaRegime"
          [nzTitle]="'Importar Planilha Regime'" [nzClosable]="true"
          (nzOnCancel)="modalImportarRegime(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
        <small style="">Clique <a nz-link (click)="downloadModelo('regime');">aqui</a> para baixar um arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileListRegime" [nzBeforeUpload]="beforeUploadRegime">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarRegime(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.upload" (click)="confirmarImportarRegime()">
            Enviar arquivo
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalImportarPlanilhaIeIest"
          [nzTitle]="'Importar Planilha IE/IE ST'" [nzClosable]="true"
          (nzOnCancel)="modalImportarIeiest(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
        <small style="">Clique <a nz-link (click)="downloadModelo('ieiest');">aqui</a> para baixar um arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileListIeiest" [nzBeforeUpload]="beforeUploadIest"
                    [nzAccept]="'.xlsx'"
                    [nzMultiple]="false"
            >
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarIeiest(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.upload" (click)="confirmarImportarIeIest()">
            Enviar arquivo
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarPlanilhaIm"
          [nzTitle]="'Importar Planilha IM'" [nzClosable]="true"
          (nzOnCancel)="modalImportarIm(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
        <small style="">Clique <a nz-link (click)="downloadModelo('im');">aqui</a> para baixar um arquivo de
            modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileListIm" [nzBeforeUpload]="beforeUploadIm">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarIm(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.upload" (click)="confirmarImportarIm()">
            Enviar arquivo
        </button>
    </div>
</nz-modal>

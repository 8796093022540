import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class NotifyTestService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/notificacao/notifyTest', {});
    }

    enviarNotificacao(tipo, id = null, mensagem): Observable<any> {

        const data = id ? {mensagem, usuario_id: id} : {mensagem};

        return this.http.post(`${environment.apiUrl}/notificacoes/enviar/${tipo}`, data);

    }

    retornarSelectUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select/console`);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class NotasfiscaisEletronicasService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricoesRegimes', null);
    }

    retornarOptionEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    listar(params: NzTableQueryParams): Observable<any> {

        const qStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/dfe/tabela${qStr}`);
    }

    exportExcel(filtros: any, search: string = null, tipoExportador: string): any {

        filtros.tipo = 'notasFiscaisEletronicasConfiguracoes';
        filtros.tipoExportador = tipoExportador;

        if (search) {
            filtros.procurar = search;
        }

        if (!filtros.ativaDFE) {
            delete filtros.ativaDFE;
        }

        if (!filtros.empresa_id) {
            delete filtros.empresa_id;
        }

        return this.http.post(`${environment.apiUrl}/exportador`, filtros);
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Conta } from '../../shared/models/conta.model';
import { AbstractService } from './abstract.service';
import { Response } from '../../shared/models/response.model';
import {environment} from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class ContaService extends AbstractService<Conta> {

    constructor(http: HttpClient) {
        super(http, '/admin/conta', Conta);
    }

    resolve() {
        return this.list();
    }

    modulosAtivos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/administracao/modulos`);
    }

    users(page: number = 1): Observable<Response<any>> {
        const newBaseUrl = `${ this.baseUrl }-usuario?page=${ page }`;
        return this.http.get<Response<any>>(`${ newBaseUrl }`);
    }

    profiles(page: number = 1): Observable<Response<any>> {
        const newBaseUrl = `${ this.baseUrl }/perfil`;
        return this.http.get<Response<any>>(`${ newBaseUrl }`);
    }
}

import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {ReceitaFederalService} from './receitaFederal.service';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {HostListener} from '@angular/core';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
    selector: 'app-receitaFederal',
    templateUrl: './receitaFederal.component.html',
    styleUrls: ['./receitaFederal.component.scss']
})
export class ReceitaFederalComponent extends AbstractListTable<any> implements AfterViewInit, TabHandlerInterface {

    arraySelectEmpresa = [];

    statusOne = false;
    currentUser: any;
    qtdFiltrosAtivos = 0;
    loading = false;

    screenWidth = 0;
    currentCnpj = '';

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private service: ReceitaFederalService,
        private i18n: NzI18nService) {

        super(service, null, toastService);

        this.resizeTable();

        this.i18n.setLocale(pt_BR);
    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.dispachResizeTable();
    }

    ngAfterViewInit() {
        this.dispachResizeTable();
    }

    dispachResizeTable() {
        this.screenWidth = Math.round(window.innerWidth - 70);
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentCnpj = this.padronizaCnpj(search);

        this.carregaReceitaFederal();
        this.resetCheckedSet();
    }

    carregaReceitaFederal(): void {

        this.loading = true;

        this.service.retornaReceitaFederal(this.currentCnpj).subscribe((response: any) => {

            this.padronizaDados(response);

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            setTimeout(() => {
                this.loading = false;
                this.cdr.detectChanges();
            }, 500);
        });
    }

    padronizaDados(response): any {

        if (!!response.total) {
            let esqueleto: any = this.retornaEsqueletoDadosForm();
            let data;
            const result = [];

            if (response.itens[this.currentCnpj]['receita-empresa']) {
                data = response.itens[this.currentCnpj]['receita-empresa'];
            }

            if (response.itens[this.currentCnpj]['receita-socio']) {
                data = response.itens[this.currentCnpj]['receita-socio'];
            }

            if (response.itens[this.currentCnpj]['receita-estabelecimento']) {
                data = response.itens[this.currentCnpj]['receita-estabelecimento'];
            }

            for (const obj in data) {
                // tslint:disable-next-line:forin
                for (const value in esqueleto) {
                    (data[obj][value] !== undefined) ? esqueleto[value] = data[obj][value] : esqueleto[value] = '';
                }

                if (!esqueleto.nome && !!data.razaoSocial) {
                    esqueleto.nome = data.razorSocial;
                }

                result.push(esqueleto);
                esqueleto = this.retornaEsqueletoDadosForm();
            }

            this.items = result;
        } else {
            this.items = [];
        }
    }

    retornaEsqueletoDadosForm(): object {

        const dados = {
            'cnpj': '',
            'cnpjOrdem': '',
            'cnpjDV': '',
            'situacaoCadastral': '',
            'situacaoCadastralData': '',
            'situacaoCadastralMotivo': '',
            'nomeCidadeExterior': '',
            'inicioAtividadeData': '',
            'cnaePrincipal': '',
            'cnaeSecundaria': '',
            'logradouroTipo': '',
            'logradouro': '',
            'numero': '',
            'complemento': '',
            'bairro': '',
            'cep': '',
            'uf': '',
            'municipio': '',
            'ddd1': '',
            'telefone1': '',
            'ddd2': '',
            'telefone2': '',
            'dddFax': '',
            'telefoneFax': '',
            'email': '',
            'situacaoEspecial': '',
            'situacaoEspecialData': '',
            'tipo': '',
            'dataAtualizacao': '',
            'nome': '',
            'qualificacao': '',
            'identificador': '',
            'codigoPais': '',
            'razaoSocial': '',
            'naturezaJuridica': '',
            'capitalSocial': '',
            'porte': '',
            'enteFederativo': '',
            'documento': '',
            'entradaData': '',
            'representanteDocumento': '',
            'representanteNome': '',
            'representanteQualificacao': '',
            'faixaEtaria': '',
        };

        return dados;
    }

    limparFiltros(): void {
        this.items = null;
        this.currentSearch = '';
    }

    clickEvent(card) {

        switch (card) {
            case 'one':
                this.statusOne = !this.statusOne;
                break;
        }
    }

    padronizaCnpj(cnpj: string): string {
        cnpj = cnpj.replace(/[^\d]/g, '');
        cnpj = cnpj.substr(0, 8);

        return cnpj;
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '@models/usuario.model';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable, Subject} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {formatDate} from '@angular/common';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ErpService extends AbstractService<any> {

    baseUrl = environment.apiUrl + '/integracoes/erp';

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornaGuia(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${id}`);
    }

    detalheArquivo(arquivoNome: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/erp/detalhe/${arquivoNome}/arquivo`);
    }

    downloadArquivo(arquivoNome: string): any {
        return this.http.get(`${environment.apiUrl}/integracoes/erp/download/${arquivoNome}/arquivo`, {responseType: 'blob'});
    }

    removerGuia(id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${id}`);
    }

    retornaArquivo(id): any {

        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/retornaArquivo/${id}`,
            {responseType: 'blob'});
    }

    retornaGuiasEstaduais(status): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/estaduais/${status}`);
    }

    retornaGuiasMunicipais(status): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/municipais/${status}`);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsUfs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/filtros/uf`);
    }

    retornaIntegracoesAtivas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/erp/configuracoes/configuracoes-ativas`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    formataCompetenciaBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }


    retornaTotais(ano: number): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/competencias?ano=${ano}`);
    }

    retornaObrigacoes(ano: number, filtros): any {

        filtros.ano = ano;

        if (filtros.competenciaMesAno && typeof filtros.competenciaMesAno.getMonth === 'function') {
            filtros.competencia = filtros.competenciaMesAno.getMonth() + 1;
            filtros.ano = filtros.competenciaMesAno.getFullYear();
            filtros.competenciaMesAno = null;
        }

        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/armazenamentos${query}`);
    }

    exportExcel(filtros: any, search: string = null, tipo: string, guia: string, status: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        let url = '';
        switch (guia) {
            case 'E': {
                url = 'estaduais/' + status + '/exportar';
                break;
            }
            case 'M': {
                url = 'municipais/' + status + '/exportar';
                break;
            }
            case 'NI': {
                url = 'naoIdentificadas/exportar';
                break;
            }
        }

        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/${url}${queryStr}`,
            {responseType: 'blob'});

    }


    exportDocumento(name): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/download/${name}`,
            {responseType: 'blob'});
    }

    uploadArquivo(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/integracoes/erp/upload`, formData);
    }

    gravarArquivo(arquivoId, formData: any): Observable<any> {

        if (formData.emissaoData) {
            formData.emissaoData = this.formataDateBD(formData.emissaoData);
        }

        if (formData.vencimentoData) {
            formData.vencimentoData = this.formataDateBD(formData.vencimentoData);
        }

        if (formData.competencia) {
            formData.competencia = this.formataCompetenciaBD(formData.competencia);
        }

        return this.http.put(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${arquivoId}`, formData);

    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DiagnosticoFiscalComponent} from './diagnostico-fiscal.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NgZorroModule} from '../../../shared/ng-zorro.module';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {UiModule} from '../../ui.module';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {NzDescriptionsModule} from "ng-zorro-antd/descriptions";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzPaginationModule} from "ng-zorro-antd/pagination";


@NgModule({
  declarations: [DiagnosticoFiscalComponent],
  exports: [DiagnosticoFiscalComponent],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NgZorroModule,
        NzBadgeModule,
        FormsModule,
        NzTagModule,
        UiModule,
        NzTabsModule,
        NgxJdenticonModule,
        NzProgressModule,
        NzTypographyModule,
        NzDescriptionsModule,
        NzAlertModule,
        NzDatePickerModule,
        ReactiveFormsModule,
        NzPaginationModule,
    ]
})
export class DiagnosticoFiscalModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {GuiasLotesComponent} from './guiasLotes.component';

const routes: Routes = [

    {path: '', component: GuiasLotesComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class GuiasLotesRoutingModule {
}

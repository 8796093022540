import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StickyHeaderDirective} from '@components/directive/directive';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {DetalhesDadosEmpresaService} from './detalhes-dados-empresa.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-detalhes-dados-empresa',
    templateUrl: './detalhes-dados-empresa.component.html',
    styleUrls: ['./detalhes-dados-empresa.component.scss'],
})
export class DetalhesDadosEmpresaComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {
    @Input() data;

    description = '';

    offsetTop = 15;
    target: any;

    obrigacao: any = {};

    formAcessosDataPrev: FormStack;

    formCertificadoA1: FormStack;
    formProcuracoesECAC: FormStack;
    formProcuradoresECAC: FormStack;
    formAcessosEstaduais: FormStack;
    formAdicionarCertificado: FormStack;
    formDataPrev: FormStack;

    pagination: any = {
        estabelecimentos: new Pagination(),
        socios: new Pagination(),
    };

    currentParams: any = {
        estabelecimentos: this.abstractService.NzTableQueryParams,
        socios: this.abstractService.NzTableQueryParams
    };
    currentSearch: any = {
        estabelecimentos: '',
        socios: '',
    };

    loading: any = {
        certificados: false,
        procuracoes: false,
        procuradores: false,
        acessos: false,
        formAcessos: false,
        repicarMatries: false,
        buscarProcuracoes: false,
        senhaCertificado: false,
        download: false
    };

    erro = false;
    mensagem = '';

    apiUrl = environment.apiUrl;

    comboUFs: any = [];
    comboObrigacoes: any = [];

    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    usaCertificadoMatriz = false;

    formSenha: FormStack;

    dadosEmpresa;
    dadosSocios;
    dadosEstabelecimentos;

    loadings = {
        estabelecimentos: false,
        socios: false
    };

    dataExport: any;

    @ViewChild('cardWidth') cardWidth: any;
    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    cardWidthValue;

    constructor(
        private service: DetalhesDadosEmpresaService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef
    ) {
        super(service, null, toastService);

        this.pagination.estabelecimentos = new Pagination();

        this.formCertificadoA1 = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],

            })
        };

        this.formSenha = {
            modalVisible: false,
            formGroup: this.fb.group({
                senha: [null, Validators.required],
            })
        };

        this.formProcuracoesECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };

        this.formDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                identificador: [null, null],
                senha: [null, null],
            })
        };

        this.formProcuradoresECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };

        this.formAcessosEstaduais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, null],
                portal: [null, null],
            })
        };

        this.formAcessosDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                identificacao: [null, Validators.required],
                senha: [null, Validators.required],
            })
        };

        this.formAdicionarCertificado = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                emissor: [null, Validators.required],
                emissaoData: [null, Validators.required],
                validade: [null, Validators.required],
                tipo: [null, Validators.required],
                serial: [null, Validators.required],
                uso: [null, Validators.required],
                arquivo: [null, Validators.required],
                padrao: [null, Validators.required],
                replicar: [null, null],
            })
        };

        this.dataExport = {
            url: '',
            filtros: {},
            name: 'Estabelecimentos',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };
    }

    ngOnInit(): void {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.retornaDadosRFB(this.data.id).then((response) => {
            // this.tratarDados(response);
        });
    }

    ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;
        this.cardWidthValue = this.cardWidth.nativeElement.offsetWidth - 50;
        this.cdr.detectChanges();
    }

    async retornaDadosRFB(cnpjBase: string) {
        return this.service.retornaDadosRFB(cnpjBase).subscribe((response: any) => {
            const data = response.itens[this.data.id]['receita-empresa2'];

            this.dadosEmpresa = {
                cnpj: data[this.data.id].cnpj,
                razaoSocial: data[this.data.id].razaoSocial,
                dataAtualizacao: data[this.data.id].dataAtualizacao
            };
        });
    }

    queryTableEstabelecimentos(params: NzTableQueryParams, search: string = null): void {

        params.pageSize = 10;
        this.currentParams.estabelecimentos = params;
        this.currentSearch.estabelecimentos = search;

        this.loadings.estabelecimentos = true;

        const url = this.service.baseUrl + this.data.id + '/estabelecimentos';

        this.abstractService.listToTable(params, search, url).subscribe((response) => {


            this.dadosEstabelecimentos = [];
            this.dadosEstabelecimentos = response.data;
            this.loadings.estabelecimentos = false;

            this.dadosEstabelecimentos.forEach(value => {

                const array = value.cnaeSecundaria.split(',');

                value.cnaeSecundaria = array.join(', ');
            });

            this.pagination.estabelecimentos = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

        }, (res) => {
            this.toastService.error('Erro ao obter estabeleciomentos');
            this.loadings.estabelecimentos = false;
        });

    }

    queryTableSocios(params: NzTableQueryParams, search: string = null): void {

        params.pageSize = 10;
        this.currentParams.socios = params;
        this.currentSearch.socios = search;

        this.loadings.socios = true;

        const url = this.service.baseUrlSocios + this.data.id + '/socios';

        this.abstractService.listToTable(params, search, url).subscribe((response) => {


            this.dadosSocios = [];
            this.dadosSocios = response.data;
            this.loadings.socios = false;

            this.pagination.socios = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, (res) => {
            this.toastService.error('Erro ao obter socios');
            this.loadings.socios = false;
        });

    }

    modalExportar(visible: boolean): void {
        this.dataExport.url = `/cnpj-receita/detalhes/${this.dadosEmpresa?.cnpj}/estabelecimentos/exportar`;
        this.componentExport.visible = visible;
    }
}

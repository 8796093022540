import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class CertidoesService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacoes', Obrigacao);
    }

    retornaCertidoes(filtros: any = null): Observable<any> {
        filtros.quantidade = 100;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/certidoes/listaCertidoes${queryStr}`);

    }

    retornaContadoresOrgao(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/sumario/lista`);
    }

    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornarSelectsEmpresas(certidaoId?: string): Observable<any> {

        const params = certidaoId ? `/${certidaoId}` : '';

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/empresas${params}`);
    }

    retornarSelectsIes(certidaoId: string, empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/ie/${certidaoId}/${empresaId}`);
    }

    retornarSelectsIms(municipioCodigo: string, empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/inscricoes-municipais?municipioCodigo=${municipioCodigo}&empresa_id=${empresaId}`);
    }

    retornarSelectsIis(certidaoId: string, empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/ii/${certidaoId}/${empresaId}`);
    }

    excluirCertidaoManual(certidaoId: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/certidoes/removerManual/${certidaoId}`);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornarOptionCertidoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/certidao/select`);
    }

    retornarSelectsObrigacoes(empresaId): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/sugeridas/${empresaId}`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';

        if (valor && typeof data.getMonth === 'function') {

            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());

        } else {

            retorno = valor.toString();

        }

        return retorno;

    }

    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    insertEmpresaVencimentos(dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = this.formataDateBD(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = this.formataDateBD(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa`, dados);

    }

    cadastrarSugeridasMassa(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/cadastrar/sugeridas`, dados);

    }

    cadastrarUnica(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa`, dados);

    }

    novaCertidao(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/gravarManual`, dados);

    }


    removeEmpresasOficiais(selectedIds: Set<string> | string[]): Observable<any> {

        const dados = {
            ids: selectedIds,
        };

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/remover`, dados);

    }


    adicionarCertidoesSugeridasPorEmpresa(empresaId: string): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/cadastrar/sugeridasPorEmpresa/${empresaId}`, null);

    }

    adicionarCertidoesSugeridas(certidaoId): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/cadastrar/sugeridas/${certidaoId}`, null);

    }

    adicionarDomiciliosSugeridos(empresaId: string): Observable<any> {

        return this.http.post(
            `${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/cadastrar/sugeridasPorEmpresa/${empresaId}`, null);
    }

    editarEmpresasVencimentosOficiais(dados): Observable<any> {

        if (dados.dataInicio && dados.dataInicio !== '' && dados.dataInicio !== '') {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        } else {
            delete dados.dataInicio;
        }

        if (dados.dataFim) {
            dados.dataFim = this.formataDateBD(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/edicao-em-massa`, dados);

    }


    editarEmpresaVencimentos(dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = this.formataDateBD(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = this.formataDateBD(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/detalhe/${dados.id}`, dados);

    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaComboMunicipios(uf: string = null): Observable<any> {

        if (uf) {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);
        } else {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select`);
        }

    }

    editarCertidaoDescricaoManual(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/editarManual/${dados.certidao_id}`, {descricao: dados.descricao});

    }

    editarHabilitado(certidaoSelecionadaObj: {
        id: string;
        descricao: string;
        habilitado: boolean
    }, habilitado: boolean) {
        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/habilitado/${certidaoSelecionadaObj.id}`, {habilitado});
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class ObrigacoesEditarRegrasService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/check/editar-obrigacoes-regras', {});
    }

    editarClassificacao(id, classificacao): Observable<any> {
        return this.http.post(`${environment.apiUrl}/check/obrigacoes/regras/editar/classificacao/${id}`, {classificacao});
    }

    retornaComboPerfis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/administracao/perfis/listar`);
    }

    gravarSupervisor(obrigacaoId, perfilId) {
        return this.http.post(`${environment.apiUrl}/check/obrigacoes/regras/gravar/supervisao/${obrigacaoId}`, {perfil_id: perfilId});
    }

    retornaSupervisores(obrigacaoId) {
        return this.http.get(`${environment.apiUrl}/check/obrigacoes/regras/listar/supervisao/${obrigacaoId}`);
    }

    removerSupervisor(obrigacaoRegraPerfisId) {
        return this.http.delete(`${environment.apiUrl}/check/obrigacoes/regras/remover/supervisao/${obrigacaoRegraPerfisId}`);
    }

}

import { Component } from '@angular/core';

@Component({
    selector: 'app-profile',
    template: `
        <router-outlet></router-outlet>
    `,
})
export class ProfileComponent {
}

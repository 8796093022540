<nz-page-header class="site-page-header" nzTitle="Receita" nzSubtitle="Federal">
</nz-page-header>
<nz-content>
    <nz-card nzTitle="Receita" [ngClass]="[!loading ? 'ant-card-table' : '', statusOne ? 'card-fullscreen' : '']"
             [nzExtra]="navBar" [nzLoading]="loading">
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzFooter]="footer"
                  class="fonte-doze table-small scrollbar"
                  [ngStyle]="{'max-width' : screenWidth + 'px'}">
            <thead>
            <tr>
                <th nzColumnKey="cnpj" [nzSortFn]="true" style="text-align: center;">CNPJ</th>
                <th nzColumnKey="nome" [nzSortFn]="true" style="text-align: center;">Nome</th>
                <th nzColumnKey="qualificacao" [nzSortFn]="true" style="text-align: center;">Qualificação</th>
                <th nzColumnKey="identificador" [nzSortFn]="true" style="text-align: center;">Identificador</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" style="text-align: center;">Tipo</th>
                <th nzColumnKey="dataAtualizacao" [nzSortFn]="true" style="text-align: center;">Data de Atualização</th>
                <th nzColumnKey="codigoPais" [nzSortFn]="true" style="text-align: center;">Código do País</th>

                <th nzColumnKey="cnpjOrdem" [nzSortFn]="true" style="text-align: center;">Ordem CNPJ</th>
                <th nzColumnKey="cnpjDV" [nzSortFn]="true" style="text-align: center;">CNPJ DV</th>
                <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" style="text-align: center;">Situação Cadastral</th>
                <th nzColumnKey="situacaoCadastralData" [nzSortFn]="true" style="text-align: center;">Data da Situação Cadastral</th>
                <th nzColumnKey="situacaoCadastralMotivo" [nzSortFn]="true" style="text-align: center;">Motivo da Situação Cadastral</th>
                <th nzColumnKey="nomeCidadeExterior" [nzSortFn]="true" style="text-align: center;">Cidade Exterior</th>
                <th nzColumnKey="inicioAtividadeData" [nzSortFn]="true" style="text-align: center;">Data do Início da Atividade</th>
                <th nzColumnKey="cnaePrincipal" [nzSortFn]="true" style="text-align: center;">Cnae Principal</th>
                <th nzColumnKey="cnaeSecundaria" [nzSortFn]="true" style="text-align: center;">Cnae Secundaria</th>
                <th nzColumnKey="logradouroTipo" [nzSortFn]="true" style="text-align: center;">Tipo de Logradouro</th>
                <th nzColumnKey="logradouro" [nzSortFn]="true" style="text-align: center;">Logradouro</th>
                <th nzColumnKey="numero" [nzSortFn]="true" style="text-align: center;">Número</th>
                <th nzColumnKey="complemento" [nzSortFn]="true" style="text-align: center;">Complemento</th>
                <th nzColumnKey="bairro" [nzSortFn]="true" style="text-align: center;">Bairro</th>
                <th nzColumnKey="cep" [nzSortFn]="true" style="text-align: center;">CEP</th>
                <th nzColumnKey="uf" [nzSortFn]="true" style="text-align: center;">UF</th>
                <th nzColumnKey="municipio" [nzSortFn]="true" style="text-align: center;">Município</th>
                <th nzColumnKey="ddd1" [nzSortFn]="true" style="text-align: center;">DDD 1</th>
                <th nzColumnKey="telefone1" [nzSortFn]="true" style="text-align: center;">Telefone 1</th>
                <th nzColumnKey="ddd2" [nzSortFn]="true" style="text-align: center;">DDD 2</th>
                <th nzColumnKey="telefone2" [nzSortFn]="true" style="text-align: center;">Telefone 2</th>
                <th nzColumnKey="dddFax" [nzSortFn]="true" style="text-align: center;">DDD Fax</th>
                <th nzColumnKey="telefoneFax" [nzSortFn]="true" style="text-align: center;">Telefone Fax</th>
                <th nzColumnKey="email" [nzSortFn]="true" style="text-align: center;">Email</th>
                <th nzColumnKey="situacaoEspecial" [nzSortFn]="true" style="text-align: center;">Situação Especial</th>
                <th nzColumnKey="situacaoEspecialData" [nzSortFn]="true" style="text-align: center;">Data da Situação Especial</th>
                <th nzColumnKey="razaoSocial" [nzSortFn]="true" style="text-align: center;">Razão Social</th>
                <th nzColumnKey="naturezaJuridica" [nzSortFn]="true" style="text-align: center;">Natureza Jurídica</th>
                <th nzColumnKey="capitalSocial" [nzSortFn]="true" style="text-align: center;">Capital Social</th>
                <th nzColumnKey="porte" [nzSortFn]="true" style="text-align: center;">Porte</th>
                <th nzColumnKey="enteFederativo" [nzSortFn]="true" style="text-align: center;">Ente Federativo</th>
                <th nzColumnKey="documento" [nzSortFn]="true" style="text-align: center;">Documento</th>
                <th nzColumnKey="entradaData" [nzSortFn]="true" style="text-align: center;">Data de Entrada</th>
                <th nzColumnKey="representanteDocumento" [nzSortFn]="true" style="text-align: center;">Documento do Representante</th>
                <th nzColumnKey="representanteNome" [nzSortFn]="true" style="text-align: center;">Nome do Representante</th>
                <th nzColumnKey="representanteQualificacao" [nzSortFn]="true" style="text-align: center;">Qualificação do Representante</th>
                <th nzColumnKey="faixaEtaria" [nzSortFn]="true" style="text-align: center;">Faixa Etária</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tasks of basicTable.data">
                <td nzLeft style="text-align: center;">{{tasks?.cnpj}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.nome}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.qualificacao}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.identificador}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.tipo}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.dataAtualizacao}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.codigoPais}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.cnpjOrdem}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.cnpjDV}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.situacaoCadastral}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.situacaoCadastralData}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.situacaoCadastralMotivo}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.nomeCidadeExterior}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.inicioAtividadeData}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.cnaePrincipal}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.cnaeSecundaria}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.logradouroTipo}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.logradouro}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.numero}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.complemento}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.bairro}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.cep}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.uf}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.municipio}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.ddd1}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.telefone1}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.ddd2}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.telefone2}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.dddFax}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.telefoneFax}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.email}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.situacaoEspecial}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.situacaoEspecialData}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.razaoSocial}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.naturezaJuridica}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.capitalSocial}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.porte}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.enteFederativo}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.documento}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.entradaData}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.representanteDocumento}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.representanteNome}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.representanteQualificacao}}</td>
                <td nzLeft style="text-align: center;">{{tasks?.faixaEtaria}}</td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<ng-template #navBar>
    <div class="d-flex align-items-center">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class StatusComAnexosService  {

    constructor(protected http: HttpClient) {
    }

    retornaPdf(zip: string, pdf: string, obrigacaoEmpresaAnexoId: string): any {

        return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/zip?zip=${zip}&pdf=${pdf}&obrigacaoEmpresaAnexo_id=${obrigacaoEmpresaAnexoId}`, {},
            {responseType: 'blob'});

    }

    retornaZip(zip: string, id: string): any {

        return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/zip?zip=${zip}
        &obrigacaoEmpresaAnexo_id=${id}`, {},
            {responseType: 'blob'});

    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Empresa} from '@models/empresa.model';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})

export class NfeTerceirosService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/administracao/empresa', {});
    }

    getNota(filtros: any): Observable<any> {
        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/notas/detalhe/${filtros.modelo}/${filtros.emissao}/${filtros.chave}${queryStr}`);
    }

    getEtapas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/notas/workflow/status`);
    }

    getStatus(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/notas/status`);
    }

    getNotaWorkflow(notaId): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/detalhe/workflow/${notaId}`);
    }

    alterarNota(dados): Observable<any> {

        return this.http.post(`${environment.apiUrl}/notas/detalhe/workflow`,
            dados);
    }


    downloadXml(notaId): any {

        return this.http.get(`${environment.apiUrl}/notas/downloadXml/${notaId}`,
            {responseType: 'blob'});
    }

    downloadDanfe(notaId): any {

        return this.http.get(`${environment.apiUrl}/notas/downloadDanfe/${notaId}`,
            {responseType: 'blob'});

    }

    getMarcadores(): any {

        return this.http.get(`${environment.apiUrl}/notas/detalhe/marcadores`);

    }

    novoMarcador(marcador: string, cor: string, ids: string[]): Observable<any> {

        const dados: any = {
            marcador,
            ids
        };

        if (cor) {
            dados.cor = cor;
        }


        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores`, dados);
    }

    removerMarcador(marcador: string, ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores/remover`, {marcador, ids});
    }

}

<nz-page-header class="site-page-header" nzTitle="Calendar" nzSubtitle="Atividades">
    <nz-page-header-extra>
        <span *ngIf="grupoFiltradoDescricao" class="filtro-selecionado"
              [nz-tooltip]="'Grupo selecionado - Clique para remover'"
              [nzTooltipPlacement]="'bottom'"
              (click)="removerFiltroGrupo()">{{ grupoFiltradoDescricao }}
        </span>
        <nz-button-group *ngIf="showContent">
            <button nz-button nzType="primary" nzGhost
                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                    (click)="openTab('CalendarObrigacoesComponent')"
            >
                <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
                Dashboard
            </button>
            <nz-badge [nzCount]="qtdFiltrosCabecalho" style="margin-right: 15px;"
                      [nzStyle]="{ backgroundColor: '#52c41a' }">
                <button nz-button nzType="primary" nzGhost
                        style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<div *ngIf="showAlert">
    <nz-row nzGutter="24">
        <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        O módulo Calendar ainda não foi contratado. Entre em contato com o nosso suporte.
                    </div>
                </div>
            </ng-template>
        </nz-alert>
    </nz-row>
</div>
<div *ngIf="showContent">
    <nz-content>
        <nz-row [nzGutter]="24">
            <nz-col [nzSpan]="5">
                <nz-card nzTitle="Eficiência"
                         [ngClass]="[!cardCabecalhoLoading ? 'ant-card-table' : '', !cardCabecalhoLoading ? sextaSemana ? 'maior' : 'custom-card-height' : '']"
                         [nzLoading]="cardCabecalhoLoading">
                    <nz-content style="height: 100%;">
                        <nz-row style="height: 100%;">
                            <nz-col [nzSpan]="24" style="height: 100%; display: flex; flex-direction: column">

                                <div style="width: 100%; height: 180px; display: block;">
                                    <highcharts-chart
                                            [Highcharts]="HighchartsSolidGauge"
                                            [options]="chartSolidGaugeOptions"
                                            [(update)]="updateFlag"
                                            *ngIf="HighchartsSolidGauge && chartSolidGaugeOptions"
                                            style="width: 100%; height: 180px; display: block;"
                                    ></highcharts-chart>
                                </div>

                                <div class="filtros-selecionados">
                                    <div *ngIf="equipefiltradaDescricao">
                                        <i [nz-tooltip]="'Equipe selecionada'" class="fa-solid fa-user-group"></i>
                                        <span>
                                        {{ equipefiltradaDescricao }}
                                    </span>
                                    </div>
                                    <div *ngIf="responsavelFiltradoDescricao">
                                        <i [nz-tooltip]="'Responsável selecionado'" class="fa-solid fa-user"></i>
                                        <span>
                                        {{ responsavelFiltradoDescricao }}
                                    </span>
                                    </div>
                                </div>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </nz-col>
            <nz-col [nzSpan]="4">
                <nz-card nzTitle="Confirmações" [nzLoading]="cardCabecalhoLoading"
                         [ngClass]="!cardCabecalhoLoading ? sextaSemana ? 'maior' : 'custom-card-height' : ''">
                    <div nz-card-grid [ngStyle]="{width: '100%', textAlign: 'center', 'padding': '15px', height: '25%'}"
                         (click)="addFiltro('confirmacoes', 'N')"
                         class="ant-card-hoverable"
                         [class.activeFiltro]="filtroConfirmacoes && filtroConfirmacoes == 'N'">
                        <nz-content style="height: 100%;">
                            <nz-row style="height: 100%;">
                                <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                        style="height: 100%;">
                                    <i class="fas fa-minus" style="font-size: 24px;"></i>
                                </nz-col>
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center"
                                        style="flex-direction: column; height: 100%;"
                                >
                                    <div>
                                        <a nz-button nzType="link">
                                            {{ tableConfirmacoesAutimaticas.qtdNaoConcluidas }}
                                        </a>
                                    </div>
                                    <h5>À Confirmar</h5>
                                </nz-col>
                            </nz-row>
                        </nz-content>
                    </div>
                    <div nz-card-grid [ngStyle]="{width: '100%', textAlign: 'center', 'padding': '15px', height: '25%'}"
                         (click)="addFiltro('confirmacoes', 'R')"
                         class="ant-card-hoverable"
                         [class.activeFiltro]="filtroConfirmacoes && filtroConfirmacoes == 'R'">
                        <nz-content style="height: 100%;">
                            <nz-row style="height: 100%;">
                                <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                        style="height: 100%;">
                                    <i class="fas fa-times" style="font-size: 24px; color: #f44336;"></i>
                                </nz-col>
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center"
                                        style="flex-direction: column; height: 100%;">
                                    <div>
                                        <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdRejeitadas }}</a>
                                    </div>
                                    <h5>Rejeitadas</h5>
                                </nz-col>
                            </nz-row>
                        </nz-content>
                    </div>
                    <div nz-card-grid [ngStyle]="{width: '100%', textAlign: 'center', 'padding': '15px', height: '25%'}"
                         (click)="addFiltro('confirmacoes', 'A')"
                         class="ant-card-hoverable"
                         [class.activeFiltro]="filtroConfirmacoes && filtroConfirmacoes == 'A'">
                        <nz-content style="height: 100%;">
                            <nz-row style="height: 100%;">
                                <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                        style="height: 100%;">
                                    <i class="fas fa-sync" style="font-size: 24px; color: #666ee8;"></i>
                                </nz-col>
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center"
                                        style="flex-direction: column; height: 100%;">
                                    <div>
                                        <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdVerificando }}</a>
                                    </div>
                                    <h5>Em Confirmação</h5>
                                </nz-col>
                            </nz-row>
                        </nz-content>
                    </div>
                    <div nz-card-grid [ngStyle]="{width: '100%', textAlign: 'center', 'padding': '15px', height: '25%'}"
                         (click)="addFiltro('confirmacoes', 'C')"
                         class="ant-card-hoverable"
                         [class.activeFiltro]="filtroConfirmacoes && filtroConfirmacoes == 'C'">
                        <nz-content style="height: 100%;">
                            <nz-row style="height: 100%;">
                                <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                        style="height: 100%;">
                                    <i class="fas fa-check"
                                       style="font-size: 24px; color: #28d094 !important;"></i>
                                </nz-col>
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center"
                                        style="flex-direction: column; height: 100%;">
                                    <div>
                                        <a nz-button nzType="link">{{ tableConfirmacoesAutimaticas.qtdConfirmadas }}</a>
                                    </div>
                                    <h5>Confirmadas</h5>
                                </nz-col>
                            </nz-row>
                        </nz-content>
                    </div>
                </nz-card>
            </nz-col>
            <nz-col [nzSpan]="8">
                <nz-card nzTitle="Status"
                         [ngClass]="!cardCabecalhoLoading ? sextaSemana ? 'maior' : 'custom-card-height' : ''"
                         [nzLoading]="cardCabecalhoLoading">
                    <div nz-row>
                        <div nz-col [nzSpan]="11" class="d-flex align-items-center">
                            <highcharts-chart
                                    [Highcharts]="HighchartsPie"
                                    [options]="chartPieOptions"
                                    [(update)]="updateFlag"
                                    *ngIf="HighchartsPie && chartPieOptions"
                                    style="width: 100%; height: 190px; display: block;"
                            ></highcharts-chart>
                        </div>
                        <div [nzSpan]="13" nz-col>
                            <ul nz-list nzBordered nzSize="small" style="margin: 20px 10px;">
                                <nz-list-item class="ant-card-hoverable" (click)="addFiltro('status', 'P')">
                                    <h5 class="custom-card-value mb0">
                                        <i class="fas fa-circle" style="color: #ff9149;"></i> Pendentes
                                    </h5>
                                    <a class="mb0">{{ tableStatus.qtdPendentes }}</a>
                                </nz-list-item>
                                <nz-list-item class="ant-card-hoverable" (click)="addFiltro('status', 'A')">
                                    <h5 class="custom-card-value mb0">
                                        <i class="fas fa-circle" style="color: #ff4961;"></i> Atrasadas
                                    </h5>
                                    <a class="mb0" (click)="addFiltro('status', 'A')">{{ tableStatus.qtdAtrasados }}</a>
                                </nz-list-item>
                                <nz-list-item class="ant-card-hoverable" (click)="addFiltro('status', 'C')">
                                    <h5 class="custom-card-value mb0">
                                        <i class="fas fa-circle" style="color: #1565c0;"></i> Concluídas
                                    </h5>
                                    <a class="mb0"
                                       (click)="addFiltro('status', 'C')">{{ tableStatus.qtdConcluidos }}</a>
                                </nz-list-item>
                                <nz-list-item class="ant-card-hoverable" (click)="addFiltro('status', 'CA')">
                                    <h5 class="custom-card-value mb0">
                                        <i class="fas fa-circle" style="color: #666ee8;"></i> Concluídas com Atraso
                                    </h5>
                                    <a class="mb0"
                                       (click)="addFiltro('status', 'CA')">{{ tableStatus.qtdConcluidoAtrasadas }}</a>
                                </nz-list-item>
                            </ul>
                        </div>
                    </div>
                </nz-card>
            </nz-col>

            <nz-col [nzSpan]="7">
                <nz-card nzTitle="Vencimento" [nzExtra]="extraVenvimento"
                         [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                         [nzLoading]="cardCabecalhoLoading">
                    <div [ngStyle]="{ width: '100%'}">
                        <nz-table nzBordered nzSize="small" class="tableCalendario" nzTemplateMode>
                            <thead>
                            <tr style="height: 5px;">
                                <th class="labelSemana labelSemanaGrande">dom</th>
                                <th class="labelSemana">seg</th>
                                <th class="labelSemana">ter</th>
                                <th class="labelSemana">qua</th>
                                <th class="labelSemana">qui</th>
                                <th class="labelSemana">sex</th>
                                <th class="labelSemana labelSemanaGrande">sab</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let semana of arraySemanas">
                                <tr class="trDia">
                                    <ng-container *ngFor="let dado of semana">
                                        <td class="cardDia"
                                            [class.cardDiaSelected]="filtroVencimento && dado.dia === filtroVencimento.getDate() && !dado.disabled">
                                            <div class="divDia" [ngStyle]="dado.disabled && {'display': 'none'}"
                                                 (click)="selecionaDiaVencimento(dado.dia);">
                                                <p class="labelDia">{{ dado.dia }}</p>
                                                <div class="badgesDia">
                                                    <nz-badge [nzColor]="arrayFontCorStatus[0]"
                                                              [nzStatus]="undefined"
                                                              [nzStyle]="{'margin-right': '3px'}"
                                                              *ngIf="dado.flags.pendentes"
                                                    ></nz-badge>
                                                    <nz-badge [nzColor]="arrayFontCorStatus[1]"
                                                              [nzStatus]="undefined"
                                                              [nzStyle]="{'margin-right': '3px'}"
                                                              *ngIf="dado.flags.atrasadas"
                                                    ></nz-badge>
                                                    <nz-badge [nzColor]="arrayFontCorStatus[2]"
                                                              [nzStatus]="undefined"
                                                              [nzStyle]="{'margin-right': '3px'}"
                                                              *ngIf="dado.flags.concluidas"
                                                    ></nz-badge>
                                                    <nz-badge [nzColor]="arrayFontCorStatus[3]"
                                                              [nzStatus]="undefined"
                                                              [nzStyle]="{'margin-right': '3px'}"
                                                              *ngIf="dado.flags.concluidasComAtraso"
                                                    ></nz-badge>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            </tbody>
                        </nz-table>
                    </div>
                </nz-card>
            </nz-col>

        </nz-row>
        <!--    <nz-row [nzGutter]="24">-->
        <!--        <nz-col [nzSpan]="10">-->
        <!--            <nz-card nzTitle="Status" class="custom-card-padding">-->
        <!--                <nz-content>-->
        <!--                    <nz-row style="text-align: center;">-->
        <!--                        <nz-col [nzSpan]="5" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #ff9149 !important;">-->
        <!--                                    7-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Pendente-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="5" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #ff4961 !important;">-->
        <!--                                    5-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Atrasado-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="5" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #1565c0 !important;">-->
        <!--                                    51-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Concluída-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="9">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #666ee8 !important;">-->
        <!--                                    12-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Concluída com Atraso-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                    </nz-row>-->
        <!--                </nz-content>-->
        <!--            </nz-card>-->
        <!--        </nz-col>-->
        <!--        <nz-col [nzSpan]="9">-->
        <!--            <nz-card nzTitle="Confirmações" class="custom-card-padding">-->
        <!--                <nz-content>-->
        <!--                    <nz-row style="text-align: center;">-->
        <!--                        <nz-col [nzSpan]="7" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0;">-->
        <!--                                    5-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Não Concluídas-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="6" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #666ee8 !important;">-->
        <!--                                    5-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Verificando-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="5" style="border-right: 1px solid rgb(240, 240, 240);">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #f44336 !important;">-->
        <!--                                    49-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Rejeitadas-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                        <nz-col [nzSpan]="6">-->
        <!--                            <div>-->
        <!--                                <h2 nz-typography class="custom-card-value"-->
        <!--                                    style="margin: 0; color: #28d094 !important;">-->
        <!--                                    10-->
        <!--                                </h2>-->
        <!--                                <h5 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">-->
        <!--                                    Confirmadas-->
        <!--                                </h5>-->
        <!--                            </div>-->
        <!--                        </nz-col>-->
        <!--                    </nz-row>-->
        <!--                </nz-content>-->
        <!--            </nz-card>-->
        <!--        </nz-col>-->
        <!--        <nz-col [nzSpan]="5">-->
        <!--            <nz-card nzTitle="Eficiência" class="ant-card-table">-->
        <!--                <nz-content>-->
        <!--                    <nz-row>-->
        <!--                        <nz-col [nzSpan]="24">-->
        <!--                            <highcharts-chart-->
        <!--                                    [Highcharts]="HighchartsSolidGauge"-->
        <!--                                    [options]="chartSolidGaugeOptions"-->
        <!--                                    [(update)]="updateFlag"-->
        <!--                                    style="width: 100%; height: 100px; display: block;"-->
        <!--                            ></highcharts-chart>-->
        <!--                        </nz-col>-->
        <!--                    </nz-row>-->
        <!--                </nz-content>-->
        <!--            </nz-card>-->
        <!--        </nz-col>-->
        <!--    </nz-row>-->
    </nz-content>
    <nz-content>
        <nz-card [ngClass]="!loading ? 'ant-card-table' : ''" [nzExtra]="navBar" nzTitle="Atividades"
                 [nzLoading]="loading">
            <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                      *ngIf="checkedItems.size > 0">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }} Registros Selecionados
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-table #basicTableTasks
                      nzSize="small"
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzShowPagination]="false"
                      style="overflow-y: auto"
            >
                <thead>
                <tr>
                    <!--                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"-->
                    <!--                    id="check-all">-->
                    <!--                </th>-->
                    <th style="text-align: center;">
                        <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                    </th>
                    <th nzColumnKey="obrigacao_descricao" [nzSortFn]="true">Obrigação</th>
                    <th nzColumnKey="competencia" [nzSortFn]="true" style="text-align: center;">Competência</th>
                    <th nzColumnKey="unidade" [nzSortFn]="true" style="text-align: center;">Unidade</th>
                    <th nzColumnKey="vencimento" [nzSortFn]="true" style="text-align: center;">Vencimento</th>
                    <th nzColumnKey="status" [nzSortFn]="true" style="text-align: center;">Status</th>
                    <th nzColumnKey="conclusao" [nzSortFn]="true" style="text-align: center;">Conclusão</th>
                    <th></th>
                    <th nzColumnKey="equipe" style="text-align: center;">Equipe</th>
                    <th nzColumnKey="responsaveis" [nzSortFn]="true" style="text-align: center;">Responsável</th>
                    <th style="text-align: center;">Anexo</th>
                    <th colSpan="2"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tasks of basicTableTasks.data">
                    <td nzLeft (nzCheckedChange)="onItemChecked(tasks.id, $event)"
                        [nzChecked]="checkedItems.has(tasks.id)"
                        [nzDisabled]="tasks.disabled">
                    </td>
                    <td nzLeft>
                        <button nz-button nzType="link"
                                (click)="openTab('ObrigacaoEmpresaDetalheComponent', tasks?.obrigacaoEmpresa_id, {id: tasks?.obrigacaoEmpresa_id})">
                            {{ tasks?.obrigacao_descricao }}
                        </button>
                    </td>
                    <td nzLeft style="text-align: center;">
                        {{ tasks?.competencia }}
                    </td>
                    <td nzLeft style="text-align: center;">{{ modoDemo(tasks?.unidade, 'empresaCodigoNome') }}
                        <span *ngIf="tasks.ie"
                              style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                              nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{tasks.ie}}">
                    </span>
                        <br>{{ modoDemo(tasks?.cnpj | cpfCnpj, 'cnpj') }}
                    </td>
                    <td nzLeft style="text-align: center;">{{ tasks?.vencimento | date: 'dd/MM/yyy' }}</td>
                    <td style="text-align: center;">
                        <nz-tag [nzColor]="tasks.cor"
                                [ngStyle]="{color: tasks.fontColor, border: tasks.fontColor + ' 1px solid'}">
                            {{ tasks.status }}
                        </nz-tag>
                    </td>
                    <td nzLeft style="text-align: center;">
                        {{ tasks?.conclusaoData | date: 'dd/MM/yyy' }}
                    </td>
                    <td>
                        <i class="fas"
                           [ngStyle]="{color: tasks.corIcon}"
                           [ngClass]="tasks.conclusaoIcon"
                           style="margin-left: 5px;"
                           nz-tooltip
                           nzTooltipTitle="{{tasks.conclusaoToltip}}"
                           [nzTooltipPlacement]="['topLeft', 'leftTop']"
                        ></i>
                    </td>
                    <td nzLeft style="text-align: center;">{{ modoDemo(tasks?.equipe, 'total') }}</td>
                    <td nzLeft style="text-align: center;">
                        <nz-avatar-group>
                            <ng-container *ngIf="tasks.responsaveis?.length > 0">
                                <div class="ant-avatar" *ngFor="let item of tasks.responsaveis"
                                     nz-tooltip
                                     nzTooltipTitle="{{item.concluiu ? 'Responsável: ' + modoDemo(item.nome, 'total') + '\n Concluído por ' + modoDemo(item.nome, 'total') : 'Responsável: ' + modoDemo(item.nome, 'total')}}"
                                     style="{{'background-color:'+item.backgroundColor}}">
                                    <span>{{ item.iniciais ? item?.iniciais : '' }}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!tasks.responsavelConcluiu && tasks.conclusaoUsuario">
                                <div class="ant-avatar"
                                     nz-tooltip
                                     nzTooltipTitle="Concluído por {{modoDemo(tasks.conclusaoUsuario, 'total')}}"
                                     style="{{'background-color:'+tasks.concluiuBackgroundColor}}">
                                    <span>{{ tasks.concluiuIniciais }}</span>
                                </div>
                            </ng-container>
                        </nz-avatar-group>
                    </td>
                    <td style="text-align: center">
                        <nz-badge [nzCount]="tasks?.quantidadeAnexos || 0" nzShowZero
                                  [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }">
                            <button nz-button nzType="link"
                                    (click)="onVerAnexos(tasks?.id, tasks?.obrigacaoEmpresa_id, tasks.anexos, tasks)"
                                    [nzLoading]="loadingModalAnexos[tasks.id]">
                                <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                            </button>
                        </nz-badge>
                    </td>
                    <td nzRight nzAlign="center">
                        <a class="centralized-icon" nz-button nzType="link" (click)="getHistorico(tasks.id)"
                           [nz-tooltip]="'Histórico'">
                            <span nz-icon nzType="file-text" nzTheme="outline"></span>
                        </a>
                    </td>
                    <td nzRight nzAlign="center">
                        <a [nzDropdownMenu]="menuAcoesTable" class="centralized-icon" nz-button nz-dropdown
                           nzType="link">
                            <i class="fas fa-cog"></i>
                        </a>
                        <nz-dropdown-menu #menuAcoesTable>
                            <ul nz-menu>
                                <li nz-menu-item>Ações</li>
                                <li nz-menu-divider></li>
                                <li *ngIf="!tasks.conclusaoData"
                                    [nzDisabled]="tasks.obrigatorioAprovacao == 1 && tasks.aprovacaoStatus != 1"
                                    nz-menu-item
                                    (click)="tasks.obrigatorioAprovacao == 1 && tasks.aprovacaoStatus != 1 ? '' : showConfirmConcluir(tasks.id, tasks.obrigatorioAprovacao, tasks.aprovacaoStatus)">
                                    Concluir
                                </li>
                                <li *ngIf="(tasks.confirmacaoStatus !== 'C' && (tasks.status == 'Concluída' || tasks.status == 'Concluída com atraso') && !tasks.agendaConfirmavel)
                                   || (tasks.confirmacaoStatus === 'C' && !tasks.agendaConfirmavel)"
                                    nz-menu-item (click)="showConfirmDesconcluir(tasks.id);">
                                    Desfazer Conclusão
                                </li>
                                <li *ngIf="!tasks.dataConclusao && tasks.obrigatorioAprovacao && !tasks.aprovacaoStatus"
                                    [nzDisabled]="tasks.aprovacaoStatus != null && tasks.aprovacaoStatus != ''"
                                    nz-menu-item
                                    (click)="showConfirmSolicitarAprovacao(tasks.id, tasks.aprovacaoStatus !== null && tasks.aprovacaoStatus != '');">
                                    Solicitar Confirmação
                                </li>
                                <li
                                        nz-menu-item (click)="onAlterarEquipeUnica(tasks.id);">
                                    Alterar Equipe
                                </li>
                                <li
                                        nz-menu-item (click)="modalAlterarResponsavel(true, tasks.id)">
                                    Alterar Responsavel
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="2" style="color: transparent;">
                        <button nz-button nzType="link" (click)="scrollContainer(300)">
                            <span nz-icon nzType="up-circle" nzTheme="outline"
                                  nz-tooltip="Voltar ao topo"></span>
                        </button>
                    </td>
                    <td colspan="6" style="align-items: center; text-align: center;">
                        <button nz-button nzType="link"
                                style="height: 40px;"
                                (click)="loadTasksNextPage(this.currentParams)"
                                [disabled]="items?.length === pagination.total">
                            <div *ngIf="loadingNextPage" class="lds-ellipsis" nz-tooltip="Por favor aguarde.">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                    </td>
                    <td></td>
                    <td colspan="3" style="text-align: right;">
                        {{ items?.length }} registros de {{ pagination.total }}
                    </td>
                </tr>
                </tfoot>
            </nz-table>
        </nz-card>
    </nz-content>

    <ng-template #navBar>
        <div class="d-flex align-items-center">
            <nz-badge [nzCount]="qtdFiltrosAtivos" style="margin-right: 15px;"
                      [nzStyle]="{ backgroundColor: '#52c41a' }">
                <button nz-button nzType="primary" (click)="abrirModal(formFiltrosGrid)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>

            <button nz-button nzType="default" style="margin-right: 15px;" (click)="limparFiltros()">
                Limpar
            </button>
            <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                    nzType="default">
                Opções
                <em nz-icon nzType="down"></em>
            </button>


            <nz-dropdown-menu #menuAcoes>
                <ul nz-menu>
                    <li nz-submenu nzTitle="Manutenção Coletiva">
                        <ul>
                            <li nz-menu-item>Ações</li>
                            <li nz-menu-divider></li>
                            <li [nzDisabled]="checkedItems?.size === 0"
                                nz-tooltip
                                nzTooltipTitle="{{checkedItems?.size === 0 ? 'Nenhuma atividade selecionada' : ''}}"
                                nz-menu-item (click)="checkedItems?.size > 0 ? showConfirmConcluir() : ''">
                                Concluir
                            </li>
                            <li [nzDisabled]="disabledDesconcluir"
                                nz-tooltip
                                nzTooltipTitle="{{disabledDesconcluir ? 'Selecione apenas atividades concluídas' : ''}}"
                                nz-menu-item (click)="!disabledDesconcluir ? showConfirmDesconcluir() : ''">
                                Desfazer Conclusão
                            </li>
                            <li [nzDisabled]="checkedItems?.size === 0"
                                nz-tooltip
                                nzTooltipTitle="{{checkedItems?.size === 0 ? 'Nenhuma atividade selecionada' : ''}}"
                                nz-menu-item (click)="checkedItems?.size > 0 ? onAlterarEquipe() : ''">
                                Alterar Equipe
                            </li>
                            <li [nzDisabled]="checkedItems?.size === 0"
                                nz-tooltip
                                nzTooltipTitle="{{checkedItems?.size === 0 ? 'Nenhuma atividade selecionada' : ''}}"
                                nz-menu-item (click)="checkedItems?.size > 0 ? modalAlterarResponsavel(true) : ''">
                                Alterar Responsável
                            </li>
                        </ul>
                    </li>
                    <li nz-menu-item (click)="modalExportar(true);">Exportar Planilha</li>
                </ul>
            </nz-dropdown-menu>
        </div>
    </ng-template>

    <ng-template #extraVenvimento>
        {{ arrayCompetencias[mesAtivo - 1] }}/{{ anoAtivo }}
    </ng-template>

    <nz-modal (nzOnCancel)="handleCancel()" [(nzVisible)]="displayVisibilidadeModal"
              [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Equipe">
        <ng-template nzModalContent>
            <nz-select [(ngModel)]="inputEquipe"
                       [nzPlaceHolder]="'Selecione as equipe'" [nzDisabled]="limparEquipes">
                <nz-option *ngFor="let option of equipeOptions" [nzLabel]="modoDemo(option.label,'total')"
                           [nzValue]="option.value"></nz-option>
            </nz-select>
            <label nz-checkbox [(ngModel)]="limparEquipes" (nzCheckedChange)="zerarInputEquipe();">Sem equipe</label>
        </ng-template>

        <ng-template #modalFooter>
            <button (click)="addNewEquipe()" class="new-markup-bnt" nz-button nzType="link">
                <em nz-icon nzType="plus-circle"></em>Nova equipe
            </button>
            <button (click)="saveBulkToEquipes()" nz-button nzType="primary">Ok</button>
            <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
        </ng-template>
    </nz-modal>

    <nz-modal (nzOnCancel)="closeNewEquipe()" (nzOnOk)="saveNewEquipe()" [(nzVisible)]="newVisibilidadeModal"
              [nzAutofocus]="null"
              nzTitle="Nova Equipe">
        <ng-template nzModalContent>
            <nz-form-item>
                <label for="novaEquipe"></label>
                <input [(ngModel)]="newEquipe" id="novaEquipe" nz-input placeholder="descrição" type="text">
            </nz-form-item>
        </ng-template>
    </nz-modal>

    <nz-modal [(nzVisible)]="formResponsavel.modalVisible"
              [nzTitle]="'Alterar Responsável'" [nzClosable]="true"
              (nzOnCancel)="modalAlterarResponsavel(false)"
              [nzWidth]="648">
        <nz-row *nzModalContent>
            <nz-col nzSpan="24">
                <form nz-form [nzNoColon]="true" [formGroup]="formResponsavel.formGroup">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                        <nz-form-control [nzSpan]="12">
                            <nz-select nzShowSearch nzAllowClear
                                       [nzPlaceHolder]="'Selecione'" formControlName="responsavel_id">
                                <nz-option *ngFor="let opt of comboResponsaveis"
                                           [nzLabel]="modoDemo(opt.label, 'total')"
                                           [nzValue]="opt.key"
                                >
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </nz-col>
        </nz-row>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="modalAlterarResponsavel(false)">Cancelar</button>
            <button nz-button nzType="primary" (click)="saveBulkToResponsavel()">Alterar</button>
        </div>
    </nz-modal>

    <nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
              [nzTitle]="'Filtros avançados'" [nzClosable]="true"
              (nzOnCancel)="fechar(formFiltrosGrid)"
              [nzWidth]="748">
        <nz-row *nzModalContent>
            <nz-col [nzSpan]="24">
                <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Obrigação</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.obrigacao">
                            <nz-select nzShowSearch
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="obrigacao"
                                       (ngModelChange)="clearInput(formFiltrosGrid.formGroup, 'obrigacao', 'categoria')">
                                <nz-option *ngFor="let opt of arrayFiltrosObrigacoes"
                                           [nzLabel]="opt.obrigacao "
                                           [nzValue]="opt.obrigacao_id">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Categoria</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.categoria">
                            <nz-select nzShowSearch
                                       [(ngModel)]="filtroCategoria"
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="categoria"
                                       (ngModelChange)="clearInput(formFiltrosGrid.formGroup, 'categoria', 'obrigacao')">
                                <nz-option *ngFor="let opt of arrayFiltrosCategorias"
                                           [nzLabel]="opt.descricao"
                                           [nzValue]="opt.id">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                        <nz-form-control [nzSpan]="12" size="60" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresaNome">
                            <nz-select nzShowSearch nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       [(ngModel)]="formFiltrosGrid.formGroup.value.empresaNome"
                                       formControlName="empresaNome"
                            >
                                <nz-option *ngFor="let opt of arraySelectEmpresa"
                                           [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">CNPJ</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresaNome">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="empresaNome"
                                       [(ngModel)]="formFiltrosGrid.formGroup.value.empresaNome">
                                <nz-option *ngFor="let opt of arraySelectCnpj"
                                           [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Status</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.status !== null">
                            <nz-select [(ngModel)]="filtroStatus"
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="status">
                                <nz-option *ngFor="let opt of arrayFiltrosStatus"
                                           [nzLabel]="opt.label"
                                           [nzValue]="opt.key">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Confirmações</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="filtroConfirmacoes !== null">
                            <nz-select [(ngModel)]="filtroConfirmacoes"
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="confirmacoes">
                                <nz-option *ngFor="let opt of arrayFiltrosConfirmacoes"
                                           [nzLabel]="opt.label"
                                           [nzValue]="opt.key">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Vencimento</nz-form-label>
                        <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.vencimento">
                            <nz-date-picker [nzFormat]="'dd/MM/yyyy'" [(ngModel)]="filtroVencimento"
                                            formControlName="vencimento" style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Competência</nz-form-label>
                        <nz-form-control [nzSpan]="12"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.competencia">
                            <nz-date-picker [nzMode]="'month'"
                                            [nzFormat]="'MM/yyyy'"
                                            formControlName="competencia" style="width: 100%;"
                                            [nzAllowClear]="true"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </nz-col>
        </nz-row>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="fechar(formFiltrosGrid)">Cancelar</button>
            <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
        </div>
    </nz-modal>

    <nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
              [nzTitle]="'Filtros'" [nzClosable]="true"
              (nzOnCancel)="fechar(formFiltrosCabecalho)"
              [nzWidth]="648">
        <nz-row *nzModalContent>
            <nz-col [nzSpan]="24">
                <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                         [nzHasFeedback]="filtroCompetenciaDate !== null">
                            <nz-date-picker [nzMode]="'month'" [nzFormat]="'MM/yyyy'"
                                            [(ngModel)]="filtroCompetenciaDate"
                                            formControlName="competenciaMesAno" style="width: 100%;"
                                            [nzAllowClear]="false"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Equipe</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                         [nzHasFeedback]="filtroEquipe !== null">
                            <nz-select [(ngModel)]="filtroEquipe"
                                       (ngModelChange)="getResponsaveis()"
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="equipe_id">
                                <nz-option *ngFor="let opt of arrayFiltrosEquipes"
                                           [nzLabel]="modoDemo(opt.label, 'total')"
                                           [nzValue]="opt.key">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Responsável</nz-form-label>
                        <nz-form-control [nzSpan]="14" [nzHasFeedback]="filtroResponsavel !== null">
                            <nz-select [(ngModel)]="filtroResponsavel"
                                       nzShowSearch
                                       [nzPlaceHolder]="'Selecione'"
                                       nzAllowClear
                                       formControlName="responsavel_id">
                                <nz-option *ngFor="let opt of arrayFiltrosResponsaveis"
                                           [nzLabel]="modoDemo(opt.label, 'total')"
                                           [nzValue]="opt.key">
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id">
                            <nz-select nzShowSearch nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="grupoEmpresarial_id">
                                <nz-option *ngFor="let opt of arraySelectGrupos"
                                           [nzLabel]="modoDemo(opt.descricao, 'total')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </nz-col>
        </nz-row>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="fechar(formFiltrosCabecalho)">Cancelar</button>
            <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
        </div>
    </nz-modal>

    <app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

    <nz-modal [(nzVisible)]="formSolicitarAprovacao.modalVisible"
              [nzTitle]="'Solicitar Confirmação'" [nzClosable]="true"
              (nzOnCancel)="modalSolicitarAprovacao(false)"
              [nzWidth]="648">
        <div *nzModalContent>

            <div>
                <p style="margin: 0px;">Selecione o(s) arquivo(s) para anexo.</p>

                <div style="margin-top: 20px;">
                    <nz-upload
                            nzType="drag"
                            [nzMultiple]="true"
                            [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                        <p class="ant-upload-drag-icon">
                            <i nz-icon nzType="inbox"></i>
                        </p>
                        <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                    </nz-upload>
                </div>
            </div>
        </div>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="modalSolicitarAprovacao(false);">Cancelar</button>
            <button nz-button nzType="primary" [nzLoading]="loadings.uploadinAnexo"
                    (click)="confirmaSolicitarAprovacao()">
                Solicitar
            </button>
        </div>
    </nz-modal>

    <nz-modal [(nzVisible)]="modalAdicionarAnexosVisible"
              [nzTitle]="'Adicionar anexos na solicitação de aprovação'" [nzClosable]="true"
              (nzOnCancel)="modalAdicionarAnexo(false)"
              [nzWidth]="648">
        <div *nzModalContent>

            <div>
                <p style="margin: 0px;">Selecione o(s) arquivo(s) para anexo.</p>

                <div style="margin-top: 20px;">
                    <nz-upload
                            nzType="drag"
                            [nzMultiple]="true"
                            [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                        <p class="ant-upload-drag-icon">
                            <i nz-icon nzType="inbox"></i>
                        </p>
                        <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                    </nz-upload>
                </div>
            </div>
        </div>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="modalAdicionarAnexo(false);">Cancelar</button>
            <button nz-button nzType="primary" [nzLoading]="loadings.uploadinAnexo" (click)="confirmaAdicionaAnexos()">
                Adicionar
            </button>
        </div>
    </nz-modal>

    <nz-modal [(nzVisible)]="modalVerAnexosVisible"
              [nzTitle]="'Anexos da solicitação de aprovação'" [nzClosable]="true"
              (nzOnCancel)="modalAnexos(false)"
              [nzWidth]="550"
              [nzBodyStyle]="{'padding': '0 5px'}">
        <div *nzModalContent>

            <app-anexos-list (removeuAnexo)="queryTable(this.currentParams, this.currentSearch)"
                             [btExcluir]="btExcluir" [anexosList]="listOfAnexos"></app-anexos-list>

        </div>
        <div *nzModalFooter>
            <button class="new-markup-bnt" nz-button nzType="link" (click)="onAdicionarAnexo()">
                <i nz-icon nzType="plus" nzTheme="outline"></i> Adicionar anexo
            </button>
            <button nz-button nzType="default" (click)="modalAnexos(false);">Fechar</button>
        </div>
    </nz-modal>

    <nz-modal (nzOnCancel)="showModalHistorico(false)"
              [nzBodyStyle]="{'padding' : '0'}"
              [(nzVisible)]="modalHistoricoVisible"
              [nzWidth]="800"
              nzTitle="Histórico"
              class="modal-table">
        <ng-container *nzModalContent>
            <nz-table #basicTable
                      [nzData]="itemsHistorico"
                      [nzFrontPagination]="false"
                      [nzShowPagination]="false"
                      [nzLoading]="loadingHistorico"
                      class="table-small"
                      style="overflow: auto">
                <thead>
                <tr>
                    <th nzAlign="center" nzColumnKey="data">Data</th>
                    <th nzAlign="center" nzColumnKey="usuario">Usuário</th>
                    <th nzAlign="center" nzColumnKey="status">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let relatorio of basicTable.data">
                    <td nzAlign="center">{{ relatorio?.data | date: 'dd/MM/yyyy HH:mm' }}</td>
                    <td nzAlign="center">{{ relatorio?.usuario }}</td>
                    <td nzAlign="center">{{ getHistoricoDescricao(relatorio?.status) }}</td>
                </tr>
                </tbody>
            </nz-table>
        </ng-container>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="showModalHistorico(false)">Fechar</button>
        </div>
    </nz-modal>
</div>

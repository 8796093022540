<nz-page-header class="site-page-header" nzTitle="Notas" nzSubtitle="Notas Emitidas Empresa">
    <nz-page-header-extra>
        <nz-button-group>
<!--            <button nz-button nzType="primary" nzGhost-->
<!--                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"-->
<!--                    (click)="openTab('CalendarObrigacoesComponent')"-->
<!--            >-->
<!--                <i nz-icon nzType="bar-chart" nzTheme="outline"></i>-->
<!--                Dashboard-->
<!--            </button>-->
            <nz-badge [nzCount]="qtdFiltrosCabecalhoAtivos"
                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                <button (click)="modalFiltrarCabecalho(true)" nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="24">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="24">
                        <nz-card>
                            <div class="d-flex align-items-center" style="margin-bottom: 20px;">
                                <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                                     class="d-flex justify-content-center align-items-center">
                                    <svg width="40" height="40"
                                         [data-jdenticon-value]="dadosAnalise?.dados?.nome"
                                         *ngIf="dadosAnalise?.dados?.nome"></svg>
                                </div>
                                <h5 nz-typography style="margin-bottom: 0; margin-left: 10px;">
                                    {{modoDemo(dadosAnalise?.dados?.nome, 'empresaCodigoNome')}}
                                </h5>
                            </div>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle="CNPJ">
                                    {{modoDemo(dadosAnalise?.dados?.cnpj | cpfCnpj, 'cnpj')}}
                                </nz-descriptions-item>
                                <!--<nz-descriptions-item nzTitle="Período">
                                    {{dadosAnalise.dataInicio | date: 'dd/MM/yyyy'}}
                                    à {{dadosAnalise.dataFim | date: 'dd/MM/yyyy'}}
                                </nz-descriptions-item>-->
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle="IE">
                                    {{dadosAnalise?.dados?.ie}}
                                </nz-descriptions-item>
                                <!--<nz-descriptions-item nzTitle="XMLs alterados">
                                    {{dadosAnalise.xmlAlterados ? dadosAnalise.xmlAlterados : '0'}}
                                </nz-descriptions-item>-->
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle="Competência">
                                    {{dadosAnalise?.dados?.competencia | date: 'MM/yyyy'}}
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </nz-card>
                    </nz-col>

                </nz-row>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="12">
                        <nz-card class="ant-card-table" [nzCover]="coverCardEscrituradas">
                            <nz-row style="height: 180px;">
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center ant-card-hoverable"
                                        style="flex-direction: column; cursor: pointer;"
                                        (click)="addRemoveFilter('temSefaz')"
                                        [class.activeFiltro]="filtrosCabecalhos.temSefaz">
                                    <h4 nz-typography
                                        style="color: #28d094; margin: 0; font-size: 36px">
                                        {{dadosAnalise?.sefaz?.qtdSefaz}}
                                    </h4>
                                </nz-col>
                                <nz-col [nzSpan]="12">
                                    <nz-content style="height: 100%;">
                                        <nz-row class="ant-card-hoverable"
                                                style="font-size: 24px;
                                                height: 100%;">
                                            <nz-col [nzSpan]="24" class="d-flex justify-content-center"
                                                    style="flex-direction: column;"
                                                    (click)="addRemoveFilter('temArmazenadas')"
                                                    [class.activeFiltro]="filtrosCabecalhos.temArmazenadas">
                                                <div class="d-flex justify-content-between">
                                                    <span style="padding-left: 25px;">Valor:</span>
                                                    <span style="color: #28d094; padding-right: 25px;">
                                                            {{dadosAnalise?.sefaz?.valorSefaz | number: '1.2-2' : 'pt-br'}}
                                                        </span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <span style="padding-left: 25px;">Armazenados:</span>
                                                    <span style="color: #28d094; padding-right: 25px;">
                                                            {{dadosAnalise?.sefaz?.qtdArmazenadas}}
                                                        </span>
                                                </div>
                                            </nz-col>
                                        </nz-row>
                                    </nz-content>
                                </nz-col>
                            </nz-row>
                        </nz-card>
                        <ng-template #coverCardEscrituradas>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center"
                                             style="width: 35px; height: 35px; background-color: #28d094; color: #fff; border-radius: 50%; margin-right: 8px;">
                                            <i class="fas fa-cloud"></i>
                                        </div>
                                        <span style="color: #28d094;">SEFAZ</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <nz-card class="ant-card-table" [nzCover]="coverCardArmazenados">
                            <nz-row style="height: 180px;">
                                <nz-col [nzSpan]="12"
                                        class="d-flex justify-content-center align-items-center ant-card-hoverable"
                                        style="flex-direction: column; cursor: pointer;"
                                        (click)="addRemoveFilter('temSefaz2')"
                                        [class.activeFiltro]="filtrosCabecalhos.temSefaz2">
                                    <h4 nz-typography
                                        style="color: #ff9149; margin: 0; font-size: 36px">
                                        {{dadosAnalise?.sefaz?.qtdSefaz}}
                                    </h4>
                                </nz-col>
                                <nz-col [nzSpan]="12">
                                    <nz-content style="height: 100%;">
                                        <nz-row class="ant-card-hoverable"
                                                style="font-size: 24px;
                                                height: 100%;">
                                            <nz-col [nzSpan]="24" class="d-flex justify-content-center"
                                                    style="flex-direction: column;"
                                                    (click)="addRemoveFilter('temErp')"
                                                    [class.activeFiltro]="filtrosCabecalhos.temErp">
                                                <div class="d-flex justify-content-between">
                                                    <span style="padding-left: 25px;">Valor:</span>
                                                    <span style="color: #ff9149; padding-right: 25px;">
                                                            {{dadosAnalise?.sefaz?.valorSefaz | number: '1.2-2' : 'pt-br'}}
                                                        </span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <span style="padding-left: 25px;">Armazenados:</span>
                                                    <span style="color: #ff9149; padding-right: 25px;">
                                                            {{dadosAnalise?.sefaz?.qtdArmazenadas}}
                                                        </span>
                                                </div>
                                            </nz-col>
                                        </nz-row>
                                    </nz-content>
                                </nz-col>
                            </nz-row>
                        </nz-card>
                        <ng-template #coverCardEscrituradas>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center"
                                             style="width: 35px; height: 35px; background-color: #28d094; color: #fff; border-radius: 50%; margin-right: 8px;">
                                            <i class="fas fa-cloud"></i>
                                        </div>
                                        <span style="color: #28d094;">SEFAZ</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #coverCardArmazenados>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center"
                                             style="width: 35px; height: 35px; background-color: #ff9149; color: #fff; border-radius: 50%; margin-right: 8px;">
                                            <i class="fa fa-database"></i>
                                        </div>
                                        <span style="color: #ff9149;">Armezenados</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>

                </nz-row>
            </nz-content>
        </nz-col>
        <!--<nz-col [nzSpan]="5">
            <nz-card class="bg-white-exagon ant-card-table" [nzCover]="coverCardDivergencias">
                <nz-content>
                    <nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="24">
                            <div>
                                <nz-list nzSize="small">
                                    <nz-list-item *ngFor="let item of divergencias"
                                                  class="ant-card-grid-hoverable" (click)="filtrarClick(item.chave)">
                                        <span nz-typography>{{item.label}}</span>
                                        <a nz-button nzType="link" style="padding: 0;"
                                        >{{ item.qtd }}</a>
                                    </nz-list-item>
                                </nz-list>
                            </div>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
            <ng-template #coverCardDivergencias>
                <div class="ant-card-head ng-star-inserted">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span>
                                <i class="fas fa-not-equal" style="margin-right: 5px;"></i> Divergências
                            </span>
                            <a (click)="filtrarClick('status_2')">{{contadores.status_dv?.total}}</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </nz-col>-->
    </nz-row>
</nz-content>
<nz-content>
    <nz-card [ngClass]="[!loadings.table ? 'ant-card-table' : '']"
             [nzExtra]="navBar" nzTitle="Notas"
             [nzLoading]="loadings.table">
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItemsNfe.size > 0 || checkedItemsCte.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItemsNfe.size + checkedItemsCte.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  *ngIf="pagination.per_page"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTableErp($event)"
                  [nzShowTotal]="rangeTemplate"
                  [nzScroll]="{ x: (screenWidth * 2) + 'px'}"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="center" nzLeft nzWidth="50px">
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllCheckedSefaz($event)"></label>
                </th>
                <th nzWidth="80px"></th>
                <th nzAlign="center" nzColumnKey="numero" [nzSortFn]="true">Nota</th>
                <th nzWidth="80px" nzAlign="center" nzColumnKey="modelo" [nzSortFn]="true">Modelo</th>
                <th nzWidth="80px" nzAlign="center" nzColumnKey="serie" [nzSortFn]="true">Série</th>
                <th nzWidth="350px" nzAlign="center" nzColumnKey="chave" [nzSortFn]="true">Chave</th>
                <th nzAlign="center" nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                <th nzAlign="center">Tipo Emissão</th>
                <th nzWidth="150px" nzAlign="center" nzColumnKey="documento" [nzSortFn]="true">Documento</th>
                <th nzWidth="250px" nzAlign="center" nzColumnKey="participante" [nzSortFn]="true">Participante</th>
                <th nzWidth="80px" nzAlign="center" nzColumnKey="uf" [nzSortFn]="true">UF</th>
                <th nzAlign="center" nzColumnKey="situacao" [nzSortFn]="true">Situação</th>
                <th nzWidth="250px" nzAlign="center" nzColumnKey="manifestacaoEventoDescricao" [nzSortFn]="true">
                    Manifestacão
                </th>
                <th nzAlign="center" nzColumnKey="emissao" [nzSortFn]="true">Data Emissão</th>
                <th nzAlign="center" nzColumnKey="armazenamento" [nzSortFn]="true">Data Armazenamento</th>
                <th nzAlign="center" nzColumnKey="valor" [nzSortFn]="true">Valor</th>
                <th nzRight nzWidth="80px"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemCheckedSefaz(item.chave, item.modelo, $event)"
                    [nzChecked]="item.modelo == 55 ? checkedItemsNfe.has(item.chave) : item.modelo == 57 ? checkedItemsCte.has(item.chave) : false"
                    [nzDisabled]="item.disabled">
                </td>
                <td nzAlign="center">
                    <i *ngIf="item.temSefaz" nz-tooltip="true" nzTooltipTitle="Sefaz" class="fa fa-cloud"
                       aria-hidden="true"
                       style="color: #28d094; margin-right: 10px;"></i>
                    <i *ngIf="item.temErp" nz-tooltip="true" [nzTooltipTitle]="item.sapNome" class="fa fa-server"
                       aria-hidden="true"
                       style="color: #9c27b0; margin-right: 10px;"></i>
                    <i *ngIf="item.temArmazenadas" nz-tooltip="true" nzTooltipTitle="Armazenadas" class="fa fa-database"
                       aria-hidden="true"
                       style="color: #ff9149;"></i>
                </td>
                <td nzAlign="center">{{item.numero}}</td>
                <td nzAlign="center">{{item.modelo}}</td>
                <td nzAlign="center">{{item.serie}}</td>
                <td nzAlign="center">{{item.chave}}</td>
                <td nzAlign="center">{{item.tipo == 0 ? 'Entrada' : 'Saída'}}</td>
                <td nzAlign="center">{{item.destinatarioDocumento == item.emitenteDocumento ? 'Própria' : 'Terceiro'}}</td>
                <td nzAlign="center">
                    {{item.emitenteDocumento | cpfCnpj}}
                </td>
                <td nzAlign="center">
                    {{item.emitenteNome}}
                </td>
                <td nzAlign="center">{{item.uf}}</td>
                <td nzAlign="center">{{item.situacao === 'A' ? 'Autorizada' : (item.situacao === 'C' ? 'Cancelada' : '')}}</td>
                <td nzAlign="center">
                    <div *ngIf="item?.manifestacaoEventoStatus === 1"
                         class="custom-alert"
                         style="background-color: #e6f7ff;border: 1px solid #91d5ff;">
                        {{item.manifestacaoEventoDescricao}}
                        <br>
                        <small>
                            {{item?.manifestacaoProtocoloData | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </small>
                        <small *ngIf="item?.manifestacaoUsuarioNome">Por {{item?.manifestacaoUsuarioNome}}</small>
                    </div>
                    <div *ngIf="item?.manifestacaoEventoStatus === 2" class="custom-alert"
                         style="background-color: #f6ffed;border: 1px solid #b7eb8f;">
                        {{item.manifestacaoEventoDescricao}}
                        <br>
                        <small>
                            {{item?.manifestacaoProtocoloData | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </small>
                        <small *ngIf="item?.manifestacaoUsuarioNome">Por {{item?.manifestacaoUsuarioNome}}</small>
                    </div>
                    <div *ngIf="item?.manifestacaoEventoStatus === 3 || item?.manifestacaoEventoStatus === 5 || item?.manifestacaoEventoStatus === 4"
                         class="custom-alert"
                         style="background-color: #fff2f0; border: 1px solid #ffccc7;">
                        {{item.manifestacaoEventoDescricao}}
                        <br>
                        <small>
                            {{item?.manifestacaoProtocoloData | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </small>
                        <small *ngIf="item?.manifestacaoUsuarioNome">Por {{item?.manifestacaoUsuarioNome}}</small>
                    </div>
                </td>
                <td nzAlign="center">{{item.dataEmissao | date : 'dd/MM/yyyy'}}</td>
                <td nzAlign="center">{{item.aataArmazenamento | date : 'dd/MM/yyyy'}}</td>
                <td nzAlign="center">{{item.valor | number: '1.2-2' : 'pt-br'}}</td>
                <td nzRight>
                    <div *ngIf="item.temArmazenadas">
                        <button nz-button nzType="link" nz-tooltip="true" nzTooltipTitle="Download XML"
                                (click)="downloadXml(item.modelo, item.chave)">
                            <i class="far fa-file-code"></i>
                        </button>
                        <button nz-button nzType="link" nz-tooltip="true" nzTooltipTitle="Download PDF"
                                (click)="downloadDanfe(item.modelo, item.chave)">
                            <i class="far fa-file-pdf"></i>
                        </button>
                    </div>
                    <div *ngIf="!item.temArmazenadas">
                        <button nz-button nzType="link" nz-tooltip="true" nzTooltipTitle="Não disponível" style="cursor: not-allowed;">
                            <i class="far fa-file-code" style="color: rgba(0,0,0,.25);"></i>
                        </button>

                        <button nz-button nzType="link" nz-tooltip="true" nzTooltipTitle="Não disponível" style="cursor: not-allowed;">
                            <i class="far fa-file-pdf" style="color: rgba(0,0,0,.25);"></i>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
    <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
    </ng-template>
</nz-content>
<ng-template #navBar>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary"
                    (click)="modalFiltrar(true);"
                    style="margin-right: 10px;">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button nz-button [nzType]="'primary'"
                style="margin-right: 10px;" (click)="modalExportar(true)">
            <span>Exportar</span>
        </button>
        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default" style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-submenu nzTitle="Manifestar NFe Como:"
                            [nzDisabled]="checkedItemsNfe?.size === 0">
                            <ul>
                                <li nz-menu-item
                                    (click)="checkedItemsNfe?.size > 0 && registrarManifestacaoConfirm(null, 1)"
                                    [nzDisabled]="checkedItemsNfe?.size === 0">
                                    Ciência da Operação
                                </li>
                                <li nz-menu-item
                                    (click)="checkedItemsNfe?.size > 0 && registrarManifestacaoConfirm(null, 2)"
                                    [nzDisabled]="checkedItemsNfe?.size === 0">
                                    Confirmada a Operação
                                </li>
                                <li nz-menu-item
                                    (click)="checkedItemsNfe?.size > 0 && modalMotivo(true)"
                                    [nzDisabled]="checkedItemsNfe?.size === 0">
                                    Operação não Realizada
                                </li>
                                <li nz-menu-item
                                    (click)="checkedItemsNfe?.size > 0 && registrarManifestacaoConfirm(null, 4)"
                                    [nzDisabled]="checkedItemsNfe?.size === 0">
                                    Desconhecida
                                </li>
                            </ul>
                        </li>
                        <li nz-submenu nzTitle="Manifestar CTe Como:"
                            [nzDisabled]="checkedItemsCte?.size === 0">
                            <ul>
                                <li nz-menu-item
                                    (click)="checkedItemsCte?.size > 0 && registrarManifestacaoConfirm(null, 5)"
                                    [nzDisabled]="checkedItemsCte?.size === 0">
                                    Desacordo
                                </li>
                            </ul>
                        </li>


                    </ul>
                </li>
                <!--<li nz-menu-item>Exportar Planilha</li>-->
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input (keydown.enter)="searsh();" type="text" nz-input placeholder="Busca" [(ngModel)]="pesquisar"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch style="margin-right: 10px;" (click)="searsh();">
                <i nz-icon nzType="search"></i>
            </button>
        </ng-template>
        <button nz-button nzType="default" (click)="limparFiltros();">Limpar</button>
    </div>
</ng-template>
<ng-template #botoesQuebra>
    <div class="d-flex align-items-center">
        <button nz-button nzType="default" (click)="cardQuebraSequencia(false)">Notas</button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="formFiltrarCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarCabecalho(false)"
          [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarCabecalho.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="4">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.empresa">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="empresa">
                        <nz-option *ngFor="let opt of comboEmpresas"
                                   [nzLabel]="modoDemo(opt.nome, 'empresaCodigoNome') + ' (' + (modoDemo('29488701000101' | cpfCnpj, 'cnpj')) + ')'" [nzValue]="opt.id">
                        </nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>


<!--            <nz-form-item>-->
<!--                <nz-form-label [nzSpan]="4" class="gutter-row">Competência</nz-form-label>-->
<!--                <nz-form-item nz-col [nzSpan]="17">-->
<!--                    <nz-form-control nzValidateStatus="success"-->
<!--                                     [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.competencia">-->
<!--                        <nz-date-picker [nzFormat]="'MM/yyyy'"-->
<!--                                        formControlName="competencia" style="width: 100%;"-->
<!--                                        (ngModelChange)="limitDate($event)"-->
<!--                                        nzPlaceHolder="MM/AAAA"></nz-date-picker>-->
<!--                    </nz-form-control>-->
<!--                </nz-form-item>-->
<!--            </nz-form-item>-->

            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Competência</nz-form-label>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.dataCompetenciaDe">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataCompetenciaDe" style="width: 100%;"
                                        (ngModelChange)="limitDateCompetencia($event)"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.dataCompetenciaAte">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataCompetenciaAte" style="width: 100%;"
                                        [nzDisabledDate]="disabledDateCompetencia"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarCabecalho(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarCabechalho()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Nota</nz-form-label>
                <nz-form-control [nzSpan]="8"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.numeroDe !== '' && formFiltrar.formGroup.value.numeroDe !== null">
                    <nz-input-number [nzStep]="1" [nzMin]="0" [nzMax]="999999999" formControlName="numeroDe"
                                     [nzPlaceHolder]="'De:'" style="width: 100%;"></nz-input-number>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="8"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.numeroAte !== '' && formFiltrar.formGroup.value.numeroAte !== null">
                    <nz-input-number [nzStep]="1" [nzMin]="0" [nzMax]="999999999" formControlName="numeroAte"
                                     [nzPlaceHolder]="'Até'" style="width: 100%;"></nz-input-number>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Modelo</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.modelo">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="modelo" nzAllowClear>
                        <nz-option [nzLabel]="'NFe (55)'" [nzValue]="'55'"></nz-option>
                        <nz-option [nzLabel]="'CTe (57)'" [nzValue]="'57'"></nz-option>
                        <!--                        <nz-option [nzLabel]="'CFE (59)'" [nzValue]="'59'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'NFCe (65)'" [nzValue]="'65'"></nz-option>-->
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Série</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.serie !== '' && formFiltrar.formGroup.value.serie !== null">
                    <nz-input-number [nzStep]="1" [nzMin]="0" [nzMax]="999" formControlName="serie"
                                     style="width: 100%;"></nz-input-number>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.tipo">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" nzAllowClear>
                        <nz-option [nzLabel]="'Entrada'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Saída'" [nzValue]="'1'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Tipo Emissão</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.tipoEmissao">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipoEmissao" nzAllowClear>
                        <nz-option [nzLabel]="'Próprio'" [nzValue]="'P'"></nz-option>
                        <nz-option [nzLabel]="'Terceiro'" [nzValue]="'T'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Participante</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.participanteDocumento !== '' && formFiltrar.formGroup.value.participanteDocumento !== null">
                    <nz-input-number [nzStep]="1" [nzMin]="0" [nzMax]="99999999999999"
                                     formControlName="participanteDocumento" style="width: 100%;"></nz-input-number>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">UF</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.uf">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="uf">
                        <nz-option *ngFor="let uf of comboUFs" [nzLabel]="uf.label" [nzValue]="uf.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Situação</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.situacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Autorizadas'" [nzValue]="'A'"></nz-option>
                        <nz-option [nzLabel]="'Canceladas / Denegadas'" [nzValue]="'C'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Manifestação</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.manifestacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [nzOptions]="listOfGroupOption"
                               formControlName="manifestacao">

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Emissão</nz-form-label>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.dataEmissaoDe">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataEmissaoDe" style="width: 100%;"
                                        (ngModelChange)="limitDate($event)"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.dataEmissaoAte">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataEmissaoAte" style="width: 100%;"
                                        [nzDisabled]="!habilitarEmissaoData"
                                        [nzDisabledDate]="disabledDate"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-label nz-col class="gutter-row" [nzSpan]="3" [nzOffset]="1">Valor</nz-form-label>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.valorDe">
                        <input nz-input type="text" formControlName="valorDe" placeholder="De:" currencyMask
                               [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="8">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.valorAte">
                        <input nz-input type="text" formControlName="valorAte" placeholder="Até:" currencyMask
                               [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                    </nz-form-control>
                </nz-form-item>
            </div>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Chave</nz-form-label>
                <nz-form-control [nzSpan]="17"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.chave">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="chave">
                        <nz-option [nzLabel]="'Com'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Sem'" [nzValue]="'2'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Solicitar Exportação'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="525">
    <div nz-row *nzModalContent>

        <p nz-typography>Selecione o formato do(s) arquivo(s) a ser(em) exportado(s).</p>

        <nz-list nzItemLayout="horizontal" nz-col [nzSpan]="24">
            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportarSemFiltros('xlsx')">
                <button style="margin-left: 12px; height: 70px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.xlsx">
                    <i nz-icon nzType="file-excel" style="color: #28a745;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18" >
                    <span>Gera um arquivo XLSX com todas as notas</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('xml')">
                <button style="margin-left: 12px; height: 70px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.xml">
                    <i class="far fa-file-code" style="color: #6c757d;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18" >
                    <span>Exporta um arquivo .zip com os XMLs (autorizados) das notas listadas na pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('pdf')">
                <button style="margin-left: 12px; height: 70px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.pdf">
                    <i nz-icon nzType="file-pdf" nzTheme="outline" style="color: #dc3545; font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18" >
                    <span>Exporta um arquivo .zip com DANFE's/DACTE's das notas listadas na pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('xlsx')">
                <button style="margin-left: 12px; height: 70px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.xlsx">
                    <i nz-icon nzType="file-excel" style="color: #28a745;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18" >
                    <span>Gera um arquivo XLSX com as notas filtradas</span>
                </div>

            </nz-list-item>

        </nz-list>


        <!--        <div nz-col [nzSpan]="24">-->
        <!--            <div nz-row>-->
        <!--                <button nz-button nzType="link" nz-col [nzSpan]="12" style="height: 70px;" nz-tooltip nzTooltipTitle="XML"-->
        <!--                        (click)="confirmaExportar('xml')"-->
        <!--                        [nzLoading]="loadings.xml">-->
        <!--                    <i class="far fa-file-code" style="color: #6c757d;  font-size: 45px"></i>-->
        <!--                </button>-->
        <!--                <span nz-col [nzSpan]="12">XML</span>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        <div nz-col [nzSpan]="24">-->
        <!--            <div nz-row>-->
        <!--                <button nz-button nzType="link" nz-col [nzSpan]="12" style="height: 70px;" nz-tooltip nzTooltipTitle="XML"-->
        <!--                        (click)="confirmaExportar('xml')"-->
        <!--                        [nzLoading]="loadings.xml">-->
        <!--                    <i class="far fa-file-code" style="color: #6c757d;  font-size: 45px"></i>-->
        <!--                </button>-->
        <!--                <span nz-col [nzSpan]="12">XML</span>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        <div nz-col [nzSpan]="24">-->
        <!--            <div nz-row>-->
        <!--                <button nz-button nzType="link" nz-col [nzSpan]="12" style="height: 70px;" nz-tooltip nzTooltipTitle="PDF"-->
        <!--                        (click)="confirmaExportar('pdf')"-->
        <!--                        [nzLoading]="loadings.pdf">-->
        <!--                    <i nz-icon nzType="file-pdf" nzTheme="outline" style="color: #dc3545; font-size: 45px"></i>-->
        <!--                </button>-->
        <!--                <span nz-col [nzSpan]="12">PDF</span>-->
        <!--            </div>-->
        <!--        </div>-->


    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <!--        <button nz-button nzType="primary" (click)="confirmaExportar()">Solicitar</button>-->
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formMotivo.modalVisible"
          [nzTitle]="'Digite o Motivo'" [nzClosable]="true"
          (nzOnCancel)="modalMotivo(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formMotivo.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-control [nzSpan]="24">
                    <input nz-input type="text" formControlName="motivo"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalMotivo(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="registrarManifestacao(null, 3)">Enviar</button>
    </div>
</nz-modal>
<div class="table-scroller">
    <button nz-button nzShape="circle" nzType="primary" (click)="scrollLeft()" style="margin-right: 10px;">
        <i nz-icon nzType="left" nzTheme="outline"></i>
    </button>
    <button nz-button nzShape="circle" nzType="primary" (click)="scrollRight()">
        <i nz-icon nzType="right" nzTheme="outline"></i>
    </button>
</div>

<nz-modal [(nzVisible)]="modalStatusManifestacaoVisible"
          [nzTitle]="'Resultado das manifestações'" [nzClosable]="true"
          (nzOnCancel)="modalRetornoManifestacoes(false)"
          [nzWidth]="725">
    <div *nzModalContent>
        <div>
            <cdk-virtual-scroll-viewport itemSize="6" class="demo-infinite-container" style="">
                <nz-alert *ngFor="let status of arrayStatusManifestacao"
                          [nzType]="status.sucesso? 'success' : 'error'" nzShowIcon [nzMessage]="checkedMessage"
                          style="width: 100%; margin-top: 15px !important;">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                {{status.mensagem}}
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>

            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalRetornoManifestacoes(false);">Fechar</button>
    </div>
</nz-modal>

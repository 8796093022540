import {AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Empresa} from '@models/empresa.model';
import {ObrigacaoService} from '@services/obrigacao.service';
import {ToastrService} from 'ngx-toastr';
import {StickyHeaderDirective} from '@components/directive/directive';
import {HostListener} from '@angular/core';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Vencimento} from '@models/vencimento.model';
import {TabHandlerInterface} from '../../../../shared/interfaces/tab-handler.interface';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {Helpers} from '../../../../core/helpers';
import {UserService} from '@services/user.service';
import {ObrigacaoVencimentoService} from "@services/obrigacaoVencimento.service";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-obrigacao-detalhe',
    templateUrl: './obrigacao-detalhe.component.html',
    styleUrls: ['./obrigacao-detalhe.component.scss'],
})
export class ObrigacaoDetalheComponent extends AbstractListTable<Vencimento> implements OnInit, AfterViewInit, TabHandlerInterface {
    @Input() data;

    obrigacao: any = {};
    objetoEditavel: any = {};

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    vencimentos: any[] = [];
    loading = false;
    loadingVencimentos = true;
    description = '';
    radioValueOrgao = 'federal';
    checkedLucroPresumido = false;
    checkedSomenteMatriz = '0';
    checkedLucroRealTrimestral = false;
    checkedLucroRealAnual = false;
    checkedSimplesNacional = false;
    checkedIndustria = false;
    checkedAtacado = false;
    checkedVarejo = false;
    checkedServico = false;
    formDadosCadastrais: FormStack;
    formSistema: FormStack;
    formPrazo: FormStack;
    formVencimento: FormStack;

    gravando = false;
    empresa: Empresa;

    selectedValue = new Date().getFullYear().toString();

    radioDisponivelAgenda = 'sim';
    radioDisponivelCompliance = 'sim';
    radioDisponivelValidacao = 'sim';
    radioDisponivelTransmissao = 'sim';
    prazoExpiracao = 7;
    radioBloqueado = 'sim';
    pagination: Pagination;
    currentParams: NzTableQueryParams;
    currentSearch: string;

    editarId = '';
    editarCompetencia = '';
    editarVencimento = new Date();
    editarAgendaConfirmavel = '';

    comboCategoria: any = [];

    offsetTop = 15;
    target = StickyHeaderDirective.target;

    arrayAnos = [];

    comboUFs: any = [];
    comboMunicipio: any = [];

    erro = false;
    mensagem = '';

    mask = '00/0000';

    vencimentosSugeridos = [];
    modalVencimentosSugeridosVidible = false;
    ultimoAno: number;

    usuarioLogado: any;

    constructor(
        private fb: UntypedFormBuilder,
        private obrigacaoService: ObrigacaoService,
        private obrbrigacaoVencimentoService: ObrigacaoVencimentoService,
        private toastService: ToastrService,
        private zone: NgZone,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService
    ) {
        super(obrbrigacaoVencimentoService, Vencimento, toastService);

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, Validators.required],
                descricao: [null, Validators.required],
                tipo: [null, Validators.required],
                periodicidade: [null, Validators.required],
                orgao: [null, Validators.required],
                uf: [null, Validators.required],
                municipioCodigo: [null, Validators.required],
                somenteMatriz: [null, Validators.required],
                regimeLP: [null],
                regimeLRT: [null],
                regimeLRA: [null],
                regimeSN: [null],
                atividadeIndustria: [null],
                atividadeVarejo: [null],
                atividadeAtacado: [null],
                atividadeServico: [null],
                categoria: [null],
                vencimentoDia: [null],
                vencimentoMes: [null],
                vencimentoUtil: [null, Validators.required],
                vencimentoModalidade: [null],
                vencimentoMesSubsequente: [null]
            })
        };

        this.formSistema = {
            modalVisible: false,
            formGroup: this.fb.group({
                disponivelAgenda: [null, Validators.required],
                disponivelCompliance: [null, Validators.required],
                disponivelValidacao: [null, Validators.required],
                disponivelTransmissao: [null, Validators.required],
                prazoExpiracao: [null, Validators.required],
                obrigacaoCompliance: [null],
                bloqueado: [null, null],
                emManutencao: [null, null],
            })
        };

        this.formPrazo = {
            modalVisible: false,
            formGroup: this.fb.group({
                prazoExpiracaoConsulta: [null, null],
            })
        };

        this.formVencimento = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, null],
                competencia: [null, null],
                vencimento: [null, null],
                agendaConfirmavel: [null, null],
            })
        };


        this.obrigacaoService.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

        this.obrigacaoService.retornaCategorias().subscribe((retorno: any) => {

            retorno.data.forEach((value) => {
                value.label = value.descricao;
                value.key = value.categoria_id;
            });
            this.comboCategoria = retorno.data;

        });

    }

    @HostListener('mousewheel', ['$event']) onMousewheel(event) {
        this.target = StickyHeaderDirective.target;
    }

    ngAfterViewInit() {
        this.onMousewheel(null);
    }

    keyPressNumber(event) {
        Helpers.keyPressNumber(event);
    }

    retornaDadosObrigacao(id: string): void {

        this.obrigacaoService.findById(id).subscribe((response: any) => {

            response.estado = this.retornaNomeEstado(response.uf);

            const regimes = [];

            if (response.regimeLP) {
                regimes.push('LP - Lucro Presumido');
                this.checkedLucroPresumido = true;
            }
            if (response.regimeLRT) {
                regimes.push('LRT - Lucro Real Trimestral');
                this.checkedLucroRealTrimestral = true;
            }
            if (response.regimeLRA) {
                regimes.push('LRA - Lucro Real Anual');
                this.checkedLucroRealAnual = true;
            }
            if (response.regimeSN) {
                regimes.push('SN - Simples Nacional');
                this.checkedSimplesNacional = true;
            }

            const atividades = [];
            if (response.atividadeIndustria) {
                atividades.push('I - Industria');
                this.checkedIndustria = true;
            }
            if (response.atividadeVarejo) {
                atividades.push('V - Varejo');
                this.checkedVarejo = true;
            }
            if (response.atividadeAtacado) {
                atividades.push('A - Atacado');
                this.checkedAtacado = true;
            }
            if (response.atividadeServico) {
                atividades.push('S - Serviço');
                this.checkedServico = true;
            }

            response.bloqueado = response.bloqueado && response.bloqueado.toUpperCase() === 'SIM';
            response.emManutencao = response.emManutencao;

            response.regimesTricutarios = regimes.join(', ');
            response.atividades = atividades.join(', ');

            this.checkedSomenteMatriz = response.somenteMatriz ? response.somenteMatriz.toString() : '0';

            this.obrigacao = response;

            if (!response.agendaConfirmavel) {
                this.obrigacao.agendaConfirmavel = 0;
            }

            this.objetoEditavel = response;

        });
    }

    ngOnInit(): void {

        this.retornaDadosObrigacao(this.data.id);

        this.atualizaComboAnos();

        this.getLoggedUser();
    }

    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.usuarioLogado = user);
    }

    atualizaComboAnos(): void {
        this.obrigacaoService.retornaAnos(this.data.id).subscribe((retorno: any) => {
            this.arrayAnos = [];
            this.arrayAnos = retorno;
        });
        this.cdr.detectChanges();
    }

    listarVencimentos(event: NzTableQueryParams): void {
        this.currentParams = event;
        this.loadingVencimentos = true;

        event.filter.push({key: 'obrigacao_id', value: this.data.id});
        event.filter.push({key: 'ano', value: this.selectedValue});
        this.queryTable(event, this.currentSearch);

        this.vencimentos = [];
        setTimeout(() => {
            // this.vencimentos = this.items;
            this.loadingVencimentos = false;
            this.cdr.detectChanges();
        }, 500);

        this.cdr.detectChanges();
    }

    editar(formulario: FormStack): void {
        if (formulario.formGroup.get('categoria')) {
            formulario.formGroup.get('categoria').setValue(this.obrigacao.categoria);
        }

        formulario.modalVisible = true;
    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;
    }

    toggleOrgao(event) {
        this.radioValueOrgao = event;
        if (this.radioValueOrgao !== 'Federal' && this.radioValueOrgao !== 'Previdenciário') {
            this.formDadosCadastrais.formGroup.get('atividadeAtacado').setValue(false);
            this.formDadosCadastrais.formGroup.get('atividadeIndustria').setValue(false);
            this.formDadosCadastrais.formGroup.get('atividadeServico').setValue(false);
            this.formDadosCadastrais.formGroup.get('atividadeVarejo').setValue(false);
            this.formDadosCadastrais.formGroup.get('regimeLP').setValue(false);
            this.formDadosCadastrais.formGroup.get('regimeLRA').setValue(false);
            this.formDadosCadastrais.formGroup.get('regimeLRT').setValue(false);
            this.formDadosCadastrais.formGroup.get('regimeSN').setValue(false);
        } else if (this.radioValueOrgao === 'Federal' || this.radioValueOrgao === 'Previdenciário') {
            this.formDadosCadastrais.formGroup.get('uf').setValue(null);
            this.formDadosCadastrais.formGroup.get('municipioCodigo').setValue(null);
        }

    }

    alterarAnoVencimentos(ano: string) {

        this.zone.run(() => {
            this.selectedValue = ano;

            this.currentParams.filter.forEach((value, index) => {
                if (value.key === 'ano' || value.key === 'obrigacao_id') {
                    value.key = null;
                }
            });
            this.listarVencimentos(this.currentParams);

            this.cdr.detectChanges();

            // setTimeout(() => {
            //
            //     t
            // }, 500);

        });

    }


    update(campos: any, form?: string): void {

        if (form === 'formDadosCadastrais') {
            delete campos.id;
            if (this.radioValueOrgao !== 'Federal' && this.radioValueOrgao !== 'Previdenciário') {
                delete campos.atividadeAtacado;
                delete campos.atividadeIndustria;
                delete campos.atividadeServico;
                delete campos.atividadeVarejo;
                delete campos.regimeLP;
                delete campos.regimeLRA;
                delete campos.regimeLRT;
                delete campos.regimeSN;
                delete campos.somenteMatriz;
            } else if (this.radioValueOrgao === 'Federal' || this.radioValueOrgao === 'Previdenciário') {
                delete campos.uf;
                delete campos.municipioCodigo;
            }

            if (this.radioValueOrgao === 'Estadual') {
                delete campos.municipioCodigo;
            }
        }


        // Validar Campos
        let erro = false;
        let mensagem = '';
        // tslint:disable-next-line:max-line-length
        if (campos.orgao === 'Federal' && !campos.atividadeIndustria && !campos.atividadeVarejo && !campos.atividadeAtacado && !campos.atividadeServico) {
            erro = true;
            mensagem = 'Ao menos 1 campo em atividades é obrigatório.';
        }

        if (this.formDadosCadastrais.formGroup.get('periodicidade').value === 'A' && !campos.vencimentoMes) {
            erro = true;
            mensagem = 'Campo Mês Vencimento é obrigatório.';
        }

        if (!erro && campos.periodicidade === 'A' && this.formDadosCadastrais.formGroup.value.vencimentoMes) {
            campos.vencimentoModalidade = null;
            campos.vencimentoMesSubsequente = null;
            const mesVencimento = new Date(this.formDadosCadastrais.formGroup.get('vencimentoMes').value).getMonth() + 1;
            this.formDadosCadastrais.formGroup.get('vencimentoMes').setValue(mesVencimento);
            campos.vencimentoMes = mesVencimento;
        } else if (this.formDadosCadastrais.formGroup.value.vencimentoMes) {
            campos.vencimentoMes = null;
        }

        if (campos.vencimentoDia && (campos.vencimentoDia > 31 || campos.vencimentoDia < 1)) {
            erro = true;
            mensagem = 'Dia de vencimento inválido';
        }

        if (campos.periodicidade === 'M' && !campos.vencimentoModalidade && !campos.vencimentoUtil) {
            erro = true;
            mensagem = 'Campo Modalidade é obrigatório';
        }

        if (campos.periodicidade === 'M' && !campos.vencimentoMesSubsequente && campos.vencimentoUtil === '0') {
            erro = true;
            mensagem = 'Campo Mês é obrigatório';
        }

        if (!erro) {


            this.obrigacaoService.update(this.obrigacao.id, campos).subscribe(
                (response) => {

                    this.toastrService.success('Obrigação atualizada com sucesso!');
                    this.formDadosCadastrais.modalVisible = false;
                    this.retornaDadosObrigacao(this.data.id);
                    this.gravando = false;
                    this.cdr.detectChanges();
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                }
            );
        } else {
            this.gravando = false;
            this.toastrService.error(mensagem);
        }

    }

    showConfirmTemAgenda(campo) {

        this.modalService.confirm({
            nzTitle: 'Todos os vencimentos dessa obrigação serão inseridos na Agenda do obrigações por empresa.',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.continuarAlterarSistema(campo),
            nzOnCancel: () => {
                this.obrigacao.temAgenda = !this.obrigacao.temAgenda;
            }
        });

    }

    updateVencimento(): void {


        this.validarFormVencimento();

        if (!this.erro) {
            this.gravando = true;

            this.obrigacaoService.updateVencimento(this.obrigacao.id, this.formVencimento.formGroup.value).subscribe(
                (response) => {

                    this.toastrService.success('Vencimento atualizado com sucesso!');
                    this.formVencimento.modalVisible = false;
                    this.queryTable(this.currentParams, this.currentSearch);
                    setTimeout(() => {
                        // this.vencimentos = this.items;
                        this.gravando = false;
                    }, 500);
                    this.atualizaComboAnos();
                    this.cdr.detectChanges();
                    this.gravando = false;


                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                }
            );


        } else {
            this.toastrService.error(this.mensagem);
            this.gravando = false;
            this.erro = false;
            this.mensagem = '';

        }
    }

    InsertVencimento(): void {

        this.validarFormVencimento();
        this.gravando = true;

        if (!this.erro) {
            this.gravando = true;
            this.obrigacaoService.insertVencimento(this.obrigacao.id, this.formVencimento.formGroup.value).subscribe(
                (response) => {
                    this.toastrService.success('Vencimento inserido com sucesso!');
                    this.formVencimento.modalVisible = false;
                    this.queryTable(this.currentParams, this.currentSearch);
                    setTimeout(() => {
                        // this.vencimentos = this.items;
                        this.gravando = false;
                    }, 500);
                    this.atualizaComboAnos();
                    this.cdr.detectChanges();
                    this.gravando = false;
                },
                (response) => {
                    Object.entries(response.error.errors).forEach(([key, value]) => {
                        this.toastrService.error(value);
                    });
                    this.gravando = false;
                }
            );
        } else {
            this.gravando = false;
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }

    }

    confirmarSalvar() {

        this.gravando = true;

        this.update(this.formDadosCadastrais.formGroup.value, 'formDadosCadastrais');
    }

    alterarMascara(event) {
        if (event.code === 'Backspace' && event.target.value.length <= 5) {

            this.mask = '000000';
        } else if (event.target.value.length > 4) {

            this.mask = '00/0000';
        } else {

            this.mask = '000000';
        }
    }


    retornaNomeEstado(uf: string):
        string {

        let estado = '';
        switch (uf) {
            case 'AC': {
                estado = 'Acre';
                break;
            }
            case 'AL': {
                estado = 'Alagoas';
                break;
            }
            case 'AP': {
                estado = 'Amapá';
                break;
            }
            case 'AM': {
                estado = 'Amazonas';
                break;
            }
            case 'BA': {
                estado = 'Bahia';
                break;
            }
            case 'CE': {
                estado = 'Ceará';
                break;
            }
            case 'DF': {
                estado = 'Distrito Federal';
                break;
            }
            case 'ES': {
                estado = 'Espírito Santo';
                break;
            }
            case 'GO': {
                estado = 'Goiás';
                break;
            }
            case 'MA': {
                estado = 'Maranhão';
                break;
            }
            case 'MT': {
                estado = 'Mato Grosso';
                break;
            }
            case 'MS': {
                estado = 'Mato Grosso do Sul';
                break;
            }
            case 'MG': {
                estado = 'Minas Gerais';
                break;
            }
            case 'PA': {
                estado = 'Pará';
                break;
            }
            case 'PB': {
                estado = 'Paraíba';
                break;
            }
            case 'PR': {
                estado = 'Paraná';
                break;
            }
            case 'PE': {
                estado = 'Pernanbuco';
                break;
            }
            case 'PI': {
                estado = 'Piauí';
                break;
            }
            case 'RJ': {
                estado = 'Rio de Janeiro';
                break;
            }
            case 'RN': {
                estado = 'Rio Grande do Norte';
                break;
            }
            case 'RS': {
                estado = 'Rio Grande do Sul';
                break;
            }
            case 'RR': {
                estado = 'Roraima';
                break;
            }
            case 'SC': {
                estado = 'Santa Catarina';
                break;
            }
            case 'SP': {
                estado = 'São Paulo';
                break;
            }
            case 'SE': {
                estado = 'Sergipe';
                break;
            }
            case 'TO': {
                estado = 'Tocantins';
                break;
            }
        }
        return estado;
    }


    alterarSistema(campo: string): void {

        if (campo === 'temAgenda' && this.obrigacao.temAgenda) {
            this.showConfirmTemAgenda(campo);

        } else {
            this.continuarAlterarSistema(campo);
        }
    }

    continuarAlterarSistema(campo: string): void {

        this.gravando = true;

        const campos: any = {};
        campos[campo] = this.obrigacao[campo];

        this.update(campos);

        this.gravando = false;
    }

    confirmar(formulario: FormStack): void {

        this.gravando = true;

        this.update(formulario.formGroup.value);

        formulario.modalVisible = false;

        this.gravando = false;

    }


    openTab(componentName: string, queryParams ?: string, data ?: {}) {
    }


    modalVencimento(id: string, competencia: string, vencimento: string, agendaConfirmavel: boolean): void {
        this.formVencimento.modalVisible = true;
        this.editarId = id;

        if (competencia) {
            if (competencia.length === 7) {
                const mes = this.obrigacaoService.mesStr(new Date(competencia + '-01T00:00').getMonth() + 1);
                const ano = new Date(competencia + '-01T00:00').getFullYear();
                this.editarCompetencia = mes + '/' + ano;
            } else {
                this.editarCompetencia = competencia;
            }
        }

        if (vencimento) {
            this.editarVencimento = new Date(vencimento);
            this.editarVencimento.setDate(this.editarVencimento.getDate() + 1);
        }

        agendaConfirmavel ? this.editarAgendaConfirmavel = 'Sim' : this.editarAgendaConfirmavel = 'Não';

    }

    removerVencimento(id: string): void {
        this.obrigacaoService.remove(id).subscribe(
            (response) => {

                this.toastrService.success('Vencimento removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                setTimeout(() => {
                    // this.vencimentos = this.items;
                    this.gravando = false;
                }, 500);
                this.cdr.detectChanges();
                this.gravando = false;
                this.cdr.detectChanges();
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );
    }

    showConfirmSugerirVencimento(): void {
        const modal: NzModalRef = this.modalService.create({
            nzTitle: 'Cadastro de vencimentos',
            nzContent: 'Selecione uma opção para o cadastro do vencimento',
            nzFooter: [
                {
                    label: 'Em Lote',
                    type: 'default',
                    onClick: () => {
                        this.sugerirVencimento();
                        modal.destroy();
                    }
                },
                {
                    label: 'Manual',
                    type: 'primary',
                    onClick: () => {
                        this.cadastrarVencimento();
                        modal.destroy();
                    },
                },
            ]
        });
    }

    sugerirVencimento() {
        this.retornaVencimentosSugeridos();
        this.toastService.warning('Essa opção irá sugerir vencimentos com base nas configurações do cadastro da obrigação');
    }

    retornaVencimentosSugeridos(): void {

        this.obrigacaoService.retornaVencimentosSugeridos().subscribe((retorno: any) => {
            this.vencimentosSugeridos = [];
            this.vencimentosSugeridos = retorno.reverse();
            this.ultimoAno = this.vencimentosSugeridos[this.vencimentosSugeridos.length - 1];
            this.showModalVencimentosSugeridos(true);
        }, error => {
            this.toastService.error(error.error.message);
        });
    }

    onAllChecked(checked: boolean): void {

        this.vencimentosSugeridos.forEach((ano) => this.updateCheckedSet(ano, checked));
    }

    onItemCheckedByClick(ano: any): void {

        if (this.checkedItems.has(ano)) {
            this.checkedItems.delete(ano);
        } else {
            this.checkedItems.add(ano);
        }
    }

    showModalVencimentosSugeridos(visible: boolean) {
        this.modalVencimentosSugeridosVidible = visible;
        if (!visible) {
            this.checkedItems.clear();
            this.gravando = false;
        }
    }

    InsertVencimentosSugeridos(): void {

        if (this.checkedItems.size === 0) {
            this.toastService.error('Selecione pelo menos um vencimento');
        } else {
            this.gravando = true;
            this.obrigacaoService.insertVencimentosEmLote(this.obrigacao.id, Array.from(this.checkedItems)).subscribe(
                (response) => {
                    this.showModalVencimentosSugeridos(false);
                    this.toastrService.success(response.message);
                    this.checkedItems.clear();
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.atualizaComboAnos();
                    this.cdr.detectChanges();
                    this.gravando = false;
                },
                (response) => {
                    this.showModalVencimentosSugeridos(false);
                    this.toastService.error(response.error.message);
                    this.gravando = false;
                }
            );
        }
    }


    cadastrarVencimento(): void {
        this.editarId = null;
        this.editarCompetencia = null;
        this.editarVencimento = null;
        this.editarAgendaConfirmavel = 'Não';
        this.formVencimento.formGroup.value.id = null;
        this.formVencimento.formGroup.value.vencimento = null;
        this.formVencimento.formGroup.value.competencia = null;
        this.formVencimento.formGroup.value.agendaConfirmavel = 'Não';
        this.formVencimento.modalVisible = true;
    }

    filtraComboMunicipio(uf: string): void {

        this.formDadosCadastrais.formGroup.get('municipioCodigo').setValue(null);

        this.obrigacaoService.retornaComboMunicipios(uf).subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboMunicipio = [];
            this.comboMunicipio = retorno.cidades;
        });
    }

    validarFormVencimento(): void {
        // VALIDA FORMULÁRIO
        const competencia = this.formVencimento.formGroup.value.competencia.replace('/', '').replace('-', '');

        if (competencia.length === 4) {
            const ano = competencia;
            if (Number(ano) < 2015 || Number(ano) > 2050) {
                this.erro = true;
                this.mensagem = 'Competência Inválida';
            }
            const dataCompetencia = ano + '-01-01';
            let dataVencimento = new Date(this.formVencimento.formGroup.value.vencimento).getFullYear().toString();
            dataVencimento += '-' + this.obrigacaoService.mesStr(new Date(this.formVencimento.formGroup.value.vencimento).getMonth() + 1);
            dataVencimento += '-' + this.obrigacaoService.mesStr(new Date(this.formVencimento.formGroup.value.vencimento).getDate());

            if (dataVencimento < dataCompetencia) {
                this.erro = true;
                this.mensagem = 'Vencimento deve ser maior que competência';
            }
        } else {
            const mes = competencia.substr(0, 2);
            const ano = competencia.substr(2, 4);

            if (Number(mes) < 1 || Number(mes) > 12 || Number(ano) < 2015 || Number(ano) > 2050) {
                this.erro = true;
                this.mensagem = 'Competência Inválida';
            }
            const dataCompetencia = ano + '-' + mes + '-01';
            let dataVencimento = new Date(this.formVencimento.formGroup.value.vencimento).getFullYear().toString();
            dataVencimento += '-' + this.obrigacaoService.mesStr(new Date(this.formVencimento.formGroup.value.vencimento).getMonth() + 1);
            dataVencimento += '-' + this.obrigacaoService.mesStr(new Date(this.formVencimento.formGroup.value.vencimento).getDate());

            if (dataVencimento < dataCompetencia) {
                this.erro = true;
                this.mensagem = 'Vencimento deve ser maior que competência';
            }
        }
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.loading = true;
        this.abstractService.listToTable(params, search).subscribe((response) => {

            this.vencimentos = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.extra = response?.extra || {};

            this.loading = false;
        });
    }

    addFieldAno(ultimoAno: number) {
        this.ultimoAno = this.vencimentosSugeridos[this.vencimentosSugeridos.length - 1];
        this.vencimentosSugeridos.push(this.ultimoAno + 1);
        this.ultimoAno += 1;
        this.cdr.detectChanges();
    }
}

<nz-page-header class="site-page-header" nzTitle="Workflow" nzSubtitle="Categorias e Status"></nz-page-header>
<nz-content>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '', expand ? 'card-fullscreen' : '']"
             [nzLoading]="loading"
             [nzTitle]="'Categorias'"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
             [nzExtra]="navBar">
        <ng-template #navBar>
            <div class="d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalAdicionar(true);">
                    Adicionar
                </button>
                <!--                <nz-badge [nzCount]="qtdFiltrosAtivos"-->
                <!--                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">-->
                <!--                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">-->
                <!--                        <i nz-icon nzType="search"></i>-->
                <!--                        Filtros-->
                <!--                    </button>-->
                <!--                </nz-badge>-->
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="expandEvent()" style="padding: 4px 8px;">
                    <i class="fas" [ngClass]="!expand ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzScroll]="{x: 'auto'}"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="left" nzColumnKey="descricao" [nzSortFn]="true">Categoria</th>
                <th nzAlign="center" [nzShowSort]="false">Status</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Cadastrado Data</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzAlign="left">
                    <i style="margin-right: 5px; color: {{item.cor}};" [ngClass]="item.icone"></i>
                    <span>
                        {{ item.descricao }}
                    </span>
                </td>

                <td nzAlign="center">
                    <button [nz-tooltip]="'Ver lista de status'" (click)="modalStatus(true, item)" nzType="link"
                            nz-button>
                        {{ item.itens?.length }}
                    </button>
                </td>

                <td nzAlign="center">
                    {{ (item.created_at | date: 'dd/MM/yyyy HH:mm') || '-' }}
                </td>
                <td nzAlign="center">
                    <div>
                        <button (click)="modalAlterar(true, item)" nz-button nzType="link">Editar</button>
                        <button *ngIf="!item.excluir else desbloqueado" [disabled]="true"
                                [nz-tooltip]="'Existem status com dados na categoria'" nz-button nzType="link">Remover
                        </button>
                        <ng-template #desbloqueado>
                            <button [nzLoading]="loadingRemover[item.id]" (click)="removerCategoria(item.id)" nz-button
                                    nzType="link">Remover
                            </button>
                        </ng-template>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="modalFiltrarVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="500">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.value.status_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="status_id">
                            <nz-option *ngFor="let opt of statusOptions"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalAdicionarVisible"
          [nzTitle]="'Adicionar'" [nzClosable]="true"
          (nzOnCancel)="modalAdicionar(false)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formAdicionarCategoria">

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60">
                        <input nz-input formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>

                <div style="margin: 15px 0 20px 0; display: flex; align-items: center;">
                    <label style="margin: 0 15px 0 63px;">
                        <span style="color: rgba(255,77,79,0.69); font-size: medium; margin-right: 5px">*</span>Cor:</label>
                    <input style="width: 367px; border: 1px solid #d9d9d9; height: 35px" formControlName="cor"
                           type="color">
                </div>
                <nz-form-item style="display: none">
                    <nz-form-label nzRequired [nzSpan]="5">Icone</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="icone">
                    </nz-form-control>
                </nz-form-item>

                <div style="margin: 15px auto; width: 100%;  display: flex; align-items: flex-start;">
                    <label style="margin-left: 55px; margin-right: -29px">
                        <span style="color: rgba(255,77,79,0.69); font-size: medium; margin-right: 5px">*</span>Ícone:</label>
                    <app-icon-list-fa (selecionouIcone)="selecionouIcone($event)"></app-icon-list-fa>
                </div>

            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAdicionar(false);">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="adicionar()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalAlterarVisible"
          [nzTitle]="'Alterar'" [nzClosable]="true"
          (nzOnCancel)="modalAlterar(false)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formAlterarCategria">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60">
                        <input nz-input formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
                <div style="margin: 15px 0 20px 0; display: flex; align-items: center;">
                    <label style="margin: 0 15px 0 63px;">
                        <span style="color: rgba(255,77,79,0.69); font-size: medium; margin-right: 5px">*</span>Cor:</label>
                    <input style="width: 367px; border: 1px solid #d9d9d9; height: 35px" formControlName="cor"
                           type="color">
                </div>
                <nz-form-item style="display: none">
                    <nz-form-label nzRequired [nzSpan]="5">Icone</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="icone">
                    </nz-form-control>
                </nz-form-item>

                <div style="margin: 15px auto; width: 100%;  display: flex; align-items: flex-start;">
                    <label style="margin-left: 55px; margin-right: -29px">
                        <span style="color: rgba(255,77,79,0.69); font-size: medium; margin-right: 5px">*</span>Ícone:</label>
                    <app-icon-list-fa (selecionouIcone)="selecionouIcone($event)"></app-icon-list-fa>
                    <li title="Ícone selecionado" class="icone-selecionado" [ngClass]="iconeSelecionado"></li>
                </div>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterar(false);">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="alterar()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal (nzOnCancel)="modalStatus(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalStatusVisible"
          [nzWidth]="600"
          [nzTitle]="'Status da Categoria ' + categoriaSelecionada?.descricao"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="categoriaSelecionada?.itens"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="overflow: auto; height: auto; max-height: 450px">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="descricao">Descrição</th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let s of categoriaSelecionada?.itens">
                <td nzAlign="center">
                    {{ s?.descricao }}
                </td>
                <td nzAlign="center">
                    <div>
                        <button (click)="modalAlterarStatus(true, s)" nz-button
                                nzType="link">Alterar
                        </button>
                        <button *ngIf="!s?.excluir else desbloqueado" [disabled]="true"
                                [nz-tooltip]="'Status com dados atrelados'" nz-button nzType="link">Remover
                        </button>
                        <ng-template #desbloqueado>
                            <button [nzLoading]="loadingRemoverStatus[s.id]" (click)="removerStatus(s.id)" nz-button
                                    nzType="link">Remover
                            </button>
                        </ng-template>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="modalAddStatus(true)">Adicionar Status</button>
        <button nz-button nzType="link" (click)="modalStatus(false)">Fechar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalAddStatusVisible"
          [nzTitle]="'Adicionar Status a categoria ' + categoriaSelecionada?.descricao" [nzClosable]="true"
          (nzOnCancel)="modalAddStatus(false, true)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formAddStatusACategoria">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60">
                        <input nz-input formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Manifestação</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="manifestacaoTipo_id">
                            <nz-option *ngFor="let opt of manifestacoes"
                                       [nzLabel]="opt[1]"
                                       [nzValue]="opt[0]"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-row nzGutter="18">
                    <nz-col nzSpan="1"></nz-col>
                    <nz-col nzSpan="20">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSpan]="8">Status permitidos</nz-form-label>
                            <nz-transfer
                                    [nzTitles]="['', 'selecionados']"
                                    [nzItemUnit]="'Status'"
                                    [nzItemsUnit]="'Status'"
                                    [nzDataSource]="statusOptions"
                                    [nzListStyle]="{ 'width.px': 210, 'height.px': 300 }"
                                    [nzRender]="render"
                                    (nzChange)="changeTranferMudaPara($event, formAddStatusACategoria)"
                            >
                                <ng-template #render let-item>
                                    {{ item.label }}
                                </ng-template>
                            </nz-transfer>
                        </nz-form-item>
                    </nz-col>
                </nz-row>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAddStatus(false, true);">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="adicionarStatus()">Adicionar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalAlterarStatusVisible"
          [nzTitle]="'Alterar Status'" [nzClosable]="true"
          (nzOnCancel)="modalAlterarStatus(false, {}, true)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formAlterarStatus">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60">
                        <input nz-input formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Manifestação</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="manifestacaoTipo_id">
                            <nz-option *ngFor="let opt of manifestacoes"
                                       [nzLabel]="opt[1]"
                                       [nzValue]="opt[0]"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
        <nz-row nzGutter="18">
            <nz-col nzSpan="1"></nz-col>
            <nz-col nzSpan="20">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="8">Status permitidos</nz-form-label>
                    <nz-transfer
                            [nzTitles]="['', 'selecionados']"
                            [nzItemUnit]="'Status'"
                            [nzItemsUnit]="'Status'"
                            [nzDataSource]="statusOptions"
                            [nzListStyle]="{ 'width.px': 210, 'height.px': 300 }"
                            [nzRender]="render"
                            (nzChange)="changeTranferMudaPara($event, formAlterarStatus)"
                    >
                        <ng-template #render let-item>
                            {{ item.label }}
                        </ng-template>
                    </nz-transfer>
                </nz-form-item>
            </nz-col>
        </nz-row>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterarStatus(false, {}, true);">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="alterarStatus()">Confirmar</button>
    </div>
</nz-modal>

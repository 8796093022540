<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Regras Desconsiderações">
</nz-page-header>

<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Regras" [nzLoading]="loadingTable"
             [nzBodyStyle]="{'padding': loadingTable ? '15px' : '0px'}"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button [disabled]="loadingTable" nz-button nzType="primary" style="margin-right: 10px;"
                            (click)="showModalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [disabled]="loadingTable" nz-button nzType="primary" style="margin-right: 10px;"
                        (click)="showModalAdicinarAlterar(true);">
                    Adicionar
                </button>
                <button [disabled]="loadingTable" nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="regras"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTableAcessosEcac($event)"
                  [nzShowTotal]="rangeTemplate"
                  [nzScroll]="{ x: '1100px' }"
                  class="table-small"
        >
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="obrigacoes_qtde" [nzSortFn]="true">Obrigações</th>
                <th nzAlign="center" nzColumnKey="modelo" [nzSortFn]="true">Modelo</th>
                <th nzAlign="center" nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                <th nzAlign="center" nzColumnKey="tipoEmissao" [nzSortFn]="true">Tipo Emissão</th>
                <th nzAlign="center" nzColumnKey="cfops" [nzSortFn]="true">CFOP</th>
                <th nzAlign="center" nzColumnKey="ncms" [nzSortFn]="true">NCM</th>
                <th style="width: 125px" nzAlign="center" nzColumnKey="emitente" [nzSortFn]="true">Emitente</th>
                <th style="width: 125px" nzAlign="center" nzColumnKey="destinatario" [nzSortFn]="true">Destinatário
                </th>
                <th nzAlign="center" nzColumnKey="serie" [nzSortFn]="true">Série</th>
                <th nzAlign="center" nzColumnKey="vigenciaInicio" [nzSortFn]="true">Vigência Início</th>
                <th nzAlign="center" nzColumnKey="vigenciaFim" [nzSortFn]="true">Vigência Fim</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Data Cadastro</th>
                <th nzAlign="center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of regras">
                <td nzAlign="center">
                    <div *ngIf="!loadingDetalhe.includes(data.id) else loading">
                        <button nz-button nzType="link" *ngIf="data.obrigacoes_qtde else zero"
                                (click)="verDetalhes('obrigacoes', true, data)">{{ data.obrigacoes_qtde }}
                        </button>
                    </div>
                </td>
                <td nzAlign="center">{{ data?.modelo || '-' }}</td>
                <td nzAlign="center">{{ data?.tipo || '-' }}</td>
                <td nzAlign="center">{{ data?.tipoEmissao === 'propria' ? 'Própria' : data.tipoEmissao === 'terceira' ? 'Terceira' : '-' }}</td>
                <td nzAlign="center">
                    <button nz-button nzType="link" *ngIf="data.cfopArray?.length else zero"
                            (click)="verDetalhes('cfop', true,  data.cfopArray)">{{ data.cfopArray.length }}
                    </button>
                </td>
                <td nzAlign="center">
                    <button nz-button nzType="link" *ngIf="data.ncmArray?.length else zero"
                            (click)="verDetalhes('ncm', true,  data.ncmArray)">{{ data.ncmArray.length }}
                    </button>
                </td>
                <td nzAlign="center">
                    <button nz-button nzType="link" *ngIf="data.emitenteArray?.length else zero"
                            (click)="verDetalhes('emitente', true,  data.emitenteArray)">{{ data.emitenteArray.length }}
                    </button>
                </td>
                <td nzAlign="center">
                    <button nz-button nzType="link" *ngIf="data.destinatarioArray?.length else zero"
                            (click)="verDetalhes('destinatario', true,  data.destinatarioArray)">{{ data.destinatarioArray.length }}
                    </button>
                </td>
                <td nzAlign="center">
                    <button nz-button nzType="link" *ngIf="data.serieArray?.length else zero"
                            (click)="verDetalhes('serie', true,  data.serieArray)">{{ data.serieArray.length }}
                    </button>
                </td>
                <td nzAlign="center">{{ data?.vigenciaInicio ? (data.vigenciaInicio | date:'dd/MM/yyyy') : '-' }}</td>
                <td nzAlign="center">{{ data?.vigenciaFim ? (data.vigenciaFim | date:'dd/MM/yyyy') : '-' }}</td>
                <td nzAlign="center">{{ data?.created_at ? (data.created_at | date:'dd/MM/yyyy') : '-' }}</td>
                <td>
                    <div style="width: 95px">

                        <button [nz-tooltip]="'Alterar'" (click)="showModalAdicinarAlterar(true, data)"
                                nz-button *ngIf="!loadingModalEditar.includes(data.id) else loading"
                                nzType="link">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button *ngIf="!loadingRemover.includes(data.id) else loading" (click)="showConfirm(data.id)"
                                nz-button
                                nzType="link">
                            <i [nz-tooltip]="'Remover'" style="color: red" class="fa-solid fa-trash"></i>
                        </button>

                        <ng-template #loading>
                        <span style="padding: 0 17px; color: #1890ff;" nz-icon nzType="loading"
                              nzTheme="outline"></span>
                        </ng-template>

                    </div>
                </td>
            </tr>
            <ng-template #zero>
                <div style="color: #1890ff; cursor: default">
                    -
                </div>
            </ng-template>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="modalDetalhesVisible"
          [nzBodyStyle]="{'padding': '2px'}"
          [nzTitle]="tituloModalDetalhes" [nzClosable]="true"
          (nzOnCancel)="verDetalhes(null,false, null)"
          [nzWidth]="380">
    <div *nzModalContent>
        <nz-table #basicTable
                  nzSize="small"
                  [nzScroll]="{ x: 'auto' }"
                  [nzData]="modalDetalhesData"
                  nzShowPagination="false"
        >
            <tbody>
            <tr *ngFor="let data of modalDetalhesData">
                <td>
                    <div style="padding-left: 35px">
                        {{ ['Emitentes', 'Destinatários'].includes(tituloModalDetalhes) ? (data | cpfCnpj) : data || '-' }}
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="verDetalhes(null,false, null)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAdicionarVisible"
          [nzTitle]="regraSelecionada ? 'Alterar Regra' : 'Adicionar Regra'" [nzClosable]="true"
          (nzOnCancel)="showModalAdicinarAlterar(false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="true" [formGroup]="formRegras">

            <nz-row nzGutter="18">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="20">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="4">Obrigações:</nz-form-label>
                        <nz-transfer
                                [nzTitles]="['', 'selecionadas']"
                                [nzItemUnit]="'Obrigações'"
                                [nzItemsUnit]="'Obrigações'"
                                [nzDataSource]="comboObrigacoes"
                                [nzListStyle]="{ 'width.px': 218, 'height.px': 300 }"
                                [nzRender]="render"
                                (nzChange)="changeTranferObrigacoes($event)"
                        >
                            <ng-template #render let-item>
                                {{ item.label }}
                            </ng-template>
                        </nz-transfer>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="4">Modelo:</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <nz-select nzPlaceHolder="Selecione o modelo" formControlName="modelo">
                        <nz-option [nzValue]="'NFE (55)'" [nzLabel]="'NFE (55)'"></nz-option>
                        <nz-option [nzValue]="'CTE (57)'" [nzLabel]="'CTE (57)'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-divider></nz-divider>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="4">Tipo:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzPlaceHolder="Selecione o tipo" formControlName="tipo">
                                <nz-option [nzValue]="'Entrada'" [nzLabel]="'Entrada'"></nz-option>
                                <nz-option [nzValue]="'Saida'" [nzLabel]="'Saída'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="4">Tipo emissão:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzPlaceHolder="Selecione o tipo" formControlName="tipoEmissao">
                                <nz-option [nzValue]="'terceira'" [nzLabel]="'Terceira'"></nz-option>
                                <nz-option [nzValue]="'propria'" [nzLabel]="'Própria'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <div nz-row>

                <nz-form-label nzRequired nz-col [nzSpan]="4">Vigência Início:</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="vigenciaInicio" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-label nz-col [nzSpan]="4">Vigência Fim:</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="vigenciaFim" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

            </div>


            <nz-row nzGutter="24">
                <nz-col nzSpan="24">

                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">CFOP:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe a CFOP e aperte ENTER"
                                       formControlName="cfops">
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">

                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">NCM:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe o NCM e aperte ENTER"
                                       formControlName="ncms">
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Série:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select (keydown)="Helpers.keyPressNumber($event)" nzMode="tags"
                                       nzPlaceHolder="Informe a série e aperte ENTER"
                                       formControlName="serie">
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">
                            <i [nz-tooltip]="'Tipo de documento para adicionar Emitente e Destinatário'"
                               class="fas fa-question-circle"
                               style="font-size: 1em; color: #1e9ff2; margin-right: 2px; margin-top: -2px"></i>
                            Documento:
                        </nz-form-label>
                        <nz-form-control [nzSpan]="9">
                            <nz-radio-group formControlName="tipoDoc" style="margin-left: 5px">
                                <label (click)="changeMask('000.000.000-00')" nz-radio nzValue="cpf">CPF</label>
                                <label (click)="changeMask('00.000.000/0000-00')" nz-radio nzValue="cnpj">CNPJ</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Emitente:</nz-form-label>
                        <nz-form-control [nzSpan]="9">
                            <input #emitente (keydown.enter)="addEmitente()" nz-input
                                   mask="{{maskDoc}}" formControlName="inputE"
                                   placeholder="Informe o Emitente e aperte ENTER">
                            <nz-select nzMode="tags" style="display: none"
                                       formControlName="emitente">
                            </nz-select>
                        </nz-form-control>
                        <nz-form-control [nzSpan]="13">
                            <div style="margin: -10px 0 0 50px">
                                <nz-list nzHeader="Lista de emitentes" class="lista-ed" style="width: 165px">
                                    <nz-list-item *ngFor="let e of formRegras.value.emitente" style="width: 170px">
                                        <nz-list-item-meta-title style="width: 170px">
                                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                                <span>{{ e }}</span>
                                                <span (click)="removerDaLista('emitente', e)" class="bt-remove-item"
                                                      nz-icon nzType="close" nzTheme="outline"></span>
                                            </div>
                                        </nz-list-item-meta-title>
                                    </nz-list-item>
                                </nz-list>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Destinatário:</nz-form-label>
                        <nz-form-control [nzSpan]="9">
                            <input #destinatario (keydown.enter)="addDestinatario()"
                                   mask="{{maskDoc}}"
                                   nz-input formControlName="inputD"
                                   placeholder="Informe o Destinatário e aperte ENTER">
                            <nz-select nzMode="tags" style="display: none"
                                       formControlName="destinatario">
                            </nz-select>
                        </nz-form-control>
                        <nz-form-control [nzSpan]="10">
                            <div style="margin: -10px 0 0 50px">
                                <nz-list nzHeader="Lista de destinatários" class="lista-ed" style="width: 165px">
                                    <nz-list-item *ngFor="let d of formRegras.value.destinatario" style="width: 170px">
                                        <nz-list-item-meta-title style="width: 170px">
                                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                                <span>{{ d }}</span>
                                                <span (click)="removerDaLista('destinatario', d)" class="bt-remove-item"
                                                      nz-icon nzType="close" nzTheme="outline"></span>
                                            </div>
                                        </nz-list-item-meta-title>
                                    </nz-list-item>
                                </nz-list>
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalAdicinarAlterar(false)">Cancelar</button>
        <button (click)="setRegra()" [nzLoading]="loadingAdicionar" nz-button nzType="primary">
            {{ regraSelecionada ? 'Alterar' : 'Adicionar' }}
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalFiltrarVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="showModalFiltrar(false)"
          [nzWidth]="600">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar">

            <nz-row nzGutter="24">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Obrigação:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <nz-select formControlName="obrigacao">
                                <nz-option *ngFor="let opt of comboObrigacoes"
                                           [nzValue]="opt.value" [nzLabel]="opt.label"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Modelo:</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="modelo">
                        <nz-option [nzValue]="'NFE (55)'" [nzLabel]="'NFE (55)'"></nz-option>
                        <nz-option [nzValue]="'CTE (57)'" [nzLabel]="'CTE (57)'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Tipo:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <nz-select formControlName="tipo">
                                <nz-option [nzValue]="'Entrada'" [nzLabel]="'Entrada'"></nz-option>
                                <nz-option [nzValue]="'Saida'" [nzLabel]="'Saída'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Emitente:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <input nz-input
                                   formControlName="emitente">
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Destinatário:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <input nz-input
                                   formControlName="destinatario">
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">CFOP:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <input nz-input formControlName="cfop">
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">NCM:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <input nz-input formControlName="ncm">
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Série:</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <input type="text" (keydown)="Helpers.keyPressNumber($event)" nz-input
                                   formControlName="serie">
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalFiltrar(false)">Cancelar</button>
        <button (click)="confirmaFiltrar()" [nzLoading]="false" nz-button nzType="primary">Filtrar</button>
    </div>
</nz-modal>

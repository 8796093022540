<nz-page-header  nzTitle="DEC/CND" nzSubtitle="Dashboard">
    <nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45rem;"
                    (click)="atualizarTela();"
            >
                <i class="fas fa-redo" style="margin-right: 5px;"></i>
                Atualizar
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>

<nz-content>
    <nz-row [nzGutter]="24" >
        <nz-col [nzSpan]="12">
            <nz-card [nzTitle]="titulo1"
                     class="card-l"
                     [nzLoading]="cardCabecalhoLoading">
                <h3 style="margin-top: -10px">Status</h3>
                <div nz-row>
                    <div style="display: flex; justify-content: center; align-items: center" [nzSpan]="12" nz-col>
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                [(update)]="updateFlag"
                                *ngIf="HighchartsPie && chartPieOptions"
                                style="width: 100%; height: 230px; display: inline-block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <ul nz-list nzBordered style="margin: 10px; cursor: pointer;">
                            <nz-list-item (click)="openControleCertidoes(1)" class="ant-card-hoverable d-flex" style="padding: 15px;">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[1]}"></i> Negativa
                                </h5>
                                <a style="margin-bottom: 0;">{{tableStatus.status_1}}</a>
                            </nz-list-item>
                            <nz-list-item (click)="openControleCertidoes(2)" class="ant-card-hoverable d-flex" style="padding: 15px;">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[2]}"></i> Positiva com
                                    Efeito de Negativa
                                </h5>
                                <a style="margin-bottom: 0;">{{tableStatus.status_2}}</a>
                            </nz-list-item>
                            <nz-list-item (click)="openControleCertidoes(3)" class="ant-card-hoverable d-flex" style="padding: 15px;">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[3]}"></i> Positiva
                                </h5>
                                <a style="margin-bottom: 0;">{{tableStatus.status_3}}</a>
                            </nz-list-item>
                            <nz-list-item (click)="openControleCertidoes(0)" class="ant-card-hoverable d-flex" style="padding: 15px;">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[0]}"></i> Nunca Emitida
                                </h5>
                                <a style="margin-bottom: 0;">{{tableStatus.status_0}}</a>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
                <h3 style="margin: 25px 0">Validade das últimas CNDs</h3>

                <nz-divider></nz-divider>
                <div >
                    <highcharts-chart
                            [Highcharts]="HighchartsBasic"
                            [options]="chartBasicOptions"
                            [(update)]="updateFlag"
                            *ngIf="HighchartsBasic && chartBasicOptions"
                            class="barras"
                            (resize)="highChart?.reflow()"
                    ></highcharts-chart>
                    <div class="info-totais">
                        <span>
                            Total: {{totaltEmissoesAutomaticas}}
                        </span>
                        <span>
                            Última Consulta {{tableEmissoesAutomaticas.ultima_consulta? (tableEmissoesAutomaticas.ultima_consulta| date: 'dd/MM/yyyy hh:mm') : 'N/D'}}
                        </span>
                    </div>
                </div>
            </nz-card>
        </nz-col>

        <nz-col [nzSpan]="12">
            <nz-card class="card-r" [nzLoading]="cardCabecalhoLoading"  [nzTitle]="titulo2" >
                <nz-content class="ant-card-hoverable status" style="margin-top: -10px">
                    <div  nz-row (click)="openEcac('1')">
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-check"
                               style="font-size: 55px; margin-right: 15px; color: rgb(40, 208, 148);"></i>
                            <div style="width: 86.40px;">
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Regular
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: rgb(40, 208, 148);">
                                    {{objectCountersValues.qtdRegulares | number}}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcRegulares | number: '1.0-0' "
                                         nzType="circle"
                                         [nzWidth]="55"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'rgb(40, 208, 148)'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
                <nz-content class="ant-card-hoverable status">
                    <div nz-row  (click)="openEcac('3')">
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-xmark"
                               style="font-size: 55px; margin-right: 15px; color: #ff4961 !important;"></i>
                            <div style="width: 86.40px;">
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Irregular
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #ff4961 !important;">
                                    {{objectCountersValues.qtdIrregulares | number}}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcIrregulares | number: '1.0-0' "
                                         nzType="circle"
                                         [nzWidth]="55"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#ff4961'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
                <nz-content class="ant-card-hoverable status">
                    <div nz-row (click)="openEcac('2')">
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center justify-content-center">
                            <i class="fa-solid fa-circle-exclamation"
                               style="font-size: 55px; margin-right: 15px; color: #ff9149 !important;"></i>
                            <div>
                                <h4 class="custom-card-title text-muted"
                                    style="margin: 0; line-height: 1; white-space: nowrap;">
                                    Ex. Suspensa
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #ff9149 !important;">
                                    {{objectCountersValues.qtdSuspensa | number}}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcSuspensas | number: '1.0-0'"
                                         nzType="circle"
                                         [nzWidth]="55"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#ff9149'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
                <div class="info-totais">
                        <span>
                            Total: {{objectCountersValues.total}}
                        </span>
                    <span>
                            Última Consulta {{objectCountersValues.ultima_consulta? (objectCountersValues.ultima_consulta | date: 'dd/MM/yyyy hh:mm') : 'N/D'}}
                        </span>
                </div>
            </nz-card>

            <nz-card class="card-r" style="margin-top: -10px" [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                     [nzTitle]="titulo3" [nzLoading]="cardCabecalhoLoading">
                <nz-content >
                    <div class="flex">
                        <div (click)="openDec()" class="bloco ant-card-hoverable" style="border-right: 1px solid #dedede;">
                            <span class="ico">
                                 <i class="fa-solid fa-building"></i>
                            </span>
                            <span>Unidades</span>
                            <span>{{domiciliosEletronicos.qtdUnidades}}</span>
                        </div>
                        <div (click)="openDec()" class="bloco ant-card-hoverable">
                            <span class="ico">
                                 <i class="fa-solid fa-building-flag"></i>
                            </span>
                            <span>Domicílios</span>
                            <span>{{domiciliosEletronicos.qtdDomicilios}}</span>
                        </div>
                        <div (click)="openDec({'tabNumber': 1})" class="bloco ant-card-hoverable" style="border-right: 1px solid #dedede;">
                            <span class="ico">
                                 <i class="fa-regular fa-envelope"></i>
                            </span>
                            <span>Mensagens</span>
                            <span>{{domiciliosEletronicos.qtdMensagens}}</span>
                        </div>
                        <div (click)="openDec({'tabNumber': 1, 'nLidas': 'nLidas'})" class="bloco ant-card-hoverable">
                            <span class="ico">
                                 <i class="fa-solid fa-inbox"></i>
                            </span>
                            <span>Não Lidos</span>
                            <span>{{domiciliosEletronicos.qtdNaoLidos}}</span>
                        </div>
                    </div>
                    <div class="info-totais">
                        <span>
                            Última Consulta {{domiciliosEletronicos.ultima_consulta? (domiciliosEletronicos.ultima_consulta  | date: 'dd/MM/yyyy hh:mm') : 'N/D'}}
                        </span>
                    </div>
                </nz-content>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

<ng-template #titulo1>
    <div class="titulo">
        <h2>Certidões</h2>
    </div>
</ng-template>
<ng-template #titulo2>
    <div class="titulo">
        <h2>Situação Fiscal - RFB</h2>
    </div>
</ng-template>
<ng-template #titulo3>
    <div class="titulo">
        <h2>Domicílios Eletrônicos</h2>
    </div>
</ng-template>
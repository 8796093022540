import {ActionTypes} from '@actions/actions-type';
import ActionTabModel from '@models/action-tab.model';
import {Tab} from '@models/tab.model';

const mastertaxStorage = getStorage();
export const tabsState = mastertaxStorage && mastertaxStorage.tabs ? mastertaxStorage.tabs : [];

export function TabReducer(state = tabsState, action: ActionTabModel) {
    switch (action.type) {
        case ActionTypes.Add:
            addTab(state, action.payload);
            setStorageTab(state);
            return state;

        case ActionTypes.Remove:
            removeTab(state, action.index);
            setStorageTab(state);
            return state;

        case ActionTypes.Clear:
            state = [];
            setStorageTab(state);
            return state;

        case ActionTypes.ClearOthers:
            const tabs = removeOthersTab(state, action.index);
            tabs[0].id = 0; // Sempre vai ter apenas uma tab
            setStorageTab(tabs);
            return tabs;

        case ActionTypes.Get:
            return state;

        case ActionTypes.Reload:
            reload(state, action.payload);
            setStorageTab(state);
            return state;

        case ActionTypes.Reorder:
            reorder(action.payload);
            return action.payload;

        default:
            return state;
    }
}

export function reload(tabs: any, tab: Tab) {
    tabs[tab.id] = tab;
}

export function reorder(tabsReordened: any) {
    setStorageTab(tabsReordened);
}

export function removeTab(tabs: Tab[], index: number) {
    tabs.splice(index, 1);
    if (tabs.length > 0) {
        for (const tab of tabs) {
            tab.active = false;
        }
        tabs[tabs.length - 1].active = true;
    }
}

export function removeOthersTab(tabs: Tab[], index: number) {
    tabs = [tabs[index]];
    if (tabs.length > 0) {
        for (const tab of tabs) {
            tab.active = false;
        }
        tabs[tabs.length - 1].active = true;
    }

    return tabs;
}

export function addTab(tabs: Tab[], newTab: Tab) {
    for (const tab of tabs) {
        if (tab.active) {
            tab.active = false;
        }
    }
    newTab.active = true;
    tabs.push(newTab);
}

export function setStorageTab(tabs) {
    const mastertax = JSON.parse(localStorage.getItem('app_mastertax'));
    if (mastertax) {
        mastertax.tabs = [];

        tabs.forEach(tab => {
            if (tab.title !== 'Embedded') {
                mastertax.tabs.push(tab);
            }
        });

        localStorage.setItem('app_mastertax', JSON.stringify(mastertax));
    }
}

export function getStorage() {
    return JSON.parse(localStorage.getItem('app_mastertax'));
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailProfileComponent } from './pages/detail/detail-profile.component';
import { ListProfileComponent } from './pages/list/list-profile.component';
import { RegisterProfileComponent } from './pages/register/register-profile.component';
import { NgZorroModule } from '../../shared/ng-zorro.module';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './pages/profile.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { UiModule } from '../ui.module';


@NgModule({
    declarations: [
        ListProfileComponent,
        DetailProfileComponent,
        RegisterProfileComponent,
        ProfileComponent
    ],
    imports: [
        CommonModule,
        ProfileRoutingModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        NzTreeModule,
        UiModule
    ]
})
export class ProfileModule {
}

import BulkChanges from '@models/entity.model';
import {Utils} from '../utils';
import {CrudModel} from '@models/crud.model';

export class Empresa extends CrudModel implements BulkChanges {
    id: string;
    // tslint:disable-next-line:variable-name
    conta_id: string;
    // tslint:disable-next-line:variable-name
    grupoEmpresarial_id: string;
    // tslint:disable-next-line:variable-name
    grupoEmpresarial_descricao: string;
    // tslint:disable-next-line:variable-name
    visibilidade_id: string;
    // tslint:disable-next-line:variable-name
    visibilidade_descricao: string;
    // tslint:disable-next-line:variable-name
    regimeTributario_id: string;
    nome: string;
    codigo: string;
    cnpj: string;
    ie: string;
    im: string;
    razao: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
    cep: string;
    // tslint:disable-next-line:variable-name
    updated_at: string;
    // tslint:disable-next-line:variable-name
    created_at: string;
    visibilidades: [];
    ativo: number;
    atividade: string;
    // tslint:disable-next-line:variable-name
    criado_por: string;
    // tslint:disable-next-line:variable-name
    atualizado_por: string;

    constructor(obj?: any) {

        super(CrudModel);

        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

        }

    }

    convertToSave() {

        const properties = [];
        const empresa = new Empresa();

        for (const prop of properties) {

            empresa[prop] = this[prop];

        }

        if (Utils.isEmpty(empresa.id)) {

            delete empresa.id;

        }

        return empresa;

    }

    toBulk(ids: string | string[], itens: string[]) {

        return {
            ids,
            itens,
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            empresas: checkedItems,
            visibilidades: propertyChanged
        };
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {formatDate} from '@angular/common';
import {UsuarioLogado} from '@models/usuarioLogado.model';

@Injectable({providedIn: 'root'})

export class MessagesListService extends AbstractService<UsuarioLogado> {

    constructor(http: HttpClient) {
        super(http, '/usuario-logado', {});
    }

    getAvisos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/messages-list`);

    }


}

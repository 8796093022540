import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {VisibilidadeDetalheService} from './visibilidade-detalhe.service';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Helpers} from '../../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-visibilidade-detalhe',
    templateUrl: './visibilidade-detalhe.component.html',
    styleUrls: ['./visibilidade-detalhe.component.scss']
})

export class VisibilidadeDetalheComponent extends AbstractListTable<any> implements OnInit {


    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @ViewChildren('treePerfil') treePerfil;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    visibilidade: any;
    currentUser: Subscription;

    formDadosCadastrais: FormStack;

    carregando = false;
    gravando = false;

    tabNumber = 0;

    qtdFiltrosAtivos = 0;

    usuarios: any;
    empresas: any;


    pagination: any = {
        usuarios: Pagination,
        empresas: Pagination
    };

    loadings = {
        carregandoUsuarios: false,
        carregandoEmpresas: false,
        salvandoUsuarios: false,
        salvandoEmpresas: false
    };

    modalListaUsuariosVisible = false;
    modalListaEmpresasVisible = false;

    arrayTodosUsuarios: any[] = [];
    arrayTodasEmpresas: any[] = [];

    checkedItemsUsuarios = new Set<string>();
    checkedItemsEmpresas = new Set<string>();

    arrayUsuariosFiltradosFront: any[] = [];
    arrayEmpresasFiltradasFront: any[] = [];
    buscaInterna: any = null;

    constructor(
        private dataService: DataService,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private modalService: NzModalService,
        private visibilidadeDetalheService: VisibilidadeDetalheService,
        public toastrService: ToastrService,
        private tabService: TabService
    ) {
        super(usuarioService, Usuario, toastService);

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
            })
        };

    }

    ngOnInit(): void {

        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.carregar(this.data.id);
        // this.getPermissoesTree();

    }

    async carregar(id, quiet = false) {

        if (!quiet) {
            this.carregando = true;
        }
        this.visibilidadeDetalheService.retornaVisibilidade(id).subscribe((response) => {
            this.visibilidade = response;
            this.carregando = false;
        });

    }

    editar(formulario: FormStack): void {

        for (const name in formulario.formGroup.controls) {

            if (name) {

                formulario.formGroup.get(name).setValue(this.visibilidade[name]);

            }
        }

        formulario.modalVisible = true;

    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;

    }

    confirmar(formulario: FormStack) {

        this.gravando = true;

        if (formulario.formGroup.valid) {

            this.gravar(this.data.id, formulario.formGroup.value);

        } else {

            this.gravando = false;

        }

    }

    gravar(id, dados) {

        this.visibilidadeDetalheService.atualizar(id, dados).subscribe(
            () => {
                this.fechar(this.formDadosCadastrais);
                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
                this.carregar(this.data.id, true);
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );

    }

    changeTabs(event: any = null) {
        this.buscaInterna = null;
        this.arrayEmpresasFiltradasFront = this.arrayTodasEmpresas;
        this.arrayUsuariosFiltradosFront = this.arrayTodosUsuarios;
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTableUsuarios(): void {

        this.loading = true;

        const url = this.visibilidadeDetalheService.baseUrl + '/detalhe/' + this.data.id + '/usuarios';

        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.usuarios = [];
            this.usuarios = response?.data || response;

            this.pagination.usuarios = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        });

    }

    queryTableEmpresas(): void {

        this.loading = true;

        const url = this.visibilidadeDetalheService.baseUrl + '/detalhe/' + this.data.id + '/empresas';

        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.empresas = [];
            this.empresas = response?.data || response;

            this.pagination.empresas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        });

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;
        this.currentSearch = search;

        switch (this.tabNumber) {
            case 0: {
                this.queryTableUsuarios();
                break;
            }
            case 1: {
                this.queryTableEmpresas();
                break;
            }
        }


    }

    filtrarFront() {
        if (!this.loadings.carregandoEmpresas) {
            this.arrayEmpresasFiltradasFront = [];

            if (this.buscaInterna) {
                const busca = this.buscaInterna.toLowerCase();
                this.arrayEmpresasFiltradasFront = this.arrayTodasEmpresas.filter(e => {
                    if (e.nome.toLowerCase().includes(busca) ||
                        e.cnpj.includes(busca) ||
                        (Helpers.maskCnpjCpf(e.cnpj).includes(busca))) {
                        return e;
                    }
                });
            } else {
                this.arrayEmpresasFiltradasFront = this.arrayTodasEmpresas;
            }

        }

        if (!this.loadings.carregandoUsuarios) {
            this.arrayUsuariosFiltradosFront = [];

            if (this.buscaInterna) {
                const busca = this.buscaInterna.toLowerCase();
                this.arrayUsuariosFiltradosFront = this.arrayTodosUsuarios.filter(u => {
                    if (u.nome.toLowerCase().includes(busca)
                        || u.email.toLowerCase().includes(busca)
                        || u.login.toLowerCase().includes(busca)) {
                        return u;
                    }
                });
            } else {
                this.arrayUsuariosFiltradosFront = this.arrayTodosUsuarios;
            }
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }


    btnResetSearch() {

        if (this.modalListaUsuariosVisible) {
            this.buscaInterna = null;
            this.arrayUsuariosFiltradosFront = this.arrayTodosUsuarios;

        } else if (this.modalListaEmpresasVisible) {
            this.buscaInterna = null;
            this.arrayEmpresasFiltradasFront = this.arrayTodasEmpresas;

        } else {
            this.currentSearch = null;
            this.currentParams = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                sort: [],
                filter: [],
            };

            this.queryTable(this.currentParams, this.currentSearch);

        }

    }

    onVincular(): void {

        switch (this.tabNumber) {

            case 0: {

                this.loadings.carregandoUsuarios = true;
                this.modalListaUsuariosVisible = true;
                this.arrayTodosUsuarios = [];
                this.checkedItemsUsuarios.clear();
                this.visibilidadeDetalheService.retornaTodosUsuarios(this.data.id).subscribe((res) => {

                    if (res.vinculados) {
                        res.vinculados.forEach((value) => {
                            this.onItemCheckedUsuarios(value.id, true);
                        });
                    }

                    this.loadings.carregandoUsuarios = false;
                    this.arrayTodosUsuarios = res.todos;
                    this.arrayUsuariosFiltradosFront = this.arrayTodosUsuarios;

                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.carregandoUsuarios = false;
                });
                break;
            }
            case 1: {

                this.loadings.carregandoEmpresas = true;
                this.modalListaEmpresasVisible = true;
                this.arrayTodasEmpresas = [];
                this.checkedItemsEmpresas.clear();
                this.visibilidadeDetalheService.retornaTodasEmpresas(this.data.id).subscribe((res) => {

                    if (res.vinculadas) {
                        res.vinculadas.forEach((value) => {
                            this.onItemCheckedEmpresas(value.id, true);
                        });
                    }

                    this.loadings.carregandoEmpresas = false;
                    this.arrayTodasEmpresas = res.todas;
                    this.arrayEmpresasFiltradasFront = this.arrayTodasEmpresas;

                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.carregandoEmpresas = false;
                });
                break;
            }

        }
    }

    modalListarUsuarios(visible: boolean): void {
        this.buscaInterna = null;
        this.modalListaUsuariosVisible = visible;
    }

    modalListarEmpresas(visible: boolean): void {
        this.buscaInterna = null;
        this.modalListaEmpresasVisible = visible;
    }

    onAllCheckedUsuarios(checked: boolean): void {

        this.arrayTodosUsuarios.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSetUsuarios(id, checked));

    }

    onAllCheckedEmpresas(checked: boolean): void {

        this.arrayTodasEmpresas.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSetEmpresas(id, checked));

    }

    onItemCheckedUsuarios(id: string, checked: boolean): void {

        this.updateCheckedSetUsuarios(id, checked);

    }

    onItemCheckedEmpresas(id: string, checked: boolean): void {

        this.updateCheckedSetEmpresas(id, checked);

    }

    updateCheckedSetUsuarios(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsUsuarios.add(id);

        } else {

            this.checkedItemsUsuarios.delete(id);

        }
    }

    updateCheckedSetEmpresas(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsEmpresas.add(id);

        } else {

            this.checkedItemsEmpresas.delete(id);

        }
    }

    onSalvarUsuarios(): void {

        this.loadings.salvandoUsuarios = true;

        const usuarios = Array.from(this.checkedItemsUsuarios);

        this.visibilidadeDetalheService.vincularUsuarios(this.data.id, usuarios).subscribe((res) => {
            this.loadings.salvandoUsuarios = false;
            this.toastService.success('Usuário(s) vinculado(s) com sucesso!');
            this.modalListarUsuarios(false);
            this.queryTableUsuarios();
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.salvandoUsuarios = false;
        });


    }

    onSalvarEmpresas(): void {

        this.loadings.salvandoEmpresas = true;

        const empresas = Array.from(this.checkedItemsEmpresas);


        this.visibilidadeDetalheService.vincularEmpresas(this.data.id, empresas).subscribe((res) => {
            this.loadings.salvandoEmpresas = false;
            this.toastService.success('Empresas(s) vinculada(s) com sucesso!');
            this.modalListarEmpresas(false);
            this.queryTableEmpresas();
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.salvandoEmpresas = false;
        });


    }

    showConfirmRemoverUsuario(usuarioId: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Usuário?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerUsuario(usuarioId)
        });

    }

    showConfirmRemoverEmpresa(empresaId: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a Empresa?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerEmpresa(empresaId)
        });

    }


    removerUsuario(usuarioId: string): void {

        this.loading = true;
        this.visibilidadeDetalheService.removerUsuario(this.data.id, usuarioId).subscribe(
            (response) => {
                this.toastrService.success('Usuário removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loading = false;

            }
        );
    }

    removerEmpresa(empresaId: string): void {

        this.loading = true;
        this.visibilidadeDetalheService.removerEmpresa(this.data.id, empresaId).subscribe(
            (response) => {
                this.toastrService.success('Empresa removida com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loading = false;

            }
        );
    }


}


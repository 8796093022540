import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Empresa} from '@models/empresa.model';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {LogsAlteracoesService} from './logsAlteracoes.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-logsAlteracoes',
    templateUrl: './logsAlteracoes.component.html',
    styleUrls: ['./logsAlteracoes.component.scss']
})
export class LogsAlteracoesComponent extends AbstractListTable<Empresa> implements OnInit {

    status = false;
    qtdFiltrosAtivos = 0;

    formFiltrar: FormStack;

    items: any;
    pagination: Pagination;
    loading = true;
    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };
    currentSearch: string;
    pageSize = 50;
    pageIndex = 1;

    types = {
        created: {
            value: 'Criado',
            alert: 'success'
        },
        deleted: {
            value: 'Deletado',
            alert: 'error'
        },
        updated: {
            value: 'Atualizado',
            alert: 'info'
        }
    };


    // Necessário incrementar manualmente ao adicionar nova tabela de log
    tabelas = [
        {key: 'certificado'},
        {key: 'controleCertidaoEmpresa'},
        {key: 'credencialPortal'},
        {key: 'domicilioEletronicoEmpresa'},
        {key: 'empresa'},
        {key: 'empresaInscricaoEstadual'},
        {key: 'empresaInscricaoFederal'},
        {key: 'empresaInscricaoImobiliaria'},
        {key: 'empresaInscricaoMunicipal'},
        {key: 'empresaRegimeTributario'},
        {key: 'perfil'},
        {key: 'procuracao'},
        {key: 'procurador'},
        {key: 'responsavel'},
        {key: 'usuario'}
    ];

    comboUsuarios = [];
    comboEmpresa = [];

    constructor(
        private fb: UntypedFormBuilder,
        private service: LogsAlteracoesService,
        private toastService: ToastrService,
        private tabService: TabService) {
        super(service, Empresa, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                user_id: [null, null],
                type: [null, null],
                table: [null, null],
            })
        };
    }

    ngOnInit() {
        this.getComboUsuarios();
        this.getComboEmpresas();
        this.queryTable(this.currentParams, this.currentSearch);
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.loading = true;

        this.calculaBadgeFiltros();

        this.abstractService.listToTable(this.currentParams, search).subscribe((response) => {

            this.items = response?.data || response;

            this.items.forEach(i => {
                i.data = JSON.parse(i.data);
                i.after = JSON.stringify(i.type === 'created' ? i.data : i.data.after, null, 2);
                i.before = JSON.stringify(i.type === 'deleted' ? i.data : i.data.before, null, 2);
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        }, error => {
            this.toastService.error(error.error.message);
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    btnResetSearch() {
        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clickEvent() {
        this.status = !this.status;
    }

    openTab(componentName: string, queryParams?: string, data?: any, close: boolean = true) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const cnpj = data.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        const newTab = new Tab(component.name, cnpj + ' - Detalhe da Empresa', url, component.urlType, data);
        if (close) {
            this.tabService.closeAndAddTab(newTab);
        } else {
            this.tabService.addTab(newTab);
        }

    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    getComboUsuarios(): void {

        this.service.getComboUsuarios().subscribe((res) => {
            this.comboUsuarios = res;
        }, (res) => {
            this.toastService.error('Erro ao obter lista de usuários');
        });
    }

    getComboEmpresas() {
        this.service.retornaComboEmpresas().subscribe((retorno: any) => {
            this.comboEmpresa = retorno.empresaCnpj;
        });

    }

}

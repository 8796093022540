import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})

export class EditarInscricoesRegimesService extends AbstractService<InscricaoRegime> {

    baseUrlMatrizes = environment.apiUrl + '/cadastros/empresas/matrizes';
    baseUrlRegimes = environment.apiUrl + '/cadastros/empresas/regimesTributarios';
    baseUrlIfs = environment.apiUrl + '/cadastros/empresas/inscricoesFederais';
    baseUrlIes = environment.apiUrl + '/cadastros/empresas/inscricoesEstaduais';
    baseUrlIms = environment.apiUrl + '/cadastros/empresas/inscricoesMunicipais';
    baseUrlImobiliarias = environment.apiUrl + '/cadastros/empresas/inscricao-imobiliarias';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaDadosCadastrais(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/empresa/detalhe/${empresaId}`);

    }

    retornaReceitaFederal(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/detalhe/${empresaId}`);

    }

    sincronizarEmbedded(acao: string, id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/inscricoesRegimes/detalhe/${id}/sincronizarEmbedded/${acao}`);

    }


    updateReceita(empresaID: string, inscricaoFederalID: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/detalhe/${empresaID}/${inscricaoFederalID}`, dados);

    }

    insertMatriz(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }
        dados.cnpj = dados.cnpj.replace('.', '').replace('/', '').replace(',', '');

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/matriz`, dados);

    }

    updateMatriz(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }
        dados.cnpj = dados.cnpj.replace('.', '').replace('/', '').replace(',', '').replace('.', '').replace('-', '');

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/matriz/detalhe/${id}`, dados);

    }

    removeMatriz(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/matriz/${id}`);

    }

    insertRegime(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/regimeTributario`, dados);

    }

    updateRegime(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/regimeTributario/detalhe/${id}`, dados);

    }

    removeRegime(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/regimeTributario/${id}`);

    }


    insertIf(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal`, dados);

    }

    updateIf(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/detalhe/${id}`, dados);

    }

    removeIf(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/${id}`);

    }


    insertIe(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual`, dados);

    }

    updateIe(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual/detalhe/${id}`, dados);

    }

    removeIe(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual/${id}`);

    }

    insertIm(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal`, dados);

    }

    insertImobiliaria(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricao-imobiliaria`, dados);

    }

    updateIm(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal/detalhe/${id}`, dados);

    }

    updateImobiliaria(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricao-imobiliaria/detalhe/${id}`, dados);

    }

    removeIm(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal/${id}`);

    }

    removeImobiliaria(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricao-imobiliaria/${id}`);

    }

    retornaCodigosReceitas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/regimes-tributarios/select`);

    }

    retornaComboUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    atualizaViaRFB(id: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/empresa/consulta/rfbApi/${id}`, {});
    }

    retornaComboMunicipios(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);

    }

    atualziarRFB(cnpj: string): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/empresa/consulta/rfb`, {cnpj});
    }

}

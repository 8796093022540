<nz-page-header class="site-page-header" nzTitle="Connect" nzSubtitle="Download"></nz-page-header>
<nz-card [nzTitle]="'Agent'">
    <nz-content>
        <nz-row style="text-align: center;" class="justify-content-center">
            <nz-col nzSpan="8">
                <h5 nz-typography>Download para Windows (x64)</h5>
                <div style="margin-bottom: 20px;">
                    <i class="fab fa-windows" style="font-size: 40px;"></i>
                </div>
                <div>
                    <button nz-button nzType="primary" style="margin-bottom: 15px;"
                            (click)="downloadAgent('windows')">
                        <i nz-icon nzType="download"></i>
                        Download
                    </button>
                    <p>
                        {{ versoes.windows }}
                        <br>
                        Windows 7, 8, 9, 10, 11
                    </p>
                </div>
            </nz-col>
            <nz-col nzSpan="8">
                <h5 nz-typography>Download para Linux (x64)</h5>
                <div style="margin-bottom: 20px;">
                    <i class="fab fa-linux" style="font-size: 40px;"></i>
                </div>
                <div>
                    <button nz-button nzType="primary" style="margin-bottom: 15px;"
                            (click)="downloadAgent('linux')">
                        <i nz-icon nzType="download"></i>
                        Download
                    </button>
                    <p>
                        {{ versoes.linux }}
                        <br>
                        Debian, Ubuntu
                    </p>
                </div>
            </nz-col>
        </nz-row>
    </nz-content>
</nz-card>

<nz-card [nzTitle]="'MasterConnect'">
    <nz-content>
        <nz-row style="text-align: center;" class="justify-content-center">
            <nz-col nzSpan="8">
                <h5 nz-typography>Download para Windows, Mac e Linux (x64)</h5>
                <div style="margin-bottom: 20px;">
                    <i class="fab fa-windows" style="font-size: 40px; margin: 0 10px;"></i>
                    <i class="fab fa-apple" style="font-size: 40px; margin: 0 10px;"></i>
                    <i class="fab fa-linux" style="font-size: 40px; margin: 0 10px;"></i>
                </div>
                <div>
                    <button nz-button nzType="primary" style="margin-bottom: 15px;" (click)="downloadConnect()"
                            [nzLoading]="loadingConnect">
                        <i nz-icon nzType="download"></i>
                        Download
                    </button>
                    <p>
                        Windows 7, 8, 9, 10<br>macOS 10.11+<br>Debian, Ubuntu
                    </p>
                </div>
            </nz-col>
        </nz-row>
    </nz-content>
</nz-card>

<div>

    <nz-alert nzType="info" *ngIf="nomeValido !== 'transmissao' && arquivo?.status === 0 else zeroSemTooltip"
              [nzMessage]="messageStatus"
              nz-tooltip [nzTooltipTitle]="arquivo.processamentoLog"></nz-alert>

    <ng-template #zeroSemTooltip>
        <nz-alert nzType="info" *ngIf="arquivo?.status === 0 || arquivo?.status === 99" [nzMessage]="messageStatus"></nz-alert>
    </ng-template>

    <nz-alert nzType="info" *ngIf="arquivo?.status === 1" [nzMessage]="messageStatus"
              nz-tooltip nzTooltipTitle="{{arquivo.processamentoLog}}"></nz-alert>

    <nz-alert nzType="success" *ngIf="arquivo?.status === 2" [nzMessage]="messageStatus"></nz-alert>

    <nz-alert nzType="error" *ngIf="arquivo?.status === 3" [nzMessage]="messageStatus"
              nz-tooltip [nzTooltipTitle]="arquivo.erroMensagem"></nz-alert>

    <nz-alert nzType="info" *ngIf="arquivo?.status === 4" [nzMessage]="messageStatus"
              nz-tooltip [nzTooltipTitle]="arquivo.processamentoLog"></nz-alert>

    <nz-alert nzType="warning" *ngIf="arquivo?.status === null" [nzMessage]="messageStatus"></nz-alert>

    <nz-alert nzType="warning" *ngIf="arquivo?.status === 7" [nzMessage]="messageStatus"
              nz-tooltip [nzTooltipTitle]="arquivo?.erroMensagem"></nz-alert>

    <ng-template #messageStatus>
        <div *ngIf="arquivo?.status === 0 || arquivo?.status === 99">
            {{arrayStatusDescricao[arquivo?.status]}}
        </div>

        <div *ngIf="arquivo?.status === 1">
            {{arrayStatusDescricao[arquivo?.status]}} à {{arquivo.tempoProcessamento}}
            <nz-progress [nzPercent]="arquivo.processPercentage" nzStatus="active"
                         style="width: 100%;"></nz-progress>
        </div>

        <div *ngIf="arquivo?.status === 2">
            {{arrayStatusDescricao[arquivo?.status]}} em {{arquivo?.tempoProcessamento}}
            <br>
            <small>
                {{arquivo?.processamentoInicio | date: 'dd/MM/yyyy HH:mm:ss'}}
                -
                {{arquivo?.processamentoFim | date: 'dd/MM/yyyy HH:mm:ss'}}
            </small>
            <div>
                <!--Não conta o zip que é a união dos demais anexos !!!!-->
                <a *ngIf="anexos.length" nz-button nzType="link" (click)="modalAnexos(true, this.anexos)">
                    {{this.arquivo.quantidadeAnexos - 1}} anexos
                </a>
            </div>
        </div>
        <div *ngIf="arquivo?.status === 3">
            {{arrayStatusDescricao[arquivo?.status]}}
            <br>
            <small>
                {{arquivo?.processamentoFim | date: 'dd/MM/yyyy HH:mm:ss'}}
            </small>
            <div>
                <!--Não conta o zip que é a união dos demais anexos !!!!-->
                <a *ngIf="anexos.length" nz-button nzType="link" (click)="modalAnexos(true, this.anexos)">
                    {{this.arquivo.quantidadeAnexos - 1}} anexos
                </a>
            </div>
        </div>
        <div *ngIf="arquivo?.status === 4">
            {{arrayStatusDescricao[arquivo?.status]}}<br>
        </div>

        <div *ngIf="arquivo?.status === null">
            Não Iniciado<br>
        </div>

        <div *ngIf="arquivo?.status === 7">
            Processado com pendência
            <br>
            <small>
                {{arquivo?.processamentoFim | date: 'dd/MM/yyyy HH:mm:ss'}}
            </small>
            <div>
                <!--Não conta o zip que é a união dos demais anexos !!!!-->
                <a *ngIf="anexos.length" nz-button nzType="link" (click)="modalAnexos(true, this.anexos)">
                    {{this.arquivo.quantidadeAnexos - 1}} anexos
                </a>
            </div>
        </div>

        <div *ngIf="arquivo?.relatorio_status == 0 || arquivo?.relatorio_status == 1">
            Relatório PVA em processamento<br>
        </div>
        <div [nz-tooltip]="arquivo.relatorio_erroMensagem" *ngIf="arquivo?.relatorio_status == 3 &&
        (arquivo?.status !== 0 && arquivo?.status !== 1)"
             style="color: red; font-size: 0.9em;">
            Relatório PVA erro<br>
        </div>

    </ng-template>
</div>


<nz-modal [(nzVisible)]="modalVerAnexosVisible"
          [nzTitle]="'Anexos da transmissão'" [nzClosable]="true"
          (nzOnCancel)="modalAnexos(false)"
          [nzWidth]="550"
          [nzBodyStyle]="{'padding': '0 5px'}">
    <div *nzModalContent>
        <app-anexos-list [btZip]="true" [anexosList]="listOfAnexos"></app-anexos-list>
    </div>
    <div *nzModalFooter>
        <button [nzLoading]="loadings.zip" nz-button nzType="link" (click)="baixarZip(anexos[0])">Baixar todos
        </button>
        <button nz-button nzType="default" (click)="modalAnexos(false);">Fechar</button>
    </div>
</nz-modal>

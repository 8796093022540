<nz-page-header class="site-page-header" nzTitle="Notas" nzSubtitle="Análise XML Saídas Detalhes">
</nz-page-header>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="12">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="24">
                        <nz-card [nzLoading]="loadings.cabecalho" style="min-height: 191px">
                            <div class="d-flex align-items-center" style="margin-bottom: 20px;">
                                <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                                     class="d-flex justify-content-center align-items-center">
                                    <svg width="40" height="40"
                                         [data-jdenticon-value]="dadosAnalise?.unidade"
                                         *ngIf="dadosAnalise.unidade"></svg>
                                </div>
                                <h5 nz-typography style="margin-bottom: 0; margin-left: 10px;">
                                    {{ modoDemo(dadosAnalise.unidade, 'empresaCodigoNome') }}
                                </h5>
                            </div>
                            <nz-descriptions nzTitle="" [nzColumn]="2">
                                <nz-descriptions-item nzTitle="CNPJ">
                                    {{ modoDemo(dadosAnalise.cnpj | cpfCnpj, 'cnpj') }}
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Período">
                                    {{ dadosAnalise.periodoInicio | date: 'dd/MM/yyyy' }}
                                    a
                                    {{ dadosAnalise.periodoFim | date: 'dd/MM/yyyy' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle=" Solicitado por">
                                    {{ dadosAnalise.cadastroUsuarioNome ? dadosAnalise.cadastroUsuarioNome : '-' }}
                                    em
                                    {{ dadosAnalise.cadastroData | date: 'dd/MM/yyyy' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </nz-card>
                    </nz-col>
                </nz-row>

            </nz-content>
        </nz-col>
        <nz-col [nzSpan]="12">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="12">
                        <div class="purple-gradiend">
                            <nz-card class="purple-card ant-card-table ant-card-hoverable"
                                     [nzLoading]="loadings.cabecalho"
                                     [nzBodyStyle]="{'padding': '10px'}"
                                     [nzCover]="coverCardTotais" style="min-height: 191px"
                                     (click)="filtrarClick(null)">
                                <nz-content>
                                    <nz-row style="color: #fff; text-align: center;">
                                        <nz-col [nzSpan]="12"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">
                                                    {{ contadores?.total_notas?.toLocaleString('pt-BR') || '-' }}
                                                </h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; color: #fff; margin-bottom: 0;">Notas</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="12"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">
                                                    {{ contadores?.total_itens?.toLocaleString('pt-BR') || '-' }}
                                                </h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; color: #fff; margin-bottom: 0;">Itens</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardTotais>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted">
                                        <i class="fas fa-file" style="margin-right: 5px; color: #fff;"></i> Totais
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <div class="red-gradiend">
                            <nz-card class="red-card ant-card-table ant-card-hoverable"
                                     [nzLoading]="loadings.cabecalho" [nzBodyStyle]="{'padding': '10px'}"
                                     [nzCover]="coverCardTotaisComDiverg" style="min-height: 191px"
                                     (click)="filtrarClick(1)">
                                <nz-content>
                                    <nz-row style="color: #fff; text-align: center;">
                                        <nz-col [nzSpan]="12"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">
                                                    {{ contadores?.total_notas_divergencias?.toLocaleString('pt-BR') || '-' }}
                                                </h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; color: #fff; margin-bottom: 0;">Notas</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="12"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">
                                                    {{ contadores?.total_itens_divergencias?.toLocaleString('pt-BR') || '-' }}
                                                </h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; color: #fff; margin-bottom: 0;">Itens</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardTotaisComDiverg>
                            <div class="ant-card-head ng-star-inserted" style="color: #fff">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted">
                                        <i class="fas fa-not-equal" style="margin-right: 5px; color: #fff;"></i>Totais
                                        Com Divergências
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </nz-col>
    </nz-row>

    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="6">
            <div>
                <nz-card class="ant-card-table ant-card-hoverable"
                         [nzLoading]="loadings.cabecalho" [nzBodyStyle]="{'padding': '10px'}"
                         [nzCover]="coverCardDivergICMS"
                         (click)="filtrarClick(2)">
                    <nz-content style="text-align: center;">
                        <nz-row>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">
                                        {{ contadores?.notas_icms?.toLocaleString('pt-BR') || '-' }}
                                    </h3>
                                </div>
                                <div>
                                    <h5 nz-typography
                                        style="margin-bottom: 0; font-weight: 400; color: #545454">Notas</h5>
                                </div>
                            </nz-col>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.itens_icms?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography style="font-weight: 400; color: #545454">Quant.
                                        Itens</h5>
                                </div>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </div>
            <ng-template #coverCardDivergICMS>
                <div class="ant-card-head ng-star-inserted" style="color: #2B53B9FF">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span><i class="fas fa-not-equal"
                                     style="margin-right: 5px;"></i>Divergências ICMS</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </nz-col>
        <nz-col [nzSpan]="6">
            <div>
                <nz-card class="ant-card-table ant-card-hoverable" [nzLoading]="loadings.cabecalho"
                         [nzBodyStyle]="{'padding': '10px'}"
                         [nzCover]="coverCardDivergPIS"
                         (click)="filtrarClick(4)">
                    <nz-content style="text-align: center;">
                        <nz-row>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.notas_pis?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography
                                        style="margin-bottom: 0; font-weight: 400; color: #545454">Notas</h5>
                                </div>
                            </nz-col>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.itens_pis?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography style="font-weight: 400; color: #545454">Quant.
                                        Itens</h5>
                                </div>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </div>
            <ng-template #coverCardDivergPIS>
                <div class="ant-card-head ng-star-inserted" style="color: #FF9149FF">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span><i class="fas fa-not-equal"
                                     style="margin-right: 5px;"></i> Divergências PIS</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </nz-col>
        <nz-col [nzSpan]="6">
            <div>
                <nz-card class="ant-card-table ant-card-hoverable" [nzLoading]="loadings.cabecalho"
                         [nzBodyStyle]="{'padding': '10px'}"
                         [nzCover]="coverCardDivergCOFINS"
                         (click)="filtrarClick(5)">
                    <nz-content style="text-align: center;">
                        <nz-row>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.notas_cofins?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography
                                        style="margin-bottom: 0; font-weight: 400; color: #545454">Notas</h5>
                                </div>
                            </nz-col>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.itens_cofins?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography style="font-weight: 400; color: #545454">Quant.
                                        Itens</h5>
                                </div>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </div>
            <ng-template #coverCardDivergCOFINS>
                <div class="ant-card-head ng-star-inserted" style="color: #9C27B0FF">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span><i class="fas fa-not-equal"
                                     style="margin-right: 5px;"></i>Divergências COFINS</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </nz-col>
        <nz-col [nzSpan]="6">
            <div>
                <nz-card class="ant-card-table ant-card-hoverable" [nzLoading]="loadings.cabecalho"
                         [nzBodyStyle]="{'padding': '10px'}"
                         [nzCover]="coverCardDivergIPI"
                         (click)="filtrarClick(3)">
                    <nz-content style="text-align: center;">
                        <nz-row>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.notas_ipi?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography
                                        style="margin-bottom: 0; font-weight: 400; color: #545454">Notas</h5>
                                </div>
                            </nz-col>
                            <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                <div>
                                    <h3 nz-typography
                                        style="margin-bottom: 0; color: #545454">{{ contadores?.itens_ipi?.toLocaleString('pt-BR') || '-' }}</h3>
                                </div>
                                <div>
                                    <h5 nz-typography style="font-weight: 400; color: #545454">Quant.
                                        Itens</h5>
                                </div>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </div>
            <ng-template #coverCardDivergIPI>
                <div class="ant-card-head ng-star-inserted" style="color: #FF4961FF">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span><i class="fas fa-not-equal"
                                     style="margin-right: 5px;"></i>Divergências IPI</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </nz-col>
    </nz-row>

</nz-content>
<nz-content>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '']" [nzExtra]="navBar"
             nzTitle="Notas"
             [nzLoading]="loading">
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzFooter]="footer"
                  style="overflow: auto"
        >
            <thead>
            <tr>
                <th nzWidth="60px"></th>
                <th nzColumnKey="numero" [nzSortFn]="true">Número</th>
                <th nzColumnKey="serie" [nzSortFn]="true" class="text-center">Série</th>
                <th nzColumnKey="emissao" [nzSortFn]="true" class="text-center">Emissão</th>
                <th nzColumnKey="chave_de_acesso" [nzSortFn]="true" class="text-center">Chave de Acesso</th>
                <th nzColumnKey="destinatario" [nzSortFn]="true" class="text-center">Destinatário</th>
                <th nzColumnKey="uf" [nzSortFn]="true" class="text-center">UF</th>
                <th nzColumnKey="documento" [nzSortFn]="true" class="text-center">Documento</th>
                <th nzColumnKey="valor" [nzSortFn]="true" class="text-center">Valor</th>
                <th nzColumnKey="situacao" [nzSortFn]="true" class="text-center">Situação</th>
                <th nzColumnKey="status" [nzSortFn]="true" class="text-center">Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of basicTable.data">
                <tr style="white-space: nowrap;">
                    <td *ngIf="loadingExpand !== item.id else loadingEx" [nzExpand]="expandSet.has(item.id)"
                        (nzExpandChange)="onExpandChange(item, $event)"></td>
                    <ng-template #loadingEx>
                        <td nzAlign="left">
                            <span style="font-size: x-small" nz-icon nzType="loading" nzTheme="outline"></span>
                        </td>
                    </ng-template>
                    <td nzAlign="center">
                        {{ item.numero }}
                    </td>
                    <td nzAlign="center">
                        {{ item.serie }}
                    </td>
                    <td nzAlign="center">
                        {{ item.emissao | date: 'dd/MM/yyyy' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.chave_de_acesso }}
                    </td>
                    <td nzAlign="center">
                        <i *ngIf="item.divergencias?.documento" [nz-tooltip]="item.divergencias?.documento"
                           style="font-size: 1.2em; color: #fab421" class="fa-solid fa-triangle-exclamation"></i>
                        {{ item.destinatario ? modoDemo(item.destinatario, 'empresaCodigoNome') : '-' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.uf || '-' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.documento ? modoDemo(item.documento | cpfCnpj, 'cnpj') : '-' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.valor | currency : 'BRL' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.situacaoDescricao ? item.situacaoDescricao : '-' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.statusDescricao || '-' }}
                    </td>
                    <td nzAlign="center" style=" width: 6%">

                        <div style="display: flex;">
                            <div *ngIf="!item.observacao else btModalOcorrencia">
                                <button style="cursor: pointer" nz-button nzType="link"
                                        (click)="modalObservacao(true, false, null, item)"
                                        [nz-tooltip]="'Adicionar Observação'">
                                    <i class="fa-solid fa-circle-plus"></i>
                                </button>
                            </div>

                            <button nz-button nzType="link" nz-tooltip="true"
                                    nzTooltipTitle="Visualizar DANFE" [nzLoading]="loadings.danfe[item.chave_de_acesso]"
                                    (click)="downloadDanfe(item)">
                                <span nz-icon nzType="file-pdf" nzTheme="outline"></span>
                            </button>
                        </div>

                        <ng-template #btModalOcorrencia>
                            <div>
                                <span nz-icon nzType="eye" nzTheme="fill"
                                      [nz-tooltip]="'Ver observação'"
                                      style="color: #1890ff; cursor: pointer; font-size: 1.2em"
                                      (click)="modalOcorrencia(true, item)"></span>
                            </div>
                        </ng-template>

                    </td>
                <tr [nzExpand]="expandSet.has(item.id)">
                    <nz-table #expandTable
                              nzSize="small"
                              class="table-small"
                              style="overflow: hidden"
                              [nzData]="arrayTableItensNota[item.id]"
                              [nzFrontPagination]="false"
                              [nzShowPagination]="false"
                    >
                        <thead>
                        <tr>
                            <th></th>
                            <th nzColumnKey="codigo">Código</th>
                            <th nzColumnKey="descricao">Descrição</th>
                            <th nzColumnKey="cfop">CFOP</th>
                            <th nzColumnKey="cst_icms">CST ICMS</th>
                            <th nzColumnKey="bc_icms">BC ICMS</th>
                            <th nzColumnKey="aliqupta_icms">Aliq. ICMS</th>
                            <th nzColumnKey="cst_pis">CST PIS</th>
                            <th nzColumnKey="bc_pis">BC PIS</th>
                            <th nzColumnKey="aliqupta_pis">Aliq. PIS</th>
                            <th nzColumnKey="cst_cofins">CST COFINS</th>
                            <th nzColumnKey="bc_cofins">BC COFINS</th>
                            <th nzColumnKey="aliqupta_cofins">Aliq. COFINS</th>
                            <th nzColumnKey="cst_ipi">CST IPI</th>
                            <th nzColumnKey="bc_ipi">BC IPI</th>
                            <th nzColumnKey="aliqupta_cipi">Aliq. IPI</th>
                            <th nzAlign="center" nzColumnKey="origem_material">Origem material</th>
                            <th nzColumnKey="codigo_enquadramento">Cód. Enquadramento</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let expandItem of expandTable.data">
                            <td style="min-width: 45px"></td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.codigo ? 'red': 'black'}">{{ expandItem.codigo }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.descricao ? 'red': 'black'}">{{ expandItem.descricao }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.cfop ? 'red': 'black'}">{{ expandItem.cfop }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.cst_icms ? 'red': 'black'}">{{ expandItem.cst_icms }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.bc_icms ? 'red': 'black'}">{{ expandItem.bc_icms }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.aliquota_icms ? 'red': 'black'}">{{ expandItem.aliquota_icms }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.cst_pis ? 'red': 'black'}">{{ expandItem.cst_pis }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.bc_pis ? 'red': 'black'}">{{ expandItem.bc_pis }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.aliquota_pis ? 'red': 'black'}">{{ expandItem.aliquota_pis }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.cst_cofins ? 'red': 'black'}">{{ expandItem.cst_cofins }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.bc_cofins ? 'red': 'black'}">{{ expandItem.bc_cofins }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.aliquota_cofins ? 'red': 'black'}">{{ expandItem.aliquota_cofins }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.cst_ipi ? 'red': 'black'}">{{ expandItem.cst_ipi }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.bc_ipi ? 'red': 'black'}">{{ expandItem.bc_ipi }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.aliquota_ipi ? 'red': 'black'}">{{ expandItem.aliquota_ipi }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.origem_material ? 'red': 'black'}">{{ expandItem.origem_material || '-' }}
                            </td>
                            <td nzAlign="center"
                                [ngStyle]="{'color': expandItem.divergencias?.codigo_enquadramento ? 'red': 'black'}">{{ expandItem.codigo_enquadramento }}
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </tr>
            </ng-container>
            </tbody>

            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>

</nz-content>
<ng-template #navBar>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button nz-button nzType="primary" nzGhost
                    (click)="modalFiltrar(true);"
                    style="margin-right: 10px;">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default" style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="modalExportar(true);">Exportar Planilha</li>
            </ul>
        </nz-dropdown-menu>

        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input (keydown.enter)="search()" type="text" nz-input placeholder="Número, Chave, Destinatário ou Série"
                   [(ngModel)]="pesquisar"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch style="margin-right: 10px;" (click)="search();"><i nz-icon
                                                                                                           nzType="search"></i>
            </button>
        </ng-template>

        <button nz-button nzType="default" (click)="limparFiltros();">Limpar</button>

    </div>
</ng-template>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Emissão</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="emissao" style="width: 100%;"
                                    nzPlaceHolder="Selecione"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Chave</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="chave_de_acesso">
                        <nz-option *ngFor="let opt of comboChaves"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Número</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="numero">
                        <nz-option *ngFor="let opt of comboNumeros"
                                   [nzLabel]="modoDemo(opt.label, 'total')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Série</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="serie">
                        <nz-option *ngFor="let opt of comboSeries"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Destinatário/Emitente</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="destinatario">
                        <nz-option *ngFor="let opt of comboDestinatariosEmitentes"
                                   [nzLabel]="modoDemo(opt.label,'empresaCodigoNome')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Documento</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="documento">
                        <nz-option *ngFor="let opt of comboDocumentos"
                                   [nzLabel]="modoDemo(opt.label, 'cnpj')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Situação</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option *ngFor="let opt of comboSituacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status Divergência</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="status">
                        <nz-option *ngFor="let opt of comboStatus"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportando" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal nzTitle="Observação"
          class="modal-table"
          [(nzVisible)]="displayOcorrenciaModal"
          (nzOnCancel)="modalOcorrencia(false)"
          [nzWidth]="848"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #observacaoTable
                  [nzData]="arrayTableObservacao"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzNoResult]="semDados"
                  class="fonte-doze table-small"
                  nzLoading="{{loadingObservacao}}">
            <thead>
            <tr>
                <th nzAlign="center">
                    Descrição
                </th>
                <th nzAlign="center">
                    Criado em
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let observacao of observacaoTable.data">
                <td nzAlign="center" style="word-break: break-word">
                    {{ observacao?.descricao }}
                </td>
                <td nzAlign="center">
                    {{ observacao?.created_at | date : 'dd/MM/yyyy HH:mm' }}
                    <br>
                    <span style="font-size: 0.9em">
                        ({{ observacao.observacaoUsuario }})
                    </span>
                </td>
                <td nzRight nzAlign="center" style="white-space: nowrap">
                    <a nz-button nzType="link"
                       nz-tooltip="true"
                       nzTooltipTitle="Editar/Ver"
                       (click)="modalObservacao(true, true, observacao.descricao, {id: notaIdSelecionada})">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #semDados>
            <div style="font-size: 1.5em; color: #c2c2c2; padding: 30px 0">
                Não há dados.
            </div>
        </ng-template>
    </div>
    <div *nzModalFooter>

        <button nz-button nzType="link" (click)="modalOcorrencia(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [nzTitle]="!flagEditarObservacao ? 'Adicionar Observação' : 'Editar Observação'"
          [(nzVisible)]="displayObservacaoModal"
          (nzOnCancel)="modalObservacao(false)"
          [nzWidth]="848">
    <div *nzModalContent>
        <textarea rows="4" nz-input [(ngModel)]="observacaoValue" placeholder="Digite..."></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalObservacao(false)">Fechar</button>
        <button [nzLoading]="loadingsObservacaoAdicionar" nz-button nzType="primary" *ngIf="!flagEditarObservacao"
                (click)="adicionarObservacao()">Adicionar
        </button>
        <button [nzLoading]="loadingsObservacaoAdicionar" nz-button nzType="primary" *ngIf="flagEditarObservacao"
                (click)="adicionarObservacao()">Gravar
        </button>
    </div>
</nz-modal>

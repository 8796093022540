import {NgModule} from '@angular/core';
import {GuiasLotesComponent} from './guiasLotes.component';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {UiModule} from '../../ui.module';
import {GuiasLotesRoutingModule} from './guiasLotes-routing.module';
import {CommonModule} from '@angular/common';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {HighchartsChartModule} from 'highcharts-angular';
import {SwiperModule} from 'swiper/angular';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NgxMaskModule} from 'ngx-mask';
import {SafePipeModule} from 'safe-pipe';
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {ExportarTabelaModule} from "@components/exportar-tabela/exportar-tabela.module";

@NgModule({
    imports: [
        GuiasLotesRoutingModule,
        NzCarouselModule,
        NzAnchorModule,
        NzTypographyModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        FormsModule,
        NzSelectModule,
        UiModule,
        NzTabsModule,
        CommonModule,
        NzBreadCrumbModule,
        NzProgressModule,
        NzPageHeaderModule,
        HighchartsChartModule,
        SwiperModule,
        NzSpaceModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NgxMaskModule,
        SafePipeModule,
        NzBadgeModule,
        ExportarTabelaModule
    ],
    declarations: [GuiasLotesComponent],
})
export class GuiasLotesModule {
}

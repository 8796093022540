import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {DecCndService} from './decCnd.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DataService} from '@services/data.service';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {environment} from '../../../../environments/environment';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {Location} from '@angular/common';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-dashboard-decCnd',
    templateUrl: './decCnd.component.html',
    styleUrls: ['./decCnd.component.scss']
})
export class DecCndComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, TabHandlerInterface {

    // Variáveis para relatório
    objectCountersValues = {
        qtdObrigacoes: 0,
        qtdAusencias: 0,
        qtdDivergencias: 0,

        porcRegulares: 0,
        porcIrregulares: 0,
        porcSuspensas: 0,
        ultima_consulta: '',

        mesSelecionado: '',
        anoSelecionado: '',
        updated_at: null,
        qtdRegulares: 0,
        qtdIrregulares: 0,
        qtdSuspensa: 0,

        total: 0
    };

    domiciliosEletronicos = {
        qtdUnidades: 0,
        qtdMensagens: 0,
        qtdNaoLidos: 0,
        qtdDomicilios: 0,
        ultima_consulta: ''
    };

    arrayColorStatus = [
        '#9e8886',
        '#28d094',
        '#666ee8',
        '#ff9149',
    ];
    tableStatus: any = {};

    // loadings
    cardCabecalhoLoading = false;
    loadingContadores = false;
    cardDomiciliosLoading = false;

    // FILTROS
    formFiltrosGrid: FormStack;
    formFiltrosCabecalho: FormStack;
    arraySelectEmpresa = [];
    arraySelectCertidao = [];
    tableEmissoesAutomaticas: any = {};
    totaltEmissoesAutomaticas = 0;

    qtdFiltrosAtivos = 0;
    qtdFiltrosCabecalho = 0;

    HighchartsBasic: typeof Highcharts = Highcharts;
    chartBasicOptions: Highcharts.Options;
    highChart: Highcharts.Chart | null;

    HighchartsPie: typeof Highcharts = Highcharts;
    chartPieOptions: Highcharts.Options;

    updateFlag: boolean;

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    currentUser: any;

    apiUrl = environment.apiUrl;

    optionsEmpresas = [];
    comboUFs: any = [];
    comboTodosMunicipios: any = [];

    constructor(
        private location: Location,
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private modalService: NzModalService,
        private service: DecCndService,
        private i18n: NzI18nService,
        private dataService: DataService) {

        super(service, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                statusUltimaEmissao: [null, null],
                validadeUltimaCnd: [null, null],
            })
        };
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    ngOnInit() {

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno;
            // this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornarSelectsCertidao().subscribe((retorno: any) => {
            this.arraySelectCertidao = retorno;
        });

        this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.service.retornaEstados().subscribe((retorno: any) => {

            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;

        });

        this.service.retornaComboMunicipios().subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboTodosMunicipios = [];
            this.comboTodosMunicipios = retorno.cidades;
        });

    }

    ngAfterViewInit() {

        this.resizeModal();

        this.carregaCabecalho();
        this.retornaSituacaoFiscalRFB();
        this.retornaDomicilios();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

    }

    carregaCabecalho(): void {

        this.cardCabecalhoLoading = true;

        this.calculaBadgeFiltrosCabecalho();

        const filtros = this.formFiltrosCabecalho.formGroup.value;

        this.service.retornaCabecalho(filtros).subscribe((response: any) => {

            this.tableEmissoesAutomaticas = response.emissoes;

            this.tableEmissoesAutomaticas.ultima_consulta = response.ultima_consulta;

            Object.entries(response.status).forEach(s => {
                this.totaltEmissoesAutomaticas += Number(s[1]);
            });

            this.tableStatus = response.status;

            this.loadChartData(response.validade, response.status);

            this.cardCabecalhoLoading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

    }

    retornaSituacaoFiscalRFB() {
        this.loadingContadores = true;
        this.service.retornaSituacaoFiscalRFB().subscribe((response) => {

            this.objectCountersValues.qtdRegulares = response.quantidade_regulares;
            this.objectCountersValues.qtdIrregulares = response.quantidade_irregulares;
            this.objectCountersValues.qtdSuspensa = response.quantidade_suspensas;
            this.objectCountersValues.total = Number(response.quantidade_regulares) + Number(
                response.quantidade_irregulares) + Number(response.quantidade_suspensas);

            this.objectCountersValues.porcRegulares = Math.round(this.getPercentage(this.objectCountersValues.qtdRegulares, this.objectCountersValues.total));


            this.objectCountersValues.porcIrregulares = Math.round(this.getPercentage(this.objectCountersValues.qtdIrregulares, this.objectCountersValues.total));
            this.objectCountersValues.porcSuspensas = Math.round(this.getPercentage(this.objectCountersValues.qtdSuspensa, this.objectCountersValues.total));

            this.objectCountersValues.ultima_consulta = response.ultima_consulta;

            this.loadingContadores = false;

        });

    }

    retornaDomicilios() {
        this.cardDomiciliosLoading = true;

        this.calculaBadgeFiltrosCabecalho();

        const filtros = this.formFiltrosCabecalho.formGroup.value;

        this.service.retornaDomicilios(filtros).subscribe((response: any) => {

            response.forEach(r => {
                this.domiciliosEletronicos.qtdUnidades += Number(r.qtdUnidades);
                this.domiciliosEletronicos.qtdMensagens += Number(r.qtdeTotal);
                this.domiciliosEletronicos.qtdNaoLidos += Number(r.qtdeNaoLida);
            });

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

        this.service.retornaDomiciliosTotal(filtros).subscribe((response: any) => {

            this.domiciliosEletronicos.qtdDomicilios = response.totalDomicilios;

            this.domiciliosEletronicos.ultima_consulta = response.ultima_consulta;

            this.cardDomiciliosLoading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });


    }

    loadChartData(validade, status): void {

        const seriesBasicChart: any = [];

        Object.entries(validade).forEach((value) => {
            seriesBasicChart.push(value[1]);
        });

        this.chartBasicOptions = {
            chart: {
                type: 'bar',
            },
            title: {
                text: undefined
            },
            subtitle: {
                text: undefined
            },
            xAxis: {
                categories: ['Superior a 60 dias', 'Em até 60 dias', 'Em até 30 dias', 'Em até 15 dias', 'Vencida'],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Certidões',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' Certidões'
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.openControleCnd.bind(this)
                        }
                    }
                },
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                type: undefined,
                name: '',
                data: seriesBasicChart
            }]
        };

        const seriesPieChart: any = [{
            type: 'pie',
            name: 'Certidões',
            data: [
                {
                    name: 'Nunca Emitida',
                    y: Number(status.status_0)
                }, {
                    name: 'Negativa',
                    y: Number(status.status_1)
                }, {
                    name: 'Positiva com Efeito de Negativa',
                    y: Number(status.status_2)
                }, {
                    name: 'Positiva',
                    y: Number(status.status_3)
                }]
        }];

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: this.arrayColorStatus,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: seriesPieChart
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    formatOne = (percent: number) => `${percent}%`;

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    atualizarTela() {
        this.carregaCabecalho();
        this.retornaSituacaoFiscalRFB();
        this.retornaDomicilios();
    }

    calculaBadgeFiltrosCabecalho(): void {

        this.qtdFiltrosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosCabecalho++;
                }

            }

        }

    }

    openControleCertidoes(statusUltimaEmissao: any): void {

        this.openTab('ControleCertidoesComponent', null, [{statusUltimaEmissao: String(statusUltimaEmissao)}]);

    }

    openControleCnd(data) {

        let validadeUltimaCnd = '0';

        if (data.point.category === 'Superior a 60 dias') {
            validadeUltimaCnd = '100';

        } else if (data.point.category === 'Em até 60 dias') {
            validadeUltimaCnd = '60';

        } else if (data.point.category === 'Em até 30 dias') {
            validadeUltimaCnd = '30';

        } else if (data.point.category === 'Em até 15 dias') {
            validadeUltimaCnd = '15';

        } else if (data.point.category === 'Vencida') {
            validadeUltimaCnd = '0';
        }

        this.openTab('ControleCertidoesComponent', null, [{validadeUltimaCnd}]);

    }

    openEcac(statusEmissao) {
        this.openTab('ConsolidadoEcacComponent', null, [{statusEmissao}]);
    }

    openDec(data?: any) {
        this.openTab('DomicilioEletronicoComponent', null, [data]);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ControleCertidoesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornaCabecalho(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes/cabecalho${queryStr}`);

    }

    retornaCertidoes(filtros: any, order: any, pageIndex: any, search: any): Observable<any> {

        if (filtros.empresa_id_codigo) {
            filtros.empresa_id = filtros.empresa_id_codigo;
            delete filtros.empresa_id_codigo;
        }

        if (filtros.vencimento) {
            filtros.vencimento = this.formataDateBD(filtros.vencimento.toString());
        }

        if (filtros.dataUltimaEmissao) {
            filtros.dataUltimaEmissao = this.formataDateBD(filtros.dataUltimaEmissao.toString());
        }

        if (filtros.ultimaCnd) {
            filtros.ultimaCnd = this.formataDateBD(filtros.ultimaCnd.toString());
        }

        if (filtros.empresaNome) {
            filtros.empresa_id = filtros.empresaNome;
        }

        if (order.ordenar && order.sentido) {
            filtros.sort = {};
            filtros.sort.by = order.ordenar;
            filtros.sort.order = order.sentido;
        }

        if (pageIndex) {
            filtros.pagina = pageIndex;
        }

        if (filtros.pageSize) {
            filtros.quantidade = filtros.pageSize;
        }

        if (search) {
            filtros.busca = search;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes${queryStr}`);

    }


    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/empresas`);
    }

    retornarSelectsEmpresasCodigo(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsCertidao(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/certidoes`);
    }


    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    montaOrder(parametros): any {
        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'asc' : 'desc'
                };
            }
        });

        return order;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    formataDateTimeBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate()) +
                'T' + this.mesStr(data.getHours()) +
                ':' + this.mesStr(data.getMinutes()) +
                ':' + this.mesStr(data.getSeconds());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }
        }

        return retorno;
    }

    exportPdfs(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=pdf`);
        queryParams.push(`tipo=cnd`);

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}${queryString}`, {});

    }

    retornaHistorico(id): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes/historico/${id}`);

    }

    retornaUltimaCnd(id): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes/historico/${id}/arquivo`);

    }

    retornaJson(processamentoId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/debug/cnd-body-post?processamento_id=${processamentoId}`);
    }

    reprocessarRegistro(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/robo/executar/cnd/${id}`);
    }

    agendarProcessamento(certidaoId, empresaId): Observable<any> {
        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/agendarProcessamento?certidao=${certidaoId}&empresa_id=${empresaId}`, {});
    }

    retornarOptionCertidoesManuais(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/certidaoManual/select`);
    }

    adicionarCndManual(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoes/adicionarManual`, formData);
    }

    getHistoricoUnico(id): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoes/historicoUnico/${id}`);

    }

    editarHistoricoUnico(id: string, dados: any): Observable<any> {

        if (dados.emissao) {
            dados.emissao = this.formataDateTimeBD(dados.emissao.toString());
        }

        if (dados.validade) {
            dados.validade = this.formataDateBD(dados.validade.toString());
        }

        return this.http.put(`${environment.apiUrl}/certidoes/controleCertidoes/historicoUnico/${id}`, dados);

    }

    excluirHistoricoUnico(id: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/certidoes/controleCertidoes/historicoUnico/${id}`);

    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaComboMunicipios(uf: string = null): Observable<any> {

        if (uf) {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);
        } else {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select`);
        }

    }

    novaCertidao(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/certidoes/gravarManual`, dados);

    }

    updateJustificativa(id: string, justificativa: string): Observable<any> {

        return this.http.put(`${environment.apiUrl}/certidoes/controleCertidoes/historicoUnico/${id} `, {justificativa});

    }

    getUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

}

// shared/shared.module.ts
import { NgModule } from '@angular/core';
import { CnpjMaskDirective } from './cnpj-mask-directve.directive';

@NgModule({
    declarations: [
        CnpjMaskDirective,
    ],
    exports: [
        CnpjMaskDirective,
    ]
})
export class CnpjMaskDirectveModule { }

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticoFiscalService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/diagnostico-fiscal', {});
    }

    getEmpresas(filtros: any): any {

        const queryStr = filtros ? Helpers.montaQueryString(filtros) : '';

        return this.http.get(`${environment.apiUrl}/diagnostico-fiscal/empresas${queryStr}`);
    }

    getDiagnosticos(filtros: any): any {

        const queryStr = filtros ? Helpers.montaQueryString(filtros) : '';

        return this.http.get(`${environment.apiUrl}/diagnostico-fiscal/tabela${queryStr}`);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    reprocessar(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/diagnostico-fiscal/reprocessar`, {ids});
    }

    novoDiagnostico(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/diagnostico-fiscal/solicitar`, data);
    }

    removerDiagnostico(ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/diagnostico-fiscal/excluir`, {ids});
    }

    donwloadBook(id: string): any {

        return this.http.get(`${environment.apiUrl}/diagnostico-fiscal/pdf/${id}`,
            {responseType: 'blob'});
    }


}

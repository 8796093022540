<div class="container">
    <nz-card [nzBordered]="false" nzTitle="Editar Perfil">
        <form nz-form [formGroup]="profileForm">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5" nzFor="descricao">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorDescricao">
                    <nz-input-group>
                        <input nz-input formControlName="descricao" name="descricao" type="text" id="descricao">
                    </nz-input-group>
                    <ng-template let-control #errorDescricao>
                        <ng-container *ngIf="control.hasError('required')">
                            Você precisa informar uma descrição !
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzOffset]="5" [nzSpan]="5">
                    <button (click)="save()" nz-button nzType="primary">Salvar</button>
                </nz-form-control>
            </nz-form-item>
        </form>
    </nz-card>
    <nz-card nzTitle="Permissões do perfil">
        <nz-tree #treePerfil [nzData]="menuTree"
                [nzShowExpand]="true"
                [nzExpandAll]="true"
                [nzBlockNode]="true"
                nzCheckable
                nzMultiple
                >
        </nz-tree>
    </nz-card>
</div>

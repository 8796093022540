import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';

@Injectable({providedIn: 'root'})
export class DetalhesDadosEmpresaService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/cnpj-receita/detalhes/';
    baseUrlSocios = environment.apiUrl + '/publico/cnpj-receita/detalhes/';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaComboUFs(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/ufsCredencialPortal/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaDadosRFB(search: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/publico/cnpj-receita/detalhes/${search}`);
    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }


        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }


    exportExcel(filtros: any): any {

        return this.http.get(`${environment.apiUrl}/cnpj-receita/detalhes/${filtros.cnpj}/estabelecimentos/exportar?tipoExportacao=${filtros.tipoExportacao}`,
            {responseType: 'blob'});

    }
}

import {Component, OnInit} from '@angular/core';
import {DeployInfoData} from '../../../../../assets/deploy-info.data';
import {UsuarioLogado} from '@models/usuarioLogado.model';

@Component({
    selector: 'deploy-info',
    templateUrl: './deploy-info.html'
})

export class DeployInfo implements OnInit {

    deployInfoData;
    currentUser: UsuarioLogado;

    constructor() {

        this.deployInfoData = DeployInfoData;
    }

    ngOnInit() {
    }

}


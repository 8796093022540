import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class MonitorDeEventosService {

    http = inject(HttpClient);

    listaEventos(filtros: any): Observable<any> {

        const queryString = Helpers.montaQueryStribgBYNzTable(filtros)

        return this.http.get(`${environment.apiUrl}/compliance/notas/analises/eventos${queryString}`);
    }

    confirmaEvento(ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/eventos/confirmar`, {ids});
    }
}

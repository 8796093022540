import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import GrupoEmpresarial from '@models/grupo-empresarial.model';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class GrupoEmpresarialService extends AbstractService<GrupoEmpresarial> {

    constructor(http: HttpClient) {
        super(http, '/administracao/grupos-empresariais', GrupoEmpresarial);
    }


    adicionar(dados: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/administracao/grupo-empresarial`, dados);
    }


}

<nz-page-header class="site-page-header" nzTitle="Configurações" nzSubtitle="Integrações"></nz-page-header>
<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Configurações" [nzLoading]="loading"
             [nzBodyStyle]="{'padding': loading ? '10px' : '0'}"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group
                        nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" (keydown.enter)="buscar()"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <!--                <th style="min-width: 30px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"-->
                <!--                    [nzIndeterminate]="indeterminate"-->
                <!--                    id="check-all">-->
                <!--                </th>-->
                <th nzColumnKey="codigo" nzAlign="center" [nzSortFn]="true">Código</th>
                <th nzColumnKey="cnpj" nzAlign="center" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="empresa" style="max-width: 250px" [nzSortFn]="true">Empresa</th>
                <th nzColumnKey="grupo" [nzSortFn]="true">Grupo</th>
                <th nzColumnKey="integracoes" nzAlign="center">Integrações</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <!--                <td (nzCheckedChange)="onItemChecked(data.id, $event)"-->
                <!--                    [nzChecked]="checkedItems.has(data.id)">-->
                <!--                </td>-->
                <td nzAlign="center">{{data?.codigo || '-'}}</td>
                <td nzAlign="center" [noWrap]="1">{{modoDemo(data?.cnpj | cpfCnpj, 'cnpj')}}</td>
                <td nzAlign="left">{{modoDemo(data?.empresa, 'empresaCodigoNome')}}</td>
                <td nzAlign="left">{{data?.grupo || '-'}}</td>
                <td nzAlign="left">
                    <div class="div-bt"
                         style="display: flex; justify-content: flex-start; align-items: center; width: 40%;">

                        <div *ngFor="let i of data.integracoes">
                            <button *ngIf="!loadingRemoveConfig.includes(i.id) else configLoad" nz-dropdown
                                    [nzDropdownMenu]="menuAcoes" [nzPlacement]="'bottomRight'"
                                    [ngClass]="[i.status == 1 ? 'bt bt-success' : 'bt bt-disabled']">
                                {{i.descricao}}
                            </button>
                            <ng-template #configLoad>
                                <span style="margin: 0 10px" nz-icon nzType="loading" nzTheme="outline"></span>
                            </ng-template>
                            <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                                <ul nz-menu>
                                    <li class="flex-drop" nz-menu-item
                                        (click)="showModalConfiguracaoERP(true, i, true)">
                                        <i class="fa-fw fas fa-pen-to-square"></i>
                                        <span>Editar Configuração</span>
                                    </li>
                                    <li class="flex-drop" nz-menu-item (click)="showModalCopiarConfiguracao(true, i)">
                                        <i class="fa-fw far fa-paper-plane"></i>
                                        <span>Exportar Configuração</span>
                                    </li>
                                    <li class="flex-drop" nz-menu-item (click)="showConfirmRemover(i)">
                                        <i style="padding: 0 2px" class="fa-regular fa-trash-can"></i>
                                        <span> Remover Configuração</span>
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                        <button class="bt bt-add" *ngIf="data.integracoes?.length < integracoes.length"
                                [nz-tooltip]="'Adicionar integração ERP'"
                                (click)="showModalConfiguracaoERP(true, null, false, data.id)">
                            +
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{opt.nome}}
                            <br><small>({{opt.cnpj | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="grupo">
                        <nz-option *ngFor="let opt of gruposEmpresariais"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalMensagemSefazVisible"
          [nzTitle]="modalUltimaMensagemTitulo" [nzClosable]="true"
          (nzOnCancel)="modalMsgSefaz(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <span>
            {{ultimaMensagemSefaz || 'Sem mansagem para exibir.'}}
        </span>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalMsgSefaz(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formConfiguracaoERP.modalVisible"
          [nzTitle]="editando ? 'Alterar Configuração ERP' : 'Cadastrar Configuração ERP'"
          [nzClosable]="true"
          (nzOnCancel)="showModalConfiguracaoERP(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadingCarregandoConfig"></nz-skeleton>
        <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="true" [formGroup]="formConfiguracaoERP.formGroup" style="width: 100%;"
              *ngIf="!loadingCarregandoConfig"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear [nzDisabled]="editando"
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option *ngFor="let opt of integracoes" [nzLabel]="opt.key"
                                   [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Status</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativa'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Configuração</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-textarea-count [nzMaxCharacterCount]="1500">
                        <textarea placeholder="{}" rows="10" formControlName="configuracao" nz-input></textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalConfiguracaoERP(false);">Cancelar</button>
        <button *ngIf="!editando else btEditar" nz-button nzType="primary"
                [nzLoading]="loadingAddConfig" (click)="confirmaCadastrarConfigERP();">Cadastrar
        </button>
        <ng-template #btEditar>
            <button nz-button nzType="primary"
                    [nzLoading]="loadingAlterandoConfig" (click)="confirmaEditarConfigERP();">Alterar
            </button>
        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalCopiarVisible"
          [nzTitle]="'Selecione as empresas que receberão a configuração'" [nzClosable]="true"
          (nzOnCancel)="showModalCopiarConfiguracao(false)"
          [nzWidth]="648">
    <div *nzModalContent>

        <div style="display: flex; align-items: end; padding: 0; margin-bottom: 10px; width: 100%">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input (keyup)="filtrarEmpresasFront()" type="text" nz-input placeholder="Busca"
                       [(ngModel)]="buscaInterna"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch (click)="filtrarEmpresasFront()">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>
            <button nz-button nzType="default" style="margin-right: 10px;"
                    (click)="btnResetSearchFront();">
                Limpar
            </button>
        </div>

        <nz-skeleton *ngIf="loadingEmpresas"></nz-skeleton>

        <nz-table #basicTable
                  [nzData]="empresasFront"
                  *ngIf="!loadingEmpresas"
                  [nzShowPagination]="false"
                  style="font-size: 10px; width: 100%;"
                  [nzScroll]="{ y: '320px', x: 'none'}">
            <thead>
            <tr>
                <th [nzWidth]="'28px'" (nzCheckedChange)="onAllCheckedEmpresas($event)" [nzChecked]="checked"
                    [nzIndeterminate]="indeterminate">
                </th>
                <th nzColumnKey="nome">Empresa</th>
                <th nzColumnKey="cnpj">CNPJ</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let data of basicTable.data" (click)="empresaChecked(data.id)"
                style="cursor: pointer;">
                <td [ngStyle]="{'background-color': checkedEmpresas.has(data.id) ? '#e6f7ff' : ''}"
                    (nzCheckedChange)="empresaChecked(data.id)"
                    [nzChecked]="checkedEmpresas.has(data.id)">
                </td>
                <td [ngStyle]="{'background-color': checkedEmpresas.has(data.id) ? '#e6f7ff' : ''}">
                    {{data?.nome}}
                </td>
                <td [ngStyle]="{'background-color': checkedEmpresas.has(data.id) ? '#e6f7ff' : ''}">
                    {{data?.cnpj | cpfCnpj}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="primary" [nzLoading]="loadingCopiandoConfig"
                disabled="{{checkedEmpresas.size < 1}}" (click)="showConfirmCopiar()">Confirmar
        </button>
    </div>
</nz-modal>

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {AnalisesXMLSaidasDetalhesService} from './AnalisesXMLSaidasDetalhes.service';
import {Helpers} from '../../../core/helpers';
import {NfeTerceirosService} from '../nfe-terceiros/nfe-terceiros.service';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-checkAnalisesDeNotasDetalhesComponent',
    templateUrl: './AnalisesXMLSaidasDetalhes.component.html',
    styleUrls: ['./AnalisesXMLSaidasDetalhes.component.scss']
})
export class AnalisesXMLSaidasDetalhesComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @Output() generalFilterHandler = new EventEmitter<string>();

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    dadosAnalise: any = {};
    contadores: any = {};

    formFiltrar: FormStack;

    formExportar: FormStack;

    pesquisar = '';

    comboChaves = [];
    comboNumeros = [];
    comboSeries = [];
    comboDestinatariosEmitentes = [];
    comboDocumentos = [];
    comboOperacoes = [];
    comboSituacoes = [];
    comboStatus = [];
    comboUFs: any = [];

    expandSet = new Set<number>();
    arrayTableItensNota = [];

    loadingExpand = null;

    loadings = {
        cabecalho: false,
        tabela: false,
        subTabela: false,
        exportando: false,
        danfe: {},
    };

    displayOcorrenciaModal = false;
    displayObservacaoModal = false;
    arrayTableObservacao = [];
    notaIdSelecionada = null;
    loadingObservacao = false;

    loadingsObservacaoAdicionar = false;
    flagEditarObservacao: any = false;
    observacaoValue = '';

    qtdFiltrosAtivos = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private service: AnalisesXMLSaidasDetalhesService,
        private cdr: ChangeDetectorRef,
        private nfeTerceirosService: NfeTerceirosService,
        private tabService: TabService) {

        super(usuarioService, Usuario, toastService);

        this.loading = false;

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                emissao: [null, null],
                chave_de_acesso: [null, null],
                numero: [null, null],
                serie: [null, null],
                destinatario: [null, null],
                documento: [null, null],
                situacao: [null, null],
                status: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.service.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

    }

    ngOnInit() {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.retornaCabecalho();
        this.queryTable(this.currentParams);
        this.retornaCombosFiltros();
    }

    retornaCombosFiltros(): void {

        this.service.retornaCombosFiltros(this.data.id).subscribe((response) => {

            Object.entries(response.chaves).forEach(
                ([key, value]) => {
                    const dados = {id: key, label: value};
                    this.comboChaves.push(dados);
                }
            );

            response.numeros.forEach((value) => {
                    const dados = {id: value, label: value};
                    this.comboNumeros.push(dados);
                }
            );

            response.series.forEach((value) => {
                    const dados = {id: value, label: value};
                    this.comboSeries.push(dados);
                }
            );

            Object.entries(response.destinatarios).forEach(
                ([key, value]) => {
                    const destinatariosEmitentes = {id: key, label: value};
                    this.comboDestinatariosEmitentes.push(destinatariosEmitentes);
                    const documentos = {id: key, label: key};
                    this.comboDocumentos.push(documentos);
                }
            );

            response.operacoes.forEach((value) => {
                    const chave = {id: value, label: value};
                    this.comboOperacoes.push(chave);
                }
            );

            Object.entries(response.situacoes).forEach(
                ([key, value]) => {
                    const destinatariosEmitentes = {id: key, label: value};
                    this.comboSituacoes.push(destinatariosEmitentes);
                }
            );

            Object.entries(response.status).forEach(
                ([key, value]) => {
                    const statusDivergencia = {id: key, label: value};
                    this.comboStatus.push(statusDivergencia);
                }
            );

        });

    }

    retornaCabecalho(): void {

        this.loadings.cabecalho = true;

        this.service.retornaCabecalho(this.data.id).subscribe((response) => {
                this.dadosAnalise = response.dadosAnalise;
                this.contadores = response.contadores;
                this.loadings.cabecalho = false;

            },
            (err) => {
                this.loadings.cabecalho = false;
                this.toastService.error(err.error.message);
            }
        );

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null, filtrar = false): void {

        this.loading = true;
        this.currentParams = params;

        this.service.id = this.data.id;
        this.service.montaUrl(this.service.id);

        if (filtrar) {

            if (this.formFiltrar.formGroup.value.emissao) {
                this.formFiltrar.formGroup.value.emissao = Helpers.formataDateBD(this.formFiltrar.formGroup.value.emissao);
            }

            this.currentParams.filter = [];

            const filtros = this.formFiltrar.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                if (valor) {
                    this.currentParams.filter.push({key: chave, value: valor});
                }

            }

        }


        this.abstractService.listToTable(this.currentParams, this.currentSearch, this.service.url).subscribe((response) => {

            this.expandSet.clear();

            this.loading = false;

            this.items = [];
            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 50);

            this.loading = false;
        });
    }

    filtrarClick(status?: number): void {

        this.currentParams.filter = [];

        this.currentParams.filter.push({key: 'cabecalho_status', value: status});

        this.queryTable(this.currentParams, this.currentSearch);

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
        this.cdr.detectChanges();
    }

    confirmaFiltrar(): void {

        if (this.formFiltrar.formGroup.value.emissao) {
            this.formFiltrar.formGroup.value.emissao = Helpers.formataDateBD(this.formFiltrar.formGroup.value.emissao);
        }

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.calculaFiltrosEmpresas();

        this.queryTable(this.currentParams, this.currentSearch, true);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
        this.cdr.detectChanges();
    }

    confirmaExportar(): void {

        const tipo = this.formExportar.formGroup.value.tipo;

        if (this.formExportar.formGroup.valid) {
            this.loadings.exportando = true;

            this.service.exportExcel(this.data.id, this.formFiltrar.formGroup.value, tipo).subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = 'XML Saída Detalhes';

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.modalVisible = false;
                    this.cdr.detectChanges();
                }
                this.loadings.exportando = false;

            }, (res) => {
                this.loadings.exportando = false;
            });

        } else {
            for (const key in this.formExportar.formGroup.controls) {

                if (key) {

                    const campo = this.formExportar.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }

    limparFiltros(): void {

        this.zerarFormFiltrar();

        this.currentParams.filter = [];
        this.currentSearch = '';
        this.pesquisar = null;

        this.calculaFiltrosEmpresas();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    zerarFormFiltrar(): void {
        this.formFiltrar.formGroup.reset();
    }

    search(): void {
        this.currentSearch = this.pesquisar.length > 0 ? this.pesquisar : null;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    onExpandChange(item: any, checked: boolean): void {

        if (checked) {

            this.expandSet.clear();
            this.loadingExpand = item.id;

            this.retornaItensNota(this.data.id, item.id, ((response) => {
                this.arrayTableItensNota[item.id] = response;
                this.expandSet.add(item.id);
                this.loadingExpand = null;
            }));

        } else {

            this.expandSet.delete(item.id);

        }

    }

    retornaItensNota(analiseId, notaId, callback): void {

        this.service.retornaItensNota(analiseId, notaId, this.currentParams.filter, this.currentSearch).subscribe((response) => {

            callback(response);

        });
    }

    modalOcorrencia(visible, item = null) {

        this.notaIdSelecionada = item?.id || null;

        if (visible && item.observacao) {
            this.arrayTableObservacao = [
                {
                    descricao: item.observacao,
                    observacaoUsuario: item.observacao_usuario_nome,
                    created_at: item.observacao_created_at
                }
            ];

        } else {
            this.arrayTableObservacao = [];
        }

        this.displayOcorrenciaModal = visible;
        this.cdr.detectChanges();
    }

    modalObservacao(visible, editar = false, descricao = null, item = null) {

        this.observacaoValue = editar ? descricao : '';

        this.notaIdSelecionada = item?.id || null;

        this.flagEditarObservacao = editar;

        this.displayObservacaoModal = visible;

        this.cdr.detectChanges();

    }

    adicionarObservacao() {

        this.loadingsObservacaoAdicionar = true;

        this.service.adicionarObservacao(
            this.data.id, this.notaIdSelecionada,
            this.observacaoValue
        ).subscribe((response) => {

            this.toastrService.success(response.message);

            this.displayOcorrenciaModal = false;
            this.displayObservacaoModal = false;

            this.loadingsObservacaoAdicionar = false;

            this.queryTable(this.currentParams);

        }, (response) => {
            this.loadingsObservacaoAdicionar = false;
            this.toastrService.error(response.error.message);

        });

    }

    calculaFiltrosEmpresas(): void {
        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }
        }
    }

    downloadDanfe(nota): void {

        this.loadings.danfe[nota.chave_de_acesso] = true;

        this.nfeTerceirosService.downloadDanfe(nota.chave_de_acesso).subscribe((res) => {

            const blob = new Blob([res], {type: 'application/pdf'});

            let pos = '';
            switch (Number(nota.modelo_id)) {
                case 55: {
                    pos = '-ProcNFe';
                    break;
                }
                case 57: {
                    pos = '-ProcCTe';
                    break;
                }

            }

            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);

            this.loadings.danfe[nota.chave_de_acesso] = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadings.danfe[nota.chave_de_acesso] = false;
        });

    }

}

<nz-page-header class="site-page-header" nzTitle="Configurações" nzSubtitle="Notas Fiscais Eletrônicas">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Notas Fiscais Eletrônicas" [nzLoading]="loadingTable"
             [nzBodyStyle]="{'padding': loadingTable ? '15px' : '0px'}"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button [disabled]="loadingTable" nz-button nzType="primary" style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        [nzDisabled]="loadingTable"
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadingAlterar"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingAlterar ? '' : showConfirmAtivarDesativar('1');">
                                    Ativar DFE
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadingAlterar"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingAlterar ? '' : showConfirmAtivarDesativar('0');">
                                    Desativar DFE
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadingAlterar"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingAlterar ? '' : showModalAlterar(true);">
                                    Alterar Intervalo de Consulta
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group
                        nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input [disabled]="loadingTable" type="text" nz-input placeholder="Busca" (keydown.enter)="buscar()"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button [disabled]="loadingTable" nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button [disabled]="loadingTable" nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  *ngIf="usuarioLogado.origem !== 'console' else toConsole"
                  nzSize="small"
                  [nzScroll]="{ x: 'auto' }"
                  [nzData]="items"
                  (nzQueryParams)="queryTable($event)"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <th style="min-width: 30px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="nome" style="min-width: 230px" [nzSortFn]="true">Empresa</th>
                <th nzAlign="center" nzColumnKey="ativaDFE" [nzSortFn]="true">Ativa DFE</th>
                <th nzAlign="center" nzColumnKey="dataConsultaDFEInicio" [nzSortFn]="true">Intervalo de Consulta DFE</th>
                <th nzAlign="center" nzColumnKey="nfeFaltaNSU" [nzSortFn]="true">NFE Falta NSU</th>
                <th nzAlign="center" nzColumnKey="nsuFaltantes" [nzSortFn]="true">NFE faltantes</th>
                <th nzAlign="center" nzColumnKey="cteFaltaNSU" [nzSortFn]="true">CTE falta NSU</th>
                <th nzAlign="center" nzColumnKey="cteFaltantes" [nzSortFn]="true">CTE faltantes</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of items">
                <td (nzCheckedChange)="onItemChecked(data.id, $event)"
                    [nzChecked]="checkedItems.has(data.id)">
                </td>
                <td nzAlign="left" [noWrap]="1">{{modoDemo(data?.cnpj | cpfCnpj, 'cnpj')}}</td>
                <td nzAlign="left">
                    {{modoDemo(data?.nome, 'empresaCodigoNome')}}
                    <nz-badge
                            *ngIf="data.certificado_status || data.certificado_status?.toString() === '0'"
                            nzColor="{{arrayStatusCertidao[data.certificado_status]?.color}}"

                            [nz-tooltip]="arrayStatusCertidao[data.certificado_status]?.descricao"
                            style="margin-left: 5px;">
                    </nz-badge>
                </td>
                <td nzAlign="center">{{data?.ativaDFE ? 'SIM' : 'NÃO'}}</td>
                <td nzAlign="center">
                    <div *ngIf="data.dataConsultaDFEInicio && data.dataConsultaDFEFim else semDataDFE">
                        {{data.dataConsultaDFEInicio || '-'}}
                        às
                        {{data.dataConsultaDFEFim || '-'}}
                    </div>
                    <ng-template #semDataDFE>
                        <span>-</span>
                    </ng-template>
                </td>
                <td nzAlign="center">{{data?.nfeFaltaNSU ? 'SIM' : 'NÃO'}}</td>
                <td nzAlign="center">{{data?.nsuFaltantes ? data?.nsuFaltantes : '0'}}</td>
                <td nzAlign="center">{{data?.cteFaltaNSU ? 'SIM' : 'NÃO'}}</td>
                <td nzAlign="center">{{data?.cteFaltantes ? data?.cteFaltantes : '0'}}</td>
                <td nzAlign="center">
                    <button (click)="openTab('/configuracoes/editarNotasFiscaisEletronicas/', data.id, {id: data.id})"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>

        <ng-template #toConsole>
            <nz-table #basicTableConsole
                      *ngIf="usuarioLogado.origem === 'console'"
                      nzSize="small"
                      [nzScroll]="{ x: 'auto' }"
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzPageIndex]="pagination?.current_page"
                      (nzQueryParams)="queryTable($event)"
                      [nzFooter]="footer"
            >
                <thead>
                <tr>
                    <th nzLeft style="min-width: 30px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                        [nzIndeterminate]="indeterminate"
                        id="check-all-c">
                    </th>
                    <th style="min-width: 100px; word-break: break-word" nzColumnKey="identificador" [nzSortFn]="true"
                        [nzShowSort]="false">
                        Identificador
                    </th>
                    <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                    <th style="min-width: 230px; word-break: break-word" nzColumnKey="nome" [nzSortFn]="true">Empresa
                    </th>
                    <th nzColumnKey="ativaDFE" style="min-width: 80px;" nzAlign="center" [nzSortFn]="true">Ativa DFE
                    </th>
                    <th nzColumnKey="dataConsultaDFEInicio" style="min-width: 100px;" nzAlign="center" [nzSortFn]="true">Intervalo Consulta DFE</th>
                    <th nzColumnKey="nfeFaltaNSU" nzAlign="center" [nzSortFn]="true">NFE Falta NSU</th>
                    <th nzColumnKey="nfeTotalNSU" nzAlign="center" [nzSortFn]="true">NFE Último NSU</th>
                    <th nzColumnKey="nfeUltimoNSU" nzAlign="center" [nzSortFn]="true">NFE Total NSU</th>
                    <th nzColumnKey="nsuFaltantes" style="min-width: 80px;" nzAlign="center" [nzSortFn]="true">NFE
                        faltantes
                    </th>
                    <th nzColumnKey="dataProcessamentoNFE" nzAlign="center" [nzSortFn]="true">NFE Data Processamento
                    </th>
                    <th nzColumnKey="cStatNFE" nzAlign="center" [nzSortFn]="true">NFE cStat</th>
                    <th nzAlign="center" [nzShowSort]="false">Última msg Sefaz NFE</th>
                    <th nzColumnKey="cteFaltaNSU" nzAlign="center" [nzSortFn]="true">CTE falta NSU</th>
                    <th nzColumnKey="cteUltimoNSU" nzAlign="center" [nzSortFn]="true">CTE Último NSU</th>
                    <th nzColumnKey="cteTotalNSU" nzAlign="center" [nzSortFn]="true">CTE Total NSU</th>
                    <th nzColumnKey="cteFaltantes" nzAlign="center" [nzSortFn]="true">CTE faltantes</th>
                    <th nzColumnKey="dataProcessamentoCTE" nzAlign="center" [nzSortFn]="true">CTE Data Processamento
                    </th>
                    <th nzColumnKey="cStatCTE" nzAlign="center" [nzSortFn]="true">CTE cStat</th>
                    <th nzAlign="center" [nzSortFn]="true">Última msg Sefaz CTE</th>
                    <th nzColumnKey="updatedAt" nzAlign="center" [nzSortFn]="true">Alterado em</th>
                    <th nzRight></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of items">
                    <td nzLeft (nzCheckedChange)="onItemChecked(data.id, $event)"
                        [nzChecked]="checkedItems.has(data.id)">
                    </td>
                    <td>{{data?.identificador | uppercase}}</td>
                    <td [noWrap]="1">{{modoDemo(data?.cnpj | cpfCnpj, 'cnpj')}}</td>
                    <td nzAlign="left">
                        {{modoDemo(data?.nome, 'empresaCodigoNome')}}
                        <nz-badge
                                *ngIf="data.certificado_status || data.certificado_status?.toString() === '0'"
                                nzColor="{{arrayStatusCertidao[data.certificado_status]?.color}}"

                                [nz-tooltip]="arrayStatusCertidao[data.certificado_status]?.descricao"
                                style="margin-left: 5px;">
                        </nz-badge>
                    </td>
                    <td nzAlign="center">{{data?.ativaDFE ? 'SIM' : 'NÃO'}}</td>
                    <td nzAlign="center">
                        <div *ngIf="data.dataConsultaDFEInicio && data.dataConsultaDFEFim else semDataDFE">
                            {{data.dataConsultaDFEInicio || '-'}}
                            às
                            {{data.dataConsultaDFEFim || '-'}}
                        </div>
                        <ng-template #semDataDFE>
                            <span>-</span>
                        </ng-template>
                    </td>
                    <td nzAlign="center">{{data?.nfeFaltaNSU ? 'SIM' : 'NÃO'}}</td>
                    <td nzAlign="center">{{data?.nfeTotalNSU}}</td>
                    <td nzAlign="center">{{data?.nfeUltimoNSU}}</td>
                    <td nzAlign="center">{{data?.nsuFaltantes ? data?.nsuFaltantes : '0'}}</td>
                    <td nzAlign="center">{{data.dataProcessamentoNFE ? (data.dataProcessamentoNFE | date: 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                    <td nzAlign="center">{{data?.cStatNFE}}</td>
                    <td nzAlign="center">
                        <a (click)="modalMsgSefaz(true, data.ultimaMensagemSefazNFE, 'Última Mensagem Sefaz NFE')">Ver</a>
                    </td>
                    <td nzAlign="center">{{data?.cteFaltaNSU ? 'SIM' : 'NÃO'}}</td>
                    <td nzAlign="center">{{data?.cteUltimoNSU}}</td>
                    <td nzAlign="center">{{data?.cteTotalNSU}}</td>
                    <td nzAlign="center">{{data?.cteFaltantes ? data?.cteFaltantes : '0'}}</td>
                    <td nzAlign="center">{{data.dataProcessamentoCTE ? (data.dataProcessamentoCTE | date: 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                    <td nzAlign="center">{{data?.cStatCTE}}</td>
                    <td nzAlign="center">
                        <a (click)="modalMsgSefaz(true, data.ultimaMensagemSefazCTE, 'Última Mensagem Sefaz CTE')">Ver</a>
                    </td>
                    <td nzAlign="center">
                        {{data.updatedAt ? (data.updatedAt| date: 'dd/MM/yyyy HH:mm:ss') : '-'}}
                    </td>
                    <td nzAlign="center" nzRight>
                        <button (click)="openTab('/configuracoes/editarNotasFiscaisEletronicas/', data.id, {id: data.id})"
                                nz-button
                                nzType="link">
                            Editar
                        </button>
                    </td>
                </tr>
                </tbody>
                <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
                </ng-template>
            </nz-table>

        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{opt.nome}}
                            <br><small>({{opt.cnpj | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Ativa DFE</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.ativaDFE">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="ativaDFE">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'s'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'n'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAlterar.modalVisible"
          [nzTitle]="'Alterar Intervalo Consulta DFE'" [nzClosable]="true"
          (nzOnCancel)="showModalAlterar(false)"
          [nzWidth]="748">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formAlterar.formGroup" style="width: 100%;">
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Intervalo Consulta DFE</nz-form-label>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formAlterar.formGroup.value.dataConsultaDFEInicio">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="dataConsultaDFEInicio"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="De:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formAlterar.formGroup.value.dataConsultaDFEFim">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="dataConsultaDFEFim"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="Até:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalAlterar(false);">Cancelar</button>
        <button [nzLoading]="loadingAlterar" nz-button nzType="primary" (click)="confirmaAlterar()">Alterar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalMensagemSefazVisible"
          [nzTitle]="modalUltimaMensagemTitulo" [nzClosable]="true"
          (nzOnCancel)="modalMsgSefaz(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <span>
            {{ultimaMensagemSefaz || 'Sem mansagem para exibir.'}}
        </span>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalMsgSefaz(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>
                    Selecione o formato do arquivo a ser exportado.
                </nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               [disabled]="loadingExportar"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button [nzLoading]="loadingExportar" nz-button nzType="primary" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>

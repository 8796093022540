import { Utils } from '../utils';

export class Log {
    id: string;
    tipo: string;
    acao: string;
    user: string;
    dataAcao: string;

    constructor(obj?: any) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[Utils.camelCase(prop)] = obj[prop];
            }
        }
    }
}

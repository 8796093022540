import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    NgZone,
    OnInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {HttpClient} from '@angular/common/http';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TabService} from '@services/tab.service';
import {ToastrService} from 'ngx-toastr';
import {BaixasAutomaticasService} from './baixas-automaticas.service';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import moment from 'moment';
import {environment} from '../../../../environments/environment';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {Helpers} from '../../../core/helpers';
import {Subscription} from 'rxjs';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-baixas-automaticas',
    templateUrl: './baixas-automaticas.component.html',
    styleUrls: ['./baixas-automaticas.component.scss']
})
export class BaixasAutomaticasComponent extends AbstractListTable<any> implements OnInit, OnDestroy {

    pagination: Pagination;
    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 10,
        sort: [{
            key: '',
            value: 'ascend',
        }],
        filter: []
    };
    currentSearch: string;

    currentUser: UsuarioLogado;

    identificador = '';

    swiperCompetencias: any = [];
    swiperCardAtivo = 0;
    swiperCollapseCard = false;
    anoAtivo = '';

    cabecalho: any = {};
    cardsCabecalhoLoading = false;
    cardsCompetenciasLoading = false;

    loadingEmpresas = false;
    loadingObrigacoes = false;
    tabNumber = 0;
    statusCard = false;

    loadingSolicitarBaixa = false;
    loadingSolicitarBaixaManual = false;
    loadingExportar = false;

    empresas = [];
    obrigacoes = [];

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild('column12') column12: ElementRef;
    column12Width = 0;

    @ViewChild('column13') column13: ElementRef;
    column13Width = 0;

    @ViewChild('column14') column14: ElementRef;
    column14Width = 0;

    @ViewChild('column15') column15: ElementRef;
    column15Width = 0;


    @ViewChild('column16') column16: ElementRef;
    column16Width = 0;

    @ViewChild('column17') column17: ElementRef;
    column17Width = 0;

    statusOne = false;

    expandSet = new Set<number>();
    expandSetEmpresas = new Set<string>();
    expandSetObrigacoes = new Set<number>();

    modalDetalhesVisible = false;

    arrayDetalhes = [];

    loadingDetalhes = [];

    formFiltrosEmpresas: FormStack;
    formFiltrosObrigacoes: FormStack;
    formSolicitarBaixa: FormStack;
    formSolicitarBaixaDarf: FormStack;

    qtdFiltrosAtivoscardOne = 0;
    qtdFiltrosAtivoscardTwo = 0;

    arraySelectGrupos = [];
    arraySelectEmpresas = [];
    arraySelectObrigacoes = [];

    empresaSelecionada = {
        empresa_nome: null,
        cnpj: null,
        empresa_id: null
    };

    expandAll = true;

    eventoSource: EventSource | any;

    allGroups = false;

    formExportar: FormStack;

    arrayStatusCertidao = [
        {
            color: '#ff4961',
            descricao: 'Certificado Vencido'
        },
        {
            color: '#42ba96',
            descricao: 'Certificado Válido'
        },
        {
            color: '#1565c0',
            descricao: 'Certificado de Procurador'
        },
        {
            color: '#b4b4b4',
            descricao: 'Sem Certificado'
        },
        {
            color: '#0f724f',
            descricao: 'Certificado Matriz'
        },
    ];

    requestE: Subscription;
    requestObg: Subscription;

    constructor(
        private fb: UntypedFormBuilder,
        private service: BaixasAutomaticasService,
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private tabService: TabService,
        private toastService: ToastrService,
        private zone: NgZone
    ) {
        super(service, null, toastService);

        this.carregaCompetencias();

        this.identificador = localStorage.getItem('identificador');

        this.service.usuarioLogado().subscribe((user) => {
            this.currentUser = user;
        });

        this.formFiltrosEmpresas = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                ie: [null, null],
                grupos: [null, Validators.required],
                minUltimaConsultaData: [null, null],
                maxUltimaConsultaData: [null, null],
                minQuantidade: [null, null],
                maxQuantidade: [null, null]
            })
        };

        this.formFiltrosObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                obrigacao: [null, null]
            })
        };

        this.formSolicitarBaixa = {
            modalVisible: false,
            formGroup: this.fb.group({
                competenciaInicial: [null, [Validators.required]],
                competenciaFinal: [null, [Validators.required]]
            })
        };

        this.formSolicitarBaixaDarf = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                nroDarf: [[], [Validators.required]],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.service.usuarioLogado().subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.toggleParamsCardTabs();
    }

    ngOnInit(): void {

        this.service.retornaComboGruposEmpresariais().subscribe((response) => {
            response.grupoEmpresarial.forEach((value) => {
                this.arraySelectGrupos.push({id: value.id, descricao: value.descricao});
            });
        });

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresas = retorno.empresaNome;
        });

        this.service.retornaComboObrigacoes().subscribe((response) => {
            response.obrigacoes.forEach((value) => {
                this.arraySelectObrigacoes.push({id: value.id, descricao: value.descricao});
            });
        });

    }

    ngOnDestroy() {
        if (this.eventoSource) {
            this.eventoSource.close();
        }
    }

    onChecked(checked: boolean): void {
        this.formFiltrosEmpresas.formGroup.get('grupos').setValue(null);
        this.allGroups = checked;
    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.swiperCardAtivo = this.swiperCardAtivo - swipNumber;

                if (this.swiperCardAtivo < 0) {
                    this.swiperCardAtivo = 0;
                }

                break;

            case 'right':

                this.swiperCardAtivo = this.swiperCardAtivo + swipNumber;

                if (this.swiperCardAtivo > this.swiperCompetencias.length - 1) {
                    this.swiperCardAtivo = this.swiperCompetencias.length - 1;
                }

                break;

        }

    }

    toggleCollapseCard() {
        this.swiperCollapseCard = !this.swiperCollapseCard;
    }

    carregaCompetencias(): void {

        this.cardsCabecalhoLoading = true;
        this.cardsCompetenciasLoading = true;

        this.service.retornaCompetencias().subscribe((response: any) => {

            Object.entries(response).forEach(value => {
                this.swiperCompetencias.push(value[1]);
            });

            this.anoAtivo = this.swiperCompetencias[this.swiperCompetencias.length - 1].ano;

            this.atualizaCabecalho(this.swiperCompetencias[this.swiperCompetencias.length - 1]);

            setTimeout(() => {
                this.swiperCardAtivo = this.swiperCompetencias.length - 1;
                this.swiperCollapseCard = true;
                this.cardsCompetenciasLoading = false;
                this.cardsCabecalhoLoading = false;

            }, 800);

        });

    }

    selecionaCardCompetencia(event: any) {
        if (event.clickedIndex !== undefined) {
            this.atualizaCabecalho(this.swiperCompetencias[event.clickedIndex]);
            if (this.empresaSelecionada.empresa_id) {
                this.retornaobrigacoes(this.empresaSelecionada, this.currentParams.filter);
            }

            this.filtrarEmpresas();

            this.swiperCardAtivo = event.clickedIndex;
        }
    }

    atualizaCabecalho(competenciaSelecionada: any): void {
        this.cabecalho.ano = competenciaSelecionada.ano;
        this.cabecalho.unidades = competenciaSelecionada.unidades;
        this.cabecalho.obrigacoes = competenciaSelecionada.obrigacoes;

        this.anoAtivo = competenciaSelecionada.ano;

        this.retornaEmpresas(this.currentParams.filter);
    }

    retornaEmpresas(filtros: any) {

        if (this.allGroups || this.formFiltrosEmpresas.formGroup.valid) {

            this.zone.run(() => {

                this.empresas = [];

                if (this.eventoSource) {
                    this.eventoSource.close();
                }

                this.loadingEmpresas = true;

                filtros.ano = this.anoAtivo;
                filtros.procurar = this.currentSearch;


                const params = new URLSearchParams({
                    identificador: this.identificador,
                    usuario_id: this.currentUser.id,
                    chave: String(Helpers.randomUID(10000))
                });


                this.eventoSource = new EventSource(
                    `${environment.apiUrl}/publico/stream/obrigacao-consulta?${params}`
                );


                this.eventoSource.onerror = (e) => {
                    this.toastService.error('Erro ao tentar obter os dados. Recarregue a página e tente novamente.');
                };

                this.eventoSource.addEventListener('obrigacao-consulta', e => {

                    const res: any = JSON.parse(e.data);
                    this.empresas = [...this.empresas, {grupoEmpresarial_descricao: res[0], empresas: res[1]}];
                    this.expandSetEmpresas.add(res[0]);

                    setTimeout(() => {
                        this.toggleParamsCardTabs();
                        this.cdr.detectChanges();
                        this.loadingEmpresas = false;
                    }, 100);
                });

                this.eventoSource.onopen = (e) => {

                    filtros.chave = params.get('chave');

                    if (this.requestE) {
                        this.requestE?.unsubscribe();
                    }

                    this.requestE = this.service.retornaEmpresas(filtros).subscribe((response: any) => {

                        setTimeout(() => {
                            this.toggleParamsCardTabs();
                            this.cdr.detectChanges();
                            this.loadingEmpresas = false;
                        }, 100);

                    }, error => {
                        this.toastService.error(error.mesage);
                        this.loadingEmpresas = false;
                    });
                };

            });
        } else if (window.location.href.includes('drive/baixas-automaticas')) {
            this.toastService.warning('Selecione os grupos empresariais que deseja visualizar');
            this.formFiltrosEmpresas.modalVisible = true;
            Object.values(this.formFiltrosEmpresas.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }

    }

    retornaobrigacoes(empresa: any, filtros: any) {

        this.empresaSelecionada.empresa_nome = empresa.empresa_nome;
        this.empresaSelecionada.cnpj = empresa.cnpj;
        this.empresaSelecionada.empresa_id = empresa.empresa_id;
        this.loadingObrigacoes = true;

        filtros.procurar = this.currentSearch;

        this.zone.run(() => {

            if (this.requestObg) {
                this.requestObg?.unsubscribe();
            }

            this.requestObg = this.service.retornaObrigacoes(empresa.empresa_id, this.anoAtivo, filtros).subscribe((response: any) => {

                this.obrigacoes = response;

                this.obrigacoes.forEach(o => {
                    this.expandSetObrigacoes.add(o.tipo);
                });

                setTimeout(() => {
                    this.toggleParamsCardTabs();
                    this.cdr.detectChanges();
                    this.loadingObrigacoes = false;
                }, 100);

            }, error => {
                this.toastService.error(error.mesage);
                this.loadingObrigacoes = false;
            });

        });

    }

    buscar() {
        if (this.tabNumber === 0) {
            this.retornaEmpresas(this.currentParams.filter);
        } else {
            this.retornaobrigacoes(this.empresaSelecionada, this.currentParams.filter);
        }
    }

    changeTabs(event: any = null) {
        if (!this.empresaSelecionada.empresa_id) {
            this.tabNumber = 0;
        } else {
            this.currentSearch = null;
        }

        if (this.tabNumber === 0) {
            this.filtrarEmpresas();
        }

        setTimeout(() => {
            this.toggleParamsCardTabs();
            this.cdr.detectChanges();
        }, 100);
    }

    onExpandChangeEmpresas(descricao: string, checked: boolean): void {

        if (checked) {
            this.expandSetEmpresas.add(descricao);
        } else {
            this.expandSetEmpresas.delete(descricao);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;
        this.column7Width = this.column7.nativeElement.offsetWidth;
        this.column8Width = this.column8.nativeElement.offsetWidth;

    }

    onExpandChangeObrigacoes(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetObrigacoes.add(id);
        } else {
            this.expandSetObrigacoes.delete(id);
        }

        this.column9Width = this.column9.nativeElement.offsetWidth;
        this.column10Width = this.column10.nativeElement.offsetWidth;
        this.column11Width = this.column11.nativeElement.offsetWidth;
        this.column12Width = this.column12.nativeElement.offsetWidth;
        this.column13Width = this.column13.nativeElement.offsetWidth;
        this.column14Width = this.column14.nativeElement.offsetWidth;
        this.column15Width = this.column15.nativeElement.offsetWidth;
        this.column16Width = this.column16.nativeElement.offsetWidth;
    }

    showDatelhesObrigacao(visible: boolean, obriacaoId?: string) {
        if (visible) {
            this.arrayDetalhes = [];
            this.loadingDetalhes.push(obriacaoId);
            this.modalDetalhesVisible = visible;

            this.service.retornaDetalheObrigacao(
                obriacaoId, this.empresaSelecionada.empresa_id, this.anoAtivo).subscribe((response: any) => {
                this.arrayDetalhes = response;

                this.arrayDetalhes.forEach(d => {

                    if (d.roboBaixa_status != null) {
                        d.roboBaixa_status = d.roboBaixa_status.toString();
                    }

                    if (d.roboBaixa_status === '0') {
                        d.roboBaixa_status_descricao = 'Na Fila';
                        d.roboBaixa_status_class = 'semCor';
                    } else if (d.roboBaixa_status === '1') {
                        d.roboBaixa_status_descricao = 'Processando';
                        d.roboBaixa_status_class = 'semCor';
                    } else if (d.roboBaixa_status === '3') {
                        d.roboBaixa_status_descricao = 'Erro ao processar';
                        d.roboBaixa_status_class = 'error';
                    } else if (d.roboBaixa_status === '2') {
                        d.roboBaixa_status_descricao = `Processado`;
                        d.roboBaixa_status_class = 'success';
                    } else if (d.roboBaixa_status === '6') {
                        d.roboBaixa_status_descricao = 'Armazenando';
                        d.roboBaixa_status_class = 'default';
                    } else if (d.roboBaixa_status === '5') {
                        d.roboBaixa_status_descricao = 'Job solicitado';
                        d.roboBaixa_status_class = 'info';
                    } else if (d.roboBaixa_status === '7') {
                        d.roboBaixa_status_descricao = 'Processado sem registros';
                        d.roboBaixa_status_class = 'warning';
                    }

                });

                // sequência do job -> 0 - 5 - 1 - 6 - 2 3 ou 7

                this.loadingDetalhes = [];
            }, error => {
                this.toastrService.error(error.message);
                this.loadingDetalhes = [];
            });
        } else {
            this.modalDetalhesVisible = visible;
            this.loadingDetalhes = [];
        }


    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    limparFiltros(): void {
        if (this.eventoSource) {
            this.eventoSource.close();
        }
        this.allGroups = false;
        this.tabNumber = 0;
        this.formFiltrosEmpresas.formGroup.reset();
        this.formFiltrosObrigacoes.formGroup.reset();
        this.currentSearch = null;
        this.currentParams.filter = [];
        this.calculaBadgeFiltros();
        this.empresas = [];

        setTimeout(() => {
            this.retornaEmpresas(this.currentParams.filter);
        }, 300);
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    calculaBadgeFiltros(): void {

        if (this.tabNumber === 0) {
            this.qtdFiltrosAtivoscardOne = 0;

            Object.entries(this.formFiltrosEmpresas.formGroup.value).forEach((value) => {

                if (value[0] !== 'ano' && value[0] !== 'sort' && value[0] !== 'page' && value[0] !== 'chave' && value[1] && value[1] !== 'todos') {
                    this.qtdFiltrosAtivoscardOne += 1;
                }

            });

        } else if (this.tabNumber === 1) {
            this.qtdFiltrosAtivoscardTwo = 0;

            Object.entries(this.formFiltrosObrigacoes.formGroup.value).forEach((value) => {

                if (value[0] !== 'ano' && value[0] !== 'sort' && value[0] !== 'page' && value[0] !== 'chave' && value[1]) {
                    this.qtdFiltrosAtivoscardTwo += 1;
                }

            });
        }

    }

    ajustaFiltros() {

        for (const [chave, valor] of Object.entries(this.formFiltrosEmpresas.formGroup.value)) {

            const chaveParaValidarData = ['minUltimaConsultaData', 'maxUltimaConsultaData'];

            if (valor && chaveParaValidarData.includes(chave)) {
                this.formFiltrosEmpresas.formGroup.value[chave] = moment(valor).format('YYYY-MM-DD HH:mm:ss');
            }
        }

    }

    filtrarEmpresas() {

        this.ajustaFiltros();

        if (this.allGroups || this.formFiltrosEmpresas.formGroup.valid) {
            this.formFiltrosEmpresas.modalVisible = false;
            this.currentParams.filter = this.formFiltrosEmpresas.formGroup.value;

            this.calculaBadgeFiltros();

            if (this.allGroups) {
                this.formFiltrosEmpresas.formGroup.value.grupos = 'todos';
            }

            this.retornaEmpresas(this.currentParams.filter);
        } else {
            this.toastService.error('Selecione os grupos empresariais que deseja visualizar');
            Object.values(this.formFiltrosEmpresas.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    filtrarObrigacoes(empresa: any, byTab = false): void {
        this.currentSearch = null;
        this.formFiltrosObrigacoes.modalVisible = false;
        this.currentParams.filter = this.formFiltrosObrigacoes.formGroup.value;
        this.retornaobrigacoes(empresa, this.currentParams.filter);

        if (byTab) {
            this.formFiltrosObrigacoes.formGroup.reset();
        }

        this.calculaBadgeFiltros();

        this.checkedItems.clear();

        this.tabNumber = 1;
    }

    clickEvent() {

        this.statusCard = !this.statusCard;

    }

    exibirTudo(exibir: boolean) {
        this.expandAll = exibir;

        if (this.expandAll) {
            this.empresas.forEach(e => {
                this.expandSetEmpresas.add(e.grupoEmpresarial_descricao);
            });

            this.obrigacoes.forEach(o => {
                this.expandSetObrigacoes.add(o.tipo);
            });
        } else {
            this.expandSetEmpresas.clear();
            this.expandSetObrigacoes.clear();
        }
    }

    modalSolicitarBaixa(visible: boolean) {
        this.formSolicitarBaixa.modalVisible = visible;
    }

    modalSolicitarBaixaDarfManual(visible: boolean) {
        this.formSolicitarBaixaDarf.formGroup.get('cnpj').setValue(this.empresaSelecionada.cnpj);
        this.formSolicitarBaixaDarf.modalVisible = visible;

        if (!visible) {
            this.formSolicitarBaixaDarf.formGroup.reset();
        }
    }

    solicitarBaixa() {
        this.loadingSolicitarBaixa = true;
        const obrigacaoId = [];

        this.checkedItems.forEach(id => {
            obrigacaoId.push(id);
        });

        const dtInicial = moment(this.formSolicitarBaixa.formGroup.get('competenciaInicial').value).format('YYYY-MM');
        const dtFinal = moment(this.formSolicitarBaixa.formGroup.get('competenciaFinal').value).format('YYYY-MM');

        if (this.formSolicitarBaixa.formGroup.valid) {
            this.service.solicitarBaixa({
                obrigacaoId,
                cnpj: this.empresaSelecionada.cnpj.replace(/\.|\-|\//g, ''),
                competenciaInicial: dtInicial,
                competenciaFinal: dtFinal,
            }).subscribe((response: any) => {
                this.toastrService.success(response.message);

                this.filtrarObrigacoes(this.empresaSelecionada);

                this.loadingSolicitarBaixa = false;
            }, e => {
                this.toastrService.error(e.error.message);
                this.loadingSolicitarBaixa = false;
            });

            this.modalSolicitarBaixa(false);
            this.formSolicitarBaixa.formGroup.reset();
        } else {

            for (const key in this.formSolicitarBaixa.formGroup.controls) {

                if (key) {

                    const campo = this.formSolicitarBaixa.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
            this.loadingSolicitarBaixa = false;
        }
    }

    solicitarBaixaDarfManual() {
        this.loadingSolicitarBaixaManual = true;

        if (this.formSolicitarBaixaDarf.formGroup.valid) {
            this.service.solicitarBaixaManualDarf(this.formSolicitarBaixaDarf.formGroup.value).subscribe((response: any) => {
                this.toastrService.success(response.message);

                this.filtrarObrigacoes(this.empresaSelecionada);

                this.loadingSolicitarBaixaManual = false;
            }, e => {
                this.toastrService.error(e.error.message);
                this.loadingSolicitarBaixaManual = false;
            });

            this.modalSolicitarBaixaDarfManual(false);
            this.formSolicitarBaixaDarf.formGroup.reset();
        } else {

            for (const key in this.formSolicitarBaixaDarf.formGroup.controls) {

                if (key) {
                    const campo = this.formSolicitarBaixaDarf.formGroup.get(key);
                    campo.markAsDirty();
                    campo.updateValueAndValidity();
                }
            }
            this.loadingSolicitarBaixaManual = false;
        }
    }

    toggleParamsCardTabs() {

        if (this.tabNumber === 0) {

            setTimeout(() => {
                this.column1Width = this.column1.nativeElement.offsetWidth;
                this.column2Width = this.column2.nativeElement.offsetWidth;
                this.column3Width = this.column3.nativeElement.offsetWidth;
                this.column4Width = this.column4.nativeElement.offsetWidth;
                this.column5Width = this.column5.nativeElement.offsetWidth;
                this.column6Width = this.column6.nativeElement.offsetWidth;
                this.column7Width = this.column7.nativeElement.offsetWidth;
                this.column8Width = this.column8.nativeElement.offsetWidth;
                this.column17Width = this.column17.nativeElement.offsetWidth;

                this.cdr.detectChanges();
            }, 100);

        } else if (this.tabNumber === 1) {

            setTimeout(() => {
                this.column9Width = this.column9.nativeElement.offsetWidth;
                this.column10Width = this.column10.nativeElement.offsetWidth;
                this.column11Width = this.column11.nativeElement.offsetWidth;
                this.column12Width = this.column12.nativeElement.offsetWidth;
                this.column13Width = this.column13.nativeElement.offsetWidth;
                this.column14Width = this.column14.nativeElement.offsetWidth;
                this.column15Width = this.column15.nativeElement.offsetWidth;
                this.column16Width = this.column16.nativeElement.offsetWidth;

                this.cdr.detectChanges();
            }, 100);

        }

    }

    pipeDataMesAno(data: string) {
        const mesAno = data.split('-');

        if (mesAno.length === 2) {
            return mesAno[1] + '/' + mesAno[0];
        } else if (mesAno.length === 1) {
            return mesAno[0];
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    menorQueUmMin(min: string, max: string): boolean {
        const ms = moment(max).diff(moment(min));

        if (ms < 60001) {
            return true;
        } else {
            return false;
        }
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
        this.formExportar.formGroup.reset();
    }

    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {

            this.loadingExportar = true;

            const tipo = this.formExportar.formGroup.value.tipo;

            if (this.tabNumber === 0) {
                this.service.exportExcelEmpresas(
                    this.formFiltrosEmpresas.formGroup.value, this.currentSearch, tipo, this.anoAtivo)
                    .subscribe((res) => {

                            this.loadingExportar = false;

                            this.toastService.success('Solicitação realizada com sucesso. Acompanhe na central de downloads.');

                            this.formExportar.modalVisible = false;

                            this.formExportar.formGroup.reset();

                            setTimeout(() => {
                                this.openTab('DownloadCenterComponent', '', {});
                            }, 1000);

                        }, err => {
                            this.loadingExportar = false;
                            this.toastService.error('Erro ao tentar exportar dados.');
                        }
                    );

            } else if (this.tabNumber === 1) {
                this.service.exportExelObrigacoes(
                    this.formFiltrosObrigacoes.formGroup.value, this.currentSearch, tipo, this.empresaSelecionada.empresa_id
                ).subscribe((res) => {

                        this.loadingExportar = false;

                        this.toastService.success('Solicitação realizada com sucesso. Acompanhe na central de downloads.');

                        this.formExportar.modalVisible = false;

                        this.formExportar.formGroup.reset();

                        setTimeout(() => {
                            this.openTab('DownloadCenterComponent', '', {});
                        }, 1000);

                    }, err => {
                        this.loadingExportar = false;
                        this.toastService.error('Erro ao tentar exportar dados.');
                    }
                );
            }

        } else {
            for (const key in this.formExportar.formGroup.controls) {

                if (key) {

                    const campo = this.formExportar.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class AnalisesXMLSaidasDetalhesService extends AbstractService<any> {

    id = '';
    url = environment.apiUrl;

    constructor(http: HttpClient) {
        super(http, '/notas/analise-nota-xml-saida/analise', {});
    }


    retornaCabecalho(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise/${id}/cabecalho`);
    }

    retornaItensNota(analiseId, notaId, filtros, search: string = null): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise/${analiseId}/nota/${notaId}/tabela${queryStr}`);

    }

    montaUrl(id: string) {
        this.url = environment.apiUrl + `/notas/analise-nota-xml-saida/analise/${id}/tabela`;
    }

    exportExcel(id: string, filtros: any, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise/${id}/tabela/1${queryStr}`,
            {responseType: 'blob'});

    }

    retornaCombosFiltros(analiseId): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise/${analiseId}/filtro/combos`);
    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    adicionarObservacao(analiseId, notaId, observacao): Observable<any> {

        const body = {
            observacao
        };

        return this.http.post(`${environment.apiUrl}/notas/analise-nota-xml-saida/analise/${analiseId}/nota/${notaId}/observacao`, body);
    }

}

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Obrigações Sugeridas"></nz-page-header>
<nz-card>
    <div style="border-bottom: 1px rgba(0,0,0,0.2) solid;">
        <nz-steps nzDirection="vertical">
            <nz-step nzTitle="Selecionar Empresas:"
                     nzSubtitle="Selecione as empresas onde serão cadastradas as Obrigações."
                     nzStatus="{{steps[0].step}}"
                     nzIcon="{{steps[0].icon}}"></nz-step>
            <nz-step *ngIf="steps[0].step === 'finish'"
                     nzTitle="Selecionar Módulos:"
                     nzSubtitle="Selecione os módulos que deseja cadastrar as Obrigações."
                     nzStatus="{{steps[1].step}}"
                     nzIcon="{{steps[1].icon}}"></nz-step>
            <nz-step *ngIf="steps[1].step === 'finish'"
                     nzTitle="Selecionar Obrigações:"
                     nzSubtitle="Selecione as Obrigações a serem cadastradas."
                     nzStatus="{{steps[2].step}}"
                     nzIcon="{{steps[2].icon}}"></nz-step>
            <nz-step *ngIf="steps[2].step === 'finish'"
                     nzTitle="Cadastro Finalizado"
                     nzStatus="{{steps[3].step}}"
                     nzIcon="{{steps[3].icon}}"></nz-step>
        </nz-steps>
    </div>
    <form nz-form [nzNoColon]="false" [formGroup]="formStepsObrigacoes.formGroup">
        <nz-content *ngIf="steps[0].step !== 'finish'" style="margin: 20px 0 40px 0;">
            <nz-row nzGutter="24" style="margin-bottom: 20px;">
                <nz-col nzSpan="24">
                    <nz-radio-group [(ngModel)]="radioValueStepZero" [ngModelOptions]="{standalone: true}">
                        <label nz-radio nzValue="todas">Todas as Empresas</label>
                        <label nz-radio nzValue="algumas">Algumas Empresas</label>
                    </nz-radio-group>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="radioValueStepZero === 'algumas'">
                <nz-col nzSpan="24">
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableEmpresas
                              [nzData]="items"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination?.total"
                              [nzPageSize]="pagination?.per_page"
                              [nzPageIndex]="pagination?.current_page"
                              (nzQueryParams)="retornaObrigacoes($event)"
                              [nzShowTotal]="rangeTemplateEmpresas"
                              [nzLoading]="loading"
                              class="table-small">
                        <thead>
                        <tr>
                            <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                                [nzIndeterminate]="indeterminate">
                            </th>
                            <th nzAlign="center" nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                            <th nzAlign="center" nzColumnKey="nome" [nzSortFn]="true">Empresa</th>
                            <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                            <th nzAlign="center" nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true">Grupo</th>
                            <th nzAlign="center" nzColumnKey="estado" [nzSortFn]="true">UF</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let arquivo of tableEmpresas.data">
                            <td nzAlign="center" (nzCheckedChange)="onItemChecked(arquivo.id, $event)"
                                [nzChecked]="checkedItems.has(arquivo.id)"
                                [nzDisabled]="arquivo.disabled">
                            </td>
                            <td nzAlign="center">{{modoDemo(arquivo?.codigo, 'total')}}</td>
                            <td style="width: 300px;">
                                {{modoDemo(arquivo?.nome, 'empresaCodigoNome')}}
                            </td>
                            <td nzAlign="center">
                                {{modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj')}}
                            </td>
                            <td nzAlign="center">{{arquivo?.grupoEmpresarial_descricao}}</td>
                            <td nzAlign="center">{{arquivo?.estado}}</td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-col>
            </nz-row>
        </nz-content>
        <nz-content *ngIf="steps[0].step === 'finish' && steps[1].step !== 'finish'" style="margin: 40px 0 40px 0;">
            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="selecionarModulos($event)">
                        <div nz-row>
                            <div nz-col nzSpan="8"><label nz-checkbox nzValue="calendar">Calendar</label></div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="8"><label nz-checkbox nzValue="check">Check</label></div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="8"><label nz-checkbox nzValue="compliance">Compliance</label></div>
                        </div>
                    </nz-checkbox-wrapper>
                </nz-col>
            </nz-row>
        </nz-content>
        <nz-content *ngIf="steps[1].step === 'finish' && steps[2].step !== 'finish'" style="margin: 20px 0 40px 0;">
            <nz-row nzGutter="24" style="margin-bottom: 20px;">
                <nz-col nzSpan="24">
                    <nz-radio-group [(ngModel)]="radioValueStepOne" [ngModelOptions]="{standalone: true}">
                        <label nz-radio nzValue="todas">Todas as Obrigações</label>
                        <label nz-radio nzValue="algumas">Algumas Obrigações</label>
                    </nz-radio-group>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="radioValueStepOne === 'algumas'">
                <nz-col nzSpan="24">
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableObrigacoes
                              [nzData]="items"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination?.total"
                              [nzPageSize]="pagination?.per_page"
                              [nzPageIndex]="pagination?.current_page"
                              (nzQueryParams)="retornaObrigacoesEmpresas($event)"
                              [nzShowTotal]="rangeTemplateObrigacao"
                              [nzLoading]="loading"
                              class="table-small">
                        <thead>
                        <tr>
                            <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                                [nzIndeterminate]="indeterminate">
                            </th>
                            <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let arquivo of tableObrigacoes.data">
                            <td nzLeft (nzCheckedChange)="onItemChecked(arquivo.id, $event)"
                                [nzChecked]="checkedItems.has(arquivo.id)"
                                [nzDisabled]="arquivo.disabled">
                            </td>
                            <td nzAlign="center">{{arquivo?.descricao}}</td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplateObrigacao let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-col>
            </nz-row>
        </nz-content>
    </form>
    <nz-content>
        <nz-row nzGutter="24">
            <nz-col nzSpan="24">
                <div class="d-flex"
                     style="border-top: 1px rgba(0,0,0,0.2) solid; padding-top: 20px;"
                     [ngClass]="[stepNumber === 0 ? 'justify-content-end' : '', stepNumber > 0 ? 'justify-content-between' : '']">
                    <button nz-button nzType="default"
                            *ngIf="stepNumber !== 0 && stepNumber < 3"
                            (click)="changeStep(this.stepNumber, 'prev')">
                        <i nz-icon nzType="double-left" nzTheme="outline"></i>
                        Retornar
                    </button>
                    <button nz-button nzType="primary"
                            *ngIf="stepNumber < 3"
                            (click)="changeStep(this.stepNumber, 'next')" [nzLoading]="stepsLoading">
                        Avançar
                        <i nz-icon nzType="double-right" nzTheme="outline"></i>
                    </button>
                </div>
            </nz-col>
        </nz-row>
    </nz-content>
</nz-card>

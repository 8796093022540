export class TableTitles {

    constructor(
        public name: string,
        public field: string = name,
        public sortable: boolean = false,
        public order: number = null
    ) {
    }

    getOrder() {

        switch (this.order) {
            case 0:
                return 'ASC';
            case 1:
                return `DESC`;
            default:
                return null;
        }

    }

    nextOrder() {

        switch (this.order) {
            case 0:
                this.order = 1;
                break;
            case 1:
                this.order = null;
                break;
            default:
                this.order = 0;
        }

    }
}

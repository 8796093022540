import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    LOCALE_ID, NgZone,
    ViewChild,
} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzCarouselComponent} from 'ng-zorro-antd/carousel';
import * as Highcharts from 'highcharts';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {ComplianceTributosService} from './complianceTributos.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {ToastrService} from 'ngx-toastr';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './complianceTributos.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./complianceTributos.component.scss']
})

export class ComplianceTributosComponent extends AbstractListTable<any> implements AfterViewInit {
    @Input() data;

    cardCompetenciaAtivo: number;
    collapseCard = false;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectIe = [];

    cardsAnos: any = [];
    carregando: true;
    empresaNome: '';
    empresaCnpj: '';

    formFiltrar: FormStack;

    arrayTableObrigacoesAcessoriasOficiais: any = [{
        cor: '',
        minMax: 0,
        obrigacao: 'COFINS',
        obrigacoesMax: 0,
        obrigacoesMin: 0,
        pagamentos: 0,
        pagtoMax: 0,
        qtde: 1
    }];

    arrayTableObrigacoesPrincipaisPagamentos: any = [
        {
            cor: '#ff4961',
            obrigacao: 'COFINS',
            pagamentos: 0,
            pagamentosMax: 0,
            qtde: 1
        }
    ];

    anoAtivo: number = null;

    arraySeriesGrafico = [];

    minMaxObrigacoesOficiais = 0;
    minObrigacoesOficiais = 0;
    maxObrigacoesOficiais = 0;

    pagamentosObrigacoes = 0;
    pagtoMaxObrigacoes = 0;

    tabNumber = 0;

    arrayImpostos = ['', ''];

    chartOk = false;

    arrayColors = [
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#2196f3',
        '#00bcd4',
        '#009688',
        '#ffeb3b',
        '#ffc107',
        '#607d8b'
    ];


    Highcharts: typeof Highcharts = Highcharts;
    HighchartsPie: typeof Highcharts = Highcharts;

    chartPieOptions: Highcharts.Options;

    cardTributosLoading = true;
    status = false;
    updateFlag = false;
    arrayTableObrigacoesEvolucao: any = [];
    idsObrigacoesEvolucao = [];
    expandSet = new Set<number>();

    cardLoadingObrigacoesAcessorias = true;
    cardLoadingObrigacoesPrincipais = true;
    cardLoadingTabs = true;
    limiteTentativas = 0;

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    isVisible = false;

    empresaSelecionada = '';

    qtdFiltrosAtivos = 0;
    totalRegistros = 0;

    obrigacaoSelecionada;

    @ViewChild(NzCarouselComponent, {static: false}) myCarousel!: NzCarouselComponent;

    valorTolerancia = 10;
    tipoTolerancia = 'federal';

    expandAll = false;

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private complianceTributos: ComplianceTributosService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private zone: NgZone
    ) {

        super(complianceTributos, null, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                ie: [null, null],
                imposto: [null, null],
            })
        };

        this.complianceTributos.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
            this.arraySelectIe = retorno.empresaIe;

        });

        this.complianceTributos = complianceTributos;

    }

    largurasTable() {

        let verifyWidth = false;

        if (this.column1Width !== this.column1.nativeElement.offsetWidth) {
            this.column1Width = this.column1.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column2Width !== this.column2.nativeElement.offsetWidth) {
            this.column2Width = this.column2.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column3Width !== this.column3.nativeElement.offsetWidth) {
            this.column3Width = this.column3.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column4Width !== this.column4.nativeElement.offsetWidth) {
            this.column4Width = this.column4.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column5Width !== this.column5.nativeElement.offsetWidth) {
            this.column5Width = this.column5.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column6Width !== this.column6.nativeElement.offsetWidth) {
            this.column6Width = this.column6.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column7Width !== this.column7.nativeElement.offsetWidth) {
            this.column7Width = this.column7.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column8Width !== this.column8.nativeElement.offsetWidth) {
            this.column8Width = this.column8.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column9Width !== this.column9.nativeElement.offsetWidth) {
            this.column9Width = this.column9.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column10Width !== this.column10.nativeElement.offsetWidth) {
            this.column10Width = this.column10.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column11Width !== this.column11.nativeElement.offsetWidth) {
            this.column11Width = this.column11.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        this.cdr.detectChanges();

        if (verifyWidth === true) {

            /*if (this.limiteTentativas === 3) {
                this.limiteTentativas = 0;
                return;
            }*/

            this.limiteTentativas++;

            setTimeout(() => {
                this.largurasTable();
            }, 100);

        }

        this.dataExport = {
            url: `/compliance/tributos/retorna-obrigacoes-grupo/${this.anoAtivo}/exportar`,
            filtros: this.formFiltrar.formGroup.value,
            name: 'Obrigações',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    ngAfterViewInit() {

        // CARDS ANOS
        this.complianceTributos.retornaAnos().subscribe((response: any) => {

            for (const mes of Object.entries(response.dados)) {
                this.cardsAnos.push(mes[1]);
            }

            if (this.cardsAnos.length > 0) {

                this.anoAtivo = this.cardsAnos[this.cardsAnos.length - 1].ano;

                setTimeout(() => {
                    this.cardCompetenciaAtivo = this.cardsAnos.length - 1;
                    this.collapseCard = true;

                    this.retornaObrigacoes(this.anoAtivo);

                }, 500);

            } else {

                this.toastrService.warning('Não há dados para exibição.');

            }


        });

    }

    handleCancel(): void {
        this.isVisible = false;
    }

    retornaObrigacoes(ano: number, recarregarGrafico?: boolean): void {

        if (recarregarGrafico === undefined) {
            this.chartOk = false;
        }

        this.arraySeriesGrafico = [];
        this.anoAtivo = ano;

        // Obrigações Acessórias (Oficiais)
        this.complianceTributos.retornaObrigacoesAcessorias(ano).subscribe((retorno: any) => {

            this.zone.run(() => {
                this.arrayTableObrigacoesAcessoriasOficiais = [];
                this.arrayImpostos = [];

                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesAcessoriasOficiais.push(dado[1]);
                    const obj: any = dado[1];
                    this.arrayImpostos.push(obj.obrigacao);
                }
            });

            const imposto = this.arrayImpostos[0];
            this.tabNumber = 0;

            this.changeTabs();

            // Totais
            this.minMaxObrigacoesOficiais = retorno.totais.totalMinMax;
            this.minObrigacoesOficiais = retorno.totais.totalMin;
            this.maxObrigacoesOficiais = retorno.totais.totalMax;


            this.cardLoadingObrigacoesAcessorias = false;
            this.cdr.detectChanges();

        });

        // Obrigações Principais - Pagamentos
        this.complianceTributos.retornaObrigacoesPagamentos(this.anoAtivo).subscribe((retorno: any) => {
            // Gráfico
            this.arraySeriesGrafico = [];
            for (const dado of Object.entries(retorno.serie)) {
                this.arraySeriesGrafico.push(dado);
            }

            // tslint:disable-next-line:no-non-null-assertion
            this.pagamentosObrigacoes = retorno.totais!.pagamentosTotal.toFixed();
            // tslint:disable-next-line:no-non-null-assertion
            this.pagtoMaxObrigacoes = retorno.totais!.pagamentosMaxTotal.toFixed();

            this.zone.run(() => {
                this.arrayTableObrigacoesPrincipaisPagamentos = [];
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesPrincipaisPagamentos.push(dado[1]);
                }

                this.arrayTableObrigacoesPrincipaisPagamentos.forEach((pagamento, index) => {
                    pagamento.cor = this.arrayColors[index];
                });
            });

            this.cardLoadingObrigacoesPrincipais = false;
            this.cdr.detectChanges();

            this.chartOk = true;

            if (this.chartOk && recarregarGrafico === undefined) {
                setTimeout(() => {
                    this.loadChartData();
                }, 1000);
            }

            if (recarregarGrafico) {
                this.loadChartData();
            }

            this.cdr.detectChanges();
        });


    }


    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    formatOne = (percent: number) => `${percent}%`;


    clickEvent() {
        this.status = !this.status;
    }

    loadChartData(): void {

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: this.arrayColors,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: [{
                type: 'pie',
                name: 'Valor',
                data: this.arraySeriesGrafico
            }]
        };

        this.cdr.detectChanges();

        this.updateFlag = true;

    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined)) {

            this.cardLoadingObrigacoesPrincipais = true;
            this.cardLoadingObrigacoesAcessorias = true;
            this.cardLoadingTabs = true;

            this.cardCompetenciaAtivo = event.clickedIndex;

            const anoAtivo = this.cardsAnos[event.clickedIndex].ano;

            this.retornaObrigacoes(anoAtivo, true);

        }

    }

    exibirTudo(exibir: boolean) {
        this.expandAll = exibir;

        if (this.expandAll) {
            this.idsObrigacoesEvolucao.forEach(id => {
                this.expandSet.add(id);
            });
        } else {
            this.idsObrigacoesEvolucao.forEach(id => {
                this.expandSet.delete(id);
            });
        }
    }

    onExpandChange(id: number, checked: boolean): void {
        this.zone.run(() => {
            if (checked) {
                this.expandSet.add(id);
            } else {
                this.expandSet.delete(id);
            }
        });

        if (this.expandSet.size === 0) {
            this.expandAll = false;
        } else {
            this.expandAll = true;
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);

    }

    changeTabs() {

        if (this.tabNumber === 4 || this.tabNumber === 5) {
            this.valorTolerancia = 1;
            this.tipoTolerancia = 'estadual';
        } else {
            this.valorTolerancia = 10;
            this.tipoTolerancia = 'federal';
        }

        if (this.cardLoadingTabs === false) {
            this.cardLoadingTabs = true;
        }

        this.calculaBadgeFiltros();

        const imposto = this.arrayImpostos[this.tabNumber];

        this.obrigacaoSelecionada = imposto;

        const ano = this.anoAtivo;

        // Impostos por Grupo
        const filtros = this.formFiltrar.formGroup.value;

        this.cardTributosLoading = true;

        this.complianceTributos.retornaObrigacoesGrupo(ano, imposto, filtros).subscribe((retorno: any) => {

            this.zone.run(() => {
                this.arrayTableObrigacoesEvolucao = retorno.dados?.data;

                if (this.arrayTableObrigacoesEvolucao) {
                    for (const [index, item] of this.arrayTableObrigacoesEvolucao.entries()) {
                        this.idsObrigacoesEvolucao.push(item.id);
                        if (item.unidades.length < 15) {
                            this.expandSet.add(item.id);
                        }
                    }
                }

                if (this.expandSet.size === 0) {
                    this.expandAll = false;
                } else {
                    this.expandAll = true;
                }

                this.totalRegistros = 0;
                this.arrayTableObrigacoesEvolucao?.forEach(value => {
                    this.totalRegistros += value.unidades.length;
                });
            });

            this.cardLoadingTabs = false;

            setTimeout(() => {
                this.largurasTable();
            }, 300);

        }, (res) => {
            this.cardLoadingTabs = false;
        });

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 2;
        }
        if (this.formFiltrar.formGroup.value.ie) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.cardsAnos.length - 1) {
                    this.cardCompetenciaAtivo = this.cardsAnos.length - 1;
                }

                break;

        }

        this.cardLoadingObrigacoesPrincipais = true;
        this.cardLoadingObrigacoesAcessorias = true;
        this.cardLoadingTabs = true;

        const anoAtivo = this.cardsAnos[this.cardCompetenciaAtivo].ano;

        this.retornaObrigacoes(anoAtivo);

    }

    abrirModal(formulario: FormStack): void {

        formulario.modalVisible = true;

        this.cdr.detectChanges();

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrar(): any {

        this.changeTabs();

        this.formFiltrar.modalVisible = false;
    }

    modalExportar(visible: boolean): void {
        this.formFiltrar.formGroup.get('imposto').setValue(this.arrayImpostos[this.tabNumber]);
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.dataExport.name = this.arrayImpostos[this.tabNumber] + ' em ' + this.anoAtivo;

        this.componentExport.visible = visible;
    }

    somaValores(arr: number[]) {

        const result: number = arr.reduce((partialSum, a) => partialSum + a, 0);

        return result;
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {CredenciaisAcessoService} from './credenciais-acesso.service';
import {DataService} from '@services/data.service';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {Helpers} from '../../../core/helpers';
import {environment} from "../../../../environments/environment";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-credenciais-acesso',
    templateUrl: './credenciais-acesso.component.html',
    styleUrls: ['./credenciais-acesso.component.scss']
})
export class CadastrosCredenciaisAcessoComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    modalFiltrarVisible = false;

    formFiltrar: FormStack;

    comboUFs: any = [];
    comboGrupos: any = [];
    arraySelectCnpj = [];
    arraySelectEmpresa = [];

    credenciais: any[] = [];

    qtdFiltrosAtivos = 0;

    formExportar: FormStack;
    formExportarProcuracoes: FormStack;
    formAcessoEcac: FormStack;

    loadings = {
        exportar: false,
        upload: false,
        downloadModelo: false,
        acessoEcac: false
    };

    formProcuracoesECAC: FormStack;

    erro = false;
    mensagem = '';

    usuarioLogado: any;
    optionsEmpresas = [];
    modalImportarPlanilhaAE = false;
    fileListAE: NzUploadFile[] = [];

    termosVisible = false;
    termosAceitos = false;
    termosCompletosVisible = false;

    responsaveis = [];

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private credenciaisAcessoService: CredenciaisAcessoService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private tabService: TabService,
        private dataService: DataService) {
        super(usuarioService, Usuario, toastService);


        this.credenciaisAcessoService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });
        this.credenciaisAcessoService.retornaComboGrupos().subscribe((retorno: any) => {
            retorno.grupoEmpresarial.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboGrupos = retorno.grupoEmpresarial;
        });
        this.credenciaisAcessoService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                codigo: [null, null],
                empresa_id: [null, null],
                grupo: [null, null],
                uf: [null, null],
                emissor: [null, null],
                validadeInicio: [null, null],
                validadeFim: [null, null],
                dataprev: [null, null],
                temCertificado: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formProcuracoesECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
            })
        };

        this.formExportarProcuracoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formAcessoEcac = {
            modalVisible: false,
            formGroup: this.fb.group({
                ids: [[], Validators.required],
                procurador: ['', Validators.required],
                statusAcessoEcac: [1, Validators.required],
            })
        };
    }

    ngOnInit() {

        if (this.data.validadeInicio) {
            this.formFiltrar.formGroup.get('validadeInicio').setValue(this.data.validadeInicio);
        }

        if (this.data.validadeFim) {
            this.formFiltrar.formGroup.get('validadeFim').setValue(this.data.validadeFim);
        }

        // this.buildExportUrl();
        this.getPerfis();

        this.dataService.currentUser.subscribe((data) => {
            this.usuarioLogado = data;
        });

        this.credenciaisAcessoService.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.restornaResponsaveis();

    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {

        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        if (this.checkedItems.size < this.items.length) {
            this.checked = false;
        }
    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: any, comTitulo: boolean = true) {

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        if (comTitulo) {
            const cnpj = data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            const newTab = new Tab(component.name, cnpj + ' - ' + component.title, url, component.urlType, data);
            this.tabService.closeAndAddTab(newTab);
        } else {
            const newTab = new Tab(component.name, component.title, url, component.urlType, data);
            this.tabService.closeAndAddTab(newTab);
        }
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosUsuario = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosUsuario[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.usuarioService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/usuario/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.getPerfis();

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });


        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {
        this.checkedItems.clear();

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }
        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                if (chave === 'validadeInicio' || chave === 'validadeFim') {
                    valor = Helpers.formatDateDb(valor.toString());
                }
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.loading = true;

        this.abstractService.listToTable(this.currentParams, this.currentSearch, this.credenciaisAcessoService.baseUrl)
            .subscribe((response) => {

                this.items = [];

                this.items = response?.data || response;

                this.items.forEach(value => {

                    if (value.validade) {

                        value.dias = this.retornaDias(value.validade, new Date());

                        if (value.dias > 30) {
                            value.statusVencimento = 2;
                        }

                        if (value.dias > 0 && value.dias < 31) {
                            value.statusVencimento = 1;
                        }

                        if (value.dias < 0) {
                            value.statusVencimento = 0;
                            value.dias = Math.abs(value.dias);
                        }

                    }

                    switch (value.tipo) {

                        case 'cnpj': {
                            value.tipo_descricao = 'eCNPJ';
                            break;
                        }
                        case 'nfe': {
                            value.tipo_descricao = 'eNFE';
                            break;
                        }
                        case 'cpf': {
                            value.tipo_descricao = 'eCPF';
                            break;
                        }

                    }

                });

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loading = false;

            });

    }

    retornaDias(dataOne, dataTwo) {
        dataOne = new Date(dataOne);
        const difference = dataOne.getTime() - dataTwo.getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {

                if (chave === 'validadeInicio' || chave === 'validadeFim') {
                    valor = Helpers.formatDateDb(valor.toString());
                }
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    modalExportarProcuracoes(visible: boolean): void {
        this.formExportarProcuracoes.modalVisible = visible;
    }

    confirmaExportar(): void {

        this.loadings.exportar = true;
        const tipo = this.formExportar.formGroup.value.tipo;

        this.credenciaisAcessoService.exportExcel(
            this.formFiltrar.formGroup.value, 'empresas', tipo, this.currentSearch
        ).subscribe((res) => {

            if (this.formExportar.formGroup.value.tipo) {

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                const name = 'Credenciais de Acesso - Empresas';

                fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                this.formExportar.modalVisible = false;
            }
            this.loadings.exportar = false;

        }, (res) => {
            this.loadings.exportar = false;
        });
    }

    confirmaExportarProcuracoes(): void {


        this.loadings.exportar = true;
        const tipo = this.formExportarProcuracoes.formGroup.value.tipo;

        this.credenciaisAcessoService.exportExcel(this.formFiltrar.formGroup.value, 'procuracoes', tipo).subscribe((res) => {

            if (this.formExportarProcuracoes.formGroup.value.tipo) {

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                const name = 'Credenciais de Acesso - Procurações';

                fileSaver.saveAs(blob, name + '.' + this.formExportarProcuracoes.formGroup.value.tipo);

                this.formExportarProcuracoes.modalVisible = false;
            }
            this.loadings.exportar = false;

        }, (res) => {
            this.loadings.exportar = false;
        });
    }

    modal(form: FormStack, visible: boolean): void {
        form.modalVisible = visible;
        if (!visible) {
            this.formProcuracoesECAC.formGroup.reset();
        }
    }

    insertProcuracoes(id = null): void {

        this.validaFormProcuracoes();

        const dados = this.formProcuracoesECAC.formGroup.value;

        const ids = id ? [id] : Array.from(this.checkedItems);

        if (!this.erro) {

            this.credenciaisAcessoService.insertProcuracoes(ids, dados).subscribe((response: any) => {

                    this.toastrService.success('Procuração E-CAC cadastrada com sucesso!');
                    this.formProcuracoesECAC.modalVisible = false;
                    this.queryTable(this.currentParams, this.currentSearch);

                },
                (response) => {
                    this.toastrService.error(response.error.message);
                });

            this.erro = false;
            this.mensagem = '';

        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    validaFormProcuracoes(): void {
        if (!this.formProcuracoesECAC.formGroup.value.pessoa) {
            this.erro = true;
            this.mensagem = 'Campo Pessoa obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.documento) {
            this.erro = true;
            this.mensagem = 'Campo Documento obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Nome obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Início obrigatório';
        }

        if (this.formProcuracoesECAC.formGroup.value.dataInicio) {
            // tslint:disable-next-line:max-line-length
            this.formProcuracoesECAC.formGroup.value.dataInicio = Helpers.formatDateDb(this.formProcuracoesECAC.formGroup.value.dataInicio);
        }

    }

    maskPessoa(form: FormStack): string {
        if (form.formGroup.value.pessoa === 'F') {
            return '000.000.000-00';
        } else {
            return '00.000.000/0000-00';
        }
    }


    statusVencimentoProcurador(dataStr: string) {

        if (dataStr) {
            const dias = this.retornaDias(dataStr, new Date());

            if (dias > 30) {
                return 2;
            } else if (dias > 0 && dias < 31) {
                return 1;
            } else if (dias < 0) {
                return 0;
            }
        } else {
            return 2;
        }

    }

    diasVencimentoProcurador(dataStr: any) {
        // tslint:disable-next-line:max-line-length
        return this.retornaDias(dataStr, new Date()) > 0 ? this.retornaDias(dataStr, new Date()) : Math.abs(this.retornaDias(dataStr, new Date()));
    }

    modalImportarAE(visible: boolean): void {
        this.modalImportarPlanilhaAE = visible;
    }

    downloadModelo(modelo: string): void {
        switch (modelo) {
            case 'AE': {
                this.loadings.downloadModelo = true;
                this.credenciaisAcessoService.exportExcel({}, 'modeloImportarAE', null).subscribe((res) => {
                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
                    fileSaver.saveAs(blob, 'Modelo Acessos Estaduais' + '.xlsx');
                    this.loadings.downloadModelo = false;
                }, (res) => {
                    this.loadings.downloadModelo = false;
                });
                break;
            }
        }
    }

    confirmarImportarAE(): void {

        this.loadings.upload = true;

        const formData = new FormData();

        this.fileListAE.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        this.credenciaisAcessoService.uploadPlanilhaAE(formData).subscribe((response: any) => {

                this.loadings.upload = false;
                this.toastrService.success(response.message);

                this.fileListAE = [];

                this.modalImportarAE(false);

                this.queryTable(this.currentParams, this.currentSearch);

            },
            (response) => {
                this.loadings.upload = false;
                this.toastrService.error(response.error.message);
            });

    }

    beforeUploadAE = (file: NzUploadFile): boolean => {

        this.fileListAE = [];
        this.fileListAE.push(file);
        return false;
    }

    ativarAcessoIntegradoECACLote() {

        this.loadings.acessoEcac = true;
        this.formAcessoEcac.formGroup.get('ids').setValue(Array.from(this.checkedItems));

        if (this.formAcessoEcac.formGroup.valid) {
            this.credenciaisAcessoService.ativarAcessoIntegradoECAC(this.formAcessoEcac.formGroup.value).subscribe((response: any) => {
                    this.toastrService.success('Ação realizada com sucesso!');
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.loadings.acessoEcac = false;
                    this.modalProcurador();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadings.acessoEcac = false;
                });

        } else {
            this.loadings.acessoEcac = false;

            Object.values(this.formAcessoEcac.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    restornaResponsaveis() {

        this.credenciaisAcessoService.retornarResponsaveis().subscribe((response: any) => {
                this.responsaveis = response.data;
            },
            (response) => {
                this.toastrService.error(response.error.message);
            });

    }

    modalProcurador() {

        this.formAcessoEcac.modalVisible = !this.formAcessoEcac.modalVisible;

        if (!this.formAcessoEcac.modalVisible) {
            this.termosAceitos = false;
            this.formAcessoEcac.formGroup.reset();
        } else {
            this.modalAcessoIntegradoEcac();
        }
    }

    modalAcessoIntegradoEcac() {
        this.termosVisible = !this.termosVisible;

        if (!this.termosVisible) {
            this.termosAceitos = false;
            this.termosCompletosVisible = false;
        }
    }

    verTermosCompletos() {
        this.termosCompletosVisible = !this.termosCompletosVisible;
    }

    exportarDados() {


        const params = this.currentParams;

        const url = `${environment.apiUrl}/exportador`;

        this.credenciaisAcessoService.exportarDados(params, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('/downloadCenter', '', {}, false);

        }, (res) => {
            this.toastService.error(res.error.message);
        });
    }
}

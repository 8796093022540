import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class BaixasAutomaticasDetalhesComponentService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/drive/baixas-automaticas-detalhes', {});
    }

    table(params: any, procurar): Observable<any> {

        const filtros = params && params.filter ? params.filter : [];

        Object.entries(filtros).forEach(([key, value]) => {
            if (value === null) {
                delete filtros[key];
            } else if (filtros[key] instanceof Date) {
                filtros[key] = Helpers.dataToFilter(filtros[key]);
            }
        });

        if (procurar) {
            filtros.procurar = procurar;
        }

        const order: any = params && params.sort ? this.montaOrder(params.sort) : [];

        if (order.ordenar && order.sentido) {
            filtros.ordenar = order.ordenar;
            filtros.sentido = order.sentido;
        }

        if (params && params.pageIndex) {
            filtros.pagina = params.pageIndex;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/obrigacao-empresa-consulta/detalhe-baixa${queryStr}`);

    }

    retornaComboEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaComboObrigacoes(categoria = null): Observable<any> {

        const param = categoria ? `?categoria=${categoria}` : '';

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select` + param);
    }

    retornaComboCategorias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    montaOrder(parametros): any {
        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'ascend' : 'descend'
                };
            }
        });

        return order;

    }

}

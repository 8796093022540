import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogComponent } from './pages/log.component';
import { ListLogComponent } from './pages/list/list-log.component';


const routes: Routes = [{
    path: '',
    component: LogComponent,
    children: [
        {
            path: 'listar',
            component: ListLogComponent
        },
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LogRoutingModule {
}

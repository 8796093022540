import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzCardModule} from "ng-zorro-antd/card";
import {HighchartsChartModule} from "highcharts-angular";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {UiModule} from "../../ui.module";
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";



@NgModule({
  declarations: [
    DashboardComponent
  ],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        HighchartsChartModule,
        NzButtonModule,
        NzIconModule,
        FormsModule,
        NzDatePickerModule,
        NzFormModule,
        NzGridModule,
        NzModalModule,
        NzSelectModule,
        ReactiveFormsModule,
        NzBadgeModule,
        NzInputModule,
        NzRadioModule,
        UiModule,
        NzSkeletonModule
    ]
})
export class DashboardModule { }

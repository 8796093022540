import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class XmlXSpedService {

    constructor(protected http: HttpClient) {
    }


    retornaComboObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes?categoria=spedicms`);
    }

    listar(filtros): Observable<any> {

        let params = '?';

        filtros.forEach(f => {
            params += f.key + '=' + f.value + '&';
        });

        if (params[params.length - 1] === '&') {
            params = params.substring(0, params.length - 1);
        }

        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/desconsideracoes${params}`);
    }


    setRegra(data, id): Observable<any> {
        delete data.tipoDoc;
        delete data.inputE;
        delete data.inputD;

        const url = id ? `/compliance/notas/analise/desconsideracoes/${id}` :
            '/compliance/notas/analise/desconsideracoes';
        return this.http.post(`${environment.apiUrl}${url}`, data);
    }

    getObrigacoes(regraId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/desconsideracoes/${regraId}`);
    }

    remover(regraId): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/compliance/notas/analise/desconsideracoes/${regraId}`);
    }
}

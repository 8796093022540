<nz-card nzTitle="Menu do Sistema">
    <nz-tree #menuTreeNodes [nzData]="menuTree"
             (nzClick)="handlerTree($event)"
             [nzTreeTemplate]="menuTemplate">
    </nz-tree>
    <button id="new-item" nz-button nzType="link" nzShape="round" (click)="add()">
        <em nz-icon nzType="plus-circle" nzTheme="outline"></em>Novo nó raiz
    </button>
</nz-card>
<ng-template #menuTemplate let-node let-origin="origin">
      <span class="custom-node">
        <span *ngIf="!node.isLeaf" class="container-action-buttons">
         <em [class]="node?.icon"></em>
          <span class="folder-name">{{ node.title }}</span>
            <span (click)="edit(node)" class="file-desc"><em class="icon-pen fas fa-pen"></em></span>
          <span nz-dropdown nzTrigger="click" [nzDropdownMenu]="options" class="folder-desc add-item">
              <em nz-icon nzType="plus-circle"></em></span>
          <span class="file-desc remove-item"><em nz-icon nzType="minus-circle"
                                                  (click)="openRemoveModal(node)"></em></span>
        </span>
        <span *ngIf="node.isLeaf" class="container-action-buttons">
              <em [class]="node?.icon"></em>
          <span class="file-name">{{ node.title }}</span>
            <span (click)="edit(node)" class="file-desc"><em class="icon-pen fas fa-pen"></em></span>
          <span nz-dropdown nzTrigger="click" [nzDropdownMenu]="options" class="file-desc add-item text-dange"><em
                  nz-icon nzType="plus-circle"></em></span>
          <span class="file-desc remove-item"><em nz-icon nzType="minus-circle"
                                                  (click)="openRemoveModal(node)"></em></span>
        </span>
      </span>
    <nz-dropdown-menu #options="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="add(node)">Adicionar nó</li>
        </ul>
    </nz-dropdown-menu>
</ng-template>

<nz-modal [nzClosable]="false"
          [(nzVisible)]="showAddModal"
          [nzTitle]="modalAddTitle"
          [nzContent]="modalAddContent"
          [nzFooter]="modalAddFooter"
          [nzWidth]="'600px'"
          (nzOnCancel)="cancel()">
    <ng-template #modalAddTitle>Adicionar novo item</ng-template>

    <ng-template #modalAddContent>
        <form nz-form [formGroup]="formNewMenu">
            <nz-form-item *ngIf="hasParent">
                <nz-form-label [nzSpan]="6" nzFor="noPai" nzRequired>Nó pai</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear formControlName="parentId" nzPlaceHolder="Selecione">
                        <nz-option [nzHide]="hiddenOwnNode(menu)" *ngFor="let menu of menuList" [nzValue]="menu?.id"
                                   [nzLabel]="menu?.descricao"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="descricao" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorDescricao">
                    <nz-input-group>
                        <input nz-input formControlName="descricao" name="descricao" type="text" id="descricao">
                    </nz-input-group>
                    <ng-template let-control #errorDescricao>
                        <ng-container *ngIf="control.hasError('required')">
                            Você precisa informar uma descrição !
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label  [nzSpan]="6" nzFor="descricaoEstendida">Descrição Estendida</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group>
                        <input formControlName="descricaoEstendida" id="descricaoEstendida" name="descricaoEstendida" nz-input type="text">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="url">Url</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorUrl">
                    <nz-input-group>
                        <input nz-input formControlName="url" name="url" type="text" id="url">
                    </nz-input-group>
                    <ng-template let-control #errorUrl>
                        <ng-container *ngIf="control.hasError('required')">
                            Você precisa informar uma url!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="icone" >Ícone</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorIcone">
                    <nz-input-group>
                        <input nz-input formControlName="icone" name="icone" type="text" id="icone">
                    </nz-input-group>
                    <ng-template let-control #errorIcone>
                        <ng-container *ngIf="control.hasError('required')">
                            Você precisa informar um ícone!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="ordem" >Ordem</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorOrdem">
                    <nz-input-group>
                        <input nz-input formControlName="ordem" name="ordem" type="text" id="ordem">
                    </nz-input-group>
                    <ng-template let-control #errorOrdem>
                        <ng-container *ngIf="control.hasError('required')">
                            Você precisa informar a ordem!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorTipo">
                    <nz-select formControlName="tipo" nzPlaceHolder="Selecione o tipo">
                        <nz-option *ngFor="let tipo of menuTipos" [nzValue]="tipo?.id"
                                   [nzLabel]="tipo?.descricao"></nz-option>
                    </nz-select>
                    <ng-template #errorTipo let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Selecione o tipo!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" >Tipo da Url</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzErrorTip]="errorUrlTipo">
                    <nz-select formControlName="urlTipo" nzPlaceHolder="Selecione o tipo da Url">
                        <nz-option *ngFor="let tipo of urlTipos" [nzValue]="tipo?.id"
                                   [nzLabel]="tipo?.descricao"></nz-option>
                    </nz-select>
                    <ng-template #errorUrlTipo let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Selecione o tipo da Url!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="extra">Extra</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group>
                        <input formControlName="extra" id="extra" name="extra" nz-input type="text">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-template>

    <ng-template #modalAddFooter>
        <button nz-button nzType="default" (click)="cancel()">Cancelar</button>
        <button [disabled]="!formNewMenu.valid" nz-button nzType="primary" (click)="save()"
                [nzLoading]="isConfirmLoading">Salvar
        </button>
    </ng-template>
</nz-modal>

<nz-modal [nzClosable]="false"
          [(nzVisible)]="showRemoveModal"
          [nzTitle]="modalRemoveTitle"
          [nzContent]="modalRemoveContent"
          [nzFooter]="modalRemoveFooter"
          (nzOnCancel)="cancel()">
    <ng-template #modalRemoveTitle>Remover item</ng-template>
    <ng-template #modalRemoveContent>
        Tem certeza que deseja remover este item do menu?
    </ng-template>

    <ng-template #modalRemoveFooter>
        <button nz-button nzType="default" (click)="cancel()">Cancelar</button>
        <button nz-button nzType="danger" (click)="remove()" [nzLoading]="isConfirmLoading">Remover</button>
    </ng-template>
</nz-modal>

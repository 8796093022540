import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AnexosListService {

    constructor(protected http: HttpClient) {
    }

    verAnexo(obrigacaoEmpresaAnexoId: string, identificador = null): Observable<any> {
        if (identificador) {
            return this.http.get(`${environment.apiUrl}/publico/anexos/obrigacao-empresa/ver/${obrigacaoEmpresaAnexoId}?identificador=${identificador}`);
        } else {
            return this.http.get(`${environment.apiUrl}/anexos/obrigacao-empresa/ver/${obrigacaoEmpresaAnexoId}`);
        }
    }

    verAnexoDoZip(zip, zipId, pdf, identificador = null): Observable<any> {
        if (identificador) {
            return this.http.post(`${environment.apiUrl}/publico/anexos/obrigacao-empresa/zip?zip=${zip}&obrigacaoEmpresaAnexo_id=${zipId}&pdf=${pdf}
        &ver=true&identificador=${identificador}`,
                {});

        } else {
            return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/zip?zip=${zip}&obrigacaoEmpresaAnexo_id=${zipId}&pdf=${pdf}&ver=true`,
                {});

        }
    }

    retornaFromAnexos(id, identificador = null): any {
        if (identificador) {
            return this.http.get(`${environment.apiUrl}/publico/anexos/obrigacao-empresa/download/${id}?identificador=${identificador}`,
                {responseType: 'blob'});
        } else {
            return this.http.get(`${environment.apiUrl}/anexos/obrigacao-empresa/download/${id}`, {responseType: 'blob'});
        }
    }

    retornaPdf(zip: string, zipId, pdf: string, identificador = null): any {

        if (identificador) {
            return this.http.post(`${environment.apiUrl}/publico/anexos/obrigacao-empresa/zip?identificador=${identificador}&zip=${zip}
        &obrigacaoEmpresaAnexo_id=${zipId}&pdf=${pdf}`, {},
                {responseType: 'blob'});

        } else {
            return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/zip?zip=${zip}
        &obrigacaoEmpresaAnexo_id=${zipId}&pdf=${pdf}`, {},
                {responseType: 'blob'});
        }
    }

    retornaZip(zip: string, zipId, identificador = null): any {

        if (identificador) {
            return this.http.post(`${environment.apiUrl}/publico/anexos/obrigacao-empresa/zip?identificador=${identificador}&zip=${zip}&obrigacaoEmpresaAnexo_id=${zipId}`, {},
                {responseType: 'blob'});
        } else {
            return this.http.post(`${environment.apiUrl}/anexos/obrigacao-empresa/zip?zip=${zip}&obrigacaoEmpresaAnexo_id=${zipId}`, {},
                {responseType: 'blob'});
        }
    }

    removerAnexo(id: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/anexos/obrigacao-empresa/${id}`);
    }
}

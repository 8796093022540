import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class RegrasPisCofinsImportarMovimentoService extends AbstractService<InscricaoRegime> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/pis-cofins-regra', {});
    }


    retornaMovimentacoes(dados): Observable<any> {

        if (dados.competencia) {
            dados.competencia = this.formataCompetencia(dados.competencia);
        }

        const queryStr = Helpers.montaQueryString(dados);

        return this.http.get(`${environment.apiUrl}/cadastros/pis-cofins-regra/movimentacao${queryStr}`);

    }

    importar(movimentacoes): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/pis-cofins-regra/movimentacao`, movimentacoes);
    }


    formataCompetencia(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);

        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@models/response.model';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    private baseUrl = `${ environment.apiUrl }/admin/logs`;

    constructor(private http: HttpClient) {
    }

    list(page: number = 1): Observable<Response<any>> {
        const baseUrl = `${ this.baseUrl }?page=${ page }`;
        return this.http.get<Response<any>>(baseUrl);
    }
}

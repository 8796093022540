import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    LOCALE_ID,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzCarouselComponent} from 'ng-zorro-antd/carousel';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {ComplianceCalendarService} from './complianceCalendar.service';

import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './complianceCalendar.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./complianceCalendar.component.scss']
})

export class ComplianceCalendarComponent {

    @Input() data;

    arrayMesesLabel = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    arrayMeses = [];

    cardCompetenciaAtivo: number;
    thumbsSwiper: any;
    collapseCard = false;
    arraySwiper: any = [];

    cardsAnos: any = [];

    arrayTableObrigacoesAcessoriasOficiais: any = [{
        cor: '',
        minMax: 0,
        obrigacao: 'COFINS',
        obrigacoesMax: 0,
        obrigacoesMin: 0,
        pagamentos: 0,
        pagtoMax: 0,
        qtde: 1
    }];

    arrayTableObrigacoesPrincipaisPagamentos: any = [
        {
            cor: '#ff4961',
            obrigacao: 'COFINS',
            pagamentos: 0,
            pagamentosMax: 0,
            qtde: 1
        }
    ];

    arrayObrigacoes = [
        'GIA-SP',
        'GIA-ST SP',
        'EFD Fiscal',
        'GARE-SP',
        'EFD Fiscal - SP',
        'DCTF',
        'DCTFWEB',
        'eSocial',
        'DARF - COFINS',
        'DARF - CSLL',
        'DARF - IPI',
        'DARF - IRPJ',
        'DARF - PIS',
        'EFD Contribuições',
        'ECD Contábil',
        'ECF Contábil'
    ];

    arrayTableObrigacoes = [];

    arrayTableDestaquesTop50 = [];

    arrayResponsaveisTop20 = [
        'Não Atribuido',
        'Fiscal',
        'Contábil',
        'João da Silva',
        'Maria Aparecida',
        'José Gonçalves'
    ];

    arrayTableResponsaveisTop20 = [];

    anoAtivo: number = null;

    arraySeriesGrafico = [];

    minMaxObrigacoesOficiais = 0;
    minObrigacoesOficiais = 0;
    maxObrigacoesOficiais = 0;

    pagamentosObrigacoes = 0;
    pagtoMaxObrigacoes = 0;

    tabNumber = 0;


    arrayImpostos = [
        'ICMS',
        'PIS',
        'COFINS',
        'IRPJ',
        'CSLL',
        'IPI',
    ];

    arrayColors = [
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336',
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336'
    ];

    HighchartsGauge: typeof Highcharts = Highcharts;
    HighchartsSolidGauge: typeof Highcharts = Highcharts;
    HighchartsPie: typeof Highcharts = Highcharts;
    HighchartsColumn: typeof Highcharts = Highcharts;

    chartPieOptions: Highcharts.Options;

    chartGaugeOptions: Highcharts.Options;

    chartSolidGaugeOptions: Highcharts.Options;

    chartColumnOptions: Highcharts.Options;

    cardTabsLoading = false;

    cardObrigacoesLoading = true;

    cardPagamentosLoading = true;

    status = false;

    updateFlag = false;

    arrayTableObrigacoesEvolucao = [];

    expandSet = new Set<number>();

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild(NzCarouselComponent, {static: false}) myCarousel!: NzCarouselComponent;

    constructor(
        private complianceCalendar: ComplianceCalendarService,
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private tabService: TabService
    ) {

        for (const mes of this.arrayMesesLabel) {

            this.arrayMeses.push({
                mes,
                ano: '2021',
                unidades: Math.floor(Math.random() * 100),
                obrigacoes: Math.floor(Math.random() * 200),
                totalPendentes: Math.floor(Math.random() * 30),
                totalAtrasadas: Math.floor(Math.random() * 30),
                totalConcluidas: Math.floor(Math.random() * 30),
                totalConcluidasAtraso: Math.floor(Math.random() * 30)
            });

        }

        for (const obrigacao of this.arrayObrigacoes) {

            this.arrayTableObrigacoes.push({
                nome: obrigacao,
                id: obrigacao,
                unidades: Math.floor(Math.random() * 100),
                qtde: Math.floor(Math.random() * 100),
            });

        }

        this.arrayObrigacoes.forEach((value, i) => {
            this.arrayTableDestaquesTop50.push({
                nome: value,
                id: i,
                competencia: i % 2 === 0 ? 'jan/21' : 'fev/21',
                vencimento: i % 2 === 0 ? '01/01/21' : '01/02/21',
                unidade: i % 2 === 0 ? 'Ambev Jaguariúna' : 'Ambev Cajamar',
                status: i % 2 === 0 ? 'Atrasadas' : 'Pendentes',
                cor: i % 2 === 0 ? '#ffeb3b' : '#fdd835',
            });
        });

        for (const responsavel of this.arrayResponsaveisTop20) {

            this.arrayTableResponsaveisTop20.push({
                nome: responsavel,
                id: responsavel,
                tipo: responsavel === 'Não Atribuido' ? 'NA' : (responsavel === 'Fiscal' || responsavel === 'Contábil' ? 'GR' : 'IN'),
                unidades: Math.floor(Math.random() * 100),
                qtde: Math.floor(Math.random() * 100),
            });

        }

        this.complianceCalendar = complianceCalendar;


        // CARDS ANOS
        this.complianceCalendar.retornaAnos().subscribe((response: any) => {
            for (const mes of Object.entries(response.dados)) {
                this.cardsAnos.push(mes[1]);
            }
            setTimeout(() => {
                this.cardCompetenciaAtivo = this.arrayMeses.length - 1;
                this.collapseCard = true;
            }, 1000);

            // this.anoAtivo = this.cardsAnos[this.cardsAnos.length - 1].ano;

            // Obrigações Acessorias

            this.complianceCalendar.retornaObrigacoesAcessorias(this.anoAtivo).subscribe((retorno: any) => {
                this.arrayTableObrigacoesAcessoriasOficiais = [];
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesAcessoriasOficiais.push(dado[1]);
                }

                // Totais
                this.minMaxObrigacoesOficiais = retorno.totais.totalMinMax;
                this.minObrigacoesOficiais = retorno.totais.totalMin;
                this.maxObrigacoesOficiais = retorno.totais.totalMax;

                this.cardObrigacoesLoading = false;
                this.cdr.detectChanges();

            });

            // Pagamentos Obrigações Principais
            this.complianceCalendar.retornaObrigacoesPagamentos(this.anoAtivo).subscribe((retorno: any) => {
                this.arrayTableObrigacoesPrincipaisPagamentos = [];
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesPrincipaisPagamentos.push(dado[1]);
                }

                this.arrayTableObrigacoesPrincipaisPagamentos.forEach((pagamento, index) => {
                    pagamento.cor = this.arrayColors[index];
                });

                // Gráfiico
                for (const dado of Object.entries(retorno.serie)) {
                    this.arraySeriesGrafico.push(dado);
                }
                this.loadChartData();
                this.cardPagamentosLoading = false;
                this.cdr.detectChanges();

                // Totais
                this.pagamentosObrigacoes = retorno.totais.pagamentosTotal;
                this.pagtoMaxObrigacoes = retorno.totais.pagamentosMaxTotal;
            });

            // Impostos por Grupo (ICMS)
            this.complianceCalendar.retornaObrigacoesGrupo(this.anoAtivo, 'ICMS').subscribe((retorno: any) => {
                this.arrayTableObrigacoesEvolucao = retorno.dados.data;

                for (const [index, item] of this.arrayTableObrigacoesEvolucao.entries()) {
                    this.expandSet.add(item.id);
                }
                setTimeout(() => {
                    // this.largurasTable();
                }, 100);
            });
        });

    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    formatOne = (percent: number) => `${percent}%`;

    clickEvent() {
        this.status = !this.status;
    }

    loadChartData(): void {

        this.chartColumnOptions = {
            chart: {
                type: 'column'
            },
            title: undefined,
            xAxis: {
                title: {
                    text: 'Entregas no prazo'
                },
                categories: [
                    '5',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30'
                ],
                crosshair: true
            },
            yAxis: {
                title: undefined,
                min: 0
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">Dia {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Entregas',
                data: [49, 71, 106, 129, 144, 176]

            }]
        };

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 100,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '85%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [78],
                dataLabels: {
                    format: '<div style="text-align:center; top: -15px; position: relative;">' +
                        '<span style="font-size:10px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.chartGaugeOptions = {
            chart: {
                type: 'solidgauge',
                marginTop: 20
            },
            title: {
                text: undefined
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '16px'
                },
                pointFormat: '<span style="font-size: 12px;">{series.name}</span><br><span style="' +
                    'font-size:10px;' +
                    ' color: {point.color}; font-weight: bold">{point.y}%</span>',
                positioner: (labelWidth) => {
                    return {
                        x: 100 - labelWidth / 2,
                        y: 70
                    };
                }
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    outerRadius: '112%',
                    innerRadius: '88%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[0])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, {
                    outerRadius: '87%',
                    innerRadius: '63%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[1])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, {
                    outerRadius: '62%',
                    innerRadius: '38%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[2])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }]
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: [{
                type: undefined,
                name: 'Minhas',
                data: [{
                    color: '#ff6f00',
                    radius: '112%',
                    innerRadius: '88%',
                    y: 80
                }]
            }, {
                type: undefined,
                name: 'Equipe',
                data: [{
                    color: '#ffab00',
                    radius: '87%',
                    innerRadius: '63%',
                    y: 65
                }]
            }, {
                type: undefined,
                name: 'Geral ',
                data: [{
                    color: '#ffd54f',
                    radius: '62%',
                    innerRadius: '38%',
                    y: 50
                }]
            }]
        };

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: [
                        '#fdd835',
                        '#ffc107',
                        '#2196f3',
                        '#9c27b0',
                    ],
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: [{
                type: 'pie',
                name: 'Browser share',
                data: [{
                    name: 'Internet Explorer',
                    y: 11.84
                }, {
                    name: 'Firefox',
                    y: 10.85
                }, {
                    name: 'Edge',
                    y: 4.67
                }, {
                    name: 'Safari',
                    y: 4.18
                }]
            }]
        };

        this.cdr.detectChanges();

        this.updateFlag = true;

    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    selecionaCardCompetencia(event: any) {
        if (!(event.clickedIndex === undefined)) {
            this.cardCompetenciaAtivo = event.clickedIndex;

            const anoAtivo = this.cardsAnos[event.clickedIndex].ano;

            // Obrigações Acessorias
            this.cardObrigacoesLoading = true;
            this.complianceCalendar.retornaObrigacoesAcessorias(anoAtivo).subscribe((retorno: any) => {
                this.arrayTableObrigacoesAcessoriasOficiais = [];
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesAcessoriasOficiais.push(dado[1]);
                }

                // Totais
                this.minMaxObrigacoesOficiais = retorno.totais.totalMinMax;
                this.minObrigacoesOficiais = retorno.totais.totalMin;
                this.maxObrigacoesOficiais = retorno.totais.totalMax;

                this.cardObrigacoesLoading = false;
                this.cdr.detectChanges();

            });

            // Pagamentos Obrigações Principais
            this.cardPagamentosLoading = true;
            this.complianceCalendar.retornaObrigacoesPagamentos(anoAtivo).subscribe((retorno: any) => {
                this.arrayTableObrigacoesPrincipaisPagamentos = [];
                for (const dado of Object.entries(retorno.dados)) {
                    this.arrayTableObrigacoesPrincipaisPagamentos.push(dado[1]);
                }

                this.arrayTableObrigacoesPrincipaisPagamentos.forEach((pagamento, index) => {
                    pagamento.cor = this.arrayColors[index];
                });

                // Gráfiico
                this.arraySeriesGrafico = [];
                for (const dado of Object.entries(retorno.serie)) {
                    this.arraySeriesGrafico.push(dado);
                }
                this.loadChartData();
                this.cardPagamentosLoading = false;
                this.cdr.detectChanges();

                // Totais
                this.pagamentosObrigacoes = retorno.totais.pagamentosTotal;
                this.pagtoMaxObrigacoes = retorno.totais.pagamentosMaxTotal;
            });

            this.anoAtivo = anoAtivo;

            this.changeTabs(event);


        }

    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    changeTabs(event: any) {
        this.cardTabsLoading = true;
        const anoAtivo = this.cardsAnos[this.cardCompetenciaAtivo].ano; // gfa
        const imposto = this.arrayImpostos[this.tabNumber];
        // Impostos por Grupo (ICMS)
        this.complianceCalendar.retornaObrigacoesGrupo(anoAtivo, imposto).subscribe((retorno: any) => {
            this.arrayTableObrigacoesEvolucao = retorno.dados.data;

            for (const [index, item] of this.arrayTableObrigacoesEvolucao.entries()) {
                this.expandSet.add(item.id);
            }
            setTimeout(() => {
                // this.largurasTable();
            }, 100);
            this.cardTabsLoading = false;
            this.cdr.detectChanges();
        });

    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {
            case 'left':
                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;
                break;
            case 'right':
                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;
                break;

        }

    }

}

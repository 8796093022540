import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../shared/ng-zorro.module';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NfeTerceirosComponent} from './nfe-terceiros.component';
import {UiModule} from '../../ui.module';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {ModalCredencialDeAcessoModule} from '@components/modal-credencial-de-acesso/modal-credencial-de-acesso.module';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {CnpjMaskDirectveModule} from '../../../core/diretivas/cnpj-mask-directve.module';

@NgModule({
    declarations: [
        NfeTerceirosComponent
    ],
    imports: [
        CnpjMaskDirectveModule,
        NzBadgeModule,
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzListModule,
        NzPageHeaderModule,
        NzAffixModule,
        NzTypographyModule,
        NzDatePickerModule,
        FormsModule,
        NzSwitchModule,
        NzSpaceModule,
        NzSkeletonModule,
        NzAlertModule,
        NzUploadModule,
        NzCheckboxModule,
        NzTimePickerModule,
        ModalCredencialDeAcessoModule,
        NzTagModule,
        NzTimelineModule,
        NzStepsModule
    ],
    entryComponents: [
        NfeTerceirosComponent
    ]
})
export class NfeTerceirosModule {
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Helpers} from '../../../core/helpers';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegrasAnaliseXmlSaidaService {

    constructor(
        protected http: HttpClient
    ) {
    }

    getRegras(tributoId: string, params: NzTableQueryParams) {

        const queryStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${tributoId}/tabela${queryStr}`);
    }

    gravarRegra(data: any) {

        const tributo_id = data.tributo_id;

        delete data.tributo_id;
        data.CFOP = data.CFOP.join(',');
        data.uf = data.uf.join(',');
        data.origem = data.origem.join(',');

        Helpers.remoeNullOfObject(data);

        return this.http.post(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${tributo_id}/gravar`, data);
    }

    alterarRegra(data: any, id: string) {

        data.CFOP = data.CFOP.join(',');
        data.uf = data.uf.join(',');
        data.origem = data.origem.join(',');

        Helpers.remoeNullOfObject(data);

        return this.http.put(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${data.tributo_id}/gravar/${id}`, data);
    }


    retornaComboUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);
    }

    retornaComboOrigem(tributoId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${tributoId}/filtros/combos/origem`);
    }

    retornaComboCst(tributoId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${tributoId}/filtros/combos/cst`);
    }

    retornaComboCfop(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/cfops/select`);
    }

    excluirRegra(id: string, tributoId: string) {
        return this.http.delete(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/tributo/${tributoId}/remover/${id}`);
    }

    uploadRegra(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/importar`, formData);
    }

    uploadBasicTable(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/cnpjs/importar`, formData);
    }


    downloadModelo(): any {

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/exportarModelo`,
            {responseType: 'blob'});

    }

    downloadModeloBasic(): any {

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/cnpjs/exportarModelo`,
            {responseType: 'blob'});

    }

    getRegrasCnpjs(params: NzTableQueryParams) {

        const queryStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/cadastros/regras-analise-xml-saida/cnpjs/tabela${queryStr}`);
    }
}

import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appCnpjMask]'
})
export class CnpjMaskDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInput(event: any): void {
        const value = event.target.value.replace(/\D/g, '');
        event.target.value = this.formatCnpj(value);
    }

    private formatCnpj(value: string): string {
        if (value.length > 14) {
            value = value.slice(0, 14);
        }

        return value ?
            value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5') :
            null;
    }
}

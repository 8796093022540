import {Directive} from '@angular/core';
import {Pagination} from '@models/pagination.model';
import {TableTitles} from '@models/table-titles.model';
import {Response} from '@models/response.model';

@Directive()
export abstract class AbstractList<E> {

    items: E[] | any;
    checkedItems = new Set<string>();
    indeterminate = false;
    checked = false;
    pagination: Pagination;
    tableTitles: TableTitles[] ;
    loading = true;
    currentFilter: E;

    protected constructor(public abstractService, public object, public toastrService) {
    }

    list() {

        this.abstractService.list.subscribe((res: Response<E>) => {
            this.formatList(res);
        });
    }

    formatList(response: Response<any>) {

        this.items = response?.data || response;

        this.pagination = new Pagination(
            response?.per_page || this.list.length,
            response?.current_page || 1,
            response?.last_page || 1,
            response?.total || this.list.length);

        this.loading = false;

    }

    getAll(filter?: any, generalFilter?: string) {

        this.loading = true;

        return this.abstractService.getAll(this.pagination.current_page, filter, generalFilter)
            .then((result: Response<any>) => {
                this.formatList(result);
                if (filter) {
                    this.currentFilter = filter;
                }
                return result;
            });

    }

    updateList() {

        this.abstractService.list(this.pagination.current_page)
            .then((result: Response<E>) => {
                this.formatList(result);
            });

    }

    onUpdateList(page: number) {

        this.pagination.current_page = page;
        this.getAll();

    }

    // Metodos de checagem
    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    onAllChecked(checked: boolean): void {

        this.items.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));

    }

    orderBy(index: number): void {

        if (this.tableTitles[index].sortable) {

            this.loading = true;

            this.cleanOldOrderBy(index);

            this.tableTitles[index].nextOrder();

            this.abstractService.orderBy(this.tableTitles[index]).subscribe(
                (response: Response<E>) => {

                    this.items = response.data;
                    this.toastrService.success('Ordenação realizada sucesso!');
                    this.loading = false;

                },
                () => {

                    this.toastrService.error('Não foi possível ordenar!');
                    this.loading = false;

                }
            );
        }
    }

    cleanOldOrderBy(index: number): void {

        for (const n in this.tableTitles) {

            if (n !== index.toString()) {

                this.tableTitles[n].order = null;

            }

        }

    }

    refreshCheckedStatus() {

        const listOfEnabledData = this.items.filter(({disabled}) => !disabled);
        this.checked = listOfEnabledData.every(({id}) => this.checkedItems.has(id));
        this.indeterminate = listOfEnabledData.some(({id}) => this.checkedItems.has(id)) && !this.checked;

    }

    resetCheckedSet() {

        this.checkedItems = new Set<string>();

    }

    protected addToList(list) {

        for (const item of list) {

            this.items.push(new this.object(item));

        }
    }
}

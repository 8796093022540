import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MonitorProcessamentosComponent} from './monitorProcessamentos.component';
import {UiModule} from '../../ui.module';
import {RouterModule} from '@angular/router';
import {MonitorProcessamentosRoutingModule} from './monitorProcessamentos-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';


@NgModule({
    declarations: [MonitorProcessamentosComponent],
    imports: [
        MonitorProcessamentosRoutingModule,
        CommonModule,
        UiModule,
        RouterModule,
        ReactiveFormsModule,
        NzBreadCrumbModule,
        NzPageHeaderModule
    ],
    exports: [
        MonitorProcessamentosComponent
    ]
})
export class MonitorProcessamentosModule {
}

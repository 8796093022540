import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class CheckEfdContribuicoesTributacaoDetalhesService extends AbstractService<any> {

    id = '';
    url = environment.apiUrl;

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaCabecalho(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/efdContrib/analise/${id}`);
    }

    retornaContadores(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/efdContrib/analise/${id}/contadores`);
    }

    montaUrl(id: string) {
        this.url = environment.apiUrl + `/check/efdContrib/analise/${id}/detalhe`;
    }

    exportExcel(id: string, filtros: any, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/check/efdContrib/analise/${id}/detalhe/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    retornaCombosFiltros(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/efdContrib/analise/${id}/detalhe/filtro/combos`);
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaItensNota(analiseId, notaId, filtros, search: string = null): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/check/efdContrib/analise/${analiseId}/nota/${notaId}/detalhe${queryStr}`);

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

}

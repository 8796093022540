import {Component, OnInit, AfterViewInit, AfterContentChecked} from '@angular/core';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {setStorageTab} from '@reducers/tab.reducer';

@Component({
    selector: 'Tabset',
    templateUrl: './tabset.component.html',
    styleUrls: ['./tabset.component.scss']
})
export class TabsetComponent implements OnInit, AfterViewInit, AfterContentChecked {
    private static store: Store;

    constructor(
        private tabService: TabService,
        private store: Store) {
        // @ts-ignore
        this.tabs$ = this.store.pipe(select('tabs'));

        this.tabs = new Array<Tab>();
    }

    tabs = new Array<Tab>();
    selectedTab: number;
    tabs$: Observable<Tab[]>;
    tabEmbedded: Tab;
    tabDragedId = null;

    alterandoTab = false;

    telasPesadas = ['/auditor/dashboard/'];

    ultimoMovimentoTab = 'naomoveu';

    lastLengthTabs = 0;

    tabsReordenadas: any[] = [];
    pDestino = null;

    setouEventos = {
        lixeira: false,
        tabList: false
    };

    ngOnInit() {
        this.tabEmbedded = JSON.parse(localStorage.getItem('tabEmbedded'));
        this.tabs$.subscribe(tabs => {
            this.tabs = tabs;
            this.lastLengthTabs = this.tabs.length;
        });
        this.getCurrentTabIndex();

    }

    ngAfterViewInit() {
        this.addDrag();
    }

    ngAfterContentChecked() {
        if (this.tabs.length !== this.lastLengthTabs) {
            this.addDrag();
            this.lastLengthTabs = this.tabs.length;
        }

        if (this.tabs.length === 0) {
            this.setouEventos.tabList = false;
        }
    }

    getCurrentTabIndex() {
        this.tabService.getTabIndex.subscribe(index => {
            this.selectedTab = index;
        });
    }

    // @ts-ignore
    getTabs = () => this.store.pipe(select('tabs'));

    removeTabById(tabId) {
        this.tabService.removeTabById(tabId);
    }

    removeOthersTabs(id: number) {
        this.tabService.removeOthersTabs(id);
    }

    removeAllTabs() {
        this.tabService.removeAllTabs();
    }

    reloadTab(tab: Tab) {
        this.tabService.reloadTab(tab);
        // Aguarda o efeito do service de 1500ms para adicionar atributos novamente.
        setTimeout(() => {
            this.addDragInTabs();
        }, 1800);
    }

    reorderTabs(tabs: Tab[], index: number) {
        this.tabService.reorderTabs(tabs, index);
    }

    openInNew(tab: any) {
        this.tabService.openInNew(tab);
    }

    selectTab(event) {

        this.alterandoTab = true;

        this.tabs.forEach(tab => {
            tab.active = tab.id === event.index;
        });

        setStorageTab(this.tabs);

        this.tabService.setCurrentTabIndex = event.index;

        let ms = 0;

        if (this.tabs.length && this.telasPesadas.includes(this.tabs[event.index].url)) {
            ms = 1500;
        }

        setTimeout(() => {
            this.alterandoTab = false;
            this.addDrag(); // para as tabs recém adicionadas
        }, ms);
    }

    // Arrastar tabs -----------------------------------------

    addDrag() {

        this.addDragInTabs();

        if (!this.setouEventos.lixeira) {

            const lixeiraTab: any = document.getElementById('lixeira-tab');

            lixeiraTab.addEventListener('dragover', () => {
                this.arrastarNaLixeira(event);
            });

            lixeiraTab.addEventListener('drop', () => {
                this.soltarNaLixeira(event);
            });

            this.setouEventos.lixeira = true;
        }

        if (!this.setouEventos.tabList) {

            // Para reordenar tabs
            const divTabs: any = document.getElementsByClassName('ant-tabs-nav-wrap');
            if (divTabs[0]) {
                divTabs[0].addEventListener('dragover', () => {
                    this.arrastarNaTabList(event);
                });

                this.setouEventos.tabList = true;
            }
        }
    }

    addDragInTabs() {

        const tabs: any = document.getElementsByClassName('ant-tabs-tab');

        let i = 0;

        for (const t of tabs) {

            if (i === this.tabs.length) {
                // podem existir outras tabs na tela
                break;
            }

            if (!t.draggable) {

                t.setAttribute('draggable', 'true');
                t.addEventListener('dragstart', () => {
                    this.segurar(event);
                });
                t.addEventListener('dragend', () => {
                    this.sotarForaDaLixeira(event);
                });
            }

            i += 1;
        }

        this.tabService.setTabIdCresc(tabs);

    }

    segurar(e: any | DragEvent) {
        this.tabDragedId = e.target.id;

        // Para abrir em nova guia
        const dev = window.location.href.includes('.dev') ? '.dev.' : '.';

        e.dataTransfer.setData('text/plain', `https://${localStorage.getItem('identificador')}${dev}mastertax.app/`
            + this.tabs[this.tabDragedId].url);

        e.target.style.opacity = '0';

        if (Number(this.tabDragedId) < this.tabs.length - 1) {
            e.target.style.marginLeft = '-' + e.target.clientWidth + 'px';
            e.target.style.zIndex = '-99';
        }

        document.getElementById('lixeira-tab').style.display = 'flex';
    }

    arrastarNaLixeira(e: any | DragEvent) {
        e.preventDefault(); // Tira o comportamento do navegador que impede que um elemento seja solto em cima do outro
    }

    arrastarNaTabList(e: any | DragEvent) {
        e.preventDefault(); // Tira o comportamento do navegador que impede que um elemento seja solto em cima do outro

        if (this.tabs.length > 1) {

            const targetTab = e.target.closest('.ant-tabs-tab');
            if (targetTab) {
                const rect = targetTab.getBoundingClientRect();
                const targetPosition = e.clientX - rect.left;

                // Inserir a tab arrastada na nova posição
                if (targetPosition > rect.width / 2) {

                    if (this.ultimoMovimentoTab === 'esquerda' || this.ultimoMovimentoTab === 'naomoveu') {
                        this.ultimoMovimentoTab = 'direita';

                        this.efeitoMargenTab('marginLeft');
                        this.efeitoMargenTab('marginRight', targetTab.id);

                        this.pDestino = targetTab.id < this.tabs.length - 1 && this.tabDragedId > targetTab.id ?
                            Number(targetTab.id) + 1 : Number(targetTab.id);
                    }

                } else {
                    if (this.ultimoMovimentoTab === 'direita' || this.ultimoMovimentoTab === 'naomoveu') {
                        this.ultimoMovimentoTab = 'esquerda';

                        this.efeitoMargenTab('marginRight');
                        this.efeitoMargenTab('marginLeft', targetTab.id);

                        let targetTabId = targetTab.id;
                        if (this.tabs.length - 1 > this.tabDragedId && this.tabDragedId === targetTab.id
                            && Number(targetTab.id) > 0) {
                            // Nesse caso a tab anterior fica com id igual a tab arrastada
                            targetTabId = targetTab.id - 1;
                        }

                        this.pDestino = targetTabId > 0 && this.tabDragedId < targetTabId ?
                            Number(targetTabId) - 1 : Number(targetTabId);

                    }
                }
            }

        }
    }

    soltarNaLixeira(e: any | DragEvent) {
        if (this.tabs[this.tabDragedId]) {
            this.removeTabById(this.tabs[this.tabDragedId].id);
            this.tabDragedId = null;
            this.ultimoMovimentoTab = 'naomoveu';
        }

        setTimeout(() => {
            document.getElementById('lixeira-tab').style.display = 'none';
        }, 100);
    }

    sotarForaDaLixeira(e: any | DragEvent) {
        e.preventDefault(); // Evitar comportamento padrão do navegador.

        e.target.style.opacity = '1';
        e.target.style.marginLeft = '2px';
        e.target.style.zIndex = '0';

        if (this.ultimoMovimentoTab !== 'naomoveu' && Number(this.tabDragedId) !== this.pDestino) {
            this.tabsReordenadas = this.reordenaArrayTabs(this.tabs, Number(this.tabDragedId), this.pDestino);
            this.reorderTabs(this.tabsReordenadas, this.pDestino);
            this.pDestino = null;
            this.ultimoMovimentoTab = 'naomoveu';
        }

        if (this.tabDragedId) { // Garante que soltou fora da lixeira
            document.getElementById('lixeira-tab').style.display = 'none';
            this.tabDragedId = null;
        }

        setTimeout(() => {
            setStorageTab(this.tabs);
        }, 500);

        this.efeitoMargenTab('marginRight');
        this.efeitoMargenTab('marginLeft');

    }

    reordenaArrayTabs(arr: Tab [], pAtual: number, pDestido: number): Tab[] {

        const retorno = [];

        if (pAtual < pDestido) {
            // direita

            for (let i = 0; i < pAtual; i++) {
                retorno.push(arr[i]);
            }

            for (let i = pAtual + 1; i <= pDestido; i++) {
                arr[i].id = arr[i].id - 1;
                retorno.push(arr[i]);
            }

            arr[pAtual].id = pDestido;
            retorno.push(arr[pAtual]);

            for (let i = pDestido + 1; i < arr.length; i++) {
                retorno.push(arr[i]);
            }

        } else {
            // esquerda

            for (let i = 0; i < pDestido; i++) {
                retorno.push(arr[i]);
            }

            arr[pAtual].id = pDestido;
            retorno.push(arr[pAtual]);

            for (let i = pDestido; i < pAtual; i++) {
                arr[i].id = arr[i].id + 1;
                retorno.push(arr[i]);
            }

            for (let i = pAtual + 1; i < arr.length; i++) {
                retorno.push(arr[i]);
            }

        }

        return retorno;
    }

    efeitoMargenTab(ladoMargem: string, idExcecao = null) {
        const tabs: any = document.getElementsByClassName('ant-tabs-tab');

        let i = 0;

        for (const t of tabs) {
            if (i === this.tabs.length) {
                // podem existir outras tabs na tela
                break;
            }

            if (idExcecao && t.id === idExcecao && !t.style[ladoMargem].includes('-')) {
                t.style[ladoMargem] = '110px';
            } else {
                if (!t.style[ladoMargem].includes('-')) {
                    // - Para não modificar a que foi ocultada
                    t.style[ladoMargem] = ladoMargem === 'marginLeft' ? '2px' : 0;
                }
            }

            i += 1;
        }
    }

}

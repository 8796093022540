import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {InscricoesRegimesService} from '@services/inscricoes-regimes.service';
import {environment} from '../../../../environments/environment';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@services/data.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-cadastros-inscricoes-regimes',
    templateUrl: './inscricoes-regimes.component.html',
    styleUrls: ['./inscricoes-regimes.component.scss']
})
export class CadastrosInscricoesRegimesComponent extends AbstractListTable<InscricaoRegime> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    modalFiltrarVisible = false;
    formCadastrar: UntypedFormGroup;
    formFiltrar: FormStack;
    cadastrando = false;
    formExportar: FormStack;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    modalImportarPlanilhaRegime = false;
    modalImportarPlanilhaIeIest = false;
    modalImportarPlanilhaIm = false;

    // Para upload
    apiUrl = environment.apiUrl;

    uploading = false;
    fileList: NzUploadFile[] = [];
    fileListRegime: NzUploadFile[] = [];
    fileListIeiest: NzUploadFile[] = [];
    fileListIm: NzUploadFile[] = [];

    qtdFiltrosAtivos = 0;

    comboUfs = [];
    comboUfsIm = [];
    comboGrupos: any = [];

    loadings = {
        exportar: false,
        upload: false
    };

    usuarioLogado: any;
    @Input() data;
    comboMunicipio: any = [];

    optionsEmpresas = [];

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: NzModalService,
        private inscricoesRegimesService: InscricoesRegimesService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private tabService: TabService,
        private dataService: DataService,
        protected http: HttpClient) {
        super(inscricoesRegimesService, InscricaoRegime, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                nome: [null, null],
                cnpj: [null, null],
                codigo: [null, null],
                empresa_id: [null, null],
                grupo: [null, null],
                uf: [null, null],
                cidade: [null, null],
                situacaoCadastral: [null, null],
                empresaInscricaoFederal_documento: [null, null],
                regimeTributario: [null, null],
                somenteSemInscricaoRegime: [null, null],
                im_uf: [null, null],
                im_cidade: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.inscricoesRegimesService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUfs = retorno.estados;
            this.comboUfsIm = retorno.estados;
        });

        this.inscricoesRegimesService.retornaComboGrupos().subscribe((retorno: any) => {
            retorno.grupoEmpresarial.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboGrupos = retorno.grupoEmpresarial;
        });

        this.dataExport = {
            url: '/cadastros/empresas/inscricoesRegimes/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Inscrições e Regimes',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };


    }

    ngOnInit() {

        if (this.data.somenteSemInscricaoRegime) {
            this.formFiltrar.formGroup.get('somenteSemInscricaoRegime').setValue(this.data.somenteSemInscricaoRegime);
        }

        this.dataService.currentUser.subscribe((data) => {
            this.usuarioLogado = data;
        });

        this.inscricoesRegimesService.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.componentExport.visible = visible;
    }

    modalImportarRegime(visible: boolean): void {
        this.modalImportarPlanilhaRegime = visible;
    }

    modalImportarIeiest(visible: boolean): void {
        this.modalImportarPlanilhaIeIest = visible;
    }

    modalImportarIm(visible: boolean): void {
        this.modalImportarPlanilhaIm = visible;
    }

    downloadModelo(modelo: string): void {
        switch (modelo) {
            case 'regime': {
                this.inscricoesRegimesService.downloadModelo('regimeTributario').subscribe((res) => {
                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
                    fileSaver.saveAs(blob, 'Modelo Regime' + '.xlsx');
                });
                break;
            }
            case 'ieiest': {
                this.inscricoesRegimesService.downloadModelo('inscricaoEstadual').subscribe((res) => {
                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
                    fileSaver.saveAs(blob, 'Modelo IE IEST' + '.xlsx');
                });
                break;
            }
            case 'im': {
                this.inscricoesRegimesService.downloadModelo('inscricaoMunicipal').subscribe((res) => {
                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
                    fileSaver.saveAs(blob, 'Modelo IM' + '.xlsx');
                });
                break;
            }
        }
    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        // const status = file.status;
        // if (status !== 'uploading') {
        // }
        // if (status === 'done') {
        //     this.msg.success(`${file.name} file uploaded successfully.`);
        // } else if (status === 'error') {
        //     this.msg.error(`${file.name} file upload failed.`);
        // }
    }

    beforeUploadRegime = (file: NzUploadFile): boolean => {

        this.fileListRegime = [];
        this.fileListRegime.push(file);
        return false;
    }
    beforeUploadIest = (file: NzUploadFile): boolean => {

        this.fileListIeiest = [];
        this.fileListIeiest.push(file);
        return false;
    }
    beforeUploadIm = (file: NzUploadFile): boolean => {

        this.fileListIm = [];
        this.fileListIm.push(file);
        return false;
    }
    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;
    }


    confirmarImportarRegime(): void {

        this.loadings.upload = true;

        const formData = new FormData();

        this.fileListRegime.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        this.inscricoesRegimesService.uploadPlanilhaRegime(formData).subscribe((response: any) => {

                this.loadings.upload = false;
                this.toastrService.success(response.message);

                this.fileListRegime = [];

                this.modalImportarPlanilhaRegime = false;

                this.queryTable(this.currentParams, this.currentSearch);

            },
            (response) => {
                this.loadings.upload = false;
                this.toastrService.error(response.error.message);
            });

    }

    confirmarImportarIeIest(): void {

        this.loadings.upload = true;

        const formData = new FormData();

        this.fileListIeiest.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        if (this.fileListIeiest[0]) {

            this.inscricoesRegimesService.uploadPlanilhaIeiest(formData).subscribe((response: any) => {

                    this.loadings.upload = false;
                    this.toastrService.success(response.message);
                    this.fileListIeiest = [];

                    this.modalImportarPlanilhaIeIest = false;

                    this.queryTable(this.currentParams, this.currentSearch);

                },
                (response) => {
                    this.loadings.upload = false;
                    this.toastrService.error(response.error.message);
                });
        }
    }

    confirmarImportarIm(): void {

        this.loadings.upload = true;

        const formData = new FormData();

        this.fileListIm.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        this.inscricoesRegimesService.uploadPlanilhaIm(formData).subscribe((response: any) => {

                this.loadings.upload = false;
                this.toastrService.success(response.message);

                this.fileListIm = [];

                this.modalImportarPlanilhaIm = false;

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {
                this.loadings.upload = false;
                this.toastrService.error(response.error.message);
            });

    }

    atualizarRFB(): void {

        this.loading = true;

        const url = `${environment.apiUrl}/cadastros/empresa/consulta/rfb`;

        this.inscricoesRegimesService.atualziarRFB(this.currentParams, null, url).subscribe((response) => {
                this.toastrService.success('Dados atualizados');
                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            });


    }

    atualizarDadosCadastraisRFB(): void {

        this.loading = true;

        const url = `${environment.apiUrl}/cadastros/empresa/consulta/rfb/completa`;

        this.inscricoesRegimesService.atualziarDadosCadastraisRFB(this.checkedItems, url).subscribe((response) => {
                this.toastrService.success('Dados atualizados');
                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            });
    }

    showConfirmatualizarIEViaPortal(): void {

        this.modalService.confirm({
            nzTitle: `Essa ação irá realizar a atualização da lista de inscrições para as empresas selecionadas,
            alterando dados existentes e inserindo novas inscrições conforme consulta no portal de cadastro centralizado de contribuinte.\n
            Deseja seguir com a consulta?`,
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.atualizarIEViaPortal()
        });

    }

    atualizarIEViaPortal(): void {

        this.loading = true;

        const url = `${environment.apiUrl}/consulta-centralizada-ie/solicitar`;

        const ids: any[] = [];

        this.items.forEach(i => {
            if (this.checkedItems.has(i.cnpj)) {
                ids.push(i.id);
            }
        });

        this.inscricoesRegimesService.atualizarIEViaPortal(ids, url).subscribe((response) => {

                if (response.error.length === this.checkedItems.size) {

                    this.toastrService.warning('Consulta para o CNPJ já foi realizada hoje. ');
                } else {

                    this.toastrService.success(response.message);
                }

                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {
                this.toastrService.warning(response.error.message);
                this.checkedItems.clear();
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            });
    }

    importarExportarEmbedded(acao: string): void {

        if (this.checkedItems.size) {
            this.loading = true;
            this.inscricoesRegimesService.importarEmbedded(acao, this.checkedItems).subscribe((response) => {
                    this.toastrService.success('Dados atualizados');
                    this.checkedItems.clear();
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.loading = false;
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.checkedItems.clear();
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.loading = false;
                });
        }

    }

    onAllChecked(checked: boolean): void {
        const itens = this.items.filter(({disabled}) => !disabled);
        itens.forEach(item => {
            this.updateCheckedSet(item.cnpj, checked);
        });
    }

    alterarFiltroEmpresa(cnpj: string) {

        // this.arraySelectCnpj.forEach(value => {
        //     if (value.descricao === cnpj) {
        //         this.formFiltrar.formGroup.get('nome').setValue(value.empresa_nome);
        //     }
        // });
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }

        this.calculaBadgeFiltros();

        this.loading = true;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.abstractService.listToTable(this.currentParams, this.currentSearch).subscribe((response) => {

            this.items = [];

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;
        });

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor && chave !== 'somenteSemInscricaoRegime') {
                    this.qtdFiltrosAtivos += 1;
                }

            }

        }

    }


    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;


        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    filtraComboMunicipio(uf: string): void {
        this.inscricoesRegimesService.retornaComboMunicipios(uf).subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboMunicipio = [];
            this.comboMunicipio = retorno.cidades;
        });
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }


    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.checkedItems.size !== this.items.length) {
            this.checked = false;
        }else{
            this.checked = true;
        }
    }

}

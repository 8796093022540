import {Directive} from '@angular/core';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Utils} from '../../../shared/utils';

@Directive()
export abstract class AbstractListTable<E> {

    items: E[] | any;
    checkedItems = new Set<string>();
    indeterminate = false;
    checked = false;
    pagination: Pagination;
    loading = true;
    currentParams: NzTableQueryParams;
    currentSearch: string;

    pageSize = 50;
    pageIndex = 1;

    extra: any = null;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    currentPermissoes: any[] = [];

    protected constructor(
        public abstractService,
        public object,
        public toastrService
    ) {

        this.pagination = new Pagination();

    }

    updateTable() {

        this.queryTable(this.currentParams, this.currentSearch);
    }

    cleanFilterParams(filter): string {
        Object.entries(filter).forEach(([key, value]) => {
            if (Utils.isEmpty(value)) {
                delete filter[key];
            }
        });
        return filter;
    }


    getGeneralSearch(generalSearch: string) {
        if (!Utils.isEmpty(generalSearch)) {

            this.currentSearch = generalSearch;
            this.updateTable();

        }
    }

    resetSearch() {
        this.queryTable({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],

        });
    }

    // Metodos de checagem
    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

    updateCardCheckedSet(id: string): void {

        if (!this.checkedItems.has(id)) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    onCardChecked(id: string): void {

        this.updateCardCheckedSet(id);

    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.items.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));

    }

    refreshCheckedStatus() {

        const listOfEnabledData = this.items.filter(({disabled}) => !disabled);
        this.checked = listOfEnabledData.every(({cnpj}) => this.checkedItems.has(cnpj));
        this.indeterminate = listOfEnabledData.some(({cnpj}) => this.checkedItems.has(cnpj)) && !this.checked;

    }

    resetCheckedSet() {
        this.checkedItems = new Set<string>();
        this.checked = false;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.loading = true;
        this.abstractService.listToTable(params, search).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.extra = response?.extra || {};

            this.refreshCheckedStatus();

            this.loading = false;
        });

    }

    modoDemo(original: string, tipo: string = ''): string {

        const isDemoMode = localStorage.getItem('modoDemo') === 'true';

        if (isDemoMode && original && typeof original.substring === 'function') {

            let retorno = '';

            switch (tipo) {

                case 'empresaCodigoNome':
                    /*const myArray = original.split('-');
                    let empresa = '';
                    if (myArray[1]) {
                        empresa = myArray[1].trim();
                    } else {
                        empresa = myArray[0].trim();
                    }*/

                    retorno = original.substring(0, 4) + '*'.repeat(original.length - 4);
                    break;

                case 'total':
                    retorno = '*'.repeat(original.length);
                    break;

                case 'cnpj':
                    retorno = original.substring(0, 6) + '.***/' + original.substring(11, 16) + '**';
                    break;

                case 'cpf':
                    retorno = '***.' + original.substring(4, 11) + '-**';
                    break;

                case 'cnpjNumber':
                    retorno = original.substring(0, 2) + '.' + original.substring(2, 1) + '**.***/****-**';
                    break;

                default:
                    if (original.length > 4) {
                        retorno = original.substring(0, 3) + '*'.repeat(original.length - 3);
                    } else {
                        retorno = '***';
                    }
                    break;

            }

            return retorno;

        } else {

            return original;

        }


    }

    maskNZDatePicker(event: any) {

        if (event.key !== 'Backspace' && (event.target.value.length === 2 || event.target.value.length === 5)) {
            event.target.value += '/';
        }
    }

    maskNZDateTimePicker(event: any) {
        if (event.key !== 'Backspace' && (event.target.value.length === 2 || event.target.value.length === 5)) {
            event.target.value += '/';
        } else if (event.key !== 'Backspace' && (event.target.value.length === 10)) {
            event.target.value += ' ';
        } else if (event.key !== 'Backspace' && (event.target.value.length === 13)) {
            event.target.value += ':';
        }
    }

    keyPressNumber(evt) {
        const e = evt || window.event;
        const key = e.keyCode || e.which;

        // Apenas números

        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            key >= 48 && key <= 57 ||
            key >= 96 && key <= 105 ||
            key === 8 || key === 9 || key === 13 ||
            key === 35 || key === 36 ||
            key === 37 || key === 39 ||
            key === 46 || key === 45 ||
            key === 17 || key === 86) {
        } else {
            e.returnValue = false;
            if (e.preventDefault) {
                e.preventDefault();
            }
        }
    }

    keyPressNoSpecialChar(evt) {
        const e = evt || window.event;
        const key = e.keyCode || e.which;

        // Letras MAIÚSCULAS, minúsculas e números
        // chamar charUnidentified() no componente para remover acentos

        const specialChars = '"!@#$%¨&*()_+=*-.;:/?><[]{}|^~';

        if (!e.altKey && !specialChars.includes(evt.key) && (
            (key >= 65 && key <= 90) || (key >= 97 && key <= 122) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105) ||
            key === 8 || key === 9 || key === 13 ||
            key === 35 || key === 36 ||
            key === 37 || key === 39 ||
            key === 46 || key === 45)) {
        } else {
            e.returnValue = false;
            if (e.preventDefault) {
                e.preventDefault();
            }
        }
    }

    hasPermissao(permissaoId: string, permissoes: any[]): boolean {

        return permissoes.indexOf(permissaoId) > -1;

    }

    getPermissoesPerfil(): void {
        this.abstractService.getPermissoesPerfil().subscribe((res) => {
            this.currentPermissoes = [];
            this.currentPermissoes = res;
        }, () => {
            this.toastrService.error('Problemas ao definir permissões');
        });
    }

}

<nz-page-header class="site-page-header" nzTitle="Detalhe da Visibilidade"
                nzSubtitle="Defina a visibilidade das empresas que o usuário tera acesso"></nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card [nzTitle]="visibilidade?.descricao" [nzExtra]="extraInfCadastrais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{visibilidade?.created_at ? (visibilidade.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{visibilidade?.updated_at ? (visibilidade.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>
                <a nz-button nzType="link" (click)="editar(formDadosCadastrais)">Alterar</a>
            </ng-template>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">

            <nz-card>

                <nz-card-tab>
                    <nz-tabset
                            [nzSize]="'large'"
                            [nzTabBarExtraContent]="extra"
                            [(nzSelectedIndex)]="tabNumber"
                            (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzTitle="Usuários"></nz-tab>
                        <nz-tab nzTitle="Empresas"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="usuarios"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination.usuarios?.total"
                          [nzPageSize]="pagination.usuarios?.per_page"
                          [nzPageIndex]="pagination.usuarios?.current_page"
                          *ngIf="tabNumber == 0"
                          [nzShowTotal]="rangeTemplateUsuarios"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="login" [nzSortFn]="true">Login</th>
                        <th nzColumnKey="email" [nzSortFn]="true">Email</th>
                        <th [ngStyle]="{'width' : '80px'}"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let usuario of basicTable.data">
                        <td nzLeft>
                            <a nz-button nzType="link"
                               (click)="openTab('/administracao/usuario/detalhe', usuario.id, {id: usuario.id})">{{usuario?.nome}}</a>
                            <nz-tag [nzColor]="'#f50'" *ngIf="!usuario?.ativo"
                                    style="margin-left: 10px; font-size: 10px">
                                Inativo
                            </nz-tag>
                        </td>
                        <td nzLeft>{{usuario?.login}}</td>
                        <td>{{usuario?.email}}</td>
                        <td>
                            <button nz-button
                                    (click)="showConfirmRemoverUsuario(usuario.id)"
                                    nzType="link">
                                Remover
                            </button>
                        </td>

                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateUsuarios let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="empresas"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination.empresas?.total"
                          [nzPageSize]="pagination.empresas?.per_page"
                          [nzPageIndex]="pagination.empresas?.current_page"
                          *ngIf="tabNumber == 1"
                          [nzShowTotal]="rangeTemplateEmpresas"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                        <th nzColumnKey="cnpj" [nzSortFn]="true">Grupo Empresarial</th>
                        <th [ngStyle]="{'width' : '80px'}"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let empresa of basicTable.data">
                        <td nzLeft>
                            <a nz-button nzType="link"
                               (click)="openTab('/administracao/empresa/detalhe', empresa.id, {id: empresa.id})">
                                {{empresa?.nome}}
                            </a>
                            <nz-tag [nzColor]="'#f50'" *ngIf="!empresa?.ativo"
                                    style="margin-left: 10px; font-size: 10px">
                                Inativa
                            </nz-tag>
                        </td>
                        <td>{{empresa?.cnpj | cpfCnpj}}</td>
                        <td>{{empresa?.grupoDescricao}}</td>
                        <td>
                            <button nz-button
                                    (click)="showConfirmRemoverEmpresa(empresa.id)"
                                    nzType="link">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

            </nz-card>
            <ng-template #extra>
                <div class="nav-item d-flex">

                    <button nz-button nzType="primary" style="margin-right: 10px;"
                            (click)="onVincular();">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                        {{tabNumber == 0 ? 'Editar usuários' : 'Editar empresas'}}
                    </button>


                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                               placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar Descrição'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalListaEmpresasVisible"
          [nzTitle]="'Editar Empresas da Visibilidade'" [nzClosable]="true"
          (nzOnCancel)="modalListarEmpresas(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.carregandoEmpresas"></nz-skeleton>

        <div *ngIf="!loadings.carregandoEmpresas"
             style="display: flex; align-items: end; padding: 0 8px 0 15px; width: 100%">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input (keyup)="filtrarFront()" type="text" nz-input placeholder="Busca" [(ngModel)]="buscaInterna"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch (click)="filtrarFront()">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>
            <button nz-button nzType="default" style="margin-right: 10px;"
                    (click)="btnResetSearch();">
                Limpar
            </button>
        </div>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%;"
                  *ngIf="!loadings.carregandoEmpresas">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItemsEmpresas.size }} {{ checkedItemsEmpresas.size === 1 ? 'Empresa Vinculada' : 'Empresas Vinculadas'}}
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-table #basicTable
                  [nzData]="arrayEmpresasFiltradasFront"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  [nzScroll]="{ y: '340px', x: 'auto'}"
                  style="font-size: 10px; width: 100%"
                  *ngIf="!loadings.carregandoEmpresas"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllCheckedEmpresas($event)" [nzChecked]="checked"
                    [nzIndeterminate]="indeterminate"
                    id="check-all-visibilidade">
                </th>
                <th>Empresa</th>
                <th nzAlign="center">CNPJ</th>
                <th nzAlign="center">Grupo Empresarial</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td nzLeft style="width: 30px;" (nzCheckedChange)="onItemCheckedEmpresas(data.id, $event)"
                    [nzChecked]="checkedItemsEmpresas.has(data.id)"
                    [nzDisabled]="data.disabled">
                </td>
                <td>{{ data?.nome }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.cnpj | cpfCnpj }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.grupoNome}}</td>
            </tr>
            </tbody>
        </nz-table>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalListarEmpresas(false);">Fechar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvandoEmpresas" (click)="onSalvarEmpresas()">Salvar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalListaUsuariosVisible"
          [nzTitle]="'Editar Usuários da Visibilidade'" [nzClosable]="true"
          (nzOnCancel)="modalListarUsuarios(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>


        <nz-skeleton *ngIf="loadings.carregandoUsuarios"></nz-skeleton>

        <div *ngIf="!loadings.carregandoUsuarios"
             style="display: flex; align-items: end; padding: 0 8px 0 15px; width: 100%">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input (keyup)="filtrarFront()" type="text" nz-input placeholder="Busca" [(ngModel)]="buscaInterna"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch (click)="filtrarFront()">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>
            <button nz-button nzType="default" style="margin-right: 10px;"
                    (click)="btnResetSearch();">
                Limpar
            </button>
        </div>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%;"
                  *ngIf="!loadings.carregandoUsuarios">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItemsUsuarios.size }} {{ checkedItemsUsuarios.size === 1 ? 'Usuário Vinculado' : 'Usuários Vinculados'}}
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-table #basicTable
                  [nzData]="arrayUsuariosFiltradosFront"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  [nzScroll]="{ y: '340px', x: 'auto'}"
                  style="font-size: 10px; width: 100%"
                  *ngIf="!loadings.carregandoUsuarios"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllCheckedUsuarios($event)" [nzChecked]="checked"
                    [nzIndeterminate]="indeterminate"
                    id="check-all-visibilidade">
                </th>
                <th>Usuário</th>
                <th nzAlign="center">Login</th>
                <th nzAlign="center">Email</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td nzLeft style="width: 30px;" (nzCheckedChange)="onItemCheckedUsuarios(data.id, $event)"
                    [nzChecked]="checkedItemsUsuarios.has(data.id)"
                    [nzDisabled]="data.disabled">
                </td>
                <td>{{ data?.nome }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.login }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.email }}</td>
            </tr>
            </tbody>
        </nz-table>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalListarUsuarios(false);">Fechar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvandoUsuarios" (click)="onSalvarUsuarios()">Salvar
        </button>
    </div>
</nz-modal>
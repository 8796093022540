import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NfeTerceirosService} from './nfe-terceiros.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {StickyHeaderDirective} from '@components/directive/directive';
import * as fileSaver from 'file-saver-es';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-empresa-detalhe',
    templateUrl: './nfe-terceiros.component.html',
    styleUrls: ['./nfe-terceiros.component.scss']
})

export class NfeTerceirosComponent extends AbstractListTable<any> implements OnInit {

    @Input() data;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    tipo = [
        'Entrada',
        'Saída'
    ];

    tipoEmissaoArray = [
        '',
        'Emissão normal (não em contingência)',
        'Contingência FS-IA, com impressão do DANFE em formulário de segurança',
        'Contingência SCAN (Sistema de Contingência do Ambiente Nacional)',
        'Contingência DPEC (Declaração Prévia da Emissão em Contingência)',
        'Contingência FS-DA, com impressão do DANFE em formulário de segurança',
        'Contingência SVC-AN (SEFAZ Virtual de Contingência do AN)',
        'Contingência SVC-RS (SEFAZ Virtual de Contingência do RS)'
    ];

    marcadores = [];

    loadings = {
        nota: true,
        notaW: true,
        notaM: true,
        informacoes: false,
        workflow: false,
        alterandoNota: false,
        tableMarcadores: false,
        addMarcador: false,
        danfe: false,
        xml: false,
    };

    loadingRemoverMarcador = {};

    target: any;
    offsetTop = 15;

    notaFiscal: any = {};
    etapas = [];
    statusArray = [];
    statusDescricao = null;
    etapasPossiveisParaEtapaAtual = [];

    disabledAlterar = false;

    etapaAtual = null;

    formNota: FormStack;
    formAddMarcador: FormStack;

    tabNumber = 1;

    novoMarcadorDescricao = null;
    corNovoMarcador = '#000';

    modalNovoMarcador = false;

    modeloDescricao = {
        1: '01 - Nota Fiscal Manual',
        2: '02 - Nota Fiscal de Venda ao Consumidor',
        6: '06 - Conta Energia Elétrica',
        7: '07 - Serviço de Transporte',
        8: '08 - Conhecimento de Transporte Rodoviário',
        21: '21 - Serviço de Comunicação',
        22: '22 - Serviço de Telecomunicação',
        28: '28 - Conta de Gás Canalizado',
        29: '29 - Conta de Água Canalizada',
        55: '55 - Nota Fiscal Eletrônica',
        57: '57 - Conhecimento de Transporte Eletrônico',
        65: '65 - Nota Fiscal ao Consumidor Eletrônica',
        67: '67 - Conhecimento de Transporte eletrônico para Outros Serviços',
    };

    statusSelecionado = null;
    showMotivo = false;

    constructor(
        private fb: UntypedFormBuilder,
        private nfeTerceirosService: NfeTerceirosService,
        private toastService: ToastrService
    ) {

        super(nfeTerceirosService, {}, toastService);

        this.formNota = {
            modalVisible: false,
            formGroup: this.fb.group({
                etapa_id: [null, Validators.required],
                motivo: [null],
            })
        };

        this.formAddMarcador = {
            modalVisible: false,
            formGroup: this.fb.group({
                marcador_id: [null, Validators.required],
                nota_id: [null, Validators.required]
            })
        };

    }

    ngOnInit(): void {
        this.getEtapas();
        this.getStatus();
        this.getNota();
    }

    ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;
    }

    getEtapas() {

        this.nfeTerceirosService.getEtapas().subscribe({
            next: (res) => {
                this.etapas = res;

            }, error: (err) => {
                this.toastService.error(err.error.mesage);
            }
        });
    }

    getStatus() {

        this.nfeTerceirosService.getStatus().subscribe({
            next: (res) => {
                this.statusArray = res;
                this.statusDescricao = this.statusArray.find(s => s.id === this.notaFiscal.status_id).descricao;
            }, error: (err) => {
                this.toastService.error(err.error.mesage);
            }
        });

    }

    preencheEtapasPossiveis() {
        this.etapasPossiveisParaEtapaAtual = [];

        if (this.etapaAtual?.mudaPara?.length) {
            this.etapas.forEach(e => {
                if (this.etapaAtual.mudaPara.includes(String(e.id))) {
                    this.etapasPossiveisParaEtapaAtual.push(e);
                }
            });
        } else {
            this.disabledAlterar = true;
        }
    }

    getNota() {

        this.loadings.nota = true;

        this.nfeTerceirosService.getNota(this.data).subscribe({
            next: (res) => {

                this.notaFiscal = res;

                this.loadings.nota = false;

                this.etapaAtual = this.etapas.find(e => e.id === this.notaFiscal.etapaAtual);

                this.getNotaWorkflow();
                this.getMarcadores();

            }, error: (err) => {
                this.loadings.nota = false;
                this.toastService.error(err.error.mesage);
            }
        });
    }

    getNotaWorkflow() {

        this.nfeTerceirosService.getNotaWorkflow(this.notaFiscal.id).subscribe({
            next: (res) => {

                this.notaFiscal.etapas_workflow = res.etapasWorkflow;

                this.etapaAtual = this.etapas.find(e => e.id === res.etapaAtual);

                this.loadings.notaW = false;

                this.preencheEtapasPossiveis();

            }, error: (err) => {
                this.loadings.notaW = false;
                this.toastService.error(err.error.mesage);
            }
        });

    }

    getMarcadores() {

        this.loadings.notaM = true;

        this.nfeTerceirosService.getMarcadores().subscribe({
            next: (res) => {

                this.marcadores = res;

                this.loadings.notaM = false;

            }, error: (err) => {
                this.loadings.notaM = false;
                this.toastService.error(err.error.mesage);
            }
        });
    }

    showModalAlterar() {

        this.formNota.modalVisible = !this.formNota.modalVisible;

        if (!this.formNota.modalVisible) {
            this.formNota.formGroup.reset();
        }
    }

    showModalAdicionarMarcador(visible: boolean) {
        this.formAddMarcador.formGroup.reset();
        this.formAddMarcador.modalVisible = visible;

        const ids = this.notaFiscal.marcadores.map(m => m.marcador);

        this.marcadores = this.marcadores?.filter(m => {
            if (!ids.includes(m.marcador)) {
                return m;
            }
        });
    }

    alterarNota() {

        if (this.formNota.formGroup.valid) {

            this.loadings.alterandoNota = true;

            const data = {
                ids: [`${this.notaFiscal.modelo_id}_${this.data.emissao}_${this.notaFiscal.id}`],
                etapa: this.formNota.formGroup.get('etapa_id').value,
                motivo: this.formNota.formGroup.get('motivo').value
            };

            this.nfeTerceirosService.alterarNota(data).subscribe({
                next: (res) => {
                    this.loadings.alterandoNota = false;
                    this.showModalAlterar();
                    this.toastService.success(res.message);
                    this.getNota();
                    this.formNota.formGroup.reset();

                }, error: (err) => {
                    this.loadings.alterandoNota = false;
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formNota.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    downloadXml(nota): void {

        this.loadings.xml = true;

        this.nfeTerceirosService.downloadXml(nota.id).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/xml'});

            fileSaver.saveAs(blob, nota.chave + this.modeloDescricao[nota.modelo_id] || 'arquivo' + '.xml');

            this.loadings.xml = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadings.xml = false;
        });

    }

    downloadDanfe(nota): void {

        this.loadings.danfe = true;

        this.nfeTerceirosService.downloadDanfe(nota.id).subscribe((res) => {

            const blob = new Blob([res], {type: 'application/pdf'});

            let pos = '';
            switch (Number(nota.modelo_id)) {
                case 55: {
                    pos = '-ProcNFe';
                    break;
                }
                case 57: {
                    pos = '-ProcCTe';
                    break;
                }

            }

            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);

            this.loadings.danfe = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadings.danfe = false;
        });

    }

    changeTabs(event: any) {
        this.tabNumber = event.index;
    }

    novoMarcador(existente: string = null): void {

        this.novoMarcadorDescricao = existente ? existente : this.novoMarcadorDescricao;

        if (existente) {
            this.marcadores?.forEach(m => {
                if (m.marcador === existente) {
                    this.corNovoMarcador = m.cor;
                }
            });
        }

        if (this.novoMarcadorDescricao) {
            this.loadings.addMarcador = true;
            const id = `${this.data.modelo}_${this.data.emissao}_${this.data.chave}`;
            this.nfeTerceirosService.novoMarcador(this.novoMarcadorDescricao, this.corNovoMarcador, [id])
                .subscribe((response) => {
                    this.toastrService.success(response.message || 'Ação realizada com sucesso.');
                    this.showModalAdicionarMarcador(false);
                    this.showModalNovoMarcador(false);
                    this.novoMarcadorDescricao = null;
                    this.corNovoMarcador = '#000';
                    this.loadings.addMarcador = false;
                    this.getNota();
                    this.getMarcadores();
                }, error => {
                    this.toastService.error(error.error.message);
                });
        } else {
            this.loadings.addMarcador = false;
            this.toastService.error('Marcador inválido!');
        }
    }

    showModalNovoMarcador(visible) {
        this.modalNovoMarcador = visible;
    }

    removerMacador(idMarcador) {

        this.loadingRemoverMarcador[idMarcador] = true;

        const ids = [`${this.data.modelo}_${this.data.emissao}_${this.data.chave}`];

        this.nfeTerceirosService.removerMarcador(idMarcador, ids).subscribe((response) => {
            this.toastrService.success(response.message || 'Ação realizada com sucesso.');
            this.loadings.tableMarcadores = true;
            const mRestabtes = [];
            this.notaFiscal.marcadores.forEach(m => {
                if (m.marcador !== idMarcador) {
                    mRestabtes.push(m);
                }
            });
            this.notaFiscal.marcadores = mRestabtes;
            this.novoMarcadorDescricao = null;
            this.loadingRemoverMarcador[idMarcador] = false;
            this.loadings.tableMarcadores = false;
        }, error => {
            this.toastService.error(error.error.message);
            this.loadingRemoverMarcador[idMarcador] = false;
            this.loadings.tableMarcadores = false;
        });

    }

    changeStatus() {

        const manifestacaoTipo_id = this.etapasPossiveisParaEtapaAtual.find(s => s.id === this.statusSelecionado)?.manifestacaoTipo_id;

        if (manifestacaoTipo_id === 3) {
            this.showMotivo = true;
            this.formNota.formGroup.get('motivo').setValidators(Validators.required);
        } else {
            this.showMotivo = false;
            this.formNota.formGroup.get('motivo').clearValidators();
            this.formNota.formGroup.get('motivo').setValue(null);
        }

        this.formNota.formGroup.get('motivo').updateValueAndValidity();
    }

}

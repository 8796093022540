import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Vencimento} from '@models/vencimento.model';
import {ObrigacaoEmpresaService} from './obrigacao-empresa.service';
import {DataService} from '@services/data.service';
import * as fileSaver from 'file-saver-es';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ObrigacaoEmpresaPublicService} from './obrigacao-empresa-public.service';
import {ActivatedRoute} from '@angular/router';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-obrigacao-detalhe',
    templateUrl: './obrigacao-empresa-detalhe.component.html',
    styleUrls: ['./obrigacao-empresa-detalhe.component.scss'],
})
export class ObrigacaoEmpresaDetalheComponent extends AbstractListTable<Vencimento> implements OnInit {
    @Input() data;


    obrigacao: any = {};

    arrayDados: any = [];
    arrayDadosTimeline: any = [];

    loadings = {
        table: false,
        cabecalho: false,
        downloads: [],
        abrirAnexos: [],
        removerAnexos: [],
        timeline: false,
        comboUsuarios: false,
        exportExcel: false,
        verAnexos: false,
        uploadinAnexo: false,
        observacao: false,
        observacaoRemover: false,
        observacaoEditando: {},
        disparandoGatilho: {}
    };

    qtdFiltrosAtivos = 0;

    currentUser: any = null;

    formFiltros: FormStack;
    formExportar: FormStack;
    formGatilhos: FormStack;

    visualizacao = 'table';
    obrigacaoEmpresa: any;

    mesAbrev = {
        1: 'Jan',
        2: 'Fev',
        3: 'Mar',
        4: 'Abr',
        5: 'Mai',
        6: 'Jun',
        7: 'Jul',
        8: 'Ago',
        9: 'Set',
        10: 'Out',
        11: 'Nov',
        12: 'Dez',
    };

    mesDescricao = {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
        8: 'Agosto',
        9: 'Setembro',
        10: 'Outubro',
        11: 'Novembro',
        12: 'Dezembro',
    };

    comboUsuarios: any[] = [];
    status = false;
    reverse = false;
    modalVerAnexosVisible = false;
    fileList: NzUploadFile[] = [];
    modalAdicionarAnexosVisible = false;
    modalObservacaoVisible = false;

    iframeUrl: SafeResourceUrl = '';

    zipNome = '';
    zipId = '';
    listOfAnexos = [];

    arquivoUrl = '';

    identificador = '';

    observacaoSelecionadaId: any = false;
    observacaoValue = null;

    comboGatilhos = [];

    constructor(
        private fb: UntypedFormBuilder,
        private service: ObrigacaoEmpresaService,
        private servicePublic: ObrigacaoEmpresaPublicService,
        private toastService: ToastrService,
        private dataService: DataService,
        public sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private modalService: NzModalService
    ) {
        super(service, Vencimento, toastService);

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                evento: [null, null],
                usuario_id: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formGatilhos = {
            modalVisible: false,
            formGroup: this.fb.group({
                evento: [null, Validators.required],
                acao: [null, Validators.required],
                md5: [null, Validators.required],
                obrigacaoEmpresa_id: [null, Validators.required],
            })
        };

        this.identificador = localStorage.getItem('identificador');

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });

        this.getComboUsuarios();
    }

    getComboUsuarios(): void {

        if (this.currentUser) {
            this.loadings.comboUsuarios = true;
            this.service.getComboUsuarios().subscribe((res) => {
                this.comboUsuarios = res;
                this.loadings.comboUsuarios = false;
            }, (res) => {
                this.loadings.comboUsuarios = false;
                this.toastService.error('Erro ao obter lista de usuários');
            });
        } else {
            this.getComboUsuariospublico();
        }
    }

    getComboUsuariospublico(): void {
        this.loadings.comboUsuarios = true;

        this.servicePublic.getComboUsuarios(this.identificador).subscribe((res) => {
            this.comboUsuarios = res;
            this.loadings.comboUsuarios = false;
        }, (res) => {
            this.loadings.comboUsuarios = false;
            this.toastService.error('Erro ao obter lista de usuários');
        });

    }

    ngOnInit(): void {

        if (this.currentUser && (!this.data || !this.data.id)) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        } else if (!this.currentUser) {
            this.activatedRoute.params.subscribe(res => {
                this.data = {};
                this.data.id = res.id;
            });
        }

        this.carregaDados().then(() => {
            // this.queryTableArmazenamento(this.currentParams.armazenamento, this.currentSearch.armazenamento);
        });

        if (this.currentUser.origem === 'console') {
            this.getGatilhos();
        }
    }

    async carregaDados(loading: boolean = true) {

        this.modalVerAnexosVisible = false;

        if (this.currentUser) {
            this.loadings.cabecalho = loading;
            this.service.retornaDados(this.data.id).subscribe((res) => {
                this.loadings.cabecalho = false;
                this.obrigacaoEmpresa = res;
            }, (res) => {
                this.loadings.cabecalho = false;
                this.toastService.error(res.error.message);
            });

        } else {
            this.carregaDadosPublico();
        }

    }

    async carregaDadosPublico(loading: boolean = true) {

        this.loadings.cabecalho = loading;
        this.servicePublic.retornaDados(this.data.id, this.identificador).subscribe((res) => {
            this.loadings.cabecalho = false;
            this.obrigacaoEmpresa = res;
        }, (res) => {
            this.loadings.cabecalho = false;
            this.toastService.error(res.error.message);
        });

    }

    queryTableHistorico(params: NzTableQueryParams, search: string = null): void {

        if (this.currentUser) {
            this.currentParams = params;
            this.currentSearch = search;
            this.loadings.table = true;

            const filtros = this.formFiltros.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                if (valor) {
                    this.currentParams.filter.push({key: chave, value: valor});
                }
            }

            this.calculaBadgeFiltros();

            const url = this.service.newBaseUrl + this.data.id;

            this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

                this.loadings.table = false;
                this.arrayDados = [];
                this.arrayDados = response.data;

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

            }, (res) => {
                this.toastService.error('Erro ao caregar dados.');
                this.loadings.table = false;
            });

        } else {
            this.queryTableHistoricoPublico(params, search);
        }

    }

    queryTableHistoricoPublico(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;
        this.currentSearch = search;
        this.loadings.table = true;

        const filtros = this.formFiltros.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.calculaBadgeFiltros();

        const url = this.servicePublic.newBaseUrl + this.data.id;

        this.servicePublic.listToTablePublic(this.currentParams, this.currentSearch, url, this.identificador).subscribe((response) => {

            this.loadings.table = false;
            this.arrayDados = [];
            this.arrayDados = response.data;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, (res) => {
            this.toastService.error('Erro ao caregar dados.');
            this.loadings.table = false;
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltros !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos++;
                }
            }
        }
    }

    queryTableSemFiltros(): void {

        if (this.currentUser) {

            this.loadings.timeline = true;

            const params = this.currentParams;
            params.filter = [];
            params.pageIndex = 1;
            params.pageSize = 99999;
            params.sort = [];

            const url = this.service.newBaseUrl + this.data.id;

            this.abstractService.listToTable(params, '', url).subscribe((response) => {

                this.loadings.timeline = false;
                this.arrayDadosTimeline = [];
                this.arrayDadosTimeline = response.data;

            }, (res) => {
                this.toastService.error('Erro ao caregar dados.');
                this.loadings.timeline = false;
            });

        } else {
            this.queryTableSemFiltrosPublico();
        }

    }

    queryTableSemFiltrosPublico(): void {

        this.loadings.timeline = true;

        const params = this.currentParams;
        params.filter = [];
        params.filter.push({key: 'identificador', value: this.identificador});
        params.pageIndex = 1;
        params.pageSize = 99999;
        params.sort = [];

        const url = this.servicePublic.newBaseUrl + this.data.id;

        this.servicePublic.listToTable(params, '', url).subscribe((response) => {

            this.loadings.timeline = false;
            this.arrayDadosTimeline = [];
            this.arrayDadosTimeline = response.data;

        }, (res) => {
            this.toastService.error('Erro ao caregar dados.');
            this.loadings.timeline = false;
        });

    }

    modalFiltrar(visible: boolean): void {
        this.formFiltros.modalVisible = visible;
    }

    btnResetSearch() {
        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltros.formGroup.reset();

        this.queryTableHistorico(this.currentParams, this.currentSearch);

    }

    filtrar(): any {
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.queryTableHistorico(this.currentParams, this.currentSearch);
        this.modalFiltrar(false);

    }

    abrevMes(competencia: string): string {

        if (competencia) {
            return this.mesAbrev[Number(competencia.substr(5, 2))];
        }

    }

    retornoMesDescricao(competencia: string): string {

        if (competencia) {
            return this.mesDescricao[Number(competencia.substr(5, 2))];
        }

    }

    abrevAno(competencia: string): string {

        if (competencia) {
            return competencia.substr(0, 4);
        }

    }

    changeVisualizacao(visualizacao: string): void {
        this.visualizacao = visualizacao;

        switch (visualizacao) {
            case 'timeline': {
                this.queryTableSemFiltros();
                break;
            }
        }

    }

    exportDocumento(id: string, arquivoNome: string, driveArquivoId: string) {

        if (this.currentUser) {
            this.loadings.downloads[id] = true;

            const orgao = this.obrigacaoEmpresa.obrigacaoOrgao === 'Estadual' ? 'estaduais' : 'federais';


            this.service.exportDocumento(driveArquivoId, orgao).subscribe((res) => {

                this.loadings.downloads[id] = false;
                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
                fileSaver.saveAs(blob, arquivoNome + '.zip');

            });

        } else {
            this.exportDocumentoPublico(id, arquivoNome, driveArquivoId);
        }
    }

    exportDocumentoPublico(id: string, arquivoNome: string, driveArquivoId: string) {

        this.loadings.downloads[id] = true;

        const orgao = this.obrigacaoEmpresa.obrigacaoOrgao === 'Estadual' ? 'estaduais' : 'federais';


        this.servicePublic.exportDocumento(driveArquivoId, orgao, this.identificador).subscribe((res) => {

            this.loadings.downloads[id] = false;
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome + '.zip');

        });
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    confirmaExportar(): void {

        if (this.currentUser) {

            if (this.formExportar.formGroup.valid) {
                const tipo = this.formExportar.formGroup.value.tipo;

                this.loadings.exportExcel = true;
                this.service.exportExcel(this.obrigacaoEmpresa.id, this.formFiltros.formGroup.value, this.currentSearch, tipo)
                    .subscribe((res) => {

                        this.loadings.exportExcel = false;
                        if (this.formExportar.formGroup.value.tipo) {

                            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                            const name = this.obrigacaoEmpresa.obrigacaoDescricao + ' - Histórico da Obrigação';

                            fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                            this.formExportar.modalVisible = false;
                        }

                    }, (res) => {
                        this.toastService.error('Erro ao exportar dados.');
                        this.loadings.exportExcel = false;
                    });

            } else {

                Object.values(this.formExportar.formGroup.controls).forEach(control => {
                    if (control.invalid) {
                        control.markAsDirty();
                        control.updateValueAndValidity({onlySelf: true});
                    }
                });

            }

        } else {
            this.confirmaExportarPublico();
        }
    }

    confirmaExportarPublico(): void {

        if (this.formExportar.formGroup.valid) {
            const tipo = this.formExportar.formGroup.value.tipo;

            this.loadings.exportExcel = true;
            this.servicePublic.exportExcel(
                this.obrigacaoEmpresa.id, this.formFiltros.formGroup.value, this.currentSearch, tipo, this.identificador)
                .subscribe((res) => {

                    this.loadings.exportExcel = false;
                    if (this.formExportar.formGroup.value.tipo) {

                        const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                        const name = this.obrigacaoEmpresa.obrigacaoDescricao + ' - Histórico da Obrigação';

                        fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                        this.formExportar.modalVisible = false;
                    }

                }, (res) => {
                    this.toastService.error('Erro ao exportar dados.');
                    this.loadings.exportExcel = false;
                });

        } else {

            Object.values(this.formExportar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }


    }

    clickEvent() {
        this.status = !this.status;
    }

    alterReserve() {
        this.reverse = !this.reverse;
    }

    modalAdicionarAnexo(visible: boolean): void {
        this.modalAdicionarAnexosVisible = visible;
    }

    modalObservacao(visible: boolean, id = null, observacao = null): void {
        this.observacaoSelecionadaId = id;
        this.observacaoValue = observacao;
        this.modalObservacaoVisible = visible;
    }

    confirmAdicionar(html: any, htmlFooter: any) {
        this.modalService.create({
            nzTitle: 'O que deseja adicionar?',
            nzContent: html,
            nzFooter: htmlFooter,
            nzCancelText: 'Cancelar',
            nzWidth: 320
        });
    }

    destroyTplModal(modelRef: NzModalRef, opt = 0): void {
        modelRef.destroy();

        opt === 1 ? this.onAdicionarAnexo() : this.modalObservacao(true);
    }

    onAdicionarAnexo(): void {
        this.fileList = [];
        this.modalAdicionarAnexo(true);
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        return false;
    }

    confirmaAdicionaAnexos(): void {

        this.loadings.uploadinAnexo = true;
        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivos[]', file);
        });

        this.service.uploadAnexos(this.obrigacaoEmpresa.id, formData).subscribe((res) => {
            this.toastService.success('Anexo(s) adicionado(s) com sucesso!');
            this.queryTableHistorico(this.currentParams, this.currentSearch);
            this.modalAdicionarAnexo(false);
            this.loadings.uploadinAnexo = false;
            this.modalAnexos(false);
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.uploadinAnexo = false;
        });
    }

    setObservacao(): void {

        this.loadings.observacao = true;

        if (this.observacaoSelecionadaId) {
            this.loadings.observacaoEditando[this.observacaoSelecionadaId] = true;
        }

        this.service.setObservacao(this.obrigacaoEmpresa.id, this.observacaoValue, this.observacaoSelecionadaId).subscribe((res) => {
            this.toastService.success('Operação realizada com sucesso!');
            this.queryTableHistorico(this.currentParams, this.currentSearch);
            this.modalObservacao(false);
            this.loadings.observacao = false;
            this.loadings.observacaoEditando[this.observacaoSelecionadaId] = false;
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.observacao = false;
            this.loadings.observacaoEditando[this.observacaoSelecionadaId] = false;
        });
    }

    removerObservacao(): void {

        this.loadings.observacaoRemover = true;
        this.loadings.observacaoEditando[this.observacaoSelecionadaId] = true;

        this.service.removerObservacao(this.observacaoSelecionadaId).subscribe((res) => {
            this.toastService.success('Operação realizada com sucesso!');
            this.queryTableHistorico(this.currentParams, this.currentSearch);
            this.modalObservacao(false);
            this.loadings.observacaoRemover = false;
            this.loadings.observacaoEditando[this.observacaoSelecionadaId] = false;
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.observacaoRemover = false;
            this.loadings.observacaoEditando[this.observacaoSelecionadaId] = false;
        });
    }

    modalAnexos(visible: boolean, anexos = []): void {
        if (visible && (!anexos || anexos.length === 0)) {
            this.listOfAnexos = [];
            this.toastService.warning('Sem arquivos para exibir');
        } else {
            this.listOfAnexos = anexos;
            this.modalVerAnexosVisible = visible;
        }
    }

    showModalGatilhos(visible: boolean) {
        this.formGatilhos.formGroup.reset();
        this.formGatilhos.modalVisible = visible;
    }

    getGatilhos() {

        this.service.getComboGatilhos(this.data?.id).subscribe({
            next: (res) => {
                this.comboGatilhos = res;
            }
        });
    }

    dispararGatilho() {

        const acao = this.comboGatilhos.find(g => g.evento === this.formGatilhos.formGroup.get('evento').value).acao;

        this.formGatilhos.formGroup.get('acao').setValue(acao);
        this.formGatilhos.formGroup.get('md5').setValue(this.obrigacaoEmpresa.md5);
        this.formGatilhos.formGroup.get('obrigacaoEmpresa_id').setValue(this.data.id);

        if (this.formGatilhos.formGroup.valid) {

            this.loadings.disparandoGatilho = true;

            this.service.dispararGatilho(this.formGatilhos.formGroup.value).subscribe({
                next: (res) => {
                    this.loadings.disparandoGatilho = false;
                    this.showModalGatilhos(false);
                    this.toastService.success('Gatilho disparado com sucesso.');

                },
                error: (err) => {
                    this.loadings.disparandoGatilho = false;
                    this.toastService.error('Erro ao disparar gatilho! ' + err);

                }
            });
        } else {
            Object.values(this.formGatilhos.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    redispararGatilho(gatilho) {

        const data = {
            evento: gatilho.gatilho_evento,
            acao: gatilho.gatilho_acao,
            obrigacaoEmpresa_id: this.data.id,
            origem: 'historico'
        };
        this.loadings.disparandoGatilho[gatilho.id] = true;

        this.service.dispararGatilho(data).subscribe({
            next: (res) => {
                this.toastService.success(res.message);
                this.loadings.disparandoGatilho[gatilho.id] = false;
            },
            error: (err) => {
                this.toastService.error(err.error.message);
                this.loadings.disparandoGatilho[gatilho.id] = false;
            }
        });
    }
}

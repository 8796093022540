import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {UsuarioEquipeService} from '@services/usuarioEquipe.service';
import {RegrasPisCofinsImportarMovimentoService} from './regras-pis-cofins-importar-movimento.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-usuarios',
    templateUrl: './regras-pis-cofins-importar-movimento.component.html',
    styleUrls: ['./regras-pis-cofins-importar-movimento.component.scss']
})
export class ImportarRegrasPisCofinsComponent extends AbstractListTable<Usuario> {

    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalFiltrarVisible = false;

    cadastrando = false;

    // Novos
    formFiltrar: FormStack;

    comboFornecedores: any[] = [];

    buscando = false;
    importando = false;

    movimentacoes: any[] = [];

    showTable = false;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioEquipeService: UsuarioEquipeService,
        private toastService: ToastrService,
        private service: RegrasPisCofinsImportarMovimentoService,
        private tabService: TabService) {
        super(usuarioEquipeService, Usuario, toastService);


        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                competencia: [null, null],
            })
        };

        const fornecedores = [
            {
                cnpj: '01844555002630',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '01844555002711',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '01844555002800',
                nome: 'CNH INDUSTRIAL BRASIL LTDA'
            },
            {
                cnpj: '43999424000114',
                nome: 'VOLVO DO BRASIL VEÍCULOS LTDA'
            },
            {
                cnpj: '43999424000629',
                nome: 'VOLVO DO BRASIL VEÍCULOS LTDA'
            }
        ];

        this.comboFornecedores = [...this.comboFornecedores, ...fornecedores];


    }

    buscarMovimentacoes(): void {
        this.buscando = true;
        this.showTable = false;

        const dados = this.formFiltrar.formGroup.value;

        const cnpjs = [];
        this.comboFornecedores.forEach((value) => {
            cnpjs.push(value.cnpj);
        });

        dados.cnpj = cnpjs;

        this.service.retornaMovimentacoes(dados).subscribe((response) => {
            this.movimentacoes = [];

            response.forEach(value => {
                value.substituir = false;
            });

            this.movimentacoes = response;
            this.buscando = false;
            this.showTable = true;
        });
    }

    importar(): void {

        this.importando = true;
        this.service.importar(this.movimentacoes).subscribe((response) => {
            this.toastService.success(response.message);
            this.importando = false;
            this.showTable = false;
            this.formFiltrar.modalVisible = false;
        });
    }

    cancelarImportar(): void {

        this.importando = false;
        this.showTable = false;
    }
}

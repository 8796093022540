import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {EditarGatilhosService} from './editar-gatilhos.service';
import {environment} from '../../../../environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StickyHeaderDirective} from '@components/directive/directive';
import {ContaService} from '@services/conta.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-editar-gatilhos',
    templateUrl: './editar-gatilhos.component.html',
    styleUrls: ['./editar-gatilhos.component.scss']
})
export class CadastrosEditarGatilhosComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {
    @Input() data: any;
    formAddGatilho: FormStack;
    formEditGatilho: FormStack;
    qtdFiltrosAtivos;
    comboEventos = [];
    comboObrigacoes = [];
    comboCertidoes = [];
    comboAcoes = [];
    comboUsuariosAcoes = [];
    comboUsuariosNotificacoes = [];
    steps = {
        selecionarEvento: true,
        selecionarCondicaoEvento: false,
        selecionarAcao: true,
        selecionarObrigacao: true,
        selecionarCondicaoUsuarioAprovacao: false,
        selecionarUsuarioAprovacao: false,
        selecionarUsuarioNotificacao: false,
    };
    loadingAtivo = {};
    obrigacaoSelecionada;
    usuariosSelecionados = [];
    condicaoSelecionada = '';

    obrigacoes = [];
    empresas = [];
    certidoes = [];
    modalObrigacoesVisible = false;
    modalUnidadesVisible = false;
    modalCertidoesVisible = false;


    eventosSelecionados = [];
    eventosSelecionadosEditar = [];

    arrayModulos = [];

    loadings = {
        adicionar: false,
        editar: false,
        remover: []
    };

    todasAsUnidades = true;
    arraySelectUnidades = [];

    eventosComVinculoAprovacoes = ['E13', 'E14'];
    eventosComVinculoAgenda = ['E1', 'E3'];
    eventosComVinculoAgendaManual = ['E16', 'E17'];

    loadingComboObg = false;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private editarGatilhosService: EditarGatilhosService,
        private modalService: NzModalService,
        private contaService: ContaService,
        private cdr: ChangeDetectorRef) {
        super(editarGatilhosService, null, toastService);

        this.editarGatilhosService.retornaComboEventos().subscribe((retorno: any) => {
            Object.entries(retorno).forEach((value: any) => {
                this.comboEventos.push({
                    value: value[0],
                    label: value[1]
                });
            });
        });

        this.retornaComboObrigacoes();

        this.editarGatilhosService.retornaComboCertidoes().subscribe((retorno: any) => {
            Object.entries(retorno).forEach((value: any) => {
                this.comboCertidoes.push({
                    value: value[1].id,
                    label: value[1].descricao
                });
            });
        });

        this.retornaComboAcoes();

        this.retornaComboUsuarios();

        this.formAddGatilho = {
            modalVisible: false,
            formGroup: this.fb.group({
                obrigacao_id: [null, null],
                obrigacaoGatilho_id: [null, null],
                condicaoEventos: [null, null],
                eventos: [[], null],
                acoes: [[], null],
                condicaoUsuariosAprovacao: [null, null],
                usuariosAprovacao: [[], null],
                usuariosNotificacao: [[], null],
                obrigacoes: [[], null],
                certidoes: [[], null],
                ids: [[], null],
                empresas: [null, Validators.required]
            })
        };

        this.formEditGatilho = {
            modalVisible: false,
            formGroup: this.fb.group({
                obrigacao_id: [null, null],
                obrigacaoGatilho_id: [null, null],
                condicaoEventos: [null, null],
                eventos: [[], null],
                acoes: [[], null],
                condicaoUsuariosAprovacao: [null, null],
                usuariosAprovacao: [[], null],
                usuariosNotificacao: [[], null],
                obrigacoes: [[], null],
                certidoes: [[], null],
                ids: [[], null],
                empresas: [null, Validators.required]
            })
        };

    }

    ngOnInit() {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.contaService.modulosAtivos().subscribe((response) => {
                this.arrayModulos = response;

            },
            (response) => {
                this.toastrService.error(response.error.message);

            }
        );

        this.retornaUnidades();
    }

    ngAfterViewInit() {
        this.formAddGatilho.formGroup.value.obrigacao_id = this.data.id;
    }

    onChecked(checked: boolean): void {
        this.todasAsUnidades = checked;
    }

    retornaUnidades() {
        this.editarGatilhosService.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectUnidades = retorno.empresaNome;
        });

    }

    retornaComboObrigacoes(ids = null) {

        this.loadingComboObg = true;

        let temAprovacao = false;
        let temAgenda = false;
        let temAgendaManual = false;

        if (ids) {
            this.eventosComVinculoAprovacoes.forEach(a => {
                if (ids.includes(a)) {
                    temAprovacao = true;
                }
            });

            this.eventosComVinculoAgenda.forEach(a => {
                if (ids.includes(a)) {
                    temAgenda = true;
                }
            });

            this.eventosComVinculoAgendaManual.forEach(a => {
                if (ids.includes(a)) {
                    temAgendaManual = true;
                }
            });
        }

        this.comboObrigacoes = [];

        this.editarGatilhosService.retornaComboObrigacoes(temAprovacao, temAgenda, temAgendaManual).subscribe((retorno: any) => {
            Object.entries(retorno).forEach((value: any) => {
                this.comboObrigacoes.push({
                    value: value[1].id,
                    label: value[1].descricao
                });
            });

            this.loadingComboObg = false;

            this.cdr.detectChanges();
        });
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.comboObrigacoes.forEach(obg => {
            obg.direction = 'left';
        });

        this.currentParams = params;

        this.currentSearch = search;

        this.loading = true;

        const url = `${environment.apiUrl}/obrigacao/gatilhos`;

        this.abstractService.newListToTable(params, search, url).subscribe((response) => {

            this.obrigacaoSelecionada = response.descricao;

            this.items = [];

            this.items = response?.data || response;

            this.loading = false;

        });

    }

    changeTranferObrigacoes(event: any, tipo: string) {

        const f = tipo === 'adicionar' ? this.formAddGatilho : this.formEditGatilho;

        if (event.to === 'right') {

            event.list.forEach(obg => {
                f.formGroup.value.obrigacoes.push(obg.value);
            });
        } else {

            event.list.forEach(obg => {
                const index = f.formGroup.value.obrigacoes.indexOf(obg.value);
                f.formGroup.value.obrigacoes.splice(index, 1);
            });
        }

    }

    changeTranferCertidoes(event: any, tipo: string) {

        const f = tipo === 'adicionar' ? this.formAddGatilho : this.formEditGatilho;

        if (event.to === 'right') {

            event.list.forEach(obg => {
                f.formGroup.value.certidoes.push(obg.value);
            });
        } else {

            event.list.forEach(cert => {
                const index = f.formGroup.value.certidoes.indexOf(cert.value);
                f.formGroup.value.certidoes.splice(index, 1);
            });
        }

    }

    verObrigacoes(obrigacoes: any) {

        Object.keys(obrigacoes).forEach(key => {
            this.obrigacoes.push(obrigacoes[key]);
        });

        this.showModalObrigacoes(true);
    }

    verCertidoes(certidao: any) {

        Object.keys(certidao).forEach(key => {
            this.certidoes.push(certidao[key]);
        });

        this.showModalCertidoes(true);
    }

    verUnidades(empresas: any) {

        Object.keys(empresas).forEach(key => {
            this.empresas.push(empresas[key]);
        });

        this.showModalUnidades(true);
    }

    showModalObrigacoes(visible: boolean) {

        if (!visible) {
            this.obrigacoes = [];
            this.todasAsUnidades = true;
        }

        this.modalObrigacoesVisible = visible;
    }

    showModalUnidades(visible: boolean) {

        if (!visible) {
            this.empresas = [];
            this.todasAsUnidades = true;
        }

        this.modalUnidadesVisible = visible;
    }

    showModalCertidoes(visible: boolean) {

        if (!visible) {
            this.certidoes = [];
            this.todasAsUnidades = true;
        }

        this.modalCertidoesVisible = visible;
    }

    showHideModal(form: FormStack, visible) {
        form.modalVisible = visible;

        if (!visible) {
            this.todasAsUnidades = true;
            this.comboObrigacoes.forEach(obg => {
                obg.direction = 'left';
            });
        }
    }

    showConfirm(ids: string[]): void {

        this.modalService.confirm({
            nzTitle: 'Deseja <b>remover</b> o gatilho selecionado?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerGatilho(ids)
        });

    }

    removerGatilho(ids: string[]) {

        this.loadings.remover = ids;

        this.editarGatilhosService.removerGatilho(ids).subscribe((retorno: any) => {

            this.toastrService.success(retorno.message);

            this.queryTable(this.currentParams, null);

            this.loadings.remover = [];

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);
            this.loadings.remover = [];

        });

    }

    retornaComboUsuarios() {

        this.comboUsuariosAcoes = [];
        this.comboUsuariosNotificacoes = [];

        this.editarGatilhosService.retornaComboUsuarios().subscribe((retorno: any) => {
            retorno.forEach((value: any) => {

                this.comboUsuariosAcoes.push({
                    value: value.id,
                    label: value.nome,
                    oldLabel: value.nome,
                    selected: false
                });

                this.comboUsuariosNotificacoes.push({
                    value: value.id,
                    label: value.nome,
                    oldLabel: value.nome,
                    selected: false
                });

            });

        });

    }

    retornaComboAcoes(ids = null) {

        this.comboAcoes = [];
        this.editarGatilhosService.retornaComboAcoes(ids).subscribe((retorno: any) => {
            Object.entries(retorno).forEach((value: any) => {
                this.comboAcoes.push({
                    value: value[0],
                    label: value[1]
                });
            });
        });
    }

    verificaEventoAdicionar(event) {

        if (this.eventosSelecionados.includes('E15') && event.length > 1) {
            // Já foi selecionado o E15 antes de qualquer evento
            this.formAddGatilho.formGroup.get('eventos').setValue(['E15']);

            this.toastService.warning('Exclua o(s) evento(s) de certidão para incluir um evento de obrigação');

        } else if (event.length > 1 && event.includes('E15') && this.eventosSelecionados.length > 0) {
            // Evento de obrigação foi selecionado antes do E15
            const eventos = event.filter((e) => e !== 'E15');
            this.formAddGatilho.formGroup.get('eventos').setValue(eventos);
            this.eventosSelecionados = eventos;

            this.toastService.warning('Exclua o(s) evento(s) de obrigação para incluir um evento de certidão');
        } else {
            this.eventosSelecionados = event;
        }

    }

    verificaEventoEditar(event) {

        if (this.eventosSelecionadosEditar.includes('E15') && event.length > 1) {
            this.formEditGatilho.formGroup.get('eventos').setValue(['E15']);

            this.toastService.warning('Exclua o(s) evento(s) de certidão para incluir um evento de obrigação');

        } else if (event.length > 1 && event.includes('E15') && this.eventosSelecionadosEditar.length > 0) {
            // Evento de obrigação foi selecionado antes do E15
            const eventos = event.filter((e) => e !== 'E15');
            this.formEditGatilho.formGroup.get('eventos').setValue(eventos);
            this.eventosSelecionadosEditar = eventos;

            this.toastService.warning('Exclua o(s) evento(s) de obrigação para incluir um evento de certidão');
        } else {
            this.eventosSelecionadosEditar = event;
            this.formEditGatilho.formGroup.get('eventos').setValue(event);
        }

    }

    changeEvento(event, tipo: string) {

        if (tipo === 'adicionar') {
            this.verificaEventoAdicionar(event);
        } else {
            this.verificaEventoEditar(event);
        }

        this.retornaComboAcoes(event);
        this.retornaComboObrigacoes(event);

        this.steps.selecionarCondicaoEvento = tipo === 'adicionar' && this.eventosSelecionados.length > 1 ||
            tipo === 'editar' && this.eventosSelecionadosEditar.length > 1;

        if (event.length === 1) {
            this.formAddGatilho.formGroup.value.condicaoEventos = null;
        }

    }

    changeAcao(event) {

        const enviarAprovacao = event.filter((element) => {
            return element === 'A4';
        });

        const enviarNotificacao = event.filter((element) => {
            return element === 'A5';
        });

        if (enviarAprovacao.length > 0 && this.steps.selecionarUsuarioAprovacao === false ||
            enviarNotificacao.length > 0 && this.steps.selecionarUsuarioNotificacao === false) {
            this.retornaComboUsuarios();
        }

        this.steps.selecionarUsuarioAprovacao = enviarAprovacao.length > 0;

        if (enviarAprovacao.length === 0) {
            this.formAddGatilho.formGroup.value.usuariosAprovacao = [];
            this.steps.selecionarCondicaoUsuarioAprovacao = false;
            this.formAddGatilho.formGroup.value.condicaoUsuariosAprovacao = null;
        }

        this.steps.selecionarUsuarioNotificacao = enviarNotificacao.length > 0;

        if (enviarNotificacao.length === 0) {
            this.formAddGatilho.formGroup.value.usuariosNotificacao = [];
        }

    }

    changeUsuario(event) {

        this.usuariosSelecionados = event;

        if (this.condicaoSelecionada === 'ORDER') {
            this.condicaoHierarquia(this.condicaoSelecionada);
        }

        this.steps.selecionarCondicaoUsuarioAprovacao = event.length > 1;

        if (event.length === 1) {
            this.formAddGatilho.formGroup.value.condicaoUsuariosAprovacao = null;
        }

    }

    condicaoHierarquia(event) {

        this.condicaoSelecionada = event;

        this.comboUsuariosAcoes.forEach((value) => {

            if (event === 'ORDER') {

                if (this.usuariosSelecionados.length > 0) {

                    this.usuariosSelecionados.forEach((valueTwo) => {

                        if (value.value !== valueTwo) {

                            value.label = value.oldLabel;

                            value.selected = false;

                        }

                    });

                    this.usuariosSelecionados.forEach((valueTwo, index) => {

                        if (value.value === valueTwo && !value.selected) {

                            value.oldLabel = value.label;

                            value.label = (index + 1) + 'º ' + value.label;

                            value.selected = true;

                        }

                    });

                } else {

                    value.label = value.oldLabel;

                    value.selected = false;

                }

            } else {

                value.label = value.oldLabel;

                value.selected = false;

            }

        });

    }

    adicionarGatilho() {

        this.loadings.adicionar = true;

        if (this.validaForm(this.formAddGatilho.formGroup)) {

            const data = {};

            if (this.todasAsUnidades) {
                this.formAddGatilho.formGroup.value.empresas = null;
            }

            Object.entries(this.formAddGatilho.formGroup.value).forEach((value: any) => {

                if (value[1] && !Array.isArray(value[1]) || Array.isArray(value[1]) && value[1].length > 0) {
                    data[value[0]] = value[1];
                }
            });

            this.editarGatilhosService.adicionarGatilho(data).subscribe((retorno: any) => {

                this.toastrService.success(retorno.message);

                this.formAddGatilho.modalVisible = false;

                this.queryTable(this.currentParams, null);

                this.clearForm(this.formAddGatilho);

                this.loadings.adicionar = false;

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.steps.selecionarCondicaoEvento = false;
                this.loadings.adicionar = false;

            });


        } else {
            this.loadings.adicionar = false;
        }

    }

    clearForm(form) {

        this.steps = {
            selecionarEvento: true,
            selecionarCondicaoEvento: false,
            selecionarAcao: true,
            selecionarCondicaoUsuarioAprovacao: false,
            selecionarUsuarioAprovacao: false,
            selecionarUsuarioNotificacao: false,
            selecionarObrigacao: true
        };

        form.formGroup.value.obrigacao_id = null;
        form.formGroup.value.obrigacaoGatilho_id = null;
        form.formGroup.value.condicaoEventos = null;
        form.formGroup.value.eventos = [];
        form.formGroup.value.acoes = [];
        form.formGroup.value.condicaoUsuariosAprovacao = null;
        form.formGroup.value.usuariosAprovacao = [];
        form.formGroup.value.usuariosNotificacao = [];
        form.formGroup.value.obrigacoes = [];
        form.formGroup.value.certidoes = [];
        form.formGroup.value.empresas = [];

        this.usuariosSelecionados = [];
        this.condicaoSelecionada = '';

        this.obrigacoes = [];
        this.certidoes = [];

        this.eventosSelecionados = [];
        this.eventosSelecionadosEditar = [];

    }

    showHideModalEditar(form: FormStack, visible, data = null) {

        this.formEditGatilho.formGroup.reset();

        form.modalVisible = visible;

        if (visible === true) {

            this.todasAsUnidades = data.todas_empresas;

            this.formEditGatilho.formGroup.value.ids = data.ids;

            // Eventos

            const eventos: any = [];

            data.eventos.forEach((value) => {
                eventos.push(value.codigo);
            });

            this.formEditGatilho.formGroup.value.eventos = eventos;
            this.eventosSelecionadosEditar = eventos;

            if (data.eventos.length > 1) {
                this.formEditGatilho.formGroup.value.condicaoEventos = data.eventos[0].condicao;
            }

            this.eventosSelecionadosEditar = data.eventos.map(e => e.codigo);

            this.steps.selecionarCondicaoEvento = data.eventos.length > 1;


            // Unidades
            const empresas: any = [];

            if (data.empresas) {
                data.empresas.forEach((value) => {
                    empresas.push(value.id);
                });

                this.formEditGatilho.formGroup.value.empresas = empresas;

            } else {
                this.formEditGatilho.formGroup.value.empresas = null;
            }

            // obrigações Certidões

            const obrigacoes: any = [];
            const certidoes: any = [];

            data.obrigacoes?.forEach((value) => {
                obrigacoes.push(value.nome);
            });

            data.certidoes?.forEach((value) => {
                certidoes.push(value.nome);
            });

            this.comboObrigacoes.forEach(obg => {
                if (obrigacoes.includes(obg.value)) {
                    obg.direction = 'right';
                }
            });

            this.comboCertidoes.forEach(cert => {
                if (certidoes.includes(cert.value)) {
                    cert.direction = 'right';
                }
            });

            this.formEditGatilho.formGroup.value.obrigacoes = obrigacoes;
            this.formEditGatilho.formGroup.value.certidoes = certidoes;

            // Ações

            const acoes: any = [];

            data.acoes.forEach((value) => {
                acoes.push(value.codigo);
            });

            this.formEditGatilho.formGroup.value.acoes = acoes;

            const temAprovacao = data.acoes.filter((element) => {
                return element.codigo === 'A4';
            });

            if (temAprovacao.length > 0 && temAprovacao[0].usuariosAprovacao && temAprovacao[0].usuariosAprovacao.length > 0) {

                this.steps.selecionarUsuarioAprovacao = temAprovacao[0].usuariosAprovacao.length > 0;

                this.steps.selecionarCondicaoUsuarioAprovacao = temAprovacao[0].usuariosAprovacao.length > 1;

                if (temAprovacao[0].usuariosAprovacao.length === 1) {
                    this.formEditGatilho.formGroup.value.condicaoUsuariosAprovacao = null;
                    this.steps.selecionarCondicaoUsuarioAprovacao = false;
                }

                const usuariosAprovacao: any = [];

                temAprovacao[0].usuariosAprovacao.forEach((value) => {
                    usuariosAprovacao.push(value.id);
                });

                this.formEditGatilho.formGroup.value.usuariosAprovacao = usuariosAprovacao;

                this.usuariosSelecionados = usuariosAprovacao;

                if (temAprovacao[0].usuariosAprovacao.length > 1) {
                    this.formEditGatilho.formGroup.value.condicaoUsuariosAprovacao = temAprovacao[0].condicaoUsuariosAprovacao;
                    this.condicaoHierarquia(temAprovacao[0].condicaoUsuariosAprovacao);
                }

            }

            const temNotificacao = data.acoes.filter((element) => {
                return element.codigo === 'A5';
            });

            if (temNotificacao.length > 0 && temNotificacao[0].usuariosNotificacao && temNotificacao[0].usuariosNotificacao.length > 0) {

                this.steps.selecionarUsuarioNotificacao = temNotificacao.length > 0;

                const usuariosNotificacao: any = [];

                temNotificacao[0].usuariosNotificacao.forEach((value) => {
                    usuariosNotificacao.push(value.id);
                });

                this.formEditGatilho.formGroup.value.usuariosNotificacao = usuariosNotificacao;

            }

        } else {

            this.comboObrigacoes.forEach(obg => {
                obg.direction = 'left';
            });

            this.comboCertidoes.forEach(cert => {
                cert.direction = 'left';
            });

            this.eventosSelecionadosEditar = [];

            this.clearForm(this.formEditGatilho);

            this.todasAsUnidades = true;

        }

    }

    editarGatilho() {

        this.loadings.editar = true;

        if (this.validaForm(this.formEditGatilho.formGroup)) {

            const data = {};


            if (this.todasAsUnidades) {
                this.formEditGatilho.formGroup.value.empresas = null;
            }

            Object.entries(this.formEditGatilho.formGroup.value).forEach((value: any) => {

                if (value[1] && !Array.isArray(value[1]) || Array.isArray(value[1]) && value[1].length > 0) {
                    data[value[0]] = value[1];
                }

            });

            this.editarGatilhosService.editarGatilho(data).subscribe((retorno: any) => {

                this.toastrService.success(retorno.message);

                this.formEditGatilho.modalVisible = false;

                this.queryTable(this.currentParams, null);

                this.clearForm(this.formEditGatilho);

                this.eventosSelecionadosEditar = [];

                this.loadings.editar = false;

            }, (response) => {

                this.toastrService.error(response.error.message);

                this.clearForm(this.formEditGatilho);

                this.loadings.editar = false;

            });

        } else {
            this.loadings.editar = false;
        }

    }

    validaForm(form) {

        if (form.value.eventos.length === 0) {
            this.toastrService.error('Por favor, selecione os eventos.');
            return false;
        } else {

            if (form.value.eventos.includes('E15') && form.value.certidoes.length === 0) {
                this.toastrService.error('Por favor, selecione pelo menos uma certidão');
                return false;
            } else if (!form.value.eventos.includes('E15') && form.value.obrigacoes.length === 0) {
                this.toastrService.error('Por favor, selecione pelo menos uma obrigação');
                return false;
            }
        }

        if (this.steps.selecionarCondicaoEvento && form.value.eventos.length > 1 && !form.value.condicaoEventos) {
            this.toastrService.error('Por favor, selecione a condição do evento.');
            return false;
        }

        if (form.value.acoes.length === 0) {
            this.toastrService.error('Por favor, selecione as ações.');
            return false;
        }

        if (this.steps.selecionarUsuarioAprovacao && form.value.usuariosAprovacao.length === 0) {
            this.toastrService.error('Por favor, selecione os usuários de aprovação.');
            return false;
        }

        if (this.steps.selecionarCondicaoUsuarioAprovacao &&
            form.value.usuariosAprovacao.length > 1 && !form.value.condicaoUsuariosAprovacao) {
            this.toastrService.error('Por favor, selecione a condição de aprovação.');
            return false;
        }

        if (this.steps.selecionarUsuarioNotificacao && form.value.usuariosNotificacao.length === 0) {
            this.toastrService.error('Por favor, selecione os usuários de notificação.');
            return false;
        }

        return true;

    }

    toggleGatilho(event, ids: string[]) {

        this.editarGatilhosService.toggleGatilho(event, ids).subscribe((retorno: any) => {

            this.toastrService.success(retorno.message);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

}


<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Perfis de Acesso">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Perfis de Acesso">
        <ng-template #navBar>
            <Navbar-List (generalFilterHandler)="getGeneralSearch($event)" (resetForm)="resetSearch()"
                         [registerComponent]="'RegisterUserComponent'" (registerClick)="modalCadastrar(true)"
                         [tabTitle]="'Cadastrar Usuário'">

                <!--<User-Filter (handleFilterEvent)="getFilterParams($event)" filtro></User-Filter>-->
            </Navbar-List>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                <th nzAlign="center" nzColumnKey="quantidadeMembros">Membros</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Cadastro Data</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let perfil of basicTable.data">
                <td nzLeft>
                    <a nz-button nzType="link"
                       (click)="openTab('/administracao/perfil/detalhe', perfil.id, {id: perfil.id})">
                        {{perfil?.descricao}}
                    </a>
                    <nz-tag *ngIf="perfil.descricao === 'Operadores' || perfil.descricao == 'Administradores'"
                            nzColor="default"
                            style="font-size: 10px">
                        Bloqueado
                    </nz-tag>
                    <nz-tag *ngIf="perfil.admin == 1" nzColor="warning" style=" font-size: 10px">
                        Admin
                    </nz-tag>
                </td>
                <td nzAlign="center">{{perfil.quantidadeMembros ? (perfil.quantidadeMembros | number) : '-' }}</td>
                <td nzAlign="center">{{perfil.created_at ? (perfil.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}</td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="openTab('/administracao/perfil/detalhe', perfil.id, {id: perfil.id})">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button
                            (click)="showConfirmRemover(perfil.id)"
                            nzType="link"
                            disabled="{{perfil.descricao === 'Operadores' || perfil.descricao == 'Administradores'}}">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-content>


<nz-modal [(nzVisible)]="modalCadastrarVisible" [nzTitle]="'Cadastrar Novo Perfil de Acesso'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)">
    <div *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" placeholder="informe a descrição do perfil"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>

import BulkChanges from '@models/entity.model';
import {Utils} from '../utils';
import {CrudModel} from '@models/crud.model';

export class Obrigacao extends CrudModel implements BulkChanges {
    id: string;
    // tslint:disable-next-line:variable-name
    conta_id: string;

    constructor(obj?: any) {

        super(CrudModel);

        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

        }

    }

    convertToSave() {

        const properties = [];
        const obrigacao = new Obrigacao();

        for (const prop of properties) {

            obrigacao[prop] = this[prop];

        }

        if (Utils.isEmpty(obrigacao.id)) {

            delete obrigacao.id;

        }

        return obrigacao;

    }

    toBulk(ids: string | string[], itens: string[]) {

        return {
            ids,
            itens,
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            Obrigacaos: checkedItems,
            visibilidades: propertyChanged
        };
    }
}

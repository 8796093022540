<div class="container-login">
    <img src="" alt="" class="image"
         *ngIf="backgroundImageUrl"
         [ngStyle]="{'background-image': 'url('+ backgroundImageUrl +')', 'background-size' : 'cover'}">
    <style>
        .animatedFade {
            animation-delay: 2s;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }

        @-webkit-keyframes fadeOut {
            0% {
                opacity: 100%;
            }
            100% {
                opacity: 10%;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 100%;
            }
            100% {
                opacity: 10%;
            }
        }

        .fadeOut {
            -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
        }

        .animatedSlide {
            animation-delay: 2s;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }

        @-webkit-keyframes slideLeft {
            0% {
                width: 0px;
            }
            100% {
                width: 270px
            }
        }

        @keyframes slideLeft {
            0% {
                width: 0px;
            }
            100% {
                width: 270px
            }
        }

        .slideLeft {
            -webkit-animation-name: slideLeft;
            animation-name: slideLeft;
        }


    </style>
    <div class="container-form" style="padding: 15px; margin-top: -90px">

        <div *ngIf="identificadorUrl" style="width: 100%; margin-bottom: 20px; margin-top: 2.5%; text-align: center">
            <nz-space [nzSize]="24" *ngIf="srcLogo">
                <img class="imagem-avatar" style="width:30%; margin: 0 auto; margin-bottom: 15px;" src="{{srcLogo}}"
                     alt="{{identificadorUrl}}">
            </nz-space>
            <h2 *ngIf="!srcLogo">{{contaNome ? contaNome : identificadorUrl}}</h2>
        </div>

        <form (ngSubmit)="onSubmit()" [formGroup]="formPasswordRecovey" class="login-form" style="margin-bottom: 45px"
              nz-form>

            <nz-form-item *ngIf="mensagem == 'senhaExpirada'">
                <nz-alert
                        nzType="warning"
                        nzMessage="Sua senha expirou!"
                        [nzDescription]="'Olá, ' + usuarioNome + '. Por motivos de segurança, a sua senha atual não é mais válida. Insira ' +
                     'uma nova senha nos campos abaixo para concluir o login.'"
                        nzShowIcon
                ></nz-alert>
            </nz-form-item>

            <nz-form-item *ngIf="usuarioLogin">
                <nz-form-control>
                    <nz-input-group class="login-input" nzPrefixIcon="user">
                    <input formControlName="login" nz-input
                           type="text">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorPassword">
                    <nz-input-group class="login-input" [nzSuffix]="showPassword" nzPrefixIcon="lock">
                        <input style="border: none; width: 100%"
                               [type]="passwordVisible ? 'text' : 'password'"
                               autocomplete="off"
                               formControlName="password"
                               id="password"
                               autofocus
                               placeholder="Senha"
                               nz-popover
                               nzPopoverPlacement="right"
                               [nzPopoverVisible]="popoverVisibleSenha"
                               [nzPopoverTitle]="validadePasswordTitle"
                               [nzPopoverContent]="validadePasswordContent"
                               (ngModelChange)="verificaSenha($event, true)"
                               (focusout)="validaCampoSenha($event, true)"
                        >
                    </nz-input-group>
                    <ng-template #suffixTemplatePassword>
                        <em (click)="passwordVisible = !passwordVisible"
                            [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                            nz-icon></em>
                    </ng-template>
                    <ng-template #errorPassword let-control>
                        <ng-container *ngIf="control.hasError('incorrect')">Senha não contém os requisitos de segurança</ng-container>
                        <ng-container *ngIf="control.hasError('required')">Campo Obrigatório</ng-container>
                    </ng-template>
                </nz-form-control>
                <ng-template #showPassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #showPasswordConfirm>
                    <span nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'"
                          (click)="confirmPasswordVisible = !confirmPasswordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #validadePasswordTitle>
                    <h5 nz-typography style="margin: 0;">
                        <i class="fa-solid fa-key" style="margin-right: 3px;"></i> Segurança da Senha
                    </h5>
                </ng-template>
                <ng-template #validadePasswordContent>
                    <div [ngStyle]="passwordValidate.length && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.length"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.length" style="margin-right: 3px;"></span>
                        <span>A senha deve conter entre 8 e 30 caracteres.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.lowercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra minúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.uppercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra maiúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.number && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.number"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.number" style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos um número.</span>
                    </div>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorConfirmPassword">
                    <nz-input-group class="login-input" [nzSuffix]="showPasswordConfirm" nzPrefixIcon="lock">
                        <input login-input [type]="confirmPasswordVisible ? 'text' : 'password'" autocomplete="off"
                               formControlName="confirmPassword"
                               id="confirm-password"
                               nz-input placeholder="Confirmar senha"/>
                    </nz-input-group>
                    <ng-template #suffixTemplateConfirmation>
                        <em (click)="confirmPasswordVisible = !confirmPasswordVisible"
                            [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'"
                            nz-icon></em>
                    </ng-template>
                    <ng-template #errorConfirmPassword let-control>
                        <ng-container *ngIf="control.hasError('mustMatch')">
                            As senhas não conferem!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                            É necessário confirmar a senha!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <button style="height: 38px" [nzType]="'primary'" class="login-form-button login-form-margin" nz-button
                    [nzLoading]="loading"
                    type="submit" [disabled]="!formPasswordRecovey.valid">Alterar senha
            </button>
        </form>
        <img alt="logo-mastertax" src="{{logoUrl}}" style="width:10%; margin-bottom: 5px;">
    </div>
</div>


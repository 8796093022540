import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComplianceBaixasEAutomacoesComponent} from './compliance-baixas-e-automacoes.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {UiModule} from '../../ui.module';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzSwitchModule} from "ng-zorro-antd/switch";



@NgModule({
  declarations: [ComplianceBaixasEAutomacoesComponent],
  exports: [ComplianceBaixasEAutomacoesComponent],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        UiModule,
        NzBadgeModule,
        FormsModule,
        NzAlertModule,
        NzTagModule,
        NzSwitchModule,
        ReactiveFormsModule
    ]
})
export class ComplianceBaixasEAutomacoesModule { }

<nz-modal [(nzVisible)]="visible"
          [nzTitle]="this.editando.certificados ? 'Detalhe Certificado Digital' : 'Adicionar Certificado Digital'"
          [nzClosable]="true"
          (nzOnCancel)="showModal(false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <div style="margin-top: 20px;">
            <form nz-form [nzNoColon]="true" [formGroup]="formAdicionarCertificado">
                <nz-form-item *ngIf="!this.editando.certificados">
                    <nz-form-label [nzSpan]="4" nzRequired>Uso:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-radio-group formControlName="uso"
                                        (ngModelChange)="changeIputsAddCerificado($event)"
                                        [nzDisabled]="this.editando.certificados">
                            <label nz-radio nzValue="Próprio">Próprio</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item class="reduce-upload"
                              *ngIf="formAdicionarCertificado.value.uso === 'Próprio' && !this.editando.certificados">
                    <nz-form-label [nzSpan]="4" [nzRequired]="!this.editando.certificados">Arquivo:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-upload
                                nzType="drag"
                                [nzMultiple]="true"
                                nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                                [(nzFileList)]="fileListCertificado" [nzBeforeUpload]="beforeUploadCertificado">
                            <p class="ant-upload-drag-icon">
                                <i nz-icon nzType="inbox"></i>
                            </p>
                            <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o
                                upload</p>
                        </nz-upload>
                        <p class="ant-upload-text" style="color: #3d3d3d; margin-bottom: 0; margin-top: 5px;">Somente
                            arquivos .pfx ou .p12</p>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Uso:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>{{formAdicionarCertificado.value.uso}}</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Arquivo:</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-form-text>{{editarCertificados.nomeArquivo}}</nz-form-text>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="2">
                        <nz-form-text>
                            <button *ngIf="this.currentUser.origem === 'console'" [nzLoading]="loadingDownload"
                                    nz-button nzType="default" (click)="downloadCertificado();">
                                <i nz-icon
                                   nzType="download"
                                   nzTheme="outline"></i>
                            </button>
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Próprio' && this.editando.certificados">
                    <nz-form-label [nzSpan]="4">Senha:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>*********</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Próprio' && !this.editando.certificados">
                    <nz-form-label [nzSpan]="4" [nzRequired]="!this.editando.certificados">Senha:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input nz-input type="password" formControlName="serial" maxlength="20" size="60">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Terceiros'">
                    <nz-form-label [nzSpan]="4" nzRequired>Emitido em:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="emissaoData" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Terceiros'">
                    <nz-form-label [nzSpan]="4" nzRequired>Validade:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="validade" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Categoria:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                            <nz-option [nzLabel]="'eCNPJ'" [nzValue]="'cnpj'"></nz-option>
                            <nz-option [nzLabel]="'eNFE'" [nzValue]="'nfe'"></nz-option>
                        </nz-select>
                    </nz-form-control>

                </nz-form-item>
                <nz-form-item
                        *ngIf="formAdicionarCertificado.value.uso === 'Próprio'">
                    <nz-form-label [nzSpan]="4"></nz-form-label>
                </nz-form-item>
                <nz-form-item
                        *ngIf="dadosCadastrais.matriz && !editando.certificados">
                    <nz-form-label [nzSpan]="4"></nz-form-label>
                </nz-form-item>
                <!-- <nz-form-item
                    *ngIf="this.editando.certificados">
                    <nz-form-label [nzSpan]="4" nzRequired>Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>Douglas Corrêa em {{ formAdicionarCertificado.formGroup.value.emissaoData | date : 'dd/MM/yyyy HH:mm'}}</nz-form-text>
                    </nz-form-control>
                </nz-form-item> -->
                <nz-form-item
                        *ngIf="editarCertificados.created_from_nome">
                    <nz-form-label [nzSpan]="4">Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>{{ editarCertificados.created_from_nome }}
                            em {{ editarCertificados.created_at | date : 'dd/MM/yyyy HH:mm'}}</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loading" *ngIf="!this.editando.certificados"
                (click)="confirmAtualiarProcuracoes()">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="this.editando.certificados" [nzLoading]="loading"
                (click)="updateCertificados()">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalSenhaVisible"
          [nzTitle]="'Essa é a senha do certificado baixado:'" [nzClosable]="true"
          (nzOnCancel)="modalSenha(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formSenha" style="width: 100%;"
              [nzLayout]="'vertical'">
            <nz-form-item>
                <nz-form-label>Senha</nz-form-label>
                <nz-form-control>

                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconSenha">
                        <input nz-input readonly type="{{typeSenha}}" formControlName="senha">
                    </nz-input-group>
                    <ng-template #suffixIconSenha>
                        <button nz-button nzType="default" (click)="mudarTypeSenha();">
                            <span nz-icon nzType="{{typeSenha === 'password' ? 'eye' : 'eye-invisible'}}"></span>
                        </button>


                        <button nz-button nzType="{{boolCopiado ? 'primary' : 'default'}}"
                                (click)="copyToClipboard();">
                            <span nz-icon nzType="{{boolCopiado ? 'check' : 'copy'}}" nzTheme="outline"></span>
                        </button>


                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalSenha(false);">Ok, fechar</button>
    </div>
</nz-modal>

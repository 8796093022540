<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Responsáveis">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze" [nzExtra]="navBar" nzTitle="Responsáveis"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="buscar()"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer"

        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="nome" [nzSortFn]="true" style="text-align: center;">Nome</th>
                <th nzColumnKey="documento" [nzSortFn]="true" style="text-align: center;">Documento</th>
                <th nzColumnKey="documento_tipo" [nzSortFn]="true" style="text-align: center;">Tipo</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" style="text-align: center;">Certificado A1</th>
                <th nzColumnKey="quantiade_procuracoes" [nzSortFn]="true" style="text-align: center;">
                    <span>Procurações<br>E-CAC</span>
                </th>
                <th nzColumnKey="quantiade_acessos_estaduais" [nzSortFn]="true" style="text-align: center;">Acessos
                    Estaduais
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let responsavel of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(responsavel.id, $event)"
                    [nzChecked]="checkedItems.has(responsavel.id)"
                    [nzDisabled]="responsavel.disabled">
                </td>
                <td nzLeft>{{modoDemo(responsavel?.nome, 'empresaCodigoNome')}}</td>
                <td nzLeft style="white-space: nowrap;text-align: center;">
                    {{modoDemo((responsavel?.documento | cpfCnpj), responsavel?.documento_tipo)}}
                </td>
                <td nzLeft style="text-align: center;">{{(responsavel?.documento_tipo | uppercase)}}</td>
                <td nzLeft style="text-align: center;">
                    <nz-alert nzType="success" nzMessage="OK"
                              *ngIf="responsavel.statusVencimento === 2 && !(responsavel.revogado === 1 && !responsavel.temCertificadosValidos)"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencimento em {{responsavel.dias}} dia(s)"></nz-alert>
                    <nz-alert nzType="warning" nzMessage="Vencendo {{responsavel?.validade | date: 'dd/MM/yyyy'}}"
                              *ngIf="responsavel.statusVencimento === 1"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencimento em {{responsavel.dias}} dia(s)"></nz-alert>
                    <nz-alert nzType="error" nzMessage="Vencido {{responsavel?.validade | date: 'dd/MM/yyyy'}}"
                              *ngIf="responsavel.statusVencimento === 0"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencido á {{responsavel.dias}} dia(s)"></nz-alert>
                    <nz-tag style="padding: 8px 15px; font-size: 14px; width: 100%;"
                            *ngIf="!responsavel?.validade">Sem
                        certificado
                    </nz-tag>
                </td>
                <td nzLeft style="text-align: center;">{{responsavel?.quantiade_procuracoes}} Empresa(s)</td>
                <td nzLeft style="text-align: center;">{{responsavel?.quantiade_acessos_estaduais}} Portal(is)
                </td>
                <td nzRight nzAlign="center" style="white-space: nowrap; width: 150px;">
                    <button (click)="openTab('/cadastros/editar-responsavel/', responsavel.id,
                    {id: responsavel.id, documento: responsavel.documento, documento_tipo: responsavel.documento_tipo})"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button (click)="showConfirm(responsavel?.id);"
                            nz-button
                            [nzLoading]="loadings.deletando[responsavel?.id]"
                            nzType="link">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Nome</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.nome !== '' && formFiltrar.formGroup.value.nome !== null">
                    <input nz-input type="text" formControlName="nome" placeholder="Nome">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Documento</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.documento !== '' && formFiltrar.formGroup.value.documento !== null">
                    <input nz-input type="text" formControlName="documento" placeholder="Documento do responsável"
                           (keydown)="verificaMascaraDoc($event, 'filtrar')"
                           (keyup)="verificaMascaraDocBack($event, 'filtrar')"
                           autocomplete="off" mask="{{maskDocFilter}}">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Certificado A1 Validade</nz-form-label>
                <nz-form-control [nzSpan]="5"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.validadeInicio">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="validadeInicio" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="5"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.validadeFim">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="validadeFim" style="width: 100%;" nzPlaceHolder="Até:"
                                    [ngModel]="formFiltrar.formGroup.value.validadeFim"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formCadastrar.modalVisible"
          [nzTitle]="'Cadastrar Responsável'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCadastrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzFor="primeiroNome" nzRequired>Nome/Sobrenome</nz-form-label>
                <nz-form-control nzErrorTip="Campo obrigatório" [nzSpan]="15">
                    <nz-input-group [nzSuffix]="inputClearNome">
                        <input nz-input type="text" formControlName="nome" placeholder="Nome">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="tipo" (ngModelChange)="changeTipoSelecionado()">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'cpf'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'cnpj'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Documento</nz-form-label>
                <nz-form-control nzErrorTip="Campo obrigatório" [nzSpan]="15">
                    <nz-input-group [nzSuffix]="inputClear">
                        <input nz-input type="text" formControlName="documento"
                               placeholder="{{this.tipoSelecionado === 'cpf' ? 'CPF do responsável' : 'CNPJ do responsável'}}"
                               autocomplete="off" mask="{{maskDoc}}">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <ng-template #inputClearNome>
              <span
                      nz-icon
                      class="ant-input-clear-icon"
                      nzTheme="fill"
                      nzType="close-circle"
                      *ngIf="formCadastrar.formGroup.value.nome"
                      (click)="formCadastrar.formGroup.get('nome').setValue(null)"
              ></span>
            </ng-template>

            <ng-template #inputClear>
              <span
                      nz-icon
                      class="ant-input-clear-icon"
                      nzTheme="fill"
                      nzType="close-circle"
                      *ngIf="formCadastrar.formGroup.value.documento"
                      (click)="formCadastrar.formGroup.get('documento').setValue(null)"
              ></span>
            </ng-template>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false);">Cancelar</button>
        <button [nzLoading]="loadings.gravando" nz-button nzType="primary" (click)="confirmaCadastrar()">Confirmar
        </button>
    </div>
</nz-modal>


<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../../core/services/log.service';
import { Pagination } from '../../../../shared/models/pagination.model';
import { Log } from '../../../../shared/models/log.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-list-log',
    templateUrl: './list-log.component.html',
    styleUrls: ['./list-log.component.scss']
})
export class ListLogComponent implements OnInit {
    logs: Log[];
    pagination: Pagination = new Pagination();
    loading = true;

    constructor(private logService: LogService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.list();
    }

    list = (currentPage = 1) => {
        this.logService.list(currentPage)
            .subscribe(result => {
                this.logs = result.data.map(obj => new Log(obj));
                this.pagination = new Pagination(
                    result.per_page,
                    result.current_page,
                    result.last_page,
                    result.total);
                this.loading = false;
            });
    }

    onPageChange(currentPage: number) {
        this.loading = true;
        this.list(currentPage);
    }
}

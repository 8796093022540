import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LogsAlteracoesComponent} from './logsAlteracoes.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '../../ui.module';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzAlertModule} from "ng-zorro-antd/alert";

@NgModule({
  declarations: [LogsAlteracoesComponent],
  exports: [LogsAlteracoesComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UiModule,
        NzPageHeaderModule,
        NzBadgeModule,
        FormsModule,
        NzAlertModule
    ]
})
export class LogsAlteracoesModule { }

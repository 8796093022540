import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Pagination} from '@models/pagination.model';
import {CheckEfdContribuicoesTributacaoService} from './checkEfdContribuicoesTributacao.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-check-checkEfdContribuicoesTributacao',
    templateUrl: './checkEfdContribuicoesTributacao.component.html',
    styleUrls: ['./checkEfdContribuicoesTributacao.component.scss']
})
export class CheckEfdContribuicoesTributacaoComponent extends AbstractListTable<any> implements OnInit, OnDestroy, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: FormStack;
    cadastrando = false;

    dadosLoading = true;

    formFiltrar: FormStack;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    erro = false;
    mensagem = '';

    qtdFiltrosAtivos = 0;

    loadings = {
        reprocessando: false,
        removendo: false,
        solicitarAnalise: false
    };

    time: any;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private service: CheckEfdContribuicoesTributacaoService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                competencia: [null, null],
            })
        };

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                competencia: [null, null],
            })
        };


        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno;
            this.arraySelectCnpj = retorno;
        });


    }

    ngOnInit() {

        this.retornaDados();

        this.animacoesPorcentagens();
        this.animacoesPorcentagens();

        this.verificaProcessando();
    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);
    }

    modalCadastrar(visible) {

        this.formCadastrar.modalVisible = visible;
    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.competencia) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    retornaDados(loading: boolean = true): void {

        this.dadosLoading = loading;

        this.calculaBadgeFiltros();

        const filtros = this.formFiltrar.formGroup.value;

        this.service.retornaDados(filtros).subscribe((response) => {

            this.items = [];
            this.items = response;

            response.forEach((value) => {

                value.porcentagemProcessamento = 0;

                switch (value.statusProcessamento) {
                    case 0: {
                        value.statusDescricao = 'Aguardando início';
                        value.statusCor = 'default';
                        break;
                    }
                    case 1: {
                        value.statusDescricao = 'Processando';
                        value.statusCor = 'warning';
                        break;
                    }
                    case 2: {
                        value.statusDescricao = 'Concluída';
                        value.statusCor = 'success';
                        break;
                    }
                    case 3: {
                        value.statusDescricao = 'Erro!';
                        value.statusCor = 'orange';
                        break;
                    }
                }
            });
            this.dadosLoading = false;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);
        });


        this.loading = true;
        // this.refreshCheckedStatus();
        this.loading = false;

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.retornaDados();

        this.modalFiltrar(false);

    }

    confirmaCadastrar(): void {

        this.validaFormCadastrar();
        const dados = this.formCadastrar.formGroup.value;

        if (!this.erro) {
            this.loadings.solicitarAnalise = true;
            this.service.solicitarAnalise(dados).subscribe((response) => {
                this.toastrService.success('Análise solicitada com sucesso.');
                this.loadings.solicitarAnalise = false;
                this.modalCadastrar(false);
                this.formCadastrar.formGroup.reset();
                this.retornaDados();
            });
        } else {
            this.loadings.solicitarAnalise = false;
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }


    }

    validaFormCadastrar(): void {
        if (!this.formCadastrar.formGroup.value.empresa_id) {
            this.erro = true;
            this.mensagem = 'Campo Unidade Obrigatório';
        }
        if (!this.formCadastrar.formGroup.value.competencia) {
            this.erro = true;
            this.mensagem = 'Campo Competência Obrigatório';
        }
    }

    reprocessar(): void {
        const ids: any = [];

        this.loadings.reprocessando = true;

        this.checkedItems.forEach((value) => {
            ids.push(value);
        });

        this.service.reprocessar(ids).subscribe((response) => {
            this.toastrService.success('Análises solicitadas com sucesso.');
            this.checkedItems.clear();
            this.retornaDados();
            this.loadings.reprocessando = false;
        }, error => {
            this.loadings.reprocessando = false;
            this.toastrService.error(error.error.message);
        });
    }

    remover(): void {
        const ids: any = [];
        this.loadings.removendo = true;

        this.checkedItems.forEach((value) => {
            ids.push(value);
        });

        this.service.remover(ids).subscribe((response) => {
            this.toastrService.success('Análises removidas com sucesso.');
            this.checkedItems.clear();
            this.retornaDados();
            this.loadings.removendo = false;
        }, error => {
            this.toastrService.error(error.error.message);
            this.loadings.removendo = false;
        });
    }

    animacoesPorcentagens(): void {
        setTimeout(() => {
            if (this.items) {
                this.items.forEach((item) => {
                    if (item.porcentagemProcessamento >= 98) {
                        item.porcentagemProcessamento = 0;
                    } else {
                        item.porcentagemProcessamento += 2;
                    }

                });
            }
            this.animacoesPorcentagens();
        }, 100);
    }

    verificaProcessando(): void {

        this.time = setTimeout(() => {

            let achou = false;
            this.items.forEach((item, index) => {
                if (!item.statusProcessamento || item.statusProcessamento === 0 || item.statusProcessamento === 1) {
                    achou = true;
                }
            });

            if (achou) {
                const filtros = this.formFiltrar.formGroup.value;
                this.service.retornaDados(filtros).subscribe((response: any) => {

                    this.items.forEach((item, index) => {
                        if (!item.statusProcessamento || item.statusProcessamento === 0 || item.statusProcessamento === 1) {
                            let itemAtualizar: any = null;
                            Object.entries(response).forEach((value: any, i: number) => {
                                if (value[1].id === item.id) {
                                    itemAtualizar = response[i];
                                }
                            });


                            if (itemAtualizar) {
                                itemAtualizar.porcentagemProcessamento = this.getRandomInt(0, 90);
                                switch (itemAtualizar.statusProcessamento) {
                                    case 0: {
                                        itemAtualizar.statusDescricao = 'Aguardando início';
                                        itemAtualizar.statusCor = 'default';
                                        break;
                                    }
                                    case 1: {
                                        itemAtualizar.statusDescricao = 'Processando';
                                        itemAtualizar.statusCor = 'warning';
                                        break;
                                    }
                                    case 2: {
                                        itemAtualizar.statusDescricao = 'Concluída';
                                        itemAtualizar.statusCor = 'success';
                                        break;
                                    }
                                    case 3: {
                                        itemAtualizar.statusDescricao = 'Erro!';
                                        itemAtualizar.statusCor = 'orange';
                                        break;
                                    }
                                }
                                this.items[index] = itemAtualizar;

                                if (itemAtualizar.statusProcessamento === 2) {
                                    this.toastrService.success('Processamento concluído');
                                }
                            }


                        }
                    });
                    this.cdr.detectChanges();
                });
            }


            this.verificaProcessando();
        }, 10000);
    }

    closeSelecionados(): void {
        this.checkedItems.clear();

    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    ngOnDestroy() {
        clearTimeout(this.time);
    }

}

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';
import {NzTableQueryParams} from 'ng-zorro-antd/table';

@Injectable({
    providedIn: 'root'
})
export class HistoricoDeAcessoService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/', {});
    }

    getHistorico(params: NzTableQueryParams, search) {

        const {pageSize, pageIndex, sort} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            params.filter.push({key: 'pagina', value: pageIndex});
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            params.filter.push({key: 'quantidade', value: pageSize});
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            params.filter.push({key: 'ordenar', value: sortField});
            params.filter.push({key: 'sentido', value: sortOrder});
        }

        const queryStr = params?.filter ? Helpers.montaQueryStribgBYNzTable(params, search) : '';

        return this.http.get(`${environment.apiUrl}/administracao/usuarios/acesso/historico${queryStr}`);
    }

    retornaComboUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }
}

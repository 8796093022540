import {ChangeDetectorRef, Component, ElementRef, Input, AfterViewInit, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UsuarioService} from '@services/usuario.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DownloadCenterService} from './downloadCenter.service';
import {environment} from '../../../environments/environment';
import {DatePipe} from '@angular/common';
import {DataService} from '@services/data.service';
import * as fileSaver from 'file-saver-es';
import {Helpers} from '../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-usuarios',
    templateUrl: './downloadCenter.component.html',
    styleUrls: ['./downloadCenter.component.scss']
})
export class DownloadCenterComponent extends AbstractListTable<null> implements AfterViewInit, OnInit {

    @Input() data: any;
    formFiltros: FormStack;
    qtdFiltrosAtivos = 0;
    descricoesTelas = {
        sefazErp: 'Sefaz x ERP',
        driveEstaduais: 'Drive Obrigações Estaduais',
        driveFederais: 'Drive Obrigações Federais',
        cnd: 'Controle de Certidões',
        mensagensDEC: 'Domicílio Eletrônico: Mensagens',
        terceiroSimples: 'Terceiros Simples',
        obrigacaoConsultaEmpresas: 'Drive Baixas Automáticas Empresas',
        obrigacaoConsultaObrigacoes: 'Drive Baixas Automáticas Obrigações',
        regrasConciliacoes: 'Regras - Check Validação & Conciliação',
        checkArmazenamento: 'Exportação Check Armazenamento',
        notasFiscaisEletronicasConfiguracoes: 'Notas Fiscais Eletrônicas - Configurações',
        credenciaisAcesso: 'Credenciais de Acesso'
    };
    selectDescricoesTelas = [
        {label: 'Sefaz x ERP', value: 'sefazErp'},
        {label: 'Drive Obrigações Estaduais', value: 'driveEstaduais'},
        {label: 'Drive Obrigações Federais', value: 'driveFederais'},
        {label: 'Controle de Certidões', value: 'cnd'},
    ];

    usuario: any = {};
    loadingsDownloads: any = {};

    optionsUsuarios: { id: string, nome: string }[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private service: DownloadCenterService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private el: ElementRef,
        private tabService: TabService,
        private dataService: DataService,
        private datePipe: DatePipe) {
        super(service, null, toastService);

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataEmissaoDe: [null, null],
                dataEmissaoAte: [null, null],
                status: [null, null],
                tipo: [null, null],
                usuario_id: [null, null],
            })
        };
    }

    ngOnInit() {
        this.getComboUsuarios();
    }

    getComboUsuarios() {
        this.usuarioService.getComboUsuarios().subscribe(res => {
            this.optionsUsuarios = res;
        });
    }

    ngAfterViewInit() {

        this.decodeSearch([this.formFiltros.formGroup]);

        this.dataService.currentUser.subscribe((data) => {
            this.usuario = data;
        });

    }

    decodeSearch(forms) {

        forms.forEach((value) => {

            const urlParams: any = this.queryStringToJSON(window.location.href);

            const haveUrlParams = Object.entries(urlParams).length > 0;

            if (urlParams.competencia) {
                const competencia = urlParams.competencia.split('-');
                urlParams.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
            }

            if (urlParams.vencimento) {
                const vencimento = urlParams.vencimento.split('-');
                urlParams.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
            }

            if (haveUrlParams) {
                this.resolveParams(urlParams, value);
            }

            const dataParams: any = Object.entries(this.data).filter((element) => {
                return element[0] !== 'link' && element[0] !== 'extra';
            });

            if (this.data.competencia && Object.prototype.toString.call(this.data.competencia) !== '[object Date]') {
                const competencia = this.data.competencia.split('-');
                this.data.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
            }

            if (this.data.vencimento && Object.prototype.toString.call(this.data.vencimento) !== '[object Date]') {
                const vencimento = this.data.vencimento.split('-');
                this.data.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
            }

            const haveDataParams = dataParams.length > 0;

            if (haveDataParams) {
                this.resolveParams(this.data, value);
            }

            if (!haveUrlParams && !haveDataParams) {

                const params = {};

                this.resolveParams(params, value);

            }

        });

    }

    resolveParams(params, form) {

        Object.entries(params).forEach((value: any) => {

            const input = form.get(value[0]);

            if (input) {
                form.get(value[0]).setValue(value[1]);
            }

        });

    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.loading = true;

        this.currentParams = params;

        this.currentSearch = search;

        this.currentParams.filter = [];

        this.calculaBadgeFiltros();

        const filtros = this.formFiltros.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (chave !== 'dataEmissaoDe' && chave !== 'dataEmissaoAte' && valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

            if (chave === 'dataEmissaoDe' && valor || chave === 'dataEmissaoAte' && valor) {
                this.currentParams.filter.push({key: chave, value: this.service.formataDateBD(valor)});
            }

        }

        const baseUrl = `${environment.apiUrl}/exportador`;

        this.abstractService.listToTable(params, search, baseUrl).subscribe((response) => {

            this.items = response.data || response;

            this.items.forEach((value) => {

                value.parametros = JSON.parse(value.parametros);

                if (value.processamentoInicio && value.status === 1) {

                    const currentdate = new Date();

                    const months = Number(
                        (currentdate.getMonth() + 1)) < 10 ? '0' + (currentdate.getMonth() + 1) : (currentdate.getMonth() + 1);

                    const days = Number(currentdate.getDate()) < 10 ? '0' + currentdate.getDate() : currentdate.getDate();

                    const hours = Number(currentdate.getHours()) < 10 ? '0' + currentdate.getHours() : currentdate.getHours();

                    const minutes = Number(currentdate.getMinutes()) < 10 ? '0' + currentdate.getMinutes() : currentdate.getMinutes();

                    const seconds = Number(currentdate.getSeconds()) < 10 ? '0' + currentdate.getSeconds() : currentdate.getSeconds();

                    const datetime = currentdate.getFullYear() + '-'
                        + months + '-'
                        + days + ' '
                        + hours + ':'
                        + minutes + ':'
                        + seconds;

                    value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio, datetime);

                }

                if (value.processamentoInicio && value.processamentoFim && value.status === 2) {
                    value.tempoProcessamento = this.timeDiff(value.processamentoInicio, value.processamentoFim);
                }

                if ((value.status === 2 || value.status === 1) && value.tamanho) {
                    value.tamanho = this.formatBytes(value.tamanho);
                }

            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

        });

    }

    timeDiff(d1, d2) {

        const dateOne = d1.split(' ').join('T');
        const dateTwo = d2.split(' ').join('T');

        d1 = new Date(dateOne).getTime();
        d2 = new Date(dateTwo).getTime();

        const diffMilissegundos = Math.abs(d1 - d2);

        let diffSegundos = diffMilissegundos / 1000;

        diffSegundos = Number(diffSegundos);

        const h = Math.floor(diffSegundos / 3600);
        const m = Math.floor(diffSegundos % 3600 / 60);
        const s = Math.floor(diffSegundos % 3600 % 60);

        const hDisplay = h > 0 ? h + 'h ' : '';
        const mDisplay = m > 0 ? m + 'm ' : '';
        const sDisplay = s > 0 ? s + 's' : '';

        return hDisplay + mDisplay + sDisplay;

    }

    downloadArquivo(id, tela, tipo, data): void {

        this.loadingsDownloads[id] = true;
        this.service.downloadArquivo(id).subscribe((res) => {

            this.loadingsDownloads[id] = false;
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            data = this.datePipe.transform(data, 'dd/MM/yyyy');

            fileSaver.saveAs(blob, tela + ' ' + tipo + ' ' + data + '.zip');

        }, (res) => {
            this.loadingsDownloads[id] = false;
        });

    }

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltros !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    filtrar() {

        this.queryTable(this.currentParams, this.currentSearch);

        this.fecharModal(this.formFiltros);

    }

    atualizarTela() {
        this.queryTable(this.currentParams, this.currentSearch);
    }

    showConfirmRemoverArquivo(id): void {
        this.modalService.confirm({
            nzTitle: 'Deseja remover o arquivo selecionado?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerArquivo(id)
        });
    }

    removerArquivo(id = null) {

        this.service.removerArquivo(id).subscribe((res) => {

            this.toastrService.success(res.message);

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    formatBytes(bytes, decimals = 2) {

        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    }

}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListProfileComponent } from './pages/list/list-profile.component';
import { RegisterProfileComponent } from './pages/register/register-profile.component';
import { DetailProfileComponent } from './pages/detail/detail-profile.component';
import { ProfileComponent } from './pages/profile.component';


const routes: Routes = [{
    path: '',
    component: ProfileComponent,
    children: [
        {
            path: 'listar',
            component: ListProfileComponent,

        },
        {
            path: 'detalhar/:id',
            component: DetailProfileComponent
        },
        {
            path: 'cadastrar',
            component: RegisterProfileComponent
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule {
}

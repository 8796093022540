import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';

@Injectable({providedIn: 'root'})
export class StepsObrigacoesEmpresasService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacoes', Obrigacao);
    }

    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsObrigacoes(empresaId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select/${empresaId}`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';

        if (valor && typeof data.getMonth === 'function') {

            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());

        } else {

            retorno = valor.toString();

        }

        return retorno;

    }

    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }
        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }

    gravarSteps(dados: any, empresaId?: string): Observable<any> {
        if (empresaId) {
            dados.empresas = [empresaId];
        }

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/obrigacoes-sugeridas-job`, dados);
    }

    retornaObrigacoesEmpresas(dados): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/obrigacoes-sugeridas/previa`, dados);

    }

}

import {Component, Input} from '@angular/core';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {StepsObrigacoesEmpresasService} from './steps-obrigacoes-empresas.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import br from '@angular/common/locales/pt';
import {environment} from '../../../../environments/environment';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

registerLocaleData(br);

@Component({
    selector: 'app-cadastros-steps-obrigacoes-empresas',
    templateUrl: './steps-obrigacoes-empresas.component.html',
    styleUrls: ['./steps-obrigacoes-empresas.component.scss'],
})
export class CadastrosStepsObrigacoesEmpresasComponent extends AbstractListTable<any> {

    @Input() data: any;

    steps = [
        {
            icon: 'calendar',
            step: 'process',
        },
        {
            icon: 'apartment',
            step: 'wait',
        },
        {
            icon: 'branches',
            step: 'wait',
        },
        {
            icon: 'check',
            step: 'finish',
        },
    ];

    icons = [
        'calendar',
        'apartment',
        'branches',
        'schedule',
        'check',
    ];

    stepNumber = 0;
    radioValueStepZero = 'todas';
    radioValueStepOne = 'todas';
    stepsLoading = false;

    formStepsObrigacoes: FormStack;

    constructor(
        private fb: UntypedFormBuilder,
        private stepsObrigacoesEmpresaService: StepsObrigacoesEmpresasService,
        private toastService: ToastrService,
        private i18n: NzI18nService,
    ) {
        super(stepsObrigacoesEmpresaService, null, toastService);

        this.formStepsObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.i18n.setLocale(pt_BR);

    }

    changeStep(stepNumber, direction) {

        this.stepNumber = stepNumber;

        if (direction === 'next') {

            if (this.stepNumber === 0) {

                this.formStepsObrigacoes.formGroup.removeControl('empresas');

                if (this.radioValueStepZero === 'todas') {

                    this.formStepsObrigacoes.formGroup.addControl('empresas', new UntypedFormControl());
                    this.formStepsObrigacoes.formGroup.get('empresas').setValue('todas');

                }

                if (this.radioValueStepZero === 'algumas') {

                    const empresas: any = Array.from(this.checkedItems);

                    if (empresas.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma empresa.');
                        return;
                    }

                    this.formStepsObrigacoes.formGroup.addControl('empresas', this.fb.array(empresas));

                }

                this.checkedItems.clear();

            }

            if (this.stepNumber === 1) {
                if (!this.formStepsObrigacoes.formGroup.value.modulos || this.formStepsObrigacoes.formGroup.value.modulos.length === 0) {
                    this.toastService.error('Por favor, selecione ao menos um módulo.');
                    return;
                }
            }

            if (this.stepNumber === 2) {

                this.formStepsObrigacoes.formGroup.removeControl('obrigacoes');

                if (this.radioValueStepOne === 'todas') {

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', new UntypedFormControl());
                    this.formStepsObrigacoes.formGroup.get('obrigacoes').setValue('todas');

                }

                if (this.radioValueStepOne === 'algumas') {

                    const empresas: any = Array.from(this.checkedItems);

                    if (empresas.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma obrigação.');
                        return;
                    }

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', this.fb.array(empresas));

                }

                this.checkedItems.clear();

            }

            this.stepsLoading = true;

            if (this.stepNumber === 2) {
                this.gravarSteps();
            }

        }

        if (direction === 'prev') {

            this.stepNumber--;

            this.steps[this.stepNumber].step = 'wait';
            this.steps[this.stepNumber].icon = this.icons[this.stepNumber];

            this.stepsLoading = false;

        }

        if (this.stepNumber < 2) {

            if (direction === 'next') {

                this.steps[this.stepNumber].step = 'process';
                this.steps[this.stepNumber].icon = 'loading';

                setTimeout(() => {

                    this.steps[this.stepNumber].step = 'finish';
                    this.steps[this.stepNumber].icon = 'check-circle';

                    this.stepNumber++;

                    this.steps[this.stepNumber].step = 'process';

                    this.stepsLoading = false;

                }, 1000);

            }

        }

    }

    selecionarModulos(value: object[]): void {
        this.formStepsObrigacoes.formGroup.removeControl('modulos');
        this.formStepsObrigacoes.formGroup.addControl('modulos', this.fb.array(value));
    }

    retornaObrigacoes(params: NzTableQueryParams, search: string = null) {

        this.loading = true;

        this.currentParams = params;

        this.checkedItems.clear();

        this.items = [];

        let url = '';

        if (this.steps[0].step !== 'finish') {
            url = `${environment.apiUrl}/administracao/empresas`;
        }

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    retornaObrigacoesEmpresas(params: NzTableQueryParams) {

        this.loading = true;

        this.checkedItems.clear();

        this.items = [];

        const dados = {
            modulos: this.formStepsObrigacoes.formGroup.value.modulos,
            empresas: this.formStepsObrigacoes.formGroup.value.empresas
        };

        this.stepsObrigacoesEmpresaService.retornaObrigacoesEmpresas(dados).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    gravarSteps(): void {

        this.steps[this.stepNumber].step = 'process';
        this.steps[this.stepNumber].icon = 'loading';

        this.stepsObrigacoesEmpresaService.gravarSteps(this.formStepsObrigacoes.formGroup.value, this.data.id).subscribe((response: any) => {

                this.steps[this.stepNumber].step = 'finish';
                this.steps[this.stepNumber].icon = 'check-circle';

                this.stepNumber++;

                this.steps[this.stepNumber].step = 'process';

                this.stepsLoading = false;

                this.toastService.success(response.message.cadastradas);

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

}

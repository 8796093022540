import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Empresa} from '@models/empresa.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ComplianceBaixasEAutomacoesService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes/configuracoes', Empresa);
    }

    alterarConfiguracoes(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/obrigacoes/configuracoes/gravar`, data);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

}

import {NgModule} from '@angular/core';
import {BaixasAutomaticasDetalhesComponent} from './baixas-automaticas-detalhes.component';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../shared/ng-zorro.module';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {UiModule} from '../../ui.module';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {HighchartsChartModule} from 'highcharts-angular';
import {NzCalendarModule} from 'ng-zorro-antd/calendar';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
    imports: [
        NzSwitchModule,
        NzUploadModule,
        NzResultModule,
        NzProgressModule,
        NzSkeletonModule,
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzListModule,
        NzPageHeaderModule,
        NzAffixModule,
        NzTypographyModule,
        NzDatePickerModule,
        FormsModule,
        NzTagModule,
        NzCheckboxModule,
        NzToolTipModule,
        NzAlertModule,
        HighchartsChartModule,
        NzCalendarModule,
        NzBadgeModule,
        NzTimePickerModule,
        NgxJdenticonModule
    ],
    declarations: [
        BaixasAutomaticasDetalhesComponent
    ],
    exports: [
        BaixasAutomaticasDetalhesComponent
    ],
})
export class BaixasAutomaticasDetalhesModule {
}

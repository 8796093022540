import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';

@Injectable({
    providedIn: 'root'
})
export class ControleDeCertificadosService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '', {});
    }

    retornaCabecalho(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/dashboard/certificados/grafico/validade`);

    }

    montaOrder(parametros): any {
        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'asc' : 'desc'
                };
            }
        });

        return order;
    }

    retornaEmpresas(filtros: any, order: any, pageIndex: any, search: any): Observable<any> {

        if (order.ordenar && order.sentido) {
            filtros.ordenar = order.ordenar;
            filtros.sentido = order.sentido;
        }

        if (pageIndex) {
            filtros.pagina = pageIndex;
        }

        if (filtros.pageSize) {
            filtros.quantidade = filtros.pageSize;
            delete filtros.pageSize;
        }

        if (search) {
            filtros.procurar = search;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/dashboard/certificados/empresas${queryStr}`);

    }

    retornaResponsaveis(filtros: any, order: any, pageIndex: any, search: any): Observable<any> {

        if (order.ordenar && order.sentido) {
            filtros.ordenar = order.ordenar;
            filtros.sentido = order.sentido;
        }

        if (pageIndex) {
            filtros.pagina = pageIndex;
        }

        if (filtros.pageSize) {
            filtros.quantidade = filtros.pageSize;
            delete filtros.pageSize;
        }

        if (search) {
            filtros.procurar = search;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/dashboard/certificados/responsaveis${queryStr}`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/empresas`);
    }

    retornaComboResponsaveis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/responsaveis-certificado/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

}

<nz-page-header class="site-page-header" nzTitle="Empresas" nzSubtitle="Dados de Empresas">
</nz-page-header>
<nz-content>
    <nz-card class="fonte-doze" [nzExtra]="navBar" nzTitle="Dados de Empresas"
             [ngClass]="[!loading ? 'ant-card-table' : '']"
             [nzLoading]="loading">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu class="disabled">
                        <li nz-menu-item [nzDisabled]="true">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event, currentSearch)"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" style="text-align: center;">CNPJ Básico</th>
                <th nzColumnKey="razaoSocial" [nzSortFn]="true" style="text-align: center;">Razão Social / Nome
                    Empresarial
                </th>
                <th nzColumnKey="naturezaJuridica" [nzSortFn]="true" style="text-align: center;">Natureza Jurídica</th>
                <th nzColumnKey="qualificacao" [nzSortFn]="true" style="text-align: center;">Qualificação do
                    Responsável
                </th>
                <th nzColumnKey="capitalSocial" [nzSortFn]="true" style="text-align: center;">Capital Social da
                    Empresa
                </th>
                <th nzColumnKey="porte" [nzSortFn]="true" style="text-align: center;">Porte da Empresa</th>
                <th nzColumnKey="enteFederativo" [nzSortFn]="true" style="text-align: center;">Ente Federativo
                    Responsável
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let dadosRFB of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(dadosRFB.cnpj, $event)"
                    [nzChecked]="checkedItems.has(dadosRFB.cnpj)">
                </td>
                <td nzLeft style="text-align: center;">{{modoDemo(dadosRFB?.cnpj, 'total')}}</td>
                <td nzLeft>{{modoDemo(dadosRFB?.razaoSocial, 'empresaCodigoNome')}}</td>
                <td nzLeft style="white-space: nowrap;text-align: center;">{{dadosRFB?.naturezaJuridica}}</td>
                <td nzLeft style="white-space: nowrap;text-align: center;">{{modoDemo(dadosRFB?.qualificacao)}}</td>
                <td nzLeft style="text-align: center;">{{dadosRFB?.capitalSocial}}</td>
                <td nzLeft style="text-align: center;">{{dadosRFB?.porte}}</td>
                <td nzLeft style="text-align: center;">{{dadosRFB?.enteFederativo}}</td>
                <td nzRight nzAlign="center">
                    <button (click)="openTab('/empresa/detalhes-dados-empresa/', {id: dadosRFB.cnpj})"
                            nz-button
                            nzType="link">
                        Detalhes
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>


<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">CNPJ Básico</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.cnpj !== '' && formFiltrar.formGroup.value.cnpj !== null">
                    <input nz-input type="text" formControlName="cnpj" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Nome</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.razaoSocial !== '' && formFiltrar.formGroup.value.razaoSocial !== null">
                    <input nz-input type="text" formControlName="razaoSocial" maxlength="60" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Natureza Jurídica</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.naturezaJuridica !== '' && formFiltrar.formGroup.value.naturezaJuridica !== null">
                    <input nz-input type="text" formControlName="naturezaJuridica" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Qual. Responsável</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.qualificacao !== '' && formFiltrar.formGroup.value.qualificacao !== null">
                    <input nz-input type="text" formControlName="qualificacao" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Capital Social</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.capitalSocial !== '' && formFiltrar.formGroup.value.capitalSocial !== null">
                    <input nz-input type="text" formControlName="capitalSocial" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Porte da Empresa</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.porte !== '' && formFiltrar.formGroup.value.porte !== null">
                    <input nz-input type="text" formControlName="porte" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Ente Federativo</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.enteFederativo !== '' && formFiltrar.formGroup.value.enteFederativo !== null">
                    <input nz-input type="text" formControlName="enteFederativo" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-page-header class="site-page-header" nzTitle="Regras" nzSubtitle="Editar Regras"></nz-page-header>
<div nz-row [nzGutter]="24">
    <div nz-col [nzMd]="16" [nzSm]="24">
        <nz-card nzTitle="Regras {{descricaoObrigacaoSelecionada}}" [nzExtra]="navBar"
                 class="editarObrigacoesRegras card-scroll"
                 [nzLoading]="loading"
                 [ngClass]="[!loading ? 'ant-card-table' : '']" #cardHeightTarget>
            <ng-template #navBar>
                <div class="nav-item d-flex">
                    <nz-badge [nzCount]="qtdFiltrosAtivos"
                              [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                        <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                            <i nz-icon nzType="search"></i>
                            Filtros
                        </button>
                    </nz-badge>
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                               placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i
                                    nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>
            </ng-template>
            <nz-table #tableRegras
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzPageIndex]="pagination?.current_page"
                      [nzShowTotal]="rangeTemplate"
                      (nzQueryParams)="queryTable($event)"
                      class="scrollbar table-small">
                <thead>
                <tr>
                    <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                    <th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">Código</th>
                    <th nzColumnKey="campo" [nzSortFn]="true" nzAlign="center">Campo</th>
                    <th nzColumnKey="ocorrencia" [nzSortFn]="true" nzAlign="center">Ocorrência</th>
                    <th nzColumnKey="classificacao" [nzSortFn]="true" nzAlign="center">Classificação</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableRegras.data">
                    <td nzAlign="center">{{ data.tipo }}</td>
                    <td nzAlign="center">{{ data.codigo }}</td>
                    <td>{{ data.campo }}</td>
                    <td>
                        <span class="border-bottom-1 border-{{data.classificacaoTipo}}">{{ data.descricao }}</span>
                    </td>
                    <td class="text-center">
                        <nz-alert *ngIf="data.classificacao" nzType="{{data.classificacaoTipo}}"
                                  nzMessage="{{data.classificacaoDescricao}}"></nz-alert>
                    </td>
                    <td>
                        <a nz-button nzType="link" (click)="editarClassificacao(data.id, data.classificacao)">Editar</a>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </nz-card>
    </div>
    <div nz-col [nzMd]="8" [nzSm]="24" class="alert-col">
        <nz-card class="ant-card-table" #cardHeight>
            <nz-content style="background-color: #e6f7ff; padding: 15px 15px 15px 0px;">
                <nz-row>
                    <nz-col nzSpan="4" style="text-align: center;">
                        <i nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 24px; color: #1890ff;"></i>
                    </nz-col>
                    <nz-col nzSpan="20">
                        <h5 nz-typography>Classificação</h5>
                        <p>
                            <strong>Graves: </strong>
                            São impeditivos de prosseguir.
                        </p>
                        <p>
                            <strong>Médias: </strong>
                            Necessitam de liberação de um supervisor.
                        </p>
                        <p>
                            <strong>Leves: </strong>
                            São apenas advertências, não precisam de liberação ou causam impedimentos.
                        </p>
                    </nz-col>
                </nz-row>
                <nz-row>
                    <nz-col nzSpan="4"></nz-col>
                    <nz-col nzSpan="20">
                        <h5 nz-typography>Exceções</h5>
                        <p>Defina uma classificação em unidades específicas.</p>
                    </nz-col>
                </nz-row>
                <nz-row>
                    <nz-col nzSpan="4"></nz-col>
                    <nz-col nzSpan="20">
                        <h5 nz-typography>Supervisores de Regras</h5>
                        <p>São capazes de efetuar a liberação das regras médias. Basta que qualquer um deles execute a
                            ação.</p>
                    </nz-col>
                </nz-row>
            </nz-content>
        </nz-card>
        <nz-card nzTitle="Supervisão de Regras"
                 [nzLoading]="loadingSupervisores"
                 [ngClass]="[!loadingSupervisores ? 'ant-card-table' : '']"
                 [nzExtra]="extraSupervisores">
            <nz-table #tableSupervisores
                      [nzData]="itemsSupervisores"
                      [nzFrontPagination]="false"
                      class="table-small">
                <thead>
                <tr>
                    <th nzColumnKey="perfil" [nzSortFn]="false" nzAlign="center">Perfil</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableSupervisores.data">
                    <td nzAlign="center">{{ data.descricao }}</td>
                    <td>
                        <a nz-button nzType="link" (click)="showConfirmRemoverSupervisor(data.id)">Remover</a>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </nz-card>
    </div>
</div>
<nz-modal [(nzVisible)]="formSupervisor.modalVisible" nzTitle="Selecionar Supervisor" nzWidth="768px"
          (nzOnCancel)="showHideModal(formSupervisor, false)">
    <div *nzModalContent>
        <form nz-form [formGroup]="formSupervisor.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="supervisor" nzRequired>Perfil de Acesso</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="perfilId" nzPlaceHolder="Selecione o Perfil de Acesso">
                        <nz-option *ngFor="let data of comboPerfis" [nzValue]="data.id"
                                   [nzLabel]="data.descricao"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showHideModal(formSupervisor, false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="submitFormSupervisor()">Enviar</button>
    </div>
</nz-modal>
<nz-modal nzTitle="Editar Classificação"
          (nzOnCancel)="showHideModal(formEditarClassificacao, false)"
          [(nzVisible)]="formEditarClassificacao.modalVisible">
    <div *nzModalContent>
        <form nz-form [formGroup]="formEditarClassificacao.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="classificacao">Classificação</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="classificacao"
                               nzPlaceHolder="Selecione a Classificação">
                        <nz-option nzValue="L" nzLabel="Leve"></nz-option>
                        <nz-option nzValue="M" nzLabel="Média"></nz-option>
                        <nz-option nzValue="G" nzLabel="Grave"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showHideModal(formEditarClassificacao, false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="submitFormClassificacao()">Enviar</button>
    </div>
</nz-modal>
<ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
</ng-template>
<ng-template #extraSupervisores>
    <div class="nav-item d-flex">
        <button nz-button nzType="primary"
                (click)="showHideModal(formSupervisor, true)"
                nzGhost>{{textoBotaoSupervisor}}</button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Tipo:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="tipo">
                        <nz-option *ngFor="let tipo of comboTipo"
                                   [nzLabel]="tipo"
                                   [nzValue]="tipo"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Código:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-input-group nzSearch>
                        <input nz-input type="text" formControlName="codigo"/>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Campo:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-input-group nzSearch>
                        <input nz-input type="text" formControlName="campo"/>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Classificacão:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="classificacao">
                        <nz-option *ngFor="let c of comboClassificacao"
                                   [nzLabel]="c.label"
                                   [nzValue]="c.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, Subject} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Menu} from '@models/menu.model';

@Injectable({providedIn: 'root'})

export class MenuService extends AbstractService<Menu> {

    currentMenuTree: Subject<any> = new Subject<any>();

    constructor(http: HttpClient) {
        super(http, '/admin/menu', Menu);
    }


    listMenus = (): Observable<any> => this.http.get(`${environment.apiUrl}?pagination=false`);

    get(id: number, recentes = false): Observable<any> {
        return this.http.get(`${environment.apiUrl}/admin/menu/${id}?recentes=${recentes}`);
    }

    public getTree() {
        return this.http.get(`${environment.apiUrl}/admin/menu/tree`);
    }

    transformMegamenuItem(items, nivel = 1, expanded = false) {

        const menuItens = [];

        for (const item of items) {

            const menuItem = {
                level: nivel,
                title: item.descricao,
                description: item.descricaoEstendida,
                icon: item.icone,
                url: item.url,
                urlType: item.urlTipo,
                open: true,
                expanded,
                selected: false,
                selectable: false,
                disabled: false,
                children: item.children ? this.transformMegamenuItem(item.children, nivel + 1, expanded) : [],
                isLeaf: item.children.length <= 0,
                key: item.id,
                tipo: item.tipo,
                ordem: item.ordem,
                extra: item.extra
            };

            menuItens.push(menuItem);

        }
        return menuItens;
    }


}

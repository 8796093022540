import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ObrigacaoEmpresaDetalheComponent} from './obrigacao-empresa-detalhe.component' ;

const routes: Routes = [

    {path: '', component: ObrigacaoEmpresaDetalheComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class ObrigacaoEmpresaDetalheRoutingModule {
}

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Categorias"></nz-page-header>
<nz-content>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '']"
             [nzLoading]="loading"
             [nzExtra]="navBar"
             nzTitle="Categorias">
        <ng-template #navBar>
            <div class="d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalAddCategoria(true);">
                    Adicionar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate"
                  [nzFrontPagination]="false"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="categoria" [nzSortFn]="true">Categoria</th>
                <th nzAlign="center" nzColumnKey="obrigacoes" [nzSortFn]="true">Obrigações</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let categorias of basicTable.data">
                <td nzAlign="center">{{categorias?.descricao}}</td>
                <td nzAlign="center">{{categorias?.obrigacoes}}</td>
                <td nzAlign="center">
                    <button nz-button nzType="link"
                            [nzLoading]="editarLoading[categorias.categoria_id]"
                            (click)="retornaCategoria(categorias.categoria_id)">
                        Editar
                    </button>
                    <button (click)="showConfirm(categorias.categoria_id)" nz-button nzType="link">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltros.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Categorias:</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.categoria">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="categoria_id">
                        <nz-option *ngFor="let option of comboCategorias"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigações:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let option of comboObrigacoes"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formAddCategoria.modalVisible"
          [nzTitle]="'Adicionar Categoria'" [nzClosable]="true"
          (nzOnCancel)="modalAddCategoria(false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Categoria ID:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <input nz-input type="text"
                           [(ngModel)]="formAddCategoria.formGroup.categoria_id"
                           [ngModelOptions]="{standalone: true}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Descrição:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <input nz-input type="text"
                           [(ngModel)]="formAddCategoria.formGroup.descricao"
                           [ngModelOptions]="{standalone: true}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigações:</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzMode="tags" nzPlaceHolder="Selecione"
                               [(ngModel)]="formAddCategoria.formGroup.obrigacoes"
                               [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let option of comboObrigacoes"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAddCategoria(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="adicionarCategoria()">Adicionar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarCategoria.modalVisible"
          [nzTitle]="'Editar Categoria'" [nzClosable]="true"
          (nzOnCancel)="modalEditarCategoria(false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Categoria ID:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <input nz-input
                           type="text"
                           [(ngModel)]="formEditarCategoria.formGroup.categoria_id"
                           [ngModelOptions]="{standalone: true}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Descrição:</nz-form-label>
                <nz-form-control [nzSpan]="12" nzValidateStatus="success">
                    <input nz-input type="text"
                           [(ngModel)]="formEditarCategoria.formGroup.descricao"
                           [ngModelOptions]="{standalone: true}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigações:</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzMode="tags" nzPlaceHolder="Selecione"
                               [(ngModel)]="formEditarCategoria.formGroup.obrigacoes"
                               [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let option of comboObrigacoes"
                                   [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarCategoria(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="adicionarCategoria(true)">Editar</button>
    </div>
</nz-modal>

import {Component, OnInit} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-transitorios-uploadArquivos',
    templateUrl: './uploadArquivos.component.html',
    styleUrls: ['./uploadArquivos.component.scss']
})


export class TransitoriosUploadArquivosComponent implements OnInit {

    apiUrl = environment.apiUrl;

    constructor(private msg: NzMessageService) {

    }

    ngOnInit() {

    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        const status = file.status;
        if (status !== 'uploading') {

        }
        if (status === 'done') {
            this.msg.success(`${file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            this.msg.error(`${file.name} file upload failed.`);
        }
    }

}

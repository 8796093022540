<nz-page-header nzTitle="Consultas" nzSubtitle="Omissões Sefaz">
    <nz-page-header-extra></nz-page-header-extra>
</nz-page-header>

<nz-content style="width: 100%">
    <div style="width: 100%" class="cards-info">
        <div class="info-card-g">
            <nz-card (click)="filtrarByCard('0')"
                     class="{{formFiltrar.value.status == '0'? 'card-cabecalho card-cabecalho-selected' : 'card-cabecalho'}}"
                     [nzLoading]="loadings.table">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center">
                        <i nz-icon nzType="exclamation-circle" nzTheme="outline"
                           style="font-size: 60px; margin-right: 15px; color: #ff4961 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Omissos
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #ff4961 !important;">
                                {{contadoresCabecalho.omisso | number}}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="contadoresCabecalho.percent_omisso"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#ff4961'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
        <div class="info-card-g">
            <nz-card (click)="filtrarByCard('1')"
                     class="{{formFiltrar.value.status == '1'? 'card-cabecalho card-cabecalho-selected' : 'card-cabecalho'}}"
                     [nzLoading]="loadings.table">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center">
                        <i class="far fa-check-circle"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Não Omissos
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important;">
                                {{contadoresCabecalho.nao_omisso | number}}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="contadoresCabecalho.percent_nao_omisso"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#009688'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>

    <nz-card [nzBodyStyle]="{width: '100%', padding: loadings.table ? '15px' : '0'}"
             [nzLoading]="loadings.table"
             [ngClass]="[statusOne ? 'card-fullscreen fonte-doze' : 'card-container fonte-doze']"
             nzTitle="Omissões" [nzExtra]="extra">
        <ng-template #extra>
            <div class="d-flex align-items-center">
                <button disabled="{{loadings.table}}" style="margin-right: 10px"
                        (click)="exibirTudo(!expandAll)" nz-button>
                    <em *ngIf="expandAll" class="fas fa-angle-double-up"></em>
                    <em *ngIf="!expandAll" class="fas fa-angle-double-down"></em>
                </button>
                <nz-badge [nzCount]="qtdFiltros"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            disabled="{{loadings.table}}"
                            style="margin-right: 10px;"
                            (click)="showModal('filtrar',true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca"
                           disabled="{{loadings.table}}"
                           (keydown.enter)="getTable(currentParams, currentSearch)"
                           [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            disabled="{{loadings.table}}"
                            (click)="getTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button [nzType]="'default'" (click)="limparFiltros()"
                        disabled="{{loadings.table}}"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="getTable(currentParams, currentSearch)"
                        disabled="{{loadings.table}}"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    <i class="fas fa-redo-alt"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #tableOmissoes
                  style="width: 100%; overflow-y: auto"
                  [nzData]="omissoes"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false">
            <thead>
            <tr>
                <th #column1 style="text-align: left">Grupo Empresarial</th>
                <th #column2 nzAlign="center"></th>
                <th #column3 style="text-align: left">Empresa</th>
                <th #column4 style="text-align: center; min-width: 150px">CNPJ</th>
                <th #column5 style="text-align: center">Último Relatório</th>
                <th #column6 style="text-align: center">Último Status</th>
                <th #column7 style="text-align: center">Última Emissão</th>
                <th #column8 style="text-align: center">Próxima Consulta</th>
            </tr>
            </thead>
            <tbody>
            <ng-container class="ant-table-tbody" style="padding: 0; margin: 0"
                          *ngFor="let item of omissoes">
                <tr>
                    <td nzAlign="left">
                        {{item.grupoEmpresarialDescricao}}
                    </td>
                    <td style="color: #1e9ff2; width: 30px;"
                        [nzExpand]="expandSet.has(item.grupoEmpresarialDescricao)"
                        (nzExpandChange)="onExpandChange(item.grupoEmpresarialDescricao, $event)"
                    ></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                </tr>
                <ng-container *ngIf="item.omissoes.length > 0">
                    <tr [nzExpand]="expandSet.has(item.grupoEmpresarialDescricao)"
                        *ngFor="let o of item.omissoes">
                        <td [ngStyle]="{
                                    'width': column1Width + 'px',
                                    position: 'relative'
                                    }">
                        </td>
                        <td [ngStyle]="{
                                    'width': column2Width + 'px',
                                    position: 'relative'
                                    }">
                        </td>
                        <td
                                [ngStyle]="{
                                    'min-width': column3Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'text-align': 'left',
                                    'overflow-wrap': 'break-word'
                                    }">
                            {{o.empresaNome}}
                        </td>
                        <td [ngStyle]="{
                                    'width': column4Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                            {{o.empresaCnpj | cpfCnpj}}
                        </td>
                        <td [ngStyle]="{
                                    'width': column5Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">

                            <li [ngStyle]="{color: o.referenciaUltimaEmissao ? '#1e9ff2' : '#969696' }"
                                class="fa fa-file-text" style="font-size: 1.3em; cursor: pointer"
                                (click)="o.referenciaUltimaEmissao ? verUltimoRelatorio(o) : semRelatoriomsg()"
                                *ngIf="!loadings.verArquivo[o.referenciaUltimaEmissao] else loading"></li>

                            <ng-template #loading>
                                <span style="color: #1e9ff2; font-size: 1.1em" nz-icon nzType="loading"
                                      nzTheme="outline"></span>
                            </ng-template>

                        </td>
                        <td [ngStyle]="{
                                    'width': column6Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">

                            <span *ngIf="o.statusUltimaEmissao != 0 && !o.statusUltimaEmissao">
                                -
                            </span>
                            <span *ngIf="o.statusUltimaEmissao?.toString() === '0'" class="alert"
                                  style="background-color: #ff4961;">
                                Omisso
                            </span>
                            <span *ngIf="o.statusUltimaEmissao?.toString() === '1'" class="alert"
                                  style="background-color: #009688">
                                Não Omisso
                            </span>

                            <i [nz-tooltip]="'Erro na consulta: ' + o.mensagemUltimaEmissao || '-'"
                               *ngIf="o.statusUltimaEmissao?.toString() === '2'"
                               style="color: #eebe07; font-weight: bold; font-size: 1.9em;"
                               class="fa fa-warning"></i>
                        </td>
                        <td [ngStyle]="{
                                    'width': column7Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                            {{o.ultimaConsulta ? (o.ultimaConsulta | date:'dd/MM/YY HH:mm') : '-'}}
                        </td>
                        <td [ngStyle]="{
                                    'width': column8Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }"
                            nzAlign="center">
                            {{o.proximoProcessamento ? (o.proximoProcessamento | date:'dd/MM/YY HH:mm') : '-'}}
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="item.omissoes.length === 0 || item.omissoes === undefined">
                    <tr [nzExpand]="expandSet.has(item.grupoEmpresarialDescricao)" style="text-align: center;">
                        Sem Dados
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </nz-table>
    </nz-card>

</nz-content>

<nz-modal [(nzVisible)]="modalVisible.filtrar" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="showModal('filtrar', false)" [nzWidth]="650">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.grupoEmpresarial">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="grupoEmpresarial">
                        <nz-option *ngFor="let opt of arraySelectGrupoEmpresarial"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Omisso'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Não Omisso'" [nzValue]="'1'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Última Emissão</nz-form-label>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.value.dataInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.value.dataFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal('filtrar',false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal
        [(nzVisible)]="modalVisible.ultimoRelatorio"
        (nzOnCancel)="showModal('ultimoRelatorio', false)"
        [nzBodyStyle]="{'padding' : '0'}"
        [nzStyle]="{ top: tamanhosTela.screenModalTop + 'px' }"
        [nzWidth]="tamanhosTela.screenWidth"
        [nzTitle]="'Último Relatório ' + arquivoSelecionado"
        class="modal-table">
    <ng-container *nzModalContent>
        <iframe [ngStyle]="{height: tamanhosTela.screenHeight + 'px'}"
                [src]="iframeUrl"
                title="" style="width: 100%; border: none;"
                *ngIf="isPdf">
        </iframe>
        <div id="html_to_print" *ngIf="!isPdf" class="d-flex justify-content-center" style="width: 100%;">
            <div [innerHTML]="conteudoUltimoRelatorio" style="width: 100%; overflow-x: auto"></div>
        </div>

    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal('ultimoRelatorio', false)">Fechar</button>
        <button nz-button nzType="primary" onClick="printRelatorio()">Imprimir</button>
    </div>
</nz-modal>

import {ChangeDetectorRef, Component, AfterViewInit, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {CalendarTasksService} from './calendarTasks.service';
import {EquipeService} from '@services/equipe.service';
import Equipe from '@models/equipe.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {Pagination} from '@models/pagination.model';
import {StickyHeaderDirective} from '@components/directive/directive';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {Subscription} from 'rxjs';
import HighchartsPie from "highcharts";
import {Helpers} from "../../../core/helpers";

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-calendarTasks',
    templateUrl: './calendarTasks.component.html',
    styleUrls: ['./calendarTasks.component.scss']
})
export class CalendarTasksComponent extends AbstractListTable<Usuario> implements OnInit, AfterViewInit, OnDestroy, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    newVisibilidade = '';

    arrayStatus = [
        'Pendente',
        'Atrasada',
        'Concluída',
        'Concluída com Atraso',
    ];

    arrayFontCorStatus = [
        '#ff9149',
        '#ff4961',
        '#1565c0',
        '#666ee8',
    ];

    HighchartsSolidGauge: typeof Highcharts = Highcharts;

    chartSolidGaugeOptions: Highcharts.Options;

    updateFlag: boolean;

    flagsMapa: any [] = [];

    cardCabecalhoLoading = true;

    eficiencia = 0;

    tableConfirmacoesAutimaticas: any = {};

    tableStatus: any = {};

    anoAtivo = 0;
    mesAtivo = 0;

    arrayCompetencias = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    @Input() data: any[];

    formFiltrosGrid: FormStack;
    formFiltrosCabecalho: FormStack;

    filtroObrigacao: string = null;
    filtroCategoria: string = null;
    filtroStatus: string = null;
    filtroEquipe: string = null;
    filtroResponsavel: string = null;
    filtroConfirmacoes: string = null;
    filtroCompetencia: string = null;
    filtroCompetenciaDate = new Date();
    filtroAno: string = null;
    empresaNome: string = null;
    unidade: string = null;

    arrayFiltrosObrigacoes: any[] = [];
    arrayFiltrosCategorias: any[] = [];
    arrayFiltrosStatus: any[] = [];
    arrayFiltrosConfirmacoes: any[] = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    arrayFiltrosEquipes: any[] = [];
    arrayFiltrosResponsaveis: any[] = [];

    filtroVencimento = null;

    // Atribuir Equipes
    equipeOptions: { label: string; value: string }[] = [];
    inputEquipe: string;
    limparEquipes = false;
    newEquipe = '';

    comboResponsaveis = [];

    formResponsavel: FormStack;

    empresaId = null;

    selectedDate = new Date();

    arraySemanas: any = [];

    disabledDesconcluir = true;

    formExportar: FormStack;

    qtdFiltrosAtivos = 0;
    qtdFiltrosCabecalho = 1;

    formSolicitarAprovacao: FormStack;
    fileList: NzUploadFile[] = [];

    loadings = {
        uploadinAnexo: false,
        verAnexos: false,
        downloads: []
    };

    loadingModalAnexos = {};

    modalVerAnexosVisible = false;
    modalAdicionarAnexosVisible = false;

    listOfAnexos = [];

    currentAtividadeId: string;
    currentObrigacaoEmpresaId: string;

    loadingNextPage = false;

    paginaAtual = this.pagination.current_page;

    modalHistoricoVisible = false;
    loadingHistorico = false;
    itemsHistorico = [];

    target: any;
    travarScroll = false;

    sextaSemana = false;

    listElm: any;

    listener: any;

    dataExport: any;

    btExcluir = false;

    arraySelectGrupos = [];

    equipefiltradaDescricao: null;
    responsavelFiltradoDescricao: null;
    grupoFiltradoDescricao: null;

    reqAtividades: Subscription;

    chartPieOptions: Highcharts.Options;
    seriesStatus: any[] = [];

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private equipeService: EquipeService,
        private modalService: NzModalService,
        private service: CalendarTasksService) {

        super(service, Usuario, toastService);

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                obrigacao: [null, null],
                categoria: [null, null],
                competencia: [null, null],
                unidade: [null, null],
                empresaNome: [null, null],
                status: [null, null],
                confirmacoes: [null, null],
                ano: [null, null],
                vencimento: [null, null],
                equipe_id: [null, null],
                responsavel_id: [null, null]
            })
        };

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                competenciaMesAno: [null, null],
                equipe_id: [null, null],
                responsavel_id: [null, null],
                grupoEmpresarial_id: [null, null]
            })
        };

        this.formSolicitarAprovacao = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, null],
            })
        };

        this.arrayFiltrosStatus = [{
            key: 'P',
            label: 'Pendentes'
        }, {
            key: 'A',
            label: 'Atrasados'
        }, {
            key: 'C',
            label: 'Concluídos'
        }, {
            key: 'CA',
            label: 'Concluídos com Atraso'
        }];

        this.arrayFiltrosConfirmacoes = [{
            key: 'A',
            label: 'Em Confirmação'
        }, {
            key: 'R',
            label: 'Rejeitadas'
        }, {
            key: 'C',
            label: 'Confirmadas'
        }, {
            key: 'N',
            label: 'À Confirmar'
        },
        ];

        this.formResponsavel = {
            modalVisible: false,
            formGroup: this.fb.group({
                responsavel_id: [null, Validators.required]
            })
        };

        this.retornaSelectsCategorias();

        this.listener = () => {

            if (this.listElm.scrollTop + this.listElm.clientHeight >= this.listElm.scrollHeight
                && this.items?.length < this.pagination.total
                && !this.loadingNextPage && !this.loading && !this.cardCabecalhoLoading
                && !this.travarScroll
                && window.location.href.includes('calendar/calendarTasks')) {
                this.loadTasksNextPage(this.currentParams);
            }
        };

        this.dataExport = {
            url: '/cadastros/pis-cofins-regra/exportar',
            filtros: this.formFiltrosGrid.formGroup.value,
            name: 'Calendar Atividades',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    modalAlterarResponsavel(visible, id: string = null) {

        this.formResponsavel.modalVisible = visible;

        this.empresaId = id ? id : null;

    }

    ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;

        this.listElm = document.querySelector('#main-content');

        this.listElm.addEventListener('scroll', this.listener, false);
    }

    ngOnDestroy() {
        this.listElm.removeEventListener('scroll', this.listener);
    }

    scrollContainer(top = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                top,
                behavior: 'smooth'
            });
        }, 100);

        // top: this.cardTabsTwo.nativeElement.offsetTop + px,

    }

    carregaDados(mes: number, ano: number): void {

        this.carregaCabecalho(mes, ano);
        this.carregaAtividades(this.currentParams);
    }

    carregaCabecalho(mes: number, ano: number): void {

        this.cardCabecalhoLoading = true;

        this.calculaBadgeFiltrosCabecalho();

        // garantindo que arraySelectGrupos esteja preenchido antes da requisição do
        // cabeçalho para aparecer o grupo filtrado caso exista (grupoFiltradoDescricao)
        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;

            if (this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id) {
                this.grupoFiltradoDescricao =
                    this.arraySelectGrupos.find(r => r.id === this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id)?.descricao;
            }

            delete this.formFiltrosCabecalho.formGroup.value.competenciaMesAno;

            const filtros = this.formFiltrosCabecalho.formGroup.value;
            this.service.retornaCabecalho(mes, ano, filtros).subscribe((response: any) => {

                this.montaDataGraficos(response);

                if (filtros.grupoEmpresarial_id) {
                    this.getEmpresas();
                }

                // Confirmações
                this.tableConfirmacoesAutimaticas = response.confirmacoesAutomaticas;

                // Status
                this.tableStatus = response.status;

                // Eficiência
                this.eficiencia = 0;
                const max = response.status.qtdConcluidos + response.status.qtdConcluidoAtrasadas + response.status.qtdAtrasados;
                const min = response.status.qtdConcluidos;
                const total = (100 * min) / max;
                if (total > 0) {
                    this.eficiencia = Math.round(total);
                }

                this.flagsMapa = response.calendario;

                // Novo Calendário
                this.montaArrayDiasMeses(mes, ano);

                this.cardCabecalhoLoading = false;

                setTimeout(() => {
                    this.loadChartData();
                }, 1000);
            });

        });

    }

    ngOnInit() {

        if (this.data && Array.isArray(this.data)) {
            this.data.forEach((value, i) => {
                switch (value.filtro) {
                    case 'mes': {
                        this.mesAtivo = value.valor;
                        this.filtroCompetencia = value.valor;
                        break;
                    }
                    case 'ano': {
                        this.anoAtivo = value.valor;
                        this.filtroAno = value.valor;
                        break;
                    }
                    case 'obrigacao_id': {
                        this.formFiltrosGrid.formGroup.get('obrigacao').setValue(value.valor);
                        this.filtroObrigacao = value.valor;
                        break;
                    }
                    case 'categoria': {
                        this.formFiltrosGrid.formGroup.get('categoria').setValue(value.valor);
                        this.filtroCategoria = value.valor;
                        break;
                    }
                    case 'confirmacoes': {
                        this.formFiltrosGrid.formGroup.get('confirmacoes').setValue(value.valor);
                        this.filtroConfirmacoes = value.valor;
                        break;
                    }
                    case 'status': {
                        this.formFiltrosGrid.formGroup.get('status').setValue(value.valor);
                        this.filtroStatus = value.valor;
                        break;
                    }
                    case 'equipe_id': {
                        this.formFiltrosGrid.formGroup.get('equipe_id').setValue(value.valor);
                        this.formFiltrosCabecalho.formGroup.get('equipe_id').setValue(value.valor);
                        this.filtroEquipe = value.valor;
                        break;
                    }
                    case 'responsavel_id': {
                        this.formFiltrosGrid.formGroup.get('responsavel_id').setValue(value.valor);
                        this.formFiltrosCabecalho.formGroup.get('responsavel_id').setValue(value.valor);
                        this.filtroResponsavel = value.valor;
                        break;
                    }
                    case 'grupoEmpresarial_id': {
                        this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').setValue(value.valor);
                        break;
                    }
                }
            });
        } else {
            this.mesAtivo = new Date().getMonth() + 1;
            this.anoAtivo = new Date().getFullYear();
            this.filtroAno = String(new Date().getFullYear());
        }

        this.getResponsaveis();

        this.service.retornaComboEquipes().subscribe((retorno: any) => {

            retorno.forEach((value) => {
                value.label = value.nome;
                value.key = value.id;
            });
            this.arrayFiltrosEquipes = retorno;

        });

        if (!this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').value) {
            this.getEmpresas();
        }

        this.selectedDate.setMonth(this.mesAtivo - 1);

        this.filtroCompetenciaDate.setMonth(this.mesAtivo - 1);
        this.filtroCompetenciaDate.setFullYear(this.anoAtivo);
        this.carregaDados(this.mesAtivo, this.anoAtivo);
        this.cdr.detectChanges();

    }

    getResponsaveis() {

        this.service.retornaComboResponsaveis(
            this.formFiltrosCabecalho.formGroup.get('equipe_id').value).subscribe({
            next: (retorno) => {

                retorno.forEach((value) => {
                    value.label = value.nome;
                    value.key = value.id;
                });

                this.comboResponsaveis = retorno;
                this.arrayFiltrosResponsaveis = retorno;

            },
            error: (err) => {
                this.toastService.error('Erro ao listar responsáveis');
            }
        });
    }

    getEmpresas() {
        this.service.retornarSelectsEmpresas(
            this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').value).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });
    }

    montaDataGraficos(response: any): void {

        // Gráfico Status
        this.seriesStatus = [{
            type: 'pie',
            name: 'Obrigações',
            data: [{
                name: 'Pendentes',
                y: Number(response.status.qtdPendentes)
            }, {
                name: 'Atrasadas',
                y: Number(response.status.qtdAtrasados)
            }, {
                name: 'Concluídas',
                y: Number(response.status.qtdConcluidos)
            }, {
                name: 'Concluídas com Atraso',
                y: Number(response.status.qtdConcluidoAtrasadas)
            }]
        }];
    }

    loadChartData(): void {

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 180,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '60%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -50
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [this.eficiencia],
                dataLabels: {
                    format: '<div style="text-align:center; top: 0px;position: relative;">' +
                        '<span style="font-size:28px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: [
                        '#ff9149',
                        '#ff4961',
                        '#1565c0',
                        '#666ee8',
                    ],
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: this.seriesStatus
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        if (componentName === 'ObrigacaoEmpresaDetalheComponent') {
            this.tabService.closeAndAddTab(newTab);
        } else {
            this.tabService.addTab(newTab);
        }

    }

    retornaComboObrigacoes() {
        this.service.retornaObrigacoes(this.mesAtivo, this.anoAtivo).subscribe((response: any) => {
            this.arrayFiltrosObrigacoes = [];
            this.arrayFiltrosObrigacoes = Object.values(response);
        }, error => {
            this.toastService.error(error.error.message);
        });
    }

    calculaBadgeFiltrosGrid(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrosGrid !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += chave === 'empresaNome' ? 2 : 1;
                }

            }

        }

    }

    calculaBadgeFiltrosCabecalho(): void {
        let qtd = 0;

        if (this.filtroCompetenciaDate) {
            qtd += 1;
        }
        if (this.formFiltrosCabecalho.formGroup.value.equipe_id) {
            qtd += 1;
        }
        if (this.formFiltrosCabecalho.formGroup.value.responsavel_id) {
            qtd += 1;
        }
        if (this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id) {
            qtd += 1;
        }

        this.qtdFiltrosCabecalho = qtd;

    }

    carregaAtividades(parametros: NzTableQueryParams): void {

        this.reqAtividades?.unsubscribe(); // caso usuário remova o grupo (se existir) antes do resultado.

        this.calculaBadgeFiltrosGrid();

        this.loading = true;

        const order: any = parametros?.sort ? this.service.montaOrder(parametros.sort) : null;

        const filtros = this.formFiltrosGrid.formGroup.value;

        filtros.page = this.pagination.current_page;

        if (this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').value) {
            filtros.grupoEmpresarial_id = this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').value;
        }

        this.reqAtividades = this.service.retornaAtividades(this.mesAtivo, this.anoAtivo, filtros, order).subscribe((response: any) => {
            this.items = response.data;
            if (response.data?.length === 0) {
                this.travarScroll = true;
            }

            // Tratamento de dados
            this.items.forEach((value, i) => {

                if (value.competencia && value.periodicidade !== 'A') {
                    const mesNum = Number(value.competencia.split('-')[1]);
                    value.competencia = this.arrayCompetencias[mesNum - 1] + '/' +
                        value.competencia.split('-')[0];
                } else {
                    value.competencia = value.competencia.split('-')[0];
                }

                value.id = value.atividade_id;
                const indexStatus = value.status;
                value.status = this.arrayStatus[indexStatus];
                value.fontColor = this.arrayFontCorStatus[indexStatus];

                if (value.confirmacaoStatus) {

                    switch (value.confirmacaoStatus) {

                        case 'N': {
                            value.conclusaoIcon = 'fa-minus';
                            value.corIcon = '';
                            value.conclusaoToltip = 'À Confirmar';
                            break;
                        }
                        case 'R': {
                            value.conclusaoIcon = 'fa-times';
                            value.corIcon = '#f44336';
                            value.conclusaoToltip = 'Rejeitada';
                            break;
                        }
                        case 'A': {
                            value.conclusaoIcon = 'fa-sync';
                            value.corIcon = '#666ee8';
                            value.conclusaoToltip = 'Verificando';
                            break;
                        }
                        case 'C': {

                            value.conclusaoIcon = 'fa-check';
                            value.corIcon = '#28d094';
                            value.conclusaoToltip = 'Confirmada';

                            break;
                        }

                    }

                }

                if (!value.agendaConfirmavel) {
                    value.conclusaoIcon = 'fa-check';
                    value.corIcon = 'gray';
                    value.conclusaoToltip = 'Não tem confirmação';
                }

                const responsaveis = value.responsaveis;

                value.responsaveis = [];

                responsaveis?.forEach((secondValue) => {


                    let iniciaisResponsavel = secondValue?.nome?.split(' ');

                    iniciaisResponsavel = iniciaisResponsavel[0].slice(0, 1) + iniciaisResponsavel[1].slice(0, 1);

                    value.responsaveis.push({
                        iniciais: iniciaisResponsavel,
                        nome: secondValue?.nome,
                        backgroundColor: this.stringToColour(secondValue?.nome),
                        concluiu: secondValue?.nome === value.conclusaoUsuario
                    });

                    value.responsavelConcluiu = secondValue?.nome === value.conclusaoUsuario;

                });

                if (value.conclusaoUsuario) {

                    const iniciaisConcluiu = value.conclusaoUsuario.split(' ');

                    value.concluiuIniciais = iniciaisConcluiu[0].slice(0, 1) + iniciaisConcluiu[1].slice(0, 1);

                    value.concluiuBackgroundColor = this.stringToColour(value.conclusaoUsuario);

                }

            });

            this.pagination = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loading = false;
            this.cdr.detectChanges();


        }, (res) => {
            this.loading = false;
        });

    }

    loadTasksNextPage(parametros: NzTableQueryParams): void {
        this.loadingNextPage = true;

        const order: any = parametros?.sort ? this.service.montaOrder(parametros.sort) : null;

        const filtros = this.formFiltrosGrid.formGroup.value;

        filtros.page = this.paginaAtual + 1;

        this.service.retornaAtividades(this.mesAtivo, this.anoAtivo, filtros, order).subscribe((response: any) => {
            // Tratamento de novos dados
            response.data.forEach((value, i) => {

                if (value.competencia && value.periodicidade !== 'A') {
                    const mesNum = Number(value.competencia.split('-')[1]);
                    value.competencia = this.arrayCompetencias[mesNum - 1] + '/' +
                        value.competencia.split('-')[0];
                } else {
                    value.competencia = value.competencia.split('-')[0];
                }

                value.id = value.atividade_id;
                const indexStatus = value.status;
                value.status = this.arrayStatus[indexStatus];
                value.fontColor = this.arrayFontCorStatus[indexStatus];

                if (value.confirmacaoStatus) {

                    switch (value.confirmacaoStatus) {

                        case 'N': {
                            value.conclusaoIcon = 'fa-minus';
                            value.corIcon = '';
                            value.conclusaoToltip = 'À Confirmar';
                            break;
                        }
                        case 'R': {
                            value.conclusaoIcon = 'fa-times';
                            value.corIcon = '#f44336';
                            value.conclusaoToltip = 'Rejeitada';
                            break;
                        }
                        case 'A': {
                            value.conclusaoIcon = 'fa-sync';
                            value.corIcon = '#666ee8';
                            value.conclusaoToltip = 'Verificando';
                            break;
                        }
                        case 'C': {

                            value.conclusaoIcon = 'fa-check';
                            value.corIcon = '#28d094';
                            value.conclusaoToltip = 'Confirmada';

                            break;
                        }

                    }

                }

                if (!value.agendaConfirmavel) {
                    value.conclusaoIcon = 'fa-check';
                    value.corIcon = 'gray';
                    value.conclusaoToltip = 'Não tem confirmação';
                }

                const responsaveis = value.responsaveis;

                value.responsaveis = [];

                responsaveis?.forEach((secondValue) => {

                    let iniciaisResponsavel = typeof secondValue === 'string' ? secondValue.split(' ') : secondValue.nome.split(' ');

                    iniciaisResponsavel = iniciaisResponsavel[0].slice(0, 1) + iniciaisResponsavel[1].slice(0, 1);

                    value.responsaveis.push({
                        iniciais: iniciaisResponsavel,
                        nome: secondValue,
                        backgroundColor: this.stringToColour(secondValue),
                        concluiu: secondValue === value.conclusaoUsuario
                    });

                    value.responsavelConcluiu = secondValue === value.conclusaoUsuario;

                });

                if (value.conclusaoUsuario) {

                    const iniciaisConcluiu = value.conclusaoUsuario.split(' ');

                    value.concluiuIniciais = iniciaisConcluiu[0].slice(0, 1) + iniciaisConcluiu[1].slice(0, 1);

                    value.concluiuBackgroundColor = this.stringToColour(value.conclusaoUsuario);

                }

            });

            this.items = [...this.items, ...response.data];

            this.paginaAtual++;

            this.pagination = new Pagination(
                response?.per_page || this.pagination.per_page,
                response?.current_page || this.pagination.current_page,
                response?.last_page || this.pagination.last_page,
                response?.total || this.pagination.total);

            this.loadingNextPage = false;
            this.cdr.detectChanges();
        });

    }

    stringToColour(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line:no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            // tslint:disable-next-line:no-bitwise
            const value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.carregaAtividades(params);

        this.resetCheckedSet();

        this.modalAnexos(false);

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrar(): any {

        this.checkedItems.clear();
        this.disabledDesconcluir = true;
        this.checked = false;

        this.paginaAtual = 1;
        this.pagination.current_page = 1;

        this.formFiltrosGrid.modalVisible = false;

        if (this.formFiltrosGrid.formGroup.value.competencia) {
            const data = new Date(this.formFiltrosGrid.formGroup.value.competencia);

            const mes = Number(data.getMonth()) + 1;

            this.formFiltrosGrid.formGroup.value.competencia = data.getFullYear() + '-' + (mes > 9 ? mes : `0${mes}`);

        }

        this.carregaAtividades(this.currentParams);

    }

    filtrarCabecalho(): any {

        this.checkedItems.clear();
        this.disabledDesconcluir = true;
        this.checked = false;

        this.mesAtivo = this.filtroCompetenciaDate.getMonth() + 1;
        this.anoAtivo = this.filtroCompetenciaDate.getFullYear();

        this.selectedDate.setMonth(this.mesAtivo - 1);

        this.formFiltrosGrid.formGroup.get('equipe_id').setValue(this.formFiltrosCabecalho.formGroup.value.equipe_id);
        this.formFiltrosGrid.formGroup.get('responsavel_id').setValue(this.formFiltrosCabecalho.formGroup.value.responsavel_id);

        if (this.formFiltrosCabecalho.formGroup.value.equipe_id) {
            this.equipefiltradaDescricao =
                this.arrayFiltrosEquipes.find(g => g.key === this.formFiltrosCabecalho.formGroup.value.equipe_id)?.label;
        } else {
            this.equipefiltradaDescricao = null;
        }

        if (this.formFiltrosCabecalho.formGroup.value.responsavel_id) {
            this.responsavelFiltradoDescricao =
                this.arrayFiltrosResponsaveis.find(r => r.key === this.formFiltrosCabecalho.formGroup.value.responsavel_id)?.label;
        } else {
            this.responsavelFiltradoDescricao = null;
        }

        if (this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id) {
            this.grupoFiltradoDescricao =
                this.arraySelectGrupos.find(r => r.id === this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id)?.descricao;
        } else {
            this.grupoFiltradoDescricao = null;
        }

        this.formFiltrosCabecalho.modalVisible = false;

        this.carregaCabecalho(this.mesAtivo, this.anoAtivo);

        this.carregaAtividades(this.currentParams);

    }

    abrirModal(formulario: FormStack): void {
        this.empresaNome = this.formFiltrosGrid.formGroup.value.empresaNome;
        formulario.modalVisible = true;

        this.retornaComboObrigacoes();
    }

    limparFiltros(): void {

        this.checkedItems.clear();
        this.disabledDesconcluir = true;
        this.checked = false;

        this.paginaAtual = 1;
        this.pagination.current_page = 1;

        this.formFiltrosGrid.formGroup.reset();
        this.formFiltrosCabecalho.formGroup.reset();
        this.filtroStatus = null;
        this.filtroConfirmacoes = null;
        this.filtroVencimento = null;

        this.filtroObrigacao = '';
        this.empresaNome = '';
        this.unidade = '';

        this.filtroEquipe = null;
        this.filtroResponsavel = null;

        this.equipefiltradaDescricao = null;
        this.responsavelFiltradoDescricao = null;
        this.grupoFiltradoDescricao = null;

        this.calculaBadgeFiltrosCabecalho();

        this.carregaAtividades(this.currentParams);
    }

    acaoConcluir(id: string = null): void {

        this.loading = true;
        this.cardCabecalhoLoading = true;

        if (id) {
            this.checkedItems.add(id);
        }

        this.service.concluirAtividades(this.checkedItems).subscribe((res: any) => {

            this.toastrService.success(res.dados.mensagem);

            if (res.dados.erros) {
                res.dados.erros.forEach(e => {
                    this.toastService.error(e);
                });
            }

            this.checkedItems.clear();

            this.carregaAtividades(this.currentParams);
            this.carregaCabecalho(this.mesAtivo, this.anoAtivo);
            this.loading = false;
            this.checked = false;

        }, (response) => {

            this.loading = false;
            this.checked = false;
            this.cardCabecalhoLoading = false;
            this.toastrService.error(response.error.message);
            this.newVisibilidadeModal = false;

        });

    }

    acaoDesconcluir(id: string = null): void {

        this.loading = true;
        this.cardCabecalhoLoading = true;

        if (id) {
            this.checkedItems.add(id);
        }

        this.service.desconcluirAtividades(this.checkedItems).subscribe((res: any) => {

            this.toastrService.success('Conclusão desfeita com sucesso!');

            this.checkedItems.clear();

            this.carregaAtividades(this.currentParams);
            this.carregaCabecalho(this.mesAtivo, this.anoAtivo);

            this.checked = false;


        }, (response) => {
            this.loading = false;
            this.cardCabecalhoLoading = false;

            this.toastrService.error(response.error.message);
            this.newVisibilidadeModal = false;

        });

    }

    onAlterarEquipe() {
        this.getEquipes();
        this.inputEquipe = '';
        this.limparEquipes = false;
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    onAlterarEquipeUnica(equipeID) {
        this.getEquipes();
        this.checkedItems.clear();
        this.checkedItems.add(equipeID);
        this.limparEquipes = false;
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    getEquipes() {
        this.equipeService.listToSelect().subscribe((result) => {
            this.equipeOptions = result.map((option: any) => ({
                label: option.nome,
                value: option.id
            }));
        });
    }

    addFiltro(filtroIn: string, valorIn: any): void {

        this.checkedItems.clear();

        const arrayFiltros: any[] = [];

        arrayFiltros.push({
            filtro: filtroIn,
            valor: valorIn
        });

        switch (filtroIn) {
            case 'confirmacoes': {
                if (this.formFiltrosGrid.formGroup.value.confirmacoes === valorIn) {
                    this.formFiltrosGrid.formGroup.get('confirmacoes').setValue('');
                    this.filtroConfirmacoes = null;
                } else {
                    this.formFiltrosGrid.formGroup.get('confirmacoes').setValue(valorIn);
                    this.filtroConfirmacoes = valorIn;
                }

                break;
            }
            case 'status': {

                if (this.formFiltrosGrid.formGroup.value.status === valorIn) {
                    this.formFiltrosGrid.formGroup.get('status').setValue('');
                    this.filtroStatus = null;
                } else {
                    this.formFiltrosGrid.formGroup.get('status').setValue(valorIn);
                    this.filtroStatus = valorIn;
                }
                break;
            }
        }

        this.formFiltrosGrid.formGroup.get('obrigacao').setValue(null);
        this.formFiltrosGrid.formGroup.value.empresaNome = null;
        this.filtroObrigacao = null;

        this.filtrar();
        this.cdr.detectChanges();


    }

    selecionaDiaVencimento(dia: number, filtrar = true): void {

        if (this.filtroVencimento !== null && this.filtroVencimento.getDate() === dia) {
            this.filtroVencimento = null;
            this.formFiltrosGrid.formGroup.get('vencimento').setValue(null);
        } else {
            const data = new Date(this.anoAtivo, this.mesAtivo - 1, dia);
            this.filtroVencimento = data;
            this.formFiltrosGrid.formGroup.get('vencimento').setValue(data);
        }

        this.formFiltrosGrid.formGroup.get('obrigacao').setValue(null);
        this.formFiltrosGrid.formGroup.get('empresaNome').setValue(null);
        this.formFiltrosGrid.formGroup.value.empresaNome = null;
        this.filtroObrigacao = null;

        if (filtrar) {
            this.filtrar();
        }
    }

    addNewEquipe() {
        this.newVisibilidadeModal = true;
    }

    closeNewEquipe() {
        this.newVisibilidadeModal = false;
    }

    saveNewEquipe() {

        if (!Utils.isEmpty(this.newEquipe)) {

            this.equipeService.save({nome: this.newEquipe} as Equipe).subscribe((res: any) => {

                this.toastrService.success('Equipe gravada com sucesso');
                // this.dataVisibilityForm.data.push(res.data);
                this.inputEquipe = res.id;
                this.getEquipes();
                this.newVisibilidadeModal = false;


            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
    }

    saveBulkToEquipes() {

        if (!this.inputEquipe && !this.limparEquipes) {
            this.toastrService.error('Selecione a equipe');
        } else {

            const selectedEquipes = [];

            if (this.inputEquipe) {
                selectedEquipes.push(this.inputEquipe);
            }

            this.equipeService.vincularEquipesObrigacoes(this.checkedItems, selectedEquipes, this.limparEquipes)
                .subscribe(
                    () => {
                        this.toastrService.success(`Alteração em massa realizada!`);
                        this.inputEquipe = '';
                        this.displayVisibilidadeModal = false;
                        this.queryTable(this.currentParams, this.currentSearch);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
        }
    }

    zerarInputEquipe() {
        this.inputEquipe = '';
    }

    saveBulkToResponsavel() {

        const responsavel = this.formResponsavel.formGroup.value.responsavel_id;

        if (this.empresaId) {
            this.checkedItems.add(this.empresaId);
        }

        this.service.vincularResponsavelCalendar(this.checkedItems, responsavel).subscribe((response) => {

                this.resetCheckedSet();

                this.refreshCheckedStatus();

                this.toastrService.success(`Responsável vinculado com sucesso!`);

                this.formResponsavel.modalVisible = false;

                this.queryTable(this.currentParams, this.currentSearch);
            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    montaArrayDiasMeses(mesAtivo: number, anoAtivo: number): void {

        const mes = [];

        const data: Date = new Date();
        data.setDate(1);
        data.setMonth(mesAtivo - 1);
        data.setFullYear(anoAtivo);
        const diaDaSemana = data.getDay() + 1;

        // dias mes anterior
        const ultimoDiaMesAnterior = new Date(data.getFullYear(), data.getMonth(), 0).getDate();
        const ultimoDiaMesAnteriorSemana = new Date(data.getFullYear(), data.getMonth(), 0).getDay() + 1;
        const primeiroDiaCalendario = ultimoDiaMesAnterior - 7 + diaDaSemana + 1;

        const objFlags = {
            pendentes: false,
            atrasadas: false,
            concluidas: false,
            concluidasComAtraso: false
        };

        if (diaDaSemana > 1) {
            for (let i = ultimoDiaMesAnteriorSemana; i >= 1; i--) {
                mes.push({
                    dia: ultimoDiaMesAnterior - i + 1,
                    disabled: true,
                    flags: objFlags,
                });
            }
        }


        const ultimoDiaMesData = new Date(data.getFullYear(), data.getMonth() + 1, 0);
        const ultimoDiaMesInt = ultimoDiaMesData.getDate();
        const ultimoDiaMesSemana = ultimoDiaMesData.getDay() + 1;

        for (let i = 1; i <= ultimoDiaMesInt; i++) {
            let flagsMes = objFlags;
            this.flagsMapa.forEach((flag) => {
                if (flag.dia === i) {
                    flagsMes = flag.flags;
                }
            });
            mes.push({
                dia: i,
                disabled: false,
                flags: flagsMes
            });
        }

        // dias mes seguinte
        for (let diaMesSeguinte = 1; diaMesSeguinte <= 7 - ultimoDiaMesSemana; diaMesSeguinte++) {
            mes.push({
                dia: diaMesSeguinte,
                disabled: true,
                flags: objFlags
            });
        }

        // Monta Semanas
        this.arraySemanas = [];

        let semana = [];
        for (let i = 0; i <= 6; i++) {
            semana.push(mes[i]);
        }
        this.arraySemanas.push(semana);
        semana = [];
        for (let i = 7; i <= 13; i++) {
            semana.push(mes[i]);
        }
        this.arraySemanas.push(semana);
        semana = [];
        for (let i = 14; i <= 20; i++) {
            semana.push(mes[i]);
        }
        this.arraySemanas.push(semana);
        semana = [];
        for (let i = 21; i <= 27; i++) {
            semana.push(mes[i]);
        }
        this.arraySemanas.push(semana);
        semana = [];
        for (let i = 28; i <= 34; i++) {
            semana.push(mes[i]);
        }
        this.arraySemanas.push(semana);

        this.sextaSemana = false;

        const ultimoDia = semana[semana.length - 1].dia;

        if ([1, 2].includes(ultimoDiaMesInt - ultimoDia)) {
            semana = [];
            for (let i = ultimoDia + 1; i <= ultimoDiaMesInt; i++) {
                const dia = mes.filter((d) => d.dia === i && !d.disabled);
                semana.push(dia[0]);
            }

            const faltaPreencher = 7 - semana.length;

            for (let i = 0; i <= faltaPreencher; i++) {
                semana.push({
                    dia: i, disabled: true,
                    flags: {
                        pendentes: false,
                        atrasadas: false,
                        concluidas: false,
                        concluidasComAtraso: false
                    }
                });
            }

            this.arraySemanas.push(semana);

            this.sextaSemana = true;

        }

        setTimeout(() => {

            this.cdr.detectChanges();
        }, 1000);


    }

    onAllChecked(checked: boolean): void {

        this.items.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));

        if (!checked) {
            this.disabledDesconcluir = true;
        } else {
            this.disabledDesconcluir = this.defineDisabledDesconcluir();
        }

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);
        this.disabledDesconcluir = this.defineDisabledDesconcluir();

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {
            this.checkedItems.add(id);
        } else {
            this.checkedItems.delete(id);
        }

        if (this.items.length === this.checkedItems.size) {
            this.checked = true;
        } else {
            this.checked = false;
        }
    }

    defineDisabledDesconcluir(): boolean {
        let disabled = true;
        let definiu = false;
        this.items.forEach((tasks) => {

            if (!definiu && this.checkedItems.has(tasks.atividade_id) && tasks.confirmacaoStatus !== 'C' &&
                (tasks.status === 'Concluída' || tasks.status === 'Concluída com atraso') &&
                !tasks.agendaConfirmavel ||
                (this.checkedItems.has(tasks.atividade_id) && tasks.confirmacaoStatus === 'C' && !tasks.agendaConfirmavel)) {
                disabled = false;
                definiu = true;
            } else if (!definiu) {
                disabled = true;
            }

        });

        return disabled;
    }

    showConfirmDesconcluir(id: string = null): void {

        this.modalService.confirm({
            nzTitle: 'Deseja desfazer a conclusão da(s) Atividade(s)?',
            nzOkText: 'Desfazer',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.acaoDesconcluir(id)
        });

    }

    showConfirmConcluir(id: string = null, obrigatorioAprovacao = false, aprovacaoStatus = false): void {

        if (!obrigatorioAprovacao || aprovacaoStatus) {
            this.modalService.confirm({
                nzTitle: 'Deseja concluir a(s) Atividade(s)?',
                nzOkText: 'Concluir',
                nzCancelText: 'Cancelar',
                nzOnOk: () => this.acaoConcluir(id)
            });
        }

    }

    showConfirmSolicitarAprovacao(id: string = null, disabled: boolean = false): void {
        if (!disabled) {
            this.fileList = [];
            this.formSolicitarAprovacao.formGroup.get('id').setValue(id);
            this.modalService.confirm({
                nzTitle: 'Deseja incluir anexo na solicitação de aprovação?',
                nzOkText: 'Sim',
                nzCancelText: 'Não',
                nzOnOk: () => this.modalSolicitarAprovacao(true),
                nzOnCancel: () => () => {
                }
            });
        } else {
            this.toastService.warning('Já existe uma solicitação de aprovação para esta obrigação');
        }
    }

    modalSolicitarAprovacao(visible: boolean): void {
        this.formSolicitarAprovacao.modalVisible = visible;
    }

    modalExportar(visible: boolean): void {
        this.dataExport.url = `/calendar/tasks/atividades/${this.anoAtivo}/${this.service.mesStr(this.mesAtivo)}/exportar`;
        this.dataExport.filtros = this.formFiltrosGrid.formGroup.value;
        this.componentExport.visible = visible;
    }

    confirmaSolicitarAprovacao(): void {

        this.loadings.uploadinAnexo = true;
        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivos[]', file);
        });

        const id = this.formSolicitarAprovacao.formGroup.value.id;

        this.service.solicitarAprovacao(id, formData).subscribe((res) => {
            this.toastService.success('Solicitação de aprovação enviada!');
            this.carregaAtividades(this.currentParams);
            this.modalSolicitarAprovacao(false);
            this.loadings.uploadinAnexo = false;
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.uploadinAnexo = false;
        });


    }

    retornaSelectsCategorias() {
        this.service.retornarSelectsCategorias().subscribe((response) => {
            response.forEach((value) => {
                this.arrayFiltrosCategorias.push({id: value.categoria, descricao: value.categoria});
            });
        });
    }

    clearInput(form, emitter, target) {
        const inputEmitter = form.get(emitter);
        const inputTarget = form.get(target);
        if (inputEmitter.value) {
            inputTarget.setValue(null);
        }
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        return false;
    }

    onAdicionarAnexo(): void {
        this.fileList = [];
        this.modalAdicionarAnexo(true);
    }

    modalAdicionarAnexo(visible: boolean): void {
        this.modalAdicionarAnexosVisible = visible;
    }

    confirmaAdicionaAnexos(): void {

        this.loadings.uploadinAnexo = true;
        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivos[]', file);
        });

        const id = this.currentAtividadeId;

        this.service.solicitarAprovacao(id, formData).subscribe((res) => {
            this.toastService.success('Anexo(s) adicionado(s) com sucesso!');
            this.carregaAtividades(this.currentParams);
            this.modalAdicionarAnexo(false);
            this.onVerAnexos(this.currentAtividadeId, this.currentObrigacaoEmpresaId);
            this.loadings.uploadinAnexo = false;
        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.uploadinAnexo = false;
        });


    }

    onVerAnexos(id: string, obrigacaoEmpresaId: string, anexos = null, tasks?): void {
        this.loadingModalAnexos[id] = true;
        this.listOfAnexos = [];
        this.currentAtividadeId = id;
        this.currentObrigacaoEmpresaId = obrigacaoEmpresaId;

        this.btExcluir = !tasks?.aprovacaoStatus || tasks?.aprovacaoStatus !== 1;

        this.modalAnexos(true, anexos);
        this.loadingModalAnexos[id] = false;
        this.loadings.verAnexos = false;
    }

    modalAnexos(visible: boolean, anexos = []): void {
        if (visible && (!anexos || anexos.length === 0)) {
            this.listOfAnexos = [];
            this.toastService.warning('Sem arquivos para exibir');
        } else {
            this.listOfAnexos = anexos;
            this.modalVerAnexosVisible = visible;
        }
    }

    showModalHistorico(visible: boolean) {
        this.modalHistoricoVisible = visible;
    }

    getHistorico(atividadeId: string): void {
        this.itemsHistorico = [];
        this.loadingHistorico = true;

        this.showModalHistorico(true);

        this.service.getHistorico(atividadeId).subscribe((response: any) => {
            this.itemsHistorico = response;
            this.loadingHistorico = false;
        }, err => {
            this.toastService.error(err.message);
        });

    }

    getHistoricoDescricao(status) {
        const descricoes = {
            C: 'Concluída',
            CA: 'Concluída com atraso',
            A: 'Atrasado',
            P: 'Pendente',
            O: 'Confirmada',
            R: 'Rejeitada',
        };

        return descricoes[status] || '-';
    }

    removerFiltroGrupo() {
        this.formFiltrosCabecalho.formGroup.get('grupoEmpresarial_id').setValue(null);
        this.grupoFiltradoDescricao = null;

        this.filtrarCabecalho();
    }

    protected readonly HighchartsPie = HighchartsPie;
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Vencimento} from '@models/vencimento.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';

@Injectable({providedIn: 'root'})
export class ObrigacaoVencimentoService extends AbstractService<Vencimento> {

    id: string;

    constructor(http: HttpClient) {

        super(http, `/cadastros/obrigacao/vencimentos`, Vencimento);

    }


    save(campos): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao`, campos);

    }

    update(id, campos): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao/detalhe/${id}`, campos);

    }

    findById(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/detalhe/${id}`);

    }



}

export class Pagination {
    // tslint:disable-next-line:variable-name
    per_page: number;
    // tslint:disable-next-line:variable-name
    current_page: number;
    // tslint:disable-next-line:variable-name
    last_page: number;
    total: number;
    from: number;
    to: number;

    // tslint:disable-next-line:variable-name
    constructor(per_page = 50, current_page = 1, last_page = 1, total = 0, from = 0, to = 0) {
        this.per_page = Number(per_page);
        this.current_page = Number(current_page);
        this.last_page = Number(last_page);
        this.total = Number(total);
        this.from = Number(from);
        this.to = Number(to);
    }
}

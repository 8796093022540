<nz-page-header class="site-page-header" nzTitle="Monitoramento" nzSubtitle="Processamentos">
</nz-page-header>
<nz-content>

    <nz-card class="ant-card-table" nzTitle="Processamentos" [nzExtra]="cardExtra">
        <nz-table #tabelaProcessamentos
                [nzFrontPagination]="false"
                [nzShowPagination]="false"
                [nzData]="arrayProcessamentos"
                nzSize="small">
            <thead>
            <tr>
                <th nzAlign="left" nzLeft>Item</th>
                <th nzAlign="center" nzWidth="5%" *ngFor="let colunaMes of colunasMeses">{{colunaMes}}</th>
            </tr>
            </thead>
            <tbody>
            <tr [ngStyle]="{'font-size' : '12px'}" *ngFor="let item of tabelaProcessamentos.data">
                <ng-container *ngIf="item.itens">
                    <td nzAlign="left" nzLeft>
                        <span style="padding-left: 15px">{{item.label}}</span>
                    </td>
                    <td nzAlign="center" *ngFor="let colunaMes of colunasMeses; index as mesIndex">
                        <ng-container *ngIf="item.itens[mesIndex]">
                            {{item.itens[mesIndex]}}
                        </ng-container>
                        <ng-container *ngIf="!item.itens[mesIndex]">-</ng-container>
                    </td>
                </ng-container>
                <ng-container *ngIf="!item.itens">
                    <td nzAlign="left" nzLeft colspan="{{colunasMeses.length+1}}">
                        <strong>{{item.label}}</strong>
                    </td>
                </ng-container>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-content>

<ng-template #cardExtra>
    <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu">{{anoSelecionado}}
        <i nz-icon nzType="down"></i>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item *ngFor="let ano of anos">
                <a (click)="selecionaAno(ano)">{{ano}}</a>
            </li>
        </ul>
    </nz-dropdown-menu>
</ng-template>

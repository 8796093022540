import {NgModule} from '@angular/core';
import {ComplianceTributosEmpresasComponent} from './complianceTributosEmpresas.component';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {UiModule} from '../../ui.module';
import {ComplianceTributosEmpresasRoutingModule} from './complianceTributosEmpresas-routing.module';
import {CommonModule} from '@angular/common';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {HighchartsChartModule} from 'highcharts-angular';
import {SwiperModule} from 'swiper/angular';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzListModule} from 'ng-zorro-antd/list';
import {SparkLineModule} from '@components/spark-line/spark-line.module';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";

@NgModule({
    imports: [
        ComplianceTributosEmpresasRoutingModule,
        NzCarouselModule,
        NzAnchorModule,
        NzTypographyModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        FormsModule,
        NzSelectModule,
        UiModule,
        NzTabsModule,
        CommonModule,
        NzBreadCrumbModule,
        NzProgressModule,
        NzPageHeaderModule,
        HighchartsChartModule,
        SwiperModule,
        NzSpaceModule,
        NzListModule,
        SparkLineModule,
        NzDescriptionsModule,
        NgxJdenticonModule,
        NzBadgeModule,
        ReactiveFormsModule,
        NzSkeletonModule
    ],
    declarations: [
        ComplianceTributosEmpresasComponent
    ],
})
export class ComplianceTributosEmpresasModule {
}

<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Grupos Empresariais"></nz-page-header>
<nz-content>
    <nz-card class="ant-card-table" [nzExtra]="navBar" nzTitle="Grupos Empresariais">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
<!--                <nz-badge [nzCount]="qtdFiltrosAtivos"-->
<!--                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">-->
<!--                    <button nz-button nzType="primary"-->
<!--                            style="margin-right: 10px;"-->
<!--                            (click)="modalFiltrar(true);">-->
<!--                        <i nz-icon nzType="search"></i>-->
<!--                        Filtros-->
<!--                    </button>-->
<!--                </nz-badge>-->
<!--                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"-->
<!--                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">-->
<!--                    Opções-->
<!--                    <em nz-icon nzType="down"></em>-->
<!--                </button>-->
<!--                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">-->
<!--                    <ul nz-menu>-->
<!--                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>-->
<!--                    </ul>-->
<!--                </nz-dropdown-menu>-->
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch)" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
            <!--<Navbar-List (generalFilterHandler)="getGeneralSearch($event)" (resetForm)="resetSearch()"
                         [registerComponent]="'RegisterUserComponent'" (registerClick)="modalCadastrar(true)"
                         [tabTitle]="'Cadastrar Usuário'">
                <div class="nav-item" menuOpcoes>
                    <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                            nzType="default">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    &lt;!&ndash;<li (click)="onAlterarPerfilDeAcesso()" [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Alterar perfil de acesso
                                    </li>&ndash;&gt;
                                    <li (click)="onAlterarVisibilidadeColetiva()"
                                        [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Alterar visibilidade
                                    </li>
                                    &lt;!&ndash;<li (click)="onEmailBoasVindas()" [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                        Enviar email de boas-vindas
                                    </li>&ndash;&gt;
                                </ul>
                            </li>
                            &lt;!&ndash;<li nz-menu-item nzDisabled>Importar para Excel</li>
                            <li (click)="exportUsers()" nz-menu-item nzDisabled>Exportar para Excel</li>&ndash;&gt;
                        </ul>
                    </nz-dropdown-menu>
                </div>
                &lt;!&ndash;<User-Filter (handleFilterEvent)="getFilterParams($event)" filtro></User-Filter>&ndash;&gt;
            </Navbar-List>-->
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="left">Descrição</th>
                <th nzColumnKey="empresas" [nzSortFn]="true" nzAlign="center">Empresas</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(item.id, $event)"
                    [nzChecked]="checkedItems.has(item.id)"
                    [nzDisabled]="item.disabled">
                </td>
                <td>
                    {{item?.descricao}}
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" *ngIf="item?.empresas"
                       (click)="onVerEmpresas(item?.id, item?.descricao)">{{item?.empresas}}</a>
                    <nz-tag  *ngIf="!item?.empresas" (click)="onVerEmpresas(item?.id, item?.descricao)">Nenhuma</nz-tag>

                </td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="editar(item?.id)">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button
                            (click)="showConfirmRemover(item?.id)"
                            nzType="link">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal *ngIf="profileOptions?.length" [(nzVisible)]="displayProfileModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Perfil de Acesso">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedProfile"
                   [nzPlaceHolder]="'Selecione'">
            <nz-option *ngFor="let option of profileOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="saveBulkToProfiles()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>
<nz-modal *ngIf="visibilidadeOptions?.length" [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Visibilidades">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedVisibilidades"
                   [nzPlaceHolder]="'Selecione as visibilidades'"
                   nzMode="multiple">
            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparVisibilidades">Limpar as já cadastradas anteriormente</label>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="addNewVisibilidade()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova visibilidade
        </button>
        <button (click)="saveBulkToVisibilities()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>
<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="saveNewVisibilidade()" [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Visibilidade">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>
<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar novo Grupo Empresarial'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzFor="primeiroNome" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditar.modalVisible" [nzTitle]="'Editar'" [nzClosable]="true"
          (nzOnCancel)="modalEditar(false)" [nzWidth]="648">


    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.carregandoGrupo"></nz-skeleton>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formEditar.formGroup"
              style="width: 100%;"
              *ngIf="!loadings.carregandoGrupo"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.editando" (click)="confirmaEditar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.descricao !== '' && formFiltrar.formGroup.value.descricao !== null">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalListarEmpresasVisible"
          [nzTitle]="'Empresas do Grupo: ' + nomeGrupoSelecionado" [nzClosable]="true"
          (nzOnCancel)="modalListarEmpresas(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.carregandoEmpresas"></nz-skeleton>
        <nz-table #basicTable
                  [nzData]="arrayEmpresasGrupo"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  style="width: 100%; font-size: 10px;"
                  [nzScroll]="{ y: '340px', x: 'auto'}"
                  *ngIf="!loadings.carregandoEmpresas"
        >
            <thead>
            <tr>
                <th>Empresa</th>
                <th nzAlign="center">CNPJ</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>{{ data?.nome }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.cnpj | cpfCnpj }}</td>
                <td><button nz-button nzType="link" nzDanger (click)="deleteVinculoEmpresa(data?.id)"><i class="far fa-trash-alt"></i></button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button class="new-markup-bnt" nz-button nzType="link" (click)="onEditarEmpresasGrupo()" >
            <i nz-icon nzType="edit" nzTheme="outline"></i> Editar empresas
        </button>
        <button nz-button nzType="link" (click)="modalListarEmpresas(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalListarEmpresasGrupoVisible"
          [nzTitle]="'Editar Grupo ' + nomeGrupoSelecionado" [nzClosable]="true"
          (nzOnCancel)="modalListarEmpresasGrupo(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>


        <nz-skeleton *ngIf="loadings.carregandoTodasEmpresas"></nz-skeleton>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%;"
                  *ngIf="!loadings.carregandoTodasEmpresas" >
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItemsEmpresas.size }} {{ checkedItemsEmpresas.size === 1 ? 'Empresa Vinculada' : 'Empresas Vinculadas'}}
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-table #basicTable
                  [nzData]="arrayTodasEmpresas"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  [nzScroll]="{ y: '340px', x: 'auto'}"
                  style="font-size: 10px; width: 100%"
                  *ngIf="!loadings.carregandoTodasEmpresas"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllCheckedEmpresas($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all-visibilidade">
                </th>
                <th>Empresa</th>
                <th nzAlign="center">CNPJ</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td nzLeft style="width: 30px;" (nzCheckedChange)="onItemCheckedEmpresas(data.id, $event)"
                    [nzChecked]="checkedItemsEmpresas.has(data.id)"
                    [nzDisabled]="data.disabled">
                </td>
                <td>{{ data?.nome }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data?.cnpj | cpfCnpj }}</td>
            </tr>
            </tbody>
        </nz-table>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalListarEmpresasGrupo(false);">Fechar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvandoEmpresas" (click)="salvarEmpresas();">Salvar</button>
    </div>
</nz-modal>
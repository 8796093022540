<nz-page-header class="site-page-header" nzTitle="Check" style="padding: 3px"
                nzSubtitle="Obrigações">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{collapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false"
                    [navigation]="true"
            >
                <ng-template *ngFor="let item of competencias; index as i" swiperSlide let-data
                             class="swiper-card-competencia">
                    <div class="card-competencia ant-card pull-up card-{{i}}"
                         [ngClass]="collapseCard && 'compress'">
                        <div class="ant-card-head">
                            <h2>{{(objectMeses[item.mes - 1] | uppercase)}}</h2>
                            <h3>{{item.ano}}</h3>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <!--[ngClass]="i == 0 ? 'cardShadow' : ''" [nzTitle]=""-->
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.obrigacoes}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.unidades}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Armazenamento</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.armazenamentoOk}}</span>
                                            <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                        </nz-col>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.armazenamentoErro}}</span>
                                            <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>

                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center">
                                    <span nz-typography nzType="secondary">Validações</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.validacoesOk}}</span>
                                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                        </nz-col>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.validacoesErro}}</span>
                                            <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Conciliações</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.conciliacoesOk}}</span>
                                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                        </nz-col>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.conciliacoesErro}}</span>
                                            <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>

                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center">
                                    <span nz-typography nzType="secondary">Aprovações</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.aprovacoesAguardando}}</span>
                                            <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                        </nz-col>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.aprovacoesAprovadas}}</span>
                                            <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>

                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Transmissões</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.transmissoesOk}}</span>
                                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                        </nz-col>
                                        <nz-col [nzSpan]="9" style="text-align: right">
                                            <span style="margin-right: 5px;">{{item.transmissoesErro}}</span>
                                            <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
    <ng-template #tabsExtra>
        <nz-space>
            <button *nzSpaceItem nz-button (click)="add(modalContent,'')" [nzType]="'primary'">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
            <button *nzSpaceItem nz-button nz-dropdown [nzDropdownMenu]="batchMenu" nzPlacement="bottomLeft">
                Operação em Lote
                <i nz-icon nzType="down"></i>
            </button>
            <button *nzSpaceItem nz-button [nzType]="'default'" (click)="clickEvent()">
                <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </nz-space>
        <nz-dropdown-menu #batchMenu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item>Excluir</li>
                <li nz-menu-item>Aprovação</li>
            </ul>
        </nz-dropdown-menu>
    </ng-template>
</nz-content>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                            <div>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    {{this.objectMeses[this.cabecalho.mes - 1]}}
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0;">
                                    {{this.cabecalho.ano}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24"
                             class="d-flex align-items-center">
                            <div>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Unidades
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #009688 !important;">
                                    {{this.cabecalho.unidades}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24"
                             class="d-flex align-items-center">
                            <!--<i class="far fa-calendar-alt"
                               style="font-size: 60px; margin-right: 15px; color:#1e9ff2 !important;"></i>-->
                            <div>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Obrigações
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #1e9ff2 !important;">
                                    {{this.cabecalho.obrigacoes}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Armazenadas
                            </h4>
                            <nz-row>
                                <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                    <nz-progress [nzPercent]="(cabecalho.porcentagemArmazenadasAguardando | number: '1.0-1')" [nzSuccessPercent]="cabecalho.porcentagemArmazenadasAprovadas" nzType="circle"
                                                 [nzWidth]="40"
                                                 nz-tooltip
                                                 nzTooltipTitle="{{this.cabecalho.armazenamentoErro}} Aguardando / {{this.cabecalho.armazenamentoOk}} Aprovadas"
                                                 [nzFormat]="formatOne"></nz-progress>
                                </nz-col>
                            </nz-row>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Validações
                            </h4>
                            <nz-row>
                                <nz-col [nzSpan]="24" style="text-align: right">
                                    <div class="d-flex justify-content-between">
                                        <span class="text-grey-dark" style="margin-right: 10px">Erros</span>
                                        <div class="d-flex align-items-center">
                                            <span style="margin-right: 5px;">{{cabecalho.validacoesErro}}</span>
                                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span class="text-grey-dark" style="margin-right: 10px">Ok</span>
                                        <div class="d-flex align-items-center">
                                            <span style="margin-right: 5px;">{{cabecalho.validacoesOk}}</span>
                                            <i class="fas fa-circle"
                                               style="color: #52c41a; font-size:10px;"></i>
                                        </div>
                                    </div>
                                </nz-col>
                            </nz-row>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Concliliações
                            </h4>
                            <nz-row>
                                <nz-col [nzSpan]="24" style="text-align: right">
                                    <div class="d-flex justify-content-between">
                                        <span class="text-grey-dark" style="margin-right: 10px">Erros</span>
                                        <div class="d-flex align-items-center">
                                            <span style="margin-right: 5px;">{{cabecalho.conciliacoesErro}}</span>
                                            <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <span class="text-grey-dark" style="margin-right: 10px">Ok</span>
                                        <div class="d-flex align-items-center">
                                            <span style="margin-right: 5px;">{{cabecalho.conciliacoesOk}}</span>
                                            <i class="fas fa-circle"
                                               style="color: #52c41a; font-size:10px;"></i>
                                        </div>
                                    </div>
                                </nz-col>
                            </nz-row>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Aprovações
                            </h4>
                            <nz-row>
                                <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                    <nz-progress [nzPercent]="(cabecalho.porcentagemAprovacoesAguardando | number: '1.0-1')" [nzSuccessPercent]="cabecalho.porcentagemAprovacoesAprovadas" nzType="circle"
                                                 [nzWidth]="40"
                                                 nz-tooltip
                                                 nzTooltipTitle="{{cabecalho.aprovacoesAguardando}} Aguardando / {{cabecalho.aprovacoesAprovadas}} Aprovadas"
                                                 [nzFormat]="formatOne"></nz-progress>
                                </nz-col>
                            </nz-row>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
        <div nz-col [nzSpan]="3">
            <nz-content class="ant-card">
                <div class="ant-card-body">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Transmissões
                            </h4>
                            <nz-row>
                                <nz-col [nzSpan]="24" style="text-align: right" class="d-flex justify-content-center">
                                    <nz-progress [nzPercent]="(cabecalho.porcentagemTransmissoesAguardando | number: '1.0-1')" [nzSuccessPercent]="cabecalho.porcentagemTransmissoesAprovadas" nzType="circle"
                                                 [nzWidth]="40"
                                                 nz-tooltip
                                                 nzTooltipTitle="{{cabecalho.transmissoesErro}} Aguardando / {{cabecalho.transmissoesOk}}  Aprovadas"
                                                 [nzFormat]="formatOne"></nz-progress>
                                </nz-col>
                            </nz-row>
                        </div>
                    </div>
                </div>
            </nz-content>
        </div>
    </div>
</nz-content>
<nz-card class="tabsContainer"
         loadingSupervisores
         [nzLoading]="cardTabsLoading">
    <div nz-row nzAlign="top">
        <div nz-col nzSpan="24">
            <nz-card-tab>
                <nz-tabset nzSize="large" class="tabs" [(nzSelectedIndex)]="tabNumber"
                           [nzTabBarExtraContent]="tabsExtra"
                           (nzSelectChange)="changeTabs($event)">
                    <nz-tab nzTitle="Obrigações"></nz-tab>
                    <nz-tab nzTitle="Armazenamento"></nz-tab>
                    <nz-tab nzTitle="Validações"></nz-tab>
                    <nz-tab nzTitle="Conciliações"></nz-tab>
                    <nz-tab nzTitle="Aprovações"></nz-tab>
                    <nz-tab nzTitle="Transmissões"></nz-tab>
                </nz-tabset>
            </nz-card-tab>
            <nz-table #tableObrigacoes *ngIf="tabNumber === 0" [nzData]="obrigacoes" class="scrollbar">
                <thead>
                <tr>
                    <th></th>
                    <th>Obrigações</th>
                    <th>Armazenadas</th>
                    <th>Regras de Validação</th>
                    <th>Regras de Conciliações</th>
                    <th>Aprovações</th>
                    <th>Transmissões</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableObrigacoes.data" style="white-space: nowrap;">
                    <td class="font-weight-bold text-center"
                        style="font-size: 16px;">{{data.obrigacaoNome}}
                    </td>
                    <td class="text-center">{{data.obrigacaoTotal}}</td>
                    <td>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px;">Armazenadas</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.armazenadas}}</span>
                                <i class="fas fa-circle"
                                   style="color: #1890ff; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark"
                                                  style="margin-right: 10px;">Não Armazenadas</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.naoArmazenadas}}</span>
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </div>
                        </div>
                        <nz-progress nz-tooltip
                                     nzTooltipTitle="{{data.armazenadas}} Armazenadas / {{data.naoArmazenadas}} Não Armazenadas"
                                     [nzPercent]="data.formatOne" [nzShowInfo]="false">
                        </nz-progress>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px">Graves</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasValidacaoGraves}}</span>
                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px; width: 100px;">Médias</span>
                            <div class="d-flex justify-content-center align-items-center">
                                <div style="margin-right: 10px;" class="d-flex align-items-center">
                                    <span style="margin-right: 5px;">{{data.regrasValidacaoMediasAguardando}}</span>
                                    <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span style="margin-right: 5px;">{{data.regrasValidacaoMediasLiberadas}}</span>
                                    <i class="fas fa-circle"
                                       style="color: #52c41a; font-size:10px;"></i>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px">Leves</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasValidacaoLeves}}</span>
                                <i class="fas fa-circle"
                                   style="color: #52c41a; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark"
                                                  style="margin-right: 10px">Sem Ocorrências</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasValidacaoSemOcorrencia}}</span>
                                <i class="fas fa-circle"
                                   style="color: #52c41a; font-size:10px;"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px">Graves</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasConciliacoesGraves}}</span>
                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px; width: 100px;">Médias</span>
                            <div class="d-flex justify-content-center align-items-center">
                                <div style="margin-right: 10px;" class="d-flex align-items-center">
                                    <span style="margin-right: 5px;">{{data.regrasConciliacoesMediasAguardando}}</span>
                                    <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span style="margin-right: 5px;">{{data.regrasConciliacoesMediasLiberadas}}</span>
                                    <i class="fas fa-circle"
                                       style="color: #52c41a; font-size:10px;"></i>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px">Leves</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasConciliacoesLeves}}</span>
                                <i class="fas fa-circle"
                                   style="color: #52c41a; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                            <span class="text-grey-dark"
                                                  style="margin-right: 10px">Sem Ocorrências</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.regrasConciliacoesSemOcorrencia}}</span>
                                <i class="fas fa-circle"
                                   style="color: #52c41a; font-size:10px;"></i>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <nz-progress [nzPercent]="data.aprovacoesPorc" nzType="circle"
                                     [nzWidth]="40"
                                     nz-tooltip
                                     nzTooltipTitle="{{data.aprovacoesAguardando}} Aguardando / {{data.aprovacoesAprovadas}} Aprovadas"
                                     [nzFormat]="formatOne"></nz-progress>
                        <div class="d-flex justify-content-between">
                                                <span class="text-grey-dark"
                                                      style="margin-right: 10px;">Aguardando</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.aprovacoesAguardando}}</span>
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                                <span class="text-grey-dark"
                                                      style="margin-right: 10px;">Aprovadas</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.aprovacoesAprovadas}}</span>
                                <i class="fas fa-circle"
                                   style="color: #1890ff; font-size:10px;"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="ant-progress-inner"
                             style="background-color: #bfbfbf !important;">
                            <div class="ant-progress-bg"
                                 [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth + data.transmissoesErroWidth) + '%'}"
                                 style="border-radius: 100px; height: 8px;background-color: red;"></div>
                            <div class="ant-progress-success-bg ng-star-inserted"
                                 [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth) + '%'}"
                                 style="border-radius: 100px;height: 8px;background-color: #faad14;"></div>
                            <div class="ant-progress-success-bg ng-star-inserted"
                                 [ngStyle]="{'width': data.transmissoesTransmitidasWidth + '%'}"
                                 style="border-radius: 100px; height: 8px;"></div>
                        </div>
                        <div class="d-flex justify-content-between">
                                                <span class="text-grey-dark"
                                                      style="margin-right: 10px">Não Iniciado</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.transmissoesNaoIniciado}}</span>
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                                <span class="text-grey-dark"
                                                      style="margin-right: 10px;">Em Execução</span>
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <span style="margin-right: 5px;">{{data.transmissoesEmExecucao}}</span>
                                    <i class="fas fa-circle"
                                       style="color:#faad14; font-size:10px;"></i>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span class="text-grey-dark" style="margin-right: 10px;">Erro</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.transmissoesErro}}</span>
                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                                                <span class="text-grey-dark"
                                                      style="margin-right: 10px">Transmitidas</span>
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px;">{{data.transmissoesTransmitidas}}</span>
                                <i class="fas fa-circle"
                                   style="color: #52c41a; font-size:10px;"></i>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </nz-table>
            <nz-table #tableArmazenamento *ngIf="tabNumber === 1" [nzData]="armazenamento" class="scrollbar">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Empresa</th>
                    <th>Competência</th>
                    <th>Obrigação</th>
                    <th>Situação</th>
                    <th>Origem</th>
                    <th>Armazenamento</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableArmazenamento.data" style="white-space: nowrap;">
                    <td class="font-weight-bold">{{data.grupo}}</td>
                    <td>{{data.empresa}}</td>
                    <td>{{data.competencia}}</td>
                    <td>{{data.obrigacao}}</td>
                    <td>
              <span nz-typography [ngStyle]="{'color': data.situacao == 'Armazenado' ? 'green' : 'red'}">
                {{data.situacao}}
              </span>
                    </td>
                    <td>{{data.origem}}</td>
                    <td>{{data.dataArmazenamento}}</td>
                </tr>
                </tbody>
            </nz-table>
            <nz-table #tableValidacoes *ngIf="tabNumber === 2" [nzData]="validacoes" class="scrollbar">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Empresa</th>
                    <th>Competência</th>
                    <th>Obrigação</th>
                    <th>Campo</th>
                    <th>Ocorrência</th>
                    <th>Situação</th>
                    <th>Liberação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableValidacoes.data" style="white-space: nowrap;">
                    <td class="font-weight-bold">{{data.grupo}}</td>
                    <td>{{data.empresa}}</td>
                    <td>{{data.competencia}}</td>
                    <td>{{data.obrigacao}}</td>
                    <td>{{data.campo}}</td>
                    <td>{{data.ocorrencia}}</td>
                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' ? 'green' : 'red'}">
                {{data.situacao}}
              </span>
                    </td>
                    <td>{{data.liberacao}}</td>
                </tr>
                </tbody>
            </nz-table>
            <nz-table #tableConciliacoes *ngIf="tabNumber === 3" [nzData]="conciliacoes" class="scrollbar">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Empresa</th>
                    <th>Competência</th>
                    <th>Obrigação</th>
                    <th>Campo</th>
                    <th>Ocorrência</th>
                    <th>Situação</th>
                    <th>Liberação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableConciliacoes.data" style="white-space: nowrap;">
                    <td class="font-weight-bold">{{data.grupo}}</td>
                    <td>{{data.empresa}}</td>
                    <td>{{data.competencia}}</td>
                    <td>{{data.obrigacao}}</td>
                    <td>{{data.campo}}</td>
                    <td>{{data.ocorrencia}}</td>
                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' ? 'green' : (data.situacao == 'N/A' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                    </td>
                    <td>{{data.liberacao}}</td>
                </tr>
                </tbody>
            </nz-table>
            <nz-table #tableAprovacoes *ngIf="tabNumber === 4" [nzData]="aprovacoes" class="scrollbar">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Empresa</th>
                    <th>Competência</th>
                    <th>Obrigação</th>
                    <th>Situação</th>
                    <th>Aprovador 1</th>
                    <th>Aprovador 2</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableAprovacoes.data" style="white-space: nowrap;">
                    <td class="font-weight-bold">{{data.grupo}}</td>
                    <td>{{data.empresa}}</td>
                    <td>{{data.competencia}}</td>
                    <td>{{data.obrigacao}}</td>
                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Armazenado' || data.situacao == 'Sem Ocorrência' || data.situacao == 'Ocorrência Média Liberada' || data.situacao == 'Aprovado' ? 'green' : (data.situacao == 'N/A' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                    </td>
                    <td>{{data.aprovador1}}</td>
                    <td>{{data.aprovador2}}</td>
                </tr>
                </tbody>
            </nz-table>
            <nz-table #tableTransmissoes *ngIf="tabNumber === 5" [nzData]="transmissoes" class="scrollbar">
                <thead>
                <tr>
                    <th>Grupo</th>
                    <th>Empresa</th>
                    <th>Competência</th>
                    <th>Obrigação</th>
                    <th>Situação</th>
                    <th>Transmissão</th>
                    <th>Arquivos</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableTransmissoes.data" style="white-space: nowrap;">
                    <td class="font-weight-bold">{{data.grupo}}</td>
                    <td>{{data.empresa}}</td>
                    <td>{{data.competencia}}</td>
                    <td>{{data.obrigacao}}</td>
                    <td>
              <span nz-typography
                    [ngStyle]="{'color': data.situacao == 'Transmitida' ? 'green' : (data.situacao == 'Na Fila' || data.situacao == 'Em Execução' ? '' : 'red')}">
                {{data.situacao}}
              </span>
                    </td>
                    <td>{{data.transmissao}}</td>
                    <td>
                        <button nz-button nzType="primary" nzGhost>link 1</button>
                        <button nz-button nzType="primary" nzGhost>link 2</button>
                        <button nz-button nzType="primary" nzGhost>link 3</button>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</nz-card>
<ng-template #modalContent>
    <ng-container *ngFor="let item of data">
        <nz-form-item
                *ngIf="item.index == 'id' || item.index == 'codigo' || item.index == 'cnpj' || item.index == 'empresa'|| item.index == 'grupo' || item.index == 'receitaFederal' || item.index == 'regimeTributario' || item.index == 'inscricaoEstadual' || item.index == 'inscricaoEstadualSt' || item.index == 'inscricaoMunicipal'">
            <nz-form-label nzFor="no">{{item.title}}</nz-form-label>
            <nz-form-control>
                <input nz-input [(ngModel)]="description" name="{{item.index}}" placeholder=""/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="item.index == 'matriz'">
            <nz-form-label nzFor="no">Matriz</nz-form-label>
            <nz-form-control>
                <nz-select>
                    <nz-option [nzLabel]="'Matriz'" [nzValue]="'matriz'"></nz-option>
                    <nz-option [nzLabel]="'Filial'" [nzValue]="'filial'"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="item.index == 'uf'">
            <nz-form-label nzFor="no">UF</nz-form-label>
            <nz-form-control>
                <nz-select>
                    <nz-option [nzLabel]="'AC'" [nzValue]="'AC'"></nz-option>
                    <nz-option [nzLabel]="'AL'" [nzValue]="'AL'"></nz-option>
                    <nz-option [nzLabel]="'AP'" [nzValue]="'AP'"></nz-option>
                    <nz-option [nzLabel]="'AM'" [nzValue]="'AM'"></nz-option>
                    <nz-option [nzLabel]="'BA'" [nzValue]="'BA'"></nz-option>
                    <nz-option [nzLabel]="'CE'" [nzValue]="'CE'"></nz-option>
                    <nz-option [nzLabel]="'DF'" [nzValue]="'DF'"></nz-option>
                    <nz-option [nzLabel]="'ES'" [nzValue]="'ES'"></nz-option>
                    <nz-option [nzLabel]="'GO'" [nzValue]="'GO'"></nz-option>
                    <nz-option [nzLabel]="'MA'" [nzValue]="'MA'"></nz-option>
                    <nz-option [nzLabel]="'MG'" [nzValue]="'MG'"></nz-option>
                    <nz-option [nzLabel]="'MT'" [nzValue]="'MT'"></nz-option>
                    <nz-option [nzLabel]="'MS'" [nzValue]="'MS'"></nz-option>
                    <nz-option [nzLabel]="'PR'" [nzValue]="'PR'"></nz-option>
                    <nz-option [nzLabel]="'PB'" [nzValue]="'PB'"></nz-option>
                    <nz-option [nzLabel]="'PA'" [nzValue]="'PA'"></nz-option>
                    <nz-option [nzLabel]="'PE'" [nzValue]="'PE'"></nz-option>
                    <nz-option [nzLabel]="'PI'" [nzValue]="'PI'"></nz-option>
                    <nz-option [nzLabel]="'RJ'" [nzValue]="'RJ'"></nz-option>
                    <nz-option [nzLabel]="'RN'" [nzValue]="'RN'"></nz-option>
                    <nz-option [nzLabel]="'RS'" [nzValue]="'RS'"></nz-option>
                    <nz-option [nzLabel]="'RO'" [nzValue]="'RO'"></nz-option>
                    <nz-option [nzLabel]="'RR'" [nzValue]="'RR'"></nz-option>
                    <nz-option [nzLabel]="'SC'" [nzValue]="'SC'"></nz-option>
                    <nz-option [nzLabel]="'SE'" [nzValue]="'SE'"></nz-option>
                    <nz-option [nzLabel]="'SP'" [nzValue]="'SP'"></nz-option>
                    <nz-option [nzLabel]="'TO'" [nzValue]="'TO'"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</ng-template>

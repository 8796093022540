import {ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EmpresaDetalheService} from './empresa-detalhe.service';
import {Empresa} from '@models/empresa.model';
import {ToastrService} from 'ngx-toastr';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {VisibilidadeService} from '@services/visibilidade.service';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {environment} from '../../../../../environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {CertidoesService} from '../../../cadastros/certidoes/certidoes.service';
import {DomicilioEletronicoService} from '../../../cadastros/domicilioEletronico/domicilioEletronico.service';
import {isCEP, isCNPJ} from '../../../../core/validatorsForms';
import {
    EditarNotasFiscaisEletronicasService
} from '../../../configuracoes/editarNotasFiscaisEletronicas/editarNotasFiscaisEletronicas.service';
import {
    EditarInscricoesRegimesService
} from '../../../cadastros/editar-inscricoes-regimes/editar-inscricoes-regimes.service';
import {
    ModalCredencialDeAcessoComponent
} from '@components/modal-credencial-de-acesso/modal-credencial-de-acesso.component';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {ObrigacaoService} from '@services/obrigacao.service';
import {
    StepsObrigacoesEmpresasService
} from '../../../cadastros/steps-obrigacoes-empresas/steps-obrigacoes-empresas.service';
import {ObrigacoesEmpresasService} from '../../../cadastros/obrigacoes-empresas/obrigacoes-empresas.service';
import {
    EditarCredenciaisAcessoService
} from '../../../cadastros/editar-credenciais-acesso/editar-credenciais-acesso.service';
import {Helpers} from '../../../../core/helpers';
import {ContaService} from '@services/conta.service';
import {StickyHeaderDirective} from '@components/directive/directive';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-empresa-detalhe',
    templateUrl: './empresa-detalhe.component.html',
    styleUrls: ['./empresa-detalhe.component.scss']
})

export class EmpresaDetalheComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, OnDestroy {

    @Input() data;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    paginationImobiliarias: Pagination;
    paginationObrigcoes: Pagination;

    formCadastrarEmMassa: FormStack;

    itemsObrigacoes = [];

    empresa: Empresa;
    userAdmin = false;
    currentUser: Subscription;

    formDadosCadastrais: FormStack;
    formEndereco: FormStack;

    formGrupoEmpresarial: FormStack;
    grupoEmpresarialOptions: { label: string; value: string }[] = [];

    formVisibilidade: FormStack;
    visibilidadeOptions: { label: string; value: string }[] = [];

    carregando = true;
    gravando = false;

    itemsCnd: any[] = [];
    itemsDec: any[] = [];

    listaAcessosMunicipais: any[] = [];
    listaAcessosPrevidenciarios: any[] = [];

    loadings = {
        tableCnd: false,
        getCnd: false,
        updateCnd: false,
        tableDec: false,
        getDec: false,
        updateDec: false,
        gravandoII: false,
        certificados: false,
        acessos: false,
        ifs: false,
        ies: false,
        ims: false,
        insertCertificado: false,
        gravandoImobiliaria: false,
        download: false,
        formAcessos: false,
        gravandoRegime: false,
        obrigacoes: false,
        previaObrigacoes: false,
        acessosEcac: false,
        acessosMunicipais: false,
        acessosPrevidenciarios: false,
        formAcessosPrevidenciarios: false,
        formAcessosMunicipais: false,
        insertAcessoMunicipal: false,
        updateAcessosMunicipal: false,
        updateAcessosPrevidenciarios: false,
        removendoEmpresas: false
    };

    loadingRegime = false;

    comboCategoria: any = [];

    formEditarDec: FormStack;
    formEditarCnd: FormStack;
    formDadosCnd: FormStack;
    formDadosDec: FormStack;
    formImobiliaria: FormStack;
    formRegime: FormStack;
    formFiltrarObrigacoes: FormStack;
    formAcessosMunicipais: FormStack;
    formAcessosPrevidenciarios: FormStack;

    cadastrando = false;

    comboUFs = [];
    comboCnd = [];

    target: any;
    offsetTop = 15;

    formCertificadoA1: FormStack;
    formAdicionarCertificado: FormStack;
    usaCertificadoMatriz = false;
    listaCretificados: any[] = [];

    params: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 10,
        sort: [],
        filter: []
    };

    currentsParams: any = {
        cnd: this.abstractService.NzTableQueryParams,
        dec: this.abstractService.NzTableQueryParams,
        certificados: this.abstractService.NzTableQueryParams,
        acessos: this.abstractService.NzTableQueryParams,
        ifs: this.abstractService.NzTableQueryParams,
        ies: this.abstractService.NzTableQueryParams,
        ims: this.abstractService.NzTableQueryParams,
        imobiliarias: this.abstractService.NzTableQueryParams,
        regimes: this.abstractService.NzTableQueryParams,
        obrigacoes: this.params,
        acessosEcac: this.params,
        acessosPrevidenciarios: this.params,
        acessosMunicipais: this.params,
    };

    currentSearch: any = {
        domicilios: '',
        cnd: '',
        certificados: '',
        acessos: '',
        ifs: '',
        ies: '',
        ims: '',
        obrigacoes: '',
        acessosEcac: '',
        acessosMunicipais: '',
        acessosPrevidenciarios: '',
    };

    loadingImombiliarias = false;
    editandoImobiliaria = null;

    paginations: any = {
        certificados: Pagination,
        acessos: Pagination,
        ifs: Pagination,
        ies: Pagination,
        ims: Pagination,
        regimes: Pagination,
        empresas: Pagination,
        acessosEcac: Pagination,
        acessosPrevidenciarios: Pagination,
        municipais: Pagination,
    };

    editando: any = {
        acessos: '',
        acessosMunicipais: '',
        acessosPrevidenciarios: null
    };

    arquivoUpdate: NzUploadFile = {
        uid: '1',
        name: 'certificado.pfx',
        status: 'done',
        response: '', // custom error message to show
        url: ''
    };

    apiUrl = environment.apiUrl;

    erro = false;
    mensagem = '';

    dadosCadastrais: any = {};

    listaAcessos: any[] = [];
    formAcessosEstaduais: FormStack;

    valueChangesSubscription: any;

    camposFormDataPrev: any = [];
    comboObrigacoes: any = [];

    formIf: FormStack;
    ifs: any[] = [];
    loadingIf = false;
    editandoIf = null;
    editarDataInicioIf = new Date();
    editarDataFimIf = new Date();
    dadosReceita: any = {};
    loadingReceita = false;

    formIe: FormStack;
    formIm: FormStack;
    ies: any[] = [];
    ims: any[] = [];
    loadingIe = false;
    loadingIm = false;
    iImobiliarias: any[] = [];

    editandoIe = null;
    editandoIm = null;

    comboMunicipio: any = [];

    qtdFiltrosAtivosCnd = 0;
    qtdFiltrosAtivosDec = 0;
    formFiltrarCnd: FormStack;
    formFiltrarDec: FormStack;

    comboCertidoes = [];
    comboDomicilios = [];

    checkedItemsDec = new Set<string>();

    formEditarDatas: FormStack;
    formEditarDatasDec: FormStack;
    loadingAdicionarCnd = false;
    loadingAdicionarDec = false;

    statusCardCnd = false;
    statusCardDec = false;

    checkedDec = false;

    updateDomiciliosMassa = false;
    loadingSugeridasMassa = false;

    empresaId = '';

    obrigacoes = [];
    previaObrigacoes = [];

    configuracoesDFE: any = {};

    qtdFiltrosObrigacoesAtivos = 0;

    formConfiguracoesDFE: FormStack;
    loadingsConfigDFE = false;
    @ViewChild('modalCredencialDeAcesso') modalCredencialDeAcesso: ModalCredencialDeAcessoComponent;

    comboRegimes: any = [];
    regimes: any[] = [];
    editandoRegime = null;
    removendoId = null;

    modalFiltrarObrigacoesVisible = false;

    steps = [
        {
            icon: 'calendar',
            step: 'finish',
        },
        {
            icon: 'apartment',
            step: 'process',
        },
        {
            icon: 'branches',
            step: 'wait',
        },
        {
            icon: 'check',
            step: 'finish',
        },
    ];

    icons = [
        'calendar',
        'apartment',
        'branches',
        'schedule',
        'check',
    ];

    nextButtonText = 'Avançar';
    stepsLoading = false;

    stepNumber = 1;
    radioValueStepOne = 'todas';

    checkedItemsObrigacoes = new Set<string>();

    modelCalendar = false;
    modelCheck = false;
    modelCompliance = false;

    formStepsObrigacoes: FormStack;

    arrayVencimentoOficialCompetencias: any = [];

    vencimentos = {};
    vencimentosHeader = {};

    arrayAnos: any[] = [];
    selectedValue = '';

    listaAcessosEcac: any[] = [];

    statusToUpdate: number;

    formAcessoEcac: FormStack;

    responsaveis = [];
    optionsEmpresas = [];

    camposFormDataPrevMunicipais: any = [];

    comboMunicipios: any = [];
    comboPortaisPrevidenciarios: any = [];

    termosVisible = false;
    termosAceitos = false;
    checkDisabled = false;
    termosCompletosVisible = false;

    loadingAcessoEcac = false;

    acessoEcacSelecionado: any = {};
    selecionarProcurador = false;
    dataPrevSelecionado: any = null;
    municipalSelecionado: any = null;

    camposFormDataPrevPrevidencirios: any = [];

    arrayModulos = [];

    modoAssistente = false;
    stepAssistAtual = 0;

    stepsAssist = [];

    checkedObrigacoes = false;

    removerTodosAnos = false;

    constructor(
        private dataService: DataService,
        private fb: UntypedFormBuilder,
        private visibilidadeService: VisibilidadeService,
        private grupoEmpresarialService: GrupoEmpresarialService,
        private empresaDetalheService: EmpresaDetalheService,
        private toastService: ToastrService,
        private i18n: NzI18nService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef,
        private CadastroCertidoesService: CertidoesService,
        private domicilioEletronicoService: DomicilioEletronicoService,
        private editarNotasFiscaisEletronicasService: EditarNotasFiscaisEletronicasService,
        private editarInscricoesRegimesService: EditarInscricoesRegimesService,
        private obrigacoesEmpresaService: ObrigacoesEmpresasService,
        private tabService: TabService,
        private obrigacaoService: ObrigacaoService,
        private stepsObrigacoesEmpresaService: StepsObrigacoesEmpresasService,
        private editarCredenciaisService: EditarCredenciaisAcessoService,
        private contaService: ContaService
    ) {

        super(empresaDetalheService, {}, toastService);

        this.i18n.setLocale(pt_BR);

        this.contaService.modulosAtivos().subscribe((response) => {
                this.arrayModulos = response;
            },
            (response) => {
                this.toastrService.error(response.error.message);

            }
        );

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                nome: [null, Validators.required],
                ativo: [null, Validators.required],
                codigo: [null],
                cnpj: [null, [isCNPJ, Validators.required]],
                razao: [null],
                atividade: [null, Validators.required],
            })
        };

        this.formCadastrarEmMassa = {
            modalVisible: false,
            formGroup: this.fb.group({
                competencia: [null, null],
                empresa_id: [null, null],
                obrigacao_id: [null, null],
            })
        };

        this.formEndereco = {
            modalVisible: false,
            formGroup: this.fb.group({
                endereco: [null],
                numero: [null],
                complemento: [null],
                bairro: [null],
                cidade: [null],
                estado: [null],
                cep: [null, [isCEP]]
            })
        };

        this.formGrupoEmpresarial = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupoEmpresarial_id: [null]
            })
        };

        this.formVisibilidade = {
            modalVisible: false,
            formGroup: this.fb.group({
                visibilidade_id: [null]
            })
        };

        this.formEditarCnd = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null],
                certidao_id: [null],
                controleCertidaoEmpresa_id: [null],
                descricao: [null],
                dataInicio: [null, Validators.required],
                dataFim: [null]
            })
        };

        this.formEditarDec = {
            modalVisible: false,
            formGroup: this.fb.group({
                domicilioEletronicoEmpresa_id: [null],
                empresa_id: [null],
                descricao: [null],
                dataInicio: [null, Validators.required],
                dataFim: [null]
            })
        };

        this.formDadosCnd = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, null],
                municipio: [null, null],
                certidao_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
            })
        };

        this.formDadosDec = {
            modalVisible: false,
            formGroup: this.fb.group({
                domicilioEletronico_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
            })
        };

        this.empresaDetalheService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

        this.formCertificadoA1 = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],

            })
        };

        this.formAcessosEstaduais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, null],
                portal: [null, null],
            })
        };

        this.formIf = {
            modalVisible: false,
            formGroup: this.fb.group({
                documento: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null]
            })
        };

        this.formIe = {
            modalVisible: false,
            formGroup: this.fb.group({
                isento: [null, null],
                ie: [null, null],
                uf: [null, null],
                tipo: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                situacaoCadastral: [null, null],
            })
        };

        this.formIm = {
            modalVisible: false,
            formGroup: this.fb.group({
                im: [null, null],
                municipioCodigo: [null, null],
                uf: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                situacaoCadastral: [null, null],
            })
        };

        this.formFiltrarCnd = {
            modalVisible: false,
            formGroup: this.fb.group({
                certidao: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formFiltrarDec = {
            modalVisible: false,
            formGroup: this.fb.group({
                domicilioEletronico: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formEditarDatas = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formEditarDatasDec = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.currentsParams.certificados = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formConfiguracoesDFE = {
            modalVisible: false,
            formGroup: this.fb.group({
                ativo: [null, [Validators.required]],
                horarioInicio: [null],
                horarioFim: [null],
            })
        };

        this.formImobiliaria = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null],
                ii: [null, [Validators.required]],
                uf: [null, [Validators.required]],
                municipioCodigo: [null, [Validators.required]],
                situacaoCadastral: [null, [Validators.required]],
                dataInicio: [null, [Validators.required]],
                setor: [null, null]
            })
        };

        this.formRegime = {
            modalVisible: false,
            formGroup: this.fb.group({
                regimeTributario_id: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null]
            })
        };

        this.formFiltrarObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                inativo: [null, null]
            })
        };

        this.formAcessoEcac = {
            modalVisible: false,
            formGroup: this.fb.group({
                ids: [[], Validators.required],
                procurador: ['', Validators.required],
                statusAcessoEcac: [1, Validators.required],
            })
        };

        this.formAcessosMunicipais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, Validators.required],
                portal: [null, Validators.required],
            })
        };

        this.formAcessosPrevidenciarios = {
            modalVisible: false,
            formGroup: this.fb.group({
                portal: [null, Validators.required],
            })
        };

        this.obrigacaoService.retornaCategorias().subscribe((retorno: any) => {

            retorno.data.forEach((value) => {
                value.label = value.descricao;
                value.key = value.categoria_id;
            });
            this.comboCategoria = retorno.data;

        });

        this.formStepsObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.paginations.certificados = new Pagination(50, 1, 1, 0, 0);

        this.editarCredenciaisService.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.editarCredenciaisService.retornaPortaisPrevidenciarios().subscribe((retorno: any) => {
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboPortaisPrevidenciarios = retorno;
        });

    }

    ngOnInit(): void {

        this.stepsAssist = [
            {desc: 'Pronto! Seu cadastro foi finalizado!'},
            {form: this.formRegime, desc: ''},
            {form: this.formIe, desc: 'Deseja cadastrar a Inscrição Estadual?'},
            {form: this.formIm, desc: 'Deseja cadastrar a Inscrição Municipal?'},
            {form: this.formImobiliaria, desc: 'Deseja cadastrar a Inscrição Imobiliária?'},
            {
                form: null,
                modulos: ['cnd'],
                desc: 'Deseja cadastrar as Certidões Sugeridas?'
            },
            {
                form: null,
                modulos: ['dec'],
                desc: 'Deseja cadastrar os Domicílios Eletrônicos Sugeridos?'
            },
            {
                form: this.formCadastrarEmMassa,
                modulos: ['compliance', 'calendar', 'check'],
                desc: 'Deseja cadastrar as Obrigações para essa empresa?'
            },
            {
                form: null,
                desc: 'Deseja adicionar o Certificado Digital A1?',
                btOk: 'Sim, vou adicionar'
            },
            {form: null, desc: 'Deseja cadastrar o Acesso E-CAC?'},
            {form: this.formAcessosEstaduais, desc: 'Deseja cadastrar o Acesso Estadual?'},
            {form: this.formAcessosMunicipais, desc: 'Deseja cadastrar o Acesso Municipal?'},
            {
                form: this.formAcessosPrevidenciarios, desc: 'Deseja cadastrar o Acesso Previdenciário?',
                btCancel: 'Não, vou encerrar as configurações'
            }
        ];

        this.currentUser = this.dataService.currentUser.subscribe((data) => {

            if (data) {
                this.userAdmin = data.admin === 1;
            }

        });

        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.empresaId = this.data.id;

        this.carregar(this.data.id);
        this.getGruposEmpresariais();
        this.getVisibilidades();
        this.carregaComboCertidoes();
        this.carregaComboDomicilios();
        this.retornaCongiguracoesNFE(this.data.id);
        this.listarAcessosPrevidenciarios(this.currentsParams.acessosPrevidenciarios);
        this.listarAcessosMunicipais(this.currentsParams.acessosMunicipais);

        this.editarInscricoesRegimesService.retornaCodigosReceitas().subscribe((retorno: any) => {
            this.comboRegimes = retorno.regimesTributarios;
        });

        this.restornaResponsaveis();

        this.listarAcessosEcac(this.currentsParams.acessosEcac);

    }

    async ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;

        if (this.data.novaEmpresa) {

            await this.pause(1000);

            this.modalService.confirm({
                nzWidth: 520,
                nzTitle: 'Deseja finalizar a configuração dessa empresa? ',
                nzOkText: 'Sim, vou configurar agora',
                nzCancelText: 'Não, farei mais tarde',
                nzOnOk: () => {
                    this.modoAssistente = true;
                    this.stepAssistAtual = 1;
                    this.formRegime.modalVisible = true;
                },
                nzOnCancel: () => {
                    this.modoAssistente = false;
                    this.data.novaEmpresa = false;
                }
            });
        }
    }

    async pause(ms: number) {
        while (this.carregando) {
            await new Promise(r => setTimeout(r, ms));
        }
    }

    nextStepAssist(menssagem: string) {
        this.stepAssistAtual += 1;

        if (!this.verificaModulo(this.stepsAssist[this.stepAssistAtual].modulos)) {
            this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
        } else {

            this.modalService.confirm({
                nzWidth: 520,
                nzTitle: menssagem,
                nzOkText: this.stepsAssist[this.stepAssistAtual].btOk || 'Sim, vou cadastrar',
                nzCancelText: this.stepsAssist[this.stepAssistAtual].btCancel || 'Não, pular para o próximo',
                nzOnOk: () => {
                    this.openModalStep(this.stepAssistAtual, this.stepsAssist[this.stepAssistAtual].form);
                },
                nzOnCancel: () => {
                    if (this.stepAssistAtual === this.stepsAssist.length - 1) {
                        this.stopAssist();
                    } else {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }
                }
            });

        }
    }

    openModalStep(step: number, form?: FormStack) {

        switch (step) {
            case 5: {
                this.adicionarCertidoesSugeridas();
                break;
            }
            case 6: {
                this.adicionarDomiciliosSugeridos();
                break;
            }
            case 8: {
                this.cadastrar(this.formAdicionarCertificado, false);
                break;
            }
            case 9: {
                this.modalAcessoEcac(true, true);
                break;
            }
            default: {
                form.modalVisible = true;
            }
        }
    }

    stopAssist() {

        if (this.stepAssistAtual === this.stepsAssist.length - 1) {
            const modal = this.modalService.success({
                nzTitle: this.stepsAssist[0].desc
            });

            setTimeout(() => modal.destroy(), 2500);

            this.modoAssistente = false;
            this.data.novaEmpresa = false;
            this.stepAssistAtual = 0;

        } else if (this.modoAssistente) {

            this.modalService.confirm({
                nzWidth: 460,
                nzTitle: 'Deseja continuar com a configuração?',
                nzOkText: 'Sim',
                nzCancelText: 'Não',
                nzOnOk: () => {
                    this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                },
                nzOnCancel: () => {
                    this.modoAssistente = false;
                    this.data.novaEmpresa = false;
                    this.stepAssistAtual = 0;
                }
            });
        }
    }

    verificaModulo(modulos: string[]) {

        if (!modulos) {
            return true;
        }

        const modulosSet = new Set(modulos);
        return this.arrayModulos.some(elemento => modulosSet.has(elemento));
    }

    restornaResponsaveis() {

        this.editarCredenciaisService.retornarResponsaveis().subscribe((response: any) => {
                this.responsaveis = response.data;
            },
            (response) => {
                this.toastrService.error(response.error.message);
            });
    }

    carregaComboCertidoes() {
        this.empresaDetalheService.retornaComboCertidoes().subscribe((retorno: any) => {
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboCertidoes = retorno;
        });

    }

    carregaComboDomicilios() {
        this.empresaDetalheService.retornaComboDomicilios().subscribe((retorno: any) => {
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboDomicilios = retorno;
        });

    }

    retornaSelectCnds(uf, municipio: string = '') {
        this.empresaDetalheService.retornaComboCnd(uf, municipio).subscribe((retorno: any) => {
            this.comboCnd = [];
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboCnd = retorno;
        });
    }

    async carregar(id, quiet = false) {
        if (!quiet) {
            this.carregando = true;
        }

        this.empresa = new Empresa(await this.empresaDetalheService.get(id).toPromise() as Empresa);

        this.dadosCadastrais = this.empresa;

        this.getComboAnos();

        if (!quiet) {
            this.carregando = false;
        }

    }

    getComboAnos() {
        this.obrigacaoService.retornaComboAnos(this.empresa.cnpj).subscribe((retorno: any) => {

            this.arrayAnos = retorno;
            this.selectedValue = new Date().getFullYear().toString();

            this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);

        });
    }

    editar(formulario: FormStack): void {

        for (const name in formulario.formGroup.controls) {

            if (name) {

                formulario.formGroup.get(name).setValue(this.modoDemo(this.empresa[name]) ?
                    this.modoDemo(this.empresa[name]) : this.empresa[name]);

            }
        }

        formulario.modalVisible = true;

    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;

        this.editandoImobiliaria = false;

        if (this.modoAssistente && formulario !== this.formEndereco) {
            this.stopAssist();
        }

    }

    confirmar(formulario: FormStack) {

        this.gravando = true;

        const dadosEmpresa = {};

        for (const key in formulario.formGroup.controls) {

            if (key) {

                const campo = formulario.formGroup.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosEmpresa[key] = campo.value;

            }

        }

        if (formulario.formGroup.valid) {

            this.gravar(this.data.id, formulario.formGroup.value, formulario);

        } else {

            this.gravando = false;

        }

    }

    gravar(id, dados, formulario = null) {

        this.empresaDetalheService.save(id, dados).subscribe(
            () => {

                this.carregar(this.data.id, true);

                if (formulario) {

                    this.fechar(formulario);

                }

                this.toastService.success('Dados atualizados com sucesso!');
                this.gravando = false;
            },
            (response) => {

                this.toastService.error(response.error.message);
                this.gravando = false;

            }
        );

    }

    async getGruposEmpresariais() {

        return this.grupoEmpresarialService.listToSelect().subscribe((result) => {
            this.grupoEmpresarialOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    queryTableCnd(params: NzTableQueryParams, search: string = null): void {
        this.currentsParams.cnd = params;

        if (this.arrayModulos.includes('cnd')) {

            this.loadings.tableCnd = true;

            const url = environment.apiUrl + '/certidoes/controleCertidoesEmpresa/empresa/' + this.data.id;

            this.calculaBadgeFiltrosCnd();

            this.abstractService.listToTable(params, search, url).subscribe((response) => {
                this.itemsCnd = [];
                this.itemsCnd = response?.data || response;

                this.paginations.cnd = new Pagination(
                    response?.per_page || 20,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loadings.tableCnd = false;
            });
        }

    }

    queryTableDec(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.dec = params;

        if (this.arrayModulos.includes('dec')) {

            this.loadings.tableDec = true;

            const url = environment.apiUrl + '/domicilio/domicilioEletronicoEmpresa/empresa/' + this.data.id;

            this.calculaBadgeFiltrosDec();

            this.abstractService.listToTable(params, search, url).subscribe((response) => {

                // this.itemsCnd = [];
                this.itemsDec = response?.data || response;
                this.paginations.dec = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loadings.tableDec = false;
            });

        }

    }

    modalCadastrarCnd(visible) {
        this.formDadosCnd.modalVisible = visible;
        this.cadastrando = false;

        if (visible) {
            this.retornaSelectCnds(this.formDadosCnd.formGroup.value.uf, this.formDadosCnd.formGroup.value.municipio);
        }
    }

    modalCadastrarDec(visible) {
        this.formDadosDec.modalVisible = visible;
        this.cadastrando = false;
    }

    confirmaCadastroCnd() {

        if (this.formDadosCnd.formGroup.valid) {

            this.cadastrando = true;

            this.empresaDetalheService.cadastrarCnd(this.formDadosCnd.formGroup.value, this.data.id).subscribe(() => {

                    this.reloadCardCnd();

                    this.toastService.success('CND cadastrada com sucesso!');

                    this.cadastrando = false;
                    this.modalCadastrarCnd(false);

                    this.formDadosCnd.modalVisible = false;
                    this.formDadosCnd.formGroup.reset();

                },
                (response) => {

                    this.toastService.error(response.error.message);
                    this.cadastrando = false;

                }
            );

        } else {

            this.toastService.error('Por favor, preencha o formulário.');

        }

    }

    confirmaCadastroDec() {

        this.cadastrando = true;

        if (this.formDadosDec.formGroup.valid) {

            this.empresaDetalheService.cadastrarDec(this.formDadosDec.formGroup.value, this.data.id).subscribe(() => {

                    this.toastService.success('DEC cadastrada com sucesso!');

                    this.cadastrando = false;

                    this.reloadCardDec();
                    this.formDadosDec.modalVisible = false;
                    this.formDadosDec.formGroup.reset();

                },
                (response) => {

                    this.toastService.error(response.error.message);
                    this.cadastrando = false;

                }
            );

        } else {

            this.toastService.error('Por favor, preencha o formulário.');
            this.cadastrando = false;

        }

    }

    changeSelectCndsUF(event) {
        if (event && event !== 'null') {
            this.retornaSelectCnds(event);
            this.filtraComboMunicipio(event);
        } else {
            this.formDadosCnd.formGroup.get('municipio').setValue(null);
        }

    }

    changeSelectCndsMunicipio(event) {
        this.retornaSelectCnds(this.formDadosCnd.formGroup.value.uf, event);
    }

    showConfirmRemover(id: string, tipo: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => tipo === 'cnd' ? this.removerCnd([id]) : this.removerDec([id])
        });

    }

    removerCnd(ids: string[]) {

        this.empresaDetalheService.removerCnd(ids).subscribe(() => {

                this.toastService.success('CND removida com sucesso!');

                this.reloadCardCnd();
                this.onAllChecked(false);
                this.checked = false;

            }, (response) => {

                this.toastService.error(response.error.message);

            }
        );

    }

    removerDec(ids: string[]) {

        this.empresaDetalheService.removerDec(ids).subscribe(() => {

                this.toastService.success('DEC removida com sucesso!');

                this.reloadCardDec();
                this.onAllCheckedDec(false);
                this.checkedDec = false;

            }, (response) => {

                this.toastService.error(response.error.message);

            }
        );

    }

    listarCertificados(params: NzTableQueryParams, search: string = null): void {

        this.loadings.certificados = true;

        this.currentSearch.certificados = search;

        if (params?.filter) {
            this.currentsParams.certificados = params;
        }

        this.currentsParams.certificados.filter.push({key: 'empresa_id', value: this.data.id});
        this.currentsParams.certificados.pageSize = 10;

        if (this.modoAssistente && this.stepAssistAtual === 9) {
            this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
        }

        console.log(this.modoAssistente)

        // tslint:disable-next-line:max-line-length
        this.abstractService.listToTable(this.currentsParams.certificados, this.currentSearch.certificados, this.empresaDetalheService.urlCertificados)
            .subscribe((response) => {

                this.listaCretificados = [];
                this.listaCretificados = response?.data || response;

                if (!this.listaCretificados.length && response.extra.usaCertificadoMatriz) {
                    this.usaCertificadoMatriz = response.extra.usaCertificadoMatriz;
                }

                this.listaCretificados.forEach(value => {
                    switch (value.tipo) {
                        case 'cnpj': {
                            value.tipo_descricao = 'eCNPJ';
                            break;
                        }
                        case 'nfe': {
                            value.tipo_descricao = 'eNFE';
                            break;
                        }
                        case 'cpf': {
                            value.tipo_descricao = 'eCPF';
                            break;
                        }
                    }
                });

                this.paginations.certificados = new Pagination(
                    response?.per_page || 10,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 10);

                // this.refreshCheckedStatus();

                this.loadings.certificados = false;
            });
    }

    alterarCertificadoA1(item: any): void {

        item.arquivoUpdate = this.arquivoUpdate;

        this.modalCredencialDeAcesso.editandoCert = true;
        this.modalCredencialDeAcesso.item = item;
        this.modalCredencialDeAcesso.showModal(true);

    }

    modal(form: FormStack, visible: boolean, dados = null, fonte = ''): void {

        if (form === this.formAdicionarCertificado) {
            this.modalCredencialDeAcesso.showModal(visible);
        } else {
            form.modalVisible = visible;
        }

        if (fonte === 'municipal') {
            this.municipalSelecionado = dados;
        } else if (fonte === 'dataPrev') {
            this.dataPrevSelecionado = dados;
        }

        if (!visible) {
            this.zerarForms();
            if (this.modoAssistente) {
                this.stopAssist();
            }
        }
    }

    zerarForms(): void {

        this.editando.certificados = null;
        this.formIf.formGroup.reset();
        this.formIe.formGroup.reset();
        this.formIm.formGroup.reset();
        this.formImobiliaria.formGroup.reset();
        this.formRegime.formGroup.reset();
        this.formAcessosEstaduais.formGroup.reset();
        this.formAcessosMunicipais.formGroup.reset();
        this.formAcessosPrevidenciarios.formGroup.reset();

    }

    showConfirm(tipo: string, item: any): void {

        let titulo = '';

        switch (tipo) {
            case 'certificados': {
                titulo = item.existeCertificadoResponsavel ? 'Esse certificado também é utilizado por um responsável, ao excluir do cadastro da Empresa, também será excluído do cadastro de Responsável. Deseja continuar?' :
                    'Deseja remover o Certificado A1?';
                break;
            }
            case 'matriz': {
                titulo = 'Deseja remover a Matriz?';
                break;
            }
            case 'if': {
                titulo = 'Deseja remover a Inscrição Federal?';
                break;
            }
            case 'acessos': {
                titulo = 'Deseja remover o Acesso Estadual?';
                break;
            }
            case 'ie': {
                titulo = 'Deseja remover a Inscrição Estadual?';
                break;
            }
            case 'im': {
                titulo = 'Deseja remover a Inscrição Municipal?';
                break;
            }
            case 'imobiliaria': {
                titulo = 'Deseja remover a Inscrição Imobiliária?';
                break;
            }
            case 'regime': {
                titulo = 'Deseja remover o Regime Tributário?';
                break;
            }
            case 'obrigacao': {
                titulo = 'Deseja remover a obrigação?';
                break;
            }
            case 'acessosMunicipais': {
                titulo = 'Deseja remover o Acesso Municipal?';
                break;
            }
            case 'acessosPrevidenciarios': {
                titulo = 'Deseja remover o Acesso Previdenciário?';
                break;
            }
        }


        this.modalService.confirm({
            nzTitle: titulo,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(tipo, item)
        });

    }

    remover(tipo: string, item: any): void {

        switch (tipo) {
            case 'certificados': {
                this.empresaDetalheService.removeCertificados(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Certificado A1 removido com sucesso!');
                        this.listarCertificados(this.currentsParams.certificados, this.currentSearch.certificados);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessos': {
                this.empresaDetalheService.removeAcessos(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Estadual removido com sucesso!');
                        this.listarAcessos(this.currentsParams.acessos, this.currentSearch.acessos);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'if': {
                this.empresaDetalheService.removeIf(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Federal removida com sucesso!');
                        this.listarIfs(this.currentsParams.ifs);
                    },
                    (response) => {

                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'ie': {
                this.empresaDetalheService.removeIe(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Estadual removida com sucesso!');
                        this.listarIes(this.currentsParams.ies);
                    },
                    (response) => {

                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'im': {
                this.empresaDetalheService.removeIm(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Municipal removida com sucesso!');
                        this.listarIms(this.currentsParams.ims);
                    },
                    (response) => {

                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'imobiliaria': {
                this.loadingImombiliarias = true;
                this.editarInscricoesRegimesService.removeImobiliaria(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Imobiliária removida com sucesso!');
                        this.listarImobiliarias(this.currentsParams.imobiliarias);
                    },
                    (response) => {

                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'regime': {
                this.removendoId = item.id;
                this.editarInscricoesRegimesService.removeRegime(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Regime Tributário removido com sucesso!');
                        this.listarRegimes(this.currentsParams.regimes);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'obrigacao': {
                this.loadings.obrigacoes = true;

                const ids: string[] = [item.id];

                this.obrigacoesEmpresaService.removeEmpresas(ids, true).subscribe((response: any) => {

                    this.toastrService.success('Obrigação removida com sucesso!');

                    this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);

                }, (response) => {

                    this.toastrService.error(response.error.message);
                    this.removendoId = null;

                });
                break;
            }
            case 'acessosMunicipais': {
                this.editarCredenciaisService.removeAcessoMunicipal(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Municipal removido com sucesso!');
                        this.listarAcessosMunicipais(this.currentsParams.acessosMunicipais, this.currentSearch.acessosM);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessosPrevidenciarios': {
                this.editarCredenciaisService.removeAcessoMunicipal(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Previdenciário removido com sucesso!');
                        this.listarAcessosPrevidenciarios(this.currentsParams.acessosP, this.currentSearch.acessosP);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
        }
    }

    cadastrar(form: FormStack, verificar: boolean = false): void {

        this.editandoRegime = null;

        if (verificar) {
            const semDataFim = this.ims.filter((element) => {
                return !element.dataFim;
            });

            if (semDataFim.length > 0) {
                this.toastService.warning('Insira Data Fim nas inscrições cadastradas.');
                return;
            }
        }

        if (form === this.formAdicionarCertificado) {
            this.modalCredencialDeAcesso.showModal(true);
        } else {
            form.modalVisible = true;
        }

        this.zerarForms();

    }

    listarAcessos(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.acessos = params;
        this.currentSearch.acessos = params;

        if (params.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
            params.pageSize = 10;
        }

        this.loadings.acessos = true;

        this.abstractService.listToTable(params, null, this.empresaDetalheService.urlAcessos).subscribe((response) => {

            this.listaAcessos = [];
            this.listaAcessos = response?.data || response;

            this.paginations.acessos = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadings.acessos = false;

            this.cdr.detectChanges();

        });

    }

    modalAcesso(visible: boolean): void {

        if (!this.editando.acessos) {
            this.formAcessosEstaduais.formGroup.get('uf').enable();
            this.formAcessosEstaduais.formGroup.get('portal').enable();
        }

        this.formAcessosEstaduais.modalVisible = visible;

        if (!visible) {
            this.formAcessosEstaduais = {
                modalVisible: false,
                formGroup: this.fb.group({
                    uf: [null, Validators.required],
                    portal: [null, Validators.required],
                })
            };
            this.cdr.detectChanges();
            this.formAcessosEstaduais.formGroup.reset();
            this.camposFormDataPrev = [];

            if (this.modoAssistente) {
                this.stopAssist();
            }
            this.editando.acessos = null;
        }

    }

    updateAcessos(): void {
        this.validaFormAcesso();

        const dados = this.formAcessosEstaduais.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro) {

            this.loadings.acessos = true;

            this.empresaDetalheService.updateAcessos(this.editando.acessos, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentsParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual alterado com sucesso!');
                    this.modalAcesso(false);
                    this.loadings.acessos = false;
                    this.editando.acessos = null;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    this.loadings.acessos = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    validaFormAcesso() {
        if (this.formAcessosEstaduais.formGroup.get('modo').value === 'Procurador' &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP' &&
            !Helpers.isValidCnpj(this.formAcessosEstaduais.formGroup.get('cnpj').value)) {

            this.erro = true;
            this.mensagem = 'CNPJ inválido!';
        } else {
            this.erro = false;
            this.mensagem = null;
        }
    }

    insertAcessos(): void {

        this.validaFormAcesso();

        const dados = this.formAcessosEstaduais.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro && this.formAcessosEstaduais.formGroup.valid) {

            this.loadings.acessos = true;

            this.empresaDetalheService.insertAcessos(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentsParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual cadastrado com sucesso!');
                    this.formAcessosEstaduais.modalVisible = false;
                    this.loadings.acessos = false;

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }

                },
                (response) => {
                    this.loadings.acessos = false;
                    this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem || 'Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosEstaduais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    async filtrarPortais(uf: string) {

        this.formAcessosEstaduais.formGroup.get('portal').setValue(null);

        this.formAcessosEstaduais.formGroup.removeControl('senha');
        this.camposFormDataPrev = [];

        if (uf) {
            this.empresaDetalheService.retornaPortaisUF(uf).subscribe((retorno: any) => {
                retorno.forEach((value) => {
                    value.label = value.descricao;
                    value.key = value.id;
                });
                this.comboObrigacoes = retorno;
            });
        }
    }

    async filtrarCampos(portal: string) {

        this.formAcessosEstaduais.formGroup.removeControl('cnpj');
        this.camposFormDataPrev = [];

        if (portal) {
            this.empresaDetalheService.retornaCamposAcessosEstaduais(portal).subscribe((retorno: any) => {
                this.camposFormDataPrev = retorno;
                retorno.forEach((value, index) => {
                    // tslint:disable-next-line:max-line-length
                    this.formAcessosEstaduais.formGroup.addControl(value.label, new UntypedFormControl(value.valores[0] && value.valores[0], Validators.required));
                });

                if (this.formAcessosEstaduais.formGroup.get('modo')) {
                    this.valueChangesSubscription = this.formAcessosEstaduais.formGroup.get('modo').valueChanges.subscribe(novoValor => {
                        this.removeCampoFormAcesssos();
                    });
                }
            });
        }
    }

    removeCampoFormAcesssos() {
        if (this.formAcessosEstaduais.formGroup.get('modo').value !== 'Procurador' &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP' &&
            this.formAcessosEstaduais.formGroup.get('cnpj')
        ) {
            this.formAcessosEstaduais.formGroup.removeControl('cnpj');
            this.camposFormDataPrev.pop();

        } else if (!this.formAcessosEstaduais.formGroup.get('cnpj') &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP') {
            this.formAcessosEstaduais.formGroup.addControl('cnpj', new UntypedFormControl(
                '', Validators.required));
            this.camposFormDataPrev.push({label: 'cnpj', valores: ''});
        }
    }

    async alterarAcessosEstaduais(item: any) {

        this.loadings.formAcessos = true;
        this.filtrarCampos(item.portal);
        this.modalAcesso(true);

        this.filtrarPortais(item.uf).then(() => {
            this.editando.acessos = item.id;
            this.formAcessosEstaduais.formGroup.get('uf').setValue(item.uf);
            this.formAcessosEstaduais.formGroup.get('portal').setValue(item.portal);
            this.formAcessosEstaduais.formGroup.get('uf').disable();
            this.formAcessosEstaduais.formGroup.get('portal').disable();

            setTimeout(() => {
                const dados = JSON.parse(item.acesso);
                for (const [key, value] of Object.entries(dados)) {
                    this.formAcessosEstaduais.formGroup.get(key)?.setValue(value?.toString().trim());
                }
                this.loadings.formAcessos = false;
            }, 2000);
        });

    }

    async listarIfs(event: any) {

        this.loadingIf = true;

        this.currentsParams.ifs = event;

        if (event?.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch.ifs,

            this.empresaDetalheService.baseUrlIfs).subscribe((response) => {

            this.ifs = [];

            this.ifs = response?.data || response;

            this.paginations.ifs = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingIf = false;

        });

    }

    alterarIf(item: any): void {

        this.zerarForms();

        this.editandoIf = item.id;
        this.formIf.formGroup.get('documento').setValue(item.documento);

        if (item.dataInicio) {
            const editarDataInicioIf = new Date(item.dataInicio);
            editarDataInicioIf.setDate(this.editarDataInicioIf.getDate() + 1);
            this.formIf.formGroup.get('dataInicio').setValue(editarDataInicioIf);
        }

        if (item.dataFim) {
            const editarDataFimIf = new Date(item.dataFim);
            editarDataFimIf.setDate(this.editarDataFimIf.getDate() + 1);
            this.formIf.formGroup.get('dataFim').setValue(editarDataFimIf);
        }

        this.formIf.modalVisible = true;

    }

    alterarReceita(cnpj: string): void {
        this.loadingReceita = true;
        this.empresaDetalheService.atualziarRFB(cnpj).subscribe((response) => {
                this.toastrService.success('Dados atualizados');
                this.loadingReceita = false;
                this.retornaDadosReceitaFederal(this.data.id);
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.loadingReceita = false;
                this.retornaDadosReceitaFederal(this.data.id);
            });
    }

    retornaDadosReceitaFederal(empresaId: string): void {
        this.empresaDetalheService.retornaReceitaFederal(empresaId).subscribe((response: any) => {
            this.dadosReceita = response;
        });
    }

    insertIf(): void {

        this.validaFormularioIf();

        const dados = this.formIf.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {

            this.empresaDetalheService.insertIf(empresaId, dados).subscribe((response: any) => {
                    this.listarIfs(this.currentsParams.ifs);
                    this.toastrService.success('Inscrição Federal cadastrada com sucesso!');
                    this.formIf.modalVisible = false;
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                });

            this.erro = false;
            this.mensagem = '';

        } else {

            this.toastrService.error(this.mensagem);
            this.erro = false;

        }
    }

    validaFormularioIf(): void {
        if (this.formIf.formGroup.value.documento.length < 14) {
            this.erro = true;
            this.mensagem = 'Documento inválido';
        }

        if (!this.formIf.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Data de início obrigatório';
        }
    }

    updateIf(): void {

        this.validaFormularioIf();

        const dados = this.formIf.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoIf;

        if (!this.erro) {
            this.empresaDetalheService.updateIf(id, dados).subscribe((response: any) => {

                    this.formIf.modalVisible = false;

                    this.listarIfs(this.currentsParams.ifs);

                    this.toastrService.success('Inscricao Federal atualizada com sucesso!');
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    listarIes(event: any) {

        this.loadingIe = true;

        this.currentsParams.ies = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, false,

            this.empresaDetalheService.baseUrlIes).subscribe((response) => {

            this.ies = [];

            this.ies = response?.data || response;

            this.paginations.ies = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingIe = false;

        });

    }

    listarIms(event: any) {

        this.loadingIm = true;

        this.currentsParams.ims = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch.ims,
            this.empresaDetalheService.baseUrlIms).subscribe((response) => {

            this.ims = [];

            this.ims = response?.data || response;

            this.paginations.ims = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingIm = false;

        });

    }

    alterarIe(item: any): void {

        this.zerarForms();

        this.empresaDetalheService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.editandoIe = item.id;

        if (!item.ie) {
            this.formIe.formGroup.get('isento').setValue(true);
        } else {
            this.formIe.formGroup.get('isento').setValue(false);
        }

        this.formIe.formGroup.get('ie').setValue(item.ie);
        this.formIe.formGroup.get('tipo').setValue(item.tipo);
        this.formIe.formGroup.get('uf').setValue(item.uf);
        this.formIe.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);

        if (item.dataInicio) {
            const editarDataInicioIe = new Date(item.dataInicio);
            editarDataInicioIe.setDate(editarDataInicioIe.getDate() + 1);
            this.formIe.formGroup.get('dataInicio').setValue(editarDataInicioIe);
        }
        if (item.dataFim) {
            const editarDataFimIe = new Date(item.dataFim);
            editarDataFimIe.setDate(editarDataFimIe.getDate() + 1);
            this.formIe.formGroup.get('dataFim').setValue(editarDataFimIe);
        }

        this.formIe.modalVisible = true;

    }

    alterarIm(item: any): void {

        this.zerarForms();

        this.empresaDetalheService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.filtraComboMunicipio(item.estado_uf);

        this.editandoIm = item.id;
        this.formIm.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);
        this.formIm.formGroup.get('municipioCodigo').setValue(item.municipioCodigo);
        this.formIm.formGroup.get('uf').setValue(item.estado_uf);
        this.formIm.formGroup.get('im').setValue(item.im);

        if (item.dataInicio) {
            const editarDataInicioIm = new Date(item.dataInicio);
            editarDataInicioIm.setDate(editarDataInicioIm.getDate() + 1);
            this.formIm.formGroup.get('dataInicio').setValue(editarDataInicioIm);
        }

        if (item.dataFim) {
            const editarDataFimIm = new Date(item.dataFim);
            editarDataFimIm.setDate(editarDataFimIm.getDate() + 1);
            this.formIm.formGroup.get('dataFim').setValue(editarDataFimIm);
        }

        this.formIm.modalVisible = true;

    }

    filtraComboMunicipio(uf: string): void {
        this.formDadosCnd.formGroup.get('municipio').setValue(null);
        this.empresaDetalheService.retornaComboMunicipios(uf).subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboMunicipio = [];
            this.comboMunicipio = retorno.cidades;
        });
    }

    insertIe(): void {

        this.validaFormularioIE();

        if (this.formIe.formGroup.value.isento && !this.formEndereco.formGroup.get('endereco').value) {
            this.toastService.warning('Necessário cadastrar o endereço para opção Isento!');
            this.editar(this.formEndereco);
            return;
        }

        this.gravando = true;

        let dados: any = {};
        if (this.formIe.formGroup.value.isento) {

            dados.isento = true;
            dados.situacaoCadastral = null;
            dados.ie = null;
            dados.uf = null;
            dados.tipo = null;
            dados.dataInicio = null;
            dados.dataFim = null;
        } else {
            dados = this.formIe.formGroup.value;
        }

        const empresaId = this.data.id;

        if (!this.erro) {
            this.empresaDetalheService.insertIe(empresaId, dados).subscribe((response: any) => {
                    this.listarIes(this.currentsParams.ies);
                    this.toastrService.success(response.message);
                    this.formIe.modalVisible = false;
                    this.gravando = false;

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }

                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.gravando = false;
                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    insertIm(): void {

        this.validaFormularioIM();

        this.gravando = true;

        const dados = this.formIm.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {
            this.empresaDetalheService.insertIm(empresaId, dados).subscribe((response: any) => {
                    this.listarIms(this.currentsParams.ims);
                    this.toastrService.success('Inscrição Municipal cadastrada com sucesso!');
                    this.formIm.modalVisible = false;
                    this.gravando = false;

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }
                },
                (err) => {
                    this.toastrService.error(err.error);
                    this.gravando = false;
                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    validaFormularioIE(): void {
        if (!this.formIe.formGroup.value.isento) {

            if (!this.formIe.formGroup.value.ie) {
                this.erro = true;
                this.mensagem = 'Campo IE obrigatório!';
            }
            if (!this.formIe.formGroup.value.uf) {
                this.erro = true;
                this.mensagem = 'Campo UF obrigatório!';
            }
            if (!this.formIe.formGroup.value.tipo) {
                this.erro = true;
                this.mensagem = 'Campo Tipo obrigatório!';
            }
            if (!this.formIe.formGroup.value.dataInicio) {
                this.erro = true;
                this.mensagem = 'Campo Data Início obrigatório!';
            }
            if (!this.formIe.formGroup.value.situacaoCadastral) {
                this.erro = true;
                this.mensagem = 'Campo Situação obrigatório!';
            }


        }

    }

    validaFormularioIM(): void {

        if (!this.formIm.formGroup.value.im) {
            this.erro = true;
            this.mensagem = 'Campo IM obrigatório!';
        }

        if (!this.formIm.formGroup.value.municipioCodigo) {
            this.erro = true;
            this.mensagem = 'Campo Município obrigatório!';
        }

        if (!this.formIm.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Data Início obrigatório!';
        }

        if (!this.formIm.formGroup.value.situacaoCadastral) {
            this.erro = true;
            this.mensagem = 'Campo Data Situação obrigatório!';
        }

    }

    updateIe(): void {

        this.validaFormularioIE();

        let dados: any = {};
        if (this.formIe.formGroup.value.isento) {
            dados.isento = true;
            dados.situacaoCadastral = null;
            dados.ie = null;
            dados.uf = null;
            dados.tipo = null;
            dados.dataInicio = null;
            dados.dataFim = null;

        } else {
            dados = this.formIe.formGroup.value;
        }

        dados.empresa_id = this.data.id;

        const id = this.editandoIe;

        if (!this.erro) {

            this.empresaDetalheService.updateIe(id, dados).subscribe((response: any) => {

                    this.formIe.modalVisible = false;

                    this.listarIes(this.currentsParams.ies);

                    this.toastrService.success(response.message);

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    updateIm(): void {

        this.validaFormularioIM();

        const dados = this.formIm.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoIm;

        if (!this.erro) {

            this.empresaDetalheService.updateIm(id, dados).subscribe((response: any) => {

                    this.formIm.modalVisible = false;

                    this.listarIms(this.currentsParams.ies);

                    this.toastrService.success('Inscrição Municipal atualizado com sucesso!');

                    this.editandoIm = null;

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.gravando = false;

                });

        } else {

            this.toastrService.error(this.mensagem);
            this.erro = false;

        }

    }

    onEditarCnd(controleCertidaoEmpresaId: any): void {
        this.formEditarCnd.modalVisible = true;
        this.loadings.getCnd = true;

        this.empresaDetalheService.getCnd(controleCertidaoEmpresaId).subscribe((res) => {

            let dataInicio = null;
            let dataFim = null;

            if (res?.dataInicio) {
                dataInicio = new Date(res?.dataInicio);
                dataInicio.setDate(dataInicio.getDate() + 1);
            }

            if (res?.dataFim) {
                dataFim = new Date(res?.dataFim);
                dataFim.setDate(dataFim.getDate() + 1);
            }

            this.formEditarCnd.formGroup.get('controleCertidaoEmpresa_id').setValue(controleCertidaoEmpresaId);
            this.formEditarCnd.formGroup.get('certidao_id').setValue(res?.certidao_id || null);
            this.formEditarCnd.formGroup.get('empresa_id').setValue(this.data.id);
            this.formEditarCnd.formGroup.get('descricao').setValue(res?.descricao || null);
            this.formEditarCnd.formGroup.get('descricao').disable();
            this.formEditarCnd.formGroup.get('dataInicio').setValue(dataInicio);
            this.formEditarCnd.formGroup.get('dataFim').setValue(dataFim);

            this.loadings.getCnd = false;

        }, (res) => {
            this.toastService.error('Erro ao obter CND');
            this.formEditarCnd.modalVisible = false;
            this.loadings.getCnd = false;
        });

    }

    confirmarEditCnd(): void {

        if (this.formEditarCnd.formGroup.valid) {

            this.loadings.updateCnd = true;

            const idCnd = this.formEditarCnd.formGroup.get('controleCertidaoEmpresa_id').value;

            this.empresaDetalheService.updateCnd(idCnd, this.formEditarCnd.formGroup.value)
                .subscribe((res) => {

                    this.loadings.updateCnd = false;

                    this.toastService.success('CND editada com sucesso!');

                    this.formEditarCnd.modalVisible = false;

                    this.reloadCardCnd();

                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.updateCnd = false;
                });


        }

    }

    onEditarDec(domicilioEletronicoEmpresaId: string, descricaoDec: string): void {

        this.formEditarDec.modalVisible = true;
        this.loadings.getDec = true;

        this.empresaDetalheService.getDec(domicilioEletronicoEmpresaId).subscribe((res) => {
            let dataInicio = null;
            let dataFim = null;

            if (res?.dataInicio) {
                dataInicio = new Date(res?.dataInicio);
                dataInicio.setDate(dataInicio.getDate() + 1);
            }

            if (res?.dataFim) {
                dataFim = new Date(res?.dataFim);
                dataFim.setDate(dataFim.getDate() + 1);
            }

            this.formEditarDec.formGroup.get('domicilioEletronicoEmpresa_id').setValue(domicilioEletronicoEmpresaId);
            this.formEditarDec.formGroup.get('descricao').setValue(descricaoDec);
            this.formEditarDec.formGroup.get('descricao').disable();
            this.formEditarDec.formGroup.get('dataInicio').setValue(dataInicio);
            this.formEditarDec.formGroup.get('dataFim').setValue(dataFim);
            this.formEditarDec.formGroup.get('empresa_id').setValue(this.data.id);

            this.loadings.getDec = false;

        }, (res) => {
            this.toastService.error('Erro ao obter DEC');
            this.formEditarCnd.modalVisible = false;
            this.loadings.getDec = false;
        });

    }

    confirmarEditDec(): void {

        if (this.formEditarDec.formGroup.valid) {

            this.loadings.updateDec = true;

            const idDec = this.formEditarDec.formGroup.get('domicilioEletronicoEmpresa_id').value;

            this.empresaDetalheService.updateDec(idDec, this.formEditarDec.formGroup.value)
                .subscribe((res) => {

                    this.loadings.updateCnd = false;

                    this.toastService.success('DEC editada com sucesso!');

                    this.formEditarDec.modalVisible = false;

                    this.reloadCardDec();

                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.updateDec = false;
                });


        }

    }

    clickEvent(card: string) {

        switch (card) {

            case 'cnd':
                this.statusCardCnd = !this.statusCardCnd;
                break;

            case 'dec':
                this.statusCardDec = !this.statusCardDec;
                break;

        }

    }

    onAllChecked(checked: boolean): void {

        this.itemsCnd.forEach(({controleCertidaoEmpresa_id}) => this.updateCheckedSet(controleCertidaoEmpresa_id, checked));
    }

    onAllCheckedDec(checked: boolean): void {

        this.itemsDec.forEach(({domicilioEletronicoEmpresa_id}) => this.updateDECCheckedSet(domicilioEletronicoEmpresa_id, checked));
    }

    calculaBadgeFiltrosCnd(): void {
        this.qtdFiltrosAtivosCnd = 0;
        if (typeof this.formFiltrarCnd !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrarCnd.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivosCnd += 1;
                }
            }
        }
    }

    calculaBadgeFiltrosDec(): void {
        this.qtdFiltrosAtivosDec = 0;
        if (typeof this.formFiltrarDec !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrarDec.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivosDec += 1;
                }
            }
        }
    }

    reloadCardCnd() {
        this.currentsParams.filter = [];
        this.currentSearch.certidoes = '';
        this.formFiltrarCnd.formGroup.reset();
        this.queryTableCnd(this.currentsParams.cnd, this.currentSearch.cnd);
    }

    reloadCardDec() {
        this.currentsParams.dec.filter = [];
        this.currentSearch.domicilios = '';
        this.formFiltrarDec.formGroup.reset();
        this.queryTableDec(this.currentsParams.dec, this.currentSearch.domicilios);
    }

    modalFiltrarCnd(visible: boolean) {
        this.formFiltrarCnd.modalVisible = visible;
    }

    modalFiltrarDec(visible: boolean) {
        this.formFiltrarDec.modalVisible = visible;
    }

    confirmaFiltrarCnd(): void {

        this.currentsParams.cnd.filter = [];

        this.currentsParams.cnd.pageIndex = 1;

        const filtros = this.formFiltrarCnd.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                if (chave === 'dataInicio' || chave === 'dataFim') {
                    valor = this.abstractService.dataToFilter(valor);
                }
                this.currentsParams.cnd.filter.push({key: chave, value: valor});
            }
        }

        this.queryTableCnd(this.currentsParams.cnd, this.currentSearch.cnd);
        this.modalFiltrarCnd(false);
    }

    confirmaFiltrarDec(): void {

        this.currentsParams.filter = [];

        this.currentsParams.pageIndex = 1;

        const filtros = this.formFiltrarDec.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                if (chave === 'dataInicio' || chave === 'dataFim') {
                    valor = this.abstractService.dataToFilter(valor);
                }
                this.currentsParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTableDec(this.currentsParams);
        this.modalFiltrarDec(false);
    }

    showConfirmAdicionarCertidoes(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja adicionar as Certidões Sugeridas?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.adicionarCertidoesSugeridas()
        });

    }

    showConfirmAdicionarDomicilios(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja adicionar os Domicílios Sugeridos?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.adicionarDomiciliosSugeridos()
        });

    }

    adicionarCertidoesSugeridas() {
        this.loadingAdicionarCnd = true;

        if (this.modoAssistente) {
            this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
        }

        this.CadastroCertidoesService
            .adicionarCertidoesSugeridasPorEmpresa(this.empresaId).subscribe((response: any) => {

            this.loadingAdicionarCnd = false;

            this.toastrService.success('Certidões adicionadas com sucesso!');

            this.reloadCardCnd();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarCnd = false;

        });

    }

    adicionarDomiciliosSugeridos() {
        this.loadingAdicionarDec = true;

        if (this.modoAssistente) {
            this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
        }

        this.CadastroCertidoesService
            .adicionarDomiciliosSugeridos(this.empresaId).subscribe((response: any) => {

            this.loadingAdicionarDec = false;

            this.toastrService.success('Domicílios adicionados com sucesso!');

            this.reloadCardDec();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarDec = false;

        });

    }

    modalEditarCndMassa(visible) {
        this.formEditarDatas.modalVisible = visible;
    }

    modalEditarDecMassa(visible) {
        this.formEditarDatasDec.modalVisible = visible;
    }

    editarVencimentosMassa() {

        if (this.validaVencimentosMassa('cnd')) {

            const dados = this.formEditarDatas.formGroup.value;

            if (this.checkedItems.size > 0) {
                dados.ids = Array.from(this.checkedItems);
            }

            this.CadastroCertidoesService.editarEmpresasVencimentosOficiais(dados).subscribe(() => {

                    this.toastrService.success(`Certidões Editadas com Sucesso.`);

                    this.formEditarDatas.modalVisible = false;

                    this.formEditarDatas.formGroup.reset();

                    this.reloadCardCnd();
                    this.onAllChecked(false);
                    this.checked = false;

                },
                (response) => {

                    this.toastrService.error(response.error.message);

                }
            );

        }

    }

    editarVencimentosDecMassa(): void {

        if (this.formEditarDatasDec.formGroup.valid) {
            const dados = this.formEditarDatasDec.formGroup.value;

            Object.entries(dados).forEach(([key, value]) => {
                if (value && value !== '' && value !== '') {

                    const data: any = value;
                    if (typeof data.getMonth === 'function') {
                        dados[key] = this.domicilioEletronicoService.formataDateTimeBD(data) + ':00';
                    }
                }
            });

            dados.empresas = Array.from(this.checkedItemsDec);

            this.updateDomiciliosMassa = true;

            this.domicilioEletronicoService.updateDomicilioEmpresasMassa(dados).subscribe(res => {
                this.toastService.success('Dados alterados com sucesso');
                this.updateDomiciliosMassa = false;
                this.formEditarDatasDec.modalVisible = false;
                this.reloadCardDec();
                this.formEditarDatasDec.formGroup.reset();
            }, (res) => {
                this.toastService.error(res.error.message);
                this.updateDomiciliosMassa = false;
            });


        } else {
            Object.values(this.formEditarDatasDec.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    validaVencimentosMassa(tipo: string) {

        let error = false;

        if (tipo === 'cdn' && !this.formEditarDatas.formGroup.value.dataInicio) {
            this.toastrService.error('Data Início obrigatória');
            error = true;
        } else if (tipo === 'dec' && !this.formEditarDatasDec.formGroup.value.dataInicio) {
            this.toastrService.error('Data Início obrigatória');
            error = true;
        }

        return !error;

    }

    showConfirmRemoverCndsEmMassa(): void {

        const ids = [...this.checkedItems.values()];
        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) CND(s)?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerCnd(ids)
        });
    }

    showConfirmRemoverDecsEmMassa(): void {

        const ids = [...this.checkedItemsDec.values()];

        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) DEC(s)?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerDec(ids)
        });
    }

    onItemDECChecked(id: string, checked: boolean): void {

        this.updateDECCheckedSet(id, checked);

    }

    updateDECCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsDec.add(id);

        } else {

            this.checkedItemsDec.delete(id);

        }
    }

    showModal(formulario): void {
        formulario.modalVisible = !formulario.modalVisible;

        if (this.modoAssistente && !formulario.modalVisible) {
            this.stopAssist();
        }
    }

    retornaCongiguracoesNFE(empresaId: string): void {

        this.loadingsConfigDFE = true;

        this.editarNotasFiscaisEletronicasService.retornaDadosCadastrais(empresaId).subscribe((response: any) => {
            this.configuracoesDFE = response || {};

            function ajustaData(horaMinuto: string) {

                if (!horaMinuto) {
                    return null;
                }

                const horario = horaMinuto.split(':');

                const data = new Date();
                data.setHours(Number(horario[0]));
                data.setMinutes(Number(horario[1]));

                return data;
            }

            this.formConfiguracoesDFE.formGroup.get('ativo').setValue(response.ativo);
            this.formConfiguracoesDFE.formGroup.get('horarioInicio').setValue(ajustaData(response.horarioInicio));
            this.formConfiguracoesDFE.formGroup.get('horarioFim').setValue(ajustaData(response.horarioFim));

            this.loadingsConfigDFE = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingsConfigDFE = false;
        });

    }

    confirmaAlterarDFE() {

        if (this.formConfiguracoesDFE.formGroup.valid) {

            this.loadingsConfigDFE = true;

            const data: any = {};
            data.empresa_id = [this.data.id];

            data.ativo = Number(this.formConfiguracoesDFE.formGroup.get('ativo').value);

            if (this.formConfiguracoesDFE.formGroup.get('horarioInicio').value) {
                data.horarioInicio = this.formConfiguracoesDFE.formGroup.get('horarioInicio').value;
                const inicio = new Date(data.horarioInicio).getHours();
                const fim = new Date(data.horarioInicio).getMinutes();
                data.horarioInicio = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
            } else {
                data.horarioInicio = null;
            }

            if (this.formConfiguracoesDFE.formGroup.get('horarioFim').value) {
                data.horarioFim = this.formConfiguracoesDFE.formGroup.get('horarioFim').value;
                const inicio = new Date(data.horarioFim).getHours();
                const fim = new Date(data.horarioFim).getMinutes();
                data.horarioFim = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
            } else {
                data.horarioFim = null;
            }

            this.editarNotasFiscaisEletronicasService.alterar(data).subscribe((response) => {
                this.toastService.success(response.message);
                this.loadingsConfigDFE = false;
                this.checkedItems.clear();
                this.showModal(this.formConfiguracoesDFE);
                this.formConfiguracoesDFE.formGroup.reset();

                this.retornaCongiguracoesNFE(this.empresaId);

                if (this.modoAssistente) {
                    this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                }

            }, error => {
                this.toastService.error(error.error.message);
                this.loadingsConfigDFE = false;

            });

        } else {
            for (const key in this.formConfiguracoesDFE.formGroup.controls) {

                if (key) {
                    const campo = this.formConfiguracoesDFE.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();
                }
            }
        }

    }

    listarImobiliarias(event: any) {

        this.loadingImombiliarias = true;

        this.currentsParams.imobiliarias = event;

        if (event?.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch,
            environment.apiUrl + '/cadastros/empresas/inscricao-imobiliarias'
        ).subscribe((response) => {

            this.iImobiliarias = [];

            this.iImobiliarias = response?.data || response;

            this.paginationImobiliarias = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingImombiliarias = false;

            this.loading = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingImombiliarias = false;
        });

    }

    alterarImobiliaria(item: any): void {

        this.zerarForms();

        this.editarInscricoesRegimesService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.filtraComboMunicipio(item.estado_uf);

        this.editandoImobiliaria = item.id;
        this.formImobiliaria.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);
        this.formImobiliaria.formGroup.get('municipioCodigo').setValue(item.municipioCodigo);
        this.formImobiliaria.formGroup.get('uf').setValue(item.estado_uf);
        this.formImobiliaria.formGroup.get('ii').setValue(item.ii);
        this.formImobiliaria.formGroup.get('setor').setValue(item.setor);

        this.changeMunicipio();

        if (item.dataInicio) {
            const editarDataInicioIm = new Date(item.dataInicio);
            editarDataInicioIm.setDate(editarDataInicioIm.getDate() + 1);
            this.formImobiliaria.formGroup.get('dataInicio').setValue(editarDataInicioIm);
        }

        this.formImobiliaria.modalVisible = true;

    }

    charUnidentified(tipo: string) {
        const form: FormStack = tipo === 'im' ? this.formIm : this.formImobiliaria;
        const inscricao = form.formGroup.get(tipo).value;

        if (inscricao && '`´~^"'.includes(inscricao[inscricao.length - 1]) || inscricao[inscricao.length - 1] === '¨') {
            form.formGroup.get(tipo).setValue(inscricao.substring(0, inscricao.length - 1));
        }
    }

    changeMunicipio() {
        if (this.formImobiliaria.formGroup.get('municipioCodigo').value === '3556206') {
            this.formImobiliaria.formGroup.get('setor').setValidators(Validators.required);
        } else {
            this.formImobiliaria.formGroup.get('setor').setValidators(null);
            this.formImobiliaria.formGroup.get('setor').setErrors(null);
        }
    }

    insertImobiliaria(): void {

        if (this.formImobiliaria.formGroup.valid) {

            const dados = this.formImobiliaria.formGroup.value;

            const empresaId = this.data.id;

            this.loadings.gravandoII = true;

            this.editarInscricoesRegimesService.insertImobiliaria(empresaId, dados).subscribe((response: any) => {

                    this.toastrService.success('Inscrição imobiliária cadastrada com sucesso!');
                    this.formImobiliaria.modalVisible = false;
                    this.formImobiliaria.formGroup.reset();
                    this.loadings.gravandoII = false;
                    this.listarImobiliarias(this.currentsParams.imobiliarias);

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoII = false;
                });
        } else {
            Object.values(this.formImobiliaria.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    updateImobiliarias(): void {

        if (this.formImobiliaria.formGroup.valid) {

            const dados = this.formImobiliaria.formGroup.value;

            dados.empresa_id = this.data.id;

            const id = this.editandoImobiliaria;

            this.loadings.gravandoII = true;

            this.editarInscricoesRegimesService.updateImobiliaria(id, dados).subscribe((response: any) => {
                    this.loadings.gravandoII = false;

                    this.formImobiliaria.modalVisible = false;
                    this.formImobiliaria.formGroup.reset();
                    this.editandoImobiliaria = false;

                    this.listarImobiliarias(this.currentsParams.imobiliarias);

                    this.toastrService.success('Inscrição Imobiliária atualizado com sucesso!');

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoII = false;

                });

        } else {
            Object.values(this.formImobiliaria.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    listarRegimes(event: any) {

        this.loadingRegime = true;

        this.currentsParams.regimes = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch,
            this.editarInscricoesRegimesService.baseUrlRegimes).subscribe((response) => {

            this.regimes = [];
            this.regimes = response?.data || response;

            this.paginations.regimes = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingRegime = false;
        }, error => {
            this.loadingRegime = false;
            this.toastService.error(error.error.message);
        });

    }

    alterarRegime(item: any): void {

        this.zerarForms();

        this.editandoRegime = item.id;

        this.formRegime.formGroup.get('regimeTributario_id').setValue(item.regimeTributario_id);

        if (item.dataInicio) {
            const editarDataInicioRegime = new Date(item.dataInicio);
            editarDataInicioRegime.setDate(editarDataInicioRegime.getDate() + 1);
            this.formRegime.formGroup.get('dataInicio').setValue(editarDataInicioRegime);
        }

        if (item.dataFim) {
            const editarDataFimRegime = new Date(item.dataFim);
            editarDataFimRegime.setDate(editarDataFimRegime.getDate() + 1);
            this.formRegime.formGroup.get('dataFim').setValue(editarDataFimRegime);
        }

        this.formRegime.modalVisible = true;

    }

    insertRegime(): void {

        this.validaFormularioRegime();

        const dados = this.formRegime.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {
            this.loadings.gravandoRegime = true;
            this.editarInscricoesRegimesService.insertRegime(empresaId, dados).subscribe((response: any) => {
                this.loadings.gravandoRegime = false;
                this.listarRegimes(this.currentsParams.regimes);
                this.toastrService.success('Regime Tributário cadastrado com sucesso!');
                this.formRegime.modalVisible = false;

                if (this.modoAssistente) {
                    this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                }

            }, (res) => {
                this.loadings.gravandoRegime = false;
                this.toastrService.error(res.error.message);
            });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.loadings.gravandoRegime = false;
        }
    }

    updateRegime(): void {

        this.validaFormularioRegime();

        const dados = this.formRegime.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoRegime;

        if (!this.erro) {
            this.loadings.gravandoRegime = true;
            this.editarInscricoesRegimesService.updateRegime(id, dados).subscribe((response: any) => {

                    this.loadings.gravandoRegime = false;
                    this.formRegime.modalVisible = false;

                    this.listarRegimes(this.currentsParams.regimes);

                    this.toastrService.success('Regime Tributário atualizado com sucesso!');
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoRegime = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    validaFormularioRegime(): void {
        if (!this.formRegime.formGroup.value.regimeTributario_id) {
            this.erro = true;
            this.mensagem = 'Regime tributário obrigatório!';
        }
        if (!this.formRegime.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Data de início obrigatório!';
        }
    }

    calculaFiltrosObrigacoes(): void {
        this.qtdFiltrosObrigacoesAtivos = 0;

        if (typeof this.formFiltrarObrigacoes !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrarObrigacoes.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosObrigacoesAtivos++;
                }

            }
        }
    }

    getObrigacoes(params: NzTableQueryParams, search: string = null, page?: number): void {

        this.checkedItemsObrigacoes.clear();

        if (this.verificaModulo(['calendar', 'check', 'compliance'])) {


            if (page) {
                this.currentsParams.obrigacoes.pageIndex = 1;
            }

            if (search) {
                this.currentSearch.obrigacoes = search;
            }

            this.currentsParams.obrigacoes = params;

            this.calculaFiltrosObrigacoes();

            if (this.selectedValue) {

                this.loadings.obrigacoes = true;
                this.obrigacaoService.getObrigacoes(
                    this.currentsParams.obrigacoes,
                    this.currentSearch.obrigacoes, this.data.id, this.selectedValue).subscribe((response) => {

                    this.vencimentos = {};

                    this.vencimentos = response.vencimentos;

                    this.vencimentosHeader = {};

                    this.vencimentosHeader = response.vencimentos;

                    const vencimentos = Object.entries(response.vencimentos);

                    this.arrayVencimentoOficialCompetencias = [];

                    vencimentos.forEach((element) => {

                        this.arrayVencimentoOficialCompetencias.push({
                            competencia: element[0],
                            index: element[1]
                        });

                        const competencia = new Date(element[1].toString());

                        competencia.setDate(competencia.getDate() + 1);

                        // this.formObrigacaoVencimento.formGroup.addControl(element[0], new UntypedFormControl(''));
                        //
                        // this.formObrigacaoVencimento.formGroup.get(element[0]).setValue(competencia);

                    });

                    response.empresas.data.forEach((element) => {
                        element.objectVencimentos = element.vencimentos;
                        element.vencimentos = Object.entries(element.vencimentos);
                    });


                    this.obrigacoes = response.empresas.data;

                    this.paginationObrigcoes = new Pagination(
                        response?.per_page || 50,
                        response?.current_page || 1,
                        response?.last_page || 1,
                        response?.total || 50,
                        response?.from || 1,
                        response?.to || 1);

                    this.loadings.obrigacoes = false;
                }, error => {
                    this.toastService.error(error.error.message);
                    this.loadings.obrigacoes = false;
                });

            }

        }

    }

    changeAno(ano: any) {

        this.selectedValue = ano;

        this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);
    }

    modalFiltrarObrigacoes(visible) {
        this.modalFiltrarObrigacoesVisible = visible;
    }

    btnResetSearchObrigacoes() {

        this.formFiltrarObrigacoes.formGroup.reset();
        this.currentsParams.obrigacoes.filter = [];
        this.currentSearch.obrigacoes = null;

        this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);

    }

    openTab(componentUrl: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentUrl);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    modalCadastrarEmMassa(visible: boolean): void {

        this.checkedItemsObrigacoes.clear();
        this.checkedObrigacoes = false;

        if (visible) {
            this.formCadastrarEmMassa.formGroup.get('empresa_id').setValue('todas');
            this.formCadastrarEmMassa.formGroup.get('obrigacao_id').setValue('todas');
        }

        this.formCadastrarEmMassa.modalVisible = visible;


        if (this.modoAssistente && !visible) {
            this.stopAssist();
        }

    }

    confirmaFiltrarObrigacoes(): void {

        this.currentsParams.obrigacoes.filter = [];
        this.currentsParams.obrigacoes.pageIndex = 1;

        const filtros = this.formFiltrarObrigacoes.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentsParams.obrigacoes.filter.push({key: chave, value: valor});
            }
        }

        this.calculaFiltrosObrigacoes();

        this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);

        this.modalFiltrarObrigacoes(false);

    }

    changeStep(stepNumber, direction) {

        this.stepNumber = stepNumber;

        if (direction === 'next') {

            if (this.stepNumber === 1) {

                if (!this.formStepsObrigacoes.formGroup.value.modulos || this.formStepsObrigacoes.formGroup.value.modulos.length === 0) {
                    this.toastService.error('Por favor, selecione ao menos um módulo.');
                    return;
                }

            }

            if (this.stepNumber === 2) {

                this.formStepsObrigacoes.formGroup.removeControl('obrigacoes');

                if (this.radioValueStepOne === 'todas') {

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', new UntypedFormControl());
                    this.formStepsObrigacoes.formGroup.get('obrigacoes').setValue('todas');

                }

                if (this.radioValueStepOne === 'algumas') {

                    const obrigacoes: any = Array.from(this.checkedItemsObrigacoes);

                    if (obrigacoes.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma obrigação.');
                        return;
                    }

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', this.fb.array(obrigacoes));

                }

            }

            this.stepsLoading = true;

            if (this.stepNumber === 2) {
                this.gravarSteps();
            }

        }

        if (direction === 'prev') {

            this.stepNumber--;

            this.steps[this.stepNumber].step = 'wait';
            this.steps[this.stepNumber].icon = this.icons[this.stepNumber];

            this.stepsLoading = false;

            if (this.stepNumber === 1) {

                this.formStepsObrigacoes.formGroup.value.modulos.forEach((value) => {

                    if (value === 'calendar') {
                        this.modelCalendar = true;
                    }

                    if (value === 'check') {
                        this.modelCheck = true;
                    }

                    if (value === 'compliance') {
                        this.modelCompliance = true;
                    }

                });

                this.nextButtonText = 'Avançar';

            }

        }

        if (this.stepNumber < 2) {

            if (direction === 'next') {

                this.steps[this.stepNumber].step = 'process';
                this.steps[this.stepNumber].icon = 'loading';

                setTimeout(() => {

                    this.steps[this.stepNumber].step = 'finish';
                    this.steps[this.stepNumber].icon = 'check-circle';

                    this.stepNumber++;

                    this.steps[this.stepNumber].step = 'process';

                    this.stepsLoading = false;

                    if (this.stepNumber === 2) {
                        this.nextButtonText = 'Concluir';
                    }

                }, 1000);

            }
        }
    }

    gravarSteps(): void {

        this.steps[this.stepNumber].step = 'process';
        this.steps[this.stepNumber].icon = 'loading';

        this.stepsObrigacoesEmpresaService
            .gravarSteps(this.formStepsObrigacoes.formGroup.value, this.empresaId).subscribe((response: any) => {

                this.steps[this.stepNumber].step = 'finish';
                this.steps[this.stepNumber].icon = 'check-circle';

                this.stepNumber++;

                this.steps[this.stepNumber].step = 'process';

                this.stepsLoading = false;

                this.formStepsObrigacoes.formGroup.reset();

                this.modelCalendar = false;
                this.modelCheck = false;
                this.modelCompliance = false;

                this.checkedItemsObrigacoes.clear();

                this.toastService.success('Processo de cadastro iniciado.');

                setTimeout(() => {
                    this.stepNumber = 1;
                    this.formCadastrarEmMassa.modalVisible = false;
                    this.steps = [
                        {
                            icon: 'calendar',
                            step: 'finish',
                        },
                        {
                            icon: 'apartment',
                            step: 'process',
                        },
                        {
                            icon: 'branches',
                            step: 'wait',
                        },
                        {
                            icon: 'check',
                            step: 'finish',
                        },
                    ];
                    this.radioValueStepOne = 'todas';
                    this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);
                    this.nextButtonText = 'Avançar';

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }

                }, 1000);

            },
            (response) => {
                this.toastrService.error(response.error.message);
                setTimeout(() => {
                    this.steps = [
                        {
                            icon: 'calendar',
                            step: 'process',
                        },
                        {
                            icon: 'apartment',
                            step: 'wait',
                        },
                        {
                            icon: 'branches',
                            step: 'wait',
                        },
                        {
                            icon: 'check',
                            step: 'finish',
                        },
                    ];
                    this.stepNumber = 0;
                    this.nextButtonText = 'Avançar';
                    this.stepsLoading = false;
                    this.modalCadastrarEmMassa(false);
                }, 1000);

            }
        );

    }

    retornaObrigacoesEmpresasSteps(params: NzTableQueryParams) {

        this.loadings.previaObrigacoes = true;

        this.previaObrigacoes = [];

        const dados = {
            modulos: this.formStepsObrigacoes.formGroup.value.modulos,
            empresas: this.formStepsObrigacoes.formGroup.value.empresas
        };

        this.stepsObrigacoesEmpresaService.retornaObrigacoesEmpresas(dados).subscribe((response) => {

            this.previaObrigacoes = response?.data || response;

            this.paginationObrigcoes = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loadings.previaObrigacoes = false;

        });

    }

    selecionarModulos(value: object[]): void {
        this.formStepsObrigacoes.formGroup.removeControl('modulos');
        this.formStepsObrigacoes.formGroup.addControl('modulos', this.fb.array(value));
    }

    retornaObrigacoes(params: NzTableQueryParams, search: string = null) {

        this.loading = true;

        this.currentParams = params;

        this.itemsObrigacoes = [];

        let url = '';

        if (this.steps[0].step !== 'finish') {
            url = `${environment.apiUrl}/administracao/empresas`;
        }

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.itemsObrigacoes = response?.data || response;

            this.paginations.empresas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    listarAcessosEcac(params: NzTableQueryParams, search: string = null): void {
        this.loadings.acessosEcac = true;

        this.currentsParams.acessosEcac = params;
        this.currentSearch.acessosEcac = search;

        if (params?.filter) {
            params.filter = [];
            // params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
        }

        params.pageSize = 10;

        this.abstractService.listToTable(
            params, search, this.editarCredenciaisService.urlAcessosEcac + `/${this.data.id}`).subscribe((response) => {
            this.loadings.acessosEcac = false;
            this.listaAcessosEcac = [];
            this.listaAcessosEcac = response?.data || response;

            this.paginations.acessosEcac = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);
        });

    }

    modalAcessoEcac(visible: boolean, cadastrando = false): void {
        if (cadastrando) {
            this.statusToUpdate = 1;
        }

        this.formAcessoEcac.modalVisible = visible;

        if (!visible) {
            this.formAcessoEcac.formGroup.reset();
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(1);

            if (this.modoAssistente) {
                this.stopAssist();
            }

        } else {
            this.formAcessoEcac.formGroup.get('ids').setValue(this.data.id);
        }
    }

    listByTableAcessosEcac(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.acessosEcac.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.acessosEcac.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.acessosEcac.current_page) {
            this.listarAcessosEcac(params);
            this.paginations.acessosEcac.current_page = params.pageIndex;
        }

    }

    confirmInativar(): void {

        this.modalService.confirm({
            nzTitle: 'Tem certeza que deseja inativar o acesso integrado E-CAC para essa empresa?',
            nzOkText: 'Inativar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.ativarInativarAcessoIntegradoECAC(true)
        });
    }

    ativarInativarAcessoIntegradoECAC(inativar = false, novo = false) {

        this.loadingAcessoEcac = true;

        if (inativar) {
            this.statusToUpdate = 0;
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(this.statusToUpdate);
            this.formAcessoEcac.formGroup.get('procurador').setValue(this.acessoEcacSelecionado.responsavel_id);
        }

        const dados = this.formAcessoEcac.formGroup.value;

        if (novo) {
            dados.ids = [this.formAcessoEcac.formGroup.get('ids').value];
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(1);
            dados.statusAcessoEcac = this.formAcessoEcac.formGroup.get('statusAcessoEcac').value;
        }

        if (this.formAcessoEcac.formGroup.valid) {

            this.editarCredenciaisService.ativarInativarAcessoIntegradoECAC(dados).subscribe((response: any) => {
                    this.toastrService.success('Ação realizada com sucesso!');
                    this.listarAcessosEcac(this.currentsParams.acessosEcac, this.currentSearch.acessosEcac);
                    this.loadingAcessoEcac = false;
                    this.formAcessoEcac.formGroup.get('procurador').setValue(null);
                    this.modalAcessoIntegradoEcac(null, false);
                    this.modalProcurador(0, false);

                    // Não charmar função de fechar modal aqui para não conflitar com assistente.
                    this.formAcessoEcac.formGroup.reset();
                    this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(1);
                    this.formAcessoEcac.modalVisible = false;

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadingAcessoEcac = false;
                });
        } else {
            this.loadingAcessoEcac = false;

            Object.values(this.formAcessoEcac.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalAcessoIntegradoEcac(acesso = null, visible: boolean) {
        if (acesso) {
            this.acessoEcacSelecionado = acesso;
            this.termosAceitos = acesso.ativo === 1;
            this.checkDisabled = acesso.ativo === 1;
        }

        this.termosVisible = visible;

        this.formAcessoEcac.formGroup.get('ids').setValue([this.acessoEcacSelecionado.empresa_id]);

        if (visible) {
            this.termosAceitos = false;
            this.termosCompletosVisible = false;
            this.formAcessoEcac.formGroup.get('procurador').setValidators(Validators.required);
        }
    }

    modalProcurador(ativar, visible: boolean) {

        this.statusToUpdate = ativar;
        this.selecionarProcurador = visible;

        if (!visible) {
            this.termosAceitos = false;
            this.formAcessoEcac.formGroup.reset();
        } else {
            this.modalAcessoIntegradoEcac(this.acessoEcacSelecionado, !visible);
        }
    }

    verTermosCompletos() {
        this.termosCompletosVisible = !this.termosCompletosVisible;
    }

    listarAcessosMunicipais(params: NzTableQueryParams, search: string = null): void {
        this.loadings.acessosMunicipais = true;

        this.currentsParams.acessosMunicipais = params;
        this.currentSearch.acessosMunicipais = search;

        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentsParams.acessosMunicipais;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
            params.filter.push({key: 'empresa_id', value: this.data.id});
        }

        params.pageSize = 10;

        this.abstractService.listToTable(params, search, this.editarCredenciaisService.urlAcessosMunicipais).subscribe((response) => {
            this.loadings.acessosMunicipais = false;
            // Descomentar quando tiver endpoint
            this.listaAcessosMunicipais = [];
            this.listaAcessosMunicipais = response?.data || response;

            this.paginations.acessosMunicipais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        });


    }

    listarAcessosPrevidenciarios(params: NzTableQueryParams, search: string = null): void {
        params.filter = [];
        this.loadings.acessosPrevidenciarios = true;

        this.currentsParams.acessosPrevidenciarios = params;
        this.currentSearch.acessosPrevidenciarios = search;

        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
        }

        params.pageSize = 10;

        this.abstractService.listToTable(params, search, this.editarCredenciaisService.urlAcessosPrevidenciarios).subscribe((response) => {
            this.loadings.acessosPrevidenciarios = false;

            this.listaAcessosPrevidenciarios = [];
            this.listaAcessosPrevidenciarios = response?.data || response;

            this.paginations.acessosPrevidenciarios = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, e => {
            this.loadings.acessosPrevidenciarios = false;
            this.toastService.error('Erro ao listar acessos previdenciários: ' + e.error.message);
        });


    }

    async alterarAcessosPrevidenciarios(item: any) {
        this.editando.acessosPrevidenciarios = item.id;

        this.loadings.formAcessosPrevidenciarios = true;
        this.formAcessosPrevidenciarios.formGroup.get('portal').setValue(item.portal);
        this.filtrarCamposPrevidenciario(item);

        this.modal(this.formAcessosPrevidenciarios, true, item, 'dataPrev');

    }

    async alterarAcessosMunicipais(item: any) {

        this.loadings.formAcessosMunicipais = true;
        this.filtrarCamposMunicipal(item.portal);
        this.modal(this.formAcessosMunicipais, true, item, 'municipal');

        this.listMunicipios(item.uf).then(() => {
            this.editando.acessosMunicipais = item.id;
            this.formAcessosMunicipais.formGroup.get('uf').setValue(item.uf);
            this.formAcessosMunicipais.formGroup.get('portal').setValue(item.portal);
            this.formAcessosMunicipais.formGroup.get('uf').disable();
            this.formAcessosMunicipais.formGroup.get('portal').disable();

            setTimeout(() => {
                const dados = JSON.parse(item.acesso);
                for (const [key, value] of Object.entries(dados)) {
                    this.formAcessosMunicipais.formGroup.get(key)?.setValue(value ? value.toString().trim() : '');
                }
                this.loadings.formAcessosMunicipais = false;
            }, 2000);
        });

    }

    async filtrarCamposPrevidenciario(item: any) {

        if (item.portal) {
            this.editarCredenciaisService.retornaCamposAcessosPrevidenciarios(item.portal).subscribe((retorno: any) => {
                this.camposFormDataPrevPrevidencirios = retorno;
                retorno.forEach((value, index) => {

                    this.formAcessosPrevidenciarios.formGroup.addControl(
                        value.label, new UntypedFormControl(value.valores[0] && value.valores[0], Validators.required));
                });

                const dados = JSON.parse(item.acesso);
                for (const [key, value] of Object.entries(dados)) {
                    this.formAcessosPrevidenciarios.formGroup.get(key)?.setValue(value ? value.toString().trim() : '');
                }
                this.loadings.formAcessosPrevidenciarios = false;
            });
        }
    }

    listByTablePrevidenciarios(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.acessosPrevidenciarios.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.acessosPrevidenciarios.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.acessosPrevidenciarios.current_page) {
            this.listarAcessosPrevidenciarios(params);
            this.paginations.acessosPrevidenciarios.current_page = params.pageIndex;
        }

    }

    async listMunicipios(uf: string) {

        this.formAcessosMunicipais.formGroup.get('portal').setValue(null);

        this.camposFormDataPrevMunicipais = [];

        if (uf) {
            this.editarCredenciaisService.retornaMunicipios(uf).subscribe((retorno: any) => {
                retorno.forEach((value) => {
                    value.label = value.descricao;
                    value.key = value.id;
                });
                this.comboMunicipios = retorno;
            });
        }
    }

    async filtrarCamposMunicipal(portal: string) {

        if (portal) {

            this.erro = false;
            this.formAcessosMunicipais = {
                modalVisible: true,
                formGroup: this.fb.group({
                    uf: [this.formAcessosMunicipais.formGroup.get('uf').value, Validators.required],
                    portal: [this.formAcessosMunicipais.formGroup.get('portal').value, Validators.required],
                })
            };

            this.editarCredenciaisService.retornaCamposAcessosMunicipais(portal).subscribe((retorno: any) => {
                this.camposFormDataPrevMunicipais = retorno;
                retorno.forEach((value, index) => {
                    this.formAcessosMunicipais.formGroup.addControl(
                        value.label, new UntypedFormControl(value.valores[0] && value.valores[0], Validators.required));
                });
            });
        }
    }

    modalAcessoPrevidenciario(visible: boolean, dados = null): void {

        if (!visible) {
            this.editando.acessosPrevidenciarios = null;
        }

        this.formAcessosPrevidenciarios.modalVisible = visible;

        if (!visible) {
            if (this.modoAssistente) {
                this.stopAssist();
            }

            this.formAcessosPrevidenciarios = {
                modalVisible: false,
                formGroup: this.fb.group({
                    portal: [null, Validators.required],
                })
            };
            this.camposFormDataPrevPrevidencirios = [];
            this.cdr.detectChanges();
        }

        this.dataPrevSelecionado = dados;

    }

    modalAcessoMunicipal(visible: boolean, dados = null): void {

        if (!visible) {
            this.editando.acessosMunicipais = null;
        }

        this.formAcessosMunicipais.modalVisible = visible;

        if (!visible) {

            if (this.modoAssistente) {
                this.stopAssist();
            }

            this.formAcessosMunicipais = {
                modalVisible: false,
                formGroup: this.fb.group({
                    uf: [null, Validators.required],
                    portal: [null, Validators.required],
                })
            };
            this.camposFormDataPrevMunicipais = [];
            this.cdr.detectChanges();
        }

        this.municipalSelecionado = dados;

    }

    listByTableMunicipais(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.municipais.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.municipais.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.municipais.current_page) {
            this.listarAcessosMunicipais(params);
            this.paginations.municipais.current_page = params.pageIndex;
        }

    }

    insertAcessosMunicipais(): void {

        const dados = this.formAcessosMunicipais.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro && this.formAcessosMunicipais.formGroup.valid) {
            this.loadings.insertAcessoMunicipal = true;

            this.editarCredenciaisService.insertAcessosMunicipais(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessosMunicipais(this.currentsParams.acessosMunicipais, this.currentSearch.acessosMunicipais);

                    this.toastrService.success('Acesso Municipal cadastrado com sucesso!');

                    if (this.dadosCadastrais.estado && this.dadosCadastrais.estado !== dados.uf) {
                        this.toastService.warning('UF diferente do cadastro da empresa (' + this.dadosCadastrais.estado + ')');
                    }

                    this.formAcessosMunicipais = {
                        modalVisible: false,
                        formGroup: this.fb.group({
                            uf: [null, Validators.required],
                            portal: [null, Validators.required],
                        })
                    };

                    if (this.modoAssistente) {
                        this.nextStepAssist(this.stepsAssist[this.stepAssistAtual + 1].desc);
                    }

                },
                (response) => {
                    this.toastrService.error(response.message);
                    this.loadings.insertAcessoMunicipal = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;
            this.loadings.insertAcessoMunicipal = false;

            Object.values(this.formAcessosMunicipais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    insertAcessosPrevidnciario(): void {

        const dados = this.formAcessosPrevidenciarios.formGroup.value;

        const empresaId = this.data.id;

        if (this.formAcessosPrevidenciarios.formGroup.invalid) {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosPrevidenciarios.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        } else if (!this.erro) {
            this.loadings.acessosPrevidenciarios = true;

            this.editarCredenciaisService.insertAcessosPrevidenciario(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessosPrevidenciarios(
                        this.currentsParams.acessosPrevidenciarios, this.currentSearch.acessosPrevidenciarios);

                    this.toastrService.success('Acesso Previdenciário cadastrado com sucesso!');

                    this.formAcessosPrevidenciarios = {
                        modalVisible: false,
                        formGroup: this.fb.group({
                            portal: [null, null],
                        })
                    };

                    this.loadings.acessosPrevidenciarios = false;

                    this.formAcessosPrevidenciarios.modalVisible = false;

                    if (this.modoAssistente) {
                        this.stopAssist();
                    }

                },
                (e) => {
                    this.loadings.acessosPrevidenciarios = false;
                    this.toastrService.error(e.error.error);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateAcessosMunicipais(): void {

        const dados = this.formAcessosMunicipais.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro && this.formAcessosMunicipais.formGroup.valid) {

            this.loadings.updateAcessosMunicipal = true;

            this.editarCredenciaisService.updateAcessosMunicipais(this.editando.acessosMunicipais, dados).subscribe((response: any) => {
                    this.listarAcessosMunicipais(this.currentsParams.acessosMunicipais, this.currentSearch.acessosMunicipais);

                    this.toastrService.success('Acesso Municipal alterado com sucesso!');
                    this.modalAcessoMunicipal(false);
                    this.editando.acessosMunicipais = null;
                    this.loadings.updateAcessosMunicipal = false;
                },
                (response) => {
                    this.loadings.updateAcessosMunicipal = false;
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosMunicipais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    updateAcessosPrevidenciarios(): void {

        const dados = this.formAcessosPrevidenciarios.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro && this.formAcessosPrevidenciarios.formGroup.valid) {

            this.loadings.updateAcessosPrevidenciarios = true;

            this.editarCredenciaisService.updateAcessosPrevidenciario(
                this.editando.acessosPrevidenciarios, dados).subscribe((response: any) => {
                    this.listarAcessosPrevidenciarios(
                        this.currentsParams.acessosPrevidenciarios, this.currentSearch.acessosPrevidenciarios);

                    this.toastrService.success('Acesso Previdenciário alterado com sucesso!');
                    this.modalAcessoPrevidenciario(false);
                    this.editando.acessosPrevidenciarios = null;
                    this.loadings.updateAcessosPrevidenciarios = false;
                },
                (e) => {
                    this.loadings.updateAcessosPrevidenciarios = false;
                    this.toastrService.error(e.error);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosPrevidenciarios.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    openTabEmbedded(componentName: string, queryParams?: string, data?: any, close: boolean = true, tabName: string = '') {
        const component = findComponentByUrl(componentName);
        const nome = data.nome;
        const url = buildUrl(component, queryParams);
        const cnpj = data.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        const title = cnpj ? cnpj : nome;
        const newTab = new Tab(component.name, title + tabName, url, component.urlType, data);
        if (close) {
            this.tabService.closeAndAddTab(newTab);
        } else {
            this.tabService.addTab(newTab);
        }

    }

    ngOnDestroy(): void {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    onAllCheckedObrigacoes(checked: boolean): void {

        this.obrigacoes.forEach(({id}) => this.updateCheckedSetObrigacoes(id, checked));

    }


    onItemCheckedObrigacoes(id: string, checked: boolean): void {

        this.updateCheckedSetObrigacoes(id, checked);

    }

    updateCheckedSetObrigacoes(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsObrigacoes.add(id);

        } else {

            this.checkedItemsObrigacoes.delete(id);

        }

        this.checkedObrigacoes = this.obrigacoes.length === this.checkedItemsObrigacoes.size;
    }

    showConfirmRemoverObgMassa(html: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) Empresa(s)?',
            nzContent: html,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerObgMassa()
        });
    }

    removerObgMassa() {
        this.toastrService.success('Solicitação realizada.');

        this.loadings.removendoEmpresas = true;

        const ids = Array.from(this.checkedItemsObrigacoes);

        const msg = ids.length > 1 ? 'Obrigações removidas com sucesso!' : 'Obrigação removida com sucesso!';

        this.checkedItemsObrigacoes.clear();
        this.checkedObrigacoes = false;

        this.obrigacoesEmpresaService.removeEmpresas(ids, this.removerTodosAnos).subscribe((response: any) => {

            this.removerTodosAnos = false;
            this.loadings.removendoEmpresas = false;

            this.toastrService.success(msg);

            this.getObrigacoes(this.currentsParams.obrigacoes, this.currentSearch.obrigacoes);

        }, (response) => {
            this.loadings.removendoEmpresas = false;
            this.toastrService.error(response.error.message);

        });

    }

}

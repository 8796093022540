<nz-page-header class="site-page-header" nzTitle="Dashboards" nzSubtitle="Controle de Certificados">
</nz-page-header>

<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col nzXs="24" nzMd="12" nzSm="24">
            <nz-card nzTitle="Certificados" class="custom-card-height"
                     [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                     [nzLoading]="cardCabecalhoLoading">
                <div nz-row style="height: 14.6em;">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                [(update)]="updateFlag"
                                *ngIf="HighchartsPie && chartPieOptions"
                                style="width: 100%; height: 180px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <ul nz-list nzBordered style="margin: 10px; cursor: pointer;">
                            <nz-list-item (click)="!loading && addFiltro('status', 'valido')"
                                          class="ant-card-hoverable"
                                          [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                          [class.activeFiltro]="filtroStatus && filtroStatus == 'valido'"
                                          style="padding: 15px;">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[0]}"></i> Certificado
                                    Válido
                                </h5>
                                <a style="margin-bottom: 0;">{{ tableStatus.valido }}</a>
                            </nz-list-item>
                            <nz-list-item (click)="!loading && addFiltro('status', 'a_vencer')"
                                          class="ant-card-hoverable" style="padding: 15px;"
                                          [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                          [class.activeFiltro]="filtroStatus && filtroStatus == 'a_vencer'">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[1]}"></i> Certificado a
                                    Vencer
                                </h5>
                                <a style="margin-bottom: 0;">{{ tableStatus.a_vencer }}</a>
                            </nz-list-item>
                            <nz-list-item (click)="!loading && addFiltro('status', 'vencido')"
                                          class="ant-card-hoverable" style="padding: 15px;"
                                          [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                          [class.activeFiltro]="filtroStatus && filtroStatus == 'vencido'">
                                <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                    <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[2]}"></i> Certificado
                                    Vencido
                                </h5>
                                <a style="margin-bottom: 0;">{{ tableStatus.vencido }}</a>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col nzXs="24" nzMd="12" nzSm="24">
            <nz-card class="custom-card-height" [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                     nzTitle="Validade de Certificados" [nzLoading]="cardCabecalhoLoading">
                <nz-content style="height: 100%;">
                    <nz-row style="height: 100%;">
                        <nz-col [nzSpan]="24" style="height: 100%;"
                                class="d-flex justify-content-center align-items-center">
                            <highcharts-chart
                                    class="validade-chart"
                                    [Highcharts]="HighchartsBasic"
                                    [options]="chartBasicOptions"
                                    [(update)]="updateFlag"
                                    *ngIf="HighchartsBasic && chartBasicOptions"
                                    style="height: 200px; display: block;"
                                    (resize)="highChart?.reflow()"
                            ></highcharts-chart>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

<nz-content>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '', statusOne ? 'card-fullscreen' : '']"
             [nzLoading]="loading">
        <nz-card-tab>
            <nz-tabset
                    [nzSize]="'large'"
                    [nzTabBarExtraContent]="navBar"
                    [(nzSelectedIndex)]="tabNumber"
                    (nzSelectChange)="changeTabs($event)">
                <nz-tab [nzTitle]="totEmpresas"></nz-tab>
                <nz-tab [nzTitle]="totResponsaveis"></nz-tab>
            </nz-tabset>
        </nz-card-tab>
        <ng-template #totEmpresas>
            <nz-badge [nz-tooltip]="'Quantidade de empresas'"
                      [nzCount]="paginationEmpresas?.total || 0"
                      style="border: none!important; position: absolute; right: -34px; top: 6px;">
            </nz-badge>
            Empresas
        </ng-template>
        <ng-template #totResponsaveis>
            <nz-badge [nz-tooltip]="'Quantidade de responsáveis'"
                      [nzCount]="paginationResponsaveis?.total || 0"
                      style="border: none!important; position: absolute; right: -34px; top: 6px;">
            </nz-badge>
            Responsáveis
        </ng-template>

        <nz-table #tableEmpresas
                  *ngIf="tabNumber === 0"
                  [nzData]="empresas"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginationEmpresas?.total"
                  [nzPageSize]="paginationEmpresas?.per_page"
                  [nzPageIndex]="paginationEmpresas?.current_page"
                  (nzQueryParams)="listByTableEmpresas($event)"
                  [nzFooter]="footer"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <th nzColumnKey="unidade" [nzSortFn]="true">Unidade</th>
                <th nzColumnKey="grupo" [nzSortFn]="true" style="text-align: center;">Grupo</th>
                <th nzColumnKey="emissor" [nzSortFn]="true" style="text-align: center;">Emissor</th>
                <th nzColumnKey="status" [nzSortFn]="true" style="text-align: center;">
                    Status Certificado
                </th>
                <th nzColumnKey="validade" [nzSortFn]="true" style="text-align: center;">Validade</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let empresa of tableEmpresas.data">
                <td nzLeft style="word-break: break-word;">{{ empresa?.unidade }}
                    <br>{{ empresa?.cnpj | cpfCnpj }}
                </td>
                <td nzLeft style="text-align: center;">
                    {{ empresa?.grupo }}
                </td>
                <td nzLeft style="text-align: center;">
                    {{ empresa?.emissor }}
                </td>
                <td style="text-align: center;">
                    <nz-tag [nzColor]="empresa.statusColor">
                        {{ empresa.statusDescricao }}
                    </nz-tag>
                </td>
                <td style="text-align: center;">
                    {{ empresa?.validade | date: 'dd/MM/yyyy HH:mm' }}
                </td>
                <td style="text-align: center;">
                    <div style="width: 100%; text-align: center">
                        <button nz-button nzType="link"
                                (click)="openTab('/cadastros/editar-credenciais-acesso/', empresa.id,
                                {id: empresa.id, apenasCertificado: true})">
                            Editar
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="empresas && empresas.length > 0">{{ paginationEmpresas?.from }}
                    -{{ paginationEmpresas?.to }}
                    de {{ paginationEmpresas?.total }} registros</span>
            </ng-template>
        </nz-table>
        <nz-table #tableResponsaveis
                  *ngIf="tabNumber === 1"
                  [nzData]="responsaveis"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginationResponsaveis?.total"
                  [nzPageSize]="paginationResponsaveis?.per_page"
                  [nzPageIndex]="paginationResponsaveis?.current_page"
                  (nzQueryParams)="listByTableResponsaveis($event)"
                  [nzFooter]="footer"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" style="text-align: center;">Tipo</th>
                <th nzColumnKey="emissor" [nzSortFn]="true" style="text-align: center;">Emissor</th>
                <th nzColumnKey="status" [nzSortFn]="true" style="text-align: center;">
                    Status Certificado
                </th>
                <th nzColumnKey="validade" [nzSortFn]="true" style="text-align: center;">Validade</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let responsavel of tableResponsaveis.data">
                <td nzLeft style="word-break: break-word;">{{ responsavel?.nome }}
                    <br>{{ responsavel?.documento | cpfCnpj }}
                </td>
                <td nzLeft style="text-align: center;">
                    {{ responsavel?.tipo === 'cnpj' ? 'eCNPJ' : 'eCPF' }}
                </td>
                <td nzLeft style="text-align: center;">
                    {{ responsavel?.emissor }}
                </td>
                <td style="text-align: center;">
                    <nz-tag [nzColor]="responsavel.statusColor">
                        {{ responsavel.statusDescricao }}
                    </nz-tag>
                </td>
                <td style="text-align: center;">
                    {{ responsavel?.validade | date: 'dd/MM/yyyy HH:mm' }}
                </td>
                <td style="text-align: center;">
                    <div style="width: 100%; text-align: center">
                        <button nz-button nzType="link"
                                (click)="openTab('/cadastros/editar-responsavel/', responsavel.id,
                                {id: responsavel.id, apenasCertificado: true})">
                            Editar
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="empresas && empresas.length > 0">{{ paginationResponsaveis?.from }}
                    -{{ paginationResponsaveis?.to }}
                    de {{ paginationResponsaveis?.total }} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<ng-template #navBar>
    <div class="d-flex align-items-center">

        <nz-badge [nzCount]="tabNumber === 1 ?qtdFiltrosResponsaveisAtivos : qtdFiltrosEmpresasAtivos"
                  style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button disabled="{{loading}}" nz-button nzType="primary"
                    (click)="abrirModal(tabNumber === 1 ? formFiltrosResponsaveis : formFiltrosEmpresas)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton"
                        style="margin-right: 10px;">
            <input (keydown.enter)="buscar();" type="text" nz-input
                   placeholder="Busca" [(ngModel)]="currentSearch"/>
        </nz-input-group>

        <ng-template #suffixIconButton>
            <button disabled="{{loading}}" nz-button nzType="primary" nzSearch
                    (click)="buscar();"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <button disabled="{{loading}}" nz-button nzType="default" style="margin-right: 10px;"
                (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="formFiltrosEmpresas.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosEmpresas)"
          [nzWidth]="700">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosEmpresas.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.unidade">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="unidade">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.grupo">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="grupo">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Status Certificado</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.status">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="status">
                            <nz-option [nzLabel]="arrayStatus[0]" [nzValue]="'valido'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[1]" [nzValue]="'a_vencer'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[2]" [nzValue]="'vencido'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Vencimento Certificado</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.vencimento">

                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="vencimento">
                            <nz-option [nzLabel]="'Superior a 60 Dias'" [nzValue]="'100'"></nz-option>
                            <nz-option [nzLabel]="'Em 60 dias'" [nzValue]="'60'"></nz-option>
                            <nz-option [nzLabel]="'Em 30 dias'" [nzValue]="'30'"></nz-option>
                            <nz-option [nzLabel]="'Em 15 dias'" [nzValue]="'15'"></nz-option>
                            <nz-option [nzLabel]="'Vencido'" [nzValue]="'vencido'"></nz-option>
                            <nz-option [nzLabel]="'Não Vencido'" [nzValue]="'valido'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosEmpresas)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrosResponsaveis.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosResponsaveis)"
          [nzWidth]="700">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosResponsaveis.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosResponsaveis.formGroup.value.responsavel">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="responsavel">
                            <nz-option *ngFor="let opt of comboResponsaveis"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Tipo</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosResponsaveis.formGroup.value.tipo">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="tipo">
                            <nz-option [nzLabel]="'eCNPJ'" [nzValue]="'cnpj'"></nz-option>
                            <nz-option [nzLabel]="'eCPF'" [nzValue]="'cpf'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Status Certificado</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosResponsaveis.formGroup.value.status">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="status">
                            <nz-option [nzLabel]="arrayStatus[0]" [nzValue]="'valido'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[1]" [nzValue]="'a_vencer'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[2]" [nzValue]="'vencido'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Vencimento Certificado</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosResponsaveis.formGroup.value.vencimento">

                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="vencimento">
                            <nz-option [nzLabel]="'Superior a 60 Dias'" [nzValue]="'100'"></nz-option>
                            <nz-option [nzLabel]="'Em 60 dias'" [nzValue]="'60'"></nz-option>
                            <nz-option [nzLabel]="'Em 30 dias'" [nzValue]="'30'"></nz-option>
                            <nz-option [nzLabel]="'Em 15 dias'" [nzValue]="'15'"></nz-option>
                            <nz-option [nzLabel]="'Vencido'" [nzValue]="'vencido'"></nz-option>
                            <nz-option [nzLabel]="'Não Vencido'" [nzValue]="'valido'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosResponsaveis)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

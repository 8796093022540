import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';
import {NzTableQueryParams} from 'ng-zorro-antd/table';


@Injectable({providedIn: 'root'})
export class ObrigacoesEstaduaisService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    getHistorico(params: NzTableQueryParams): Observable<any> {

        const strParams = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get<any>(`${environment.apiUrl}/drive/obrigacoes/estaduais/historico-armazenamentos${strParams}`);
    }

    retornaAnos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/estaduais/anos`);
    }

    getTamanhoBucket(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/drive/obrigacoes/tamanho-bucket`);
    }

    retornaObrigacoes(ano: number, filtros: any): any {

        filtros.ano = ano;

        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/estaduais/armazenamentos${query}?tipoObrigacao=A`);

    }

    exportExcel(ano: number, filtros): any {

        filtros.ano = ano;
        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/estaduais/armazenamentos/exportar${query}`,
            {responseType: 'blob'});

    }

    exportDocumento(id, tipoArquivo): any {

        let url = `${environment.apiUrl}/drive/obrigacoes/estaduais/download/${id}`;

        url += tipoArquivo === 'recibo' ? '/recibo' : '';

        return this.http.get(url, {responseType: 'blob'});

    }

    retornarSelectsEmpresas(grupoEmpId?: string): Observable<any> {

        const url = grupoEmpId ? '/filtros/empresa/select?grupoEmpresarial=' + grupoEmpId : '/filtros/empresa/select';

        return this.http.get(`${environment.apiUrl}${url}`);
    }

    retornarSelectsGrupoEmpresarial(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarSelectsCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias?orgao=Estadual&tipo=A`);
    }

    retornaCabecalho(ano): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/estaduais/sumarizar/${ano}?tipoObrigacao=A`);
    }

    retornaTiposParaBaixar(ano: number, id: string): any {

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/estaduais/tiposDownload/${ano}?obrigacao_id=${id}`);

    }

    retornaComboObrigacoes(empresaId, grupoEmp?: string, categoria?: string): Observable<any> {

        let url = `${environment.apiUrl}/filtros/obrigacoes/select?`;

        if (empresaId) {
            url = `${environment.apiUrl}/filtros/obrigacoes/select/${empresaId}?`;

        } else if (grupoEmp) {
            url += `grupoEmpresarial=${grupoEmp}&`;
        }

        if (categoria) {
            url += `categoria=${categoria}&`;
        }

        url += `orgao=Estadual&tipo=A`;

        return this.http.get(url);

    }

    solicitarExportar(filtros, tipo, ano): any {

        const data: any = [];

        filtros.forEach((filtro) => {

            data[filtro.key] = filtro.value;
        });

        data.ano = ano;
        data.tipoExportador = tipo;
        data.tipo = 'driveEstaduais';
        data.orgao = 'estaduais';

        const queryStr = Helpers.montaQueryString(data);

        return this.http.post(`${environment.apiUrl}/exportador${queryStr}`, null);

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';


@Injectable({providedIn: 'root'})
export class GruposEmpresariaisService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/administracao/grupos-empresariais', {});
    }

    remover(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/grupo-empresarial/${id}`);

    }

    retornaEmpresasGrupo(id: string): any {


        return this.http.get(`${environment.apiUrl}/administracao/grupo-empresarial/listarEmpresas/${id}`);

    }

    retornaEmpresasTodas(): any {


        return this.http.get(`${environment.apiUrl}/administracao/grupo-empresarial/listarEmpresas`);

    }

    deletarVinculoEmpresa(id: string): any {


        return this.http.delete(`${environment.apiUrl}/administracao/grupo-empresarial/deletarVinculoEmpresa/${id}`);

    }

    salvarEmpresasGrupo(grupo: string, empresas: any[], clear: boolean = true): any {


        return this.http.put(`${environment.apiUrl}/administracao/grupo-empresarial/vincularEmpresasGrupo`,
            {grupo, empresas, clear});

    }

    detalhe(id: any): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/grupo-empresarial/${id}`);

    }

    cadastrar(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/administracao/grupo-empresarial`, dados);

    }

    editar(id, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/grupo-empresarial/${id}`, dados);

    }


}

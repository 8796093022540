<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Credenciais de Acesso">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze" [nzExtra]="navBar" nzTitle="Credenciais de Acesso"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li nz-menu-item [nzDisabled]="checkedItems?.size === 0"
                                    nz-tooltip="true" [nzTooltipTitle]="'Usuário Console'"
                                    *ngIf="usuarioLogado.origem === 'console'"
                                    (click)="checkedItems?.size > 0 && modal(formProcuracoesECAC, true);">
                                    Cadastrar Procurações E-CAC
                                </li>
                                <li nz-menu-item [nzDisabled]="checkedItems?.size === 0 || loadings.acessoEcac"
                                    (click)="checkedItems?.size > 0 && !loadings.acessoEcac && modalAcessoIntegradoEcac();">
                                    Ativar Acesso Integrado E-CAC
                                </li>
                            </ul>
                        </li>

                        <li nz-menu-item (click)="exportarDados()">Exportar Dados</li>
                        <li *ngIf="usuarioLogado.origem === 'console'"
                            nz-menu-item nz-button (click)="modalImportarAE(true);" [nz-tooltip]="'(usuário Console)'">
                            Importar Planilha Acessos Estaduais
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFooter]="footer"
                  style="overflow-x: auto"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <!--<th nzColumnKey="id" [nzSortFn]="true">ID</th>-->
                <th nzColumnKey="codigo" [nzSortFn]="true" style="text-align: center;">Código</th>
                <th nzColumnKey="nome" [nzSortFn]="true" style="text-align: center;">Empresa</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" style="text-align: center;">CNPJ</th>
                <th nzColumnKey="descricao" [nzSortFn]="true" style="text-align: center;">Grupo</th>
                <th nzColumnKey="estado" [nzSortFn]="true" style="text-align: center;">UF</th>
                <!--<th nzColumnKey="tipo" [nzSortFn]="true" style="text-align: center;">Certificado A1</th>-->
                <th nzColumnKey="validade" [nzSortFn]="true" style="text-align: center;">Certificado A1
                    Validade
                </th>
                <th nzColumnKey="quantiade_procuracoes" [nzSortFn]="true" style="text-align: center;">
                    <span>Procurações<br>E-CAC</span>
                </th>
                <th [nzShowSort]="false" style="text-align: center;">
                    <span>Certificado Procuradores</span>
                </th>
                <th nzColumnKey="quantiade_acesso_eCAC" [nzShowSort]="false" style="text-align: center;">
                    Acessos Integrados E-CAC
                </th>
                <th nzColumnKey="quantiade_acessos_estaduais" [nzSortFn]="true" style="text-align: center;">Acessos
                    Estaduais
                </th>
                <th nzColumnKey="quantiade_acessos_dataprev" [nzSortFn]="true" style="text-align: center;">
                    Acessos Previdenciários
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let credenciaisAcesso of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(credenciaisAcesso.id, $event)"
                    [nzChecked]="checkedItems.has(credenciaisAcesso.id)"
                    [nzDisabled]="credenciaisAcesso.disabled">
                </td>
                <!--<td nzLeft>{{credenciaisAcesso?.id}}</td>-->
                <td nzLeft style="text-align: center;">{{modoDemo(credenciaisAcesso?.codigo, 'total')}}</td>
                <td nzLeft>{{modoDemo(credenciaisAcesso?.nome, 'empresaCodigoNome')}}</td>
                <td nzLeft
                    style="white-space: nowrap;text-align: center;">{{modoDemo(credenciaisAcesso?.cnpj | cpfCnpj, 'cnpj')}}</td>
                <td nzLeft
                    style="text-align: center;">{{modoDemo(credenciaisAcesso?.grupoEmpresarial_descricao)}}</td>
                <td nzLeft style="text-align: center;">{{credenciaisAcesso?.estado}}</td>
                <!--<td nzLeft style="text-align: center;">{{credenciaisAcesso?.tipo_descricao}}</td>-->
                <td nzLeft style="text-align: center;">
                    <nz-alert nzType="success" nzMessage="OK"
                              *ngIf="credenciaisAcesso.statusVencimento === 2 && !(credenciaisAcesso.revogado === 1 && !credenciaisAcesso.temCertificadosValidos)"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencimento em {{credenciaisAcesso.dias}} dia(s)"></nz-alert>
                    <nz-alert nzType="warning" nzMessage="Vencendo {{credenciaisAcesso?.validade | date: 'dd/MM/yyyy'}}"
                              *ngIf="credenciaisAcesso.statusVencimento === 1"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencimento em {{credenciaisAcesso.dias}} dia(s)"></nz-alert>
                    <nz-alert nzType="error" nzMessage="Vencido {{credenciaisAcesso?.validade | date: 'dd/MM/yyyy'}}"
                              *ngIf="credenciaisAcesso.statusVencimento === 0"
                              nz-tooltip="true"
                              nzTooltipTitle="Vencido á {{credenciaisAcesso.dias}} dia(s)"></nz-alert>
                    <nz-alert nzType="error" nzMessage="Revogado"
                              *ngIf="credenciaisAcesso.revogado === 1 && !credenciaisAcesso.temCertificadosValidos"></nz-alert>
                    <nz-tag style="padding: 8px 15px; font-size: 14px; width: 100%;"
                            *ngIf="!credenciaisAcesso?.validade && !credenciaisAcesso.usaCertificadoMatriz">Sem
                        certificado
                    </nz-tag>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" nz-tooltip="true"
                              nzTooltipTitle="Esta filial usa certificado da matriz."
                              *ngIf="credenciaisAcesso.usaCertificadoMatriz">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    Certificado Matriz
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                </td>
                <td nzLeft style="text-align: center; min-width: 160px">
                    <span style="word-break: keep-all">{{credenciaisAcesso?.quantiade_procuracoes}} Procurações</span>
                    <br>
                    <span style="word-break: keep-all">{{credenciaisAcesso?.quantiade_procuradores}} Procuradores</span>
                </td>
                <td nzLeft style="text-align: center;">
                    <div *ngIf="credenciaisAcesso.usaCertificadoMatrizProcuracao else certificadoProprio">
                        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" nz-tooltip="true"
                                  nzTooltipTitle="Esta filial utiliza procurador da matriz."
                                  *ngIf="credenciaisAcesso.usaCertificadoMatrizProcuracao">
                            <ng-template #checkedMessage>
                                <div nz-row nzAlign="middle" nzGutter="24">
                                    <div nz-col nzSpan="24">
                                        <strong class="text-primary"></strong>
                                        Procuração Matriz
                                    </div>
                                </div>
                            </ng-template>
                        </nz-alert>

                    </div>

                    <ng-template #certificadoProprio>
                        <nz-alert nzType="success"
                                  [nzMessage]="credenciaisAcesso?.procurador_dataFim ? (credenciaisAcesso?.procurador_dataFim | date: 'dd/MM/yyyy') : 'OK'"
                                  *ngIf="credenciaisAcesso?.procurador_dataInicio && statusVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) === 2 && credenciaisAcesso?.quantiade_procuradores"
                                  nz-tooltip="true"
                                  [nzTooltipTitle]="credenciaisAcesso?.procurador_dataFim ?
                              'Vencimento em ' + diasVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) + ' dia(s)' :
                              'Certificado Válido'">
                        </nz-alert>
                        <nz-alert nzType="warning"
                                  [nzMessage]="(credenciaisAcesso?.procurador_dataFim | date: 'dd/MM/yyyy') + ' Vencendo'"
                                  *ngIf="credenciaisAcesso?.procurador_dataInicio && statusVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) === 1"
                                  nz-tooltip="true"
                                  [nzTooltipTitle]="'Vencimento em ' + diasVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) + ' dia(s)'"></nz-alert>
                        <nz-alert nzType="error"
                                  [nzMessage]="(credenciaisAcesso?.procurador_dataFim | date: 'dd/MM/yyyy') + ' Vencido'"
                                  *ngIf="credenciaisAcesso?.procurador_dataInicio && statusVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) === 0"
                                  nz-tooltip="true"
                                  [nzTooltipTitle]="'Vencido à ' + diasVencimentoProcurador(credenciaisAcesso?.procurador_dataFim) + ' dia(s)'"></nz-alert>
                        <nz-tag style="padding: 8px 15px; font-size: 14px; width: 100%;"
                                *ngIf="(!credenciaisAcesso?.procurador_dataInicio && !credenciaisAcesso.usaCertificadoMatrizProcuracao) ||
                             !credenciaisAcesso?.quantiade_procuradores && !credenciaisAcesso.usaCertificadoMatrizProcuracao">
                            Sem certificado
                        </nz-tag>


                    </ng-template>

                </td>
                <td nzLeft
                    style="text-align: center;">{{credenciaisAcesso.quantidade_acessos_ecac}}</td>
                <td nzLeft style="text-align: center;">{{credenciaisAcesso?.quantiade_acessos_estaduais}} Portal(is)
                </td>
                <td nzLeft
                    style="text-align: center;">{{credenciaisAcesso.quantidade_acessos_dataprev ? 'Sim' : 'Não'}}</td>
                <td nzRight nzAlign="center">
                    <button (click)="openTab('/cadastros/editar-credenciais-acesso/', credenciaisAcesso.id, {id: credenciaisAcesso.id, cnpj: credenciaisAcesso.cnpj})"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Código</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.codigo !== '' && formFiltrar.formGroup.value.codigo !== null">
                    <input nz-input type="text" formControlName="codigo" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{opt.nome}}
                            <br><small>({{opt.cnpj | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="grupo">
                        <nz-option *ngFor="let opt of comboGrupos"
                                   [nzLabel]="modoDemo(opt.label)"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">UF</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.uf">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tem Certificado</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.temCertificado">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="temCertificado">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Certificado A1 Validade</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.validadeInicio">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="validadeInicio" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="1">
                </nz-form-control>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.validadeFim">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="validadeFim" style="width: 100%;" nzPlaceHolder="Até:"
                                    [ngModel]="formFiltrar.formGroup.value.validadeFim"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Empresas'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formProcuracoesECAC.modalVisible"
          [nzTitle]="'Cadastrar Procuração E-CAC'"
          [nzClosable]="true"
          (nzOnCancel)="modal(formProcuracoesECAC, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formProcuracoesECAC.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="pessoa">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'J'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="documento" size="60"
                           [mask]="maskPessoa(formProcuracoesECAC)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="nome" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formProcuracoesECAC, false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="insertProcuracoes();">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportarProcuracoes.modalVisible"
          [nzTitle]="'Exportar Procuracoes'" [nzClosable]="true"
          (nzOnCancel)="modalExportarProcuracoes(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportarProcuracoes.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportarProcuracoes(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportarProcuracoes()">
            Exportar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarPlanilhaAE"
          [nzTitle]="'Importar Planilha Acessos Estaduais'" [nzClosable]="true"
          (nzOnCancel)="modalImportarAE(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
        <small style="">Clique
            <button nz-button nzType="link"
                    [nzLoading]="loadings.downloadModelo"
                    (click)="downloadModelo('AE');"
                    style="padding: 0;">aqui
            </button>
            para baixar um arquivo de modelo.</small>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    [(nzFileList)]="fileListAE" [nzBeforeUpload]="beforeUploadAE">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarAE(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.upload" (click)="confirmarImportarAE()">
            Enviar arquivo
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="termosVisible" nzTitle="Ativar Acesso Integrado E-CAC"
          (nzOnCancel)="modalAcessoIntegradoEcac()">
    <div *nzModalContent>
        <div class="termos-resumidos" *ngIf="!termosCompletosVisible else termosCompletos">
            <h3 style="margin-bottom: 5px">Termos e condições</h3>
            <p>Ao ativar a integração com o eCAC, o contribuinte diretamente
                ou indiretamente, através de um procurador,
                autoriza a Master Intelligence Tax a utilizar os serviços SERPRO para fins de <b>CONSULTA</b>. Caso não
                exista as procurações necessárias, será feita a outorga automaticamente. <br>
                Veja mais detalhes <a (click)="verTermosCompletos()">aqui</a>. </p>
        </div>
        <ng-template #termosCompletos>
            <div class="termos-completos">
                <a (click)="verTermosCompletos()">< Voltar</a>
                <h3 style="margin: 5px 0">Termos e condições</h3>
                <div style="max-height: 400px; overflow-x: auto; margin: 15px 0">
                    <h3>Autorização</h3>
                    <p style="padding-left: 5px">
                        Autorizo a empresa CONTRATANTE, identificada neste termo de autorização como DESTINATÁRIO, a
                        executar as requisições dos serviços web disponibilizados pela API INTEGRA CONTADOR,
                        onde terei o papel de AUTOR PEDIDO DE DADOS no corpo da mensagem enviada na requisição do
                        serviço
                        web. Esse termo de autorização está assinado digitalmente com o certificado digital
                        do PROCURADOR ou OUTORGADO DO CONTRIBUINTE responsável, identificado como AUTOR DO PEDIDO DE
                        DADOS.
                    </p>
                    <h3>Aviso Legal</h3>
                    <p style="padding-left: 5px">
                        O acesso a estas informações foi autorizado pelo próprio PROCURADOR ou OUTORGADO DO
                        CONTRIBUINTE,
                        responsável pela informação, via assinatura digital. É dever do destinatário da
                        autorização e consumidor deste acesso observar a adoção de base legal para o tratamento dos
                        dados
                        recebidos conforme artigos 7º ou 11º da LGPD (Lei n.º 13.709, de 14 de agosto de 2018),
                        aos direitos do titular dos dados (art. 9º, 17 e 18, da LGPD) e aos princípios que norteiam
                        todos os
                        tratamentos de dados no Brasil (art. 6º, da LGPD).
                    </p>
                    <h3>Finalidade</h3>
                    <p style="padding-left: 5px">
                        A finalidade única e exclusiva desse TERMO DE AUTORIZAÇÃO, é garantir que o CONTRATANTE
                        apresente a API
                        INTEGRA CONTADOR esse consentimento do PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                        assinado digitalmente, para que possa realizar as requisições dos serviços web da API INTEGRA
                        CONTADOR
                        em nome do AUTOR PEDIDO DE DADOS (PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                    </p>
                </div>
            </div>
        </ng-template>

        <label nz-checkbox [(ngModel)]="termosAceitos">Aceito</label>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="modalAcessoIntegradoEcac()">
            Cancelar
        </button>
        <button *ngIf="!termosAceitos else btAtivar" [nz-tooltip]="'Aceite os termos para proseguir'" nz-button
                class="bt-disabled">Ativar
        </button>
        <ng-template #btAtivar>
            <button nz-button nzType="primary" [nzLoading]="loadings.acessoEcac"
                    (click)="modalProcurador()">Ativar
            </button>
        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessoEcac.modalVisible"
          [nzTitle]="'Selecione o Procurador'"
          [nzClosable]="true"
          (nzOnCancel)="modalProcurador()"
          [nzWidth]="600">
    <div nz-row *nzModalContent>
        <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="true" [formGroup]="formAcessoEcac.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Procurador</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="procurador" nzShowSearch nzAllowClear>
                        <nz-option *ngFor="let r of responsaveis"
                                   [nzLabel]="r.nome + ' (' + (r.documento | cpfCnpj) + ')'"
                                   [nzValue]="r.id"
                                   nzCustomContent>
                            {{r.nome}}
                            <br><small>({{r.documento | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalProcurador();">Cancelar</button>
        <button [nzLoading]="loadings.acessoEcac" nz-button nzType="primary" (click)="ativarAcessoIntegradoECACLote();">
            Confirmar
        </button>
    </div>
</nz-modal>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../../core/helpers';

@Injectable({providedIn: 'root'})
export class ObrigacaoEmpresaPublicService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/publico/obrigacao-empresa/';
    newBaseUrl = environment.apiUrl + '/publico/obrigacao-empresa/historico/';

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricaoFederal/', InscricaoRegime);
    }

    retornaDados(id: string, identificador: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/publico/obrigacao-empresa/${id}?identificador=${identificador}`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    exportDocumento(id, orgao, identificador): any {

        const url = `${environment.apiUrl}/publico/drive/obrigacoes/${orgao}/download/${id}?identificador=${identificador}`;

        return this.http.get(url, {responseType: 'blob'});

    }

    getComboUsuarios(identificador: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/publico/filtros/usuarios/select?identificador=${identificador}`);
    }

    exportExcel(id: string, filtros: any, search: string = null, tipo: string, identificador: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/publico/obrigacao-empresa/historico/${id}/exportar?identificador=${identificador}&${queryStr}`,
            {responseType: 'blob'});

    }

    listToTablePublic(params, search: string = null, baseUrl = null, identificador: string): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined' && search.length > 0) {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?identificador=' + identificador + '&' + queryParams.join('&') :
            '?identificador=' + identificador + '&';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.get(`${prefix}${queryString}`);

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class CategoriasService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacao/categorias', null);
    }

    retornaSelectObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes`);
    }

    retornaSelectCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);
    }

    adicionarCategoria(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao/categorias/salvar`, data);
    }

    retornaCategoria(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/categorias/editar/${id}`);
    }

    removerCategoria(id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/cadastros/obrigacao/categorias/remover/${id}`);
    }

}

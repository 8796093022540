import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DownloadCenterService extends AbstractService<any> {
    baseUrl = environment.apiUrl + '/check/armazenamentos/';

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornarSelectsObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/aprovacoes/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarCabecalho(obrigacao, competencia): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/aprovacoes/cabecalho/${obrigacao}/${competencia}`);
    }


    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10 && mes.toString().length === 1) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    exportDocumento(id, orgao): any {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/arquivo/${id}/${orgao}`, {responseType: 'blob'});
    }

    downloadArquivo(id): any {

        return this.http.get(`${environment.apiUrl}/exportador/${id}/arquivo`,
            {responseType: 'blob'});

    }

    formataDateBD(valor): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    removerArquivo(id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/exportador/${id}`);
    }

}

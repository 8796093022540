<div class="container-iframe">
    <nz-alert nzCloseable
              *ngIf="showAlertObgBaixa" nzType="error"
              [nzMessage]="message"></nz-alert>
    <iframe id="iframe" #iframe (load)="loadEvent($event)"
            [src]="safeExternalLink" class="iframe-element"
            title="Mastertax Embedded Page"
    ></iframe>
</div>

<ng-template #message style="display: grid; grid-template-columns: 10% auto">
    <div style="display: inline-block; float: left">
        <i style="font-size: xx-large; padding: 13px 20px 0 0" class="fa-solid fa-triangle-exclamation"></i>
    </div>
    <div style="display: inline-block;">
        <span>
            Essa tela será substituída em breve!
        </span>
        <br>
        <span>
            A nova versão estará disponível em
        </span>
        <b> Menu / Drive / Baixa de Obrigações / Baixa Automática </b>
        <br>
        <a (click)="openTab('/drive/baixas-automaticas')">Clique aqui para acessar a nova versão.</a>
    </div>
</ng-template>

import {Component, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {ResponsaveisService} from './responsaveis.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {CpfCnpjPipe} from '../../../shared/pipes/cpfCnpj.pipe';
import {Helpers} from '../../../core/helpers';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-credenciais-acesso',
    templateUrl: './responsaveis.component.html',
    styleUrls: ['./responsaveis.component.scss']
})

export class CadastroResponsaveisComponent extends AbstractListTable<any> implements TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    checked = false;

    modalCadastrarVisible = false;
    formCadastrar: FormStack;
    cadastrando = false;

    modalFiltrarVisible = false;

    formFiltrar: FormStack;

    comboUFs: any = [];
    comboGrupos: any = [];
    arraySelectCnpj = [];
    arraySelectEmpresa = [];

    credenciais: any[] = [];

    qtdFiltrosAtivos = 0;

    loadings = {
        gravando: false,
        deletando: {}
    };

    maskDoc = '000.000.000-00';
    maskDocFilter = '000.000.000-00';

    tipoSelecionado = 'cpf';

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private responsaveisService: ResponsaveisService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(responsaveisService, {}, toastService);


        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                documento: [null, null],
                nome: [null, null],
                emissor: [null, null],
                validadeInicio: [null, null],
                validadeFim: [null, null],
                dataprev: [null, null]
            })
        };

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                nome: [null, Validators.required],
                documento: [null, Validators.required],
                tipo: ['cpf', null],
            })
        };

        this.dataExport = {
            url: '/cadastros/responsaveis/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Responsáveis',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };
    }

    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);

        const documento = this.modoDemo(CpfCnpjPipe.prototype.transform(data.documento), data.tipo);
        const newTab = new Tab(component.name, documento + ' - ' + component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    verificaMascaraDoc(event: KeyboardEvent, tipo: string) {
        if (event.key !== 'Backspace' && event.key !== 'Enter' && tipo === 'filtrar') {
            this.maskDocFilter = this.formFiltrar.formGroup.get('documento')?.value?.length >= 11 ? '00.000.000/0000-00' : '000.000.000-00';
        }
    }

    verificaMascaraDocBack(event: KeyboardEvent, tipo: string) {
        if (event.key === 'Backspace' && tipo === 'filtrar') {
            this.maskDocFilter = this.formFiltrar.formGroup.get('documento')?.value?.length <= 11 ? '000.000.000-00' : '00.000.000/0000-00';
        }
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.loading = true;
        this.abstractService.listToTable(params, search, this.responsaveisService.baseUrl).subscribe((response) => {


            this.items = [];
            this.items = response?.data || response;
            this.items.forEach(value => {

                if (value.validade) {

                    value.dias = this.retornaDias(value.validade, new Date());

                    if (value.dias > 30) {
                        value.statusVencimento = 2;
                    }

                    if (value.dias > 0 && value.dias < 31) {
                        value.statusVencimento = 1;
                    }

                    if (value.dias < 0) {
                        value.statusVencimento = 0;
                        value.dias = Math.abs(value.dias);
                    }

                }


                switch (value.tipo) {
                    case 'cnpj': {
                        value.tipo_descricao = 'eCNPJ';
                        break;
                    }
                    case 'nfe': {
                        value.tipo_descricao = 'eNFE';
                        break;
                    }
                    case 'cpf': {
                        value.tipo_descricao = 'eCPF';
                        break;
                    }
                }
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    modalCadastrar(visible) {
        this.formCadastrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {

                if (chave === 'validadeInicio' || chave === 'validadeFim') {
                    valor = Helpers.formatDateDb(valor.toString());
                }
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.tipoSelecionado = 'cpf';

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    confirmaCadastrar(): void {

        this.loadings.gravando = true;

        if (this.formCadastrar.formGroup.valid) {

            const tipo = this.formCadastrar.formGroup.get('documento').value?.length === 11 ? 'cpf' : 'cnpj';

            this.formCadastrar.formGroup.get('tipo').setValue(tipo);

            this.responsaveisService.cadastrar(this.formCadastrar.formGroup.value).subscribe((res) => {

                this.loadings.gravando = false;
                this.modalCadastrar(false);
                this.toastService.success('Responsável cadastrado com sucesso');
                this.queryTable(this.currentParams, this.currentSearch);

                this.formCadastrar.formGroup.reset();

                this.tipoSelecionado = 'cpf';

                this.formCadastrar.formGroup.get('tipo').setValue('cpf');

            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.gravando = false;
            });


        } else {
            for (const key in this.formCadastrar.formGroup.controls) {

                if (key) {

                    const campo = this.formCadastrar.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
            this.loadings.gravando = false;
        }

    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    changeTipoSelecionado() {
        this.tipoSelecionado = this.formCadastrar.formGroup.get('tipo').value;

        this.maskDoc = this.tipoSelecionado === 'cpf' ? '000.000.000-00' : '00.000.000/0000-00';

        setTimeout(() => {
            if (!this.loadings.gravando) {
                this.formCadastrar.formGroup.get('documento').reset();
            }
        }, 10);

    }

    showConfirm(id: string): void {
        this.modalService.confirm({
            nzTitle: 'Deseja remover o responsável?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(id)
        });
    }

    remover(id: string): void {
        this.loadings.deletando[id] = true;
        this.responsaveisService.removerResponsavel(id).subscribe(
            (response) => {
                this.toastrService.success('Responsável removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loadings.deletando[id] = false;
            },
            (response) => {
                this.loadings.deletando[id] = false;
                this.toastrService.error(response.error.message);
            }
        );
    }

    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.componentExport.visible = visible;
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    retornaDias(dataOne, dataTwo) {
        dataOne = new Date(dataOne);
        const difference = dataOne.getTime() - dataTwo.getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
    }
}

<div class="div-main">

    <input [(ngModel)]="pesquisa" nz-input placeholder="Selecione. Busque pelo nome em inglês"
           (keyup)="this.search()">

    <div class="div-icons">
        <ul>
            <li *ngFor="let icon of iconesFiltrados">
                <fa-icon [ngClass]="[icon.iconName === iconSelectedName && iconSelectedType === icon.prefix ?
                 'selected' : '']"
                         (click)="selectIcon(icon)"
                         title="{{icon.iconName}}" [icon]="icon"></fa-icon>
            </li>
        </ul>
    </div>
</div>

<ng-template #inputClear>
    <span *ngIf="pesquisa"
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          (click)="clear()"
    ></span>
</ng-template>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ConciliacoesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornaCompetencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/flow/obrigacoes/competencias`);

    }

    retornaobrigacoes(mes: string, ano: string, filtros: any): Observable<any> {

        let queryStr = Helpers.montaQueryString(filtros, ['cnpj']);
        queryStr = queryStr.replace('?', '');

        return this.http.get(`${environment.apiUrl}/flow/obrigacoes/${ano}/${mes}?obrigacaoFiltro=validacaoConciliacao&${queryStr}`);

    }

    retornaComboObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);

    }

    retornaComboCategorias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);

    }

}

<div [ngClass]="!currentUser? 'overflow' : ''">
    <nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Detalhes Obrigação-Empresa">
        <nz-page-header-extra>
            <nz-button-group>
                <button nz-button nzType="primary" [nzGhost]="visualizacao !== 'table'"
                        (click)="changeVisualizacao('table')"
                        style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;">
                    <i class="fa-solid fa-table" style="margin-right: 5px"></i>
                    Tabela
                </button>
                <button nz-button nzType="primary" [nzGhost]="visualizacao !== 'timeline'"
                        (click)="changeVisualizacao('timeline')"
                        style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">
                    <i class="fa-solid fa-timeline" style="margin-right: 5px"></i>
                    Timeline
                </button>
            </nz-button-group>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-content>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style=" margin: 15px !important;">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        Atenção: Os arquivos ficam disponíveis para download por até 90 dias. Após esse período eles
                        serão excluídos automaticamente.
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <div nz-row [nzGutter]="24" *ngIf="visualizacao == 'table'">
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">

                    <div nz-col [nzXs]="24" [nzSm]="13" [nzMd]="12" [nzXl]="8">
                        <nz-card [nzLoading]="loadings.cabecalho">
                            <div nz-row>
                                <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                                    <div>
                                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                            {{ modoDemo(obrigacaoEmpresa?.empresaNome, 'empresaCodigoNome') }}
                                        </h4>
                                        <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                            {{ modoDemo(obrigacaoEmpresa?.empresaCnpj | cpfCnpj, 'cnpj') }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                    <div nz-col [nzXs]="24" [nzSm]="11" [nzMd]="10" [nzXl]="6">
                        <nz-card [nzLoading]="loadings.cabecalho">
                            <div nz-row>
                                <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                                    <div>
                                        <h1 nz-typography class="custom-card-value text-nowrap"
                                            style="margin: 0; font-size: 28px; margin-bottom: 10px;">
                                            {{ obrigacaoEmpresa?.obrigacaoDescricao }}
                                        </h1>
                                        <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                            {{ obrigacaoEmpresa?.obrigacaoOrgao }}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </nz-card>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="6" [nzXl]="4">
                        <nz-card [nzLoading]="loadings.cabecalho">
                            <div nz-row>
                                <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                                    <div>
                                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                            Competência:
                                        </h4>
                                        <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                            {{ abrevMes(obrigacaoEmpresa?.competencia) }}<span
                                                style="font-weight: 400;">/</span>{{ abrevAno(obrigacaoEmpresa?.competencia) }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
                        <nz-card [nzLoading]="loadings.cabecalho">
                            <div nz-row>
                                <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                                    <div>
                                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                            Vencimento:
                                        </h4>
                                        <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                            {{ obrigacaoEmpresa?.vencimento | date:'dd/MM/YYYY' }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </div>
            <div nz-col [nzSpan]="24">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="24">
                        <nz-card [nzTitle]="'Histórico da Obrigação'" [nzExtra]="navBar"
                                 [nzLoading]="loadings.table"
                                 [nzBodyStyle]="loadings.table ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                                 [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
                            <ng-template #confirm let-ref="modalRef">
                                <div style="display: flex; justify-content: center; width: 100%">
                                    <button style="margin-right: 10px" nz-button nzType="primary" [nzGhost]="true"
                                            (click)="destroyTplModal(ref, 1)">Anexos
                                    </button>
                                    <button nz-button nzType="primary" [nzGhost]="true"
                                            (click)="destroyTplModal(ref, 2)">Observação
                                    </button>
                                </div>

                                <!--                                <div style="display: flex; justify-content: center; width: 100%">-->

                                <!--                                    <nz-button-group>-->
                                <!--                                        <button nz-button nzType="primary" [nzGhost]="true"-->
                                <!--                                                (click)="destroyTplModal(ref, 1)"-->
                                <!--                                                style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;">-->
                                <!--                                            Anexos-->
                                <!--                                        </button>-->
                                <!--                                        <button nz-button nzType="primary" [nzGhost]="true"-->
                                <!--                                                (click)="destroyTplModal(ref, 2)"-->
                                <!--                                                style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">-->
                                <!--                                            Observação-->
                                <!--                                        </button>-->

                                <!--                                    </nz-button-group>-->

                                <!--                                </div>-->
                            </ng-template>
                            <ng-template #tplFooter let-ref="modalRef">
                                <button nz-button (click)="ref.destroy()">Cancelar</button>
                            </ng-template>
                            <ng-template #navBar>
                                <div class="d-flex" *ngIf="visualizacao == 'table'">
                                    <button *ngIf="currentUser" class="new-markup-bnt" nz-button nzType="link"
                                            (click)="confirmAdicionar(confirm, tplFooter)">
                                        ADICIONAR
                                    </button>
                                    <nz-badge [nzCount]="qtdFiltrosAtivos"
                                              [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                                        <button nz-button nzType="primary" style="margin-right: 10px;"
                                                (click)="modalFiltrar(true);">
                                            <i nz-icon nzType="search"></i>
                                            Filtros
                                        </button>
                                    </nz-badge>
                                    <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                                            [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                                        Opções
                                        <em nz-icon nzType="down"></em>
                                    </button>
                                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                                        <ul nz-menu>
                                            <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar
                                                Planilha
                                            </li>
                                            <li *ngIf="currentUser.origem === 'console'" nz-submenu nzTitle="Console">
                                                <ul>
                                                    <li [nzDisabled]="!comboGatilhos.length" nz-menu-item
                                                        (click)="comboGatilhos.length && showModalGatilhos(true)">
                                                        Gatilhos
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nz-dropdown-menu>
                                    <button nz-button nzType="default" style="margin-right: 10px;"
                                            (click)="btnResetSearch();">
                                        Limpar
                                    </button>
                                    <button nz-button nzType="default"
                                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                            (click)="queryTableHistorico(currentParams, currentSearch)"
                                            title="Atualizar">
                                        <i nz-icon nzType="reload"></i>
                                    </button>
                                    <button nz-button [nzType]="'default'" (click)="clickEvent()"
                                            style="padding-left: 8px; padding-right: 8px;">
                                        <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                                    </button>
                                </div>
                            </ng-template>
                            <nz-table #historicoTable
                                      nzSize="small"
                                      [nzData]="arrayDados"
                                      [nzFrontPagination]="false"
                                      [nzLoading]="loadings.table"
                                      [nzTotal]="pagination?.total"
                                      [nzPageSize]="pagination?.per_page"
                                      [nzPageIndex]="pagination?.current_page" class="fonte-doze table-small"
                                      (nzQueryParams)="queryTableHistorico($event, currentSearch)"
                            >
                                <thead>
                                <tr>
                                    <th nzColumnKey="dataHoraEvento" [nzSortFn]="true">Data/Hora do evento</th>
                                    <th nzColumnKey="evento" [nzSortFn]="true" nzAlign="center">Evento</th>
                                    <th nzColumnKey="acaoEvento" [nzSortFn]="true" nzAlign="center">Ação</th>
                                    <th nzColumnKey="usuario_nome" [nzSortFn]="true" nzAlign="center">Usuário</th>
                                    <th nzAlign="center">Observações/Anexo</th>
                                    <th nzAlign="center" *ngIf="currentUser.origem === 'console'">Re-disparar</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of historicoTable.data">
                                    <td>{{ data?.dataHoraEvento | date: 'dd/MM/YYYY HH:mm:ss' }}</td>
                                    <td nzAlign="center">{{ data?.evento }}</td>
                                    <td nzAlign="center">{{ data?.acaoEvento }}</td>
                                    <td nzAlign="center">{{ modoDemo(data?.usuario_nome ? data?.usuario_nome : 'Sistema') }}</td>
                                    <td nzAlign="center">
                                        <div *ngIf="data.evento == 'Armazenamento'">
                                            <a nz-button nzType="link"
                                               *ngIf="data?.driveArquivo_id && data?.arquivoNome"
                                               [nzLoading]="loadings.downloads[data.id]"
                                               (click)="exportDocumento(data.id, data?.arquivoNome, data?.driveArquivo_id);">
                                                <i nz-icon nzType="download" nzTheme="outline"></i>
                                            </a>
                                        </div>
                                        <div *ngIf="data?.quantidadeAnexos > 0">
                                            <nz-badge [nzCount]="data?.quantidadeAnexos || 0" nzShowZero
                                                      *ngIf="data?.aprovacaoStatus !== null"
                                                      [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }">
                                                <button nz-button nzType="link"
                                                        (click)="modalAnexos(true, data.anexos)">
                                                    <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                                                </button>
                                            </nz-badge>
                                        </div>
                                        <div *ngIf="data?.obrigacaoEmpresaObservacao_id">
                                            <a nz-button nzType="link"
                                               nz-tooltip="true"
                                               nzTooltipTitle="Editar"
                                               (click)="modalObservacao(true, data.obrigacaoEmpresaObservacao_id, data.observacao)">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td nzAlign="center" *ngIf="currentUser.origem === 'console'">
                                        <a *ngIf="data.gatilho_evento && data.gatilho_acao" nz-button nzType="link"
                                           [nzLoading]="loadings.disparandoGatilho[data.id]"
                                           [nz-tooltip]="'Re-disparar gatitlho (user-console)'"
                                           (click)="redispararGatilho(data)">
                                            <i class="fa-fw far fa-paper-plane"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </nz-table>
                        </nz-card>
                    </div>
                </div>
            </div>
        </div>

        <div nz-row [nzGutter]="24" *ngIf="visualizacao == 'timeline'">
            <div nz-col [nzSpan]="8">
                <nz-affix>
                    <nz-card class="ant-card-meta-reduce" [nzLoading]="loadings.cabecalho"
                             [nzBodyStyle]="loadings.cabecalho ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                        <nz-card-meta [nzDescription]="'ID: ' + obrigacaoEmpresa?.id"></nz-card-meta>
                        <nz-divider style="margin-bottom: 0"></nz-divider>
                        <nz-list style="margin-top: 0">
                            <nz-list-item style="margin-top: 0">
                                <nz-list-item-meta [nzDescription]="obrigacaoEmpresa?.empresaNome">
                                    <nz-list-item-meta-title>
                                        {{ obrigacaoEmpresa?.empresaCnpj | cpfCnpj }}
                                    </nz-list-item-meta-title>
                                </nz-list-item-meta>
                            </nz-list-item>
                            <nz-list-item>
                                <nz-list-item-meta [nzDescription]="obrigacaoEmpresa?.obrigacaoOrgao">
                                    <nz-list-item-meta-title>
                                        {{ obrigacaoEmpresa?.obrigacaoDescricao }}
                                    </nz-list-item-meta-title>
                                </nz-list-item-meta>
                            </nz-list-item>

                            <nz-list-item>
                                <nz-list-item-meta nzDescription="Competência">
                                    <nz-list-item-meta-title>
                                        {{ retornoMesDescricao(obrigacaoEmpresa?.competencia) }}
                                        <span style="font-weight: 400;">/</span>
                                        {{ abrevAno(obrigacaoEmpresa?.competencia) }}
                                    </nz-list-item-meta-title>
                                </nz-list-item-meta>
                            </nz-list-item>
                            <nz-list-item>
                                <nz-list-item-meta nzDescription="Vencimento">
                                    <nz-list-item-meta-title>
                                        {{ obrigacaoEmpresa?.vencimento | date:'dd/MM/YYYY' }}
                                    </nz-list-item-meta-title>
                                </nz-list-item-meta>
                            </nz-list-item>
                        </nz-list>
                    </nz-card>
                </nz-affix>
            </div>
            <div nz-col [nzSpan]="16">
                <nz-card class="ant-card-table" [nzTitle]="'Histórico da Obrigação'" [nzExtra]="navBarTimeline"
                         [nzLoading]="loadings.timeline"
                         [nzBodyStyle]="loadings.timeline ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
                    <ng-template #navBarTimeline>
                        <div class="d-flex">
                            <button nz-button nzType="default"
                                    style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                    (click)="alterReserve()" title="Atualizar">
                            <span nz-icon [nzType]="reverse ? 'arrow-down' : 'arrow-up'" nzTheme="outline"
                                  title="Alterar ordem"></span>
                            </button>
                            <button nz-button nzType="default"
                                    style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                    (click)="queryTableSemFiltros()" title="Atualizar">
                                <i nz-icon nzType="reload"></i>
                            </button>
                            <button nz-button [nzType]="'default'" (click)="clickEvent()"
                                    style="padding-left: 8px; padding-right: 8px;">
                                <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                            </button>
                        </div>
                    </ng-template>
                    <div style="padding-top: 50px;">
                        <nz-timeline nzMode="alternate" *ngIf="visualizacao === 'timeline'" [nzReverse]="reverse">

                            <nz-timeline-item *ngFor="let data of arrayDadosTimeline"
                                              class="item-timeline">
                                <p nz-typography><strong>{{ data.evento }}</strong></p>
                                <p nz-typography>{{ data?.dataHoraEvento | date: 'dd/MM/YYYY HH:mm:ss' }}</p>
                                <p><i class="fa-solid fa-check"></i> {{ data.acaoEvento }}</p>
                                <p>
                                    <i class="fa-solid fa-user"></i>{{ data?.usuario_nome ? data?.usuario_nome : 'Sistema' }}
                                </p>
                                <div style="padding-top: 4px; margin-top: 4px; margin-left: -4px"
                                     *ngIf="data?.quantidadeAnexos > 0">
                                    <nz-badge [nzCount]="data?.quantidadeAnexos || 0" nzShowZero
                                              *ngIf="data?.aprovacaoStatus !== null"
                                              [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }">
                                        <button nz-button nzType="link" (click)="modalAnexos(true, data.anexos)">
                                            <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                                        </button>
                                    </nz-badge>
                                </div>
                                <p nz-typography>
                                    <a nz-button nzType="link" style="padding: 0; text-align: left;"
                                       *ngIf="data?.driveArquivo_id && data?.arquivoNome"
                                       [nzLoading]="loadings.downloads[data.id]"
                                       (click)="exportDocumento(data.id, data?.arquivoNome, data?.driveArquivo_id);">
                                        <i nz-icon nzType="download" nzTheme="outline"></i> Arquivo
                                    </a>
                                </p>
                            </nz-timeline-item>

                        </nz-timeline>
                    </div>


                </nz-card>
            </div>
        </div>
    </nz-content>
</div>

<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltros.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Evento</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.evento">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="evento">
                        <nz-option [nzLabel]="'Armazenamento'" [nzValue]="'Armazenamento'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Usuário</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltros.formGroup.value.usuario_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="usuario_id">
                        <nz-option *ngFor="let option of comboUsuarios"
                                   [nzLabel]="option.nome"
                                   [nzValue]="option.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control nzErrorTip="Informe o tipo" [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaExportar()" [nzLoading]="loadings.exportExcel">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAdicionarAnexosVisible"
          [nzTitle]="'Adicionar anexos no histórico da obrigação'" [nzClosable]="true"
          (nzOnCancel)="modalAdicionarAnexo(false)"
          [nzWidth]="648">
    <div *nzModalContent>

        <div>
            <p style="margin: 0px;">Selecione o(s) arquivo(s) para anexo.</p>

            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzMultiple]="true"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAdicionarAnexo(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.uploadinAnexo" (click)="confirmaAdicionaAnexos()">
            Adicionar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalVerAnexosVisible"
          [nzTitle]="'Anexos da solicitação de aprovação'" [nzClosable]="true"
          (nzOnCancel)="modalAnexos(false)"
          [nzWidth]="550"
          [nzBodyStyle]="{'padding': '0 5px'}">
    <div *nzModalContent>

        <app-anexos-list (removeuAnexo)="queryTableHistorico(currentParams, currentSearch)" [btExcluir]="true"
                         [anexosList]="listOfAnexos"></app-anexos-list>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="modalAnexos(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal
        [nzTitle]="!observacaoSelecionadaId ? 'Adicionar observação no histórico da obrigação' : 'Editar observação do histórico da obrigação'"
        [(nzVisible)]="modalObservacaoVisible"
        (nzOnCancel)="modalObservacao(false)"
        [nzWidth]="648">
    <div *nzModalContent>
        <textarea rows="4" nz-input [(ngModel)]="observacaoValue" placeholder="Digite aqui..."></textarea>
    </div>
    <div *nzModalFooter>
        <a [nz-tooltip]="'Remover observação'" style="margin-right: -15px" nz-button nzType="text" nzDanger
           *ngIf="observacaoSelecionadaId" (click)="removerObservacao()">
            <i class="far fa-trash-alt"></i>
        </a>
        <button nz-button nzType="link"
                (click)="modalObservacao(false)">{{ observacaoSelecionadaId ? 'Fechar' : 'Cancelar' }}
        </button>
        <button [nzLoading]="loadings.observacao" nz-button nzType="primary"
                (click)="setObservacao()">{{ observacaoSelecionadaId ? 'Gravar' : 'Adicionar' }}
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formGatilhos.modalVisible"
          [nzTitle]="'Gatilhos'" [nzClosable]="true"
          (nzOnCancel)="showModalGatilhos(false)"
          [nzWidth]="620">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formGatilhos.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label style="font-weight: bold" [nzSpan]="24">Arquivo em uso:</nz-form-label>
                <nz-form-control [nzSpan]="24" class="info-modal">
                    <div>
                        <span>MD5:</span>
                        <span class="valor">
                            {{obrigacaoEmpresa.md5}}
                        </span>
                    </div>
                    <div>
                        <span style="margin-right: 2px">Data:</span>
                        <span class="valor">{{obrigacaoEmpresa.arquivoData | date: 'dd/MM/YYYY'}}</span>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">Selecione o gatilho</nz-form-label>
                <nz-form-control nzErrorTip="Gatilho Disponíveis" [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="evento" style="width: 100%;">
                        <nz-option *ngFor="let opt of comboGatilhos" [nzLabel]="opt.gatilho"
                                   [nzValue]="opt.evento"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalGatilhos(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="dispararGatilho()" [nzLoading]="loadings.disparandoGatilho">Disparar
            Gatilho
        </button>
    </div>



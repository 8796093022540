<nz-page-header class="site-page-header" nzTitle="Obrigações Estaduais"
                nzSubtitle="Drive">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ collapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>

<nz-affix [nzOffsetTop]="0" [nzTarget]="target"></nz-affix>

<div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" style="overflow: hidden;">
        <swiper
                [(index)]="cardCompetenciaAtivo"
                (click)="selecionaCardCompetencia($event)"
                slidesPerView="auto"
                [spaceBetween]="30"
                [centeredSlides]="true"
                [mousewheel]="false"
                [scrollbar]="false"
                [navigation]="true"
        >
            <ng-template *ngFor="let item of arrayAnos; index as i" swiperSlide let-data
                         class="swiper-card-competencia">
                <div class="card-competencia ant-card pull-up card-{{i}}"
                     [ngClass]="collapseCard && 'compress'">
                    <div class="ant-card-head">
                        <h2>{{ item.ano }}</h2>
                    </div>
                    <div class="text-center ant-card-body card-{{i}}">
                        <div nz-row style="margin-bottom: 15px;">
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{ item.unidades }}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Unidades
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center;">
                                <span nz-typography nzType="secondary">Acessórias</span>
                                <nz-row>
                                    <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;">{{ item.acessoriasOficiais }} Oficiais</span>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;">{{ item.acessoriasNaoOficiais }}
                                            Não Oficiais</span>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
</div>
<nz-content>
    <nz-row nzGutter="24">
        <nz-col nzSpan="15">
            <nz-card nzTitle="{{anoSelecionado}}" [nzExtra]="cardExtra"
                     [nzLoading]="loadingCardCabecalho"
                     [nzBodyStyle]="loadingCardCabecalho ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
                     class="obrigacoesEstaduais card-scroll card-compress"
                     #cardHeightTarget>

                <nz-card *ngFor="let categoria of arrayCabecalho" [nzTitle]="tituloMenor"
                         [nzExtra]="cardExtraSub"
                         [nzSize]="'small'"
                         [nzLoading]="loadingCardCabecalho"
                         [nzBodyStyle]=" {'margin': '0','padding':'0px','overflow':'hidden', 'background-color': '#fafafa'}"
                         class="sub-card card-compress">

                    <ng-template #tituloMenor>
                        <span style="font-size: small;">
                            {{ categoria.categoria | uppercase }}
                        </span>
                    </ng-template>

                    <div style="justify-content: start; flex-wrap: wrap"
                         [ngStyle]="expandidos[categoria.categoria] ? {display: 'flex'} : {display: 'none'}">
                        <div *ngFor="let item of categoria.itens"
                             [ngClass]="'card'"
                             class="{{item.tamanho ? item.obrigacao_id === formFiltrarObrigacoes.formGroup.value.obrigacao_id ?
                             'enable selected' : 'enable' : 'disabled'}}">
                            <nz-content>
                                <nz-row *ngIf="item.tamanho else cardDisabled"
                                        (click)=" filtrarObrigacoesCard(item)">

                                    <div nz-col [nzSpan]="12">
                                        <h4 class="custom-card-title"
                                            style="line-height: 1; color: #3d3d3d; font-weight: bold; margin-bottom: 20px;">
                                            {{ item?.obrigacao }}
                                        </h4>
                                        <div class="text-muted">Oficiais</div>
                                        <div class="text-muted">Não Oficiais</div>
                                    </div>
                                    <div nz-col [nzSpan]="12" style="text-align: right;">
                                        <h4 class="custom-card-title"
                                            style="line-height: 1; color: #3d3d3d; margin-bottom: 20px;">
                                            {{ formatBytes((item.tamanho ? item.tamanho : 0), 2) }}
                                        </h4>
                                        <div class="text-muted">{{ item.qtdOficial ? (item.qtdOficial | number: '1.0-0') : '0' }}</div>
                                        <div class="text-muted">{{ item.qtdNaoOficial ? (item.qtdNaoOficial  | number: '1.0-0') : '0' }}</div>
                                    </div>
                                </nz-row>

                                <ng-template #cardDisabled>
                                    <nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <h4 class="custom-card-title"
                                                style="line-height: 1; color: #3d3d3d; font-weight: bold; margin-bottom: 20px;">
                                                {{ item?.obrigacao }}
                                            </h4>
                                            <div class="text-muted">Oficiais</div>
                                            <div class="text-muted">Não Oficiais</div>
                                        </div>
                                        <div nz-col [nzSpan]="12" style="text-align: right;">
                                            <h4 class="custom-card-title"
                                                style="line-height: 1; color: #3d3d3d; margin-bottom: 20px;">
                                                {{ formatBytes((item.tamanho ? item.tamanho : 0), 2) }}
                                            </h4>
                                            <div class="text-muted">{{ item.qtdOficial ? (item.qtdOficial  | number: '1.0-0') : '0' }}</div>
                                            <div class="text-muted">{{ item.qtdNaoOficial ? (item.qtdNaoOficial  | number: '1.0-0') : '0' }}</div>
                                        </div>
                                    </nz-row>
                                </ng-template>
                            </nz-content>
                        </div>
                    </div>

                    <ng-template #cardExtraSub>
                        <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)"
                           (click)="expandidos[categoria.categoria] = !expandidos[categoria.categoria]"
                           [nzSize]="'small'">
                            <i style="font-size: small;"
                               [ngClass]="expandidos[categoria.categoria] ? ['fa fa-minus'] : ['fa fa-plus']"></i>
                        </a>
                    </ng-template>
                </nz-card>
            </nz-card>
        </nz-col>
        <nz-col nzSpan="9">
            <nz-card [nzExtra]="cardExtraEmpresas"
                     nzTitle="Empresas"
                     [nzLoading]="loadingCardCabecalho"
                     [ngClass]="[!loadingCardCabecalho ? 'ant-card-table' : '']"
                     class="obrigacoesEstaduais card-chart card-compress" #cardHeight>
                <div class="d-flex justify-content-between" style="padding: 15px 15px 0 15px;">
                    <div>
                        <nz-badge nzColor="#1890ff" nzText="Estaduais" style="margin-right: 20px;"></nz-badge>
                        <nz-badge nzColor="#3d3d3d" nzText="Federais"></nz-badge>
                    </div>
                    <h5 nz-typography style="margin-bottom: 0; text-align: center;">
                        {{ formatBytes(obrigacoesSize, 2) }}
                        de
                        {{ serverSize + ' GB' }}
                    </h5>
                </div>
                <div style="padding: 0 15px 15px 15px;">
                    <nz-progress class="server-size" [nzPercent]="obrigacoesFederaisPercentage"
                                 [nzSuccessPercent]="obrigacoesEstaduaisPercentage"
                                 [nzShowInfo]="false"></nz-progress>
                </div>
                <div>
                    <div id="containerEstaduais" style="width:100%; display:inline-block;"></div>
                </div>
                <div>
                    <ul class="two-column-list">
                        <li *ngFor="let item of arrayChart">
                            <div class="d-flex justify-content-between" style="padding: 0px 10px;">
                                <span class="text-mu">{{ item.obrigacao_id }}</span>
                                <span>{{ formatBytes(item.tamanho, 2) }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>
<div id="cardTabsTwo" #cardTabsTwo *ngIf="showPainel">
    <nz-card [ngClass]="[status ? 'card-fullscreen' : '', !cardTabsLoading ? 'ant-card-table' : '']"
             [nzLoading]="cardTabsLoading">
        <nz-card-tab>
            <nz-tabset
                    [nzSize]="'large'"
                    [nzTabBarExtraContent]="tabsExtra"
                    [(nzSelectedIndex)]="tabNumber"
                    (nzSelectChange)="changeTabs($event)">
                <nz-tab nzTitle="Empresas"></nz-tab>
                <nz-tab nzTitle="Obrigações"></nz-tab>
            </nz-tabset>
        </nz-card-tab>
        <nz-table #tableTotais
                  [nzData]="arrayTableTotais"
                  [nzFrontPagination]="false"
                  *ngIf="tabNumber == 0 && anoSelecionado"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="queryTable($event)"
                  class="scrollbar table-small">
            <thead>
            <tr>
                <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="center">Unidade</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" nzAlign="center">CNPJ</th>
                <th nzColumnKey="qtdOficiais" [nzSortFn]="true" nzAlign="center">Oficiais</th>
                <th nzColumnKey="qtdNaoOficiais" [nzSortFn]="true" nzAlign="center">Não Oficiais</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of tableTotais.data">
                <td>
                    <span style="display:block; min-width: 200px;">{{ data?.nome }}</span>
                </td>
                <td nzAlign="center">{{ data?.cnpj | cpfCnpj }}</td>
                <td nzAlign="center">
                    <a nz-button nzType="link"
                       (click)="filtrarObrigacoes('Oficial', data.id)">{{ data.qtdOficiais ? (data.qtdOficiais | number: '1.0-0') : '-' }}</a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link"
                       (click)="filtrarObrigacoes('NaoOficial', data.id)">{{ data.qtdNaoOficiais ? (data.qtdNaoOficiais | number: '1.0-0') : '-' }}</a>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th>Total</th>
                <th></th>
                <th nzAlign="center">{{ this.totalOficiais | number: '1.0-0' }}</th>
                <th nzAlign="center">{{ this.totalNaoOficiais | number: '1.0-0' }}</th>
            </tr>
            </tfoot>
        </nz-table>
        <nz-table #tableObrigacoes
                  [nzData]="arrayTableTotais"
                  [nzFrontPagination]="false"
                  *ngIf="tabNumber == 1 && anoSelecionado"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="queryTable($event)"
                  class="scrollbar table-small">
            <thead>
            <tr style="white-space: nowrap;">
                <th nzColumnKey="empresa" [nzSortFn]="true" nzAlign="center">Empresa</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" nzAlign="center">CNPJ</th>
                <th nzColumnKey="ie" [nzSortFn]="true" nzAlign="center">IE</th>
                <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzColumnKey="obrigacao_descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                <th nzColumnKey="arquivoTamanho" [nzSortFn]="true" nzAlign="center">Tamanho</th>
                <th nzColumnKey="armazenamentoData" [nzSortFn]="true" nzAlign="center">Armazenado Em</th>
                <th nzColumnKey="reciboNumero" [nzSortFn]="true" nzAlign="center">Protocolo</th>
                <th nzColumnKey="reciboData" [nzSortFn]="true" nzAlign="center">Transmissão</th>
                <th nzColumnKey="tipoTransmissao" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzColumnKey="arquivoNome" [nzSortFn]="true" nzAlign="center">Arquivos</th>
                <th nzAlign="center">Versões</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of tableObrigacoes.data">
                <td>
                    <div class="d-flex justify-content-between" style="width: 100%; min-width: 300px;">
                        <span>{{ modoDemo(data?.empresa, 'empresaCodigoNome') }}</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">{{ data.cnpj | cpfCnpj }}</td>
                <td style="white-space: nowrap;" nzAlign="center">{{ data.ie || '-' }}</td>
                <td nzAlign="center">{{ data?.competencia | date:'MM/yyyy' }}</td>
                <td nzAlign="center">{{ data?.tipo }}</td>
                <td nzAlign="center">{{ data?.obrigacao_descricao }}</td>
                <td nzAlign="center">{{ formatBytes(data?.arquivoTamanho) }}</td>
                <td nzAlign="center">{{ data?.armazenamentoData | date:'dd/MM/yyyy HH:mm' }}</td>
                <td nzAlign="center">
                    <span nz-tooltip="{{data?.reciboNumero}}">{{ data?.reciboNumeroElipses }}</span>
                </td>
                <td nzAlign="center">{{ data?.reciboData | date:'dd/MM/yyyy HH:mm' }}</td>
                <td nzAlign="center">{{ data?.tipoTransmissao }}</td>
                <td nzAlign="center">
                    <a [nzLoading]="loadings.downloadArquivo.includes(data.arquivoNome)" nz-button nzType="link"
                       *ngIf="data?.temDownload"
                       (click)="exportDocumento(data?.id, data?.arquivoNome, 'arquivo');">
                        <i nz-icon nzType="download" nzTheme="outline"></i>
                    </a>
                    <a nz-button nzType="link"
                       *ngIf="data.arquivoRecibo"
                       (click)="exportDocumento(data?.id, data?.arquivoRecibo, 'recibo');">
                        <i *ngIf="!loadings.downloadArquivo.includes(data.arquivoRecibo) else loading"
                           class="fa fa-file-text" aria-hidden="true"></i>
                        <ng-template #loading>
                            <span nz-icon nzType="loading" nzTheme="outline"></span>
                        </ng-template>
                    </a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [nzLoading]="loadingHistorico === data.id"
                       (click)="getHistorico(currentParamsHistorico, data)">Histórico</a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</div>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <button nz-button [nzType]="'primary'" [nzLoading]="loadings.tipos"
                (click)="modalExportar(true)"
                style="margin-right: 10px;">
            <span>Exportar</span>
        </button>
        <button nz-button nzType="primary"
                style="margin-right: 10px;"
                (click)="openTab('MonitorArquivosTransitoriosComponentUpload', null,'upload')">Uploads
        </button>
        <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }"
                  style="margin-right: 10px;">
            <button nz-button [nzType]="'primary'"
                    (click)="abrirModal(tabNumber === 0 ? formFiltrarTotais : tabNumber === 1 && formFiltrarObrigacoes)">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch)" type="text" nz-input placeholder="Busca"
                   [ngModel]="currentSearch"
                   (ngModelChange)="changeSearch($event)"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="queryTable(currentParams, currentSearch)">
                <i nz-icon nzType="search"></i>
            </button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'" style="padding: 0 8px;"></i>
        </button>
    </div>
</ng-template>
<nz-modal
        [(nzVisible)]="tabNumber === 0 ? formFiltrarTotais.modalVisible : tabNumber === 1 && formFiltrarObrigacoes.modalVisible"
        [nzTitle]="'Filtros Avançados'"
        [nzClosable]="true"
        (nzOnCancel)="fechar(tabNumber === 0 ? formFiltrarTotais : tabNumber === 1 && formFiltrarObrigacoes)"
        [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form *ngIf="tabNumber === 0" [nzNoColon]="true"
              [formGroup]="formFiltrarTotais.formGroup"
              style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarTotais.formGroup.value.empresa_id">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarTotais.formGroup.value.empresa_id"
                               formControlName="empresa_id"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ') '"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Categorias</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarTotais.formGroup.value.categoria">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarTotais.formGroup.value.categoria"
                               formControlName="categoria"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectcategoria"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.categoria"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarTotais.formGroup.value.obrigacao_id">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarTotais.formGroup.value.obrigacao_id"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
        <form nz-form *ngIf="tabNumber === 1" [nzNoColon]="true"
              [formGroup]="formFiltrarObrigacoes.formGroup"
              style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.grupoEmpresarial">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarObrigacoes.formGroup.value.grupoEmpresarial"
                               formControlName="grupoEmpresarial"
                               (ngModelChange)="retornaSelectsEmpresasEObrigacoes(formFiltrarObrigacoes.formGroup.value.grupoEmpresarial)">
                        <nz-option *ngFor="let opt of arraySelectGrupoEmpresarial"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.empresa_id">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarObrigacoes.formGroup.value.empresa_id"
                               formControlName="empresa_id"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inscrição estadual</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.ie !=  null && formFiltrarObrigacoes.formGroup.value.ie !=  '' ">
                    <input nz-input type="text" formControlName="ie" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.categoria">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarObrigacoes.formGroup.value.categoria"
                               formControlName="categoria"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectcategoria"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.categoria"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.obrigacao_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.tipo">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="tipo" nzAllowClear>
                        <nz-option [nzLabel]="'Oficial'" [nzValue]="'Oficial'"></nz-option>
                        <nz-option [nzLabel]="'Não Oficial'" [nzValue]="'NaoOficial'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.competencia">
                    <nz-date-picker [nzFormat]="'MM/yyyy'" nzMode="month"
                                    formControlName="competencia"
                                    nzPlaceHolder="Selecione"
                                    style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link"
                (click)="fechar(tabNumber === 0 ? formFiltrarTotais : tabNumber === 1 && formFiltrarObrigacoes)">
            Cancelar
        </button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Solicitar Exportação'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="525">
    <div nz-row *nzModalContent>

        <p nz-typography>Selecione o formato do(s) arquivo(s) a ser(em) exportado(s).</p>

        <nz-list nzItemLayout="horizontal" nz-col [nzSpan]="24">
            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('xlsx')">
                <button style="margin-left: 12px;" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.xlsx">
                    <i nz-icon nzType="file-excel" class="far fa-file-excel"
                       style="color: #28a745;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Gera arquivo xlsx com lista de arquivos da pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          *ngIf="tiposParaBaixar.downloadTxt"
                          (click)="confirmaExportar('txt')">
                <button style="margin-left: 12px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.txt">
                    <i nz-icon nzType="file-text" nzTheme="outline" style="color: #1890ff;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Exporta arquivos “Em Uso” listados na pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          *ngIf="tiposParaBaixar.downloadPdf"
                          (click)="confirmaExportar('pdf')">
                <button style="margin-left: 12px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.pdf">
                    <i nz-icon nzType="file-pdf" nzTheme="outline" style="color: #dc3545; font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Exporta arquivo .zip com recibos em PDF das obrigações listadas</span>
                </div>

            </nz-list-item>

        </nz-list>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
    </div>
</nz-modal>
<ng-template #cardExtraEmpresas>
    <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="cardCompress($event.target)"
       [nzSize]="'small'">
        <i nz-icon nzType="plus" class="d-none" nzTheme="outline"></i>
        <i nz-icon nzType="minus" nzTheme="outline"></i>
    </a>
</ng-template>
<ng-template #cardExtra>
    <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="expandirRecolherTodos()"
       [nzSize]="'small'">
        <i [ngClass]="exibirTudo ? ['fa fa-plus d-none'] : ['fa fa-plus']" nzTheme="outline"></i>
        <i [ngClass]="exibirTudo ? ['fa fa-minus'] : ['fa fa-minus d-none']" nzTheme="outline"></i>
    </a>
</ng-template>

<nz-modal [(nzVisible)]="modalHistoricoVisible"
          [nzTitle]="'Histórico'" [nzClosable]="true"
          (nzOnCancel)="showModalHistorico(false)"
          [nzBodyStyle]="{'padding': '0'}"
          [nzWidth]="750">
    <div nz-row *nzModalContent style="display: flex; justify-content: center">
        <nz-table #tableHistorico
                  style="max-height: 600px; overflow-y: auto"
                  [nzData]="arrayTableHistorico"
                  [nzFrontPagination]="false"
                  [nzLoading]="loadingHistorico"
                  [nzTotal]="paginationHistorico?.total"
                  [nzPageSize]="paginationHistorico?.per_page"
                  [nzPageIndex]="paginationHistorico?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="listByTableHistorico($event)"
                  class="scrollbar table-small">
            <thead>
            <tr style="white-space: nowrap;">
                <th nzColumnKey="obrigacao_descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                <th nzColumnKey="arquivoTamanho" [nzSortFn]="true" nzAlign="center">Tamanho</th>
                <th nzColumnKey="armazenamentoData" [nzSortFn]="true" nzAlign="center">Armazenado Em</th>
                <th nzColumnKey="reciboNumero" [nzSortFn]="true" nzAlign="center">Protocolo</th>
                <th nzColumnKey="tipoTransmissao" [nzSortFn]="true" nzAlign="center">Transmissao</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzAlign="center">Arquivos</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of tableHistorico.data">
                <td>
                    <div style="display: flex; justify-content: center; width: 100%; min-width: 150px; gap: 6px; padding-left: 8px">
                        <span>{{ data.obrigacao_descricao }}</span>
                        <div>
                            <nz-tag *ngIf="data.obrigacaoEmUso" [nzColor]="'green'">
                                Em Uso
                            </nz-tag>
                        </div>
                    </div>
                </td>
                <td style="white-space: nowrap;">{{ data.arquivoTamanho || '-' }}</td>
                <td nzAlign="center">{{ data?.armazenamentoData ? (data?.armazenamentoData | date:'dd/MM/yyyy HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ data?.reciboNumero || '-' }}</td>
                <td nzAlign="center">{{ data?.tipoTransmissao || '-' }}</td>
                <td nzAlign="center">{{ data?.tipo || '-' }}</td>
                <td nzAlign="center">
                    <a [nzLoading]="loadings.downloadArquivo.includes(data.arquivoNome)" nz-button nzType="link"
                       *ngIf="data?.temDownload"
                       (click)="exportDocumento(data?.id, data?.arquivoNome, 'arquivo');">
                        <i nz-icon nzType="download" nzTheme="outline"></i>
                    </a>
                    <a nz-button nzType="link" *ngIf="data.arquivoRecibo"
                       (click)="exportDocumento(data?.id, data?.arquivoRecibo, 'recibo');">
                        <i *ngIf="!loadings.downloadArquivo.includes(data.arquivoRecibo) else loading"
                           class="fa fa-file-text" aria-hidden="true"></i>
                        <ng-template #loading>
                            <span nz-icon nzType="loading" nzTheme="outline"></span>
                        </ng-template>
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalHistorico(false);">Fechar</button>
    </div>
</nz-modal>

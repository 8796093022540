import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegrasAnaliseXmlSaidaComponent} from './regras-analise-xml-saida.component';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {UiModule} from '../../ui.module';
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzUploadModule} from "ng-zorro-antd/upload";

@NgModule({
    declarations: [
        RegrasAnaliseXmlSaidaComponent
    ],
    imports: [
        CommonModule,
        NgxMaskModule,
        FormsModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        NzTabsModule,
        NzBadgeModule,
        NzTableModule,
        NzButtonModule,
        NzInputModule,
        UiModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NzUploadModule
    ]
})
export class RegrasAnaliseXmlSaidaModule {
}

import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class NotasFiscaisEntradaService {

    http = inject(HttpClient);

    getCategorias(filtros: any): Observable<any> {
        delete filtros.intervalo;

        Object.entries(filtros).forEach(([key, value]) => {
            if (value === null) {
                delete filtros[key];
            } else if (filtros[key] instanceof Date) {
                filtros[key] = Helpers.dataToFilter(filtros[key]);
            }
        });

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/notas/workflow/cabecalho${queryStr}`);
    }

    getEntradas(filtros: any): Observable<any> {

        const dados: any = {};

        if (filtros) {


            filtros.forEach(f => {
                if (f) {

                    if (f.value instanceof Date) {
                        f.value = Helpers.dataToFilter(f.value);
                    }
                    dados[f.key] = f.value;
                }
            });

        }


        return this.http.post(`${environment.apiUrl}/notas/workflow/tabela`, dados);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    getMarcadores(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/detalhe/marcadores`);
    }

    getStatusW(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/workflow/status`);
    }

    novoMarcador(marcador: string, cor: string, ids: string[]): Observable<any> {

        const dados: any = {
            marcador,
            ids
        };

        if (cor) {
            dados.cor = cor;
        }


        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores`, dados);
    }

    removerMarcador(marcador: string, ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores/remover`, {marcador, ids});
    }

    deletarMarcadores(ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores/excluir`, {ids});
    }
}

import {NgModule} from '@angular/core';
import {TasksComponent} from '../tasks/tasks.component';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [
        TasksComponent,
    ],
    exports: [
        TasksComponent,
    ],
    imports: [
        CommonModule,
        NzBadgeModule,
        NzDropDownModule,
        NzButtonModule,
        NzTypographyModule,
        NzCardModule,
        NzIconModule,
        NzProgressModule,
        NzToolTipModule
    ]
})
export class TasksModule {
}

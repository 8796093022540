import BulkChanges from './entity.model';

export default class GrupoEmpresarial implements BulkChanges {
    id: string;
    descricao: string;

    constructor(obj?: object) {
        if (obj) {
            Object.entries(obj).forEach(([key, value]) => this[key] = value);
        }
    }

    toBulk(id: string, itens: string[]) {
        return {
            id,
            itens
        };
    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MonitorProcessamentosComponent} from './monitorProcessamentos.component';

const routes: Routes = [
    {
        path: '',
        component: MonitorProcessamentosComponent
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ]
})
export class MonitorProcessamentosRoutingModule {
}

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    LOCALE_ID, NgZone,
    OnInit, Renderer2,
    ViewChild,
    AfterContentChecked
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ObrigacoesPrincipaisService} from './obrigacoesPrincipais.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {TabService} from '@services/tab.service';
import {StickyHeaderDirective} from '@components/directive/directive';
import {Helpers} from '../../../core/helpers';
import {NzUploadFile} from 'ng-zorro-antd/upload';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-drive-obrigacoes-principais',
    templateUrl: './obrigacoesPrincipais.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./obrigacoesPrincipais.component.scss']
})

export class ObrigacoesPrincipaisComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, AfterContentChecked {

    apiUrl = environment.apiUrl;

    paginations: any = {
        e: Pagination,
        o: Pagination
    };
    fileList: NzUploadFile[] = [];
    uploading = false;
    cardCompetenciaAtivo: number;
    formFiltrarTotais: FormStack;
    formFiltrarObrigacoes: FormStack;
    formExportar: FormStack;
    arrayFiltrosObrigacoes: any = [];
    anoSelecionado: number = null;
    arrayAnos = [];
    status = false;
    cardTabsLoading = false;
    tabNumber = 0;
    collapseCard = false;
    indexTab = 0;
    arraySelectEmpresa = [];
    arraySelectGrupoEmpresarial = [];
    arraySelectcategoria = [];
    arraySelectCnpj = [];
    qtdFiltrosAtivos = 0;
    arrayCabecalho = [];
    arrayChart = [];
    serverSize = 1073741824;
    obrigacoesSize = 0;
    obrigacoesEstaduaisPercentage = 0;
    obrigacoesFederaisPercentage = 0;
    updateFlag = false;
    loadingCardCabecalho = true;
    comboObrigacoes = [];
    modalImportarVisible = false;
    modalImportarVisibleComposicao = false;

    expandidos: any = {};

    exibirTudo = false;

    totaisEmpresas = {
        darf: 0,
        gare: 0,
        perdcomp: 0,
        parcFederal: 0
    };
    modalExtratoVisible = false;
    modalComposicaoVisible = false;
    modalCompetenciaVisible = false;
    arrayExtrato = [];
    arrayComposicao = [];
    arrayCompetencia = [];
    loadingExtrato = {};
    loadingComposicao = {};
    loadingCompetencia = {};
    downloadArquivos = {
        pdf: false,
        txt: false
    };
    arrayTableEmpresas = [];
    arrayTableObrigacoes = [];
    tableObrigacaoSelecionada: any = {colunas: [], dados: [], categoria: '', id: '', nome: '', url: ''};

    responseFake: any = {
        darf:
            {
                id: 'darf',
                nome: 'Darf',
                categoria: 'darf',
                colunas: [
                    {
                        key: 'empresa',
                        descricao: 'Unidade'
                    },
                    {
                        key: 'cnpj',
                        descricao: 'cnpj'
                    },
                    {
                        key: 'tipo',
                        descricao: 'Tipo'
                    },
                    {
                        key: 'numero',
                        descricao: 'Número'
                    },
                    {
                        key: 'data_apuracao',
                        descricao: 'Apuração'
                    },
                    {
                        key: 'data_arrecadacao',
                        descricao: 'Arrecadação'
                    },
                    {
                        key: 'data_vencimento',
                        descricao: 'Vencimento'
                    },
                    {
                        key: 'codigo',
                        descricao: 'Cód'
                    },
                    {
                        key: 'obrigacao_descricao',
                        descricao: 'Descrição'
                    },
                    {
                        key: 'referencia',
                        descricao: 'Referência'
                    },
                    {
                        key: 'valor',
                        descricao: 'Valor'
                    },
                    {
                        key: 'valor_original',
                        descricao: 'Vl. Original'
                    },
                    {
                        key: 'multa',
                        descricao: 'Multas'
                    },
                    {
                        key: 'juros',
                        descricao: 'Juros'
                    },
                ],
                dados: [
                    {
                        id: '',
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '25.458.0001-58'},
                        tipo: {conteudo: 'darf'},
                        numero: {conteudo: '554146835'},
                        data_apuracao: {conteudo: '27/03/2023'},
                        data_arrecadacao: {conteudo: '27/03/2023'},
                        data_vencimento: {conteudo: '27/03/2023'},
                        codigo: {conteudo: '51658656456'},
                        obrigacao_descricao: {conteudo: 'Exemplo de descrição da obrigação'},
                        referencia: {conteudo: '...'},
                        valor: {conteudo: 1500.00},
                        valor_original: {conteudo: 500},
                        multas: {conteudo: 1500},
                        juros: {conteudo: 500},
                        composicao: [
                            {conteudo: 'NomeDoBotão', tipo: 'link-detalhesDarf', url: 'url para a sub tabela'},
                        ]
                    }
                ]
            },
        gare:
            {
                id: 'gare',
                nome: 'Gare',
                categoria: 'Gare',
                colunas: [
                    {
                        key: 'empresa',
                        descricao: 'Unidade'
                    },
                    {
                        key: 'cnpj',
                        descricao: 'cnpj'
                    },
                    {
                        key: 'tipo',
                        descricao: 'Tipo'
                    },
                    {
                        key: 'uf',
                        descricao: 'UF'
                    },
                    {
                        key: 'cod_recolhimento',
                        descricao: 'Cód. Recolhimento'
                    },
                    {
                        key: 'referencia',
                        descricao: 'Referência'
                    },
                    {
                        key: 'data_arrecadacao',
                        descricao: 'Arrecadação'
                    },
                    {
                        key: 'valor_total',
                        descricao: 'Valor Total'
                    },
                    {
                        key: 'valor_original',
                        descricao: 'Vl. Original'
                    },
                    {
                        key: 'multa',
                        descricao: 'Multas'
                    },
                    {
                        key: 'juros',
                        descricao: 'Juros'
                    },
                ],
                dados: [
                    {
                        id: '',
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '25.458.0001-58'},
                        tipo: {conteudo: 'Gare'},
                        uf: {conteudo: 'SP'},
                        cod_recolhimento: {conteudo: 354135455},
                        referencia: {conteudo: '...'},
                        data_arrecadacao: {conteudo: '27/03/2023'},
                        valor_total: {conteudo: 56985.00},
                        valor_original: {conteudo: 52665},
                        multa: {conteudo: 50},
                        juros: {conteudo: 100}
                    },
                    {
                        id: '',
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '152548000154'},
                        tipo: {conteudo: 'Gare'},
                        uf: {conteudo: 'SP'},
                        cod_recolhimento: {conteudo: 354135455},
                        referencia: {conteudo: '...'},
                        data_arrecadacao: {conteudo: '27/03/2023'},
                        valor_total: {conteudo: 56985.00},
                        valor_original: {conteudo: 52665},
                        multa: {conteudo: 50},
                        juros: {conteudo: 100}
                    },
                    {
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '25.458.0001-58'},
                        tipo: {conteudo: 'Gare'},
                        uf: {conteudo: 'SP'},
                        cod_recolhimento: {conteudo: 354135455},
                        referencia: {conteudo: '...'},
                        data_arrecadacao: {conteudo: '27/03/2023'},
                        valor_total: {conteudo: 56985.00},
                        valor_original: {conteudo: 52665},
                        multa: {conteudo: 50},
                        juros: {conteudo: 100}
                    },
                ]
            },
        perdcomp:
            {
                id: 'perdcomp',
                nome: 'Perdcomp',
                categoria: 'Perdcomp',
                colunas: [
                    {
                        key: 'empresa',
                        descricao: 'Unidade'
                    },
                    {
                        key: 'cnpj',
                        descricao: 'cnpj'
                    },
                    {
                        key: 'tipo',
                        descricao: 'Tipo'
                    },
                    {
                        key: 'data_transmissao',
                        descricao: 'Data Transmissão'
                    },
                    {
                        key: 'num_processo',
                        descricao: 'Processo'
                    },
                    {
                        key: 'tipo_documento',
                        descricao: 'Tipo Documento'
                    },
                    {
                        key: 'tipo_credito',
                        descricao: 'Tipo Crédito'
                    },
                    {
                        key: 'arquivos',
                        descricao: 'Arquivos'
                    }
                ],
                dados: [
                    {
                        id: '',
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '25.458.0001-58'},
                        tipo: {conteudo: 'PERDCOMP'},
                        data_transmissao: {conteudo: '27/03/2023'},
                        num_processo: {conteudo: '6516555451'},
                        tipo_documento: {conteudo: 'Tipo Doc.'},
                        tipo_credito: {conteudo: 'Tipo Cred.'},
                        arquivos: [
                            {conteudo: '', tipo: 'bt-download-pdf'},
                            {conteudo: '', tipo: 'bt-download-txt'},
                        ]
                    }
                ]
            },
        parcFederal:
            {
                id: 'parcFederal',
                nome: 'Parcelamento Federal',
                categoria: 'parcFederal',
                colunas: [
                    {
                        key: 'empresa',
                        descricao: 'Unidade'
                    },
                    {
                        key: 'cnpj',
                        descricao: 'cnpj'
                    },
                    {
                        key: 'tipo',
                        descricao: 'Tipo'
                    },
                    {
                        key: 'data',
                        descricao: 'Data'
                    },
                    {
                        key: 'num_processo',
                        descricao: 'Processo'
                    },
                    {
                        key: 'data_recibo',
                        descricao: 'Recibo'
                    },
                    {
                        key: 'situacao',
                        descricao: 'Situação'
                    },
                    {
                        key: 'arquivos',
                        descricao: 'Arquivos'
                    }
                ],
                dados: [
                    {
                        id: '',
                        empresa: {conteudo: 'Nome Empresa'},
                        cnpj: {conteudo: '25.458.0001-58'},
                        tipo: {conteudo: 'Parcelamento Federal'},
                        data: {conteudo: '27/03/2023'},
                        num_processo: {conteudo: '515416565'},
                        data_recibo: {conteudo: '27/03/2023'},
                        situacao: {conteudo: 'SITUAÇÂO'},
                        arquivos: [
                            {conteudo: 'Competência', tipo: 'link-competencia'},
                            {conteudo: 'Extrato', tipo: 'link-extrato'},
                        ]
                    },
                ]
            }
    };

    @ViewChild('cardHeight') cardHeight: any;
    @ViewChild('cardHeightTarget') cardHeightTarget: any;

    private cardTabsTwo;

    @ViewChild('cardTabsTwo') set contentCard(content: ElementRef) {
        if (content) {
            this.cardTabsTwo = content;
        }
    }

    loadings = {
        xlsx: false,
        pdf: false,
        txt: false,
        downloadArquivo: []
    };

    offsetTop = 15;
    target: any = 0;

    constructor(
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private obrigacoesPrincipaisService: ObrigacoesPrincipaisService,
        private fb: UntypedFormBuilder,
        private i18n: NzI18nService,
        private toastService: ToastrService,
        private renderer: Renderer2,
        private tabService: TabService,
        private zone: NgZone,
    ) {

        super(obrigacoesPrincipaisService, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltrarTotais = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                obrigacao_id: [null, null],
                categoria: [null, null]
            })
        };

        this.formFiltrarObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                tipo: [null, null],
                obrigacao_descricao: [null, null],
                obrigacao_id: [null, null],
                arquivoTamanho: [null, null],
                armazenamentoData: [null, null],
                arquivoHash: [null, null],
                reciboNumero: [null, null],
                reciboData: [null, null],
                tipoTransmissao: [null, null],
                arquivoNome: [null, null],
                grupoEmpresarial: [null, null],
                categoria: [null, null],
                emUso: [null, null],
                ie: [null, null]
            })
        };
        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, null]
            })
        };

    }

    ngOnInit(): void {
        this.target = StickyHeaderDirective.target;

        this.anoSelecionado = new Date().getFullYear(); // Apenas para aparecer durando 1° carregamento

        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.currentSearch = '';

        this.retornaSelectsEmpresas();

        this.retornaSelectsGruposEmp();

        this.retornaSelectsCategorias();

        // this.formFiltrarObrigacoes.formGroup.get('emUso').setValue('true'); //Verificar se mantém

        this.retornaAnos();
        this.retornaEmpresas(this.currentParams, this.currentSearch);
        this.retornaComboObrigacoes();

    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeCard();
    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    showModalExtrato(visible, url: string = null) {

        if (visible) {
            this.loadingExtrato[url] = true;
            this.arrayExtrato = [];

            this.obrigacoesPrincipaisService.retornaDadosModal(url).subscribe((response) => {
                this.arrayExtrato = response;
                this.modalExtratoVisible = visible;
                this.loadingExtrato[url] = false;
            }, error => {
                this.loadingExtrato[url] = false;
                this.toastService.error(error.error.message);
            });

        } else {
            this.arrayExtrato = [];
            this.modalExtratoVisible = false;
        }
    }

    showModalCompetencia(visible, url: string = null) {
        // this.arrayCompetencia = [
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     },
        //     {
        //         imposto: 'PIS',
        //         mes: 4,
        //         ano: 2012,
        //         cod_receita: '8109',
        //         receita: 2572.21,
        //         juros: 25,
        //         total: 2597.21,
        //     }
        // ];

        if (visible) {
            this.loadingCompetencia[url] = true;
            this.arrayCompetencia = [];

            this.obrigacoesPrincipaisService.retornaDadosModal(url).subscribe((response) => {
                this.arrayCompetencia = response;

                this.modalCompetenciaVisible = visible;
                this.loadingCompetencia[url] = false;
            }, error => {
                this.loadingCompetencia[url] = false;
                this.toastService.error(error.error.message);
            });

        } else {
            this.arrayCompetencia = [];
            this.modalCompetenciaVisible = false;
        }

    }

    showModalComposicao(visible, url: string = null) {
        if (visible) {
            this.loadingComposicao[url] = true;
            this.arrayComposicao = [];

            this.obrigacoesPrincipaisService.retornaComposicaoDarf(url).subscribe((response) => {
                this.arrayComposicao = response;
                this.modalComposicaoVisible = visible;
                this.loadingComposicao[url] = false;
            }, error => {
                this.loadingComposicao[url] = false;
                this.toastService.error(error.error.message);
            });

        } else {
            this.arrayComposicao = [];
            this.modalComposicaoVisible = false;
        }
    }

    modalImportar(visible: boolean): void {

        this.modalImportarVisible = visible;
    }

    modalImportarComposicao(visible: boolean): void {

        this.modalImportarVisibleComposicao = visible;
    }

    downloadModelo(): void {

        this.obrigacoesPrincipaisService.downloadModelo().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    downloadModeloComposicao(): void {

        this.obrigacoesPrincipaisService.downloadModeloComposicao().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        if (file.name.substr(file.name.length - 5) === '.xlsx') {
            this.fileList = [];
            this.fileList.push(file);
        } else {
            this.toastService.error('Extensão inválida. Esperado: xlsx (.xlsx)');
        }

        return false;
    }

    confirmarImportar(): void {

        const formData = new FormData();

        if (!this.fileList.length) {
            this.toastService.error('Anexo é obrigatório!');
        } else {

            this.uploading = true;

            this.fileList.forEach((file: any) => {

                formData.append('arquivo', file);
            });

            this.obrigacoesPrincipaisService.uploadPlanilha(formData).subscribe((response: any) => {
                    this.toastrService.success(response.message);

                    this.fileList = [];

                    this.modalImportar(false);

                    this.retornaObrigacoes(this.currentParams, this.currentSearch, this.tableObrigacaoSelecionada.url);
                    this.uploading = false;

                },
                (response) => {

                    this.uploading = false;
                    this.toastrService.error(response.error.message);
                });
        }
    }

    confirmarImportarComposicao(): void {

        const formData = new FormData();

        if (!this.fileList.length) {
            this.toastService.error('Anexo é obrigatório!');
        } else {

            this.uploading = true;

            this.fileList.forEach((file: any) => {

                formData.append('arquivo', file);
            });

            this.obrigacoesPrincipaisService.uploadPlanilhaComposicao(formData).subscribe((response: any) => {
                    this.toastrService.success(response.message);

                    this.fileList = [];

                    this.modalImportar(false);

                    this.retornaObrigacoes(this.currentParams, this.currentSearch, this.tableObrigacaoSelecionada.url);
                    this.uploading = false;

                },
                (response) => {

                    this.uploading = false;
                    this.toastrService.error(response.error.message);
                });
        }
    }

    // baixar arquivos
    downloadArquivo(arquivos, btnTipo): void {

        let data = {url: '', arquivoNome: ''};

        if (btnTipo === 'bt-download-pdf') {
            data = arquivos[0];
        } else if (btnTipo === 'bt-download-txt') {
            data = arquivos[1];
        }

        this.loadings.downloadArquivo.push(data.url);

        this.downloadArquivos[data.url] = true;
        this.obrigacoesPrincipaisService.retornaArquivos(data.url).subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, data.arquivoNome);
            this.downloadArquivos[data.url] = false;
            this.loadings.downloadArquivo = [];
        }, error => {
            this.loadings.downloadArquivo = [];
            this.toastService.error(error.error.message);
        });
    }

    retornaSelectsCategorias() {
        this.obrigacoesPrincipaisService.retornarSelectsCategorias().subscribe((retorno: any) => {
            this.arraySelectcategoria = retorno;
        });
    }

    retornaSelectsGruposEmp() {
        this.obrigacoesPrincipaisService.retornarSelectsGrupoEmpresarial().subscribe((retorno: any) => {
            this.arraySelectGrupoEmpresarial = retorno.grupoEmpresarial;
        });
    }

    retornaSelectsEmpresas(grupoEmpId?: string) {

        this.obrigacoesPrincipaisService.retornarSelectsEmpresas(grupoEmpId).subscribe((retorno: any) => {

            const empresaId = this.formFiltrarObrigacoes.formGroup.value.empresa_id;

            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;

            let reset = true;

            this.arraySelectEmpresa.map(empN => {
                if (empN.id === empresaId) {
                    reset = false;
                }
            });

            this.arraySelectCnpj.map(empC => {
                if (empC.id === empresaId) {
                    reset = false;
                }
            });

            if (reset) {
                this.formFiltrarObrigacoes.formGroup.get('empresa_id').setValue(null);
            }

        });
    }

    retornaSelectsEmpresasEObrigacoes(grupoEmpId: string) {
        this.retornaSelectsEmpresas(grupoEmpId);
        this.retornaComboObrigacoes();
    }

    retornaComboObrigacoes(empresaId?: string, grupoEmp?: string, categoria?: string) {

        if (this.tabNumber === 0) {
            grupoEmp = this.formFiltrarTotais.formGroup.value.empresa_id;
            categoria = this.formFiltrarTotais.formGroup.value.categoria;
        } else {
            grupoEmp = this.formFiltrarObrigacoes.formGroup.value.grupoEmpresarial;
            empresaId = this.formFiltrarObrigacoes.formGroup.value.empresa_id;
            categoria = this.formFiltrarObrigacoes.formGroup.value.categoria;
        }

        const obrigacaoId = this.formFiltrarObrigacoes.formGroup.value.obrigacao_id;

        this.obrigacoesPrincipaisService.retornaComboObrigacoes(empresaId, grupoEmp, categoria).subscribe((response) => {

            this.comboObrigacoes = [];

            response.obrigacoes.forEach((value) => {
                const dados = {id: value.id, label: value.descricao};
                this.comboObrigacoes.push(dados);
            });

            let reset = true;

            this.comboObrigacoes.map(ob => {
                if (ob.id === obrigacaoId) {
                    reset = false;
                }
            });

            if (reset) {
                this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(null);
            }

        });
    }

    selecionaCardCompetencia(event: any) {

        this.arrayTableObrigacoes = [];

        if (event.clickedIndex !== undefined) {

            this.cardCompetenciaAtivo = event.clickedIndex;

            this.anoSelecionado = this.arrayAnos[event.clickedIndex].ano;

            this.limparFiltros();

            this.tabNumber = 0;

            this.retornaCabecalho(this.anoSelecionado);

        }

    }

    retornaAnos(): void {

        this.obrigacoesPrincipaisService.retornaAnos().subscribe((retorno: any) => {

            this.arrayAnos = retorno;

            this.anoSelecionado = this.arrayAnos[this.arrayAnos.length - 1].ano;

            this.retornaCabecalho(this.anoSelecionado);

            setTimeout(() => {

                this.cardCompetenciaAtivo = this.arrayAnos.length - 1;

                setTimeout(() => {
                    this.collapseCard = true;
                }, 500);

            }, 1000);

        });

    }

    retornaEmpresas(params: NzTableQueryParams, search: string = null) {

        this.calculaBadgeFiltros();

        this.cardTabsLoading = true;

        const url = `${this.apiUrl}/drive/obrigacoes/principais/empresas/${this.anoSelecionado}`;

        params.filter.forEach((value) => {

            if (value.key === 'competencia' && value.value) {
                value.value = Helpers.formatDateDb(value.value).substr(0, 7);
            }

        });

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.totaisEmpresas.darf = 0;
            this.totaisEmpresas.gare = 0;
            this.totaisEmpresas.perdcomp = 0;
            this.totaisEmpresas.parcFederal = 0;

            this.arrayTableEmpresas = response.data;

            this.arrayTableEmpresas.forEach((value) => {
                this.totaisEmpresas.darf += Number(value.qtdDarf);
                this.totaisEmpresas.gare += Number(value.qtdGare);
                this.totaisEmpresas.perdcomp += Number(value.qtdDcomp);
                this.totaisEmpresas.parcFederal += Number(value.qtdParcelamentoFederal);
            });

            this.fechar(this.formFiltrarTotais);

            this.paginations.e = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.cardTabsLoading = false;

        });

    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.e.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.e.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.e.current_page) {
            this.retornaEmpresas(params);
            this.paginations.e.current_page = params.pageIndex;
        }

    }

    retornaObrigacoes(params: NzTableQueryParams, search: string = null, url: string) {

        this.calculaBadgeFiltros();

        this.cardTabsLoading = true;

        const finalUrl = `${this.apiUrl}${url}`;

        this.abstractService.listToTable(params, search, finalUrl).subscribe((response) => {

            this.tableObrigacaoSelecionada.dados = response.data;

            this.fechar(this.tabNumber === 0 ? this.formFiltrarTotais : this.formFiltrarObrigacoes);

            this.paginations.o = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.cardTabsLoading = false;

        }, error => {
            this.toastService.error(error.error.message || 'Erro ao realizar operação');
            this.cardTabsLoading = false;
            this.tabNumber = 0;
        });

    }

    listByTableObrigacoes(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.o.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.o.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.o.current_page) {
            this.retornaObrigacoes(params, null, this.tableObrigacaoSelecionada.url);
            this.paginations.o.current_page = params.pageIndex;
        }

    }

    calculaBadgeFiltros(force = false): void {

        let form = null;

        if (force || this.tabNumber === 0) {
            form = this.formFiltrarTotais;
        } else if (this.tabNumber > 0) {
            form = this.formFiltrarObrigacoes;
        }

        this.qtdFiltrosAtivos = 0;

        if (typeof form !== 'undefined') {

            for (const [chave, valor] of Object.entries(form.formGroup.value)) {

                if (chave === 'empresa_id' && valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    msgParaUsuario(msg: string) {
        this.toastService.warning(msg);
    }

    filtrar(tipo: any = null, url = null, filtrosAPI = null): any {

        let filtros;

        this.currentParams.filter = [];

        if (!tipo) {
            // this.tabNumber será 0

            filtros = this.formFiltrarTotais.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                this.currentParams.filter.push({key: chave, value: valor});
            }

            this.formFiltrarTotais.modalVisible = false;

            this.retornaEmpresas(this.currentParams, this.currentSearch);

        } else {

            filtros = this.formFiltrarObrigacoes.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                this.currentParams.filter.push({key: chave, value: valor});
            }

            if (filtrosAPI) {
                for (const [chave, valor] of Object.entries(filtrosAPI)) {

                    this.currentParams.filter.push({key: chave, value: valor});
                }
            }

            this.formFiltrarObrigacoes.modalVisible = false;

            this.retornaObrigacoes(this.currentParams, this.currentSearch, url);

        }

        this.currentParams.pageIndex = 1;
    }

    filtrarObrigacoesCard(item, tipo) {
        this.formFiltrarTotais.formGroup.get('empresa_id').setValue(null);
        this.formFiltrarObrigacoes.formGroup.get('empresa_id').setValue(null);

        if (item.obrigacao_id === this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').value) {
            this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(null);
            this.tabNumber = 0;

        } else {
            this.tableObrigacaoSelecionada = this.arrayTableObrigacoes.find(obg => obg.categoria === item.categoria);
            this.tabNumber = 1;

            this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(item.obrigacao_id);

            this.filtrar(tipo, this.tableObrigacaoSelecionada.url, item.filtros);

            setTimeout(() => {
                this.scrollContainer(200);
            }, 120);
        }

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTabsTwo.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 800);

    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.arrayAnos.length - 1) {
                    this.cardCompetenciaAtivo = this.arrayAnos.length - 1;
                }

                break;

        }

        this.anoSelecionado = this.arrayAnos[this.cardCompetenciaAtivo].ano;

        this.tabNumber = 0;

        this.filtrar();

    }

    retornaCabecalho(ano) {

        this.loadingCardCabecalho = true;

        this.zone.run(() => {

            this.obrigacoesPrincipaisService.retornaCabecalho(ano).subscribe((retorno: any) => {

                retorno.dados.forEach(categoria => {
                    categoria.itens.sort((a, b) => {
                        if (a.qtdTotal < b.qtdTotal) {
                            return 1;
                        }
                        if (a.qtdTotal > b.qtdTotal) {
                            return -1;
                        }
                        return 0;
                    });

                    const data: any = {};

                    data.colunas = categoria.colunas;
                    data.categoria = categoria.categoria;
                    data.id = categoria.categoria;
                    data.url = categoria.url;
                    data.nome = categoria.categoria.toUpperCase();

                    this.arrayTableObrigacoes.push(data);
                });

                this.arrayCabecalho = retorno.dados;

                this.arrayChart = retorno.dadosTotais;

                this.obrigacoesSize = retorno.tamanhoTotalEstadual;

                this.obrigacoesEstaduaisPercentage = parseFloat(
                    this.getPercentage(retorno.tamanhoTotalEstadual, this.serverSize).toFixed(6)
                );

                this.obrigacoesFederaisPercentage = parseFloat(
                    this.getPercentage(retorno.tamanhoTotalEstadual + retorno.tamanhoTotalFederal, this.serverSize).toFixed(6)
                );

                this.loadingCardCabecalho = false;

                this.arrayCabecalho.forEach(i => {
                    this.expandidos[i.categoria] = false;
                });

            });

        });

    }

    expandirRecolherTodos() {
        this.exibirTudo = !this.exibirTudo;

        this.arrayCabecalho.forEach(i => {
            this.expandidos[i.categoria] = this.exibirTudo;
        });
    }

    resizeCard() {

        if (this.cardHeight) {

            setTimeout(() => {

                const height = `${this.cardHeight.elementRef.nativeElement.lastElementChild.offsetHeight}px`;
                this.renderer.setStyle(this.cardHeightTarget.elementRef.nativeElement.lastElementChild, 'height', height);

            }, 300);

        }

    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    formatBytes(bytes, decimals = 2) {

        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    }

    public cardCompress(target: HTMLElement): void {

        let barElement: HTMLElement | null = target;

        while (barElement && !barElement.classList.contains('ant-card')) {

            barElement = barElement.parentElement;

        }

        const iconPlus = barElement.querySelector('.anticon-plus');
        const iconMinus = barElement.querySelector('.anticon-minus');

        barElement = barElement.querySelector('.ant-card-body');

        if (barElement.classList.contains('compress')) {

            iconPlus.classList.remove('d-inline-block');
            iconPlus.classList.add('d-none');

            iconMinus.classList.remove('d-none');
            iconMinus.classList.add('d-inline-block');

            barElement.classList.remove('compress');

        } else {

            iconMinus.classList.remove('d-inline-block');
            iconMinus.classList.add('d-none');

            iconPlus.classList.remove('d-none');
            iconPlus.classList.add('d-inline-block');

            barElement.classList.add('compress');


        }

    }

    changeSearch(event) {
        this.currentSearch = event;
    }

    limparFiltros() {

        this.checkedItems.clear();
        this.checked = false;

        this.formFiltrarTotais.formGroup.reset();
        this.formFiltrarObrigacoes.formGroup.reset();

        this.tabNumber = 0;

        this.currentSearch = null;

        this.filtrar();

    }

    filtrarObrigacoes(categoria, empresaId) {

        this.tableObrigacaoSelecionada = this.arrayTableObrigacoes.find(obg => obg.categoria === categoria);
        this.tabNumber = 1;

        this.formFiltrarObrigacoes.formGroup.get('empresa_id').setValue(empresaId);
        this.formFiltrarObrigacoes.formGroup.get('tipo').setValue(categoria);

        this.filtrar(categoria, this.tableObrigacaoSelecionada.url);

    }

    changeTabs(event) {
        this.tabNumber = event.index;
        if (this.tabNumber === 0) {
            this.formFiltrarObrigacoes.formGroup.reset();
            this.tableObrigacaoSelecionada = null;
        }
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    confirmaExportar(tipo: string): void {

        this.loadings[tipo] = true;

        this.obrigacoesPrincipaisService.solicitarExportar(this.currentParams.filter, tipo, this.anoSelecionado).subscribe((res) => {

            this.loadings[tipo] = false;

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.formExportar.modalVisible = false;

            this.formExportar.formGroup.reset();

        });

    }

    export() {

        const filtros = this.formFiltrarObrigacoes.formGroup.value;
        filtros.obrigacao = this.arrayFiltrosObrigacoes[this.indexTab].key;

        const ano = this.anoSelecionado;

        this.obrigacoesPrincipaisService.exportExcel(ano, filtros).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            const name = 'Obrigações Federais em ' + this.anoSelecionado;

            fileSaver.saveAs(blob, name + '.xlsx');

        });

        this.formExportar.modalVisible = false;

    }

    exportDocumento(id, arquivoNome, tipoArquivo) {

        this.obrigacoesPrincipaisService.exportDocumento(id, tipoArquivo).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome);

        });
    }

}

import {Conta} from './conta.model';
import {Utils} from '../utils';
import {Profile} from './profile.model';
import BulkChanges from '@models/entity.model';

export class User implements BulkChanges {
    id: string;
    name: string;
    phone: string;
    username: string;
    email: string;
    cpf: string;
    ativo: number;
    responsavel: number;
    admin: number;
    password: string;
    conta: Conta;
    perfil: Profile;
    perfil_id: string;
    two_factor_auth: number;

    constructor(obj?: any) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
            if (obj.hasOwnProperty('conta')) {
                this.conta = new Conta(obj.conta);
                delete obj.conta;
            }
            if (obj.hasOwnProperty('perfil')) {
                this.perfil = new Profile(obj.perfil);
                delete obj.perfil;
            }
        }
    }

    convertToSave() {
        const properties = ['id', 'name', 'username', 'phone', 'email', 'cpf', 'password', 'ativo', 'responsavel', 'admin', 'two_factor_auth'];
        const user = new User();
        for (const prop of properties) {
            user[prop] = this[prop];
        }
        if (this.perfil) {
            user.perfil_id = this.perfil.id;
        }
        if (Utils.isEmpty(user.id)) {
            delete user.id;
        }
        return user;
    }

    toBulk(id: string | string[], itens: string[]) {
        return {
            id,
            itens
        };
    }


    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            usuarios: checkedItems,
            visibilidades: propertyChanged
        };
    }
}

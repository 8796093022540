import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '@services/profile.service';
import {Profile} from '@models/profile.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MegamenuService} from '@components/megamenu/megamenu.service';
import {TabService} from '@services/tab.service';
import {NzTreeNode} from 'ng-zorro-antd/tree';

interface RecursivoParams {
    nodes: NzTreeNode[];
    stack: string[];
}

@Component({
    selector: 'app-detail-profile',
    templateUrl: './detail-profile.component.html',
    styleUrls: ['./detail-profile.component.scss']
})
export class DetailProfileComponent implements OnInit {
    @Input() data;
    @ViewChildren('treePerfil') treePerfil;
    menuTree: NzTreeNode[];
    profile: Profile;
    profileForm: UntypedFormGroup;

    constructor(
        private tabService: TabService,
        private router: Router,
        private profileService: ProfileService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private toastrService: ToastrService,
        private megamenuService: MegamenuService
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.getMenuTree();
    }

    getMenuTree() {
        this.megamenuService.getTree().subscribe(res => {
            this.menuTree = this.megamenuService.transformMegamenuItem(res, 0, true);
            this.getProfile();
        });
    }

    buildForm() {
        this.profileForm = this.formBuilder.group({
            descricao: [null, Validators.required]
        });
    }

    updateForm() {
        this.profileForm.patchValue({
            descricao: this.profile.descricao
        });
    }

    getProfile() {
        this.profileService.get(this.data.id).subscribe((profile: Profile) => {
            this.profile = new Profile(profile);
            this.setSelectedItens(profile.itens);
            this.updateForm();
        });
    }

    updateProfile() {
        this.profile = new Profile({
            id: this.profile.id,
            descricao: this.getControl('descricao').value,
            itens: this.getSelectedItens2()
            // itens: this.getSelectedItens(this.menuTree, {hasChecked: false, itens: []}).itens
        });
    }

    getControl = (name) => this.profileForm.get(name);

    save() {

        this.updateProfile();
        if (this.profileForm.valid) {
            const profile = this.profile.convertToSave();
            this.profileService.save(profile).subscribe((res: any) => {
                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.tabService.closeCurrentTab();
                } else {
                    this.toastrService.error(res.error);
                }
            }, error => {
                this.toastrService.error(error);
            });
        }
    }

    getSelectedItens2() {

        const recursivo = ({nodes, stack}: RecursivoParams) => {

            for (const node of nodes) {

                if (node.children && node.children.length > 0) {

                    stack = recursivo({nodes: node.children, stack});

                }

                if ((node.isChecked || node.isHalfChecked) && stack.indexOf(node.key) === -1) {

                    stack.push(node.key);
                }


            }

            return stack;

        };

        let selected = [];

        this.treePerfil.forEach(tree => {

            selected = recursivo({nodes: tree.getTreeNodes(), stack: selected});

        });

        return selected;

    }

    setSelectedItens(keys: string[]) {

        const recursivo = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {

                    count = recursivo(node.children);

                } else if (keys.indexOf(node.key) > -1) {

                    count++;

                }

                node.isChecked = count > 0;
                hasChecked += count;

            }

            return hasChecked;

        };

        this.treePerfil.forEach(tree => {

            recursivo(tree.getTreeNodes());

        });

    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    template: `
        <router-outlet></router-outlet>
    `,
})
export class MenuComponent {
}

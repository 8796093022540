import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from "../../../core/helpers";

@Injectable({
  providedIn: 'root'
})
export class ComplianceConfiguracoesService {

  constructor( private http: HttpClient) { }

  retornaValorTabela(key: string): Observable<any> {

    return this.http.get(`${environment.apiUrl}/configuracao/value?key=` + key);

  }

  retornaComboResponsaveis(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/filtros/responsaveis/select`);

  }

  retornaResponsaveisPorEmpresa(filtros): Observable<any> {

    const queryStr = Helpers.montaQueryString(filtros);

    return this.http.get(`${environment.apiUrl}/compliance/notas/analises/configuracoes/responsaveis${queryStr}`);

  }

  alteraResponsaveisPorEmpresa(data): Observable<any> {

    return this.http.post(`${environment.apiUrl}/compliance/notas/analises/configuracoes/responsaveis`, data);

  }

  atualizaValorTabela(key: string, data): Observable<any> {

    return this.http.post(`${environment.apiUrl}/configuracao/atualizar?key=` + key, data);

  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class NotasEmitidasEmpresaService extends AbstractService<any> {

    id = '';
    url = environment.apiUrl;

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaCabecalho(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/sefazErp/notas/${id}/cabecalho`);
    }

    retornaContadores(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/sefazErp/notas/${id}/cabecalho`);
    }

    montaUrl() {
        this.url = environment.apiUrl + '/check/sefazErp/notas/' + this.id;
    }

    solicitarExportar(filtros, tipo, empresaId, checkSefazErpSumarioId): any {

        const data = [];

        filtros.forEach((filtro) => {

            data.push({
                key: filtro.key,
                value: filtro.value
            });

        });

        data.push({
            key: 'tipoExportador',
            value: tipo
        });

        data.push({
            key: 'empresa_id',
            value: empresaId
        });

        data.push({
            key: 'checkSefazErpSumario_id',
            value: checkSefazErpSumarioId
        });

        data.push({
            key: 'tipo',
            value: 'sefazErp'
        });

        const queryStr = Helpers.montaQueryString(data);

        return this.http.post(`${environment.apiUrl}/exportador${queryStr}`, null);

    }

    downloadXml(chave): any {

        return this.http.get(`${environment.apiUrl}/notas/downloadXml/${chave}`,
            {responseType: 'blob'});

    }

    downloadDanfe(chave): any {

        return this.http.get(`${environment.apiUrl}/notas/downloadDanfe/${chave}`,
            {responseType: 'blob'});

    }

    retornaQuebras(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}/quebras`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaSefazErpDetalhes(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.get(`${prefix}${queryString}`);

    }

    registrarManifestacao(dados): Observable<any> {

        return this.http.post(`${environment.apiUrl}/notas/manifestar`, dados);

    }

    // precisa de uma api para listar as empresas
    retornarComboEmpresas(obrigacao: string = null): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/combo/empresas/${obrigacao}`);

    }

}

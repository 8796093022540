import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '@models/usuario.model';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UsuarioEquipeService extends AbstractService<Usuario> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/usuarios/equipes', Usuario);
    }

    usuarioLogado: Subject<UsuarioLogado> = new Subject<UsuarioLogado>();

    exportExcel(): any {
        return this.http.get(`${this.baseUrl}/excel/exportar`, {responseType: 'blob'});
    }

    removerEquipeUsuario(idUsuario: string, idEquipe: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/cadastros/equipes/usuarios/remover?usuario_id=${idUsuario}&equipe_id=${idEquipe}`);
    }

    removerEquipe(idEquipe: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/cadastros/equipes/${idEquipe}`);
    }

    editarEquipe(id: string, dados: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/cadastros/equipes/${id}`, dados);
    }

    vincularEquipesUsuarios(selectedIds: [] | string[], itens, clearBefore = true): Observable<any> {

        const data = {
            ids: selectedIds,
            itens,
            limpar: clearBefore
        };

        return this.http.put(`${environment.apiUrl}/cadastros/equipes/usuarios`, data, this.httpOptions);
    }

    retornaComboUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }

}

import {NgModule} from '@angular/core';
import {SparkLineComponent} from '../spark-line/spark-line.component';
import {HighchartsChartModule} from 'highcharts-angular';

@NgModule({
    declarations: [
        SparkLineComponent,
    ],
    exports: [
        SparkLineComponent,
    ],
    imports: [
        HighchartsChartModule
    ]
})
export class SparkLineModule {
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '@services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { Profile } from '@models/profile.model';
import { MegamenuService } from '@components/megamenu/megamenu.service';

@Component({
    selector: 'app-register-profile',
    templateUrl: './register-profile.component.html',
    styleUrls: ['./register-profile.component.scss']
})
export class RegisterProfileComponent implements OnInit {
    profileForm: UntypedFormGroup;
    profile: Profile;
    menuTree;
    selectedKeys = [];

    constructor(private router: Router,
                private profileService: ProfileService, private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder, private toastrService: ToastrService,
                private megamenuService: MegamenuService) {
    }

    ngOnInit(): void {
        this.getMenuTree();
        this.buildForm();
    }

    getMenuTree() {
        this.megamenuService.getTree().subscribe(res => {
            this.menuTree = this.megamenuService.transformMegamenuItem(res);
        });
    }

    buildForm() {
        this.profileForm = this.formBuilder.group({
            descricao: [null, Validators.required]
        });
    }

    getControl = (name) => this.profileForm.get(name);

    updateProfile() {
        this.profile = new Profile({ descricao: this.getControl('descricao').value, itens: this.selectedKeys });
    }


    save() {
        this.updateProfile();
        if (this.profileForm.valid) {
            this.profileService.save(this.profile).subscribe((res: any) => {
                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                } else {
                    this.toastrService.error(res.error);
                }
            }, error => {
                this.toastrService.error(error);
            });
        }
    }

    selectedItems(event: any) {
        this.selectedKeys = event.keys;
    }
}

import {Injectable} from '@angular/core';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {select, Store} from '@ngrx/store';
import {Utils} from '../../shared/utils';
import {buildUrl, findComponentByUrl, urlHasParams} from '../../shared/components-helper';
import {MegamenuService} from '@components/megamenu/megamenu.service';

@Injectable({
    providedIn: 'root'
})
export class UrlHelperService {
    private tabs: Tab[];

    constructor(private tabService: TabService,
                private store: Store,
                private megamenuService: MegamenuService) {
        // @ts-ignore
        this.store.pipe(select('tabs')).subscribe((tabs: Tab[]) => {
            this.tabs = tabs;
        });
    }

    checkUrl(url: string, isLogged: boolean = true): string {

        // if (!this.exists(url)) {
        /*const returnUrl = localStorage.getItem('returnUrl');
        const urlSearch = JSON.parse(localStorage.getItem('urlSearch'));

        if (returnUrl) {

            url = returnUrl;

            if (urlSearch) {
                url += this.montaQueryString(urlSearch);
            }

        }

        const urlSplit = url.split('?');

        const component = findComponentByUrl(urlSplit[0]);

        if (component && url !== '/') {

            // const data = urlHasParams(component) ? { id: this.extractId(url) } : {};
            const data = this.queryStringToJSON(url);

            setTimeout(() => {
                this.openTab(urlSplit[0], urlSplit[1], data);
            }, 300);

            return null;

        } else {

            this.tabService.setCurrentTabIndex = this.tabs.findIndex((tab) => tab.url === urlSplit[0]);

        }*/

        if (!this.exists(url)) {
            const component = findComponentByUrl(url);

            if (component && url !== '/') {
                const data = urlHasParams(component) ? {id: this.extractId(url)} : {};
                const newTab = new Tab(component.name, component.title, url, component.urlType, data);
                this.tabService.addTab(newTab);
                return null;
            } else {

                if (isLogged) {

                    this.megamenuService.getTree().subscribe((data: any[]) => {
                        data.forEach(tree => {
                            tree.children.forEach(item => {

                                if (item.url.includes(url)) {
                                    const newTab = new Tab('ExternalPageComponent', item.title, item.url, item.urlType, {});
                                    this.tabService.addTab(newTab);
                                }
                            });
                        });
                    });
                }
            }

        } else {
            this.tabService.setCurrentTabIndex = this.tabs.findIndex((tab) => tab.url === url);
        }

        return;
    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }
        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;

    }

    extractId(url: string) {
        const splittedUrl = url.split('/');
        return splittedUrl[splittedUrl.length - 1];
    }


    exists(url: string) {
        let exists = true;
        const matchUrl = this.tabs.find(tab => tab.url === url);
        if (Utils.isEmpty(matchUrl) && url !== '/home' && url !== '/') {
            exists = false;
            const componentFound = findComponentByUrl(url);
            if (componentFound && componentFound.name !== 'ErrorComponent') {
                const splittedUrl = url.split('/');
                const splittedTabUrl = componentFound.url.split('/');
                if (urlHasParams(componentFound)) {
                    if (splittedUrl[splittedUrl.length - 1] === splittedTabUrl[splittedTabUrl.length - 1]) {
                        exists = true;
                    }
                }
            }
        }
        return exists;
    }

    openTab(path: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

    }

}

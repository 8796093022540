import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class ResponsaveisService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/cadastros/responsaveis';

    constructor(http: HttpClient) {
        super(http, '/cadastros/responsaveis/', InscricaoRegime);
    }


    cadastrar(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/responsavel`, dados);

    }

    removerResponsavel(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/responsavel/detalhe/${id}`);

    }

    retornaComboResponsaveisEmail(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/compliance/notas/responsaveis/email`);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';

@Injectable({providedIn: 'root'})
export class ConfiguracoesService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricoesRegimes', null);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    cadastrarConfiguracaoERP(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/integracoes/erp/configuracoes/gravar`, data);
    }

    removerConfiguracaoERP(id): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/integracoes/erp/configuracoes/remover/${id}`);
    }

    retornaCamposConfigERP(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/erp/configuracoes/detalhe/${id}`);

    }

    alterarConfigERP(id: string, data: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/integracoes/erp/configuracoes/gravar/${id}`, data);

    }

    copiarConfigERP(data: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/integracoes/erp/configuracoes/replicar`, data);

    }

}

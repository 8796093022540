import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class NotasEmitidasService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornaCompetencias() {

        return this.http.get(`${environment.apiUrl}/check/sefazErp/competencias`);
    }


    retornaContadores(mes, ano): Observable<any> {

        const mesAno = mes + '-' + ano;

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-competencias/${mesAno}`);

    }

    retornaSefazErp(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.get(`${prefix}${queryString}`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

    exportExcel(tabela: string, mes: number, ano: number, filtros: any): any {

        const queryParams = Helpers.montaQueryString(filtros);
        let mesString = '';
        if (Number(mes) < 10) {
            mesString = '0' + mes;
        }else{
            mesString = mes.toString();
        }
        const mesAno = mesString + '-' + ano;

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/${tabela}/${mesAno}/exportar${queryParams}`,
            {responseType: 'blob'});

    }

    retornaTipos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/erp/tabela`);
    }

    retornaComboGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

}

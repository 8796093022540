<nz-layout>
    <app-header *ngIf="isLogged"></app-header>
    <nz-content #mainContent *ngIf="isLogged && getUserName" id="main-content"
                [class.main-content]="isLogged"
                stickyHeaderDirective
                [initTarget]="mainContent">
        <div [ngStyle]="!staticGuide && {'opacity' : '0'}" style="transition: all 1s ease; opacity: 1;">
            <div class="arrow">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div style="position: absolute;top: 80px;">
                <h2 nz-typography style="left: 140px;position: relative;top: 45px; color: #964b00;">Olá {{getUserName}},
                    clique aqui para começar!</h2>
            </div>
        </div>
        <div [ngStyle]="!staticGuide && {'opacity' : '0', 'position' : 'absolute'}">
            <messages-list></messages-list>
        </div>
        <section *ngFor="let tab of getTabs() | async" [class.app-tab-disabled]="!tab.active" style="height: 100%;">
            <app-tab-content [tab]="tab" style="height: 100%;"></app-tab-content>
            <!--            <app-tab-content *ngIf="tab.name !== 'ExternalPageComponent'" [tab]="tab" style="height: 100%;"></app-tab-content>-->
            <!--            <app-tab-content *ngIf="tab.name === 'ExternalPageComponent'" [tab]="tabEmbedded" style="border: 5px solid red;height: 100%;"></app-tab-content>-->
        </section>
    </nz-content>
    <router-outlet></router-outlet>
    <!--<router-outlet name="home"></router-outlet>-->

    <div id="lixeira-tab">
        <i class="fa-solid fa-trash-can"></i>
        <span>Solte aqui para remover</span>
    </div>
</nz-layout>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class GuiasArquivosService extends AbstractService<any> {

    baseUrlEstaduais = environment.apiUrl + '/integracoes/guias-arquivos/estaduais/';
    baseUrlMunicipais = environment.apiUrl + '/integracoes/guias-arquivos/municipais/';
    baseUrlFederais = environment.apiUrl + '/integracoes/guias-arquivos/federais/';
    baseUrlNaoIdentificadas = environment.apiUrl + '/integracoes/guias-arquivos/';

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornaGuia(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${id}`);
    }

    removerGuia(id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${id}`);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsUfs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/filtros/uf`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    formataCompetenciaBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaObrigacoes(ano: number, filtros): any {

        filtros.ano = ano;

        if (filtros.competenciaMesAno && typeof filtros.competenciaMesAno.getMonth === 'function') {
            filtros.competencia = filtros.competenciaMesAno.getMonth() + 1;
            filtros.ano = filtros.competenciaMesAno.getFullYear();
            filtros.competenciaMesAno = null;
        }

        const query = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/armazenamentos${query}`);
    }

    exportDocumento(name): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/download/${name}`,
            {responseType: 'blob'});
    }

    uploadPlanilha(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/integracoes/guias-arquivos/importar`, formData);
    }

    gravarArquivo(arquivoId, formData: any): Observable<any> {

        if (formData.emissaoData) {
            formData.emissaoData = this.formataDateBD(formData.emissaoData);
        }

        if (formData.vencimentoData) {
            formData.vencimentoData = this.formataDateBD(formData.vencimentoData);
        }

        if (formData.competencia) {
            formData.competencia = this.formataCompetenciaBD(formData.competencia);
        }

        return this.http.put(`${environment.apiUrl}/integracoes/guias-arquivos/detalhe/${arquivoId}`, formData);

    }

    retornaSelectsIdentificadores(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/guias-arquivos/filtros/identificadores`);
    }

}

import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ComplianceBaixasEAutomacoesService} from './compliance-baixas-e-automacoes.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {NzTableQueryParams} from 'ng-zorro-antd/table';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-compliance-baixas-e-automacoes',
    templateUrl: './compliance-baixas-e-automacoes.component.html',
    styleUrls: ['./compliance-baixas-e-automacoes.component.scss']
})
export class ComplianceBaixasEAutomacoesComponent extends AbstractListTable<any> implements OnInit {

    status = false;
    qtdFiltrosAtivos = 0;

    pageSize = 50;
    pageIndex = 1;

    formFiltrar: FormStack;
    formAlterar: FormStack;

    checkedItems = new Set<string>();
    checked = false;
    pagination: Pagination;
    loading = true;
    currentParams: NzTableQueryParams;
    currentSearch: string;

    indeterminate = false;

    items: any = [];

    empresaSelecionada = null;

    alterandoEmMassa = false;
    alterando = {
        compliance: {},
        cnd: {},
        dec: {},
    };

    arraySelectEmpresa = [];
    arraySelectGrupos = [];

    constructor(
        private fb: UntypedFormBuilder,
        private service: ComplianceBaixasEAutomacoesService,
        private toastService: ToastrService,
        private tabService: TabService) {
        super(service, {}, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                compliance: [null, null],
                baixa_obrigacao: [null, null],
                grupo_empresarial_id: [null, null],
                empresa_id: [null, null]
            })
        };

        this.formAlterar = {
            modalVisible: false,
            formGroup: this.fb.group({
                compliance: [null, null],
                baixa_obrigacao: [null, null],
                dec: [null, null],
                cnd: [null, null]
            })
        };

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });

    }

    ngOnInit() {
        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.currentSearch = '';

        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        params.filter = this.currentParams.filter;

        this.currentParams = params;

        this.loading = true;

        this.calculaBadgeFiltros();

        const url = this.service.baseUrl + '/tabela';

        this.service.listToTable(params, search, url).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.refreshCheckedStatus();

            this.loading = false;
        });

    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    modalAlterar(visible: boolean) {
        this.formAlterar.modalVisible = visible;

        if (!visible) {
            this.empresaSelecionada = null;
            this.formAlterar.formGroup.reset();
        }
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    alterarCompliance(id, data, tipo: string): void {

        this.alterando[tipo][id] = true;

        data.ids = [id];

        this.service.alterarConfiguracoes(data).subscribe((response) => {

            this.refreshCheckedStatus();
            this.alterando[tipo][id] = false;

            this.queryTable(this.currentParams, this.currentSearch);

        }, error => {
            this.toastService.error(error.error.message);
            this.alterando[tipo][id] = false;
        });

    }

    alterarBaixaObrigacao(empresa) {

        this.empresaSelecionada = empresa;
        this.formAlterar.formGroup.get('baixa_obrigacao').setValue(this.empresaSelecionada.baixa_obrigacao.toString());
        this.modalAlterar(true);
    }

    alterarEmMassa(): void {

        this.alterandoEmMassa = true;

        const data: any = {};

        if (this.empresaSelecionada) {
            data.ids = [this.empresaSelecionada.empresa_id];
            data.baixa_obrigacao = this.empresaSelecionada.baixa_obrigacao === 1 ? '0' : '1';
        } else {
            data.ids = Array.from(this.checkedItems);

            if (this.formAlterar.formGroup.get('compliance').value) {
                data.compliance = this.formAlterar.formGroup.get('compliance').value;
            }

            if (this.formAlterar.formGroup.get('baixa_obrigacao').value) {
                data.baixa_obrigacao = this.formAlterar.formGroup.get('baixa_obrigacao').value;
            }

            if (this.formAlterar.formGroup.get('cnd').value) {
                data.cnd = this.formAlterar.formGroup.get('cnd').value;
            }

            if (this.formAlterar.formGroup.get('dec').value) {
                data.dec = this.formAlterar.formGroup.get('dec').value;
            }
        }

        if (!data.compliance && !data.baixa_obrigacao && !data.cnpj && !data.dec) {
            this.toastService.warning('Preencha pelo menos um campo para alteração!');
            this.alterandoEmMassa = false;
            return;

        } else if (this.empresaSelecionada && !this.formAlterar.formGroup.get('baixa_obrigacao').value) {
            this.toastService.warning('Selecione uma opção!');
            this.alterandoEmMassa = false;
            return;
        }

        this.service.alterarConfiguracoes(data).subscribe((response) => {

            this.refreshCheckedStatus();
            this.alterandoEmMassa = false;
            this.modalAlterar(false);
            this.queryTable(this.currentParams, this.currentSearch);

            this.resetCheckedSet();
            this.formAlterar.formGroup.reset();

        }, error => {
            this.toastService.error(error.error.message);
            this.alterandoEmMassa = false;
        });

    }

    openTab(componentName: string, queryParams?: string, data?: any, close: boolean = true) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const cnpj = data.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        const newTab = new Tab(component.name, cnpj + ' - Detalhe da Empresa', url, component.urlType, data);
        if (close) {
            this.tabService.closeAndAddTab(newTab);
        } else {
            this.tabService.addTab(newTab);
        }

    }

    btnResetSearch() {
        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clickEvent() {
        this.status = !this.status;
    }

    refreshCheckedStatus() {

        const listOfEnabledData = this.items.filter(({disabled}) => !disabled);
        this.checked = listOfEnabledData.every(({cnpj}) => this.checkedItems.has(cnpj));
        this.indeterminate = listOfEnabledData.some(({cnpj}) => this.checkedItems.has(cnpj)) && !this.checked;

    }

    resetCheckedSet() {

        this.checkedItems = new Set<string>();

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        if (this.checkedItems.size !== this.items.length) {
            this.checked = false;
        }

    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        this.items.forEach(({empresa_id}) => this.updateCheckedSet(empresa_id, checked));

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

}

import {componentsConfig} from './constants/componentsConfig.const';

export function getComponentConfigByName(componentName: string) {
    for (const key in componentsConfig) {
        if (componentsConfig[key].name === componentName) {
            return componentsConfig[key];
        }
    }
    return null;
}

export function urlHasParams(component: any) {
    return component ? component.url.includes('/:id') : false;
}

export function buildUrl(component: any, params?: string): string {
    if (component){
        return urlHasParams(component) ? component.url.replace(':id', params) : component.url;
    } else {
        return '/home';
    }
}


export function findComponentByUrl(url: string) {
    for (const key in componentsConfig) {
        const baseUrl = componentsConfig[key].url.replace('/:id', '');
        if (baseUrl && url && url.startsWith(baseUrl)) {
            return componentsConfig[key];
        }
    }

    // return componentsConfig.ExternalPageComponent;
    return null;
}

import BulkChanges from '@models/entity.model';
import {Utils} from '../utils';
import {CrudModel} from '@models/crud.model';

export class Usuario extends CrudModel implements BulkChanges {
    id: string;
    nome: string;
    primeiroNome: string;
    sobreNome: string;
    email: string;
    token: string;
    telefone: string;
    cpf: string;
    loginMFA: number;
    nascimentoData: string;
    departamento: string;
    login: string;
    senha: string;
    senhaValidadeData: string;
    senhaAlteracaoData: string;
    // tslint:disable-next-line:variable-name
    perfil_id: string;
    // tslint:disable-next-line:variable-name
    perfil_descricao: string;
    admin: number;
    beta: number;
    suporte: number;
    ativo: number;
    ultimoLoginData: string;
    penultimoLoginData: string;
    // tslint:disable-next-line:variable-name
    updated_at: string;
    // tslint:disable-next-line:variable-name
    created_at: string;
    visibilidades: [];
    notificacaoFrequencia: string;
    notificacaoHorario: string;
    notificacaoViaEmail: number;
    srcAvatar: string;
    tempoSessao: number;

    constructor(obj?: any) {

        super(CrudModel);

        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

            if (this.nome) {

                const partNames = this.nome.split(' ');

                this.primeiroNome = partNames.shift();
                this.sobreNome = partNames.length > 0 ? partNames.join(' ') : null;

            }

        }

    }

    convertToSave() {

        const properties = [];
        const usuario = new Usuario();

        for (const prop of properties) {

            usuario[prop] = this[prop];

        }

        if (Utils.isEmpty(usuario.id)) {

            delete usuario.id;

        }

        return usuario;

    }

    toBulk(ids: string | string[], itens: string[]) {

        return {
            ids,
            itens,
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            usuarios: checkedItems,
            visibilidades: propertyChanged
        };
    }
}

<nz-page-header class="site-page-header" nzTitle="Check" nzSubtitle="EFD Contribuições x Tributação">
    <nz-page-header-extra>
        <div class="d-flex align-items-center">
            <nz-alert nzType="info" *ngIf="checkedItems.size > 0" nzShowIcon [nzMessage]="checkedMessage"
                      style="margin-right: 15px;">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }}
                            <span *ngIf="checkedItems.size === 1">Registro selecionado</span>
                            <span *ngIf="checkedItems.size > 1">Registros selecionados</span>
                            <i nz-icon nzType="close" nzTheme="outline" style="margin-left: 10px; cursor: pointer"
                               (click)="closeSelecionados();"></i>
                        </div>

                    </div>
                </ng-template>
            </nz-alert>
            <nz-button-group>
                <button nz-button nzType="primary" nzGhost
                        style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                        (click)="retornaDados(true);"
                >
                    <i nz-icon nzType="reload" nzTheme="outline"></i>
                    Atualizar
                </button>
                <button nz-button nzType="primary" nzGhost
                        (click)="modalCadastrar(true);"
                >

                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Nova
                </button>

                <button [nzDropdownMenu]="menuAcoes" nzType="primary" nzGhost
                        nz-button
                        nz-dropdown>
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>

                <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }">
                    <button nz-button nzType="primary" nzGhost (click)="modalFiltrar(true);"
                            style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadings.reprocessando"
                                    nz-menu-item (click)="reprocessar();">
                                    Reprocessar
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadings.reprocessando"
                                    nz-menu-item (click)="remover();">
                                    Remover
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </nz-button-group>
        </div>
    </nz-page-header-extra>
</nz-page-header>
<ng-container *ngFor="let item of items">
    <nz-card (click)="onCardChecked(item.id)" class="list-card" [ngClass]="checkedItems.has(item.id) && 'active'"
             [nzLoading]="dadosLoading">
        <nz-content>
            <nz-row>
                <nz-col [nzSpan]="15" class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px;"
                             class="d-flex justify-content-center align-items-center">
                            <svg width="40" height="40" [data-jdenticon-value]="item?.unidade"></svg>
                        </div>
                        <div style="margin-left: 15px;">
                            <div style="margin-bottom: 10px">
                                <strong>
                                    {{modoDemo(item?.unidade, 'empresaCodigoNome')}}
                                </strong>
                                ({{modoDemo(item?.cnpj | cpfCnpj, 'cnpj')}})
                            </div>
                            <div style="margin-bottom: 10px">
                                <span style="margin-right: 5px; font-weight: 500; color: #6b6f82;">
                                    <i class="far fa-calendar"></i> Competência:
                                    {{item.competencia | date: 'MM/yyyy'}}
                                </span>
                                <span style="color: #28d094;" *ngIf="item.spedAlterados > 0">
                                    <i class="fas fa-check-circle"></i>
                                    {{item.spedAlterados + ' SPED(s) alterados'}}
                                </span>
                            </div>
                            <div>
                                <nz-tag nzColor="{{item.statusCor}}">{{item.statusDescricao}}</nz-tag>
                                <!--<nz-avatar nzText="AL"></nz-avatar>-->
                                <span style="font-weight: 500;color: #6b6f82;">{{item.cadastroUsuarioNome ? item.cadastroUsuarioNome : '-'}}
                                    solicitou em {{(item.cadastroData | date:'dd/MM/yyyy HH:mm')}}</span>
                            </div>
                        </div>
                    </div>
                </nz-col>
                <nz-col [nzSpan]="9">
                    <nz-progress
                            [nzPercent]="item.porcentagemProcessamento"
                            *ngIf="item.statusProcessamento === 1" [nzShowInfo]="false"></nz-progress>
                    <nz-progress
                            [nzPercent]="0"
                            *ngIf="item.statusProcessamento === 0" [nzShowInfo]="false"></nz-progress>
                    <div class="d-flex justify-content-between align-items-center"
                         *ngIf="item.statusProcessamento === 2">
                        <!--<div class="d-flex justify-content-center align-items-center" style="flex-direction: column;" >
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#28d094'}">
                                <i class="far fa-file-alt"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_ec ? (item.status_ec | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#ff9149'}">
                                <i class="far fa-file-alt"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_ei ? (item.status_ei | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>-->
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#9c27b0'}">
                                <i class="far fa-file-alt"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_nnm ? (item.status_nnm | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#ff4961'}">
                                <i class="fas fa-not-equal"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_ecd ? (item.status_ecd | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                            <div class="d-flex justify-content-center align-items-center itemContador"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#ff4961'}">
                                <i class="fas fa-not-equal"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_scd ? (item.status_scd | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>
                        <!--<div class="d-flex justify-content-center align-items-center" style="flex-direction: column;" >
                            <div class="d-flex justify-content-center align-items-center itemContador itemContadorDisabled"
                                 [ngStyle]="{'background-color' : item.statusProcessamento !== 2 ? '#f5f5f5' : '#333333'}">
                                <i class="fas fa-not-equal"
                                   [ngClass]="{'iconeContador': item.statusProcessamento === 2, 'iconeContadorDisabled' : item.statusProcessamento !== 2}"></i>
                            </div>
                            <span style="display: block; font-weight: bold; font-size: 16px;">{{item.status_dv ? (item.status_dv | number:'1.0-0' : 'pt-br') : '0'}}</span>
                        </div>-->
                    </div>
                    <div style="text-align: center;">
                        <button nz-button nzBlock nzType="dashed" *ngIf="item.statusProcessamento === 2"
                                (click)="openTab('CheckEfdContribuicoesTributacaoDetalhesComponent', item.id, {id: item.id, source: 'CheckEfdContribuicoesTributacaoDetalhesComponent'})">
                            Ver Resultado
                        </button>
                        <button nz-button nzBlock nzType="dashed" disabled="true" style="margin-top: 30px;"
                                *ngIf="item.statusProcessamento !== 2"> Ver Resultado
                        </button>
                    </div>
                </nz-col>
            </nz-row>
        </nz-content>
    </nz-card>
</ng-container>

<div class="semDados" *ngIf="!items?.length">
        <span>
            <nz-empty [nzNotFoundContent]="contentTpl" nzNotFoundImage="simple"></nz-empty>
        </span>

    <ng-template #contentTpl>
    <span class="msgSemDados">
      Não há dados
    </span>
    </ng-template>
</div>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formCadastrar.formGroup.value.empresa_id"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="modoDemo(opt.nome, 'empresaCodigoNome') + ' ' + modoDemo(opt.cnpj | cpfCnpj, 'cnpj')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.competencia">
                    <nz-date-picker [nzFormat]="'MM/yyyy'" nzMode="month"
                                    formControlName="competencia" style="width: 100%;"
                                    nzPlaceHolder="Selecione"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible"
          [nzTitle]="'Solicitar Análise'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCadastrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formCadastrar.formGroup.value.empresa_id"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="modoDemo(opt.nome, 'empresaCodigoNome') + ' ' + modoDemo(opt.cnpj | cpfCnpj, 'cnpj')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Competência</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'MM/yyyy'" nzMode="month"
                                    formControlName="competencia" style="width: 100%;"
                                    nzPlaceHolder="Selecione"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false);">Cancelar</button>
        <button [nzLoading]="loadings.solicitarAnalise" nz-button nzType="primary" (click)="confirmaCadastrar()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-page-header class="site-page-header" nzTitle="Detalhe do Perfil"
                nzSubtitle="Permissões de acesso atribuídos ao perfil"></nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card [nzTitle]="extraTitle" [nzExtra]="extraAlterar">

                <ng-template #extraTitle>
                    <span style="margin-right: 5px">
                        {{perfil.descricao}}
                    </span>

                    <nz-tag *ngIf="perfil.descricao === 'Operadores' || perfil.descricao == 'Administradores'"
                            nzColor="default"
                            style="font-size: 10px">
                        Bloqueado
                    </nz-tag>

                    <nz-tag *ngIf="perfil.admin == 1 && !alterandoAdmin" nzColor="warning"
                            style="font-size: 10px">
                        Admin
                    </nz-tag>
                    <nz-tag *ngIf="perfil.admin == 0 && alterandoAdmin" nzColor="warning"
                            style="font-size: 10px">
                        Admin
                    </nz-tag>

                </ng-template>

                <ng-template #extraAlterar>
                    <button *ngIf="perfil.descricao !== 'Operadores' && perfil.descricao !== 'Administradores'"
                            disabled="{{alterandoAdmin}}"
                            nz-button nzType="link" [nzLoading]="gravando" (click)="editar(formDadosCadastrais)">Editar
                    </button>
                </ng-template>
                <!--                <nz-list>-->
                <!--                    <nz-list-item>-->
                <!--                        <nz-list-item-meta nzDescription="Suporte Disponível">-->
                <!--                            <nz-list-item-meta-title>-->
                <!--                                {{perfil.ativoSuporte ? 'Sim' : 'Não'}}-->
                <!--                            </nz-list-item-meta-title>-->
                <!--                        </nz-list-item-meta>-->
                <!--                    </nz-list-item>-->
                <!--                </nz-list>-->

                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{perfil.created_at ? (perfil.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{perfil.updated_at ? (perfil.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">

            <nz-card>

                <nz-card-tab>
                    <nz-tabset
                            [nzSize]="'large'"
                            [nzTabBarExtraContent]="extraPermissoes"
                            [(nzSelectedIndex)]="tabNumber"
                            (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzTitle="Permissões de Acesso"></nz-tab>
                        <nz-tab nzTitle="Membros"></nz-tab>
                        <nz-tab nzTitle="Modo Admin"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>

                <nz-tree #treePerfil [nzData]="permissoesTree"
                         [nzShowExpand]="true"
                         [nzExpandAll]="true"
                         [nzBlockNode]="true"
                         *ngIf="tabNumber == 0"
                         [nzTreeTemplate]="nzTreeTemplate"
                         nzCheckable
                         nzMultiple
                >
                </nz-tree>

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="items"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination?.total"
                          [nzPageSize]="pagination?.per_page"
                          [nzPageIndex]="pagination?.current_page"
                          *ngIf="tabNumber == 1"
                          [nzShowTotal]="rangeTemplate"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <!--                        <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate" id="check-all">-->
                        <!--                        </th>-->
                        <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="login" [nzSortFn]="true">Login</th>
                        <th nzColumnKey="email" [nzSortFn]="true">Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let usuario of basicTable.data">
                        <!--                        <td nzLeft (nzCheckedChange)="onItemChecked(usuario.id, $event)"-->
                        <!--                            [nzChecked]="checkedItems.has(usuario.id)"-->
                        <!--                            [nzDisabled]="usuario.disabled">-->
                        <!--                        </td>-->
                        <td nzLeft>
                            <a nz-button nzType="link"
                               (click)="openTab('/administracao/usuario/detalhe', usuario.id, {id: usuario.id})">{{usuario?.nome}}</a>
                        </td>
                        <td nzLeft>{{usuario?.login}}</td>
                        <td>{{usuario?.email}}</td>

                    </tr>
                    </tbody>
                </nz-table>


                <div *ngIf="tabNumber == 2" nzTitle="Informações Cadastrais">
                    <nz-list>
                        <!--                        <nz-list-item [nzExtra]="extraAlterar">-->
                        <!--                            <nz-list-item-meta nzDescription="Descrição">-->
                        <!--                                <nz-list-item-meta-title>-->
                        <!--                                    {{perfil.descricao}}-->
                        <!--                                </nz-list-item-meta-title>-->
                        <!--                            </nz-list-item-meta>-->

                        <!--                        </nz-list-item>-->

                        <nz-list-item>
                            <nz-list-item-meta>
                                <nz-list-item-meta-title>
                                    Admin
                                </nz-list-item-meta-title>
                                <nz-list-item-meta-description>
                                    Esse parâmetro define o Perfil de Acesso como Administrador de sistema,
                                    habilita-lo possibilita que os membros do perfil realizem as inclusões
                                    e alterações em cadastros de empresas, usuários, visibilidades e demais
                                    configurações
                                    do modo Administrador.
                                </nz-list-item-meta-description>
                            </nz-list-item-meta>
                            <ul nz-list-item-actions>
                                <nz-list-item-action>
                                    <nz-switch
                                            *ngIf="perfil.descricao === 'Operadores' || perfil.descricao === 'Administradores' || alterandoAdmin else elseSwitch"
                                            nz-tooltip
                                            [nzTooltipTitle]="'Essa ação não é permitida, pois o perfil é padrão e está bloqueado.'"
                                            [nzTooltipPlacement]="'bottomLeft'"
                                            [nzDisabled]="true"
                                            nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                    ></nz-switch>
                                    <ng-template #elseSwitch>
                                        <nz-switch
                                                [(ngModel)]="perfil.admin"
                                                (ngModelChange)="showConfirm()"
                                                nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                        ></nz-switch>
                                    </ng-template>
                                </nz-list-item-action>
                            </ul>
                        </nz-list-item>

                    </nz-list>
                </div>


                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

                <ng-template #nzTreeTemplate let-node let-origin="origin">
                     <span id="{{ node.key }}" title="{{ origin.tipo === 'menu' ? origin.url : origin.description }}">
                        <span>{{ node.title }}</span>
                        <i class="fas fa-key"
                           style="margin-left: 10px; font-size: 10px;"
                           *ngIf="origin.tipo === 'permissão'"
                        ></i>
                        <span *ngIf="origin.tipo === 'menu' && (origin.beta || origin.alfa)"
                              [ngClass]="origin.beta ? 'beta-tag' : (origin.alfa ? 'alfa-tag' : '')"
                              class="beta-tag">{{origin.beta ? 'beta' : (origin.alfa ? 'alfa' : '')}}
                        </span>
                     </span>

                </ng-template>

            </nz-card>

            <ng-template #extraPermissoes>
                <button *ngIf="tabNumber == 0" nz-button nzType="primary" [nzLoading]="gravando"
                        (click)="gravarPermissoes()">Gravar Alterações
                </button>

                <div class="nav-item d-flex" *ngIf="tabNumber == 1">

                    <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                            [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item nz-button (click)="openTab('/administracao/usuarios')">Gerenciar Usuários
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>


            </ng-template>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar descrição do perfil'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" size="60">
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>

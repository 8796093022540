import {Component, OnInit} from '@angular/core';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {HistoricoDeAcessoService} from './historico-de-acesso.service';
import moment from 'moment';
import {UserService} from "@services/user.service";

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}

@Component({
    selector: 'app-historico-de-acesso',
    templateUrl: './historico-de-acessos.component.html',
    styleUrls: ['./historico-de-acessos.component.scss']
})
export class HistoricoDeAcessosComponent implements OnInit {

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };
    currentSearch: string;
    historico = [];

    cardLoading = false;
    statusOne = false;

    formFiltros: FormStack;
    qtdFiltros = 0;
    paginations: Pagination = {
        per_page: 50,
        current_page: 1,
        from: 1,
        to: 50,
        total: 500,
        last_page: 10
    };

    comboUsuarios = [];

    usuarioLogado: any;

    constructor(
        private fb: FormBuilder,
        private service: HistoricoDeAcessoService,
        private userService: UserService,
        private toastService: ToastrService) {

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                usuario_id: [null, null],
                dataHoraInicio: [null, null],
                dataHoraFim: [null, null],
                usuario_console: [null, null],
            })
        };

        this.retornaComboUsuarios();
    }

    ngOnInit(): void {
        this.getLoggedUser();

        this.getHistorico(this.currentParams);

    }

    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.usuarioLogado = user);
    }

    retornaComboUsuarios() {

        this.comboUsuarios = [];

        this.service.retornaComboUsuarios().subscribe((retorno: any) => {
            this.comboUsuarios = retorno;
        });

    }

    getHistorico(params: NzTableQueryParams, search?: any) {

        this.currentParams = params;

        this.cardLoading = true;
        this.historico = [];

        this.currentParams.filter = [];

        this.calculaBadgeFiltros();

        let error = false;

        const datas: any = {};

        if (this.formFiltros.formGroup.get('dataHoraInicio').value) {

            const diferencaEmDias = moment().diff(moment(new Date(this.formFiltros.formGroup.get('dataHoraInicio').value)), 'days');

            if (diferencaEmDias > 90) {
                this.toastService.warning('A data de início não pode ter mais que 90 dias!');
                error = true;
            }

            datas.dataHoraInicio = moment(new Date(this.formFiltros.formGroup.get('dataHoraInicio').value)).format('YYYY-MM-DD')

        }

        if (this.formFiltros.formGroup.get('dataHoraFim').value) {

            const diferencaEmDias = moment().diff(moment(new Date(this.formFiltros.formGroup.get('dataHoraFim').value)), 'days');

            if (diferencaEmDias > 90) {
                this.toastService.warning('A data de fim não pode ter mais que 90 dias!');
                error = true;
            }

            datas.dataHoraFim = moment(new Date(this.formFiltros.formGroup.get('dataHoraFim').value)).format('YYYY-MM-DD')

        }

        if (!error) {
            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                if (chave === 'dataHoraInicio' || chave === 'dataHoraFim') {
                    this.currentParams.filter.push({key: chave, value: datas[chave]});
                } else {
                    this.currentParams.filter.push({key: chave, value: valor?.toString()});
                }
            }


        }

        this.service.getHistorico(this.currentParams, this.currentSearch).subscribe(
            (response: any) => {
                this.historico = response.data;

                this.paginations = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.cardLoading = false;

            }, (error) => {
                this.toastService.error(error.error.mesage);
                this.cardLoading = false;
            });
    }

    listByTableHistorico(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.current_page) {
            this.getHistorico(params);
            this.paginations.current_page = params.pageIndex;
        }

    }

    modalFiltrarVisible(visible: boolean) {
        this.formFiltros.modalVisible = visible;
    }

    confirmaFiltrar() {


        this.currentParams.pageIndex = 1;

        this.getHistorico(this.currentParams, this.currentSearch);
        this.modalFiltrarVisible(false);


    }

    limparFiltros(): void {
        this.formFiltros.formGroup.reset();
        this.currentSearch = null;
        this.currentParams.filter = [];
        this.calculaBadgeFiltros();
        this.historico = [];

        setTimeout(() => {
            this.getHistorico(this.currentParams);
        }, 50);
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltros = 0;

        if (typeof this.formFiltros !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {
                if (valor && chave !== 'pagina' && valor && chave !== 'quantidade' && chave !== 'procurar'
                    && chave !== 'ordenar' && chave !== 'sentido') {

                    this.qtdFiltros++;
                }
            }
        }
    }

    clickEvent(card) {
        switch (card) {
            case 'one':
                this.statusOne = !this.statusOne;
                break;
        }
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {formatDate} from '@angular/common';
import {Empresa} from '@models/empresa.model';
import {environment} from '../../../../../environments/environment';
import {Helpers} from '../../../../core/helpers';

@Injectable({providedIn: 'root'})

export class EmpresaDetalheService extends AbstractService<Empresa> {

    urlCertificados = environment.apiUrl + '/cadastros/credenciais-acesso/certificados';
    urlAcessos = environment.apiUrl + '/cadastros/credenciais-acesso/acessos-estaduais';
    baseUrlIfs = environment.apiUrl + '/cadastros/empresas/inscricoesFederais';
    baseUrlIes = environment.apiUrl + '/cadastros/empresas/inscricoesEstaduais';
    baseUrlIms = environment.apiUrl + '/cadastros/empresas/inscricoesMunicipais';

    constructor(http: HttpClient) {
        super(http, '/administracao/empresa', {});
    }

    get(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}`);

    }

    save(id, campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.put(`${this.baseUrl}/detalhe/${id}`, campos);

    }

    getCnd(idCnd: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/detalhe/${idCnd}`);

    }

    getDec(idDec: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/detalhe/${idDec}`);

    }


    updateCnd(idCnd: string, data: any): Observable<any> {

        if (data.dataInicio && data.dataInicio !== '' && data.dataInicio !== '') {
            data.dataInicio = Helpers.formatDateDb(data.dataInicio);
        } else {
            delete data.dataInicio;
        }

        if (data.dataFim) {
            data.dataFim = Helpers.formatDateDb(data.dataFim);
        }
        delete data.controleCertidaoEmpresa_id;


        return this.http.put(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/detalhe/${idCnd}`, data);

    }

    updateDec(idDec: string, data: any): Observable<any> {

        if (data.dataInicio) {
            data.dataInicio = Helpers.formatDateDb(data.dataInicio);
        }

        if (data.dataFim) {
            data.dataFim = Helpers.formatDateDb(data.dataFim);
        }

        delete data.domicilioEletronico_id;
        if (data.descricao) {
            data.domicilioEletronico_descricao = data.descricao;
            delete data.descricao;
        }

        return this.http.put(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/${idDec}`, data);

    }

    removerCnd(ids: string[]) {

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/remover`, {ids});

    }

    removerDec(ids: string[]) {

        return this.http.put(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/removerMassa`, {ids});

    }

    cadastrarCnd(data: any, id: any) {

        if (data.dataInicio) {
            data.dataInicio = Helpers.formatDateDb(data.dataInicio);
        }

        if (data.dataFim) {
            data.dataFim = Helpers.formatDateDb(data.dataFim);
        }

        if (id) {
            data.empresa_id = id;
            data.ie = null;
        }

        return this.http.post(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa`, data);

    }

    cadastrarDec(data: any, id: any) {

        if (data.dataInicio) {
            data.dataInicio = Helpers.formatDateDb(data.dataInicio);
        }

        if (data.dataFim) {
            data.dataFim = Helpers.formatDateDb(data.dataFim);
        }

        if (id) {
            data.empresa_id = id;
        }

        return this.http.post(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa`, data);

    }

    retornaComboUFs(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaComboCnd(uf: string | null, municipio: string = ''): Observable<any> {

        const extra: string = municipio !== '' ? '?municipio=' + municipio : '';
        return this.http.get(`${environment.apiUrl}/filtros/certidao/select/${uf}${extra}`);

    }

    retornaComboCertidoes() {

        return this.http.get(`${environment.apiUrl}/certidoes/controleCertidoesEmpresa/combo/certidoes`);
    }

    retornaComboDomicilios() {

        return this.http.get(`${environment.apiUrl}/filtros/domicilio/select`);
    }

    removeCertificados(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/${id}`);

    }

    exportExcel(certificadoId: any, senha: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/download/${certificadoId}?senha=${senha}`,
            {responseType: 'blob'});

    }

    updateAcessos(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/detalhe/${id}`, dados);

    }

    insertAcessos(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual`, dados);

    }

    retornaPortaisUF(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/portais/estaduais/select/${uf}`);

    }

    retornaCamposAcessosEstaduais(obrigacao: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/portais/dados-acesso/${obrigacao}`);

    }

    atualziarRFB(cnpj: string): Observable<any> {
        const dados = {
            documento: cnpj
        };
        return this.http.put(`${environment.apiUrl}/cadastros/empresa/consulta/rfb`, dados);
    }

    retornaReceitaFederal(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/detalhe/${empresaId}`);

    }

    removeIf(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/${id}`);

    }

    insertIf(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal`, dados);

    }

    updateIf(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoFederal/detalhe/${id}`, dados);

    }

    retornaComboMunicipios(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);

    }

    removeAcessos(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/credenciais-acesso/acesso-estadual/${id}`);

    }

    removeIe(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual/${id}`);

    }

    removeIm(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal/${id}`);

    }

    insertIe(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual`, dados);

    }

    updateIe(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoEstadual/detalhe/${id}`, dados);

    }

    insertIm(empresaId: string, dados: any): Observable<any> {

        if (empresaId) {
            dados.empresa_id = empresaId;
        }

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal`, dados);

    }

    updateIm(id: string, dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = Helpers.formatDateDb(dados.dataInicio);
        }

        if (dados.dataFim) {
            dados.dataFim = Helpers.formatDateDb(dados.dataFim);
        }

        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricaoMunicipal/detalhe/${id}`, dados);

    }

}

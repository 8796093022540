import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AnexosListComponent} from '@components/anexos-list/anexos-list.component';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';

@NgModule({
    declarations: [
        AnexosListComponent
    ],
    exports: [
        AnexosListComponent
    ],
    imports: [
        CommonModule,
        NzButtonModule,
        NzTableModule,
        NzModalModule,
        NzIconModule,
        NzSkeletonModule,
        NzTabsModule,
        NzToolTipModule
    ]
})
export class AnexosListModule { }

import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import {GruposEmpresariaisService} from '@services/grupos-empresariais.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-visibilidades',
    templateUrl: './grupos-empresariais.component.html',
    styleUrls: ['./grupos-empresariais.component.scss']
})
export class GruposEmpresariaisComponent extends AbstractListTable<Usuario> implements TabHandlerInterface {

    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: FormStack;

    cadastrando = false;

    formFiltrar: FormStack;
    formExportar: FormStack;
    status = false;
    qtdFiltrosAtivos = 0;

    formEditar: FormStack;

    loadings = {
        carregandoGrupo: false,
        editando: false,
        cadastrando: false,
        removendo: false,
        carregandoEmpresas: false,
        desvinculandoEmpresa: false,
        carregandoEmpresasGrupo: false,
        salvandoEmpresas: false,
        carregandoTodasEmpresas: false
    };

    idGrupoSelecionado = '';
    nomeGrupoSelecionado = '';

    modalListarEmpresasVisible = false;
    modalListarEmpresasGrupoVisible = false;

    arrayEmpresasGrupo: any[] = [];
    arrayTodasEmpresas: any[] = [];

    checkedItemsEmpresas = new Set<string>();

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private gruposEmpresariaisService: GruposEmpresariaisService,
        private perfilService: PerfilService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required]
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, null],
            })
        };

        this.formEditar = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, Validators.required],
                descricao: [null, Validators.required],
            })
        };
    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.gruposEmpresariaisService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {

        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.gruposEmpresariaisService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.loadings.cadastrando = true;

        if (this.formCadastrar.formGroup.valid) {

            this.gruposEmpresariaisService.cadastrar(this.formCadastrar.formGroup.value).subscribe(
                (response) => {

                    this.formCadastrar.formGroup.reset();
                    this.toastrService.success('Grupo Empresarial cadastrado com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.loadings.cadastrando = false;
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {
        this.formCadastrar.modalVisible = visible;
    }


    modalEditar(visible) {

        this.formEditar.modalVisible = visible;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        const url = environment.apiUrl + '/administracao/grupos-empresariais';

        this.loading = true;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = [];
            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clickEvent() {
        this.status = !this.status;
    }

    showConfirmRemover(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Grupo Empresarial?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    remover(id: string): void {

        this.loadings.removendo = true;
        this.gruposEmpresariaisService.remover(id).subscribe(
            (response) => {
                this.toastrService.success('Grupo Empresarial removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loadings.removendo = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loadings.removendo = false;

            }
        );
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.descricao) {
            qtd += 1;
        }
        this.qtdFiltrosAtivos = qtd;
    }

    editar(id: string): void {

        this.loadings.carregandoGrupo = true;

        this.modalEditar(true);

        this.gruposEmpresariaisService.detalhe(id).subscribe((res) => {

            this.loadings.carregandoGrupo = false;
            this.formEditar.formGroup.get('id').setValue(res.id);
            this.formEditar.formGroup.get('descricao').setValue(res.descricao);


        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.carregandoGrupo = false;
        });
    }

    confirmaEditar(): void {

        this.loadings.editando = true;

        const dados = this.formEditar.formGroup.value;

        const id = this.formEditar.formGroup.value.id;


        this.gruposEmpresariaisService.editar(id, dados).subscribe((res) => {

            this.toastService.success('Grupo Empresarial alterado com sucesso');
            this.loadings.editando = false;
            this.formEditar.formGroup.reset();
            this.modalEditar(false);
            this.queryTable(this.currentParams, this.currentSearch);


        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.editando = false;
        });

    }

    modalListarEmpresas(visible: boolean) {
        this.modalListarEmpresasVisible = visible;
    }

    onVerEmpresas(id: string, nome: string): void {

        this.idGrupoSelecionado = id;
        this.nomeGrupoSelecionado = nome;
        this.loadings.carregandoEmpresas = true;
        this.modalListarEmpresas(true);

        this.gruposEmpresariaisService.retornaEmpresasGrupo(id).subscribe((res) => {

            this.arrayEmpresasGrupo = [];
            this.arrayEmpresasGrupo = res;

            this.loadings.carregandoEmpresas = false;

        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.carregandoEmpresas = false;
        });


    }

    deleteVinculoEmpresa(id): void {

        this.loadings.desvinculandoEmpresa = true;
        this.loadings.carregandoEmpresas = true;

        this.gruposEmpresariaisService.deletarVinculoEmpresa(id).subscribe((res) => {

            this.loadings.desvinculandoEmpresa = false;
            this.toastService.success('Empresa desvinculada com sucesso!');
            this.queryTable(this.currentParams, this.currentSearch);

            this.onVerEmpresas(this.idGrupoSelecionado, this.nomeGrupoSelecionado);

        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.desvinculandoEmpresa = false;
            this.loadings.carregandoEmpresas = false;
        });

    }

    onEditarEmpresasGrupo(): void {

        this.modalListarEmpresasGrupo(true);

        this.retornaEmpresas();


    }

    retornaEmpresas(): void {

        this.loadings.carregandoTodasEmpresas = true;

        this.gruposEmpresariaisService.retornaEmpresasTodas().subscribe((res) => {

            this.arrayTodasEmpresas = [];
            this.arrayTodasEmpresas = res;
            this.loadings.carregandoTodasEmpresas = false;

        }, (res) => {
            this.loadings.carregandoTodasEmpresas = false;
            this.toastService.error(res.error.message);
        });

    }

    modalListarEmpresasGrupo(visible: boolean) {

        this.checkedItemsEmpresas.clear();
        this.arrayEmpresasGrupo.forEach((value) => {
            this.updateCheckedSetEmpresas(value.id, true);
        });

        this.modalListarEmpresasGrupoVisible = visible;


        if (!visible) {
            this.queryTable(this.currentParams, this.currentSearch);
        }

    }

    updateCheckedSetEmpresas(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsEmpresas.add(id);

        } else {

            this.checkedItemsEmpresas.delete(id);

        }
    }

    onAllCheckedEmpresas(checked: boolean): void {

        this.arrayTodasEmpresas.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSetEmpresas(id, checked));

    }

    onItemCheckedEmpresas(id: string, checked: boolean): void {

        this.updateCheckedSetEmpresas(id, checked);

    }

    salvarEmpresas(): void {

        this.loadings.salvandoEmpresas = true;

        this.gruposEmpresariaisService.salvarEmpresasGrupo(this.idGrupoSelecionado, Array.from(this.checkedItemsEmpresas))
            .subscribe((res) => {

                this.loadings.salvandoEmpresas = false;
                this.toastService.success('Empresas do Grupo Empresarial ' + this.nomeGrupoSelecionado + ' atualizadas.');
                this.modalListarEmpresasGrupo(false);
                this.onVerEmpresas(this.idGrupoSelecionado, this.nomeGrupoSelecionado);


            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.salvandoEmpresas = false;
            });
    }



}

<nz-modal [(nzVisible)]="visible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="showModal(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form [nzAutoTips]="autoTips"  nz-form [nzNoColon]="true" [formGroup]="formExportar" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option *ngFor="let op of data.tiposAceitos" [nzLabel]="op.key"
                                   [nzValue]="op.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaExportar()" [nzLoading]="loadingExportar">Exportar</button>
    </div>
</nz-modal>
import {ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tab} from '@models/tab.model';
import {select, Store} from '@ngrx/store';
import {Add, Clear, ClearOthers, Remove, Reload, Reorder} from '@actions/actions-tab';
import {setStorageTab} from '@reducers/tab.reducer';
import {AppComponent} from '../../app.component';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {UrlTypeEnum} from '../../shared/enums/url-type.enum';

@Injectable({
    providedIn: 'root',
})
export class TabService {
    public tabs: Tab[] = [];
    private currentTabIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private currentTabIndex: number;
    private currentTab: BehaviorSubject<any> = new BehaviorSubject<any>('');
    private tabs$: Observable<Tab[]>;
    private cdr: ChangeDetectorRef;

    constructor(private store: Store, private location: Location, private router: Router) {
        // @ts-ignore
        this.tabs$ = this.store.pipe(select('tabs'));

        this.tabs$.subscribe((tabs: Tab[]) => {
            this.tabs = tabs;
            this.setCurrentTabIndex = this.tabs.findIndex(tab => tab.active);
        });
    }

    set setCurrentTabIndex(index: number) {

        this.currentTabIndex = index;

        if (this.currentTabIndex > -1) {
            localStorage.setItem('currentTab', JSON.stringify(this.tabs[this.currentTabIndex]));
        }

        if (index >= 0) {
            AppComponent.hideGuideOnApp();
        }

        this.currentTabIndexSubject.next(index);

        this.currentTab.next(this.tabs[index]);

        if (this.tabs[index]) {

            setTimeout(() => {

                const returnUrl = localStorage.getItem('returnUrl');
                let urlSearch = localStorage.getItem('urlSearch');

                if (urlSearch) {
                    urlSearch = this.montaQueryString(JSON.parse(urlSearch));
                }

                let url = this.tabs[index] ? this.tabs[index].url : '/home';

                if (url === returnUrl) {
                    url = url + urlSearch;
                }

                this.location.go(url);

            }, 300);

        }
    }

    disableAllTabs() {
        for (const tab of this.tabs) {
            tab.active = false;
        }
    }

    get getCurrentTab(): Observable<Tab> {
        return this.currentTab.asObservable();
    }

    get getTabIndex(): Observable<number> {
        return this.currentTabIndexSubject.asObservable();
    }

    public removeTab(index: number) {

        const returnUrl = localStorage.getItem('returnUrl');

        if (this.tabs[index].url === returnUrl) {
            localStorage.removeItem('returnUrl');
            localStorage.removeItem('urlSearch');
        }

        let newIndex = null;

        if (this.tabs[index].data && this.tabs[index].data.source) {
            newIndex = this.tabs.findIndex((tab) => tab.componentName === this.tabs[index].data.source);
        }

        this.store.dispatch(Remove(index));

        const prevTabIndex = this.tabs.findIndex(tab => tab.componentName === JSON.parse(localStorage.getItem('currentTab')).componentName);

        newIndex = newIndex !== null ? newIndex : (prevTabIndex > -1 ? prevTabIndex : this.tabs.length - 1);

        if (newIndex === -1) {

            setTimeout(() => {

                this.location.go('/home');

            }, 300);

        }

        this.setCurrentTabIndex = newIndex;
    }

    public removeTabById(id: number) {

        const returnUrl = localStorage.getItem('returnUrl');

        let index = 0;

        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].id === id) {
                index = i;
            }
        }

        if (this.tabs[index].url === returnUrl) {
            localStorage.removeItem('returnUrl');
            localStorage.removeItem('urlSearch');
        }

        let newIndex = null;

        if (!this.currentTab.value) {
            this.setCurrentTabIndex = 0;
        }

        if (this.currentTab.value.id !== id) {
            newIndex = this.currentTab.value.id;
        } else if (this.tabs[index].data && this.tabs[index].data.source) {
            newIndex = this.tabs.findIndex((tab) => tab.componentName === this.tabs[index].data.source);
        }

        this.store.dispatch(Remove(index));

        for (let i = 0; i < this.tabs.length; i++) {
            this.tabs[i].id = i;
        }

        const prevTabIndex = this.tabs.findIndex(tab => tab.componentName === JSON.parse(localStorage.getItem('currentTab')).componentName);

        newIndex = newIndex !== null ? newIndex : (prevTabIndex > -1 ? prevTabIndex : this.tabs.length - 1);

        if (newIndex === -1) {

            setTimeout(() => {

                this.location.go('/home');

            }, 300);

        }

        this.setCurrentTabIndex = newIndex;
    }

    public removeAllTabs() {

        this.store.dispatch(Clear());

        const newIndex = this.tabs.length - 1;

        if (newIndex === -1) {

            setTimeout(() => {

                this.location.go('/home');

            }, 300);

        }

        this.setCurrentTabIndex = -1;

    }

    public removeOthersTabs(id: number) {

        const index = this.tabs.findIndex(tab => tab.id === id);

        this.store.dispatch(ClearOthers(index));

        const newIndex = this.tabs.length - 1;

        if (newIndex === -1) {

            setTimeout(() => {

                this.location.go('/home');

            }, 300);
        }
    }

    public addTab(newTab: Tab) {

        if (!newTab) {
            this.location.go('/home');

        } else {

            let index;

            if (newTab.componentName === 'ExternalPageComponent') {

                index = this.tabs.findIndex((tab) => tab.url === newTab.url);

            } else {

                index = this.tabs.findIndex((tab) => tab.componentName === newTab.componentName);

            }

            if (index > -1) {

                this.setCurrentTabIndex = index;

            } else {

                if (this.tabs.length === 0) {
                    this.currentTab = new BehaviorSubject<Tab>(newTab);
                }

                newTab.id = this.tabs.length;

                this.store.dispatch(Add(newTab));

                this.setCurrentTabIndex = this.tabs.length - 1;

            }

        }
    }

    public openInNew(tab: Tab) {
        setStorageTab([]);
        if (tab.componentName === 'ExternalPageComponent' && tab.url !== '/embedded' && tab.title !== 'Embedded') {
            localStorage.setItem('tabEmbedded', JSON.stringify(tab));
        }

        window.open(tab.url, '_blank').focus();
    }

    public reloadTab(tab: Tab) {

        this.tabs.forEach(t => {
            t.active = t.id === tab.id;
        });

        this.setCurrentTabIndex = tab.id;

        this.tabs[tab.id] = {
            data: tab.data,
            componentName: 'LoadingComponent',
            title: tab.title,
            urlType: UrlTypeEnum.nativo,
            url: tab.url,
            id: tab.id,
            active: true
        };

        setTimeout(() => {
            this.tabs.forEach(t => {
                t.active = t.id === tab.id;
            });
            this.setCurrentTabIndex = tab.id;
            this.tabs[tab.id] = tab;
            this.store.dispatch(Reload(tab));
            this.setTabIdCresc();
        }, 1500);
    }

    public reorderTabs(tabs: Tab [], index: number) {
        this.store.dispatch(Reorder(tabs));

        this.setTabIdCresc();
        setTimeout(() => {
            this.setCurrentTabIndex = index;
        }, 100);
    }

    public closeAndAddTab(newTab: Tab) {

        const index = this.tabs.length > 0 && newTab ?
            this.tabs.findIndex((tab) => tab.componentName === newTab.componentName) :
            -1;

        if (index > -1) {
            this.removeTabById(index);
        }

        this.addTab(newTab);
    }

    closeCurrentTab() {
        this.removeTab(this.currentTabIndex);
    }

    closeAll() {

        this.store.dispatch(Clear());
    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }
        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }

    setTabIdCresc(tabs?: HTMLCollection) {

        setTimeout(() => {
            const tabsDom: any = tabs || document.getElementsByClassName('ant-tabs-tab');

            let i = 0;
            for (const t of tabsDom) {

                if (i === this.tabs.length) {
                    // podem existir outras tabs na tela
                    break;
                }

                t.setAttribute('id', i);
                i += 1;
            }

        }, 500);

    }

}

import {
    Component,
    ViewChild,
    ElementRef,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {SwiperComponent} from 'swiper/angular';

// import Swiper core and required components
import SwiperCore, {
    Navigation,
    Mousewheel,
} from 'swiper/core';

// install Swiper components
SwiperCore.use([
    Navigation,
    Mousewheel
]);

interface Obrigacoes {
    obrigacaoNome: string;
    obrigacaoTotal: number;
    armazenadas: number;
    naoArmazenadas: number;
    regrasValidacaoGraves: number;
    regrasValidacaoMediasAguardando: number;
    regrasValidacaoMediasLiberadas: number;
    regrasValidacaoLeves: number;
    regrasValidacaoSemOcorrencia: number;
    regrasConciliacoesGraves: number;
    regrasConciliacoesMediasAguardando: number;
    regrasConciliacoesMediasLiberadas: number;
    regrasConciliacoesLeves: number;
    regrasConciliacoesSemOcorrencia: number;
    aprovacoesAguardando: number;
    aprovacoesAprovadas: number;
    aprovacoesPorc: number;
    transmissoesNaoIniciado: number;
    transmissoesEmExecucao: number;
    transmissoesErro: number;
    transmissoesTransmitidas: number;
    formatOne: number;
    transmissoesNaoIniciadoWidth: number;
    transmissoesEmExecucaoWidth: number;
    transmissoesErroWidth: number;
    transmissoesTransmitidasWidth: number;
}

interface Armazenamento {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    origem: string;
    dataArmazenamento: string;
}

interface Validacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    campo: string;
    ocorrencia: string;
    situacao: string;
    liberacao: string;
}

interface Conciliacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    campo: string;
    ocorrencia: string;
    situacao: string;
    liberacao: string;
}

interface Aprovacoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    aprovador1: string;
    aprovador2: string;
}

interface Transmissoes {
    grupo: string;
    empresa: string;
    competencia: string;
    obrigacao: string;
    situacao: string;
    transmissao: string;
    arquivos: string;
}

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './obrigacoesDashboard.component.html',
    providers: [NzMessageService],
    styleUrls: ['./obrigacoesDashboard.component.scss']
})

export class ObrigacoesDashboardComponent {

    constructor(
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private el: ElementRef
    ) {

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.obrigacoes.length; i++) {
            this.obrigacoes[i].formatOne = this.getPercentage(this.obrigacoes[i].armazenadas, this.obrigacoes[i].naoArmazenadas);

            const totalAprovacoes: number = (this.obrigacoes[i].aprovacoesAguardando + this.obrigacoes[i].aprovacoesAprovadas);
            this.obrigacoes[i].aprovacoesPorc = Number(this.getPercentage(this.obrigacoes[i].aprovacoesAprovadas, totalAprovacoes)
                .toFixed(0));

            const totalTransmissoes: number = (this.obrigacoes[i].transmissoesNaoIniciado + this.obrigacoes[i].transmissoesEmExecucao) +
                (this.obrigacoes[i].transmissoesErro + this.obrigacoes[i].transmissoesTransmitidas);

            this.obrigacoes[i].transmissoesNaoIniciadoWidth = this.getPercentage(
                this.obrigacoes[i].transmissoesNaoIniciado, totalTransmissoes);

            this.obrigacoes[i].transmissoesEmExecucaoWidth = this.getPercentage(
                this.obrigacoes[i].transmissoesEmExecucao, totalTransmissoes);

            this.obrigacoes[i].transmissoesErroWidth = this.getPercentage(
                this.obrigacoes[i].transmissoesErro, totalTransmissoes);

            this.obrigacoes[i].transmissoesTransmitidasWidth = this.getPercentage(
                this.obrigacoes[i].transmissoesTransmitidas, totalTransmissoes);
        }

    }

    @ViewChild('swiperRef', {static: false}) swiperRef?: SwiperComponent;

    cardCompetenciaAtivo: number;
    navigation = true;

    id = this.route.snapshot.params.id;
    i: any;
    date = null;
    array = [
        {
            mes: 'Janeiro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Fevereiro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Março',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Abril',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Maio',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Junho',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Julho',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Agosto',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Setembro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Outubro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Novembro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        },
        {
            mes: 'Dezembro',
            ano: 2021,
            show: false,
            unidades: Math.floor(Math.random() * 1000),
            obrigacoes: Math.floor(Math.random() * 1000),
            armazenamentoOk: Math.floor(Math.random() * 1000),
            armazenamentoErro: Math.floor(Math.random() * 1000),
            validacoesOk: Math.floor(Math.random() * 1000),
            validacoesErro: Math.floor(Math.random() * 1000),
            conciliacoesOk: Math.floor(Math.random() * 1000),
            conciliacoesErro: Math.floor(Math.random() * 1000),
            aprovacoesAguardando: Math.floor(Math.random() * 1000),
            aprovacoesAprovadas: Math.floor(Math.random() * 1000),
            transmissoesOk: Math.floor(Math.random() * 1000),
            transmissoesErro: Math.floor(Math.random() * 1000)
        }
    ];
    tabNumber = 0;
    grupo = 'todos';
    unidade = 'todas';
    q: {
        pi: number;
        ps: number;
        buscar: string;
        sorter: string;
        table: string;
    } = {
        pi: 1,
        ps: 10,
        buscar: '',
        sorter: '',
        table: '',
    };
    data: any[] = [];
    loading = false;
    description = '';
    cardTitle = 'Jan/21';

    obrigacoes: Obrigacoes[] =
        [
            {
                obrigacaoNome: 'SPED Fiscal',
                obrigacaoTotal: Math.floor(Math.random() * 100),
                armazenadas: Number(this.getRandomArbitrary(0, 70).toFixed(2)),
                naoArmazenadas: Number(this.getRandomArbitrary(70, 100).toFixed(2)),
                regrasValidacaoGraves: Math.floor(Math.random() * 100),
                regrasValidacaoMediasAguardando: Math.floor(Math.random() * 100),
                regrasValidacaoMediasLiberadas: Math.floor(Math.random() * 100),
                regrasValidacaoLeves: Math.floor(Math.random() * 100),
                regrasValidacaoSemOcorrencia: Math.floor(Math.random() * 100),
                regrasConciliacoesGraves: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasAguardando: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasLiberadas: Math.floor(Math.random() * 100),
                regrasConciliacoesLeves: Math.floor(Math.random() * 100),
                regrasConciliacoesSemOcorrencia: Math.floor(Math.random() * 100),
                aprovacoesAguardando: Math.floor(Math.random() * 100),
                aprovacoesAprovadas: Math.floor(Math.random() * 100),
                aprovacoesPorc: 0,
                transmissoesNaoIniciado: Math.floor(Math.random() * 100),
                transmissoesEmExecucao: Math.floor(Math.random() * 100),
                transmissoesErro: Math.floor(Math.random() * 100),
                transmissoesTransmitidas: Math.floor(Math.random() * 100),
                formatOne: 0,
                transmissoesNaoIniciadoWidth: 0,
                transmissoesEmExecucaoWidth: 0,
                transmissoesErroWidth: 0,
                transmissoesTransmitidasWidth: 0,
            },
            {
                obrigacaoNome: 'GIA',
                obrigacaoTotal: Math.floor(Math.random() * 100),
                armazenadas: Number(this.getRandomArbitrary(0, 70).toFixed(2)),
                naoArmazenadas: Number(this.getRandomArbitrary(70, 100).toFixed(2)),
                regrasValidacaoGraves: Math.floor(Math.random() * 100),
                regrasValidacaoMediasAguardando: Math.floor(Math.random() * 100),
                regrasValidacaoMediasLiberadas: Math.floor(Math.random() * 100),
                regrasValidacaoLeves: Math.floor(Math.random() * 100),
                regrasValidacaoSemOcorrencia: Math.floor(Math.random() * 100),
                regrasConciliacoesGraves: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasAguardando: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasLiberadas: Math.floor(Math.random() * 100),
                regrasConciliacoesLeves: Math.floor(Math.random() * 100),
                regrasConciliacoesSemOcorrencia: Math.floor(Math.random() * 100),
                aprovacoesAguardando: Math.floor(Math.random() * 100),
                aprovacoesAprovadas: Math.floor(Math.random() * 100),
                aprovacoesPorc: 0,
                transmissoesNaoIniciado: Math.floor(Math.random() * 100),
                transmissoesEmExecucao: Math.floor(Math.random() * 100),
                transmissoesErro: Math.floor(Math.random() * 100),
                transmissoesTransmitidas: Math.floor(Math.random() * 100),
                formatOne: 0,
                transmissoesNaoIniciadoWidth: 0,
                transmissoesEmExecucaoWidth: 0,
                transmissoesErroWidth: 0,
                transmissoesTransmitidasWidth: 0,
            },
            {
                obrigacaoNome: 'DCTF',
                obrigacaoTotal: Math.floor(Math.random() * 100),
                armazenadas: Number(this.getRandomArbitrary(0, 70).toFixed(2)),
                naoArmazenadas: Number(this.getRandomArbitrary(70, 100).toFixed(2)),
                regrasValidacaoGraves: Math.floor(Math.random() * 100),
                regrasValidacaoMediasAguardando: Math.floor(Math.random() * 100),
                regrasValidacaoMediasLiberadas: Math.floor(Math.random() * 100),
                regrasValidacaoLeves: Math.floor(Math.random() * 100),
                regrasValidacaoSemOcorrencia: Math.floor(Math.random() * 100),
                regrasConciliacoesGraves: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasAguardando: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasLiberadas: Math.floor(Math.random() * 100),
                regrasConciliacoesLeves: Math.floor(Math.random() * 100),
                regrasConciliacoesSemOcorrencia: Math.floor(Math.random() * 100),
                aprovacoesAguardando: Math.floor(Math.random() * 100),
                aprovacoesAprovadas: Math.floor(Math.random() * 100),
                aprovacoesPorc: 0,
                transmissoesNaoIniciado: Math.floor(Math.random() * 100),
                transmissoesEmExecucao: Math.floor(Math.random() * 100),
                transmissoesErro: Math.floor(Math.random() * 100),
                transmissoesTransmitidas: Math.floor(Math.random() * 100),
                formatOne: 0,
                transmissoesNaoIniciadoWidth: 0,
                transmissoesEmExecucaoWidth: 0,
                transmissoesErroWidth: 0,
                transmissoesTransmitidasWidth: 0,
            },
            {
                obrigacaoNome: 'EFD Contribuições',
                obrigacaoTotal: Math.floor(Math.random() * 100),
                armazenadas: Number(this.getRandomArbitrary(0, 70).toFixed(2)),
                naoArmazenadas: Number(this.getRandomArbitrary(70, 100).toFixed(2)),
                regrasValidacaoGraves: Math.floor(Math.random() * 100),
                regrasValidacaoMediasAguardando: Math.floor(Math.random() * 100),
                regrasValidacaoMediasLiberadas: Math.floor(Math.random() * 100),
                regrasValidacaoLeves: Math.floor(Math.random() * 100),
                regrasValidacaoSemOcorrencia: Math.floor(Math.random() * 100),
                regrasConciliacoesGraves: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasAguardando: Math.floor(Math.random() * 100),
                regrasConciliacoesMediasLiberadas: Math.floor(Math.random() * 100),
                regrasConciliacoesLeves: Math.floor(Math.random() * 100),
                regrasConciliacoesSemOcorrencia: Math.floor(Math.random() * 100),
                aprovacoesAguardando: Math.floor(Math.random() * 100),
                aprovacoesAprovadas: Math.floor(Math.random() * 100),
                aprovacoesPorc: 0,
                transmissoesNaoIniciado: Math.floor(Math.random() * 100),
                transmissoesEmExecucao: Math.floor(Math.random() * 100),
                transmissoesErro: Math.floor(Math.random() * 100),
                transmissoesTransmitidas: Math.floor(Math.random() * 100),
                formatOne: 0,
                transmissoesNaoIniciadoWidth: 0,
                transmissoesEmExecucaoWidth: 0,
                transmissoesErroWidth: 0,
                transmissoesTransmitidasWidth: 0,
            }
        ];

    armazenamento: Armazenamento[] = [
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa A',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Armazenado',
            origem: 'Connect',
            dataArmazenamento: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa B',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Armazenado',
            origem: 'Upload',
            dataArmazenamento: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa C',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Armazenado',
            origem: 'EDI',
            dataArmazenamento: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa D',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Não Armazenado',
            origem: 'EDI',
            dataArmazenamento: 'por Anderson em 01/02/20 1h'
        }
    ];

    validacoes: Validacoes[] = [
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa A',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro E110 x C170',
            ocorrencia: 'Diferença de total de Valor Contábil',
            situacao: 'Sem Ocorrência',
            liberacao: ''
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa B',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro E110 x C170',
            ocorrencia: 'Diferença de total de Valor Contábil',
            situacao: 'Ocorrência Rejeitada',
            liberacao: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa C',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro E110 x C170',
            ocorrencia: 'Diferença de total de Valor Contábil',
            situacao: 'Ocorrência Média Liberada',
            liberacao: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa D',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro E110 x C170',
            ocorrencia: 'Diferença de total de Valor Contábil',
            situacao: 'Média Aguardando Liberação',
            liberacao: '[]'
        }
    ];

    conciliacoes: Conciliacoes[] = [
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa A',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro SPED E110 x EFD M110',
            ocorrencia: 'Valores de Faturamento Divergentes',
            situacao: 'N/A',
            liberacao: ''
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa B',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro SPED E110 x EFD M110',
            ocorrencia: 'Valores de Faturamento Divergentes',
            situacao: 'Ocorrência Rejeitada',
            liberacao: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa C',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro SPED E110 x EFD M110',
            ocorrencia: 'Valores de apuração Divergentes',
            situacao: 'Ocorrência Média Liberada',
            liberacao: 'por Anderson em 01/02/20 1h'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa D',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            campo: 'Registro SPED E110 x EFD M110',
            ocorrencia: 'Valores de apuração Divergentes',
            situacao: 'Média Aguardando Liberação',
            liberacao: '[]'
        }
    ];

    aprovacoes: Aprovacoes[] = [
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa A',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            situacao: 'Aguardando Aprovação',
            aprovador1: 'por João em 01/02/20 1h',
            aprovador2: 'Aguardando José'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa B',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            situacao: 'Ocorrência Rejeitada',
            aprovador1: 'por Anderson em 01/02/20 1h',
            aprovador2: 'N/A'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa C',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Aprovado',
            aprovador1: 'por Anderson em 01/02/20 1h',
            aprovador2: ''
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa D',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Aguardando Aprovação',
            aprovador1: '[]',
            aprovador2: '[]'
        }
    ];

    transmissoes: Transmissoes[] = [
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa A',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            situacao: 'Na Fila',
            transmissao: '--',
            arquivos: 'Aguardando José'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa B',
            competencia: 'jan/20',
            obrigacao: 'SPED Fiscal',
            situacao: 'Em Execução',
            transmissao: '--',
            arquivos: 'N/A'
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa C',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Erro ao Transmitir',
            transmissao: 'Certificado Inválido',
            arquivos: ''
        },
        {
            grupo: 'Grupo 1',
            empresa: 'Empresa D',
            competencia: 'jan/20',
            obrigacao: 'DCTF',
            situacao: 'Transmisitda',
            transmissao: 'em 10/01/2020 10:00',
            arquivos: '[]'
        }
    ];
    tabs: HTMLElement;

    formatOne = (percent: number) => `${percent}%`;

    add(tpl: TemplateRef<{}>, table: string): void {
        this.loading = true;
        this.q.table = table;
        let keys: any = [];
        if (table === 'armazenamento') {
            keys = this.armazenamento[0];
        }

        for (const key in keys) {
            if (key) {
                this.data.push({index: key, value: keys[key]});
            }
        }
        this.cdr.detectChanges();
        this.modalSrv.create({
            nzTitle: 'Filtro Avançado',
            nzContent: tpl,
            nzOnOk: () => {
            }
        });
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        const result = (menor / maior) * 100;
        return result;
    }

    getRandomArbitrary(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }

    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined)) {
            this.cardCompetenciaAtivo = event.clickedIndex;
        }

    }
}

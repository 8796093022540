<nz-page-header class="site-page-header" nzTitle="Obrigações Principais"
                nzSubtitle="Drive">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{collapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>

<nz-affix [nzOffsetTop]="0" [nzTarget]="target"></nz-affix>

<div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" style="overflow: hidden;">
        <swiper
                [(index)]="cardCompetenciaAtivo"
                (click)="selecionaCardCompetencia($event)"
                slidesPerView="auto"
                [spaceBetween]="30"
                [centeredSlides]="true"
                [mousewheel]="false"
                [scrollbar]="false"
                [navigation]="true"
        >
            <ng-template *ngFor="let item of arrayAnos; index as i" swiperSlide let-data
                         class="swiper-card-competencia">
                <div class="card-competencia ant-card pull-up card-{{i}}"
                     [ngClass]="collapseCard && 'compress'">
                    <div class="ant-card-head">
                        <h2>{{item.ano}}</h2>
                    </div>
                    <div class="text-center ant-card-body card-{{i}}">
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div nz-row>
                                    <div nz-col [nzSpan]="24" style="text-align: center;">
                                        <span style="margin-bottom: 5px" nz-typography
                                              nzType="secondary">Principal</span>
                                        <nz-row style="margin-bottom: 2px">
                                            <nz-col [nzSpan]="24" style="text-align: center">
                                                <span style="margin-right: 5px;">DARF {{item.darf}}</span>
                                            </nz-col>
                                        </nz-row>
                                        <nz-row style="margin-bottom: 2px">
                                            <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;">GARE {{item.gare}}
                                            </span>
                                            </nz-col>
                                        </nz-row>
                                        <nz-row style="margin-bottom: 2px">
                                            <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px; margin-top: 5px"> PERDCOMP {{item.dcomp}}
                                            </span>
                                            </nz-col>
                                        </nz-row>
                                        <nz-row style="margin-bottom: 2px">
                                            <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px; margin-top: 5px"> Parc. Federal {{item.parcelamentoFederal}}
                                            </span>
                                            </nz-col>
                                        </nz-row>
                                        <!--                                        <nz-row style="margin-bottom: 2px">-->
                                        <!--                                            <nz-col [nzSpan]="24" style="text-align: center">-->
                                        <!--                                        <span style="margin-right: 5px; margin-top: 5px"> Parc. Estadual {{item.parcelamentoEstadual}}-->
                                        <!--                                            </span>-->
                                        <!--                                            </nz-col>-->
                                        <!--                                        </nz-row>-->
                                    </div>
                                </div>
                                <nz-row>
                                    <nz-col [nzSpan]="24" style="text-align: center; margin-top: 10px">
                                        <span style="margin-right: 5px;">
                                            Total {{item.principal}}
                                        </span>
                                    </nz-col>
                                </nz-row>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
</div>
<nz-content>
    <nz-row nzGutter="24">
        <nz-col nzSpan="24">
            <ng-template #tituloAno>
                <span [ngClass]="[loadingCardCabecalho ? 'txt-disabled' : '']">
                    {{anoSelecionado}}
                </span>
            </ng-template>
            <nz-card [nzTitle]="tituloAno" [nzExtra]="cardExtra"
                     [nzLoading]="loadingCardCabecalho"
                     [nzBodyStyle]="loadingCardCabecalho ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
                     class="obrigacoesEstaduais card-scroll card-compress"
                     #cardHeightTarget>

                <nz-card *ngFor="let categoria of arrayCabecalho" [nzTitle]="tituloMenor"
                         [nzExtra]="cardExtraSub"
                         [nzSize]="'small'"
                         [nzLoading]="loadingCardCabecalho"
                         [nzBodyStyle]="{'margin': '0','padding':'0px','overflow':'hidden', 'background-color': '#fafafa'}"
                         class="sub-card card-compress">

                    <ng-template #tituloMenor>
                        <div style="font-size: small; cursor:pointer; padding: 10px 81vw 10px 0;"
                             (click)="expandidos[categoria.categoria] = !expandidos[categoria.categoria]">
                            {{categoria.categoria | uppercase}}
                        </div>
                    </ng-template>

                    <div style="justify-content: start; flex-wrap: wrap"
                         [ngStyle]="expandidos[categoria.categoria] ? {display: 'flex'} : {display: 'none'}">
                        <div *ngFor="let item of categoria.itens"
                             [ngClass]="'card'"
                             (click)="item.tamanho || true ? filtrarObrigacoesCard(item, categoria.categoria) : ''"
                             class="{{item.tamanho || true ? item.obrigacao_id === formFiltrarObrigacoes.formGroup.value.obrigacao_id ?
                             'enable selected' : 'enable' : 'disabled'}}">
                            <nz-content>
                                <nz-row *ngIf="item.tamanho else cardDisabled">
                                    <div nz-col [nzSpan]="12">
                                        <h4 class="custom-card-title"
                                            style="line-height: 1; color: #3d3d3d; font-weight: bold; margin-bottom: 20px;">
                                            {{item?.obrigacao}}
                                        </h4>
                                        <div class="text-muted">Total</div>
                                    </div>
                                    <div nz-col [nzSpan]="12" style="text-align: right;">
                                        <h4 class="custom-card-title"
                                            style="line-height: 1; color: #3d3d3d; margin-bottom: 20px;">
                                            {{formatBytes((item.tamanho ? item.tamanho : 0), 2)}}
                                        </h4>
                                        <div class="text-muted">{{item.qtdTotal ? (item.qtdTotal | number: '1.0-0') : '0'}}</div>
                                    </div>
                                </nz-row>

                                <ng-template #cardDisabled>
                                    <nz-row>
                                        <div nz-col [nzSpan]="12">
                                            <h4 class="custom-card-title"
                                                style="line-height: 1; color: #3d3d3d; font-weight: bold; margin-bottom: 20px;">
                                                {{item?.obrigacao}}
                                            </h4>
                                            <div class="text-muted">Total</div>
                                        </div>
                                        <div nz-col [nzSpan]="12" style="text-align: right;">
                                            <h4 class="custom-card-title"
                                                style="line-height: 1; color: #3d3d3d; margin-bottom: 20px;">
                                                {{formatBytes((item.tamanho ? item.tamanho : 0), 2)}}
                                            </h4>
                                            <div class="text-muted">{{item.qtdTotal ? (item.qtdTotal  | number: '1.0-0') : '0'}}</div>
                                        </div>
                                    </nz-row>
                                </ng-template>
                            </nz-content>
                        </div>
                    </div>

                    <ng-template #cardExtraSub>
                        <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)"
                           (click)="expandidos[categoria.categoria] = !expandidos[categoria.categoria]"
                           [nzSize]="'small'">
                            <i style="font-size: small;"
                               [ngClass]="expandidos[categoria.categoria] ? ['fa fa-minus'] : ['fa fa-plus']"></i>
                        </a>
                    </ng-template>
                </nz-card>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>
<div id="cardTabsTwo" #cardTabsTwo>
    <nz-card class="{{status ? 'card-fullscreen' : 'ant-card-table'}}"
             [nzLoading]="cardTabsLoading" [ngStyle]="cardTabsLoading ? {padding: '15px'} : {}">
        <nz-card-tab>
            <nz-tabset
                    [nzSize]="'large'"
                    [nzTabBarExtraContent]="tabsExtra"
                    [(nzSelectedIndex)]="tabNumber"
                    (nzSelectChange)="changeTabs($event)">
                <nz-tab nzTitle="Empresas"></nz-tab>
                <nz-tab *ngIf="tableObrigacaoSelecionada"
                        nzTitle="{{tableObrigacaoSelecionada.categoria.toUpperCase()}}" nz-button></nz-tab>
            </nz-tabset>
        </nz-card-tab>

        <nz-table #tableEmpresas
                  [nzData]="arrayTableEmpresas"
                  [nzFrontPagination]="false"
                  *ngIf="tabNumber == 0 && anoSelecionado"
                  [nzTotal]="paginations.e?.total"
                  [nzPageSize]="paginations.e?.per_page"
                  [nzPageIndex]="paginations.e?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="listByTableEmpresas($event)"
                  class="scrollbar table-small">
            <thead>
            <tr>
                <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="center">Unidade</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" nzAlign="center">CNPJ</th>
                <th nzColumnKey="qtdDarf" [nzSortFn]="true" nzAlign="center">DARF</th>
                <th nzColumnKey="qtdGare" [nzSortFn]="true" nzAlign="center">GARE</th>
                <th nzColumnKey="qtdDcomp" [nzSortFn]="true" nzAlign="center">PERDCOMP</th>
                <th nzColumnKey="qtdParcelamentoFederal" [nzSortFn]="true" nzAlign="center">Parcelamento Federal</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of tableEmpresas.data">
                <td>
                    <span style="display:block; min-width: 200px;">{{data?.nome}}</span>
                </td>
                <td nzAlign="center">{{data?.cnpj | cpfCnpj}}</td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [ngClass]="[loadingCardCabecalho ? 'txt-disabled' : '']"
                       (click)="loadingCardCabecalho ? '' : filtrarObrigacoes('darf', data.id)">{{data.qtdDarf ? (data.qtdDarf | number: '1.0-0') : '-'}}</a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [ngClass]="[loadingCardCabecalho ? 'txt-disabled' : '']"
                       (click)="loadingCardCabecalho ? '' : filtrarObrigacoes('gare', data.id)">{{data.qtdGare ? (data.qtdGare | number: '1.0-0') : '-'}}</a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [ngClass]="[loadingCardCabecalho ? 'txt-disabled' : '']"
                       (click)="loadingCardCabecalho ? '' : filtrarObrigacoes('dcomp', data.id)">{{data.qtdDcomp ? (data.qtdDcomp | number: '1.0-0') : '-'}}</a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" [ngClass]="[loadingCardCabecalho ? 'txt-disabled' : '']"
                       (click)="loadingCardCabecalho ? '' : filtrarObrigacoes('parc', data.id)">{{data.qtdParcelamentoFederal ? (data.qtdParcelamentoFederal | number: '1.0-0') : '-'}}</a>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th>Total</th>
                <th></th>
                <th nzAlign="center">{{totaisEmpresas.darf | number: '1.0-0'}}</th>
                <th nzAlign="center">{{totaisEmpresas.gare | number: '1.0-0'}}</th>
                <th nzAlign="center">{{totaisEmpresas.perdcomp | number: '1.0-0'}}</th>
                <th nzAlign="center">{{totaisEmpresas.parcFederal | number: '1.0-0'}}</th>
            </tr>
            </tfoot>
        </nz-table>

        <nz-table #tableObrigacoes
                  *ngIf="tabNumber > 0 && anoSelecionado"
                  [nzData]="tableObrigacaoSelecionada.dados"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.o?.total"
                  [nzPageSize]="paginations.o?.per_page"
                  [nzPageIndex]="paginations.o?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="listByTableObrigacoes($event)"
                  class="table-small"
                  [nzScroll]="{x: '2200px'}"
        >
            <thead>
            <tr style="white-space: nowrap;">
                <th *ngFor="let coluna of tableObrigacaoSelecionada.colunas" nzColumnKey="{{coluna.key}}"
                    [nzSortFn]="true"
                    nzAlign="center">{{coluna.descricao}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let linha of tableObrigacoes.data">
                <td *ngFor="let c of tableObrigacaoSelecionada.colunas" nzAlign="center">
                    <span *ngIf="c.key !== 'arquivos' && c.key !== 'composicao' &&
                     (!linha[c.key].tipo || linha[c.key].tipo === 'text') else tdBotoes">
                        {{c.key === 'cnpj' ? (linha[c.key].conteudo | cpfCnpj) : linha[c.key].conteudo}}
                    </span>

                    <!--                    se for = arqivos (ou composicao no caso de darf) linha[c.key].conteudo será um array-->

                    <ng-template #tdBotoes>

                        <!--                        Alterar modal darf-->

                        <div *ngIf="linha.composicao && linha.composicao[0].tipo == 'link-detalhesDarf'"
                             style="display: flex; justify-content: center; gap: 10px">
                            <button *ngFor="let link of linha.composicao" nz-button nzType="link"
                                    [nzLoading]="loadingComposicao[link.url]"
                                    [ngClass]="[link.disabled ? 'txt-disabled' : '']"
                                    (click)="!link.disabled ? showModalComposicao(true, link.url) : ''">
                                {{link.conteudo}}
                            </button>
                        </div>

                        <div *ngIf="linha.arquivos && (linha.arquivos[0].tipo == 'link-competencia' || linha.arquivos[0].tipo == 'link-extratoParcelamento')"
                             style="display: flex; justify-content: center; gap: 10px">
                            <div *ngFor="let link of linha.arquivos">
                                <a nz-button nzType="link" *ngIf="!link.disabled else linkDisabled"
                                   [nzLoading]="link.tipo === 'link-extratoParcelamento' ? loadingExtrato[link.url] : loadingCompetencia[link.url]"
                                   (click)="link.tipo === 'link-extratoParcelamento' ? showModalExtrato(true, link.url) :
                                showModalCompetencia(true, link.url)">
                                    {{link.conteudo}}
                                </a>

                                <ng-template #linkDisabled>
                                    <a nz-button nzType="link" disabled="disabled">{{link.conteudo}}</a>
                                </ng-template>
                            </div>
                        </div>

                        <div *ngIf="linha.arquivos && (linha.arquivos[0].tipo == 'bt-download-pdf' || linha.arquivos[0].tipo == 'bt-download-txt')"
                             style="display: flex; justify-content: center; gap: 5px">
                            <div *ngFor="let btn of linha.arquivos">

                                <a *ngIf="btn.disabled === 0 else btDisabled" nz-button nzType="link"
                                   (click)="downloadArquivo(linha.arquivos, btn.tipo)"
                                   [nzLoading]="btn.tipo == 'bt-download-pdf' && loadings.downloadArquivo.includes(linha.arquivos[0].url) ||
                                btn.tipo == 'bt-download-txt' && loadings.downloadArquivo.includes(linha.arquivos[1].url)">
                                    <i *ngIf="!downloadArquivos.pdf && btn.tipo == 'bt-download-pdf'" nz-icon
                                       nzType="download" nzTheme="outline" style="margin: 0 3px;"></i>
                                    <i *ngIf="!downloadArquivos.txt && btn.tipo == 'bt-download-txt' &&
                                    !loadings.downloadArquivo.includes(linha.arquivos[1].url)"
                                       class="fa fa-file-text" aria-hidden="true" style="margin: 0 3px;"></i>
                                </a>

                                <ng-template #btDisabled>
                                    <a nz-button nzType="link" disabled="disabled">
                                        <i *ngIf="!downloadArquivos.pdf && btn.tipo == 'bt-download-pdf'" nz-icon
                                           nzType="download" nzTheme="outline" style="margin: 0 3px;"></i>
                                        <i *ngIf="!downloadArquivos.txt && btn.tipo == 'bt-download-txt'"
                                           class="fa fa-file-text" aria-hidden="true" style="margin: 0 3px;"></i>
                                    </a>
                                </ng-template>

                            </div>
                        </div>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </nz-table>

        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</div>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }"
                  style="margin-right: 10px;">
            <!--            (click)="abrirModal(tabNumber === 0 ? formFiltrarTotais : tabNumber > 0 && formFiltrarObrigacoes)"-->
            <button nz-button [nzType]="'primary'"
                    (click)="msgParaUsuario('Filtros em desenvolvimento')">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
        </nz-badge>
        <button *ngIf="tabNumber > 0 && tableObrigacaoSelecionada.categoria === 'darf'" [nzDropdownMenu]="menuAcoes"
                style="margin-right: 10px;" class="centralized-icon" nz-button
                nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="modalImportar(true);">Importar Planilha DARF</li>
                <li nz-menu-item (click)="modalImportarComposicao(true);">Importar Planilha Composição</li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="msgParaUsuario('Filtros em desenvolvimento')" type="text" nz-input
                   placeholder="Busca"
                   [ngModel]="currentSearch"
                   (ngModelChange)="changeSearch($event)"/>
        </nz-input-group>
        <!--        (click)="queryTable(currentParams, currentSearch)"-->
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="msgParaUsuario('Filtros em desenvolvimento')">
                <i nz-icon nzType="search"></i>
            </button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'" style="padding: 0 8px;"></i>
        </button>
    </div>
</ng-template>
<nz-modal
        [(nzVisible)]="tabNumber === 0 ? formFiltrarTotais.modalVisible : tabNumber > 0 && formFiltrarObrigacoes.modalVisible"
        [nzTitle]="'Filtros Avançados'"
        [nzClosable]="true"
        (nzOnCancel)="fechar(tabNumber === 0 ? formFiltrarTotais : tabNumber > 0 && formFiltrarObrigacoes)"
        [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form *ngIf="tabNumber === 0" [nzNoColon]="true"
              [formGroup]="formFiltrarTotais.formGroup"
              style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarTotais.formGroup.value.empresa_id">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarTotais.formGroup.value.empresa_id"
                               formControlName="empresa_id"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ') '"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
        <form nz-form *ngIf="tabNumber > 0" [nzNoColon]="true"
              [formGroup]="formFiltrarObrigacoes.formGroup"
              style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.empresa_id">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               [(ngModel)]="formFiltrarObrigacoes.formGroup.value.empresa_id"
                               formControlName="empresa_id"
                               (ngModelChange)="retornaComboObrigacoes()">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link"
                (click)="fechar(tabNumber === 0 ? formFiltrarTotais : formFiltrarObrigacoes)">
            Cancelar
        </button>
        <button [nzLoading]="cardTabsLoading" nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Solicitar Exportação'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="525">
    <div nz-row *nzModalContent>

        <p nz-typography>Selecione o formato do(s) arquivo(s) a ser(em) exportado(s).</p>

        <nz-list nzItemLayout="horizontal" nz-col [nzSpan]="24">
            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('xlsx')">
                <button style="margin-left: 12px;" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.xlsx">
                    <i nz-icon nzType="file-excel" class="far fa-file-excel"
                       style="color: #28a745;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Gera arquivo xlsx com lista de arquivos da pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('txt')">
                <button style="margin-left: 12px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.txt">
                    <i nz-icon nzType="file-text" nzTheme="outline" style="color: #1890ff;  font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Exporta arquivos “Em Uso” listados na pesquisa</span>
                </div>

            </nz-list-item>

            <nz-list-item style="border-radius: 3px" nz-row class="ant-card-hoverable"
                          (click)="confirmaExportar('pdf')">
                <button style="margin-left: 12px" nz-button nzType="link" nz-col [nzSpan]="4" class="btn-exportador"
                        [nzLoading]="loadings.pdf">
                    <i nz-icon nzType="file-pdf" nzTheme="outline" style="color: #dc3545; font-size: 45px"></i>
                </button>
                <div nz-col [nzSpan]="18">
                    <span>Exporta arquivo .zip com recibos em PDF das obrigações listadas</span>
                </div>

            </nz-list-item>

        </nz-list>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
    </div>
</nz-modal>
<ng-template #cardExtraEmpresas>
    <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="cardCompress($event.target)"
       [nzSize]="'small'">
        <i nz-icon nzType="plus" class="d-none" nzTheme="outline"></i>
        <i nz-icon nzType="minus" nzTheme="outline"></i>
    </a>
</ng-template>
<ng-template #cardExtra>
    <a nz-button [nzType]="'link'" style="color: rgba(0,0,0,.85)" (click)="expandirRecolherTodos()"
       [nzSize]="'small'">
        <i [ngClass]="exibirTudo ? ['fa fa-plus d-none'] : ['fa fa-plus']" nzTheme="outline"></i>
        <i [ngClass]="exibirTudo ? ['fa fa-minus'] : ['fa fa-minus d-none']" nzTheme="outline"></i>
    </a>
</ng-template>

<nz-modal nzTitle="Competência"
          [(nzVisible)]="modalCompetenciaVisible"
          (nzOnCancel)="showModalCompetencia(false)"
          [nzWidth]="1500"
          style="min-height: 950px; max-height: 950px"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #tableCompetencia
                  [nzData]="arrayCompetencia"
                  [nzFrontPagination]="false"
                  class="tabela-detalhes table-small scrollbar"
                  [nzShowPagination]="false"
                  style="overflow: auto !important;">
            <thead>
            <tr>
                <th nzColumnKey="imposto" nzAlign="center">Imposto</th>
                <th nzColumnKey="mes" nzAlign="center">Mês</th>
                <th nzColumnKey="ano" nzAlign="center">Ano</th>
                <th nzColumnKey="cod_receita" nzAlign="center">Código Receita</th>
                <th nzColumnKey="receita" nzAlign="center">Receita</th>
                <th nzColumnKey="multa" nzAlign="center">Multa</th>
                <th nzColumnKey="juros" nzAlign="center">Juros</th>
                <th nzColumnKey="valorTotal" nzAlign="center">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableCompetencia.data">
                <td nzAlign="center">{{item.imposto}}</td>
                <td nzAlign="center">{{item.mes}}</td>
                <td nzAlign="center">{{item.ano}}</td>
                <td nzAlign="center">{{item.cod_receita}}</td>
                <td nzAlign="center">{{item.receita | currency:'BRL'}}</td>
                <td nzAlign="center">{{item.multa | currency:'BRL'}}</td>
                <td nzAlign="center">{{item.juros | currency:'BRL'}}</td>
                <td nzAlign="center">{{item.total | currency:'BRL'}}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalCompetencia(false)">Fechar</button>
    </div>
</nz-modal>
<nz-modal nzTitle="Extrato"
          [(nzVisible)]="modalExtratoVisible"
          (nzOnCancel)="showModalExtrato(false)"
          [nzWidth]="1500"
          style="min-height: 600px; max-height: 600px;"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #tableExtrato
                  [nzData]="arrayExtrato"
                  [nzFrontPagination]="false"
                  class="tabela-detalhes table-small scrollbar"
                  [nzScroll]="{ y: '500px' }"
                  [nzShowPagination]="false">
            <thead>
            <tr>
                <th nzColumnKey="imposto_id" nzAlign="center">Imposto</th>
                <th nzColumnKey="parcelaNumero" nzAlign="center">Parcela</th>
                <th nzColumnKey="valor" nzAlign="center">Valor</th>
                <th nzColumnKey="vencimento" nzAlign="center">Vencimento</th>
                <th nzColumnKey="situacao" nzAlign="center">Situação</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableExtrato.data">
                <td nzAlign="center">{{item.imposto_id}}</td>
                <td nzAlign="center">{{item.parcelaNumero}}</td>
                <td nzAlign="center">{{item.valor | currency:'BRL'}}</td>
                <td nzAlign="center">{{item.vencimento | date: 'dd/MM/YYYY'}}</td>
                <td nzAlign="center">{{item.situacao}}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalExtrato(false)">Fechar</button>
    </div>
</nz-modal>
<nz-modal nzTitle="Composição"
          [(nzVisible)]="modalComposicaoVisible"
          (nzOnCancel)="showModalComposicao(false)"
          [nzWidth]="1500"
          style="min-height: 950px; max-height: 950px"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #tableComposicao
                  [nzData]="arrayComposicao"
                  [nzFrontPagination]="false"
                  class="tabela-detalhes table-small scrollbar"
                  [nzShowPagination]="false"
                  style="overflow: auto !important;">
            <thead>
            <tr>
                <th nzColumnKey="numeroComposicao" nzAlign="center">Número</th>
                <th nzColumnKey="descricaoComposicao" style="width: 25%; word-break: break-word" nzAlign="left">
                    Descrição
                </th>
                <th nzColumnKey="receitaCodigo" nzAlign="center">Cód. Receita</th>
                <th nzColumnKey="valorTotal" nzAlign="center">Vl. Total</th>
                <th nzColumnKey="detalhePrincipal" nzAlign="center">Vl. Principal</th>
                <th nzColumnKey="detalheMulta" nzAlign="center">Vl. Multa</th>
                <th nzColumnKey="detalheJuros" nzAlign="center">Vl. Juros</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableComposicao.data">
                <td nzAlign="center">{{item.numeroComposicao || '-'}}</td>
                <td nzAlign="left">{{item.descricaoComposicao || '-'}}</td>
                <td nzAlign="center">{{item.receitaCodigo || '-'}}</td>
                <td nzAlign="center">{{(item.valorTotal | currency:'BRL') || '-'}}</td>
                <td nzAlign="center">{{(item.detalhePrincipal | currency:'BRL') || '-'}}</td>
                <td nzAlign="center">{{(item.detalheMulta | currency:'BRL') || '-'}}</td>
                <td nzAlign="center">{{(item.detalheJuros | currency:'BRL') || '-'}}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalComposicao(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Planilha DARF'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">


    <div *nzModalContent>
        <div>
            <p style="margin: 0px;">Selecione o arquivo (.xlsx) para importar.</p>
            <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar
                um arquivo de modelo.</small><br>
            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzAccept]="'.xlsx'"
                        nzAction="{{apiUrl}}/drive/obrigacoes/upload-planilha/darf"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="uploading" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalImportarVisibleComposicao"
          [nzTitle]="'Importar Planilha Composição'" [nzClosable]="true"
          (nzOnCancel)="modalImportarComposicao(false)"
          [nzWidth]="648">


    <div *nzModalContent>
        <div>
            <p style="margin: 0px;">Selecione o arquivo (.xlsx) para importar.</p>
            <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModeloComposicao();">aqui</a>
                para baixar
                um arquivo de modelo.</small><br>
            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzAccept]="'.xlsx'"
                        nzAction="{{apiUrl}}/drive/obrigacoes/upload-planilha/darf"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarComposicao(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="uploading" (click)="confirmarImportarComposicao()">Enviar
            arquivo
        </button>
    </div>
</nz-modal>

import {Component, HostListener, Input, OnInit, AfterViewInit, Output, EventEmitter} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AnexosListService} from '@components/anexos-list/anexos-list.service';
import {ToastrService} from 'ngx-toastr';
import * as fileSaver from 'file-saver-es';
import {DataService} from '@services/data.service';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-anexos-list',
    templateUrl: './anexos-list.component.html',
    styleUrls: ['./anexos-list.component.scss']
})
export class AnexosListComponent implements OnInit, AfterViewInit {

    @Input() anexosList: any;
    @Input() btExcluir: boolean;
    @Input() btZip;

    @Output() removeuAnexo = new EventEmitter();

    currentUser: any = null;

    identificador = '';

    loadings = {
        verAnexos: false,
        downloads: [],
        abrirAnexos: [],
        excluirAnexos: []
    };

    iframeUrl: SafeResourceUrl = '';

    zipNome: '';
    zipId: '';

    modalVerAnexoUnicoVisible = false;

    arquivoSelecionado = '';

    arquivoUrl: string;

    modalVerAnexosVisible = false;

    tamanhosTela = {
        screenHeight: 0,
        screenModalTop: 0,
        screenWidth: 0
    };

    constructor(
        public service: AnexosListService,
        public toastService: ToastrService,
        public sanitizer: DomSanitizer,
        private dataService: DataService,
        private modalService: NzModalService,
    ) {
        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });

        this.identificador = localStorage.getItem('identificador');
    }

    ngOnInit(): void {
        this.resizeModal();

        if (this.anexosList) {
            this.mapeiaAnexos(this.anexosList);
        }
    }

    ngAfterViewInit() {
        this.resizeModal();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    resizeModal() {
        this.tamanhosTela.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.tamanhosTela.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.tamanhosTela.screenModalTop = Math.round((window.innerHeight / 100) * 9);
    }

    mapeiaAnexos(anexos: any): void {

        if (anexos && anexos.length > 0) {
            this.loadings.verAnexos = true;

            setTimeout(() => {
                this.anexosList = [];
                anexos.forEach(a => {
                    if (a.tipo !== 'zip') {
                        this.anexosList.push({
                            nome: a.arquivoNome, id: a.obrigacaoEmpresaAnexo_id, disabledExcluir: a.disabledExcluir
                        });
                    } else if (a.tipo === 'zip') {

                        if (!this.btZip) {
                            // para casos em que no modal existirá o botão de baixar todos (btZip)
                            // será responsabilidade do componente pai e nenhum zip aparecerá na lista!
                            this.anexosList.push({
                                nome: a.arquivoNome, id: a.obrigacaoEmpresaAnexo_id,
                                zipNome: a.arquivoNome, zipId: a.obrigacaoEmpresaAnexo_id,
                                disabledExcluir: a.disabledExcluir
                            });
                        }

                        this.zipNome = a.arquivoNome;
                        this.zipId = a.obrigacaoEmpresaAnexo_id;

                        a.pdfs?.forEach(pdf => {
                            this.anexosList.push({
                                nome: pdf, id: pdf, origem: 'zip',
                                zipNome: a.arquivoNome, zipId: a.obrigacaoEmpresaAnexo_id,
                                disabledExcluir: a.disabledExcluir
                            });
                        });
                    }
                });

                this.loadings.verAnexos = false;
            }, 200);
        } else {
            this.toastService.warning('Sem arquivos para exibir');
        }
    }

    abreviar(valor: string): string {

        return valor && valor.length >= 30 ?
            valor.substring(0, 20) + ' ... ' + valor.substring(valor.length - 10) :
            valor;

    }

    modalVerAnexoUnico(visible: boolean) {
        this.modalVerAnexoUnicoVisible = visible;
    }

    modalAnexos(visible: boolean): void {
        this.modalVerAnexosVisible = visible;
        if (!visible) {
            this.anexosList = [];
            this.zipNome = '';
        }
    }

    verAnexo(arquivo: any): void {
        const identificadorPublico = this.currentUser ? null : this.identificador;

        this.loadings.abrirAnexos[arquivo.id] = true;
        this.iframeUrl = '';
        if (!arquivo.origem || arquivo.origem !== 'zip') {

            this.service.verAnexo(arquivo.id, identificadorPublico).subscribe((response) => {

                if (encodeURI(response.arquivo).split(/%..|./).length > 999900 && navigator.userAgent.indexOf('Chrome') > -1) {
                    this.toastService.warning('Arquivo muito grande para ser exibido no seu navegador atual.' +
                        ' Será baixado automaticamente.');
                    this.downloadPDF(arquivo.arquivoNome);
                } else {
                    this.modalVerAnexoUnico(true);
                    this.arquivoSelecionado = arquivo.nome;
                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.arquivo);

                    this.loadings.abrirAnexos[arquivo.id] = false;
                }

            }, (res) => {
                this.toastService.error('Falha ao abrir anexo.');
                this.loadings.abrirAnexos[arquivo.id] = false;
            });
        } else {

            this.service.verAnexoDoZip(arquivo.zipNome, arquivo.zipId, arquivo.id, identificadorPublico).subscribe((response) => {
                this.modalVerAnexoUnico(true);
                this.arquivoSelecionado = arquivo.nome;
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.arquivo);

                this.loadings.abrirAnexos[arquivo.id] = false;

            }, (res) => {
                this.toastService.error('Falha ao abrir PDF.');
                this.loadings.abrirAnexos[arquivo.id] = false;
            });

        }
    }

    downloadArquivo(arquivo: any) {

        if (arquivo.origem && arquivo.origem === 'zip') {
            this.downloadPDF(arquivo);
        } else if (arquivo.nome.includes('.zip')) {
            this.baixarZip(arquivo);
        } else {
            this.downloadFromAnexos(arquivo.id);
        }
    }

    downloadPDF(arquivo: any): void {

        const identificadorPublico = this.currentUser ? null : this.identificador;

        this.loadings.downloads[arquivo.id] = true;

        this.service.retornaPdf(arquivo.zipNome, arquivo.zipId, arquivo.id, identificadorPublico).subscribe((res) => {

            fileSaver.saveAs(res, arquivo.id);

            this.loadings.downloads[arquivo.id] = false;

        }, (res) => {
            this.toastService.error('Falha ao fazer download.');
            this.loadings.downloads[arquivo.id] = false;
        });

    }

    downloadFromAnexos(arquivoNome: string): void {

        const identificadorPublico = this.currentUser ? null : this.identificador;

        this.loadings.downloads[arquivoNome] = true;

        this.service.retornaFromAnexos(arquivoNome, identificadorPublico).subscribe((res) => {

            fileSaver.saveAs(res, arquivoNome);

            this.loadings.downloads[arquivoNome] = false;

        }, (res) => {
            this.toastService.error('Falha ao fazer download.');
            this.loadings.downloads[arquivoNome] = false;
        });

    }

    baixarZip(arquivo: any): void {

        const identificadorPublico = this.currentUser ? null : this.identificador;

        this.arquivoUrl = '';
        this.loadings.downloads[arquivo.id] = true;

        this.service.retornaZip(arquivo.id, arquivo.zipId, identificadorPublico).subscribe((res) => {

            const blob = new Blob([res], {type: 'application/zip; charset=utf-8'});

            fileSaver.saveAs(blob, arquivo.id);

            this.loadings.downloads[arquivo.id] = false;
        }, (res) => {
            this.modalAnexos(false);
            this.toastService.error('Falha ao fazer download.');
            this.loadings.downloads[arquivo.id] = false;
        });
    }

    showConfirmRemover(arquivo): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o anexo?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerAnexo(arquivo)
        });

    }

    removerAnexo(arquivo: any) {
        this.loadings.excluirAnexos[arquivo.id] = true;

        this.service.removerAnexo(arquivo.id).subscribe((res) => {
            this.modalAnexos(false);
            this.toastService.success(res.message);
            this.loadings.excluirAnexos[arquivo.id] = false;

            this.removeuAnexo.emit(this.anexosList);

        }, (err) => {
            this.modalAnexos(false);
            this.toastService.error(err.error.message);
            this.loadings.excluirAnexos[arquivo.id] = false;
        });
    }

}

<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Editar Credenciais de Acesso"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce"
                         [nzBodyStyle]="loading.dadosCadastrais ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.dadosCadastrais">
                    <nz-card-meta [nzTitle]="modoDemo(dadosCadastrais.nome, 'empresaCodigoNome')"
                                  [nzDescription]="dadosCadastrais.id ? 'CNPJ: ' + modoDemo((dadosCadastrais.cnpj | cpfCnpj), 'cnpj') : ''"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="ID">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.id }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Criado em">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.created_at ? (dadosCadastrais.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última alteração">
                                <nz-list-item-meta-title>
                                    {{ dadosCadastrais.updated_at ? (dadosCadastrais.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#st1" nzTitle="Certificado A1"></nz-link>
                    <div *ngIf="!data.apenasCertificado">
                        <nz-link nzHref="#st7" nzTitle="Acessos E-CAC"></nz-link>
                        <nz-link nzHref="#st2" nzTitle="Procurações E-CAC"></nz-link>
                        <nz-link nzHref="#st3" nzTitle="Procuradores E-CAC"></nz-link>
                        <nz-link nzHref="#st4" nzTitle="Acessos Estaduais"></nz-link>
                        <nz-link nzHref="#st5" nzTitle="Acessos Municipais"></nz-link>
                        <nz-link nzHref="#st6" nzTitle="Acessos Previdenciários"></nz-link>
                    </div>
                    <!--                    <nz-link nzHref="#acessosDataPrev" nzTitle="Acessos DataPrev"></nz-link>-->
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card id="st1" class="ant-card-table" nzTitle="Certificado A1" [nzExtra]="st1Extra"
                     [nzBodyStyle]="loading.certificados ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="loading.certificados">
                <ng-template #st1Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarCertificados(currentParams.certificados, currentSearch.certificados);"
                            title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="cadastrar(formAdicionarCertificado);">
                        Adicionar
                    </button>
                </ng-template>
                <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                          *ngIf="usaCertificadoMatriz">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                <strong class="text-primary"></strong>
                                Esta filial usa certificado da matriz.
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>
                <nz-table #basicTable1
                          nzSize="small"
                          [nzData]="listaCretificados"
                          [nzFrontPagination]="false"
                          [nzLoading]="reloadingCert"
                          [nzTotal]="paginations.certificados?.total"
                          [nzPageSize]="paginations.certificados?.per_page"
                          [nzPageIndex]="paginations.certificados?.current_page"
                          (nzQueryParams)="listByTableCertificados($event)"
                          [ngStyle]="usaCertificadoMatriz && !listaCretificados.length ? {'display': 'none'} : {}"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="emissor" [nzSortFn]="true" nzAlign="left">Emissor</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Categoria</th>
                        <th nzColumnKey="situacao" [nzSortFn]="false" nzShowSort="false" nzAlign="center">Situação</th>
                        <th nzColumnKey="validade" [nzSortFn]="true" nzAlign="center">Validade</th>
                        <th nzColumnKey="padrao" [nzSortFn]="true" nzAlign="center">Padrão</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable1.data">
                        <td>{{ item.emissor }}</td>
                        <td nzAlign="center">{{ item.tipo_descricao }}</td>
                        <td nzAlign="center">{{ item.situacao_descricao }}</td>
                        <td nzAlign="center">{{ item.validade | date: 'dd/MM/yyyy hh:mm:ss' }}</td>
                        <td nzAlign="center">{{ item.certificado_id == certificadoPadrao ? 'Sim' : '-' }}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarCertificadoA1(item);"
                                    style="margin-right: 10px;">
                                Detalhe
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('certificados', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <ng-template #templateToltipProcuracoes>
                <span nz-icon nzType="info-circle"
                      nzTheme="outline"
                      nzTooltipTitle="Empresas que eu posso acessar"
                      nzTooltipPlacement="bottomLeft"
                      style="color: #E4A11B; padding-right: 10px;font-weight: bold;cursor: pointer"
                      nz-button nz-tooltip>
                </span>
                <span>Procurações E-CAC</span>
            </ng-template>

            <!--            Caso abrir pela tela controle-de-certificados -->
            <div *ngIf="!data.apenasCertificado">
                <nz-card id="st7" class="ant-card-table" nzTitle="Acessos E-CAC" [nzExtra]="st7Extra"
                         [nzBodyStyle]="loading.acessosEcac ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.acessosEcac">
                    <ng-template #st7Extra>
                        <button nz-button nzType="default"
                                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                (click)="listarAcessosEcac(currentParams.acessosEcac, currentSearch.acessosEcac);"
                                title="Atualizar">
                            <i nz-icon nzType="reload"></i>
                        </button>
                        <button nz-button nzType="primary" (click)="modalAcessoEcac(true, true);">
                            Cadastrar
                        </button>
                    </ng-template>
                    <nz-table #basicTable7
                              nzSize="small"
                              [nzData]="listaAcessosEcac"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.acessosEcac?.total"
                              [nzPageSize]="paginations.acessosEcac?.per_page"
                              [nzPageIndex]="paginations.acessosEcac?.current_page"
                              (nzQueryParams)="listByTableAcessosEcac($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Data</th>
                            <th nzColumnKey="validade" [nzSortFn]="true" nzAlign="center">Validade</th>
                            <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                            <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Última Baixa</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable7.data">
                            <td nzAlign="center">
                                <div>
                                    <span>
                                        {{ item.created_at ? (item.created_at | date : 'dd/MM/yyyy HH:mm') : '-' }}
                                    </span>
                                </div>
                            </td>
                            <td nzAlign="center">
                                <div>
                                    <span>
                                        {{ item.validade ? (item.validade | date : 'dd/MM/yyyy HH:mm') : '-' }}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div style="text-align: center">
                                    <span>
                                        {{ item.ativo ? 'Ativo' : 'inativo' }}
                                    </span>
                                </div>
                            </td>
                            <td nzAlign="center">
                                <div *ngIf="item.statusProcessamento else semStatus"
                                     style="text-align: center; font-size: 1.2em">
                                    <span *ngIf="item.statusProcessamento == 1 else erroUltimoProcessamentoEcac">
                                       <i [nz-tooltip]="item.statusMensagem + ' - Data: ' + (item.statusData | date: 'dd/MM/yyyy HH:mm' )"
                                          style="color: #32a852" class="fa-solid fa-check"></i>
                                    </span>
                                    <ng-template #erroUltimoProcessamentoEcac>
                                        <i [nz-tooltip]="item.statusMensagem + ' - Data: ' + (item.statusData | date: 'dd/MM/yyyy HH:mm' )"
                                           style="color: #eb1a2b" class="fa-solid fa-xmark"></i>
                                    </ng-template>
                                </div>

                                <ng-template #semStatus>
                                    <span>
                                        -
                                    </span>
                                </ng-template>
                            </td>
                            <td nzAlign="center">
                                <button nz-button nzType="link" (click)="modalAcessoIntegradoEcac(item, true);">
                                    Ver
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>

                <nz-card id="st2" class="ant-card-table" [nzTitle]="templateToltipProcuracoes" [nzExtra]="st2Extra"
                         [nzBodyStyle]="loading.procuracoes ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.procuracoes">
                    <ng-template #st2Extra>
                        <div class="d-flex">
                            <button nz-button nzType="default" style="margin-right: 15px;"
                                    (click)="showConfirmBuscarProcuracao();"
                                    [nzLoading]="loading.buscarProcuracoes">
                                Buscar procurações no ECAC
                            </button>
                            <button nz-button nzType="primary" (click)="cadastrar(formProcuracoesECAC);"
                                    style="margin-right: 10px;"
                                    nz-tooltip [nzTooltipTitle]="'Usuário Console'" *ngIf="user?.origem === 'console'">
                                Cadastrar
                            </button>

                            <nz-badge [nzCount]="qtdFiltrosAtivosProcuracoes"
                                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                                <button nz-button nzType="primary" style="margin-right: 10px;"
                                        (click)="modalFiltrarProcuracoes(true);">
                                    <i nz-icon nzType="search"></i>
                                    Filtros
                                </button>
                            </nz-badge>
                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                                <input (keydown.enter)="confirmaBuscarProcuracoes();" type="text" nz-input
                                       placeholder="Busca" [(ngModel)]="currentSearch.procuracoes">
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="primary" nzSearch
                                        (click)="confirmaBuscarProcuracoes();">
                                    <i nz-icon nzType="search"></i></button>
                            </ng-template>

                            <button nz-button nzType="default" style="margin-right: 10px;"
                                    (click)="btnResetSearchProcuracoes();">
                                Limpar
                            </button>
                        </div>
                        <div style="text-align: right; margin-right: 15px; margin-top: 5px;">
                        <span *ngIf="ultimaConsulta.status && ultimaConsulta.status != 'Andamento'">
                            Última Consulta:
                            {{ ultimaConsulta.dataHora ? (ultimaConsulta.dataHora | date:'dd/MM/YY - HH:mm:ss') : '' }}
                            - {{ ultimaConsulta.status }}
                            </span>
                            <span *ngIf="ultimaConsulta.status && ultimaConsulta.status == 'Andamento'">
                                Última Solicitação:
                                {{ ultimaConsulta.dataHora ? (ultimaConsulta.dataHora | date:'dd/MM/YY - HH:mm:ss') : '' }}
                                - {{ ultimaConsulta.status }}
                            </span>
                        </div>
                    </ng-template>
                    <nz-table #basicTable2
                              nzSize="small"
                              [nzData]="listaProcuracoes"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.procuracoes?.total"
                              [nzPageSize]="paginations.procuracoes.per_page"
                              [nzPageIndex]="paginations.procuracoes.current_page"
                              (nzQueryParams)="listByTableProcuracoes($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="left">Nome</th>
                            <th nzColumnKey="situacaoCadastral" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                                Situação
                            </th>
                            <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                            <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                            <th [nzShowSort]="false" nzAlign="center">Empresa cadastrada</th>
                            <th nzColumnKey="updated_at" [nzSortFn]="true" nzAlign="center">Atualizada</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable2.data">
                            <td>{{ item.nome }}<br><small>{{ item.documento | cpfCnpj }}</small></td>
                            <td nzAlign="center">{{ item.situacao_descricao }}</td>
                            <td nzAlign="center">{{ item.dataInicio | date:'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">{{ item.dataFim | date:'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">{{ item.cadastrada ? 'Sim' : 'Não' }}</td>
                            <td nzAlign="center">{{ item.updated_at | date:'dd/MM/YY - HH:mm:ss' }}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarProcuracoesECAC(item);"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="user?.origem === 'console'"
                                        style="margin-right: 10px;">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('procuracoes', item);"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="user?.origem === 'console'"
                                >
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
                <ng-template #templateToltip>
                    <span nz-icon nzType="info-circle"
                          nzTheme="outline"
                          nzTooltipTitle="Empresas que podem me acessar"
                          nzTooltipPlacement="bottomLeft"
                          style="color: #E4A11B; padding-right: 10px;font-weight: bold;cursor: pointer"
                          nz-button nz-tooltip>
                    </span>
                    <span>Procuradores E-CAC</span>
                </ng-template>

                <nz-card id="st3" class="ant-card-table" [nzTitle]="templateToltip" [nzExtra]="st3Extra"
                         [nzBodyStyle]="loading.procuradores ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.procuradores">
                    <ng-template #st3Extra>
                        <div style="display: flex;">

                            <button nz-button nzType="primary" (click)="cadastrar(formProcuradoresECAC);"
                                    style="margin-right: 15px;"
                                    nz-tooltip [nzTooltipTitle]="'Usuário Console'" *ngIf="user?.origem === 'console'">
                                Cadastrar
                            </button>

                            <nz-badge [nzCount]="qtdFiltrosAtivosProcuradores"
                                      [nzStyle]="{background: '#52c41a', 'margin-right': '15px'}">
                                <button nz-button nzType="primary" style="margin-right: 10px;"
                                        (click)="modalFiltrarProcuradores(true);">
                                    <i nz-icon nzType="search"></i>
                                    Filtros
                                </button>
                            </nz-badge>

                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                                <input type="text" nz-input placeholder="Busca"
                                       [(ngModel)]="currentSearch.procuradores">
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="primary" nzSearch (click)="confirmaFiltrarProcuradores();">
                                    <i nz-icon nzType="search"></i>
                                </button>
                            </ng-template>

                            <button nz-button nzType="default" (click)="btnResetSearchProcuradores();">
                                Limpar
                            </button>
                        </div>
                    </ng-template>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="usaCertificadoMatrizProcuracao">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    Esta filial utiliza procuração da matriz.
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #basicTable3
                              nzSize="small"
                              [nzData]="listaProcuradores"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.procuradores?.total"
                              [nzPageSize]="paginations.procuradores?.per_page"
                              [nzPageIndex]="paginations.procuradores?.current_page"
                              (nzQueryParams)="listByTableProcuradores($event)"
                              [ngStyle]="usaCertificadoMatrizProcuracao && !listaProcuradores.length ? {'display': 'none'} : {}">
                        <thead>
                        <tr>
                            <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="left">Nome</th>
                            <th nzColumnKey="situacaoCadastral" [nzSortFn]="false" nzShowSort="false" nzAlign="center">
                                Situação
                            </th>
                            <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                            <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                            <th [nzShowSort]="false" nzAlign="center">Empresa cadastrada</th>
                            <th nzColumnKey="updated_at" [nzSortFn]="true" nzAlign="center">Atualizada</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable3.data">
                            <td>
                                {{ item.nome }}<br>
                                <small>{{ (item.documento | cpfCnpj) }}</small>
                            </td>
                            <td nzAlign="center">{{ item.situacao_descricao }}</td>
                            <td nzAlign="center">{{ item.dataInicio | date:'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">{{ item.dataFim | date:'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">{{ item.cadastrada ? 'Sim' : 'Não' }}</td>
                            <td nzAlign="center">{{ item.updated_at | date:'dd/MM/YY - HH:mm:ss' }}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarProcuradoresECAC(item);"
                                        style="margin-right: 10px;"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="user?.origem === 'console'">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('procuradores', item);"
                                        nz-tooltip [nzTooltipTitle]="'Usuário Console'"
                                        *ngIf="user?.origem === 'console'">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>

                <nz-card id="st4" class="ant-card-table" nzTitle="Acessos Estaduais" [nzExtra]="st4Extra"
                         [nzBodyStyle]="loading.acessos ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.acessos">
                    <ng-template #st4Extra>
                        <button nz-button nzType="default"
                                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                (click)="listarAcessos(currentParams.acessos, currentSearch.acessos);"
                                title="Atualizar">
                            <i nz-icon nzType="reload"></i>
                        </button>
                        <button nz-button nzType="primary" (click)="modalAcesso(true);">
                            Cadastrar
                        </button>
                    </ng-template>
                    <nz-table #basicTable4
                              nzSize="small"
                              [nzData]="listaAcessos"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.acessosEstaduais?.total"
                              [nzPageSize]="paginations.acessosEstaduais?.per_page"
                              [nzPageIndex]="paginations.acessosEstaduais?.current_page"
                              (nzQueryParams)="listByTableEstaduais($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                            <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Portal</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable4.data">
                            <td nzAlign="center">
                                <div *ngIf="dadosCadastrais.estado && dadosCadastrais.estado != item.uf else mesmaUF">
                                    <span>
                                        {{ item?.uf }}
                                    </span>
                                    <span
                                            nz-icon nzType="info-circle" nzTheme="outline"
                                            style="font-size: 12px; color: #FAA646; margin-left: 5px;"
                                            [nz-tooltip]="'UF diferente do cadastro da empresa (' + dadosCadastrais.estado + ')'"
                                    ></span>
                                </div>
                                <ng-template #mesmaUF>
                                    <span style="margin-left: -20px">
                                         {{ item?.uf }}
                                    </span>
                                </ng-template>
                            </td>
                            <td>
                                <div style="text-align: center">
                                    <span>
                                        {{ item?.portal }}
                                    </span>
                                    <nz-tag [nzColor]="'#f50'" *ngIf="item.credencialInvalida"
                                            style="margin-left: 10px; font-size: 10px; line-height: 14px; padding: 5px 2px 0 2px">
                                        Senha Inválida
                                    </nz-tag>

                                </div>
                            </td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarAcessosEstaduais(item, item.credencialInvalida);"
                                        style="margin-right: 10px;">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('acessos', item);">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>

                <nz-card id="st5" class="ant-card-table" nzTitle="Acessos Municipais" [nzExtra]="st5Extra"
                         [nzBodyStyle]="loading.acessosMunicipais ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.acessosMunicipais">
                    <ng-template #st5Extra>
                        <button nz-button nzType="default"
                                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                (click)="listarAcessosMunicipais(currentParams.acessosMunicipais, currentSearch.acessosMunicipais);"
                                title="Atualizar">
                            <i nz-icon nzType="reload"></i>
                        </button>
                        <button nz-button nzType="primary" (click)="modalAcessoMunicipal(true);">
                            Cadastrar
                        </button>
                    </ng-template>
                    <nz-table #basicTable5
                              nzSize="small"
                              [nzData]="listaAcessosMunicipais"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.acessosMunicipais?.total"
                              [nzPageSize]="paginations.acessosMunicipais?.per_page"
                              [nzPageIndex]="paginations.acessosMunicipais?.current_page"
                              (nzQueryParams)="listByTableMunicipais($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                            <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Município</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable5.data">
                            <td nzAlign="center">
                                <div *ngIf="dadosCadastrais.estado && dadosCadastrais.estado != item.uf else mesmaUFMunicipais">
                                    {{ item?.uf }}
                                    <span
                                            nz-icon nzType="info-circle" nzTheme="outline"
                                            style="font-size: 12px; color: #FAA646; margin-left: 5px;"
                                            [nz-tooltip]="'UF diferente do cadastro da empresa (' + dadosCadastrais.estado + ')'"
                                    ></span>
                                </div>
                                <ng-template #mesmaUFMunicipais>
                                    <span style="margin-left: -20px">
                                         {{ item?.uf }}
                                    </span>
                                </ng-template>
                            </td>
                            <td nzAlign="center">{{ item?.portal }}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarAcessosMunicipais(item);"
                                        style="margin-right: 10px;">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('acessosMunicipais', item);">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>

                <nz-card id="st6" class="ant-card-table" nzTitle="Acessos Previdenciários" [nzExtra]="st6Extra"
                         [nzBodyStyle]="loading.acessosPrevidenciarios ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                         [nzLoading]="loading.acessosPrevidenciarios">
                    <ng-template #st6Extra>
                        <button nz-button nzType="default"
                                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                                (click)="listarAcessosPrevidenciarios(currentParams.acessosPrevidenciarios, currentSearch.acessosPrevidenciarios);"
                                title="Atualizar">
                            <i nz-icon nzType="reload"></i>
                        </button>
                        <button nz-button nzType="primary" (click)="modalAcessoPrevidenciario(true);">
                            Cadastrar
                        </button>
                    </ng-template>
                    <nz-table #basicTable6
                              nzSize="small"
                              [nzData]="listaAcessosPrevidenciarios"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginations.acessosPrevidenciarios?.total"
                              [nzPageSize]="paginations.acessosPrevidenciarios?.per_page"
                              [nzPageIndex]="paginations.acessosPrevidenciarios?.current_page"
                              (nzQueryParams)="listByTablePrevidenciarios($event)">
                        <thead>
                        <tr>
                            <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Portal</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of basicTable6.data">
                            <td nzAlign="center">{{ item?.portal }}</td>
                            <td nzAlign="right">
                                <button nz-button nzType="default"
                                        (click)="alterarAcessosPrevidenciarios(item);"
                                        style="margin-right: 10px;">
                                    Alterar
                                </button>
                                <button nz-button nzType="default"
                                        (click)="showConfirm('acessosPrevidenciarios', item);">
                                    Remover
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
            </div>

        </div>
    </div>
</nz-content>
<nz-modal [(nzVisible)]="formCertificadoA1.modalVisible"
          [nzTitle]="'Alterar Certificado A1'" [nzClosable]="true"
          (nzOnCancel)="modal(formCertificadoA1, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCertificadoA1.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Emissor</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="emissor" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="categoria" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Situação</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="situacao" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Validade</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="validade" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formCertificadoA1, false);">Cancelar</button>
        <button nz-button nzType="primary">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formProcuracoesECAC.modalVisible"
          [nzTitle]="this.editando.procuracoes ? 'Alterar Procuração E-CAC' : 'Cadastrar Procuração E-CAC' "
          [nzClosable]="true"
          (nzOnCancel)="modal(formProcuracoesECAC, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formProcuracoesECAC.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="pessoa">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'J'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="documento" size="60"
                           [mask]="maskPessoa(formProcuracoesECAC)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="nome" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <!--<nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>-->
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formProcuracoesECAC, false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loading.insertProcuracao"
                (click)="!loading.insertProcuracao && insertProcuracoes();"
                *ngIf="!this.editando.procuracoes"
        >Confirmar
        </button>
        <button nz-button nzType="primary"
                (click)="updateProcuracoes();"
                *ngIf="this.editando.procuracoes"
        >Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formProcuradoresECAC.modalVisible"
          [nzTitle]="'Alterar Procurador E-CAC'" [nzClosable]="true"
          (nzOnCancel)="modal(formProcuradoresECAC, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formProcuradoresECAC.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Pessoa</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="pessoa">
                        <nz-option [nzLabel]="'Física'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Jurídica'" [nzValue]="'J'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="documento" maxlength="20" size="60"
                           [mask]="maskPessoa(formProcuradoresECAC)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="nome" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <!--<nz-form-item>
                <nz-form-label [nzSpan]="10">Fim</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>-->
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formProcuradoresECAC, false);">Cancelar</button>
        <button nz-button nzType="primary"
                [nzLoading]="loading.insertProcurador"
                (click)="!loading.insertProcurador && insertProcuradores();"
                *ngIf="!this.editando.procuradores"
        >Confirmar
        </button>
        <button nz-button nzType="primary"
                (click)="updateProcuradores();"
                *ngIf="this.editando.procuradores"
        >Confirmar
        </button>
    </div>
</nz-modal>

<app-modal-credencial-de-acesso #modalCredencialDeAcesso
                                (concluiuOperacao)="listarCertificados(currentParams, currentSearch)"
                                [data]="data">
</app-modal-credencial-de-acesso>

<nz-modal [(nzVisible)]="formDataPrev.modalVisible"
          [nzTitle]="'Alterar Acesso DataPrev'"
          [nzClosable]="true"
          (nzOnCancel)="modal(formDataPrev, false, null)"
          [nzWidth]="648">
    <div *nzModalContent>
        <div style="margin-top: 20px;">
            <form nz-form [nzNoColon]="true" [formGroup]="formDataPrev.formGroup">

                <nz-form-item>
                    <nz-form-label [nzSpan]="10" nzRequired>Identificação:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-input-group>
                            <input type="text"
                                   nz-input
                                   placeholder="vazio"
                                   formControlName="identificador"
                                   size="60"/>
                        </nz-input-group>
                    </nz-form-control>

                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="10" nzRequired>Senha:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-input-group [nzSuffix]="suffixSenha">
                            <input [type]="senhaVisible ? 'text' : 'password'"
                                   nz-input
                                   placeholder="vazio"
                                   formControlName="senha"
                                   size="60"/>
                        </nz-input-group>
                    </nz-form-control>
                    <ng-template #suffixSenha>
                        <i nz-icon [nzType]="senhaVisible ? 'eye-invisible' : 'eye'"
                           (click)="senhaVisible = !senhaVisible"></i>
                    </ng-template>
                </nz-form-item>

            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formDataPrev, false, null);">Cancelar</button>
        <button nz-button nzType="primary" (click)="updateDataPrev()">Confirmar
        </button>

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarProcuracoes.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarProcuracoes(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarProcuracoes.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Expiradas / Canceladas</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuracoes.formGroup.value.situacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Exibir somente expiradas/canceladas'" [nzValue]="'somente'"></nz-option>
                        <nz-option [nzLabel]="'Exibir também as expiradas/canceladas'" [nzValue]="'tambem'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuracoes.formGroup.value.cadastrada">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'sim'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'nao'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarProcuracoes(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarProcuracoes()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarProcuradores.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarProcuradores(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarProcuradores.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Expiradas / Canceladas</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuradores.formGroup.value.situacao">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Exibir somente expiradas/canceladas'" [nzValue]="'somente'"></nz-option>
                        <nz-option [nzLabel]="'Exibir também as expiradas/canceladas'" [nzValue]="'tambem'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa Cadastrada</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarProcuradores.formGroup.value.cadastrada">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cadastrada">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'sim'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'nao'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarProcuradores(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarProcuradores()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessosEstaduais.modalVisible"
          [nzTitle]="editando.acessos ? 'Alterar Acesso Estadual' : 'Cadastrar Acesso Estadual'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcesso(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loading.formAcessos"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosEstaduais.formGroup" style="width: 100%;"
              *ngIf="!loading.formAcessos"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtrarPortais(formAcessosEstaduais.formGroup.value.uf)">

                        <nz-option *ngFor="let opt of comboUFsEstaduais"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Portal</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCampos(formAcessosEstaduais.formGroup.value.portal);">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-content *ngIf="camposFormDataPrev.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrev">
                    <nz-form-label [nzSpan]="10" *ngIf="!loading.formAcessos">{{ item.label }}:</nz-form-label>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length > 0 && !loading.formAcessos">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length === 0 && !loading.formAcessos">
                        <input appCnpjMask *ngIf="item.label === 'cnpj' else semFormatacao" nz-input type="text"
                               formControlName="{{item.label}}"/>
                        <ng-template #semFormatacao>
                            <input nz-input type="text" formControlName="{{item.label}}"/>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcesso(false);">Cancelar</button>
        <button [nzLoading]="loading.insertEstadual" nz-button nzType="primary" *ngIf="!editando.acessos"
                (click)="insertAcessos();">Confirmar
        </button>
        <button [nzLoading]="loading.updateAcesso" nz-button nzType="primary" *ngIf="editando.acessos"
                (click)="updateAcessos();">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessosMunicipais.modalVisible"
          [nzTitle]="editando.acessosMunicipais ? 'Alterar Acesso Municipal' : 'Cadastrar Acesso Municipal'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoMunicipal(false, null)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loading.formAcessosMunicipais"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosMunicipais.formGroup" style="width: 100%;"
              *ngIf="!loading.formAcessosMunicipais"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="listMunicipios(formAcessosMunicipais.formGroup.value.uf)">

                        <nz-option *ngFor="let opt of comboUFsMunicipais"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCamposMunicipal(formAcessosMunicipais.formGroup.value.portal)">

                        <nz-option *ngFor="let opt of comboMunicipios"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-content *ngIf="camposFormDataPrevMunicipais.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrevMunicipais">
                    <nz-form-label nzRequired [nzSpan]="10" *ngIf="!loading.formAcessos">{{ item.label }}:
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length > 0 && !loading.formAcessosMunicipais">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length === 0 && !loading.formAcessosMunicipais">
                        <input nz-input type="text" formControlName="{{item.label}}"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="editando.acessosMunicipais && municipalSelecionado.created_at">
                    <nz-form-label [nzSpan]="10">Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ municipalSelecionado.criado_por }}
                            em {{ municipalSelecionado.created_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosMunicipais && municipalSelecionado.atualizado_por">
                    <nz-form-label [nzSpan]="10">Alterado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ municipalSelecionado.atualizado_por }}
                            em {{ municipalSelecionado.updated_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoMunicipal(false, null);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!editando.acessosMunicipais"
                [nzLoading]="loading.insertAcessoMunicipal" (click)="insertAcessosMunicipais();">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="editando.acessosMunicipais"
                [nzLoading]="loading.updateAcessosMunicipal" (click)="updateAcessosMunicipais();">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessosPrevidenciarios.modalVisible"
          [nzTitle]="editando.acessosPrevidenciarios ? 'Alterar Acesso Previdenciário' : 'Cadastrar Acesso Previdenciário'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoPrevidenciario(false, null)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loading.formAcessosPrevidenciarios"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosPrevidenciarios.formGroup" style="width: 100%;"
              *ngIf="!loading.formAcessosPrevidenciarios"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Portal</nz-form-label>
                <nz-form-control nzErrorTip="Informe o portal!" [nzSpan]="10">
                    <nz-select nzShowSearch nzAllowClear required
                               [nzDisabled]="editando.acessosPrevidenciarios"
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCamposPrevidenciario(formAcessosPrevidenciarios.formGroup.value)">

                        <nz-option *ngFor="let opt of comboPortaisPrevidenciarios"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-content *ngIf="camposFormDataPrevPrevidencirios.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrevPrevidencirios">
                    <nz-form-label nzRequired [nzSpan]="10" *ngIf="!loading.formAcessosPrevidenciarios">{{ item.label }}
                        :
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13"
                                     *ngIf="item.valores.length > 0 && !loading.formAcessosPrevidenciarios">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13"
                                     *ngIf="item.valores.length === 0 && !loading.formAcessosPrevidenciarios">
                        <input nz-input type="text" formControlName="{{item.label}}"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosPrevidenciarios  && dataPrevSelecionado.created_at">
                    <nz-form-label [nzSpan]="10">Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ dataPrevSelecionado.criado_por }}
                            em {{ dataPrevSelecionado.created_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosPrevidenciarios && dataPrevSelecionado.atualizado_por">
                    <nz-form-label [nzSpan]="10">Alterado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ dataPrevSelecionado.atualizado_por }}
                            em {{ dataPrevSelecionado.updated_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoPrevidenciario(false, null);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!editando.acessosPrevidenciarios"
                [nzLoading]="loading.acessosPrevidenciarios" (click)="insertAcessosPrevidnciario();">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="editando.acessosPrevidenciarios"
                [nzLoading]="loading.updateAcessosPrevidenciarios" (click)="updateAcessosPrevidenciarios();">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="termosVisible" nzTitle="Acesso Integrado E-CAC"
          (nzOnCancel)="modalAcessoIntegradoEcac(null, false)">
    <div *nzModalContent>

        <div class="termos-resumidos" *ngIf="!termosCompletosVisible else termosCompletos">

            <div nz-row style="margin-bottom: 20px">
                <form nz-form [nzNoColon]="true" style="width: 100%;">
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Data</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.created_at ? (acessoEcacSelecionado.created_at | date : 'dd/MM/yyyy') : '-' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Validade</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.validade ? (acessoEcacSelecionado.validade | date : 'dd/MM/yyyy') : '-' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Status</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.ativo === 1 ? 'Ativo' : 'Inativo' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Integrador</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>{{ (acessoEcacSelecionado.contratante | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Procurador</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span> {{ (acessoEcacSelecionado.procurador | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Contribuinte</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span> {{ (acessoEcacSelecionado.contribuinte | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>

            <h3 style="margin-bottom: 5px">Termos e condições</h3>

            <p>Ao ativar a integração com o
                eCAC, o contribuinte diretamente
                ou indiretamente, através de um procurador,
                autoriza a Master Intelligence Tax a utilizar os serviços SERPRO para fins de <b>CONSULTA</b>. Caso não
                exista as procurações necessárias, será feita a outorga automaticamente. <br>
                Veja mais detalhes
                <a (click)="verTermosCompletos()">aqui</a>. </p>
        </div>
        <ng-template #termosCompletos>
            <div class="termos-completos">
                <a (click)="verTermosCompletos()">< Voltar</a>
                <h3 style="margin: 5px 0">Termos e condições</h3>
                <div style="max-height: 400px; overflow-x: auto; margin: 15px 0">
                    <h3>Autorização</h3>
                    <p style="padding-left: 5px">
                        Autorizo a empresa CONTRATANTE, identificada neste termo de autorização como DESTINATÁRIO, a
                        executar as requisições dos serviços web disponibilizados pela API INTEGRA CONTADOR,
                        onde terei o papel de AUTOR PEDIDO DE DADOS no corpo da mensagem enviada na requisição do
                        serviço
                        web. Esse termo de autorização está assinado digitalmente com o certificado digital
                        do PROCURADOR ou OUTORGADO DO CONTRIBUINTE responsável, identificado como AUTOR DO PEDIDO DE
                        DADOS.
                    </p>
                    <h3>Aviso Legal</h3>
                    <p style="padding-left: 5px">
                        O acesso a estas informações foi autorizado pelo próprio PROCURADOR ou OUTORGADO DO
                        CONTRIBUINTE,
                        responsável pela informação, via assinatura digital. É dever do destinatário da
                        autorização e consumidor deste acesso observar a adoção de base legal para o tratamento dos
                        dados
                        recebidos conforme artigos 7º ou 11º da LGPD (Lei n.º 13.709, de 14 de agosto de 2018),
                        aos direitos do titular dos dados (art. 9º, 17 e 18, da LGPD) e aos princípios que norteiam
                        todos os
                        tratamentos de dados no Brasil (art. 6º, da LGPD).
                    </p>
                    <h3>Finalidade</h3>
                    <p style="padding-left: 5px">
                        A finalidade única e exclusiva desse TERMO DE AUTORIZAÇÃO, é garantir que o CONTRATANTE
                        apresente a API
                        INTEGRA CONTADOR esse consentimento do PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                        assinado digitalmente, para que possa realizar as requisições dos serviços web da API INTEGRA
                        CONTADOR
                        em nome do AUTOR PEDIDO DE DADOS (PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                    </p>
                </div>
            </div>
        </ng-template>

        <label *ngIf="!checkDisabled" nz-checkbox [(ngModel)]="termosAceitos">Aceito</label>


        <div style="margin-top: 25px; padding-top: 10px; max-height: 300px; overflow-y: auto"
             *ngIf="!termosCompletosVisible">

            <nz-timeline *ngIf="acessoEcacSelecionado.historico">
                <nz-timeline-item *ngFor="let h of acessoEcacSelecionado.historico"
                                  [nzColor]="h.acao === 'autorizado' ? 'blue' : 'red'">
                    {{ h.acao === 'autorizado' ? 'Aceito ' : 'Inativo ' }} por {{ h.created_from_nome || '-' }}
                    em {{ h.created_at ? (h.created_at | date : 'dd/MM/yyyy') + ' às ' + (h.created_at | date : 'HH:mm') : '-' }}
                </nz-timeline-item>
            </nz-timeline>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="modalAcessoIntegradoEcac(null, false)">
            Fechar
        </button>
        <button *ngIf="acessoEcacSelecionado.ativo === 1" nzType="link"
                [nzLoading]="loadingAcessoEcac"
                (click)="confirmInativar()" nz-button>Inativar
        </button>
        <button *ngIf="acessoEcacSelecionado.ativo === 0 && !termosAceitos else btAtivar"
                [nz-tooltip]="'Aceite os termos para proseguir'" nz-button
                class="bt-disabled">Ativar
        </button>
        <ng-template #btAtivar>
            <button *ngIf="acessoEcacSelecionado.ativo === 0 && termosAceitos" nz-button nzType="primary"
                    [nzLoading]="loadingAcessoEcac"
                    (click)="modalProcurador(1, true)">Ativar
            </button>
        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessoEcac.modalVisible"
          [nzTitle]="'Cadastrar Acesso E-CAC'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoEcac(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAcessoEcac.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear [nzDisabled]="true"
                               [nzPlaceHolder]="'Selecione'" formControlName="ids">

                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{ opt.nome }}
                            <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Procurador</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Selecione'" formControlName="procurador">
                        <nz-option *ngFor="let r of responsaveis"
                                   [nzLabel]="r.nome + ' (' + (r.documento | cpfCnpj) + ')'"
                                   [nzValue]="r.id"
                                   nzCustomContent>
                            {{ r.nome }}
                            <br><small>({{ r.documento | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>

        <div style="margin-top: 20px">
            <div class="termos-resumidos" *ngIf="!termosCompletosVisible else termosCompletos">
                <h3 style="margin-bottom: 5px">Termos e condições</h3>
                <p>Ao ativar a integração com o eCAC, o contribuinte diretamente
                    ou indiretamente, através de um procurador,
                    autoriza a Master Intelligence Tax a utilizar os serviços SERPRO para fins de <b>CONSULTA</b>. Caso
                    não
                    exista as procurações necessárias, será feita a outorga automaticamente. <br>
                    Veja mais detalhes <a (click)="verTermosCompletos()">aqui</a>. </p>
            </div>
            <ng-template #termosCompletos>
                <div class="termos-completos">
                    <a (click)="verTermosCompletos()">< Voltar</a>
                    <h3 style="margin: 5px 0">Termos e condições</h3>
                    <div style="max-height: 400px; overflow-x: auto; margin: 15px 0">
                        <h3>Autorização</h3>
                        <p style="padding-left: 5px">
                            Autorizo a empresa CONTRATANTE, identificada neste termo de autorização como DESTINATÁRIO, a
                            executar as requisições dos serviços web disponibilizados pela API INTEGRA CONTADOR,
                            onde terei o papel de AUTOR PEDIDO DE DADOS no corpo da mensagem enviada na requisição do
                            serviço
                            web. Esse termo de autorização está assinado digitalmente com o certificado digital
                            do PROCURADOR ou OUTORGADO DO CONTRIBUINTE responsável, identificado como AUTOR DO PEDIDO DE
                            DADOS.
                        </p>
                        <h3>Aviso Legal</h3>
                        <p style="padding-left: 5px">
                            O acesso a estas informações foi autorizado pelo próprio PROCURADOR ou OUTORGADO DO
                            CONTRIBUINTE,
                            responsável pela informação, via assinatura digital. É dever do destinatário da
                            autorização e consumidor deste acesso observar a adoção de base legal para o tratamento dos
                            dados
                            recebidos conforme artigos 7º ou 11º da LGPD (Lei n.º 13.709, de 14 de agosto de 2018),
                            aos direitos do titular dos dados (art. 9º, 17 e 18, da LGPD) e aos princípios que norteiam
                            todos os
                            tratamentos de dados no Brasil (art. 6º, da LGPD).
                        </p>
                        <h3>Finalidade</h3>
                        <p style="padding-left: 5px">
                            A finalidade única e exclusiva desse TERMO DE AUTORIZAÇÃO, é garantir que o CONTRATANTE
                            apresente a API
                            INTEGRA CONTADOR esse consentimento do PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                            assinado digitalmente, para que possa realizar as requisições dos serviços web da API
                            INTEGRA
                            CONTADOR
                            em nome do AUTOR PEDIDO DE DADOS (PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                        </p>
                    </div>
                </div>
            </ng-template>

            <label nz-checkbox [(ngModel)]="termosAceitos">Aceito</label>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoEcac(false);">Cancelar</button>

        <button *ngIf="!termosAceitos else btConfirmar"
                [nz-tooltip]="'Aceite os termos para proseguir'" nz-button
                class="bt-disabled">Confirmar
        </button>
        <ng-template #btConfirmar>
            <button *ngIf="termosAceitos" nz-button nzType="primary"
                    [nzLoading]="loadingAcessoEcac"
                    (click)="ativarInativarAcessoIntegradoECAC(false, true)">Confirmar
            </button>
        </ng-template>

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="selecionarProcurador"
          [nzTitle]="'Selecione o Procurador'"
          [nzClosable]="true"
          (nzOnCancel)="modalProcurador(0, false)"
          [nzWidth]="600">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAcessoEcac.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Procurador</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="procurador" nzShowSearch nzAllowClear>
                        <nz-option *ngFor="let r of responsaveis"
                                   [nzLabel]="r.nome + ' (' + (r.documento | cpfCnpj) + ')'"
                                   [nzValue]="r.id"
                                   nzCustomContent>
                            {{ r.nome }}
                            <br><small>({{ r.documento | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalProcurador(0, false);">Cancelar</button>
        <button [nzLoading]="loadingAcessoEcac" nz-button nzType="primary"
                (click)="ativarInativarAcessoIntegradoECAC();">Confirmar
        </button>
    </div>
</nz-modal>

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LOCALE_ID} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GuiasLotesService} from './guiasLotes.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './guiasLotes.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./guiasLotes.component.scss']
})

export class GuiasLotesComponent extends AbstractListTable<any> implements OnInit {


    pagination: Pagination;

    cardCompetenciaAtivo: number;

    formFiltrar: FormStack;
    formExportar: FormStack;

    empresaNome = '';

    dataExport: any;

    anoSelecionado: number = null;

    arrayAnos = [];
    arrayTableObrigacoes = [];

    arrayTableLotes = [];

    status = false;

    expandSet = new Set<number>();

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild('column12') column12: ElementRef;
    column12Width = 0;

    @ViewChild('column13') column13: ElementRef;
    column13Width = 0;

    @ViewChild('column14') column14: ElementRef;
    column14Width = 0;

    @ViewChild('columnDados') columnDados: ElementRef;
    columnDadosWidth = 0;

    tabNumber = 0;

    collapseCard = false;

    formGerar: FormStack;
    currentParams: NzTableQueryParams;
    currentSearch: string;
    gridLoading = false;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectLayouts = [];

    arraySelectEmpresasFiltros = [];

    formLayout: FormStack;
    arquivoUrl = null;
    camposLayout = [];
    arquivoId = null;
    apiUrl = environment.apiUrl;

    qtdFiltrosAtivos = 0;

    loadings = {
        gravar: false,
        download: []
    };

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private guiasLotesService: GuiasLotesService,
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
    ) {
        super(guiasLotesService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                dataInicioDe: [null, null],
                dataInicioAte: [null, null],
                dataFimDe: [null, null],
                dataFimAte: [null, null],
                layout: [null, null],
                qtdRegistrosDe: [null, null],
                qtdRegistrosAte: [null, null],
                valorTotalDe: [null, null],
                valorTotalAte: [null, null],
            })
        };

        this.guiasLotesService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });


        this.guiasLotesService.retornarSelectsLayouts(
        ).subscribe((retorno: any) => {
            this.arraySelectLayouts = retorno;
        });

        this.formGerar = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                layout: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formLayout = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.dataExport = {
            url: '/integracoes/guias-lotes/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Integrações - Guias Lotes',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    ngOnInit(): void {
        this.retornarSelectsEmpresasFiltros();
    }

    retornarSelectsEmpresasFiltros(): void {
        this.guiasLotesService.retornarSelectsEmpresasFiltros(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresasFiltros = [];
            this.arraySelectEmpresasFiltros = retorno;
        });
    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.cdr.detectChanges();
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        if (formulario === this.formExportar) {
            this.dataExport.filtros = this.formFiltrar.formGroup.value;
            this.componentExport.visible = true;
        } else {
            formulario.modalVisible = true;
        }
    }

    confirmaFiltrar(): void {

        this.queryTable(this.currentParams, this.currentSearch);

        this.fechar(this.formFiltrar);

    }


    downloadArquivo(id: string) {

        this.loadings.download.push(id);


        this.guiasLotesService.exportArquivo(id).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            fileSaver.saveAs(blob, id);
            this.loadings.download = [];

        }, error => {
            this.loadings.download = [];
            this.toastService.error(error.error.message);
        });

    }

    exportDocumento(name) {

        this.guiasLotesService.exportDocumento(name).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, name);

        });
    }

    modalGerar(visible: boolean) {
        this.formGerar.modalVisible = visible;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {

            if (valor) {

                if (chave === 'dataInicioDe') {
                    valor = this.guiasLotesService.formataDateBD(valor.toString());
                }

                if (chave === 'dataInicioAte') {
                    valor = this.guiasLotesService.formataDateBD(valor.toString());
                }

                if (chave === 'dataFimDe') {
                    valor = this.guiasLotesService.formataDateBD(valor.toString());
                }

                if (chave === 'dataFimAte') {
                    valor = this.guiasLotesService.formataDateBD(valor.toString());
                }

                this.currentParams.filter.push({key: chave, value: valor});

            }

        }

        this.gridLoading = true;

        const url = environment.apiUrl + '/integracoes/guias-lotes';

        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.arrayTableLotes = [];
            this.arrayTableLotes = response.data;

            this.gridLoading = false;
            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);
        });

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataInicioDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataInicioAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataFimDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataFimAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.layout) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.qtdRegistrosDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.qtdRegistrosAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.valorTotalDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.valorTotalAte) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }


    showConfirm(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Lote?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    remover(id: string) {

        this.guiasLotesService.remover(id).subscribe((response) => {

            this.toastrService.success('Registro removido com sucesso!');

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {
            this.toastrService.error(response.error.message);
        });
    }

    gerarLote() {

        const formItens = this.formGerar.formGroup.value;

        this.loadings.gravar = true;

        if (formItens.dataInicio) {
            formItens.dataInicio = this.guiasLotesService.formataDateBDFirstDay(formItens.dataInicio.toString());
        }

        if (formItens.dataFim) {
            formItens.dataFim = this.guiasLotesService.formataDateBDFirstDay(formItens.dataFim.toString());
        }

        this.guiasLotesService.gerarLote(this.formGerar.formGroup.value).subscribe((response) => {

            this.loadings.gravar = false;

            this.toastrService.success(response.message);

            this.formGerar.modalVisible = false;

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {
            this.toastrService.error(response.error.message);
            this.loadings.gravar = false;
        });

    }

    modalArquivo(visible: boolean, arquivoId: string = null) {

        this.arquivoUrl = null;
        this.camposLayout = [];

        this.formLayout.modalVisible = visible;

        if (arquivoId) {

            const formItens = {};

            this.guiasLotesService.retornaGuia(arquivoId).subscribe((response) => {

                this.arquivoUrl = this.apiUrl + '/' + response.arquivoUrl;

                this.arquivoId = response.id;

                this.camposLayout = Object.entries(response);

                this.camposLayout.forEach(value => {

                    formItens[value[0]] = [null, null];

                    switch (value[0]) {

                        case 'arquivoNome':
                            value.push('Identificador');
                            break;

                        case 'orgao':
                            value.push('Orgão');
                            break;

                        case 'cnpj':
                            value.push('CNPJ');
                            break;

                        case 'empresaNome':
                            value.push('Empresa');
                            break;

                        case 'ie':
                            value.push('Inscrição Estadual');
                            break;

                        case 'im':
                            value.push('Inscrição Municipal');
                            break;

                        case 'vencimentoData':
                            value.push('Data Vencimento');
                            break;

                        case 'emissaoData':
                            value.push('Data Emissão');
                            break;

                        case 'competencia':
                            value.push('Competência');
                            break;

                        case 'codigoReceita':
                            value.push('Código Receita');
                            break;

                        case 'linhaDigitavel':
                            value.push('Linha Digitável');
                            break;

                        case 'nossoNumero':
                            value.push('Nosso Número');
                            break;

                        case 'numeroDocumento':
                            value.push('Numero Documento');
                            break;

                        case 'valorTotal':
                            value.push('Total');
                            break;

                        case 'valorPrincipal':
                            value.push('Principal');
                            break;

                        case 'valorMulta':
                            value.push('Multa');
                            break;

                        case 'valorJuros':
                            value.push('Juros');
                            break;

                        case 'valorOutros':
                            value.push('Outros');
                            break;

                        case 'valorDesconto':
                            value.push('Desconto');
                            break;

                    }

                });

                this.formLayout.formGroup = this.fb.group(formItens);

                this.camposLayout.forEach(value => {
                    this.formLayout.formGroup.get(value[0]).setValue(value[1]);
                });

            });

        }

    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ComplianceCalendarComponent} from './complianceCalendar.component';

const routes: Routes = [

    {path: '', component: ComplianceCalendarComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class ComplianceCalendarRoutingModule {
}

import {Action} from '@ngrx/store';

export enum ActionTypes {
    Add = 'ADD',
    Remove = 'REM',
    Clear = 'CLE',
    ClearOthers = 'CLEOTH',
    Get = 'GET',
    Reload = 'RELOAD',
    Reorder = 'REORDER'
}

export const Add = (payload: any, name) => {
    return {type: ActionTypes.Add, payload, name} as Action;
};

export const Remove = (payload: any) => {
    return {type: ActionTypes.Remove, payload} as Action;
};

export const Clear = (name: string) => {
    return {type: ActionTypes.Clear, payload: null, name} as Action;
};

export const ClearOthers = (name: string) => {
    return {type: ActionTypes.ClearOthers, payload: null, name} as Action;
};

export const Get = () => {
    return {type: ActionTypes.Get, payload: null} as Action;
};

export const Reload = (payload: any, name) => {
    return {type: ActionTypes.Reload, payload, name} as Action;
};

export const Reorder = (payload: any, index: number) => {
    return {type: ActionTypes.Reorder, payload, index} as Action;
};

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Empresa} from '@models/empresa.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})

export class LogsAlteracoesService extends AbstractService<Empresa> {

  constructor(http: HttpClient) {
    super(http, '/logs', {});
  }

  getComboUsuarios(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
  }

  retornaComboEmpresas(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
  }


}

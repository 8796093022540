import {
    Component,
    Input,
    OnInit,
    ViewChildren,
    ViewEncapsulation,
    ChangeDetectorRef
} from '@angular/core';
import {NzFormatEmitEvent, NzTreeNode} from 'ng-zorro-antd/tree';
import {MegamenuService} from './megamenu.service';
import {TabService} from '@services/tab.service';
import {UrlTypeEnum} from '../../../shared/enums/url-type.enum';
import {ExternalPageComponent} from '../../../modules/external-page/pages/external-page.component';
import {Tab} from '@models/tab.model';
import {Subscription} from 'rxjs';
import {Favorito} from '@components/megamenu/favorito.model';
import {FavoritosService} from '@components/megamenu/favoritos.service';
import {Recente} from '@components/megamenu/recente.model';
import {RecentesService} from '@components/megamenu/recentes.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {DataService} from '@services/data.service';
import {AppComponent} from '../../../app.component';
import {UserService} from '@services/user.service';

@Component({
    selector: 'app-megamenu',
    templateUrl: './megamenu.component.html',
    styleUrls: ['./megamenu.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class MegaMenuComponent implements OnInit {
    private static cdr: ChangeDetectorRef;

    constructor(
        private dataService: DataService,
        private megamenuService: MegamenuService,
        private favoritosService: FavoritosService,
        private recentesService: RecentesService,
        private tabService: TabService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
    ) {

    }

    @ViewChildren('treeMegaMenu') treesMegaMenu;
    isVisibleTop = false;
    @Input() expandAll = false;
    searchValue = null;

    menus = [];
    favoritos: Favorito[];
    recentes: Recente[];

    components = {
        ExternalPageComponent
    };
    exibirAlfa: false;
    exibirBeta = false;
    exibirDemo = false;
    carregando: boolean;
    userAdmin = false;
    currentUser: Subscription;


    valorMenu = 'embedded.json';
    identificador = '';

    arrayOne = [];
    arrayTwo = [];
    arrayThree = [];

    expand = false;

    ngOnInit() {

        this.exibirDemo = localStorage.getItem('modoDemo') === 'true';

        this.currentUser = this.dataService.currentUser.subscribe((data) => {

            if (data) {

                this.userAdmin = data.origem === 'console';

            }

        });

        const beta = JSON.parse(localStorage.getItem('exibirBeta'));

        this.exibirBeta = beta;

        this.getMenuItens();

        this.cdr.detectChanges();

    }

    validaPesquisa() {
        this.searchValue = this.searchValue?.length === 0 ? null : this.searchValue;
    }

    showMegaMenu(): void {

        AppComponent.hideGuideOnApp();

        this.isVisibleTop = true;

        setTimeout(() => {
            if (window.Conpass) {
                // fabricio desabiliou
                // window.Conpass.startFlow('KuvYT5UrLwk');
            }
            document.getElementById('autoFocus').focus();
        }, 500);

    }

    closeMegaMenu(): void {
        this.isVisibleTop = false;
        this.searchValue = null;
    }

    getMenuItens(): void {

        localStorage.setItem('exibirBeta', JSON.stringify(this.exibirBeta));

        this.carregando = true;

        this.megamenuService.getTree().subscribe((data: any[]) => {

                const orderedData = [];
                let firstIndex = Math.min(...data.map(({order}) => order));

                if (data.length > 0) {

                    while (orderedData.length <= data.length) {

                        const item = data.filter((element => {
                            return element.order === firstIndex;
                        }));

                        if (item.length > 0) {

                            item.forEach((value) => {
                                orderedData.push(value);
                            });

                        }

                        firstIndex++;

                        // Para cases de erros no "order" (vazios ou repetidos) no back-end, sair do loop para evitar quebra
                        if (firstIndex === 100) {
                            break;
                        }

                    }
                }

                const menus = this.megamenuService.transformMegamenuItem(orderedData, 1, false, this.exibirBeta);

                this.menus = menus;
                this.getRecentes();

                this.arrayOne = [];
                this.arrayTwo = [];
                this.arrayThree = [];

                if (menus.length >= 3) {

                    const menuLength = menus.length;

                    const divideForThree = this.verifyDivide(menuLength, 3);

                    if (!divideForThree) {

                        let counterOne = menuLength;
                        let counterTwo = 0;

                        while (counterOne % 3 !== 0) {
                            counterOne--;
                            counterTwo++;
                        }

                        if (counterTwo === 1) {

                            if (menuLength % 4 === 0) {
                                this.arrayOne = menus.slice(0, Math.ceil(counterOne / 2));
                                this.arrayTwo = menus.slice(Math.ceil(counterOne / 2), counterOne);
                                this.arrayThree = menus.slice(counterOne, counterOne + counterTwo);
                            } else if (menuLength % 7 === 0) {
                                this.arrayOne = menus.slice(0, counterOne / 2);
                                this.arrayTwo = menus.slice(counterOne / 2, counterOne - 1);
                                this.arrayThree = menus.slice(counterOne - 1, counterOne + counterTwo);
                            } else if (menuLength % 10 === 0) {
                                this.arrayOne = menus.slice(0, counterOne / 2);
                                this.arrayTwo = menus.slice(counterOne / 2, counterOne - 1);
                                this.arrayThree = menus.slice(counterOne - 1, counterOne + counterTwo);
                            } else {
                                const divisao: number = Math.floor(menuLength / 3);
                                this.arrayOne = menus.slice(0, divisao);
                                this.arrayTwo = menus.slice(divisao, divisao * 2);
                                this.arrayThree = menus.slice(divisao * 2);
                            }

                        }

                        if (counterTwo === 2) {

                            if (menuLength % 5 === 0) {
                                this.arrayOne = menus.slice(0, Math.ceil(counterOne / 2));
                                this.arrayTwo = menus.slice(Math.ceil(counterOne / 2), counterOne + 1);
                                this.arrayThree = menus.slice(counterOne + 1, counterOne + counterTwo);
                            } else if (menuLength % 8 === 0) {
                                this.arrayOne = menus.slice(0, counterOne / 2);
                                this.arrayTwo = menus.slice(counterOne / 2, counterOne);
                                this.arrayThree = menus.slice(counterOne, counterOne + counterTwo);
                            } else {
                                const divisao: number = Math.floor(menuLength / 3);
                                this.arrayOne = menus.slice(0, divisao);
                                this.arrayTwo = menus.slice(divisao, divisao * 2);
                                this.arrayThree = menus.slice(divisao * 2);
                            }

                        }

                    } else {

                        const splitMenuLength = menuLength / 3;

                        this.arrayOne = menus.slice(0, splitMenuLength);
                        this.arrayTwo = menus.slice(splitMenuLength, (splitMenuLength * 2));
                        this.arrayThree = menus.slice((splitMenuLength * 2), (splitMenuLength * 3));

                    }

                } else {

                    if (menus.length === 1) {
                        this.arrayOne = menus.slice(0, 1);
                    }

                    if (menus.length === 2) {
                        this.arrayOne = menus.slice(0, 1);
                        this.arrayTwo = menus.slice(1, 2);
                    }

                }

                this.favoritos = this.favoritosService.transformItem(orderedData, [], this.exibirBeta, this.userAdmin);
                this.carregando = false;

            }
        );

    }

    verifyDivide(numero, divisor) {

        return numero % divisor === 0;

    }

    handleOk(): void {

        this.isVisibleTop = false;

    }

    handleCancel(): void {

        this.isVisibleTop = false;

    }

    nzEvent(event: NzFormatEmitEvent): void {

        const origin = event.node.origin;

        if (origin.url) {

            this.clickItem(origin);

        } else {

            const parentNode = event.node;
            const expanded = !parentNode.isExpanded;
            this.expandChild(parentNode, expanded);

        }

    }

    clickItem(origin): void {

        if (origin.url) {

            this.updateMenuRecentes(origin);
            let urlType: UrlTypeEnum;
            let componentName = 'ExternalPageComponent';

            switch (origin.urlType) {
                case UrlTypeEnum.embedded:
                    urlType = UrlTypeEnum.embedded;
                    break;
                case UrlTypeEnum.external:
                    urlType = UrlTypeEnum.external;
                    break;
                default:
                    urlType = UrlTypeEnum.nativo;

                    const component = findComponentByUrl(origin.url);
                    origin.url = buildUrl(component, '');
                    componentName = component.name;

                    break;
            }

            const data = {
                link: origin.url,
                extra: origin.extra
            };

            this.openTab(componentName, origin.description ? origin.description : origin.title, origin.url, data, urlType);

            this.closeMegaMenu();

        }
    }

    openTab(componentName: string, title: string, url: string, data ?: any, urlType ?: UrlTypeEnum) {

        const newTab = new Tab(componentName, title, url, urlType, data);
        this.tabService.addTab(newTab);

        setTimeout(() => {
            const urlAtual = window.location.href;
            if (!urlAtual.includes('.local') && !urlAtual.includes('.dev')) {
                this.userService.setHistory(urlAtual).subscribe(
                    (res) => {
                    },
                    (error) => console.log('Erro ao gravar log de acesso ' + error)
                );
            }
        }, 600);
    }

    expandChild(node: NzTreeNode, expanded: boolean) {

        node.isExpanded = expanded;

        if (node.children) {

            node.children.forEach(child => this.expandChild(child, expanded));

        }

    }

    expandAllHandler(expanded = true) {

        this.expand = expanded;

        this.searchValue = null;
        const expandTreeNodes = (node: NzTreeNode) => {

            node.isExpanded = expanded;

            if (node.children) {

                node.children.forEach(expandTreeNodes);

            }
        };

        this.treesMegaMenu.forEach(treeMegaMenu => {
            treeMegaMenu.handleSearchValue('');
            treeMegaMenu.getTreeNodes().forEach(expandTreeNodes);

        });

    }

    updateMenuRecentes(menu) {

        this.recentesService.toggle(menu.key, true).subscribe(null, null, () => {

            this.getRecentes();

        });
    }

    getRecentes() {

        this.recentesService.list().subscribe(itens => {

            const recentes = [];

            for (const item of itens) {

                const menuItem = this.getMenusById(item.menu_id);

                if (menuItem) {

                    const recenteItem = new Recente();
                    recenteItem.id = menuItem.key;
                    recenteItem.descricao = menuItem.title;
                    recenteItem.descricaoEstendida = menuItem.description;

                    recentes.push(recenteItem);

                }

            }

            this.recentes = recentes;

        });

    }

    clickRecente(id: string) {

        const found = this.getTreeItemById(id);

        if (found) {
            this.clickItem(found.origin);
        }

    }

    setMenuItemRececente(event, id, toggle = true) {

        event.stopPropagation();

        const menuItem = this.getMenusById(id);

        if (menuItem && toggle) {

            const item = new Recente();
            item.id = menuItem.key;
            item.descricao = menuItem.title;
            item.descricaoEstendida = menuItem.description;

            this.recentes.push(item);

        } else {

            this.recentes = this.recentes.filter(recente => recente.id !== id);

        }

        this.recentesService.toggle(menuItem.key, toggle).subscribe(null, null, () => {

            this.getRecentes();

        });

    }

    clickFavorito(id: string) {

        const found = this.getTreeItemById(id);

        if (found) {
            this.clickItem(found.origin);
        }

    }

    setMenuItemFavorito(event, id, toggle = true) {

        event.stopPropagation();

        const menuItem = this.getTreeItemById(id);

        if (menuItem) {

            menuItem.origin.favorito = toggle;

        }

        if (menuItem && toggle) {

            const item = new Favorito();
            item.id = menuItem.key;
            item.descricao = menuItem.origin.title;
            item.descricaoEstendida = menuItem.origin.description;

            this.favoritos.push(item);

        } else {

            this.favoritos = this.favoritos.filter(favorito => favorito.id !== id);

        }

        this.favoritosService.toggle(menuItem.key, toggle).subscribe(() => {

        });

    }

    carregaMenu() {

        this.getMenuItens();

    }

    getTreeItemById(id) {

        const expandTreeNodes = (node: NzTreeNode) => {

            if (node.key === id) {
                return node;
            }

            if (node.children) {

                for (const item of node.children) {

                    const found = expandTreeNodes(item);

                    if (found) {
                        return found;
                    }

                }
            }
        };

        for (const tree of this.treesMegaMenu) {

            for (const item of tree.getTreeNodes()) {

                const found = expandTreeNodes(item);

                if (found) {
                    return found;
                }

            }

        }

    }

    getMenusById(id) {

        const expandTreeNodes = (node) => {

            if (node.key === id) {
                return node;
            }

            if (node.children) {

                for (const item of node.children) {

                    const found2 = expandTreeNodes(item);

                    if (found2) {
                        return found2;
                    }

                }
            }
        };

        for (const menus of this.arrayOne) {

            const found = expandTreeNodes(menus);

            if (found) {
                return found;
            }

        }
        for (const menus of this.arrayTwo) {

            const found = expandTreeNodes(menus);

            if (found) {
                return found;
            }

        }
        for (const menus of this.arrayThree) {

            const found = expandTreeNodes(menus);

            if (found) {
                return found;
            }

        }

    }

    mudarMenuTesteJson(event: any): void {
        this.valorMenu = event;
        this.getMenuItens();
    }

    modoDemonstracao(): void {
        this.carregando = true;
        localStorage.setItem('modoDemo', this.exibirDemo ? 'true' : 'false');
        setTimeout(() => {
            this.carregando = false;
        }, 500);

    }

}

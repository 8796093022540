import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {CalendarObrigacoesComponent} from './calendarObrigacoes.component';

const routes: Routes = [

    {path: '', component: CalendarObrigacoesComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class CalendarObrigacoesRoutingModule {
}

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit, ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LOCALE_ID} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GuiasArquivosService} from './guiasArquivos.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {UsuarioEquipeService} from '@services/usuarioEquipe.service';
import {ToastrService} from 'ngx-toastr';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {boleto} from 'boleto-brasileiro-validator';
import {Boleto} from 'broleto';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Helpers} from '../../../core/helpers';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './guiasArquivos.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./guiasArquivos.component.scss']
})

export class GuiasArquivosComponent extends AbstractListTable<any> implements OnInit {

    pagination: any = {
        estaduais: Pagination,
        municipais: Pagination,
        federais: Pagination,
        naoIdentificadas: Pagination
    };

    cardCompetenciaAtivo: number;

    formLayout: FormStack;

    empresaNome = '';

    anoSelecionado: number = null;
    arrayAnos = [];

    arrayTableObrigacoes = [];

    arrayTableEstaduais = [];
    arrayTableMunicipais = [];
    arrayTableFederais = [];
    arrayTableNaoIdentificadas = [];

    status = false;
    statusProcessamento = 'emProcessamento';

    expandSet = new Set<number>();

    gridLoading = false;

    cardTabsLoading = false;

    tabNumber = 0;

    collapseCard = false;
    pageTitle = 'Guias Federais';
    showTable = 'F';
    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    currentSearch: string;

    formFiltrar: FormStack;
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectUf = [];
    viewModalUploadArquivo = false;

    apiUrl = environment.apiUrl;

    uploading = false;
    fileList: NzUploadFile[] = [];

    camposLayout = [];
    arquivoUrl = null;
    arquivoId = '';
    qtdFiltrosAtivos = 0;

    widthColumnEmpresaNome = 0;
    widthColumnLinhaDigitavel = 0;
    widthColumnArquivoNome = 0;

    loadings = {
        gravando: false
    };

    codBarrasValido = true;

    errorsFormLayout = {
        codBarras: 'Cód. Barras Inválido'
    };

    valorTotalValido = true;

    selectIdentificadores = [];

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    iframeUrl: SafeResourceUrl = '';

    constructor(
        private toastService: ToastrService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef,
        private guiasArquivosService: GuiasArquivosService,
        private fb: UntypedFormBuilder,
        public sanitizer: DomSanitizer,
    ) {
        super(guiasArquivosService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                uf: [null, null],
                ie: [null, null],
                im: [null, null],
                competencia: [null, null],
                dataInicioDe: [null, null],
                dataInicioAte: [null, null],
                dataFimDe: [null, null],
                dataFimAte: [null, null],
                armazenamento: [null, null],
                arquivoNome: [null, null],
                valorDe: [null, null],
                valorAte: [null, null],
                codBarras: [null, null],
                vencimento: [null, null],
                status: [null, null],
                possuiLote: [null, null],
            })
        };

        this.formLayout = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.guiasArquivosService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.guiasArquivosService.retornarSelectsUfs(
        ).subscribe((retorno: any) => {
            this.arraySelectUf = retorno;
        });

        this.guiasArquivosService.retornaSelectsIdentificadores(
        ).subscribe((retorno: any) => {
            this.selectIdentificadores = retorno;
        });

        this.dataExport = {
            url: '/',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Guias Federais',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };


    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    ngOnInit(): void {
        this.changeTabs();
    }

    displayTextWidth(text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '12px Arial';
        const metrics = context.measureText(text);
        return metrics.width + 16;
    }

    changeTable(tabela) {

        if (!this.gridLoading) {

            this.currentParams.pageIndex = 1;
            this.pagination.federais.current_page = 1;
            this.pagination.estaduais.current_page = 1;
            this.pagination.municipais.current_page = 1;
            this.pagination.naoIdentificadas.current_page = 1;

            if (tabela === 'F') {
                this.pageTitle = 'Guias Federais';
                this.statusProcessamento = 'armazenadas';
            }

            if (tabela === 'E') {
                this.pageTitle = 'Guias Estaduais';
                this.statusProcessamento = 'armazenadas';
            }

            if (tabela === 'M') {
                this.pageTitle = 'Guias Municipais';
                this.statusProcessamento = 'armazenadas';
            }

            if (tabela === 'NI') {
                this.pageTitle = 'Guias Não Identificadas';
                this.statusProcessamento = 'naoIdentificadas';
                this.tabNumber = 0;
                this.queryTable(this.currentParams, this.currentSearch);
            }

            this.showTable = tabela;
        }
    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.cdr.detectChanges();
    }

    changeTabs(event: any = null) {

        switch (this.tabNumber) {

            case 0: {

                if (this.showTable === 'E' || this.showTable === 'M' || this.showTable === 'F') {
                    this.statusProcessamento = 'armazenadas';

                } else {
                    this.statusProcessamento = 'naoIdentificadas';

                }

                break;
            }

            case 1: {
                this.statusProcessamento = 'emProcessamento';
                break;
            }

        }

        this.queryTable(this.currentParams, this.currentSearch);

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                if (chave === 'competencia' || chave === 'competencia') {
                    valor = this.guiasArquivosService.formataCompetenciaBD(valor.toString());
                }
                if (chave === 'dataInicioDe' || chave === 'dataInicioDe') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }
                if (chave === 'dataInicioAte' || chave === 'dataInicioAte') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }
                if (chave === 'dataFimDe' || chave === 'dataFimDe') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }
                if (chave === 'dataFimAte' || chave === 'dataFimAte') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }
                if (chave === 'armazenamento' || chave === 'armazenamento') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }
                if (chave === 'vencimento' || chave === 'vencimento') {
                    valor = this.guiasArquivosService.formataDateBD(valor.toString());
                }

                this.currentParams.filter.push({key: chave, value: valor});
            }
        }


        this.currentSearch = search;


        if (this.showTable === 'E') {
            this.queryTableEstaduais(this.currentParams, this.currentSearch);
        } else if (this.showTable === 'M') {
            this.queryTableMunicipais(this.currentParams, this.currentSearch);
        } else if (this.showTable === 'F') {
            this.queryTableFederais(this.currentParams, this.currentSearch);
        } else if (this.showTable === 'NI') {
            this.queryTableNaoIdentificadas(this.currentParams, this.currentSearch);
        }


    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 2;
        }
        if (this.formFiltrar.formGroup.value.uf) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.ie) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.im) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.competencia) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataInicioDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataInicioAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.armazenamento) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.arquivoNome) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.valorDe) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.valorAte) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.codBarras) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.vencimento) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.possuiLote !== null) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.status && this.tabNumber === 0 && this.showTable !== 'NI') {
            qtd += 1;
        }


        this.qtdFiltrosAtivos = qtd;
    }


    queryTableEstaduais(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;

        this.gridLoading = true;

        const url = this.guiasArquivosService.baseUrlEstaduais + this.statusProcessamento;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.arrayTableEstaduais = [];
            this.arrayTableEstaduais = response.data;

            this.arrayTableEstaduais.forEach((value) => {

                const widthEmpresaNome = this.displayTextWidth(value.unidade);

                if (widthEmpresaNome > this.widthColumnEmpresaNome) {
                    this.widthColumnEmpresaNome = widthEmpresaNome;
                }

                const widthLinhaDigitavel = this.displayTextWidth(value.codBarras);

                if (widthLinhaDigitavel > this.widthColumnLinhaDigitavel) {
                    this.widthColumnLinhaDigitavel = widthLinhaDigitavel;
                }

                const widthArquivoNome = this.displayTextWidth(value.arquivoNome);

                if (widthArquivoNome > this.widthColumnArquivoNome) {
                    this.widthColumnArquivoNome = widthArquivoNome;
                }

                value.arquivoNomeAbrev = '...' + value.arquivoNome.substring(value.arquivoNome.length - 10);

            });

            this.gridLoading = false;
            this.pagination.estaduais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);
        });
    }

    queryTableMunicipais(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;
        this.currentSearch = search;

        this.gridLoading = true;

        const url = this.guiasArquivosService.baseUrlMunicipais + this.statusProcessamento;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.arrayTableMunicipais = [];
            this.arrayTableMunicipais = response.data;

            this.arrayTableMunicipais.forEach((value) => {

                const widthEmpresaNome = this.displayTextWidth(value.unidade);

                if (widthEmpresaNome > this.widthColumnEmpresaNome) {
                    this.widthColumnEmpresaNome = widthEmpresaNome;
                }

                const widthLinhaDigitavel = this.displayTextWidth(value.codBarras);

                if (widthLinhaDigitavel > this.widthColumnLinhaDigitavel) {
                    this.widthColumnLinhaDigitavel = widthLinhaDigitavel;
                }

                const widthArquivoNome = this.displayTextWidth(value.arquivoNome);

                if (widthArquivoNome > this.widthColumnArquivoNome) {
                    this.widthColumnArquivoNome = widthArquivoNome;
                }

                value.arquivoNomeAbrev = '...' + value.arquivoNome.substring(value.arquivoNome.length - 10);
            });

            this.gridLoading = false;
            this.pagination.municipais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

        });
    }

    queryTableFederais(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;
        this.currentSearch = search;

        this.gridLoading = true;

        const url = this.guiasArquivosService.baseUrlFederais + this.statusProcessamento;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.arrayTableFederais = [];
            this.arrayTableFederais = response.data;


            this.arrayTableFederais.forEach((value) => {

                const widthEmpresaNome = this.displayTextWidth(value.unidade);

                if (widthEmpresaNome > this.widthColumnEmpresaNome) {
                    this.widthColumnEmpresaNome = widthEmpresaNome;
                }

                const widthLinhaDigitavel = this.displayTextWidth(value.codBarras);

                if (widthLinhaDigitavel > this.widthColumnLinhaDigitavel) {
                    this.widthColumnLinhaDigitavel = widthLinhaDigitavel;
                }

                const widthArquivoNome = this.displayTextWidth(value.arquivoNome);

                if (widthArquivoNome > this.widthColumnArquivoNome) {
                    this.widthColumnArquivoNome = widthArquivoNome;
                }

                value.arquivoNomeAbrev = '...' + value.arquivoNome.substring(value.arquivoNome.length - 10);
            });

            this.gridLoading = false;
            this.pagination.federais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

        });
    }

    queryTableNaoIdentificadas(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;
        this.currentSearch = search;

        this.gridLoading = true;

        if (this.tabNumber === 1) {
            params.pageSize = null;
            params.pageIndex = null;
        }

        const url = this.guiasArquivosService.baseUrlNaoIdentificadas + this.statusProcessamento;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            // Não Identificadas
            if (this.tabNumber === 0) {
                this.arrayTableNaoIdentificadas = [];
                this.arrayTableNaoIdentificadas = response.data;

                this.arrayTableNaoIdentificadas.forEach((value) => {

                    const widthEmpresaNome = this.displayTextWidth(value.unidade);

                    if (widthEmpresaNome > this.widthColumnEmpresaNome) {
                        this.widthColumnEmpresaNome = widthEmpresaNome;
                    }

                    const widthLinhaDigitavel = this.displayTextWidth(value.codBarras);

                    if (widthLinhaDigitavel > this.widthColumnLinhaDigitavel) {
                        this.widthColumnLinhaDigitavel = widthLinhaDigitavel;
                    }

                    const widthArquivoNome = this.displayTextWidth(value.arquivoNome);

                    if (widthArquivoNome > this.widthColumnArquivoNome) {
                        this.widthColumnArquivoNome = widthArquivoNome;
                    }

                    value.arquivoNomeAbrev = '...' + value.arquivoNome.substring(value.arquivoNome.length - 10);
                });

                this.gridLoading = false;
                this.pagination.naoIdentificadas = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                // Em processamento
            } else if (this.tabNumber === 1) {


                this.arrayTableNaoIdentificadas = [];
                this.arrayTableNaoIdentificadas = response;
                this.gridLoading = false;


            }
        });
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrar(): any {

        this.changeTabs(null);

        this.formFiltrar.modalVisible = false;

        this.cdr.detectChanges();
    }

    exportDocumento(name) {

        this.guiasArquivosService.exportDocumento(name).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, name);

        });

    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;

    }

    confirmarImportar(): void {

        this.uploading = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivos[]', file);
        });

        formData.append('orgao', this.showTable === 'E' ? 'Estadual' : 'Municipal');

        this.guiasArquivosService.uploadPlanilha(formData).subscribe((response: any) => {

                if (response && response.status === 200) {
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }

                this.fileList = [];

                this.viewModalUploadArquivo = false;

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.uploading = false;
            });

    }

    modalCadastrar(visible: boolean, zerar: boolean = false) {

    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    async modalArquivo(visible: boolean, arquivoId: string = null) {

        this.arquivoUrl = null;
        this.camposLayout = [];

        this.formLayout.modalVisible = visible;

        if (arquivoId) {


            this.guiasArquivosService.retornaGuia(arquivoId).subscribe(async (response) => {

                this.arquivoId = response.id;

                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.arquivoBase64);

                const camposLayout = Object.entries(response).filter((element) => {
                    return element[0] !== 'arquivoLayout';
                });

                camposLayout.unshift(['arquivoLayout', response.arquivoLayout]);

                const formItens = await this.montaCamposLayout(camposLayout);

                this.formLayout.formGroup = this.fb.group({});

                Object.entries(formItens).forEach(([key, value]) => {

                    if (key === 'arquivoLayout' ||
                        key === 'cnpjRecebedor' ||
                        key === 'empresaNome' ||
                        key === 'emissaoData' ||
                        key === 'vencimentoData' ||
                        key === 'competencia' ||
                        key === 'tipoReceita' ||
                        key === 'codigoReceita' ||
                        key === 'valorMulta' ||
                        key === 'valorJuros') {

                        this.formLayout.formGroup.addControl(key, new UntypedFormControl(null, Validators.required));

                    } else if (key === 'linhaDigitavel') {

                        this.formLayout.formGroup.addControl(key, new UntypedFormControl(null, this.confirmationCodBarras));

                    } else if (key === 'cnpj') {

                        this.formLayout.formGroup.addControl(key, new UntypedFormControl(null, this.confirmationCnpj));

                    } else if (key === 'valorTotal' || key === 'valorPrincipal') {

                        this.formLayout.formGroup.addControl(key, new UntypedFormControl(null, this.verficaValorPositivo));

                    } else {

                        this.formLayout.formGroup.addControl(key, new UntypedFormControl(null, null));

                    }

                });

                this.formLayout.formGroup = this.fb.group(formItens);

                camposLayout.forEach(value => {

                    if (value[1]) {

                        switch (value[0]) {

                            case 'competencia':
                            case 'emissaoData':
                            case 'vencimentoData':
                                const date = value[1].toString().split('-');
                                date[2] = date[2] ? date[2].toString() : '1';
                                value[1] = new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]));
                                break;

                            case 'valorDesconto':
                            case 'valorJuros':
                            case 'valorMulta':
                            case 'valorOutros':
                            case 'valorPrincipal':
                            case 'valorTotal':

                                value[1] = value[1].toString().replace('.', ',');

                                break;

                        }

                        if (value[1]) {
                            this.formLayout.formGroup.get(value[0]).setValue(value[1]);
                        }

                    }

                });

                this.camposLayout = camposLayout;


                Object.values(this.formLayout.formGroup.controls).forEach(control => {

                    if (control.invalid) {
                        control.markAsDirty();
                        control.updateValueAndValidity({onlySelf: true});
                    }

                });

                this.verificaValorTotal();

            });

        }

    }

    modalImportarArquivo(visible: boolean) {
        this.viewModalUploadArquivo = visible;
    }

    confirmaFiltrar(): void {

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;

        let url = '';
        switch (this.showTable) {
            case 'E': {
                url = 'estaduais/' + this.statusProcessamento + '/exportar';
                break;
            }
            case 'M': {
                url = 'municipais/' + this.statusProcessamento + '/exportar';
                break;
            }
            case 'NI': {
                url = 'naoIdentificadas/exportar';
                break;
            }
        }

        this.dataExport.url = url;


        this.componentExport.visible = visible;
    }

    gravarArquivo() {


        this.codBarrasValido = true;
        const campo = this.formLayout.formGroup.controls.linhaDigitavel;
        campo.updateValueAndValidity({onlySelf: true});


        let formInvalid = !this.valorTotalValido;
        Object.values(this.formLayout.formGroup.controls).forEach(control => {
            if (control.invalid) {
                formInvalid = true;
            }
        });


        if (!formInvalid) {

            this.loadings.gravando = true;

            const itens = Object.entries(this.formLayout.formGroup.value).filter((item) => {
                return item[1] !== null;
            });

            const data = {};

            itens.forEach((item) => {
                data[item[0]] = item[1];
            });

            this.guiasArquivosService.gravarArquivo(this.arquivoId, data).subscribe((response) => {

                    this.loadings.gravando = false;

                    if (response.message) {
                        this.toastService.success('Dados atualizados com sucesso!');
                    }

                    this.formLayout.modalVisible = false;

                    this.queryTable(this.currentParams, this.currentSearch);

                },
                (response) => {
                    this.loadings.gravando = false;
                    this.toastrService.error(response.error);

                    if (response.error === 'Codigo de barras inválido!') {
                        this.codBarrasValido = false;
                        const campo = this.formLayout.formGroup.controls.linhaDigitavel;
                        campo.markAsDirty();
                        campo.updateValueAndValidity({onlySelf: true});
                    }


                });

        }

    }

    showConfirmRemover(idGuia: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Arquivo?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerGuia(idGuia)
        });

    }

    removerGuia(id: string): void {

        if (id) {

            this.guiasArquivosService.removerGuia(id).subscribe((response) => {


                this.queryTable(this.currentParams, this.currentSearch);
                this.toastService.success('Arquivo removido com sucesso');

            }, (response) => {
                this.toastService.error(response.error.message);
            });
        }
    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }


    confirmationCodBarras = (control: UntypedFormControl): { [s: string]: boolean } => {

        if (!control.value) {
            this.errorsFormLayout.codBarras = 'Campo Obrigatório';
            return {confirm: true, error: true};
        }

        let codBarras = control.value;

        if (codBarras.length < 44) {
            while (codBarras.length < 44) {
                codBarras = '0' + codBarras;
            }
        }

        if (!boleto(codBarras)) {
            this.errorsFormLayout.codBarras = 'Cód. Barras Inválido';
            return {confirm: true, error: true};
        } else {
            const valorBoleto = new Boleto(codBarras).amount();
            let valorFomulario = 0;
            if (this.formLayout.formGroup.value.valorTotal) {
                valorFomulario = this.formLayout.formGroup.value.valorTotal.replace(',', '.');
            }


            if (valorBoleto !== Number(valorFomulario)) {
                this.errorsFormLayout.codBarras = 'Valor do código de barras (' + valorBoleto + ') não confere com formulário (' +
                    Number(valorFomulario) + ')';
                return {confirm: true, error: true};
            }

        }


        return {};
    }

    confirmationCnpj = (control: UntypedFormControl): { [s: string]: boolean } => {

        if (!Helpers.isValidCnpj(control.value)) {
            return {confirm: true, error: true};
        }
        return {};
    }

    verficaValorPositivo(control: UntypedFormControl) {
        const valor = Math.sign(parseFloat(control.value));
        const retorno = (valor !== 1) ? {confirm: true, error: true} : {};

        return retorno;
    }

    verificaValorTotal(): any {

        const valores = {
            principal: Number(this.formLayout.formGroup.value.valorPrincipal.replace(',', '.')),
            multa: Number(this.formLayout.formGroup.value.valorMulta.replace(',', '.')),
            juros: Number(this.formLayout.formGroup.value.valorJuros.replace(',', '.')),
            outros: Number(this.formLayout.formGroup.value.valorOutros.replace(',', '.')),
            desconto: Number(this.formLayout.formGroup.value.valorDesconto.replace(',', '.')),
            total: Number(this.formLayout.formGroup.value.valorTotal.replace(',', '.')),
        };

        let soma = valores.principal + valores.multa + valores.juros + valores.outros - valores.desconto;
        soma = parseFloat(soma.toFixed(2));

        this.valorTotalValido = soma === valores.total;

    }

    preencheCampos(event) {

        const identificadorSelecionado = this.selectIdentificadores.filter((element) => {
            return element.arquivoLayout === event;
        });

        this.formLayout.formGroup.get('cnpjRecebedor').setValue(identificadorSelecionado[0].cnpjRecebedor);

        this.formLayout.formGroup.get('tipoReceita').setValue(identificadorSelecionado[0].tipoReceita);

    }

    private async montaCamposLayout(camposLayout: [string, unknown][]) {

        const formItens = {};

        camposLayout.forEach(value => {

            formItens[value[0]] = [null, null];

            switch (value[0]) {

                case 'arquivoLayout':
                    value.push('Identificador');
                    break;

                case 'orgao':
                    value.push('Orgão');
                    break;

                case 'cnpj':
                    value.push('CNPJ');
                    break;

                case 'cnpjRecebedor':
                    value.push('CNPJ Recebedor');
                    break;

                case 'empresaNome':
                    value.push('Empresa');
                    break;

                case 'ie':
                    value.push('Inscrição Estadual');
                    break;

                case 'im':
                    value.push('Inscrição Municipal');
                    break;

                case 'vencimentoData':
                    value.push('Data Vencimento');
                    break;

                case 'emissaoData':
                    value.push('Data Emissão');
                    break;

                case 'competencia':
                    value.push('Competência');
                    break;

                case 'tipoReceita':
                    value.push('Tipo Receita');
                    break;

                case 'codigoReceita':
                    value.push('Código Receita');
                    break;

                case 'linhaDigitavel':
                    value.push('Linha Digitável');
                    break;

                case 'nossoNumero':
                    value.push('Nosso Número');
                    break;

                case 'numeroDocumento':
                    value.push('Numero Documento');
                    break;

                case 'valorTotal':
                    value.push('Total');
                    break;

                case 'valorPrincipal':
                    value.push('Principal');
                    break;

                case 'valorMulta':
                    value.push('Multa');
                    break;

                case 'valorJuros':
                    value.push('Juros');
                    break;

                case 'valorOutros':
                    value.push('Outros');
                    break;

                case 'valorDesconto':
                    value.push('Desconto');
                    break;

            }

        });

        return formItens;
    }
}

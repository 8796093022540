import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';
import {XmlXSpedService} from './regras-desconsideracoes.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {Helpers} from '../../../core/helpers';

@Component({
    selector: 'app-regras-desconsiderações',
    templateUrl: './regras-desconsideracoes.component.html',
    styleUrls: ['./regras-desconsideracoes.component.scss']
})
export class RegrasDesconsideracoesComponent implements OnInit {


    pagination = new Pagination();

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            cnpj: 'cnpj inválido',
        }
    };

    formRegras = this.fb.group({
        obrigacoes: [[]],
        modelo: [null, Validators.required],
        tipo: [null, Validators.required],
        vigenciaInicio: [null, Validators.required],
        vigenciaFim: [null],
        cfops: [[]],
        ncms: [[]],
        serie: [[]],
        emitente: [[]],
        destinatario: [[]],
        tipoDoc: ['cnpj'],
        tipoEmissao: [null, Validators.required],
        inputD: [null],
        inputE: [null],
    });

    formFiltrar = this.fb.group({
        modelo: [null],
        tipo: [null],
        emitente: [null],
        destinatario: [null],
        cfop: [null],
        serie: [null],
        ncm: [null],
        obrigacao: [null]
    });

    maskDoc = '00.000.000/0000-00';

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };

    qtdFiltrosAtivos = 0;

    currentSearch = null;

    modalAdicionarVisible = false;
    modalFiltrarVisible = false;

    loadingTable = false;
    loadingAdicionar = false;
    loadingRemover = [];
    loadingDetalhe = [];
    loadingModalEditar = [];
    statusCard = false;

    modalDetalhesData = [];
    tituloModalDetalhes = null;
    modalDetalhesVisible = false;

    titulos = {
        obrigacoes: 'Obrigações',
        cfop: 'CFOPs',
        ncm: 'NCMs',
        emitente: 'Emitentes',
        destinatario: 'Destinatários',
        serie: 'Séries',
    };

    regras = [];

    comboObrigacoes = [];

    regraSelecionada = null;

    @ViewChild('emitente') emitente: any;
    @ViewChild('destinatario') destinatario: any;

    protected readonly Helpers = Helpers;

    constructor(
        private fb: FormBuilder,
        private modalService: NzModalService,
        private toastService: ToastrService,
        private service: XmlXSpedService
    ) {

        this.service.retornaComboObrigacoes().subscribe((retorno: any) => {
            Object.entries(retorno).forEach((value: any) => {
                this.comboObrigacoes.push({
                    value: value[1].id,
                    label: value[1].descricao
                });
            });
        });

    }

    ngOnInit() {
        this.formRegras.get('tipoDoc').setValue('cnpj');
        this.queryTable(this.currentParams);
    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        params.sort.forEach(s => {
            if (s.value) {
                this.currentParams.filter.push({key: 'ordenar', value: s.key});
                this.currentParams.filter.push({key: 'sentido', value: s.value});
            }
        });

        if (this.currentSearch) {
            this.currentParams.filter.push({key: 'procurar', value: this.currentSearch});
        }

        if (this.currentParams.pageIndex) {
            this.pagination.current_page = this.currentParams.pageIndex;
            this.currentParams.filter.push({key: 'pagina', value: this.pagination.current_page});
        }

        this.loadingTable = true;

        this.service.listar(this.currentParams.filter).subscribe({
            next: (response) => {
                this.regras = [];

                this.regras = response.data;

                this.regras.forEach(r => {
                    r.cfopArray = r.cfops?.split(',');
                    r.ncmArray = r.ncms?.split(',');
                    r.emitenteArray = r.emitente?.split(',');
                    r.serieArray = r.serie?.split(',');
                    r.destinatarioArray = r.destinatario?.split(',');
                });

                this.pagination = new Pagination(
                    response.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loadingTable = false;

            }, error: err => {
                this.loadingTable = false;
                this.toastService.error(err.error.message);
            }
        });

    }

    listByTableAcessosEcac(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.value)) {

                if (valor && chave) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }

    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: [],
        };

        this.formFiltrar.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    showModalAdicinarAlterar(visible: boolean, data = null) {

        this.regraSelecionada = data;

        if (data) {

            this.loadingModalEditar.push(data.id);

            this.service.getObrigacoes(data.id).subscribe({
                next: (retorno: any) => {

                    const obgIds = [];
                    retorno.obrigacoes.forEach(o => {
                        obgIds.push(o.obrigacao_id);
                    });

                    this.resetForm(obgIds);

                    this.comboObrigacoes.forEach(obg => {
                        // @ts-ignore
                        if (this.formRegras.get('obrigacoes').value.includes(obg.value)) {
                            obg.direction = 'right';
                        }
                    });

                    data.emitenteArray = data.emitenteArray?.map(e => {
                        return Helpers.maskCnpjCpf(e);
                    });

                    data.destinatarioArray = data.destinatarioArray?.map(d => {
                        return Helpers.maskCnpjCpf(d);
                    });

                    this.formRegras.get('modelo').setValue(data.modelo);
                    this.formRegras.get('tipo').setValue(data.tipo);
                    this.formRegras.get('cfops').setValue(data.cfopArray);
                    this.formRegras.get('ncms').setValue(data.ncmArray);
                    this.formRegras.get('serie').setValue(data.serieArray);
                    this.formRegras.get('emitente').setValue(data.emitenteArray);
                    this.formRegras.get('destinatario').setValue(data.destinatarioArray);
                    this.formRegras.get('vigenciaInicio').setValue(data.vigenciaInicio);
                    this.formRegras.get('vigenciaFim').setValue(data.vigenciaFim);
                    this.formRegras.get('tipoEmissao').setValue(data.tipoEmissao || null);

                    this.loadingModalEditar = [];

                    this.modalAdicionarVisible = visible;
                }, error: err => {
                    this.loadingModalEditar = [];
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            this.modalAdicionarVisible = visible;
        }

        if (!visible) {
            this.resetForm();
            this.comboObrigacoes.forEach(obg => {
                obg.direction = 'left';
            });
            this.queryTable(this.currentParams, this.currentSearch);
        }

    }

    verDetalhes(modal: string, visible: boolean, data: any) {
        this.tituloModalDetalhes = this.titulos[modal];
        switch (modal) {

            case 'obrigacoes':
                this.loadingDetalhe.push(data.id);

                this.service.getObrigacoes(data.id).subscribe({
                    next: (retorno: any) => {
                        this.modalDetalhesData = retorno.obrigacoes.map(o => o.obrigacao_descricao);
                        this.modalDetalhesVisible = visible;
                        this.loadingDetalhe = [];
                    }, error: err => {
                        this.loadingDetalhe = [];
                        this.toastService.error(err.error.message);
                    }
                });
                break;
            default:
                this.modalDetalhesData = data;
                this.modalDetalhesVisible = visible;
        }

    }

    showConfirm(id: number): void {

        this.modalService.confirm({
            nzTitle: 'Deseja <b>remover</b> o registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    showModalFiltrar(visible: boolean) {
        this.modalFiltrarVisible = visible;
    }

    remover(id: number) {

        this.loadingRemover.push(id);

        this.service.remover(id).subscribe({
            next: (retorno: any) => {
                this.toastService.success(retorno.message);
                this.loadingRemover = [];
                this.queryTable(this.currentParams, this.currentSearch);
            }, error: err => {
                this.loadingRemover = [];
                this.toastService.error(err.error.message);
            }
        });
    }

    changeTranferObrigacoes(event: any) {

        const f = this.formRegras;

        if (event.to === 'right') {

            event.list.forEach(obg => {
                // @ts-ignore
                f.get('obrigacoes').value.push(obg.value);
            });
        } else {

            event.list.forEach(obg => {
                // @ts-ignore
                const index = f.controls.obrigacoes.value.indexOf(obg.value);
                // @ts-ignore
                f.controls.obrigacoes.value.splice(index, 1);
            });
        }

    }

    setRegra() {

        const ncms: any = this.formRegras.value.ncms;
        const cfops: any = this.formRegras.value.cfops;

        if (this.formRegras.valid) {

            const obg: any = this.formRegras.value.obrigacoes;
            let error = false;

            if (obg.length === 0) {
                this.toastService.error('É necessário informar pelo menos uma obrigação.');
                error = true;
            }

            if (!ncms?.length && !cfops?.length &&
                !this.formRegras.value.serie &&
                !this.formRegras.value.emitente &&
                !this.formRegras.value.destinatario) {

                this.toastService.error('Preencha pelo menos um dos campos: CFOP, NCM, Série, Emitente ou Destinatário');
                error = true;
            }

            if (this.validaFormRegra() || error) {
                return;
            }

            if (this.formRegras.value.emitente) {
                const emitentes = [];

                // @ts-ignore
                this.formRegras.get('emitente').value.forEach(e => {
                    e = e.replace(/\D/g, '');
                    emitentes.push(e);
                });

                // @ts-ignore
                this.formRegras.get('emitente').setValue(emitentes);
            }

            if (this.formRegras.value.destinatario) {
                const destinatarios = [];

                // @ts-ignore
                this.formRegras.get('destinatario').value.forEach(d => {
                    d = d.replace(/\D/g, '');
                    destinatarios.push(d);
                });

                // @ts-ignore
                this.formRegras.get('destinatario').setValue(destinatarios);
            }


            this.loadingAdicionar = true;

            this.service.setRegra(this.formRegras.value, this.regraSelecionada?.id).subscribe({
                next: (retorno: any) => {
                    this.toastService.success(retorno.message);
                    this.showModalAdicinarAlterar(false);
                    this.loadingAdicionar = false;

                }, error: err => {
                    this.loadingAdicionar = false;
                    this.toastService.error(err.error.message);
                }
            });
        } else {
            this.loadingAdicionar = false;
            for (const key in this.formRegras.controls) {
                if (key) {

                    const campo = this.formRegras.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }

    validaFormRegra() {
        let error = false;

        if (this.formRegras.get('vigenciaInicio').value && this.formRegras.get('vigenciaFim').value) {

            const dtInicio = new Date(this.formRegras.get('vigenciaInicio').value);
            const dtFim = new Date(this.formRegras.get('vigenciaFim').value);

            if (dtFim < dtInicio) {
                this.toastService.error('A data de vigência fim não pode ser menor que a de início.');
                error = true;
            }
        }

        // @ts-ignore
        this.formRegras.get('serie').value?.forEach(s => {
            if (Number(s) < 1 ||
                Number(s) > 999) {
                this.toastService.error('Série deve ser um número de 1 a 999. ' + s);
                error = true;
            }
        });

        return error;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;
        this.currentParams.filter = Object.entries(this.formFiltrar.value).map((key, value) => {
            return {key, value};
        });

        this.queryTable(this.currentParams, this.currentSearch);

        this.showModalFiltrar(false);

    }

    resetForm(obgIds = []) {
        this.formRegras = this.fb.group({
            obrigacoes: [obgIds],
            modelo: [null],
            tipo: [null],
            vigenciaInicio: [null],
            vigenciaFim: [null],
            cfops: [[]],
            ncms: [[]],
            serie: [[]],
            emitente: [[]],
            destinatario: [[]],
            tipoDoc: ['cnpj'],
            tipoEmissao: [null, Validators.required],
            inputD: [null],
            inputE: [null],
        });
    }

    addEmitente() {

        if (!this.emitente.nativeElement.value) {
            return;
        }

        if (this.formRegras.get('tipoDoc').value === 'cnpj' && !Helpers.isValidCnpj(this.emitente.nativeElement.value)) {
            this.toastService.error('CNPJ inválido!');
            return;
        } else if (this.formRegras.get('tipoDoc').value === 'cpf' && !Helpers.isValidCPF(this.emitente.nativeElement.value)) {
            this.toastService.error('CPF inválido!');
            return;
        }

        // @ts-ignore
        if (this.formRegras.get('emitente')?.value?.includes(this.emitente.nativeElement.value)) {
            this.toastService.warning('Emitente já adicionado a lista.');
            return;
        }

        const emitentes = this.formRegras.get('emitente').value || [];

        emitentes.push(this.emitente.nativeElement.value);
        // @ts-ignore
        this.formRegras.get('emitente').setValue(emitentes);

        this.emitente.nativeElement.value = null;
        // @ts-ignore
        this.formRegras.get('inputE').setValue(null);
    }

    addDestinatario() {

        if (!this.destinatario.nativeElement.value) {
            return;
        }

        if (this.formRegras.get('tipoDoc').value === 'cnpj' && !Helpers.isValidCnpj(this.destinatario.nativeElement.value)) {
            this.toastService.error('CNPJ inválido!');
            return;

        } else if (this.formRegras.get('tipoDoc').value === 'cpf' && !Helpers.isValidCPF(this.destinatario.nativeElement.value)) {
            this.toastService.error('CPF inválido!');
            return;
        }

        // @ts-ignore
        if (this.formRegras.get('destinatario')?.value?.includes(this.destinatario.nativeElement.value)) {
            this.toastService.warning('Destinatário já adicionado a lista.');
            return;
        }

        const destinatarios = this.formRegras.get('destinatario').value || [];

        destinatarios.push(this.destinatario.nativeElement.value);
        // @ts-ignore
        this.formRegras.get('destinatario').setValue(destinatarios);

        this.destinatario.nativeElement.value = null;
        // @ts-ignore
        this.formRegras.get('inputD').setValue(null);
    }

    changeMask(mascara: string) {
        this.maskDoc = mascara;
        // @ts-ignore
        this.formRegras.get('inputD').setValue(null);
        // @ts-ignore
        this.formRegras.get('inputE').setValue(null);
    }

    removerDaLista(lista: string, item: string) {

        let listaForm = this.formRegras.get(lista).value;

        listaForm = listaForm.filter(cnpj => cnpj !== item);

        this.formRegras.get(lista).setValue(listaForm);
    }
}

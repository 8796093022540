import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { FlowObrigacoesService } from "./flowObrigacoes.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  buildUrl,
  findComponentByUrl,
} from "../../../shared/components-helper";
import { Tab } from "@models/tab.model";
import { TabService } from "@services/tab.service";
import { AbstractListTable } from "@components/abstract/AbstractListTable";
import { ToastrService } from "ngx-toastr";

interface FormStack {
  modalVisible: boolean;
  formGroup: UntypedFormGroup;
}

@Component({
  selector: "app-check-obrigacoesFlow",
  templateUrl: "./flowObrigacoes.component.html",
  styleUrls: ["./flowObrigacoes.component.scss"],
})
export class FlowObrigacoesComponent
  extends AbstractListTable<any>
  implements OnInit
{
  swiperCompetencias: any = [];
  mesesFlow = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  obrigacoesFlow = [];
  swiperCardAtivo: number;
  tabNumber = 0;
  cardsCabecalhoLoading = true;
  cardTabsLoading = true;
  swiperCollapseCard = false;
  cardCompress = false;
  cabecalhoFlow: any = {};
  formFiltrosFlow: FormStack;
  comboObrigacoes = [];
  comboCategorias = [];
  anoAtivoFlow: number = null;
  mesAtivoFlow: number = null;
  qtdFiltrosAtivos = 0;

  loadFake = false;

  exibirColunaAprovacoes = false;

  constructor(
    private fb: UntypedFormBuilder,
    private flowObrigacoesService: FlowObrigacoesService,
    private route: ActivatedRoute,
    private msgSrv: NzMessageService,
    private http: HttpClient,
    private modalSrv: NzModalService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private tabService: TabService,
    private toastService: ToastrService
  ) {
    super(flowObrigacoesService, null, toastService);

    this.formFiltrosFlow = {
      modalVisible: false,
      formGroup: this.fb.group({
        obrigacao: [null, null],
        categoria: [null, null],
      }),
    };
  }

  ngOnInit() {
    this.carregaCompetencias();

    this.retornaComboObrigacoes();

    this.retornaComboCategorias();
  }

  carregaCompetencias(): void {
    this.flowObrigacoesService
      .retornaCompetencias()
      .subscribe((response: any) => {
        response.forEach((value) => {
          value.mes = value.anoMesVencimento.split("-")[1];
          value.ano = value.anoMesVencimento.split("-")[0];
        });

        this.swiperCompetencias = response;

        this.anoAtivoFlow =
          this.swiperCompetencias[this.swiperCompetencias.length - 1].ano;

        this.mesAtivoFlow =
          this.swiperCompetencias[this.swiperCompetencias.length - 1].mes;

        this.atualizaCabecalho(
          this.swiperCompetencias[this.swiperCompetencias.length - 1]
        );

        setTimeout(() => {
          this.swiperCardAtivo = this.swiperCompetencias.length - 1;
          this.swiperCollapseCard = true;
        }, 900);
      });
  }

  clickEvent() {
    this.cardCompress = !this.cardCompress;
  }

  toggleCollapseCard() {
    this.swiperCollapseCard = !this.swiperCollapseCard;
  }

  formatOne = (percent: number) => `${percent}%`;

  modalFiltrar(visible) {
    this.formFiltrosFlow.modalVisible = visible;
  }

  getPercentage(campo1: number, campo2: number): number {
    const maior: any = campo1 > campo2 ? campo1 : campo2;
    const menor: any = campo1 < campo2 ? campo1 : campo2;
    return 100 - (menor / maior) * 100;
  }

  selecionaCardCompetencia(event: any) {
    if (!(event.clickedIndex === undefined)) {
      this.swiperCardAtivo = event.clickedIndex;

      this.anoAtivoFlow = this.swiperCompetencias[event.clickedIndex].ano;

      this.mesAtivoFlow = this.swiperCompetencias[event.clickedIndex].mes;

      this.atualizaCabecalho(this.swiperCompetencias[event.clickedIndex]);
    }
  }

  atualizaCabecalho(competenciaSelecionada: any): void {
    this.cabecalhoFlow = competenciaSelecionada;

    this.cabecalhoFlow.porcentagemAgendas = 0;
    this.cabecalhoFlow.porcentagemArmazenadas = 0;
    this.cabecalhoFlow.porcentagemValidasConciliadas = 0;
    this.cabecalhoFlow.porcentagemAprovacoes = 0;
    this.cabecalhoFlow.porcentagemTransmissoes = 0;

    const agendas =
      Number(this.cabecalhoFlow.agendas) +
      Number(this.cabecalhoFlow.agendasAtrasadas);
    const agendasAtrasadas = Number(this.cabecalhoFlow.agendasAtrasadas);

    if (agendas > 0) {
      this.cabecalhoFlow.porcentagemAgendas = Math.round(
        this.getPercentage(agendas, agendasAtrasadas)
      );
    }

    const armazenamentos =
      Number(this.cabecalhoFlow.armazenamentos) +
      Number(this.cabecalhoFlow.armazenamentosAtrasados);
    const armazenamentosAtrasados = this.cabecalhoFlow.armazenamentosAtrasados;

    if (armazenamentos > 0) {
      this.cabecalhoFlow.porcentagemArmazenadas = Math.round(
        this.getPercentage(armazenamentos, armazenamentosAtrasados)
      );
    }

    const validasConciliadas =
      Number(this.cabecalhoFlow.validacoes) +
      Number(this.cabecalhoFlow.validacoesAtrasadas) +
      Number(this.cabecalhoFlow.conciliacoes) +
      Number(this.cabecalhoFlow.conciliacoesAtrasadas);

    const validasConciliadasAtrasadas =
      (this.cabecalhoFlow.validacoesAtrasadas
        ? this.cabecalhoFlow.validacoesAtrasadas
        : 0) +
      (this.cabecalhoFlow.conciliacoesAtrasadas
        ? this.cabecalhoFlow.conciliacoesAtrasadas
        : 0);

    // if (validasConciliadas > 0) { Validação retirada!
    this.cabecalhoFlow.porcentagemValidasConciliadas = Math.round(
      this.getPercentage(validasConciliadas, validasConciliadasAtrasadas)
    );

    const aprovacoes =
      Number(this.cabecalhoFlow.aprovacoes_aprovadas) +
      Number(this.cabecalhoFlow.aprovacoes_aguardando);
    const aprovacoesAtrasadas = this.cabecalhoFlow.aprovacoes_aguardando;

    if (aprovacoes > 0) {
      this.cabecalhoFlow.porcentagemAprovacoes = Math.round(
        this.getPercentage(aprovacoes, aprovacoesAtrasadas)
      );
    }

    const transmissoes =
      Number(this.cabecalhoFlow.transmissoes) +
      Number(this.cabecalhoFlow.transmissoes_atrasadas);
    const transmissoesAtrasadas = this.cabecalhoFlow.transmissoes_atrasadas;

    if (transmissoes > 0) {
      this.cabecalhoFlow.porcentagemTransmissoes = Math.round(
        this.getPercentage(transmissoes, transmissoesAtrasadas)
      );
    }

    this.retornaObrigacoes(
      this.mesAtivoFlow,
      this.anoAtivoFlow,
      this.formFiltrosFlow.formGroup.value
    );
  }

  retornaObrigacoes(mesAtivo, anoAtivo, filtros, clear = false) {
    if (!clear) {
      this.cardsCabecalhoLoading = true;
    }

    this.cardTabsLoading = true;

    this.calculaBadgeFiltros(this.formFiltrosFlow);

    this.flowObrigacoesService
      .retornaobrigacoes(mesAtivo, anoAtivo, filtros)
      .subscribe((retorno: any) => {
        this.cardsCabecalhoLoading = false;

        retorno.forEach((value, index) => {
          value.armazenadas = Number(value.armazenadas);
          value.naoArmazenadas = Number(value.naoArmazenadas);

          value.porcentagemArmazenadas = 0;

          const totalArmazenadas = value.armazenadas + value.naoArmazenadas;

          if (totalArmazenadas > 0) {
            value.porcentagemArmazenadas = Math.round(
              100 - this.getPercentage(value.armazenadas, totalArmazenadas)
            );
          }

          value.aprovacoesAprovadas = Number(value.aprovacoesAprovadas);
          value.aprovacoesAguardando = Number(value.aprovacoesAguardando);

          value.porcentagemAprovacoes = 0;

          const totalAprovacoes =
            value.aprovacoesAprovadas + value.aprovacoesAguardando;

          if (totalAprovacoes > 0) {
            value.porcentagemAprovacoes = Math.round(
              this.getPercentage(value.aprovacoesAprovadas, totalAprovacoes)
            );
          }

          value.transmissoesEmExecucao = Number(value.transmissoesEmExecucao);
          value.transmissoesErro = Number(value.transmissoesErro);
          value.transmissoesNaoIniciado = Number(value.transmissoesNaoIniciado);
          value.transmissoesTransmitidas = Number(
            value.transmissoesTransmitidas
          );

          const totalTransmissoes =
            value.transmissoesEmExecucao +
            value.transmissoesErro +
            value.transmissoesNaoIniciado +
            value.transmissoesTransmitidas;

          value.transmissoesEmExecucaoWidth = 0;

          if (value.transmissoesEmExecucao > 0) {
            value.transmissoesEmExecucaoWidth =
              100 -
              Math.round(
                this.getPercentage(
                  value.transmissoesEmExecucao,
                  totalTransmissoes
                )
              );
          }

          value.transmissoesErroWidth = 0;

          if (value.transmissoesErro > 0) {
            value.transmissoesErroWidth =
              100 -
              Math.round(
                this.getPercentage(value.transmissoesErro, totalTransmissoes)
              );
          }

          value.transmissoesTransmitidasWidth = 0;

          if (value.transmissoesTransmitidas > 0) {
            value.transmissoesTransmitidasWidth =
              100 -
              Math.round(
                this.getPercentage(
                  value.transmissoesTransmitidas,
                  totalTransmissoes
                )
              );
          }

          retorno[index] = value;
        });

        retorno.sort((a, b) => {
          const nameA = a.descricao?.toUpperCase();
          const nameB = b.descricao?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        this.obrigacoesFlow = retorno;

        this.obrigacoesFlow.forEach((d) => {
          if (d.temAprovacao && (d.temValidacao || d.temConciliacao)) {
            this.exibirColunaAprovacoes = true;
          }
        });

        this.cardTabsLoading = false;

        this.cdr.detectChanges();
      });
  }

  slideSwiper(direction: string, swipNumber: number) {
    switch (direction) {
      case "left":
        this.swiperCardAtivo = this.swiperCardAtivo - swipNumber;

        if (this.swiperCardAtivo < 0) {
          this.swiperCardAtivo = 0;
        }

        break;

      case "right":
        this.swiperCardAtivo = this.swiperCardAtivo + swipNumber;

        if (this.swiperCardAtivo > this.swiperCompetencias.length - 1) {
          this.swiperCardAtivo = this.swiperCompetencias.length - 1;
        }

        break;
    }
  }

  retornaComboObrigacoes() {
    this.flowObrigacoesService
      .retornaComboObrigacoes()
      .subscribe((response) => {
        response.obrigacoes.forEach((value) => {
          this.comboObrigacoes.push({
            id: value.id,
            descricao: value.descricao,
          });
        });
      });
  }

  retornaComboCategorias() {
    this.flowObrigacoesService
      .retornaComboCategorias()
      .subscribe((response) => {
        response.forEach((value) => {
          this.comboCategorias.push({
            id: value.categoria,
            descricao: value.descricao,
          });
        });
      });
  }

  confirmaFiltrar() {
    this.retornaObrigacoes(
      this.mesAtivoFlow,
      this.anoAtivoFlow,
      this.formFiltrosFlow.formGroup.value
    );

    this.modalFiltrar(false);
  }

  openTab(path: string, queryParams?: string, data?: {}) {
    const component = findComponentByUrl(path);
    const url = buildUrl(component, queryParams);
    const newTab = new Tab(
      component.name,
      component.title,
      url,
      component.urlType,
      data
    );
    this.tabService.closeAndAddTab(newTab);

    // Demora para abrir tela
    setTimeout(() => {
      this.loadFake = true;
      this.cdr.detectChanges();
    }, 10);

    setTimeout(() => {
      this.loadFake = false;
    }, 1000);
  }

  filtrarCalendar(filtroIn: string, valorIn: any, obrigacaoId?: string): void {
    const arrayFiltros: any[] = [];

    arrayFiltros.push({
      filtro: filtroIn,
      valor: valorIn,
    });

    if (obrigacaoId) {
      arrayFiltros.push({
        filtro: "categoria",
        valor: obrigacaoId,
      });
    }

    const mes = Number(this.mesAtivoFlow);

    arrayFiltros.push({
      filtro: "mes",
      valor: mes,
    });

    arrayFiltros.push({
      filtro: "ano",
      valor: this.anoAtivoFlow,
    });

    this.openTab("/calendar/calendarTasks/", null, arrayFiltros);
  }

  filtrarArmazenamento(filtros: any): void {
    const objectFiltros = {
      vencimento: this.anoAtivoFlow + "-" + this.mesAtivoFlow,
    };

    Object.entries(filtros).forEach((value: any) => {
      objectFiltros[value[0]] = value[1];
    });

    this.openTab("/check/armazenamentosDetalhes/", null, objectFiltros);
  }

  filtrarValidacoes(filtros: any, e?: any): void {
    const objectFiltros = {
      vencimento: this.anoAtivoFlow + "-" + this.mesAtivoFlow,
    };

    Object.entries(filtros).forEach((value: any) => {
      objectFiltros[value[0]] = value[1];
    });

    this.openTab("/check/conciliacaoDetalhes", null, objectFiltros);
  }

  filtrarAprovacoes(filtros: any): void {
    const objectFiltros = {
      vencimento: this.anoAtivoFlow + "-" + this.mesAtivoFlow,
    };

    Object.entries(filtros).forEach((value: any) => {
      objectFiltros[value[0]] = value[1];
    });

    this.openTab("/flow/flowAprovacoes", null, objectFiltros);
  }

  filtrarTransmissoes(filtros: any): void {
    const objectFiltros = {
      vencimento: this.anoAtivoFlow + "-" + this.mesAtivoFlow,
    };

    Object.entries(filtros).forEach((value: any) => {
      objectFiltros[value[0]] = value[1];
    });

    this.openTab("/delivery/transmissoesDetalhes", null, objectFiltros);
  }

  clearInput(form, emitter, target) {
    const inputEmitter = form.get(emitter);
    const inputTarget = form.get(target);
    if (inputEmitter.value) {
      inputTarget.setValue(null);
    }
  }

  resetSearch() {
    this.formFiltrosFlow.formGroup.reset();
    this.retornaObrigacoes(
      this.mesAtivoFlow,
      this.anoAtivoFlow,
      this.formFiltrosFlow.formGroup.value,
      true
    );
  }

  calculaBadgeFiltros(form): void {
    this.qtdFiltrosAtivos = 0;

    if (typeof form !== "undefined") {
      for (const [chave, valor] of Object.entries(form.formGroup.value)) {
        if (valor) {
          this.qtdFiltrosAtivos++;
        }
      }
    }
  }
}

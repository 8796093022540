<nz-page-header nzTitle="Terceiros" nzSubtitle="Simples Nacional">
    <nz-page-header-extra></nz-page-header-extra>
</nz-page-header>

<nz-content style="width: 100%">
    <div style="width: 100%" class="cards-info">
        <div class="info-card-g">
            <nz-card (click)="filtrarSNByCard('atualizados')"
                     [ngClass]="formFiltrosSN.formGroup.get('status').value == 'atualizados'? ['card-cabecalho', 'card-cabecalho-selected']
                     : ['card-cabecalho']"
                     [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center">
                        <i class="far fa-check-circle"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Atualizados
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important;">
                                {{contadoresCabecalho.atualizados | number}}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="contadoresCabecalho.porcAtualizados"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#009688'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
        <div class="info-card-g">
            <nz-card (click)="filtrarSNByCard('desatualizados')"
                     class="{{formFiltrosSN.formGroup.get('status').value == 'desatualizados'? 'card-cabecalho card-cabecalho-selected' : 'card-cabecalho'}}"
                     [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center">
                        <i class="far fa-times-circle"
                           style="font-size: 60px; margin-right: 15px; color: #ff4961 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Desatualizados
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #ff4961 !important;">
                                {{contadoresCabecalho.desatualizados | number}}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="contadoresCabecalho.porcDesatualizados"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#ff4961'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
        <div class="info-card-g">
            <nz-card (click)="filtrarSNByCard('solicitados')"
                     class="{{formFiltrosSN.formGroup.get('status').value == 'solicitados'? 'card-cabecalho card-cabecalho-selected' : 'card-cabecalho'}}"
                     [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center">
                        <i nz-icon nzType="exclamation-circle" nzTheme="outline"
                           style="font-size: 60px; margin-right: 15px; color: #ff9149 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Solicitados
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #ff9149 !important;">
                                {{contadoresCabecalho.solicitados | number}}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="contadoresCabecalho.porcSolicitados"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#ff9149'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>

    <nz-card [nzBodyStyle]="{width: '100%', 'padding': cardLoading || loadingAtualizandoSimples ? '15px' : '0px'}"
             [ngClass]="[statusOne ? 'card-fullscreen fonte-doze' : 'card-container fonte-doze']"
             [nzLoading]="cardLoading || loadingAtualizandoSimples"
             nzTitle="Simples Nacional" [nzExtra]="cndExtra">
        <ng-template #cndExtra>
            <div class="d-flex align-items-center">
                <nz-badge [nzCount]="qtdFiltrosSN"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrarSP(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca"
                           (keydown.enter)="tableSimplesNacional(currentParams, currentSearch)"
                           [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="tableSimplesNacional(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon" nz-button
                        nz-dropdown
                        nzType="default">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="modalImportar(true);">Importar Planilha</li>
                        <li nz-menu-item (click)="exportPlanilha()">Exportar Planilha</li>
                        <li *ngIf="!loadingAtualizandoSimples" nz-menu-item (click)="atualizarSimples()">Atualizar
                            Consulta Simples
                        </li>
                        <li *ngIf="loadingAtualizandoSimples" nz-menu-item nzDisabled="true">Atualizando Simples.
                            Aguarde
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <button nz-button [nzType]="'default'" (click)="limparSN()"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="tableSimplesNacional(currentParams, currentSearch)"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    <i class="fas fa-redo-alt"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  style="width: 100%; overflow-y: auto"
                  [nzData]="itensSimplesNacional"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.sn?.total"
                  [nzPageSize]="paginations.sn?.per_page"
                  [nzPageIndex]="paginations.sn?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzFooter]="footer">
            <thead>
            <tr>
                <th nzAlign="center">
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                </th>
                <th nzColumnKey="razaoSocial" style="min-width: 250px" [nzSortFn]="true" nzAlign="left">Empresa</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true" nzAlign="center">CNPJ</th>
                <th nzColumnKey="opcaoMei" [nzSortFn]="true" nzAlign="center">Opção MEI</th>
                <th nzColumnKey="dataMei" [nzSortFn]="true" nzAlign="center">Início MEI</th>
                <th nzColumnKey="exclusaoMei" [nzSortFn]="true" nzAlign="center">Exclusão MEI</th>
                <th nzColumnKey="opcaoSimples" [nzSortFn]="true" nzAlign="center">Opção Simples</th>
                <th nzColumnKey="dataSimples" [nzSortFn]="true" nzAlign="center">Início Simples</th>
                <th nzColumnKey="exclusaoSimples" [nzSortFn]="true" nzAlign="center">Exclusão Simples</th>
                <th nzColumnKey="dataAtualizacao" [nzSortFn]="true" nzAlign="center">Data Cadastro</th>
                <th nzColumnKey="dataExtracao" [nzSortFn]="true" nzAlign="center">Atualização RFB</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(item.cnpj, $event)"
                    [nzChecked]="checkedItems.has(item.cnpj)">
                </td>
                <td nzLeft>{{modoDemo(item?.razaoSocial, 'empresaCodigoNome')}}</td>
                <td nzAlign="center" style="white-space: nowrap;">{{modoDemo(item?.cnpj | cpfCnpj, 'cnpj')}}</td>
                <td nzAlign="center">{{item?.opcaoMei == 'N' ? "Não" : "Sim"}}</td>
                <td nzAlign="center">{{item?.dataMei && item.dataMei != '0000-00-00' ? (item.dataMei | date: 'dd/MM/YYYY') : '-'}}</td>
                <td nzAlign="center">{{item?.exclusaoMei && item.exclusaoMei != '0000-00-00' ? (item.exclusaoMei | date: 'dd/MM/YYYY') : '-'}}</td>
                <td nzAlign="center">{{item?.opcaoSimples == 'N' ? "Não" : "Sim"}}</td>
                <td nzAlign="center">{{item?.dataSimples && item.dataSimples != '0000-00-00' ? (item.dataSimples | date: 'dd/MM/YYYY') : '-'}}</td>
                <td nzAlign="center">{{item?.exclusaoSimples && item.exclusaoSimples != '0000-00-00' ? (item.exclusaoSimples | date: 'dd/MM/YYYY') : '-'}}</td>
                <td nzAlign="center">{{item?.dataAtualizacao && item.dataAtualizacao != '0000-00-00' ? (item.dataAtualizacao | date: 'dd/MM/YYYY HH:mm:ss') : '-'}}</td>
                <td nzAlign="center">{{item?.dataExtracao && item.dataExtracao != '0000-00-00' ? (item.dataExtracao | date: 'dd/MM/YYYY HH:mm:ss') : '-'}}</td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="itensSimplesNacional && itensSimplesNacional.length > 0">{{paginations.sn?.from}}
                    -{{paginations.sn?.to}}
                    de {{paginations.sn?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrosSN.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarSP(false)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrosSN.formGroup"
              style="width: 100%;">
            <!--            <nz-form-item>-->
            <!--                <nz-form-label [nzSpan]="6">CNPJ</nz-form-label>-->
            <!--                <nz-form-control [nzSpan]="13"-->
            <!--                                 nzValidateStatus="success"-->
            <!--                                 [nzHasFeedback]="formFiltrosSN.formGroup.value.cnpj">-->
            <!--                    <nz-select nzShowSearch nzAllowClear-->
            <!--                               [nzPlaceHolder]="'Selecione'" formControlName="cnpj">-->
            <!--                        <nz-option *ngFor="let opt of comboEmpresa"-->
            <!--                                   [nzLabel]="modoDemo(opt.razaoSocial, 'empresaCodigoNome') + ' (' + (modoDemo(opt.cnpj | cpfCnpj, 'cnpj')) + ')'"-->
            <!--                                   [nzValue]="opt.cnpj">-->
            <!--                        </nz-option>-->

            <!--                    </nz-select>-->
            <!--                </nz-form-control>-->
            <!--            </nz-form-item>-->

            <nz-form-item>
                <nz-form-label [nzSpan]="6">CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosSN.formGroup.value.cnpj !== '' && formFiltrosSN.formGroup.value.cnpj !== null">
                    <input nz-input type="text" placeholder="00.000.000/0000-00" [mask]="'00.000.000/0000-00'"
                           formControlName="cnpj" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>

            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Data Cadastro</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataAtualizacaoInicio">
                        <nz-date-picker style="width: 100%;"
                                        [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                        formControlName="dataAtualizacaoInicio"
                                        nzFormat="dd/MM/yyyy HH:mm"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataAtualizacaoFim">
                        <nz-date-picker style="width: 100%;"
                                        [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                        formControlName="dataAtualizacaoFim"
                                        nzFormat="dd/MM/yyyy HH:mm"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Atualização RFB</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataExtracaoInicio">
                        <nz-date-picker style="width: 100%;"
                                        [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                        formControlName="dataExtracaoInicio"
                                        nzFormat="dd/MM/yyyy HH:mm"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataExtracaoFim">
                        <nz-date-picker style="width: 100%;"
                                        [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                        formControlName="dataExtracaoFim"
                                        nzFormat="dd/MM/yyyy HH:mm"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Opção MEI</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosSN.formGroup.value.opcaoMei">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="opcaoMei">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'S'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'N'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Início MEI</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataMeiInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataMeiInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataMeiFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataMeiFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Exclusão MEI</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.exclusaoMeiInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="exclusaoMeiInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.exclusaoMeiFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="exclusaoMeiFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Opção Simples</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosSN.formGroup.value.opcaoSimples">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="opcaoSimples">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'S'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'N'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Início Simples</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataSimplesInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataSimplesInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.dataSimplesFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataSimplesFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Exclusão Simples</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.exclusaoSimplesInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="exclusaoSimplesInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosSN.formGroup.value.exclusaoSimplesFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="exclusaoSimplesFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status</nz-form-label>
                <nz-form-control [nzSpan]="13"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosSN.formGroup.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Atualizados'" [nzValue]="'atualizados'"></nz-option>
                        <nz-option [nzLabel]="'Desatualizados'" [nzValue]="'desatualizados'"></nz-option>
                        <nz-option [nzLabel]="'Solicitados'" [nzValue]="'solicitados'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarSP(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarSN()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">


    <div *nzModalContent>
        <nz-result
                *ngIf="erroLicencas"
                nzStatus="warning"
                nzTitle=""
                nzSubTitle="Esta Conta tem limite de licenças para cadastro de Usuários e todas as licenças permitidas
                já foram utilizadas. Por favor entrar em contato com o Atendimento para a aquisição de novas licenças."
        ></nz-result>
        <div *ngIf="!erroLicencas">
            <p style="margin: 0px;">Selecione o arquivo (.xlsx) para importar.</p>
            <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar
                um arquivo de modelo.</small><br>
            <div style="margin-top: 15px">
                Subistituir os registros antigos
                <nz-switch (click)="substituirRegistros()" style="margin-left: 10px" [ngModel]="false"
                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"></nz-switch>
            </div>

            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzAccept]="'.xlsx'"
                        nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="uploading" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>
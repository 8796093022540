<nz-page-header class="site-page-header" nzTitle="Empresa" nzSubtitle="Detalhes da Empresa"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce">
                    <nz-card-meta [nzTitle]="dadosEmpresa?.razaoSocial"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="CNPJ Base">
                                <nz-list-item-meta-title>
                                    {{dadosEmpresa?.cnpj}}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Data de Atualização">
                                <nz-list-item-meta-title>
                                    {{dadosEmpresa?.dataAtualizacao ? (dadosEmpresa?.dataAtualizacao | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#st1" nzTitle="Estabelecimentos"></nz-link>
                    <nz-link nzHref="#st2" nzTitle="Socios"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <ng-template #tituloComBotao>
            Estabelecimentos
            <button nz-button nzType="default" style="float: right"
                (click)="modalExportar(true);">
                Exportar
            </button>
        </ng-template>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <div #cardWidth></div>
            <nz-card id="st1" class="ant-card-table" [nzTitle]="tituloComBotao"
                     [ngStyle]="{'width': cardWidthValue + 'px'}"
                     [nzExtra]="st1Extra">
                <ng-template #st1Extra>
                </ng-template>
                <nz-table #basicTable1
                          nzSize="small"
                          [nzData]="dadosEstabelecimentos"
                          [nzFrontPagination]="false"
                          (nzQueryParams)="queryTableEstabelecimentos($event)"
                          [nzLoading]="loadings.estabelecimentos"
                          [nzTotal]="pagination?.estabelecimentos?.total"
                          [nzPageSize]="pagination?.estabelecimentos?.per_page"
                          [nzPageIndex]="pagination?.estabelecimentos?.current_page"
                          [nzFooter]="footer"
                          class="fonte-doze table-small scrollbar">
                    <thead>
                    <tr>
                        <th nzColumnKey="cnpjOrdem" [nzSortFn]="true" nzLeft>CNPJ Ordem</th>
                        <th nzColumnKey="cnpjDV" [nzSortFn]="true" nzAlign="center">CNPJ DV</th>
                        <th nzColumnKey="identificador" [nzSortFn]="true" nzAlign="center">Identificador Matriz/Filial
                        </th>
                        <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="center">Nome Fantazia</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação Cadastral</th>
                        <th nzColumnKey="situacaoCadastralData" [nzSortFn]="true" nzAlign="center">Data da Situação
                            Cadastral
                        </th>
                        <th nzColumnKey="situacaoCadastralMotivo" [nzSortFn]="true" nzAlign="center">Motivação da
                            Situação Cadastral
                        </th>
                        <th nzColumnKey="inicioAtividadeData" [nzSortFn]="true" nzAlign="center">Data de Início da
                            Atividade
                        </th>
                        <th nzColumnKey="cnaePrincipal" [nzSortFn]="true" nzAlign="center">CNAE Fiscal Principal</th>
                        <th nzColumnKey="cnaeSecundaria" nzWidth="250px" [nzSortFn]="true" nzAlign="center">CNAE Fiscal
                            Secundária
                        </th>
                        <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="cidade" [nzSortFn]="true" nzAlign="center">Cidade</th>
                        <th nzColumnKey="endereco" nzWidth="200px" [nzSortFn]="true" nzAlign="center">Endereço</th>
                        <th nzColumnKey="cep" [nzSortFn]="true" nzAlign="center">Cep</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable1.data">
                        <td nzAlign="center">{{item.cnpjOrdem}}</td>
                        <td nzAlign="center">{{item.cnpjDV}}</td>
                        <td nzAlign="center">{{item.identificador}}</td>
                        <td nzAlign="center" style="min-width: 150px;">{{item.nome}}</td>
                        <td nzAlign="center">{{item.situacaoCadastral}}</td>
                        <td nzAlign="center" style="min-width: 100px;">{{item.situacaoCadastralData}}</td>
                        <td nzAlign="center" style="min-width: 100px;">{{item.situacaoCadastralMotivo}}</td>
                        <td nzAlign="center">{{item.inicioAtividadeData}}</td>
                        <td nzAlign="center" style="min-width: 100px;">{{item.cnaePrincipal}}</td>
                        <td nzAlign="center">{{item.cnaeSecundaria}}</td>
                        <td nzAlign="center">{{item.uf}}</td>
                        <td nzAlign="center">{{item.cidade}}</td>
                        <td nzAlign="center">{{item.logradouroTipo}} {{item.logradouro}}, {{item.numero}}
                            - {{item.bairro}}</td>
                        <td nzAlign="center" style="white-space: nowrap;">{{item.cep | cep}}</td>
                    </tr>
                    </tbody>
                    <ng-template #footer>
                        <span *ngIf="dadosEstabelecimentos && dadosEstabelecimentos.length > 0">{{pagination?.estabelecimentos?.from}}
                            -{{pagination?.estabelecimentos?.to}}
                            de {{pagination?.total}} registros
                        </span>
                    </ng-template>
                </nz-table>
            </nz-card>
            <nz-card id="st2" class="ant-card-table" nzTitle="Socios" [ngStyle]="{'width': cardWidthValue + 'px'}">
                <nz-table #basicTable2
                          nzSize="small"
                          [nzData]="dadosSocios"
                          [nzLoading]="loadings.socios"
                          [nzFrontPagination]="false"
                          (nzQueryParams)="queryTableSocios($event)"
                          [nzTotal]="pagination?.socios?.total"
                          [nzPageSize]="pagination?.socios?.per_page"
                          [nzPageIndex]="pagination?.socios?.current_page"
                          class="fonte-doze table-small scrollbar">
                    <thead>
                    <tr>
                        <th nzColumnKey="identificador" [nzSortFn]="true" nzAlign="left">Identificador de Sócio</th>
                        <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="center" style="min-width: 180px;">Razão
                            Social
                        </th>
                        <th nzColumnKey="documento" [nzSortFn]="true" nzAlign="center">CNPJ/CPF do Sócio</th>
                        <th nzColumnKey="qualificacao" [nzSortFn]="true" nzAlign="center">Qualificação do Sócio</th>
                        <th nzColumnKey="entradaData" [nzSortFn]="true" nzAlign="center">Data de Entrada</th>
                        <th nzColumnKey="codigoPais" [nzSortFn]="true" nzAlign="center">País</th>
                        <th nzColumnKey="representanteDocumento" [nzSortFn]="true" nzAlign="center">Representante
                            Legal
                        </th>
                        <th nzColumnKey="representanteNome" [nzSortFn]="true" nzAlign="center">Nome do Representante
                        </th>
                        <th nzColumnKey="representanteQualificacao" [nzSortFn]="true" nzAlign="center">Qualificação do
                            Representante
                        </th>
                        <th nzColumnKey="faixaEtaria" [nzSortFn]="true" nzAlign="center">Faixa Etária</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable2.data">
                        <td nzAlign="center">{{item.identificador}}</td>
                        <td nzAlign="center">{{item.nome}}</td>
                        <td nzAlign="center">{{item.documento}}</td>
                        <td nzAlign="center">{{item.qualificacao}}</td>
                        <td nzAlign="center">{{item.entradaData}}</td>
                        <td nzAlign="center">{{item.codigoPais}}</td>
                        <td nzAlign="center">{{item.representanteDocumento}}</td>
                        <td nzAlign="center">{{item.representanteNome}}</td>
                        <td nzAlign="center">{{item.representanteQualificacao}}</td>
                        <td nzAlign="center">{{item.faixaEtaria}}</td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
        </div>
    </div>
</nz-content>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-page-header class="site-page-header" nzTitle="Integrações"
                nzSubtitle="Guias Lotes">
    <!--<nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                    (click)="changeTable('E')">
                Guias Estaduais
            </button>
            <button nz-button nzType="primary" nzGhost
                    style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;"
                    (click)="changeTable('M')">
                    (click)="changeTable('M')">
                Guias Municipais
            </button>
        </nz-button-group>
    </nz-page-header-extra>-->
</nz-page-header>
<nz-card
        [nzLoading]="gridLoading"
        [nzBodyStyle]="gridLoading ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
        [ngClass]="[status ? 'card-fullscreen' : '',
        !gridLoading ? 'ant-card-table' : '']"
        [nzExtra]="tabsExtra"
        nzTitle="Lotes">
    <nz-table #tableLotes
              nzSize="small"
              [nzData]="arrayTableLotes"
              [nzFrontPagination]="false"
              [nzShowPagination]="true"
              [nzTotal]="pagination?.total"
              [nzPageSize]="pagination?.per_page"
              [nzPageIndex]="pagination?.current_page"
              (nzQueryParams)="queryTable($event)"
              [nzFooter]="footer"
              class="fonte-doze scrollbar">
        <thead>
        <tr>
            <th nzColumnKey="unidade" [nzSortFn]="true">Unidade</th>
            <th nzColumnKey="cnpj" [nzSortFn]="true" nzAlign="center">CNPJ</th>
            <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Data<br>Início</th>
            <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Data<br>Fim</th>
            <th nzColumnKey="qtdRegistros" [nzSortFn]="true" nzAlign="center">Registros</th>
            <th nzColumnKey="layout" [nzSortFn]="true" nzAlign="center">Layout</th>
            <th nzColumnKey="valorTotal" [nzSortFn]="true" nzAlign="center">Total</th>
            <th nzColumnKey="armazenado" [nzSortFn]="true" nzAlign="center">Gerado<br>em</th>
            <th style="width: 80px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr class="ant-table-tbody" *ngFor="let data of tableLotes.data">
            <td class="text-nowrap">{{data.unidade}}</td>
            <td class="text-center text-nowrap">{{data.cnpj | cpfCnpj}}</td>
            <td class="text-center">{{data.dataInicio | date : 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{data.dataFim | date : 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{data.qtdRegistros}}</td>
            <td class="text-center">{{data.layout}}</td>
            <td class="text-center">{{data.valorTotal | number: '1.2-2' : 'pt-br'}}</td>
            <td class="text-center">{{data.armazenado | date : 'dd/MM/yyyy - HH:mm:ss'}} <br>{{data.nome ? 'por' : ''}}
                <strong>{{data.nome}}</strong></td>
            <td style="white-space: nowrap; text-align: right">
                <span class="text-muted" *ngIf="!data.arquivoId">Download</span>
                <button (click)="downloadArquivo(data.arquivoId);"
                        [nzLoading]="loadings.download.includes(data.arquivoId)"
                        *ngIf="data.arquivoId"
                        nz-button
                        nzType="link">
                    Download
                </button>
                <nz-divider nzType="vertical"></nz-divider>
                <button nz-button
                        (click)="showConfirm(data.id)"
                        nzType="link">
                    Remover
                </button>
            </td>
        </tr>
        </tbody>
        <ng-template #footer>
                <span *ngIf="arrayTableLotes && arrayTableLotes.length > 0">{{pagination?.from}}-{{pagination?.to}}
                    de {{pagination?.total}} registros</span>
        </ng-template>
    </nz-table>
</nz-card>
<ng-template #tabsExtra>
    <div class="nav-item d-flex">
        <button nz-button nzType="primary" style="margin-right: 10px;" (click)="abrirModal(formGerar);">
            Gerar
        </button>
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="abrirModal(formFiltrar);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item nz-button (click)="abrirModal(formExportar);">Exportar Planilha</li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca"
                   [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearch();">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()" style="padding-left: 5px; padding-right: 5px;">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrar)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="16"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id"
                               [(ngModel)]="this.formFiltrar.formGroup.value.empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresasFiltros"

                                   [nzLabel]="opt.empresa_nome + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                   nzCustomContent
                                   [nzValue]="opt.empresa_id"
                        >
                            {{opt.empresa_nome}}<br>
                            <small>({{opt.empresa_cnpj | cpfCnpj}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataInicioDe">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicioDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataInicioAte"
                >
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicioAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="4">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataFimDe">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFimDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.dataFimAte">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFimAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Layout</nz-form-label>
                <nz-form-control [nzSpan]="16"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.layout">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="layout">
                        <nz-option *ngFor="let opt of arraySelectLayouts"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Registros</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.qtdRegistrosDe">
                    <input nz-input
                           formControlName="qtdRegistrosDe" type="number" step="1" autocomplete="off"
                           placeholder="De:"/>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.qtdRegistrosAte">
                    <input nz-input
                           formControlName="qtdRegistrosAte" type="number" step="1" autocomplete="off"
                           placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="4">Valor</nz-form-label>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.valorTotalDe">
                    <input nz-input
                           formControlName="valorTotalDe" type="number" step="0.10" autocomplete="off"
                           placeholder="De:"/>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="7"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.valorTotalAte">
                    <input nz-input
                           formControlName="valorTotalAte" type="number" step="0.10" autocomplete="off"
                           placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrar)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal [(nzVisible)]="formGerar.modalVisible"
          [nzTitle]="'Gerar Lote'" [nzClosable]="true"
          (nzOnCancel)="modalGerar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formGerar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="cnpj"
                               [(ngModel)]="this.formGerar.formGroup.value.cnpj">
                        <nz-option *ngFor="let opt of arraySelectCnpj"
                                   [nzLabel]="opt.empresa_nome + ' ' + (opt.descricao | cpfCnpj)"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data</nz-form-label>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'MM/yyyy'"
                                    nzMode="month"
                                    formControlName="dataInicio" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'MM/yyyy'"
                                    nzMode="month"
                                    formControlName="dataFim" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Layout</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="layout">
                        <nz-option *ngFor="let opt of arraySelectLayouts"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalGerar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.gravar" (click)="gerarLote()">Gravar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="this.formLayout.modalVisible"
          [nzTitle]="'Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalArquivo(false)"
          [nzWidth]="768"
          [nzClassName]="'no-border'">
    <div nz-row *nzModalContent>
        <div nz-col nzSpan="24">
            <object>
                <embed *ngIf="arquivoUrl" [src]="arquivoUrl | safe : 'resourceUrl'" frameborder="0" width="100%"
                       height="500px">
            </object>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalArquivo(false);">Cancelar</button>
    </div>
</nz-modal>

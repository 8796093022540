import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class CalendarObrigacoesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/tributos', {});
    }


    retornaCompetencias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/competencias`);
    }

    retornaCabecalho(mes: number, ano: number): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/cabecalho/${ano}/${mesStr}`);
    }

    retornaObrigacoes(mes: number, ano: number): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/obrigacoes/${ano}/${mesStr}`);
    }

    retornaNaoAtribuidos(mes: number, ano: number): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/nao-atribuidos/${ano}/${mesStr}`);
    }

    retornaResponsaveis(mes: number, ano: number): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/responsaveis/${ano}/${mesStr}`);
    }

    retornaEquipes(mes: number, ano: number): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/equipes/${ano}/${mesStr}`);
    }

    retornaIniciais(responsavel: any): any {
        let iniciaisVar = '';
        const nomecompleto: any = responsavel.replace(/\s(de|da|dos|das)\s/g, ' ');
        const iniciais: any = nomecompleto.match(/\b(\w)/gi);

        for (const letra of iniciais) {
            if (letra === letra.toUpperCase()) {
                iniciaisVar += letra;
            }
        }

        return iniciaisVar.substr(0, 2);

    }

    retornaGrupos(mes: number, ano: number, busca = null): Observable<any> {

        const mesStr = Helpers.monthStr(mes);

        let params = '';

        if (busca) {
            params = '?procurar=' + busca;
        }

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/grupos/${ano}/${mesStr}${params}`);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ComplianceTributosService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/tributos', {});
    }


    retornaAnos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-anos`);
    }

    retornaObrigacoesPagamentos(ano: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-pagamentos-principais/` + ano);
    }

    retornaObrigacoesAcessorias(ano: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-obrigacoes-acessorias/` + ano);
    }

    retornaObrigacoesGrupo(ano: number, imposto: string, filtros: any): Observable<any> {
        filtros.tributo = imposto;
        const queryStr = Helpers.montaQueryString(filtros);
        return this.http.get(`${environment.apiUrl}/compliance/tributos/retorna-obrigacoes-grupo/` + ano + queryStr);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

}

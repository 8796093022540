import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StickyHeaderDirective} from '@components/directive/directive';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {EditarResponsaveisService} from './editar-responsaveis.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';
import {UserService} from '@services/user.service';
import {DataService} from '@services/data.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-editar-credenciais-acesso',
    templateUrl: './editar-responsaveis.component.html',
    styleUrls: ['./editar-responsaveis.component.scss'],
})
export class CadastrosEditarResponsaveisComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {
    @Input() data;
    dadosCadastrais: any = {};
    dadosDataPrev: any = {};

    listaCretificados: any[] = [];
    listaProcuracoes: any[] = [];
    listaProcuradores: any[] = [];
    listaAcessos: any[] = [];

    description = '';

    offsetTop = 15;
    target: any;

    obrigacao: any = {};

    formAcessosDataPrev: FormStack;

    formCertificadoA1: FormStack;
    formProcuracoesECAC: FormStack;
    formProcuradoresECAC: FormStack;
    formAcessosEstaduais: FormStack;
    formAdicionarCertificado: FormStack;
    formDataPrev: FormStack;
    formResponsavel: FormStack;

    // pagination: Pagination;
    pagination: any = {
        certificados: Pagination,
        procuracoes: Pagination,
        procuradores: Pagination,
        acessos: Pagination,
    };

    currentParams: any = {
        certificados: this.abstractService.NzTableQueryParams,
        procuracoes: this.abstractService.NzTableQueryParams,
        procuradores: this.abstractService.NzTableQueryParams,
        acessos: this.abstractService.NzTableQueryParams,
    };
    currentSearch: any = {
        certificados: '',
        procuracoes: '',
        procuradores: '',
        acessos: '',
    };

    loading: any = {
        certificados: false,
        procuracoes: false,
        procuradores: false,
        acessos: false,
        formAcessos: false,
        repicarMatries: false,
        buscarProcuracoes: false,
    };

    editando: any = {
        certificados: '',
        procuracoes: '',
        procuradores: '',
        acessos: '',
    };

    editarCertificados: any = {};

    erro = false;
    mensagem = '';

    apiUrl = environment.apiUrl;
    fileListCertificado: NzUploadFile[] = [];

    arquivoUpdate: NzUploadFile =
        {
            uid: '1',
            name: 'certificado.pfx',
            status: 'done',
            response: '', // custom error message to show
            url: ''
        };

    comboUFs: any = [];
    comboObrigacoes: any = [];
    camposFormDataPrev: any = [];

    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    identificacaoVisible = false;
    senhaVisible = false;

    loadings: {
        alterandoResponsavel: boolean;
        dadosCadastrais: boolean,
        gravar: boolean,
        importando: boolean,
        exportando: boolean,
        modalAlterar: boolean
    } = {
        dadosCadastrais: false,
        gravar: false,
        importando: false,
        exportando: false,
        modalAlterar: false,
        alterandoResponsavel: false
    };

    visibles: {
        modalAlterar: boolean
    } = {modalAlterar: false};

    formSenha: FormStack;

    user: any;

    qtdFiltrosAtivosProcuracoes = 0;

    formFiltrarProcuracoes: FormStack;
    formFiltrarProcuradores: FormStack;

    fullCardProcuracoes = false;

    currentUser: any;
    qtdFiltrosAtivosProcuradores = 0;


    constructor(
        private service: EditarResponsaveisService,
        private toastService: ToastrService,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private dataService: DataService
    ) {
        super(service, null, toastService);

        this.formCertificadoA1 = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],

            })
        };

        this.formProcuracoesECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };
        this.formDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                identificador: [null, null],
                senha: [null, null],
            })
        };

        this.formProcuradoresECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };

        this.formAcessosEstaduais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, null],
                obrigacao_id: [null, null],
            })
        };


        this.formAcessosDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                identificacao: [null, Validators.required],
                senha: [null, Validators.required],
            })
        };

        this.formAdicionarCertificado = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                emissor: [null, Validators.required],
                emissaoData: [null, Validators.required],
                validade: [null, Validators.required],
                tipo: ['cpf', Validators.required],
                serial: [null, Validators.required],
                uso: [null, Validators.required],
                arquivo: [null, Validators.required],
                padrao: [null, Validators.required],
                replicar: [null, null],
            })
        };

        this.formResponsavel = {
            modalVisible: false,
            formGroup: this.fb.group({
                nome: [null, Validators.required]
            })
        };

        this.service.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            // this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.formSenha = {
            modalVisible: false,
            formGroup: this.fb.group({
                senha: [null, Validators.required],
            })
        };

        this.formFiltrarProcuracoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                situacao: [null, null],
                cadastrada: [null, null]
            })
        };

        this.formFiltrarProcuradores = {
            modalVisible: false,
            formGroup: this.fb.group({
                situacao: [null, null],
                cadastrada: [null, null]
            })
        };


    }

    ngOnInit(): void {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.getLoggedUser();
        this.retornaDadosCadastrais(this.data.id);
        this.retornaDadosDataPrev(this.data.id);
    }

    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.user = user);
    }


    async retornaDadosCadastrais(responsavelId: string, loading: boolean = true) {
        this.loadings.dadosCadastrais = loading;

        this.dadosCadastrais = await this.service.retornaDadosCadastrais(responsavelId).toPromise();
        this.loadings.dadosCadastrais = false;
    }

    retornaDadosDataPrev(empresaId: string): void {
        this.service.retornaDadosDataPrev(empresaId).subscribe((retorno: any) => {
            this.dadosDataPrev = retorno;
        });
    }

    ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;
        this.cdr.detectChanges();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });
    }

    modal(form: FormStack, visible: boolean): void {
        form.modalVisible = visible;
        if (!visible) {
            this.zerarForms();
        }
    }

    alterarCertificadoA1(item: any): void {
        this.formAdicionarCertificado.formGroup.get('uso').setValue(item.uso);

        this.editando.certificados = item.id;


        this.formAdicionarCertificado.formGroup.get('serial').setValue(item.serial);
        this.formAdicionarCertificado.formGroup.get('emissaoData').setValue(item.emissaoData);
        this.formAdicionarCertificado.formGroup.get('validade').setValue(item.validade);
        this.formAdicionarCertificado.formGroup.get('tipo').setValue(item.tipo);
        this.formAdicionarCertificado.formGroup.get('padrao').setValue(item?.padrao.toString() === '1');


        this.editarCertificados.empresa_id = item.empresa_id;
        this.editarCertificados.emissor = item.emissor;
        this.editarCertificados.nomeArquivo = item.nomeArquivo;


        this.beforeUploadCertificado(this.arquivoUpdate);

        this.modal(this.formAdicionarCertificado, true);
    }

    alterarProcuracoesECAC(item: any): void {
        this.zerarForms();
        this.editando.procuracoes = item.id;

        this.formProcuracoesECAC.formGroup.get('pessoa').setValue(item.pessoa);
        this.formProcuracoesECAC.formGroup.get('documento').setValue(item.documento);
        this.formProcuracoesECAC.formGroup.get('nome').setValue(item.nome);


        if (item.dataInicio) {
            const dataInicio = new Date(item.dataInicio);
            dataInicio.setDate(new Date(item.dataInicio).getDate() + 1);
            this.formProcuracoesECAC.formGroup.get('dataInicio').setValue(dataInicio);
        }
        if (item.dataFim) {
            const dataFim = new Date(item.dataFim);
            dataFim.setDate(new Date(item.dataFim).getDate() + 1);
            this.formProcuracoesECAC.formGroup.get('dataFim').setValue(dataFim);
        }

        this.formProcuracoesECAC.formGroup.get('cadastrada').setValue(item.cadastrada ? '1' : '0');

        this.modal(this.formProcuracoesECAC, true);
    }

    alterarProcuradoresECAC(item: any): void {
        this.zerarForms();
        this.editando.procuradores = item.id;
        this.formProcuradoresECAC.formGroup.get('pessoa').setValue(item.pessoa);
        this.formProcuradoresECAC.formGroup.get('documento').setValue(item.documento);
        this.formProcuradoresECAC.formGroup.get('nome').setValue(item.nome);
        this.formProcuradoresECAC.formGroup.get('cadastrada').setValue(item.cadastrada ? '1' : '0');

        if (item.dataInicio) {
            const dataInicio = new Date(item.dataInicio);
            dataInicio.setDate(new Date(item.dataInicio).getDate() + 1);
            this.formProcuradoresECAC.formGroup.get('dataInicio').setValue(dataInicio);
        }
        if (item.dataFim) {
            const dataFim = new Date(item.dataFim);
            dataFim.setDate(new Date(item.dataFim).getDate() + 1);
            this.formProcuradoresECAC.formGroup.get('dataFim').setValue(dataFim);
        }

        this.modal(this.formProcuradoresECAC, true);
    }

    async alterarAcessosEstaduais(item: any) {
        this.loading.formAcessos = true;
        await this.filtrarObrigacoes(item.obrigacao_uf);
        await this.filtrarCampos(item.obrigacao_id);

        this.editando.acessos = item.id;
        this.formAcessosEstaduais.formGroup.get('uf').setValue(item.obrigacao_uf);
        this.formAcessosEstaduais.formGroup.get('obrigacao_id').setValue(item.obrigacao_id);

        setTimeout(() => {
            const dados = JSON.parse(item.acesso);
            for (const [key, value] of Object.entries(dados)) {
                this.formAcessosEstaduais.formGroup.get(key).setValue(value.toString().trim());
                // this.editarAcessosEstaduais[key] = value.toString().trim();
                this.loading.formAcessos = false;
            }
        }, 1500);


        this.modal(this.formAcessosEstaduais, true);
    }

    listarCertificados(params: NzTableQueryParams, search: string = null): void {

        this.currentParams.certificados = params;
        this.currentSearch.certificados = params;

        if (params.filter) {
            params.filter.push({key: 'responsavel_id', value: this.data.id});
            params.pageSize = 10;
        }


        this.loading.certificados = true;
        this.abstractService.listToTable(params, search, this.service.urlCertificados).subscribe((response) => {

            // Descomentar quando tiver endpoint
            this.listaCretificados = [];
            this.listaCretificados = response?.data || response;

            this.listaCretificados.forEach(value => {
                switch (value.tipo) {
                    case 'cnpj': {
                        value.tipo_descricao = 'eCNPJ';
                        break;
                    }
                    case 'nfe': {
                        value.tipo_descricao = 'eNFE';
                        break;
                    }
                    case 'cpf': {
                        value.tipo_descricao = 'eCPF';
                        break;
                    }
                }
            });

            this.pagination.certificados = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loading.certificados = false;
        });

    }

    listarProcuracoes(params: NzTableQueryParams, search: string = null): void {


        this.loading.procuracoes = true;

        if (params.filter) {
            params.filter = [];
            params.filter.push({key: 'responsavel_id', value: this.data.id});
            params.pageSize = 10;

            const filtros = this.formFiltrarProcuracoes.formGroup.value;

            this.qtdFiltrosAtivosProcuracoes = 0;
            for (const [chave, valor] of Object.entries(filtros)) {
                if (valor) {
                    params.filter.push({key: chave, value: valor});
                    this.qtdFiltrosAtivosProcuracoes++;
                }
            }
        }

        this.currentParams.procuracoes = params;
        this.currentSearch.procuracoes = search;

        this.abstractService.listToTable(params, search, this.service.urlProcuracoes).subscribe((response) => {

            this.loading.procuracoes = false;

            this.listaProcuracoes = [];
            this.listaProcuracoes = response?.data || response;

            this.pagination.procuracoes = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, (response) => {
            this.loading.procuracoes = false;
        });


    }

    listarProcuradores(params: NzTableQueryParams, search: string = null): void {

        this.currentParams.procuradores = params;
        this.currentSearch.procuradores = search;

        if (params.filter) {
            params.filter = [];
            params.filter.push({key: 'responsavel_id', value: this.data.id});
            params.pageSize = 10;

            const filtros = this.formFiltrarProcuradores.formGroup.value;

            this.qtdFiltrosAtivosProcuradores = 0;
            for (const [chave, valor] of Object.entries(filtros)) {
                if (valor) {
                    params.filter.push({key: chave, value: valor});
                    this.qtdFiltrosAtivosProcuradores++;
                }
            }
        }


        this.loading.procuradores = true;
        this.abstractService.listToTable(params, search, this.service.urlProcuradores).subscribe((response) => {

            this.loading.procuradores = false;

            this.listaProcuradores = [];
            this.listaProcuradores = response?.data || response;

            this.pagination.procuradores = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        });

    }

    listarAcessos(params: NzTableQueryParams, search: string = null): void {

        this.currentParams.acessos = params;
        this.currentSearch.acessos = params;

        if (params.filter) {
            params.filter.push({key: 'responsavel_id', value: this.data.id});
            params.pageSize = 10;
        }

        this.loading.acessos = true;
        this.abstractService.listToTable(params, null, this.service.urlAcessos).subscribe((response) => {

            // Descomentar quando tiver endpoint
            this.listaAcessos = [];
            this.listaAcessos = response?.data || response;

            this.pagination.acessos = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading.acessos = false;
        });


    }


    cadastrar(form: FormStack): void {
        this.zerarForms();

        form.modalVisible = true;
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 500);

    }

    zerarForms(): void {

        this.editando.procuracoes = null;
        this.editando.procuradores = null;
        this.editando.acessos = null;
        this.formProcuracoesECAC.formGroup.reset();
        this.formProcuradoresECAC.formGroup.reset();
        this.formAcessosEstaduais.formGroup.reset();
        this.formDataPrev.formGroup.reset();
        this.formAdicionarCertificado.formGroup.reset();
        this.editando.certificados = null;
        this.formAdicionarCertificado.formGroup.get('uso').setValue('Próprio');
        this.fileListCertificado = [];

        // DataPrev
        this.identificacaoVisible = false;
        this.senhaVisible = false;
        this.cdr.detectChanges();


    }


    insertProcuracoes(): void {

        this.validaFormProcuracoes();

        const dados = this.formProcuracoesECAC.formGroup.value;

        const responsavelId = this.data.id;

        if (!this.erro) {

            this.loading.procuracoes = true;
            this.service.insertProcuracoes(responsavelId, dados).subscribe((response: any) => {
                    this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

                    this.toastrService.success('Procuração E-CAC cadastrada com sucesso!');
                    this.formProcuracoesECAC.modalVisible = false;
                    this.loading.procuracoes = false;
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loading.procuracoes = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    insertCertificados(): void {

        this.loadings.gravar = true;

        this.validaFormCertificados();

        const dados = this.formAdicionarCertificado.formGroup.value;

        const formData = new FormData();

        Object.entries(this.formAdicionarCertificado.formGroup.value).forEach(
            // tslint:disable-next-line:variable-name
            ([key, value]) => {
                if (value) {
                    formData.set(key, value.toString());
                }
            });

        formData.set('responsavel_id', this.data.id);

        this.fileListCertificado.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        const responsavelId = this.data.id;

        if (!this.erro) {

            this.loading.certificados = true;
            this.service.insertCertificado(responsavelId, formData).subscribe((response: any) => {
                    this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);

                    this.loading.certificados = false;
                    this.toastrService.success('Certificado A1 cadastrado com sucesso!');
                    this.formAdicionarCertificado.modalVisible = false;

                },
                (response) => {
                    this.loading.certificados = false;
                    this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.loadings.gravar = false;
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateProcuracoes(): void {

        this.validaFormProcuracoes();

        const dados = this.formProcuracoesECAC.formGroup.value;

        dados.responsavel_id = this.data.id;

        if (!this.erro) {

            this.loading.procuracoes = true;
            this.service.updateProcuracoes(this.editando.procuracoes, dados).subscribe((response: any) => {
                    this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

                    this.toastrService.success('Procuração E-CAC alterada com sucesso!');
                    this.formProcuracoesECAC.modalVisible = false;
                    this.retornaDadosCadastrais(this.data.id);
                    this.loading.procuracoes = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    this.loading.procuracoes = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateProcuradores(): void {

        this.validaFormProcuradores();

        const dados = this.formProcuradoresECAC.formGroup.value;

        dados.responsavel_id = this.data.id;

        if (!this.erro) {

            this.loading.procuradores = true;
            this.service.updateProcuradores(this.editando.procuradores, dados).subscribe((response: any) => {
                    this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

                    this.toastrService.success('Procurador E-CAC alterado com sucesso!');
                    this.formProcuradoresECAC.modalVisible = false;
                    this.retornaDadosCadastrais(this.data.id);
                    this.loading.procuradores = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    this.loading.procuradores = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateAcessos(): void {

        const dados = this.formAcessosEstaduais.formGroup.value;

        dados.responsavel_id = this.data.id;

        if (!this.erro) {
            this.loading.acessos = true;
            this.service.updateAcessos(this.editando.acessos, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual alterado com sucesso!');
                    this.formAcessosEstaduais.modalVisible = false;
                    this.loading.acessos = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    this.loading.acessos = false;
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateCertificados(): void {

        this.loadings.gravar = true;
        this.validaFormCertificados();

        const formData = new FormData();

        Object.entries(this.formAdicionarCertificado.formGroup.value).forEach(
            // tslint:disable-next-line:variable-name
            ([key, value]) => {
                if (value && (key === 'padrao' || key === 'tipo')) {
                    formData.set(key, value.toString());
                }
            });

        formData.set('responsavel_id', this.data.id);

        if (!this.erro) {

            this.loading.certificados = true;
            this.service.updateCertificados(this.editando.certificados, formData).subscribe((response: any) => {
                    this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);

                    this.toastrService.success('Certificado A1 alterado com sucesso!');
                    this.formAdicionarCertificado.modalVisible = false;
                    this.loading.certificados = false;
                    this.retornaDadosCadastrais(this.data.id);
                },
                (response) => {
                    this.loading.certificados = false;
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.loadings.gravar = false;
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    insertProcuradores(): void {

        this.validaFormProcuradores();

        const dados = this.formProcuradoresECAC.formGroup.value;

        const responsavalId = this.data.id;

        if (!this.erro) {

            this.loading.procuradores = true;
            this.service.insertProcuradores(responsavalId, dados).subscribe((response: any) => {
                    this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

                    this.toastrService.success('Procurador E-CAC cadastrado com sucesso!');
                    this.formProcuradoresECAC.modalVisible = false;
                    this.loading.procuradores = false;
                },
                (response) => {
                    if (response.error.erros) {
                        response.error.erros.forEach(erro => {
                            this.toastrService.error(erro);
                        });
                    } else {
                        this.toastrService.error(response.error.message);
                    }
                    this.loading.procuradores = false;


                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    insertAcessos(): void {

        const dados = this.formAcessosEstaduais.formGroup.value;

        const responsavelId = this.data.id;

        if (!this.erro) {

            this.loading.acessos = true;
            this.service.insertAcessos(responsavelId, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual cadastrado com sucesso!');
                    this.formAcessosEstaduais.modalVisible = false;
                    this.loading.acessos = false;
                },
                (response) => {
                    this.loading.acessos = false;
                    this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    validaFormProcuracoes(): void {
        if (!this.formProcuracoesECAC.formGroup.value.pessoa) {
            this.erro = true;
            this.mensagem = 'Campo Pessoa obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.documento) {
            this.erro = true;
            this.mensagem = 'Campo Documento obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Nome obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Início obrigatório';
        }

        if (this.formProcuracoesECAC.formGroup.value.dataInicio) {
            // tslint:disable-next-line:max-line-length
            this.formProcuracoesECAC.formGroup.value.dataInicio = this.service.formataDateBD(this.formProcuracoesECAC.formGroup.value.dataInicio);
        }
        if (this.formProcuracoesECAC.formGroup.value.dataFim) {
            // tslint:disable-next-line:max-line-length
            this.formProcuracoesECAC.formGroup.value.dataFim = this.service.formataDateBD(this.formProcuracoesECAC.formGroup.value.dataFim);
        }


    }

    validaFormCertificados(): void {

        if (this.formAdicionarCertificado.formGroup.value.padrao) {

            this.formAdicionarCertificado.formGroup.value.padrao = '1';

        } else {

            this.formAdicionarCertificado.formGroup.value.padrao = '0';

        }

        if (this.fileListCertificado.length < 1) {
            this.erro = true;
            this.mensagem = 'Campo Arquivo obrigatório';
        }

        if (!this.formAdicionarCertificado.formGroup.value.serial) {
            this.erro = true;
            this.mensagem = 'Campo Senha obrigatório';
        }

    }

    validaFormProcuradores(): void {
        if (!this.formProcuradoresECAC.formGroup.value.pessoa) {
            this.erro = true;
            this.mensagem = 'Campo Pessoa obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.documento) {
            this.erro = true;
            this.mensagem = 'Campo Documento obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Nome obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Início obrigatório';
        }

        if (this.formProcuradoresECAC.formGroup.value.dataInicio) {
            // tslint:disable-next-line:max-line-length
            this.formProcuradoresECAC.formGroup.value.dataInicio = this.service.formataDateBD(this.formProcuradoresECAC.formGroup.value.dataInicio);
        }
        if (this.formProcuradoresECAC.formGroup.value.dataFim) {
            // tslint:disable-next-line:max-line-length
            this.formProcuradoresECAC.formGroup.value.dataFim = this.service.formataDateBD(this.formProcuradoresECAC.formGroup.value.dataFim);
        }

    }

    maskPessoa(form: FormStack): string {
        if (form.formGroup.value.pessoa === 'F') {
            return '000.000.000-00';
        } else {
            return '00.000.000/0000-00';
        }
    }

    showConfirm(tipo: string, item: any): void {

        let titulo = '';
        switch (tipo) {
            case 'procuracoes': {
                titulo = 'Deseja remover a Procuração E-CAC?';
                break;
            }
            case 'procuradores': {
                titulo = 'Deseja remover o Procurador E-CAC?';
                break;
            }
            case 'certificados': {
                titulo = 'Deseja remover o Certificado A1?';
                break;
            }
            case 'acessos': {
                titulo = 'Deseja remover o Acesso Estadual?';
                break;
            }
        }


        this.modalService.confirm({
            nzTitle: titulo,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(tipo, item)
        });
    }

    showConfirmBuscarProcuracao(): void {

        this.modalService.confirm({
            nzTitle: 'Buscar procurações no ECAC?',
            nzOkText: 'Buscar',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.buscarProcuracoesEcac()
        });
    }

    remover(tipo: string, item: any): void {
        switch (tipo) {
            case 'procuracoes': {
                this.service.removeProcuracoes(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Procuração E-CAC removida com sucesso!');
                        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'procuradores': {
                this.service.removeProcuradores(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Procurador E-CAC removido com sucesso!');
                        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'certificados': {
                this.service.removeCertificados(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Certificado A1 removido com sucesso!');
                        this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessos': {
                this.service.removeAcessos(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Estadual removido com sucesso!');
                        this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }

        }
    }

    buscarProcuracoesEcac(insert: boolean = false): void {

        if (insert) {
            this.insertCertificados();
        }

        this.loading.buscarProcuracoes = true;
        this.service.buscarProcuracoes(this.dadosCadastrais.id).subscribe(
            (response) => {
                this.loading.buscarProcuracoes = false;
                this.toastrService.success(response.message);

            },
            (response) => {
                this.loading.buscarProcuracoes = false;
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        // const status = file.status;
        // if (status !== 'uploading') {
        // }
        // if (status === 'done') {
        //     this.msg.success(`${file.name} file uploaded successfully.`);
        // } else if (status === 'error') {
        //     this.msg.error(`${file.name} file upload failed.`);
        // }
    }

    beforeUploadCertificado = (file: NzUploadFile): boolean => {

        this.fileListCertificado = [];
        this.fileListCertificado.push(file);
        return false;
    }

    filtrarObrigacoes(uf: string): void {

        if (uf) {
            this.service.retornaObrigacoesUF(uf).subscribe((retorno: any) => {
                retorno.obrigacoes.forEach((value) => {
                    value.label = value.descricao;
                    value.key = value.id;
                });
                this.comboObrigacoes = retorno.obrigacoes;
            });
        }
    }

    filtrarCampos(obrigacao: string): void {

        if (obrigacao) {
            this.service.retornaCamposAcessosEstaduais(obrigacao).subscribe((retorno: any) => {
                this.camposFormDataPrev = retorno;
                retorno.forEach((value, index) => {
                    this.formAcessosEstaduais.formGroup.addControl(value.label, new UntypedFormControl(null, Validators.required));
                });
            });
        }
    }

    downloadCertificado() {

        const senha = this.formSenha.formGroup.value.senha;
        if (senha) {

            this.loading.download = true;

            this.modalSenha(false);


            this.service.exportExcel(this.editando.certificados, senha).subscribe((res) => {

                this.formSenha.formGroup.reset();

                this.loading.download = false;

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                fileSaver.saveAs(blob, this.editarCertificados.nomeArquivo);
            });
        } else {
            this.toastService.error('Informe a nova senha do certificado.');
        }
    }

    modalSenha(visible: boolean) {
        this.formSenha.modalVisible = visible;
    }

    modalFiltrarProcuracoes(visible) {
        this.formFiltrarProcuracoes.modalVisible = visible;
    }

    confirmaFiltrarProcuracoes(): void {

        this.currentParams.procuracoes.pageIndex = 1;
        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

        this.modalFiltrarProcuracoes(false);

    }

    confirmaBuscarProcuracoes(): void {

        this.currentParams.procuracoes.pageIndex = 1;
        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);
    }

    btnResetSearchProcuracoes() {

        this.currentSearch.procuracoes = '';
        this.currentParams.procuracoes = {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: [],
        };

        this.formFiltrarProcuracoes.formGroup.reset();

        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

    }

    clickEventProcuracoes() {
        this.fullCardProcuracoes = !this.fullCardProcuracoes;
    }

    sincronizarEmbedded(acao: string): void {

        switch (acao) {

            case 'importar': {

                this.loadings.importando = true;

                this.service.sincronizarEmbedded(acao, this.data.id).subscribe((res) => {

                    this.retornaDadosCadastrais(this.data.id);
                    this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);
                    this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);
                    this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

                    this.toastService.success('Dados importados com sucesso!');
                    this.loadings.importando = false;
                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.importando = false;
                });
                break;
            }
            case 'exportar': {

                this.loadings.exportando = true;

                this.service.sincronizarEmbedded(acao, this.data.id).subscribe((res) => {
                    this.toastService.success('Dados exportados com sucesso!');
                    this.loadings.exportando = false;
                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.exportando = false;
                });
                break;
            }


        }
    }

    modalFiltrarProcuradores(visible) {

        this.formFiltrarProcuradores.modalVisible = visible;
    }

    confirmaFiltrarProcuradores(): void {

        this.currentParams.procuradores.pageindex = 1;
        this.modalFiltrarProcuradores(false);
        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);
    }

    btnResetSearchProcuradores() {

        this.currentSearch.procuradores = '';
        this.currentParams.procuradores = {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: [],
        };

        this.formFiltrarProcuradores.formGroup.reset();

        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

    }

    confirmAtualiarProcuracoes() {

        this.modalService.confirm({
            nzTitle: 'Deseja consultar procurações?',
            nzOkText: 'Atualizar',
            nzOnOk: () => this.buscarProcuracoesEcac(true),
            nzOnCancel: () => this.insertCertificados()
        });
    }

    onAlterarResponsavel(visible: boolean) {

        this.visibles.modalAlterar = visible;

        if (visible) {
            this.loadings.modalAlterar = true;
            this.retornaDadosCadastrais(this.data.id, false).then(() => {
                this.formResponsavel.formGroup.get('nome').setValue(this.dadosCadastrais.nome);
                this.loadings.modalAlterar = false;
            });
        }
    }

    confirmaEditarResponsavel() {


        if (this.formResponsavel.formGroup.valid) {
            this.loadings.alterandoResponsavel = true;

            this.service.editar(this.data.id, this.formResponsavel.formGroup.value).subscribe((res) => {
                console.log(res);
                this.loadings.alterandoResponsavel = false;
                this.toastService.success('Dados cadastrais alterados com sucesso.');
                this.onAlterarResponsavel(false);
                this.retornaDadosCadastrais(this.data.id);
            }, (res) => {
                this.toastService.error(res?.error?.message);
                this.loadings.alterandoResponsavel = false;
            });

        } else {
            Object.values(this.formResponsavel.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../auth/authentication.service';
import {UserService} from '@services/user.service';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {DataService} from '@services/data.service';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Store} from '@ngrx/store';
import {MessagesListService} from '@services/messages-list.service';

declare global {
    interface Window {
        Conpass: any;
    }
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'messages-list',
    templateUrl: './messages-list.component.html',
    styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements OnInit, TabHandlerInterface {

    avisos: any = [];


    constructor(
        private dataService: DataService,
        private router: Router, private authenticationService: AuthenticationService,
        private userService: UserService, private tabService: TabService,
        private toastrService: ToastrService,
        private http: HttpClient,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private msg: NzMessageService,
        private menuListService: MessagesListService,
        private store: Store
    ) {
    }


    ngOnInit() {

        this.menuListService.getAvisos().subscribe((res) => {
            this.avisos = [];
            this.avisos = res;

            this.avisos.forEach(value => {
                value.mensagem = '<h3>' + value.titulo + '</h3><br>' + value.mensagem;
            });


        }, (res) => {
            console.log(res.error.message);
        });


    }

    openTab(path, queryParams = '', data = '') {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }


}


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../../shared/ng-zorro.module';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {VisibilidadeDetalheComponent} from './visibilidade-detalhe.component';
import {UiModule} from '../../../ui.module';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzTagModule} from 'ng-zorro-antd/tag';

@NgModule({
    declarations: [
        VisibilidadeDetalheComponent
    ],
    imports: [
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzListModule,
        NzPageHeaderModule,
        NzAffixModule,
        NzTypographyModule,
        NzDatePickerModule,
        FormsModule,
        NzSwitchModule,
        NzSpaceModule,
        NzTreeModule,
        NzBadgeModule,
        NzSkeletonModule,
        NzAlertModule,
        NzTagModule,
    ]
})
export class VisibilidadeDetalheModule {
}

<nz-page-header class="site-page-header" nzTitle="Check"
                nzSubtitle="Armazenamentos"
                style="padding: 3px">
    <nz-page-header-extra *ngIf="showContent">
        <a *ngIf="loadFake">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{swiperCollapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>

<div *ngIf="showAlert">
    <nz-row nzGutter="24">
        <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        O módulo Obrigações: Check - Validação, Conciliação ainda não foi contratado. Entre em contato com o nosso suporte.
                    </div>
                </div>
            </ng-template>
        </nz-alert>
    </nz-row>
</div>

<nz-row [nzGutter]="24" *ngIf="showContent">
    <nz-col [nzSpan]="24" style="overflow: hidden;">
        <swiper [(index)]="swiperCardAtivo"
                (click)="selecionaCardCompetencia($event)"
                slidesPerView="auto"
                [spaceBetween]="30"
                [centeredSlides]="true"
                [mousewheel]="false"
                [scrollbar]="false"
                [navigation]="false">
            <ng-template swiperSlide *ngFor="let item of swiperCompetencias; index as i"
                         class="swiper-card-competencia">
                <div class="card-competencia ant-card pull-up card-{{i}}"
                     [ngClass]="swiperCollapseCard && 'compress'">
                    <div class="ant-card-head">
                        <h3 style="margin-bottom: 0;">Vencimento:</h3>
                        <h2 style="margin-bottom: 0;">{{(mesesFlow[item.mes - 1] | uppercase)}}/{{item.ano}}</h2>
                    </div>
                    <div class="text-center ant-card-body card-{{i}}">
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.obrigacoes}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Obrigações
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.unidades}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Unidades
                            </div>
                        </div>

                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Armazenamento</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.armazenamentos}}</span>
                                    <span style="color: red;">({{item.armazenamentosAtrasados}})</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template>
        </swiper>
    </nz-col>
</nz-row>
<nz-content *ngIf="showContent">
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="6">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row class="armazenamentos-cabecalho">
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                            {{this.mesesFlow[this.cabecalhoFlow.mes - 1]}}
                        </h4>
                        <div class="d-flex align-items-center justify-content-center">
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0;">
                                {{this.cabecalhoFlow.ano}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="6">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row class="armazenamentos-cabecalho">
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center">
                        <i class="far fa-building"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important;">
                                {{this.cabecalhoFlow.unidades}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="6">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row class="armazenamentos-cabecalho">
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center">
                        <i class="far fa-calendar-alt"
                           style="font-size: 60px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; color: #1e9ff2 !important;">
                                {{this.cabecalhoFlow.obrigacoes}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="6">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex justify-content-between align-items-center">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Armazenamento
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemArmazenadas"
                                             nzType="circle"
                                             [nzWidth]="80"
                                             nz-tooltip="true"
                                             nzTooltipTitle="{{this.cabecalhoFlow.armazenamentos}} Armazenadas / {{this.cabecalhoFlow.armazenamentosAtrasadas}} Atrasadas"
                                             [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>

    </nz-row>
    <nz-row [nzGutter]="24">

    </nz-row>
</nz-content>
<nz-card nzTitle="Obrigações"
         *ngIf="showContent"
         [nzExtra]="tabsExtra"
         [ngClass]="[cardCompress ? 'card-fullscreen' : '', !cardTabsLoading ? 'ant-card-table' : '']"
         [nzLoading]="cardTabsLoading">
    <nz-table #tableFlow [nzData]="obrigacoesFlow" class="table-small"
              [nzPageSize]="100"
              [nzFrontPagination]="true"
              [nzShowTotal]="rangeTemplate">
        <thead>
        <tr>
            <th nzWidth="250px"></th>
            <th nzAlign="center" nzWidth="100px">Obrigações</th>
            <th nzAlign="center" nzWidth="300px">Armazenadas</th>
            <th nzAlign="center" nzWidth="300px">Ausentes</th>
            <th nzAlign="center"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of tableFlow.data">
            <tr *ngIf="data.pastaArmazenamento">
                <td nzAlign="center">
                    <span style="font-size: 16px;">{{data.descricao}}</span>
                </td>
                <td nzAlign="center">
                    <span>{{data.obrigacoes}}</span>
                </td>
                <td>
                    <ng-container>
                        <div class="d-flex justify-content-center"
                             (click)="filtrarArmazenamento({'situacao': 'armazenadas', 'categoria': data.obrigacao_id})">
                            <!--<a>Armazenadas</a>-->
                            <a>
                                {{data.armazenadas}}
                                <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                            </a>
                        </div>
                    </ng-container>
                </td>
                <td>
                    <ng-container>
                        <div class="d-flex justify-content-center"
                             (click)="filtrarArmazenamento({'situacao': 'nao-armazenadas', 'categoria': data.obrigacao_id})">
                            <a>
                                {{data.naoArmazenadas}}
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </a>
                        </div>
                    </ng-container>
                </td>
                <td>
                    <ng-container>
                        <nz-progress [nzPercent]="data.porcentagemArmazenadas"></nz-progress>
                    </ng-container>
                </td>

            </tr>
        </ng-container>
        </tbody>
    </nz-table>
    <ng-template #tabsExtra>
        <div class="d-flex">
            <button nz-button nzType="default"
                    (click)="openTab('MonitorArquivosTransitoriosComponentUpload', null,
                                   {pastaTipo: 'efdfiscal', upload: 'upload', tabStatus: 'processados' })"
                    style="margin-right: 10px;">
                Transferência
            </button>
            <nz-badge [nzCount]="qtdFiltrosAtivos"
                      [nzStyle]="{ backgroundColor: '#52c41a'}"
                      style="margin-right: 10px;">
                <button nz-button nzType="primary" (click)="modalFiltrar(true)">
                    <i nz-icon nzType="search"></i>
                    <span>Filtros</span>
                </button>
            </nz-badge>

            <button nz-button nzType="default" (click)="resetSearch();" style="margin-right: 10px;">
                Limpar
            </button>
            <button nz-button [nzType]="'default'" (click)="clickEvent()">
                <i class="fas" [ngClass]="!cardCompress ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </div>
    </ng-template>
    <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
    </ng-template>
</nz-card>
<nz-modal [(nzVisible)]="formFiltrosFlow.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrosFlow.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosFlow.formGroup.value.categoria">
                    <nz-select nzShowSearch
                               nzPlaceHolder="Selecione"
                               formControlName="categoria"
                               [(ngModel)]="formFiltrosFlow.formGroup.value.categoria"
                               (ngModelChange)="clearInput(formFiltrosFlow.formGroup, 'categoria', 'obrigacao')">
                        <nz-option *ngFor="let opt of comboCategorias"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrosFlow.formGroup.value.obrigacao">
                    <nz-select nzShowSearch
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao"
                               [(ngModel)]="formFiltrosFlow.formGroup.value.obrigacao"
                               (ngModelChange)="clearInput(formFiltrosFlow.formGroup, 'obrigacao', 'categoria')">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

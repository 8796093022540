<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Gatilhos">
</nz-page-header>
<nz-content>
    <nz-card class="fonte-doze"
             [ngClass]="[!loading ? 'ant-card-table' : '']"
             [nzExtra]="navBar"
             nzTitle="Gatilhos"
             [nzLoading]="loading">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <!--<button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nzDisabled>Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>-->
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);"  type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <!--<th (nzCheckedChange)="onAllChecked($event)"
                    [nzChecked]="checked"
                    [nzIndeterminate]="indeterminate"
                    id="check-all"></th>-->
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                <th nzColumnKey="orgao" [nzSortFn]="true" nzAlign="center">Orgão</th>
                <th nzColumnKey="gatilhos" [nzSortFn]="true" nzAlign="center">Gatilhos</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let editarGatilhos of basicTable.data">
                <!--<td nzAlign="center" (nzCheckedChange)="onItemChecked(editarGatilhos.id, $event)"
                    [nzChecked]="checkedItems.has(editarGatilhos.id)"
                    [nzDisabled]="editarGatilhos.disabled">
                </td>-->
                <td nzAlign="center">{{editarGatilhos?.descricao}}</td>
                <td nzAlign="center">{{editarGatilhos?.orgao}}</td>
                <td nzAlign="center">{{editarGatilhos?.gatilhos}}</td>
                <td nzRight nzAlign="center">
                    <button (click)="openTab('/cadastros/editar-gatilhos/', editarGatilhos.id, {id: editarGatilhos.obrigacao_id})"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.obrigacao_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="obrigacao_id">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.nome">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="orgao">
                        <nz-option [nzLabel]="'Estadual'" [nzValue]="'Estadual'"></nz-option>
                        <nz-option [nzLabel]="'Federal'" [nzValue]="'Federal'"></nz-option>
                        <nz-option [nzLabel]="'Previdenciário'" [nzValue]="'Previdenciário'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LOCALE_ID} from '@angular/core';
import {NotifyTestService} from './notifyTest.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioEquipeService} from '@services/usuarioEquipe.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-notificacoes-notifyTest',
    templateUrl: './notifyTest.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./notifyTest.component.scss']
})

export class NotifyTestComponent extends AbstractListTable<any> implements OnInit {

    pageTitle;
    validateForm: UntypedFormGroup;
    showForm;
    arraySelectUsuarios;

    constructor(
        private route: ActivatedRoute,
        private msgSrv: NzMessageService,
        private usuarioEquipeService: UsuarioEquipeService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        private http: HttpClient,
        private modalSrv: NzModalService,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private notifyTestService: NotifyTestService,
        private fb: UntypedFormBuilder
    ) {
        super(usuarioEquipeService, Usuario, toastService);

        this.notifyTestService.retornarSelectUsuarios(
        ).subscribe((retorno: any) => {
            this.arraySelectUsuarios = retorno;
        });

    }

    ngOnInit(): void {
        this.changeNotifyTipe('PUB');
    }

    changeNotifyTipe(tipo) {

        this.showForm = tipo;

        if (tipo === 'PUB') {

            this.pageTitle = 'Notificações Publicas';

            this.validateForm = this.fb.group({
                titulo: ['', [Validators.required]],
                mensagem: ['', [Validators.required]],
            });

        }

        if (tipo === 'PRI') {

            this.pageTitle = 'Notificações Privadas';

            this.validateForm = this.fb.group({
                usuario_id: ['', [Validators.required]],
                titulo: ['', [Validators.required]],
                mensagem: ['', [Validators.required]],
            });

        }

        if (tipo === 'PRO') {

            this.pageTitle = 'Notificações Processamento';

            this.validateForm = this.fb.group({
                usuario_id: ['', [Validators.required]],
                titulo: ['', [Validators.required]],
                mensagem: ['', [Validators.required]],
            });

        }

    }

    enviarNotificacao() {

        const tipo = this.showForm === 'PUB' ? 'publica' : (this.showForm === 'PRI' ? 'privada' : 'processamento');

        const id = this.validateForm.value.usuario_id;

        const mensagem = {
            titulo: this.validateForm.value.titulo,
            mensagem: this.validateForm.value.mensagem,
            id: this.generateId(),
            dataNotificacao: '2021-12-02',
            diasNotificacao: null,
            actions: [
                {
                    titulo: 'Cadastrar',
                    url: '',
                    modelo: 'cadastrar'
                }
            ]
        };

        this.notifyTestService.enviarNotificacao(tipo, id, mensagem).subscribe((response) => {

                this.toastrService.success(response.message);

                this.limparForm();

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    limparForm() {
        this.validateForm.reset();
    }


}

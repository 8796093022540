import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';

interface IDictionary {
    [index: string]: string;
}


@Injectable({providedIn: 'root'})
export class DomicilioEletronicoService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornaDecs(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronico`);
    }

    retornaInfos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronico/cabecalho`);
    }

    retornarSelectUnidades(domicilio): Observable<any> {

        domicilio = domicilio ? `/${domicilio}` : '';

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/combo/empresas${domicilio}`);

    }

    retornarSelectDomicilios(empresaId): Observable<any> {

        return this.http.get(`${environment.apiUrl}/domicilio/domicilioEletronicoEmpresa/combo/domicilios`);

    }

    retornarSelectCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/categoriasDecMensagens/select`);
    }

    montaQueryStringcustom(filtros): string {

        const queryParams = [];

        for (const [key, value] of Object.entries(filtros)) {

            if (key === 'sort') {

                for (const [keyTwo, valueTwo] of Object.entries(value)) {
                    queryParams.push('sort[' + keyTwo + ']' + '=' + valueTwo);
                }

            } else if (key === 'dataDe') {

                if (value) {
                    queryParams.push('data' + '[de]=' + value);
                }

            } else if (key === 'dataAte') {

                if (value) {
                    queryParams.push('data' + '[ate]=' + value);
                }

            } else {

                if (value) {
                    queryParams.push(key + '=' + value);
                }

            }

        }

        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaDomicilios(filtros: any, order: any, url: any, pageIndex: any, search: any): Observable<any> {

        if (filtros.ultimaCaptura) {
            filtros.ultimaCaptura = this.formataDateBD(filtros.ultimaCaptura.toString());
        }

        if (filtros.proximaLeitura) {
            filtros.proximaLeitura = this.formataDateBD(filtros.proximaLeitura.toString());
        }

        if (filtros.proximaConsulta) {
            filtros.proximaConsulta = this.formataDateBD(filtros.proximaConsulta.toString());
        }
        const params = {} as IDictionary;
        params.heart = 'HP';
        params.teste = 'HP';

        if (filtros.dataDe) {
            filtros.dataDe = this.formataDateBD(filtros.dataDe.toString()) + ' 00:00:00';
        }

        if (filtros.dataAte) {
            filtros.dataAte = this.formataDateBD(filtros.dataAte.toString()) + ' 23:59:59';
        }

        if (filtros.prazoTacito) {
            filtros.prazoTacito = this.formataDateBD(filtros.prazoTacito.toString());
        }

        if (order.ordenar && order.sentido) {
            filtros.sort = {};
            filtros.sort.by = order.ordenar;
            filtros.sort.order = order.sentido;
        }

        if (pageIndex) {
            filtros.page = pageIndex;
        }

        if (search) {
            filtros.busca = search;
        } else {
            delete filtros.busca;
        }

        const queryStr = this.montaQueryStringcustom(filtros);

        return this.http.get(`${environment.apiUrl}${url}${queryStr}`);

    }

    montaOrder(parametros): any {

        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'asc' : 'desc'
                };
            }
        });

        return order;

    }

    exportExcel(url: string, filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        if (filtros.ultimaCaptura) {
            filtros.ultimaCaptura = this.formataDateBD(filtros.ultimaCaptura.toString());
        }

        if (filtros.proximaLeitura) {
            filtros.proximaLeitura = this.formataDateBD(filtros.proximaLeitura.toString());
        }

        if (filtros.proximaConsulta) {
            filtros.proximaConsulta = this.formataDateBD(filtros.proximaConsulta.toString());
        }
        const params = {} as IDictionary;
        params.heart = 'HP';
        params.teste = 'HP';

        if (filtros.dataDe) {
            filtros.dataDe = this.formataDateBD(filtros.dataDe.toString()) + ' 00:00:00';
        }

        if (filtros.dataAte) {
            filtros.dataAte = this.formataDateBD(filtros.dataAte.toString()) + ' 23:59:59';
        }

        if (filtros.prazoTacito) {
            filtros.prazoTacito = this.formataDateBD(filtros.prazoTacito.toString());
        }

        const queryStr = this.montaQueryStringcustom(filtros);

        return this.http.get(`${environment.apiUrl}${url}/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    retornaJson(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/debug/dec-body-post?domicilioEletronicoEmpresa_id=${id}`);
    }

    processarMensagens(ids, force = false): Observable<any> {

        const extra = force ? '?processar-agora=1' : '';

        return this.http.post(`${environment.apiUrl}/domicilio/ativar-leitura-mensagem-selecionados${extra}`, ids);
    }

    lerNovamente(ids, force = false): Observable<any> {

        const extra = force ? '?processar-agora=1' : '';

        return this.http.post(`${environment.apiUrl}/domicilio/ativar-leitura-novamente-mensagem-selecionados`, ids);
    }

    processarMensagensEmpresas(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/domicilio/ativar-leitura-selecionados`, ids);
    }

    reprocessarRegistro(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/robo/executar/dec/${id}`);
    }

    processarMensagensEmpresa(id, force = false): Observable<any> {

        const extra = force ? '?processar-agora=1' : '';

        return this.http.post(`${environment.apiUrl}/domicilio/ativar-leitura-selecionados${extra}`, [id]);
    }

    exportarMensagens(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=xlsx`);
        queryParams.push(`tipo=mensagensDEC`);

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}${queryString}`, {});

    }


    baixaAnexo(id: string): any {

        return this.http.get(`${environment.apiUrl}/domicilio/mensagem/anexo/${id}`,
            {responseType: 'blob'});

    }

}

<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="SPED Notas Detalhes">
</nz-page-header>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="19">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="16">
                        <nz-card [nzLoading]="loadings.cabecalho"
                                 [nzBodyStyle]="loadings.contadores ? {'height': '225px'} : {}">
                            <div class="d-flex align-items-center" style="margin-bottom: 20px;">
                                <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                                     class="d-flex justify-content-center align-items-center">
                                    <svg width="40" height="40"
                                         [data-jdenticon-value]="dadosAnalise?.unidade"
                                         *ngIf="dadosAnalise.unidade"></svg>
                                </div>
                                <h5 nz-typography style="margin-bottom: 0; margin-left: 10px;">
                                    {{ modoDemo(dadosAnalise.unidade, 'empresaCodigoNome') }}
                                </h5>
                            </div>
                            <nz-descriptions nzTitle="" [nzColumn]="2">
                                <nz-descriptions-item nzTitle="CNPJ">
                                    {{ modoDemo(dadosAnalise.cnpj | cpfCnpj, 'cnpj') }}
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Período">
                                    {{ dadosAnalise.dataInicio | date: 'dd/MM/yyyy' }}
                                    à {{ dadosAnalise.dataFim | date: 'dd/MM/yyyy' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="2">
                                <nz-descriptions-item nzTitle="SPEDs alterados">
                                    {{ dadosAnalise.spedAlterados ? dadosAnalise.spedAlterados : '0' }}
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="XMLs alterados">
                                    {{ dadosAnalise.xmlAlterados ? dadosAnalise.xmlAlterados : '0' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle="Solicitação">
                                    {{ dadosAnalise.cadastroData | date: 'dd/MM/yyyy' }} por
                                    {{ dadosAnalise.cadastroUsuarioNome ? dadosAnalise.cadastroUsuarioNome : '-' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </nz-card>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <nz-card
                                style="height: 230px; cursor: pointer; text-align: center; background-color: rgba(156,39,176,0.73)"
                                [nzCover]="coverDesconsideradas" class="ant-card-table"
                                [nzLoading]="loadings.contadores"
                                [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                            <nz-content>
                                <nz-row (click)="filtrarClick('status_18')">
                                    <nz-col [nzSpan]="24"
                                            style="padding-top: 20px">
                                        <div>
                                            <h1 nz-typography
                                                style="color: #ffffff; margin-bottom: 0;">{{ (contadores.status_desconsideradas?.quantidade | number: '1.0-0' : 'pt-br') || '-' }}</h1>
                                        </div>
                                        <div style="padding-bottom: 25px">
                                            <h3 nz-typography style="color: #ffffff; margin-bottom: 0.7em;">
                                                {{
                                                    contadores.status_desconsideradas?.valor ?
                                                        'R$ ' + (contadores.status_desconsideradas.valor | number: '1.2-2' : 'pt-br') : '-'
                                                }}</h3>
                                        </div>
                                        <span style="font-weight: 900;color: #fff;">NFs que não devem ser escrituradas</span>
                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </nz-card>
                        <ng-template #coverDesconsideradas>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex">
                                        <div style="color: #fff; border: none; margin-right: 8px">
                                            <i class="fas fa-file"></i>
                                        </div>
                                        <span style="color: #ffffff;">Desconsideradas</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                </nz-row>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="8">
                        <nz-card class="ant-card-table" [nzCover]="coverCardEscrituradas"
                                 [nzLoading]="loadings.contadores"
                                 [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px', 'height': '125px'} : {'height': '122px'}">
                            <nz-content style="padding: 15px 0;">
                                <nz-row>
                                    <nz-col [nzSpan]="8"
                                            class="d-flex justify-content-center align-items-center ant-card-hoverable"
                                            style="flex-direction: column; cursor: pointer;"
                                            (click)="filtrarClick('status_1')">
                                        <h4 nz-typography
                                            style="color: #28d094; margin: 0;">{{ contadores.status_ec?.total }}</h4>
                                    </nz-col>
                                    <nz-col [nzSpan]="16">
                                        <nz-content>
                                            <nz-row class="ant-card-hoverable"
                                                    (click)="filtrarClick('status_1', 'tipo_0')">
                                                <nz-col [nzSpan]="6"
                                                        class="d-flex align-items-center justify-content-center">
                                                    (E)
                                                </nz-col>
                                                <nz-col [nzSpan]="18">
                                                    <div class="d-flex justify-content-between">
                                                        <span>Valor:</span>
                                                        <span style="color: #28d094; padding-right: 15px;">{{ contadores?.status_ec?.entradas?.valor  | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>ICMS:</span>
                                                        <span style="color: #28d094; padding-right: 15px;">{{ contadores?.status_ec?.entradas?.icms | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                </nz-col>
                                            </nz-row>
                                            <nz-row class="ant-card-hoverable"
                                                    (click)="filtrarClick('status_1', 'tipo_1')">
                                                <nz-col [nzSpan]="6"
                                                        class="d-flex align-items-center justify-content-center">
                                                    (S)
                                                </nz-col>
                                                <nz-col [nzSpan]="18">
                                                    <div class="d-flex justify-content-between">
                                                        <span>Valor:</span>
                                                        <span style="color: #28d094; padding-right: 15px;">{{ contadores?.status_ec?.saidas?.valor | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>ICMS:</span>
                                                        <span style="color: #28d094; padding-right: 15px;">{{ contadores?.status_ec?.saidas?.icms | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                </nz-col>
                                            </nz-row>
                                        </nz-content>
                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </nz-card>
                        <ng-template #coverCardEscrituradas>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center"
                                             style="width: 35px; height: 35px; background-color: #28d094; color: #fff; border-radius: 50%; margin-right: 8px;">
                                            <i class="fas fa-file-alt"></i>
                                        </div>
                                        <span style="color: #28d094;">Escrituradas Corretamente</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <nz-card class="ant-card-table" [nzCover]="coverCardEscrituradasIndevidamente"
                                 [nzLoading]="loadings.contadores"
                                 [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px', 'height': '125px'} : {}">
                            <nz-content style="padding: 15px 0;">
                                <nz-row>
                                    <nz-col [nzSpan]="8"
                                            class="d-flex justify-content-center align-items-center ant-card-hoverable"
                                            style="flex-direction: column; cursor: pointer;"
                                            (click)="filtrarClick('status_5')">
                                        <h4 nz-typography
                                            style="color: #ff9149; margin: 0;">{{ contadores.status_ei?.total }}</h4>
                                    </nz-col>
                                    <nz-col [nzSpan]="16">
                                        <nz-content>
                                            <nz-row class="ant-card-hoverable"
                                                    style="margin-bottom: 5px;"
                                                    (click)="filtrarClick('status_5', 'tipo_0')">
                                                <nz-col [nzSpan]="6"
                                                        class="d-flex align-items-center justify-content-center">
                                                    (E)
                                                </nz-col>
                                                <nz-col [nzSpan]="18">
                                                    <div class="d-flex justify-content-between">
                                                        <span>Valor:</span>
                                                        <span style="color: #ff9149; padding-right: 15px;">{{ contadores?.status_ei?.entradas?.valor | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>ICMS:</span>
                                                        <span style="color: #ff9149; padding-right: 15px;">{{ contadores?.status_ei?.entradas?.icms | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                </nz-col>
                                            </nz-row>
                                            <nz-row class="ant-card-hoverable"
                                                    (click)="filtrarClick('status_5', 'tipo_1')">
                                                <nz-col [nzSpan]="6"
                                                        class="d-flex align-items-center justify-content-center">
                                                    (S)
                                                </nz-col>
                                                <nz-col [nzSpan]="18">
                                                    <div class="d-flex justify-content-between">
                                                        <span>Valor:</span>
                                                        <span style="color: #ff9149; padding-right: 15px;">{{ contadores?.status_ei?.saidas?.valor | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <span>ICMS:</span>
                                                        <span style="color: #ff9149; padding-right: 15px;">{{ contadores?.status_ei?.saidas?.icms | number: '1.2-2' : 'pt-br' }}</span>
                                                    </div>
                                                </nz-col>
                                            </nz-row>
                                        </nz-content>
                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </nz-card>
                        <ng-template #coverCardEscrituradasIndevidamente>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center"
                                             style="width: 35px; height: 35px; background-color: #ff9149; color: #fff; border-radius: 50%; margin-right: 8px;">
                                            <i class="fas fa-file-alt"></i>
                                        </div>
                                        <span style="color: #ff9149;">Escrituradas Indevidamente</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="8">
                        <div class="red-gradiend">
                            <nz-card class="bg-red-exagon ant-card-table" [nzCover]="coverCardNaoEscrituradas"
                                     [nzLoading]="loadings.contadores"
                                     [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px', 'height': '135px'} : {}">
                                <nz-content style="padding: 10px 0;">
                                    <nz-row style="color: #fff; text-align: center;">
                                        <nz-col [nzSpan]="8" class="ant-card-hoverable"
                                                (click)="cardQuebraSequencia(true);">
                                            <div>
                                                <h3 nz-typography
                                                    style="color: #fff;">{{ contadores?.status_ne?.status_55 || '-' }}</h3>
                                            </div>
                                            <div>
                                                <span>Falta de sequencia<br/>das emissões</span>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="8" class="ant-card-hoverable"
                                                (click)="filtrarClick('status_4')">
                                            <div>
                                                <h3 nz-typography
                                                    style="color: #fff;">{{ contadores?.status_ne?.status_4?.quantidade || '-' }}</h3>
                                            </div>
                                            <div>
                                                <span>R$ {{ (contadores?.status_ne?.status_4?.valor | currency: 'R$' : '' : '1.2-2' : 'pt-br') || '-' }}</span>
                                            </div>
                                            <div>
                                                <small>XMLs fora<br/>do SPED</small>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="8" class="ant-card-hoverable"
                                                (click)="filtrarClick('status_6')">
                                            <div>
                                                <h3 nz-typography
                                                    style="color: #fff;">{{ contadores?.status_ne?.status_6?.quantidade || '-' }}</h3>
                                            </div>
                                            <div>?
                                                <!--                                                <span>{{contadores?.status_ne?.status_6?.valor | number: '1-2.2' : 'pt-br'}}</span>-->
                                            </div>
                                            <div>
                                                <small>NFs somente<br/>na SEFAZ</small>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardNaoEscrituradas>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted">
                                        <i class="fas fa-file" style="margin-right: 5px; color: #fff;"></i> Não
                                        Escrituradas
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </nz-col>
        <nz-col [nzSpan]="5">


            <nz-card class="bg-white-exagon ant-card-table"
                     [nzLoading]="loadings.contadores"
                     [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px', 'height': '173px'} : {'height': '173px'}"
                     [nzCover]="coverCardDivergencias">
                <nz-row [nzGutter]="12">
                    <nz-col [nzSpan]="24">
                        <div>
                            <nz-list nzSize="small">
                                <nz-list-item *ngFor="let item of divergencias"
                                              class="ant-card-grid-hoverable"
                                              style="cursor: pointer"
                                              (click)="filtrarClick(item.chave)">
                                    <span nz-typography>{{ item.label }}</span>
                                    <a nz-button nzType="link" style="padding: 0;"
                                    >{{ item.qtd }}</a>
                                </nz-list-item>
                            </nz-list>
                        </div>
                    </nz-col>
                </nz-row>
            </nz-card>
            <ng-template #coverCardDivergencias>
                <div class="ant-card-head ng-star-inserted">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                            <span>
                                <i class="fas fa-not-equal" style="margin-right: 5px;"></i> Divergências
                            </span>
                            <a (click)="filtrarClick('status_2')">{{ contadores.status_dv?.total }}</a>
                        </div>
                    </div>
                </div>
            </ng-template>


            <nz-card style="height: 191px" [nzCover]="coverCardXmlsAusentes" class="ant-card-table"
                     [nzLoading]="loadings.contadores"
                     [nzBodyStyle]="loadings.contadores ? {'overflow' : 'hidden', 'padding':'15px', 'height': '191px'} : {}">
                <nz-content>
                    <nz-row>
                        <nz-col [nzSpan]="24"
                                style="text-align: center; cursor: pointer;"
                                (click)="filtrarClick('status_3')">
                            <div>
                                <h1 nz-typography
                                    style="color: rgba(156,39,176,0.73); margin-bottom: 0;">{{ contadores.status_xa?.quantidade | number: '1.0-0' : 'pt-br' }}</h1>
                            </div>
                            <div>
                                <h3 nz-typography style="color: rgba(156,39,176,0.73); margin-bottom: 0.7em;">R$
                                    {{ contadores.status_xa?.valor  | number: '1.2-2' : 'pt-br' }}</h3>
                            </div>
                            <span style="font-weight: 500;color: #6b6f82;">NFs Escrituradas s/ XML Armazenado</span>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
            <ng-template #coverCardXmlsAusentes>
                <div class="ant-card-head ng-star-inserted">
                    <div class="ant-card-head-wrapper">
                        <div class="ant-card-head-title ng-star-inserted d-flex align-items-center">
                            <div class="d-flex justify-content-center align-items-center"
                                 style="width: 35px; height: 35px; background-color: rgba(156,39,176,0.73); color: #fff; border-radius: 50%; margin-right: 8px;">
                                <i class="fas fa-file-code"></i>
                            </div>
                            <span style="color: rgba(156,39,176,0.73);">XMLs Ausentes</span>
                        </div>
                    </div>
                </div>
            </ng-template>

        </nz-col>
    </nz-row>
</nz-content>
<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Notas" *ngIf="cardNotaVisible"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table']"
             [nzLoading]="loading" [nzBodyStyle]="loadings ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  style="overflow-x: hidden"
                  [nzData]="items"
                  [nzScroll]="{ x: 'auto' }"
                  [nzFrontPagination]="false"
                  [nzShowTotal]="rangeTemplate"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  class="table-small"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="numero" [nzSortFn]="true">Nota</th>
                <th nzColumnKey="modelo" [nzSortFn]="true" class="text-center">Modelo</th>
                <th nzColumnKey="serie" [nzSortFn]="true" class="text-center">Série</th>
                <th nzColumnKey="emissaoTipo" [nzSortFn]="true" class="text-center">Emissão</th>
                <th nzColumnKey="tipoDocumento" [nzSortFn]="true" class="text-center">Tipo XML</th>
                <th nzColumnKey="tipoEscrituracao" [nzSortFn]="true" class="text-center">Tipo SPED</th>
                <th nzColumnKey="documento" [nzSortFn]="true" class="text-center">Documento</th>
                <th nzColumnKey="nome" [nzSortFn]="true" class="text-center">Participante</th>
                <th nzColumnKey="uf" [nzSortFn]="true" class="text-center">UF</th>
                <th nzColumnKey="situacao" [nzSortFn]="true" class="text-center">Situação</th>
                <th nzColumnKey="data" [nzSortFn]="true" class="text-center">dtEmissão</th>
                <th nzColumnKey="entradaData" [nzSortFn]="true" class="text-center">dtEntrada</th>
                <th nzColumnKey="valor" [nzSortFn]="true" class="text-center">Valor</th>
                <th nzColumnKey="icmsValor" [nzSortFn]="true" class="text-center">ICMS Valor</th>
                <th nzColumnKey="ipiValor" [nzSortFn]="true" class="text-center">IPI Valor</th>
                <th nzColumnKey="natureza" [nzSortFn]="true" class="text-center">Natureza</th>
                <th nzColumnKey="chave" [nzSortFn]="true" class="text-center">Chave</th>
                <th nzColumnKey="arquivoCompetencia" [nzSortFn]="true" class="text-center">Competência</th>
                <th nzColumnKey="status" [nzSortFn]="true" class="text-center">Status</th>
                <th nzColumnKey="analise" [nzSortFn]="true" class="text-center">Resultado da Análise</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data" style="white-space: nowrap;">
                <td nzLeft (nzCheckedChange)="onItemChecked(item.chave, $event)"
                    [nzChecked]="checkedItems.has(item.chave)">
                </td>
                <td nzLeft>
                    <span [ngStyle]="item.objDivergentes.numero && {color: 'red'}">
                        {{ item.numero ? modoDemo(item.numero, 'total') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.numero && {color: 'blue'}"
                          *ngIf="item.objDivergentes.numero">
                        <br>{{ modoDemo(item.objDivergentes.numero, 'total') }}
                    </span>
                    <span *ngIf="item.ignorar" [nz-tooltip]="'Nota ignorada'"
                          nz-icon nzType="warning" nzTheme="outline" style="color: #faad14">
                    </span>

                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.modelo && {color: 'red'}">
                        {{ item.modelo ? stringModelo[item.modelo] : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.modelo && {color: 'blue'}"
                          *ngIf="item.objDivergentes.modelo">
                        <br>{{ stringModelo[item.objDivergentes.modelo] }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.serie && {color: 'red'}">
                        {{ item.serie ? item.serie : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.serie && {color: 'blue'}"
                          *ngIf="item.objDivergentes.serie">
                        <br>{{ item.objDivergentes.serie }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.emissaoTipo && {color: 'red'}">
                        {{ item.emissaoTipo ? item.emissaoTipo : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.emissaoTipo && {color: 'blue'}"
                          *ngIf="item.objDivergentes.emissaoTipo">
                        <br>{{ item.objDivergentes.emissaoTipo }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.tipoDocumento && {color: 'red'}">
                        {{ item.tipoDocumento ? formataEntradaSaida(item.tipoDocumento) : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.tipoDocumento && {color: 'blue'}"
                          *ngIf="item.objDivergentes.tipoDocumento">
                        <br>{{ formataEntradaSaida(item.objDivergentes.tipoDocumento) }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.tipoEscrituracao && {color: 'red'}">
                        {{ item.tipoEscrituracao ? formataEntradaSaida(item.tipoEscrituracao) : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.tipoEscrituracao && {color: 'blue'}"
                          *ngIf="item.objDivergentes.tipoEscrituracao">
                        <br>{{ formataEntradaSaida(item.objDivergentes.tipoEscrituracao) }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.documento && {color: 'red'}">
                        {{ item.documento ? modoDemo(item.documento | cpfCnpj, 'cnpj') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.documento && {color: 'blue'}"
                          *ngIf="item.objDivergentes.documento">
                        <br>{{ modoDemo(item.objDivergentes.documento | cpfCnpj, 'cnpj') }}
                    </span>
                </td>
                <td class="text-center">
                     <span [ngStyle]="item.objDivergentes.nome && {color: 'red'}">
                        {{ item.nome ? modoDemo(item.nome, 'total') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.nome && {color: 'blue'}"
                          *ngIf="item.objDivergentes.nome">
                        <br>{{ modoDemo(item.objDivergentes.nome, 'total') }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.uf && {color: 'red'}">
                        {{ item.uf ? item.uf : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.uf && {color: 'blue'}"
                          *ngIf="item.objDivergentes.uf">
                        <br>{{ item.objDivergentes.uf }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.situacao && {color: 'red'}">
                        {{ item.situacao ? item.situacao : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.situacao && {color: 'blue'}"
                          *ngIf="item.objDivergentes.situacao">
                        <br>{{ item.objDivergentes.situacao }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.data && {color: 'red'}">
                        {{ item.data && item.data !== '0000-00-00' ? (item.data | date: 'dd/MM/yyyy') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.data && {color: 'blue'}"
                          *ngIf="item.objDivergentes.data">
                        <br>{{ item.objDivergentes.data | date: 'dd/MM/yyyy' }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.entradaData && {color: 'red'}">
                        {{ item.entradaData && item.entradaData !== '0000-00-00' ? (item.entradaData | date: 'dd/MM/yyyy') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.entradaData && {color: 'blue'}"
                          *ngIf="item.objDivergentes.entradaData">
                        <br>{{ item.objDivergentes.entradaData | date: 'dd/MM/yyyy' }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.valor && {color: 'red'}">
                        {{ item.valor ? (item.valor | number: '1.2-2' : 'pt-br') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.valor && {color: 'blue'}"
                          *ngIf="item.objDivergentes.valor">
                        <br>{{ item.objDivergentes.valor | number: '1.2-2' : 'pt-br' }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.icmsValor && {color: 'red'}">
                        {{ item.icmsValor ? (item.icmsValor | number: '1.2-2' : 'pt-br') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.icmsValor && {color: 'blue'}"
                          *ngIf="item.objDivergentes.icmsValor">
                        <br>{{ item.objDivergentes.icmsValor | number: '1.2-2' : 'pt-br' }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.ipiValor && {color: 'red'}">
                        {{ item.ipiValor ? (item.ipiValor | number: '1.2-2' : 'pt-br') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.ipiValor && {color: 'blue'}"
                          *ngIf="item.objDivergentes.ipiValor">
                        <br>{{ item.objDivergentes.ipiValor | number: '1.2-2' : 'pt-br' }}
                    </span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.natureza && {color: 'red'}">
                        {{ item.natureza ? item.natureza : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.natureza && {color: 'blue'}"
                          *ngIf="item.objDivergentes.natureza">
                        <br>{{ item.objDivergentes.natureza }}
                    </span>
                </td>
                <td class="text-center">
                    <a nz-button nzType="link" [ngStyle]="item.objDivergentes.chave && {color: 'red'}"
                       *ngIf="item.temS3"
                       (click)="abrirPdf(item.chave)">
                        {{ item.chave ? item.chave : '-' }}
                    </a>
                    <span [ngStyle]="item.objDivergentes.chave && {color: 'red'}"
                          *ngIf="!item.temS3">
                        {{ item.chave ? item.chave : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.chave && {color: 'blue'}"
                          *ngIf="item.objDivergentes.chave">
                        <br>{{ item.objDivergentes.chave }}
                    </span>
                </td>
                <td class="text-center">
                    <a nz-button nzType="link" [ngStyle]="item.objDivergentes.arquivoCompetencia && {color: 'red'}"
                       *ngIf="item.arquivoCompetencia && item.modelo && item.chave && item.documento && item.numero && item.serie"
                       (click)="showModal(item.arquivoCompetencia, item.modelo, item.chave, item.documento, item.numero, item.serie)">
                        {{ item.arquivoCompetencia ? (item.arquivoCompetencia | date: 'MM/yyyy') : '-' }}
                    </a>
                    <span [ngStyle]="item.objDivergentes.arquivoCompetencia && {color: 'red'}"
                          *ngIf="!item.arquivoCompetencia || !item.modelo || !item.chave || !item.documento || !item.numero || !item.serie">
                        {{ item.arquivoCompetencia ? (item.arquivoCompetencia | date: 'MM/yyyy') : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.arquivoCompetencia && {color: 'blue'}"
                          *ngIf="item.objDivergentes.arquivoCompetencia">
                        <br>{{ item.objDivergentes.arquivoCompetencia | date: 'MM/yyyy' }}
                    </span>
                <td class="text-center">
                    <span [ngStyle]="item.objDivergentes.status && {color: 'red'}">
                        {{ item.status ? stringStatus[item.status] : '-' }}
                    </span>
                    <span [ngStyle]="item.objDivergentes.status && {color: 'blue'}"
                          *ngIf="item.objDivergentes.status">
                        <br>{{ stringStatus[item.objDivergentes.status] }}
                    </span>
                </td>
                <td class="text-center">
                    <span>
                        {{ item.analise || '-'}}
                    </span>
                </td>

            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>

    <nz-card class="ant-card-table ant-card-scroll" [nzExtra]="botoesQuebra" nzTitle="Quebra de sequência"
             *ngIf="cardQuebraVisible">
        <nz-table #basicTable2
                  nzSize="small"
                  [nzData]="quebras"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false">
            <thead>
            <tr>
                <th nzShowSort="false" class="text-center">Série</th>
                <th nzShowSort="false" class="text-center">Modelo</th>
                <th nzShowSort="false" class="text-center">Análise</th>
                <th nzShowSort="false" class="text-center">Total de Ausência</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable2.data" style="white-space: nowrap;">
                <td class="text-center">{{ item.serie }}</td>
                <td class="text-center">{{ item.modelo }}</td>
                <td class="text-center">De {{ item.min | number:'1.0-0' : 'pt-br' }}
                    até {{ item.max | number:'1.0-0' : 'pt-br' }}
                </td>
                <td class="text-center">{{ (item.max - item.min)  | number:'1.0-0' : 'pt-br' }}</td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-content>
<ng-template #navBar>
    <div style="display: flex; align-items: center; justify-content: flex-end">
        <nz-alert *ngIf="notasIgnoradas.length" nzType="warning" nzShowIcon [nzMessage]="checkedMessage"
                  style="margin: 15px !important; width: 490px!important;">
            <ng-template #checkedMessage>
                <div>
                    <div>
                        <strong class="text-primary"></strong>
                        Existe(m) {{ notasIgnoradas.length }} nota(s) Ignoradas nesta análise.
                        <a *ngIf="!loadings.ignoradas" (click)="getNotasIgnoradas(this.currentParamsIgnoradas)">
                            Clique aqui para ver.
                        </a>
                        <span *ngIf="loadings.ignoradas" nz-tooltip="Carregando notas. Por favor aguarde."
                              style="color: #1890ff; margin-left: 20px" nz-icon nzType="loading" nzTheme="outline">
                        </span>
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a'}"
                  style="margin-right: 10px;">
            <button nz-button nzType="primary" nzGhost
                    (click)="modalFiltrar(true);"
                    style="margin-right: 10px;">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>

        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default" style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item
                            (click)="checkedItems?.size > 0 && showConfirmIgnorarNotas(true)"
                            [nzDisabled]="checkedItems?.size === 0">Ignorar Nota(s)
                        </li>
                    </ul>
                </li>
                <li nz-menu-item (click)="modalExportar(true);">Exportar Planilha</li>
            </ul>
        </nz-dropdown-menu>

        <div style="max-width: 400px">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                <input (keydown.enter)="searsh();" type="text" nz-input placeholder="Busca" [(ngModel)]="pesquisar"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch style="margin-right: 10px;" (click)="searsh();"><i nz-icon
                                                                                                               nzType="search"></i>
                </button>
            </ng-template>
        </div>

        <button nz-button nzType="default" (click)="limparFiltros();" style="margin-right: 8px">Limpar</button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('dec')"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="!statusCard ? 'fa-expand' : 'fa-compress'"></i>
        </button>

    </div>
</ng-template>

<ng-template #botoesQuebra>
    <div class="d-flex align-items-center">
        <button nz-button nzType="default" (click)="cardQuebraSequencia(false)">Notas</button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nota</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="notaDe" placeholder="De:"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="notaAte" placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Modelo</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="modelo">
                        <nz-option [nzLabel]="'NFe (55)'" [nzValue]="'55'"></nz-option>
                        <nz-option [nzLabel]="'CTe (57)'" [nzValue]="'57'"></nz-option>
                        <nz-option [nzLabel]="'CFE (59)'" [nzValue]="'59'"></nz-option>
                        <nz-option [nzLabel]="'NFCe (65)'" [nzValue]="'65'"></nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-label [nzSpan]="3">Série</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="serie"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Emissão</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="emissaoTipo">
                        <nz-option [nzLabel]="'Próprio'" [nzValue]="'Própria'"></nz-option>
                        <nz-option [nzLabel]="'Terceiro'" [nzValue]="'Terceiro'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo XML</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="tipoDocumento">
                        <nz-option [nzLabel]="'Entrada'" [nzValue]="'E'"></nz-option>
                        <nz-option [nzLabel]="'Saída'" [nzValue]="'S'"></nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-label [nzSpan]="5">Tipo SPED</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="tipoEscrituracao">
                        <nz-option [nzLabel]="'Entrada'" [nzValue]="'E'"></nz-option>
                        <nz-option [nzLabel]="'Saída'" [nzValue]="'S'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Participante</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <input nz-input type="text" formControlName="nome"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="4">UF</nz-form-label>
                <nz-form-control [nzSpan]="5">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="uf">
                        <nz-option *ngFor="let uf of comboUFs" [nzLabel]="uf.label" [nzValue]="uf.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Situação</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option [nzLabel]="'Autorizadas'" [nzValue]="'00'"></nz-option>
                        <nz-option [nzLabel]="'Canceladas/Denegadas'" [nzValue]="'06'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">dtEmissão</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">dtEntrada</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="entradaDataDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="entradaDataAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Valor</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="valorDe" placeholder="De:"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="valorAte" placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">ICMS Valor</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="icmsValorDe" placeholder="De:"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="icmsValorAte" placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">IPI Valor</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="ipiValorDe" placeholder="De:"/>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="ipiValorAte" placeholder="Até:"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Natureza</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <input nz-input type="text" formControlName="natureza"/>

                    <!--<nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="natureza">
                        <nz-option *ngFor="let opt of comboNaturezas" [nzLabel]="opt.id"
                                   [nzValue]="opt.label"></nz-option>
                    </nz-select>-->
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Chave</nz-form-label>
                <nz-form-control [nzSpan]="19">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="chave">
                        <nz-option [nzLabel]="'Com'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Sem'" [nzValue]="'2'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                <nz-form-control [nzSpan]="5">
                    <nz-date-picker [nzFormat]="'MM/yyyy'" nzMode="month"
                                    formControlName="competencia" style="width: 100%;"
                                    nzPlaceHolder="MM/AAAA"></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="3">Status</nz-form-label>
                <nz-form-control [nzSpan]="11">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="status">
                        <nz-option *ngFor="let status of objDescricaoStatus"
                                   [nzLabel]="status.label" [nzValue]="status.value"></nz-option>
                        <!--                        <nz-option [nzLabel]="'Escrituradas Corretamente'" [nzValue]="'1'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Divergências'" [nzValue]="'2'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'XMLs Ausentes'" [nzValue]="'3'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'XMLs Fora do SPED'" [nzValue]="'4'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Escrituradas Indevidamente'" [nzValue]="'5'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'NFs somente na SEFAZ'" [nzValue]="'6'"></nz-option>-->

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Divergência</nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="tipoDivergencia">
                        <nz-option [nzLabel]="'Igual'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Maior ou Igual'" [nzValue]="'2'"></nz-option>
                        <nz-option [nzLabel]="'Menor ou Igual'" [nzValue]="'3'"></nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-label [nzSpan]="1"></nz-form-label>
                <nz-form-control [nzSpan]="9">
                    <input nz-input type="text" formControlName="valorDivergencia" placeholder="Valor:"/>
                </nz-form-control>
            </nz-form-item>
        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button [nzLoading]="loadingExportar" nz-button nzType="primary" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="modalIframe(false)" [nzBodyStyle]="{'padding' : '0'}" [(nzVisible)]="isVisible" [nzWidth]="1200"
          nzTitle="Detalhes">
    <ng-container *nzModalContent>
        <iframe [src]="iframeUrl" title="" style="width: 100%; height: 550px; border: none;"></iframe>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalIframe(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="modalNotasIgnoradas(false, true)" [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalNotasIgnoradasVisible" [nzWidth]="900"
          nzTitle="Notas Ignoradas">
    <ng-container *nzModalContent>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
        <nz-table #basicTableNotasIgnoradas
                  [nzLoading]="loadings.removerDasIgnoradas"
                  nzSize="small"
                  [nzData]="notasIgnoradas"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  [nzShowTotal]="rangeTemplate"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTableignoradas($event)">
            <thead>
            <tr>
                <th nzShowSort="false" class="text-center">Chave</th>
                <th nzShowSort="false" class="text-center">Motivo</th>
                <th nzShowSort="false" class="text-center">Usuário</th>
                <th nzShowSort="false" class="text-center">Data</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTableNotasIgnoradas.data">
                <td class="text-center">{{ item.chave || '-' }}</td>
                <td>
                    <div style="word-break: break-word; max-width: 220px; padding-left: 12px">
                        {{ item.ignorarMotivo || '-' }}
                    </div>
                </td>
                <td class="text-center">{{ item.usuario_nome || '-' }}</td>
                <td class="text-center">{{ item.ignorarData ? (item.ignorarData | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td class="text-center">
                    <button (click)="showConfirmRemoverDasIgnoradas(item.chave)"
                            [nz-tooltip]="'Remove nota das ignoradas'"
                            nz-button nzType="default">Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalNotasIgnoradas(false, true)">Fechar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showConfirmIgnorarNotas(false)"
          [(nzVisible)]="formIgnorar.modalVisible" [nzWidth]="600"
          nzTitle="Ignorar notas">
    <ng-container *nzModalContent>
        <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="true" [formGroup]="formIgnorar.formGroup"
              style="width: 100%;">
            <p>
                {{ msgModalIgnorar }}
            </p>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">Motivo</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-textarea-count [nzMaxCharacterCount]="100" placeHolder="Informe o motivo">
                        <textarea rows="4" maxlength="100" formControlName="motivo" nz-input></textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>
        </form>

    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showConfirmIgnorarNotas(false)">Cancelar</button>
        <button [nzLoading]="loadings.ignorando" nz-button nzType="link" (click)="ignorarNotas()">Gravar</button>
    </div>
</nz-modal>

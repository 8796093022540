<nz-page-header class="site-page-header" nzTitle="Configurações"
                nzSubtitle="Editar Notas Fiscais Eletrônicas"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce" [nzLoading]="loadingsConfigDFE">
                    <nz-card-meta [nzTitle]="empresa.nome ? modoDemo(empresa.nome, 'empresaCodigoNome') : '-'"
                                  [nzDescription]="empresa.cnpj ? 'CNPJ: ' + (empresa.cnpj | cpfCnpj) : '-'"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="ID">
                                <nz-list-item-meta-title>
                                    {{ empresa.empresa_id ? empresa.empresa_id : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>

                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Alterado em">
                                <nz-list-item-meta-title>
                                    {{ empresa.updated_at ? (empresa.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list *ngIf="empresa.updated_from">
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Alterado por">
                                <nz-list-item-meta-title>
                                    {{ empresa.updated_from ? empresa.updated_from : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#configuracoesDFE" nzTitle="Configurações DFE"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card nzTitle="Configurações DFE" [nzExtra]="extraConfigura" [nzLoading]="loadingsConfigDFE">
                <ng-template #extraConfigura>
                    <button nz-button nzType="primary"
                            (click)="showModal(formConfiguracoesDFE)">
                        Alterar
                    </button>
                </ng-template>
                <nz-list id="configuracoesDFE">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Ativa DFE">
                            <nz-list-item-meta-title>
                                {{ configuracoesDFE.ativo ? 'SIM' : 'NÃO' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Intervalo Consulta">
                            <nz-list-item-meta-title *ngIf="configuracoesDFE.horarioInicio else semIntervaloInformado">
                                {{ configuracoesDFE.horarioInicio || '-' }}
                                às
                                {{ configuracoesDFE.horarioFim || '-' }}
                            </nz-list-item-meta-title>
                            <ng-template #semIntervaloInformado>
                                <nz-list-item-meta-title>
                                    -
                                </nz-list-item-meta-title>
                            </ng-template>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formConfiguracoesDFE.modalVisible"
          [nzTitle]="'Alterar Configurações DFE'" [nzClosable]="true"
          (nzOnCancel)="showModal(formConfiguracoesDFE)" [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formConfiguracoesDFE.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Ativa DFE</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.ativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="ativo">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Intervalo Consulta DFE</nz-form-label>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.horarioInicio">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="horarioInicio"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="Até:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.horarioFim">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="horarioFim"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="Até:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(formConfiguracoesDFE)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="this.loadingsConfigDFE" (click)="confirmaAlterarDFE()">
            Alterar
        </button>
    </div>
</nz-modal>
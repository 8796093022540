import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '../../../../shared/models/profile.model';
import { ProfileService } from '../../../../core/services/profile.service';
import { AbstractList } from '@components/abstract/abstract-list';
import { ToastrService } from 'ngx-toastr';
import { TableTitles } from '@models/table-titles.model';
import { TabHandlerInterface } from '../../../../shared/interfaces/tab-handler.interface';
import { Tab } from '@models/tab.model';
import { TabService } from '@services/tab.service';
import { buildUrl, getComponentConfigByName } from '../../../../shared/components-helper';

@Component({
    selector: 'app-list-profile',
    templateUrl: './list-profile.component.html',
    styleUrls: ['./list-profile.component.scss']
})
export class ListProfileComponent extends AbstractList<Profile> implements OnInit, TabHandlerInterface {
    name = 'Listar Perfis';
    readonly registerLink = '/perfil/cadastrar';
    loading = true;
    tableTitles: TableTitles[] = [
        new TableTitles('Descrição', 'descricao'),
    ];

    constructor(
        private tabService: TabService,
        private profileService: ProfileService,
        route: ActivatedRoute,
        private toastService: ToastrService) {
        super(profileService, Profile, toastService);

    }

    ngOnInit() {
        this.profileService.list().subscribe((response) => {
            this.formatList(response);
        });
        this.loading = false;
    }

    resetSearch() {

    }

    getGeneralSearch($event: any) {

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }
}

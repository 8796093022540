import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TransmissoesDetalhesService extends AbstractService<any> {
    baseUrl = environment.apiUrl + '/check/armazenamentos/';

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    retornarSelectsObrigacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/transmissoes/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarCabecalho(obrigacao, competencia): Observable<any> {

        return this.http.get(`${environment.apiUrl}/check/transmissoes/cabecalho/${obrigacao}/${competencia}`);
    }


    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10 && mes.toString().length === 1) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    exportDocumento(id, orgao): any {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/arquivo/${id}/${orgao}`, {responseType: 'blob'});
    }

    retornaselectsResponsaveis(competencia): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/aprovacoes/select/usuarios/${competencia}`);
    }

    reprocessarRegistro(arrayData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/check/transmissoes/solicitarTransmissao`, {
            data: arrayData
        });
    }

    exportarEmLote(data: any): any {

        return this.http.post(`${environment.apiUrl}/exportador`, data);

    }


    retornaAgrupamento(obrigacao: string, vencimento, id: string, ignorarPva: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/transmissoes/tabela/${obrigacao}/${vencimento}?obrigacaoEmpresa_id=${id}&ignorarPva=${ignorarPva}`);
    }

    retornaSelectsUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IconDefinition, library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-icon-list-fa',
    templateUrl: './icon-list-fa.component.html',
    styleUrls: ['./icon-list-fa.component.scss']
})
export class IconListFaComponent implements OnInit {

    @Output() selecionouIcone = new EventEmitter();

    icons: IconDefinition[] = [];
    iconesFiltrados: IconDefinition[] = [];
    iconSelected = null;
    iconSelectedName = null;
    iconSelectedType = null;

    pesquisa = null;

    constructor() {
        library.add(fas);
        library.add(far);
    }

    ngOnInit(): void {

        Object.values(far).forEach(i => {
            if (!this.icons.includes(i)) {
                this.icons.push(i);
            }
        });

        Object.values(fas).forEach(i => {
            if (!this.icons.includes(i) && i.iconName !== 'hand-middle-finger') {
                this.icons.push(i);
            }
        });

        this.iconesFiltrados = this.icons;
    }

    selectIcon(icon) {
        const tipo = icon.prefix === 'far' ? 'fa-regular' : 'fa-solid';

        this.iconSelected = `${tipo} fa-${icon.iconName}`;
        this.iconSelectedName = icon.iconName;
        this.iconSelectedType = icon.prefix;

        this.selecionouIcone.emit(this.iconSelected);
    }

    search() {
        if (this.pesquisa) {
            this.iconesFiltrados = [];
            this.icons.forEach(i => {
                if (i.iconName.includes(this.pesquisa)) {
                    this.iconesFiltrados.push(i);
                }
            });
        } else {
            this.iconesFiltrados = this.icons;
        }
    }

    clear() {
        this.pesquisa = null;
        this.search();
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class GuiasLotesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaAnos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/anos`);
    }

    remover(id: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/integracoes/guias-lotes/detalhe/${id}`);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasMatrizes/select`);
    }

    retornarSelectsEmpresasFiltros(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasGuiasLotes/select`);
    }

    retornarSelectsLayouts(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/integracoes/guias-lotes/filtros/layouts`);
    }

    exportArquivo(id: string): any {


        return this.http.get(`${environment.apiUrl}/integracoes/guias-lotes/download/${id}`,
            {responseType: 'blob'});

    }

    exportDocumento(name): any {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/download/${name}`,
            {responseType: 'blob'});
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    formataDateBDFirstDay(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-01';
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    gerarLote(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/integracoes/guias-lotes`, data);
    }

    retornaGuia(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/integracoes/guias-lotes/detalhe/${id}`);
    }

}

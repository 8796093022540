export default class Equipe {
    id: string;
    nome: string;

    constructor(obj?: object) {
        if (obj) {
            Object.entries(obj).forEach(([key, value]) => this[key] = value);
        }
    }

}

export class Modulo {
    id:string;
    nome: string;
    status: string;
    franquia: string;
    unidade: string;
    data: string;

    constructor(obj?) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
        }
    }
}

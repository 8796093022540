import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Profile } from '../../shared/models/profile.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends AbstractService<Profile> {

    private basePath = `${ environment.apiUrl }/admin/perfil`;

    constructor(http: HttpClient) {
        super(http, '/admin/perfil', Profile);
    }

    listToSelect(): Observable<Profile[]>{
        return this.http.get<Profile[]>(`${ this.basePath }?pagination=false`);
    }
}

<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Calendar">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 12)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 12)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{collapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="true"
                    [scrollbar]="false"
                    [navigation]="true"
            >
                <ng-template *ngFor="let item of arrayMeses; index as i"
                             swiperSlide
                             class="swiper-card-competencia"
                >
                    <div class="card-competencia ant-card pull-up card-{{i}}"
                         [ngClass]="collapseCard && 'compress'">
                        <div class="ant-card-head">
                            <h2>{{item.mes | uppercase}}</h2>
                            <h3>{{item.ano}}</h3>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.unidades}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{item.obrigacoes}}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pendentes</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #ff9149 !important;">
                                                <strong>
                                                    {{item.totalPendentes}}
                                                </strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Atrasadas</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #ff4961 !important;">
                                                <strong>{{item.totalAtrasadas}}</strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Concluídas</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; color: #28d094 !important;">
                                                <strong>{{item.totalConcluidas}}</strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Concluídas com Atraso</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;">
                                                <strong>
                                                    {{item.totalConcluidasAtraso}}
                                                </strong>
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="7">
            <nz-card class="ant-card-table" nzTitle="Obrigações Concluídas">
                <div nz-row style="height: 14.6em;">
                    <div nz-col [nzSpan]="13" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsGauge"
                                [options]="chartGaugeOptions"
                                [(update)]="updateFlag"
                                style="width: 100%; height: 180px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div nz-col [nzSpan]="11">
                        <ul nz-list nzBordered nzSize="small" style="margin: 10px;">
                            <nz-list-item>
                                <a nz-button nzType="link" style="padding-left: 0 !important;">
                                    Minhas
                                </a>
                                <p class="mb0">23/100</p>
                            </nz-list-item>
                            <nz-list-item>
                                <a nz-button nzType="link" style="padding-left: 0 !important;">
                                    Equipe
                                </a>
                                <p class="mb0">57/200</p>
                            </nz-list-item>
                            <nz-list-item>
                                <a nz-button nzType="link" style="padding-left: 0 !important;">
                                    Geral
                                </a>
                                <p class="mb0">
                                    68/300
                                </p>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="7">
            <nz-card class="ant-card-table" nzTitle="Status">
                <div nz-row style="height: 14.6em;">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                [(update)]="updateFlag"
                                style="width: 100%; height: 180px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <ul nz-list nzBordered nzSize="small" style="margin: 10px;">
                            <nz-list-item>
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #fdd835;"></i> Pendentes
                                </h5>
                                <p class="mb0">233</p>
                            </nz-list-item>
                            <nz-list-item>
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #ffc107;"></i> Atrasadas
                                </h5>
                                <p class="mb0">574</p>
                            </nz-list-item>
                            <nz-list-item>
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #2196f3;"></i> Concluídas
                                </h5>
                                <p class="mb0">151</p>
                            </nz-list-item>
                            <nz-list-item>
                                <h5 class="custom-card-value mb0">
                                    <i class="fas fa-circle" style="color: #9c27b0;"></i> Concluídas com Atraso
                                </h5>
                                <p class="mb0">147</p>
                            </nz-list-item>
                        </ul>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="5">
            <nz-card nzTitle="Confirmações">
                <div nz-card-grid [ngStyle]="{width: '50%', textAlign: 'center', 'padding': '15px'}">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-minus" style="font-size: 24px;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">233</a>
                                </div>
                                <h5>Não Concluídas</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid [ngStyle]="{width: '50%', textAlign: 'center', 'padding': '15px'}">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-times" style="font-size: 24px; color: #f44336;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">242</a>
                                </div>
                                <h5 style="line-height: 3.1;">Rejeitadas</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid [ngStyle]="{width: '50%', textAlign: 'center', 'padding': '15px'}">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-sync" style="font-size: 24px; color: #666ee8;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">242</a>
                                </div>
                                <h5 style="line-height: 3.1;">Verificando</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
                <div nz-card-grid [ngStyle]="{width: '50%', textAlign: 'center', 'padding': '15px'}">
                    <nz-content>
                        <nz-row>
                            <nz-col [nzSpan]="12" class="d-flex align-items-center">
                                <i class="fas fa-check"
                                   style="font-size: 24px; color: #28d094 !important;"></i>
                            </nz-col>
                            <nz-col [nzSpan]="12" class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;">
                                <div>
                                    <a nz-button nzType="link">242</a>
                                </div>
                                <h5 style="line-height: 3.1;">Confirmadas</h5>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="5">
            <nz-card nzTitle="Eficiência" class="ant-card-table">
                <nz-content>
                    <nz-row>
                        <nz-col [nzSpan]="24">
                            <highcharts-chart
                                    [Highcharts]="HighchartsSolidGauge"
                                    [options]="chartSolidGaugeOptions"
                                    [(update)]="updateFlag"
                                    style="width: 100%; height: 100px; display: block;"
                            ></highcharts-chart>
                        </nz-col>
                    </nz-row>
                    <nz-row>
                        <nz-col [nzSpan]="24">
                            <highcharts-chart
                                    [Highcharts]="HighchartsColumn"
                                    [options]="chartColumnOptions"
                                    [(update)]="updateFlag"
                                    style="width: 100%; height: 100px; display: block;"
                            ></highcharts-chart>
                        </nz-col>
                    </nz-row>
                </nz-content>
            </nz-card>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="7">
            <nz-card class="ant-card-table" nzTitle="Obrigações" [nzLoading]="cardObrigacoesLoading">
                <nz-table #tableObrigacoes [nzData]="arrayTableObrigacoes"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th style="text-align: center;">Obrigação</th>
                        <th style="text-align: center;">Unidades</th>
                        <th style="text-align: center;" nzWidth="100px">Qtde</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableObrigacoes.data" style="white-space: nowrap;">
                        <td>
                            <a nz-button nzType="link">{{item.nome}}</a>
                        </td>
                        <td style="text-align: center;">{{item.unidades}}</td>
                        <td style="text-align: right;">
                            <div>
                                <nz-progress [nzPercent]="item.qtde"></nz-progress>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card class="ant-card-table" nzTitle="Responsáveis - TOP 20" [nzLoading]="cardObrigacoesLoading">
                <nz-table #tableResponsaveisTop20 [nzData]="arrayTableResponsaveisTop20"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;" nzWidth="80px">Unidades</th>
                        <th style="text-align: center;" nzWidth="80px">Qtde</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableResponsaveisTop20.data" style="white-space: nowrap;">
                        <td>
                            <i *ngIf="item.tipo === 'GR' || item.tipo === 'GR'" class="fas fa-users"></i>
                            <i *ngIf="item.tipo === 'IN'" class="fas fa-user"></i>
                            <a nz-button nzType="link">{{item.nome}}</a>
                        </td>
                        <td style="text-align: center;">
                            <div>
                                <nz-progress [nzPercent]="item.unidades"></nz-progress>
                            </div>
                        </td>
                        <td style="text-align: right;">
                            <div>
                                <nz-progress [nzPercent]="item.qtde"></nz-progress>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="17">
            <nz-card class="ant-card-table" nzTitle="Destaques - TOP 50" [nzLoading]="cardObrigacoesLoading">
                <nz-table #tableObrigacoesDestaqueTop50
                          [nzData]="arrayTableDestaquesTop50"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th style="text-align: center;">Obrigação</th>
                        <th style="text-align: center;">Competência</th>
                        <th style="text-align: center;">Vencimento</th>
                        <th style="text-align: center;">Unidade</th>
                        <th style="text-align: center;">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableObrigacoesDestaqueTop50.data"
                        style="white-space: nowrap;">
                        <td style="">
                            <a nz-button nzType="link">{{item.nome}}</a>
                        </td>
                        <td style="text-align: center;">{{item.competencia}}</td>
                        <td style="text-align: center;">{{item.vencimento}}</td>
                        <td style="text-align: center;">{{item.unidade}}</td>
                        <td>
                            <i class="fas fa-circle"
                               [ngStyle]="{'color': item.cor, 'margin-right': '5px'}"></i>
                            <span>{{item.status}}</span>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <button nz-button [nzType]="'primary'"
                style="margin-right: 10px;">
            <i nz-icon nzType="search"></i>
            <span>Filtros</span>
        </button>
        <button nz-button [nzType]="'primary'"
                style="margin-right: 10px;">
            <span>Exportar</span>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<ng-template #labelAno>
    <div class="d-flex align-items-center">
        <h3 style="color: rgba(0,0,0,.45);">{{anoAtivo}}</h3>
    </div>
</ng-template>

<div class="notify">
    <nz-badge [nzCount]="badge" style="margin-right: 30px; border: none!important;">
        <a nz-button nzType="link" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu"
           [nzDisabled]="!staticConnected" disabled="{{!staticConnected}}"
           nzPlacement="bottomRight"
           style="padding-right: 0px!important;"
           [nzClickHide]="true">
            <i class="far fa-bell" style="font-size: 22px;" [ngStyle]="staticConnected && {color: '#fff'}"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <div class="seta-para-cima seta-notify"></div>
            <div class="notify-container"
                 style="margin-top: -3px; margin-right: -5px; border: none; box-shadow: 0 0 5px 0.5px #e1e1e1">
                <div class="notify-header"></div>
                <div class="notify-scrollbar">
                    <ul nz-menu>
                        <ng-container *ngIf="staticArrayNotify.length > 0">
                            <li nz-menu-item *ngFor="let notify of staticArrayNotify;"
                                class="caixa-notificacao">
                                <div style="padding-right: 7px; width: 100%; min-width: 450px">
                                    <div style="padding-left: 7px;">
                                        <h5 nz-typography [innerHTML]="notify.titulo" style="margin-bottom: 0;"></h5>
                                        <p [innerHTML]="notify.mensagem"
                                           style="max-width: 450px; white-space: normal; margin-bottom: 7px; color: rgba(0,0,0,0.8);"></p>
                                        <ng-container *ngFor="let widget of notify.widget">
                                            <nz-progress *ngIf="widget.tipo === 'progress_bar'"
                                                         [nzPercent]="widget.progressoPorc"
                                                         nz-tooltip="true"
                                                         nzTooltipTitle="{{widget.mensagem}}"
                                                         [nzFormat]="formatOne"></nz-progress>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex">
                                        <div>
                                            <ng-container *ngFor="let action of notify.actions;">
                                                <button nz-button nzType="link"
                                                        nzSize="small"
                                                        *ngIf="action.tipo === 'link'"
                                                        (click)="openTab(action.url, null, null);"
                                                        style="font-size: 12px;">
                                                    {{ action['titulo'] }}
                                                </button>
                                            </ng-container>
                                        </div>
                                        <h5 style="color: rgba(0,0,0,.45); margin-bottom: 0; margin-left: 7px; display: inline-block;">
                                            {{ notify.created_at | date: 'dd/MM/yyyy' }}
                                            -
                                            <span *ngIf="notify.diasNotificacao > 0">há {{ notify.diasNotificacao }}
                                                dia(s)</span>
                                            <span *ngIf="notify.diasNotificacao === 0">Hoje</span>
                                        </h5>
                                    </div>
                                </div>
                                <div>
                                    <button nz-button nzType="link" (click)="notifyDimiss(notify.id)" nzSize="small">
                                        <i class="fa-regular fa-trash-can" [nz-tooltip]="'Remover'"></i>
                                    </button>
                                </div>
                            </li>
                        </ng-container>
                        <li nz-menu-item *ngIf="staticArrayNotify.length === 0">
                            <div style="padding: 0 15px;">
                                <span>Você não tem notificações no momento</span>
                                <h5 style="color: rgba(0,0,0,.45);">
                                    Última Atualização: {{ staticUltimaAtualizacao | date: 'dd/MM/yyyy h:mm:ss' }}
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nz-dropdown-menu>
    </nz-badge>
</div>

<ng-template #badge>
    <span *ngIf="staticArrayNotify.length" class="badge-manual">
        {{ staticConnected ? staticArrayNotify.length : null }}
    </span>
</ng-template>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class CheckNotasDetalhesService extends AbstractService<any> {

    id = '';
    url = environment.apiUrl;

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaCabecalho(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}`);
    }

    retornaContadores(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}/contadores`);
    }

    montaUrl() {
        this.url = environment.apiUrl + '/compliance/notas/analise/' + this.id + '/detalhe';
    }

    exportExcel(id: string, filtros: any, tipo: string): any {

        filtros.push({
            key: 'tipoExportacao',
            value: tipo
        });

        let queryStr = '?';

        filtros.forEach(f => {
            queryStr === '?' ? queryStr += `${f.key}=${f.value}` : queryStr += `&${f.key}=${f.value}`;
        });

        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}/detalhe/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    retornaQuebras(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}/quebras`);
    }

    formataCompetencia(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1);
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaEstados(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);
    }

    abrirPdf(chave): Observable<any> {
        return this.http.get(`${environment.apiUrl}/notas/downloadDanfe/${chave}`, {responseType: 'blob'});
    }

    ignorarNotas(data: { chaves: string[], motivo: string }): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/ignorar-notas`, data);
    }

    getNotasIgnoradas(id, params): Observable<any> {


        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }


        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }
            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';


        return this.http.get(`${environment.apiUrl}/compliance/notas/analise/${id}/ignoradas${queryString}`);
    }

    removerDasIgnoradas(data: { chaves: string[] }): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/designorar-notas`, data);
    }

}

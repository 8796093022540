import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TabService} from '@services/tab.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private toastr: ToastrService,
                private router: Router, private tabservice: TabService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // let error = {code: ''};

        return next.handle(request).pipe(catchError(response => {

            const splitUrl = window.location.href.split('.');

            // response.status = 401;

            /*if (response.error.message === 'A token is required') {
                this.router.navigate(['/login']);
            }*/

            if (response.status === 401 ||
                response.error.status === 'Token expirou' ||
                response.error.message === 'Token has expired') {

                this.authenticationService.closeSession(null);
                this.router.navigate(['/login']);
            }

            if (response.status === 500 && response.error.status !== 'Token expirou' ||
                response.status === 500 && response.error.message !== 'Token has expired') {

                if (splitUrl[1] === 'dev') {

                    this.toastr.error(response.error.message);

                } else {

                    this.toastr.error('Problemas ao obter dados do servidor!');

                }

                return [];

            }


            // if (response.status === 422 && splitUrl[1] === 'dev') {
            //
            //     this.toastr.error(response.error.message);
            //
            // }

            /*else if (response.status !== 422 && response.status !== 404) {

                        error.code = response.status;
                        this.tabservice.addTab(new Tab('ErrorComponent', 'Error ' + error.code,
                            '/error', UrlTypeEnum.nativo, { code: `${ error.code }` }));

             }*/

            if (response.error.message !== 'Token has expired' && response.error.message !== 'Senha inválida') {

                this.handleErrors(response.error.errors);

            }

            return throwError(response);

        }));

    }

    handleErrors(errors: any): void {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.toastr.error(errors[key]);
            }
        }
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef, HostListener,
    LOCALE_ID, NgZone,
    OnInit, Renderer2,
    ViewChild
} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ObrigacoesFederaisService} from './obrigacoesFederais.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {StickyHeaderDirective} from '@components/directive/directive';
import {Helpers} from '../../../core/helpers';
import {Subscription} from 'rxjs';

HighchartsMore(Highcharts);

interface ExtendedChart extends Highcharts.PlotPackedbubbleOptions {
    layoutAlgorithm: {
        splitSeries: any;
    };
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-drive-obrigacoes-federais',
    templateUrl: './obrigacoesFederais.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./obrigacoesFederais.component.scss']
})

export class ObrigacoesFederaisComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    pagination: Pagination;
    paginationHistorico: Pagination;
    cardCompetenciaAtivo: number;
    formFiltrarTotais: FormStack;
    formFiltrarObrigacoes: FormStack;
    formExportar: FormStack;
    anoSelecionado: number = null;
    arrayAnos = [];
    arrayTableTotais = [];
    status = false;
    cardTabsLoading = false;
    tabNumber = 0;
    collapseCard = false;
    arraySelectCnpj = [];
    qtdFiltrosAtivos = 0;
    arrayCabecalho = [];
    arrayChart = [];
    serverSize = 0;
    obrigacoesSize = 0;
    obrigacoesEstaduaisPercentage = 0;
    obrigacoesFederaisPercentage = 0;
    updateFlag = false;
    totalOficiais = 0;
    totalNaoOficiais = 0;
    totalPrincipais = 0;
    loadingCardCabecalho = true;
    comboObrigacoes = [];
    arraySelectEmpresa = [];
    arraySelectGrupoEmpresarial = [];
    arraySelectcategoria = [];

    showPainel = false;

    expandidos: any = {};

    exibirTudo = false;

    openModal = 0;

    @ViewChild('cardHeight') cardHeight: any;
    @ViewChild('cardHeightTarget') cardHeightTarget: any;

    private cardTabsTwo;

    @ViewChild('cardTabsTwo') set contentCard(content: ElementRef) {
        if (content) {
            this.cardTabsTwo = content;
        }
    }

    offsetTop = 15;
    target: any = StickyHeaderDirective.target;

    loadings = {
        xlsx: false,
        pdf: false,
        txt: false,
        downloadArquivo: [],
        tipos: false
    };

    tiposParaBaixar: any = {};

    modalHistoricoVisible = false;

    arrayTableHistorico = [];
    loadingHistorico = null;
    currentParamsHistorico: NzTableQueryParams;
    dataHistoricoSelecionada = null;

    requestH: Subscription;

    constructor(
        private obrigacoesFederaisService: ObrigacoesFederaisService,
        private fb: UntypedFormBuilder,
        private i18n: NzI18nService,
        private toastService: ToastrService,
        private renderer: Renderer2,
        private tabService: TabService,
        private zone: NgZone
    ) {

        super(obrigacoesFederaisService, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formFiltrarTotais = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                obrigacao_id: [null, null],
                categoria: [null, null]
            })
        };

        this.formFiltrarObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                tipo: [null, null],
                obrigacao_descricao: [null, null],
                obrigacao_id: [null, null],
                arquivoTamanho: [null, null],
                armazenamentoData: [null, null],
                arquivoHash: [null, null],
                reciboNumero: [null, null],
                reciboData: [null, null],
                tipoTransmissao: [null, null],
                arquivoNome: [null, null],
                grupoEmpresarial: [null, null],
                categoria: [null, null]
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, null]
            })
        };

    }

    clickEvent() {
        this.status = !this.status;
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    ngOnInit(): void {

        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.currentParamsHistorico = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.paginationHistorico = new Pagination();

        this.currentSearch = '';

        this.retornaSelectsEmpresas();

        this.retornaSelectsGruposEmp();

        this.retornaSelectsCategorias();

        this.obrigacoesFederaisService.getTamanhoBucket().subscribe({
            next: (res) => {
                this.serverSize = res.tamanho;
            },
            error: (err) => {
                this.toastService.error(err.error.message);
            }
        });
    }

    ngAfterViewInit() {
        // this.target = StickyHeaderDirective.target;

        this.retornaAnos();

        this.retornaComboObrigacoes();

    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeCard();
    }

    retornaSelectsCategorias() {
        this.obrigacoesFederaisService.retornarSelectsCategorias().subscribe((retorno: any) => {
            this.arraySelectcategoria = retorno;
        });
    }

    retornaSelectsGruposEmp() {

        this.obrigacoesFederaisService.retornarSelectsGrupoEmpresarial().subscribe((retorno: any) => {
            this.arraySelectGrupoEmpresarial = retorno.grupoEmpresarial;
        });
    }

    filtrarObrigacoesCard(id) {

        this.retornaTiposParaBaixar(id);

        if (id === this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').value) {
            this.showPainel = false;
            this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(null);

        } else {
            this.showPainel = true;
            this.scrollContainer(120);

            this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(id);

            this.tabNumber = 1;

            this.filtrar();

            setTimeout(() => {
                this.scrollContainer(120);
            }, 500);

        }

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTabsTwo.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 800);

    }

    retornaSelectsEmpresas(grupoEmpId?: string) {

        this.obrigacoesFederaisService.retornarSelectsEmpresas(grupoEmpId).subscribe((retorno: any) => {

            const empresaId = this.formFiltrarObrigacoes.formGroup.value.empresa_id;

            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;

            let reset = true;

            this.arraySelectEmpresa.map(empN => {
                if (empN.id === empresaId) {
                    reset = false;
                }
            });

            this.arraySelectCnpj.map(empC => {
                if (empC.id === empresaId) {
                    reset = false;
                }
            });

            if (reset) {
                this.formFiltrarObrigacoes.formGroup.get('empresa_id').setValue(null);
            }

        });
    }

    expandirRecolherTodos() {
        this.exibirTudo = !this.exibirTudo;

        this.arrayCabecalho.forEach(i => {
            this.expandidos[i.categoria] = this.exibirTudo;
        });
    }

    retornaSelectsEmpresasEObrigacoes(grupoEmpId: string) {
        this.retornaSelectsEmpresas(grupoEmpId);
        this.retornaComboObrigacoes(null, null, null);
    }

    retornaComboObrigacoes(empresaId?: string, grupoEmp?: string, categoria?: string) {

        if (this.openModal > 0) {
            this.openModal--;
        } else {

            if (this.tabNumber === 0) {
                grupoEmp = this.formFiltrarTotais.formGroup.value.empresa_id;
                categoria = this.formFiltrarTotais.formGroup.value.categoria;
            } else {
                grupoEmp = this.formFiltrarObrigacoes.formGroup.value.grupoEmpresarial;
                empresaId = this.formFiltrarObrigacoes.formGroup.value.empresa_id;
                categoria = this.formFiltrarObrigacoes.formGroup.value.categoria;
            }

            const obrigacaoId = this.formFiltrarObrigacoes.formGroup.value.obrigacao_id;

            this.obrigacoesFederaisService.retornaComboObrigacoes(empresaId, grupoEmp, categoria).subscribe((response) => {

                this.comboObrigacoes = [];

                response.obrigacoes.forEach((value) => {
                    const dados = {id: value.id, label: value.descricao};
                    this.comboObrigacoes.push(dados);
                });

                let reset = true;

                this.comboObrigacoes.map(ob => {
                    if (ob.id === obrigacaoId) {
                        reset = false;
                    }
                });

                if (reset) {
                    this.formFiltrarObrigacoes.formGroup.get('obrigacao_id').setValue(null);
                }

            });

        }

    }


    selecionaCardCompetencia(event: any) {
        this.showPainel = false;

        this.limparFiltros();

        if (event.clickedIndex !== undefined) {

            this.cardCompetenciaAtivo = event.clickedIndex;

            this.anoSelecionado = this.arrayAnos[event.clickedIndex].ano;

            this.tabNumber = 0;

            this.retornaCabecalho(this.anoSelecionado);

            this.filtrar();

        }

    }

    retornaTiposParaBaixar(id: string) {

        this.loadings.tipos = true;

        this.obrigacoesFederaisService.retornaTiposParaBaixar(this.anoSelecionado, id).subscribe((retorno: any) => {
            this.tiposParaBaixar = retorno;

            this.loadings.tipos = false;

        }, err => {
            this.toastService.error(err.error.message);
            this.loadings.tipos = false;
        });
    }

    retornaAnos(): void {

        this.obrigacoesFederaisService.retornaAnos().subscribe((retorno: any) => {

            this.arrayAnos = retorno;

            this.anoSelecionado = this.arrayAnos[this.arrayAnos.length - 1].ano;


            this.retornaCabecalho(this.anoSelecionado);

            setTimeout(() => {

                this.cardCompetenciaAtivo = this.arrayAnos.length - 1;

                setTimeout(() => {
                    this.collapseCard = true;
                }, 500);

            }, 1000);

        });

    }

    retornaObrigacoes(params: NzTableQueryParams, search: string = null) {

        this.calculaBadgeFiltros();

        this.cardTabsLoading = true;

        let url = '';

        switch (this.tabNumber) {

            case 0 :
                url = `${environment.apiUrl}/drive/obrigacoes/federais/empresas/${this.anoSelecionado}`;
                break;

            case 1 :
                url = `${environment.apiUrl}/drive/obrigacoes/federais/armazenamentos/${this.anoSelecionado}`;
                break;

        }

        params.filter.push({key: 'tipoObrigacao', value: 'A'});

        params.filter.forEach((value) => {

            if (value.key === 'competencia' && value.value) {
                value.value = Helpers.formatDateDb(value.value).substr(0, 7);
            }

        });

        if (this.showPainel) {

            params.filter.push({key: 'emUso', value: true});

            this.abstractService.listToTable(params, search, url).subscribe((response) => {

                this.arrayTableTotais = response.data;

                this.totalOficiais = 0;
                this.totalNaoOficiais = 0;
                this.totalPrincipais = 0;

                this.arrayTableTotais.forEach((value) => {

                    switch (this.tabNumber) {

                        case 0 :
                            this.totalOficiais = this.totalOficiais + Number(value.qtdOficiais);
                            this.totalNaoOficiais = this.totalNaoOficiais + Number(value.qtdNaoOficiais);
                            this.totalPrincipais = this.totalPrincipais + Number(value.qtdPrincipais);

                            break;

                        case 1 :
                            value.reciboNumeroElipses = value.reciboNumero && value.reciboNumero.length >= 11 ?
                                value.reciboNumero.substring(0, 10) + '...' :
                                value.reciboNumero;

                            break;

                    }

                });

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.cardTabsLoading = false;

                // this.cdr.detectChanges();

            });
        }

    }

    calculaBadgeFiltros(): void {

        let form = null;

        if (this.tabNumber === 0) {
            form = this.formFiltrarTotais;
        }

        if (this.tabNumber === 1) {
            form = this.formFiltrarObrigacoes;
        }

        this.qtdFiltrosAtivos = 0;

        if (typeof form !== 'undefined') {

            for (const [chave, valor] of Object.entries(form.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack, filtrar = false): void {
        if (filtrar) {
            this.openModal = 4;
        }
        formulario.modalVisible = true;
    }

    filtrar(): any {

        let filtros;

        this.currentParams.filter = [];

        if (this.tabNumber === 0) {

            filtros = this.formFiltrarTotais.formGroup.value;

            this.formFiltrarTotais.modalVisible = false;

        }

        if (this.tabNumber === 1) {

            filtros = this.formFiltrarObrigacoes.formGroup.value;

            this.formFiltrarObrigacoes.modalVisible = false;

        }

        for (const [chave, valor] of Object.entries(filtros)) {

            this.currentParams.filter.push({key: chave, value: valor});

        }

        this.retornaObrigacoes(this.currentParams, this.currentSearch);

        this.currentParams.pageIndex = 1;
    }

    exportDocumento(id, arquivoNome, tipoArquivo) {

        this.loadings.downloadArquivo.push(arquivoNome);

        this.obrigacoesFederaisService.exportDocumento(id, tipoArquivo).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome);

            this.loadings.downloadArquivo = [];

        }, error => {
            this.toastService.error(error.error.message ?? 'Falha ao obter arquivo.');
            this.loadings.downloadArquivo = [];
        });
    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.arrayAnos.length - 1) {
                    this.cardCompetenciaAtivo = this.arrayAnos.length - 1;
                }

                break;

        }

        this.anoSelecionado = this.arrayAnos[this.cardCompetenciaAtivo].ano;

        this.tabNumber = 0;

        this.filtrar();

    }

    retornaCabecalho(ano) {

        this.loadingCardCabecalho = true;
        this.zone.run(() => {

            this.obrigacoesFederaisService.retornaCabecalho(ano).subscribe((retorno: any) => {

                retorno.dados.forEach(categoria => {
                    categoria.itens.sort((a, b) => {
                        if (a.qtdTotal < b.qtdTotal) {
                            return 1;
                        }
                        if (a.qtdTotal > b.qtdTotal) {
                            return -1;
                        }
                        return 0;
                    });
                });

                this.arrayCabecalho = retorno.dados;

                this.arrayChart = retorno.dadosTotais;

                this.obrigacoesSize = retorno.tamanhoTotalFederal;

                this.obrigacoesEstaduaisPercentage = parseFloat(
                    this.getPercentage(retorno.tamanhoTotalFederal + retorno.tamanhoTotalFederal, this.serverSize).toFixed(6)
                );

                this.obrigacoesFederaisPercentage = parseFloat(
                    this.getPercentage(retorno.tamanhoTotalFederal, this.serverSize).toFixed(6)
                );

                this.loadingCardCabecalho = false;

                this.arrayCabecalho.forEach(i => {
                    this.expandidos[i.categoria] = false;
                });

                setTimeout(() => {

                    this.atualizaGrafico(retorno.dadosTotais);

                }, 2000);

            });
        });

    }

    atualizaGrafico(array) {

        const series = array.map(value => {
            return {
                name: value.obrigacao_id,
                value: value.tamanho
            };
        });

        const serverSize = this.serverSize.toString().replace(/\d(?=(\d{3})+\.)/g, '$&.');

        if (Highcharts) {
            Highcharts.chart({
                chart: {
                    renderTo: 'containerFederais',
                    type: 'packedbubble',
                    height: '60%',
                },
                yAxis: {
                    startOnTick: false,
                    minPadding: 0
                },
                title: {
                    text: undefined
                },
                tooltip: {
                    useHTML: true,
                    formatter() {

                        let tooltip =
                            `<div>` +
                            `<b>${this.series.name}</b>` +
                            `</div>`;

                        // @ts-ignore
                        const bytes = this.point.value;
                        const decimals = 2;

                        if (bytes) {

                            if (bytes === 0) {
                                return '0 Bytes';
                            }

                            const k = 1024;
                            const dm = decimals < 0 ? 0 : decimals;
                            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                            const i = Math.floor(Math.log(bytes) / Math.log(k));

                            const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

                            tooltip += `<div>` +
                                `${this.point.name}: ` + value +
                                `</div>`;

                        }

                        return tooltip;

                    }
                },
                plotOptions: {
                    packedbubble: {
                        minSize: '10%',
                        maxSize: '42%',
                        zMin: 0,
                        zMax: 1000,
                        layoutAlgorithm: {
                            gravitationalConstant: 0.05,
                            splitSeries: true,
                            seriesInteraction: false,
                            dragBetweenSeries: true,
                            parentNodeLimit: true
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal'
                            }
                        }
                    } as ExtendedChart
                },
                series: [
                    {
                        type: 'packedbubble',
                        name: 'Servidor ' + serverSize,
                        data: series
                    }
                ]
            });
        }

        this.updateFlag = true;

        this.resizeCard();

    }

    resizeCard() {

        if (this.cardHeight?.elementRef) {

            setTimeout(() => {

                const height = `${this.cardHeight.elementRef.nativeElement.lastElementChild.offsetHeight}px`;
                this.renderer.setStyle(this.cardHeightTarget.elementRef.nativeElement.lastElementChild, 'height', height);

            }, 300);

        }

    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    formatBytes(bytes, decimals = 2) {

        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    }

    public cardCompress(target: HTMLElement): void {

        let barElement: HTMLElement | null = target;

        while (barElement && !barElement.classList.contains('ant-card')) {

            barElement = barElement.parentElement;
        }

        const iconPlus = barElement.querySelector('.anticon-plus');
        const iconMinus = barElement.querySelector('.anticon-minus');

        barElement = barElement.querySelector('.ant-card-body');

        if (barElement.classList.contains('compress')) {

            iconPlus.classList.remove('d-inline-block');
            iconPlus.classList.add('d-none');

            iconMinus.classList.remove('d-none');
            iconMinus.classList.add('d-inline-block');

            barElement.classList.remove('compress');

        } else {

            iconMinus.classList.remove('d-inline-block');
            iconMinus.classList.add('d-none');

            iconPlus.classList.remove('d-none');
            iconPlus.classList.add('d-inline-block');

            barElement.classList.add('compress');


        }

    }

    changeSearch(event) {
        this.currentSearch = event;
    }

    limparFiltros() {

        this.checkedItems.clear();
        this.checked = false;

        if (this.tabNumber === 0) {
            this.formFiltrarTotais.formGroup.reset();
        }

        if (this.tabNumber === 1) {
            this.formFiltrarObrigacoes.formGroup.reset();
        }

        this.currentSearch = null;

        this.filtrar();

    }

    filtrarObrigacoes(tipo, id) {

        this.formFiltrarObrigacoes.formGroup.get('tipo').setValue(tipo);

        this.formFiltrarObrigacoes.formGroup.get('empresa_id').setValue(id);

        this.tabNumber = 1;

        this.filtrar();

    }

    changeTabs(event) {
        this.tabNumber = event.index;
    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.currentSearch = search;

        this.filtrar();

        this.resetCheckedSet();

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    confirmaExportar(tipo: string): void {

        this.loadings[tipo] = true;

        this.obrigacoesFederaisService.solicitarExportar(this.currentParams.filter, tipo, this.anoSelecionado).subscribe((res) => {

            this.loadings[tipo] = false;

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.formExportar.modalVisible = false;

            this.formExportar.formGroup.reset();

        });

    }

    showModalHistorico(visible: boolean) {
        this.modalHistoricoVisible = visible;
    }

    getHistorico(params: NzTableQueryParams, data = null) {

        this.dataHistoricoSelecionada = data;

        this.loadingHistorico = data?.id || null;

        params.filter.push({key: 'obrigacao_id', value: data.obrigacao_id});
        params.filter.push({key: 'cnpj', value: data.cnpj});
        params.filter.push({key: 'tipo', value: data.tipo});
        params.filter.push({key: 'competencia', value: data.competencia});

        if (this.requestH) {
            this.requestH?.unsubscribe();
        }

        this.requestH = this.obrigacoesFederaisService.getHistorico(params).subscribe({
            next: (response) => {

                this.arrayTableHistorico = response.data;

                this.paginationHistorico = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loadingHistorico = null;

                this.showModalHistorico(true);
            },
            error: (err) => {

                this.loadingHistorico = null;
            }
        });
    }

    listByTableHistorico(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationHistorico.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationHistorico.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationHistorico.current_page) {
            this.getHistorico(params, this.dataHistoricoSelecionada);
            this.paginationHistorico.current_page = params.pageIndex;
        }

    }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class ObrigacoesEmpresasService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacoes', Obrigacao);
    }

    retornaObrigacoesEmpresas(filtros: any = null): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/sumario/obrigacao${queryStr}`);

    }

    atualizarInfo() {
        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/sumarizar/job`, {});
    }

    retornaEmpresasObrigacoesOficiais(filtros = null, busca = null, url: string): Observable<any> {

        let queryStr = '?';

        const currentSort = filtros?.sort?.find(item => item.value !== null) || null;
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryStr += `ordenar=${sortField}&`;
            queryStr += `sentido=${sortOrder}&`;
        }

        if (filtros?.filter) {
            filtros.filter.forEach(f => {
                queryStr += f.key + '=' + f.value + '&';
            });
        }

        if (filtros?.pageIndex) {
            queryStr += 'pagina=' + filtros.pageIndex + '&';
        }

        if (busca) {
            queryStr += 'procurar=' + busca + '&';
        }

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimentos/empresa/${url}${queryStr}`);

    }

    retornaVigencias(obrigacao: string, empresaId, ano: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vigencia/${obrigacao}?empresa=${empresaId}&ano=${ano}`);
    }

    retornaContadoresOrgao(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/sumario/orgao`);
    }

    retornaAnosObrigacoes(obrigacaoId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/sumario/anos/${obrigacaoId}`);

    }

    retornaComboEmpresas(obrigacao: string = null): Observable<any> {


        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/combo/empresas/${obrigacao}`);

    }

    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornaComboEquipes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/equipes/select`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsObrigacoes(empresaId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select/${empresaId}`);
    }

    retornarSelectsCompetencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/vencimentos/competencias`);
    }

    retornaComboResponsaveis(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/responsaveis/select`);

    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';

        if (valor && typeof data.getMonth === 'function') {

            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());

        } else {

            retorno = valor.toString();

        }

        return retorno;

    }

    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    insertEmpresaVencimentos(dados: any): Observable<any> {

        dados.vencimentos.forEach((value) => {
            const dataExplode = this.formataDateBD(value.data).split('T');
            value.data = dataExplode[0];
        });

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento`, dados);

    }

    insertEmpresaCadastros(dados: any): Observable<any> {

        dados.vencimentos.forEach((value) => {
            const dataExplode = this.formataDateBD(value.data).split('T');
            value.data = dataExplode[0];
        });

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento`, dados);

    }

    removeEmpresas(ids: string[], todosAnos: boolean) {
        const dados = {
            ids,
            todosAnos
        };

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/deletar/vencimento`, dados);
    }

    adicionarEmpresasSugeridas(obrigacao: any, ano: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/empresas-sugeridas/${obrigacao}/${ano}`, null);
    }

    limparEmpresasSugeridas(): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/limpar-sugeridas`, {});
    }

    restaurarVencimentos(obrigacaoId: any, ano: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacoes-empresas/restaurar-vencimentos`, {
            obrigacaoId, ano
        });
    }

    vincularEquipesCalendar(
        selectedIds: Set<string> | string[], equipeId: string[], clearBefore = true, alterarEquipeObgPendente = false
    ): Observable<any> {

        const data: any = {
            ids: Array.from(selectedIds),
            equipe_id: equipeId
        };

        if (alterarEquipeObgPendente) {
            data.alterarEquipeObgPendente = true;
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/calendar/equipes`, data);
    }

    vincularResponsavelCalendar(selectedIds: Set<string> | string[], responsavelId: any, alterarResObgPendente = false) {

        const data: any = {
            ids: Array.from(selectedIds),
            responsavel_id: responsavelId
        };

        if (alterarResObgPendente) {
            data.alterarResObgPendente = true;
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/calendar/responsaveis`, data);

    }

    editarEmpresasVencimentosOficiais(vencimentos: any, selectedIds: Set<string> | string[]): Observable<any> {

        vencimentos.forEach((value) => {
            const dataExplode = this.formataDateBD(value.data).split('T');
            value.data = dataExplode[0];
        });

        const data = {
            ids: Array.from(selectedIds),
            vencimentos
        };

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/oficiais/vencimentos`, data);

    }

    editarEmpresasVencimentosCalendar(
        vencimentos: any, selectedIds: Set<string> | string[], alterarPendente: boolean = false): Observable<any> {

        vencimentos.forEach((value) => {
            const dataExplode = this.formataDateBD(value.data).split('T');
            value.data = dataExplode[0];
        });

        const data = {
            ids: Array.from(selectedIds),
            vencimentos,
            alterarPendente
        };

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento/calendar/vencimentos`, data);

    }

    exportarDiferenca(matriz = null, orgao: string, uf = null): any {

        if (uf) {
            return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/exportar/diferenca/${matriz}/${orgao}/${uf}`,
                {responseType: 'blob'});

        } else {
            if (matriz) {
                return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/exportar/diferenca/${matriz}/${orgao}`,
                    {responseType: 'blob'});
            } else {
                return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/exportar/diferenca/${matriz}/${orgao}/null/1`,
                    {responseType: 'blob'});
            }
        }

    }


    editarEmpresaVencimentos(dados: any): Observable<any> {

        dados.vencimentos.forEach((value) => {
            const dataExplode = this.formataDateBD(value.data).split('T');
            value.data = dataExplode[0];
        });

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento`, dados);

    }

    editarEmpresaCalendar(dados: any, alterarResObgPendente = false, alterarEquipeObgPendente = false): Observable<any> {

        dados.vencimentos.forEach((value) => {
            if (typeof value.data.getMonth === 'function') {
                const dataExplode = this.formataDateBD(value.data).split('T');
                value.data = dataExplode[0] !== 'NaN-NaN-NaN' ? dataExplode[0] : null;
            } else {
                value.data = null;
            }

        });

        if (alterarResObgPendente) {
            dados.alterarResObgPendente = true;
        }

        if (alterarEquipeObgPendente) {
            dados.alterarEquipeObgPendente = true;
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimento`, dados);

    }


    retornaAlerta(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/empresas-alerta`);

    }

    verificaSePossuiObgPendente(data: { obrigacaoId: string, cnpj: string[], ano: string }) {
        return this.http.post(`${environment.apiUrl}/calendar/tasks/temPendente`, data);
    }

    verLog(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/log`);

    }

    cadastrarEquipe(newEquipe: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/equipes`, {nome: newEquipe});
    }
}

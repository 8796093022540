import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '../../ui.module';
import {EquipesComponent} from './equipes.component';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';

@NgModule({
    declarations: [
        EquipesComponent
    ],
    imports: [
        CommonModule,
        NgxMaskModule,
        ReactiveFormsModule,
        UiModule,
        FormsModule,
        NzAlertModule,
        NzPageHeaderModule,
        NzBadgeModule,
        NzDatePickerModule,
        NzSkeletonModule,
        NzTagModule,
        NzCheckboxModule,
    ]
})
export class EquipesModule {
}

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalCredencialDeAcessoService {

    constructor(protected http: HttpClient) {
    }

    buscarProcuracoes(id: any): Observable<any> {

        const dados: any = {
            empresa_id: id,
            robo: 'ecac',
            tipo: 'procuracao'
        };

        return this.http.post(`${environment.apiUrl}/robo/rpa/solicitacao`, dados);
    }

    insertCertificado(empresaId: string, dados: any): Observable<any> {

        dados.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado`, dados);

    }

    updateCertificados(id: string, dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/detalhe/${id}`, dados);

    }

    downloadCertificado(certificadoId: any, senha: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/credenciais-acesso/certificado/download/${certificadoId}?senha=${senha}`,
            {responseType: 'blob'});

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {Helpers} from "../helpers";

@Injectable({providedIn: 'root'})
export class InscricoesRegimesService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/empresas/inscricoesRegimes', InscricaoRegime);
    }


    save(campos): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/empresas/inscricoesRegimes`, campos);

    }

    update(id, campos): Observable<any> {

        if (campos.bloqueado) {
            campos.bloqueado === true ? campos.bloqueado = 'SIM' : campos.bloqueado = 'NAO';
        }
        if (campos.obrigacaoCompliance) {
            campos.obrigacaoCompliance === true ? campos.obrigacaoCompliance = 'SIM' : campos.obrigacaoCompliance = 'NAO';
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao/detalhe/${id}`, campos);

    }

    remove(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/obrigacao/vencimento/${id}`);

    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    downloadModelo(tipo: string): any {

        return this.http.get(`${environment.apiUrl}/cadastros/empresas/${tipo}/exportar/modelo`,
            {responseType: 'blob'});

    }


    uploadPlanilhaRegime(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/empresa/regimeTributario/importar`, formData);
    }

    uploadPlanilhaIeiest(formData: FormData): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/empresa/inscricaoEstadual/importar`, formData);
    }

    uploadPlanilhaIm(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/cadastros/empresa/inscricaoMunicipal/importar`, formData);
    }

    atualziarRFB(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.put(`${prefix}${queryString}`, {});

    }

    atualziarDadosCadastraisRFB(documentos: any, baseUrl = null): Observable<any> {

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.put(`${prefix}`, {documentos: [...documentos]});

    }

    atualizarIEViaPortal(empresas: any, baseUrl = null): Observable<any> {

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}`, {empresas: [...empresas]});

    }

    importarEmbedded(acao, selectedIds: Set<string> | string[]): Observable<any> {
        const dados = {
            documento: Array.from(selectedIds)
        };
        return this.http.put(`${environment.apiUrl}/cadastros/empresas/inscricoesRegimes/sincronizarEmbeddedMassa/${acao}`, dados);
    }

    retornaComboGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);

    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    retornaComboMunicipios(uf: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);

    }

}

import {Component, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Obrigacao} from '@models/obrigacao.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ObrigacaoService} from '@services/obrigacao.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Helpers} from '../../../core/helpers';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-obrigacoes',
    templateUrl: './obrigacoes.component.html',
    styleUrls: ['./obrigacoes.component.scss']
})
export class CadastrosObrigacoesComponent extends AbstractListTable<Obrigacao> implements TabHandlerInterface {

    readonly registerLink = '/obrigacoes/cadastrar';

    checked = false;

    modalCadastrarVisible = false;
    modalFiltrarVisible = false;

    formDadosCadastrais: FormStack;
    formFiltrar: FormStack;

    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    radioValuePrincipalAcessoria = 'P';
    radioValuePeriodicidade = 'A';
    radioValueOrgao = 'Federal';
    apenasUnidadesMatriz = '0';
    radioValueVencimentoUtil = '1';
    radioValueVencimentoModalidade = 'Antecipa';
    radioValueVencimentoMesSubsequente = '1';

    checkedLucroPresumido = true;
    checkedLucroRealTrimestral = true;
    checkedLucroRealAnual = false;
    checkedSimplesNacional = false;
    checkedIndustria = true;
    checkedAtacado = false;
    checkedVarejo = false;
    checkedServico = false;

    comboCidades = [];

    comboUFs: any = [];
    comboMunicipio: any = [];
    comboTodosMunicipios: any = [];

    comboCategoria: any = [];

    qtdFiltrosAtivos = 5;

    excluindoObrigacao = {
        loading: false,
        id: ''
    };

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private obrigacaoService: ObrigacaoService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(obrigacaoService, Obrigacao, toastService);

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, Validators.required],
                descricao: [null, Validators.required],
                tipo: [null, Validators.required],
                periodicidade: [null, Validators.required],
                orgao: [null, Validators.required],
                uf: [null, Validators.required],
                municipioCodigo: [null, Validators.required],
                somenteMatriz: [null, Validators.required],
                regimeLP: [null],
                regimeLRT: [null],
                regimeLRA: [null],
                regimeSN: [null],
                somenteAtividadeIndustrial: [null],
                atividadeAtacado: [null],
                atividadeVarejo: [null],
                atividadeServico: [null],
                vencimentoDia: [null],
                vencimentoMes: [null, this.radioValuePeriodicidade === 'A' ? Validators.required : null],
                vencimentoUtil: [null, Validators.required],
                vencimentoModalidade: [null],
                vencimentoMesSubsequente: [null]
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, null],
                tipo: [null, null],
                orgao: [null, null],
                categoria: [null, null],
                uf: [null, null],
                municipioCodigo: [null, null],
                prazoExpiracaoConsulta: [null, null],
                emManutencao: [null, null],
            })
        };

        this.obrigacaoService.retornaEstados().subscribe((retorno: any) => {

            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;

        });

        this.obrigacaoService.retornaCategorias().subscribe((retorno: any) => {

            retorno.data.forEach((value) => {
                value.label = value.descricao;
                value.key = value.categoria_id;
            });
            this.comboCategoria = retorno.data;

        });


        this.obrigacaoService.retornaComboMunicipios().subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboTodosMunicipios = [];
            this.comboTodosMunicipios = retorno.cidades;
        });

        this.obrigacaoService.retornaMunicipios().subscribe((retorno: any) => {

            this.comboCidades = retorno.cidades;

        });

        this.dataExport = {
            url: '/cadastros/obrigacoes/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Obrigações',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    keyPressNumber(event) {
        Helpers.keyPressNumber(event);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        // Validar Campos
        const campos = this.formDadosCadastrais.formGroup.value;
        let erro = false;
        let mensagem = '';

        if (!campos.descricao) {
            erro = true;
            mensagem = 'Campo descrição é obrigatório.';
        }

        // tslint:disable-next-line:max-line-length
        if (campos.orgao === 'Federal' && !campos.somenteAtividadeIndustrial && !campos.atividadeVarejo && !campos.atividadeAtacado && !campos.atividadeServico) {
            erro = true;
            mensagem = 'Ao menos 1 campo em atividades é obrigatório.';
        }

        if (this.radioValuePeriodicidade === 'A' && !campos.vencimentoMes) {
            erro = true;
            mensagem = 'Campo Mês Vencimento é obrigatório.';
        }

        if (!erro && campos.periodicidade === 'A') {
            this.formDadosCadastrais.formGroup.get('vencimentoModalidade').setValue(null);
            this.formDadosCadastrais.formGroup.get('vencimentoMesSubsequente').setValue(null);
            const mesVencimento = new Date(this.formDadosCadastrais.formGroup.get('vencimentoMes').value).getMonth() + 1;
            this.formDadosCadastrais.formGroup.get('vencimentoMes').setValue(mesVencimento);
        }

        if (campos.vencimentoDia && (campos.vencimentoDia > 31 || campos.vencimentoDia < 1)) {
            erro = true;
            mensagem = 'Dia de vencimento inválido';
        }

        if (!erro) {

            this.obrigacaoService.save(this.formDadosCadastrais.formGroup.value).subscribe(
                (response) => {

                    this.toastrService.success('Obrigação cadastrada com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.cadastrando = false;

                    if (response.id) {
                        this.openTab('/cadastros/obrigacoes/obrigacao-detalhe/', response.id, {id: response.id});
                    } else {
                        this.queryTable(this.currentParams, null);
                    }

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );
        } else {
            this.toastrService.error(mensagem);
            this.cadastrando = false;
        }

    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];
        this.currentParams.pageIndex = 1;



        this.queryTable(this.currentParams, null);

        this.modalFiltrar(false);

    }

    modalCadastrar(visible) {

        if (visible) {

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });

        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    modalFiltrar(visible) {
        this.modalFiltrarVisible = visible;
    }

    toggleOrgao(event) {
        this.radioValueOrgao = event;
    }


    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.componentExport.visible = visible;
    }

    filtraComboMunicipio(uf: string): void {


        this.obrigacaoService.retornaComboMunicipios(uf).subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboMunicipio = [];
            this.comboMunicipio = retorno.cidades;
        });
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                params.filter.push({key: chave, value: valor});
            }
        }

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.loading = true;
        this.abstractService.listToTable(params, this.currentSearch).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.refreshCheckedStatus();

            this.loading = false;
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }
        }
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    showConfirmEmcluirObrigacaoM(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.excluirObrigacaoManual(id)
        });

    }

    excluirObrigacaoManual(id: string) {
        this.excluindoObrigacao.loading = true;
        this.excluindoObrigacao.id = id;
        this.obrigacaoService.excluirObrigacaoManual(id).subscribe(
            (response) => {
                this.queryTable(this.currentParams, this.currentSearch);
                this.toastrService.success('Obrigação excluída com sucesso!');
                this.excluindoObrigacao.loading = false;
                this.excluindoObrigacao.id = '';

            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.excluindoObrigacao.loading = false;
                this.excluindoObrigacao.id = '';

            }
        );
    }

    nItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }
}

<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Obrigações">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 12)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 12)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ collapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content class="complianceObrigacoes">
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false"
            >
                <ng-template *ngFor="let item of arrayMeses; index as i" swiperSlide let-data
                             class="swiper-card-competencia">
                    <div class="card-competencia ant-card pull-up card-{{i}}"
                         [ngClass]="collapseCard && 'compress'">
                        <div [ngClass]="[item.id === null ? 'ant-card-head-gray d-flex justify-content-between' : 'ant-card-head']">
                            <div>
                                <h2>{{ objectMeses[item.mes - 1] | uppercase }}</h2>
                                <h3>{{ item.ano }}</h3>
                            </div>
                            <a nz-button nzType="link" nzSize="small" *ngIf="item.id === null">
                                <i class="fas fa-lock" style="color:#fff;"></i>
                            </a>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <!--[ngClass]="i == 0 ? 'cardShadow' : ''" [nzTitle]=""-->
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.qtdUnidades }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.qtdObrigacoes | number }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Ausências</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;">{{ item.qtdAusencias }}</span>
                                        </nz-col>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <nz-progress
                                                    [nzPercent]="item.porcAusencias | number: '1.0-0'"
                                                    [nzFormat]="formatOne"
                                                    [nzShowInfo]="true"
                                                    [nzStrokeColor]="'#ff9149'">
                                            </nz-progress>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center">
                                    <span nz-typography nzType="secondary">Divergências</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;">{{ item.qtdDivergencias | number }}</span>
                                        </nz-col>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <nz-progress [nzPercent]="item.porcDivergencias | number: '1.0-0'"
                                                         [nzFormat]="formatOne"
                                                         [nzShowInfo]="true"
                                                         [nzStrokeColor]="'#ff4961'">
                                            </nz-progress>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</nz-content>
<nz-content>
    <div class="cards-info">
        <div class="info-card-p-mes">
            <nz-card [nzLoading]="loadingContadores" [nzBodyStyle]="{'padding-left': '8px', 'padding-right': '8px'}">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div style="padding-bottom: 6px">
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                {{ objectCountersValues.anoSelecionado }}
                            </h4>
                            <h2 nz-typography class="custom-card-value value-aling"
                                [ngStyle]="{margin: 0, 'font-size': fonteMenor}">
                                {{ objectCountersValues.mesSelecionado }}
                            </h2>
                            <small class="ant-page-header-heading-sub-title"
                                   style="position: absolute; font-size: small">
                                Atualizado em {{ objectCountersValues.updated_at | date: 'dd/MM/yy HH:mm' }}</small>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div class="info-card-p">
            <nz-card [nzLoading]="loadingContadores" [nzBodyStyle]="{'padding-left': '4px', 'padding-right': '4px'}">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                        <i class="far fa-building"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1; ">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="color: #009688 !important"
                                [ngStyle]="{margin: 0, 'font-size': fonteMaior}">
                                {{ objectCountersValues.qtdUnidades }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div [ngClass]="[objectCountersValues.qtdObrigacoes < 99.999 ? 'info-card-m' : 'info-card-g']">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center justify-content-center"
                         style="height: 60px">
                        <i class="far fa-calendar-alt"
                           style="font-size: 60px; margin-right: 12px; color:#1e9ff2 !important;"></i>
                        <div style="margin-right: 10px; padding-right: 10px;">
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1; ">
                                Obrigações
                            </h4>
                            <div class="value-aling">
                                <h1 nz-typography class="custom-card-value"
                                    style="color: #1e9ff2 !important"
                                    [ngStyle]="{margin: 0, 'font-size': fonteMaior}">
                                    {{ objectCountersValues.qtdObrigacoes | number }}
                                </h1>
                            </div>
                        </div>
                        <div style="border-left: 1px solid #cdcdcd; padding-left: 18px">
                            <div style="margin-bottom: -5px">
                                <h5 class="custom-card-title text-muted"
                                    style="margin: 0; margin-top: 8px; line-height: 1;">
                                    Principais
                                </h5>
                                <div style="text-align: center">
                                    <h4 nz-typography class="custom-card-value"
                                        style="margin: 0; color: #1e9ff2 !important; font-size: medium">
                                        {{ totaisPrincipais ? (totaisPrincipais | number) : '-' }}
                                    </h4>
                                </div>

                            </div>
                            <div>
                                <h5 class="custom-card-title text-muted;"
                                    style="margin: 0; margin-top: 1px; line-height: 1;">
                                    Acessórias
                                </h5>
                                <div style="text-align: center">
                                    <h4 nz-typography class="custom-card-value"
                                        style="margin: 0; color: #1e9ff2 !important; font-size: medium">
                                        {{ totaisAcessorias ? (totaisAcessorias | number) : '-' }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div [ngClass]="[objectCountersValues.qtdAusencias < 99.999 ? 'info-card-m' : 'info-card-g']">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="17"
                         class="d-flex justify-content-center align-items-center">
                        <i class="far fa-times-circle"
                           style="font-size: 60px; margin-right: 15px; color: #ff9149 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Ausências
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="color: #ff9149 !important"
                                [ngStyle]="{margin: 0, 'font-size': fonteMaior}">
                                {{ objectCountersValues.qtdAusencias | number }}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="7"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="objectCountersValues.porcAusencias | number: '1.0-0' "
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#ff9149'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
        <div [ngClass]="[objectCountersValues.qtdDivergencias < 99.999 ? 'info-card-m' : 'info-card-g']">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="15"
                         class="d-flex justify-content-center align-items-center justify-content-center">
                        <i class="fas fa-not-equal"
                           style="font-size: 60px; margin-right: 15px; color: #ff4961 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted title-aling" style="margin: 0; line-height: 1;">
                                Divergências
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="color: #ff4961 !important"
                                [ngStyle]="{margin: 0, 'font-size': fonteMaior}">
                                {{ objectCountersValues.qtdDivergencias | number }}
                            </h1>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="9"
                         class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                        <nz-progress [nzPercent]="objectCountersValues.porcDivergencias | number: '1.0-0'"
                                     nzType="circle"
                                     [nzWidth]="60"
                                     [nzFormat]="formatOne"
                                     [nzStrokeColor]="'#ff4961'"></nz-progress>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-card [ngClass]="[status ? 'card-fullscreen' : '', !loadingCardTabs ? 'ant-card-table' : '']"
         [nzLoading]="loadingCardTabs">
    <nz-card-tab>
        <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtiva" [nzTabBarExtraContent]="tabsExtra"
                   (nzSelectChange)="changeTabs($event)">
            <nz-tab nzTitle="Obrigações"></nz-tab>
            <nz-tab nzTitle="Divergências"></nz-tab>
            <nz-tab nzTitle="Ausências"></nz-tab>
        </nz-tabset>
    </nz-card-tab>
    <nz-table #tableObrigacoes
              [nzData]="arrayTableObrigacoes"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              *ngIf="tabAtiva === 0"
              nzSize="small"
              nzBordered
              style="table-layout:fixed;">
        <thead>
        <tr>
            <th #column1 style="text-align: center;">Obrigação</th>
            <th #column2 style="text-align: center;" nzWidth="34px"></th>
            <th #column3 style="text-align: center;">Orgão</th>
            <th #column4 style="text-align: center;">Obrigações</th>
            <th #column5 style="text-align: center;">Ausências</th>
            <th #column6 style="text-align: center;">% de Ausências</th>
            <th #column7 style="text-align: center;">Divergências</th>
            <th #column8 style="text-align: center;">% Divergências</th>
        </tr>
        </thead>
        <tbody>
        <ng-container class="ant-table-tbody" *ngFor="let data of arrayTableObrigacoes">
            <tr>
                <td [ngStyle]="{
                        'width': '200px',
                        'min-width': '200px'
                        }">
                    <h5 nz-typography>{{ data.label }} {{ data.obrigacoes.size }}</h5>
                </td>
                <td style="color: #1e9ff2;" [nzExpand]="expandSet.has(data.id)"
                    (nzExpandChange)="onExpandChange(data.id, $event)"
                ></td>
                <td></td>
                <td nzAlign="center">
                    <strong>{{ data.totais.obrigacoes }}</strong>
                </td>
                <td nzAlign="center">
                    <strong>{{ data.totais.ausencias }}</strong>
                </td>
                <td nzAlign="center">
                    <nz-progress nz-tooltip
                                 nzTooltipTitle="{{data.totais.ausencias}} Ausências"
                                 [nzPercent]="data.totais.porcAusencias | number: '1.0-0'"
                                 [nzShowInfo]="true"
                                 [nzStrokeColor]="'#ff9149'"
                                 [nzFormat]="formatOne"
                    >
                    </nz-progress>
                </td>
                <td nzAlign="center">
                    <strong>{{ data.totais.divergencias }}</strong>
                </td>
                <td nzAlign="center">
                    <nz-progress nz-tooltip
                                 nzTooltipTitle="{{data.totais.divergencias}} Divergências"
                                 [nzPercent]="(data.totais.porcDivergencias | number)" [nzShowInfo]="true"
                                 [nzStrokeColor]="'#ff4961'"
                                 [nzFormat]="formatOne">
                    </nz-progress>
                </td>
            </tr>
            <ng-container *ngIf="data.obrigacoes.length > 0">
                <tr [nzExpand]="expandSet.has(data.id)" *ngFor="let obrigacao of data.obrigacoes">
                    <td [ngStyle]="{
                            'width': column1Width + 'px',
                            'min-width': '200px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                        {{ obrigacao.obrigacao }}
                    </td>
                    <td [ngStyle]="{
                            'width': column2Width + 'px',
                            'min-width': column2Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                    </td>
                    <td [ngStyle]="{
                            'width': column3Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ obrigacao.orgao }}
                    </td>
                    <td [ngStyle]="{
                            'width': column4Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ obrigacao.obrigacoes }}
                    </td>
                    <td [ngStyle]="{
                            'width': column5Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ obrigacao.ausencias }}
                    </td>
                    <td nzAlign="center" [ngStyle]="{
                            'width': column6Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        <nz-progress nz-tooltip
                                     nzTooltipTitle="{{obrigacao.ausencias}} Ausências"
                                     [nzPercent]="obrigacao.porcAusencias | number: '1.0-0'"
                                     [nzShowInfo]="true"
                                     [nzStrokeColor]="'#ff9149'"
                                     [nzFormat]="formatOne"
                        >
                        </nz-progress>
                    </td>
                    <td [ngStyle]="{
                            'width': column7Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ obrigacao.divergencias }}
                    </td>
                    <td nzAlign="center"
                        [ngStyle]="{
                            'width': column8Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        <nz-progress nz-tooltip
                                     nzTooltipTitle="{{obrigacao.divergencias}} Divergências"
                                     [nzPercent]="(obrigacao.porcDivergencias | number)" [nzShowInfo]="true"
                                     [nzStrokeColor]="'#ff4961'"
                                     [nzFormat]="formatOne">
                        </nz-progress>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="data.obrigacoes.length === 0 || data.obrigacoes === undefined">
                <tr [nzExpand]="expandSet.has(data.id)" style="text-align: center;">
                    Sem Dados
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </nz-table>
    <nz-table #tableInconsistencias
              [nzData]="arrayTableDivergencias"
              *ngIf="tabAtiva === 1" class="scrollbar"
              [nzFrontPagination]="false"
              nzSize="small"
              nzBordered
              style="table-layout:fixed;">
        <thead>
        <tr>
            <th #column16 nzAlign="center" style="width: 250px">Grupo Empresarial</th>
            <th #column17 nzAlign="center" nzWidth="34px"></th>
            <th #column18 style="width: 450px" nzAlign="center">Empresa</th>
            <th #column19 style="width: 15%" nzAlign="center">Obrigação</th>
            <th #column20 style="width: 12%" nzAlign="center">Campo</th>
            <th #column21 style="width: 12%" nzAlign="center">Ocorrência</th>
            <th #column22 style="text-align: center;  width: 6%">Observações</th>
        </tr>
        </thead>
        <tbody>
        <ng-container class="ant-table-tbody" *ngFor="let divergencia of arrayTableDivergencias">
            <tr>
                <td [ngStyle]="{
                        'width': '250px',
                        'min-width': '250px'
                        }">
                    <h5 nz-typography>{{ divergencia.grupoEmpresarial_descricao }}</h5>
                </td>
                <td nzWidth="34px" nzAlign="center" style="color: #1e9ff2; text-align: center"
                    [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)"
                    (nzExpandChange)="onExpandChangeDivergencias(divergencia.grupoEmpresarial_id, $event)"
                ></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
            </tr>
            <ng-container *ngIf="divergencia.registros.length > 0">
                <tr [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)"
                    *ngFor="let data of divergencia.registros">
                    <td [ngStyle]="{
                            'width': column16Width + 'px',
                            'min-width': '270px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                    </td>
                    <td [ngStyle]="{
                            'width': column17Width + 'px',
                            'min-width': column17Width + 'px',
                            position: 'relative',
                            padding: '8px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                    </td>
                    <td nzAlign="center" [ngStyle]="{
                            'width': '750px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                        <a style="font-weight: bold; margin-right: 5px" *ngIf="data.tributo_id else semTributoId"
                           (click)="openTab('ComplianceTributosEmpresasComponent', null,
                            {unidade: data.empresaId, ano: anoSelecionado, mes: mesSelecionado, divergencia: data.tributo_id})">
                            {{ modoDemo(data.empresa, 'empresaCodigoNome') }}
                            <br>({{ modoDemo(data.cnpj | cpfCnpj, 'cnpj') }})
                        </a>

                        <ng-template #semTributoId>
                            <span>
                                {{ modoDemo(data.empresa, 'empresaCodigoNome') }}
                                <br>({{ modoDemo(data.cnpj | cpfCnpj, 'cnpj') }})
                            </span>
                        </ng-template>

                        <span *ngIf="data.ie"
                              style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                              nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{data.ie}}">
                        </span>
                    </td>
                    <td nzAlign="center" [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ data.obrigacao }}
                    </td>
                    <td nzAlign="center" [ngStyle]="{
                            'width': '12%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ data.campo }}
                    </td>
                    <td nzAlign="center" [ngStyle]="{
                            'width': '12%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        <i *ngIf="data.semMovimento === '1'" class="fa-solid fa-circle-info" style="color: #faad14"
                           nz-tooltip="Justificada como Sem Movimento por {{data.usuario_nome}} em {{data.updated_at | date:'dd/MM/yyyy HH:mm'}}"></i>
                        {{ data.ocorrencia === 'Justificada como Sem Movimento' && data.semMovimento !== '1' ? '' : data.ocorrencia }}
                    </td>
                    <td nzAlign="center" style=" width: 6%">
                                     <span style="margin: 0; cursor: pointer;"
                                           (click)="modalOcorrencia(true, 'divergencia', data.id)"
                                           class="ant-badge ant-badge-not-a-wrapper ng-star-inserted">
                                        <div class="ant-scroll-number ng-animate-disabled ng-trigger ng-trigger-zoomBadgeMotion ant-badge-count ng-star-inserted">{{ data.qtdObservacoes }}</div>
                                    </span>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="divergencia.registros.length === 0 || divergencia.registros === undefined">
                <tr [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)" style="text-align: center;">
                    Sem Dados
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </nz-table>
    <nz-table #tableAusencias
              [nzData]="arrayTableAusencias"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              *ngIf="tabAtiva === 2"
              nzSize="small"
              nzBordered
              style="table-layout:fixed;">
        <thead>
        <tr>
            <th #column9 style="text-align: center; width: 250px">Grupo Empresarial</th>
            <th #column10 style="text-align: center;" nzWidth="34px"></th>
            <th #column11 style="text-align: center;  width: 450px">Empresa</th>
            <th #column13 style="text-align: center; width: 15%">IE</th>
            <th #column14 style="text-align: center; width: 12%">Obrigação</th>
            <th #column15 style="text-align: center;  width: 12%">Vencimento</th>
            <th #column23 style="text-align: center; width: 6%">Observações</th>
        </tr>
        </thead>
        <tbody>
        <ng-container class="ant-table-tbody" *ngFor="let ausencia of arrayTableAusencias">
            <tr>
                <td [ngStyle]="{
                        'width': '250px',
                        'min-width': '250px'
                        }">
                    <h5 nz-typography>{{ ausencia.grupoEmpresarial_descricao }}</h5>
                </td>
                <td nzWidth="34px" style="color: #1e9ff2; text-align: center"
                    [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)"
                    (nzExpandChange)="onExpandChangeAusencias(ausencia.grupoEmpresarial_id, $event)"
                ></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
                <td nzAlign="center"></td>
            </tr>
            <ng-container *ngIf="ausencia.registros.length > 0">
                <tr [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)"
                    *ngFor="let data of ausencia.registros">
                    <td [ngStyle]="{
                            'width': column9Width + 'px',
                            'min-width': '270px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                    </td>
                    <td [ngStyle]="{
                            'width': column10Width + 'px',
                            'min-width': column10Width + 'px',
                            position: 'relative',
                            padding: '8px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                    </td>
                    <td [ngStyle]="{
                            'width': '750px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ modoDemo(data.empresa, 'empresaCodigoNome') }}
                        <br>({{ modoDemo(data.cnpj | cpfCnpj, 'cnpj') }})
                    </td>
                    <td [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ data.ie }}
                    </td>
                    <td [ngStyle]="{
                            'width': '12%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        <i *ngIf="data.semMovimento === '1'" class="fa-solid fa-circle-info" style="color: #faad14"
                           nz-tooltip="Justificada como Sem Movimento por {{data.usuario_nome}} em {{data.updated_at | date:'dd/MM/yyyy HH:mm'}}"></i>
                        {{ data.obrigacao }}
                    </td>
                    <td [ngStyle]="{
                            'width': '12%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        {{ data.vencimento | date: 'dd/MM/YYYY' }}
                    </td>
                    <td nzAlign="center" style=" width: 6%">

                        <span *ngIf="idOcorrencia === data.id && loadingObservacao else btModal" nz-icon
                              nzType="loading" nzTheme="outline" style="color: #ff4d4f"></span>

                        <ng-template #btModal>

                             <span style="margin: 0; cursor: pointer;"
                                   (click)="modalOcorrencia(true, 'ausencia', data.id)"
                                   class="ant-badge ant-badge-not-a-wrapper ng-star-inserted">
                            <div class="ant-scroll-number ng-animate-disabled ng-trigger ng-trigger-zoomBadgeMotion ant-badge-count ng-star-inserted">{{ data.qtdObservacoes }}</div>
                        </span>

                        </ng-template>

                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="ausencia.registros.length === 0 || ausencia.registros === undefined">
                <tr [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)" style="text-align: center;">
                    Sem Dados
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </nz-table>
</nz-card>
<nz-card nzTitle="Ausências e Divergências por Ano" [nzExtra]="chartExtra" *ngIf="tabAtiva === 0"
         [nzLoading]="loadingCardHighcharts">
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="19" class="d-flex align-items-center">
            <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    [(update)]="updateChartFlag"
                    *ngIf="Highcharts && chartOptions"
                    style="width: 100%; height: 350px; display: block;"
            ></highcharts-chart>
        </div>
        <div nz-col [nzSpan]="5">
            <ul nz-list nzBordered nzSize="large">
                <nz-list-item>
                    <div>
                        <p class="mb0">{{ objectAno.unidades | number }}</p>
                        <h5 nz-typography class="custom-card-value mb0" style="color: #009688;">
                            Unidades
                        </h5>
                    </div>
                </nz-list-item>
                <nz-list-item>
                    <div>
                        <p class="mb0">{{ objectAno.obrigacoes | number }}</p>
                        <h5 nz-typography class="custom-card-value mb0" style="color: #1e9ff2;">
                            Obrigações
                        </h5>
                    </div>
                </nz-list-item>
                <nz-list-item>
                    <div>
                        <p class="mb0">
                            {{ objectAno.ausencias | number }} ({{ objectAno.porcAusencias | number: '1.0-0' }}%)
                        </p>
                        <h5 nz-typography class="custom-card-value mb0" style="color: #ff9149;">
                            Ausências
                        </h5>
                    </div>
                </nz-list-item>
                <nz-list-item>
                    <div>
                        <p class="mb0">
                            {{ objectAno.divergencias }} ({{ objectAno.porcDivergencias | number: '1.0-0' }}%)
                        </p>
                        <h5 nz-typography class="custom-card-value mb0" style="color: #ff4961;">
                            Divergências
                        </h5>
                    </div>
                </nz-list-item>
            </ul>
        </div>
    </div>
</nz-card>
<ng-template #chartExtra>
    <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu">
        {{ cardHighchartsAnoSelecionado }}<i nz-icon nzType="down"></i>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item *ngFor="let item of arrayAnos">
                <a (click)="mudarAno(item)">{{ item }}</a>
            </li>
        </ul>
    </nz-dropdown-menu>
</ng-template>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button disabled="{{loadingCardTabs}}"
                    [ngStyle]="tabAtiva > 0 ? {'margin-right': '20px'} : {'margin-right': '-5px'} "
                    (click)="exibirTudo(!expandCards)" nz-button>
                <em *ngIf="expandCards" class="fas fa-angle-double-up"></em>
                <em *ngIf="!expandCards" class="fas fa-angle-double-down"></em>
            </button>
            <button nz-button [nzType]="'primary'" (click)="abrirModal(formFiltrar)"
                    style="margin-right: 0;"
                    *ngIf="tabAtiva !== 0"
            >
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
        </nz-badge>
        <button nz-button [nzType]="'primary'"
                [nzLoading]="loadingExportar"
                (click)="export()"
                style="margin-right: 17px;"
        >
            <span>Exportar</span>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrar)" [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id"
                        >
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectCnpj"
                                       [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">IE</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.ie !== '' && formFiltrar.formGroup.value.ie !== null">
                        <input nz-input type="text" formControlName="ie" maxlength="20" size="60"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.obrigacao">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'"
                                   formControlName="obrigacao">
                            <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Ocorrência</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.ocorrencia">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="ocorrencia">
                            <nz-option *ngFor="let opt of arraySelectOcorrencias"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrar)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal nzTitle="Observações"
          class="modal-table"
          [(nzVisible)]="displayObservacaoModal"
          (nzOnCancel)="modalOcorrencia(false)"
          [nzWidth]="848"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #observacaoTable
                  [nzData]="arrayTableObservacao"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzNoResult]="semDados"
                  class="fonte-doze table-small"
                  nzLoading="{{loadingObservacao}}">
            <thead>
            <tr>
                <th *ngFor="let column of listOfColumn;"
                    [(nzSortOrder)]="column.sortOrder"
                    [nzSortFn]="column.sortFn"
                    [ngStyle]="{'text-align' : column.align}">
                    {{ column.title }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let observacao of observacaoTable.data">
                <td nzAlign="center">{{ observacao?.descricao }}</td>
                <td nzAlign="center">{{ observacao?.created_at | date : 'dd/MM/yyyy HH:mm' }}</td>
                <td nzRight nzAlign="center" style="white-space: nowrap">
                    <a nz-button nzType="link"
                       nz-tooltip="true"
                       nzTooltipTitle="Editar"
                       (click)="modalObservacao(true, true, observacao.descricao, observacao.id)">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a nz-button nzType="link"
                       nzDanger
                       nz-tooltip="true"
                       nzTooltipTitle="Remover"
                       [nzLoading]="loadingsObservacaoExcluir[observacao.id]"
                       (click)="showConfirmExcluirObservacao(this.tabAtiva === 1 ? 'divergencia' : 'ausencia', observacao.id)">
                        <i class="far fa-trash-alt"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #semDados>
            <div style="font-size: 1.5em; color: #c2c2c2; padding: 30px 0">
                {{ jutificadoComoSemMovimento ? 'Justificado como sem movimento.' : 'Não há dados.' }}
            </div>
        </ng-template>
    </div>
    <div *nzModalFooter class="d-flex justify-content-between">
        <div>
            <button nz-button nzType="link" (click)="modalObservacao(true)">
                <i class="fa-solid fa-circle-plus" style="margin-right: 5px;"></i> Adicionar Observação
            </button>
            <button nz-button nzType="link" (click)="confirmJustificar()" [nzLoading]="loadingJustificar">
                <i class="fa-solid fa-circle-exclamation" style="margin-right: 5px;"></i>
                {{ jutificadoComoSemMovimento ? 'Remover justificativa' : 'Justificar como sem movimento' }}
            </button>
        </div>

        <button nz-button nzType="link" (click)="modalOcorrencia(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [nzTitle]="!flagEditarObservacao ? 'Adicionar Observação' : 'Editar Observação'"
          [(nzVisible)]="displayObservasaoModal"
          (nzOnCancel)="modalObservacao(false)"
          [nzWidth]="848">
    <div *nzModalContent>
        <textarea rows="4" nz-input [(ngModel)]="observacaoValue" placeholder="Digite..."></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalObservacao(false)">Fechar</button>
        <button [nzLoading]="loadingsObservacaoAdicionar" nz-button nzType="primary" *ngIf="!flagEditarObservacao"
                (click)="adicionarObservacao()">Adicionar
        </button>
        <button [nzLoading]="loadingsObservacaoEditar" nz-button nzType="primary" *ngIf="flagEditarObservacao"
                (click)="editarObservacao()">Gravar
        </button>
    </div>
</nz-modal>

import {AfterViewInit, Directive, HostListener, Input, OnInit} from '@angular/core';

@Directive({
    /* tslint:disable-next-line:directive-selector */
    selector: '[stickyHeaderDirective]'
})
export class StickyHeaderDirective implements OnInit, AfterViewInit {

    public static scrollValue: number;
    public static target: any;

    @Input() initTarget: any;

    constructor() {
    }

    @HostListener('scroll', ['$event'])
    private onScroll($event: { target }): void {
        StickyHeaderDirective.scrollValue = $event.target.scrollTop;
    }

    ngOnInit() {
        StickyHeaderDirective.target = this.initTarget.elementRef.nativeElement;
    }

    ngAfterViewInit() {
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';

@Injectable({
    providedIn: 'root'
})
export class OmissoesSefazService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/consultas/omissoes-sefaz', {});
    }

    retornarGruposEmpresariais(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    getTable(filtros: any): Observable<any> {

        const queryStr = filtros ? Helpers.montaQueryString(filtros) : '';

        return this.http.get(`${environment.apiUrl}/omissao/tabela${queryStr}`);

    }

    getUltimoRelatorio(referenciaUltimaEmissao: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/omissao/arquivo?referenciaUltimaEmissao=${referenciaUltimaEmissao}`);
    }
}

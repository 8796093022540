<nz-page-header nzTitle="Monitoramento" nzSubtitle="Histórico de Acessos">
    <nz-page-header-extra></nz-page-header-extra>
</nz-page-header>

<nz-content style="width: 100%">

    <nz-card [nzBodyStyle]="{width: '100%', padding: '0'}" [nzLoading]="cardLoading"
             [nzBodyStyle]="{'padding': cardLoading ? '15px' : '0px'}"
             [ngClass]="[statusOne ? 'card-fullscreen fonte-doze' : 'card-container fonte-doze']"
             nzTitle="Histórico" [nzExtra]="cndExtra">
        <ng-template #cndExtra>
            <div class="d-flex align-items-center">
                <nz-badge [nzCount]="qtdFiltros"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrarVisible(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca"
                           (keydown.enter)="getHistorico(currentParams, currentSearch)"
                           [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="getHistorico(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button [nzType]="'default'" (click)="limparFiltros()"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="getHistorico(currentParams, currentSearch)"
                        style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                    <i class="fas fa-redo-alt"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #basicTableHistorico
                  [nzData]="historico"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations?.total"
                  [nzPageSize]="paginations?.per_page"
                  [nzPageIndex]="paginations?.current_page"
                  (nzQueryParams)="getHistorico($event, currentSearch)"
                  [nzShowTotal]="rangeTemplateHistorico"
                  [nzScroll]="{ x: 'auto' }"
                  class="table-small">
            <thead>
            <tr>
                <th nzColumnKey="usuario_nome" [nzSortFn]="true" nzAlign="left">Usuário</th>
                <th nzColumnKey="usuario_ip" [nzSortFn]="true" nzAlign="left">Endereço IP</th>
                <th nzColumnKey="url" [nzSortFn]="true" nzAlign="left">URL</th>
                <th nzColumnKey="data" style="width: 200px" [nzSortFn]="true" nzAlign="left">Data/Hora</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let h of basicTableHistorico.data">
                <td nzAlign="left">{{h.usuario_nome}}</td>
                <td nzAlign="left">{{h.usuario_ip}}</td>
                <td nzAlign="left">{{h.url}}</td>
                <td nzAlign="left">{{h?.data && h.data != '0000-00-00' ? (h.data | date: 'dd/MM/YYYY HH:mm') : '-'}}</td>
            </tr>
            </tbody>
            <ng-template #rangeTemplateHistorico let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
            <ng-template #footer>
                        <span *ngIf="historico && historico.length > 0">{{paginations?.from}}
                            -{{paginations?.to}}
                            de {{paginations?.total}} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltros.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarVisible(false)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltros.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Usuário:</nz-form-label>
                <nz-form-control [nzSpan]="13" [nzHasFeedback]="formFiltros.formGroup.value.usuario_id">
                    <nz-select nzMode="default" nzShowSearch nzAllowClear nzPlaceHolder="Informe os usuários"
                               formControlName="usuario_id">
                        <nz-option *ngFor="let option of comboUsuarios" [nzLabel]="option.nome"
                                   [nzValue]="option.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Data</nz-form-label>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.dataHoraInicio">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataHoraInicio"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="6">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.dataHoraFim">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataHoraFim"
                                        nzFormat="dd/MM/yyyy"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row *ngIf="usuarioLogado.origem === 'console'">
                <nz-form-label nz-col [nzSpan]="6">Usuários console</nz-form-label>
                <nz-form-item nz-col [nzSpan]="13">
                    <nz-form-control nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.usuario_console">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="usuario_console">
                            <nz-option [nzLabel]="'Mostrar também os usuários console'" [nzValue]="'tambem'"></nz-option>
                            <nz-option [nzLabel]="'Mostrar somente os usuários console'" [nzValue]="'somente'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </div>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarVisible(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

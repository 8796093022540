import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../../core/auth/authentication.service';


@Injectable({providedIn: 'root'})
export class ConnectDownloadService extends AbstractService<any> {

    embeddedUrlBase = environment.embeddedUrlBase;

    constructor(http: HttpClient, private authService: AuthenticationService) {
        super(http, '/connect/connectDownload', {});
    }

    versoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/agent/versoes`);
    }

    downloadConnect() {
        window.open(this.getUrlConnect(), '_blank').focus();
    }

    getUrlConnect() {

        const token = this.authService.currentTokenValue;

        let embeddedUrl = this.embeddedUrlBase;
        const partesAppUrl = window.location.href.split('/');

        if (partesAppUrl.length > 3 &&
            (partesAppUrl[2].search('mastertax.app') >= 0 ||
                partesAppUrl[2].search('mastertax.local') >= 0)) {

            const partsDomain = partesAppUrl[2].split('.');
            const identificador = partsDomain.shift();

            embeddedUrl = partesAppUrl[0] + '//' + identificador + '.embedded.' + partsDomain.join('.').replace(':4200', '') + '/contas/login/auth';

        }

        return `${embeddedUrl}?token=${token.access_token}&redirect=/admin/programa/connect/full&extra=nosel`;

    }

}

<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Logs" xmlns="http://www.w3.org/1999/html">
</nz-page-header>

<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Empresas" [nzLoading]="loading"
             [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzShowTotal]="rangeTemplateUsuarios"
                  style="overflow-x: auto"
                  class="table-small">
            <thead>
            <tr>
                <th nzColumnKey="table" [nzSortFn]="true">Contexto</th>
                <th nzAlign="center" nzColumnKey="type" [nzSortFn]="true">Tipo</th>
                <th nzColumnKey="empresa_nome" [nzSortFn]="true">Empresa</th>
                <th nzAlign="center">Antes</th>
                <th nzAlign="center">Depois</th>
                <th nzAlign="center" nzColumnKey="user_name">Usuário</th>
                <th nzColumnKey="date" [nzSortFn]="true">Data</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>
                    {{data.table}}
                </td>
                <td nzAlign="center">
                    <span class="alert" [ngClass]="[types[data.type].alert]">
                        {{types[data.type].value}}
                    </span>
                </td>
                <td>
                    <div *ngIf="data.empresa_nome && data.empresa_cnpj else semEmpresa">
                        <span>{{data.empresa_nome || '-'}}</span>
                        <br>
                        <small>({{(data.empresa_cnpj | cpfCnpj) || '-'}})</small>
                    </div>
                    <ng-template #semEmpresa>
                        <span>-</span>
                    </ng-template>

                </td>
                <td nzAlign="center" style="max-width: 230px;">
                    <pre>
                        {{data.before?.replace('{', '').replace('}', '') || ''}}
                    </pre>
                </td>
                <td nzAlign="center" style="max-width: 230px;">
                    <pre>
                        {{data.after?.replace('{', '').replace('}', '') || ''}}
                    </pre>
                </td>
                <td nzAlign="center">
                    {{data.usuario || '-'}}
                </td>
                <td>
                    {{data.date | date: 'dd/MM/yyyy HH:mm:ss'}}
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateUsuarios let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="empresa_id">
                        <nz-option *ngFor="let opt of comboEmpresa"
                                   [nzLabel]="modoDemo(opt.empresa_nome, 'empresaCodigoNome') + ' (' + (modoDemo(opt.descricao | cpfCnpj, 'cnpj')) + ')'"
                                   [nzValue]="opt.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Usuário</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.user_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="user_id">
                        <nz-option *ngFor="let opt of comboUsuarios"
                                   [nzLabel]="opt.nome"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.type">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="type">
                        <nz-option [nzLabel]="'Criado'" [nzValue]="'created'"></nz-option>
                        <nz-option [nzLabel]="'Atualizado'" [nzValue]="'updated'"></nz-option>
                        <nz-option [nzLabel]="'Deletado'" [nzValue]="'deleted'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Contexto</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.table">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="table">
                        <nz-option *ngFor="let t of tabelas"
                                   [nzLabel]="t.key" [nzValue]="t.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loading" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

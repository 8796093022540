import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CalendarConfirmacoesService extends AbstractService<any> {
    baseUrl = environment.apiUrl + '/calendar/confirmacoes/';

    constructor(http: HttpClient) {
        super(http, '/calendar/confirmacoes', {});
    }

    table(params, search: string = null, baseUrl = null): Observable<any> {
        const {pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        /*if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`perPage=${pageSize}`);
        }*/

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`search=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.get(`${prefix}${queryString}`);

    }

    retornarSelectsObrigacoes(mes: number, ano: number): Observable<any> {

        const mesStr = this.mesStr(mes);

        return this.http.get(`${environment.apiUrl}/calendar/obrigacoes/obrigacoes/${ano}/${mesStr}`);
    }

    retornarSelectsCategorias(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/categorias`);
    }

    retornarSelectsEmpresas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaselectsGrupos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornarCabecalho(obrigacao, competencia): Observable<any> {
        obrigacao = obrigacao ? '/' + obrigacao : '';
        return this.http.get(`${environment.apiUrl}/calendar/confirmacoes/cabecalho/${competencia}${obrigacao}`);
    }

    mesStr(mes: number): any {

        let mesStr;

        if (mes < 10 && mes.toString().length === 1) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;

    }

    exportDocumento(id, orgao): any {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/arquivo/${id}/${orgao}`, {responseType: 'blob'});
    }

    retornaselectsResponsaveis(competencia): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/aprovacoes/select/usuarios/${competencia}`);
    }

    aprovar(ids: Set<string> | string[], status): Observable<any> {
        return this.http.post(`${environment.apiUrl}/calendar/confirmacoes/aprovar`, {ids, status});
    }

    resumoCFOP(checkObrigacaoValidacaoId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/resumo-cfop/${checkObrigacaoValidacaoId}`);
    }

    apuracaoICMS(checkObrigacaoValidacaoId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/check/conciliacoes/apuracao-icms/${checkObrigacaoValidacaoId}`);
    }

}

import {Component, Input, OnInit, AfterViewInit, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '@services/user.service';
import {AuthenticationService} from '../../core/auth/authentication.service';
import {TabHandlerInterface} from '../../shared/interfaces/tab-handler.interface';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../shared/components-helper';
import {AdministracaoService} from './administracao.service';
import {NzFormatEmitEvent, NzTreeNode} from 'ng-zorro-antd/tree';
import {UrlTypeEnum} from '../../shared/enums/url-type.enum';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-conta-administracao',
    templateUrl: './administracao.component.html',
    styleUrls: ['./administracao.component.scss']
})
export class AdministracaoComponent implements OnInit, AfterViewInit, TabHandlerInterface {
    @Input() data;
    @Input() expandAll = false;
    @ViewChildren('treeMegaMenu') treesMegaMenu;
    user: any;
    decodedToken: any;
    safeExternalLink;

    menuAdminLegado: any = {
        modalVisible: false,
        carregando: false,
        exibirBeta: false,
        menus: [],
        searchValue: ''
    };

    urlCockpit = 'https://sistema.p7jhyjo.local/contas/login/auth?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYubWFzdGVydGF4LmNvbS5ici9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTY0NDI1OTI2OCwiZXhwIjoxNjQ0MjcwMDY4LCJuYmYiOjE2NDQyNTkyNjgsImp0aSI6InYyTE13SGRDcHU3RGNpcXUiLCJzdWIiOiIwMWNlNTE4Yy00NTVlLTQxYTAtODdhZS1jODNiMjczOTVlNjEiLCJwcnYiOiI1ODcwODYzZDRhNjJkNzkxNDQzZmFmOTM2ZmMzNjgwMzFkMTEwYzRmIiwiaWRlbnRpZmljYWRvciI6ImRlc2Vudm9sdmltZW50byIsInVzdWFyaW9JZCI6IjAxY2U1MThjLTQ1NWUtNDFhMC04N2FlLWM4M2IyNzM5NWU2MSIsIm5vbWUiOiJNaWd1ZWwgQ2FtcG9zIiwiZW1haWwiOiJkZXYubWlndWVsY2FtcG9zQGdtYWlsLmNvbSIsImxvZ2luIjoiNDYwNzYzMDM4MTYiLCJvcmlnZW0iOiJjb25zb2xlIiwiYWRtaW4iOjEsImNvbnRhX2lkIjoiMDAwMDAwMDAtYmE1ZC0xMWVhLWIzZGUtMDI0MmFjMTMwMDA0IiwiYWNjZXNzX3Rva2VuIjoiJDJ5JDEwJDVtU09NZmo5Wk84OE8ybHBSY2xmUWU0MWhsMjZSa1gyNldtWGVQQy44NFBaQVNVcUpncU5xIiwiaXBfdXNlciI6IjE4Ny4xMC4xMzEuMTk3In0.KReb9EYq4hZ3Dm-i3oLcxwjpQVKqlpB_sjAsx9u0bQI&redirect=/auditor/controle-entrega/analise/&extra=nosel';

    loadings = {
        sincronizandoEmpresas: false
    };

    constructor(private tabService: TabService, private router: Router,
                private administracaoService: AdministracaoService,
                private userService: UserService,
                private authenticationService: AuthenticationService,
                private modalService: NzModalService,
                private toastService: ToastrService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.decodedToken = this.authenticationService.currentTokenValue;
        this.getLoggedUser();

    }

    ngAfterViewInit() {
        this.safeExternalLink = this.getSafeExternalLink(this.urlCockpit);
    }

    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.user = user);
    }

    openTab(path: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);

    }

    modalMenuAdminLegado(visible: boolean): void {
        this.menuAdminLegado.modalVisible = visible;

        if (visible) {
            this.getMenuItens();
        }
    }

    getMenuItens(): void {

        localStorage.setItem('exibirBeta', JSON.stringify(this.menuAdminLegado.exibirBeta));

        this.menuAdminLegado.carregando = true;

        this.administracaoService.getTree().subscribe((data: any[]) => {

            const orderedData = [];
            let firstIndex = Math.min(...data.map(({order}) => order));

            while (orderedData.length <= data.length) {

                const item = data.filter((element => {
                    return element.order === firstIndex;
                }));

                if (item.length > 0) {

                    item.forEach((value) => {
                        orderedData.push(value);
                    });

                }

                firstIndex++;

                // Para cases de erros no "order" (vazios ou repetidos) no back-end, sair do loop para evitar quebra
                if (firstIndex === 100) {
                    break;
                }

            }

            this.menuAdminLegado.menus = this.administracaoService.transformMegamenuItem(
                orderedData, 1, true, this.menuAdminLegado.exibirBeta);
            this.menuAdminLegado.carregando = false;

        });

    }

    nzEvent(event: NzFormatEmitEvent): void {

        const origin = event.node.origin;

        if (origin.url) {

            this.clickItem(origin);

        } else {

            const parentNode = event.node;
            const expanded = !parentNode.isExpanded;
            this.expandChild(parentNode, expanded);

        }

    }

    clickItem(origin): void {

        if (origin.url) {

            let urlType: UrlTypeEnum;
            let componentName = 'ExternalPageComponent';

            switch (origin.urlType) {
                case UrlTypeEnum.embedded:
                    urlType = UrlTypeEnum.embedded;
                    break;
                case UrlTypeEnum.external:
                    urlType = UrlTypeEnum.external;
                    break;
                default:
                    urlType = UrlTypeEnum.nativo;

                    const component = findComponentByUrl(origin.url);
                    origin.url = buildUrl(component, '');
                    componentName = component.name;

                    break;
            }

            const data = {
                link: origin.url,
                extra: origin.extra
            };


            this.openTabMenuAdmin(componentName, origin.description ? origin.description : origin.title, origin.url, data, urlType);
            this.modalMenuAdminLegado(false);

        }
    }

    openCockipit(): void {


        const url = '/admin/cockpit-implantacao/';
        const title = 'Cockpit de Implantação';
        const type = UrlTypeEnum.embedded;
        const data = {
            extra: 'nosel',
            link: '/admin/cockpit-implantacao/'
        };

        const newTab = new Tab('ExternalPageComponent', title, url, type, data);
        this.tabService.addTab(newTab);

    }

    openTabMenuAdmin(componentName: string, title: string, url: string, data?: {}, urlType?: UrlTypeEnum) {

        const newTab = new Tab(componentName, title, url, urlType, data);
        this.tabService.addTab(newTab);

    }

    expandChild(node: NzTreeNode, expanded: boolean) {

        node.isExpanded = expanded;

        if (node.children) {

            node.children.forEach(child => this.expandChild(child, expanded));

        }

    }

    expandAllHandler(expanded = true) {

        this.menuAdminLegado.searchValue = '';
        const expandTreeNodes = (node: NzTreeNode) => {

            node.isExpanded = expanded;

            if (node.children) {

                node.children.forEach(expandTreeNodes);

            }
        };

        this.treesMegaMenu.forEach(treeMegaMenu => {

            treeMegaMenu.handleSearchValue('');
            treeMegaMenu.getTreeNodes().forEach(expandTreeNodes);

        });

    }

    getSafeExternalLink(externalLink: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(externalLink);
    }

    showConfirmSincronizarEmpresa(): void {

        this.loadings.sincronizandoEmpresas = true;

        this.administracaoService.empresasNaoSincronizadas().subscribe(
            (response) => {
                const qntNaoSincronizada = response ? response : 0;
                this.loadings.sincronizandoEmpresas = false;

                if (qntNaoSincronizada) {
                    this.modalService.confirm({
                        nzTitle: 'Existem ' + qntNaoSincronizada + ' Empresas não sincronizadas ou desatualizadas. Sincronizar?',
                        nzOkText: 'Sincronizar',
                        nzCancelText: 'Cancelar',
                        nzOnOk: () =>
                            this.sincronizarEmpresas()
                    });
                }else{
                    this.modalService.success({
                        nzTitle: 'Todas empresas já estão sincronizadas.',
                        nzOkText: 'Ressincronizar',
                        nzCancelText: 'Ok',
                        nzOnOk: () =>
                            this.sincronizarEmpresas()
                    });
                }

            },
            (response) => {
                this.loadings.sincronizandoEmpresas = false;
                this.toastService.error(response.error.message);
            }
        );


    }

    sincronizarEmpresas(): void {
        this.loadings.sincronizandoEmpresas = true;
        this.administracaoService.sincronizarEmpresas().subscribe(
            (response) => {
                this.loadings.sincronizandoEmpresas = false;
                this.toastService.success('Empresas sincronizadas com sucesso!');
            },
            (response) => {
                this.loadings.sincronizandoEmpresas = false;
                this.toastService.error(response.error.message);
            }
        );
    }


}

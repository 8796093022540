import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {Favorito} from '@components/megamenu/favorito.model';
import {AbstractService} from '@services/abstract.service';

@Injectable({providedIn: 'root'})

export class FavoritosService extends AbstractService<Favorito> {

    constructor(http: HttpClient) {
        super(http, '/menu/favoritos', {});
    }

    list(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/menu/favoritos`);
    }

    toggle(id, toggle = true): Observable<any> {

        if (toggle) {

            return this.http.post(`${environment.apiUrl}/menu/favoritos/${id}`, '');

        } else {

            return this.http.delete(`${environment.apiUrl}/menu/favoritos/${id}`);

        }

    }

    transformItem(items, stack = [], beta = true, alfa = true) {

        for (const item of items) {

            if (!beta) {
                if (item.release === 'alfa') {
                    continue;
                }
            }

            if (item.favorite) {

                const favorito = new Favorito();
                favorito.id = item.id;
                favorito.descricao = item.title;
                favorito.descricaoEstendida = item.titleAlt;

                stack.push(favorito);

            }

            if (item.children) {

                stack = this.transformItem(item.children, stack, beta, alfa);
            }

        }

        return stack;

    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';

@Injectable({providedIn: 'root'})
export class EditarGatilhosService extends AbstractService<InscricaoRegime> {

    baseUrl = environment.apiUrl + '/obrigacao/gatilhos/tabela';

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacao/gatilhos/tabela', InscricaoRegime);
    }

    retornaComboEventos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/obrigacao/gatilhos/eventos`);
    }

    retornaComboObrigacoes(temAprovacao = null, temAgenda = null, temAgendaManual = null): Observable<any> {
        let queryString = '';

        if (temAprovacao) {
            queryString += 'temAprovacao=true';
        }

        if (temAgenda) {
            queryString += 'temAgenda=true';
        }

        if (temAgendaManual) {
            queryString += 'temAgendaManual=true';
        }
        
        return this.http.get(`${environment.apiUrl}/obrigacao/gatilhos/obrigacoes?${queryString}`);
    }

    retornaComboCertidoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/certidao/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaComboAcoes(ids): Observable<any> {

        let queryString = '';

        if (ids) {
            queryString = '?eventos=' + ids.join(',');
        }

        return this.http.get(`${environment.apiUrl}/obrigacao/gatilhos/acoes${queryString}`);

    }

    retornaComboUsuarios(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/usuarios/select`);
    }

    removerGatilho(ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/obrigacao/gatilhos/remover`, {ids});
    }

    adicionarGatilho(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/obrigacao/gatilhos`, data);
    }

    editarGatilho(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/obrigacao/gatilhos/editar`, data);
    }

    toggleGatilho(ativar, ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/obrigacao/gatilhos/ativar`, {ids, ativar});
    }

}

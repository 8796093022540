<nz-page-header class="site-page-header" nzTitle="{{pageTitle}}"
                nzSubtitle="Integrações">
    <nz-page-header-extra>
        <button nz-button nzType="primary" [nzGhost]="true" style="margin-right: 10px; border-radius: 0.45rem;"
                (click)="modalImportarArquivo(true)">
            Upload
        </button>
        <nz-button-group>
            <button nz-button nzType="primary" [nzGhost]="showTable !== 'F'"
                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                    (click)="changeTable('F')" [disabled]="gridLoading">
                Guias Federais
            </button>
            <button nz-button nzType="primary" [nzGhost]="showTable !== 'E'"
                    class="ant-btn ant-btn-primary"
                    (click)="changeTable('E')" [disabled]="gridLoading">
                Guias Estaduais
            </button>
            <button nz-button nzType="primary" [nzGhost]="showTable !== 'M'"
                    class="ant-btn ant-btn-primary"
                    (click)="changeTable('M')" [disabled]="gridLoading">
                Guias Municipais
            </button>
            <button nz-button nzType="primary" [nzGhost]="showTable !== 'NI'"
                    style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;"
                    class="ant-btn ant-btn-primary"
                    (click)="changeTable('NI')" [disabled]="gridLoading">
                Não Identificadas
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-card [ngClass]="[status ? 'card-fullscreen' : '', !cardTabsLoading ? 'ant-card-table' : '']"
             [nzLoading]="gridLoading" [nzBodyStyle]="gridLoading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <nz-card-tab>
            <nz-tabset
                    [nzSize]="'large'"
                    [nzTabBarExtraContent]="tabsExtra"
                    [(nzSelectedIndex)]="tabNumber"
                    (nzSelectChange)="changeTabs($event)">
                <nz-tab nzTitle="Armazenadas"
                        *ngIf="showTable === 'E' || showTable === 'F' || showTable === 'M'"></nz-tab>

                <nz-tab nzTitle="Não Identificadas" *ngIf="showTable === 'NI'"></nz-tab>
                <nz-tab nzTitle="Em Processamento"
                        *ngIf="showTable === 'NI'"></nz-tab>
            </nz-tabset>
        </nz-card-tab>
        <nz-table #tableFederais
                  [nzData]="arrayTableFederais"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="true"
                  [nzTotal]="pagination?.federais?.total"
                  [nzPageSize]="pagination?.federais?.per_page"
                  [nzPageIndex]="pagination?.federais?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzLoading]="gridLoading"
                  *ngIf="showTable === 'F'"
                  class="fonte-doze table-small"
                  [nzScroll]="{ x: '2200px', y: '400px' }"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="empresaNome"
                    [nzSortFn]="true" nzLeft
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Unidade
                </th>
                <!--<th nzColumnKey="status" nzAlign="center" [nzSortFn]="true" nzWidth="80px" nzLeft>Status</th>-->
                <th nzColumnKey="arquivo" nzAlign="center" nzLeft nzWidth="80px">Editar</th>
                <th nzColumnKey="estado" [nzSortFn]="true" nzAlign="left" nzWidth="60px">UF</th>
                <th nzColumnKey="arquivoData" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="valorTotal" [nzSortFn]="true" nzAlign="center">Valor</th>
                <th nzColumnKey="vencimentoData" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="linhaDigitavel"
                    [nzSortFn]="true" nzAlign="center"
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Cod Barras
                </th>
                <th nzColumnKey="arquivoNome"
                    [nzSortFn]="true" nzAlign="left"
                    [nzWidth]="widthColumnArquivoNome + 'px'">
                    Arquivo
                </th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="guiaLote_updated_at" [nzSortFn]="true" nzAlign="center">Lote</th>
                <th nzWidth="80px">Remover</th>
            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of tableFederais.data">
                <td nzLeft>
                    <div class="d-flex justify-content-between">
                        <div>
                            <span nz-typography>{{data.unidade}}</span>
                            <small style="display: block;">
                                {{data.cnpj ? (data.cnpj | cpfCnpj) : '-'}}
                            </small>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-exclamation" nz-tooltip nzTooltipTitle="Processado com Pendência(s)"
                               style="color: #ff9149;" *ngIf="data.status === 1"></i>
                            <i class="fas fa-check" nz-tooltip nzTooltipTitle="Processado sem Pendência(s)"
                               style="color: #28d094;" *ngIf="data.status === 2"></i>
                        </div>
                    </div>
                </td>
                <!--<td nzAlign="center" nzLeft></td>-->
                <td nzAlign="center" nzLeft>
                    <button nz-button
                            nzSize="small"
                            nzType="link" (click)="modalArquivo(true, data.id);">
                        <i class="far fa-edit"></i>
                    </button>
                </td>
                <td nzAlign="center">{{data.uf ? data.uf : '-'}}</td>
                <td nzAlign="center">{{data.competencia ? (data.competencia | date : 'MM/yyyy') : '-'}}</td>
                <td nzAlign="right">{{data.valor ? (data.valor | number: '1.2-2' : 'pt-br') : '-'}}</td>
                <td nzAlign="center">{{data.vencimento ? (data.vencimento | date : 'dd/MM/yyyy') : '-'}}</td>
                <td class="text-nowrap">{{data.codBarras ? data.codBarras : '-'}}</td>
                <td class="text-nowrap">
                    <a href="{{apiUrl + '/' + data.arquivoUrl}}" target="_blank">{{data.arquivoNome}}</a>
                </td>
                <td nzAlign="center">{{data.armazenamento ? (data.armazenamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td class="text-nowrap" nzAlign="center">
                    <div *ngIf="data.guiaLote_updated_at">
                        Processado em
                        <br>
                        {{data.guiaLote_updated_at | date : 'dd/MM/yyyy HH:mm:ss'}}
                    </div>
                </td>
                <td nzAlign="center">
                    <button nz-button
                            (click)="showConfirmRemover(data.id)"
                            nzType="link" style="color: red;">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <nz-table #tableEstaduais
                  [nzData]="arrayTableEstaduais"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="true"
                  [nzTotal]="pagination?.estaduais?.total"
                  [nzPageSize]="pagination?.estaduais?.per_page"
                  [nzPageIndex]="pagination?.estaduais?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzLoading]="gridLoading"
                  *ngIf="showTable === 'E'"
                  class="fonte-doze table-small"
                  [nzScroll]="{ x: '2200px', y: '400px' }"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="empresaNome"
                    [nzSortFn]="true" nzLeft
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Unidade
                </th>
                <!--<th nzColumnKey="status" nzAlign="center" [nzSortFn]="true" nzWidth="80px" nzLeft>Status</th>-->
                <th nzColumnKey="arquivo" nzAlign="center" nzLeft nzWidth="80px">Editar</th>
                <th nzColumnKey="estado" [nzSortFn]="true" nzAlign="left" nzWidth="60px">UF</th>
                <th nzColumnKey="ie" [nzSortFn]="true" nzAlign="center">IE</th>
                <th nzColumnKey="arquivoData" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="valorTotal" [nzSortFn]="true" nzAlign="center">Valor</th>
                <th nzColumnKey="vencimentoData" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="linhaDigitavel"
                    [nzSortFn]="true" nzAlign="center"
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Cod Barras
                </th>
                <th nzColumnKey="arquivoNome"
                    [nzSortFn]="true" nzAlign="left"
                    [nzWidth]="widthColumnArquivoNome + 'px'">
                    Arquivo
                </th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="guiaLote_updated_at" [nzSortFn]="true" nzAlign="center">Lote</th>
                <th nzWidth="80px">Remover</th>
            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of tableEstaduais.data">
                <td nzLeft>
                    <div class="d-flex justify-content-between">
                        <div>
                            <span nz-typography>{{data.unidade}}</span>
                            <small style="display: block;">
                                {{data.cnpj ? (data.cnpj | cpfCnpj) : '-'}}
                            </small>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-exclamation" nz-tooltip nzTooltipTitle="Processado com Pendência(s)"
                               style="color: #ff9149;" *ngIf="data.status === 1"></i>
                            <i class="fas fa-check" nz-tooltip nzTooltipTitle="Processado sem Pendência(s)"
                               style="color: #28d094;" *ngIf="data.status === 2"></i>
                        </div>
                    </div>
                </td>
                <!--<td nzAlign="center" nzLeft></td>-->
                <td nzAlign="center" nzLeft>
                    <button nz-button
                            nzSize="small"
                            nzType="link" (click)="modalArquivo(true, data.id);">
                        <i class="far fa-edit"></i>
                    </button>
                </td>
                <td nzAlign="center">{{data.uf ? data.uf : '-'}}</td>
                <td>{{data.ie ? data.ie : '-'}}</td>
                <td nzAlign="center">{{data.competencia ? (data.competencia | date : 'MM/yyyy') : '-'}}</td>
                <td nzAlign="right">{{data.valor ? (data.valor | number: '1.2-2' : 'pt-br') : '-'}}</td>
                <td nzAlign="center">{{data.vencimento ? (data.vencimento | date : 'dd/MM/yyyy') : '-'}}</td>
                <td class="text-nowrap">{{data.codBarras ? data.codBarras : '-'}}</td>
                <td class="text-nowrap">
                    <a href="{{apiUrl + '/' + data.arquivoUrl}}" target="_blank">{{data.arquivoNome}}</a>
                </td>
                <td nzAlign="center">{{data.armazenamento ? (data.armazenamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td class="text-nowrap" nzAlign="center">
                    <div *ngIf="data.guiaLote_updated_at">
                        Processado em
                        <br>
                        {{data.guiaLote_updated_at | date : 'dd/MM/yyyy HH:MM:ss'}}
                    </div>
                </td>
                <td nzAlign="center">
                    <button nz-button
                            (click)="showConfirmRemover(data.id)"
                            nzType="link" style="color: red;">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <nz-table #tableMunicipais
                  [nzData]="arrayTableMunicipais"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.municipais?.total"
                  [nzPageSize]="pagination?.municipais?.per_page"
                  [nzPageIndex]="pagination?.municipais?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzLoading]="gridLoading"
                  *ngIf="showTable === 'M'"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate"
                  [nzScroll]="{ x: '2200px', y: '400px' }"
        >
            <thead>
            <tr>
                <th nzColumnKey="empresaNome"
                    [nzSortFn]="true" nzLeft
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Unidade
                </th>
                <!--<th nzAlign="center" [nzSortFn]="true" nzLeft nzWidth="80px">Status</th>-->
                <th nzColumnKey="arquivo" nzAlign="center" nzLeft nzWidth="80px">Editar</th>
                <th nzColumnKey="estado" [nzSortFn]="true" nzAlign="left" nzWidth="60px">UF</th>
                <th nzColumnKey="im" [nzSortFn]="true" nzAlign="center">IM</th>
                <th nzColumnKey="arquivoData" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="valorTotal" [nzSortFn]="true" nzAlign="center">Valor</th>
                <th nzColumnKey="vencimentoData" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="linhaDigitavel"
                    [nzSortFn]="true" nzAlign="center"
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Cod Barras
                </th>
                <th nzColumnKey="arquivoNome"
                    [nzSortFn]="true" nzAlign="left"
                    [nzWidth]="widthColumnArquivoNome + 'px'">
                    Arquivo
                </th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="guiaLote_updated_at" [nzSortFn]="true" nzAlign="center">Lote</th>
                <th nzWidth="80px">Remover</th>
            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of tableMunicipais.data">
                <td nzLeft>
                    <div class="d-flex justify-content-between">
                        <div>
                            <span nz-typography>{{data.unidade}}</span>
                            <small style="display: block;">
                                {{data.cnpj ? (data.cnpj | cpfCnpj) : '-'}}
                            </small>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-exclamation" nz-tooltip nzTooltipTitle="Processado com Pendência(s)"
                               style="color: #ff9149;" *ngIf="data.status === 1"></i>
                            <i class="fas fa-check" nz-tooltip nzTooltipTitle="Processado sem Pendência(s)"
                               style="color: #28d094;" *ngIf="data.status === 2"></i>
                        </div>
                    </div>
                </td>
                <!--<td nzAlign="center" nzLeft>
                    <i class="fas fa-exclamation" nz-tooltip nzTooltipTitle="Processado com Pendência(s)"
                       style="color: #ff9149;" *ngIf="data.status === 1"></i>
                    <i class="fas fa-check" nz-tooltip nzTooltipTitle="Processado sem Pendência(s)"
                       style="color: #28d094;" *ngIf="data.status === 2"></i>
                </td>-->
                <td nzAlign="center" nzLeft>
                    <button nz-button
                            nzSize="small"
                            nzType="link" (click)="modalArquivo(true, data.id);">
                        <i class="far fa-edit"></i>
                    </button>
                </td>
                <td nzAlign="center">{{data.uf ? data.uf : '-'}}</td>
                <td>{{data.im ? data.im : '-'}}</td>
                <td nzAlign="center">{{data.competencia ? (data.competencia | date : 'MM/yyyy') : '-'}}</td>
                <td nzAlign="right">{{data.valor ? (data.valor | number: '1.2-2' : 'pt-br') : '-'}}</td>
                <td nzAlign="center">{{data.vencimento ? (data.vencimento | date : 'dd/MM/yyyy') : '-'}}</td>
                <td class="text-nowrap">{{data.codBarras ? data.codBarras : '-'}}</td>
                <td class="text-nowrap">
                    <a href="{{apiUrl + '/' + data.arquivoUrl}}" target="_blank">{{data.arquivoNome}}</a>
                </td>
                <td nzAlign="center">{{data.armazenamento ? (data.armazenamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td class="text-nowrap" nzAlign="center">
                    <div *ngIf="data.guiaLote_updated_at">
                        Processado em
                        <br>
                        {{data.guiaLote_updated_at | date : 'dd/MM/yyyy HH:MM:ss'}}
                    </div>
                </td>
                <td nzAlign="center">
                    <button nz-button
                            (click)="showConfirmRemover(data.id)"
                            nzType="link" style="color: red;">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <nz-table #tableNaoIdentificadas
                  [nzData]="arrayTableNaoIdentificadas"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.naoIdentificadas?.total"
                  [nzPageSize]="pagination?.naoIdentificadas?.per_page"
                  [nzPageIndex]="pagination?.naoIdentificadas?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzLoading]="gridLoading"
                  *ngIf="showTable === 'NI' && tabNumber == 0"
                  class="fonte-doze table-small"
                  [nzScroll]="{ x: '2200px', y: '400px' }"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="empresaNome"
                    [nzSortFn]="true" nzLeft
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Unidade
                </th>
                <th nzColumnKey="arquivo" nzAlign="center" nzLeft nzWidth="80px">Editar</th>
                <th nzColumnKey="estado" [nzSortFn]="true" nzAlign="left" nzWidth="60px">UF</th>
                <th nzColumnKey="arquivoData" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="valorTotal" [nzSortFn]="true" nzAlign="center">Valor</th>
                <th nzColumnKey="vencimentoData" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="linhaDigitavel"
                    [nzSortFn]="true" nzAlign="center"
                    [nzWidth]="widthColumnLinhaDigitavel + 'px'">
                    Cod Barras
                </th>
                <th nzColumnKey="arquivoNome"
                    [nzSortFn]="true" nzAlign="left"
                    [nzWidth]="widthColumnArquivoNome + 'px'">
                    Arquivo
                </th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="guiaLote_updated_at" [nzSortFn]="true" nzAlign="center">Lote</th>
                <th nzWidth="80px">Remover</th>
            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of tableNaoIdentificadas.data">
                <td nzLeft>
                    <span nz-typography>
                        {{data.unidade}}
                    </span>
                    <small style="display: block;">
                        {{data.cnpj ? (data.cnpj | cpfCnpj) : '-'}}
                    </small>
                </td>
                <td nzAlign="center" nzLeft>
                    <button nz-button
                            nzSize="small"
                            nzType="link" (click)="modalArquivo(true, data.id);">
                        <i class="far fa-edit"></i>
                    </button>
                </td>
                <td nzAlign="center">{{data.uf ? data.uf : '-'}}</td>
                <td nzAlign="center">{{data.competencia ? (data.competencia | date : 'MM/yyyy') : '-'}}</td>
                <td nzAlign="right">{{data.valor ? (data.valor | number: '1.2-2' : 'pt-br') : '-'}}</td>
                <td nzAlign="center">{{data.vencimento ? (data.vencimento | date : 'dd/MM/yyyy') : '-'}}</td>
                <td class="text-nowrap">{{data.codBarras ? data.codBarras : '-'}}</td>
                <td class="text-nowrap">
                    <a href="{{apiUrl + '/' + data.arquivoUrl}}" target="_blank">{{data.arquivoNome}}</a>
                </td>
                <td nzAlign="center">{{data.armazenamento ? (data.armazenamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td class="text-nowrap" nzAlign="center">
                    <div *ngIf="data.guiaLote_updated_at">
                        Processado em
                        <br>
                        {{data.guiaLote_updated_at | date : 'dd/MM/yyyy HH:mm:ss'}}
                    </div>
                </td>
                <td nzAlign="center">
                    <button nz-button
                            (click)="showConfirmRemover(data.id)"
                            nzType="link" style="color: red;">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <nz-table #tableNaoIdentificadas
                  [nzData]="arrayTableNaoIdentificadas"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowPagination]="true"
                  [nzLoading]="gridLoading"
                  *ngIf="showTable === 'NI' && tabNumber == 1"
                  class="fonte-doze table-small"
                  [nzScroll]="{ x: '1100px', y: '400px' }"
                  [nzShowTotal]="rangeTemplate"
                  style="padding-bottom: 15px;"
                  [nzFooter]="totalizar">
            <thead>
            <tr>
                <th nzColumnKey="arquivoNome">Arquivo</th>

                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="tamanho" [nzSortFn]="true" nzAlign="center">Tamanho</th>

            </tr>
            </thead>
            <tbody>
            <tr class="ant-table-tbody" *ngFor="let data of tableNaoIdentificadas.data">
                <td class="text-nowrap">
                    <a href="{{apiUrl + '/' + data?.arquivoUrl}}" target="_blank">{{data?.nome}}</a>
                </td>
                <td nzAlign="center">{{data.dataArmazanamento ? (data.dataArmazanamento | date : 'dd/MM/yyyy HH:mm:ss') : '-'}}</td>
                <td nzAlign="center">{{data?.tamanho ? data?.tamanho : '-'}}</td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>

        <ng-template #totalizar>
            <p style="padding-top: 0px; margin-top: -12px;">{{arrayTableNaoIdentificadas.length}} {{arrayTableNaoIdentificadas.length == 1 ? 'Registro' : 'Registros'}}</p>
        </ng-template>
    </nz-card>
</nz-content>
<ng-template #tabsExtra>
    <div class="nav-item d-flex">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearch();">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()" style="padding-left: 5px; padding-right: 5px;">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id"
                               [(ngModel)]="this.formFiltrar.formGroup.value.empresa_id">
                        <nz-option *ngFor="let opt of arraySelectCnpj"
                                   [nzLabel]="opt.descricao | cpfCnpj"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id"
                               [(ngModel)]="this.formFiltrar.formGroup.value.empresa_id">
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="showTable === 'E'">
                <nz-form-label [nzSpan]="5">IE</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.ie !== '' && formFiltrar.formGroup.value.ie !== null">
                    <input nz-input
                           formControlName="ie" type="text"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="showTable === 'M'">
                <nz-form-label [nzSpan]="5">IM</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.im !== '' && formFiltrar.formGroup.value.im !== null">
                    <input nz-input
                           formControlName="im" type="text"
                           autocomplete="off" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="tabNumber === 0 && showTable !== 'NI'">
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.status !== '' && formFiltrar.formGroup.value.status !== null">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="status">
                        <nz-option [nzLabel]="'Processado com Pendência(s)'"
                                   [nzValue]="'1'"
                        ></nz-option>
                        <nz-option [nzLabel]="'Processado sem Pendência(s)'"
                                   [nzValue]="'2'"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">UF</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.uf">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="uf">
                        <nz-option *ngFor="let opt of arraySelectUf"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.competencia">
                    <nz-date-picker [nzFormat]="'MM/yyyy'" nzMode="month"
                                    formControlName="competencia" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <!--<nz-form-item>
                <nz-form-label [nzSpan]="5">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicioDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicioAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFimDe" style="width: 100%;"
                                    nzPlaceHolder="De:"></nz-date-picker>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="6">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFimAte" style="width: 100%;"
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>-->
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Valor</nz-form-label>
                <nz-form-control [nzSpan]="6"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.valorDe">
                    <input nz-input
                           formControlName="valorDe" type="number" step="any"
                           style="width: 100%;" placeholder="De:"
                           autocomplete="off"/>
                </nz-form-control>
                <nz-form-control [nzSpan]="2">
                </nz-form-control>
                <nz-form-control [nzSpan]="6"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.valorAte">
                    <input nz-input
                           formControlName="valorAte" type="number" step="any"
                           style="width: 100%;" placeholder="Até:"
                           autocomplete="off"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Cód. Barras</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.codBarras !== '' && formFiltrar.formGroup.value.codBarras !== null">
                    <input nz-input
                           formControlName="codBarras" type="text"
                           autocomplete="off"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Armazenamento</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.armazenamento">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="armazenamento" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.vencimento">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="vencimento" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">Possui lote?</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.possuiLote !== null">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="possuiLote">
                        <nz-option [nzLabel]="'Sim'"
                                   [nzValue]="'1'"
                        ></nz-option>
                        <nz-option [nzLabel]="'Não'"
                                   [nzValue]="'0'"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="this.formLayout.modalVisible"
          [nzTitle]="'Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalArquivo(false)"
          [nzWidth]="1024"
          [nzClassName]="'no-border'">
    <div nz-row *nzModalContent>
        <div nz-col nzSpan="14">
            <iframe [ngStyle]="{height: '500px'}"
                    [src]="iframeUrl" title=""
                    style="width: 100%; border: none;"
                    *ngIf="iframeUrl"></iframe>
        </div>
        <div nz-col nzSpan="10">
            <form nz-form [nzNoColon]="true" [formGroup]="formLayout.formGroup" style="width: 100%">
                <ng-container *ngFor="let item of camposLayout">
                    <nz-form-item *ngIf="item[0] === 'arquivoLayout' && showTable === 'NI'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Campo Obrigatório">
                            <nz-select nzPlaceHolder="Selecione" (ngModelChange)="preencheCampos($event)"
                                       formControlName="{{item[0]}}" style="width: 100%; margin-bottom: 0;">
                                <nz-option *ngFor="let data of selectIdentificadores" [nzLabel]="data.descricao"
                                           [nzValue]="data.arquivoLayout"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="item[0] === 'cnpj'
                            || item[0] === 'cnpjRecebedor'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzErrorTip="{{item[0] === 'cnpj' ? 'CNPJ Inválido' : 'Campo Obrigatório'}}">
                            <input nz-input
                                   mask="00.000.000/0000-00"
                                   formControlName="{{item[0]}}" type="text"
                                   autocomplete="off"/>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="item[0] === 'codigoReceita' ||
                        item[0] === 'tipoReceita' ||
                        item[0] === 'empresaNome' ||
                        item[0] === 'ie' && showTable === 'E'||
                        item[0] === 'im' && showTable === 'M'||
                     item[0] === 'linhaDigitavel' ||
                     item[0] === 'nossoNumero' ||
                     item[0] === 'numeroDocumento'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzErrorTip="{{item[0] === 'linhaDigitavel' ? errorsFormLayout.codBarras : 'Campo Obrigatório'}}">
                            <input nz-input
                                   formControlName="{{item[0]}}" type="text"
                                   autocomplete="off"/>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="item[0] === 'emissaoData' ||
                     item[0] === 'vencimentoData' || item[0] === 'competencia' ">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Campo Obrigatório">
                            <nz-date-picker [nzMode]="item[0] === 'competencia' ? 'month' : 'date'"
                                            [nzFormat]="item[0] === 'competencia' ? 'MM/yyyy': 'dd/MM/yyyy'"
                                            formControlName="{{item[0]}}" style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="item[0] === 'valorDesconto' ||
                        item[0] === 'valorJuros' ||
                        item[0] === 'valorMulta' ||
                        item[0] === 'valorOutros'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Campo Obrigatório">
                            <input nz-input
                                   formControlName="{{item[0]}}" type="text"
                                   autocomplete="off"
                                   (ngModelChange)="verificaValorTotal()"
                                   mask="separator.2" thousandSeparator="."/>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="item[0] === 'valorTotal'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14" [nzValidateStatus]="!valorTotalValido ? 'error' : ''">
                            <input nz-input
                                   nzType="text"
                                   formControlName="{{item[0]}}"
                                   autocomplete="off"
                                   (ngModelChange)="verificaValorTotal()"
                            >
                            <span style="color: #ff4d4f;" *ngIf="!valorTotalValido">Valor Total é diferente da somatória dos outros valores.</span>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="item[0] === 'valorPrincipal'">
                        <nz-form-label [nzSpan]="10">{{item[2]}}</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Valor Inválido">
                            <input nz-input
                                   (ngModelChange)="verificaValorTotal()"
                                   formControlName="{{item[0]}}" type="text"
                                   autocomplete="off"
                                   mask="separator.2" thousandSeparator="."/>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalArquivo(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="gravarArquivo()" [nzLoading]="loadings.gravando">Gravar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="viewModalUploadArquivo"
          [nzTitle]="'Importar Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalImportarArquivo(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <p style="margin: 0px;">Selecione o arquivo .pdf para importar.</p>
        <div style="margin-top: 20px;">
            <nz-upload
                    nzType="drag"
                    [nzMultiple]="true"
                    nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                    (nzChange)="handleChange($event)"
                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <p class="ant-upload-drag-icon">
                    <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
            </nz-upload>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportarArquivo(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="uploading" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class ReceitaFederalService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, null, {});
    }

    retornaReceitaFederal(search: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}/publico/cnpj-receita/consultar/${search}`);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class BaixasAutomaticasService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    usuarioLogado = (): Observable<UsuarioLogado> => this.http.get<UsuarioLogado>(`${environment.apiUrl}/usuario-logado`);

    retornaCompetencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/obrigacao-empresa-consulta/retorna-anos`);

    }

    retornaEmpresas(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/obrigacao-empresa-consulta/tabela-empresas${queryStr}`);

    }

    retornaDetalheObrigacao(obrigacaoId: string, empresaId: string, ano): Observable<any> {

        return this.http.get(`${environment.apiUrl}/obrigacao-empresa-consulta/detalhe-obrigacao/${obrigacaoId}/${empresaId}?ano=${ano}`);
    }

    retornaObrigacoes(empresaId: string, ano: string, filtros: any): Observable<any> {

        filtros.ano = ano;
        const queryStr = filtros ? Helpers.montaQueryString(filtros) : '';

        return this.http.get(`${environment.apiUrl}/obrigacao-empresa-consulta/tabela-obrigacoes/${empresaId}${queryStr}`);

    }

    retornaComboGruposEmpresariais(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    retornaComboObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    solicitarBaixa(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/obrigacao-empresa-consulta/solicitar-baixa`, data);
    }

    solicitarBaixaManualDarf(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/obrigacao-empresa-consulta/solicitar-baixa/darf`, data);
    }

    exportExcelEmpresas(filtros: any, search: string = null, tipoExportador: string, ano: string): any {

        filtros.tipo = 'obrigacaoConsultaEmpresas';
        filtros.tipoExportador = tipoExportador;
        filtros.ano = ano;

        if (typeof filtros.grupos !== 'string') {
            filtros.grupos = filtros.grupos.toString();
        }

        return this.http.post(`${environment.apiUrl}/exportador`, filtros);

    }

    exportExelObrigacoes(filtros: any, search: string = null, tipoExportador: string, empresaId: string): any {

        filtros.tipo = 'obrigacaoConsultaObrigacoes';
        filtros.tipoExportador = tipoExportador;
        filtros.empresa_id = empresaId;

        return this.http.post(`${environment.apiUrl}/exportador`, filtros);

    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ControleDeCertificadosComponent} from './controle-de-certificados.component';
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzCardModule} from "ng-zorro-antd/card";
import {HighchartsChartModule} from "highcharts-angular";
import {NzListModule} from "ng-zorro-antd/list";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzTableModule} from "ng-zorro-antd/table";
import {UiModule} from "../../ui.module";
import {NzTagModule} from "ng-zorro-antd/tag";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzTabsModule} from "ng-zorro-antd/tabs";



@NgModule({
  declarations: [
      ControleDeCertificadosComponent
  ],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzGridModule,
        NzCardModule,
        HighchartsChartModule,
        NzListModule,
        NzBadgeModule,
        NzAlertModule,
        NzTableModule,
        UiModule,
        NzTagModule,
        FormsModule,
        NzTabsModule,
        ReactiveFormsModule
    ]
})
export class ControleDeCertificadosModule { }

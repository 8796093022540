import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Empresa} from '@models/empresa.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})

export class AdministracaoService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/administracao', {});
    }

    public getTree() {
        return this.http.get(`${environment.apiUrl}/menu/treeAdminLegado`);
    }

    public sincronizarEmpresas() {
        return this.http.get(`${environment.apiUrl}/administracao/empresas/sincronizarEmpresas`);
    }

    public empresasNaoSincronizadas() {
        return this.http.get(`${environment.apiUrl}/administracao/empresas/empresasNaoSincronizadas`);
    }

    transformMegamenuItem(items, nivel = 1, expanded = false, beta = false) {

        const megamenuItems = [];
        let ordem = 0;

        for (const item of items) {

            if (!beta) {
                if (item.release === 'alfa') {
                    continue;
                }
            }

            const megamenuItem = {
                level: nivel,
                title: item.title,
                description: item.titleAlt,
                icon: item.icon,
                url: item.url,
                urlType: item.urlType,
                open: true,
                expanded,
                selected: false,
                selectable: false,
                disabled: false,
                children: item.children ? this.transformMegamenuItem(item.children, nivel + 1, expanded, beta) : [],
                isLeaf: item.children ? item.children.length === 0 : true,
                key: item.id,
                tipo: item.type,
                ordem: ++ordem,
                extra: item.extra,
                beta: item.release === 'beta',
                alfa: item.release === 'alfa',
                release: item.release,
                favorito: item.favorite ? item.favorite : false,
            };

            megamenuItems.push(megamenuItem);
        }

        return megamenuItems;

    }


}

<nz-skeleton *ngIf="loadings.verAnexos"></nz-skeleton>

<nz-table style="overflow: auto; max-height: 550px" nzSize="small"
          [nzData]="anexosList" [nzShowPagination]="false" *ngIf="!loadings.verAnexos">
    <thead>
    <tr>
        <th>Arquivo</th>
        <th nzAlign="center">Ações</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of anexosList">
        <td>
            <span style="padding-left: 5px" nz-tooltip="{{data.nome}}" nzTooltipPlacement="left">
                {{ abreviar(data.nome) }}
            </span>
        </td>
        <td style="width: 150px;">
            <div style="display: flex; justify-content: center; gap: 10px">
                <a [disabled]="!data?.nome?.includes('.pdf')"
                   style="border: none; background-color: transparent;"
                   nz-button nz-tooltip="Visualizar" nzTooltipPlacement="left"
                   (click)="data?.nome?.includes('.pdf') && verAnexo(data)">
                    <i *ngIf="!loadings.abrirAnexos[data.id]" class="fa-regular fa-eye"></i>
                    <i *ngIf="loadings.abrirAnexos[data.id]" nz-icon nzType="loading" nzTheme="outline"></i>
                </a>
                <a nz-button (click)="downloadArquivo(data)"
                   nz-tooltip="Baixar" nzTooltipPlacement="right"
                   style="border: none; background-color: transparent">
                    <i *ngIf="!loadings.downloads[data.id]" nz-icon nzType="download" nzTheme="outline"></i>
                    <i *ngIf="loadings.downloads[data.id]" nz-icon nzType="loading" nzTheme="outline"></i>
                </a>
                <a *ngIf="btExcluir && (!data.origem && data.origem !== 'zip') && !data.disabledExcluir" nz-button
                   (click)="showConfirmRemover(data)"
                   nz-tooltip="Excluir" nzTooltipPlacement="right"
                   style="border: none; background-color: transparent">
                    <i *ngIf="!loadings.excluirAnexos[data.id]" class="fa fa-trash"
                       style="color: rgba(217,21,21,0.9)"></i>
                    <i *ngIf="loadings.excluirAnexos[data.id]" nz-icon nzType="loading" nzTheme="outline"></i>
                </a>

                <a *ngIf="btExcluir && (data.origem && data.origem === 'zip' && !data.disabledExcluir)" nz-button
                   [nz-tooltip]="'Arquivo faz parte do zip - ' + data.zipNome" nzTooltipPlacement="right"
                   style="border: none; background-color: transparent">
                    <i class="fa fa-trash" style="color: rgba(182,182,182,0.9)"></i>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
</nz-table>

<nz-modal
        [(nzVisible)]="modalVerAnexoUnicoVisible"
        (nzOnCancel)="modalVerAnexoUnico(false)"
        [nzBodyStyle]="{'padding' : '0'}"
        [nzStyle]="{ top: tamanhosTela.screenModalTop + 'px' }"
        [nzWidth]="tamanhosTela.screenWidth"
        [nzTitle]="arquivoSelecionado"
        class="modal-table">
    <ng-container *nzModalContent>
        <iframe [ngStyle]="{height: tamanhosTela.screenHeight + 'px'}"
                [src]="iframeUrl" title=""
                style="width: 100%; border: none;"
        ></iframe>

    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalVerAnexoUnico(false)">Fechar</button>
    </div>
</nz-modal>

<!--Código para o componente pai-->

<!--HTML-->
<!--(click)="modalAnexos(true, arquivo.anexos)-->

<!--<nz-modal [(nzVisible)]="modalVerAnexosVisible"-->
<!--          [nzTitle]="'Anexos da solicitação de aprovação'" [nzClosable]="true"-->
<!--          (nzOnCancel)="modalAnexos(false)"-->
<!--          [nzWidth]="550"-->
<!--          [nzBodyStyle]="{'padding': '0 5px'}">-->
<!--    <div *nzModalContent>-->

<!--        <app-anexos-list [anexosList]="listOfAnexos"></app-anexos-list>-->

<!--    </div>-->
<!--    <div *nzModalFooter>-->
<!--        <button nz-button nzType="default" (click)="modalAnexos(false);">Fechar</button>-->
<!--    </div>-->
<!--</nz-modal>-->


<!--TS-->
<!--modalVerAnexosVisible = false;-->
<!--listOfAnexos = [];-->

<!--modalAnexos(visible: boolean, anexos = []): void {-->
<!--if (visible && (!anexos || anexos.length === 0)) {-->
<!--this.listOfAnexos = [];-->
<!--this.toastService.warning('Sem arquivos para exibir');-->
<!--} else {-->
<!--this.listOfAnexos = anexos;-->
<!--this.modalVerAnexosVisible = visible;-->
<!--}-->
<!--        }-->

import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class CategoriasEStatusService {

    http = inject(HttpClient);

    getStatus(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/notas/workflow/status`);
    }

    getCategorias(params: any, search): Observable<any> {

        const qString = Helpers.montaQueryStribgBYNzTable(params, search);

        return this.http.get(`${environment.apiUrl}/notas/workflow/categoria/tabela${qString}`);
    }

    addCategoria(data) {
        return this.http.post(`${environment.apiUrl}/notas/workflow/categoria`, data);
    }

    alterarCategoria(data, id) {
        return this.http.post(`${environment.apiUrl}/notas/workflow/categoria/${id}`, data);
    }


    removerCategoria(id: string) {
        return this.http.delete(`${environment.apiUrl}/notas/workflow/categoria/remover/${id}`);
    }

    addStatus(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/workflow/categoria/gravar-status`, data);
    }

    alterarStatus(data, id): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/workflow/categoria/gravar-status/${id}`, data);
    }

    removerStatus(id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/notas/workflow/categoria/remover-status/${id}`);
    }

    getManifestacoes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/manifestacao`);
    }
}

import Visibilidade from './visibilidade.model';
import GrupoEmpresarial from './grupo-empresarial.model';
import { Profile } from './profile.model';
import { Utils } from '../utils';
import { Modulo } from './modulo.model';
import { Acesso } from './acesso.model';

export class Conta {
    id: string;
    nome: string;
    loginMFA: string;
    tipo: string;
    identificador: string;
    visibilidades: Visibilidade[];
    gruposEmpresariais: GrupoEmpresarial[];
    perfis: Profile[];
    modulos: Modulo[];
    acessos: Acesso[];

    public constructor(obj?: any) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

            if (obj.hasOwnProperty('visibilidades')) {
                this.visibilidades = obj.visibilidades.map((visibilidade: Visibilidade) => new Visibilidade(visibilidade));
                delete obj.visibilidades;
            }

            if (obj.hasOwnProperty('gruposEmpresariais')) {
                this.gruposEmpresariais = obj.gruposEmpresariais.map((grupo: GrupoEmpresarial) => new GrupoEmpresarial(grupo));
                delete obj.gruposEmpresariais;
            }

            if (obj.hasOwnProperty('perfis')) {
                this.perfis = obj.perfis.map((perfil: Profile) => new Profile(perfil));
                delete obj.perfis;
            }

            if (obj.hasOwnProperty('modulos')) {
                this.modulos = obj.modulos.map((modulo: Modulo) => new Modulo(modulo));
                delete obj.modulos;
            }

            if (obj.hasOwnProperty('acessos')) {
                this.acessos = obj.acessos.map((acesso: Acesso) => new Acesso(acesso));
                delete obj.acessos;
            }
        }
    }

    convertToSave(): Conta {
        const properties = ['id', 'nome', 'tipo', 'identificador', 'modulos', 'acessos'];
        const conta = new Conta();
        for (const prop of properties) {
            conta[prop] = this[prop];
        }

        if (Utils.isEmpty(conta.id)) {
            delete conta.id;
        }
        return conta;
    }
}

import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../core/auth/authentication.service';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {TabService} from '@services/tab.service';
import {ExternalPageService} from '../external-page.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {AppComponent} from "../../../app.component";
import {UsuarioLogadoService} from "../../usuario-logado/usuario-logado.service";

@Component({
    selector: 'external-page',
    templateUrl: './external-page.component.html',
    styleUrls: ['./external-page.component.scss'],
})

export class ExternalPageComponent implements OnInit, AfterViewInit {
    // static iframeElement: ElementRef<any>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private authService: AuthenticationService,
        private userService: UsuarioLogadoService,
        private service: ExternalPageService,
        private tabService: TabService) {
    }

    public static isExternalPage = false;

    static iframe;

    showAlertObgBaixa = false;

    @ViewChild('iframe') iframeElement: ElementRef;

    @Input() data: any;
    embeddedUrlBase = environment.embeddedUrlBase;
    safeExternalLink: SafeResourceUrl;
    loading = true;
    identificador: string;

    static isExternal() {
        ExternalPageComponent.isExternalPage = true;
    }

    ngOnInit(): void {

        this.userService.usuarioLogado().subscribe((res) => {

            this.identificador = res.identificador;

            this.urlHandler();

        });

    }

    ngAfterViewInit() {

        ExternalPageComponent.iframe = this.iframeElement.nativeElement;
    }

    urlHandler() {

        ExternalPageComponent.isExternal();

        const token = this.authService.currentTokenValue;

        let embeddedUrl = this.embeddedUrlBase;
        const partesAppUrl = window.location.href.split('/');

        if (partesAppUrl.length > 3 &&
            (partesAppUrl[2].search('mastertax.app') >= 0 ||
                partesAppUrl[2].search('mastertax.local') >= 0)) {

            const partsDomain = partesAppUrl[2].split('.');
            partsDomain.shift();

            embeddedUrl = partesAppUrl[0] + '//' + this.identificador + '.embedded.' + partsDomain.join('.').replace(':4200', '') + '/contas/login/auth';

        }

        let fullUrl: string;
        const tabEmbedded = JSON.parse(localStorage.getItem('tabEmbedded'));

        // if (this.data?.link.includes('auditor/obrigacoes-baixa')) {
        //     this.showAlertNovaTelaMT();
        // }

        if (!this.data?.link && tabEmbedded) {
            fullUrl = `${embeddedUrl}?token=${token.access_token}&redirect=${tabEmbedded.data.link}&extra=nosel`;

        } else {

            fullUrl = `${embeddedUrl}?token=${token.access_token}&redirect=${this.data?.link}`;

            if (this.data?.hasOwnProperty('extra') && this.data?.extra != null) {

                let extra = `&extra=${this.data?.extra}`;

                const modoDemo = localStorage.getItem('modoDemo') === 'true';
                if (modoDemo) {
                    extra += '&modoDemo=true';
                }

                fullUrl = fullUrl + extra;

            }
        }


        this.safeExternalLink = this.getSafeExternalLink(fullUrl);
    }

    getSafeExternalLink(externalLink: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(externalLink);
    }

    loadEvent(event) {
        this.loading = false;
    }

    openTab(path: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

}

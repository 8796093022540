import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailMenuComponent } from './pages/detail/detail-menu.component';
import { MenuComponent } from './pages/menu.component';
import { NgZorroModule } from '../../shared/ng-zorro.module';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { MenuRoutingModule } from './menu-routing.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
    declarations: [DetailMenuComponent, MenuComponent],
    imports: [
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        NzTreeModule,
        MenuRoutingModule,
        FormsModule,
        NzToolTipModule,
    ]
})
export class MenuModule {
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';

@Injectable({
    providedIn: 'root'
})

export class ExternalPageService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/conta', {});
    }

}

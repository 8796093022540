import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from './abstract.service';
import Equipe from '@models/equipe.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class EquipeService extends AbstractService<Equipe> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/equipes', Equipe);
    }

    vincularEquipesObrigacoes(selectedIds: Set<string> | string[], itens, clearBefore = true): Observable<any> {

        const data = {
            ids: Array.from(selectedIds),
            itens,
            limpar: clearBefore
        };

        return this.http.put(`${environment.apiUrl}/calendar/tasks/obrigacoes/equipe`, data, this.httpOptions);
    }

    retornaEquipe(equipeID: string): Observable<any>{
        return this.http.get(`${environment.apiUrl}/cadastros/equipe/${equipeID}`);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

}

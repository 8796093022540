import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {CategoriasService} from './categorias.service';
import {NzModalService} from 'ng-zorro-antd/modal';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-cadastros-categorias',
    templateUrl: './categorias.component.html',
    styleUrls: ['./categorias.component.scss']
})
export class CadastrosCategoriasComponent extends AbstractListTable<any> {

    comboObrigacoes = [];
    comboCategorias = [];
    formFiltros: FormStack;
    formAddCategoria;
    formEditarCategoria;
    qtdFiltrosAtivos = 0;
    editarLoading = {};

    constructor(
        private fb: UntypedFormBuilder,
        private categoriasService: CategoriasService,
        private toastService: ToastrService,
        private perfilService: PerfilService,
        private modalService: NzModalService) {
        super(categoriasService, '/cadastros/obrigacao/categorias', toastService);

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                categoria_id: [null, null],
                obrigacao_id: [null, null],
            })
        };

        this.formAddCategoria = {
            modalVisible: false,
            formGroup: {
                categoria_id: null,
                descricao: null,
                obrigacoes: [],
            }
        };

        this.formEditarCategoria = {
            modalVisible: false,
            formGroup: {
                categoria_id: null,
                descricao: null,
                obrigacoes: [],
            }
        };

        this.retornaSelectCategorias();

        this.retornaSelectObrigacoes();

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.loading = true;

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.abstractService.newListToTable(params, search).subscribe((response) => {

            this.items = response?.data || response;

            this.items.forEach((value) => {
                this.editarLoading[value.categoria_id] = false;
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;
        });

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltros !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    modalFiltrar(visible) {
        this.formFiltros.modalVisible = visible;
    }

    filtrar(): void {

        this.currentParams.filter = [];

        const filtros = this.formFiltros.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.modalFiltrar(false);

        this.queryTable(this.currentParams, this.currentSearch);

    }

    modalAddCategoria(visible) {
        this.formAddCategoria.modalVisible = visible;
    }

    modalEditarCategoria(visible) {
        this.formEditarCategoria.modalVisible = visible;
    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltros.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    retornaSelectCategorias() {
        this.categoriasService.retornaSelectCategorias().subscribe((response) => {
            response.forEach((value) => {
                this.comboCategorias.push({value: value.categoria, label: value.descricao});
            });
        });
    }

    retornaSelectObrigacoes() {
        this.categoriasService.retornaSelectObrigacoes().subscribe((retorno: any) => {
            retorno.forEach((value: any) => {
                this.comboObrigacoes.push({value: value.id, label: value.descricao});
            });
        });
    }

    adicionarCategoria(editar = false) {

        const form = !editar ? this.formAddCategoria.formGroup : this.formEditarCategoria.formGroup;

        this.categoriasService.adicionarCategoria(form).subscribe((response) => {

            if (!editar) {

                this.toastrService.success('Categoria adicionada com sucesso.');

                this.modalAddCategoria(false);

                this.formAddCategoria = {
                    modalVisible: false,
                    formGroup: {
                        categoria_id: null,
                        descricao: null,
                        obrigacoes: [],
                    }
                };

            } else {

                this.toastrService.success(response.message);

                this.modalEditarCategoria(false);

                this.formEditarCategoria = {
                    modalVisible: false,
                    formGroup: {
                        categoria_id: null,
                        descricao: null,
                        obrigacoes: [],
                    }
                };

            }

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    retornaCategoria(id) {

        this.editarLoading[id] = true;

        this.categoriasService.retornaCategoria(id).subscribe((response) => {

            this.editarLoading[id] = false;

            this.formEditarCategoria.formGroup = response;

            this.modalEditarCategoria(true);

        }, (response) => {

            this.editarLoading[id] = false;

            this.toastrService.error(response.error.message);

        });

    }

    showConfirm(id): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a categoria selecionada?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerCategoria(id)
        });

    }

    removerCategoria(id) {

        this.categoriasService.removerCategoria(id).subscribe((response) => {

            this.toastrService.success(response.message);

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

}

import {ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EquipeService} from '@services/equipe.service';
import {UsuarioEquipeService} from '@services/usuarioEquipe.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import Equipe from '@models/equipe.model';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-equipes',
    templateUrl: './equipes.component.html',
    styleUrls: ['./equipes.component.scss']
})
export class EquipesComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {

    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    equipesOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;
    checked = false;

    newVisibilidadeModal = false;
    modalEditarEquipeVisible = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    usuarioOptions: { id: string; nome: string }[] = [];
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalFiltrarVisible = false;
    formFiltrar: UntypedFormGroup;
    formNovaEquipe: UntypedFormGroup;
    formAlterarEquipes: UntypedFormGroup;
    cadastrando = false;

    statusCard = false;

    expandSet = new Set<number>();

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    alterandoEquipeUsuario = '';

    formEditarEquipe: UntypedFormGroup;

    loadingEditarEquipe = false;

    erro = false;
    mensagem = '';
    loadingForm = false;

    qtdFiltrosAtivos = 0;

    totUser = 0;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioEquipeService: UsuarioEquipeService,
        private toastService: ToastrService,
        private equipeService: EquipeService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private el: ElementRef,
        private tabService: TabService,
        private zone: NgZone) {
        super(usuarioEquipeService, Usuario, toastService);


        this.formEditarEquipe = this.fb.group({
            id: [null, null],
            nome: [null, [Validators.required]],
            usuarios: [null, null]
        });

        this.formNovaEquipe = this.fb.group({
            nome: [null, null],
        });

        this.formFiltrar = this.fb.group({
            nome: [null, null],
            email: [null, null],
            login: [null, null],
            equipe: [null, null],
            created_at_de: [null, null],
            created_at_ate: [null, null],
        });


        this.formAlterarEquipes = this.fb.group({
            equipesSelecionadas: [null, null],
            limparEquipes: [null, null],
        });

    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.toggleParams();
    }

    ngOnInit() {
        this.getEquipes();
        this.usuarioEquipeService.retornaComboUsuarios().subscribe((responseCombo) => {
            this.usuarioOptions = [];
            this.usuarioOptions = responseCombo;
        });
    }


    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getEquipes() {

        return this.equipeService.listToSelect().subscribe((result) => {
            this.equipeOptions = result.map((option: any) => ({
                label: option.nome,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.equipesOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarEquipe() {
        if (this.checkedItems.size > 0) {
            this.getEquipes();
            this.formAlterarEquipes.get('equipesSelecionadas').setValue([]);
            this.displayVisibilidadeModal = true;
            this.refreshCheckedStatus();
        }
    }

    alterarEquipeIndividual(usuarioID: string) {
        this.getEquipes();
        this.formAlterarEquipes.get('equipesSelecionadas').setValue([]);
        this.displayVisibilidadeModal = true;
        this.alterandoEquipeUsuario = usuarioID;
    }

    saveBulkToEquipes() {

        let arrayUsuarios: any[] = [];
        if (this.alterandoEquipeUsuario) {
            arrayUsuarios.push(this.alterandoEquipeUsuario);
        } else {
            arrayUsuarios = Array.from(this.checkedItems);
        }

        if (arrayUsuarios.length > 0) {
            const selectedEquipes = this.formAlterarEquipes.value.equipesSelecionadas;
            const limparEquipes = this.formAlterarEquipes.value.limparEquipes;
            this.usuarioEquipeService.vincularEquipesUsuarios(arrayUsuarios, selectedEquipes, limparEquipes)
                .subscribe(
                    () => {
                        this.toastrService.success(`Alteração em massa realizada!`);
                        this.formAlterarEquipes.get('equipesSelecionadas').setValue([]);
                        this.displayVisibilidadeModal = false;
                        this.updateTable();
                        this.alterandoEquipeUsuario = '';
                    },
                    (response) => {
                        this.alterandoEquipeUsuario = '';
                        this.toastrService.error(response.error.message);
                    }
                );
        } else {
            this.toastrService.error('Selecione um usuário');
        }

    }

    saveBulkToProfiles() {
        this.usuarioEquipeService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
        this.alterandoEquipeUsuario = '';
    }

    addNewEquipe() {
        this.newVisibilidadeModal = true;
    }

    saveNewEquipe() {

        const newEquipe = this.formNovaEquipe.value.nome;
        if (!Utils.isEmpty(newEquipe)) {
            const selectedEquipes = [];
            this.equipeService.save({nome: newEquipe} as Equipe).subscribe((res: any) => {

                this.toastrService.success('Equipe gravada com sucesso');
                // this.dataVisibilityForm.data.push(res.data);

                selectedEquipes.push(res.id);
                this.formAlterarEquipes.get('equipesSelecionadas').setValue(selectedEquipes);
                this.getEquipes();
                this.newVisibilidadeModal = false;
                this.queryTable(this.currentParams, this.currentSearch);


            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewEquipe() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    confirmaFiltrar() {

        this.currentParams.pageIndex = 1;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {

                if (chave === 'created_at_de' || chave === 'created_at_ate') {
                    valor = this.equipeService.formataDateBD(valor.toString());
                }
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    modalFiltrar(visible) {

        this.modalFiltrarVisible = visible;
        this.cadastrando = false;
    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.value.nome) {
            qtd += 1;
        }
        if (this.formFiltrar.value.email) {
            qtd += 1;
        }
        if (this.formFiltrar.value.login) {
            qtd += 1;
        }
        if (this.formFiltrar.value.equipe) {
            qtd += 1;
        }
        if (this.formFiltrar.value.created_at_de) {
            qtd += 1;
        }

        if (this.formFiltrar.value.created_at_ate) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.zone.run(() => {
            this.currentParams = params;

            this.calculaBadgeFiltros();

            this.loading = true;

            this.abstractService.listToTable(this.currentParams, this.currentSearch).subscribe((response) => {

                this.checkedItems.clear();

                this.totUser = 0;
                this.items = [];
                this.items = response?.data || response;

                this.items.forEach((item) => {
                    this.expandSet.add(item.key);

                    item.itens.forEach(integrante => {
                        this.totUser = integrante.id ? this.totUser + 1 : this.totUser;
                    });
                });

                this.pagination = new Pagination(
                    response?.per_page || 1,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 1,
                    response?.from || 1,
                    response?.to || 1);

                this.refreshCheckedStatus();

                setTimeout(() => {
                    this.loading = false;
                    this.toggleParams();
                    this.cdr.detectChanges();
                }, 100);
            }, error => {
                this.loading = false;
                this.toastService.error(error.error.message);
            });
        });

    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }

        this.toggleParams();
    }

    toggleParams() {

        if (!this.loading) {
            setTimeout(() => {
                this.column1Width = this.column1.nativeElement.offsetWidth + 20;
                this.column2Width = this.column2.nativeElement.offsetWidth;
                this.column4Width = this.column4.nativeElement.offsetWidth + 20;
                this.column5Width = this.column5.nativeElement.offsetWidth;
                this.column6Width = this.column6.nativeElement.offsetWidth;

                this.cdr.detectChanges();
            }, 100);

        }
    }

    onAllChecked(checked: boolean): void {

        this.items.forEach((item) => {
            item.itens.forEach(({id}) => {
                this.updateCheckedSet(id, checked);
            });
        });

    }

    showConfirm(idUsuario: string, idEquipe: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o vínculo Usuário - Equipe?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removeEquipeUsuario(idUsuario, idEquipe)
        });

    }

    showConfirmEquipe(idEquipe: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a Equipe?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removeEquipe(idEquipe)
        });

    }

    removeEquipe(idEquipe: string) {

        this.usuarioEquipeService.removerEquipe(idEquipe).subscribe((response) => {

            this.toastrService.success(response.message);
            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {
            this.toastrService.error(response.error.message);
        });
    }

    removeEquipeUsuario(idUsuario: string, idEquipe: string) {

        this.usuarioEquipeService.removerEquipeUsuario(idUsuario, idEquipe).subscribe((response) => {

            this.toastrService.success(response.message);

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {
            this.toastrService.error(response.error.message);
        });


    }

    modalCadastrar(visible: boolean, zerar: boolean = false) {
        this.addNewEquipe();
    }

    onEditarEquipe(equipeID: string): void {
        this.loadingForm = true;

        this.equipeService.retornaEquipe(equipeID).subscribe((response) => {
            this.formEditarEquipe.get('id').setValue(response.message.id);
            this.formEditarEquipe.get('nome').setValue(response.message.nome);

            // Usuários
            const arrayUsuarios = [];
            response.message.usuarios.forEach((value) => {
                arrayUsuarios.push(value.id);
            });
            this.formEditarEquipe.get('usuarios').setValue(arrayUsuarios);

            this.loadingForm = false;


        });


        this.modalEditarEquipe(true);


    }

    modalEditarEquipe(visible: boolean): void {

        this.modalEditarEquipeVisible = visible;
    }

    confirmarEditarEquipe(): void {


        this.validaFormEditarEquipe();

        // this.loadingEditarEquipe = true;

        const dados = {
            nome: this.formEditarEquipe.value.nome,
            usuarios: this.formEditarEquipe.value.usuarios
        };

        const arrayUsuarios = this.formEditarEquipe.value.usuarios;

        const arrayEmpresas = [];
        arrayEmpresas.push(this.formEditarEquipe.value.id);

        const id = this.formEditarEquipe.value.id;

        if (!this.erro) {
            this.usuarioEquipeService.editarEquipe(id, dados).subscribe((response) => {
                this.toastService.success(response.message);
                this.modalEditarEquipe(false);
                this.loadingEditarEquipe = false;
                this.queryTable(this.currentParams, this.currentSearch);
            }, (response) => {
                this.toastService.error(response.message);
                this.loadingEditarEquipe = false;
            });


        } else {
            this.loadingEditarEquipe = false;
            this.toastService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }

    }

    validaFormEditarEquipe(): void {
        if (!this.formEditarEquipe.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Descrição Obrigatório';
        }
    }

    btnResetSearch() {
        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);
        } else {

            this.checkedItems.delete(id);
        }

        if (this.totUser !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class DecCndService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/calendar/tasks', {});
    }

    contadoresCabecalho(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/terceiros/simples-cabecalho`);

    }

    table(params: any, procurar): Observable<any> {

        const filtros = params && params.filter ? params.filter : [];


        Object.entries(filtros).forEach(([key, value]) => {
            if (value === null) {
                delete filtros[key];
            } else if (filtros[key] instanceof Date) {
                filtros[key] = Helpers.dataToFilter(filtros[key]);
            }
        });

        if (procurar) {
            filtros.procurar = procurar;
        }

        const order: any = params && params.sort ? this.montaOrder(params.sort) : [];

        if (order.ordenar && order.sentido) {
            filtros.ordenar = order.ordenar;
            filtros.sentido = order.sentido;
        }

        if (params && params.pageIndex) {
            filtros.pagina = params.pageIndex;
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/terceiros/simples${queryStr}`);

    }

    exportPlanilha(filtros, procurar: string = null, baseUrl = null): Observable<any> {
        // datas já formatadas no form

        Object.entries(filtros).forEach(([key, value]) => {
            if (value === null) {
                delete filtros[key];
            }
        });

        filtros.tipoExportador = 'xlsx';
        filtros.tipo = 'terceiroSimples';
        if (procurar !== null && typeof procurar !== 'undefined') {
            filtros.procurar = procurar;
        }

        const queryString = Helpers.montaQueryString(filtros);

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.post(`${prefix}${queryString}`, {});

    }

    downloadModelo(): any {

        return this.http.get(`${environment.apiUrl}/terceiros/simples/exportar/modelo`,
            {responseType: 'blob'});
    }

    atualizarSimples(cnpjs): any {

        return this.http.post(`${environment.apiUrl}/terceiros/simples/reprocessar`, {cnpjs});
    }

    uploadPlanilha(formData: FormData): Observable<any> {

        return this.http.post(`${environment.apiUrl}/terceiros/simples/upload`, formData);
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    montaOrder(parametros): any {
        let order = {};

        parametros.forEach((value) => {

            if (value.value) {
                order = {
                    ordenar: value.key,
                    sentido: value.value === 'ascend' ? 'ascend' : 'descend'
                };
            }
        });

        return order;

    }

}

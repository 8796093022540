import BulkChanges from '@models/entity.model';

export class Profile implements BulkChanges {
    id: string;
    descricao: string;
    tipo: string;
    itens: [];

    public constructor(obj?: any) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
        }
    }

    convertToSave() {
        const properties = ['id', 'descricao', 'itens'];
        const profile = new Profile();
        for (const prop of properties) {
            profile[prop] = this[prop];
        }
        return profile;
    }

    toBulk(modifierID: string, ids: string[]) {

        return {
            perfil_id: modifierID,
            usuarios: ids
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
    }
}

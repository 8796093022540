import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ObrigacoesPrincipaisComponent} from './obrigacoesPrincipais.component';

const routes: Routes = [

    {path: '', component: ObrigacoesPrincipaisComponent}];

@NgModule({
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class ObrigacoesPrincipaisRoutingModule {
}

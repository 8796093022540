<nz-tabset style="min-height: 43px; width: 100%" (nzSelectChange)="selectTab($event)"
           [nzSelectedIndex]="selectedTab" nzHideAdd nzType="editable-card">
    <nz-tab [nzForceRender]="true" *ngFor="let tab of getTabs() | async"
            (dragend)="sotarForaDaLixeira($event)"
            [nzTitle]="tabTitle" [nzDisabled]="alterandoTab">
        <ng-template #tabTitle>
            <span class="txt-branco">
                {{tab?.title != 'Embedded' ? tab?.title : tabEmbedded?.title}}
            </span>
            <a style="color: #fff" nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'bottomLeft'"
               [nzDisabled]="tabDragedId != null">
                <span *ngIf="!telasPesadas.includes(tab.url) || (!alterandoTab && telasPesadas.includes(tab.url))"
                      class="tabMore" nz-icon nzType="more" nzTheme="outline"></span>
                <span *ngIf="alterandoTab && telasPesadas.includes(tab.url)" class="tabMore" nz-icon nzType="loading"
                      nzTheme="outline"></span>
            </a>

        </ng-template>
        <!--        *ngIf="tabs[selectedTab].id == tab.id"-->
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <!--                *ngIf="tabs && tabs[selectedTab]?.urlType === 'nativo'"-->
                <li class="flex-tab" nz-menu-item (click)="reloadTab(tab)">
                    <span>Recarregar</span>
                    <i class="fa-fw fas fa-redo"></i>
                </li>
                <li nz-menu-divider></li>
                <div style="min-width: 220px">
                    <li class="flex-tab" nz-menu-item (click)="openInNew(tab)">
                        <span>Abrir em nova guia</span>
                        <i class="fa-fw far fa-window-restore"></i>
                    </li>
                </div>
                <li nz-menu-divider></li>
                <li class="flex-tab" nz-menu-item (click)="removeTabById(tab.id)">
                    <span>Fechar</span>
                    <i class="fa-fw fas fa-times"></i>
                </li>
                <li *ngIf="tabs.length > 1" class="flex-tab" nz-menu-item (click)="removeOthersTabs(tab.id)">
                    <span>Fechar outras abas</span>
                    <i class="fa-fw far fa-window-close"></i>
                </li>
                <li class="flex-tab" nz-menu-item (click)="removeAllTabs()">
                    <span>Fechar todas abas</span>
                    <i class="fa-fw fas fa-window-close"></i>
                </li>
            </ul>
        </nz-dropdown-menu>
    </nz-tab>
</nz-tabset>

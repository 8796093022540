import {Component, ComponentFactoryResolver, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ContentContainerDirective} from './content-container.directive';
import {SkeletonInterface} from './skeleton.interface';
import {Tab} from '@models/tab.model';
import {componentsClassConst} from 'src/app/shared/constants/componentsClass.const';

@Component({
    selector: 'app-tab-content',
    template: '<ng-template content-container></ng-template>'
})
export class TabContentComponent implements OnChanges {
    @Input() tab;
    @ViewChild(ContentContainerDirective, {static: true})
    contentContainer: ContentContainerDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }


    ngOnChanges(changes: SimpleChanges) {
        const tab: Tab = this.tab;

        if (tab.componentName && componentsClassConst[tab.componentName]) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                componentsClassConst[tab.componentName].component
            );

            const componentRef = this.contentContainer.viewContainerRef.createComponent(componentFactory);
            (componentRef.instance as SkeletonInterface).data = tab.data;
        }
    }

}

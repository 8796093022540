import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ConnectDownloadService} from './connectDownload.service';
import * as fileSaver from 'file-saver-es';

@Component({
    selector: 'app-connectDownload',
    templateUrl: './connectDownload.component.html',
    styleUrls: ['./connectDownload.component.scss']
})
export class ConnectDownloadComponent implements OnInit {

    loadingConnect = false;

    versoes = {
        windows: '',
        arquivo_windows: '',
        linux: '',
        arquivo_linux: '',
    };


    constructor(
        private toastService: ToastrService,
        private connectService: ConnectDownloadService) {
    }

    ngOnInit() {
        this.connectService.versoes().subscribe((res) => {
            this.versoes = res;
        }, (res) => {
            this.toastService.error('Erro ao obter versões.');
        });
    }

    downloadAgent(sistema) {

        let arquivo: string;

        switch (sistema) {

            case 'windows' :
                arquivo = this.versoes.arquivo_windows;
                break;

            case 'macOs' :
                arquivo = this.versoes.arquivo_linux;
                break;

            case 'linux' :
                arquivo = this.versoes.arquivo_linux;
                break;

        }

        window.open(`https://atm-mastertax.s3.sa-east-1.amazonaws.com/download/agent/${arquivo}`, '_blank').focus();
    }


    downloadConnect() {

        this.loadingConnect = true;

        this.connectService.downloadConnect();

        setTimeout(() => {
            this.loadingConnect = false;
        }, 1000);

    }


}

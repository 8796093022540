import {
    Component,
    ViewChild,
    ElementRef,
    ChangeDetectorRef, NgZone
} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzCarouselComponent} from 'ng-zorro-antd/carousel';
import {NzModalService} from 'ng-zorro-antd/modal';
import * as Highcharts from 'highcharts';
import {LOCALE_ID} from '@angular/core';
import {ComplianceObrgacoesService} from './complianceObrgacoes.service';
import * as fileSaver from 'file-saver-es';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from "rxjs";


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

interface DataItem {
    controleCertidaoProcessamento_id: string;
    created_at: string;
    descricao: string;
    emissao: string;
    id: string;
    identificador: string;
    status: number;
    tipoEmissao: string;
}


@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './complianceObrigacoes.component.html',
    providers: [
        NzMessageService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./complianceObrigacoes.component.scss']
})

export class ComplianceObrigacoesComponent extends AbstractListTable<any> {

    subscription: Subscription;

    loadingContadores: boolean;
    loadingCardTabs: boolean;
    loadingCardHighcharts: boolean;
    loadingExportar: boolean;

    arrayMeses = [];

    objectMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    cardCompetenciaAtivo: number;

    objectCountersValues = {
        qtdUnidades: 0,
        qtdObrigacoes: 0,
        qtdAusencias: 0,
        porcAusencias: 0,
        qtdDivergencias: 0,
        porcDivergencias: 0,
        mesSelecionado: '',
        anoSelecionado: '',
        updated_at: null
    };

    cardHighchartsAnoSelecionado: string;

    arrayAnos: [string, string];
    arrayTableObrigacoes = [];

    expandSet = new Set<number>();
    expandSetDivergencias = new Set<number>();
    expandSetAusencias = new Set<number>();

    totaisPrincipais: any = false;
    totaisAcessorias: any = false;

    mes: string;
    ano: string;

    mesSelecionado: number;
    anoSelecionado: number;
    fonteMaior = '2.7em';
    fonteMenor = '2.2em';

    objectAno = {
        ano: null,
        show: null,
        unidades: null,
        obrigacoes: null,
        ausencias: null,
        divergencias: null,
        porcAusencias: null,
        porcDivergencias: null,
        dataAtualizacao: null,
        updated_at: null
    };

    carregando = true;

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;

    arrayTableDivergencias: any = [];

    arrayTableAusencias: any = [];

    status = false;

    formFiltrar: FormStack;

    gravando = false;

    empresa = '';
    cnpj = '';
    obrigacao = '';
    ocorrencia = '';
    ie = '';

    updateChartFlag = false;

    tabAtiva = 0;

    collapseCard = false;

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild('column13') column13: ElementRef;
    column13Width = 0;

    @ViewChild('column14') column14: ElementRef;
    column14Width = 0;

    @ViewChild('column15') column15: ElementRef;
    column15Width = 0;

    @ViewChild('column16') column16: ElementRef;
    column16Width = 0;

    @ViewChild('column17') column17: ElementRef;
    column17Width = 0;

    @ViewChild('column18') column18: ElementRef;
    column18Width = 0;

    @ViewChild('column19') column19: ElementRef;
    column19Width = 0;

    @ViewChild('column20') column20: ElementRef;
    column20Width = 0;

    @ViewChild('column21') column21: ElementRef;
    column21Width = 0;

    @ViewChild('column22') column22: ElementRef;
    column22Width = 0;

    @ViewChild('column23') column23: ElementRef;
    column23Width = 0;

    displayObservacaoModal = false;
    arrayTableObservacao = [];

    jutificadoComoSemMovimento = false;

    tipoOcorrencia;
    idOcorrencia;
    loadingObservacao = false;

    loadingsObservacao = {};
    loadingsObservacaoAdicionar = false;
    loadingsObservacaoEditar = false;
    loadingsObservacaoExcluir = {};

    displayObservasaoModal = false;
    flagEditarObservacao: any = false;
    observacaoValue = '';
    observacaoSelecionada = '';

    listOfColumn = [
        {
            title: 'Descrição',
            sortFn: (a: DataItem, b: DataItem) => a.tipoEmissao.localeCompare(b.tipoEmissao),
            priority: 1,
            align: 'center'
        },
        {
            title: 'Criado Em',
            sortFn: (a: DataItem, b: DataItem) => a.created_at.localeCompare(b.created_at),
            priority: 5,
            align: 'center'
        },
        {
            title: '',
            compare: null,
            priority: false,
            align: 'center'
        }
    ];

    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectIe = [];
    arraySelectObrigacoes = [];
    arraySelectOcorrencias = [];

    @ViewChild(NzCarouselComponent, {static: false}) myCarousel!: NzCarouselComponent;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    arrayVazio = true;

    qtdFiltrosAtivos = 0;

    expandCards = false;

    loadingJustificar = false;

    constructor(
        private complianceObrigacoesService: ComplianceObrgacoesService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private fb: UntypedFormBuilder,
        private tabService: TabService,
        private toastService: ToastrService,
    ) {

        super(complianceObrigacoesService, null, toastService);

        this.complianceObrigacoesService = complianceObrigacoesService;

        this.loadingContadores = true;
        this.loadingCardTabs = true;
        this.loadingCardHighcharts = true;

        this.complianceObrigacoesService.retornaCompetencias().subscribe((response: any) => {

            this.arrayAnos = response.anos;

            for (const mes of Object.entries(response.dados)) {
                this.arrayMeses.push(mes[1]);
            }

            let verificaId = 1;

            for (const mes of this.arrayMeses) {

                if (this.arrayMeses[this.arrayMeses.length - verificaId].id === null) {

                    verificaId++;

                } else {

                    this.arrayVazio = false;

                    this.mesSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].mes;
                    this.anoSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].ano;

                    this.objectCountersValues = this.arrayMeses[this.arrayMeses.length - verificaId];
                    this.objectCountersValues.mesSelecionado = this.objectMeses[this.mesSelecionado - 1].toUpperCase();
                    this.objectCountersValues.anoSelecionado = this.anoSelecionado.toString();

                    if (this.objectCountersValues.qtdAusencias > 99999 && this.objectCountersValues.qtdDivergencias > 99999 &&
                        this.objectCountersValues.qtdObrigacoes > 99999) {
                        this.fonteMaior = 'x-large';
                        this.fonteMenor = '2em';
                    } else {
                        this.fonteMaior = '2.7em';
                        this.fonteMenor = '2.2em';
                    }

                    this.cardHighchartsAnoSelecionado = this.anoSelecionado.toString();

                    setTimeout(() => {
                        this.cardCompetenciaAtivo = this.arrayMeses.length - verificaId;
                        this.collapseCard = true;
                    }, 1000);

                    setTimeout(() => {
                        this.loadingContadores = false;
                    }, 1200);

                    this.cdr.detectChanges();

                    this.complianceObrigacoesService.retornaObrigacoes(
                        this.mesSelecionado.toString(),
                        this.anoSelecionado.toString(),
                    ).subscribe((retorno: any) => {

                        this.montaTabelaObrigacoes(retorno, callback => {
                            this.toggleParamsCardTabs(callback);
                        });

                    });

                    this.complianceObrigacoesService.retornaAno(this.anoSelecionado).subscribe(data => {

                        this.objectAno = data.dados;

                        this.objectCountersValues.updated_at = this.objectAno.updated_at;

                        this.loadingCardHighcharts = false;

                        this.cdr.detectChanges();

                        this.atualizaGrafico(data.grafico);

                    });

                    return;

                }

            }

            if (this.arrayVazio) {

                verificaId = 1;

                this.mesSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].mes;
                this.anoSelecionado = this.arrayMeses[this.arrayMeses.length - verificaId].ano;

                this.objectCountersValues = this.arrayMeses[this.arrayMeses.length - verificaId];
                this.objectCountersValues.mesSelecionado = this.objectMeses[this.mesSelecionado - 1].toUpperCase();
                this.objectCountersValues.anoSelecionado = this.anoSelecionado.toString();

                this.cardHighchartsAnoSelecionado = this.anoSelecionado.toString();

                setTimeout(() => {
                    this.cardCompetenciaAtivo = this.arrayMeses.length - verificaId;
                    this.collapseCard = true;
                }, 1000);

                this.loadingContadores = false;
                this.loadingCardTabs = false;
                this.loadingCardHighcharts = false;

                this.toastrService.warning('Não há dados para exibição.');

                this.cdr.detectChanges();

            }

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                cnpj: [null, null],
                ie: [null, null],
                obrigacao: [null, null],
                ocorrencia: [null, null]
            })
        };

        this.complianceObrigacoesService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {

            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
            this.arraySelectIe = retorno.empresaIe;

        });

        this.complianceObrigacoesService.retornarSelectsObrigacoes(
        ).subscribe((retorno: any) => {

            this.arraySelectObrigacoes = retorno.obrigacoes;

        });

        this.complianceObrigacoesService.retornarSelectsOcorrencias(
        ).subscribe((retorno: any) => {

            this.arraySelectOcorrencias = retorno.ocorrencias;

        });
    }

    montaTabelaObrigacoes(retorno, callback) {

        this.arrayTableObrigacoes = [];

        for (const dataItem of Object.entries(retorno)) {
            let label = '';
            let obrigacoes = [];
            const totais = {
                ausencias: 0,
                divergencias: 0,
                obrigacoes: 0,
                porcAusencias: 0,
                porcDivergencias: 0
            };

            switch (dataItem[0]) {

                case 'acessorias':

                    label = 'Acessórias';

                    obrigacoes = retorno.acessorias.length ? retorno.acessorias : [];

                    retorno.acessorias.forEach((value) => {
                        totais.obrigacoes = totais.obrigacoes + value.obrigacoes;
                        totais.ausencias = totais.ausencias + value.ausencias;
                        totais.divergencias = totais.divergencias + value.divergencias;
                    });

                    this.totaisAcessorias = totais.obrigacoes;

                    break;

                case 'principais':

                    label = 'Principais';

                    obrigacoes = retorno.principais.length ? retorno.principais : [];

                    retorno.principais.forEach((value) => {

                        totais.obrigacoes = totais.obrigacoes + value.obrigacoes;
                        totais.ausencias = totais.ausencias + value.ausencias;
                        totais.divergencias = totais.divergencias + value.divergencias;
                    });

                    this.totaisPrincipais = totais.obrigacoes;

                    break;

            }

            totais.porcAusencias = Math.round(this.getPercentage(totais.ausencias, totais.obrigacoes));
            totais.porcDivergencias = Math.round(this.getPercentage(totais.divergencias, totais.obrigacoes));

            this.arrayTableObrigacoes.push({
                id: this.generateId(),
                label,
                obrigacoes,
                totais
            });
        }

        callback(true);

    }

    montaTabelaDivergencias(retorno, callback) {

        this.arrayTableDivergencias = [];
        this.arrayTableDivergencias = retorno;

        callback(true);

    }

    montaTabelaAusencias(retorno, callback) {

        this.arrayTableAusencias = [];
        this.arrayTableAusencias = retorno;

        callback(true);

    }

    toggleParamsCardTabs(callback) {

        if (callback) {

            this.loadingCardTabs = false;

            if (this.tabAtiva === 0) {

                setTimeout(() => {
                    this.column1Width = this.column1.nativeElement.offsetWidth;
                    this.column2Width = this.column2.nativeElement.offsetWidth;
                    this.column3Width = this.column3.nativeElement.offsetWidth;
                    this.column4Width = this.column4.nativeElement.offsetWidth;
                    this.column5Width = this.column5.nativeElement.offsetWidth;
                    this.column6Width = this.column6.nativeElement.offsetWidth;
                    this.column7Width = this.column7.nativeElement.offsetWidth;
                    this.column8Width = this.column8.nativeElement.offsetWidth;
                }, 100);

            }

            if (this.tabAtiva === 1) {

                setTimeout(() => {
                    this.column16Width = this.column16.nativeElement.offsetWidth;
                    this.column17Width = this.column17.nativeElement.offsetWidth;
                    this.column18Width = this.column18.nativeElement.offsetWidth;
                    this.column19Width = this.column19.nativeElement.offsetWidth;
                    this.column20Width = this.column20.nativeElement.offsetWidth;
                    this.column21Width = this.column21.nativeElement.offsetWidth;
                }, 100);

            }

            if (this.tabAtiva === 2) {

                setTimeout(() => {
                    this.column9Width = this.column9.nativeElement.offsetWidth;
                    this.column10Width = this.column10.nativeElement.offsetWidth;
                    this.column11Width = this.column11.nativeElement.offsetWidth;
                    this.column13Width = this.column13.nativeElement.offsetWidth;
                    this.column14Width = this.column14.nativeElement.offsetWidth;
                    this.column15Width = this.column15.nativeElement.offsetWidth;
                    this.column23Width = this.column23.nativeElement.offsetWidth;
                }, 100);

            }

            this.cdr.detectChanges();

        }

    }

    onExpandChange(id: number, checked: boolean): void {

        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;
        this.column7Width = this.column7.nativeElement.offsetWidth;

        this.cdr.detectChanges();
    }

    onExpandChangeDivergencias(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetDivergencias.add(id);
        } else {
            this.expandSetDivergencias.delete(id);
        }

        this.column16Width = this.column16.nativeElement.offsetWidth;
        this.column17Width = this.column17.nativeElement.offsetWidth;
        this.column18Width = this.column18.nativeElement.offsetWidth;
        this.column19Width = this.column19.nativeElement.offsetWidth;
        this.column20Width = this.column20.nativeElement.offsetWidth;
        this.column21Width = this.column21.nativeElement.offsetWidth;

        this.cdr.detectChanges();

    }

    onExpandChangeAusencias(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetAusencias.add(id);
        } else {
            this.expandSetAusencias.delete(id);
        }

        this.column9Width = this.column9.nativeElement.offsetWidth;
        this.column10Width = this.column10.nativeElement.offsetWidth;
        this.column11Width = this.column11.nativeElement.offsetWidth;
        this.column13Width = this.column13.nativeElement.offsetWidth;
        this.column14Width = this.column14.nativeElement.offsetWidth;
        this.column15Width = this.column15.nativeElement.offsetWidth;
        this.column23Width = this.column23.nativeElement.offsetWidth;

        this.cdr.detectChanges();
    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    clickEvent() {
        this.status = !this.status;
    }

    formatOne = (percent: number) => `${percent}%`;

    mudarAno(ano: string) {

        this.cardHighchartsAnoSelecionado = ano;

        this.complianceObrigacoesService.retornaAno(ano).subscribe(data => {

            this.objectAno = data.dados;

            this.loadingCardHighcharts = false;

            this.cdr.detectChanges();

            this.atualizaGrafico(data.grafico);

        });

        let continuar = true;

        this.arrayMeses.forEach((competencia, index) => {

            const anoLoop = competencia.ano;

            if (continuar) {

                if (anoLoop.toString() === ano && competencia.id != null) {

                    continuar = false;

                    this.cardCompetenciaAtivo = index;

                    this.mesSelecionado = this.arrayMeses[index].mes;
                    this.anoSelecionado = this.arrayMeses[index].ano;

                    this.objectCountersValues = this.arrayMeses[index];
                    this.objectCountersValues.mesSelecionado = this.objectMeses[this.arrayMeses[index].mes - 1].toUpperCase();
                    this.objectCountersValues.anoSelecionado = this.arrayMeses[index].ano.toString();

                    this.updateObrigacoes(this.arrayMeses[index].mes, this.arrayMeses[index].ano);

                    if (this.tabAtiva === 1) {
                        this.updateDivergencias(this.arrayMeses[index].mes, this.arrayMeses[index].ano);

                    } else if (this.tabAtiva === 2) {
                        this.updateAusencias(this.arrayMeses[index].mes, this.arrayMeses[index].ano);

                    }
                }

            }

        });

    }

    atualizaGrafico(serie: any) {

        this.chartOptions = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: undefined
            },
            xAxis: [{
                categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                crosshair: true,
            }],
            yAxis: [{
                title: {
                    text: 'Porcentagem',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                },
                labels: {
                    format: '{value}%',
                },
            }, {
                title: {
                    text: 'Obrigações',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                labels: {
                    format: '',
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 60,
                verticalAlign: 'top',
                y: 0,
                floating: true,
                backgroundColor: '#FFFFFF'
            },
            series: serie
        };

        this.updateChartFlag = true;

        this.cdr.detectChanges();

    }

    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined) && this.arrayMeses[event.clickedIndex].id !== null) {

            this.loadingContadores = true;
            this.loadingCardHighcharts = true;

            this.cardCompetenciaAtivo = event.clickedIndex;

            if (this.arrayMeses[event.clickedIndex].ano !== Number(this.anoSelecionado)) {
                this.mudarAno(this.arrayMeses[event.clickedIndex].ano);
            }

            this.mesSelecionado = this.arrayMeses[event.clickedIndex].mes;
            this.anoSelecionado = this.arrayMeses[event.clickedIndex].ano;

            this.objectCountersValues = this.arrayMeses[event.clickedIndex];
            this.objectCountersValues.mesSelecionado = this.objectMeses[this.arrayMeses[event.clickedIndex].mes - 1].toUpperCase();
            this.objectCountersValues.anoSelecionado = this.arrayMeses[event.clickedIndex].ano.toString();

            this.objectCountersValues.updated_at = this.arrayMeses[event.clickedIndex].updated_at;

            this.loadingContadores = false;
            this.loadingCardHighcharts = false;

            this.cdr.detectChanges();

            this.changeTabs({index: this.tabAtiva});

        }
    }

    updateObrigacoes(mes: number, ano: number) {
        this.subscription?.unsubscribe();

        this.subscription = this.complianceObrigacoesService.retornaObrigacoes(
            mes.toString(),
            ano.toString()
        ).subscribe((retorno: any) => {

            this.montaTabelaObrigacoes(retorno, callback => {
                this.toggleParamsCardTabs(callback);
            });

        });

    }

    updateDivergencias(mes: number, ano: number) {
        this.subscription?.unsubscribe();

        const filtros = this.formFiltrar.formGroup.value;

        this.calculaBadgeFiltros();

        this.subscription = this.complianceObrigacoesService.retornaDivergencias(mes.toString(), ano.toString(), filtros).subscribe((retorno: any) => {

            this.montaTabelaDivergencias(retorno, (callback) => {
                this.toggleParamsCardTabs(callback);
            });

        });

    }

    updateAusencias(mes: number, ano: number) {
        this.subscription?.unsubscribe();

        this.loadingCardTabs = true;

        this.calculaBadgeFiltros();

        this.subscription = this.complianceObrigacoesService.retornaAusencias(
            mes.toString(),
            ano.toString(),
            this.formFiltrar.formGroup.value
        ).subscribe((retorno: any) => {
            this.loadingCardTabs = false;
            this.montaTabelaAusencias(retorno, (callback) => {
                this.toggleParamsCardTabs(callback);
            });

        });

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 2;
        }
        if (this.formFiltrar.formGroup.value.cnpj) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.ie) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.obrigacao) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.ocorrencia) {
            qtd += 1;
        }


        this.qtdFiltrosAtivos = qtd;
    }


    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    changeTabs(event: any) {

        this.formFiltrar.formGroup.reset();
        this.qtdFiltrosAtivos = 0;

        this.loadingCardTabs = true;
        this.exibirTudo(false);

        switch (event.index) {
            case 0:
                this.updateObrigacoes(this.mesSelecionado, this.anoSelecionado);
                break;
            case 1:
                this.updateDivergencias(this.mesSelecionado, this.anoSelecionado);
                break;
            case 2:
                this.updateAusencias(this.mesSelecionado, this.anoSelecionado);
                break;

        }

    }

    abrirModal(formulario: FormStack): void {

        formulario.modalVisible = true;

    }

    filtrar(): any {

        switch (this.tabAtiva) {
            case 0:
                this.updateObrigacoes(this.mesSelecionado, this.anoSelecionado);
                break;
            case 1:
                this.updateDivergencias(this.mesSelecionado, this.anoSelecionado);
                break;
            case 2:
                this.updateAusencias(this.mesSelecionado, this.anoSelecionado);
                break;

        }

        this.formFiltrar.modalVisible = false;
    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.arrayMeses.length - 1) {
                    this.cardCompetenciaAtivo = this.arrayMeses.length - 1;
                }

                break;

        }

        if (!this.arrayVazio) {

            this.loadingContadores = true;
            this.loadingCardTabs = true;
            this.loadingCardHighcharts = true;

            if (this.arrayMeses[this.cardCompetenciaAtivo].ano !== Number(this.anoSelecionado)) {
                this.mudarAno(this.arrayMeses[this.cardCompetenciaAtivo].ano);
            }

            this.mesSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].mes;
            this.anoSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].ano;

            this.objectCountersValues = this.arrayMeses[this.cardCompetenciaAtivo];
            this.objectCountersValues.mesSelecionado = this.objectMeses[this.arrayMeses[this.cardCompetenciaAtivo].mes - 1].toUpperCase();
            this.objectCountersValues.anoSelecionado = this.arrayMeses[this.cardCompetenciaAtivo].ano.toString();

            this.loadingContadores = false;
            this.loadingCardHighcharts = false;

            this.changeTabs({index: this.tabAtiva});

        }

    }

    export() {

        this.loadingExportar = true;

        let tabela;

        switch (this.tabAtiva) {

            case 0:
                tabela = 'obrigacoes';
                break;

            case 1:
                tabela = 'divergencias';
                break;

            case 2:
                tabela = 'ausencias';
                break;

        }

        const filtros = this.formFiltrar.formGroup.value;

        this.complianceObrigacoesService.exportExcel(tabela, this.mesSelecionado, this.anoSelecionado, filtros).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            let mes = '';
            if (Number(this.mesSelecionado) < 10) {
                mes = '0' + this.mesSelecionado;
            } else {
                mes = this.mesSelecionado.toString();
            }
            const name = tabela + ' em ' + mes + '-' + this.anoSelecionado;

            fileSaver.saveAs(blob, name + '.xlsx');

            this.loadingExportar = false;

        }, (error) => {
            this.loadingExportar = false;
            this.toastService.error(error.error.message);
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}): void {
        this.zone.run(() => {
            const component = getComponentConfigByName(componentName);
            const url = buildUrl(component, queryParams);
            const newTab = new Tab(component.name, component.title, url, component.urlType, data);
            this.tabService.closeAndAddTab(newTab);
        });
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    exibirTudo(exibir: boolean) {
        if (exibir) {

            switch (this.tabAtiva) {
                case 0:
                    this.arrayTableObrigacoes.forEach(d => {
                        this.expandSet.add(d.id);
                    });
                    break;
                case 1:
                    this.arrayTableDivergencias.forEach(d => {
                        this.expandSetDivergencias.add(d.grupoEmpresarial_id);
                    });
                    break;
                case 2:
                    this.arrayTableAusencias.forEach(d => {
                        this.expandSetAusencias.add(d.grupoEmpresarial_id);
                    });
                    break;

            }
        } else {
            this.expandSet.clear();
            this.expandSetDivergencias.clear();
            this.expandSetAusencias.clear();
        }

        this.expandCards = exibir;
    }

    modalOcorrencia(visible, tipoOcorrencia = null, idOcorrencia = null) {
        this.tipoOcorrencia = tipoOcorrencia;
        this.idOcorrencia = idOcorrencia;

        if (visible) {
            this.retornaObservacao();
        } else {
            this.displayObservacaoModal = visible;
            this.cdr.detectChanges();
        }
    }

    retornaObservacao() {

        this.loadingObservacao = true;

        this.complianceObrigacoesService.retornaObservacao(this.tipoOcorrencia, this.idOcorrencia).subscribe((response) => {

                this.arrayTableObservacao = [];

                if (!response.error) {
                    this.arrayTableObservacao = response.ocorrencias;

                    this.jutificadoComoSemMovimento = response.jutificadoComoSemMovimento === '1';

                    this.arrayTableObservacao.forEach((value) => {
                        this.loadingsObservacao[value.id] = false;
                    });
                }

                this.displayObservacaoModal = true;
                this.cdr.detectChanges();
                this.loadingObservacao = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    modalObservacao(visible, editar = false, descricao = null, observacaoId = null) {

        this.observacaoValue = editar ? descricao : '';

        this.observacaoSelecionada = observacaoId;

        this.flagEditarObservacao = editar;

        this.displayObservasaoModal = visible;

        this.cdr.detectChanges();

    }

    adicionarObservacao() {

        this.loadingsObservacaoAdicionar = true;

        this.complianceObrigacoesService.adicionarObservacao(
            this.tipoOcorrencia,
            this.idOcorrencia,
            this.observacaoValue
        ).subscribe((response) => {

            this.toastrService.success(response.message);

            this.displayObservasaoModal = false;

            this.retornaObservacao();
            if (this.tabAtiva === 2) {
                this.updateAusencias(this.mesSelecionado, this.anoSelecionado);
            } else {
                this.updateDivergencias(this.mesSelecionado, this.anoSelecionado);
            }

            this.loadingsObservacaoAdicionar = false;

        }, (response) => {
            this.loadingsObservacaoAdicionar = false;
            this.toastrService.error(response.error.message);

        });

    }

    showConfirmExcluirObservacao(tipoOcorrencia, idObservacao): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerObservacao(tipoOcorrencia, idObservacao)
        });
    }

    removerObservacao(tipoOcorrencia, idObservacao): void {

        this.loadingsObservacaoExcluir[idObservacao] = true;

        this.complianceObrigacoesService.removerObservacao(tipoOcorrencia, idObservacao).subscribe((response) => {

            this.loadingsObservacaoExcluir[idObservacao] = false;

            this.toastService.success(response.message);

            this.retornaObservacao();
            if (this.tabAtiva === 2) {
                this.updateAusencias(this.mesSelecionado, this.anoSelecionado);
            } else {
                this.updateDivergencias(this.mesSelecionado, this.anoSelecionado);
            }

        }, (response) => {

            this.loadingsObservacaoExcluir[idObservacao] = false;

            this.toastrService.error(response.error.message);

        });

    }

    editarObservacao() {

        this.loadingsObservacaoEditar = true;

        this.complianceObrigacoesService.editarObservacao(
            this.tipoOcorrencia,
            this.idOcorrencia,
            this.observacaoSelecionada,
            this.observacaoValue
        ).subscribe((response) => {

            this.toastrService.success(response.message);

            this.displayObservasaoModal = false;

            this.retornaObservacao();
            this.loadingsObservacaoEditar = false;

        }, (response) => {

            this.loadingsObservacaoEditar = false;
            this.toastrService.error(response.error.message);

        });

    }

    confirmJustificar() {

        this.modalService.confirm({
            nzTitle: this.jutificadoComoSemMovimento ? 'Deseja remover a justificativa?' : 'Deseja justificar como sem movimento?',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.justificarComoSemMovimento()
        });

    }

    justificarComoSemMovimento(): void {

        this.loadingJustificar = true;

        this.complianceObrigacoesService.justificarComoSemMovimento(
            this.tipoOcorrencia, this.idOcorrencia, this.jutificadoComoSemMovimento ? 'remover' : 'gravar'
        ).subscribe({
            next: (response) => {

                setTimeout(() => {
                    this.modalOcorrencia(false);
                    this.toastService.success('Ação realizada com seucesso.');
                    this.loadingJustificar = false;

                    this.tipoOcorrencia === 'divergencia' ? this.updateDivergencias(this.mesSelecionado, this.anoSelecionado) :
                        this.updateAusencias(this.mesSelecionado, this.anoSelecionado);
                }, 3000);


            },
            error: (response) => {

                this.toastrService.error(response.error.message);
                this.loadingJustificar = false;

            }
        });

    }
}

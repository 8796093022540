import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Empresa} from '@models/empresa.model';
import {EmpresaService} from '@services/empresa.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzModalService} from 'ng-zorro-antd/modal';
import {UserService} from '@services/user.service';
import {Helpers} from '../../../core/helpers';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-empresas',
    templateUrl: './empresas.component.html',
    styleUrls: ['./empresas.component.scss']
})

export class EmpresasComponent extends AbstractListTable<Empresa> implements OnInit, TabHandlerInterface {

    grupoEmpresarialOptions: { label: string; value: string }[] = [];
    visibilidadesOptions: { label: string; value: string }[] = [];
    displayGrupoEmpresarialModal = false;
    displayAtividadesModal = false;
    selectedGrupoEmpresarial;
    selectedAtividade;

    atividadesOptions = [
        {label: 'Indústria', value: 'Indústria'},
        {label: 'Varejo', value: 'Varejo'},
        {label: 'Comércio', value: 'Comércio'},
        {label: 'Serviços', value: 'Serviços'},
    ];

    displayVisibilidadeModal = false;

    newVisibilidadeModal = false;
    newGrupoEmpresarialModal = false;
    limparVisibilidades = false;
    newVisibilidade = '';
    newGrupoEmpresarial = '';
    dataVisibilityForm: Response<Visibilidade>;
    token;

    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    qtdFiltrosAtivos = 0;

    formFiltrar: FormStack;

    status = false;

    erroLicencas = false;

    modalImportarVisible = false;

    fileList: NzUploadFile[] = [];

    uploading = false;

    loadings = {
        buscandoLimites: false,
        alterandoStatus: false,
        alterandoAtividade: false,
        getVisibilidades: false,
        salvandoVisibilidades: false
    };

    formStatus: FormStack;

    formVisibilidade: FormStack;
    arrayComboVisibilidades: any[] = [];

    modalAtualizarProcuracoesVisible = false;

    procuradores: any = [];
    procuradoresFront: any = [];
    checkedProcuradores = new Set<string>();

    buscaInterna: any = null;

    loadingProcuradores = false;
    loadinAtualizandoprocuracoes = false;

    tipo: string;

    user: any;

    dataExport: any;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private empresaService: EmpresaService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private grupoEmpresarialService: GrupoEmpresarialService,
        private modalService: NzModalService,
        private userService: UserService,
        private tabService: TabService) {
        super(empresaService, Empresa, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupoEmpresarial_id: [null, null],
                visibilidade_id: [null, null],
                inativo: [null, null],
            })
        };

        this.formStatus = {
            modalVisible: false,
            formGroup: this.fb.group({
                ativo: ['1', Validators.required],
            })
        };

        this.formVisibilidade = {
            modalVisible: false,
            formGroup: this.fb.group({
                visibilidade: [null, null],
            })
        };

        this.dataExport = {
            url: '/administracao/empresas/exportar',
            filtros: this.formFiltrar.formGroup.value,
            name: 'Empresas',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

    ngOnInit() {
        this.getGruposEmpresariais();
        this.getVisibilidades();
        this.getLoggedUser();
    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getGruposEmpresariais() {

        return this.grupoEmpresarialService.listToSelect().subscribe((result) => {
            this.grupoEmpresarialOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    async getVisibilidades() {

        return this.empresaService.getVisibilidades().subscribe((result) => {
            this.visibilidadesOptions = result;
        });

    }

    onAlterarAtividades() {
        this.displayAtividadesModal = true;
    }


    onAlterarGrupoEmpresarial() {
        this.getGruposEmpresariais();
        this.displayGrupoEmpresarialModal = true;
    }

    saveBulkToAtividades() {

        this.loadings.alterandoAtividade = true;

        this.empresaService.alterarAtividadesMassa(Array.from(this.checkedItems), this.selectedAtividade)
            .subscribe(() => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedAtividade = '';
                this.displayAtividadesModal = false;
                this.updateTable();
                this.checkedItems.clear();
                this.loadings.alterandoAtividade = false;
            }, (e) => {
                if (!this.selectedAtividade) {
                    this.toastrService.error('Selecione uma atividade');
                } else {
                    this.toastrService.error(e.error.message);
                }
                this.loadings.alterandoAtividade = false;
            });


    }

    saveBulkToGruposEmpresariais() {


        this.empresaService.alterarGrupoEmpresarialMassa(Array.from(this.checkedItems), this.selectedGrupoEmpresarial)
            .subscribe(() => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedGrupoEmpresarial = '';
                this.displayGrupoEmpresarialModal = false;
                this.updateTable();
            }, () => {
                this.toastrService.error('Não foi possível realizar alteração em massa!');
            });


    }

    handleCancel() {
        this.displayGrupoEmpresarialModal = false;
        this.displayVisibilidadeModal = false;
        this.displayAtividadesModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    addNewGrupoEmpresarial() {
        this.newGrupoEmpresarialModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.cadastrarVisibilidade({descricao: this.newVisibilidade} as Visibilidade).subscribe((response: any) => {

                if (response.message) {
                    this.toastrService.success(response.message);

                    this.newVisibilidadeModal = false;
                }

            }, () => {

                this.toastrService.error('Não foi possível criar uma nova visibilidade');
                this.newVisibilidadeModal = false;

            });
        }
    }

    saveNewGrupoEmpresarial() {

        if (!Utils.isEmpty(this.newGrupoEmpresarial)) {

            this.grupoEmpresarialService.adicionar({descricao: this.newGrupoEmpresarial}).subscribe((response: any) => {

                this.toastrService.success('Grupo empresarial cadastrado.');
                this.newGrupoEmpresarial = null;
                this.getGruposEmpresariais();
                this.newGrupoEmpresarialModal = false;


            }, (response) => {

                this.toastrService.error('Não foi possível criar um novo grupo empresarial');
                this.newGrupoEmpresarialModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    closeNewGrupoEmpresarial() {
        this.newGrupoEmpresarialModal = false;
    }

    openTab(componentName: string, queryParams?: string, data?: any, close: boolean = true) {
        const component = findComponentByUrl(componentName);
        const nome = data.nome;
        const url = buildUrl(component, queryParams);
        const cnpj = data.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        const title = cnpj ? cnpj : nome;
        const newTab = new Tab(component.name, title + ' - Detalhe da Empresa', url, component.urlType, data);
        if (close) {
            this.tabService.closeAndAddTab(newTab);
        } else {
            this.tabService.addTab(newTab);
        }

    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosEmpresa: any = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosEmpresa[key] = campo.value;

            }

        }

        dadosEmpresa.ativo = 1;

        if (this.formCadastrar.valid) {

            this.empresaService.save(dadosEmpresa).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);

                    this.tipo = null;

                    this.openTab('/administracao/empresa/detalhe/',
                        response.id, {id: response.id, cnpj: response.cnpj, nome: response.nome, novaEmpresa: true});
                },
                (response) => {
                    this.tipo = null;
                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        this.erroLicencas = false;

        if (visible) {

            this.loadings.buscandoLimites = true;

            this.empresaService.limiteCadastral().subscribe((res) => {

                if (res.totalEmpresas) {
                    this.extra.totalEmpresas = res.totalEmpresas;
                }

                if (res.limiteEmpresas) {
                    this.extra.limiteEmpresas = res.limiteEmpresas;
                }

                if (!this.extra.limiteEmpresas || this.extra.totalEmpresas < this.extra.limiteEmpresas) {

                    this.getGruposEmpresariais();

                    this.formCadastrar = this.fb.group({
                        nome: [null, [Validators.required]],
                        codigo: [null, null],
                        cnpj: [null, [Validators.required]],
                        grupoEmpresarial_id: [null, [Validators.required]],
                        visibilidade_id: [null, null],
                        atividade: [null, [Validators.required]],
                    });

                    this.erroLicencas = false;

                } else {
                    this.erroLicencas = true;
                }

                this.loadings.buscandoLimites = false;


            }, (res) => {
                this.loadings.buscandoLimites = false;
                this.erroLicencas = true;
                this.toastService.error('Erro ao definir limite cadastral');
            });


        }

        this.modalCadastrarVisible = visible;

        this.cadastrando = false;
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    btnResetSearch() {
        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    btnResetSearchFront() {
        this.buscaInterna = null;
        this.procuradoresFront = this.procuradores;
    }

    clickEvent() {
        this.status = !this.status;
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        this.currentParams.pageIndex = 1;

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.loading = true;

        this.calculaBadgeFiltros();

        this.abstractService.listToTable(params, search).subscribe((response) => {

            this.items = response?.data || response;


            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.extra = response?.extra || {};

            this.refreshCheckedStatus();

            this.loading = false;
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    modalImportar(visible: boolean): void {
        if (visible) {

            if (!this.extra.limiteEmpresas || this.extra.totalEmpresas < this.extra.limiteEmpresas) {
                this.erroLicencas = false;
            } else {
                this.erroLicencas = true;
            }
        } else {
            this.fileList = [];
        }

        this.modalImportarVisible = visible;
    }

    downloadModelo(): void {

        this.empresaService.downloadModelo().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;
    }

    filtrarProcuradoresFront() {
        this.procuradoresFront = [];

        if (this.buscaInterna) {
            const busca = this.buscaInterna.toLowerCase();
            this.procuradoresFront = this.procuradores.filter(e => {
                if (e.unidade.toLowerCase().includes(busca) ||
                    e.cnpj.includes(busca) ||
                    (Helpers.maskCnpjCpf(e.cnpj).includes(busca))) {
                    return e;
                }
            });
        } else {
            this.procuradoresFront = this.procuradores;
        }
    }

    procuracaoChecked(id: string): void {

        this.updateCheckedSetProcuracoes(id);
    }

    updateCheckedSetProcuracoes(id: string): void {
        if (!this.checkedProcuradores.has(id)) {
            this.checkedProcuradores.clear();
            this.checkedProcuradores.add(id);

        } else {
            this.checkedProcuradores.delete(id);
        }
    }

    showModalAtualizarProcuracoes(visible: boolean, tipo = null): void {
        if (this.items.length > 1) {

            this.getProcuradoes();
            this.modalAtualizarProcuracoesVisible = visible;

        } else if (visible) {

            tipo === 'planilha' ? this.confirmarImportar() : this.confirmaCadastro();

        }

        if (!visible) {
            this.modalAtualizarProcuracoesVisible = visible;
            this.procuradores = [];
            this.checkedProcuradores.clear();
            tipo === 'planilha' ? this.confirmarImportar() : this.confirmaCadastro();
        }
    }

    confirmAtualizarprocuracoes(titulo: string, tipo: string): void {

        this.tipo = tipo;

        this.modalService.confirm({
            nzTitle: titulo,
            nzOkText: 'Sim',
            nzCancelText: 'Não',
            nzOnOk: () => this.showModalAtualizarProcuracoes(true, tipo),
            nzOnCancel: () => this.fileList.length && tipo === 'planilha' ? this.confirmarImportar() :
                tipo === 'unico' ? this.confirmaCadastro() : null
        });

    }

    getProcuradoes() {
        this.loadingProcuradores = true;

        this.empresaService.getProcuradoes().subscribe((res) => {
            this.procuradores = res.data;

            this.filtrarProcuradoresFront();

            this.loadingProcuradores = false;
        });
    }

    atualizarProcuracoes() {

        if (this.fileList.length) {
            this.confirmarImportar(); // importa a planilha 1º
        }

        this.loadinAtualizandoprocuracoes = true;

        const empresas = Array.from(this.checkedProcuradores);

        this.empresaService.atualizarProcuracoes(
            {empresa_id: empresas[0], robo: 'ecac', tipo: 'procuracao'}).subscribe((res) => {
            this.loadinAtualizandoprocuracoes = false;
            this.showModalAtualizarProcuracoes(false, this.tipo);
            this.toastService.success(res.message);
        }, error => {
            this.loadinAtualizandoprocuracoes = false;
            this.showModalAtualizarProcuracoes(false, this.tipo);
            this.toastService.error(error.error.message);
        });

    }

    confirmarImportar(): void {

        this.filtrarProcuradoresFront();

        this.cadastrando = true;

        this.uploading = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivo', file);
        });

        formData.append('totalEmpresas', this.extra.totalEmpresas);
        formData.append('limiteEmpresas', this.extra.limiteEmpresas);

        this.empresaService.uploadPlanilha(formData).subscribe((response: any) => {

                let msg = 'Dados importados com sucesso!';
                if (response.total) {
                    msg = response.sucesso ? response.sucesso : '0';
                    msg += '/' + response.total + ' (importadas com sucesso)';
                }
                this.toastrService.success(msg);

                this.fileList = [];

                this.modalImportar(false);

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

                this.cadastrando = false;

                this.tipo = null;

            },
            (response) => {
                this.uploading = false;
                this.toastrService.error(response.error.message);
                this.cadastrando = false;
                this.tipo = null;
            });

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

        this.checked = this.checkedItems.size === this.items.length;

    }

    modalExportar(visible: boolean): void {
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.componentExport.visible = visible;
    }

    modalStatus(visible: boolean) {
        this.formStatus.modalVisible = visible;
    }

    confirmaAlterarStatus(): void {

        this.loadings.alterandoStatus = true;

        this.empresaService.alterarStatusEmMassa(Array.from(this.checkedItems), this.formStatus.formGroup.value.ativo)
            .subscribe((res) => {

                this.loadings.alterandoStatus = false;
                this.modalStatus(false);
                this.formStatus.formGroup.get('ativo').setValue('1');
                this.queryTable(this.currentParams, this.currentSearch);
                this.checkedItems.clear();
                this.toastService.success('Status alterados nestas empresas');

            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.alterandoStatus = false;
            });
    }

    onAlterarVisibilidadeColetiva(): void {


        if (this.checkedItems.size > 0) {
            this.loadings.getVisibilidades = true;

            this.modalVisibilidadeColetiva(true);
            this.arrayComboVisibilidades = [];
            this.empresaService.getVisibilidades().subscribe((res) => {

                this.arrayComboVisibilidades = res;

                this.loadings.getVisibilidades = false;

            }, (res) => {
                this.toastService.error(res.error.message);
                this.loadings.getVisibilidades = false;
            });

        }

    }

    modalVisibilidadeColetiva(visible: boolean) {
        this.formVisibilidade.modalVisible = visible;

        if (visible) {
            this.formVisibilidade.formGroup.reset();
        }

    }

    confirmaAlterarVisibilidades(): void {

        this.loadings.salvandoVisibilidades = true;

        const empresas = Array.from(this.checkedItems);
        const visibilidade = this.formVisibilidade.formGroup.value.visibilidade;

        this.empresaService.salvarVisibilidadesUsuarios(empresas, visibilidade).subscribe((res) => {

            this.toastService.success('Visibilidade alterada com sucesso');
            this.checkedItems.clear();
            this.loadings.salvandoVisibilidades = false;
            this.modalVisibilidadeColetiva(false);
            this.queryTable(this.currentParams, this.currentSearch);

        }, (res) => {
            this.toastService.error(res.error.message);
            this.loadings.salvandoVisibilidades = false;
        });


    }

    abrevVisibilidade(visibilidade: string): string {
        if (visibilidade.length > 15) {
            return visibilidade.substr(0, 15) + '...';
        } else {
            return visibilidade;
        }
    }

    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.user = user);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {DiagnosticoFiscalService} from './diagnostico-fiscal.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Helpers} from '../../../core/helpers';
import {NzModalService} from 'ng-zorro-antd/modal';
import * as fileSaver from 'file-saver-es';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {DataService} from '@services/data.service';
import {differenceInCalendarDays} from 'date-fns';

@Component({
    selector: 'app-diagnostico-fiscal',
    templateUrl: './diagnostico-fiscal.component.html',
    styleUrls: ['./diagnostico-fiscal.component.scss']
})
export class DiagnosticoFiscalComponent implements OnInit {
    @Input() data: any;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    statusCard = false;

    pageSize = 50;
    pageIndex = 1;

    tabNumber = 0;

    checkedItems = new Set<string>();

    empresas: [] | any;
    diagnosticos: [] | any;

    paginationEmpresas: Pagination;
    paginationDiagnosticos: Pagination = {
        per_page: 0,
        current_page: 0,
        last_page: 0,
        total: 0,
        from: 0,
        to: 0
    };

    loadings = {
        empresas: false,
        diagnosticos: false,
        diagnosticosPorEmpresa: [],
        removendoDiagnostico: [],
        baixandoDiagnostico: [],
        novoDiagnostico: false
    };

    qtdFiltrosAtivos: 0;

    // utilizando forms tipados da v14

    formNovoDiagnostico = this.fb.group({
        empresa_id: [null, Validators.required],
        dataInicio: [null, Validators.required],
        dataFim: [null, Validators.required],
        intervalo: ['p', Validators.required],
    });

    formFiltrar = this.fb.group({
        empresa: [null],
        status: [null],
        dataInicio: [null],
        dataFim: [null],
    });

    currentParamsEmpresas: NzTableQueryParams;
    currentSearchEmpresas: string;

    currentParamsDiagnosticos: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };

    currentSearchDiagnosticos: string;

    modalFiltrarVisible = false;

    statusBook = [
        {
            color: 'processing',
            descricao: 'Solicitado',
            key: '0'
        },
        {
            color: 'warning',
            descricao: 'Em processamento',
            key: '1'
        },
        {
            color: 'success',
            descricao: 'Concluído',
            key: '2'
        },
        {
            color: 'error',
            descricao: 'Erro',
            key: '3'
        }
    ];

    modalVisible = {
        filtrarDiagnosticos: false,
        novoDiagnostico: false
    };

    optionsEmpresas = [];

    usuarioLogado: UsuarioLogado;

    ultimosCincoAnos = [];

    disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;


    formatOne = (percent: number) => `${percent}%`;

    constructor(private tabService: TabService,
                private service: DiagnosticoFiscalService,
                private fb: FormBuilder,
                private toastService: ToastrService,
                private modalService: NzModalService,
                private dataService: DataService) {

        this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

    }

    ngOnInit() {

        const anoAtual = new Date().getFullYear();

        for (let i = 5; i >= 0; i--){
            this.ultimosCincoAnos.push(anoAtual - i);
        }

        this.getEmpresas(this.currentParamsEmpresas, this.currentSearchEmpresas);

        if (this.data?.idEmpresa) {
            this.formFiltrar.get('empresa').setValue(this.data.idEmpresa);
            this.confirmaFiltrar();
            this.tabNumber = 1;

        } else {
            this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
        }

        this.dataService.currentUser.subscribe((data) => {
            this.usuarioLogado = data;
        });
    }

    openTab(path: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    changeTabs(index) {
        this.tabNumber = index;
    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationEmpresas?.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationEmpresas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationEmpresas.current_page) {
            this.getEmpresas(params);
            this.paginationEmpresas.current_page = params.pageIndex;
        }

    }

    getEmpresas(params: any, search: string = null): void {

        this.currentParamsEmpresas = params || {filter: []};

        this.loadings.empresas = true;

        // sem filtro para empresa por enquanto this.calculaBadgeFiltros();

        const filtros: any = {};

        this.currentParamsEmpresas.filter.forEach(f => {
            filtros[f.key] = f.value;
        });

        if (search) {
            filtros.procurar = search;
        }

        if (params?.pageIndex) {
            filtros.pagina = params.pageIndex;
        }

        if (params?.pageSize) {
            filtros.quantidade = params.pageSize;
        }

        this.service.getEmpresas(filtros).subscribe((response) => {

            this.empresas = [];
            this.empresas = response?.data;

            this.paginationEmpresas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadings.empresas = false;
        }, error => {
            this.loadings.empresas = false;
            this.toastService.error(error.erros.message);
        });
    }

    getDiagnosticos(params: any, search: string = null): void {

        this.currentParamsDiagnosticos = params || {filter: []};

        this.loadings.diagnosticos = true;

        this.calculaBadgeFiltros();

        const filtros: any = {};

        const datas = ['dataInicio', 'dataFim'];

        this.currentParamsDiagnosticos.filter.forEach(f => {
            filtros[f.key] = datas.includes(f.key) ? Helpers.formatDateDb(f.value) : f.value;
        });

        if (search) {
            filtros.search = search;
        }

        filtros.pagina = this.paginationDiagnosticos?.current_page || 1;
        filtros.quantidade = this.paginationDiagnosticos?.per_page || 10;

        this.service.getDiagnosticos(filtros).subscribe((response) => {

            this.diagnosticos = [];
            this.diagnosticos = response?.data;

            this.diagnosticos.forEach(d => {

                if (d.dados) {
                    d.dados = JSON.parse(d.dados);
                    d.percent_divergencias = (d.dados.divergencias * 100) / d.dados.obrigacoes;
                    d.percent_ausencias = (d.dados.ausencias * 100) / d.dados.obrigacoes;
                }

                const anoFim = new Date(d.dataFim).getFullYear();
                const anoInicio = new Date(d.dataInicio).getFullYear();

                const dif = anoFim - anoInicio;

                d.anosSolicitados = [];

                d.anosSolicitados.push(anoFim);

                for (let i = dif; i > 0; i--) {
                    d.anosSolicitados.push(anoFim - i);
                }
            });

            this.paginationDiagnosticos = new Pagination(
                response.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadings.diagnosticos = false;
            this.loadings.diagnosticosPorEmpresa = [];

        }, error => {

            this.loadings.diagnosticosPorEmpresa = [];
            this.loadings.diagnosticos = false;
            this.toastService.error(error.erros.message);
        });
    }

    changePageD(index) {
        this.paginationDiagnosticos.current_page = index;
        this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.value)) {
                if (valor && chave !== 'pagina' && valor && chave !== 'quantidade' && chave !== 'search'
                    && chave !== 'ordenar' && chave !== 'sentido') {

                    this.qtdFiltrosAtivos++;
                }
            }
        }
    }

    modalFiltrar(visible: boolean) {
        this.modalFiltrarVisible = visible;
    }

    btnResetSearchEmpresas() {
        this.currentParamsEmpresas.filter = [];
        this.currentSearchEmpresas = '';
        // this.formFiltrar.reset(); sem formulário de filtros para empresa
        this.currentParamsEmpresas.pageIndex = 1;

        this.getEmpresas(this.currentParamsEmpresas, this.currentSearchEmpresas);
    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    onCardChecked(id: string): void {

        this.updateCardCheckedSet(id);
    }

    updateCardCheckedSet(id: string): void {

        if (!this.checkedItems.has(id)) {

            this.checkedItems.add(id);
        } else {

            this.checkedItems.delete(id);
        }
    }

    reprocessar(): void {
        const ids: any = [];

        this.checkedItems.forEach((value) => {
            ids.push(value);
        });

        this.service.reprocessar(ids).subscribe((response) => {
            this.toastService.success('Ação realizada com sucesso.');
            this.checkedItems.clear();
            this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
        }, error => {
            this.toastService.error(error.error.mesage);
        });
    }

    closeSelecionados(): void {
        this.checkedItems.clear();
    }

    showModal(modal: string, visible: boolean) {
        this.modalVisible[modal] = visible;
    }

    confirmaFiltrar() {

        this.checkedItems.clear();

        this.currentParamsDiagnosticos.filter = [];
        this.paginationDiagnosticos.current_page = 1;

        if (this.formFiltrar.get('dataInicio').value) {
            const dataInicio = new Date(this.formFiltrar.get('dataInicio').value);
            dataInicio.setDate(1);
            dataInicio.setMonth(0);
            this.formFiltrar.get('dataInicio').setValue(dataInicio);
        }

        if (this.formFiltrar.get('dataFim').value) {
            const dataFim = new Date(this.formFiltrar.get('dataFim').value);
            dataFim.setDate(31);
            dataFim.setMonth(11);
            this.formFiltrar.get('dataFim').setValue(dataFim);
        }

        for (const [key, valor] of Object.entries(this.formFiltrar.value)) {
            if (valor || valor === 0) {
                this.currentParamsDiagnosticos.filter.push({key, value: valor.toString()});
            }
        }

        if (this.data?.idEmpresa) {
            this.data.idEmpresa = null;
        }

        this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
        this.modalVisible.filtrarDiagnosticos = false;
    }

    novoDiagnostico() {

        if (this.formNovoDiagnostico.value.intervalo === 'a') {
            this.formNovoDiagnostico.get('dataFim').setValue(this.formNovoDiagnostico.get('dataInicio').value);
        }

        if (this.formNovoDiagnostico.valid) {

            const data: any = this.formNovoDiagnostico.value;

            this.loadings.novoDiagnostico = true;

            delete data.intervalo;

            if (data.dataInicio > data.dataFim) {
                this.toastService.error('Ano inicio deve ser menor que ano fim!');
                this.loadings.novoDiagnostico = false;
                return;
            }

            data.dataInicio = `${data.dataInicio}-01-01`;
            data.dataFim = `${data.dataFim}-12-31`;

            this.service.novoDiagnostico(data).subscribe((response) => {
                this.toastService.success('Ação realizada com sucesso.');
                this.tabNumber = 1;
                this.loadings.novoDiagnostico = false;
                this.paginationDiagnosticos.current_page = 1;
                this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
                this.getEmpresas(this.currentSearchEmpresas, this.currentSearchEmpresas);
                this.showModal('novoDiagnostico', false);
                this.formNovoDiagnostico.reset();
                this.formNovoDiagnostico.get('intervalo').setValue('p');

            }, error => {
                this.loadings.novoDiagnostico = false;
                this.toastService.error(error.error.message);
            });

        } else {

            Object.values(this.formNovoDiagnostico.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    filtrarPorEmpresa(empresaId: string) {
        this.formFiltrar.get('empresa').setValue(empresaId);
        this.loadings.diagnosticosPorEmpresa.push(empresaId);
        this.currentParamsDiagnosticos.filter.push({key: 'empresa', value: empresaId});
        this.getDiagnosticos(this.currentParamsDiagnosticos);
        this.tabNumber = 1;
    }

    confirmRemover(id: any [], msg: string) {
        this.modalService.confirm({
            nzTitle: msg,
            nzOkText: 'Remover',
            nzOnOk: () => this.removerDiagnostico(id)
        });
    }

    removerDiagnostico(ids: string[] = null) {

        ids = ids.length > 0 ? ids : Array.from(this.checkedItems);

        ids.forEach(id => {
            this.loadings.removendoDiagnostico.push(id);
        });

        this.service.removerDiagnostico(ids).subscribe((response) => {
            this.toastService.success('Ação realizada com sucesso.');
            this.loadings.removendoDiagnostico = [];
            this.getDiagnosticos(this.currentParamsDiagnosticos, this.currentSearchDiagnosticos);
        }, error => {
            this.loadings.removendoDiagnostico = [];
            this.toastService.error(error.error.mesage);
        });
    }

    baixarDiagnostico(data) {

        this.loadings.baixandoDiagnostico.push(data.id);

        this.service.donwloadBook(data.id).subscribe((response) => {
            fileSaver.saveAs(response, `Diagnostico_MT_${data.empresa_nome}_${data.dataInicio}_${data.dataFim}.pdf`);

            this.loadings.baixandoDiagnostico = [];
        }, error => {
            this.loadings.baixandoDiagnostico = [];
            this.toastService.error(error.error.mesage);
        });
    }

}

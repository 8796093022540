import {ChangeDetectorRef, Component} from '@angular/core';
import * as Highcharts from 'highcharts';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
    ControleDeCertificadosService,
} from './controle-de-certificados.service';
import {Pagination} from '@models/pagination.model';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-controle-de-certificados',
    templateUrl: './controle-de-certificados.component.html',
    styleUrls: ['./controle-de-certificados.component.scss']
})
export class ControleDeCertificadosComponent {

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    currentParamsEmpresas: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    currentParamsResponsaveis: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    updateFlag: boolean;

    currentSearch = null;

    paginationEmpresas: Pagination = {
        per_page: 50,
        current_page: 1,
        last_page: 50,
        total: 500,
        from: 1,
        to: 50
    };

    paginationResponsaveis: Pagination = {
        per_page: 50,
        current_page: 1,
        last_page: 50,
        total: 500,
        from: 1,
        to: 50
    };

    HighchartsPie: typeof Highcharts = Highcharts;
    chartPieOptions: Highcharts.Options;

    HighchartsBasic: typeof Highcharts = Highcharts;
    chartBasicOptions: Highcharts.Options;
    highChart: Highcharts.Chart | null;

    cardCabecalhoLoading = false;
    loading = false;

    formFiltrosEmpresas: FormStack;
    formFiltrosResponsaveis: FormStack;

    arraySelectEmpresa = [];
    comboResponsaveis = [];
    arraySelectGrupos = [];

    filtroStatus: string = null;

    tableStatus: any = {};

    arrayColorStatus = [
        '#28d094',
        '#FF9149',
        '#ff4949',
    ];

    loadingsUltimaCertidao = {};

    processamentoFlag = {};
    agendamentoFlag = {};

    arrayStatus = [
        'Certificado Válido',
        'Certificado a Vencer',
        'Certificado Vencido'
    ];

    empresas = [];
    responsaveis = [];

    apagarFiltro = true;

    qtdFiltrosEmpresasAtivos = 0;
    qtdFiltrosResponsaveisAtivos = 0;

    statusOne = false;

    tabNumber = 0;
    requestTab: Subscription;

    constructor(
        private fb: UntypedFormBuilder,
        private service: ControleDeCertificadosService,
        private cdr: ChangeDetectorRef,
        private toastService: ToastrService,
        private dataService: DataService,
        private tabService: TabService,
    ) {

        this.formFiltrosEmpresas = {
            modalVisible: false,
            formGroup: this.fb.group({
                unidade: [null, null],
                grupo: [null, null],
                status: [null, null],
                vencimento: [null, null]
            })
        };

        this.formFiltrosResponsaveis = {
            modalVisible: false,
            formGroup: this.fb.group({
                responsavel: [null, null],
                tipo: [null, null],
                status: [null, null],
                vencimento: [null, null]
            })
        };
    }

    ngOnInit() {

        this.carregaCabecalho();

        this.formFiltrosEmpresas.formGroup.get('status').setValue(this.filtroStatus);
        this.formFiltrosResponsaveis.formGroup.get('status').setValue(this.filtroStatus);

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno;
            // this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaComboResponsaveis().subscribe((retorno: any) => {

            retorno.forEach((value) => {
                value.label = value.nome;
                value.key = value.id;
            });

            this.comboResponsaveis = retorno;

        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });

        this.carregaEmpresas(this.currentParamsEmpresas);
        this.carregaResponsaveis(this.currentParamsResponsaveis);

        this.dataService.currentUser.subscribe((data) => {
            this.cdr.detectChanges();
        });

    }

    carregaCabecalho(): void {

        this.cardCabecalhoLoading = true;

        this.service.retornaCabecalho().subscribe((response: any) => {

            // this.tableEmissoesAutomaticas = response.emissoes;

            this.tableStatus = response.status;

            this.loadChartData(response.validade, response.status);

            this.cardCabecalhoLoading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

    }

    changeTabs(event: any = null) {

        // this.requestTab?.unsubscribe();

        if (this.tabNumber === 0) {
            this.formFiltrosEmpresas.formGroup.get('status').setValue(this.formFiltrosResponsaveis.formGroup.get('status').value);
            this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(this.formFiltrosResponsaveis.formGroup.get('vencimento').value);

        } else {
            this.formFiltrosResponsaveis.formGroup.get('status').setValue(this.formFiltrosEmpresas.formGroup.get('status').value);
            this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(this.formFiltrosEmpresas.formGroup.get('vencimento').value);
        }

        // Faz as duas requisições para atualizar os contadores nas tabs
        this.currentParamsEmpresas.pageIndex = 1;
        this.carregaEmpresas(this.currentParamsEmpresas);

        this.currentParamsResponsaveis.pageIndex = 1;
        this.carregaResponsaveis(this.currentParamsResponsaveis);
    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

        }

    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrarEmpresas(): any {

        this.fecharModal(this.formFiltrosEmpresas);

        this.filtroStatus = this.formFiltrosEmpresas.formGroup.get('status').value;
        this.currentParamsEmpresas.pageIndex = 1;
        this.carregaEmpresas(this.currentParamsEmpresas);
    }

    filtrarResposaveis(): any {

        this.fecharModal(this.formFiltrosResponsaveis);

        this.currentParamsResponsaveis.pageIndex = 1;
        this.filtroStatus = this.formFiltrosResponsaveis.formGroup.get('status').value;
        this.carregaResponsaveis(this.currentParamsResponsaveis);

    }

    filtrar() {
        const status = this.formFiltrosEmpresas.formGroup.get('status').value ||
            this.formFiltrosResponsaveis.formGroup.get('status').value || null;
        const vencimento = this.formFiltrosEmpresas.formGroup.get('vencimento').value ||
            this.formFiltrosResponsaveis.formGroup.get('vencimento').value || null;

        this.formFiltrosEmpresas.formGroup.get('status').setValue(status);
        this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(vencimento);
        this.formFiltrosResponsaveis.formGroup.get('status').setValue(status);
        this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(vencimento);

        // Filtras as duas tabs para atualizar os contadores
        this.filtrarEmpresas();
        this.filtrarResposaveis();
    }

    buscar() {

        this.currentParamsEmpresas.pageIndex = 1;
        this.carregaEmpresas(this.currentParamsEmpresas);

        this.currentParamsResponsaveis.pageIndex = 1;
        this.carregaResponsaveis(this.currentParamsResponsaveis);
    }

    limparFiltros(): void {
        this.currentSearch = null;
        this.filtroStatus = null;

        this.formFiltrosEmpresas.formGroup.reset();
        this.currentParamsEmpresas.pageIndex = 1;
        this.currentParamsEmpresas.pageSize = 50;

        this.formFiltrosResponsaveis.formGroup.reset();
        this.currentParamsResponsaveis.pageIndex = 1;
        this.currentParamsResponsaveis.pageSize = 50;


        this.carregaEmpresas(this.currentParamsEmpresas);
        this.carregaResponsaveis(this.currentParamsResponsaveis);

    }

    loadChartData(vencimento, status): void {

        const seriesBasicChart: any = [
            10
        ];

        Object.entries(vencimento).forEach((value) => {
            switch (value[0]) {
                case 'superior_60':
                    seriesBasicChart[1] = value[1];
                    break;
                case '60':
                    seriesBasicChart[2] = value[1];
                    break;
                case '30':
                    seriesBasicChart[3] = value[1];
                    break;
                case '15':
                    seriesBasicChart[4] = value[1];
                    break;
                case 'vencido':
                    seriesBasicChart[5] = value[1];
                    break;
            }
        });

        this.chartBasicOptions = {
            chart: {
                type: 'bar',
            },
            title: {
                text: undefined
            },
            subtitle: {
                text: undefined
            },
            xAxis: {
                categories: ['', 'Superior a 60 dias', 'Em até 60 dias', 'Em até 30 dias', 'Em até 15 dias', 'Vencida'],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Certificados',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' Certificados'
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.filtrarChart.bind(this)
                        }
                    }
                },
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                type: undefined,
                name: '',
                data: seriesBasicChart
            }]
        };

        const seriesPieChart: any = [{
            type: 'pie',
            name: 'Certificados',
            data: [
                {
                    name: 'Certificado Válido',
                    y: Number(status.valido)
                }, {
                    name: 'Certificado a Vencer',
                    y: Number(status.a_vencer)
                }, {
                    name: 'Certificado Vencido',
                    y: Number(status.vencido)
                }]
        }];

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: this.arrayColorStatus,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: seriesPieChart
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }

    addFiltro(filtroIn: string, valorIn: any): void {

        const arrayFiltros: any[] = [];

        arrayFiltros.push({
            filtro: filtroIn,
            valor: valorIn
        });

        switch (filtroIn) {

            case 'status':

                if (this.formFiltrosEmpresas.formGroup.value.status === valorIn) {
                    this.formFiltrosEmpresas.formGroup.get('status').setValue(null);
                    this.filtroStatus = null;
                } else {
                    this.formFiltrosEmpresas.formGroup.get('status').setValue(valorIn);
                    this.filtroStatus = valorIn;
                }

                if (this.formFiltrosResponsaveis.formGroup.value.status === valorIn) {
                    this.formFiltrosResponsaveis.formGroup.get('status').setValue(null);
                    this.filtroStatus = null;
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('status').setValue(valorIn);
                    this.filtroStatus = valorIn;
                }

                break;

        }


        this.currentParamsEmpresas.pageIndex = 1;
        this.carregaEmpresas(this.currentParamsEmpresas);

        this.currentParamsResponsaveis.pageIndex = 1;
        this.carregaResponsaveis(this.currentParamsResponsaveis);

    }

    filtrarChart(data) {

        if (!this.loading) {

            if (data.point.category === 'Superior a 60 dias') {
                if (this.formFiltrosEmpresas.formGroup.value.vencimento === '100') {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue('100');
                }

                if (this.formFiltrosResponsaveis.formGroup.value.vencimento === '100') {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue('100');
                }
            }

            if (data.point.category === 'Em até 60 dias') {
                if (this.formFiltrosEmpresas.formGroup.value.vencimento === '60') {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue('60');
                }

                if (this.formFiltrosResponsaveis.formGroup.value.vencimento === '60') {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue('60');
                }
            }

            if (data.point.category === 'Em até 30 dias') {
                if (this.formFiltrosEmpresas.formGroup.value.vencimento === '30') {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue('30');
                }

                if (this.formFiltrosResponsaveis.formGroup.value.vencimento === '30') {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue('30');
                }
            }

            if (data.point.category === 'Em até 15 dias') {
                if (this.formFiltrosEmpresas.formGroup.value.vencimento === '15') {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue('15');
                }

                if (this.formFiltrosResponsaveis.formGroup.value.vencimento === '15') {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue('15');
                }
            }

            if (data.point.category === 'Vencida') {
                if (this.formFiltrosEmpresas.formGroup.value.vencimento === 'vencido') {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosEmpresas.formGroup.get('vencimento').setValue('vencido');
                }

                if (this.formFiltrosResponsaveis.formGroup.value.vencimento === 'vencido') {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue(null);
                } else {
                    this.formFiltrosResponsaveis.formGroup.get('vencimento').setValue('vencido');
                }
            }


            this.currentParamsEmpresas.pageIndex = 1;
            this.carregaEmpresas(this.currentParamsEmpresas);

            this.currentParamsResponsaveis.pageIndex = 1;
            this.carregaResponsaveis(this.currentParamsResponsaveis);

        }
    }

    carregaEmpresas(parametros: NzTableQueryParams): void {

        this.calculaFiltrosEmpresas();

        this.loading = true;

        const order = this.service.montaOrder(parametros.sort);

        const filtros: any = {};

        if (parametros.pageSize) {
            filtros.pageSize = parametros.pageSize;
            this.paginationEmpresas.per_page = parametros.pageSize;
        }

        for (const [chave, valor] of Object.entries(this.formFiltrosEmpresas.formGroup.value)) {
            if (valor) {
                filtros[chave] = valor;
            }
        }

        this.requestTab = this.service.retornaEmpresas(filtros, order, parametros.pageIndex, this.currentSearch).subscribe({
            next: (response: any) => {

                response.data.forEach((value, i) => {

                    const indexStatus = value.status === 'valido' ? 0 : value.status === 'vencido' ? 2 : 1;

                    value.statusDescricao = this.arrayStatus[indexStatus];

                    value.statusColor = this.arrayColorStatus[indexStatus];
                });

                this.empresas = response.data;

                this.paginationEmpresas = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total,
                    response?.from || 1,
                    response?.to || 1);

                setTimeout(() => {
                    this.loading = false;
                    this.apagarFiltro = true;
                    this.cdr.detectChanges();
                }, 500);

            },
            error: (res) => {
                this.empresas = [];
                this.toastService.error('Erro ao carregar empresas.');
                this.loading = false;
            }
        });
    }

    carregaResponsaveis(parametros: NzTableQueryParams): void {

        this.calculaFiltrosResponsaveis();

        this.loading = true;

        const order = this.service.montaOrder(parametros.sort);

        const filtros: any = {};

        if (parametros.pageSize) {
            filtros.pageSize = parametros.pageSize;
            this.paginationResponsaveis.per_page = parametros.pageSize;
        }

        for (const [chave, valor] of Object.entries(this.formFiltrosResponsaveis.formGroup.value)) {
            if (valor) {
                filtros[chave] = valor;
            }
        }

        this.requestTab = this.service.retornaResponsaveis(filtros, order, parametros.pageIndex, this.currentSearch).subscribe({
            next: (response: any) => {

                response.data.forEach((value, i) => {

                    const indexStatus = value.status === 'valido' ? 0 : value.status === 'vencido' ? 2 : 1;

                    value.statusDescricao = this.arrayStatus[indexStatus];

                    value.statusColor = this.arrayColorStatus[indexStatus];
                });

                this.responsaveis = response.data;

                this.paginationResponsaveis = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total,
                    response?.from || 1,
                    response?.to || 1);

                setTimeout(() => {
                    this.loading = false;
                    this.apagarFiltro = true;
                    this.cdr.detectChanges();
                }, 500);

            },
            error: (res) => {
                this.empresas = [];
                this.toastService.error('Erro ao carregar responsáveis.');
                this.loading = false;
            }
        });
    }

    calculaFiltrosEmpresas(): void {
        this.qtdFiltrosEmpresasAtivos = 0;

        if (typeof this.formFiltrosEmpresas !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosEmpresas.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosEmpresasAtivos++;
                }

            }
        }
    }

    calculaFiltrosResponsaveis(): void {
        this.qtdFiltrosResponsaveisAtivos = 0;

        if (typeof this.formFiltrosResponsaveis !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosResponsaveis.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosResponsaveisAtivos++;
                }

            }
        }
    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationEmpresas.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationEmpresas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationEmpresas.current_page) {
            this.carregaEmpresas(params);
            this.paginationEmpresas.current_page = params.pageIndex;
        }

    }

    listByTableResponsaveis(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationResponsaveis.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationResponsaveis.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationResponsaveis.current_page) {
            this.carregaResponsaveis(params);
            this.paginationResponsaveis.current_page = params.pageIndex;
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }
}


<nz-page-header class="site-page-header" nzTitle="Calendar" nzSubtitle="Confirmações">
    <nz-page-header-extra>
        <nz-button-group *ngIf="showContent">
            <nz-badge [nzCount]="qtdFiltrosAtivosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>

<div *ngIf="showAlert">
    <nz-row nzGutter="24">
        <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        O módulo Calendar ainda não foi contratado. Entre em contato com o nosso suporte.
                    </div>
                </div>
            </ng-template>
        </nz-alert>
    </nz-row>
</div>
<div *ngIf="showContent">
    <nz-content>
        <div class="cards-cabecalho">
            <div class="card-g" *ngIf="showCardCategoria">
                <nz-card [nzLoading]="loadingContadores">
                    <div nz-row>
                        <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                            <div>
                                <h1 nz-typography class="custom-card-value text-nowrap"
                                    style="margin: 0; font-size: 28px; margin-bottom: 10px;">
                                    {{dadosCabecalho.obrigacao}}
                                </h1>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    {{dadosCabecalho.orgao}}
                                </h4>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="card-g">
                <nz-card [nzLoading]="loadingContadores">
                    <div nz-row>
                        <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                            <div>
                                <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                    Vencimento:
                                </h4>
                                <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                    {{dadosCabecalho.mesSelecionado}}<span
                                        style="font-weight: 400;">/</span>{{dadosCabecalho.anoSelecionado}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="card-p">
                <nz-card [nzLoading]="loadingContadores">
                    <div nz-row>
                        <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                            <i class="far fa-building"
                               style="font-size: 56px; margin-right: 15px; color: #009688 !important;"></i>
                            <div>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Unidades
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #009688 !important; font-size: 36px;">
                                    {{dadosCabecalho.qtdUnidades | number: '1.0-0'}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="card-p">
                <nz-card [nzLoading]="loadingContadores">
                    <div nz-row>
                        <div nz-col [nzSpan]="24"
                             class="d-flex align-items-center justify-content-center">
                            <i class="far fa-calendar-alt"
                               style="font-size: 56px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                            <div>
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Obrigações
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #1e9ff2 !important; font-size: 36px;">
                                    {{dadosCabecalho.qtdObrigacoes | number: '1.0-0'}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="card-g">
                <nz-card [nzLoading]="loadingContadores">
                    <div nz-row>
                        <div nz-col [nzSpan]="24"
                             class="d-flex">
                            <i nz-icon nzType="info-circle" nzTheme="outline"
                               style="font-size: 56px; margin-right: 15px; color: #3653a1 !important;"></i>
                            <div>
                                <h1 class="custom-card-title text-muted" style="line-height: 1;">
                                    Status
                                </h1>
                                <div class="d-flex justify-content-between">
                                    <a class="custom-card-title text-muted"
                                       (click)="filtrarCampo(formFiltrosGrid, 'status', 'A')"
                                       nz-tooltip nzTooltipTitle="Em confirmação"
                                       style="margin-right: 10px; white-space: nowrap; margin-bottom: 5px; line-height: 1; width: 100%; font-size: 18px;">
                                        <i class="fa-solid fa-rotate"></i>
                                        <span class="custom-card-span-valor" style="color: #666ee8;">
                                    {{dadosCabecalho.status?.pendentes | number: '1.0-0'}}
                                    </span>
                                    </a>
                                    <a class="custom-card-title text-muted"
                                       nz-tooltip nzTooltipTitle="Confirmada"
                                       (click)="filtrarCampo(formFiltrosGrid, 'status', 'C')"
                                       style="margin-right: 10px; white-space: nowrap; margin-bottom: 5px; line-height: 1; width: 100%; font-size: 18px;">
                                        <i class="fas fa-check"></i>
                                        <span class="custom-card-span-valor" style="color: #28a745;">
                                        {{dadosCabecalho.status?.confirmadas | number: '1.0-0'}}
                                    </span>
                                    </a>
                                    <a class="custom-card-title text-muted"
                                       nz-tooltip nzTooltipTitle="Rejeitadas"
                                       (click)="filtrarCampo(formFiltrosGrid, 'status', 'R')"
                                       style="margin-right: 10px; white-space: nowrap; margin-bottom: 0; line-height: 1; width: 100%; font-size: 18px;">
                                        <i class="fas fa-times"></i>
                                        <span class="custom-card-span-valor" style="color: #cf1322;">
                                        {{dadosCabecalho.status?.rejeitadas | number: '1.0-0'}}
                                    </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </nz-content>
    <nz-content>
        <nz-card class="fonte-doze"
                 [nzLoading]="loadingTabela"
                 [ngClass]="[!loadingTabela ? 'ant-card-table' : '']"
                 [nzExtra]="navBar"
                 nzTitle="Aprovações">
            <ng-template #navBar>
                <div class="nav-item d-flex">
                    <nz-badge [nzCount]="qtdFiltrosAtivos"
                              [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                        <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                            <i nz-icon nzType="search"></i>
                            Filtros
                        </button>
                    </nz-badge>
                    <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                            nzType="default" style="margin-right: 10px;">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    <li nz-menu-item
                                        (click)="checkedItems?.size > 0 && aprovarConfirm(null, 'C')"
                                        [nzDisabled]="checkedItems?.size === 0">
                                        Confirmar
                                    </li>
                                    <li nz-menu-item
                                        (click)="checkedItems?.size > 0 && aprovarConfirm(null, 'R')"
                                        [nzDisabled]="checkedItems?.size === 0">
                                        Rejeitar
                                    </li>
                                </ul>
                            </li>
                            <!--<li nz-menu-item>Exportar Planilha</li>-->
                        </ul>
                    </nz-dropdown-menu>
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input (keydown.enter)="queryTable(currentParams, currentSearch, true);" type="text" nz-input
                               placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch
                                (click)="queryTable(currentParams, currentSearch, true);"><i
                                nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>
            </ng-template>
            <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                      *ngIf="checkedItems.size > 0">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }} Registros Selecionados
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-table #tableA
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzPageIndex]="pagination?.current_page"
                      (nzQueryParams)="listByTable($event)"
                      [nzShowTotal]="rangeTemplate"
                      class="table-small">
                <thead>
                <tr>
                    <th nzAlign="center">
                        <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                    </th>
                    <th nzColumnKey="obrigacao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                    <th nzColumnKey="grupo" [nzSortFn]="true" nzAlign="center">Grupo</th>
                    <th nzColumnKey="empresa" [nzSortFn]="true" nzAlign="center">Empresa</th>
                    <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                    <th nzColumnKey="vencimento" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                    <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                    <th nzColumnKey="agendaConfirmavel" [nzSortFn]="true" nzAlign="center">Tipo Confirmação</th>
                    <th nzColumnKey="dataHoraConfirmacao" nzAlign="center">Confirmações</th>
                    <th nzColumnKey="anexos" nzAlign="center">Anexos</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let arquivo of tableA.data">
                    <td nzLeft (nzCheckedChange)="onItemChecked(arquivo.obrigacaoEmpresa_id, $event)"
                        [nzChecked]="checkedItems.has(arquivo.obrigacaoEmpresa_id)"
                        [nzDisabled]="arquivo.disabled">
                    </td>
                    <td nzLeft style="white-space: nowrap;">
                        <button nz-button nzType="link"
                                *ngIf="arquivo?.temHistorico"
                                (click)="openTab('/cadastros/obrigacao-empresa-detalhe/', arquivo?.obrigacaoEmpresa_id, {id: arquivo?.obrigacaoEmpresa_id})">
                            {{arquivo?.obrigacao_descricao}}
                        </button>
                        <button nz-button nzType="link"
                                *ngIf="!arquivo?.temHistorico"
                                (click)="msgSemHistorico()">
                            {{arquivo?.obrigacao_descricao}}
                        </button>
                    </td>
                    <td nzLeft>{{modoDemo(arquivo?.grupo, 'grupo')}}</td>
                    <td nzLeft style="width: 300px;">
                        {{modoDemo(arquivo?.empresa, 'empresaCodigoNome')}}
                        ({{modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj')}})
                    </td>
                    <td nzAlign="center">{{arquivo?.competencia | date : 'MM/yyyy'}}</td>
                    <td nzAlign="center">{{arquivo?.vencimento | date : 'dd/MM/yyyy'}}</td>
                    <td nzAlign="center">
                        <nz-alert nzType="warning" *ngIf="arquivo?.status === 'A'"
                                  nzMessage="{{arquivo?.statusDescricao}}"></nz-alert>
                        <nz-alert nzType="success" *ngIf="arquivo?.status === 'C'"
                                  nzMessage="{{arquivo?.statusDescricao}}"></nz-alert>
                        <nz-alert nzType="error" *ngIf="arquivo?.status === 'R'"
                                  nzMessage="{{arquivo?.statusDescricao}}"></nz-alert>
                    </td>
                    <td nzAlign="center">{{arquivo?.agendaConfirmavel === 1 ? 'Automática' : arquivo?.agendaConfirmavel === 2 ? 'Manual' : 'Nenhuma'}}</td>
                    <td nzAlign="center">
                    <span *ngIf="arquivo.usuarioConfirmacao">
                        {{arquivo.usuarioConfirmacao}}
                    </span>
                        <br>
                        <span style="font-size: 0.9em">
                        {{ arquivo.dataHoraConfirmacao ? (arquivo.dataHoraConfirmacao | date : 'dd/MM/yyyy HH:mm') : '-'}}
                    </span>
                    </td>
                    <td style="text-align: center">
                        <nz-badge [nzCount]="arquivo?.quantidadeAnexos || 0" nzShowZero
                                  [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }">
                            <button nz-button nzType="link" (click)="modalAnexos(true, arquivo)">
                                <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                            </button>
                        </nz-badge>
                    </td>
                    <td nzAlign="center" style="white-space: nowrap;"
                        *ngIf="arquivo.agendaConfirmavel === 2 && arquivo.status === 'A'">
                        <a nz-button nzType="link"
                           nz-tooltip="Aprovar"
                           (click)="aprovarConfirm(arquivo?.obrigacaoEmpresa_id, 'C')">
                            <i class="fas fa-check-circle"></i>
                        </a>
                        <a nz-button nzType="link"
                           nz-tooltip="Rejeitar"
                           (click)="aprovarConfirm(arquivo?.obrigacaoEmpresa_id, 'R')">
                            <i class="fas fa-times-circle"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </nz-table>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-card>
    </nz-content>
    <nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
              [nzTitle]="'Filtros'" [nzClosable]="true"
              (nzOnCancel)="fecharModal(formFiltrosCabecalho)"
              [nzWidth]="648">
        <nz-row *nzModalContent>
            <nz-col [nzSpan]="24">
                <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.vencimento">
                            <nz-date-picker [nzMode]="'month'"
                                            [nzFormat]="'MM/yyyy'"
                                            [(ngModel)]="formFiltrosCabecalho.formGroup.value.vencimento"
                                            (ngModelChange)="retornaSelectObrigacoesChange()"
                                            formControlName="vencimento" style="width: 100%;"
                                            [nzAllowClear]="false"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.categoria">
                            <nz-select nzShowSearch nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="categoria"
                                       [(ngModel)]="formFiltrosCabecalho.formGroup.value.categoria"
                                       (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'categoria', 'obrigacao')">
                                <nz-option *ngFor="let opt of arraySelectCategorias"
                                           [nzLabel]="opt.descricao"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.obrigacao">
                            <nz-select nzShowSearch
                                       nzPlaceHolder="Selecione"
                                       formControlName="obrigacao"
                                       [(ngModel)]="formFiltrosCabecalho.formGroup.value.obrigacao"
                                       (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'obrigacao', 'categoria')">
                                <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                           [nzLabel]="opt.key"
                                           [nzValue]="opt.value"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </nz-col>
        </nz-row>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
            <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
        </div>
    </nz-modal>
    <nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
              [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
              (nzOnCancel)="fecharModal(formFiltrosGrid)"
              [nzWidth]="648">
        <nz-row *nzModalContent>
            <nz-col [nzSpan]="24">
                <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.grupo">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="grupo">
                                <nz-option *ngFor="let opt of arraySelectGrupos"
                                           [nzLabel]="modoDemo(opt.descricao, 'total')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="empresa_id"
                                       [(ngModel)]="formFiltrosGrid.formGroup.value.empresa_id">
                                <nz-option *ngFor="let opt of arraySelectCnpj"
                                           [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="empresa_id"
                                       [(ngModel)]="formFiltrosGrid.formGroup.value.empresa_id">
                                <nz-option *ngFor="let opt of arraySelectEmpresa"
                                           [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.competencia">
                            <nz-date-picker [nzMode]="'month'"
                                            [nzFormat]="'MM/yyyy'"
                                            [(ngModel)]="formFiltrosGrid.formGroup.value.competencia"
                                            formControlName="competencia" style="width: 100%;"
                                            [nzAllowClear]="false"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Status</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.status">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="status"
                                       [(ngModel)]="formFiltrosGrid.formGroup.value.status">
                                <nz-option [nzLabel]="'Em confirmação'" [nzValue]="'A'"></nz-option>
                                <nz-option [nzLabel]="'Confirmada'" [nzValue]="'C'"></nz-option>
                                <nz-option [nzLabel]="'Rejeitada'" [nzValue]="'R'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Tipo Confirmação</nz-form-label>
                        <nz-form-control [nzSpan]="14"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosGrid.formGroup.value.tipo">
                            <nz-select nzShowSearch
                                       nzAllowClear
                                       nzPlaceHolder="Selecione"
                                       formControlName="tipo">
                                <nz-option [nzLabel]="'Automática'" [nzValue]="1"></nz-option>
                                <nz-option [nzLabel]="'Manual'" [nzValue]="2"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </nz-col>
        </nz-row>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="fecharModal(formFiltrosGrid)">Cancelar</button>
            <button nz-button nzType="primary" (click)="filtrarGrid()">Filtrar</button>
        </div>
    </nz-modal>

    <nz-modal nzTitle="Prévia Apuração"
              [(nzVisible)]="modalDetalhes"
              (nzOnCancel)="modalDetalheApruracao(false)"
              [nzWidth]="1500"
              [nzBodyStyle]="{'padding' : '0'}">
        <div *nzModalContent>

            <nz-tabset [(nzSelectedIndex)]="tabDetalhesAtiva"
                       (nzSelectChange)="changeTabsDetalhes($event)"
                       style="margin-left: 20px">
                <nz-tab nzTitle="Resumo CFOP"></nz-tab>
                <nz-tab nzTitle="Apuração ICMS"></nz-tab>
            </nz-tabset>

            <nz-table #tableResumoCFOP
                      [nzScroll]="{ y: '50vh' }"
                      [nzData]="arrayResumoCFOP"
                      [nzFrontPagination]="false"
                      *ngIf="tabDetalhesAtiva === 0"
                      [nzLoading]="resumoCFOPLoading"
                      class="tabela-detalhes table-small scrollbar"
                      [ngStyle]="{'max-width' : '700 px'}">
                <thead>
                <tr>
                    <th nzColumnKey="cfop" nzAlign="center">CFOP</th>
                    <th nzColumnKey="valor" nzAlign="center">Valor</th>
                    <th nzColumnKey="icmsBase" nzAlign="center" [nzShowFilter]="false">Base ICMS</th>
                    <th nzColumnKey="icmsValor" nzAlign="center">Valor ICMS</th>
                    <th nzColumnKey="valorIsento" nzAlign="center">Valor Isento</th>
                    <th nzColumnKey="icmsStValor" nzAlign="center">Valor ICMS ST</th>
                    <th nzColumnKey="outros" nzAlign="center">Outros</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of tableResumoCFOP.data">
                    <td nzAlign="center">{{item.cfop}}</td>
                    <td nzAlign="center">{{item.valor | currency : 'BRL'}}</td>
                    <td nzAlign="center">{{item.icmsBase | currency : 'BRL'}}</td>
                    <td nzAlign="center">{{item.icmsValor | currency : 'BRL'}}</td>
                    <td nzAlign="center">{{item.valorIsento | currency : 'BRL'}}</td>
                    <td nzAlign="center">{{item.icmsStValor | currency : 'BRL'}}</td>
                    <td nzAlign="center">{{item.outros| currency : 'BRL'}}</td>
                </tr>
                </tbody>
            </nz-table>

            <nz-table #tableApuracaoICMS
                      [nzData]="arrayApuracaoICMS"
                      [nzFrontPagination]="false"
                      [nzLoading]="apuracaoICMSLoading"
                      *ngIf="tabDetalhesAtiva === 1"
                      class="tabela-detalhes table-small scrollbar"
                      [ngStyle]="{'max-width' : '700 px'}">
                <thead *ngFor="let item of tableApuracaoICMS.data">
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Total de débitos</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_tot_debitos | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Débitos ajustado</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_aj_debitos | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Total de débitos ajustado</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_tot_aj_debitos | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Crédito estornado</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_estornos_cred | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Total de crédito</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_tot_creditos | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Total de crédito ajustado</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_tot_aj_creditos | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Débitos estornados</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_estornos_deb | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Saldo credor ant.</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_sld_credor_ant | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Saldo apurado</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_sld_apurado | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Total DED</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_tot_ded | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">ICMS a recolher</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_icms_recolher | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Saldo credor transportar</span>
                        <span style="float: right; margin-right: 60%">{{item.vl_sld_credor_transportar | currency : 'BRL'}}</span>
                    </td>
                </tr>
                <tr style="width: 50em">
                    <td>
                        <span style="margin-left: 15px">Débito Esp.</span>
                        <span style="float: right; margin-right: 60%">{{item.deb_esp | currency : 'BRL'}}</span>
                    </td>
                </tr>
                </thead>
            </nz-table>
        </div>
        <div *nzModalFooter>
            <button nz-button nzType="link" (click)="modalDetalheApruracao(false)">Fechar</button>
        </div>
    </nz-modal>

    <nz-modal [(nzVisible)]="modalVerAnexosVisible"
              [nzTitle]="'Anexos da solicitação de aprovação'" [nzClosable]="true"
              (nzOnCancel)="modalAnexos(false)"
              [nzWidth]="550"
              [nzBodyStyle]="{'padding': '0 5px'}">
        <div *nzModalContent>

            <app-anexos-list [btExcluir]="btExcluir" [anexosList]="listOfAnexos"></app-anexos-list>

        </div>
        <div *nzModalFooter>
            <button nz-button nzType="default" (click)="modalAnexos(false);">Fechar</button>
        </div>
    </nz-modal>
</div>

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {DadosEmpresaService} from './dados-empresa.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-dados-empresa',
    templateUrl: './dados-empresa.component.html',
    styleUrls: ['./dados-empresa.component.scss']
})
export class DadosEmpresaComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {

    formFiltrar: FormStack;
    loading = false;
    qtdFiltrosAtivos = 0;

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private dadosEmpresaService: DadosEmpresaService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                razaoSocial: [null, null],
                naturezaJuridica: [null, null],
                qualificacao: [null, null],
                capitalSocial: [null, null],
                porte: [null, null],
                enteFederativo: [null, null]
            })
        };
    }

    ngOnInit() {
    }

    openTab(componentName: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;
        this.currentSearch = search;

        this.carregaDadosRFB(params);
        this.resetCheckedSet();
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    btnResetSearch() {
        this.items = null;
        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };
        this.formFiltrar.formGroup.reset();
    }

    carregaDadosRFB(params): void {

        if (!!params.filter.length || !!this.currentSearch) {

            if (!!params.filter.length) {
                params = this.validaCNPJFilter(params);
            }

            if (!!this.currentSearch) {
                this.currentSearch = this.formataCNPJSearch(this.currentSearch);
            }

            this.loading = true;

            this.abstractService.listToTable(params, this.currentSearch, this.dadosEmpresaService.baseUrl).subscribe((response) => {

                this.padronizaDados(response);

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                setTimeout(() => {
                    this.loading = false;
                    this.cdr.detectChanges();
                }, 500);
            });
        }
    }

    padronizaDados(response): any {
        if (!!response.total && response.data) {
            this.items = this.retornaDadosRFBPadronizados(response.data);
        } else {
            this.items = [];
        }
    }

    retornaEsqueletoDadosForm(): object {

        const dados = {
            cnpj: '',
            razaoSocial: '',
            naturezaJuridica: '',
            qualificacao: '',
            capitalSocial: '',
            porte: '',
            enteFederativo: '',
        };

        return dados;
    }

    retornaDadosRFBPadronizados(data) {

        let esqueleto = this.retornaEsqueletoDadosForm();
        const result = [];

        // tslint:disable-next-line:forin
        for (const obj in data) {

            if (!!data[obj].capitalSocial) {
                data[obj].capitalSocial = this.mascaraMoeda(data[obj].capitalSocial);
            }


            // tslint:disable-next-line:forin
            for (const value in esqueleto) {
                (data[obj][value] !== undefined) ? esqueleto[value] = data[obj][value] : esqueleto[value] = '';
            }

            result.push(esqueleto);
            esqueleto = this.retornaEsqueletoDadosForm();
        }

        return result;
    }

    validaCNPJFilter(data) {

        for (const object in data.filter) {
            if (data.filter[object].key === 'cnpj') {
                // A Propriedade CNPJ do objeto vai receber o valor do cnpj base sem mascara
                data.filter[object].value = data.filter[object].value.replace(/[^0-9]/g, '').substr(0, 8);
            }
        }

        return data;
    }

    formataCNPJSearch(data: string): string {

        const cnpjFormated = data.replace(/[^0-9]/g, '');
        if (cnpjFormated.length === 14) {
            data = cnpjFormated.substr(0, 8);
        }

        return data;
    }

    mascaraMoeda(valor: string): string {
        valor = valor.replace(/\D/g, '');
        valor = valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
        valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        valor = valor.replace(/^(\d)/g, 'R$ $1');

        return valor;
    }
}

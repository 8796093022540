import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {ControleCertidoesService} from './controleCertidoes.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {DataService} from '@services/data.service';
import {environment} from '../../../../environments/environment';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {ContaService} from '@services/conta.service';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface DataItem {
    controleCertidaoProcessamento_id: string;
    created_at: string;
    descricao: string;
    emissao: string;
    id: string;
    identificador: string;
    status: number;
    tipoEmissao: string;
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-controleCertidoes',
    templateUrl: './controleCertidoes.component.html',
    styleUrls: ['./controleCertidoes.component.scss']
})
export class ControleCertidoesComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, TabHandlerInterface {

    listOfColumn = [
        {
            title: 'Tipo de Emissão',
            sortFn: (a: DataItem, b: DataItem) => a.tipoEmissao.localeCompare(b.tipoEmissao),
            priority: 1,
            align: 'center'
        },
        {
            title: 'Emissão',
            sortFn: (a: DataItem, b: DataItem) => a.emissao.localeCompare(b.emissao),
            priority: 2,
            align: 'center'
        },
        {
            title: 'Número',
            sortFn: (a: DataItem, b: DataItem) => a.identificador.localeCompare(b.identificador),
            priority: 3,
            align: 'center'
        },
        {
            title: 'Status',
            compare: (a: DataItem, b: DataItem) => a.status - b.status,
            priority: 4,
            align: 'center'
        },
        {
            title: 'Criado Em',
            sortFn: (a: DataItem, b: DataItem) => a.created_at.localeCompare(b.created_at),
            priority: 5,
            align: 'center'
        },
        {
            title: 'Certidão',
            compare: null,
            priority: false,
            align: 'center'
        },
        {
            title: 'Justificativa',
            compare: null,
            priority: false,
            align: 'center'
        },
    ];

    arrayStatus = [
        'Nunca Emitida',
        'Negativa',
        'Positiva com Efeito de Negativa',
        'Positiva',
    ];

    arrayColorStatus = [
        '#9e8886',
        '#28d094',
        '#666ee8',
        '#ff9149',
    ];

    cardCabecalhoLoading = true;

    tableEmissoesAutomaticas: any = {};

    tableStatus: any = {};

    anoAtivo = 0;
    mesAtivo = 0;

    @Input() data: any[];

    formFiltrosGrid: FormStack;
    formFiltrosCabecalho: FormStack;
    filtroStatus: string = null;
    arraySelectEmpresa = [];
    arraySelectEmpresaCodigo = [];
    arraySelectCertidao = [];
    arraySelectCnpj = [];

    filtroVencimento = null;
    filtroConfirmacoes = null;

    formExportar: FormStack;

    qtdFiltrosAtivos = 0;

    HighchartsBasic: typeof Highcharts = Highcharts;
    chartBasicOptions: Highcharts.Options;
    highChart: Highcharts.Chart | null;

    updateFlag: boolean;

    labelEmissoes = {
        A: 'Automáticas',
        M: 'Manuais',
        FC: 'Falha na consulta',
        NI: 'Não Identificas',
    };

    displayHistoricoModal = false;
    arrayTableHistorico = [];

    historyId;
    loadingHistory = false;

    displayModalCnd = false;
    iframeUrl: SafeResourceUrl = '';
    base64Selecionado = '';
    cndSelecionada = '';

    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;

    statusOne = false;
    scrollYvh: string;
    loadingsUltimaCertidao = {};

    modalJsonFlag = false;
    textAreaValue = '';
    currentUser: any;

    modalStatusFlag = false;
    conteudoStatus = '';
    urlRelatorio = '';
    ultimoProcessamentoErro = '';

    conteudoUltimaCnd = '';
    isPdf = true;

    loadingsHistorico = {};
    loadingsHistoricoEditar = {};
    loadingsHistoricoExcluir = {};

    processamentoFlag = {};
    agendamentoFlag = {};

    HighchartsPie: typeof Highcharts = Highcharts;
    chartPieOptions: Highcharts.Options;

    modalStatusTitle = '';
    qtdFiltrosCabecalho = 0;

    formAdicionar: FormStack;
    formEditarHistorico: FormStack;
    optionsCertidoesManuais = [];
    apiUrl = environment.apiUrl;
    fileListAdicionar: NzUploadFile[] = [];

    loadings = {
        comboCertidoesManuais: false,
        comboEmpresasCndsManuais: false,
        adicionarCndManual: false,
        editarHistoricoUnico: false,
        cadastrarCertidaoEmpresa: false,
        novaCertidao: false,
        exportar: false,
        editandoJustificativa: false
    };

    editandoHistorico = {
        id: '',
        certidao_descricao: '',
        empresa_nome: '',
        empresa_cnpj: '',
        controleCertidaoProcessamento_id: '',
    };

    formCadastrarCertidaoManual: FormStack;
    formNovaCertidao: FormStack;
    optionsEmpresas = [];

    radioValueOrgao = 'Federal';
    apenasUnidadesMatriz = '0';
    comboUFs: any = [];
    comboTodosMunicipios: any = [];

    arrayFiltroCertidao: any = [];

    arrayModulos = [];

    showAlert = false;
    showContent = false;

    dataExport: any;

    formEditarJustificativa: FormStack;
    historicoIdSelecionado = null;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    apagarFiltro = true;

    sizeOptions = [];
    lastSizeSelected = 50;

    grupoEmpresarialOptions: { label: string; value: string }[] = [];

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    optionsUF: { nome: string, uf: string }[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private modalService: NzModalService,
        private service: ControleCertidoesService,
        public sanitizer: DomSanitizer,
        private i18n: NzI18nService,
        private dataService: DataService,
        private grupoEmpresarialService: GrupoEmpresarialService,
        private contaService: ContaService) {

        super(service, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.getScreenSize();

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupoEmpresarial_id: [null, null],
                statusUltimaEmissao: [null, null],
                validadeUltimaCnd: [null, null],
                emManutencao: [null, null],
            })
        };

        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                empresa_id_codigo: [null, null],
                certidao: [null, null],
                tipoEmissao: [null, null],
                dataUltimaEmissao: [null, null],
                ultimaCnd: [null, null],
                emManutencao: [null, null],
                orgao: [null, null],
                uf: [null, null],
            })
        };

        this.formAdicionar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                certidao_id: [null, Validators.required],
                emissao: [null, Validators.required],
                identificador: [null, null],
                validade: [null, Validators.required],
                status: [null, Validators.required]
            })
        };

        this.formEditarHistorico = {
            modalVisible: false,
            formGroup: this.fb.group({
                emissao: [null, Validators.required],
                identificador: [null, null],
                validade: [null, Validators.required],
                status: [null, Validators.required]
            })
        };

        this.formEditarJustificativa = {
            modalVisible: false,
            formGroup: this.fb.group({
                justificativa: [null]
            })
        };

        this.formCadastrarCertidaoManual = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                certidao_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
            })
        };

        this.formNovaCertidao = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
                orgao: [null, Validators.required],
                uf: [null, null],
                municipioCodigo: [null, null],
                somenteMatriz: [null, null],
            })
        };

        this.dataExport = {
            url: '/certidoes/controleCertidoes/exportar',
            filtros: this.formFiltrosGrid.formGroup.value,
            name: 'Controle de Certidões',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    carregaCabecalho(): void {

        this.cardCabecalhoLoading = true;

        this.calculaBadgeFiltrosCabecalho();

        const filtros = this.formFiltrosCabecalho.formGroup.value;

        this.service.retornaCabecalho(filtros).subscribe((response: any) => {

            this.tableEmissoesAutomaticas = response.emissoes;

            this.tableStatus = response.status;

            this.loadChartData(response.validade, response.status);

            this.cardCabecalhoLoading = false;

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);

        });

    }


    ngOnInit() {
        this.virificarStatusModulo();
    }

    virificarStatusModulo() {

        this.contaService.modulosAtivos().subscribe((response) => {

                this.arrayModulos = response;
                if (this.arrayModulos.includes('cnd')) {
                    this.showContent = true;
                } else {
                    this.showAlert = true;
                }

                if (!this.showAlert) {

                    this.getGruposEmpresariais();

                    this.getUFs();
                    this.filtroStatus = this?.data[0]?.statusUltimaEmissao || null;
                    this.formFiltrosCabecalho.formGroup.get('statusUltimaEmissao').setValue(this.filtroStatus);
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(this?.data[0]?.validadeUltimaCnd ||
                        this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').value);


                    this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
                        this.arraySelectEmpresa = retorno;
                        // this.arraySelectCnpj = retorno.empresaCnpj;
                    });

                    this.service.retornarSelectsEmpresasCodigo().subscribe((retorno: any) => {
                        this.arraySelectEmpresaCodigo = retorno.empresaCodigo;
                    });

                    this.service.retornarSelectsCertidao().subscribe((retorno: any) => {
                        this.arraySelectCertidao = retorno;
                    });

                    this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
                        this.optionsEmpresas = [];
                        this.optionsEmpresas = retorno;
                    });

                    this.service.retornaEstados().subscribe((retorno: any) => {

                        retorno.estados.forEach((value) => {
                            value.label = value.uf + ' - ' + value.nome;
                            value.key = value.uf;
                        });
                        this.comboUFs = retorno.estados;

                    });

                    this.service.retornaComboMunicipios().subscribe((retorno: any) => {
                        retorno.cidades.forEach((value) => {
                            value.key = value.ibgeCodigo;
                            value.label = value.ibgeCodigo + ' - ' + value.nome;
                        });
                        this.comboTodosMunicipios = [];
                        this.comboTodosMunicipios = retorno.cidades;
                    });

                    this.carregaCabecalho();

                    this.dataService.currentUser.subscribe((data) => {
                        this.currentUser = data;
                        this.cdr.detectChanges();
                    });

                }

            },
            (response) => {
                this.toastrService.error(response.error.message);

            }
        );

    }

    ngAfterViewInit() {

        this.resizeModal();
    }

    resizeModal() {

        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);

        const toDivide = this.screenHeight > 800 ? 10 :
            this.screenHeight > 630 ? 9 :
                this.screenHeight > 530 ? 8 :
                    this.screenHeight > 430 ? 7 :
                        this.screenHeight > 330 ? 6 :
                            this.screenHeight > 230 ? 5 : 4;


        this.scrollYvh = this.statusOne ? this.screenHeight / toDivide + 'vh' : '';

    }

    loadChartData(validade, status): void {

        const seriesBasicChart: any = [
            10
        ];

        Object.entries(validade).forEach((value) => {
            seriesBasicChart.push(value[1]);
        });

        this.chartBasicOptions = {
            chart: {
                type: 'bar',
            },
            title: {
                text: undefined
            },
            subtitle: {
                text: undefined
            },
            xAxis: {
                categories: ['', 'Superior a 60 dias', 'Em até 60 dias', 'Em até 30 dias', 'Em até 15 dias', 'Vencida'],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Certidões',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' Certidões'
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: this.filtrarChart.bind(this)
                        }
                    }
                },
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                type: undefined,
                name: '',
                data: seriesBasicChart
            }]
        };

        const seriesPieChart: any = [{
            type: 'pie',
            name: 'Certidões',
            data: [
                {
                    name: 'Nunca Emitida',
                    y: Number(status.status_0)
                }, {
                    name: 'Negativa',
                    y: Number(status.status_1)
                }, {
                    name: 'Positiva com Efeito de Negativa',
                    y: Number(status.status_2)
                }, {
                    name: 'Positiva',
                    y: Number(status.status_3)
                }]
        }];

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: this.arrayColorStatus,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: seriesPieChart
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }

    filtrarChart(data) {

        if (!this.loading) {

            this.currentParams.pageIndex = 1;


            if (data.point.category === 'Superior a 60 dias') {
                if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd === '100') {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(null);
                } else {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue('100');
                }
            }

            if (data.point.category === 'Em até 60 dias') {
                if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd === '60') {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(null);
                } else {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue('60');
                }
            }

            if (data.point.category === 'Em até 30 dias') {
                if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd === '30') {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(null);
                } else {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue('30');
                }
            }

            if (data.point.category === 'Em até 15 dias') {
                if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd === '15') {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(null);
                } else {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue('15');
                }
            }

            if (data.point.category === 'Vencida') {
                if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd === '0') {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue(null);
                } else {
                    this.formFiltrosCabecalho.formGroup.get('validadeUltimaCnd').setValue('0');
                }
            }

            this.carregaCabecalho();

            this.carregaAtividades(this.currentParams);
        }
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    calculaBadgeFiltrosGrid(): void {

        let qtd = 0;

        if (this.formFiltrosGrid.formGroup.value.empresa_id) {
            qtd += 1;
        }

        if (this.formFiltrosGrid.formGroup.value.uf) {
            qtd += 1;
        }

        if (this.formFiltrosGrid.formGroup.value.tipoEmissao) {
            qtd += 1;
        } else {
            this.filtroConfirmacoes = null;
        }

        if (this.formFiltrosGrid.formGroup.value.dataUltimaEmissao) {
            qtd += 1;
        }

        if (this.formFiltrosGrid.formGroup.value.ultimaCnd) {
            qtd += 1;
        }

        if (this.arrayFiltroCertidao.length > 0) {
            qtd += 1;
        }

        if (this.formFiltrosGrid.formGroup.value.empresa_id_codigo) {
            qtd += 1;
        }

        if (this.formFiltrosGrid.formGroup.value.orgao) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;

    }

    carregaAtividades(parametros: NzTableQueryParams): void {
        // this.carregaCabecalho();

        this.calculaBadgeFiltrosGrid();

        this.loading = true;

        const order = this.service.montaOrder(parametros.sort);

        const filtros: any = {};

        if (parametros.pageSize) {
            filtros.pageSize = parametros.pageSize;
            this.pagination.per_page = parametros.pageSize;
            this.lastSizeSelected = parametros.pageSize;
        }

        this.formFiltrosGrid.formGroup.get('emManutencao').setValue(
            this.formFiltrosCabecalho.formGroup.get('emManutencao').value
        );

        for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {
            if (valor) {
                if (chave === 'certidao') {
                    const array: any = valor;
                    if (array.length > 0) {
                        filtros[chave] = valor;
                    }
                } else {
                    filtros[chave] = valor;
                }
            }
        }

        filtros.statusUltimaEmissao = this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao;

        filtros.validadeUltimaCnd = this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd;

        filtros.grupoEmpresarial_id = this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id;


        this.service.retornaCertidoes(filtros, order, parametros.pageIndex, this.currentSearch).subscribe((response: any) => {

            response.data.forEach((value, i) => {

                if (typeof value.preRequisitos === 'string') {
                    value.exibirPreRequisito = true;
                }

                this.processamentoFlag[value.id] = false;

                const indexStatus = value.statusUltimaEmissao !== null ? value.statusUltimaEmissao : 0;

                value.statusDescricao = this.arrayStatus[indexStatus];

                value.statusColor = this.arrayColorStatus[indexStatus];

                this.loadingsUltimaCertidao[value.ultimoHistorico_id] = false;

            });

            this.items = response.data;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.sizeOptions = [10, 50, 100, 200, 300, 500];

            let remove = this.sizeOptions[this.sizeOptions.length - 1] > this.pagination?.total;

            while (remove) {

                this.sizeOptions.pop();

                remove = this.sizeOptions[this.sizeOptions.length - 1] > this.pagination?.total;

            }

            this.sizeOptions.push(this.pagination?.total);

            setTimeout(() => {
                this.loading = false;
                this.apagarFiltro = true;
                this.cdr.detectChanges();
            }, 500);

        }, (res) => {
            this.items = [];
            this.toastService.error('Erro ao carregar certidões.');
            this.loading = false;
        });

    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.currentSearch = search;

        this.carregaAtividades(params);

        this.resetCheckedSet();

    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrar(): any {

        this.apagarFiltro = false;

        this.formFiltrosGrid.modalVisible = false;
        this.currentParams.pageIndex = 1;
        this.carregaAtividades(this.currentParams);

    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    limparFiltrosCabecalho(): void {

        this.formFiltrosGrid.formGroup.reset();
        this.formFiltrosCabecalho.formGroup.reset();
        this.filtroStatus = null;
        this.filtroConfirmacoes = null;
        this.filtroVencimento = null;
        this.currentSearch = null;
        this.currentParams.pageIndex = 1;
        this.currentParams.pageSize = 50;

        this.carregaCabecalho();
        this.carregaAtividades(this.currentParams);
    }

    limparFiltros(): void {

        this.formFiltrosGrid.formGroup.reset();
        this.filtroStatus = null;
        this.filtroConfirmacoes = null;
        this.filtroVencimento = null;
        this.currentSearch = null;
        this.currentParams.pageIndex = 1;
        this.currentParams.pageSize = this.lastSizeSelected < 10 ? 10 : this.lastSizeSelected;

        this.carregaAtividades(this.currentParams);
    }

    addFiltro(filtroIn: string, valorIn: any): void {

        this.currentParams.pageIndex = 1;

        const arrayFiltros: any[] = [];

        arrayFiltros.push({
            filtro: filtroIn,
            valor: valorIn
        });

        switch (filtroIn) {

            case 'tipoEmissao':

                if (this.formFiltrosGrid.formGroup.value.tipoEmissao === valorIn) {
                    this.formFiltrosGrid.formGroup.get('tipoEmissao').setValue(null);
                    this.filtroConfirmacoes = null;
                } else {
                    this.formFiltrosGrid.formGroup.get('tipoEmissao').setValue(valorIn);
                    this.filtroConfirmacoes = valorIn;
                }

                break;

            case 'statusUltimaEmissao':

                if (this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao === valorIn) {
                    this.formFiltrosCabecalho.formGroup.get('statusUltimaEmissao').setValue(null);
                    this.filtroStatus = null;
                } else {
                    this.formFiltrosCabecalho.formGroup.get('statusUltimaEmissao').setValue(valorIn);
                    this.filtroStatus = valorIn;
                }

                break;

        }

        setTimeout(() => {
            this.carregaAtividades(this.currentParams);
        }, 500);
        this.carregaCabecalho();

    }

    handleCancel() {
        this.displayModalCnd = false;
    }

    modalHistorico(visible) {
        this.displayHistoricoModal = visible;
    }

    onAllChecked(checked: boolean): void {
        this.items.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
    }

    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
    }

    modalExportar(visible: boolean): void {

        const filtros = this.formFiltrosGrid.formGroup.value;

        if (this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao) {
            filtros.statusUltimaEmissao = this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao;
        }

        if (this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id) {
            filtros.grupoEmpresarial_id = this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id;
        }

        if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd) {
            filtros.validadeUltimaCnd = this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd;
        }


        this.dataExport.filtros = filtros;
        this.componentExport.visible = visible;
    }

    retornaHistorico(id) {

        this.historyId = id;

        this.displayHistoricoModal = true;

        this.loadingHistory = true;

        this.service.retornaHistorico(id).subscribe((response) => {

                this.arrayTableHistorico = [];

                if (!response.error) {
                    this.arrayTableHistorico = response;

                    this.arrayTableHistorico.forEach((value) => {
                        this.loadingsHistorico[value.id] = false;
                    });

                }

                this.loadingHistory = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    verCnd(certidaoId: any, nomeCnd, tipo = null): void {

        if (tipo === 'ultima') {
            this.loadingsUltimaCertidao[certidaoId] = true;
        }

        if (tipo === 'historico') {
            this.loadingsHistorico[certidaoId] = true;
        }

        this.service.retornaUltimaCnd(certidaoId).subscribe((response) => {

            this.conteudoUltimaCnd = '';
            this.iframeUrl = '';

            if (response.arquivo) {
                // caso tamanho do base64 > 999kb no Chrome não exibe e baixa direto
                if (response.tipo === 'pdf' && encodeURI(response.arquivo).split(/%..|./).length > 99900 && navigator.userAgent.indexOf('Chrome') > -1) {
                    this.toastrService.warning('Arquivo muito grande para ser exibido no seu navegador atual.' +
                        ' Será baixado automaticamente.');

                    this.base64Selecionado = response.arquivo;
                    this.cndSelecionada = nomeCnd;

                    this.onClickDownloadPdf();

                    if (tipo === 'ultima') {
                        this.loadingsUltimaCertidao[certidaoId] = false;
                    }

                    if (tipo === 'historico') {
                        this.loadingsHistorico[certidaoId] = false;
                    }

                    return;

                } else {

                    const archive = atob(response.arquivo);

                    if (response.tipo === 'pdf') {

                        this.base64Selecionado = response.arquivo;
                        this.cndSelecionada = nomeCnd;
                        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.arquivo);
                        this.isPdf = true;

                    }

                    if (response.tipo === 'html') {

                        // this.conteudoUltimaCnd = decodeURIComponent(escape(archive));

                        this.conteudoUltimaCnd = archive;
                        this.cndSelecionada = nomeCnd;
                        this.isPdf = false;

                    }
                }

                this.displayModalCnd = true;

            } else {
                this.toastService.error('Falha ao tentar exibir arquivo. Erro: Arquivo não retornado.');
            }

            if (tipo === 'ultima') {
                this.loadingsUltimaCertidao[certidaoId] = false;
            }

            if (tipo === 'historico') {
                this.loadingsHistorico[certidaoId] = false;
            }

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    exportarPdfs() {

        const filtros: any = this.formFiltrosGrid.formGroup.value;

        if (this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao) {
            filtros.statusUltimaEmissao = this.formFiltrosCabecalho.formGroup.value.statusUltimaEmissao;
        }

        if (this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id) {
            filtros.grupoEmpresarial_id = this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id;
        }

        if (this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd) {
            filtros.validadeUltimaCnd = this.formFiltrosCabecalho.formGroup.value.validadeUltimaCnd;
        }

        const url = `${environment.apiUrl}/exportador`;

        Object.keys(filtros).forEach((key) => {
            if (filtros[key]) {
                this.currentParams.filter.push({key: String(key), value: filtros[key]});
            }
        });


        this.service.exportPdfs(this.currentParams, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('DownloadCenterComponent', '', {});

        }, (res) => {
            this.toastService.error(res.error.message);
        });

    }

    downloadPdf(base64String = null, fileName = null) {

        if (base64String) {

            const source = `data:application/pdf;base64,${base64String}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = fileName ? `${fileName}.pdf` : 'download.pdf';
            link.click();

        } else {

            this.toastService.error('Arquivo não encontrado.');

        }

    }

    onClickDownloadPdf() {
        this.downloadPdf(this.base64Selecionado, this.cndSelecionada);
    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                this.resizeModal();
                break;

        }

    }

    modalJson(visible) {
        this.modalJsonFlag = visible;
    }

    retornaJson(processamentoId) {

        this.service.retornaJson(processamentoId).subscribe((res) => {

            this.textAreaValue = JSON.stringify(res, null, '\t');
            this.modalJsonFlag = true;

        });

    }

    atualizarTela() {
        this.carregaCabecalho();
        this.queryTable(this.currentParams, this.currentSearch);
    }

    modalStatus(visible) {
        this.modalStatusFlag = visible;
    }

    exibirErro(erro, certidaoId: string, dataUltimoProcessamento, urlRelatorio = null) {
        if (erro || (urlRelatorio && certidaoId === 'RFB')) {
            this.conteudoStatus = erro;
            if (certidaoId === 'RFB') {
                this.urlRelatorio = urlRelatorio;
            } else {
                this.urlRelatorio = null;
            }

            this.ultimoProcessamentoErro = dataUltimoProcessamento;
            this.modalStatus(true);
        }
    }

    showConfirm(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja reprocessar o registro?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(id)
        });

    }

    showConfirmAgendar(certidaoId: any, empresaId): void {

        const now = new Date();

        this.modalService.confirm({
            nzTitle: 'O processamento será agendado para ' + now.toLocaleDateString() + ' - 23:59h',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.agendarProcessamento(certidaoId, empresaId)
        });

    }

    reprocessarRegistro(id) {

        this.processamentoFlag[id] = true;

        this.service.reprocessarRegistro(id).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.processamentoFlag[id] = false;

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

            this.processamentoFlag[id] = false;

        });

    }

    agendarProcessamento(certidaoId, empresaId) {

        this.agendamentoFlag[certidaoId + empresaId] = true;

        this.service.agendarProcessamento(certidaoId, empresaId).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.agendamentoFlag[certidaoId + empresaId] = false;

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

            this.agendamentoFlag[certidaoId + empresaId] = false;

        });

    }

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    filtrarCabecalho(): any {

        this.filtroStatus = null;

        this.fecharModal(this.formFiltrosCabecalho);

        this.carregaCabecalho();

        this.queryTable(this.currentParams, this.currentSearch);

        this.currentParams.pageIndex = 1;
    }

    calculaBadgeFiltrosCabecalho(): void {

        this.qtdFiltrosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosCabecalho++;
                }

            }

        }

    }

    modalAdicionar(visible: boolean): void {
        this.formAdicionar.modalVisible = visible;

        if (visible) {
            this.retornarOptionCertidoesManuais().then();
        }
    }

    modalEditarHistorico(visible: boolean): void {
        this.formEditarHistorico.modalVisible = visible;
    }

    async retornarOptionCertidoesManuais() {
        this.loadings.comboCertidoesManuais = true;
        this.service.retornarOptionCertidoesManuais().subscribe((retorno: any) => {
            this.optionsCertidoesManuais = [];
            this.optionsCertidoesManuais = retorno;
            this.loadings.comboCertidoesManuais = false;
        }, (res) => {
            this.loadings.comboCertidoesManuais = false;
            this.toastService.error('Erro ao caregar certidões manuais.');
        });
    }

    beforeUploadAdicionar = (file: NzUploadFile): boolean => {

        if (file.name.substr(file.name.length - 4) === '.pdf') {
            this.fileListAdicionar = [];
            this.fileListAdicionar.push(file);
        } else {
            this.toastService.error('Extensão inválida. Eperado: PDF (.pdf)');
        }

        return false;
    }

    adicionarCndManual(): void {

        if (this.formAdicionar.formGroup.valid) {


            if (!this.fileListAdicionar.length) {
                this.toastService.error('Campo Anexo obrigatório!');
            } else {

                this.loadings.adicionarCndManual = true;

                const formData = new FormData();

                this.fileListAdicionar.forEach((file: any) => {
                    formData.append('arquivo', file);
                });
                for (const [chave, valor] of Object.entries(this.formAdicionar.formGroup.value)) {

                    if (valor) {
                        if (chave === 'validade') {
                            formData.append(chave, this.service.formataDateBD(valor.toString()));
                        } else if (chave === 'emissao') {
                            formData.append(chave, this.service.formataDateTimeBD(valor.toString()));
                        } else {
                            formData.append(chave, valor.toString());
                        }
                    }
                }


                this.service.adicionarCndManual(formData).subscribe((res) => {
                    this.loadings.adicionarCndManual = false;
                    this.toastService.success('Certidão Emitida com Sucesso!');
                    this.modalAdicionar(false);
                    this.formAdicionar.formGroup.reset();
                    this.fileListAdicionar = [];
                    this.queryTable(this.currentParams, this.currentSearch);
                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.adicionarCndManual = false;
                });


            }


        } else {
            Object.values(this.formAdicionar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }


    }

    editarHistorico(historicoId: string): void {


        this.loadingsHistoricoEditar[historicoId] = true;


        this.service.getHistoricoUnico(historicoId).subscribe((res) => {
            this.editandoHistorico.id = res.id;
            this.editandoHistorico.certidao_descricao = res.certidao_descricao;
            this.editandoHistorico.empresa_nome = res.empresa_nome;
            this.editandoHistorico.empresa_cnpj = res.empresa_cnpj;
            this.editandoHistorico.controleCertidaoProcessamento_id = res.controleCertidaoProcessamento_id;

            if (res.emissao) {
                const dataEmissao = new Date(res.emissao);
                this.formEditarHistorico.formGroup.get('emissao').setValue(dataEmissao);
            }
            if (res.validade) {
                const dataValidade = new Date(res.validade);
                this.formEditarHistorico.formGroup.get('validade').setValue(dataValidade);
            }

            this.formEditarHistorico.formGroup.get('identificador').setValue(res.identificador);
            this.formEditarHistorico.formGroup.get('status').setValue(res.status.toString());


            this.modalEditarHistorico(true);

            this.loadingsHistoricoEditar[historicoId] = false;
        }, (res) => {
            this.loadingsHistoricoEditar[historicoId] = false;
            this.toastrService.error(res.error.message);
        });

    }

    confirmarEditarHistorico(): void {

        if (this.formEditarHistorico.formGroup.valid) {

            this.loadings.editarHistoricoUnico = true;

            this.service.editarHistoricoUnico(this.editandoHistorico.id, this.formEditarHistorico.formGroup.value).subscribe((res) => {
                this.toastService.success('Dados alterados com sucesso.');
                this.loadings.editarHistoricoUnico = false;
                this.modalEditarHistorico(false);
                this.retornaHistorico(this.historyId);
                this.queryTable(this.currentParams, this.currentSearch);
            }, (res) => {
                this.loadings.editarHistoricoUnico = false;
                this.toastrService.error(res.error.message);
            });

        } else {
            Object.values(this.formEditarHistorico.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }


    }

    showConfirmExcluirHistorico(id: string): void {
        this.modalService.confirm({
            nzTitle: 'Deseja remover registro?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerHistoricoCnd(id)
        });
    }

    removerHistoricoCnd(id: string): void {
        this.loadingsHistoricoExcluir[id] = true;


        this.service.excluirHistoricoUnico(id).subscribe((res) => {
            this.loadingsHistoricoExcluir[id] = false;
            this.toastService.success('Histórico removido com sucesso.');
            this.retornaHistorico(this.historyId);
            this.queryTable(this.currentParams, this.currentSearch);
        }, (res) => {
            this.loadingsHistoricoExcluir[id] = false;
            this.toastrService.error(res.error.message);
        });

    }

    modalNovaCertidao(visible: boolean): void {
        this.formNovaCertidao.modalVisible = visible;
    }

    toggleOrgao(event) {
        this.radioValueOrgao = event;
    }

    cadastrarNovaCertidao(): void {

        if (this.formNovaCertidao.formGroup.valid) {

            const dados = this.formNovaCertidao.formGroup.value;

            if (dados.orgao === 'Municipal' && !dados.municipioCodigo) {
                this.toastService.error('Código do município obrigatório');
            } else if (dados.orgao === 'Estadual' && !dados.uf) {
                this.toastService.error('UF obrigatório');
            } else {


                this.loadings.novaCertidao = true;

                this.service.novaCertidao(dados).subscribe((response) => {


                    this.retornarOptionCertidoesManuais().then(() => {
                        this.formAdicionar.formGroup.get('certidao_id').setValue(response.dados.id);
                        this.modalNovaCertidao(false);
                        this.toastrService.success('Certidão Cadastrada com Sucesso.');
                        this.loadings.novaCertidao = false;
                        this.formNovaCertidao.formGroup.reset();
                    });


                }, (res) => {
                    this.toastrService.error(res.error.message);
                    this.loadings.novaCertidao = false;
                });
            }
        } else {
            Object.values(this.formNovaCertidao.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalJustificativa(visible: boolean, historico = null): void {

        if (historico) {
            this.historicoIdSelecionado = historico.id;
            this.formEditarJustificativa.formGroup.get('justificativa').setValue(historico.justificativa);
        }

        this.formEditarJustificativa.modalVisible = visible;
    }

    updateJustificativa(): void {

        if (this.formEditarJustificativa.formGroup.valid) {

            this.loadings.editandoJustificativa = true;

            this.service.updateJustificativa(
                this.historicoIdSelecionado, this.formEditarJustificativa.formGroup.get('justificativa').value).subscribe({
                next: (response) => {
                    this.toastrService.success('justificativa alterada com sucesso!');
                    this.loadings.editandoJustificativa = false;
                    this.modalJustificativa(false);
                    this.modalHistorico(false);
                }, error: (res) => {
                    this.toastrService.error(res.error.message);
                    this.loadings.editandoJustificativa = false;
                }
            });

        } else {
            Object.values(this.formEditarJustificativa.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    apagaFiltro(toDelete: string) {
        if (this.apagarFiltro && this.formFiltrosGrid.formGroup.get(toDelete).value) {
            this.apagarFiltro = false;
            this.formFiltrosGrid.formGroup.get(toDelete).setValue(null);
        }

        setTimeout(() => {
            this.apagarFiltro = true;
        }, 200);
    }

    chagePageSize(event: number) {
        console.log(event);
    }

    async getGruposEmpresariais() {

        return this.grupoEmpresarialService.listToSelect().subscribe((result) => {
            this.grupoEmpresarialOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    async getUFs() {

        return this.service.getUFs().subscribe((result) => {
            this.optionsUF = result.estados;
        });

    }
}

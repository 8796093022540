import {NgModule} from '@angular/core';
import {NotifyTestComponent} from './notifyTest.component';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {UiModule} from '../../ui.module';
import {NotifyTestRoutingModule} from './notifyTest-routing.module';
import {CommonModule} from '@angular/common';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {HighchartsChartModule} from 'highcharts-angular';
import {SwiperModule} from 'swiper/angular';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NgxMaskModule} from 'ngx-mask';
import {SafePipeModule} from 'safe-pipe';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NzPaginationModule} from "ng-zorro-antd/pagination";

@NgModule({
    imports: [
        NotifyTestRoutingModule,
        NzCarouselModule,
        NzAnchorModule,
        NzTypographyModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        FormsModule,
        NzSelectModule,
        UiModule,
        NzTabsModule,
        CommonModule,
        NzBreadCrumbModule,
        NzProgressModule,
        NzPageHeaderModule,
        HighchartsChartModule,
        SwiperModule,
        NzSpaceModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NzUploadModule,
        NgxMaskModule,
        SafePipeModule,
        NzBadgeModule,
        SafePipeModule,
        ScrollingModule,
        NzPaginationModule
    ],
    declarations: [NotifyTestComponent],
})
export class NotifyTestModule {
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzContextMenuService} from 'ng-zorro-antd/dropdown';
import {NzTreeNode} from 'ng-zorro-antd/tree';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Menu} from '@models/menu.model';
import {ToastrService} from 'ngx-toastr';
import {MenuService} from '../../menu.service';

@Component({
    selector: 'app-detail-menu',
    templateUrl: './detail-menu.component.html',
    styleUrls: ['./detail-menu.component.scss']
})
export class DetailMenuComponent implements OnInit {
    tabName = 'Editar Menu';
    searchValue = '';
    menuTree = [];
    menuList = [];
    showAddModal = false;
    showRemoveModal = false;
    isConfirmLoading = false;
    formNewMenu: UntypedFormGroup;
    hasParent = true;
    currentNode: NzTreeNode;
    readonly menuTipos = [
        {id: 'menu', descricao: 'Menu'}, {id: 'modulo', descricao: 'Módulo'},
        {id: 'funcionalidade', descricao: 'Funcionalidade'}];
    readonly urlTipos = [
        {id: 'nativo', descricao: 'Nativo'}, {id: 'embedded', descricao: 'Embedded'},
        {id: 'externo', descricao: 'Externo'}];

    constructor(
        private router: Router,
        private menuService: MenuService,
        private nzContextMenuService: NzContextMenuService,
        private formBuilder: UntypedFormBuilder,
        private toastrService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.getMenuTree();
        this.getMenuList();
        this.buildForm();
    }

    getMenuList() {
        this.menuService.listMenus().subscribe(menuList => {
            this.menuList = menuList;
        });
    }

    getMenuTree() {
        this.menuService.getTree().subscribe(res => {
            this.menuTree = this.menuService.transformMegamenuItem(res, 1, true);
        });
    }

    buildForm() {
        this.formNewMenu = this.formBuilder.group({
            descricao: ['', Validators.required],
            descricaoEstendida: [null],
            tipo: ['', Validators.required],
            icone: null,
            url: null,
            urlTipo: null,
            ordem: null,
            parentId: null,
            childId: null,
            id: null,
            extra: null
        });
    }

    getControl = (name) => this.formNewMenu.get(name);

    edit(node: NzTreeNode) {
        this.hasParent = true;
        this.currentNode = node;
        this.formNewMenu.reset();
        this.showAddModal = true;

        this.formNewMenu.patchValue({
            parentId: node.parentNode ? node.parentNode.key : null,
            descricao: node.origin.title,
            descricaoEstendida: node.origin.description,
            tipo: node.origin.tipo,
            icone: node.origin.icon,
            url: node.origin.url,
            urlTipo: node.origin.urlType,
            ordem: node.origin.ordem,
            id: node.key,
            extra: node.origin.extra
        });
    }

    add(node?: NzTreeNode) {
        this.currentNode = node;
        this.formNewMenu.reset();
        this.showAddModal = true;
        this.hasParent = false;
        if (node) {
            this.formNewMenu.patchValue({
                parentId: node.key
            });
            this.hasParent = true;
        }
    }

    cancel(): void {
        this.showAddModal = false;
        this.showRemoveModal = false;
        this.currentNode = null;
    }

    openRemoveModal(node: NzTreeNode) {
        this.currentNode = node;
        this.showRemoveModal = true;
    }

    remove() {
        this.menuService.delete(new Menu({id: this.currentNode.key})).subscribe((res: any) => {
            if (res.hasOwnProperty('success')) {
                this.toastrService.success(res.success);
                this.menuTree = this.menuService.transformMegamenuItem(res.tree);
                this.menuService.currentMenuTree.next(this.menuTree);
            } else {
                this.toastrService.error(res.error);
            }
            this.cancel();
        });
    }

    createMenu() {
        return new Menu({
            id: this.getControl('id').value,
            descricao: this.getControl('descricao').value,
            descricaoEstendida: this.getControl('descricaoEstendida').value,
            tipo: this.getControl('tipo').value,
            urlTipo: this.getControl('urlTipo').value,
            url: this.getControl('url').value,
            parentId: this.getControl('parentId').value,
            ordem: this.getControl('ordem').value,
            icone: this.getControl('icone').value,
            child_id: this.getControl('childId').value,
            extra: this.getControl('extra').value,
        });
    }

    save() {
        this.isConfirmLoading = true;
        const menu = this.createMenu().convertToSave();
        setTimeout(() => {
            this.menuService.save(menu).subscribe((res: any) => {
                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.menuTree = this.menuService.transformMegamenuItem(res.tree);
                    this.menuService.currentMenuTree.next(this.menuTree);
                } else {
                    this.toastrService.error(res.error);
                }
            }, error => {
                this.toastrService.error(error);
            });
            this.cancel();
            this.isConfirmLoading = false;
        }, 1000);
    }

    hiddenOwnNode = (menu: Menu) => this.currentNode && this.currentNode.key === menu.id;

    handlerTree(event: any) {
        const parentNode = event.node;
        const expanded = !parentNode.isExpanded;
        this.expandChild(parentNode, expanded);
    }

    expandChild(node: NzTreeNode, expanded: boolean) {
        node.isExpanded = expanded;
        if (node.children) {
            node.children.forEach(child => this.expandChild(child, expanded));
        }
    }
}
